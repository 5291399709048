import React, { useContext } from 'react';
import Icon from 'shared/components/Icon/Icon';
import { ThemeContext } from 'styled-components';
import styles from './styles.module.scss';

const DataCalculateDescription = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <div>
      <a
        href="https://smartline.zendesk.com/hc/en-us/articles/360001294496-How-are-Report-calculations-done-in-Launchpad"
        className={styles.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="info" size="15" color={themeContext.palette.linkPrimary} />
        How is this data calculated?
      </a>
      <div className={styles.disclaimer}>
        Please be aware that if you have migrated to Broker Platform, the information in this screen will only
        include statistics for applications and loans written in Launchpad. You should reference Broker Platform
        for your reporting needs.
      </div>
    </div>
);
};

export default DataCalculateDescription;
