import { combineReducers } from 'redux';
import standAlone from './standAloneProductSearch';
import lenders from './lenders';
import features from './features';

export default combineReducers({
  standAlone,
  lenders,
  features,
});
