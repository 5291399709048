import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import { useSelector } from 'react-redux';
import ConnectedComparisonPreparerModal from '../ComparisonPreparerModal';

const businessCountSelector = state => get(state, 'profile.businesses.length', 0);
const profileSelector = state => state.profile;
const DownloadComparison = React.memo(({
  onDownload, disabled, className,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const businessCount = useSelector(businessCountSelector);
  const currentProfile = useSelector(profileSelector);

  const handleDownloadClick = useCallback(async () => {
    if (businessCount > 0) {
      setModalOpen(true);
    } else {
      setIsDownloading(true);
      await onDownload(currentProfile);
      setIsDownloading(false);
    }
  }, [onDownload, setIsDownloading, businessCount, setModalOpen, currentProfile]);

  const handleDownload = useCallback(async (profile) => {
    setIsDownloading(true);
    await onDownload(profile);
    setIsDownloading(false);
  }, [setIsDownloading, onDownload]);

  const handleRequestClose = useCallback(() => setModalOpen(false), [setModalOpen]);
  return (
    <>
      <SecondaryButton
        disabled={(isDownloading && businessCount === 0) || disabled}
        onClick={handleDownloadClick}
        className={className}
      >
        Download as PDF
      </SecondaryButton>
      {businessCount > 0 && (
      <ConnectedComparisonPreparerModal
        isOpen={modalOpen}
        onDownload={handleDownload}
        onRequestClose={handleRequestClose}
        defaultProfile={currentProfile}
      />
      )}
    </>
  );
});

DownloadComparison.defaultProps = {
  disabled: false,
  className: '',
};

DownloadComparison.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
};

export default DownloadComparison;
