import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { Item } from 'shared/components/Timeline';
import ExtraInfoContent from '../contents/ExtraInfoContent';
import logConfiguration from '../../LogConfiguration';

const getKey = (log, index) => `${log.id}-${index}`;

const LogItem = ({ activityLog, editable, context }) => {
  const { activityLogType, hide } = activityLog;

  const logMeta = useMemo(() => get(logConfiguration, activityLogType, {}), [activityLogType]);
  const { displayComponent: LogComponent, icon = 'note-circle', logTransformer } = logMeta;

  const logs = useMemo(
    () => (isFunction(logTransformer) ? logTransformer(activityLog, context) : [])
    .filter(e => !isNil(e)),
    [activityLog, logTransformer, context],
  );

  if (hide || isEmpty(logs)) {
    return null;
  }

  return (
    <>
      {
      logs.map((log, index) => {
        const {
          actionTime,
          operator,
          lastModifiedTime,
          lastModifiedByName,
          hasLastModify,
          extraInfo = {},
        } = log;
        return (
          <Item
            key={getKey(log, index)}
            iconName={icon}
            actionTime={actionTime}
            operator={operator}
            lastModifiedTime={lastModifiedTime}
            lastModifiedByName={lastModifiedByName}
            hasLastModify={hasLastModify}
          >
            <LogComponent {...log} editable={editable} />
            {!isEmpty(extraInfo.applicantNames) && (<ExtraInfoContent extraInfo={extraInfo} />)}
          </Item>
        );
      })
    }
    </>
  );
};

LogItem.defaultProps = {
  editable: false,
  context: {},
};

LogItem.propTypes = {
  activityLog: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  context: PropTypes.object,
};

export default React.memo(LogItem);
