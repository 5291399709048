import { connect } from 'react-redux';
import * as actions from '../ApplicationDetails/redux/action';
import ConfirmSettlementPopup from './ConfirmSettlementPopup';

const mapStateToProps = state => ({
  isStatusUpdating: state.application.applicationStatusRequestStatus.isUpdating,
  lendersConfig: state.lendersConfig,
});

const mapDispatchToProps = {
  updateLoanRequests: actions.updateApplicationLoanRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSettlementPopup);
