import classNames from 'classnames';
import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import shortId from 'shortid';
import styles from './ResponsiveTable.module.scss';

const Line = ({ row, header, className }) => (
  <div
    className={classNames(
      styles.line,
      className,
    )}
  >
    {
      map(row, (column) => {
        const columnHeader = find(header, { key: get(column, 'key', '') }) || {};

        return (
          <div
            className={classNames(styles.cell, styles[columnHeader.className])}
            key={shortId.generate()}
          >
            <div className={classNames(styles.title)}>{columnHeader.content}</div>
            <div className={styles.content}>{column.content}</div>
          </div>
        );
      })
    }
  </div>
);

Line.defaultProps = {
  row: [],
  className: undefined,
};

Line.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
  })).isRequired,
  row: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  })),
  className: PropTypes.string,
};

export default Line;
