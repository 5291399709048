import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ReduxFormNumberInput from '../../formFields/ReduxForm/NumberInput';

const MAX_INTEREST_ONLY_TERM = 10;
const isValidInterestOnlyTerm = term =>
  (term === undefined || (term > 0 && term <= MAX_INTEREST_ONLY_TERM));

const style = {
  display: 'inline-block',
  margin: '0rem 1.2rem 2rem 0rem',
  padding: 0,
  verticalAlign: 'top',
  width: '19.5rem',
};

const InterestOnlyTermComponent = (props) => (
  <ReduxFormNumberInput
    label="Interest only term (1-10 yrs)"
    className={styles.label}
    isAllowed={isValidInterestOnlyTerm}
    style={style}
    textClassName={classNames(styles.inputText, styles.short)}
    {...props}
  />
);

export default InterestOnlyTermComponent;
