import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';
import NewLeftRouterBar from 'shared/components/LeftNavBar/NewLeftRouterBar';
import { useHasAnyPermission } from 'shared/components/authorisation/hasPermissionTo';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import styles from './ApplicationDetailContentNav.module.scss';
import {
  ACTIVITY_TAB,
  APPLICANTS_TAB,
  APPLICATION_NAV_TABS,
  CONTACTS_TAB,
  ESSENTIAL_DATES_TAB,
  INFORMATION_GATHERING_NAV_TABS,
  INSURANCE_REFERRAL_TAB,
  LOAN_DETAIL_TAB,
  MIGRATED_APPLICATION_NAV_TABS,
  PRODUCT_SEARCH_TAB,
  SCENARIO_NAV_TABS,
} from '../../../constants/navTab';
import { isApplicationLaunchpadCreated } from '../../../utils';

const LP_CREATED_APPLICATION_HOME_NAVS = includeProductSearch => {
  const navs = [
    ACTIVITY_TAB,
    ...INFORMATION_GATHERING_NAV_TABS,
    ...SCENARIO_NAV_TABS,
    ...APPLICATION_NAV_TABS,
  ];

  return includeProductSearch ? navs : navs.filter(nav => nav.key !== PRODUCT_SEARCH_TAB.key);
};

const LP_CREATED_APPLICATION_COMMERCIAL = [
  ACTIVITY_TAB,
  APPLICANTS_TAB,
  LOAN_DETAIL_TAB,
  ESSENTIAL_DATES_TAB,
  INSURANCE_REFERRAL_TAB,
  CONTACTS_TAB,
];

const LP_CREATED_APPLICATION = (includeProductSearch, loanType) => {
  if (loanType === 'Commercial') {
    return {
      LP_CREATED_APPLICATION_SECTIONS: LP_CREATED_APPLICATION_COMMERCIAL,
      LP_CREATED_APPLICATION_NAVS: LP_CREATED_APPLICATION_COMMERCIAL,
    };
  }
  return {
    LP_CREATED_APPLICATION_SECTIONS: LP_CREATED_APPLICATION_HOME_SECTIONS(includeProductSearch),
    LP_CREATED_APPLICATION_NAVS: LP_CREATED_APPLICATION_HOME_NAVS(includeProductSearch),
  };
};

const LP_CREATED_APPLICATION_HOME_SECTIONS = includeProductSearch => ([
  ACTIVITY_TAB,
  {
    name: 'Information Gathering',
    children: INFORMATION_GATHERING_NAV_TABS,
  },
  {
    name: 'Scenario',
    children: includeProductSearch ? SCENARIO_NAV_TABS
      : SCENARIO_NAV_TABS.filter(nav => nav.key !== PRODUCT_SEARCH_TAB.key),
  },
  {
    name: 'Application summary',
    children: APPLICATION_NAV_TABS,
  },
]);

const MIGRATED_APPLICATION_NAVS = [ACTIVITY_TAB, ...MIGRATED_APPLICATION_NAV_TABS];
const MIGRATED_APPLICATION_SECTIONS = [ACTIVITY_TAB, ...MIGRATED_APPLICATION_NAV_TABS];

const ApplicationDetailContentNav = (props) => {
  const {
    applicationUpdatesCount,
    match,
    applicationSource,
    loanType,
  } = props;

  const isLaunchpadCreated = useMemo(() => isApplicationLaunchpadCreated(applicationSource), [applicationSource]);
  const includeProductSearch = useHasAnyPermission([
    PERMISSIONS.EDIT_OWN_APPLICATION_TRACKING, PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING]);

  const { LP_CREATED_APPLICATION_SECTIONS, LP_CREATED_APPLICATION_NAVS } = LP_CREATED_APPLICATION(
    includeProductSearch,
    loanType,
  );

  const sections = isLaunchpadCreated
    ? LP_CREATED_APPLICATION_SECTIONS
    : MIGRATED_APPLICATION_SECTIONS;
  const navs = isLaunchpadCreated
    ? LP_CREATED_APPLICATION_NAVS
    : MIGRATED_APPLICATION_NAVS;

  return (
    <div className={styles.contentNavWrapper}>
      <NewLeftRouterBar
        className={styles.leftNav}
        sections={sections}
        title="Details"
        circleNumberMap={{ [ACTIVITY_TAB.key]: applicationUpdatesCount }}
      />
      <div className={styles.appContent}>
        <Route
          path="/applications/:applicationId"
          exact
          render={({ history: { location: { search, hash } } }) => (
            <Redirect to={`/applications/${match.params.applicationId}/activities${search}${hash}`} />
            )}
        />
        {navs.map(({ path, component }) => (
          <Route
            key={path}
            path={`/applications/:applicationId/${path}`}
            component={component}
            exact
          />
          ))}
      </div>
    </div>
  );
};

ApplicationDetailContentNav.propTypes = {
  applicationUpdatesCount: PropTypes.number.isRequired,
  applicationSource: PropTypes.string.isRequired,
  loanType: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  applicationUpdatesCount: state.application.applicationUpdates.length,
  applicationSource: get(state, 'application.applicationDetail.source', ''),
  loanType: get(state, 'application.applicationDetail.loanType'),
});

export default withRouter(connect(mapStateToProps)(ApplicationDetailContentNav));
