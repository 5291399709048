import React from 'react';
import { iconDefaultProps, baseIcon } from './baseIcon';

const AlertInvert = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/alert-invert"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M11,15 L13,15 L13,17 L11,17 L11,15 Z M11,7 L13,7 L13,13 L11,13 L11,7 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

AlertInvert.defaultProps = iconDefaultProps;
AlertInvert.propTypes = baseIcon;

export default AlertInvert;
