import { isValidUUID } from 'shared/utils';
import isUndefined from 'lodash/isUndefined';
import { getLoanViaGraphql, updateLoanViaGraphql } from 'Loans/components/LoanDetails/loanDetailsGraphQL';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { getRequestIdFromAction, ifValidRequestId, startRequest } from 'redux/requestIds';
import { toDateStamp } from 'utils/datetime';
import { showToast } from '../../../../redux/toast/actions';

export const ACTIONS = {
  GET_LOAN_START: 'LOAN:GET_LOAN_START',
  GET_LOAN_SUCCESS: 'LOAN:GET_LOAN_SUCCESS',
  GET_LOAN_ERROR: 'LOAN:GET_LOAN_ERROR',
  TOGGLE_EDIT_MODE: 'LOAN:TOGGLE_EDIT_MODE',
  UPDATE_LOAN_SUCCESS: 'LOAN:UPDATE_LOAN_SUCCESS',
  UPDATE_LOAN_START: 'LOAN:UPDATE_LOAN_START',
  UPDATE_LOAN_ERROR: 'LOAN:UPDATE_LOAN_ERROR',
  SELECT_LOAN_TYPE: 'SELECT_LOAN_TYPE',
  SELECT_LENDER: 'LOAN:SELECT_LENDER',
};

const getLoanStart = () => ({
  type: ACTIONS.GET_LOAN_START,
});

const getLoanSuccess = loan => ({
  type: ACTIONS.GET_LOAN_SUCCESS,
  payload: {
    loan,
  },
});

const getLoanError = errors => ({
  type: ACTIONS.GET_LOAN_ERROR,
  payload: {
    errors,
  },
});

const toggleLoanEditMode = editMode => ({
  type: ACTIONS.TOGGLE_EDIT_MODE,
  payload: {
    isEditing: editMode,
  },
});

export const selectLoanType = selectedLoanType => ({
  type: ACTIONS.SELECT_LOAN_TYPE,
  payload: {
    selectedLoanType,
  },
});

export const selectLender = lender => ({
  type: ACTIONS.SELECT_LENDER,
  payload: lender,
});

export const getLoan = (id, dispatchGetLoanStart = true) => async (dispatch, getState) => {
  if (!isValidUUID(id)) {
    dispatch(getLoanError());
    return;
  }

  if (dispatchGetLoanStart) {
    dispatch(getLoanStart());
  }
  const startRequestAction = startRequest(ACTIONS.GET_LOAN_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);

  try {
    const loan = await getLoanViaGraphql(id);
    ifValidRequestId(getState().requestIds, ACTIONS.GET_LOAN_START, requestId, () => {
      dispatch(getLoanSuccess(loan.data.loanDetails));
    });
  } catch (err) {
    ifValidRequestId(getState().requestIds, ACTIONS.GET_LOAN_START, requestId, () => {
      dispatch(getLoanError(err.graphQLErrors));
    });
  }
};

export const toggleEdit = editMode => (dispatch) => {
  dispatch(toggleLoanEditMode(editMode));
};

const updateLoanStart = () => ({
  type: ACTIONS.UPDATE_LOAN_START,
});

const updateLoanError = errors => ({
  type: ACTIONS.UPDATE_LOAN_ERROR,
  payload: {
    errors,
  },
});
const updateLoanSuccess = { type: ACTIONS.UPDATE_LOAN_SUCCESS };

const getYesNoValue = (option) => {
  if (option === undefined) {
    return null;
  }
  return Object.keys(option).includes('value') ? option.value : option;
};

const getStringValue = value => (trim(value) || null);

const getDropDownValue = option => (option ? option.value : null);

const getCommercialProductType = loan => (loan.loanType && loan.loanType.value === 'Commercial'
  ? getDropDownValue(loan.commercialProductType) : null);

const getFixedTerm = loan => (loan.productType && loan.productType.value === 'Fixed' && loan.fixedTerm ? loan.fixedTerm : null);

const getLenderValue = lender => (lender ? { id: lender.value, name: lender.label } : null);

const getAssetFinanceLenderValue = (subLender, lender) => {
  if (!lender || (lender.label !== 'Smartline Asset Finance' && lender.label !== 'Xenium Finance')) {
    return null;
  }

  return (subLender ? {
    id: subLender.value,
    name: subLender.label,
  } : null);
};

const getUpdateLoanRequest = (formsyFields, state, isComplianceFormCompleted) => {
  const isExistingClientFromState = get(state, 'loans.loan.data.referrer.isExistingClient');

  const isExistingClient = isUndefined(formsyFields.isExistingClient)
    ? isExistingClientFromState
    : getYesNoValue(formsyFields.isExistingClient);

  return {
    referrer: { isExistingClient },
    commission: {
      isUpfrontExpected: getYesNoValue(formsyFields.isUpfrontExpected),
      isTrailExpected: getYesNoValue(formsyFields.isTrailExpected),
      excludeFromSafetyNet: getYesNoValue(formsyFields.excludeFromSafetyNet),
      expectedUpfrontOverride: formsyFields.expectedUpfrontOverride || null,
      upfrontPercentageOverride: formsyFields.upfrontPercentageOverride || null,
      fixedTrailPercentage: formsyFields.fixedTrailPercentage || null,
      fixedTrailStartDate: formsyFields.fixedTrailStartDate ? toDateStamp(formsyFields.fixedTrailStartDate) : null,
      fixedTrailEndDate: formsyFields.fixedTrailEndDate ? toDateStamp(formsyFields.fixedTrailEndDate) : null,
    },
    accountNumber: getStringValue(formsyFields.accountNumber),
    approvalNumber: getStringValue(formsyFields.approvalNumber),
    adviserId: getDropDownValue(formsyFields.adviserId),
    discountRate: formsyFields.discountRate,
    clientDiscountRate: formsyFields.clientDiscountRate,
    baseRate: formsyFields.baseRate,
    commercialProductType: getCommercialProductType(formsyFields),
    fixedTerm: getFixedTerm(formsyFields),
    lastKnownRate: formsyFields.lastKnownRate,
    loanAmount: formsyFields.loanAmount,
    loanTerm: formsyFields.loanTerm,
    loanType: getDropDownValue(formsyFields.loanType),
    productPurpose: getDropDownValue(formsyFields.productPurpose),
    productType: getDropDownValue(formsyFields.productType),
    repaymentType: getDropDownValue(formsyFields.repaymentType),
    lender: getLenderValue(formsyFields.lender),
    assetFinanceLender: getAssetFinanceLenderValue(formsyFields.subLender, formsyFields.lender),
    isComplianceFormCompleted,
    repaidDate: formsyFields.repaidDate ? toDateStamp(formsyFields.repaidDate) : null,
    status: getDropDownValue(formsyFields.status),
    productName: getYesNoValue(formsyFields.isCustomProduct)
    ? getStringValue(formsyFields.customProductName)
    : getDropDownValue(formsyFields.productName),
  };
};

export const updateLoan = (formsyFields, isComplianceFormCompleted) => async (dispatch, getState) => {
  dispatch(updateLoanStart());
  const loanId = get(getState(), 'loans.loan.data.id');

  try {
    const updateLoanRequest = getUpdateLoanRequest(formsyFields, getState(), isComplianceFormCompleted);
    await updateLoanViaGraphql(updateLoanRequest, loanId);
    dispatch(showToast('Your changes to the loan have been saved', { type: 'success' }));
    await dispatch(getLoan(loanId, false));
    dispatch(updateLoanSuccess);
  } catch (err) {
    dispatch(updateLoanError(err));
  }
};
