import { gql } from '@apollo/client';

export const GET_EXISTING_LOANS = gql`
    query loansByApplicants($lenderId: String, $businessId: String!, $applicants: ApplicantSearchRequest!) {
        loansByApplicants(lenderId: $lenderId, businessId: $businessId, applicants: $applicants) {
            loans {
                id
                accountNumber
                settledAmount
                lenderName
                productName
                applicantsName
            }
        }
    }

`;

export const GET_EXISTING_LOANS_WITH_MORE_INFO = gql`
  query loansByApplicants($lenderId: String, $businessId: String!, $applicants: ApplicantSearchRequest!) {
    loansByApplicants(lenderId: $lenderId, businessId: $businessId, applicants: $applicants) {
      loans {
        id
        accountNumber
        settledAmount
        repaymentType
        productType
        loanTerm
        lenderName
        productName
        applicantsName
      }
    }
  }
`;
