import { useQuery, gql } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import MultiSelect from '../../formFields/MultiSelect';
import styles from './styles.module.scss';

export const GET_ALL_LENDERS = gql`
  query allLenders {
    allLenders {
      name
    }
  }
`;

const getLenderNames = (loading, error, data) => {
  if (loading || error) {
    return undefined;
  }

  return data ? map(data.allLenders, lender => lender.name) : undefined;
};

const LendersSelect = ({
                         value,
                         onChange,
                       }) => {
  const { loading, error, data } = useQuery(GET_ALL_LENDERS);
  const lenderNames = getLenderNames(loading, error, data);

  return (
    <div className={styles.lendersSelect}>
      <MultiSelect
        label="Lenders"
        placeholder="Type to add lenders"
        name="lenders"
        valuesSet={lenderNames}
        selectedValues={value}
        hasError={!isEmpty(error)}
        onChange={onChange}
      />
    </div>
  );
};

LendersSelect.defaultProps = {
  value: [],
};

LendersSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default LendersSelect;
