import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Context from './Context';

export const Provider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const close = useCallback(() => setModal(null), [setModal]);
  return (
    <Context.Provider value={{ trigger: setModal, close }}>
      {children}
      {modal}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export default Provider;
