import React from 'react';
import Feature from '../Feature';
import ConnectedProductType from '../ProductSearchFields/ProductType';
import ConnectedRepaymentType from '../ProductSearchFields/RepaymentType';
import ConnectedPurpose from '../ProductSearchFields/Purpose';
import ConnectedLoanTerm from '../ProductSearchFields/LoanTerm';
import ConnectedFixedLoanTerm from '../ProductSearchFields/FixedLoanTerm';
import ConnectedInterestOnlyTerm from '../ProductSearchFields/InterestOnlyTerm';
import ConnectedLoanAmount from '../ProductSearchFields/LoanAmount';
import ConnectedSecurityValue from '../ProductSearchFields/SecurityValue';
import ConnectedLoanValueRatio from '../ProductSearchFields/LoanValueRatio';
import ConnectedLendersSelect from '../Lenders/LendersSelect';

const LoanCriteria = () => (
  <form>
    <div className="criteria-section">
      <div className="criteria-header">
        <h2>Loan criteria</h2>
      </div>
      <div className="criteria-body">
        <div className="first-section">
          <div>
            <ConnectedLoanAmount index={0} />
            <ConnectedSecurityValue />
            <ConnectedLoanValueRatio />
          </div>
          <div>
            <ConnectedPurpose index={0} />
            <ConnectedProductType index={0} />
            <ConnectedRepaymentType index={0} />
          </div>
          <div>
            <ConnectedLoanTerm index={0} />
            <ConnectedFixedLoanTerm index={0} />
            <ConnectedInterestOnlyTerm index={0} />
          </div>
          <div>
            <ConnectedLendersSelect />
          </div>
        </div>
        <div className="features-section">
          <Feature index={0} />
        </div>
      </div>
    </div>
  </form>
);

export default LoanCriteria;
