/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';

const Checkbox = (props) => {
  const {
    labelName, disabled, readOnly, styles, onChange, checked, defaultChecked, className, inputProps, name, ...others
  } = props;
  return (
    <label
      style={styles.label}
      className={`checkbox formField ${disabled ? 'disabledFormFields' : ''} ${className}`}
      {...others}
    >
      <input
        style={styles.input}
        type="checkbox"
        disabled={disabled || readOnly}
        onChange={e => onChange({ checked: get(e, 'target.checked') })}
        checked={checked}
        defaultChecked={defaultChecked}
        name={name}
        {...inputProps}
      />
      {labelName && (<span style={styles.span}>{labelName}</span>)}
    </label>
  );
};

Checkbox.propTypes = {
  labelName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  inputProps: PropTypes.object,
  styles: PropTypes.shape({
    label: PropTypes.object,
    span: PropTypes.object,
    input: PropTypes.object,
  }),
};

Checkbox.defaultProps = {
  checked: undefined,
  defaultChecked: undefined,
  labelName: undefined,
  disabled: false,
  readOnly: false,
  styles: {},
  className: '',
  name: '',
  onChange: noop,
  inputProps: {},
};

export default Checkbox;
