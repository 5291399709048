import React from 'react';
import TotalLoanAmountComponent from './TotalLoanAmountComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedTotalLoanAmount = () => (
  <ProductSearchContext.Consumer>
    {
      context => (<TotalLoanAmountComponent totalLoanAmount={context.applicationCriteria.totalLoanAmount} />)
    }

  </ProductSearchContext.Consumer>
);

export default ConnectedTotalLoanAmount;
