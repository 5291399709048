export const LENDER = {
  lenderId: 'Lender',
  name: 'Lender name',
};

export const ADDRESS = {
  state: 'Address state',
  countryCode: 'Country',
  addressType: 'Address type',
  // standard
  street: 'Address street',
  name: 'Street name',
  type: 'Street type',
  no: 'Street no.',
  // non-standard
  nonStdAddress: 'Non standard address',
};
export const EMPLOYER = {
  personName: {
    surname: 'Employer surname',
  },
  employerContact: {
    phone: 'Employer phone',
    countryCode: 'Employer country',
    address: ADDRESS,
  },
};
export const EMPLOYMENT_INCOME = {
  value: 'Income',
  periodUnit: 'Frequency',
  type: 'Income type',
};
export const EMPLOYMENT = {
  status: 'Status',
  role: 'Occupation',
  type: 'Employment status',
  employmentType: 'Employment type',
  employmentIncomes: EMPLOYMENT_INCOME,
  employer: EMPLOYER,
};

export const COMPANY_INCOME = {
  type: 'Income type',
};

export const SIGNIFICANT_CHANGE = {
  amount: 'Financial impact - monthly',
  periodOfImpact: 'Period of impact',
  type: 'Nature of change',
  repaymentPlanOtherDetail: 'Repayment plan of other detail',
  typeOtherDetail: 'Type of other detail',
};
export const FUTURE_CIRCUMSTANCE = {
  // rate
  importantDegree: 'Importance',
  'adverseChanges.significantChanges': SIGNIFICANT_CHANGE,
};

const buildPreference = suffix => ({
  importantDegree: `Important degree in ${suffix}`,
  otherDetail: `Other detail in ${suffix}`,
});
export const NEEDS_AND_OBJECTIVE_RATE = {
  variable: buildPreference('variable'),
  fixed: buildPreference('fixed'),
  fixedAndVariable: buildPreference('fixed and variable'),
};
export const NEEDS_AND_OBJECTIVE_REMPAYMENT = {
  principalAndInterest: buildPreference('principal and interest'),
  interestOnly: buildPreference('interest only'),
  interestInAdvance: buildPreference('interest in advance'),
  lineOfCredit: {
    ...buildPreference('line of credit'),
    repaymentPlanOtherDetail: 'Repayment plan of other detail in line of credit',
  },
};
export const NEEDS_AND_OBJECTIVE_PRODUCT_FEATURE = {
  offset: buildPreference('offset'),
  redraw: buildPreference('redraw'),
};
export const NEEDS_AND_OBJECTIVE = {
  rate: NEEDS_AND_OBJECTIVE_RATE,
  repayment: NEEDS_AND_OBJECTIVE_REMPAYMENT,
  productFeature: NEEDS_AND_OBJECTIVE_PRODUCT_FEATURE,
  futureCircumstances: FUTURE_CIRCUMSTANCE,
};
export const ADDITIONAL_INCOME = {
  type: 'Income type',
  periodUnit: 'Frequency',
  value: 'Income',
  governmentBenefitsType: 'Government benefits type',
};
export const ADVISER = {
  surname: 'Surname',
  nameTitle: 'Title',
  isAdviser: 'It\'s not adviser',
  isActive: 'It\'s not active',
  brokerCodes: 'Broker code',
  mobilePhone: 'At least one phone number must not be null',
  officePhone: 'Office phone',
  officeFax: 'Office fax',
};

export const LIABILITY = {
  clearingStatus: 'Pay out/top up',
  currentBalance: 'Outstanding balance',
  value: 'Repayments',
  periodUnit: 'Repayment frequency',
  type: 'Liability type',
  accountDetails: {
    accountNumber: 'Account number',
  },
  taxDeductible: 'Tax deductible',
};

export const MARITAL_STATUS = {
  status: 'Marital status',
  spouse: {
    relatedApplicantId: 'Related applicant',
    surname: 'Spouse surname',
    isNonApplicant: 'It\'s not applicant',
  },
};

export const APPLICANT = {
  // general
  nameTitle: 'Title',
  surname: 'Surname',
  isPrimary: 'Primary applicant',
  gender: 'Gender',
  // driversLicense
  driversLicense: {
    expiryDate: 'Expiry date',
    number: 'Drivers license No.',
  },
  // maritalStatus
  maritalStatus: MARITAL_STATUS,
  // residency
  residency: 'Residency status',
  country: 'Country of residence',
  'contactDetails.addresses': ADDRESS,
};

const BENEFICIARY = {
  surname: 'Surname',
};

export const COMPANY = {
  isPrimary: 'Primary applicant',
  beneficiaries: BENEFICIARY,
};

export const EXPENSE = {
  value: 'Expense value',
  type: 'Expense type',
  periodUnit: 'Expense frequency',
  monthlyValue: 'Monthly value',
};
export const THIRD_PARTY = {
  type: 'Type',
  surname: 'Surname',
};
export const ASSET = {
  // general
  value: 'Value',
  primaryPurpose: 'Purpose',
  propertyType: 'Property type',
  asSecurity: 'Use as security',
  isPreApproval: 'Pre approval',
  accountNumber: 'Account number',
  // rentalIncome
  rentalAmount: 'Rental income',
  periodUnit: 'Rental frequency',
};
export const REAL_ESTATE = {
  value: 'Value',
  primaryPurpose: 'Purpose',
  asSecurity: 'Use as security',
  transactionType: 'Transaction type',
  rentalIncome: {
    rentalAmount: 'Rental income',
    periodUnit: 'Rental frequency',
  },
  address: ADDRESS,
  isPreApproval: 'Pre approval',
  residentialType: 'Residential type',
  propertyType: 'Property type',
  ruralType: 'Rural type',
  contractPrice: {
    contractPriceAmount: 'Price amount',
  },
};

export const SECURITY_ASSET = {
  ...REAL_ESTATE,
  value: 'Purchase price',
};

export const NON_REAL_ESTATE = {
  value: 'Value',
  accountNumber: 'Account number',
};
export const HOUSEHOLD_EXPENSE = {
  value: 'Value',
  type: 'Type',
  periodUnit: 'Frequency',
  monthlyValue: 'Monthly value',
};
export const LOAN = {
  term: 'Loan term',
  amount: 'Loan amount',
  absLendingPurposeCode: 'Purpose details',
  code: 'Product code',
  purpose: 'Purpose',
  product: 'Product',
};
