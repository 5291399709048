import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import cx from 'classnames';
import { TemplateConnector } from '@devexpress/dx-react-core';
import styles from '../style.module.scss';

/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
const TableSelectionCell = (props) => {
  const {
    selected,
    onToggle,
    style,
    className,
  } = props;
  const styleWithoutBackgroundColor = omit(style, 'backgroundColor');
  return (
    <TemplateConnector>
      {({ disableSelection, reachMaximumSelectionCount, classNames = {} }) => (
        <td
          className={cx(className, styles.cell, styles.selectionCell, classNames.cell, classNames.selectionCell)}
          style={styleWithoutBackgroundColor}
        >
          <input
            type="checkbox"
            checked={selected}
            disabled={disableSelection || (reachMaximumSelectionCount && !selected)}
            onChange={(e) => {
              onToggle(e.target.checked);
            }}
          />
        </td>
      )}
    </TemplateConnector>
  );
};
/* eslint-enable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
TableSelectionCell.propTypes = {
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default TableSelectionCell;
