import React from 'react';
import {
  toPercentage,
  toRatePercentage,
  toDollarAmount,
  formatOngoingFee,
  formatCostCalculation,
  formatLoanTerm,
  formatDiscountMonth,
  formatYearAndMonth,
} from 'shared/formatterUtils';
import { labelOf } from './constants';
import { REPAYMENT_FREQUENCIES } from '../../../../constants';
import { convertDecimalYearToIntegerYearAndMonth } from '../../../utils';

export const formatAttribute = (attributeName, product) => {
  const COMPARISON_RATE_DEFAULT = '--%';
  const repaymentTypeAvailable = type =>
    product.repaymentAvailable && product.repaymentTypes && product.repaymentTypes.indexOf(type) >= 0;
  const formatterMap = {
    totalCost: formatCostCalculation,
    firstPeriodRepayment: repayment =>
      formatCostCalculation(repayment, product.repaymentAvailable),
    firstPeriodPrincipalAndInterest: repayment =>
      formatCostCalculation(repayment, repaymentTypeAvailable('Principal and Interest Repayment')),
    firstPeriodInterestOnly: repayment =>
      formatCostCalculation(repayment, repaymentTypeAvailable('Interest Only')),
    upfrontFee: toDollarAmount,
    ongoingFee: fee => formatOngoingFee(fee, product.ongoingFeeFrequency),
    comparisonRate: cr => toRatePercentage(cr, COMPARISON_RATE_DEFAULT),
    standardRate: toRatePercentage,
    discountRate: toRatePercentage,
    discountMonths: (discountMonths) => (discountMonths === 'N/A' ? discountMonths : formatDiscountMonth(discountMonths)),
    minLoanValueRatio: toPercentage,
    maxLoanValueRatio: toPercentage,
    minLoanAmount: toDollarAmount,
    maxLoanAmount: toDollarAmount,
    minTerm: formatLoanTerm,
    maxTerm: formatLoanTerm,
    lmi: toDollarAmount,
  };

  return (formatterMap[attributeName] || (a => a))(product[attributeName]);
};

export const getDisplayOfRowName = (rowName, data) => (
  <>
    <span>{labelOf[rowName] || rowName}</span>
    {
        rowName === 'totalCost' && (
          <div>
            (
            {formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(data.totalCostTerm))}
            )
          </div>
        )
      }
    {
        rowName === 'firstPeriodRepayment' && (
          <div>
            (
            {REPAYMENT_FREQUENCIES[data.repaymentFrequency]}
            )
          </div>
        )
      }
  </>
  );
