import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import HasPermissions from 'shared/components/authorisation/HasPermissions';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const DownloadExcelButton = ({
  name,
  disabled,
  downloadReport,
  withIcon,
  allowedPermissions,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <HasPermissions permissions={allowedPermissions} fallback={null}>
      <SecondaryButton
        className={cx({ downloadButton: true })}
        disabled={disabled}
        onClick={downloadReport}
      >
        <span>{name}</span>
        {withIcon && (
          <Icon
            name="file-excel"
            color={disabled ? themeContext.palette.textSecondary : themeContext.palette.textPrimary}
          />
        )}
      </SecondaryButton>
    </HasPermissions>
  );
};

DownloadExcelButton.defaultProps = {
  withIcon: true,
};

DownloadExcelButton.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  downloadReport: PropTypes.func.isRequired,
  withIcon: PropTypes.bool,
  allowedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      application: PropTypes.string.isRequired,
      area: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DownloadExcelButton;
