import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Buttons/Button';
import Modal from 'shared/components/Modal';
import { SIZES } from 'shared/components/Modal/Modal';
import DatePicker from 'shared/components/formFields/DatePicker';
import { connect } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import { LoanSplitInput } from './LoanSplitInput';
import ComplianceSection from './ComplianceSection';
import styles from './LoanSplit.module.scss';
import {
  add,
  reset,
  remove,
  updateSettledAmount,
  updateSplitAccountNumber,
  updateSplitSettledAmount,
  updateSplitSettledDate,
  updateSplitter,
  createLoans,
  updateHasDeclared,
} from './redux/loanSplitActions';
import { splitsSelector } from './redux/loanSplitReducer';

const LoanSplitModal = ({
  accountNumber,
  lenderId,
  dispatchAdd,
  dispatchReset,
  dispatchRemove,
  dispatchUpdateSettledAmount,
  dispatchUpdateSplitAccountNumber,
  dispatchUpdateSplitSettledAmount,
  dispatchUpdateSplitSettledDate,
  dispatchCreateLoans,
  dispatchUpdateSplitter,
  isOpen,
  onClose,
  settledAmount,
  splitSettledDate,
  splits,
  splitter,
  hasDeclared,
  dispatchHasDeclared,
}) => {
  useEffect(() => {
    dispatchReset();
  }, [accountNumber, dispatchReset]);

  const [isLoading, setIsLoading] = useState(false);
  const isSplitSettledDateAfterToday = moment(splitSettledDate).isAfter(moment());

  const isInvalid = () => {
    let invalidInputs = isEmpty(accountNumber) || !isNumber(settledAmount);
    const isInvalidDeclaration = hasDeclared === undefined;
    splits.forEach((s) => {
      if (isEmpty(s.accountNumber) || !isNumber(s.settledAmount)) {
        invalidInputs = true;
      }
    });
    return invalidInputs || isInvalidDeclaration || isSplitSettledDateAfterToday;
  };

  const handleCreateLoans = async () => {
    await setIsLoading(true);
    try {
      await dispatchCreateLoans();
      dispatchReset();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const maxDate = useMemo(() => moment().format('YYYY-MM-DD'), []);

  return (
    <Modal
      isOpen={isOpen}
      header="Splitting the loan"
      footer={<Button disabled={isInvalid()} loading={isLoading} onClick={handleCreateLoans}>Create loans</Button>}
      onRequestClose={onClose}
      size={SIZES.LARGE}
    >
      <div className={styles.modalBody}>
        <section>
          <div className={styles.whiteBox}>
            <h4>Loan being split</h4>
            <NewInputLabelWrapper label="Account number">
              <div>{accountNumber}</div>
            </NewInputLabelWrapper>
          </div>
          <div className={styles.whiteBox}>
            <h4>Split request</h4>
            <div className={styles.inputColumns}>
              <LoanSplitInput
                number={1}
                accountNumber={accountNumber}
                lenderId={lenderId}
                settledAmount={settledAmount}
                onSettledAmountChange={dispatchUpdateSettledAmount}
              />
              <DatePicker
                label="Confirm settlement date for new loans"
                type="date"
                value={splitSettledDate}
                className={styles.datePicker}
                max={maxDate}
                onChange={dispatchUpdateSplitSettledDate}
                errorMessage={isSplitSettledDateAfterToday ? 'Please enter a date not after today' : ''}
                fullWidth
              />
              {splits.map((split, index) => (
                <LoanSplitInput
                  key={split.id}
                  number={index + 2}
                  accountNumber={split.accountNumber}
                  lenderId={lenderId}
                  settledAmount={split.settledAmount}
                  onAccountNumberChange={number => dispatchUpdateSplitAccountNumber(split.id, number)}
                  onSettledAmountChange={amount => dispatchUpdateSplitSettledAmount(split.id, amount)}
                  onRemove={() => dispatchRemove(split.id)}
                />
              ))}
            </div>
            <Button
              onClick={dispatchAdd}
              disabled={splits.length > 7}
              style={{ minWidth: 'auto', marginTop: '1.3rem' }}
            >Add split
            </Button>
          </div>
        </section>
        <ComplianceSection
          splitter={splitter}
          hasDeclared={hasDeclared}
          onSplitterChange={dispatchUpdateSplitter}
          onHasDeclaredChange={dispatchHasDeclared}
        />
      </div>
    </Modal>
  );
};

LoanSplitModal.defaultProps = {
  settledAmount: null,
  splitSettledDate: null,
  splits: [],
  hasDeclared: undefined,
};

LoanSplitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  accountNumber: PropTypes.string.isRequired,
  lenderId: PropTypes.string.isRequired,
  settledAmount: PropTypes.number,
  splitSettledDate: PropTypes.instanceOf(Date),
  splitter: PropTypes.string.isRequired,
  splits: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    accountNumber: PropTypes.string,
    settledAmount: PropTypes.number,
  })),
  hasDeclared: PropTypes.bool,
  dispatchHasDeclared: PropTypes.func.isRequired,
  dispatchAdd: PropTypes.func.isRequired,
  dispatchReset: PropTypes.func.isRequired,
  dispatchRemove: PropTypes.func.isRequired,
  dispatchUpdateSettledAmount: PropTypes.func.isRequired,
  dispatchUpdateSplitAccountNumber: PropTypes.func.isRequired,
  dispatchUpdateSplitSettledAmount: PropTypes.func.isRequired,
  dispatchUpdateSplitSettledDate: PropTypes.func.isRequired,
  dispatchCreateLoans: PropTypes.func.isRequired,
  dispatchUpdateSplitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({
 loans: {
   loan: { data: { accountNumber, lender: { lenderId } } },
   loanSplit,
 },
}) => ({
  accountNumber,
  lenderId,
  settledAmount: loanSplit.settledAmount,
  splitSettledDate: loanSplit.splitSettledDate,
  splits: splitsSelector(loanSplit),
  splitter: loanSplit.splitter,
  hasDeclared: loanSplit.hasDeclared,
});

const mapDispatchToProps = dispatch => ({
  dispatchAdd: () => dispatch(add()),
  dispatchReset: () => dispatch(reset()),
  dispatchRemove: id => dispatch(remove(id)),
  dispatchUpdateSettledAmount: settledAmount => dispatch(updateSettledAmount(settledAmount)),
  dispatchUpdateSplitAccountNumber: (id, accountNumber) => dispatch(updateSplitAccountNumber(id, accountNumber)),
  dispatchUpdateSplitSettledAmount: (id, settledAmount) => dispatch(updateSplitSettledAmount(id, settledAmount)),
  dispatchUpdateSplitSettledDate: splitSettledDate => dispatch(updateSplitSettledDate(splitSettledDate)),
  dispatchCreateLoans: () => dispatch(createLoans()),
  dispatchUpdateSplitter: splitter => dispatch(updateSplitter(splitter)),
  dispatchHasDeclared: hasDeclared => dispatch(updateHasDeclared(hasDeclared)),
});

const connectLoanSplitModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanSplitModal);

export { connectLoanSplitModal as LoanSplitModal };
