import {
  PALETTE,
  FONTS,
  PADDING,
  MARGIN,
} from 'shared/theme/depricatedStyles';
import colours from 'shared/theme/colours';
import { groupNames } from './constants';

const alternateColor = (index, colors) => (
  index % 2 === 0 ? colors[0] : colors[1]
);

const productAttrContainer = width => ({
  flexGrow: '1',
  display: 'inline-block',
  verticalAlign: 'top',
  minWidth: '14rem',
  maxWidth: '32rem',
  width,
});

const titleColor = index => ({
  background: alternateColor(
    index,
    [PALETTE.comparisonTable.darkBackground, PALETTE.comparisonTable.greyBackground],
  ),
});

const titlePadding = isSplit => ({ padding: isSplit ? PADDING.medium : PADDING.mediumLarge });

const cellColor = (columnIndex, rowIndex, rowName) => {
  const colors = columnIndex % 2 === 0
    ? [PALETTE.comparisonTable.rowWhite, PALETTE.comparisonTable.rowDark]
    : [PALETTE.comparisonTable.rowGrey, PALETTE.comparisonTable.rowDark];
  const color = groupNames.includes(rowName)
    ? PALETTE.comparisonTable.groupNameRow
    : alternateColor(rowIndex, colors);
  return { background: color };
};

const commonCellStyle = {
  minWidth: '22.6rem',
  minHeight: '3.8rem',
  fontFamily: FONTS.standard,
};

export default {
  productAttrContainer,
  titleColor,
  titlePadding,
  cellColor,
  comparisonTable: {
    position: 'relative',
  },
  bodyCell: (columnIndex, rowIndx, rowName) => ({
    ...cellColor(columnIndex, rowIndx, rowName),
    position: 'relative',
    paddingTop: '0',
    borderRight: `${PADDING.small} solid white`,
  }),
  contentCell: {
    ...commonCellStyle,
    textAlign: 'right',
    fontSize: FONTS.size.large,
  },
  rowName: {
    ...commonCellStyle,
    textAlign: 'left',
    padding: `${PADDING.medium} ${PADDING.xxlarge}`,
    width: '22.6rem',
    minHeight: '3.8rem',
    fontSize: FONTS.size.large,
    lineHeight: 'normal',
    whiteSpace: 'normal',
  },
  titleCell: {
    ...commonCellStyle,
    padding: PADDING.none,
    borderRight: `${PADDING.small} solid white`,
  },
  title: {
    color: PALETTE.primaryText,
  },
  lenderName: {
    fontWeight: 'bold',
    fontSize: FONTS.size.xlarge,
    lineHeight: '1',
  },
  totals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  total: {
    textAlign: 'left',
    margin: `${MARGIN.small} ${MARGIN.large} ${MARGIN.none} ${MARGIN.none}`,
  },
  totalRepaymentText: {
    fontSize: FONTS.size.small,
    margin: MARGIN.none,
    padding: PADDING.none,
  },
  totalRepaymentValue: {
    fontWeight: 'bold',
    fontSize: FONTS.size.xlarge,
    margin: `${MARGIN.xxsmall} ${MARGIN.none} ${MARGIN.none}`,
    padding: PADDING.none,
  },
  productNames: {
    color: colours.ocean,
    padding: `${PADDING.medium} ${PADDING.none}`,
    minWidth: '14rem',
    display: 'flex',
    flexDirection: 'row',
  },
  productName: {
    whiteSpace: 'normal',
    padding: `${PADDING.none} ${PADDING.mediumLarge}`,
    fontSize: FONTS.size.regular,
  },
  productAttr: {
    padding: PADDING.medium,
  },
  groupName: {
    ...commonCellStyle,
    fontFamily: FONTS.standardBold,
    fontSize: FONTS.size.xlarge,
    fontWeight: 'bold',
    textAlign: 'left',
    width: '22.6rem',
    height: '3.8rem',
    padding: `${PADDING.medium} ${PADDING.xxlarge}`,
  },
  firstColumn: {
    position: 'relative',
    marginRight: MARGIN.medium,
  },
  rowNameShadow: {
    mixBlendMode: 'multiply',
    backgroundImage: 'linear-gradient(to right, #e5e5e5, rgba(255, 255, 255, 0))',
    position: 'absolute',
    width: MARGIN.small,
    height: '100%',
    left: '22.6rem',
    zIndex: '1',
    top: '0',
  },
  viewHeight: {
    height: '95vh',
  },
  tableHeadShadow: {
    height: MARGIN.medium,
    minHeight: MARGIN.medium,
    backgroundImage: 'linear-gradient(to bottom, #e5e5e5, rgba(224, 224, 224, 0.2) 100%)',
  },
  availabilityIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: MARGIN.medium,
  },
  availability: {
    display: 'inline-block',
    lineHeight: '1rem',
    fontSize: FONTS.size.large,
  },
  featureComments: {
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    letterSpacing: '0.02rem',
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  modifiableField: {
    padding: 0,
    fontFamily: FONTS.standard,
    text: { color: colours.ocean, marginLeft: '.5rem', cursor: 'pointer' },
  },
};
