import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  inputBaseDefaultProps,
  inputBaseProps,
} from 'shared/components/formFields/base/baseProps';
import { wrapInLabel } from 'shared/components/formFields/NewInputLabelWrapper';
import styles from './TextInput.module.scss';

const TextInput = ({
  value,
  className,
  textClassName,
  disabled,
  onChange,
  onBlur,
  fullWidth,
  errorMessage,
  hasError,
  mini,
  isAllowed,
  ...others
}) => (
  <input
    value={value}
    type="text"
    className={classNames(styles.textContainer, className, {
      [styles.miniTextContainer]: mini,
    })}
    onChange={event => isAllowed(event.target.value) && onChange(event.target.value)}
    onBlur={onBlur}
    disabled={disabled}
    {...others}
  />
  );

TextInput.defaultProps = {
  value: undefined,
  mini: false,
  isAllowed: () => true,
  ...inputBaseDefaultProps,
};

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mini: PropTypes.bool,
  isAllowed: PropTypes.func,
  ...inputBaseProps,
};

export default wrapInLabel(TextInput, { disabled: styles.disabled, error: styles.textContainerError });
