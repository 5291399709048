import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import filter from 'lodash/filter';
import get from 'lodash/get';
import shortId from 'shortid';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import keys from 'lodash/keys';
import uniqBy from 'lodash/uniqBy';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import Icon from 'shared/components/Icon/Icon';
import { matchPath, withRouter } from 'react-router-dom';
import ExternalLink from 'shared/components/Links/ExternalLink';
import styles from './ValidationSettlementErrorPopup.module.scss';
import { ESSENTIAL_DATES_TAB } from '../../constants/navTab';

const errorPopUpButtonStyle = {
  height: '3rem',
  minWidth: '16.2rem',
  padding: '0',
};
const launchpad = 'Launchpad';
const lotusNotes = 'Lotus notes';
const errorPopUpNotification = 'Please update the following missing information before you can settle this application:';
const adviserErrorNotification = 'To change the Adviser, please use the "Change the Writer" button';

const errorPropertyDisplayMapping = {
  'writer.firstName': {
    content: 'Adviser name',
    note: adviserErrorNotification,
    fixIn: lotusNotes,
  },
  'writer.surname': {
    content: 'Adviser name',
    note: adviserErrorNotification,
    fixIn: lotusNotes,
  },
  'referrer.isExistingClient': {
    content: 'Client origin',
    fixIn: lotusNotes,
  },
  'applicationStatus.actionTimeLineMap\\(SUBMITTED\\).statusDateTime': {
    content: (context) => {
      const { applicationId, location: { pathname } } = context;
      const matchProfile = matchPath(pathname, {
        path: '/applications/:applicationId/:activeTab',
      });
      return (
        <>
          Submitted date
          {get(matchProfile, 'params.activeTab') === ESSENTIAL_DATES_TAB.path ? ''
          : (
            <>
              <span> - </span>
              <ExternalLink to={`/applications/${applicationId}/essentialDates`}>add to essential dates</ExternalLink>
            </>
            )}
        </>
      );
    },
    fixIn: launchpad,
  },
  'loans\\[\\d\\].amount': {
    content: 'Loan amount',
    fixIn: lotusNotes,
  },
  'loans\\[\\d\\].loanType': {
    content: 'Loan type',
    fixIn: lotusNotes,
  },
  'loans\\[\\d\\].product.purpose': {
    content: 'Loan purpose',
    fixIn: lotusNotes,
  },
  'loans\\[\\d\\].purposeCategory': {
    content: 'Purpose category',
    fixIn: lotusNotes,
  },
  'loans\\[\\d\\].term': {
    content: 'Loan term',
    fixIn: lotusNotes,
  },
  'loans\\[\\d\\].isTopUp': {
    content: 'Top up',
    fixIn: lotusNotes,
  },
  'applicants.persons\\[\\d\\].isPrimary': {
    content: 'Primary personal applicant',
    fixIn: lotusNotes,
  },
  'applicants.companies\\[\\d\\].isPrimary': {
    content: 'Primary company applicant',
    fixIn: lotusNotes,
  },
  'applicants.companies\\[\\d\\].businessName': {
    content: 'Company applicant/s name',
    fixIn: lotusNotes,
  },
};

const ValidationSettlementErrorPopup = ({
 errorBody, loanRequests, onClose, applicationId, location,
}) => {
  const convertToDisplayPropertyName = (propertyPath) => {
    const pattern = keys(errorPropertyDisplayMapping)
      .find(propertyPattern => new RegExp(propertyPattern).test(propertyPath));
    return pattern ? errorPropertyDisplayMapping[pattern] : null;
  };

  const convertToCommonDisplayItem = propertyList => (propertyList ? {
    properties: uniqBy(propertyList.map(convertToDisplayPropertyName), property => property.content || ''),
  } : null);

  const convertToLoanDisplayItem = (loanKey, propertyList) => {
    const loanId = loanKey.split(':')[1];
    const loanRequest = loanRequests.find(loan => loan.id === loanId);
    return loanRequest ? {
      order: loanRequests.indexOf(loanRequest),
      title: `Loan ${loanRequests.indexOf(loanRequest) + 1} ${loanRequest.productName || ''}`,
      properties: uniqBy(propertyList.map(convertToDisplayPropertyName), property => property.content),
    } : null;
  };

  const processErrorBody = (error) => {
    const { notesLink, invalidProperties } = error;
    const application = convertToCommonDisplayItem(get(invalidProperties, 'application'));
    const applicationLaunchpad = {
      properties: filter(get(application, 'properties'), item => item.fixIn === launchpad),
    };
    const applicationLotusNotes = {
      properties: filter(get(application, 'properties'), item => item.fixIn === lotusNotes),
    };
    const person = convertToCommonDisplayItem(get(invalidProperties, 'person'));
    const company = convertToCommonDisplayItem(get(invalidProperties, 'company'));
    const loans = keys(invalidProperties)
      .filter(key => key.includes('loan:'))
      .map(key => convertToLoanDisplayItem(key, invalidProperties[key]))
      .sort((item, otherItem) => item.order - otherItem.order);
    const needFixInLotusNotes = some(
      map([applicationLotusNotes, person, company, loans], item => get(item, 'properties')),
      item => !isEmpty(item),
    );
    return ({
      notesLink,
      needFixInLotusNotes,
      errorProperties: {
        applicationLaunchpad,
        applicationLotusNotes,
        person,
        company,
        loans,
      },
    });
  };

  const renderErrorSection = (errorSection, context) => errorSection
      && errorSection.properties
      && errorSection.properties.length > 0
      && (
        <div key={shortId.generate()} className={styles.errorSection}>
          {errorSection.title && <div className={styles.errorTitle}>{errorSection.title}</div>}
          <ul className={styles.errorList}>
            {errorSection.properties.map(item => (
              <li key={shortId.generate()}>
                <div
                  className={styles.errorItem}
                >{isFunction(item.content) ? item.content(context) : item.content}
                </div>
                {item.note ? (<div className="subtext">{item.note}</div>) : null}
              </li>
            ))}
          </ul>
        </div>
      );

  const businessError = processErrorBody(errorBody);

  return (
    <ModalWithButtons
      isOpen
      header={<span className={styles.errorHead}>Application has not been settled - Missing information</span>}
      className={styles.errorPopUpWrapper}
      onRequestClose={onClose}
      submitText={<div className={styles.notesButton}>Open Lotus Notes <Icon name="share" color="#ffffff" size="1.8rem" /></div>}
      submitButtonProps={{
        style: {
          ...errorPopUpButtonStyle,
          display: businessError.needFixInLotusNotes ? undefined : 'none',
        },
        onClick: () => window.open(businessError.notesLink, '_blank'),
      }}
    >
      <div>
        <div className={styles.note}>{
        errorPopUpNotification
        }
        </div>
        {renderErrorSection({
          title: 'Application (Launchpad)',
          properties: compact(get(businessError, 'errorProperties.applicationLaunchpad.properties', null)),
        }, {
          applicationId,
          location,
        })}
        {renderErrorSection({
          title: "Application '(Lotus Notes)'",
          properties: compact(get(businessError, 'errorProperties.applicationLotusNotes.properties', null)),
        })}
        {renderErrorSection({
          title: 'Client',
          properties: compact(concat(
            get(businessError, 'errorProperties.person.properties', null),
            get(businessError, 'errorProperties.company.properties', null),
          )),
        })}
        {businessError.errorProperties.loans.map(renderErrorSection)}
      </div>
    </ModalWithButtons>
  );
};

ValidationSettlementErrorPopup.defaultProps = {
  onClose: () => null,
};

ValidationSettlementErrorPopup.propTypes = {
  onClose: PropTypes.func,
  errorBody: PropTypes.shape({
    notesLink: PropTypes.string.isRequired,
    invalidProperties: PropTypes.shape({
      application: PropTypes.arrayOf(PropTypes.string.isRequired),
      person: PropTypes.arrayOf(PropTypes.string.isRequired),
      company: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
  }).isRequired,
  loanRequests: PropTypes.array.isRequired,
  applicationId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ValidationSettlementErrorPopup);
