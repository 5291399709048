import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toDateWithSlash } from 'utils/datetime';
import { mapLogToProps } from './utils';

const isEditableFieldChange = ({ path }) => /(creditGuideSentDate|isPrimary|isVerified|spouse|beneficiaries|trustees|directors)/.test(path);
const isEditableFieldChangeForGuarantors = ({ path }) => /(creditGuideSentDate|isVerified|spouse|beneficiaries|trustees|directors)/.test(path);
const isValidPath = ({ path }) => /(applicants|guarantors)\.(persons|companies)\[([a-zA-Z0-9,-]+)]/.test(path);
const invalidPropertyChange = ({ path }) => !/(isMainContact|isSurveyContact)/.test(path);

const applicantsChangeLogTransformer = origin => {
  const isValid = origin.eventDetail?.changes?.filter(invalidPropertyChange).some(isValidPath);

  if (!isValid) return null;

  const pathWithApplicantId = origin.eventDetail?.changes?.find(
    item => item.path.includes('guarantors') || item.path.includes('applicants'),
  )?.path;

  if (isEmpty(pathWithApplicantId)) {
    return null;
  }

  const isApplicant = pathWithApplicantId.includes('applicants');
  const applicantId = /(applicants|guarantors)\.(persons|companies)\[([a-zA-Z0-9,-]+)]/.exec(pathWithApplicantId)[3];
  const extraInfo = JSON.parse(get(origin, 'eventDetail.extraInfo', '{}')) || {};
  const applicantName = extraInfo[applicantId];

  return mapLogToProps({
    origin,
    type: 'APPLICANT/S',
    summary: `${isApplicant ? 'Applicant' : 'Guarantor'} information #action - ${applicantName}`,
    fieldMap: {
      isPrimary: ['Primary Applicant', $ => ($ === 'true' ? 'Yes' : 'No')],
      creditGuideSentDate: [null, dateStr => dateStr && toDateWithSlash(dateStr)],
      isVerified: ['Identification', $ => ($ === 'true' ? 'Identity verified' : 'Not Verified')],
    },
    handleChanges: changes => {
      const editableFieldChanges = isApplicant
        ? changes.filter(isEditableFieldChange)
        : changes.filter(isEditableFieldChangeForGuarantors);

      const collectionReplacement = [{
        regex: /.spouse/,
        replacement: {
          path: '*.spouse',
          value: 'updated',
        },
      }, {
        regex: /.trustees/,
        replacement: {
          path: '*.trustee',
          value: 'updated',
        },
      }, {
        regex: /.beneficiaries/,
        replacement: {
          path: '*.beneficiary',
          value: 'updated',
        },
      }, {
        regex: /.directors/,
        replacement: {
          path: '*.director',
          value: 'updated',
        },
      }];
      const remainedReplacement = collectionReplacement
        .filter(item => changes.some(({ path }) => item.regex.test(path)));
      return isEmpty(remainedReplacement)
        ? editableFieldChanges
        : [...editableFieldChanges
          .filter(({ path }) => !remainedReplacement.map(it => it.regex)
            .some(regex => regex.test(path))),
          ...remainedReplacement.map(it => it.replacement),
        ];
    },
  });
};

export default applicantsChangeLogTransformer;
