import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { toDollarAmount } from 'shared/formatterUtils';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import { MENU_SIZE } from 'shared/components/formFields/Select/Select';
import { GET_EXISTING_LOANS } from '../../../redux/existingLoanGraphQL';

const getLoanLabel = loan =>
  `${toDollarAmount(loan.settledAmount)}, ${loan.lenderName}, ${loan.productName}, ${loan.applicantsName}, ${loan.accountNumber}`;

const getExistingLoanOptions = (loans, allOtherExistingLoanIds) =>
  loans?.filter(loan => !includes(allOtherExistingLoanIds, loan.id))
  .map(loan => ({
    label: getLoanLabel(loan),
    value: loan,
  }));

const getApplicants = (allApplicants, ownerships) => ({
  persons: allApplicants.persons
    .filter(p => ownerships.find(ownership => ownership.applicantId === p.id))
    .map(p => ({ id: p.id })),
  companies: allApplicants.companies
    .filter(c => ownerships.find(ownership => ownership.applicantId === c.id))
    .map(c => ({ id: c.id })),
});

const ExistingLoanSelector = ({
                                businessId, allApplicants, ownerships, lenderId, allOtherExistingLoanIds, ...props
                              }) => {
  const { loading, data } = useQuery(GET_EXISTING_LOANS, {
    variables: {
      businessId,
      lenderId,
      applicants: getApplicants(allApplicants, ownerships),
    },
  });
  const existingLoanOption = useMemo(() => {
    const loans = get(data, 'loansByApplicants.loans');
    return getExistingLoanOptions(loans, allOtherExistingLoanIds);
  }, [data, allOtherExistingLoanIds]);

  const selectedValue = get(props, 'input.value');
  const selectedOptionValue = useMemo(() => {
    if (selectedValue === undefined || existingLoanOption === undefined) {
      return undefined;
    }
    const selectedOption = existingLoanOption.find(option => option.value.id === selectedValue.id);
    return get(selectedOption, 'value');
  }, [existingLoanOption, selectedValue]);

  return (
    <ReduxFormSelect
      options={existingLoanOption}
      loading={loading}
      menuSize={MENU_SIZE.LARGE}
      isClearable
      value={selectedOptionValue}
      readOnlyFormatter={() => selectedValue && getLoanLabel(selectedValue)}
      {...props}
    />
  );
};

ExistingLoanSelector.propTypes = {
  businessId: PropTypes.string.isRequired,
  className: PropTypes.string,
  allApplicants: PropTypes.shape({
    persons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  ownerships: PropTypes.arrayOf(PropTypes.shape({
    applicantId: PropTypes.string.isRequired,
  })).isRequired,
  lenderId: PropTypes.string,
  allOtherExistingLoanIds: PropTypes.arrayOf(PropTypes.string),
};

ExistingLoanSelector.defaultProps = {
  className: '',
  lenderId: undefined,
  allOtherExistingLoanIds: [],
};

export default ExistingLoanSelector;
