import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MultipleSelectFilter from '../MultipleSelectFilter/MultipleSelectFilter';

const YesNoFilter = ({
 filter, disabled, onFilter, options,
}) => {
  const getFilterName = (selectedStatusList) => {
    if (isEmpty(selectedStatusList)) {
      return '';
    }
    if (selectedStatusList.length === 1) {
      return options[selectedStatusList[0]];
    }
    return Object.values(options).join(', ');
  };

  const placeholder = `Filter (${Object.values(options).join('/')})`;

  return (
    <MultipleSelectFilter
      filter={filter}
      onFilter={onFilter}
      disabled={disabled}
      options={options}
      getFilterName={getFilterName}
      placeholder={placeholder}
    />
  );
};

YesNoFilter.defaultProps = {
  disabled: false,
};

YesNoFilter.propTypes = {
  disabled: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object.isRequired,
};

export default YesNoFilter;
