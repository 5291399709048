import React from 'react';
import PropTypes from 'prop-types';
import {
 Field, FieldArray, fieldArrayFieldsPropTypes,
} from 'redux-form';
import { connect } from 'react-redux';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { toDollarAmountWithDecimal } from 'shared/formatterUtils';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import ReduxFormFieldArrayTupleInput, { validateValues as validateOtherFunds } from '../components/FieldArrayTupleInput';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';
import SubTotalSection from '../components/SubTotalSection';
import { getLiabilitiesBasicValues } from '../../../redux/selectors';
import { liabilityTypes, typeOptions } from '../../Liabilities/constants';
import { calculateTotalRefinances } from '../../../redux/selectors/fundingWorksheet/utils';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';

const EditRefinanceSection = ({ liability, path }) => {
  const fields = [{
    title: 'Lender',
    content: liability.lender,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }, {
    title: 'Liability type',
    content: typeOptions.find(option => option.value === liabilityTypes[liability.type])?.label,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }, {
    title: 'Clearing amount',
    content: liability.clearingAmount ? toDollarAmountWithDecimal(liability.clearingAmount) : null,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }];

  const showDischargeFee = [liabilityTypes.LineOfCredit, liabilityTypes.MortgageLoan].includes(liability.type);

  return (
    <SubSection
      className={styles.refinanceSubSection}
    >
      <FieldsContainer
        fields={fields}
        fieldsWrapperClassName={styles.liabilityFieldWrapper}
        className={styles.refinanceFieldsContainer}
      />
      {showDischargeFee && (
        <Field
          name={`${path}.dischargeFee`}
          component={ReduxFormNumberInput}
          label="Discharge fee"
          placeholder="$"
          className={styles.sideBySideTextInput}
          decimalScale={2}
          prefix="$"
          fullWidth
          allowNegative={false}
        />
      )}
      <div className={styles.othersWrapper}>
        <FieldArray
          name={`${path}.others`}
          component={ReduxFormFieldArrayTupleInput}
          descriptionLabel="Other cost"
          descriptionPlaceholder="description"
          validate={validateOtherFunds}
          allowNegative={false}
        />
      </div>
    </SubSection>
  );
};

EditRefinanceSection.propTypes = {
  liability: PropTypes.shape({
    lender: PropTypes.string,
    type: PropTypes.string,
    clearingAmount: PropTypes.number,
  }),
  path: PropTypes.string.isRequired,
};

EditRefinanceSection.defaultProps = {
  liability: {},
};

const EditRefinancesComponent = ({
 fields, liabilities, refinances, userGuidanceCode,
}) => {
  if (fields.length === 0) {
    return null;
  }

  return (
    <InformationSection
      wrapperStyle={styles.infoSection}
      contentWrapperStyle={styles.infoSectionContent}
      titleWrapperStyle={styles.titleWrapperStyle}
      title="Refinance and debt consolidation"
    >
      {
        fields.map((field, index) => (
          <EditRefinanceSection
            liability={liabilities[fields.get(index).liabilityId]}
            path={field}
            key={field}
          />
      ))
}
      <SubTotalSection
        total={calculateTotalRefinances(refinances, liabilities)}
        title={`Refinance/debt consolidation (${userGuidanceCode})`}
      />
    </InformationSection>
  );
};

EditRefinancesComponent.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  liabilities: PropTypes.object,
  refinances: PropTypes.array.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

EditRefinancesComponent.defaultProps = {
  liabilities: {},
};

const mapStateToProps = (state) => ({
  liabilities: getLiabilitiesBasicValues(state),
  refinances: fundingWorksheetFormValueSelector(state, 'refinances'),
});

const renderEditRefinances = connect(mapStateToProps)(EditRefinancesComponent);

const EditRefinances = ({ userGuidanceCode }) => (
  <FieldArray
    name="refinances"
    component={renderEditRefinances}
    userGuidanceCode={userGuidanceCode}
  />
);

EditRefinances.defaultProps = {
  userGuidanceCode: '',
};

EditRefinances.propTypes = {
  userGuidanceCode: PropTypes.string,
};
export default EditRefinances;
