import axios from 'axios';
import { BACKEND_BASE_URL } from '../../config/environment';

axios.defaults.withCredentials = true;

const requestParameters = () => ({
  baseURL: BACKEND_BASE_URL,
});

const commonRequest = async (method, route, params, body, additionalParameters) => axios.request({
  url: route,
  method,
  params,
  data: body,
  ...requestParameters(),
  ...additionalParameters,
});

const Request = {
  get: async (route, params, additionalParameters = {}) => commonRequest('GET', route, params, undefined, additionalParameters),
  post: async (route, body, additionalParameters = {}) => commonRequest('POST', route, null, body, additionalParameters),
};

export default Request;
