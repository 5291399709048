import includes from 'lodash/includes';
import {
  NEW_RELIC_LICENCE_KEY,
  NEW_RELIC_APPLICATION_ID,
  NEW_RELIC_ACCOUNT_ID,
  NEW_RELIC_TRUST_KEY,
  NEW_RELIC_AGENT_ID,
  NEW_RELIC_DISTRIBUTED_TRACING_ENABLED,
  BACKEND_BASE_URL,

} from './environment';

const ErrorIgnores = [
  'ResizeObserver loop limit exceeded',
];

export default () => {
  if (NEW_RELIC_LICENCE_KEY && NEW_RELIC_APPLICATION_ID) {
    window.NREUM = window.NREUM || {};
    if (NEW_RELIC_ACCOUNT_ID && NEW_RELIC_AGENT_ID && NEW_RELIC_TRUST_KEY) {
      window.NREUM.loader_config = {
        accountID: NEW_RELIC_ACCOUNT_ID,
        trustKey: NEW_RELIC_TRUST_KEY,
        agentID: NEW_RELIC_AGENT_ID,
        licenseKey: NEW_RELIC_LICENCE_KEY,
        applicationID: NEW_RELIC_APPLICATION_ID,
      };
    }
    window.NREUM.init = window.NREUM.init || {};
    if (NEW_RELIC_DISTRIBUTED_TRACING_ENABLED && NEW_RELIC_DISTRIBUTED_TRACING_ENABLED === 'true') {
      window.NREUM.init.distributed_tracing = window.NREUM.init.distributed_tracing || {};
      window.NREUM.init.distributed_tracing.enabled = true;
      window.NREUM.init.distributed_tracing.allowed_origins = [
        BACKEND_BASE_URL,
      ];
    }
    window.NREUM.info = {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: NEW_RELIC_LICENCE_KEY,
      applicationID: NEW_RELIC_APPLICATION_ID,
      sa: 1,
    };
  }
  global.newrelic.setErrorHandler((err) => includes(ErrorIgnores, err.message));
};
