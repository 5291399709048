import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import flatten from 'lodash/flatten';
import { createSelector } from '@reduxjs/toolkit';

export const getApplicants = (state) => get(state, 'application.applicationDetail.applicants.persons', []);
export const getAllApplicants = (state) => get(state, 'application.applicationDetail.applicants', {});

export const getApplicantsInitialValues = createSelector(
  getAllApplicants,
  applicants => concat(
    applicants?.persons?.map(person => ({
      isPerson: true,
      creditGuideSentDate: null,
      ...person,
    })),
    applicants?.companies?.map(company => ({
      isPerson: false,
      creditGuideSentDate: null,
      ...company,
      directors: !isEmpty(company.directors) ? company.directors : [{}],
      trustees: !isEmpty(company.trustees) ? company.trustees : [{}],
      beneficiaries: !isEmpty(company.beneficiaries) ? company.beneficiaries : [{}],
    })),
  ),
);

export const getGuarantors = (state) => get(state, 'application.applicationDetail.guarantors');

export const getGuarantorsInitialValues = createSelector(
  getGuarantors,
  guarantors => (concat(guarantors?.persons?.map(person => ({
        isPerson: true,
        creditGuideSentDate: null,
        ...person,
      })) || [],
      guarantors?.companies?.map(company => ({
        isPerson: false,
        ...company,
        directors: !isEmpty(company.directors) ? company.directors : [{}],
        trustees: !isEmpty(company.trustees) ? company.trustees : [{}],
        beneficiaries: !isEmpty(company.beneficiaries) ? company.beneficiaries : [{}],
      })) || [])),
);

export const getApplicantsAndGuarantors = createSelector(
  getAllApplicants,
  getGuarantors,
  (applicants, guarantors) => flatten(concat(
    applicants?.persons?.map(person => ({ isPerson: true, ...person })) ?? [],
    applicants?.companies?.map(company => ({ isPerson: false, ...company })) ?? [],
    guarantors?.persons?.map(person => ({ isPerson: true, ...person })) ?? [],
  guarantors?.companies?.map(company => ({ isPerson: false, ...company })) ?? [],
  )),
);
