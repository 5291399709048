import get from 'lodash/get';
import { openInTab } from 'shared/utils';
import moment from 'moment';
import graphQL from './invoicesGraphQL';
import { closeErrorBanner, showErrorBanner } from '../../../redux/banner';
import * as api from '../../api/myBusinessApi';
import { trackCustomEvent } from '../../../tealium/Tracker';
import { downloadInvoiceDetailsXlsxV100, downloadInvoicePdfV100 } from '../../../tealium/TrackingDataBuilder';
import {
  getRequestIdFromAction, ifValidRequestId,
  startRequest,
} from '../../../redux/requestIds';

export const ACTIONS = {
  GET_INVOICES_START: 'MY_BUSINESS:INVOICES:GET_INVOICES_START',
  GET_INVOICES_SUCCESS: 'MY_BUSINESS:INVOICES:GET_INVOICES_SUCCESS',
  GET_INVOICES_ERRORS: 'MY_BUSINESS:INVOICES:GET_INVOICES_ERRORS',
  DOWNLOAD_INVOICE_START: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_START',
  DOWNLOAD_INVOICE_SUCCESS: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_SUCCESS',
  DOWNLOAD_INVOICE_ERROR: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_ERROR',
  DOWNLOAD_INVOICE_DETAILS_START: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_DETAILS_START',
  DOWNLOAD_INVOICE_DETAILS_SUCCESS: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_DETAILS_SUCCESS',
  DOWNLOAD_INVOICE_DETAILS_ERROR: 'MY_BUSINESS:INVOICES:DOWNLOAD_INVOICE_DETAILS_ERROR',
};

const getInvoicesStart = () => ({ type: ACTIONS.GET_INVOICES_START });
const getInvoicesSuccess = invoices => ({ type: ACTIONS.GET_INVOICES_SUCCESS, invoices });
const getInvoicesErrors = errors => ({ type: ACTIONS.GET_INVOICES_ERRORS, errors });

const downloadInvoicesStart = ({ processingMonth }) => ({ type: ACTIONS.DOWNLOAD_INVOICE_START, processingMonth });
const downloadInvoiceSuccess = ({ processingMonth }) => ({ type: ACTIONS.DOWNLOAD_INVOICE_SUCCESS, processingMonth });
const downloadInvoicesError = (error, { processingMonth }) =>
  ({ type: ACTIONS.DOWNLOAD_INVOICE_ERROR, error, processingMonth });

const downloadInvoiceDetailsStart = processingMonth =>
  ({ type: ACTIONS.DOWNLOAD_INVOICE_DETAILS_START, processingMonth });
const downloadInvoiceDetailsSuccess = processingMonth =>
  ({ type: ACTIONS.DOWNLOAD_INVOICE_DETAILS_SUCCESS, processingMonth });
const downloadInvoiceDetailsError = (error, processingMonth) =>
  ({ type: ACTIONS.DOWNLOAD_INVOICE_DETAILS_ERROR, error, processingMonth });
const toYearMonth = processingMonth => moment(processingMonth).format('MMMM-YYYY');

export const getInvoices = () => async (dispatch, getState) => {
  const businessId = get(getState(), 'business.selectedBusiness.id');
  if (!businessId) {
    return;
  }

  dispatch(getInvoicesStart());
  const startRequestAction = startRequest(ACTIONS.GET_INVOICES_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const results = await graphQL.getInvoices(businessId);
    ifValidRequestId(getState().requestIds, ACTIONS.GET_INVOICES_START, requestId, () => {
      dispatch(getInvoicesSuccess(results.data.invoices));
    });
  } catch (error) {
    ifValidRequestId(getState().requestIds, ACTIONS.GET_INVOICES_START, requestId, () => {
      dispatch(getInvoicesErrors(error));
    });
  }
};

export const downloadInvoicePdf = invoice => (dispatch, getState) => {
  trackCustomEvent(downloadInvoicePdfV100(invoice.processingMonth, getState()));
  dispatch(downloadInvoicesStart(invoice));

  return api.downloadInvoicePdf(invoice)
    .then((pdfBlob) => {
      dispatch(downloadInvoiceSuccess(invoice));
      openInTab(pdfBlob, `Invoice-${toYearMonth(invoice.processingMonth)}.pdf`);
    })
    .catch((err) => {
      dispatch(showErrorBanner('There was a problem while downloading your pdf. Please try again later.'));
      dispatch(downloadInvoicesError(err, invoice));
    });
};

export const downloadInvoiceDetails = processingMonth => (dispatch, getState) => {
  dispatch(closeErrorBanner());
  trackCustomEvent(downloadInvoiceDetailsXlsxV100(processingMonth, getState()));
  dispatch(downloadInvoiceDetailsStart(processingMonth));

  const businessId = get(getState(), 'business.selectedBusiness.id');
  return api.downloadInvoiceDetailsXlsx(processingMonth, businessId)
    .then((xlsxBlob) => {
      dispatch(downloadInvoiceDetailsSuccess(processingMonth));
      openInTab(xlsxBlob, `Invoice-${toYearMonth(processingMonth)}.xlsx`);
    })
    .catch((err) => {
      dispatch(showErrorBanner('There was a problem while downloading your invoice details. Please try again later.'));
      dispatch(downloadInvoiceDetailsError(err, processingMonth));
    });
};

const setLoadingStatus = (state, action, type) => [...(state.isLoadingFile || []), `${action.processingMonth}-${type}`];
const unsetLoadingStatus = (state, action, type) => (state.isLoadingFile || []).filter(n => n !== `${action.processingMonth}-${type}`);
const xlsx = 'xlsx';
const pdf = 'pdf';

export default (state = { isLoading: false, isLoadingFile: [] }, action) => {
  switch (action.type) {
    case ACTIONS.GET_INVOICES_START:
      return {
        ...state, isLoading: true, errors: null,
      };

    case ACTIONS.GET_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: action.invoices,
        isLoading: false,
      };
    }

    case ACTIONS.GET_INVOICES_ERRORS:
      return { ...state, errors: action.errors, isLoading: false };

    case ACTIONS.DOWNLOAD_INVOICE_START:
      return {
        ...state, isLoadingFile: setLoadingStatus(state, action, pdf), errors: null,
      };

    case ACTIONS.DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state, isLoadingFile: unsetLoadingStatus(state, action, pdf),
      };

    case ACTIONS.DOWNLOAD_INVOICE_ERROR:
      return { ...state, error: action.error, isLoadingFile: unsetLoadingStatus(state, action, pdf) };

    case ACTIONS.DOWNLOAD_INVOICE_DETAILS_START:
      return {
        ...state, isLoadingFile: setLoadingStatus(state, action, xlsx), errors: null,
      };

    case ACTIONS.DOWNLOAD_INVOICE_DETAILS_SUCCESS:
      return {
        ...state, isLoadingFile: unsetLoadingStatus(state, action, xlsx),
      };

    case ACTIONS.DOWNLOAD_INVOICE_DETAILS_ERROR:
      return { ...state, error: action.error, isLoadingFile: unsetLoadingStatus(state, action, xlsx) };

    default:
      return state;
  }
};
