import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, formPropTypes, reduxForm } from 'redux-form';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Primary from './Primary';
import RateType from './RateType';
import RepaymentType from './RepaymentType';
import ProductFeature from './ProductFeature';
import { updateNeedsAndObjectives } from '../../redux';
import { getNeedAndObjectiveInitialValues } from '../../redux/selectors';
import { ApplicantFutureCircumstances, GuarantorFutureCircumstances } from './FutureCircumstance';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import RefinanceAndDebtConsolidation from './RefinanceAndDebtConsolidation';
import { FETCHING_STATUS } from '../../../../../constants';

const onSubmit = (formData, dispatch) => dispatch(updateNeedsAndObjectives(formData));

const NeedsAndObjectives = ({
 handleSubmit, pristine, submitting, hasEditPermission, valid, hasGuarantors, isFetching,
}) => (
  <FormMetaProvider readOnly={!hasEditPermission}>
    <Primary hasGuarantors={hasGuarantors} />
    <RateType />
    <RepaymentType />
    <ProductFeature />
    <RefinanceAndDebtConsolidation />
    <FieldArray
      name="futureCircumstances"
      component={ApplicantFutureCircumstances}
    />
    <FieldArray
      name="guarantorFutureCircumstances"
      component={GuarantorFutureCircumstances}
    />
    {hasEditPermission && (
      <StickyFooter
        onClick={handleSubmit}
        disabled={pristine || submitting || !valid || isFetching}
      />
      )}
    <LoadingWithOverlay isLoading={submitting || isFetching} />
    <UnsavedChangePrompt shouldConfirm={!pristine} />
  </FormMetaProvider>
  );

NeedsAndObjectives.propTypes = {
  ...formPropTypes,
};

const mapStateToProps = (state) => ({
  initialValues: getNeedAndObjectiveInitialValues(state),
  hasEditPermission: hasEditPermissionSelector(state),
  hasGuarantors: !(
    isEmpty(get(state, 'application.applicationDetail.guarantors.persons', []))
    && isEmpty(get(state, 'application.applicationDetail.guarantors.companies', []))
  ),
  isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START,
});

export default connect(mapStateToProps)(reduxForm({
  form: 'needsAndObjectivesForm',
  enableReinitialize: true,
  onSubmit,
})(NeedsAndObjectives));
