import React from 'react';
import NumberInput from 'shared/components/formFields/NumberInput/NumberInput';
import styles from './styles.module.scss';

const MAX_NUM = 1000000000;
const isAllowed = value => (value === undefined || (value > 0 && value < MAX_NUM));

const style = {
  display: 'inline-block',
  margin: '0rem 1.2rem 1rem 0rem',
  padding: 0,
  verticalAlign: 'top',
  width: '19.5rem',
};

const SecurityValueComponent = (props) => (
  <NumberInput
    label="Total security value ($)"
    className={styles.label}
    isAllowed={isAllowed}
    {...props}
    style={style}
    textClassName={styles.inputText}
  />
  );

export default SecurityValueComponent;
