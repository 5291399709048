import React, {
 useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardHeaderInfo from './CardHeaderInfo';
import ApplicationInfo from './ApplicationInfo';
import Reminder from './Reminder';
import styles from '../Applications.module.scss';
import { ApplicationShape } from './ApplicationShape';

export const ApplicationCardContent = ({
                  application,
                  innerRef,
                  dragHandleProps,
                  draggableProps,
                  style,
                  isDragging,
                  isDropAnimating,
                  isDragDisabled,
                  onClick,
                  onKeyUp,
                }) => {
  const originalCard = useRef();
  const shadowCard = useRef();
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(originalCard.current.offsetHeight);
  }, []);

  useLayoutEffect(() => {
    if (isDragging) {
      shadowCard.current.style.height = `${contentHeight}px`;
    }
  }, [isDragging, contentHeight]);

  return (
    <div className={styles.cardWrapper} ref={originalCard}>
      <div
        id={application.id}
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyUp={onKeyUp}
        ref={innerRef}
        {...dragHandleProps}
        {...draggableProps}
        className={styles.applicationCard}
        style={style}
      >
        <div className={classNames(styles.card, {
          [styles.dragged]: isDragging,
          [styles.dropped]: isDropAnimating,
          [styles.draggable]: !isDragDisabled,
        })}
        >
          <div className={styles.lenderInfo}>
            <CardHeaderInfo
              lender={application.lender}
              applicationUpdateCount={application.applicationUpdateCount}
            />
          </div>
          <ApplicationInfo application={application} />
          <Reminder
            applicationStatus={application.applicationStatus}
            expectedSettlementDate={application.expectedSettlementDate}
            settledDate={application.settledDate}
            preApprovalExpiryDate={application.preApprovalExpiryDate}
            financeDueDate={application.financeDueDate}
          />
        </div>
      </div>
      <div
        className={`${styles.placeholder}`}
        role="button"
        style={{ width: isDragging ? 'unset' : 0, overflow: 'hidden', border: 'none' }}
        ref={shadowCard}
      />
    </div>
  );
};

ApplicationCardContent.defaultProps = {
  dragHandleProps: null,
};

ApplicationCardContent.propTypes = {
  application: ApplicationShape.isRequired,
  innerRef: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object,
  draggableProps: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isDropAnimating: PropTypes.bool.isRequired,
  isDragDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
};
