import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'shared/components/ErrorMessage';

const RetryComponent = (props) => {
  const { children } = props;
  return (
    <ErrorMessage
      title={children ? '' : 'Information unavailable'}
      description={children ? '' : 'The information is unavailable at the moment, it could be because of a temporary issue. Please try again in a few minutes.'}
      {...props}
    />
);
};

RetryComponent.propTypes = {
  children: PropTypes.any,
};

RetryComponent.defaultProps = {
  children: null,
};

export default RetryComponent;
