import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Fields, getFormValues } from 'redux-form';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormDateSelector from 'shared/components/formFields/ReduxForm/DateSelector';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import get from 'lodash/get';
import find from 'lodash/find';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import FormMetaContext from 'shared/components/FormMeta/FormMetaContext';
import { useSelector } from 'react-redux';
import Icon from 'shared/components/Icon/Icon';
import classNames from 'classnames/bind';
import map from 'lodash/map';
import styles from './styles.module.scss';
import { containOther } from '../../utils';
import { APPLICANT_REPAYMENT_PLAN_OPTION, CHANGE_TYPE } from '../../constants';
import createOtherDetailValidator from '../../validator/validateOtherDetails';
import { toDateWithSlash } from '../../../../../../../utils/datetime';

const cx = classNames.bind(styles);

const valueRender = value => (value?.length > 1 ? 'Multiple' : find(APPLICANT_REPAYMENT_PLAN_OPTION, item => item.id === value[0])?.name);

const RepaymentPlanSection = props => {
  const { namePrefix, ...others } = props;
  const fields = get(others, namePrefix, {});
  const { repaymentPlans, repaymentPlanOtherDetail } = fields;
  return (
    <>
      <ReduxFormCheckboxDropdown
        label="Repayment plan"
        className={styles.repaymentPlanField}
        options={APPLICANT_REPAYMENT_PLAN_OPTION}
        inputProps={{ className: styles.repaymentPlanInput }}
        {...repaymentPlans}
        onChange={(v) => {
            repaymentPlans.input.onChange(v);
            if (!containOther(v)) {
              repaymentPlanOtherDetail.input.onChange('');
            }
          }}
        valueRender={valueRender}
        readOnlyFormatter={value => {
          const values = map(value, (id => find(APPLICANT_REPAYMENT_PLAN_OPTION, { id })));
          return (
            <div className={styles.readOnlyOptions}>
              {values.map(({ name, id }) => (
                <div key={id}>{name}</div>
              ))}
            </div>
          );
        }}
        buttonText="Select"
        flipToggle
      />
      {containOther(repaymentPlans.input.value) && (
        <ReduxFormTextArea
          isRequired
          label="Other detail"
          rows="1"
          {...repaymentPlanOtherDetail}
          className={styles.repaymentPlanOtherDetailField}
        />
      )}
    </>
  );
};

RepaymentPlanSection.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

const TypeSection = props => {
  const { namePrefix, ...others } = props;
  const fields = get(others, namePrefix, {});
  const { type, typeOtherDetail } = fields;
  return (
    <>
      <ReduxFormSelect
        label="Nature of the planned or anticipated change"
        className={styles.typeField}
        options={CHANGE_TYPE}
        menuPlacement="auto"
        {...type}
      />
      {type.input.value === 'Other' && (
        <ReduxFormTextArea
          isRequired
          label="Other detail"
          rows="1"
          {...typeOtherDetail}
          className={styles.typeOtherDetailField}
        />
      )}
    </>
  );
};

TypeSection.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

const Change = ({
 namePrefix, showAddButton, showDeleteButton, onDelete, onAdd,
}) => {
  const { readOnly } = useContext(FormMetaContext);
  const periodOfImpact = useSelector(state => get(getFormValues('needsAndObjectivesForm')(state), `${namePrefix}.periodOfImpact`, null));

  const validatorForRepaymentPlanOtherDetail = useMemo(() => createOtherDetailValidator({
    selectField: `${namePrefix}.repaymentPlans`,
    otherDetailField: `${namePrefix}.repaymentPlanOtherDetail`,
  }), [namePrefix]);

  const validatorForTypeOtherDetail = useMemo(() => ({
    [`${namePrefix}.typeOtherDetail`]: (otherDetail, allValues) => {
      const type = get(allValues, `${namePrefix}.type`);
      if (type?.toLowerCase() === 'other' && !otherDetail?.trim()) {
        return 'Other detail is required';
      }
      return null;
    },
  }), [namePrefix]);

  return (
    <div className={cx(styles.container, { [styles.readOnlyContainer]: readOnly })}>
      <Fields
        names={[`${namePrefix}.type`, `${namePrefix}.typeOtherDetail`]}
        namePrefix={namePrefix}
        component={TypeSection}
        validate={validatorForTypeOtherDetail}
      />
      <Field
        name={`${namePrefix}.periodOfImpact`}
        component={ReduxFormNumberInput}
        label="Period of impact"
        className={styles.periodOfImpactField}
        type="text"
        placeholder="Months"
        suffix={periodOfImpact > 1 ? ' months' : ' month'}
        allowNegative={false}
        decimalScale={0}
        readOnlyFormatter={value => value}
      />
      <Field
        name={`${namePrefix}.startDate`}
        component={ReduxFormDateSelector}
        label="Estimated start date"
        type="date"
        placeholder="Select a date"
        displayFormat="dd/MM/yyyy"
        className={styles.startDateField}
        useMaskBehavior
        hiddenComponentLabel
        readOnlyFormatter={value => (value ? toDateWithSlash(value) : '')}
      />
      <Field
        name={`${namePrefix}.amount`}
        component={ReduxFormNumberInput}
        type="text"
        label="Financial impact - monthly"
        className={styles.amountField}
        decimalScale={2}
        placeholder="$"
        prefix="$"
        allowNegative={false}
        readOnlyFormatter={value => value}
      />
      <Fields
        names={[`${namePrefix}.repaymentPlans`, `${namePrefix}.repaymentPlanOtherDetail`]}
        namePrefix={namePrefix}
        component={RepaymentPlanSection}
        validate={validatorForRepaymentPlanOtherDetail}
      />
      {showDeleteButton && !readOnly && (
        <TertiaryButton
          className={styles.deleteBtn}
          onClick={onDelete}
        >
          <Icon name="close" />
        </TertiaryButton>
        )}
      {showAddButton && !readOnly && (
        <SecondaryButton
          className={styles.addBtn}
          onClick={onAdd}
        >
          <span>Add</span>
          <Icon name="add" size="18" />
        </SecondaryButton>
        )}
    </div>
  );
};

Change.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default Change;
