import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './NewInputLabelWrapper.module.scss';

export const NewInputLabelWrapper = (props) => {
  const {
    label,
    title,
    errorMessage,
    className,
    children,
    additionalButton,
    style,
    fullWidth,
    inline,
    isRequired,
    labelProps,
  } = props;
  const { className: labelClassName } = labelProps;

  return (
    <div
      className={classNames(className, 'input-label-wrapper', styles.content, {
        [styles.fullWidth]: fullWidth,
        [styles.inline]: inline,
      })}
      style={style}
    >
      {label && (
        <label className={classNames(styles.label, { hint: !!title }, labelClassName)} htmlFor={label} hint={title}>
          {label}
          {isRequired && <span className={styles.redStar} />}
          {additionalButton}
        </label>
      )}
      {children}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
};

export const wrapInLabel = (Component, classes = {}) => {
  const HOC = ({
            label,
            hiddenComponentLabel,
            errorMessage,
            className,
            fullWidth,
            disabled,
            readOnly,
            style,
            inline,
            isRequired,
            inputProps = {},
            labelProps = {},
            ...others
          }) => {
    const error = readOnly ? null : errorMessage;
    const componentClasses = classNames(
      { [classes.disabled]: disabled },
      { [classes.error]: !!error },
    );
    const { className: inputClassName, ...restInputProps } = inputProps;
    const { additionalButton } = others;
    return (
      <NewInputLabelWrapper
        label={label}
        errorMessage={error}
        className={className}
        fullWidth={fullWidth}
        inline={inline}
        style={style}
        labelProps={labelProps}
        isRequired={isRequired}
        additionalButton={additionalButton}
      >
        <Component
          className={classNames(componentClasses, inputClassName)}
          disabled={disabled}
          label={hiddenComponentLabel ? undefined : label}
          hasError={!!errorMessage}
          readOnly={readOnly}
          {...restInputProps}
          {...others}
        />
      </NewInputLabelWrapper>
    );
  };
  HOC.propTypes = {
    label: PropTypes.node,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
    style: PropTypes.object,
    fullWidth: PropTypes.bool,
    inline: PropTypes.bool,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    labelProps: PropTypes.object,
  };
  HOC.defaultProps = {
    label: undefined,
    errorMessage: undefined,
    className: undefined,
    style: {},
    fullWidth: false,
    inline: false,
    isRequired: false,
    disabled: false,
    readOnly: false,
    inputProps: {},
    labelProps: {},
  };
  HOC.displayName = `wrapInLabel(${Component.displayName || Component.name || 'component'})`;
  return HOC;
};

NewInputLabelWrapper.defaultProps = {
  title: '',
  label: undefined,
  errorMessage: undefined,
  className: undefined,
  additionalButton: undefined,
  style: {},
  fullWidth: false,
  inline: false,
  isRequired: false,
  labelProps: {},
};

NewInputLabelWrapper.propTypes = {
  title: PropTypes.string,
  label: PropTypes.node,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  additionalButton: PropTypes.object,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  inline: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelProps: PropTypes.object,
};
