export const FREQUENCY_OPTIONS = [
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Fortnightly',
    value: 'Fortnightly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  },
];

export const INCOME_TYPE_OPTIONS = [
  {
    label: 'Annuities',
    value: 'Annuities',
  },
  {
    label: 'Dividends',
    value: 'Dividends',
  },
  {
    label: 'Government benefits',
    value: 'GovernmentBenefits',
  },
  {
    label: 'Interest income',
    value: 'InterestIncome',
  },
  {
    label: 'Other income',
    value: 'OtherIncome',
  },
  {
    label: 'Private pension',
    value: 'PrivatePension',
  },
  {
    label: 'Superannuation',
    value: 'Superannuation',
  },
];

export const GB_TYPE_OPTIONS = [
  {
    label: 'Family allowance',
    value: 'FamilyAllowance',
  },
  {
    label: 'Unemployed allowance',
    value: 'UnemployedAllowance',
  },
  {
    label: 'Widows allowance',
    value: 'WidowsAllowance',
  },
];
