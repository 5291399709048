import React from 'react';

import LendersSelectComponent from './LendersSelectComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedLenderSelect = () => (
  <ProductSearchContext.Consumer>
    {context => {
      const { hasError, lenderNames } = context.lenders;
      const selectedLenderNames = context.applicationCriteria.selectedLenderNames || [];
      return (
        <LendersSelectComponent
          hasError={hasError}
          lenderNames={lenderNames}
          selectedLenderNames={selectedLenderNames}
          onChange={context.dispatchUpdateSelectedLenderNames}
          dispatchGetLenders={context.dispatchGetLenders}
        />
        );
    }}
  </ProductSearchContext.Consumer>
  );

export default ConnectedLenderSelect;
