import pick from 'lodash/pick';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import every from 'lodash/every';
import isNil from 'lodash/isNil';

export const buildRequest = ({
  variants,
  applicationId,
  loansCriteria,
  lenderCode,
  productId,
}) => ({
  productPackages: [{
    lenderCode,
    variants: variants.map(variant => pick(variant,
      [
        'nextgenMaliId',
        'nextgenFeatureCodes',
        'nextgenPaymentType',
        'nextgenAbsCode',
        'nextgenPropertyStatus',
      ])),
  }],
  applicationId,
  productId,
  loansCriteria: loansCriteria.map(c => pick(c, [
    'loanAmount',
    'loanValueRatio',
    'purpose',
    'productType',
    'repaymentType',
    'loanTerm',
    'fixedLoanTerm',
    'interestOnlyTerm',
    'selectedFeatureNames',
    'totalCostTerm',
    'repaymentFrequency',
    'purposeCategory',
    'purposeDetails',
    'refinanceReason',
  ])),
});

export const buildRequestsFromProducts = ({
  products,
  applicationId,
  loansCriteria,
  lenders,
}) => {
  const lenderMap = mapKeys(lenders, 'name');
  return products
    .map(({ variants, id }) => ({
      variants,
      lenderCode: get(lenderMap, [variants[0].lenderName, 'serviceabilityLenderCode'], null),
      productId: id,
    }))
    .filter(({ lenderCode, variants }) => lenderCode && every(variants, it => !isNil(it.nextgenMaliId)))
    .map(({ variants, lenderCode, productId }) => buildRequest({
      variants,
      lenderCode,
      loansCriteria,
      applicationId,
      productId,
    }));
};
