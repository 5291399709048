import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

const getThirdParties = (state) => {
  const thirdParties = get(state, 'application.applicationDetail.thirdParties', []);
  return thirdParties.filter(i => !i.isSearched);
};

const getSearchedThirdParties = (state) => {
  const thirdParties = get(state, 'application.applicationDetail.thirdParties', []);
  return thirdParties.filter(i => i.isSearched);
};
const getReferrer = (state) => get(state, 'application.applicationDetail.referrer');

const typeMapping = {
  'Real Estate Agent': 'AgentVendor',
  Guarantor: 'Other',
};

const getAllApplicantsAndGuarantors = (state) => {
  const applicationDetail = get(state, 'application.applicationDetail');
  return {
    personApplicants: applicationDetail?.applicants?.persons ?? [],
    companyApplicants: applicationDetail?.applicants?.companies ?? [],
    personGuarantors: applicationDetail?.guarantors?.persons ?? [],
    companyGuarantors: applicationDetail?.guarantors?.companies ?? [],
  };
};

export const getContactsInitialValue = createSelector(
  getAllApplicantsAndGuarantors,
  getThirdParties,
  getSearchedThirdParties,
  getReferrer,
  (applicants, thirdParties, searchedThirdParties, referrer) => {
    const survey = [...applicants.personApplicants, ...applicants.personGuarantors]
      .find(applicant => applicant.isSurveyContact)?.id;
    const mainContact = [...applicants.personApplicants, ...applicants.personGuarantors]
      .find(applicant => applicant.isMainContact)?.id;
    return {
      applicants,
      survey,
      mainContact,
      thirdParties: thirdParties.map(thirdParty => ({
        ...thirdParty,
        type: get(typeMapping, thirdParty.type, thirdParty.type),
      })),
      searchedThirdParties,
      referrer,
    };
  },
);
