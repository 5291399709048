import { createReducer } from '../../../../../../utils/reducerGenerator';
import { FETCHING_STATUS } from '../../../../../../constants';
import types from '../../constants';

const initialState = {
  baseLvr: null,
  fundsAvailable: null,
  proposedLoanAmountBeforeLmi: null,
  totalCosts: null,
  totalLvr: null,
  totalSecurity: null,
  fetchingStatus: FETCHING_STATUS.INIT,
  capitaliseLmi: false,
};

const fundingPositions = createReducer(initialState, {
  [types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_SUCCESS]: (state, action) => ({
    ...action.payload,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
  }),
  [types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_ERROR]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});

export default fundingPositions;
