import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ApplicationSummaryDetails from './ApplicationSummaryDetails/ApplicationSummaryDetails';
import usePageViewTracking from '../../../pages/usePageViewTracking';
import { switchApplication } from './redux';

const ApplicationDetails = ({ match }) => {
  usePageViewTracking('applicationDetails');
  const applicationId = get(match, 'params.applicationId', '');

  const dispatch = useDispatch();
  useEffect(() => () => { dispatch(switchApplication()); }, [applicationId, dispatch]);

  return (
    <ApplicationSummaryDetails applicationId={applicationId} />
  );
};

ApplicationDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ApplicationDetails;
