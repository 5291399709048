import React, { useEffect } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { calculateFundingPositions } from '../../../redux';

const ViewCalculation = ({
  fundingWorksheet, dispatchCalculateFundingPositions, applicationId,
}) => {
  useEffect(() => {
    if (fundingWorksheet) {
      dispatchCalculateFundingPositions(applicationId, fundingWorksheet);
    }
  }, [applicationId, fundingWorksheet, dispatchCalculateFundingPositions]);
  return (<></>);
};

const mapStateToProps = state => ({
  applicationId: get(state, 'application.applicationDetail.id'),
  fundingWorksheet: get(state, 'application.applicationDetail.fundingWorksheet'),
});

const mapDispatchToProps = dispatch => {
  const dispatchCalculateFundingPositions = (applicationId, fundingWorksheet) =>
    dispatch(calculateFundingPositions(applicationId, fundingWorksheet));
  const debouncedDispatchCalculateFundingPositions = debounce(dispatchCalculateFundingPositions, 500);
  return {
    dispatchCalculateFundingPositions: debouncedDispatchCalculateFundingPositions,
  };
};

ViewCalculation.propTypes = {
  fundingWorksheet: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  dispatchCalculateFundingPositions: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCalculation);
