import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import sizes from 'shared/theme/sizes.scss';
import Icon from 'shared/components/Icon/Icon';
import PropTypes from 'prop-types';
import styles from './InfoBar.module.scss';

const InfoBar = ({ text }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <div className={styles.infoBar}>
      <Icon
        name="info"
        color={themeContext.palette.textInformation}
        size={sizes.large}
      />
      <div className={styles.infoBarText}>{text}</div>
    </div>
  );
};

InfoBar.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoBar;
