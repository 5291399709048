import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import MultiSelect from '../../../formFields/MultiSelect';
import FONTS from '../../../../theme/depricatedStyles/Fonts';
import styles from './Feature.module.scss';

const FeatureSelect = (props) => {
  const {
    features,
    buttonLabel,
    buttonOnClick,
    selectedFeatureNames,
    fixedFeatureNames,
    onChange,
    hasError,
  } = props;
  const featuresNames = map(features, 'name');

  const subLabel = fixedFeatureNames.length ? (
    <p>
      <span style={{ fontFamily: FONTS.standardBold, paddingRight: '1rem' }}>Product features added</span>
      <span style={{ fontFamily: FONTS.standard }}>{fixedFeatureNames.join(', ')}</span>
    </p>
  ) : null;
  return (
    <div className={styles.featuresSelect}>
      <MultiSelect
        label="Product features"
        subLabel={subLabel}
        placeholder="Type to add features"
        name="features"
        classPrefix="features-select"
        valuesSet={featuresNames}
        selectedValues={selectedFeatureNames}
        fixedValues={fixedFeatureNames}
        buttonLabel={buttonLabel}
        buttonOnClick={buttonOnClick}
        hasError={hasError}
        onChange={onChange}
      />
    </div>
  );
};

FeatureSelect.defaultProps = {
  buttonOnClick: () => {},
  buttonLabel: undefined,
  features: [],
  selectedFeatureNames: [],
  fixedFeatureNames: [],
  hasError: false,
  onChange: () => {},
};

FeatureSelect.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  buttonOnClick: PropTypes.func,
  buttonLabel: PropTypes.string,
  onChange: PropTypes.func,
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  fixedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool,
};

export default FeatureSelect;
