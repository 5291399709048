import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Image from 'shared/components/Image';

const getLargeLenderIconPath = lenderId => `/lenders/${lenderId}/LARGE.png`;
const getSmallLenderIconPath = lenderId => `/lenders/${lenderId}/SMALL.png`;

const LenderIconImage = ({ lender, className, iconSize }) => (
  <Image
    className={className}
    alt={get(lender, 'name') || ''}
    src={iconSize === 'SMALL'
      ? getSmallLenderIconPath(get(lender, 'lenderId'))
      : getLargeLenderIconPath(get(lender, 'lenderId'))}
  />
);

LenderIconImage.defaultProps = {
  className: undefined,
  iconSize: 'LARGE',
};

LenderIconImage.propTypes = {
  lender: PropTypes.shape({
    lenderId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  iconSize: PropTypes.string,
};

export default LenderIconImage;
