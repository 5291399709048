/* eslint-disable */
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import resolver from './resolvers/AOLResolver';
import settlementResolver from './resolvers/SettlementResolver';
import serviceabilityResolver from './resolvers/ServiceabilityResolver';

const createNode = (name, nodes = [], info = {}) =>
  (name && isString(name)) ?
  ({
    name,
    nodes,
    info
  }) : null;

const collectNodesFromEntity = (entity, currentGlossary = {}, skips = []) => {
  if (isEmpty(entity) || isString(entity))
    return [];

  const nodes = [];
  const flatEntity = (entity, parent, glossary) =>
    Object.entries(entity)
    .map(([prop, value]) => [
      prop,
      {
        parent: isString(value) ? parent : prop,
        entity: value,
        glossary: isObject(get(glossary, prop)) ? get(glossary, prop) : glossary
      }
    ]);

  let entities = flatEntity(entity, null, currentGlossary);

  while (!isEmpty(entities)) {
    const [prop, {
      parent,
      entity,
      glossary
    }] = entities.pop();
    const shouldSkip = skips.some(skip => new RegExp(skip).test(prop));
    if (!shouldSkip) {
      if (isString(entity)) {
        const nodeName = get(glossary, prop);
        nodes.push(createNode(nodeName));
      }
      if (isObject(entity)) {
        entities = entities.concat(flatEntity(entity, parent, glossary));
      }
    }
  }
  return nodes;
};


export const buildError = validationResult =>
  `${validationResult}`
  .split(';')
  .sort((a, b) => b.split('-')[0].length - a.split('-')[0].length)
  .reduce((result, current) => {
    const [path, message] = current.split(' - ');
    return set(result, path, message);
  }, {});


export const buildNodes = (descriptors, error, context = {}) => {
  const resolveDescriptor = (currentDescriptor, currentError) => {
    const {
      title,
      path,
      glossary,
      skips,
      validate = $ => true,
      descriptors,
      getInfo = $ => {},
      args = []
    } = currentDescriptor;

    const getNodes = () => {
      const entities = get(currentError, path);//(string | object[] | object)
      if (isEmpty(entities)) {
        return [];
      } else {

        if (isString(entities)) {
          const isValid = validate.call(context, last(args), entities);
          if (isValid) {
            const nodeName = isFunction(title) ? title.call(context, entities) : title;
            return createNode(nodeName);
          }
          return [];
        }

        if (isArray(entities)) {
          return entities.map((entity, index) => {
            const isValid = !isEmpty(entity) && validate.call(context, index, entity);
            if (isValid) {
              const nodeName = isFunction(title) ? title.call(context, index) : title;
              return createNode(
                nodeName,
                collectNodesFromEntity(entity, glossary, skips), {
                  ...getInfo.call(context, index),
                  realIndex: index
                }
              );
            }
            return null;
          }).filter(Boolean);
        }

        if (isObject(entities)) {
          const nodeName = isFunction(title) ? title.call(context) : title;
          return [createNode(nodeName, collectNodesFromEntity(entities, glossary, skips))];
        }
      }
    }

    const nodes = getNodes();

    if (isEmpty(descriptors)) {
      return nodes;
    } else {
      const entities = get(currentError, path);
      if (isArray(entities)) {
        return [].concat(nodes).map((node, i) => {
          const index = get(node, 'info.realIndex', i);
          const entity = get(entities, `[${index}]`);
          const nextNodes = descriptors.map(descriptor => {
            const mergedDescriptor = {
              ...descriptor,
              title: isFunction(descriptor.title) ? descriptor.title.bind(context, ...args, index) : descriptor.title,
              args: args.concat(index),
              glossary: get(glossary, descriptor.path) || descriptor.glossary || glossary
            };
            return resolveDescriptor(mergedDescriptor, entity);
          }).flat();

          return {
            ...node,
            nodes: node.nodes.concat(nextNodes)
          };
        });
      } else {
        return validate.call(context, null, nodes) ? nodes : null;
      }
    }
  };

  //first level is section tab
  const nodes = descriptors.map(({
      title,
      descriptors
    }) =>
    createNode(
      title,
      descriptors.map(descriptor => resolveDescriptor(descriptor, error)).flat().filter(Boolean)
    )
  ).filter(({
    nodes
  }) => !isEmpty(nodes));
  return nodes;
};

export const resolveError = (validationResult, contextData) => {
  const error = buildError(validationResult);
  const {
    context,
    descriptors
  } = resolver(contextData);
  return buildNodes(descriptors, error, context);
};

export const resolveSettlementError = (settlementValidationResult, contextData) => {
  const error = Object.keys(settlementValidationResult).reduce((result, current) => {
    return set(result, current, settlementValidationResult[current]);
  }, {});
  const {
    context,
    descriptors
  } = settlementResolver(contextData);
  return buildNodes(descriptors, error, context);
};

export const resolveServiceabilityError = (serviceabilityValidationResult, contextData) => {
  const error = buildError(serviceabilityValidationResult);
  const {
    context,
    descriptors
  } = serviceabilityResolver(contextData);
  return buildNodes(descriptors, error, context);
};
