import { itemInListReplacer } from '../redux-utils';
import { ACTIONS as COMPARISON_ACTIONS } from '../comparison';
import { ACTIONS } from './loanCriteriaActions';
import {
  initialState,
  processAddLoanCriteria,
  processEnsureLoanTerms,
  processRemoveLoanCriteria,
  processResetSearchCriteria,
  processSetFixedLoanTerm,
  processSetInterestOnlyTerm,
  processSetLoanAmount,
  processSetLoanTerm,
  processSetProductType,
  processSetPurpose,
  processSetRepaymentTypes,
  processToggleSelectFeature,
  processUpdateSelectedFeaturesList,
  processValidateFixedLoanTerm,
  processValidateInterestOnlyTerm,
} from './loanCriteriaList';

export default (state = initialState, action) => {
  const stateWithNewItem = itemInListReplacer(state);

  switch (action.type) {
    case ACTIONS.ADD_LOAN_CRITERIA: {
      return processAddLoanCriteria(state, stateWithNewItem);
    }
    case ACTIONS.REMOVE_LOAN_CRITERIA: {
      return processRemoveLoanCriteria(state, action);
    }
    case ACTIONS.SET_LOAN_AMOUNT: {
      return processSetLoanAmount(action, stateWithNewItem, state);
    }
    case ACTIONS.SET_PURPOSE: {
      return processSetPurpose(action, state, stateWithNewItem);
    }
    case ACTIONS.SET_PRODUCT_TYPE: {
      return processSetProductType(action, state, stateWithNewItem);
    }
    case ACTIONS.SET_REPAYMENT_TYPE: {
      return processSetRepaymentTypes(action, state, stateWithNewItem);
    }
    case ACTIONS.SET_LOAN_TERM: {
      return processSetLoanTerm(action, state, stateWithNewItem);
    }
    case ACTIONS.ENSURE_LOAN_TERMS: {
      return processEnsureLoanTerms(state);
    }
    case ACTIONS.SET_FIXED_LOAN_TERM: {
      return processSetFixedLoanTerm(action, state, stateWithNewItem);
    }
    case ACTIONS.SET_INTEREST_ONLY_TERM: {
      return processSetInterestOnlyTerm(action, state, stateWithNewItem);
    }
    case ACTIONS.UPDATE_SELECTED_FEATURES_LIST: {
      return processUpdateSelectedFeaturesList(action, stateWithNewItem, state);
    }
    case ACTIONS.TOGGLE_SELECT_FEATURE: {
      return processToggleSelectFeature(action, state, stateWithNewItem);
    }
    case ACTIONS.VALIDATE_FIXED_LOAN_TERM: {
      return processValidateFixedLoanTerm(action, state, stateWithNewItem);
    }
    case ACTIONS.VALIDATE_INTEREST_ONLY_TERM: {
      return processValidateInterestOnlyTerm(action, state, stateWithNewItem);
    }
    case COMPARISON_ACTIONS.RESET_SEARCH_CRITERIA: {
      return processResetSearchCriteria(action);
    }
    default:
      return state;
  }
};
