import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { GET_ADVISERS } from './advisersGraphQL';
import AdviserShape from './AdviserShape';
import { getAdviserName } from '../../../Loans/utils';

const getListOfAdvisers = (data, selectedAdviser) => {
  const advisers = get(data, 'advisersByBusinessId');
  return (isEmpty(advisers) ? [selectedAdviser] : advisers);
};

const createOption = adviser => ({ label: getAdviserName(adviser), value: adviser.id });

const getSelectedOption = (value, options) => options.find(option => option.value === value);

const getOptions = (data, selectedAdviser) => getListOfAdvisers(data, selectedAdviser)
  .map(adviser => ({ label: getAdviserName(adviser), value: adviser.id }));

const getValue = (selectedAdviser, options) =>
  getSelectedOption(selectedAdviser.id, options)
  || createOption(selectedAdviser);

export const FormsyAdviserSelector = ({ businessId, selectedAdviser, disabled }) => {
  const { data } = useQuery(GET_ADVISERS, { variables: { businessId } });

  const options = useMemo(() => getOptions(data, selectedAdviser), [data, selectedAdviser]);
  const value = useMemo(() => getValue(selectedAdviser, options), [selectedAdviser, options]);

  return (
    <FormsySelect
      name="adviserId"
      options={options}
      value={value}
      fullWidth
      required
      disabled={disabled}
    />
  );
};

FormsyAdviserSelector.defaultProps = {
  disabled: false,
};

FormsyAdviserSelector.propTypes = {
  businessId: PropTypes.string.isRequired,
  selectedAdviser: AdviserShape.isRequired,
  disabled: PropTypes.bool,
};
