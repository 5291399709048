import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Field from '../Field/Field';
import styles
  from './FieldsContainer.module.scss';

const FieldsContainer = ({
 fields, title, className, fieldsWrapperClassName, children,
}) => (
  <div className={classNames(styles.defaultWrapper, className)}>
    <div className={styles.title}>{title}</div>
    <div className={fieldsWrapperClassName || styles.defaultFieldWrapper}>
      {children || fields.map(field => <Field key={field.key || field.title} {...field} />)}
    </div>
  </div>
);

FieldsContainer.defaultProps = {
  className: '',
  fieldsWrapperClassName: undefined,
  title: '',
  children: undefined,
  fields: [],
};

FieldsContainer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType(
        [PropTypes.string, PropTypes.func, PropTypes.node],
      ),
      className: PropTypes.string,
      sideBarClassName: PropTypes.string,
      hidden: PropTypes.bool,
      noDisplay: PropTypes.bool,
    }),
  ),
  title: PropTypes.string,
  className: PropTypes.string,
  fieldsWrapperClassName: PropTypes.string,
  children: PropTypes.node,
};

export default FieldsContainer;
