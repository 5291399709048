import moment from 'moment';

const dateString = date => (date ? moment(date).format('YYYY-MM-DD') : null);

const getTimeDuration = (startDateStr, endDateStr, isPrevious) => {
  const getDiff = (end, start, unit) => moment(end).diff(moment(start), unit, false);
  const yearsDiff = startDateStr && (!isPrevious || endDateStr)
    ? getDiff(endDateStr, startDateStr, 'years') : null;
  const monthsDiff = startDateStr && (!isPrevious || endDateStr)
    ? getDiff(endDateStr, startDateStr, 'months') - 12 * yearsDiff : null;
  return ({
    yearsDiff,
    monthsDiff,
  });
};

const getCurrentFinancialYear = () => {
  const currentDate = moment();
  const currentFinancialYearStart = moment(`${currentDate.year()}-07-01`);
  return currentDate.isBefore(currentFinancialYearStart)
    ? currentDate.year() - 1
    : currentDate.year();
};

const dateUtil = {
  dateString, getTimeDuration, getCurrentFinancialYear,
};

export default dateUtil;
