import React, { useMemo } from 'react';
import { Field, fieldArrayPropTypes, Fields } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import concat from 'lodash/concat';
import styles from './Company.module.scss';
import ReduxFormSelect from '../../../../../../../shared/components/formFields/ReduxForm/Select';
import ReduxFormTextInput from '../../../../../../../shared/components/formFields/ReduxForm/TextInput';
import { AddRemoveButtonGroup } from './AddRemoveButtonGroup';

const nonApplicantOption = {
  label: 'Non applicant',
  value: {
    isNonApplicant: true,
    firstName: null,
    surname: null,
    relatedApplicantId: null,
  },
};
const Beneficiaries = ({
                         fields, hasEditPermission, persons, label, className,
                       }) => {
  const onRemove = useMemo(() => (i) => fields.remove(i), [fields]);
  const onAdd = useMemo(() => () => fields.push({}), [fields]);
  const selectedIds = useMemo(() => fields.getAll().map(field => field.relatedApplicantId), [fields]);
  const optionsForLN = useMemo(
    () => concat(persons
      .filter(({ id }) => !isEmpty(id))
      .map(({ id, name }) => ({
        label: name,
        isDisabled: selectedIds.includes(id),
        value: {
          isNonApplicant: false,
          relatedApplicantId: id,
        },
      })), nonApplicantOption), [persons, selectedIds],
  );

  return (
    <div className={styles.beneficiaries}>
      {fields.map((fieldNamePrefix, index) => (
        <BeneficiaryForLN
          key={fieldNamePrefix}
          fieldNamePrefix={fieldNamePrefix}
          hasEditPermission={hasEditPermission}
          onRemove={() => onRemove(index)}
          onAdd={onAdd}
          shouldAdd={index === fields.length - 1}
          isOnlyOne={fields.length === 1}
          options={optionsForLN}
          label={label}
          className={className}
        />
        ))}
    </div>
  );
};

const BeneficiaryForLN = ({
       fieldNamePrefix, onRemove, onAdd, hasEditPermission, options, label, shouldAdd, isOnlyOne,
}) => (
  <>
    <Field
      name={`${fieldNamePrefix}`}
      component={BeneficiarySelector}
      className={classNames(
        [styles.fieldWrapper, styles.input, styles.atFirstColumnOnDesktop, styles.atFirstColumnOnMobile],
      )}
      label={label}
      options={options}
    />
    <Fields
      names={[`${fieldNamePrefix}.isNonApplicant`, `${fieldNamePrefix}.firstName`, `${fieldNamePrefix}.surname`]}
      component={TextInputAndButtonGroupFields}
      addFunc={onAdd}
      removeFunc={onRemove}
      showAdd={shouldAdd}
      showRemove={!isOnlyOne}
      hasEditPermission={hasEditPermission}
    />
  </>
);

BeneficiaryForLN.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  shouldAdd: PropTypes.bool.isRequired,
  isOnlyOne: PropTypes.bool.isRequired,
};

const BeneficiarySelector = (field) => {
  const selectedOption = useMemo(() => {
    const value = get(field, 'input.value');
    if (isEmpty(value)) {
      return null;
    }
    return find(field.options, (option) => get(option, 'value.isNonApplicant') === get(value, 'isNonApplicant')
      && get(option, 'value.relatedApplicantId', '') === get(value, 'relatedApplicantId', ''))?.value ?? null;
  }, [field]);

  return (<ReduxFormSelect {...field} value={selectedOption} options={field.options} />);
};

const TextInputAndButtonGroupFields = (fields) => {
  const [
    isNonApplicantFieldName,
    firstNameFieldName,
    surnameFieldName,
  ] = fields.names;
  const isNonApplicant = get(fields, `${isNonApplicantFieldName}.input.value`, false);
  const {
    addFunc, removeFunc, showAdd, showRemove, hasEditPermission, ...others
  } = fields;
  if (!isNonApplicant) {
    return hasEditPermission ? (
      <AddRemoveButtonGroup
        addFunc={addFunc}
        removeFunc={removeFunc}
        showAdd={showAdd}
        showRemove={showRemove}
        className={classNames(
            styles.singleColumnWrapperOnDesktop, styles.atThirdColumnOnDesktop,
          )}
      />
      ) : null;
  }
  const firstNameField = get(fields, `${firstNameFieldName}`);
  const surnameField = get(fields, `${surnameFieldName}`);
  return (
    <>
      <ReduxFormTextInput
        {...others}
        {...firstNameField}
        className={classNames([
          styles.fieldWrapper,
          styles.atThirdColumnOnDesktop,
          styles.singleColumnWrapperOnDesktop,
          styles.nameInput,
          styles.input,
        ])}
        label="First name"
      />
      <ReduxFormTextInput
        {...others}
        {...surnameField}
        className={classNames([
          styles.fieldWrapper,
          styles.atForthColumnOnDesktop,
          styles.singleColumnWrapperOnDesktop,
          styles.nameInput,
          styles.input,
        ])}
        label="Surname"
      />
      {hasEditPermission && (
        <AddRemoveButtonGroup
          addFunc={addFunc}
          removeFunc={removeFunc}
          showAdd={showAdd}
          showRemove={showRemove}
          className={classNames(
            styles.singleColumnWrapperOnDesktop, styles.atFifthColumnOnDesktop,
          )}
        />
      )}
    </>
  );
};

Beneficiaries.propTypes = {
  ...fieldArrayPropTypes,
};

export default Beneficiaries;
