import uniqueId from 'lodash/uniqueId';
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import get from 'lodash/get';
import { splitLoan } from '../loanSplitGraphQL';
import { showToast } from '../../../../redux/toast/actions';
import { getLoan } from '../../LoanDetails/redux/loanActions';

export const ACTIONS = {
  RESET: 'LOAN_SPLIT:RESET',
  ADD: 'LOAN_SPLIT:ADD',
  REMOVE: 'LOAN_SPLIT:REMOVE',
  UPDATE_SETTLED_AMOUNT: 'LOAN_SPLIT:UPDATE_SETTLED_AMOUNT',
  UPDATE_SPLIT_SETTLED_DATE: 'LOAN_SPLIT:UPDATE_SPLIT_SETTLED_DATE',
  UPDATE_SPLIT_ACCOUNT_NUMBER: 'LOAN_SPLIT:UPDATE_SPLIT_ACCOUNT_NUMBER',
  UPDATE_SPLIT_SETTLED_AMOUNT: 'LOAN_SPLIT:UPDATE_SPLIT_SETTLED_AMOUNT',
  CREATE_LOANS_START: 'LOAN_SPLIT:CREATE_LOANS_START',
  CREATE_LOANS_SUCCESS: 'LOAN_SPLIT:CREATE_LOANS_SUCCESS',
  CREATE_LOANS_ERROR: 'LOAN_SPLIT:CREATE_LOANS_ERROR',
  UPDATE_SPLITTER: 'LOAN_SPLIT:UPDATE_SPLITTER',
  UPDATE_HAS_DECLARED: 'LOAN_SPLIT:UPDATE_HAS_DECLARED',
};

const buildSplit = ({ id, accountNumber = '', settledAmount = null } = {}) => ({
  id: id || uniqueId(),
  accountNumber,
  settledAmount,
});

export const reset = createAction(ACTIONS.RESET, () => ({
  payload: {
    splitSettledDate: moment().toDate(),
    splits: [buildSplit()],
  },
}));

export const add = createAction(ACTIONS.ADD, () => ({
  payload: uniqueId(),
}));

export const remove = createAction(ACTIONS.REMOVE);

export const updateSettledAmount = createAction(ACTIONS.UPDATE_SETTLED_AMOUNT);

export const updateSplitSettledDate = createAction(
  ACTIONS.UPDATE_SPLIT_SETTLED_DATE,
);

export const updateSplitAccountNumber = createAction(
  ACTIONS.UPDATE_SPLIT_ACCOUNT_NUMBER,
  (id, accountNumber) => ({
    payload: {
      id,
      accountNumber,
    },
  }),
);

export const updateSplitSettledAmount = createAction(
  ACTIONS.UPDATE_SPLIT_SETTLED_AMOUNT,
  (id, settledAmount) => ({
    payload: {
      id,
      settledAmount,
    },
  }),
);

export const updateSplitter = createAction(ACTIONS.UPDATE_SPLITTER);
export const updateHasDeclared = createAction(ACTIONS.UPDATE_HAS_DECLARED);

const createLoansStart = createAction(ACTIONS.CREATE_LOANS_START);
const createLoansSuccess = createAction(ACTIONS.CREATE_LOANS_SUCCESS);
const createLoansError = createAction(ACTIONS.CREATE_LOANS_ERROR);

export const createLoans = () => async (dispatch, getState) => {
  dispatch(createLoansStart());

  const id = get(getState(), 'loans.loan.data.id');
  const settledAmount = get(getState(), 'loans.loanSplit.settledAmount');
  const splitSettledDate = get(getState(), 'loans.loanSplit.splitSettledDate');
  const ids = get(getState(), 'loans.loanSplit.splits.ids', []);
  const byId = get(getState(), 'loans.loanSplit.splits.byId');
  const hasDeclared = get(getState(), 'loans.loanSplit.hasDeclared');

  const splits = ids.map((sid) => {
    const split = byId[sid];

    return {
      accountNumber: split.accountNumber.trim(),
      settledAmount: split.settledAmount,
      settledDate: moment(splitSettledDate).format('YYYY-MM-DD'),
    };
  });

  try {
    await splitLoan(id, { settledAmount, splits, hasDeclared });
    dispatch(createLoansSuccess());
    dispatch(showToast('Loans created', { type: 'success' }));
    dispatch(getLoan(id));
  } catch (err) {
    dispatch(createLoansError(err));
    throw err;
  }
};
