import React from 'react';
import flatten from 'lodash/flatten';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import keys from 'lodash/keys';
import { toTimeStr } from 'utils/datetime';
import BasicRichContent from '../BasicRichContent';
import styles from './TaskContent.module.scss';

const renderWhenExists = (update, component) => (update === undefined ? null : component);

const taskUpdateMap = {
  summary: summary => renderWhenExists(summary, 'TASK SUMMARY CHANGED'),
  description: description => renderWhenExists(description, 'TASK DESCRIPTION CHANGED'),
  dueDateTime: dueDateTime => renderWhenExists(dueDateTime,
    dueDateTime === null ? 'DUE DATE REMOVED' : `DUE ${toTimeStr(dueDateTime)}`),
  assignee: assignee => renderWhenExists(assignee, isEmpty(assignee) ? 'UNASSIGNED' : `ASSIGNED TO ${assignee}`),
  commentAdded: commentAdded => (commentAdded ? 'COMMENT ADDED' : null),
  completed: completed => renderWhenExists(completed, completed ? 'DONE' : 'NO LONGER DONE'),
};

const renderLogContent = (isLink, allTasks, applicationId, resourceId, name) => {
  const foundTask = allTasks.find(task => task.id === resourceId);
  return (
    isLink && foundTask
    ? (
      <div className={styles.link}>
        <Link to={`#/tasks/${foundTask.id}`}>
          {name}
        </Link>
      </div>
      ) : <div>{name}</div>
  );
};

const renderLogUpdates = updates => keys(updates).map(key => taskUpdateMap[key](updates[key]));

const TaskContent = ({
 title, updates, allTasks, isLink, applicationId, resourceId, name,
}) => (
  <BasicRichContent
    type={title}
    content={renderLogContent(isLink, allTasks, applicationId, resourceId, name)}
    subContent={updates ? renderLogUpdates(updates) : null}
  />
);

TaskContent.defaultProps = {
  isLink: true,
  applicationId: '',
  updates: undefined,
};

TaskContent.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  updates: PropTypes.shape({
    summary: PropTypes.string,
    description: PropTypes.string,
    dueDateTime: PropTypes.string,
    assignee: PropTypes.string,
    commentAdded: PropTypes.bool,
    completed: PropTypes.bool,
  }),
  allTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLink: PropTypes.bool,
  applicationId: PropTypes.string,
};

export default connect(
  state => ({
    allTasks: flatten(get(state, 'application.tasksInfo.taskLists', []).map(list => list.tasks)),
    applicationId: get(state, 'application.applicationDetail.id', ''),
  }),
)(TaskContent);
