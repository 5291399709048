import React from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import style from './style';

const REPAYMENT_OPTIONS = [
  { value: undefined, label: 'Any' },
  { value: 'Interest Only', label: 'Interest only' },
  { value: 'Principal and Interest Repayment', label: 'Principal and interest' },
];

const toSelectValue = value => REPAYMENT_OPTIONS.find(option => option.value === value);

const RepaymentTypeComponent = (props) => {
  const {
    repaymentType,
    dispatchUpdateRepaymentType,
  } = props;
  return (
    <Select
      label="Repayment type"
      className="select-input repaymentType"
      value={toSelectValue(repaymentType)}
      onChange={selected => dispatchUpdateRepaymentType(selected.value)}
      options={REPAYMENT_OPTIONS}
      style={style}
    />
  );
};

RepaymentTypeComponent.defaultProps = {
  repaymentType: undefined,
};

RepaymentTypeComponent.propTypes = {
  dispatchUpdateRepaymentType: PropTypes.func.isRequired,
  repaymentType: PropTypes.oneOf(REPAYMENT_OPTIONS.map(option => option.value)),
};

export default RepaymentTypeComponent;
