import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import every from 'lodash/every';
import flatten from 'lodash/flatten';
import cx from 'classnames';
import hasPermissionTo from 'shared/components/authorisation/hasPermissionTo';
import RadioButtonGroup from 'shared/components/formFields/RadioButtonGroup';
import Checkbox from '../../../shared/components/formFields/Checkbox'; // TODO: Move formFields to general components
import styles from './styles';
import BusinessShape from '../../../shapes/BusinessShape';
import { PERMISSIONS } from '../../../shared/components/authorisation/permissions';
import cssStyles from './styles.module.scss';

const applicationPermissions = [
  {
    permissions: [
      PERMISSIONS.VIEW_ALL_APPLICATION_TRACKING,
      PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING,
      PERMISSIONS.VIEW_ALL_LOANS,
      PERMISSIONS.EDIT_ALL_LOANS,
    ],
    label: 'Access to all',
    value: 'Access to all',
  },
  {
    permissions: [
      PERMISSIONS.VIEW_OWN_APPLICATION_TRACKING,
      PERMISSIONS.EDIT_OWN_APPLICATION_TRACKING,
      PERMISSIONS.VIEW_OWN_LOANS,
      PERMISSIONS.EDIT_OWN_LOANS,
    ],
    label: 'Access to assigned and owned',
    value: 'Access to assigned and owned',
  },
];

const hasPermissions = (permissions, user, business) => (
  every(permissions, item =>
    hasPermissionTo(item, user.permissions, (business || {}).id))
);

const getPermissionsValue = permissions => (
  permissions.map(item => `${item.application}:${item.area}:${item.action}`)
);

const generateAccessBoxStyle = isOwner => (isOwner ? {
    ...styles.accessBox,
    span: styles.disabledText,
  } : styles.accessBox);

const getCheckBoxAccessCell = (user, business, updatePermissions, permissions) => {
  const selectCheckBox = () => {
    const checked = hasPermissions(permissions, user, business);
    const permissionsToAdd = getPermissionsValue(checked ? [] : permissions);
    const permissionsToDelete = getPermissionsValue(checked ? permissions : []);
    updatePermissions({
      userId: user.userId, businessId: business.id, permissionsToAdd, permissionsToDelete,
    });
  };
  return (
    <td style={styles.commonCell}>
      <Checkbox
        className={cx(cssStyles.accessCheck, { [cssStyles.disabeldAccessCheck]: user.isOwner })}
        checked={user.isOwner || hasPermissions(permissions, user, business)}
        disabled={user.isOwner}
        labelName="Access"
        onChange={selectCheckBox}
      />
    </td>
  );
};

const getRadioButtonGroupAccessCell = (user, business, updatePermissions, permissions) => {
  const permissionItems = permissions.map(option => ({
    label: option.label,
    disabled: user.isOwner,
    checked: user.isOwner || hasPermissions(option.permissions, user, business),
    value: option.value,
  }));
  const selectButton = (selectValue) => {
    const permissionsToAdd = getPermissionsValue(permissions.find(option => option.value === selectValue).permissions);
    const permissionsToDelete = getPermissionsValue(flatten(permissions.filter(option =>
      option.value !== selectValue).map(option => option.permissions)));
    updatePermissions({
      userId: user.userId, businessId: business.id, permissionsToAdd, permissionsToDelete,
    });
  };
  return (
    <td style={styles.commonCell}>
      <RadioButtonGroup
        style={generateAccessBoxStyle(user.isOwner)}
        groupName={`permissionGroup-${user.userId}`}
        onSelect={selectButton}
        items={permissionItems}
        value={get(find(permissionItems, { checked: true }), 'value', undefined)}
      />
    </td>
  );
};

const mapUserToRow = (updatePermissions, business) => (user, index) => {
  const rowStyle = index % 2 === 0 ? styles.rowEven : styles.rowOdd;
  const checkBoxCell = permissions => getCheckBoxAccessCell(user, business, updatePermissions, permissions);
  const radioButtonGroup = permissions => getRadioButtonGroupAccessCell(user, business, updatePermissions, permissions);
  return (
    <tr style={rowStyle} key={`${user.userId}row`}>
      <td style={{ ...styles.commonCell, ...styles.nameCell }}>{user.userName}</td>
      {checkBoxCell([PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS])}
      {checkBoxCell([PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONMANAGEMENT])}
      {checkBoxCell([PERMISSIONS.VIEW_LOANAPPLICATION_INVOICES])}
      {radioButtonGroup(applicationPermissions)}
      {checkBoxCell([PERMISSIONS.VIEW_TEMPLATE_MANAGEMENT, PERMISSIONS.EDIT_TEMPLATE_MANAGEMENT])}
    </tr>
  );
};

const PermissionSettingsTable = ({
 users, updatePermissions, business,
}) => {
  const getSortedUsers = condition =>
    users.filter(condition)
      .sort((user1, user2) => (user1.userName > user2.userName ? 1 : -1));
  const owners = getSortedUsers(user => user.isOwner && user.isActive);
  const others = getSortedUsers(user => !user.isOwner && user.isActive);
  const sortedUserList = owners.concat(others);
  return (
    <div>
      <table style={styles.table}>
        <thead>
          <tr style={styles.header}>
            <th style={{ ...styles.commonCell, ...styles.headerCell }} />
            <th style={{ ...styles.commonCell, ...styles.headerCell }}>Commissions</th>
            <th style={{ ...styles.commonCell, ...styles.headerCell }}>Commission management reports</th>
            <th style={{ ...styles.commonCell, ...styles.headerCell }}>Invoices</th>
            <th style={{ ...styles.commonCell, ...styles.wideHeaderCell }}>Applications/Loans</th>
            <th style={{ ...styles.commonCell, ...styles.wideHeaderCell }}>Template management</th>
          </tr>
        </thead>
        <tbody>
          {sortedUserList.map(mapUserToRow(updatePermissions, business))}
        </tbody>
      </table>
    </div>
  );
};

PermissionSettingsTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updatePermissions: PropTypes.func.isRequired,
  business: BusinessShape.isRequired,
};

export default PermissionSettingsTable;
