import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConnectedProductsSearch from './ProductsSearchCriteria';
import {
  modifyCriteria,
  onLoanAmountBlur, onLoanValueRatioBlur,
  onSecurityValueBlur, updateLoanValueRatio,
  updateSecurityValue, updateSelectedLenderNames, updateLoanAmount,
} from '../redux/applicationCriteria';
import {
  addLoanCriteria,
  removeLoanCriteria,
  toggleSelectFeature,
  updateFixedLoanTerm, updateInterestOnlyTerm,
  updateLoanTerm,
  updateProductType,
  updatePurpose,
  updateRepaymentType, updateSelectedFeatureNames, validateFixedLoanTerm, validateInterestOnlyTerm,
} from '../redux/loanCriteriaList';
import { getLenders } from '../redux/lenders';
import { getFeatures } from '../redux/features/features';
import { getProducts } from '../redux/products/products';

const StandAloneProductSearchCriteria = ({
                                   loanCriteriaList,
                                   applicationCriteria,
                                   lenders,
                                   features,
                                   dispatchOnLoanAmountBlur,
                                   dispatchUpdateLoanAmount,
                                   dispatchOnSecurityValueBlur,
                                   dispatchUpdateSecurityValue,
                                   dispatchOnLoanValueRatioBlur,
                                   dispatchUpdateLoanValueRatio,
                                   dispatchUpdatePurpose,
                                   dispatchUpdateProductType,
                                   dispatchUpdateRepaymentType,
                                   dispatchUpdateLoanTerm,
                                   dispatchValidateFixedLoanTerm,
                                   dispatchValidateInterestOnlyTerm,
                                   dispatchUpdateFixedLoanTerm,
                                   dispatchUpdateInterestOnlyTerm,
                                   dispatchUpdateSelectedLenderNames,
                                   dispatchGetLenders,
                                   dispatchUpdateSelectedFeaturesNames,
                                   dispatchToggleSelectFeature,
                                   dispatchGetFeatures,
                                   dispatchRemoveLoanCriteria,
                                   dispatchGetProducts,
                                   dispatchAddLoanCriteria,
                                   dispatchModifyCriteria,
                                 }) => {
  const context = {
    loanCriteriaList,
    applicationCriteria,
    lenders,
    features,
    dispatchOnLoanAmountBlur,
    dispatchUpdateLoanAmount,
    dispatchOnSecurityValueBlur,
    dispatchUpdateSecurityValue,
    dispatchOnLoanValueRatioBlur,
    dispatchUpdateLoanValueRatio,
    dispatchUpdatePurpose,
    dispatchUpdateProductType,
    dispatchUpdateRepaymentType,
    dispatchUpdateLoanTerm,
    dispatchValidateFixedLoanTerm,
    dispatchValidateInterestOnlyTerm,
    dispatchUpdateFixedLoanTerm,
    dispatchUpdateInterestOnlyTerm,
    dispatchUpdateSelectedLenderNames,
    dispatchGetLenders,
    dispatchUpdateSelectedFeaturesNames,
    dispatchToggleSelectFeature,
    dispatchGetFeatures,
    dispatchRemoveLoanCriteria,
    dispatchGetProducts,
    dispatchAddLoanCriteria,
    dispatchModifyCriteria,
  };
  return (
    <ConnectedProductsSearch
      context={context}
    />
  );
};

StandAloneProductSearchCriteria.propTypes = {
  loanCriteriaList: PropTypes.arrayOf(PropTypes.shape({
    loanAmount: PropTypes.number,
    errorMessage: PropTypes.shape({ interestOnlyTermError: PropTypes.string }),
  })).isRequired,
  applicationCriteria: PropTypes.shape({
    errorMessage: PropTypes.shape({ securityValue: PropTypes.string }),
    modifySearch: PropTypes.bool,
  }).isRequired,
  lenders: PropTypes.shape({
    lenderNames: PropTypes.arrayOf(PropTypes.string),
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
  features: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      groupName: PropTypes.string,
    })),
    hasError: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
  dispatchOnLoanAmountBlur: PropTypes.func.isRequired,
  dispatchUpdateLoanAmount: PropTypes.func.isRequired,
  dispatchOnSecurityValueBlur: PropTypes.func.isRequired,
  dispatchUpdateSecurityValue: PropTypes.func.isRequired,
  dispatchOnLoanValueRatioBlur: PropTypes.func.isRequired,
  dispatchUpdateLoanValueRatio: PropTypes.func.isRequired,
  dispatchUpdatePurpose: PropTypes.func.isRequired,
  dispatchUpdateProductType: PropTypes.func.isRequired,
  dispatchUpdateRepaymentType: PropTypes.func.isRequired,
  dispatchUpdateLoanTerm: PropTypes.func.isRequired,
  dispatchUpdateFixedLoanTerm: PropTypes.func.isRequired,
  dispatchValidateFixedLoanTerm: PropTypes.func.isRequired,
  dispatchUpdateInterestOnlyTerm: PropTypes.func.isRequired,
  dispatchValidateInterestOnlyTerm: PropTypes.func.isRequired,
  dispatchUpdateSelectedLenderNames: PropTypes.func.isRequired,
  dispatchGetLenders: PropTypes.func.isRequired,
  dispatchUpdateSelectedFeaturesNames: PropTypes.func.isRequired,
  dispatchToggleSelectFeature: PropTypes.func.isRequired,
  dispatchGetFeatures: PropTypes.func.isRequired,
  dispatchRemoveLoanCriteria: PropTypes.func.isRequired,
  dispatchGetProducts: PropTypes.func.isRequired,
  dispatchAddLoanCriteria: PropTypes.func.isRequired,
  dispatchModifyCriteria: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loanCriteriaList: state.productSearch.standAlone.loanCriteriaList,
  applicationCriteria: state.productSearch.standAlone.applicationCriteria,
  lenders: state.productSearch.lenders,
  features: state.productSearch.features,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOnLoanAmountBlur: () => dispatch(onLoanAmountBlur()),
  dispatchUpdateLoanAmount: index => value => dispatch(updateLoanAmount(value, index)),
  dispatchOnSecurityValueBlur: () => dispatch(onSecurityValueBlur()),
  dispatchUpdateSecurityValue: value => dispatch(updateSecurityValue(value)),
  dispatchOnLoanValueRatioBlur: () => dispatch(onLoanValueRatioBlur()),
  dispatchUpdateLoanValueRatio: value => dispatch(updateLoanValueRatio(value)),
  dispatchUpdatePurpose: index => option => dispatch(updatePurpose(option, index)),
  dispatchUpdateProductType: index => option => dispatch(updateProductType(option, index)),
  dispatchUpdateRepaymentType: index => option => dispatch(updateRepaymentType(option, index)),
  dispatchUpdateLoanTerm: index => value => dispatch(updateLoanTerm(value, index)),
  dispatchUpdateFixedLoanTerm: index => value => dispatch(updateFixedLoanTerm(value, index)),
  dispatchValidateFixedLoanTerm: index => () => dispatch(validateFixedLoanTerm(index)),
  dispatchUpdateInterestOnlyTerm: index => value => dispatch(updateInterestOnlyTerm(value, index)),
  dispatchValidateInterestOnlyTerm: index => () => dispatch(validateInterestOnlyTerm(index)),
  dispatchUpdateSelectedLenderNames: selectedLenderNames =>
    dispatch(updateSelectedLenderNames(selectedLenderNames)),
  dispatchGetLenders: () => dispatch(getLenders()),
  dispatchUpdateSelectedFeaturesNames:
    index => selectedFeatures => dispatch(updateSelectedFeatureNames(selectedFeatures, index)),
  dispatchToggleSelectFeature:
    index => featureName => dispatch(toggleSelectFeature(featureName, index)),
  dispatchGetFeatures: () => dispatch(getFeatures()),
  dispatchRemoveLoanCriteria: index => dispatch(removeLoanCriteria(index)),
  dispatchGetProducts: () => dispatch(getProducts()),
  dispatchAddLoanCriteria: () => dispatch(addLoanCriteria()),
  dispatchModifyCriteria: () => dispatch(modifyCriteria()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandAloneProductSearchCriteria);
