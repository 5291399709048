import React from 'react';
import PropTypes from 'prop-types';
import BasicRichContent from '../BasicRichContent';

const NewSomaCreateContent = ({ somaType }) => (
  <BasicRichContent
    type="FINALISE"
    content={`The SOMA has been generated for the ${somaType}`}
  />
);

NewSomaCreateContent.propTypes = {
  somaType: PropTypes.string.isRequired,
};

export default NewSomaCreateContent;
