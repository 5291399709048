import React from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import defaultTo from 'lodash/defaultTo';
import SingleLoanCriteria from './SingleLoanCriteria';
import SplitLoanCriteria from './SplitLoanCriteria';
import SecondaryButton from '../../shared/components/Buttons/SecondaryButton';
import PrimaryButton from '../../shared/components/Buttons/PrimaryButton';
import CriteriaSummary from '../CriteriaSummary/CriteriaSummary';
import { ProductSearchContext } from '../ProductSearchContext';

const MAX_SPLIT = 3;

export const ProductsSearchCriteria = ({ context }) => {
  const {
    loanCriteriaList,
    allowCustomProduct,
    applicationCriteria,
    dispatchGetProducts,
    dispatchAddLoanCriteria,
    dispatchModifyCriteria,
  } = context;
  const criteriaLength = loanCriteriaList.length;
  const modifySearch = defaultTo(applicationCriteria.modifySearch, true);
  const disableSearch = isSearchDisabled(loanCriteriaList, applicationCriteria) || false;
  const disableAddSplit = criteriaLength >= MAX_SPLIT;
  const applicationSearch = (
    <>
      <div className="criteria-container">
        { criteriaLength === 1
          ? <SingleLoanCriteria />
          : <SplitLoanCriteria /> }
        <div className="criteria-section">
          <div className="button-container">
            <SecondaryButton onClick={dispatchAddLoanCriteria} disabled={disableAddSplit} data-test-id="loanSplitButton">
              Add a loan split
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div className="search-button">
        <PrimaryButton onClick={dispatchGetProducts} disabled={disableSearch} data-test-id="searchButton">
          Search
        </PrimaryButton>
      </div>
    </>
  );
  const searchCriteria = modifySearch
    ? applicationSearch
    : (
      <CriteriaSummary
        modifiable
        allowCustomProduct={allowCustomProduct}
        modifySearchCallback={dispatchModifyCriteria}
        applicationCriteria={applicationCriteria}
        loanCriteriaList={loanCriteriaList}
      />
      );

  return (
    <ProductSearchContext.Provider value={context}>
      <div className="products-search">
        <h1>Product search</h1>
        { searchCriteria }
      </div>
    </ProductSearchContext.Provider>
  );
};

ProductsSearchCriteria.propTypes = {
  context: PropTypes.shape({
    loanCriteriaList: PropTypes.arrayOf(PropTypes.shape({
      loanAmount: PropTypes.number,
      errorMessage: PropTypes.shape({ interestOnlyTermError: PropTypes.string }),
    })).isRequired,
    applicationCriteria: PropTypes.shape({
      errorMessage: PropTypes.shape({ securityValue: PropTypes.string }),
      modifySearch: PropTypes.bool,
    }).isRequired,
    allowCustomProduct: PropTypes.bool,
    dispatchGetProducts: PropTypes.func.isRequired,
    dispatchAddLoanCriteria: PropTypes.func.isRequired,
    dispatchModifyCriteria: PropTypes.func.isRequired,
  }).isRequired,
};

const isEmpty = (obj = {}) => {
  const cleanedObject = pickBy(obj, v => v !== undefined);
  return lodashIsEmpty(cleanedObject);
};

const hasErrorMessage = (loanCriteriaList, applicationCriteria) => {
  const hasErrorInList = loanCriteriaList.some(criteria => !isEmpty(criteria.errorMessage));
  const hasErrorInApplicationCriteria = !isEmpty(applicationCriteria.errorMessage);
  return hasErrorInList || hasErrorInApplicationCriteria;
};

const isSearchDisabled = (loanCriteriaList, applicationCriteria) => {
  const someLoanAmountUndefined = (loanCriteriaList.length > 1
    && loanCriteriaList.some(loanCriteria => !loanCriteria.loanAmount));
  return hasErrorMessage(loanCriteriaList, applicationCriteria) || someLoanAmountUndefined;
};

export default ProductsSearchCriteria;
