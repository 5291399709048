import get from 'lodash/get';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { applicationStatus as applicationStatusMap } from '../../../../../constants/applicationStatus';
import { APPLICATION_STAGE } from '../../../../../constants/applicationDetail';

const initialState = APPLICATION_STAGE.APPLICATION;

const applicationStage = createReducer(initialState, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, action) => {
    const status = get(action, 'data.applicationStatus.status');
    return status === applicationStatusMap.PRE_SUBMISSION
      ? APPLICATION_STAGE.PRE_SUBMISSION
      : APPLICATION_STAGE.APPLICATION;
  },
});

export default applicationStage;
