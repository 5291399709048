import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector } from 'redux-form';
import range from 'lodash/range';
import {
  mapApplicationCostsFromStateToFormData,
  mapCostToPurchasesFromStateToFormData,
  mapContributionsFromStateToFormData,
  mapLoanAmountFromStateToFormData,
  mapRefinanceFromStateToFormData,
  mapOtherCostsFromStateToFormData,
  mapConstructionsFromStateToFormData,
} from './utils';
import { getHasTopUp } from '../liabilities';

const getFundingWorksheet = (state) => get(state, 'application.applicationDetail.fundingWorksheet');

export const getConstructions = (state) => get(getFundingWorksheet(state), 'constructions', []) || [];

export const getFundingWorksheetInitialValues = createSelector(
  getFundingWorksheet,
  (fundingWorksheet) => ({
    refinances: mapRefinanceFromStateToFormData(get(fundingWorksheet, 'refinances', []) || []),
    contributions: mapContributionsFromStateToFormData(get(fundingWorksheet, 'contributions')),
    applicationCosts: mapApplicationCostsFromStateToFormData(get(fundingWorksheet, 'applicationCosts')),
    costToPurchases: mapCostToPurchasesFromStateToFormData(get(fundingWorksheet, 'costToPurchases', []) || []),
    constructions: mapConstructionsFromStateToFormData(get(fundingWorksheet, 'constructions', []) || []),
    loanAmount: mapLoanAmountFromStateToFormData(get(fundingWorksheet, 'loanAmount')),
    somaComments: get(fundingWorksheet, 'somaComments'),
    otherCosts: mapOtherCostsFromStateToFormData(get(fundingWorksheet, 'otherCosts', []) || []),
  }),
);

export const hasCostToPurchases = (state) => !isEmpty(get(
  state,
  'application.applicationDetail.fundingWorksheet.costToPurchases',
));

const getCostToPurchasesCount = state => get(getFundingWorksheet(state), 'costToPurchases.length', 0) || 0;

const getConstructionsCount = state => get(getFundingWorksheet(state), 'constructions.length', 0) || 0;

const getRefinances = state => get(state, 'application.applicationDetail.fundingWorksheet.refinances', []) || [];

export const getUserGuidanceCodes = createSelector(
  getCostToPurchasesCount,
  getConstructionsCount,
  getRefinances,
  getHasTopUp,
  (costToPurchasesCount, constructionsCount, refinances, hasTopUp) => {
    const userGuidanceStartingCode = 'A'.charCodeAt(0);
    const result = {
      costToPurchases: range(costToPurchasesCount).map(index => String.fromCharCode(userGuidanceStartingCode + index)),
    };
    let counter = costToPurchasesCount;
    if (!isEmpty(refinances)) {
      result.refinanceAndDebtConsolidation = String.fromCharCode(userGuidanceStartingCode + counter);
      counter += 1;
    }

    result.constructions = range(constructionsCount)
    .map(index => String.fromCharCode(userGuidanceStartingCode + counter + index));
    counter += constructionsCount;

    if (hasTopUp) {
      result.otherCosts = String.fromCharCode(userGuidanceStartingCode + counter);
      counter += 1;
    }
    result.applicationCosts = String.fromCharCode(userGuidanceStartingCode + counter);
    result.contributions = String.fromCharCode(userGuidanceStartingCode + counter + 1);
    result.loanAmount = String.fromCharCode(userGuidanceStartingCode + counter + 2);
    result.capitalisedLmi = String.fromCharCode(userGuidanceStartingCode + counter + 3);
    return result;
  },
);

export const fundingWorksheetFormValueSelector = formValueSelector('fundingWorksheet');
