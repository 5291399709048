export const LOAN_CRITERIA = {
  loanAmount: 'Loan amount',
  purpose: 'Purpose',
  loanTerm: 'Loan term',
  purposeDetails: 'Purpose details',
  repaymentType: 'Repayment type',
};

export const MARITAL_STATUS = {
  status: 'Status',
};

export const APPLICANT = {
  maritalStatus: MARITAL_STATUS,
};

export const ADDRESS = {
  postcode: 'Post code must be with length 4',
};

export const EMPLOYER = {
  employerContact: {
    address: ADDRESS,
  },
};

export const EMPLOYMENT_INCOME = {
  periodUnit: 'Frequency',
  type: 'Income type',
};

export const EMPLOYMENT = {
  financialYear: 'Financial year',
  employmentIncomes: EMPLOYMENT_INCOME,
  employer: EMPLOYER,
};

export const REAL_ESTATE = {
  value: 'Value',
  primaryPurpose: 'Purpose',
  asSecurity: 'Use as security',
  transactionType: 'Transaction type',
  ownerships: 'Ownerships',
  rentalIncome: {
    rentalAmount: 'Rental income',
    periodUnit: 'Rental frequency',
  },
  investmentPropertyCost: {
    value: 'Investment property costs (expenses)',
    frequency: 'Costs frequency',
  },
  address: ADDRESS,
  propertyType: 'Property type',
};

export const NON_REAL_ESTATE = {
  value: 'Value',
  ownerships: 'Ownerships',
};

export const LIABILITY = {
  type: 'Liability type',
  currentBalance: 'Outstanding balance',
  limit: 'Limit',
  taxDeductible: 'Tax deductible',
  clearingStatus: 'Pay out/top up',
  ownerships: 'Ownerships',
  paymentType: 'Payment type',
  repayments: 'Repayments',
  value: 'Repayments',
  periodUnit: 'Repayments frequency',
  clearingAmount: 'Clearing amount must not be more than Outstanding balance',
};

export const ADDITIONAL_INCOME = {
  type: 'Income type',
  periodUnit: 'Frequency',
  value: 'Income',
  ownerships: 'Ownerships',
};

export const EXPENSE = {
  type: 'Expense type',
  periodUnit: 'Expense frequency',
  monthlyValue: 'Monthly value',
  ownerships: 'Ownerships',
};

export const SECURITY = {
  ...REAL_ESTATE,
  value: 'Purchase price',
  assetStatus: 'Status',
};
