import isEmpty from 'lodash/isEmpty';
import {
  getTargetChangedValue,
  getTargetChangedValues,
  getTargetOriginalValue,
} from 'ApplicationTracking/constants/utils';

export const getBasicLogInfo = origin => ({
  id: origin.id,
  actionTime: origin.actionTime,
  operator: origin.userName,
  extraInfo: {
    applicationId: origin.applicationId,
    lenderId: origin.lenderId,
    lenderName: origin.lenderName,
    applicationNumber: origin.applicationNumber,
    applicantNames: origin.applicantNames || [],
  },
});

export const toUpperCase = prop => {
  if (!prop) return prop;

  const finalStr = prop.replace(/([A-Z])/g, ' $1').trim();

  return finalStr.toUpperCase();
};

export const mapLogToProps = ({
  origin,
  prefix = '.+',
  type,
  content,
  summary,
  fieldMap = {},
  handleChanges = $ => $,
}) => {
  if (summary && !/#action/.test(summary)) {
    throw new Error('summary must include #action');
  }
  const queryRule = `${prefix}\\[[a-zA-Z0-9,-]+\\].id`;
  const oldValue = getTargetOriginalValue(origin, queryRule);
  const newValue = getTargetChangedValue(origin, queryRule);
  // no need for id in display logic
  const newChanges = getTargetChangedValues(origin, '((?!\\.id).)*');

  let action = '';

  if (oldValue && !newValue) {
    action = 'removed';
  } else if (!oldValue && newValue) {
    action = 'added';
  } else {
    action = 'updated';
  }

  const handler = changes => handleChanges(changes)
    .filter(Boolean)
    .map(change => {
      const {
        value,
        path,
      } = change;
      const prop = path.split('.').pop();
      const [propName, format = $ => $] = fieldMap[prop] || [];
      return [(propName || toUpperCase(prop)), (format(value) || 'Removed')]
        .filter(item => !isEmpty(item)).join(' - ');
    });

  return {
    ...getBasicLogInfo(origin),
    type,
    content: content || summary.replace('#action', action),
    subContent: action === 'removed' ? [] : handler(newChanges),
  };
};
