import { useMutation, gql } from '@apollo/client';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { updateLoanCategories } from 'Loans/components/Loans/redux/loansRedux';
import CheckboxDropdown from 'shared/components/formFields/CheckboxDropdown';
import styles from './styles.module.scss';
import { PERMISSIONS } from '../../../shared/components/authorisation/permissions';
import { useHasPermission } from '../../../shared/components/authorisation/hasPermissionTo';
import { MESSAGE_KEYS } from '../../../constants/message';

export const SET_CATEGORIES = gql`
  mutation SetCategies($loanId: String!, $categoryIds: [String]!) {
    setLoanCategories(body: { loanId: $loanId, categoryIds: $categoryIds })
  }
`;

export const UnconnectedCategoriesDropdown = ({
  categories,
  selectedCategoryIds,
  value,
  loanId,
  onSuccess,
  onUpdated,
  popoverClassName,
}) => {
  const noCategoriesMessageWhenEditPermission = 'Categories can be created by clicking the “Manage categories" button above.';
  const noCategoriesMessageWhenViewPermission = 'No Categories provided.';
  const noCategoriesSelectedMessageWhenViewPermission = 'No Categories selected.';

  const [setCategories] = useMutation(
    SET_CATEGORIES,
    {
      context: {
        messageConfig: {
          messageKey: MESSAGE_KEYS.SET_LOAN_CATEGORIES,
        },
      },
    },
  );
  const canEdit = useHasPermission(PERMISSIONS.EDIT_ALL_LOANS);

  const selectedOptionIds = useMemo(() => (selectedCategoryIds === '' ? [] : selectedCategoryIds?.split(',')), [selectedCategoryIds]);

  const options = useMemo(() => categories.map(cat => pick(cat, ['id', 'name'])), [categories]);
  return (
    <CheckboxDropdown
      popoverClassName={popoverClassName}
      inputProps={{ className: styles.box }}
      className={styles.wrapperInput}
      value={value}
      onUpdate={async (selectedCategories) => {
        // eslint-disable-next-line no-unused-vars
        const result = await setCategories({
          variables: {
            loanId,
            categoryIds: selectedCategories.map(cat => cat.id),
          },
        });
        onSuccess(loanId, selectedCategories);
        onUpdated(loanId, selectedCategories);
      }}
      options={options}
      selectedOptionIds={selectedOptionIds}
      disabled={!canEdit}
      listOnDisabled
      emptyOptionsMessage={canEdit ? noCategoriesMessageWhenEditPermission : noCategoriesMessageWhenViewPermission}
      emptySelectedOptionsMessage={noCategoriesSelectedMessageWhenViewPermission}
    />
  );
};

UnconnectedCategoriesDropdown.defaultProps = {
  categories: [],
  selectedCategoryIds: '',
  popoverClassName: '',
  value: undefined,
  onSuccess: () => {},
  onUpdated: () => {},
};

UnconnectedCategoriesDropdown.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedCategoryIds: PropTypes.string,
  value: PropTypes.string,
  popoverClassName: PropTypes.string,
  loanId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onUpdated: PropTypes.func,
};

const mapStateToProps = state => ({
  categories: state.loanCategories?.categories || [],
});

const mapDispatchToProps = dispatch => ({
  onSuccess: (loanId, categoryNames, categoryIds) => {
    dispatch(updateLoanCategories(loanId, categoryNames, categoryIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedCategoriesDropdown);
