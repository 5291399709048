import PropTypes from 'prop-types';
import React from 'react';

import FixedLoanTermComponent from './FixedLoanTermComponent';

import {
  isFixedProduct,
} from '../../redux/loanCriteriaList';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedFixedLoanTerm = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
      const { fixedLoanTermError } = context.loanCriteriaList[index].errorMessage || {};
      const { fixedLoanTerm } = context.loanCriteriaList[index];
      const disabled = !isFixedProduct(context.loanCriteriaList[index].productType);
      return (
        <FixedLoanTermComponent
          fixedLoanTerm={fixedLoanTerm}
          fixedLoanTermError={fixedLoanTermError}
          disabled={disabled}
          dispatchUpdateFixedLoanTerm={context.dispatchUpdateFixedLoanTerm(index)}
          dispatchValidateFixedLoanTerm={context.dispatchValidateFixedLoanTerm(index)}
        />
        );
    }}
  </ProductSearchContext.Consumer>
  );

ConnectedFixedLoanTerm.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedFixedLoanTerm;
