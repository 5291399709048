import PropTypes from 'prop-types';
import React from 'react';
import Select from 'shared/components/formFields/Select';
import { PURPOSE_OPTION_VALUES } from 'constants.js';
import styles from './styles.module.scss';

const PURPOSE_OPTIONS = [
  { value: PURPOSE_OPTION_VALUES.ANY, label: 'Any' },
  { value: PURPOSE_OPTION_VALUES.INVESTMENT, label: 'Investment' },
  { value: PURPOSE_OPTION_VALUES.OWNER_OCCUPIED, label: 'Owner occupied' },
];

const toSelectValue = (value, options) => options.find(option => option.value === value);

const PurposeComponent = (props) => {
  const {
    allowAny,
    value,
    onChange,
  } = props;
  const options = allowAny ? PURPOSE_OPTIONS : PURPOSE_OPTIONS.slice(1);

  return (
    <Select
      label="Purpose"
      className={styles.select}
      value={toSelectValue(value, options)}
      onChange={selected => onChange(selected.value)}
      options={options}
    />
  );
};

PurposeComponent.defaultProps = {
  value: undefined,
  allowAny: true,
};

PurposeComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(PURPOSE_OPTIONS.map(option => option.value)),
  allowAny: PropTypes.bool,
};

export default PurposeComponent;
