import { useQuery, gql } from '@apollo/client';
import React from 'react';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import find from 'lodash/find';

export const GET_SUB_LENDERS_BY_PRIMARY_ID = gql`
  query subLenders($primaryLenderId: String!) {
    subLenders(primaryLenderId: $primaryLenderId) {
      id
      name
    }
  }
`;
const getOptions = (loading, error, data) => {
  if (loading || error) {
    return undefined;
  }

  return (data ? map(data.subLenders, lender => ({
    label: lender.name,
    value: lender.id,
  })) : undefined);
};

const getValue = (initialLender, options) => (initialLender && options
  ? find(options, option => option.value === initialLender.id)
  : undefined);

const SubLender = ({
 selectedSubLender, primaryLenderId, required, disabled,
}) => {
   const { loading, error, data } = useQuery(GET_SUB_LENDERS_BY_PRIMARY_ID, {
     variables: { primaryLenderId },
   });

  const options = getOptions(loading, error, data);
  const value = getValue(selectedSubLender, options);

  return (
    <FormsySelect
      name="subLender"
      options={options}
      loading={loading}
      loadingMessage="Getting sub lenders"
      customErrorMessage={error ? 'Error getting sub lenders' : undefined}
      value={value}
      fullWidth
      required={!loading && required}
      disabled={disabled}
    />
  );
};

SubLender.defaultProps = {
  selectedSubLender: undefined,
  primaryLenderId: undefined,
  required: false,
  disabled: false,
};

SubLender.propTypes = {
  selectedSubLender: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  primaryLenderId: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SubLender;
