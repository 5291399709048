import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.module.scss';

export const Alert = ({ message, type, onClose }) => {
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) {
    return null;
  }

  return (
    <div className={`alert alert-${type} alert-dismissible fade show ${styles.alert}`} role="alert">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: message }} />
      {onClose && (
      <button
        type="button"
        className={`close ${styles.button}`}
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          onClose();
          setDismissed(true);
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    )}
    </div>
);
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  type: 'info',
  onClose: undefined,
};
