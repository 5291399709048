import PropTypes from 'prop-types';
import { dataShape } from './data';
import { seriesShape } from './series';

export const viewShape = PropTypes.shape({
  key: PropTypes.string,
  parentKey: PropTypes.key,
  data: dataShape,
  series: seriesShape,
});
