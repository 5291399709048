import React from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import LabelledFieldValue from 'shared/components/LabelledFieldValue/LabelledFieldValue';
import { orderBy } from 'lodash/collection';
import { Field } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import ExternalLink from '../components/Links/ExternalLink';
import ReduxFormRadioButton from '../components/formFields/ReduxForm/RadioButton';

const transformPerson = ({ surname, firstName, ...rest }) => ({
  name: [surname, firstName].filter(Boolean).join(', ').trim(),
  ...rest,
});

const orderByPrimary = target => orderBy(target, ['isPrimary'], ['desc']);

export const orderApplicants = applicants => [
  ...orderByPrimary(applicants.people.map(transformPerson)),
  ...orderByPrimary(applicants.companies)];

const HEADERS = [
  {
    key: 'name',
    content: 'APPLICANT',
    className: 'fixedMedium',
  },
  {
    key: 'email',
    content: 'EMAIL',
    className: 'variableSmallMedium',
  },
  {
    key: 'mobile',
    content: 'MOBILE',
    className: 'fixedSmall',
  },
  {
    key: 'home',
    content: 'HOME',
    className: 'fixedSmall',
  },
  {
    key: 'work',
    content: 'WORK',
    className: 'fixedSmall',
  },
  {
    key: 'surveyContact',
    content: 'SURVEY',
    className: 'fixedSmall',
  },
  {
    key: 'mainContact',
    content: 'MAIN CONTACT',
    className: 'fixedSmall',
  },
];

export const getHeadersWithSurvey = () => generateHeaders(true);

export const getHeaders = () => generateHeaders(false);

const generateHeaders = (withSurvey) => {
  if (withSurvey) {
    return HEADERS;
  }
  return filter(HEADERS, column => column.key !== 'surveyContact');
};

const renderPhoneNumber = phone => {
  const { number, isPreferred } = phone;
  return number && (
    <LabelledFieldValue label="Preferred" withLabel={isPreferred}>
      <a href={`tel:${number}`}>{number}</a>
    </LabelledFieldValue>
  );
};

const renderLoanMainContactContent = applicant => (
  <input
    type="radio"
    disabled
    checked={!!applicant.isPrimary}
  />
);

const renderApplicationMainContactContent = (applicant, disabled, isHidden) => (
  !isHidden ? <Field name="mainContact" component={ReduxFormRadioButton} type="radio" value={applicant.id} disabled={disabled} />
    : null
);

const renderApplicationSurveyContactContent = (applicant, disabled, isHidden) => (
  !isHidden ? <Field name="survey" component={ReduxFormRadioButton} type="radio" value={applicant.id} disabled={disabled} />
    : null
);

export const getRowPresenterWithSurvey = (applicant, disableSurveyContact = true,
                                          disableMainContact = true, isHidden = false) =>
  generateApplicationRowPresenter(applicant, disableSurveyContact, disableMainContact, isHidden);

export const getRowPresenter = applicant =>
  generateLoanRowPresenter(applicant);

const getNameContent = applicant => {
  const name = get(applicant, 'name', '');
  const externalLink = get(applicant, 'externalLink');
  return externalLink
    ? <ExternalLink to={externalLink}>{name}</ExternalLink>
    : name;
};

const getEmail = applicant => get(applicant, 'email', '');

const generateCommonRowPresenter = applicant => [
  {
    key: 'name',
    content: getNameContent(applicant),
  },
  {
    key: 'email',
    content: getEmail(applicant),
  },
  {
    key: 'mobile',
    content: renderPhoneNumber(
      get(applicant, 'mobile', {}),
    ),
  },
  {
    key: 'home',
    content: renderPhoneNumber(
      get(applicant, 'home', {}),
    ),
  },
  {
    key: 'work',
    content: renderPhoneNumber(
      get(applicant, 'work', {}),
    ),
  },
];

const generateApplicationRowPresenter = (applicant, disableSurveyContact, disableMainContact, isHidden) => {
  const commonRowPresenter = generateCommonRowPresenter(applicant);
  return [
    ...commonRowPresenter,
    {
      key: 'surveyContact',
      content: renderApplicationSurveyContactContent(applicant, disableSurveyContact
        || isEmpty(getEmail(applicant)), isHidden),
    },
    {
      key: 'mainContact',
      content: renderApplicationMainContactContent(applicant, disableMainContact, isHidden),
    },
  ];
};

const generateLoanRowPresenter = applicant => {
  const commonRowPresenter = generateCommonRowPresenter(applicant);
  return [
    ...commonRowPresenter,
    {
      key: 'mainContact',
      content: renderLoanMainContactContent(applicant),
    },
  ];
};
