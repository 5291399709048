import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import Modal from 'shared/components/Modal/Modal';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import { getUserContactsByBusinessId } from 'shared/api';
import Select from 'shared/components/formFields/Select';
import styles from './ComparisonPreparerModal.module.scss';

const selectedBusinessIdSelector = state => get(state, 'business.selectedBusiness.id');
const ComparisonPreparerModal = ({
  onDownload, isOpen, onRequestClose, defaultProfile,
}) => {
  const selectedBusinessId = useSelector(selectedBusinessIdSelector);
  const [isDownloading, setIsDownloading] = useState(false);
  const [availableAdvisers, setAdvisers] = useState([defaultProfile]);
  const [selectedAdviser, selectAdviser] = useState(defaultProfile);
  const [isFetchingAdvisers, setFetching] = useState(false);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);
    await onDownload(selectedAdviser);
    setIsDownloading(false);
  }, [setIsDownloading, onDownload, selectedAdviser]);

  useEffect(() => {
    if (isOpen && selectedBusinessId) {
      setFetching(true);
      getUserContactsByBusinessId(selectedBusinessId)
        .then((advisers) => {
          const otherUsers = advisers
            .filter(adviser => adviser.email !== defaultProfile.email)
            .sort((adviser1, adviser2) => (adviser1.username.toLowerCase() > adviser2.username.toLowerCase() ? 1 : -1));
          setAdvisers([defaultProfile, ...otherUsers]);
          selectAdviser(defaultProfile);
        })
        .finally(() => setFetching(false));
      }
    }, [selectedBusinessId, defaultProfile, isOpen]);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header={(<div className={styles.header}>Comparison printout adviser name</div>)}
      footer={(
        <PrimaryButton
          className={styles.button}
          disabled={isDownloading}
          onClick={handleDownload}
        >
          Download PDF
        </PrimaryButton>
      )}
    >
      <div>
        <div className={styles.label}>Which adviser would you like on the printout?</div>
        <Select
          className={styles.select}
          label=""
          value={({ label: selectedAdviser.username, value: selectedAdviser.email })}
          onChange={value => selectAdviser(availableAdvisers.find(adviser => adviser.email === value.value))}
          disabled={availableAdvisers.length <= 1 || isFetchingAdvisers}
          options={availableAdvisers.map(user => ({ label: user.username, value: user.email }))}
        />
      </div>
    </Modal>
  );
};

ComparisonPreparerModal.propTypes = {
  onDownload: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  defaultProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default ComparisonPreparerModal;
