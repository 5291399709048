import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ApplicationTitleActions from './ApplicationTitleActions';
import { hideApplication } from '../redux';
import { hasEditPermissionSelectorRegardlessOfSettlement } from '../redux/selectors/permissions';

export default connect(state => ({
  hasEditPermission: hasEditPermissionSelectorRegardlessOfSettlement(state),
  isHiddenOnSwimlane: state.application.swimlaneInfo.isHidden ?? false,
}), dispatch => bindActionCreators({
  hideApplication,
}, dispatch))(ApplicationTitleActions);
