import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/components/Icon/Icon';
import { FieldArray } from 'redux-form';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import isEmpty from 'lodash/isEmpty';
import ThirdPartySection from './ThirdPartySection';
import styles from './ThirdParty.module.scss';

const validateValues = (values) => (!values || values.filter(item => isEmpty(item)).length > 0
    ? 'Should not have empty third party' : undefined);

const renderAddButton = ({ fields }) => (
  <SecondaryButton
    className={styles.addButton}
    onClick={() => fields.push({})}
  >
    <span>Add a third party</span><Icon name="add" size="18" />
  </SecondaryButton>
);

const ThirdParties = ({ hasEditPermission }) => (
  <div>
    <ThirdPartySection
      hasEditPermission={hasEditPermission}
      name="thirdParties"
      isSearchedThirdParty={false}
      validateValues={validateValues}
    />
    {hasEditPermission && (
      <div className={styles.addButtonWrapper}>
        <FieldArray name="thirdParties" component={renderAddButton} validate={validateValues} />
      </div>
    )}
  </div>
  );

ThirdParties.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

export default ThirdParties;
