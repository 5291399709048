import { ACTIONS } from './productsActions';
import {
  initialState,
  processGetAllProductsError,
  processGetAllProductsLoading,
  processGetAllProductsSuccess,
  processResetRepaymentsFrequency,
  processSaveSearchCriteria,
  processSetRepaymentFrequency,
  processSetTotalCostTerm,
} from './products';
import { registerBeforeFunction } from '../../../store/middleware/sideEffectMiddleware';
import { trackCustomEvent } from '../../../tealium/Tracker';
import {
  changeRepaymentFrequencyV100,
  changeTotalCostTermV100,
  productSearchV100,
} from '../../../tealium/TrackingDataBuilder';
import { REPAYMENT_FREQUENCIES } from '../../../constants';

registerBeforeFunction(ACTIONS.GET_ALL_PRODUCTS_LOADING, (store) => {
  trackCustomEvent(productSearchV100(store.getState()));
});

registerBeforeFunction(ACTIONS.SET_TOTAL_COST_TERM, (store, { totalCostTerm }) => {
  trackCustomEvent(changeTotalCostTermV100(totalCostTerm, store.getState()));
});

registerBeforeFunction(ACTIONS.SET_REPAYMENT_FREQUENCY, (store, { repaymentFrequency }) => {
  trackCustomEvent(
    changeRepaymentFrequencyV100(REPAYMENT_FREQUENCIES[repaymentFrequency], store.getState()),
  );
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_TOTAL_COST_TERM: {
      return processSetTotalCostTerm(state, action);
    }
    case ACTIONS.SET_REPAYMENT_FREQUENCY: {
      return processSetRepaymentFrequency(state, action);
    }
    case ACTIONS.RESET_REPAYMENTS_FREQUENCY: {
      return processResetRepaymentsFrequency(state);
    }
    case ACTIONS.GET_ALL_PRODUCTS_LOADING: {
      return processGetAllProductsLoading(action, state);
    }
    case ACTIONS.GET_ALL_PRODUCTS_SUCCESS: {
      return processGetAllProductsSuccess(action, state);
    }
    case ACTIONS.GET_ALL_PRODUCTS_ERROR: {
      return processGetAllProductsError(state);
    }
    case ACTIONS.SAVE_SEARCH_CRITERIA: {
      return processSaveSearchCriteria(state, action);
    }
    default:
      return state;
  }
};
