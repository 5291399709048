import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import uniqueId from 'lodash/uniqueId';
import isUndefined from 'lodash/isUndefined';
import cx from 'classnames';
import styles from './RadioGroup.module.scss';

const RadioGroup = ({
 options, value, className, name, readOnly, disabled, onChange,
}) => {
  const ids = useMemo(() => range(options.length).map(() => uniqueId('radio_group_option_')), [options]);
  return (
    <div className={cx(styles.container, className)}>
      {options.map((option, index) => (
        <React.Fragment key={option.label}>
          <input
            type="radio"
            name={name}
            id={ids[index]}
            className={styles.input}
            onChange={onChange}
            checked={isUndefined(value) ? value : value === option.value}
            value={option.value}
            disabled={disabled || readOnly}
          />
          <label
            htmlFor={ids[index]}
            className={styles.item}
          >
            {option.label}
          </label>
        </React.Fragment>
        ))}
    </div>
  );
};

RadioGroup.defaultProps = {
  value: undefined,
  className: undefined,
  disabled: false,
  readOnly: false,
};

RadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default RadioGroup;
