import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'shared/components/formFields/Select';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import DownloadExcelButton from 'shared/components/DownloadExcelButton';
import styles from './styles.module.scss';
import { ConnectedSubmissionReport } from './components/SubmissionReport';
import { ConnectedSettlementReport } from './components/SettlementReport';
import { ConnectedExpectedSettlementReport } from './components/ExpectedSettlementReport';
import { ConnectedConversionReport } from './components/ConversionReport';
import { useHasPermission } from '../../shared/components/authorisation/hasPermissionTo';
import { ConnectedSummaryReport } from './components/SummaryReport';
import DataCalculateDescription from './components/DataCalculateDescription/DataCalculateDescription';

const cx = classNames.bind(styles);

const calculateMonthRanges = (startMoment) => {
  const ranges = [];
  let end = moment()
    .endOf('month')
    .startOf('day');
  let start;
  do {
    start = end.clone()
      .subtract(1, 'year')
      .add(1, 'day');
    ranges.push({
      label: `${start.format('MMM YYYY')} - ${end.format('MMM YYYY')}`,
      value: {
        start,
        end,
      },
    });
    end = start.clone()
      .subtract(1, 'day');
  }
  while (startMoment !== null && start.isAfter(startMoment));
  return ranges;
};

const monthRangeSelectorStyles = {
  menu: provided => ({
    ...provided,
    backgroundColor: 'white',
    zIndex: 1061,
  }),
};

export const ReportsPage = React.memo(({
                                         isLenderReconciliationReportDownloading,
                                         downloadLenderReconciliationReport,
                                         isInternallyReferredAssetFinanceReportDownloading,
                                         downloadInternallyReferredAssetFinanceReport,
                                         businessId,
                                         reportingMeta,
                                         anyLoading,
                                         retrieveLoanViewsForSubmissionAndSettlementReport,
                                         retrieveReportingMeta,
                                         retrieveLoanViewsForExpectedSettlementReport,
                                         retrieveCommissionSummary,
                                         retrieveLoanViewsForSettlementSummaryReport,
                                         retrieveLoanViewsForConversionRateReport,
                                       }) => {
  const earliestDate = useMemo(() => get(reportingMeta, 'earliestDate', null), [reportingMeta]);
  const monthRanges = useMemo(() => calculateMonthRanges(earliestDate), [earliestDate]);
  const [selectedRange, setSelectedRange] = useState(undefined);
  const selectedRangeLabel = get(selectedRange, 'label', '');
  const isAllowedViewCommissions = useHasPermission(PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS);

  // retrieve reporting meta when businessId changed
  useEffect(() => {
    retrieveReportingMeta(businessId);
  }, [businessId, retrieveReportingMeta]);

  // change selected range when month ranges changed
  useEffect(() => {
    if (!isEmpty(monthRanges)) {
      setSelectedRange(monthRanges[0]);
    }
  }, [monthRanges, setSelectedRange]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectedRange) {
      const { start, end } = selectedRange.value;
      retrieveLoanViewsForSubmissionAndSettlementReport(businessId, start, end);
    }
  }, [businessId, retrieveLoanViewsForSubmissionAndSettlementReport, selectedRangeLabel]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    retrieveLoanViewsForConversionRateReport(businessId);
  }, [businessId, retrieveLoanViewsForConversionRateReport]);

 /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    retrieveLoanViewsForSettlementSummaryReport(businessId);
  }, [businessId, retrieveLoanViewsForSettlementSummaryReport]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isAllowedViewCommissions) {
      retrieveCommissionSummary(businessId);
    }
  }, [businessId, isAllowedViewCommissions]);

  useEffect(() => {
    retrieveLoanViewsForExpectedSettlementReport(businessId);
  }, [businessId, retrieveLoanViewsForExpectedSettlementReport]);

  return (
    <>
      <GroupOfficeBusinessSelector
        key={businessId}
        className={cx({ businessSelector: true })}
      >
        <section className={cx({ buttonContainer: true })}>
          <DownloadExcelButton
            name="Internally Referred Asset Finance"
            downloadReport={downloadInternallyReferredAssetFinanceReport}
            disabled={isInternallyReferredAssetFinanceReportDownloading}
            allowedPermissions={[PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL]}
          />
          <DownloadExcelButton
            name="Lender Reconciliation"
            downloadReport={downloadLenderReconciliationReport}
            disabled={isLenderReconciliationReportDownloading}
            allowedPermissions={[PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL]}
          />
        </section>
      </GroupOfficeBusinessSelector>
      <div className={cx({ page: true })}>

        <div className={cx({ header: true })}>
          <h1>Reports</h1>
        </div>

        <div className={cx({ reportSectionContainer: true })}>
          <ConnectedSummaryReport />
        </div>

        <div className={cx({ header: true })}>
          <Select
            disabled={anyLoading}
            className={cx({ monthRangeSelector: true })}
            label="Show data for"
            styles={monthRangeSelectorStyles}
            options={monthRanges}
            value={selectedRange}
            onChange={setSelectedRange}
          />
        </div>

        <div className={cx({ reportSectionContainer: true })}>
          <ConnectedSubmissionReport />
        </div>

        <div className={cx({ reportSectionContainer: true })}>
          <ConnectedSettlementReport />
        </div>

        <hr style={{ backgroundColor: '#979797' }} />

        <div className={cx({ reportSectionContainer: true })}>
          <ConnectedConversionReport />
        </div>

        <div className={cx({ reportSectionContainer: true })}>
          <ConnectedExpectedSettlementReport className={cx({ expectedSettlementReport: true })} />
        </div>

        <DataCalculateDescription />
      </div>
    </>
  );
});

ReportsPage.propTypes = {
  businessId: PropTypes.string.isRequired,
  retrieveLoanViewsForSubmissionAndSettlementReport: PropTypes.func.isRequired,
  retrieveLoanViewsForExpectedSettlementReport: PropTypes.func.isRequired,
  retrieveReportingMeta: PropTypes.func.isRequired,
  reportingMeta: PropTypes.shape({
    earliestDate: PropTypes.object,
  }).isRequired,
  anyLoading: PropTypes.bool.isRequired,
  isLenderReconciliationReportDownloading: PropTypes.bool.isRequired,
  downloadLenderReconciliationReport: PropTypes.func.isRequired,
  isInternallyReferredAssetFinanceReportDownloading: PropTypes.bool.isRequired,
  downloadInternallyReferredAssetFinanceReport: PropTypes.func.isRequired,
  retrieveCommissionSummary: PropTypes.func.isRequired,
  retrieveLoanViewsForSettlementSummaryReport: PropTypes.func.isRequired,
  retrieveLoanViewsForConversionRateReport: PropTypes.func.isRequired,
};
