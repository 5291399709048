import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { applicationStatusSchema, getApplicationActionTimeLineMap } from 'ApplicationTracking/constants/graphQLSchema';
import { MESSAGE_KEYS } from '../../../../constants/message';

const retrieveApplications = (businessId, conditions = {}) => {
  const applicantName = conditions.applicant || '';
  const lenderName = conditions.lender || '';
  const applicationNumber = conditions.applicationNumber || '';
  return client.query({
    query: gql`query (
      $applicantName: String!
      $lenderName: String!
      $applicationNumber: String!,
    ){
      applicationsSearch(businessId: "${businessId}",
        applicantName: $applicantName,
        lenderName: $lenderName,
        applicationNumber: $applicationNumber,
        adviserId: "${conditions.adviser || ''}",
      )
      {
        id
        applicationNumber
        source
        applicants{
          persons{
            name
            fullName
            abbreviateName
            isPrimary
            applicantUniqueKey
          }
          companies{
            name
            fullName
            abbreviateName
            isPrimary
            applicantUniqueKey
          }
        }
        ${applicationStatusSchema}
        totalLoanAmount
        adviser{
          firstName
          surname
          name
          id
        }
        lender{
          name
          lenderId
        }
        applicationUpdateCount
        isOwnerOccupied
        notesLink
        expectedSettlementDate
        preApprovalExpiryDate
        financeDueDate
        settledDate
      }
    }`,
    variables: {
      applicantName,
      lenderName,
      applicationNumber,
    },
  });
};

const updateApplicationTimeline = (
  {
    applicationId, milestoneType, actionDateTime, statusDateTime, reason,
  },
) => client.mutate({
  mutation: gql`mutation{
    updateApplicationTimeline(
     applicationId: "${applicationId}"
     updateInfo: {
       milestoneType: "${milestoneType}"
       actionDateTime: "${actionDateTime}"
       statusDateTime: "${statusDateTime}"
       reason: "${reason}"
     }
    )
    {
      status
    }
  }`,
}, { messageKey: MESSAGE_KEYS.APPLICATION_STATUS_UPDATE });

const updateDueDateTime = ({ applicationId, dueDateTime, dueDateKey }) => client.mutate({
  mutation: gql`mutation{
    updateDueDateTime(
     applicationId: "${applicationId}"
     updateInfo: {
       dueDateTime: "${dueDateTime}"
       dueDateKey: "${dueDateKey}"
     }
    )
    {
      dueDateTime
    }
  }`,
}, { messageKey: MESSAGE_KEYS.APPLICATION_STATUS_UPDATE });

const validateApplicationSettlement = applicationId => client.query({
  query: gql`{
    validateSettlement(applicationId: "${applicationId}")
  }`,
}, { messageKey: MESSAGE_KEYS.APPLICATION_STATUS_UPDATE });

const createApplication = ({
                             businessId,
                             adviserId,
                             loanType,
                             applicants,
                             borrowingGroupId,
                           }) => client.mutate({

  mutation: gql`mutation($businessId: String!, $adviserId: String!, $personalApplicantIds: [String], $companyApplicantIds: [String], $loanType: String!){
      createApplication(
          input: {
              businessId: $businessId
              adviserId: $adviserId
              loanType: $loanType
              applicants: {
                personIds: $personalApplicantIds
                companyIds: $companyApplicantIds
              }
          }
      )
  }`,
  variables: {
    businessId,
    adviserId,
    loanType,
    personalApplicantIds: (applicants.personIds || []).filter(Boolean),
    companyApplicantIds: (applicants.companyIds || []).filter(Boolean),
    borrowingGroupId,
  },
}, { messageKey: MESSAGE_KEYS.CREATE_APPLICATION });

const copyApplication = (applicationId) => client.mutate({
  mutation: gql`mutation ($applicationId: String!) {
    copyApplication(applicationId: $applicationId)
  }`,
  variables: {
    applicationId,
  },
}, { messageKey: MESSAGE_KEYS.COPY_APPLICATION });

const getAllClientsByBusinessId = (businessId) => client.query({
  query: gql`
    {
      clients(businessId: "${businessId}") {
        persons {
          id,
          firstName,
          surname,
          email
        }
        companies {
          id,
          companyDescription,
          companyName,
          trustName
        }
      }
    }
  `,
}, { messageKey: MESSAGE_KEYS.GET_ALL_CLIENTS });

const getApplicationActionTimeMap = (applicationId) => client.query({
  query: gql`{
      application(applicationId: "${applicationId}")
      ${getApplicationActionTimeLineMap()}
    }`,
}, { messageKey: MESSAGE_KEYS.APPLICATION_ACTION_TIMELINE_MAP });

const hideApplication = (applicationId, isHiddenOnSwimlane) => client.mutate({
  mutation: gql`mutation ($applicationId: String!, $data: SwimlaneInput!) {
    updateSwimlaneInfo(applicationId: $applicationId, data: $data)
  }`,
  variables: {
    applicationId,
    data: {
      isHidden: isHiddenOnSwimlane,
    },
  },
}, { messageKey: MESSAGE_KEYS.HIDE_APPLICATION_ON_SWIMLANE });

export default {
  retrieveApplications,
  updateApplicationTimeline,
  updateDueDateTime,
  validateApplicationSettlement,
  createApplication,
  copyApplication,
  getAllClientsByBusinessId,
  getApplicationActionTimeMap,
  hideApplication,
};
