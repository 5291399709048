import moment from 'moment';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import map from 'lodash/map';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export const toMoment = str => moment(str || null);

// if a loan is already settled then we use settled date as expected settlement date
export const loanFormatter = loan => ({
  ...loan,
  submittedDate: toMoment(loan.submittedDate),
  settledDate: toMoment(loan.settledDate),
  expectedSettlementDate: toMoment(loan.settledDate || loan.expectedSettlementDate),
});

export const generateContextFromLoansAndState = (loans, { lenderColorConfig }) => {
  const lendersFromLoans = mapValues(mapKeys(loans, 'lenderName'), loan => ({
    id: loan.lenderId,
    name: loan.lenderName,
  }));
  const lendersFromState = mapValues(mapKeys(lenderColorConfig, 'lenderName'), config => ({
    id: config.lenderId,
    color: config.hexColor,
  }));
  const mergedLenders = mapValues(lendersFromLoans, lender => ({
    ...lender,
    ...get(lendersFromState, lender.name, {}),
  }));
  return ({
    advisers: mapValues(mapKeys(loans, 'adviserId'), loan => ({
      id: loan.adviserId,
      name: loan.adviserName,
    })),
    lenders: mergedLenders,
  });
};

export const sum = nums => nums.reduce((res, curr) => {
    if (isNil(res) && isNil(curr)) {
      return null;
    }
    return res + curr;
  }, null);

export const sumBy = (items, mapper) => sum(map(items, mapper));
