import { useMemo, useRef } from 'react';
import isFunction from 'lodash/isFunction';

export const usePromise = (executorCreator, deps) => {
  // eslint-disable-next-line
  const executor = useMemo(executorCreator, deps);
  const context = useRef();

  const wrappedResolve = useMemo(() => async (...args) => {
    const { undo, hook } = context.current ?? {};
    if (isFunction(undo)) {
      await undo(true, ...args);
    }
    if (!isFunction(hook?.resolve)) {
      throw new Error('promise not created yet');
    }
    hook.resolve(...args);
  }, []);

  const wrappedReject = useMemo(() => async (...args) => {
    const { undo, hook } = context.current ?? {};
    if (isFunction(undo)) {
      await undo(false, ...args);
    }
    if (!isFunction(hook?.reject)) {
      throw new Error('promise not created yet');
    }
    hook.reject(...args);
  }, []);

  const promiseCreator = useMemo(() => (...args) => new Promise((re, rj) => {
    context.current = {
      undo: executor(...args),
      hook: { resolve: re, reject: rj },
    };
  }), [executor]);
  return [promiseCreator, wrappedResolve, wrappedReject];
};
