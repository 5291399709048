import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './styles';
import getEditingInputPartial from './getEditingInputPartial';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { iSet } from '../../../utils';
import { calculateVariantUpfrontFee } from '../../../productUtils';

const ComparisonEditingModal = React.memo(({
  isEditing,
  isLoading,
  currentModification,
  style,
  onRequestClose,
  onApplyModification,
  hasError,
  isEditable,
}) => {
  const [modificationState, setModificationState] = useState(currentModification);
  useEffect(() => {
    setModificationState(currentModification);
  }, [currentModification]);

  const handleModificationChanged = useCallback((field, value) => {
    const updatedModificationState = iSet(modificationState, ['variant', field], value);
    const recalculatedModificationState = iSet(
      updatedModificationState,
      ['variant', 'upfrontFee'],
      calculateVariantUpfrontFee(updatedModificationState.variant),
    );
    setModificationState(recalculatedModificationState);
  }, [setModificationState, modificationState]);

  const handleApply = useCallback(() => {
    onApplyModification(modificationState);
  }, [onApplyModification, modificationState]);

  const applyButtonOrLoadingSpinner = isLoading
    ? (<LoadingSpinner style={styles.loading} />)
    : (
      <PrimaryButton
        style={styles.applyButton}
        onClick={handleApply}
        disabled={!isEditable}
      >
        Apply
      </PrimaryButton>
    );

  return (
    <Modal
      isOpen={isEditing}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      style={{ ...styles.editModalStyle, ...style }}
      ariaHideApp={false}
    >
      <div style={styles.editingContentContainer}>
        <div style={styles.editingContentTitle}>
          Edit field
        </div>
        <button
          onClick={onRequestClose}
          type="button"
          style={styles.crossButton}
        >
          &#x2715;
        </button>
      </div>
      {getEditingInputPartial(modificationState, handleModificationChanged, isEditable)}
      <div style={styles.editingButtonsSection}>
        {hasError && (
          <p style={styles.errorMessage}>
            Please input valid number
          </p>
        )}
        {applyButtonOrLoadingSpinner}
      </div>
    </Modal>
  );
});

ComparisonEditingModal.propTypes = {
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentModification: PropTypes.shape({
    variant: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    optionIndex: PropTypes.number.isRequired,
    splitIndex: PropTypes.number.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onApplyModification: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isEditable: PropTypes.bool,
};

ComparisonEditingModal.defaultProps = {
  isEditing: false,
  isLoading: false,
  currentModification: {
    variant: {},
    fieldName: '',
    optionIndex: 0,
    splitIndex: 0,
  },
  style: {},
  hasError: false,
  isEditable: true,
};

export default ComparisonEditingModal;
