import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import classNames from 'classnames';
import DateBox from 'devextreme-react/date-box';
import PropTypes from 'prop-types';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import { isBeforeThan, toUTCStr } from 'utils/datetime';
import orderBy from 'lodash/orderBy';
import LoanDetails from './LoanRequestDetails';
import styles from './ConfirmSettlementPopup.module.scss';
import { applicationStatus } from '../../constants/applicationStatus';
import { generateLoanRequestName, validateIgnoringSpaces } from '../../utils';
import { generateErrorMessage, getAlias as getAccountNumberAlias } from '../../../utils/accountNumber';

const submitButtonStyle = {
  height: '3rem',
  minWidth: '13rem',
};

const invalidSettlementDateMessage = 'Please select a more recent date and time. It cannot be before the time and date of the previous status.';

const isValid = (value, regex) => {
  const trimmed = value && value.trim();
  return Boolean(!isEmpty(trimmed) && validateIgnoringSpaces(regex, trimmed));
};

const ConfirmSettlementPopup = ({
                                  onSubmit, onClose, actionTimelineMap, applicants, isStatusUpdating, applicationId,
                                  lenderId, loanRequests, lendersConfig, updateLoanRequests,
                                }) => {
  const { config: { lenderConfigList, defaultLenderValidationConfig } } = lendersConfig;

  const lenderConfig = useMemo(() =>
    lenderConfigList.find(config => config.lenderId === lenderId) || defaultLenderValidationConfig,
    [lenderConfigList, lenderId, defaultLenderValidationConfig]);

  const { regex } = lenderConfig;
  const alias = useMemo(() => getAccountNumberAlias(lenderConfig), [lenderConfig]);
  const errorMessage = useMemo(() => generateErrorMessage(lenderConfig), [lenderConfig]);

  const [state, setState] = useState({
    dateTimeErrorMessage: null,
    settlementDateTime: toUTCStr(new Date()),
    toBeUpdatedLoanRequests: [],
  });
  const [isSaving, setSaving] = useState(false);

  useEffect(
    () => setState(prev => ({
        ...prev,
        toBeUpdatedLoanRequests: orderBy(loanRequests, 'createdAt').map((loanRequest, index) => ({
          id: loanRequest.id,
          loanRequestName: generateLoanRequestName(index, loanRequest.productName),
          accountNumber: {
            value: loanRequest.accountNumber || '',
            valid: isValid(loanRequest.accountNumber, regex),
            showError: false,
          },
          isUpfrontExpected: isNil(loanRequest.isUpfrontExpected) ? true : loanRequest.isUpfrontExpected,
          isTrailExpected: isNil(loanRequest.isTrailExpected) ? true : loanRequest.isTrailExpected,
        })),
      })), [loanRequests, regex],
  );

  const getMostRecentDateTime = changedSettledDateTime => reduce(
    actionTimelineMap,
    ((mostRecentTime, item) => {
      const currentTime = get(item, 'statusDateTime', '');
      return isBeforeThan(mostRecentTime, currentTime) ? currentTime : mostRecentTime;
    }),
    changedSettledDateTime,
  );

  const validateSettlementDateTime = (dateTime, onSuccess) => {
    const mostRecentStatusTime = getMostRecentDateTime(dateTime);
    if (dateTime === mostRecentStatusTime) {
      setState(prev => ({ ...prev, dateTimeErrorMessage: null }));
      onSuccess();
    } else {
      setState(prev => ({ ...prev, dateTimeErrorMessage: invalidSettlementDateMessage }));
    }
  };

  const handleDateValueChange = ({ value }) => {
    if (isNil(value)) { setState(prev => ({ ...prev, dateTimeErrorMessage: invalidSettlementDateMessage })); return; }
    const changedSettledDateTime = toUTCStr(value);
    validateSettlementDateTime(
      changedSettledDateTime,
      () => setState(prev => ({ ...prev, settlementDateTime: changedSettledDateTime })),
    );
  };

  const handleLoanRequestChange = id => (changedValue) => {
    setState(prev => ({
      ...prev,
      toBeUpdatedLoanRequests: prev.toBeUpdatedLoanRequests.map((toBeUpdatedLoanRequest) => {
        if (toBeUpdatedLoanRequest.id !== id) return toBeUpdatedLoanRequest;
        if (!changedValue.accountNumber) return ({ ...toBeUpdatedLoanRequest, ...changedValue });
        const valid = isValid(changedValue.accountNumber.value, regex);
        return ({
          ...toBeUpdatedLoanRequest,
          ...changedValue,
          accountNumber: {
            value: changedValue.accountNumber.value,
            valid,
            showError: !valid && toBeUpdatedLoanRequest.accountNumber.showError,
          },
        });
      }),
    }));
  };

  const handleSubmit = () => {
    const { toBeUpdatedLoanRequests, settlementDateTime } = state;
    const allValid = toBeUpdatedLoanRequests.every(item => item.accountNumber.valid);
    if (allValid) {
      validateSettlementDateTime(settlementDateTime, () => {
        setSaving(true);
        const loanRequestsUpdates = toBeUpdatedLoanRequests.map(
          ({
             id,
             loanRequestName,
             accountNumber: { value: accountNumberValue },
             isUpfrontExpected,
             isTrailExpected,
           }) => ({
            id,
            accountNumber: trim(accountNumberValue),
            loanRequestName,
            isUpfrontExpected,
            isTrailExpected,
          }),
        );
        const updateLoanPromise = isEmpty(toBeUpdatedLoanRequests)
          ? Promise.resolve() : updateLoanRequests(applicationId, loanRequestsUpdates);

        updateLoanPromise.then(() => onSubmit({
          statusDateTime: settlementDateTime,
          milestoneType: applicationStatus.SETTLED,
        })).finally(() => setSaving(false));
      });
    } else {
      setState(prev => ({
        ...prev,
        toBeUpdatedLoanRequests: prev.toBeUpdatedLoanRequests.map(update => ({
          ...update,
          accountNumber: { ...update.accountNumber, showError: !update.accountNumber.valid },
        })),
      }));
    }
  };

  const submitButtonProps = useMemo(() => ({
    style: submitButtonStyle,
    disabled: !isEmpty(state.dateTimeErrorMessage)
      || isEmpty(state.settlementDateTime)
      || isStatusUpdating
      || isSaving,
  }), [state.dateTimeErrorMessage, state.settlementDateTime, isStatusUpdating, isSaving]);

  return (
    <ModalWithButtons
      isOpen
      header="Confirm settlement"
      submitText="Settle"
      className={styles.popupWrapper}
      onRequestClose={onClose}
      submitButtonProps={{ ...submitButtonProps, onClick: handleSubmit }}
    >
      <div className={styles.modal}>
        <div className={styles.applicationName}>
          {`Application for ${applicants.map(item => item.name).join('; ')}`}
        </div>
        <div className={styles.dateTimeSection}>
          <div className={styles.sectionTitle}>Confirm the settlement date</div>
          <div
            className={classNames(styles.dateBoxWrapper, {
              [styles.errorDateBox]: !isEmpty(state.dateTimeErrorMessage),
            })}
          >
            <DateBox
              width="27rem"
              defaultValue={state.settlementDateTime}
              displayFormat="d MMMM yyyy h:mm a"
              valueChangeEvent="blur"
              type="datetime"
              applyButtonText="Save"
              placeholder="Select a date and a time"
              useMaskBehavior
              onValueChanged={handleDateValueChange}
            />
          </div>
          <div className={styles.errorMessage}>{state.dateTimeErrorMessage}</div>
        </div>
        <div className={styles.loanSection}>
          {
            !lendersConfig.isLoading && !lendersConfig.hasError
            && state.toBeUpdatedLoanRequests.map((tobeUpdatedLoanRequest) => {
              const { id, accountNumber: { showError } } = tobeUpdatedLoanRequest;
              return (
                <LoanDetails
                  key={id}
                  loanRequest={tobeUpdatedLoanRequest}
                  alias={alias}
                  errorMessage={showError ? errorMessage : null}
                  onLoanRequestChange={handleLoanRequestChange(tobeUpdatedLoanRequest.id)}
                />
              );
            })
          }
        </div>
      </div>
    </ModalWithButtons>
  );
};

ConfirmSettlementPopup.defaultProps = {
  onSubmit: () => null,
  onClose: () => null,
};

ConfirmSettlementPopup.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  actionTimelineMap: PropTypes.object.isRequired,
  applicants: PropTypes.array.isRequired,
  loanRequests: PropTypes.array.isRequired,
  isStatusUpdating: PropTypes.bool.isRequired,
  applicationId: PropTypes.string.isRequired,
  lenderId: PropTypes.string.isRequired,
  lendersConfig: PropTypes.shape({
    config: PropTypes.shape({
      lenderConfigList: PropTypes.arrayOf(PropTypes.shape({
        lenderId: PropTypes.string.isRequired,
        loanIdentifierCalledAs: PropTypes.string,
        regex: PropTypes.string.isRequired,
        maxLength: PropTypes.string.isRequired,
        minLength: PropTypes.string.isRequired,
      })).isRequired,
      defaultLenderValidationConfig: PropTypes.shape({
        regex: PropTypes.string.isRequired,
        maxLength: PropTypes.string.isRequired,
        minLength: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    hasError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  updateLoanRequests: PropTypes.func.isRequired,
};

export default ConfirmSettlementPopup;
