import React from 'react';
import HtmlParser from 'react-html-parser';
import Modal from 'shared/components/Modal';
import classNames from 'classnames';
import Tab from 'shared/components/Tab';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import { toRatePercentage, toDollarAmount } from 'shared/formatterUtils';

import styles from './ServiceabilityDetailsModal.module.scss';

const getProductKey = (product, index) => `${index} - ${product.productName}`;

const formatDollars = value => (isNil(value) ? null : toDollarAmount(value));

const ServiceabilityDetailsModal = ({
                                      lenderName,
                                      isOpen,
                                      onRequestClose,
                                      serviceability,
                                      loanAmount,
                                      serviceabilityDetails,
}) => {
  const fields = [
    {
      title: 'UMI',
      content: formatDollars(serviceability.umi),
      showSideBar: false,
    },
    {
      title: 'TOTAL LVR',
      content: toRatePercentage(serviceability.totalLVR, ''),
      showSideBar: false,
    },
    {
      title: 'TOTAL SECURITY VALUE',
      content: formatDollars(serviceability.totalSecurityValue),
      showSideBar: false,
    },
    {
      title: 'LOAN AMOUNT',
      content: formatDollars(loanAmount),
      showSideBar: false,
    },
    {
      title: 'BORROWING CAPACITY',
      content: formatDollars(serviceability.borrowingCapacity),
      showSideBar: false,
    }];

  const { products } = serviceability;
  const showOngoingFee = filter(products, product => !isNil(product.ongoingFee)).length > 0;
  const overview = (
    <div className={styles.contentWrapper}>
      <FieldsContainer fields={fields} className={styles.fieldWrapper} />
      <div className={styles.productsWrapper}>
        {map(products, (product, index) => (
          <InformationSection
            title={product.productName}
            titleWrapperStyle={styles.productTitle}
            wrapperStyle={styles.productWrapper}
            key={getProductKey(product, index)}
          >
            <div className={styles.productDetail}>
              Assessment rate <span>{toRatePercentage(product.assessmentRate, '')}</span>
            </div>
            <div className={styles.productDetail}>
              Assessment<br />repayment (monthly) <span>{formatDollars(product.assessmentRepayment)}</span>
            </div>
            {showOngoingFee
              ? (
                <div className={styles.productDetail}>
                  Ongoing fees <span>{formatDollars(product.ongoingFee)}</span>
                </div>
              )
              : null}
          </InformationSection>
        ))}
      </div>
    </div>
  );

  const renderAdditionalValues = metrics => (
    <div className={classNames(styles.additionalValue,
      { [styles.notInmostAdditionalValue]: !isEmpty(metrics.metrics) })}
    >
      {
        metrics.additionalValues.map(additionalValue => (
          <div className={styles.additionalValueItem} key={additionalValue.type}>
            <span>{capitalize(lowerCase(additionalValue.type.split('-')[0]))}</span>
            <span>{additionalValue.value}</span>
          </div>
        ))
      }
    </div>
  );

  const renderMetricHeader = metric => (
    <div className={styles.metricHeader}>
      <div className={styles.metricHeaderContent}>
        <span className={styles.metricHeaderDisplayName}>{metric.displayName}</span>
        <span className={styles.metricHeaderDisplayValue}>{metric.displayValue}</span>
      </div>
      <p className={styles.description}>{HtmlParser(metric.description)}</p>
    </div>
  );

  const renderTable = metric => (
    (
      <div className={styles.detailTable}>
        <div className={styles.tableWrapper}>
          <table className={classNames('table')}>
            {HtmlParser(metric.detailTable)}
          </table>
        </div>
      </div>
    )
  );

  const renderMetricLists = (metricLists) => metricLists.map(item => (
    <div key={item.id} className={classNames(styles.metric, { [styles.inmostMetric]: isEmpty(item.metrics) })}>
      <ShowMore
        extended={false}
        showMoreClass={styles.showMore}
        name={renderMetricHeader(item)}
        headerClassName={styles.metricHeaderWrapper}
      >
        {
                  item.additionalValues && renderAdditionalValues(item)
                }
        {
                  item.detailTable && renderTable(item)
                }
        {
                  !isEmpty(item.metrics) && <div className={styles.metrics}>{renderMetricLists(item.metrics)}</div>
                }
      </ShowMore>
    </div>
          ));

  const details = (
    <div className={styles.detailWrapper}>
      {
        !isEmpty(serviceabilityDetails)
        && serviceabilityDetails?.metricLists.map(item => (
          <React.Fragment key={item.lender}>
            {
              renderMetricLists(item.metrics)
            }
          </React.Fragment>
        ))
      }
    </div>
  );

  return (
    <Modal
      header={`${lenderName} serviceability details`}
      footer={(
        <PrimaryButton
          onClick={onRequestClose}
          className={styles.button}
        >
          Close
        </PrimaryButton>
      )}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="LARGE"
      className={styles.modal}
    >
      <div className={styles.serviceAbilityContent}>
        <Tab
          tabs={[
                {
                  key: '1',
                  title: 'Overview',
                  content: overview,
                },
                {
                  key: '2',
                  title: 'Details',
                  content: details,
                },
              ]}
        />
      </div>
    </Modal>
  );
};

ServiceabilityDetailsModal.defaultProps = {
  isOpen: false,
  loanAmount: null,
  lenderName: undefined,
  serviceabilityDetails: {},
};

ServiceabilityDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  lenderName: PropTypes.string,
  serviceability: PropTypes.shape({
    totalLVR: PropTypes.number,
    borrowingCapacity: PropTypes.number,
    umi: PropTypes.number,
    totalSecurityValue: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      productName: PropTypes.string,
      assessmentRate: PropTypes.number,
      assessmentRepayment: PropTypes.number,
      ongoingFee: PropTypes.number,
      })),
  }).isRequired,
  loanAmount: PropTypes.number,
  serviceabilityDetails: PropTypes.shape({
    metricLists: PropTypes.arrayOf(PropTypes.shape({
      lender: PropTypes.string,
      metrics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pid: PropTypes.string,
        code: PropTypes.string,
        displayName: PropTypes.string,
        value: PropTypes.string,
        displayValue: PropTypes.string,
        identifier: PropTypes.string,
        type: PropTypes.string,
        description: PropTypes.string,
        dependentMetrics: PropTypes.arrayOf(PropTypes.shape({
          code: PropTypes.string,
          identifier: PropTypes.string,
        })),
        additionalValues: PropTypes.arrayOf(PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
          relatedTo: PropTypes.string,
          valueItems: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.string,
            relatedTo: PropTypes.string,
          })),
        })),
        detailTable: PropTypes.string,
        validationResults: PropTypes.arrayOf(PropTypes.shape({
          validationID: PropTypes.string,
          severity: PropTypes.string,
          description: PropTypes.string,
          suggestedAction: PropTypes.string,
          helpText: PropTypes.string,
          controlID: PropTypes.string,
        })),
      })),
    })),
  }),
};

export default ServiceabilityDetailsModal;
