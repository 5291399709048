/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RetryComponent from 'shared/components/RetryComponent';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.warn('Above error caught by Error Boundary. Showing fallback UI');
    const { onCatchingError } = this.props;
    onCatchingError(error, { info });
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) return fallback;

    return children;
  }
}

ErrorBoundary.defaultProps = {
  fallback: <RetryComponent />,
  onCatchingError: () => {},
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
    .isRequired,
  fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCatchingError: PropTypes.func,
};

export default ErrorBoundary;
