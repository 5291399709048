import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import style from './style';
import inputComponentStyles from './InputComponent.module.scss';

const MAX_INTEREST_ONLY_TERM = 10;
const isValidInterestOnlyTerm = term =>
  (term === undefined || (term > 0 && term <= MAX_INTEREST_ONLY_TERM));

const InterestOnlyTermComponent = (props) => {
  const {
    repaymentType,
    interestOnlyTerm,
    interestOnlyTermError,
    dispatchUpdateInterestOnlyTerm,
    dispatchValidateInterestOnlyTerm,
  } = props;
  return (
    <NumberInput
      label="Interest only term (1-10 yrs)"
      value={interestOnlyTerm}
      textClassName={classNames(inputComponentStyles.inputText, inputComponentStyles.short)}
      onChange={dispatchUpdateInterestOnlyTerm}
      onBlur={dispatchValidateInterestOnlyTerm}
      errorMessage={interestOnlyTermError}
      disabled={repaymentType !== 'Interest Only'}
      isAllowed={isValidInterestOnlyTerm}
      style={style}
    />
  );
};

InterestOnlyTermComponent.defaultProps = {
  interestOnlyTerm: undefined,
  repaymentType: undefined,
  interestOnlyTermError: undefined,
};

InterestOnlyTermComponent.propTypes = {
  dispatchUpdateInterestOnlyTerm: PropTypes.func.isRequired,
  dispatchValidateInterestOnlyTerm: PropTypes.func.isRequired,
  interestOnlyTermError: PropTypes.string,
  interestOnlyTerm: PropTypes.number,
  repaymentType: PropTypes.string,
};

export default InterestOnlyTermComponent;
