import React, { useEffect } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isValid } from 'redux-form';
import { calculateFees, calculateFundingPositions, getNormalizedFundingWorksheetFormData } from '../../../redux';

const formValuesSelector = getFormValues('fundingWorksheet');
const isValidSelector = isValid('fundingWorksheet');

const FundingPositionsCalculationTrigger = React.memo(({
                                                         applicationId,
                                                         dispatchCalculateFundingPositions,
                                                         valid,
                                                         ...formValues
                                                       }) => {
  useEffect(() => {
    if (valid) {
      dispatchCalculateFundingPositions(applicationId, getNormalizedFundingWorksheetFormData(formValues));
    }
  }, [applicationId, dispatchCalculateFundingPositions, formValues, valid]);
  return null;
});

FundingPositionsCalculationTrigger.propTypes = {
  applicationId: PropTypes.string.isRequired,
  dispatchCalculateFundingPositions: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

const FeesCalculationTrigger = React.memo(({
                                                         applicationId,
                                                         dispatchCalculateFees,
                                                         valid,
                                                         ...formValues
                                                       }) => {
  useEffect(() => {
    if (valid) {
      dispatchCalculateFees(applicationId, getNormalizedFundingWorksheetFormData(formValues));
    }
  }, [applicationId, dispatchCalculateFees, formValues, valid]);
  return null;
});

FeesCalculationTrigger.propTypes = {
  applicationId: PropTypes.string.isRequired,
  dispatchCalculateFees: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

const CalculationTrigger = ({
  formValues,
  dispatchCalculateFundingPositions,
  dispatchCalculateFees,
  applicationId,
  valid,
}) => (
  <>
    <FundingPositionsCalculationTrigger
      applicationId={applicationId}
      dispatchCalculateFundingPositions={dispatchCalculateFundingPositions}
      valid={valid}
      {...formValues}
      somaComments={undefined}
    />
    <FeesCalculationTrigger
      applicationId={applicationId}
      dispatchCalculateFees={dispatchCalculateFees}
      valid={valid}
      {...formValues}
      somaComments={undefined}
    />
  </>
);

const mapStateToProps = state => ({
  applicationId: get(state, 'application.applicationDetail.id'),
  formValues: formValuesSelector(state),
  valid: isValidSelector(state),
});

const mapDispatchToProps = dispatch => {
  const dispatchCalculateFundingPositions = (applicationId, fundingWorksheet) =>
    dispatch(calculateFundingPositions(applicationId, fundingWorksheet));
  const dispatchCalculateFees = (applicationId, fundingWorksheet) =>
    dispatch(calculateFees(applicationId, fundingWorksheet));

  const debouncedDispatchCalculateFundingPositions = debounce(dispatchCalculateFundingPositions, 500);
  const debouncedDispatchCalculateFees = debounce(dispatchCalculateFees, 500);

  return {
    dispatchCalculateFundingPositions: debouncedDispatchCalculateFundingPositions,
    dispatchCalculateFees: debouncedDispatchCalculateFees,
  };
};

CalculationTrigger.propTypes = {
  formValues: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  dispatchCalculateFundingPositions: PropTypes.func.isRequired,
  dispatchCalculateFees: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationTrigger);
