import { createSelector } from '@reduxjs/toolkit';
import { TRANSACTION_TYPES } from '../../constants';
import { getAssets } from '../assets';

export const getSecurity = createSelector(
  getAssets,
  (assets) => ({
    purchasingAssets: assets?.realEstates
      ?.filter(item => item.transactionType === TRANSACTION_TYPES.PURCHASING || !!item.isForConstructionLoan),
    ownedSecurity: assets?.realEstates?.filter(({ transactionType, asSecurity, isForConstructionLoan }) => (
      transactionType === TRANSACTION_TYPES.OWNS && asSecurity === true && isForConstructionLoan !== true)),
  }),
);
