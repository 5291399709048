import PropTypes from 'prop-types';

export default PropTypes.shape({
  lenderName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  id: PropTypes.string,
  standardRate: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  discountMonths: PropTypes.number,
  firstPeriodRepayment: PropTypes.number,
  firstPeriodPrincipalAndInterest: PropTypes.number,
  firstPeriodInterestOnly: PropTypes.number,
  upfrontFee: PropTypes.number,
  ongoingFee: PropTypes.number,
  ongoingFeeFrequency: PropTypes.string,
  minLoanValueRatio: PropTypes.number,
  maxLoanValueRatio: PropTypes.number,
  minLoanAmount: PropTypes.number,
  maxLoanAmount: PropTypes.number,
  repaymentTypes: PropTypes.arrayOf(PropTypes.string),
  repaymentAvailable: PropTypes.bool,
});
