import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { FETCHING_STATUS } from '../../../../constants';
import { buildFilter } from '../../../utils';
import { showToast } from '../../../../redux/toast/actions';
import { removeFixedTrails } from './removeFixedTrailGraphQL';

export const actions = {
  REMOVE_FIXED_TRAIL_START: 'REMOVE_FIXED_TRAIL_START',
  REMOVE_FIXED_TRAIL_SUCCESS: 'REMOVE_FIXED_TRAIL_SUCCESS',
  REMOVE_FIXED_TRAIL_ERROR: 'REMOVE_FIXED_TRAIL_ERROR',
};

export const handleRemoveFixedTrail = () => async (dispatch, getState) => {
  dispatch({ type: actions.REMOVE_FIXED_TRAIL_START });
  try {
    const businessId = get(getState(), 'business.selectedBusiness.id');
    const filters = get(getState(), 'loans.loans.remoteFilters');
    const totalCount = get(getState(), 'loans.loans.totalRowCount');

    await removeFixedTrails(
      businessId, buildFilter(filters), totalCount,
    );
    dispatch(showToast('Fixed trails have been removed from all selected loan.', { type: 'success' }));
    dispatch({
      type: actions.REMOVE_FIXED_TRAIL_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: actions.REMOVE_FIXED_TRAIL_ERROR });
    throw error;
  }
};

const initialState = {
  updatingStatus: FETCHING_STATUS.INIT,
};

export default createReducer(
  initialState, {
    [actions.REMOVE_FIXED_TRAIL_START]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.START,
    }),
    [actions.REMOVE_FIXED_TRAIL_ERROR]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.ERROR,
    }),
    [actions.REMOVE_FIXED_TRAIL_SUCCESS]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.SUCCESS,
    }),
  },
);
