import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { components } from 'react-select';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import styles from './styles.module.scss';
import { ApplicantSelector } from './ApplicantSelector';

const { Option, SingleValue } = components;

const OptionValue = (props) => {
  const { data: { firstName, surname } } = props;
  return (
    <SingleValue {...props}>
      <span>{surname}, {firstName}</span>
    </SingleValue>
  );
};

OptionValue.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
};

const OptionLabel = (props) => {
  const {
    data: {
      firstName, surname, email, hidden,
    },
  } = props;
  return hidden ? null : (
    <Option {...props}>
      <span>{surname}, {firstName}</span>
      <br />
      <span className={styles.email}>{email}</span>
    </Option>
  );
};

OptionLabel.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    hidden: PropTypes.bool,
  }).isRequired,
};

const readOnlyFormatter = (v) => `${get(v, 'surname')}, ${get(v, 'firstName')}`;

const getLabel = (surname, firstName, email) => `${surname}, ${firstName} ${email || ''}`;

const getValue = (options, value) => find(options, { value }) || null;

const getOptions = (clients, formatOption) => {
  if (isArray(clients)) {
    const results = clients.map(({
                                   id, firstName, surname, email,
                                 }) => ({
      value: id,
      label: getLabel(surname, firstName, email),
      firstName,
      surname,
      email,
      isDisabled: true,
    }));
    return map(sortBy(results, ['label']), formatOption);
  }
  return clients;
};

const PersonApplicantSelector = (props) => {
  const { selectedApplicant, clients, formatOption } = props;
  const options = useMemo(
    () => {
      const originalOptions = getOptions(clients, formatOption);
      return selectedApplicant && selectedApplicant.displayInfo && !getValue(originalOptions, selectedApplicant.id)
        ? [...originalOptions,
          {
            ...selectedApplicant.displayInfo,
            value: selectedApplicant.id,
            label: getLabel(selectedApplicant.displayInfo.surname, selectedApplicant.displayInfo.firstName),
            hidden: true,
            isDisabled: true,
          }]
        : originalOptions;
    }, [clients, formatOption, selectedApplicant],
  );

  const value = useMemo(() => getValue(options, selectedApplicant?.id), [selectedApplicant, options]);

  return (
    <ApplicantSelector
      OptionLabel={OptionLabel}
      OptionValue={OptionValue}
      readOnlyFormatter={readOnlyFormatter}
      options={options}
      selectedValue={value}
      {...props}
    />
  );
};

PersonApplicantSelector.propTypes = {
  selectedApplicant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayInfo: PropTypes.shape({
      firstName: PropTypes.string,
      surname: PropTypes.string,
    }),
  }),
  formatOption: PropTypes.func,
  clients: PropTypes.array.isRequired,
};

PersonApplicantSelector.defaultProps = {
  selectedApplicant: undefined,
  formatOption: undefined,
};

const mapStateToProps = state => ({
  clients: state.applications.clients?.persons ?? [],
  fetchingStatus: state.applications.clientsFetchingStatus,
});

export default connect(
  mapStateToProps,
)(PersonApplicantSelector);
