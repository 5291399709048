import React from 'react';
import { Field, Fields } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import range from 'lodash/range';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import SelectWithReadonly from 'shared/components/formFields/Select/WithReadOnlySelect';
import ReduxTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import styles from './Employment.module.scss';
import { frequencyOptions } from './constants';
import dateUtil from './dateUtil';

export const EmploymentIncomeTypeField = ({
 fieldNamePrefix, options, isSelfEmployed, clearIncomeField,
}) => (
  <Field
    name={`${fieldNamePrefix}.type`}
    className={classNames(styles.fieldWrapper, styles.atFirstColumnOnMobile, styles.atFirstColumnOnDesktop)}
    label="Income type"
    component={ReduxFormSelect}
    options={options}
    onChange={() => {
        if (isSelfEmployed) {
          clearIncomeField(fieldNamePrefix, 'value');
          clearIncomeField(fieldNamePrefix, 'previousValue');
        }
      }}
  />
  );

const Addback = (fields) => {
  const [typeField, addbackField] = fields.names.map(name => get(fields, name));
  const type = typeField.input.value;
  return (
    <>
      {type === 'Addback' && (
        <ReduxFormSelect
          className={fields.className}
          label="Addback type"
          options={fields.options}
          {...addbackField}
        />
      )}
    </>
  );
};

const GovernmentBenefits = ({ options, ...fields }) => {
  const [typeField, governmentBenefitsField] = fields.names.map(name => get(fields, name));
  const type = typeField.input.value;
  return (
    <>
      {type === 'GovernmentBenefits' && (
        <ReduxFormSelect
          className={classNames(styles.fieldWrapper, styles.atFirstColumnOnMobile, styles.atFirstColumnOnDesktop)}
          label="Government benefits type"
          options={options}
          {...governmentBenefitsField}
        />
      )}
    </>
  );
};

GovernmentBenefits.propTypes = {
  options: PropTypes.array.isRequired,
};

export const AddbackTypeField = ({ fieldNamePrefix, className, options }) => (
  <Fields
    names={[`${fieldNamePrefix}.type`, `${fieldNamePrefix}.addbackType`]}
    component={Addback}
    props={{
      className: className
        || classNames(styles.fieldWrapper, styles.atFirstColumnOnMobile, styles.atFirstColumnOnDesktop),
      options,
    }}
  />
);

export const GovernmentBenefitsField = ({ fieldNamePrefix, options }) => (
  <Fields
    names={[`${fieldNamePrefix}.type`, `${fieldNamePrefix}.governmentBenefitsType`]}
    component={GovernmentBenefits}
    options={options}
  />
);

export const FrequencyField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.periodUnit`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
    label="Frequency"
    component={ReduxFormSelect}
    options={frequencyOptions}
  />
);

export const ValueField = ({ fieldNamePrefix, allowNegative }) => (
  <Field
    name={`${fieldNamePrefix}.value`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.valueItem)}
    textClassName={styles.valueItemText}
    label="Income"
    component={ReduxFormNumberInput}
    decimalScale={2}
    placeholder="$"
    prefix="$"
    allowNegative={allowNegative}
  />
);

export const PreviousValueField = ({ fieldNamePrefix, allowNegative }) => (
  <Field
    name={`${fieldNamePrefix}.previousValue`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.valueItem)}
    textClassName={styles.valueItemText}
    label="Income"
    component={ReduxFormNumberInput}
    decimalScale={2}
    placeholder="$"
    prefix="$"
    allowNegative={allowNegative}
  />
);

export const DetailsField = ({ fieldNamePrefix, className }) => (
  <Field
    name={`${fieldNamePrefix}.description`}
    className={className
      || classNames(styles.fieldWrapper, styles.atThirdColumnOnDesktop, styles.atThirdColumnOnTablet)}
    label="Details"
    component={ReduxTextInput}
    isAllowed={value => value === undefined || value.length <= 50}
  />
);

const generateFinancialYearOption = yearNumber => ({
  value: yearNumber,
  label: `${`${yearNumber}`.slice(-2)}/${`${yearNumber + 1}`.slice(-2)}`,
});

const generateFinancialYearOptions = (currentYear, optionNumber, existingValue) => {
  const availableYears = range(optionNumber).map(diff => currentYear - diff);
  const options = availableYears.map(generateFinancialYearOption);
  return existingValue > 0 && availableYears.includes(existingValue)
  ? options
  : [...options, { ...generateFinancialYearOption(existingValue), isDisabled: true }];
};

const FinancialYearSelect = (financialYearField) => {
  const financialYearOptions = generateFinancialYearOptions(
    dateUtil.getCurrentFinancialYear(),
    5,
    financialYearField.input.value,
  );
  const previousFinancialYearOption = financialYearField.input.value
    ? generateFinancialYearOption(financialYearField.input.value - 1)
    : null;

  return (
    <>
      <SelectWithReadonly
        className={classNames(
          styles.fieldWrapper,
          styles.financialYearField,
        )}
        label="Financial year"
        options={financialYearOptions}
        value={financialYearField.input.value
          && financialYearOptions.find(option => option.value === financialYearField.input.value)}
        onChange={({ value }) => financialYearField.input.onChange(value)}
      />
      <SelectWithReadonly
        disabled
        className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop,
          financialYearField.financialYearClassName)}
        label="Financial year"
        options={[previousFinancialYearOption].filter(Boolean)}
        value={previousFinancialYearOption}
      />
    </>
  );
};

export const FinancialYearFields = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.financialYear`}
    component={FinancialYearSelect}
  />
);

const basePropType = {
  fieldNamePrefix: PropTypes.string.isRequired,
};
EmploymentIncomeTypeField.propTypes = {
  ...basePropType,
  options: PropTypes.array.isRequired,
  clearIncomeField: PropTypes.func.isRequired,
};
AddbackTypeField.propTypes = {
  ...basePropType,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
};

AddbackTypeField.defaultProps = {
  className: '',
};

GovernmentBenefitsField.propTypes = {
  ...basePropType,
  options: PropTypes.array.isRequired,
};
FrequencyField.propTypes = basePropType;

ValueField.propTypes = {
  ...basePropType,
  allowNegative: PropTypes.bool,
};
ValueField.defaultProps = {
  allowNegative: false,
};
PreviousValueField.propTypes = {
  ...basePropType,
  allowNegative: PropTypes.bool,
};

PreviousValueField.defaultProps = {
  allowNegative: false,
};

DetailsField.propTypes = {
  ...basePropType,
  className: PropTypes.string,
};

DetailsField.defaultProps = {
  className: '',
};

FinancialYearFields.propTypes = basePropType;
