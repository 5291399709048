import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { Field, Fields } from 'redux-form';
import React, { useMemo } from 'react';
import ReduxFormTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import classNames from 'classnames';
import styles from './Person.module.scss';
import { getCountryNameByCode } from '../../../../../../utils/address';
import { toDateWithSlash } from '../../../../../../../utils/datetime';
import { formatResidencyStatus } from '../utils/formatter';
import SpouseField from './SpouseField';

const SpouseNameTextInputField = (field) => {
  const [
    isNonApplicantFieldName,
    spouseNameFieldName,
  ] = field.names;
  const isNonApplicant = get(field, `${isNonApplicantFieldName}.input.value`, false);
  if (!isNonApplicant) {
    return null;
  }
  const spouseNameField = get(field, `${spouseNameFieldName}`);
  return (
    <ReduxFormTextInput {...field} {...spouseNameField} />
  );
};
const GeneralSection = ({ fieldNamePrefix, persons }) => {
  const idsAsStr = map(persons, 'id').join(',');
  const applicantInfos = useMemo(
    () => map(persons, it => pick(it, ['surname', 'firstName', 'id'])).filter(({ id }) => !isEmpty(id)),
    [persons],
  );

  return (
    <FieldsContainer
      title="General"
      className={styles.sectionWrapper}
      fieldsWrapperClassName={styles.overrideSectionWrapper}
    >
      <Field
        name={`${fieldNamePrefix}.title`}
        className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop,
                  styles.atSecondColumnOnDesktop])}
        component={PlainTextField}
        label="Title"
      />
      <Field
        name={`${fieldNamePrefix}.firstName`}
        className={styles.fieldWrapper}
        component={PlainTextField}
        label="First name"
      />
      <Field
        name={`${fieldNamePrefix}.otherName`}
        className={styles.fieldWrapper}
        component={PlainTextField}
        label="Middle name(s)"
      />
      <Field
        name={`${fieldNamePrefix}.surname`}
        className={styles.fieldWrapper}
        component={PlainTextField}
        label="Surname"
      />
      <Field
        name={`${fieldNamePrefix}.dateOfBirth`}
        className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
        component={PlainTextField}
        label="Date of birth"
        format={value => (value ? toDateWithSlash(value) : '')}
      />
      <Field
        name={`${fieldNamePrefix}.gender`}
        className={classNames([styles.fieldWrapper,
                  styles.singleColumnWrapperOnDesktop, styles.atSecondColumnOnDesktop])}
        component={PlainTextField}
        label="Gender"
      />
      <Field
        name={`${fieldNamePrefix}.maritalStatus.status`}
        className={styles.fieldWrapper}
        component={PlainTextField}
        label="Marital status"
      />
      <Fields
        names={[`${fieldNamePrefix}.maritalStatus.status`, `${fieldNamePrefix}.maritalStatus.spouse`,
                  `${fieldNamePrefix}.id`]}
        component={SpouseField}
        className={classNames([styles.fieldWrapper, styles.atFifthColumnOnDesktop, styles.input])}
        label="Spouse"
        applicantIds={idsAsStr}
        personalApplicants={applicantInfos}
        isClearable
      />
      <Field
        name={`${fieldNamePrefix}.residency.country`}
        className={classNames([styles.fieldWrapper, styles.atSeventhColumnOnDesktop])}
        component={PlainTextField}
        label="Country of residence"
        format={getCountryNameByCode}
      />
      <Field
        name={`${fieldNamePrefix}.residency.status`}
        className={styles.fieldWrapper}
        component={PlainTextField}
        label="Residency status"
        format={formatResidencyStatus}
      />
      <Fields
        names={[`${fieldNamePrefix}.maritalStatus.spouse.isNonApplicant`, `${fieldNamePrefix}.maritalStatus.spouse.firstName`]}
        className={classNames([
                  styles.fieldWrapper,
                  styles.atFifthColumnOnDesktop,
                  styles.singleColumnWrapperOnDesktop,
                  styles.atFifthRowOnMobile,
                  styles.spouseNameInput,
                  styles.input,
                ])}
        component={SpouseNameTextInputField}
        label="Spouse first name"
      />
      <Fields
        names={[`${fieldNamePrefix}.maritalStatus.spouse.isNonApplicant`, `${fieldNamePrefix}.maritalStatus.spouse.surname`]}
        className={classNames([
                  styles.fieldWrapper,
                  styles.atSixthColumnOnDesktop,
                  styles.singleColumnWrapperOnDesktop,
                  styles.atFifthRowOnMobile,
                  styles.spouseNameInput,
                  styles.input,
                ])}
        component={SpouseNameTextInputField}
        label="Spouse surname"
      />
      <Field
        name={`${fieldNamePrefix}.citizenship.country`}
        className={classNames([styles.fieldWrapper, styles.atSeventhColumnOnDesktop])}
        component={PlainTextField}
        label="Country of citizenship"
        format={getCountryNameByCode}
      />
      <Field
        name={`${fieldNamePrefix}.residency.permanentInAustralia`}
        className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
        component={PlainTextField}
        label="Permanent resident"
        format={value => (value ? 'Yes' : 'No')}
      />
    </FieldsContainer>

  );
};

GeneralSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  persons: PropTypes.array.isRequired,
};

export default GeneralSection;
