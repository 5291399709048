import React from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatters';
import styles from './styles.module.scss';

export const Tooltip = (props) => {
  const { targetItem: { point, series: name }, data, series } = props;
  const serie = find(series, { name });
  const { key } = serie;
  const item = data[point];
  const isPreviousYearSerie = key === 'previousYearTotalLoanAmount';
  return (
    <table className={styles.tooltipContent}>
      <tbody>
        <tr>
          <td>LOAN TOTALS</td>
          <td>{formatCurrency(
            (isPreviousYearSerie ? item.previousYearTotalLoanAmount : item.monthlyTotalLoanAmount)
            || 0,
          )}
          </td>
        </tr>
        <tr>
          <td>NO. OF APPLICATIONS</td>
          <td>{(isPreviousYearSerie ? item.previousYearApplicationsCount : item.applicationsCount) || 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

Tooltip.propTypes = {
  targetItem: PropTypes.shape({
    point: PropTypes.number,
    series: PropTypes.string,
  }).isRequired,
  data: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
};
