import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const applicationStatusSchema = `applicationStatus{
  status
  statusChangeTime
  actionTimelineMap{
    SETTLED{
      statusDateTime
    }
    DECLINED{
      statusDateTime
      reason
    }
    WITHDRAWN{
      statusDateTime
      reason
    }
    UNCONDITIONALLY_APPROVED{
      statusDateTime
    }
    CONDITIONALLY_APPROVED{
      statusDateTime
    }
    SUBMITTED{
      statusDateTime
    }
    DRAFT{
      statusDateTime
    }
    PRE_SUBMISSION{
     statusDateTime
    }
  }
  applyOnlineTimelineMap{
    SETTLED{
      statusDateTime
    }
    DECLINED{
      statusDateTime
    }
    WITHDRAWN{
      statusDateTime
    }
    UNCONDITIONALLY_APPROVED{
      statusDateTime
    }
    CONDITIONALLY_APPROVED{
      statusDateTime
    }
    SUBMITTED{
      statusDateTime
    }
    DRAFT{
      statusDateTime
    }
    PRE_SUBMISSION{
     statusDateTime
    }
  }
}`;
const applicationSearchSchema = `applicationList{
        id
        applicationNumber
        applicants{
          persons{
            name
            fullName
            abbreviateName
            isPrimary
            applicantUniqueKey
          }
          companies{
            name
            fullName
            abbreviateName
            isPrimary
            applicantUniqueKey
          }
        }
        ${applicationStatusSchema}
        totalLoanAmount
        adviser{
          firstName
          surname
          name
          id
        }
        lender{
          name
          lenderId
        }
        applicationUpdateCount
        isOwnerOccupied
        notesLink
        expectedSettlementDate
        settledDate
        expectedSettlementDate
        applicationCreateTime
        isPreApproval
        submittedDate
        conditionalApprovedDate
        unconditionalApprovedDate
      }`;
const adviserListSchema = `adviserList{
  firstName
  surname
  name
  id
}`;

const pageableQueryAllApplications = (conditions = {}) => {
  const {
    take, skip, filter, timeZoneId,
} = conditions;
  const businessId = conditions.businessId || '';
  return client.query({
    query: gql`query (
      $take: Int!,
      $skip: Int!,
      $filter: AllApplicationsFilter,
      $timeZoneId: String
    ){
      allApplicationsSearch(businessId: "${businessId}",
        skip: $skip,
        take: $take,
        filter: $filter,
        timeZoneId: $timeZoneId,
      )
      {
        ${applicationSearchSchema},
        totalCount,
        totalLoanAmount,
        ${adviserListSchema},
        lenderList,
      }
    }`,
    variables: {
      skip,
      take,
      filter,
      timeZoneId,
    },
  });
};

export {
  pageableQueryAllApplications,
};
