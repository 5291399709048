import React from 'react';
import PropTypes from 'prop-types';
import { emptyToNA, toDollarAmount, toPercentage } from 'shared/formatterUtils';
import getStyles from './styles';
import loanCriteriaFields from './loanCriteriaFields';
import { APPLICATION_TYPES, APPLICATION_TYPES_LABELS } from '../constants';

const SingleLoanCriteriaSummary = ({ applicationCriteria, loanCriteria, isTopUp }) => {
  const numOfItemsPerRow = !loanCriteria?.purposeCategory ? 3 : 4;
  const styles = getStyles(true, numOfItemsPerRow);
  const loanCriteriaStyle = styles.loanCriteriaSummary;
  const {
    getFeaturesField,
    getFixedLoanTermField,
    getInterestOnlyTermField,
    getCombinedLoanAmountField,
    getLoanAmountField,
    getLoanTermField,
    getProductTypeField,
    getPurposeField,
    getRepaymentTypeField,
    getPurposeCategoryField,
    getPurposeDetailsField,
    getRefinanceReasonsField,
  } = loanCriteriaFields(true);

  return (
    <div>
      <div style={loanCriteriaStyle.leftHalf}>
        {(applicationCriteria.applicationType) && (
          <div className="loan-criteria-summary__application-type" style={styles.criteriaField}>
            <div style={styles.criteriaField.label}>Application type</div>
            <div>{APPLICATION_TYPES_LABELS[applicationCriteria.applicationType]}</div>
          </div>
        )}
        {isTopUp && getLoanAmountField(loanCriteria)}
        <div />
        {isTopUp ? getCombinedLoanAmountField(loanCriteria) : getLoanAmountField(loanCriteria)}
        <div className="loan-criteria-summary__security-value" style={styles.criteriaField}>
          <div style={styles.criteriaField.label}>Total security value</div>
          <div>{emptyToNA(applicationCriteria.securityValue, toDollarAmount)}</div>
        </div>
        <div className="loan-criteria-summary__lvr" style={styles.criteriaField}>
          <div style={styles.criteriaField.label}>LVR</div>
          <div>{emptyToNA(applicationCriteria.loanValueRatio, toPercentage)}</div>
        </div>
        {getPurposeCategoryField(loanCriteria)}
        <div />
        {getPurposeField(loanCriteria)}
        {getProductTypeField(loanCriteria)}
        {getRepaymentTypeField(loanCriteria)}
        {getPurposeDetailsField(loanCriteria)}
        <div />
        {getLoanTermField(loanCriteria, isTopUp)}
        {getFixedLoanTermField(loanCriteria)}
        {getInterestOnlyTermField(loanCriteria)}
        {getRefinanceReasonsField(loanCriteria)}
      </div>
      {getFeaturesField(loanCriteria, loanCriteriaStyle.featuresField)}
    </div>
  );
};

SingleLoanCriteriaSummary.propTypes = {
  applicationCriteria: PropTypes.object.isRequired,
  loanCriteria: PropTypes.object.isRequired,
  isTopUp: PropTypes.bool.isRequired,
};

const SplitLoanCriteriaSummary = ({ loanCriteria, loanNum, isTopUp }) => {
  const styles = getStyles(false);
  const loanCriteriaStyles = styles.loanCriteriaSummary;
  const {
    getFeaturesField,
    getFixedLoanTermField,
    getInterestOnlyTermField,
    getCombinedLoanAmountField,
    getLoanAmountField,
    getLoanTermField,
    getProductTypeField,
    getPurposeField,
    getRepaymentTypeField,
    getPurposeCategoryField,
    getPurposeDetailsField,
    getRefinanceReasonsField,
  } = loanCriteriaFields(false);

  return (
    <div>
      <div style={loanCriteriaStyles.leftHalf}>
        <div className="loan-criteria-summary__label" style={styles.sectionLabel}>
          {`LOAN ${loanNum}`}
        </div>
        {isTopUp && (
          <>
            {getLoanAmountField(loanCriteria)}
            {getCombinedLoanAmountField(loanCriteria)}
            <div />
          </>
        )}
        {isTopUp ? <div style={styles.secondLineIndent} /> : getLoanAmountField(loanCriteria, isTopUp)}
        {getPurposeField(loanCriteria)}
        {getProductTypeField(loanCriteria)}
        {getRepaymentTypeField(loanCriteria)}
        <div />
        <div style={styles.secondLineIndent} />
        {getLoanTermField(loanCriteria, isTopUp)}
        {!isTopUp && <div style={styles.blankField} />}
        {getFixedLoanTermField(loanCriteria)}
        {getInterestOnlyTermField(loanCriteria)}
        <div />
        <div style={styles.secondLineIndent} />
        {getPurposeCategoryField(loanCriteria)}
        {getPurposeDetailsField(loanCriteria)}
        {getRefinanceReasonsField(loanCriteria)}
      </div>
      {getFeaturesField(loanCriteria, { ...loanCriteriaStyles.featuresField, marginLeft: '9.9rem' })}
    </div>
  );
};

SplitLoanCriteriaSummary.propTypes = {
  loanCriteria: PropTypes.object.isRequired,
  loanNum: PropTypes.number.isRequired,
  isTopUp: PropTypes.bool.isRequired,
};

const LoanCriteriaSummary = ({
 applicationCriteria, loanCriteria, loanNum, isSingleLoan,
}) => {
  const isTopUp = applicationCriteria.applicationType === APPLICATION_TYPES.TOP_UP;
  return (
    <>
      {isSingleLoan
        ? (
          <SingleLoanCriteriaSummary
            isTopUp={isTopUp}
            applicationCriteria={applicationCriteria}
            loanCriteria={loanCriteria}
          />
          )
        : (
          <SplitLoanCriteriaSummary
            isTopUp={isTopUp}
            loanCriteria={loanCriteria}
            loanNum={loanNum}
          />
        )}
    </>
  );
};

LoanCriteriaSummary.propTypes = {
  applicationCriteria: PropTypes.object.isRequired,
  loanCriteria: PropTypes.object.isRequired,
  loanNum: PropTypes.number.isRequired,
  isSingleLoan: PropTypes.bool,
};

LoanCriteriaSummary.defaultProps = {
  isSingleLoan: false,
};

export default LoanCriteriaSummary;
