import { createReducer } from '@reduxjs/toolkit';
import map from 'lodash/map';
import get from 'lodash/get';
import { calculateTotalCost, calculateTotalRepayment, calculateVariantUpfrontFee } from 'shared/productUtils';
import concat from 'lodash/concat';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { iSet } from 'shared/utils';
import { getDetailsForProducts, getVariantModificationFromApi } from 'shared/api';
import * as types from './types';
import { FETCHING_STATUS } from '../../../../../../constants';
import graphQL from '../../../redux/applicationDetailsGraphQL';
import { delayedUpdateActivityLogs } from '../../../redux';
import applicationDetailsTypes from '../../../redux/constants';
import getFormDataFromComparison from '../utils/getFormDataFromComparison';
import getEnrichedProducts from '../utils/getEnrichedProducts';
import {
  applyDetailsToVariantsCombinations,
  applyMockFeatureDetailsToCustomProducts,
} from '../utils/variantDetailsUtils';

export const compareProducts = ({ products, searchCriteria }) => ({
  type: types.COMPARE_PRODUCTS,
  products,
  searchCriteria,
});

export const updateComment = (comment) => ({
  type: types.UPDATE_COMMENT,
  comment,
});

export const initComparison = (initialValues) => ({
  type: types.INIT_COMPARISON,
  payload: initialValues,
});

export const resetComparison = () => ({
  type: types.RESET_COMPARISON,
});

export const updatePreferredOption = (optionId) => ({
  type: types.UPDATE_PREFERRED_OPTION,
  payload: optionId,
});

export const selectFeatureFromComparison = (featureName) => ({
  type: types.UPDATE_SELECTED_FEATURE_FROM_COMPARISON,
  payload: featureName,
});

export const updateSelectFeaturesFromComparison = (featureNames) => ({
  type: types.UPDATE_SELECTED_FEATURES_FROM_COMPARISON,
  payload: featureNames,
});

export const toggleIncludeCommentsInPrintout = (v) => ({
  type: types.TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT,
  payload: v,
});

export const mockFeatureDetailsForCustomVariants = (variantIds, featureDetails) => ({
  type: types.MOCK_FEATURE_DETAILS_FOR_CUSTOM_VARIANTS,
  variantIds,
  featureDetails,
});

export const getDetailsForVariants = (variantIds) => (disaptch) => {
  disaptch({ type: types.GET_VARIANTS_DETAILS_START });
  return getDetailsForProducts(variantIds).then((variantsDetails) => {
    disaptch({
      type: types.GET_VARIANTS_DETAILS_SUCCESS,
      variantsDetails,
    });
  }).catch(() => {
    disaptch({ type: types.GET_VARIANTS_DETAILS_FAIL });
  });
};

export const applyModification = (modification, { loansCriteria, totalCostTerm, repaymentFrequency }) => (dispatch) => {
  const {
    optionIndex,
    splitIndex,
    variant: {
      id,
      standardRate, discountRate, discountMonths,
      applicationFee, legalFee, valuationFee, upfrontFee, ongoingFee, ongoingFeeFrequency,
      firstPeriodRepayment, firstPeriodPrincipalAndInterest, firstPeriodInterestOnly,
      totalCost, comparisonRate, repaymentTypes, featureDetails, lmi,
    },
  } = modification;
  const {
    loanAmount, loanTerm, interestOnlyTerm, repaymentType,
  } = loansCriteria[splitIndex];
  const discountTermEnd = discountMonths === 'N/A' ? 0 : discountMonths;
  const variant = {
    id,
    standardRate,
    discountRate,
    discountTermStart: 0,
    discountTermEnd,
    applicationFee,
    legalFee,
    valuationFee,
    upfrontFee,
    ongoingFee,
    ongoingFeeFrequency,
    firstPeriodRepayment,
    firstPeriodPrincipalAndInterest,
    firstPeriodInterestOnly,
    totalCost,
    comparisonRate,
    repaymentTypes: repaymentTypes || [],
    featureNames: map(featureDetails, fd => fd.featureName),
    lmi,
  };
  const context = {
    totalCostTerm,
    loanAmount,
    interestOnlyTerm,
    repaymentType,
    loanTermYears: loanTerm,
    repaymentFrequency,
  };
  return getVariantModificationFromApi(variant, context)
    .then((calculatedVariant) => {
      const updatedVariant = {
        ...variant,
        ...calculatedVariant,
      };

      return {
        ...updatedVariant,
        upfrontFee: calculateVariantUpfrontFee(updatedVariant),
      };
    })
    .then((updatedVariant) => {
      dispatch({
        type: types.APPLY_VARIANT_MODIFICATION,
        updatedVariant,
        optionIndex,
        splitIndex,
      });
    });
};

export const updateComparison = (applicationId, currentComparison, lenderBrokerCodes) => async dispatch => {
  dispatch({ type: types.UPDATE_PRODUCT_COMPARISON_START });
  const formattedComparison = getFormDataFromComparison(currentComparison);

  try {
    const response = await graphQL.updateProductComparison(applicationId, formattedComparison);
    const { lender, totalLoanAmount, productComparisonId } = response.data.updateProductComparison;
    dispatch({
      type: types.UPDATE_PRODUCT_COMPARISON_SUCCESS,
      applicationId,
      data: currentComparison,
      lender,
      totalLoanAmount,
      lenderBrokerCode: find(lenderBrokerCodes, { lenderName: lender.name })?.value ?? '',
      productComparisonId,
      isOwnerOccupied: (get(formattedComparison, 'searchCriteria.LoansCriteria') || []).some(
        ({ purpose, purposeCategory }) =>
          purpose === 'Owner Occupied' && purposeCategory === 'Purchase',
      ),
    });
    dispatch({
      type: types.UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT,
      estimatedCommissionLoanAmount: get(formattedComparison, 'searchCriteria.applicationCriteria.totalLoanAmount'),
      applicationId,
    });
    await dispatch(delayedUpdateActivityLogs(applicationId));
  } catch {
    dispatch({ type: types.UPDATE_PRODUCT_COMPARISON_ERROR });
  }
};

const initialValues = null;

const productComparisonReducer = createReducer(initialValues, {
  [applicationDetailsTypes.SWITCH_APPLICATION]: () => initialValues,
  [types.COMPARE_PRODUCTS]: (state, { products, searchCriteria }) => ({
    searchCriteria,
    comment: '',
    includeCommentsInPrintout: false,
    selectedFeaturesFromComparison: [],
    variantsCombinations: getEnrichedProducts(products),
  }),
  [types.UPDATE_COMMENT]: (state, { comment }) => (iSet(state, 'comment', comment)),
  [types.INIT_COMPARISON]: (state, { payload }) => {
    if (isNil(payload)) {
      return null;
    }
    return {
      ...payload,
      variantsCombinations: getEnrichedProducts(payload.variantsCombinations),
    };
  },
  [types.UPDATE_PREFERRED_OPTION]: (state, { payload }) => ({
    ...state,
    variantsCombinations: state.variantsCombinations.map(v => (v.id === payload
      ? { ...v, preferred: true }
      : { ...v, preferred: false })),
  }),
  [types.UPDATE_SELECTED_FEATURE_FROM_COMPARISON]: (state, { payload: featureName }) => {
    const { selectedFeaturesFromComparison } = state;
    const alreadyInclude = selectedFeaturesFromComparison.includes(featureName);
    const updatedValue = alreadyInclude
      ? selectedFeaturesFromComparison.filter(it => it !== featureName)
      : concat(selectedFeaturesFromComparison, featureName);
    return ({
      ...state,
      selectedFeaturesFromComparison: updatedValue.sort(),
    });
  },
  [types.UPDATE_PRODUCT_COMPARISON_SUCCESS]: (state, { data, productComparisonId }) => ({
    ...data,
    id: productComparisonId,
  }),
  [types.UPDATE_SELECTED_FEATURES_FROM_COMPARISON]: (state, { payload }) => ({
    ...state,
    selectedFeaturesFromComparison: payload.sort(),
  }),
  [types.TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT]: (state, { payload }) => ({
    ...state,
    includeCommentsInPrintout: payload,
  }),
  [types.APPLY_VARIANT_MODIFICATION]: (state, { optionIndex, splitIndex, updatedVariant }) => {
    const { variantsCombinations } = state;
    const variant = get(variantsCombinations, [optionIndex, 'variants', splitIndex], {});
    const updatedOption = iSet(variantsCombinations[optionIndex], ['variants', splitIndex], {
      ...variant,
      ...updatedVariant,
    });
    const updatedVariantsCombinations = iSet(variantsCombinations, [optionIndex], {
      ...updatedOption,
      totalRepayment: calculateTotalRepayment(updatedOption.variants),
      totalCost: calculateTotalCost(updatedOption.variants),
    });

    return {
      ...state,
      variantsCombinations: updatedVariantsCombinations,
    };
  },
  [types.GET_VARIANTS_DETAILS_START]: (state) => ({
    ...state,
    variantsDetailFetchingStatus: FETCHING_STATUS.START,
  }),
  [types.GET_VARIANTS_DETAILS_SUCCESS]: (state, { variantsDetails }) => ({
    ...state,
    variantsDetailFetchingStatus: FETCHING_STATUS.SUCCESS,
    variantsCombinations: applyDetailsToVariantsCombinations(state.variantsCombinations, variantsDetails),
  }),
  [types.MOCK_FEATURE_DETAILS_FOR_CUSTOM_VARIANTS]: (state, { featureDetails }) => ({
    ...state,
    variantsCombinations: applyMockFeatureDetailsToCustomProducts(state.variantsCombinations, featureDetails),
  }),
  [types.GET_VARIANTS_DETAILS_FAIL]: (state) => ({
    ...state,
    variantsDetailFetchingStatus: FETCHING_STATUS.ERROR,
  }),
  [types.RESET_COMPARISON]: () => initialValues,
});

export const initialComparisonSelector = state => get(state, 'application.applicationDetail.productComparison');
export const stateSelector = state => get(state, 'application.productComparison');
export const productComparisonLoadingStatusSelector = (state) => {
  const applicationLoadingStatus = get(state, 'application.applicationFetchingStatus.fetchingStatus');
  const variantDetailsLoadingStatus = get(state, 'application.productComparison.variantsDetailFetchingStatus');

  return [applicationLoadingStatus, variantDetailsLoadingStatus].includes(FETCHING_STATUS.START);
};

export default productComparisonReducer;
