import React from 'react';
import Change from './Change';

const initialChange = {
  type: null,
  amount: null,
  periodOfImpact: null,
  startDate: null,
  repaymentPlans: null,
  repaymentPlanOtherDetail: null,
};

export default ({ fields }) =>
  fields.map((namePrefix, index) => (
    <Change
      namePrefix={namePrefix}
      key={namePrefix}
      showAddButton={fields.length === index + 1}
      showDeleteButton={fields.length > 1}
      onAdd={() => {
        fields.push(initialChange);
      }}
      onDelete={() => {
        fields.remove(index);
      }}
    />
  ));
