import PropTypes from 'prop-types';

export const LoansQueryShape = PropTypes.shape({
  businessId: PropTypes.string,
  skip: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    applicantsName: PropTypes.string,
    lenderName: PropTypes.string,
    accountNumber: PropTypes.string,
    adviserName: PropTypes.string,
  }),
});
