import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toDateStamp } from 'utils/datetime';
import classNames from 'classnames';
import DatePicker from '../../../formFields/DatePicker';
import styles from './style.module.scss';

export const DatePickerFilter = ({ onFilter, disabled }) => {
  const [pickedValue, setPickedValue] = useState(null);
  const onDateChange = (value) => {
    const dateValue = value ? toDateStamp(value) : undefined;
    setPickedValue(dateValue);
    onFilter({ value: dateValue });
  };
  return (
    <DatePicker
      type="localDate"
      onChange={onDateChange}
      value={pickedValue}
      hideIcon
      showClearButton
      placeholder="Filter..."
      mini
      max={moment().toDate()}
      className={classNames(styles.datePickerFilter)}
      disabled={disabled}
    />
  );
};

DatePickerFilter.defaultProps = {
  disabled: false,
};

DatePickerFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
