import { submitFeedbackV100, viewPageV100 } from '../tealium/TrackingDataBuilder';
import { registerAfterFunction, registerBeforeFunction } from '../store/middleware/sideEffectMiddleware';
import { trackCustomEvent, trackPageView } from '../tealium/Tracker';

export const ACTIONS = {
  VIEW_PAGE: 'TRACK:VIEW_PAGE',
  SUBMIT_FEEDBACK: 'TRACK:SUBMIT_FEEDBACK',
};

export const trackSubmitFeedback = () => ({ type: ACTIONS.SUBMIT_FEEDBACK });
export const trackViewPage = pageName => ({ type: ACTIONS.VIEW_PAGE, pageName });

function getPageName(state) {
  return state.tracking.pageName;
}

registerBeforeFunction(ACTIONS.SUBMIT_FEEDBACK, (store) => {
  const state = store.getState();
  const pageName = getPageName(state);
  trackCustomEvent(submitFeedbackV100(pageName, state));
});

registerAfterFunction(ACTIONS.VIEW_PAGE, (store) => {
  trackPageView(viewPageV100(store.getState()));
});

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.VIEW_PAGE: {
      return {
        ...state,
        pageName: action.pageName,
      };
    }
    default:
      return state;
  }
};
