const TOGGLES = {
  STYLEGUIDE_LINK: 'STYLEGUIDE_LINK',
  TEST_FEATURE: 'TEST_FEATURE',
  POPULATE_LOAN_DETAILS: 'POPULATE_LOAN_DETAILS',
  DISPLAY_ACCOUNT_NUMBER_VALIDATION_RULE: 'DISPLAY_ACCOUNT_NUMBER_VALIDATION_RULE',
  COMMERCIAL_LOAN: 'COMMERCIAL_LOAN',
  SMARTLINE_DECOMMISSION: 'SMARTLINE_DECOMMISSION',
  NO_SEND_TO_APPLYONLINE: 'NO_SEND_TO_APPLYONLINE',
  LOAN_DETAILS_DISPLAY_SUBLENDER: 'LOAN_DETAILS_DISPLAY_SUBLENDER',
  REMOVE_APPLYONLINE: 'REMOVE_APPLYONLINE',
};

export { default as getToggles } from './getToggles';

export default TOGGLES;
