import { EditField, USER_PERMISSIONS } from 'Loans/components/LoanDetails/EditField';
import FormsyTextInput from 'shared/components/formFields/Formsy/FormsyTextInput';
import PropTypes from 'prop-types';
import React from 'react';
import { generateErrorMessage } from '../../../../utils/accountNumber';

const AccountNumberInput = ({ accountNumber, accountNumberValidationRule, disabled }) => {
  const { regex, lenderId = '' } = accountNumberValidationRule;
  const errorMessage = generateErrorMessage(
    accountNumberValidationRule,
    );

  const validationKeyName = `matchTrimmedRegexp-${lenderId}`;

  return (
    <FormsyTextInput
      validations={{
        [validationKeyName]: (values, value) => (accountNumber !== value
          ? new RegExp(regex).test(value ? value.replace(/\s/g, '') : value)
          : true),
      }}
      validationErrors={{
        [validationKeyName]: errorMessage,
      }}
      name="accountNumber"
      value={accountNumber}
      fullWidth
      required
      disabled={disabled}
    />
  );
};

AccountNumberInput.defaultProps = {
  disabled: false,
};

AccountNumberInput.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  accountNumberValidationRule: PropTypes.shape({
    lenderId: PropTypes.string,
    loanIdentifierCalledAs: PropTypes.string,
    regex: PropTypes.string.isRequired,
    maxLength: PropTypes.string.isRequired,
    minLength: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

export const AccountNumberField = ({ accountNumber, accountNumberValidationRule, reconciled }) => (
  <EditField
    permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
    fallback={accountNumber}
  >
    {reconciled
      ? (
        <EditField
          permissions={USER_PERMISSIONS.GROUP_OFFICE}
          fallback={(
            <AccountNumberInput
              accountNumberValidationRule={accountNumberValidationRule}
              accountNumber={accountNumber}
              disabled
            />
          )}
        >
          <AccountNumberInput accountNumberValidationRule={accountNumberValidationRule} accountNumber={accountNumber} />
        </EditField>
      )
      : <AccountNumberInput accountNumberValidationRule={accountNumberValidationRule} accountNumber={accountNumber} />}
  </EditField>
);

AccountNumberField.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  reconciled: PropTypes.bool.isRequired,
  accountNumberValidationRule: PropTypes.shape({
    lenderId: PropTypes.string,
    loanIdentifierCalledAs: PropTypes.string,
    regex: PropTypes.string.isRequired,
    maxLength: PropTypes.string.isRequired,
    minLength: PropTypes.string.isRequired,
  }).isRequired,
};
