import React, { useContext } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Field, Fields } from 'redux-form';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import TextInput from 'shared/components/formFields/ReduxForm/TextInput';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import FormMetaContext from 'shared/components/FormMeta/FormMetaContext';
import { FREQUENCY_OPTIONS, GB_TYPE_OPTIONS, INCOME_TYPE_OPTIONS } from '../../constant';
import WrappedOwnerships from '../../../../shared/WrappedOwnerships';
import styles from '../Wrapper.module.scss';

const GovernmentBenefitsTypeField = fields => {
  const [typeName, gBTypeName] = fields.names;
  const typeField = get(fields, `${typeName}`);
  const gBTypeField = get(fields, `${gBTypeName}`);
  const isGBType = typeField.input.value === 'GovernmentBenefits';
  if (isGBType) {
    return (
      <ReduxFormSelect
        {...gBTypeField}
        className={styles.governmentBenefits}
        options={GB_TYPE_OPTIONS}
        label="Government benefits type"
      />
    );
  }
  return null;
};

const Incomes = ({ fields, hideDetails, incomeTypeField }) => {
  const { readOnly } = useContext(FormMetaContext);
  return (
    <>
      {fields.map((prefix, index) => (
        <div className={styles.container} key={fields.get(index).id || fields.get(index).tempId}>
          <div className={styles.fieldSection}>
            {incomeTypeField ?? (
              <Field
                name={`${prefix}.type`}
                className={styles.type}
                component={ReduxFormSelect}
                options={INCOME_TYPE_OPTIONS}
                label="Income type"
                menuPlacement="auto"
              />
            )}
            <Fields
              names={[`${prefix}.type`, `${prefix}.governmentBenefitsType`]}
              component={GovernmentBenefitsTypeField}
            />
            {!hideDetails && (
              <Field
                name={`${prefix}.description`}
                className={styles.details}
                component={TextInput}
                label="Details"
                maxLength={50}
              />
            )}
            <WrappedOwnerships fieldName={`${prefix}.ownerships`} rowSpan={3} />
            <Field
              name={`${prefix}.periodUnit`}
              className={styles.frequency}
              component={ReduxFormSelect}
              options={FREQUENCY_OPTIONS}
              label="Frequency"
            />
            <Field
              name={`${prefix}.value`}
              className={styles.income}
              textClassName={styles.incomeAmountText}
              component={ReduxFormNumberInput}
              label="Income"
              decimalScale={2}
              placeholder="$"
              prefix="$"
              allowNegative={false}
            />
          </div>
          {prefix?.startsWith('additionalIncomes.incomes')
              && !readOnly
              && (
                <div className={styles.buttonRow}>
                  <TertiaryButton
                    onClick={() => fields.remove(index)}
                    className={styles.removeButton}
                  >
                    Remove
                  </TertiaryButton>
                </div>
              )}
        </div>
      ))}
    </>
  );
};

Incomes.propTypes = {
  fields: PropTypes.object.isRequired,
  hideDetails: PropTypes.bool,
  incomeTypeField: PropTypes.node,
};

Incomes.defaultProps = {
  hideDetails: false,
  incomeTypeField: null,
};

export default Incomes;
