import { GET_SPECIAL_ACTIVITY_LOGS_SUCCESSFUL } from '../../action/activityLog';

const initialState = [];

export default (state = initialState, action) => {
  if (action.type === GET_SPECIAL_ACTIVITY_LOGS_SUCCESSFUL) {
    return [...action.data.specialActivityLogs];
  }
  return state;
};
