import { createReducer } from 'utils/reducerGenerator';
import * as types from '../actions';
import { FETCHING_STATUS } from '../../../../constants';

export const reducer = createReducer({
  fetchingStatus: FETCHING_STATUS.INIT,
}, {
  [types.DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_START]: () => ({
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_END]: () => ({
    fetchingStatus: FETCHING_STATUS.SUCCESS,
  }),
});
