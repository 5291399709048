import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldArrayPropTypes, Fields } from 'redux-form';
import trim from 'lodash/trim';
import get from 'lodash/get';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import Icon from 'shared/components/Icon/Icon';
import styles from './FieldArrayTupleInput.module.scss';

export const validateValues = (values) => {
  const validationResults = map(values, (value, index) => {
    const trimmedFieldName = trim(value.fieldName);
    if (values.slice(0, index)
      .some(v => trim(v.fieldName) === trimmedFieldName) && !isEmpty(trimmedFieldName)) {
      return {
        fieldName: 'Please create a unique description',
        fieldAmount: !isNumber(value.fieldAmount),
      };
    }

    const fieldNameError = isNumber(value.fieldAmount) && isEmpty(trimmedFieldName);

    const errors = {
      fieldName: fieldNameError,
      fieldAmount: !isEmpty(trimmedFieldName) && !isNumber(value.fieldAmount),
    };

    if (errors.fieldName || errors.fieldAmount) {
      return errors;
    }

    return null;
  });
  return validationResults.every(isNil)
    ? null
    : validationResults;
};

const TupleInput = ({
 namePrefix, error, index, descriptionLabel, descriptionPlaceholder, amountLabel, allowNegative, ...others
}) => {
 const { fieldName, fieldAmount } = get(others, namePrefix, {});
 return (
   <>
     <ReduxFormTextInput
       {...fieldName}
       errorMessage={fieldName.meta.touched && get(error, [index, 'fieldName'])}
       className={styles.field}
       type="text"
       label={descriptionLabel}
       placeholder={descriptionPlaceholder}
       isRequired={isNumber(fieldAmount.input.value) && isEmpty(fieldName.input.value)}
       maxLength="30"
     />
     <ReduxFormNumberInput
       {...fieldAmount}
       errorMessage={fieldAmount.meta.touched && get(error, [index, 'fieldAmount'])}
       className={classNames(styles.field, styles.amountField)}
       type="text"
       label={amountLabel}
       isRequired={!isEmpty(fieldName.input.value) && !isNumber(fieldAmount.input.value)}
       decimalScale={2}
       placeholder="$"
       prefix="$"
       allowNegative={allowNegative}
     />
   </>
 );
};

TupleInput.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  error: PropTypes.array,
  index: PropTypes.number.isRequired,
  descriptionLabel: PropTypes.string,
  descriptionPlaceholder: PropTypes.string,
  amountLabel: PropTypes.string,
  allowNegative: PropTypes.bool,
};

TupleInput.defaultProps = {
  error: undefined,
  descriptionLabel: 'Description',
  descriptionPlaceholder: '',
  amountLabel: 'Amount',
  allowNegative: true,
};

const ReduxFormFieldArrayTupleInput = ({ ...props }) => {
  const {
    fields,
    meta: { error },
    descriptionLabel,
    descriptionPlaceholder,
    amountLabel,
    allowNegative,
  } = props;

  return (
    <ul className={styles.listWrapper}>
      {fields.map((tuple, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={styles.list} key={`${tuple.fieldName}-${index}`}>
          <Fields
            names={[
              `${tuple}.fieldName`,
              `${tuple}.fieldAmount`,
            ]}
            index={index}
            error={error}
            component={TupleInput}
            namePrefix={tuple}
            descriptionLabel={descriptionLabel}
            descriptionPlaceholder={descriptionPlaceholder}
            amountLabel={amountLabel}
            allowNegative={allowNegative}
          />
          <NewInputLabelWrapper label={'\u00A0'} className={styles.buttonContainer}>
            <div className={styles.buttonGroup}>
              <div>
                { fields.length > 1 && (
                  <TertiaryButton className={styles.removeButton} onClick={() => fields.remove(index)}>
                    <Icon name="close" />
                  </TertiaryButton>
                )}
              </div>
              <div>
                { index === fields.length - 1 && (
                  <SecondaryButton className={styles.addButton} onClick={() => fields.push({})}>
                    <span>Add</span>
                    <Icon name="add" size="18" />
                  </SecondaryButton>
                )}
              </div>
            </div>
          </NewInputLabelWrapper>
        </li>
      ))}
    </ul>
  );
};

ReduxFormFieldArrayTupleInput.propTypes = fieldArrayPropTypes;

export default ReduxFormFieldArrayTupleInput;
