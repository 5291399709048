import { gql } from '@apollo/client';

export const GET_AUDIT_TRAILS = gql`
    query auditTrail($loanId: String!) {
        auditTrail(loanId: $loanId) {
            auditEvents {
                id
                user {
                    id
                    firstName
                    surname
                }
                actionTime
                type
                changes {
                    oldValue
                    newValue
                    fieldName
                }
            }
        }
    }
`;
