import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import {
  getRequestIdFromAction, ifValidRequestId,
  startRequest,
} from 'redux/requestIds';
import { COMMISSION_TYPES } from '../../../../constants';
import graphQL from '../commissionManagementReportsGraphQL';

export const ACTIONS = {
  GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START',
  GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_SUCCESS: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_SUCCESS',
  GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_ERRORS: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_ERRORS',

  GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_START: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_START',
  GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_SUCCESS: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_SUCCESS',
  GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_ERRORS: 'MY_BUSINESS:COMMISSION_MANAGEMENT:GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_ERRORS',
};

const getCommissionManagementReportsSummaryStart = () =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START });
const getCommissionManagementReportsSummarySuccess = commissionManagementReports =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_SUCCESS, commissionManagementReports });
const getCommissionManagementReportsSummaryErrors = errors =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_ERRORS, errors });

const getCommissionManagementReportDetailsStart = () =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_START });
const getCommissionManagementReportDetailsSuccess = issues =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_SUCCESS, issues });
const getCommissionManagementReportDetailsErrors = errors =>
  ({ type: ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_ERRORS, errors });

export const getCommissionManagementReportDetails = processingMonth => async (dispatch, getState) => {
  const businessId = get(getState(), 'business.selectedBusiness.id');
  if (!businessId) {
    return;
  }
  try {
    dispatch(getCommissionManagementReportDetailsStart());
    const results = await graphQL.getReportDetails(businessId, processingMonth);
    dispatch(getCommissionManagementReportDetailsSuccess(results.data.commissionManagementReports[0].issues));
  } catch (error) {
    dispatch(getCommissionManagementReportDetailsErrors(error));
  }
};

export const getCommissionManagementReportsSummary = () => async (dispatch, getState) => {
  const businessId = get(getState(), 'business.selectedBusiness.id');
  if (!businessId) {
    return;
  }

  dispatch(getCommissionManagementReportsSummaryStart());
  const startRequestAction = startRequest(ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const results = await graphQL.getReportsSummary(businessId);
    ifValidRequestId(getState().requestIds, ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START, requestId, () => {
      dispatch(getCommissionManagementReportsSummarySuccess(results.data.commissionManagementReports));
    });
  } catch (error) {
    ifValidRequestId(getState().requestIds, ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START, requestId, () => {
      dispatch(getCommissionManagementReportsSummaryErrors(error));
    });
  }
};

const sortIssuesDescWithNulls = path => (item1, item2) => {
  const value1 = get(item1, path);
  const value2 = get(item2, path);
  if (value1 === value2) {
    return 0;
  }
  return Number(value1) < Number(value2) ? 1 : -1;
};

const sortIssuesAscWithNulls = path => (item1, item2) =>
  sortIssuesDescWithNulls(path)(item2, item1);

const sortMissingCommissions = (item1, item2) => {
  const { commissionType: item1CommissionType } = item1.props;
  const { commissionType: item2CommissionType } = item2.props;
  const compareCommissionType = item2CommissionType.localeCompare(item1CommissionType); // sort by commission type
  if (compareCommissionType !== 0) {
    return compareCommissionType;
  }
  if (item1CommissionType === COMMISSION_TYPES.TRAIL.toUpperCase()) { // if trail sort by number of missing months
    const compareNumberOfMissingMonths = sortIssuesDescWithNulls('props.numberOfMissingMonths')(item1, item2);
    if (compareNumberOfMissingMonths !== 0) {
      return compareNumberOfMissingMonths;
    }
  }
  return sortIssuesDescWithNulls('loanBalance')(item1, item2);
};

export default (state = {
 isLoading: false, hasError: false, errors: null, summary: [], issues: {},
}, action) => {
  switch (action.type) {
    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_START:
    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errors: null,
        summary: [],
        issues: {},
      };

    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.commissionManagementReports,
        isLoading: false,
      };

    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_SUCCESS: {
      const issues = groupBy(action.issues, 'reportType');
      return {
        ...state,
        issues: {
          ...issues,
          LOAN_NOT_IN_SYSTEM: (issues.LOAN_NOT_IN_SYSTEM || []).sort(sortIssuesDescWithNulls('commissionAmount')),
          MISSING_COMMISSIONS: (issues.MISSING_COMMISSIONS || []).sort(sortMissingCommissions),
          CLAWBACKS: (issues.CLAWBACKS || []).sort(sortIssuesAscWithNulls('commissionAmount')),
          PAID_OUT: (issues.PAID_OUT || []).sort(sortIssuesDescWithNulls('approvedAmount')),
          BALANCE_REDUCED: (issues.BALANCE_REDUCED || []).sort(sortIssuesDescWithNulls('previousLoanBalance')),
        },
        isLoading: false,
      };
    }
    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORTS_SUMMARY_ERRORS:
    case ACTIONS.GET_COMMISSION_MANAGEMENT_REPORT_DETAILS_ERRORS:
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
        hasError: true,
      };

    default:
      return state;
  }
};
