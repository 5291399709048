import { useQuery } from '@apollo/client';
import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import isNil from 'lodash/isNil';
import { GET_PRODUCT_NAMES_BY_LENDER } from './productNamesGraphQL';

const getProductName = (product) => {
  switch (product.type) {
    case 'VARIABLE':
      return `${product.productName} (variable) ${product.standardRate}%`;

    case 'FIXED':
      return `${product.productName} (${product.maxTerm}yrs) ${product.standardRate}%`;

    case 'CREDIT':
      return `${product.productName} (Line of credit) ${product.standardRate}%`;

    default:
      return undefined;
  }
};

const getOptions = (loading, error, data) => {
  if (loading || error) {
    return [];
  }
  return (data ? data.variantsByLender.map(getProductName).map(productName => ({
    label: productName,
    value: productName,
  })) : []);
};

const getValue = (productName, options) => (productName && options
  ? find(options, option => option.value === productName)
  : undefined);

export const ProductNameSelector = ({
 lenderName, productName, updateProductName, disabled, enableQuery,
}) => {
  const { loading, error, data } = useQuery(GET_PRODUCT_NAMES_BY_LENDER, {
    variables: { lenderName },
    skip: isNil(lenderName) || !enableQuery,
  });

  const options = useMemo(() => getOptions(loading, error, data), [loading, error, data]);

  const initialValue = useMemo(() => (!disabled ? getValue(productName, options) : undefined),
    [productName, options, disabled]);

  const key = useMemo(() => JSON.stringify([...options, initialValue, disabled]), [options, initialValue, disabled]);

  const onChange = useCallback((value) => {
    updateProductName(value ? value.value : undefined);
  }, [updateProductName]);

  useEffect(() => {
    if (!loading) {
      onChange(initialValue);
    }
  }, [loading, initialValue, onChange]);

  return (
    <FormsySelect
      key={key}
      name="productName"
      options={options}
      value={initialValue}
      onChange={onChange}
      loading={loading}
      loadingMessage="Getting products"
      customErrorMessage={(error ? 'Error getting products' : undefined)}
      fullWidth
      required={!disabled && !loading}
      disabled={disabled}
    />
  );
};

ProductNameSelector.defaultProps = {
  productName: undefined,
  lenderName: undefined,
  disabled: false,
};

ProductNameSelector.propTypes = {
  productName: PropTypes.string,
  lenderName: PropTypes.string,
  updateProductName: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  enableQuery: PropTypes.bool.isRequired,
};
