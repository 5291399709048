import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import LoansSummaryDetails from './LoansSummaryDetails';
import usePageViewTracking from '../../../pages/usePageViewTracking';

const LoanDetails = ({ match }) => {
  usePageViewTracking('loanDetails');
  const id = get(match, 'params.id', '');

  return (
    <>
      <GroupOfficeBusinessSelector />
      <LoansSummaryDetails id={id} />
    </>
  );
};

LoanDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default LoanDetails;
