import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import styles from './Soma.module.scss';
import SomaRow from './SomaRow';

const Somas = ({ hasEditPermission }) => (
  <div className={styles.section}>
    <h5 className={styles.subTitle}>Previously generated SOMAs</h5>
    <div className={styles.somasContent}>
      <div className={styles.somasHeader}>
        <div>SOMA type</div>
        <div>Date (time displayed in local time)</div>
        <div className={styles.issued}>Issued</div>
        <div className={styles.signed}>Signed by all applicants or guarantors</div>
        <div className={styles.download}>Download</div>
      </div>
      <FieldArray
        name="somas"
        component={SomaRow}
        props={{
          hasEditPermission,
        }}
      />
    </div>
  </div>
);

Somas.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

export default Somas;
