import React, { useContext, useMemo } from 'react';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import CalculationExplanation from 'shared/components/productSearch/CalculationExplanation';
import FeatureModal from 'shared/components/productSearch/FeatureModal';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import RetryComponent from 'shared/components/RetryComponent';
import ProductComparisonTableComponent from 'shared/components/productSearch/ComparisonTable';
import { ProductsComparisonTableContext } from '../../ProductSearchContext';
import ConnectedComparisonEditingModal from '../ComparisonEditingModal/ConnectedComparisonEditingModal';
import styles from '../style.module.scss';

const retrieveFeatureNamesFromCriteria = (loanCriteriaList) => uniq(
  flatMap(loanCriteriaList, (criteria => criteria.selectedFeatureNames)),
).sort();

const ProductComparisonTable = React.memo(() => {
  const {
    readOnly,
    loanCriteriaList,
    comparison,
    totalCostTerm,
    repaymentFrequency,
    features,
    featuresHaveError,
    dispatchStartVariantEditing,
    dispatchToggleIncludeCommentsInPrintout,
    dispatchToggleFeatureModalView,
    dispatchToggleSelectFeatureFromComparison,
    dispatchUpdateSelectedFeaturesFromComparison,
  } = useContext(ProductsComparisonTableContext);

  const {
    selectedOptions,
    hasError,
    isLoading,
    includeCommentsInPrintout,
    featureModalViewInComparison,
    selectedFeaturesFromComparison,
  } = comparison;

  const selectedFeatureNames = useMemo(
    () => retrieveFeatureNamesFromCriteria(loanCriteriaList),
    [loanCriteriaList],
  );
  const sortedComparisonFeatureNames = useMemo(
    () => uniq(selectedFeaturesFromComparison).sort(),
    [selectedFeaturesFromComparison],
  );
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (hasError) {
    return <RetryComponent />;
  }

  return (
    <div>
      <div>
        <div className={styles.calculationExplanation}>
          <CalculationExplanation />
        </div>
      </div>
      <ConnectedComparisonEditingModal />
      <ProductComparisonTableComponent
        readOnly={readOnly}
        totalCostTerm={totalCostTerm}
        repaymentFrequency={repaymentFrequency}
        variantsCombinations={selectedOptions}
        selectedFeatureNames={selectedFeatureNames}
        selectedFeaturesFromComparison={sortedComparisonFeatureNames}
        includeCommentsInPrintout={includeCommentsInPrintout}
        onToggleIncludeCommentsInPrintout={dispatchToggleIncludeCommentsInPrintout}
        onStartVariantEditing={dispatchStartVariantEditing}
        onEditFeature={dispatchToggleFeatureModalView}
      />
      <FeatureModal
        isOpen={featureModalViewInComparison}
        onRequestClose={dispatchToggleFeatureModalView}
        features={features}
        hasError={featuresHaveError}
        selectedFeatureNames={sortedComparisonFeatureNames}
        fixedFeatureNames={selectedFeatureNames}
        onToggleSelectFeature={dispatchToggleSelectFeatureFromComparison}
        onUpdateSelectedFeaturesNames={dispatchUpdateSelectedFeaturesFromComparison}
      />
    </div>
  );
});

export default ProductComparisonTable;
