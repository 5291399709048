import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'shared/components/Icon/Icon';
import styles from './invoiceStyles.module.scss';
import { moneyValueRenderer } from '../renderers';

const handleMouseDown = callback => (e) => {
  if (e) {
    e.currentTarget.style.outline = 'none';
  }
  callback(e);
};
const handleBlur = (e) => {
  if (e) {
    e.currentTarget.style.outline = '';
  }
};

const InvoiceSummaryRow = ({
  processingMonth,
  totalIncludingGst,
  totalGst,
  totalExcludingGst,
  isLoadingFile,
  downloadInvoicePdf,
  downloadInvoiceDetails,
}) => {
  const themeContext = useContext(ThemeContext);
  const handlePdfDownload = handleMouseDown(downloadInvoicePdf);
  const handleXlsxDownload = handleMouseDown(downloadInvoiceDetails);
  return (
    <tr>
      <td>{moment(processingMonth).format('MMMM YYYY')}</td>
      <td style={{ textAlign: 'right' }}>
        {moneyValueRenderer(totalExcludingGst)}
      </td>
      <td style={{ textAlign: 'right' }}>{moneyValueRenderer(totalGst)}</td>
      <td style={{ textAlign: 'right' }}>
        {moneyValueRenderer(totalIncludingGst)}
      </td>
      <td style={{ textAlign: 'center' }}>
        <button
          type="button"
          className={styles.download}
          onClick={handlePdfDownload}
          onBlur={handleBlur}
        >
          <Icon
            name="file-pdf"
            size="23px"
            color={themeContext.palette.textInformation}
            loading={isLoadingFile.includes(`${processingMonth}-pdf`)}
          />
        </button>
        <button
          type="button"
          className={styles.download}
          onClick={handleXlsxDownload}
          onBlur={handleBlur}
        >
          <Icon
            name="file-excel"
            size="23px"
            color={themeContext.palette.textInformation}
            loading={isLoadingFile.includes(`${processingMonth}-xlsx`)}
          />
        </button>
      </td>
    </tr>
  );
};

InvoiceSummaryRow.propTypes = {
  processingMonth: PropTypes.string.isRequired,
  totalIncludingGst: PropTypes.string.isRequired,
  totalGst: PropTypes.string.isRequired,
  totalExcludingGst: PropTypes.string.isRequired,
  isLoadingFile: PropTypes.arrayOf(PropTypes.string).isRequired,
  downloadInvoicePdf: PropTypes.func.isRequired,
  downloadInvoiceDetails: PropTypes.func.isRequired,
};

export default InvoiceSummaryRow;
