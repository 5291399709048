import React from 'react';
import { ValueAxis } from '@devexpress/dx-react-chart-bootstrap4';
import PropTypes from 'prop-types';
import { formatAxisLabel } from './utils';

export const ValueLabel = React.memo(({ text, ...others }) => (
  <ValueAxis.Label
    text={formatAxisLabel(text)}
    {...others}
  />
));

ValueLabel.propTypes = {
  text: PropTypes.string.isRequired,
};
