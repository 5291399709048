import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveTable from 'shared/components/ResponsiveTable/ResponsiveTable';
import { getHeaders, getRowPresenter, orderApplicants } from 'shared/helpers/applicantsTableHelper';

const Applicants = ({ applicants }) => (
  <ResponsiveTable
    header={getHeaders()}
    rows={orderApplicants(applicants).map(applicant => getRowPresenter(applicant))}
  />
);

Applicants.propTypes = {
  applicants: PropTypes.shape({
    people: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    companies: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
}).isRequired,
};

export default Applicants;
