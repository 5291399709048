import React from 'react';
import PropTypes from 'prop-types';
import ProductTypeComponent from './ProductTypeComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedProductType = ({ index }) => (
  <ProductSearchContext.Consumer>
    {
      context => {
        const { productType } = context.loanCriteriaList[index];
        return (
          <ProductTypeComponent
            productType={productType}
            dispatchUpdateProductType={context.dispatchUpdateProductType(index)}
          />
        );
      }
    }
  </ProductSearchContext.Consumer>
);

ConnectedProductType.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedProductType;
