import React, { useCallback } from 'react';
import { FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { getAssetsById } from '../../../redux/selectors/assets';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';
import ReduxFormFieldArrayTupleInput, { validateValues } from '../components/FieldArrayTupleInput';
import SubtotalConstructionCosts from './SubtotalConstructionCosts';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';
import { getAssetFields, getConstructionTitle } from './utils';

const EditConstruction = ({
  asset, pathToConstruction, userGuidanceCode,
}) => {
  const constructionAmount = get(asset, 'contractPrice.contractPriceAmount');
  const constructionSelector = useCallback(
    (state) => fundingWorksheetFormValueSelector(state, pathToConstruction),
    [pathToConstruction],
  );
  const construction = useSelector(constructionSelector);
  const assetFields = getAssetFields(asset);
  return (
    <div className={styles.construction}>
      <InformationSection
        title={getConstructionTitle(asset)}
        wrapperStyle={styles.infoSection}
        contentWrapperStyle={styles.infoSectionContent}
        titleWrapperStyle={styles.titleWrapperStyle}
      >
        <SubSection>
          <FieldsContainer
            fields={assetFields}
            fieldsWrapperClassName={styles.assetFieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
          <section className={styles.otherCostsSectionWrapper}>
            <FieldArray
              name={`${pathToConstruction}.otherCosts`}
              component={ReduxFormFieldArrayTupleInput}
              validate={validateValues}
              allowNegative={false}
              descriptionLabel="Other cost"
              descriptionPlaceholder="description"
            />
          </section>
        </SubSection>
        <SubtotalConstructionCosts
          construction={construction}
          constructionAmount={constructionAmount}
          userGuidanceCode={userGuidanceCode}
        />
      </InformationSection>
    </div>
  );
};

EditConstruction.propTypes = {
  asset: PropTypes.shape({
    primaryPurpose: PropTypes.string,
    contractPrice: PropTypes.shape({
      contractPriceAmount: PropTypes.number,
    }),
  }).isRequired,
  pathToConstruction: PropTypes.string.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

const EditConstructionsComponent = ({ fields, userGuidanceCodes }) => {
  const assets = useSelector(getAssetsById);
  if (fields.length === 0) {
    return null;
  }
  return fields.map(
    (name, index) => {
      const asset = assets[fields.get(index).assetId];
      return (
        <EditConstruction
          asset={asset}
          key={name}
          pathToConstruction={name}
          userGuidanceCode={userGuidanceCodes[index]}
        />
        );
    },
  );
};

EditConstructionsComponent.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  userGuidanceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const EditConstructions = ({ userGuidanceCodes }) => (
  <FieldArray
    name="constructions"
    component={EditConstructionsComponent}
    props={{
        userGuidanceCodes,
      }}
  />
);

EditConstructions.propTypes = {
  userGuidanceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditConstructions;
