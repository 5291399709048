import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { DEFAULT_SEARCH_PREVIOUS_X_DAYS } from '../constants';

const getTasks = businessId =>
  client.query({
    query: gql`{
      specialTasksSearch(businessId: "${businessId}")
      {
        id
        applicationId
        name
        completed
        dueDateTime
        lenderId
        applicants
        lastCompletedTime
      }
    }`,
  });

const getActivityLogs = businessId =>
client.query({
  query: gql`{
    specialActivityLogsSearch(
      businessId: "${businessId}"
      searchPreviousXDays: ${DEFAULT_SEARCH_PREVIOUS_X_DAYS}
    )
    {
      id
      userId
      userName
      applicationId
      actionTime
      activityLogType
      eventDetail {
        resourceId
        resourceName
        resourceSource
        extraInfo
        changes {
          path
          oldValue
          newValue
        }
      }
      lenderId
      lenderName
      applicantNames
      applicationNumber
    }
  }`,
});

export {
  getTasks,
  getActivityLogs,
};
