import has from 'lodash/has';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import { getTargetChangedValue, getUpdatedChanges } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const FinaliseDisplayFields = {
  'preSubmissionInfo.comment': 'lender comments',
  'preSubmissionInfo.estimatedCommissionLoanAmount': 'Estimated commission loan amount',
  'preSubmissionInfo.somaAdditionalComment': 'SOMA additional comments',
};

const FinaliseSignedFormsDisplayFields = {
  'preSubmissionInfo.signedCreditCheckAuthority': 'Credit check authority',
  'preSubmissionInfo.signedFixRateLockDeclaration': 'Fixed rate lock declaration',
  'preSubmissionInfo.signedLowDocClientAcknowledgement': 'Low doc client acknowledgement',
};

const getSeparatedFields = (updatedChanges, origin) => updatedChanges
  .filter(change => has(FinaliseDisplayFields, change.path))
  .map(changedField => {
    const changedValue = getTargetChangedValue(origin, changedField.path);
    const displayName = get(FinaliseDisplayFields, changedField.path);
    return ({
      ...getBasicLogInfo(origin),
      fieldName: changedField.path,
      fieldValue: changedValue,
      displayName,
    });
  });

const getSignedFormsFields = (updatedChanges, origin) => {
  const changedSignedFormsFields = updatedChanges.filter(change =>
    has(FinaliseSignedFormsDisplayFields, change.path));
  if (isEmpty(changedSignedFormsFields)) {
    return [];
  }
  const grouped = groupBy(changedSignedFormsFields,
    field => (getTargetChangedValue(origin, field.path) === 'true' ? 'signed' : 'unsigned'));
  const changes = mapValues(grouped, fields =>
    fields.map(field => get(FinaliseSignedFormsDisplayFields, field.path)));
  return [{
    ...getBasicLogInfo(origin),
    fieldName: 'signedForms',
    changes,
  }];
};

const finaliseChangeLogTransformer = (origin) => {
  const updatedChanges = getUpdatedChanges(origin);

  return [
    ...getSeparatedFields(updatedChanges, origin),
    ...getSignedFormsFields(updatedChanges, origin),
  ];
};

export default finaliseChangeLogTransformer;
