import React from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import ThirdParty from './ThirdParty';

const ThirdPartyList = ({ fields, hasEditPermission, isSearchedThirdParty }) => (
  <>
    {fields.map((item, index) => (
      <ThirdParty
        key={item}
        fieldNamePrefix={item}
        hasEditPermission={hasEditPermission}
        onRemove={() => fields.remove(index)}
        isSearchedThirdParty={isSearchedThirdParty}
      />
     ))}
  </>
  );

ThirdPartyList.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isSearchedThirdParty: PropTypes.bool.isRequired,
};

export default ThirdPartyList;
