import find from 'lodash/find';
import get from 'lodash/get';
import { getDisplayAddress } from '../../../../../utils/address';
import { getNumberContent, getTextContent } from '../contentUtils';
import { PRIMARY_PURPOSE_OPTIONS } from '../../shared/RealEstateAsset/constants';

export const getConstructionTitle = (asset) => {
  const address = getDisplayAddress(asset.address);
  return address ? `Construction for ${address}` : 'Construction';
};

export const getAssetFields = (asset) => {
  const constructionAmount = get(asset, 'contractPrice.contractPriceAmount');
  return [
    getTextContent('Purpose', find(PRIMARY_PURPOSE_OPTIONS, ['value', asset.primaryPurpose])?.label),
    getNumberContent('Construction amount', constructionAmount),
  ];
};
