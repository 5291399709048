import WithReadOnlyCheckboxDropdown from 'shared/components/formFields/CheckboxDropdown/WithReadOnlyCheckboxDropdown';
import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import withReduxForm from '../ReduxFormFieldWrapper';
import { withFormMeta } from '../../../FormMeta';

const ReduxFormCheckboxDropdown = withFormMeta(
  withReduxForm(({ onChange, value, selectedOptionIds }) => {
    const handleChange = useCallback((v) => onChange(isEmpty(v) ? null : map(v, 'id')),
      [onChange]);

    return ({
      onUpdate: handleChange,
      selectedOptionIds: selectedOptionIds || value || [],
    });
  })(WithReadOnlyCheckboxDropdown),
);

export default ReduxFormCheckboxDropdown;
