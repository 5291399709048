import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeatureSelect from 'shared/components/productSearch/FeatureModal/FeatureSelect';
import FeatureModal from 'shared/components/productSearch/FeatureModal';

class Feature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewAllFeature: false,
    };
  }

  // eslint-disable-next-line camelcase,react/sort-comp
  UNSAFE_componentWillMount() {
    const { dispatchGetFeatures } = this.props;
    dispatchGetFeatures();
  }

  toggleViewAllFeature = () => {
    this.setState(prevState => ({
      ...prevState,
      viewAllFeature: !prevState.viewAllFeature,
    }));
  };

  render() {
    const { viewAllFeature } = this.state;
    const {
      dispatchUpdateSelectedFeaturesNames, dispatchToggleSelectFeature,
      hasError, features, selectedFeatureNames,
    } = this.props;
    return (
      <>
        <FeatureSelect
          buttonLabel="Show all features"
          buttonOnClick={this.toggleViewAllFeature}
          onChange={dispatchUpdateSelectedFeaturesNames}
          hasError={hasError}
          features={features}
          selectedFeatureNames={selectedFeatureNames}
        />
        <FeatureModal
          isOpen={viewAllFeature}
          hasError={hasError}
          features={features}
          selectedFeatureNames={selectedFeatureNames}
          onRequestClose={this.toggleViewAllFeature}
          onUpdateSelectedFeaturesNames={dispatchUpdateSelectedFeaturesNames}
          onToggleSelectFeature={dispatchToggleSelectFeature}
        />
      </>
    );
  }
}

Feature.defaultProps = {
  features: [],
  selectedFeatureNames: [],
};

Feature.propTypes = {
  dispatchGetFeatures: PropTypes.func.isRequired,
  dispatchUpdateSelectedFeaturesNames: PropTypes.func.isRequired,
  dispatchToggleSelectFeature: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
  })),
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool.isRequired,
};

export default Feature;
