import React from 'react';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
 change, Field, Fields, formPropTypes, reduxForm,
} from 'redux-form';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import { SIZES } from 'shared/components/Modal/Modal';
import ReduxFromTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import { ADDRESS_TYPE, ADDRESS_TYPE_OPTIONS, STATE_OPTIONS } from '../constants';
import styles from './index.module.scss';
import { getCountryOptions, getStreetTypeOptions } from '../../../../../../utils/address';

const AU = 'AU';
const countryCodeOptions = getCountryOptions();
const streetTypeOptions = getStreetTypeOptions();

const standardAddressFields = (
  <>
    <Field
      label="Building name"
      name="buildingName"
      component={ReduxFromTextInput}
      className={styles.buildingName}
    />
    <Field
      label="Unit no."
      name="unitNo"
      component={ReduxFromTextInput}
      className={styles.unitNo}
    />
    <Field
      label="Floor"
      name="floorNo"
      component={ReduxFromTextInput}
      className={styles.floorNo}
    />
    <Field
      label="Street no."
      name="street.no"
      component={ReduxFromTextInput}
      className={styles.streetNo}
    />
    <Field
      label="Street name"
      name="street.name"
      component={ReduxFromTextInput}
      className={styles.streetName}
    />
    <Field
      label="Street type"
      name="street.type"
      component={ReduxFormSelect}
      className={styles.streetType}
      options={streetTypeOptions}
    />
  </>
  );

const nonStandardAddressFields = (
  <Field
    label="Address"
    name="nonStdAddress"
    component={ReduxFromTextInput}
    className={styles.nonStdAddress}
  />
);

const renderAddressFields = (field) => {
  const addressType = field.input.value;
  return addressType === ADDRESS_TYPE.STANDARD
    ? standardAddressFields
    : nonStandardAddressFields;
};

const renderStateAndCountryFields = (fields) => {
  const [countryFieldName, stateFieldName] = fields.names;
  const countryCode = get(fields, `${countryFieldName}.input.value`);
  const stateField = get(fields, stateFieldName);
  const countryField = get(fields, countryFieldName);
  return (
    <>
      {countryCode === AU
        ? (
          <ReduxFormSelect
            {...stateField}
            label="State"
            className={styles.state}
            options={STATE_OPTIONS}

          />
        ) : (
          <ReduxFromTextInput
            {...stateField}
            label="State"
            className={styles.state}
          />
        )}
      <Field
        label="Postcode"
        name="postcode"
        component={ReduxFromTextInput}
        className={styles.postcode}
      />
      <ReduxFormSelect
        {...countryField}
        label="Country"
        className={styles.country}
        options={countryCodeOptions}
        onChange={(value) => {
          countryField.input.onChange(value);
          countryField.meta.dispatch(change(countryField.meta.form, stateFieldName, null));
        }}
      />
    </>
  );
};

const AddressModal = ({
  handleSubmit,
  change: changeFormValue,
  ...others
                      }) =>
  (
    <ModalWithButtons
      submitButtonProps={{
        onClick: handleSubmit,
      }}
      header="Enter address"
      submitText="Apply"
      size={SIZES.MEDIUM}
      className={styles.addressModal}
      {...others}
    >
      <div className={styles.container}>
        <Field
          label="Address type"
          name="addressType"
          component={ReduxFormSelect}
          options={ADDRESS_TYPE_OPTIONS}
          className={styles.addressType}
          onChange={(value) => {
            if (value === ADDRESS_TYPE.STANDARD) {
              changeFormValue('nonStdAddress', null);
            } else {
              changeFormValue('buildingName', null);
              changeFormValue('unitNo', null);
              changeFormValue('floorNo', null);
              changeFormValue('street', null);
            }
          }}
        />
        <Field
          name="addressType"
          component={renderAddressFields}
        />
        <Field
          label="Suburb"
          name="city"
          component={ReduxFromTextInput}
          className={styles.suburb}
        />
        <Fields
          names={['countryCode', 'state']}
          component={renderStateAndCountryFields}
        />
      </div>
    </ModalWithButtons>
  );

AddressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default reduxForm({
  form: 'addressForm',
})(AddressModal);
