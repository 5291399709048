import React from 'react';
import { useSelector } from 'react-redux';
import {
 Field, FieldArray, Fields, formValueSelector,
} from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import classNames from 'classnames';
import get from 'lodash/get';
import styles from './Company.module.scss';
import DirectorsOrTrustees from './DirectorsOrTrustees';
import Beneficiaries from './Beneficiaries';
import { toDateWithSlash } from '../../../../../../../utils/datetime';
import { FORM_NAME } from '../shared/constants';

const selector = formValueSelector(FORM_NAME);

const CompanyDetail = (fields) => {
  const [descriptionFieldName, idName] = fields.names;
  const description = get(fields, `${descriptionFieldName}.input.value`, '');
  const id = get(fields, `${idName}.input.value`);

  const allApplicantsWithoutSelected = useSelector(state => {
    const applicants = selector(state, 'applicants');
    const guarantors = selector(state, 'guarantors');
    return concat(
      applicants,
      guarantors,
    ).filter(item => item.id !== id);
  });

  if (isEmpty(id)) {
    return null;
  }

  if (description && description.toLowerCase() === 'trust') {
    return (
      <>
        <div className={styles.directorsTrusteesSection}>
          <FieldArray
            name={`${fields.fieldNamePrefix}.trustees`}
            component={DirectorsOrTrustees}
            persons={allApplicantsWithoutSelected}
            label="Trustee"
            hasEditPermission={fields.hasEditPermission}
          />
        </div>
        <div className={styles.beneficiariesSection}>
          <FieldArray
            name={`${fields.fieldNamePrefix}.beneficiaries`}
            component={Beneficiaries}
            persons={allApplicantsWithoutSelected}
            label="Beneficiary"
            hasEditPermission={fields.hasEditPermission}
          />
        </div>
      </>
);
  }
  return (
    <div className={styles.directorsTrusteesSection}>
      <FieldArray
        name={`${fields.fieldNamePrefix}.directors`}
        component={DirectorsOrTrustees}
        persons={fields.persons}
        className={classNames(
        [styles.fieldWrapper, styles.threeColumnWrapperOnDesktop, styles.input, styles.atFirstColumnOnDesktop],
      )}
        label="Director"
        hasEditPermission={fields.hasEditPermission}
      />
    </div>
);
};

const DetailSection = ({
  fieldNamePrefix, hasEditPermission, persons,
}) => (
  <FieldsContainer
    title=""
    className={styles.sectionWrapper}
    fieldsWrapperClassName={styles.overrideSectionWrapper}
  >
    <Field
      name={`${fieldNamePrefix}.description`}
      className={classNames([styles.fieldWrapper, styles.atFirstColumnOnDesktop])}
      component={PlainTextField}
      label="Company description"
    />
    <Field
      name={`${fieldNamePrefix}.type`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Company type"
    />
    <Field
      name={`${fieldNamePrefix}.abn`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Company ABN"
    />
    <Field
      name={`${fieldNamePrefix}.abrn`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Company ARBN"
    />
    <Field
      name={`${fieldNamePrefix}.acn`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Company ACN"
    />
    <Field
      name={`${fieldNamePrefix}.dateOfRegistration`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Date of registration"
      format={value => (value ? toDateWithSlash(value) : '')}
    />
    <Fields
      names={[`${fieldNamePrefix}.description`, `${fieldNamePrefix}.id`]}
      className={classNames([styles.fieldWrapper, styles.atFirstColumnOnDesktop])}
      component={CompanyDetail}
      persons={persons}
      fieldNamePrefix={fieldNamePrefix}
      hasEditPermission={hasEditPermission}
    />
  </FieldsContainer>
  );

DetailSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  persons: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

export default DetailSection;
