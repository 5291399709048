import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/checkboxCircle"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M11.003,16 L18.073,8.929 L16.659,7.515 L11.003,13.172 L8.174,10.343 L6.76,11.757 L11.003,16 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
