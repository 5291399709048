import React, { useMemo } from 'react';
import { Fields } from 'redux-form';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import isFunction from 'lodash/isFunction';
import ReduxFormRadioGroup from 'shared/components/formFields/ReduxForm/RadioGroup';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import PropTypes from 'prop-types';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import { isImportant, containOther } from '../utils';
import styles from '../index.module.scss';
import { RADIO_GROUP_OPTION } from '../constants';
import createOtherDetailValidator from '../validator/validateOtherDetails';

const PreferenceEditorContent = React.memo((props) => {
  const {
    namePrefix, extraProps, extraRender, clearValue, ...others
  } = props;
  const fields = get(others, namePrefix, {});
  const { importantDegree, reasons, otherDetail } = fields;

  const valueRender = useMemo(() => (value) => (value?.length > 1
    ? 'Multiple'
    : find(extraProps.reason.options, item => item.id === value[0])?.name),
    [extraProps.reason.options]);

  return (
    <>
      <ReduxFormRadioGroup
        options={RADIO_GROUP_OPTION}
        {...importantDegree}
        {...extraProps.importantDegree}
        onChange={({ target }) => {
          importantDegree.input.onChange(target.value);
          if (!isImportant(target.value)) {
            reasons.input.onChange(null);
            otherDetail.input.onChange('');
            clearValue();
          }
        }}
        className={styles.radioGroup}
      />
      {isImportant(importantDegree.input.value) && (
        <ReduxFormCheckboxDropdown
          label="Reason of importance"
          className={styles.multipleSelect}
          inputProps={{ className: styles.input }}
          {...reasons}
          {...extraProps.reason}
          onChange={(v) => {
            reasons.input.onChange(v);
            if (!containOther(v)) {
              otherDetail.input.onChange('');
            }
          }}
          valueRender={valueRender}
          readOnlyFormatter={value => {
            const values = map(value, (id => find(extraProps.reason.options, { id })));
            return (
              <div className={styles.readOnlyOptions}>
                {values.map(({ name, id }) => (
                  <div key={id}>{name}</div>
                ))}
              </div>
            );
          }}
          buttonText="Select"
        />
      )}
      {containOther(reasons.input.value) && (
        <ReduxFormTextArea
          isRequired
          label="Other detail"
          rows="1"
          className={styles.otherDetail}
          {...otherDetail}
          {...extraProps.otherDetail}
        />
      )}
      {
        isFunction(extraRender) && extraRender({
          importantDegree, reasons, otherDetail, namePrefix,
        })
      }
    </>
  );
});

PreferenceEditorContent.defaultProps = {
  extraRender: () => {},
  clearValue: () => {},
};

PreferenceEditorContent.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  extraProps: PropTypes.shape({
    importantDegree: PropTypes.object,
    reason: PropTypes.object,
    otherDetail: PropTypes.object,
  }).isRequired,
  extraRender: PropTypes.func,
  clearValue: PropTypes.func,
};

const PreferenceEditor = React.memo(({
 namePrefix, props, extraRender, clearValue,
}) => {
  const names = useMemo(() => ([
    `${namePrefix}.importantDegree`,
    `${namePrefix}.reasons`,
    `${namePrefix}.otherDetail`,
  ]), [namePrefix]);

  const validator = useMemo(() => createOtherDetailValidator({
    selectField: `${namePrefix}.reasons`,
    otherDetailField: `${namePrefix}.otherDetail`,
  }), [namePrefix]);

  return (
    <div className={styles.item}>
      <Fields
        names={names}
        namePrefix={namePrefix}
        component={PreferenceEditorContent}
        extraProps={props}
        extraRender={extraRender}
        validate={validator}
        clearValue={clearValue}
      />
    </div>
  );
});

PreferenceEditor.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  props: PropTypes.shape({
    importantDegree: PropTypes.object,
    reasons: PropTypes.object,
    otherDetail: PropTypes.object,
  }).isRequired,
  extraRender: PropTypes.func,
  clearValue: PropTypes.func,
};

PreferenceEditor.defaultProps = {
  extraRender: () => {},
  clearValue: () => {},
};

export default PreferenceEditor;
