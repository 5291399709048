export const LOAN_CRITERIA_FIELDS = ['loanAmount', 'combinedLoanAmount', 'purpose', 'productType', 'repaymentType', 'loanTerm',
  'fixedLoanTerm', 'interestOnlyTerm', 'selectedFeatureNames', 'purposeCategory', 'purposeDetails', 'refinanceReason'];

export const APPLICATION_CRITERIA_FIELDS = ['applicationType', 'totalCombinedLoanAmount',
  'totalLoanAmount', 'loanValueRatio', 'selectedLenderNames', 'securityValue'];

export const MODIFICATION_ORIGINAL_FIELDS = ['totalCost', 'firstPeriodInterestOnly', 'firstPeriodPrincipalAndInterest',
  'firstPeriodRepayment', 'upfrontFee', 'ongoingFee', 'lmi', 'comparisonRate', 'standardRate'];

export const VARIANT_FIELDS = ['id', 'source', 'productName', 'displayName', 'lenderName', 'lenderType',
  'minLoanAmount', 'maxLoanAmount', 'type', 'minLoanValueRatio', 'maxLoanValueRatio', 'standardRate', 'baseRate',
  'maxTerm', 'minTerm', 'discountRate', 'discountTermStart', 'discountTermEnd', 'applicationFee', 'legalFee',
  'valuationFee', 'ongoingFee', 'lmi', 'ongoingFeeFrequency', 'externalLink', 'originalMinLoanAmount',
  'originalMaxLoanAmount', 'originalMinLoanValueRatio', 'originalMaxLoanValueRatio', 'repaymentTypes',
  'firstPeriodRepayment', 'firstPeriodPrincipalAndInterest', 'firstPeriodInterestOnly', 'totalCost', 'comparisonRate',
  'repaymentAvailable', 'discountMonths', 'upfrontFee', 'nextgenMaliId', 'customProduct'];
