import { connect } from 'react-redux';
import { SummaryReport } from './SummaryReport';
import { summaryReportSelector, settlementSummarySelector } from '../../redux/selectors/commissionSummarySelector';

const mapStateToProps = (state) => ({
  loanBook: summaryReportSelector(state),
  settledApplication: settlementSummarySelector(state),
});

export const ConnectedSummaryReport = connect(mapStateToProps)(SummaryReport);
