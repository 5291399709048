import React, { useState } from 'react';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import { SIZES } from 'shared/components/Modal/Modal';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { createSelector } from '@reduxjs/toolkit';
import LoanCriteriaSummary from 'shared/components/productSearch/CriteriaSummary/LoanCriteriaSummary';
import styles from 'shared/components/productSearch/CriteriaSummary/styles.module.scss';
import EmbeddedApplicationCriteriaSummary from 'shared/components/productSearch/CriteriaSummary/EmbeddedApplicationCriteriaSummary';
import ConnectedCustomProduct from './CustomProduct/ConnectedCustomProduct';

const productSearchSelector = state => get(state, 'application.productSearch', {});
const searchCriteriaSelector = createSelector(
  productSearchSelector,
  (productSearch) => productSearch.searchCriteria,
);
const lenderSelector = (state) => get(state, 'lenders.lenders');
const customProductSelector = (state) => get(state, 'application.productSearch.customProduct');

const CriteriaSummary = ({
 onModifySearch,
}) => {
  const searchCriteria = useSelector(searchCriteriaSelector);

  const {
    loansCriteria,
    applicationCriteria,
    totalCostTerm,
    repaymentFrequency,
  } = searchCriteria ?? {};

  const panelLenders = useSelector(lenderSelector);
  const hasCustomProduct = !isNil(useSelector(customProductSelector));

  const isSingleLoan = size(loansCriteria) === 1;
  const [shouldConfirm, setShouldConfirm] = useState(false);
  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <h2>Loan criteria</h2>
          </div>
          <div>
            {map(loansCriteria, (criteria, index) => {
              const key = `loan${index}`;
              return (
                <LoanCriteriaSummary
                  key={key}
                  applicationCriteria={applicationCriteria}
                  loanCriteria={criteria}
                  loanNum={index + 1}
                  isSingleLoan={isSingleLoan}
                />
              );
            })}
            <EmbeddedApplicationCriteriaSummary
              applicationCriteria={applicationCriteria}
              isSingleLoan={isSingleLoan}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <PrimaryButton
            className={styles.topButton}
            onClick={() => {
                if (hasCustomProduct === true) {
                  setShouldConfirm(true);
                } else {
                  onModifySearch();
                }
              }}
          >
            Modify search
          </PrimaryButton>
          <ConnectedCustomProduct
            panelLenders={panelLenders}
            loansCriteria={loansCriteria}
            hasCustomProduct={hasCustomProduct}
            totalCostTerm={totalCostTerm}
            repaymentFrequency={repaymentFrequency}
          />
        </div>
      </div>

      <ModalWithButtons
        isOpen={shouldConfirm}
        header="Custom product will be deleted"
        size={SIZES.SMALL}
        submitText="Confirm"
        secondarySubmitText="Close"
        submitButtonProps={{
          onClick: () => {
            onModifySearch();
            setShouldConfirm(false);
          },
          style: {
            minWidth: '17rem',
          },
        }}
        secondaryButtonPros={{
          onClick: () => {
            setShouldConfirm(false);
          },
          style: {
            minWidth: '17rem',
          },
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={() => {
          setShouldConfirm(false);
        }}
      >
        <p className={styles.text}>Changing the loan criteria will delete the custom product you previously entered.</p>
      </ModalWithButtons>
    </>
  );
};

CriteriaSummary.propTypes = {
  onModifySearch: PropTypes.func.isRequired,
};

export default CriteriaSummary;
