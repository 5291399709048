import { useCallback, useMemo } from 'react';
import withReduxForm from '../ReduxFormFieldWrapper';
import WithReadOnlyNumberInput from '../../NumberInput/WithReadOnlyNumberInput';

const ReduxFormNumberInput = withReduxForm((props) => {
  const {
    onChange,
    onBlur,
    formatter,
  } = props;
  const handleBlur = useMemo(() => () => onBlur(), [onBlur]);
  const handleChange = useCallback((v) => onChange(v === undefined ? null : v), [onChange]);

  return {
    onChange: handleChange,
    onBlur: handleBlur,
    format: formatter,
  };
})(WithReadOnlyNumberInput);

export default ReduxFormNumberInput;
