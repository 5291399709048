import types from '../constants';
import { createReducer } from '../../../../../utils/reducerGenerator';

const initialState = {
  isFetching: false,
};

export default createReducer(initialState, {
  [types.COPY_APPLICATION_START]: () => ({ isFetching: true }),
  [types.COPY_APPLICATION_SUCCESS]: () => ({ isFetching: false }),
  [types.COPY_APPLICATION_ERROR]: () => ({ isFetching: false }),
});
