import React, { useCallback } from 'react';
import isNil from 'lodash/isNil';
import { fieldPropTypes } from 'redux-form';
import Checkbox from 'shared/components/formFields/Checkbox';

const ReduxFormCheckbox = ({ ...props }) => {
  const {
    input: { value, onChange, name },
  } = props;
  const handleChange = useCallback(v => onChange(isNil(v.checked) ? v : v.checked), [onChange]);
  return (
    <Checkbox
      checked={!!value}
      onChange={handleChange}
      name={name}
      {...props}
    />
  );
};

ReduxFormCheckbox.propTypes = fieldPropTypes;

export default ReduxFormCheckbox;
