import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isPristine,
  isValid,
  submit,
  isSubmitting,
} from 'redux-form';
import StickyFooter from 'shared/components/StickyFooter';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import get from 'lodash/get';
import EmploymentForm from './EmploymentForm';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import AdditionalIncomeForm from './AdditionalIncomeForm/AdditionalIncomeForm';
import FinancialSummary from '../shared/FinancialSummary';
import { FETCHING_STATUS } from '../../../../../constants';

const IncomeAndEmploymentContainer = () => {
  const employmentsPristine = useSelector(isPristine('employmentsForm'));
  const additionalIncomesPristine = useSelector(isPristine('additionalIncomesForm'));
  const employmentsValid = useSelector(isValid('employmentsForm'));
  const additionalIncomesValid = useSelector(isValid('additionalIncomesForm'));
  const employmentsSubmitting = useSelector(isSubmitting('employmentsForm'));
  const additionalIncomesSubmitting = useSelector(isSubmitting('additionalIncomesForm'));

  const employmentsCanSave = !employmentsPristine && employmentsValid;
  const additionalIncomesCanSave = !additionalIncomesPristine && additionalIncomesValid;
  const submitting = employmentsSubmitting || additionalIncomesSubmitting;
  const hasEditPermission = useSelector(state => hasEditPermissionSelector(state));
  const isFetching = useSelector(state =>
    get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    if (employmentsCanSave) {
      dispatch(submit('employmentsForm'));
    }
    if (additionalIncomesCanSave) {
      dispatch(submit('additionalIncomesForm'));
    }
  };

  return (
    <div>
      <FinancialSummary />
      <EmploymentForm />
      <AdditionalIncomeForm />
      {
        hasEditPermission && (
          <StickyFooter
            disabled={!(employmentsCanSave || additionalIncomesCanSave) || submitting || isFetching}
            onClick={handleSubmit}
            buttonText="Save all"
          />
        )
      }
      <LoadingWithOverlay isLoading={submitting || isFetching} />
      <UnsavedChangePrompt shouldConfirm={!employmentsPristine || !additionalIncomesPristine} />
    </div>
  );
};

export default IncomeAndEmploymentContainer;
