import React from 'react';
import PropTypes from 'prop-types';
import LoanTermComponent from './LoanTermComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedLoanTerm = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
      const { loanTerm } = context.loanCriteriaList[index];

      return (
        <LoanTermComponent
          loanTerm={loanTerm}
          dispatchUpdateLoanTerm={context.dispatchUpdateLoanTerm(index)}
          dispatchValidateFixedLoanTerm={context.dispatchValidateFixedLoanTerm(index)}
          dispatchValidateInterestOnlyTerm={context.dispatchValidateInterestOnlyTerm(index)}
        />
        );
    }}
  </ProductSearchContext.Consumer>
);

ConnectedLoanTerm.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedLoanTerm;
