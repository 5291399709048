import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import inputComponentStyles from './InputComponent.module.scss';
import style from './style';

const MAX_NUM = 1000000000;
const isAllowed = a => (a === undefined || (a > 0 && a < MAX_NUM));

const LoanAmountComponent = (props) => {
  const {
    dispatchUpdateLoanAmount, dispatchOnLoanAmountBlur, loanAmount, isRequired,
  } = props;

  return (
    <NumberInput
      label="Loan amount ($)"
      errorMessage={isRequired && !loanAmount ? 'Loan amount is required' : undefined}
      onChange={dispatchUpdateLoanAmount}
      onBlur={dispatchOnLoanAmountBlur}
      value={loanAmount}
      isAllowed={isAllowed}
      style={style}
      textClassName={inputComponentStyles.inputText}
    />
  );
};

LoanAmountComponent.defaultProps = {
  loanAmount: undefined,
  isRequired: false,
};

LoanAmountComponent.propTypes = {
  dispatchUpdateLoanAmount: PropTypes.func.isRequired,
  dispatchOnLoanAmountBlur: PropTypes.func.isRequired,
  loanAmount: PropTypes.number,
  isRequired: PropTypes.bool,
};

export default LoanAmountComponent;
