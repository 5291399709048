import React from 'react';
import PropTypes from 'prop-types';
import { Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import GenerateSoma from './GenerateSoma';
import Somas from './Somas';
import styles from './Soma.module.scss';
import { getMaxLengthValidator } from '../../../shared/utils';

const isPositive = a => (a === undefined || a > 0);

const validateMaxLength = getMaxLengthValidator(500);

const SOMA_ADDITIONAL_COMMENT_PLACEHOLDER = 'Disclose any preferential treatment'
  + ' you have received for this application or may receive as a result of your'
  + ' status with the selected lender, or any conflicts of interest you may have'
  + ' in relation to this application. This text will be displayed in the SOMA.';

const Soma = ({ dirty, hasEditPermission }) => (
  <div>
    <div className={styles.title}>SOMA</div>
    <div className={styles.section}>
      <Field
        name="preSubmission.estimatedCommissionLoanAmount"
        component={ReduxFormNumberInput}
        label="Estimated commission loan amount"
        placeholder="$"
        decimalScale={2}
        prefix="$"
        allowNegative={false}
        isAllowed={isPositive}
        disabled={!hasEditPermission}
      />
    </div>
    <div className={styles.section}>
      <Field
        name="preSubmission.somaAdditionalComment"
        component={ReduxFormTextArea}
        placeholder={SOMA_ADDITIONAL_COMMENT_PLACEHOLDER}
        rows="8"
        disabled={!hasEditPermission}
        label="SOMA additional comments"
        className={styles.textAreaWrapper}
        validate={validateMaxLength}
      />
    </div>
    <GenerateSoma disabled={dirty} />
    <Somas hasEditPermission={hasEditPermission} />
  </div>
);

Soma.propTypes = {
  dirty: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dirty: isDirty('finalise')(state),
});

export default connect(mapStateToProps)(Soma);
