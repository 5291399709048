import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionWrapper.module.scss';

const SectionWrapper = ({ title, children }) => (
  <div className={styles.titleWrapper}>
    <div className={styles.sectionTitle}>{title}</div>
    {children}
  </div>
);

SectionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SectionWrapper;
