import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { formatHouseholdExpenses } from './utils';

export const getExpenseInfo = (state) => get(state, 'application.applicationDetail.finance.expenseInfo');

export const getExpenseInfoInitialValues = createSelector(
  getExpenseInfo,
  (expenseInfo) => ({
    expenseNote: expenseInfo?.expenseNote,
    householdExpenses: formatHouseholdExpenses(expenseInfo?.householdExpenses),
  }),
);
