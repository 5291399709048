import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Checkbox from 'shared/components/formFields/Checkbox';
import { ACTIVE_MISSING_COMMISSIONS, ACTIVE_OTHER, INACTIVE } from './LoanStatus';
import styles from './styles.module.scss';

const LoanStatusSection = ({
 onChange, isSelected, options, title,
}) => (
  <section className={styles.loanStatusSection}>
    <h4>{title}</h4>
    {map(options, (value, key) =>
      (
        <Checkbox
          key={key}
          checked={isSelected(key)}
          labelName={value}
          onChange={() => onChange(key)}
        />
      ))}
  </section>
);

LoanStatusSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  options: PropTypes.oneOf([ACTIVE_MISSING_COMMISSIONS, ACTIVE_OTHER, INACTIVE]).isRequired,
  title: PropTypes.string.isRequired,
};

export default LoanStatusSection;
