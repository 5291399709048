import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import * as GLOSSARY from '../glossary/AOLGlossary';

const SKIP_ALL = '^((?!SKIP_ALL).)*$';

const getEmploymentDescriptor = (prefix, context) => ({
  path: `${prefix}.persons`,
  title: context.getApplicantName(`${prefix}.persons`),
  glossary: GLOSSARY.EMPLOYMENT,
  skips: [SKIP_ALL],
  validate(index, entity) {
    const keys = Object.keys(entity || {});
    return keys.some(key => key === 'employments');
  },
  descriptors: [{
    path: 'employments',
    title: (...args) => context.getEmploymentType(...args)(`${prefix}.persons`),
    skips: ['employer', 'employmentIncomes'],
    descriptors: [{
      path: 'employer',
      title: 'Employer',
    },
      {
        path: 'employmentIncomes',
        title: context.getEmploymentIncomeType,
      },
    ],
  }],
});

const getCompanyIncomeDescriptor = (prefix, context) => ({
  path: `${prefix}.companies`,
  title: context.getApplicantName(`${prefix}.companies`),
  skips: [SKIP_ALL],
  validate(index, entity) {
    const keys = Object.keys(entity || {});
    return keys.some(key => key === 'companyIncomeInfo');
  },
  descriptors: [{
    path: 'companyIncomeInfo.incomes',
    title: context.getCompanyIncomeType,
    glossary: GLOSSARY.COMPANY_INCOME,
  }],
});

const getApplicantDescriptors = (prefix, context) => ([
  {
    path: `${prefix}.persons`,
    title: context.getApplicantName(`${prefix}.persons`),
    getInfo: context.getLinkOfApplicant(`${prefix}.persons`),
    glossary: GLOSSARY.APPLICANT,
    skips: ['employments', 'contactDetails', 'maritalStatus'],
    validate(index, entity) {
      const keys = Object.keys(entity || {});
      return keys.some(key => key !== 'employments');
    },
    descriptors: [{
      path: 'maritalStatus',
      title: (...args) => (isString(args[1]) ? 'Marital status must not be null' : 'Marital status'),
    },
      {
        path: 'contactDetails.addresses',
        title: context.getAddressType,
      },
    ],
  },
  {
    path: `${prefix}.companies`,
    title: context.getApplicantName(`${prefix}.companies`),
    getInfo: context.getLinkOfApplicant(`${prefix}.companies`),
    glossary: GLOSSARY.COMPANY,
    validate(index, entity) {
      const keys = Object.keys(entity || {});
      return keys.some(key => key !== 'companyIncomeInfo');
    },
    skips: ['companyIncomeInfo', 'beneficiaries'],
    descriptors: [
      {
        path: 'beneficiaries',
        title: context.getBeneficiaryKey,
      },
    ],
  },
]);

export default context => [{
    title: 'Application',
    descriptors: [{
        path: 'writer',
        title: arg => (isString(arg) ? arg : 'Adviser'),
        glossary: GLOSSARY.ADVISER,
      },
      {
        path: 'lender',
        title: arg => (isString(arg) ? 'Lender must not be null' : 'Lender'),
        glossary: GLOSSARY.LENDER,
      },
    ],
  },
  {
    title: 'Applicants',
    descriptors: getApplicantDescriptors('applicants', context),
  },
  {
    title: 'Guarantors',
    descriptors: getApplicantDescriptors('guarantors', context),
  },
  {
    title: 'Income and employment',
    descriptors: [
      getEmploymentDescriptor('applicants', context),
      getCompanyIncomeDescriptor('applicants', context),
      getEmploymentDescriptor('guarantors', context),
      getCompanyIncomeDescriptor('guarantors', context),
      {
        path: 'finance.incomes',
        title: context.getAdditionalIncomeType,
        glossary: GLOSSARY.ADDITIONAL_INCOME,
      },
    ],
  },
  {
    title: 'Assets',
    descriptors: [{
        path: 'finance.assets.realEstates',
        title: context.getRealEstateType,
        glossary: GLOSSARY.REAL_ESTATE,
        validate: context.isRealEstate,
      },
      {
        path: 'finance.assets.nonRealEstates',
        title: context.getNonRealEstateType,
        glossary: GLOSSARY.NON_REAL_ESTATE,
      },
    ],
  },
  {
    title: 'Liabilities',
    descriptors: [{
      path: 'finance.liabilities',
      title: context.getLiabilityType,
      glossary: GLOSSARY.LIABILITY,
    }],
  },
  {
    title: 'Expenses',
    descriptors: [{
      path: 'finance.expenseInfo.householdExpenses',
      title: index => `Household(${index + 1})`,
      skips: ['expenses'],
      descriptors: [
        {
          path: 'expenses',
          title: (...args) => `Expense(${args[1] + 1})`,
          glossary: GLOSSARY.HOUSEHOLD_EXPENSE,
        },
      ],
    }],
  },
  {
    title: 'Security',
    descriptors: [{
        path: 'finance.assets',
        title: arg => (isString(arg) ? 'One application must have at least one security' : null),
      },
      {
        path: 'finance.assets.realEstates',
        title: context.getSecurityType,
        glossary: GLOSSARY.SECURITY_ASSET,
        validate: context.isSecurity,
      },
    ],
  },
  {
    title: 'Needs and objectives',
    descriptors: [{
        title: 'Rate',
        path: 'needAndObjective.rate',
        glossary: GLOSSARY.NEEDS_AND_OBJECTIVE_RATE,
      },
      {
        title: 'Repayment',
        path: 'needAndObjective.repayment',
        glossary: GLOSSARY.NEEDS_AND_OBJECTIVE_REMPAYMENT,
      },
      {
        title: 'Product feature',
        path: 'needAndObjective.productFeature',
        glossary: GLOSSARY.NEEDS_AND_OBJECTIVE_PRODUCT_FEATURE,
      },
      {
        path: 'needAndObjective.futureCircumstances',
        glossary: GLOSSARY.FUTURE_CIRCUMSTANCE,
        title: context.getFutureCircumstanceType('Applicant'),
        skips: ['adverseChanges'],
        descriptors: [{
          path: 'adverseChanges.significantChanges',
          title: context.getSignificantChangeType,
        }],
      },
      {
        path: 'needAndObjective.guarantorFutureCircumstances',
        glossary: GLOSSARY.FUTURE_CIRCUMSTANCE,
        title: context.getFutureCircumstanceType('Guarantor'),
        skips: ['adverseChanges'],
        descriptors: [{
          path: 'adverseChanges.significantChanges',
          title: context.getSignificantChangeType,
        }],
      },
    ],
  },
  {
    title: 'Loan details',
    descriptors: [
      {
        path: 'loans',
        title: 'Product must be selected',
        skips: [SKIP_ALL],
        validate: index => isNil(index),
      },
      {
        path: 'loans',
        glossary: GLOSSARY.LOAN,
        title: context.getLoanName,
        validate: context.isProductSelected,
      },
    ],
  },
  {
    title: 'Contacts',
    descriptors: [{
      path: 'thirdParties',
      title: context.getThirdPartyType,
      glossary: GLOSSARY.THIRD_PARTY,
    }],
  },
];
