import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  getAllLendersFromApi,
} from 'shared/api';

export const ACTIONS = {
  GET_ALL_LENDERS_LOADING: 'GET_ALL_LENDERS_LOADING',
  GET_ALL_LENDER_NAMES_SUCCESS: 'GET_ALL_LENDER_NAMES_SUCCESS',
  GET_ALL_LENDERS_ERROR: 'GET_ALL_LENDERS_ERROR',
};

const getLenderNamesFromApi = () => (dispatch) => {
  dispatch({ type: ACTIONS.GET_ALL_LENDERS_LOADING });

  return getAllLendersFromApi()
    .then((lenders) => {
      const lenderNames = map(lenders, 'name');
      dispatch({
        type: ACTIONS.GET_ALL_LENDER_NAMES_SUCCESS,
        lenderNames,
      });
    })
    .catch(error => dispatch({
      type: ACTIONS.GET_ALL_LENDERS_ERROR,
      error,
    }));
};

export const getLenders = () => (dispatch, getState) => {
  const currentNames = getState().productSearch.lenders.lenderNames;
  if (isEmpty(currentNames)) {
    return dispatch(getLenderNamesFromApi());
  }
  return Promise.resolve();
};

const initialState = {
  lenderNames: [],
  hasError: false,
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ALL_LENDERS_LOADING: {
      return {
        ...state,
        lenderNames: [],
        hasError: false,
        isLoading: true,
      };
    }
    case ACTIONS.GET_ALL_LENDER_NAMES_SUCCESS: {
      return {
        ...state,
        lenderNames: action.lenderNames,
        hasError: false,
        isLoading: false,
      };
    }
    case ACTIONS.GET_ALL_LENDERS_ERROR: {
      return {
        ...state,
        lenderNames: [],
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
