import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styles from './SignedFormContent.module.scss';

const SIGNED_FORMS_MAIN_SUMMARY = 'Signed by all applicants';
const UNSIGNED_FORMS_MAIN_SUMMARY = 'Un-checked signed by all applicants';

const renderDetails = (changes, mainSummary, displayHeading = true) => (
  <>
    <div className={styles.tabUpdated}>{ displayHeading ? 'FINALISE' : ''}</div>
    <div className={styles.mainSummary}>{mainSummary}</div>
    <div className={styles.updates}>
      { changes.map(value => <div key={value}>{value}</div>) }
    </div>
  </>
);

const sortByLength = (array) => array.sort((a, b) => a.length - b.length);

const SignedFromContent = ({ changes: { signed: signedForms, unsigned: unsignedForms } }) =>
  (
    <div className={styles.container}>
      {!isEmpty(signedForms) && renderDetails(sortByLength(signedForms), SIGNED_FORMS_MAIN_SUMMARY)}
      {!isEmpty(unsignedForms) && renderDetails(
        sortByLength(unsignedForms),
        UNSIGNED_FORMS_MAIN_SUMMARY,
        isEmpty(signedForms),
      )}
    </div>
  );

SignedFromContent.propTypes = {
  changes: PropTypes.shape().isRequired,
};

export default SignedFromContent;
