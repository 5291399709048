import PropTypes from 'prop-types';

export const serieShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  stack: PropTypes.bool,
  color: PropTypes.string,
});
export const seriesShape = PropTypes.arrayOf(serieShape);
