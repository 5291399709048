import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from '../../../constants/message';

export const splitLoan = (loanId, loanSplitData) =>
client.mutate({
  mutation: gql`mutation($id: String!, $body: SplitLoanRequest!) {
    splitLoan(
      id: $id
      body: $body
    )
  }`,
  variables: {
    id: loanId,
    body: loanSplitData,
  },
  fetchPolicy: 'no-cache',
}, { messageKey: MESSAGE_KEYS.SPLIT_LOAN });
