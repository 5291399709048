import { getUserProfile } from 'shared/api';
import { LOGIN } from 'shared/api/APIEndpoints';
import get from 'lodash/get';
import { setProfile } from './profile';
import { selectBusiness } from './business';
import { LOGGED_IN, LOGGED_OUT } from '../constants';
import { BACKEND_BASE_URL } from '../config/environment';
import { retrieveLocalSelectedBusinessId } from '../store/persistState';
import { getLendersConfig } from './lendersConfig';
import { getAllCategoryLenders } from './lender';
import { getNotifications } from './notification';
import { AUTH_COOKIES, LOGOUT_URL } from './authConfig';

const gotoLogin = () => {
  const query = new URLSearchParams({ redirect_uri: window.location.href }).toString();
  window.location = `${BACKEND_BASE_URL}${LOGIN}?${query}`;
};

export const ACTIONS = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',
  LOGOUT: 'LOGOUT',
};

const ACTIONS_LOGOUT = { type: ACTIONS.LOGOUT };

export const noLongerLoggedIn = (dispatch) => {
  dispatch(ACTIONS_LOGOUT);
  gotoLogin();
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const logout = (onComplete = () => {}) => (dispatch) => {
  dispatch(ACTIONS_LOGOUT);
  AUTH_COOKIES.forEach(cookie => deleteCookie(cookie));
  window.location.href = process.env.REACT_APP_ENV === 'prod' ? LOGOUT_URL.prod : LOGOUT_URL.dev;
  onComplete();
};

const getBusinessId = (businessIdFromUrl, profile) =>
  businessIdFromUrl
  || retrieveLocalSelectedBusinessId()
  || get(profile, 'businesses[0].id');

const createShellBusiness = businessId => ({ id: businessId });

export const checkAuthenticatedUserProfile = businessIdFromUrl => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START });
  return getUserProfile()
    .then((profile) => {
      dispatch(setProfile(profile));
      const businessId = getBusinessId(businessIdFromUrl, profile);
      if (businessId) {
        const business = profile.businesses.find(
            businessFromProfile => businessFromProfile.id === businessId,
          ) || createShellBusiness(businessId);
        dispatch(selectBusiness(business));
      }
      dispatch({ type: ACTIONS.LOGIN_SUCCESS });
      dispatch(getLendersConfig());
      dispatch(getAllCategoryLenders());
      dispatch(getNotifications());
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.GET_USER_PROFILE_ERROR, error });
    });
};

const initialState = {
  loginStatus: LOGGED_OUT,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN_START: {
      return { ...state, loginStatus: LOGGED_OUT };
    }
    case ACTIONS.LOGIN_SUCCESS: {
      return { ...state, loginStatus: LOGGED_IN };
    }
    case ACTIONS.LOGOUT: {
      return { ...state, loginStatus: LOGGED_OUT };
    }
    default:
      return state;
  }
};
