import React from 'react';
import PropTypes from 'prop-types';
import SubTotalSection from '../components/SubTotalSection';
import { calculateTotalConstructionCosts } from '../../../redux/selectors/fundingWorksheet/utils';
import { ConstructionShape } from '../../shared/shapes/FundingWorksheetShapes';

const SubtotalConstructionCosts = ({ construction, constructionAmount, userGuidanceCode }) => (
  <SubTotalSection
    total={calculateTotalConstructionCosts(construction, constructionAmount)}
    title={`Construction costs (${userGuidanceCode})`}
  />
);

SubtotalConstructionCosts.defaultProps = {
  constructionAmount: 0,
};

SubtotalConstructionCosts.propTypes = {
  construction: ConstructionShape.isRequired,
  constructionAmount: PropTypes.number,
  userGuidanceCode: PropTypes.string.isRequired,
};

export default SubtotalConstructionCosts;
