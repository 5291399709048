import PropTypes from 'prop-types';

export default PropTypes.shape({
  totalLoanAmount: PropTypes.number,
  securityValue: PropTypes.number,
  loanValueRatio: PropTypes.number,
  productType: PropTypes.string,
  selectedLenderNames: PropTypes.arrayOf(PropTypes.string),
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
});
