import GLOSSARY from '../glossary/SettlementGlossary';

export default context => [
  {
    title: 'Application',
    descriptors: [
      {
        path: 'applicationStatus.submittedDate',
        title: 'Submitted date',
      },
      {
        path: 'writer',
        title: 'Adviser',
        glossary: GLOSSARY.ADVISER,
      },
    ],
  },
  {
    title: 'Finance',
    descriptors: [
      {
        path: 'finance.liabilities',
        title: index => `Liabilities #${index + 1}`,
        glossary: GLOSSARY.LIABILITY,
      },
    ],
  },
  {
    title: 'Applicant/s',
    descriptors: [
      {
        path: 'applicants.persons',
        title: context.getPersonName,
        glossary: GLOSSARY.APPLICANT,
      },
      {
        path: 'applicants.companies',
        title: context.getCompanyName,
        glossary: GLOSSARY.APPLICANT,
      },
    ],
  },
  {
    title: 'Security',
    descriptors: [
      {
        path: 'finance.assets.realEstates',
        title: context.getSecurityType,
        glossary: GLOSSARY.REAL_ESTATE,
      },
    ],
  },
  {
    title: 'Loan details',
    descriptors: [
      {
        path: 'loans',
        title: context.getLoanName,
        glossary: GLOSSARY.LOAN,
      },
    ],
  },
  {
    title: 'Insurance referral',
    descriptors: [
      {
        path: 'insuranceReferrals',
        title: 'Loan protection insurance',
        glossary: GLOSSARY.INSURANCE_REFERRAL,
      },
    ],
  },
];
