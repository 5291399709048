import React from 'react';
import PropTypes from 'prop-types';
import { emptyToNA, toDollarAmount, toPercentage } from 'shared/formatterUtils';
import getStyles from './styles';

const ApplicationCriteriaSummary = ({ applicationCriteria, isSingleLoan }) => {
  const styles = getStyles(isSingleLoan);
  const {
    selectedLenderNames,
    totalLoanAmount,
    securityValue,
    loanValueRatio,
  } = applicationCriteria || {};

  const lenderNamesText = (selectedLenderNames || []).join(', ') || 'N/A';

  return (
    <>
      {!isSingleLoan
        && (
        <>
          <div className="application-criteria-summary__label" style={styles.sectionLabel}>APPLICATION</div>
          <div className="application-criteria-summary__total-amount" style={styles.criteriaField}>
            <div style={styles.criteriaField.label}>Total loan amount</div>
            <div>{toDollarAmount(totalLoanAmount)}</div>
          </div>
          <div className="application-criteria-summary__security-value" style={styles.criteriaField}>
            <div style={styles.criteriaField.label}>Total security value</div>
            <div>{emptyToNA(securityValue, toDollarAmount)}</div>
          </div>
          <div className="application-criteria-summary__lvr" style={styles.criteriaField}>
            <div style={styles.criteriaField.label}>LVR</div>
            <div>{emptyToNA(loanValueRatio, toPercentage)}</div>
          </div>
        </>
      )}
      <div className="application-criteria-summary__lender-names" style={styles.lenderNameField}>
        <div style={styles.criteriaField.label}>Lenders</div>
        <div>{lenderNamesText}</div>
      </div>
    </>
  );
};

ApplicationCriteriaSummary.propTypes = {
  applicationCriteria: PropTypes.shape({
    selectedLenderNames: PropTypes.array,
    totalLoanAmount: PropTypes.number,
    securityValue: PropTypes.number,
    loanValueRatio: PropTypes.number,
  }).isRequired,
  isSingleLoan: PropTypes.bool,
};

ApplicationCriteriaSummary.defaultProps = {
  isSingleLoan: false,
};

export default ApplicationCriteriaSummary;
