import React from 'react';
import find from 'lodash/find';
import Checkbox from 'shared/components/formFields/Checkbox';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import styles from './styles';
import cssStyles from './style.module.scss';
import getRowNameCell from './CellTemplates';

export const getFeatureHeader = (row) => {
  const {
    hasFeatures,
    includeCommentsInPrintout,
    onToggleIncludeCommentsInPrintout,
    onEditFeature,
    props: {
      readOnly,
    },
  } = row;
  const commentPrintControl = hasFeatures && (
    <div style={{ marginTop: 'var(--space)', textAlign: 'left' }} key="commentPrintControl">
      <Checkbox
        disabled={readOnly}
        checked={includeCommentsInPrintout}
        onChange={onToggleIncludeCommentsInPrintout}
        className={cssStyles.includeCommentInPrintControl}
        labelName="Include comments in printout"
      />
    </div>
  );

  const addFeatureButtonStyle = {
    padding: 0,
    height: '2.4rem',
    minWidth: '6rem',
  };
  const addFeatureButton = (
    <SecondaryButton
      disabled={readOnly}
      onClick={onEditFeature}
      key="addFeatureButton"
      style={addFeatureButtonStyle}
    >
      + Add
    </SecondaryButton>
  );
  return (
    <div className={cssStyles.featureSectionHeader}>
      {getRowNameCell('features', null, [addFeatureButton, commentPrintControl])}
    </div>
  );
};

export const getFeature = ({ type, rowName }) => (getRowNameCell(type === 'comments' ? 'comments' : rowName));

export const getFeatureCell = (
  row,
  { variant },
) => {
  const {
    props: {
      selectedFeatureNames,
      selectedFeaturesFromComparison,
    },
    rowName,
    type,
  } = row;

  const geAvailability = (imgSrc, availability) => (
    <div>
      <img src={imgSrc} alt={availability} style={styles.availabilityIcon} />
      <span style={styles.availability}>{availability}</span>
    </div>
    );

  const availabilityDom = (isAvailable) => {
    const imgSrc = isAvailable ? '/green-tick-icon.svg' : '/x-icon.svg';
    const availability = isAvailable ? 'Available' : 'Not available';
    return geAvailability(imgSrc, availability);
  };

  const customProductAvailabilityDom = () => {
    const imgSrc = '/information-icon-filled-orange.svg';
    const availability = 'See comments';
    return geAvailability(imgSrc, availability);
  };

  const toFeaturePresenter = (featureName) => {
    const featureDetail = find(variant.featureDetails, f => f.featureName === featureName);
    return variant.customProduct
      ? {
        featureName,
        availability: customProductAvailabilityDom(),
        comments: '',
      }
      : {
        featureName,
        availability: availabilityDom(featureDetail),
        comments: featureDetail && featureDetail.comments,
      };
  };

  const variantPresenter = {
    variantId: variant.id,
    featurePresenters: selectedFeatureNames
      .concat(selectedFeaturesFromComparison)
      .map(toFeaturePresenter),
  };

  const featurePresenter = variantPresenter.featurePresenters.find(
    fp => fp.featureName === rowName,
  );

  return (
    <div style={styles.featureComments}>
      {featurePresenter[type]}
    </div>
  );
};
