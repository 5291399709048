/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import styles from './ValidationModal.module.scss';

const Node = props => {
  const { name, nodes, info: { link } } = props;
  return (
    <div className={styles.node}>
      <li className={classNames(styles.name, { [styles.required]: isEmpty(nodes) })}>
        {link ? <a href={link} target="_blank" rel="noopener noreferrer">{name}</a> : name}
      </li>
      <div className={styles.nodes}>
        {nodes.map((node, index) => node && <Node key={index} {...node} />)}
      </div>
    </div>
      );
};

Node.defaultProps = {
  nodes: [],
  info: {},
};

Node.propTypes = {
  name: PropTypes.string.isRequired,
  nodes: PropTypes.array,
  info: PropTypes.object,
};

export default Node;
