import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import get from 'lodash/get';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { toDollarAmount } from 'shared/formatterUtils';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import { MENU_SIZE } from 'shared/components/formFields/Select/Select';
import { GET_EXISTING_LOANS_WITH_MORE_INFO } from '../../../redux/existingLoanGraphQL';
import { repaymentTypeFromLoan, interestTypeFromLoan } from '../constants';

const getLoanLabel = loan =>
  `${toDollarAmount(loan.settledAmount)}, ${loan.lenderName}, ${loan.productName}, ${loan.applicantsName}, ${loan.accountNumber}`;

const getExistingLoanOptions = (loans, allOtherExistingLoanIds) =>
  loans?.filter(loan => !includes(allOtherExistingLoanIds, loan.id))
  .map(loan => ({
    label: getLoanLabel(loan),
    value: {
      id: loan.id,
      accountNumber: loan.accountNumber,
      settledAmount: loan.settledAmount,
      lenderName: loan.lenderName,
      productName: loan.productName,
      applicantsName: loan.applicantsName,
    },
  }));

const getApplicants = (allApplicants, ownerships) => ({
  persons: allApplicants.persons
    .filter(p => ownerships.find(ownership => ownership.applicantId === p.id))
    .map(p => ({ id: p.id })),
  companies: allApplicants.companies
    .filter(c => ownerships.find(ownership => ownership.applicantId === c.id))
    .map(c => ({ id: c.id })),
});

const getOnChange = (props, fieldName) => get(props, fieldName)?.input.onChange;

const ExistingLoanSelect = ({
  namePrefix, businessId, allApplicants, ownerships, lenderId, allOtherExistingLoanIds, label, className, ...props
}) => {
  const existingLoan = get(props, `${namePrefix}.existingLoan`);
  const { loading, data } = useQuery(GET_EXISTING_LOANS_WITH_MORE_INFO, {
    variables: {
      businessId,
      lenderId,
      applicants: getApplicants(allApplicants, ownerships),
    },
  });
  const existingLoanOption = useMemo(() => {
    const loans = get(data, 'loansByApplicants.loans');
    return getExistingLoanOptions(loans, allOtherExistingLoanIds);
  }, [data, allOtherExistingLoanIds]);

  const selectedValue = get(existingLoan, 'input.value');
  const selectedOptionValue = useMemo(() => {
    if (selectedValue === undefined || existingLoanOption === undefined) {
      return undefined;
    }
    const selectedOption = existingLoanOption.find(option => option.value.id === selectedValue.id);
    return get(selectedOption, 'value');
  }, [existingLoanOption, selectedValue]);

  const onChange = useCallback(value => {
    if (!value) return;
    const { id } = value;
    const selectedLoan = get(data, 'loansByApplicants.loans')?.find(loan => loan.id === id);
    if (!selectedLoan) return;
    const {
      lenderName, settledAmount, repaymentType, productType, loanTerm,
    } = selectedLoan;
    getOnChange(props, `${namePrefix}.accountDetails.lender`)(lenderName);
    getOnChange(props, `${namePrefix}.limit`)(settledAmount);
    getOnChange(props, `${namePrefix}.paymentType`)(repaymentTypeFromLoan[repaymentType]);
    getOnChange(props, `${namePrefix}.interestType`)(interestTypeFromLoan[productType]);
    getOnChange(props, `${namePrefix}.originalTermYears`)(loanTerm);
  }, [data, namePrefix, props]);
  return (
    <Field
      name={`${namePrefix}.existingLoan`}
      component={ReduxFormSelect}
      value={selectedOptionValue}
      onChange={onChange}
      props={{
        label,
        className,
        menuSize: MENU_SIZE.LARGE,
        loading,
        options: existingLoanOption,
        readOnlyFormatter: () => selectedValue && getLoanLabel(selectedValue),
        isClearable: true,
      }}
    />
  );
};

ExistingLoanSelect.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  allApplicants: PropTypes.shape({
    persons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  ownerships: PropTypes.arrayOf(PropTypes.shape({
    applicantId: PropTypes.string.isRequired,
  })).isRequired,
  lenderId: PropTypes.string,
  allOtherExistingLoanIds: PropTypes.arrayOf(PropTypes.string),
};

ExistingLoanSelect.defaultProps = {
  className: '',
  lenderId: undefined,
  allOtherExistingLoanIds: [],
};

export default ExistingLoanSelect;
