import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

export const LOAN_IN_ARREARS = 'Loan in arrears';
export const PAID_OUT = 'Paid out';
const LOAN_STATUS_OPTIONS = [
  { label: 'Refinanced by lender (inactive)', value: 'Refinanced by lender' },
  { label: 'Refinanced by Smartline (inactive)', value: 'Refinanced by Smartline' },
  { label: 'Refinanced by competitor (inactive)', value: 'Refinanced by competitor' },
  { label: 'Refinanced by unknown (inactive)', value: 'Refinanced by unknown' },
  { label: 'Topped up (inactive)', value: 'Topped up' },
  { label: LOAN_IN_ARREARS, value: LOAN_IN_ARREARS },
  { label: 'Paid out (inactive)', value: PAID_OUT },
];

export const getLoanStatusDescription = loan => (loan.isActive ? loan.status : `${loan.status} (inactive)`);

export const getLoanStatusOptions = (loan) => {
  const currentLoanStatusOption = {
    label: getLoanStatusDescription(loan),
    value: loan.status,
  };

  const filteredOptions = filter(LOAN_STATUS_OPTIONS, option => option.label !== currentLoanStatusOption.label);

  if (isEmpty(loan.reconciledDate)) {
    return [
      currentLoanStatusOption,
      ...filter(filteredOptions, option => ![LOAN_IN_ARREARS, PAID_OUT].includes(option.value)),
    ];
  }
  return [currentLoanStatusOption, ...filteredOptions];
};

export const getOrderedLoanStatusOptions = loan =>
  orderBy(getLoanStatusOptions(loan), ['label'], ['asc']);
