import { ACTIONS } from './applicationCriteriaActions';
import { ACTIONS as LOAN_CRITERIA_ACTIONS } from '../loanCriteriaList/loanCriteriaActions';
import { ACTIONS as COMPARISON_ACTIONS } from '../comparison';
import {
  initialState,
  processAddLoanCriteria,
  processModifyCriteria,
  processResetSearchCriteria,
  processSetLoanValueRatio,
  processSetSecurityValue,
  processSetTotalLoanAmount,
  processSubmitModifiedCriteria,
  processUpdateCriteria,
  processUpdateSelectedLenderNames,
} from './applicationCriteria';

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAN_CRITERIA_ACTIONS.ADD_LOAN_CRITERIA: {
      return processAddLoanCriteria(state);
    }
    case ACTIONS.UPDATE_CRITERIA: {
      return processUpdateCriteria(state, action);
    }
    case ACTIONS.SET_TOTAL_LOAN_AMOUNT: {
      return processSetTotalLoanAmount(state, action);
    }
    case ACTIONS.SET_SECURITY_VALUE: {
      return processSetSecurityValue(state, action);
    }
    case ACTIONS.SET_LOAN_VALUE_RATIO: {
      return processSetLoanValueRatio(action, state);
    }
    case ACTIONS.UPDATE_SELECTED_LENDER_NAMES: {
      return processUpdateSelectedLenderNames(state, action);
    }
    case ACTIONS.MODIFY_CRITERIA: {
      return processModifyCriteria(state);
    }
    case ACTIONS.SUBMIT_MODIFIED_CRITERIA: {
      return processSubmitModifiedCriteria(state);
    }
    case COMPARISON_ACTIONS.RESET_SEARCH_CRITERIA: {
      return processResetSearchCriteria(action);
    }
    default:
      return state;
  }
};
