import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import map from 'lodash/map';
import { STREET_TYPE } from 'ApplicationTracking/utils/address';
import { ADDRESS_TYPE, STATE_OPTIONS } from '../AddressInput/constants';

export const AddressShape = PropTypes.shape({
  nonStdAddress: PropTypes.string,
  buildingName: PropTypes.string,
  floorNo: PropTypes.string,
  unitNo: PropTypes.string,
  street: PropTypes.shape({
    type: PropTypes.oneOf(keys(STREET_TYPE)),
    name: PropTypes.string,
    no: PropTypes.string,
  }),
  city: PropTypes.string,
  state: PropTypes.oneOf(map(STATE_OPTIONS, 'value')),
  postcode: PropTypes.string,
  countryCode: PropTypes.string,
  addressType: PropTypes.oneOf(keys(ADDRESS_TYPE)),
});
