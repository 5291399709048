import React from 'react';
import PropTypes from 'prop-types';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import { formatAmount } from 'utils/formatters';

const getAssetFinance = loan => ([
  {
    title: 'Referring adviser ',
    content: loan.referringAdviser,
  },
  {
    title: 'Contract Number',
    content: loan.contractNumber,
  },
  {
    title: 'Commission amount (excludes origination fee)',
    content: formatAmount(loan.commissionExcludingOriginationFee),
  },
  {
    title: 'Origination fee',
    content: formatAmount(loan.originationFee),
  },
]);

const AssetFinance = ({ loan }) => (
  <FieldsContainer fields={getAssetFinance(loan)} title="Internally referred asset finance" />
);

AssetFinance.propTypes = {
  loan: PropTypes.shape({
    referringAdviser: PropTypes.string,
    contractNumber: PropTypes.string,
    commissionExcludingOriginationFee: PropTypes.number,
    originationFee: PropTypes.number,
  }).isRequired,
};

export default AssetFinance;
