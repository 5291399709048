import React from 'react';

export default (confirm, isApplicant) => confirm({
  primaryButtonText: 'Confirm',
  secondaryButtonText: 'Cancel',
  title: `Remove ${isApplicant ? 'applicant' : 'guarantor'}`,
  message: (
    <div>
      <p>
        Are you sure you want to remove this {isApplicant ? 'applicant' : 'guarantor'}?
      </p>
      <p>
        Once you save this page, the action cannot be undone and their assets, liabilities and expense information
        will be removed from all parts of the application.
      </p>
    </div>
  ),
});
