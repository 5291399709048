import React from 'react';
import styles from './styles';

const NoAccess = () => (
  <div style={styles.box}>
    <div style={{ ...styles.commonText, ...styles.title }}>Access not allowed.</div>
    <div style={{ ...styles.commonText, ...styles.text }}>
      You do not have access to this page. Please contact your
      <br />
      franchise owner to have permissions granted.
    </div>
  </div>
);

export default NoAccess;
