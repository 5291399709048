import React from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import { Field } from 'redux-form';
import ReduxFromTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import ReduxFromSelect from 'shared/components/formFields/ReduxForm/Select';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import styles from './ThirdParty.module.scss';
import { THIRD_PARTY_TYPE_MAP } from '../constants';

const TYPE_OPTIONS = concat(sortBy(keys(THIRD_PARTY_TYPE_MAP).filter(key => key !== 'Other')
  .map(key => ({
    label: THIRD_PARTY_TYPE_MAP[key],
    value: key,
  })), 'label'), { label: 'Other', value: 'Other' });

const ThirdParty = ({
  fieldNamePrefix, hasEditPermission, isSearchedThirdParty, onRemove,
}) => (
  <>
    <div className={styles.container}>
      {isSearchedThirdParty ? (
        <Field
          label="Type"
          name={`${fieldNamePrefix}.type`}
          component={PlainTextField}
          className={styles.plainText}
        />
      ) : (
        <Field
          label="Type"
          name={`${fieldNamePrefix}.type`}
          component={ReduxFromSelect}
          options={TYPE_OPTIONS}
          menuPlacement="auto"
          props={{
                className: styles.type,
                type: 'text',
              }}
        />
      )}
      <Field
        label="Company name"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.companyName`}
        component={ReduxFromTextInput}
        props={{
              type: 'text',
              className: styles.companyName,
            }}
      />
      <Field
        label="First name"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.firstName`}
        component={ReduxFromTextInput}
        props={{
              className: styles.firstName,
              type: 'text',
            }}
      />
      <Field
        label="Surname"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.surname`}
        component={ReduxFromTextInput}
        props={{
              className: styles.surname,
              type: 'text',
            }}
      />
      <Field
        label="Email"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.emailAddress`}
        component={ReduxFromTextInput}
        props={{
              className: styles.emailAddress,
              type: 'text',
            }}
      />
      <Field
        label="Mobile"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.mobile`}
        component={ReduxFromTextInput}
        props={{
              className: styles.mobile,
              type: 'text',
            }}
      />
      <Field
        label="Work"
        readOnly={!hasEditPermission || isSearchedThirdParty}
        name={`${fieldNamePrefix}.workPhone`}
        component={ReduxFromTextInput}
        props={{
              className: styles.workPhone,
              type: 'text',
            }}
      />
      {hasEditPermission
            && (
            <TertiaryButton
              className={styles.remove}
              onClick={onRemove}
            >
              Remove
            </TertiaryButton>
        )}
    </div>
  </>
  );

ThirdParty.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  isSearchedThirdParty: PropTypes.bool.isRequired,
};

export default ThirdParty;
