export const STREET_TYPE = {
  Access: 'Access',
  Accessway: 'Accessway',
  Alley: 'Alley',
  Alleyway: 'Alleyway',
  Amble: 'Amble',
  Anchorage: 'Anchorage',
  Approach: 'Approach',
  Arcade: 'Arcade',
  Arch: 'Arch',
  Arterial: 'Arterial',
  Artery: 'Artery',
  Avenue: 'Avenue',
  Banan: 'Banan',
  Bank: 'Bank',
  Basin: 'Basin',
  Bay: 'Bay',
  Beach: 'Beach',
  Belt: 'Belt',
  Bend: 'Bend',
  Block: 'Block',
  Bluff: 'Bluff',
  Boardwalk: 'Boardwalk',
  Boulevard: 'Boulevard',
  Boulevarde: 'Boulevarde',
  Bowl: 'Bowl',
  Brace: 'Brace',
  Brae: 'Brae',
  Break: 'Break',
  Briars: 'Briars',
  Bridge: 'Bridge',
  Broadway: 'Broadway',
  Brow: 'Brow',
  Bypass: 'Bypass',
  Byway: 'Byway',
  Canal: 'Canal',
  Causeway: 'Causeway',
  Centre: 'Centre',
  Centreway: 'Centreway',
  Chase: 'Chase',
  Circle: 'Circle',
  Circlet: 'Circlet',
  Circuit: 'Circuit',
  Circus: 'Circus',
  Claim: 'Claim',
  Close: 'Close',
  Cluster: 'Cluster',
  Colonnade: 'Colonnade',
  Common: 'Common',
  Concourse: 'Concourse',
  Connection: 'Connection',
  Copse: 'Copse',
  Corner: 'Corner',
  Corso: 'Corso',
  Course: 'Course',
  Court: 'Court',
  Courts: 'Courts',
  Courtyard: 'Courtyard',
  Cove: 'Cove',
  Creek: 'Creek',
  Crescent: 'Crescent',
  Crest: 'Crest',
  Cross: 'Cross',
  Crossing: 'Crossing',
  Crossroad: 'Crossroad',
  Crossway: 'Crossway',
  Cruiseway: 'Cruiseway',
  Cul: 'Cul',
  'Cul-De-Sac': 'Cul-De-Sac',
  Cutting: 'Cutting',
  Dale: 'Dale',
  Dell: 'Dell',
  Dene: 'Dene',
  Deviation: 'Deviation',
  Dip: 'Dip',
  Distributor: 'Distributor',
  Divide: 'Divide',
  Dock: 'Dock',
  Domain: 'Domain',
  Downs: 'Downs',
  Drive: 'Drive',
  Driveway: 'Driveway',
  Dune: 'Dune',
  Edge: 'Edge',
  Elbow: 'Elbow',
  Elm: 'Elm',
  End: 'End',
  Entrance: 'Entrance',
  Esplanade: 'Esplanade',
  Estate: 'Estate',
  Estuary: 'Estuary',
  Expressway: 'Expressway',
  Extension: 'Extension',
  Fairway: 'Fairway',
  Fall: 'Fall',
  Fare: 'Fare',
  Farms: 'Farms',
  Fen: 'Fen',
  Fern: 'Fern',
  FireTrack: 'Fire Track',
  Firebreak: 'Firebreak',
  Fireline: 'Fireline',
  Firetrack: 'Firetrack',
  Firetrail: 'Firetrail',
  Flat: 'Flat',
  Flats: 'Flats',
  Follow: 'Follow',
  Footway: 'Footway',
  Ford: 'Ford',
  Foreshore: 'Foreshore',
  Formation: 'Formation',
  Freeway: 'Freeway',
  Front: 'Front',
  Frontage: 'Frontage',
  Gap: 'Gap',
  Garden: 'Garden',
  Gardens: 'Gardens',
  Gate: 'Gate',
  Gates: 'Gates',
  Gateway: 'Gateway',
  Glade: 'Glade',
  Glen: 'Glen',
  Grange: 'Grange',
  Green: 'Green',
  Ground: 'Ground',
  Grove: 'Grove',
  Gully: 'Gully',
  Harbour: 'Harbour',
  Haven: 'Haven',
  Head: 'Head',
  Heath: 'Heath',
  Heights: 'Heights',
  Highroad: 'Highroad',
  Highway: 'Highway',
  Hill: 'Hill',
  Hollow: 'Hollow',
  Hub: 'Hub',
  Interchange: 'Interchange',
  Intersection: 'Intersection',
  Island: 'Island',
  Junction: 'Junction',
  Key: 'Key',
  Keys: 'Keys',
  Knob: 'Knob',
  Ladder: 'Ladder',
  Landing: 'Landing',
  Lane: 'Lane',
  Laneway: 'Laneway',
  Lees: 'Lees',
  Line: 'Line',
  Link: 'Link',
  Little: 'Little',
  Lookout: 'Lookout',
  Loop: 'Loop',
  Lower: 'Lower',
  Mall: 'Mall',
  Manor: 'Manor',
  Mead: 'Mead',
  Meadows: 'Meadows',
  Meander: 'Meander',
  Mears: 'Mears',
  Mew: 'Mew',
  Mews: 'Mews',
  Mile: 'Mile',
  Motorway: 'Motorway',
  Motu: 'Motu',
  Mount: 'Mount',
  Neaves: 'Neaves',
  Nook: 'Nook',
  Oaks: 'Oaks',
  Outlet: 'Outlet',
  Outlook: 'Outlook',
  Paddock: 'Paddock',
  Paku: 'Paku',
  Parade: 'Parade',
  Park: 'Park',
  Parklands: 'Parklands',
  Parkway: 'Parkway',
  Part: 'Part',
  Pass: 'Pass',
  Passage: 'Passage',
  Path: 'Path',
  Pathway: 'Pathway',
  Piazza: 'Piazza',
  Pier: 'Pier',
  Place: 'Place',
  Plateau: 'Plateau',
  Plaza: 'Plaza',
  Pocket: 'Pocket',
  Point: 'Point',
  Port: 'Port',
  Portal: 'Portal',
  Priors: 'Priors',
  Promenade: 'Promenade',
  Pursuit: 'Pursuit',
  Quad: 'Quad',
  Quadrangle: 'Quadrangle',
  Quadrant: 'Quadrant',
  Quay: 'Quay',
  Quays: 'Quays',
  Ramble: 'Ramble',
  Ramp: 'Ramp',
  Range: 'Range',
  Reach: 'Reach',
  Reef: 'Reef',
  Reserve: 'Reserve',
  Rest: 'Rest',
  Retreat: 'Retreat',
  Return: 'Return',
  Ride: 'Ride',
  Ridge: 'Ridge',
  Ridgeway: 'Ridgeway',
  RightOfWay: 'Right Of Way',
  Ring: 'Ring',
  Rise: 'Rise',
  Rising: 'Rising',
  River: 'River',
  Riverway: 'Riverway',
  Riviera: 'Riviera',
  Road: 'Road',
  Roads: 'Roads',
  Roadside: 'Roadside',
  Roadway: 'Roadway',
  Ronde: 'Ronde',
  Rosebowl: 'Rosebowl',
  Rotary: 'Rotary',
  Round: 'Round',
  Route: 'Route',
  Row: 'Row',
  Rue: 'Rue',
  Run: 'Run',
  ServiceLane: 'Service Lane',
  ServiceWay: 'Service Way',
  Shunt: 'Shunt',
  Siding: 'Siding',
  Slope: 'Slope',
  Sound: 'Sound',
  Spa: 'Spa',
  Spur: 'Spur',
  Square: 'Square',
  Stairs: 'Stairs',
  StateHighway: 'State Highway',
  Steep: 'Steep',
  Steps: 'Steps',
  Straight: 'Straight',
  Strand: 'Strand',
  Street: 'Street',
  Streets: 'Streets',
  Strip: 'Strip',
  Subway: 'Subway',
  Tarn: 'Tarn',
  TarniceWay: 'Tarnice Way',
  Terrace: 'Terrace',
  Thoroughfare: 'Thoroughfare',
  Throughway: 'Throughway',
  Tollway: 'Tollway',
  Top: 'Top',
  Tor: 'Tor',
  Towers: 'Towers',
  Track: 'Track',
  Trail: 'Trail',
  Trailer: 'Trailer',
  Tramway: 'Tramway',
  Trees: 'Trees',
  Triangle: 'Triangle',
  Trunkway: 'Trunkway',
  Turn: 'Turn',
  Twist: 'Twist',
  Underpass: 'Underpass',
  Upper: 'Upper',
  Vale: 'Vale',
  Valley: 'Valley',
  Venus: 'Venus',
  Viaduct: 'Viaduct',
  View: 'View',
  Views: 'Views',
  Village: 'Village',
  Villas: 'Villas',
  Vista: 'Vista',
  Vue: 'Vue',
  Wade: 'Wade',
  Walk: 'Walk',
  Walkway: 'Walkway',
  Waters: 'Waters',
  Waterway: 'Waterway',
  Way: 'Way',
  Wharf: 'Wharf',
  Woods: 'Woods',
  Wynd: 'Wynd',
  Yard: 'Yard',
};

const STREET_TYPE_ABBR = {
  accs: 'access',
  ally: 'alley',
  alwy: 'alleyway',
  ambl: 'amble',
  ancg: 'anchorage',
  app: 'approach',
  arc: 'arcade',
  art: 'artery',
  artl: 'arterial',
  av: 'avenue',
  ave: 'avenue',
  ba: 'banan',
  bank: 'bank',
  basn: 'basin',
  bay: 'bay',
  bch: 'beach',
  bdge: 'bridge',
  bdwy: 'broadway',
  bend: 'bend',
  blk: 'block',
  bowl: 'bowl',
  br: 'brace',
  brae: 'brae',
  brce: 'brace',
  brk: 'break',
  brow: 'brow',
  bswy: 'busway',
  bvd: 'boulevard',
  bvde: 'boulevarde',
  bwlk: 'boardwalk',
  bypa: 'bypass',
  bywy: 'byway',
  caus: 'causeway',
  cct: 'circuit',
  cds: 'culdesac',
  ch: 'chase',
  cir: 'circle',
  cl: 'close',
  clde: 'colonnade',
  clr: 'circle',
  clt: 'circlet',
  cmmn: 'common',
  cmmns: 'commons',
  cncd: 'concord',
  cnr: 'corner',
  cntn: 'connection',
  cnwy: 'centreway',
  con: 'concourse',
  cove: 'cove',
  cowy: 'crossway',
  cps: 'copse',
  cr: 'crescent',
  crcs: 'circus',
  crd: 'crossroad',
  cres: 'crescent',
  crf: 'crief',
  crse: 'course',
  crsg: 'crossing',
  crss: 'cross',
  crst: 'crest',
  cso: 'corso',
  cswy: 'causeway',
  ct: 'court',
  ctr: 'centre',
  cttg: 'cutting',
  ctyd: 'courtyard',
  cutt: 'cutting',
  cuwy: 'cruiseway',
  dale: 'dale',
  dash: 'dash',
  de: 'deviation',
  dell: 'dell',
  dene: 'dene',
  devn: 'deviation',
  dip: 'dip',
  div: 'divide',
  dock: 'dock',
  dom: 'domain',
  down: 'down',
  dr: 'drive',
  drwy: 'driveway',
  dstr: 'distributor',
  dvwy: 'driveway',
  edge: 'edge',
  elb: 'elbow',
  end: 'end',
  ent: 'entrance',
  esmt: 'easement',
  esp: 'esplanade',
  est: 'estate',
  exp: 'expressway',
  extn: 'extension',
  fawy: 'fairway',
  fitr: 'firetrail',
  flat: 'flat',
  flne: 'fireline',
  folw: 'follow',
  ford: 'ford',
  fork: 'fork',
  form: 'formation',
  frnt: 'front',
  frtg: 'frontage',
  fshr: 'foreshore',
  ftrk: 'firetrack',
  ftwy: 'footway',
  fwy: 'freeway',
  gap: 'gap',
  gdn: 'garden',
  gdns: 'gardens',
  gld: 'glade',
  glde: 'glade',
  glen: 'glen',
  gly: 'gully',
  gr: 'grove',
  gra: 'grange',
  grn: 'green',
  grnd: 'ground',
  gte: 'gate',
  gtes: 'gates',
  gwy: 'gateway',
  hill: 'hill',
  hllw: 'hollow',
  hrbr: 'harbour',
  hrd: 'highroad',
  hth: 'heath',
  hts: 'heights',
  hub: 'hub',
  hvn: 'haven',
  hwy: 'highway',
  id: 'island',
  intg: 'interchange',
  intn: 'intersection',
  jnc: 'junction',
  key: 'key',
  keys: 'keys',
  lane: 'lane',
  ldg: 'landing',
  lees: 'lees',
  line: 'line',
  link: 'link',
  lkt: 'lookout',
  lnwy: 'laneway',
  loop: 'loop',
  lt: 'little',
  lwr: 'lower',
  lynn: 'lynne',
  mall: 'mall',
  manr: 'manor',
  mead: 'mead',
  mew: 'mew',
  mews: 'mews',
  mndr: 'meander',
  mt: 'mount',
  mtwy: 'motorway',
  mwy: 'motorway',
  nook: 'nook',
  nth: 'north',
  otlk: 'outlook',
  otlt: 'outlet',
  park: 'park',
  part: 'part',
  pass: 'pass',
  path: 'path',
  pde: 'parade',
  phwy: 'pathway',
  piaz: 'piazza',
  pkld: 'parklands',
  pkt: 'pocket',
  pkwy: 'parkway',
  pl: 'place',
  plat: 'plateau',
  plza: 'plaza',
  pnt: 'point',
  port: 'port',
  prom: 'promenade',
  prec: 'precinct',
  prst: 'pursuit',
  psge: 'passage',
  pway: 'pathway',
  pwy: 'parkway',
  qdgl: 'quadrangle',
  qdrt: 'quadrant',
  quad: 'quad',
  qy: 'quay',
  qys: 'quays',
  ramp: 'ramp',
  rch: 'reach',
  rd: 'road',
  rdge: 'ridge',
  rds: 'roads',
  rdsd: 'roadside',
  rdwy: 'roadway',
  res: 'reserve',
  rest: 'rest',
  rgwy: 'ridgeway',
  ride: 'ride',
  ring: 'ring',
  rise: 'rise',
  rmbl: 'ramble',
  rnd: 'round',
  rnde: 'ronde',
  rnge: 'range',
  row: 'row',
  rowy: 'rightofway',
  rsbl: 'rosebowl',
  rsng: 'rising',
  rte: 'route',
  rtn: 'return',
  rtt: 'retreat',
  rty: 'rotary',
  rue: 'rue',
  run: 'run',
  rvr: 'river',
  rvra: 'riviera',
  rvwy: 'riverway',
  sbwy: 'subway',
  sdng: 'siding',
  shwy: 'statehighway',
  slpe: 'slope',
  snd: 'sound',
  spur: 'spur',
  sq: 'square',
  st: 'street',
  stai: 'strait',
  stps: 'steps',
  stra: 'strand',
  strp: 'strip',
  strs: 'stairs',
  svwy: 'serviceway',
  swy: 'serviceway',
  tarn: 'tarn',
  tce: 'terrace',
  thor: 'thoroughfare',
  thru: 'throughway',
  tkwy: 'trunkway',
  tlwy: 'tollway',
  top: 'top',
  tor: 'tor',
  tri: 'triangle',
  trk: 'track',
  trl: 'trail',
  trlr: 'trailer',
  turn: 'turn',
  twist: 'twist',
  twrs: 'towers',
  upas: 'underpass',
  upr: 'upper',
  vale: 'vale',
  vdct: 'viaduct',
  view: 'view',
  vlla: 'vlla',
  vlls: 'villas',
  vlly: 'valley',
  vsta: 'vista',
  vws: 'views',
  wade: 'wade',
  walk: 'walk',
  way: 'way',
  wds: 'woods',
  west: 'west',
  whrf: 'wharf',
  wkwy: 'walkway',
  wtrs: 'waters',
  wynd: 'wynd',
  yard: 'yard',
};

const capitalize = string => (string ? string.charAt(0).toUpperCase() + string.substring(1).toLowerCase() : string);

export const getDisplayStreetType = (type) => STREET_TYPE[type];

export const getStreetTypeByAbbr = (abbr) => abbr && capitalize(STREET_TYPE_ABBR[abbr.toLowerCase()]);

export const getStreetTypeOptions = () =>
  Object.keys(STREET_TYPE).map(item => ({ label: STREET_TYPE[item], value: item }));
