import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Button from 'shared/components/Buttons/SecondaryButton';
import ConnectedTaskList from '../TaskList';
import styles from './ViewableTaskLists.module.scss';

const createDefaultTaskList = (taskListsLength) => {
  const defaultTaskListName = 'Task list';
  return {
    name: taskListsLength === 0 ? defaultTaskListName : `${defaultTaskListName} ${taskListsLength + 1}`,
    order: taskListsLength,
  };
};

const ViewableTaskLists = ({ taskLists }) => {
  const [theTaskLists, setTheTaskList] = useState(
    isEmpty(taskLists) ? [createDefaultTaskList(taskLists.length)] : taskLists,
  );

  useEffect(() => setTheTaskList(
    isEmpty(taskLists) ? [createDefaultTaskList(taskLists.length)] : taskLists,
), [taskLists]);

  const ifNoDefaultTaskList = useMemo(
    () => !isEmpty(taskLists) && theTaskLists.length === taskLists.length,
    [taskLists, theTaskLists],
  );
  return (
    <div>
      {theTaskLists.map(taskList => (<ConnectedTaskList key={taskList.id || 'default-task-list-id'} taskList={taskList} />))}
      {ifNoDefaultTaskList && (
        <Button
          className={styles.btnCreateTaskList}
          onClick={() => setTheTaskList(theTaskLists.concat([createDefaultTaskList(theTaskLists.length)]))}
        >
          Add new task list
        </Button>
      )}
    </div>
  );
};

ViewableTaskLists.propTypes = {
  taskLists: PropTypes.array.isRequired,
};

export default React.memo(ViewableTaskLists);
