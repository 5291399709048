import ReactSelect from 'react-select';
import React from 'react';
import { inputBaseDefaultProps, inputBaseProps } from 'shared/components/formFields/base/baseProps';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MiniSpinner from 'shared/components/LoadingSpinner/MiniSpinner';
import styles from './Select.module.scss';

/*
* [WARNING!!] this components does not work in uncontrolled mode if set to be readOnly
* */

export const MENU_SIZE = {
  DEFAULT: '',
  LARGE: 'LARGE',
};

export const Select = ({
                       value,
                       className,
                       disabled,
                       onChange,
                       placeholder,
                       options,
                       loading,
                       loadingMessage,
                       readOnly,
                       isSearchable,
                       menuIsOpen,
                       isClearable,
                       menuSize,
                       ...others
                     }) => (
                       <ReactSelect
                         isDisabled={disabled}
                         value={value}
                         on
                         isLoading={loading}
                         loadingMessage={() => <MiniSpinner text={loadingMessage} />}
                         options={options}
                         className={classNames(styles.reactSelect, className, menuSize === MENU_SIZE.LARGE ? styles.large : '')}
                         classNamePrefix="react-select"
                         onChange={readOnly ? undefined : onChange}
                         placeholder={placeholder}
                         isSearchable={!readOnly && isSearchable}
                         menuIsOpen={readOnly === undefined ? menuIsOpen : (!readOnly && menuIsOpen)}
                         isClearable={!readOnly && isClearable}
                         {...others}
                       />
  );

Select.defaultProps = {
  value: undefined,
  loading: false,
  loadingMessage: undefined,
  placeholder: 'Select',
  menuSize: 'DEFAULT',
  ...inputBaseDefaultProps,
};

Select.propTypes = {
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.any,
  })]),
  menuSize: PropTypes.oneOf(Object.keys(MENU_SIZE)),
  ...inputBaseProps,
};
