import hasPermissionTo from './components/authorisation/hasPermissionTo';
import { getBusinessSummary } from './api';

import { PERMISSIONS } from './components/authorisation/permissions';
import { isValidUUID } from './utils';

let getState = () => {
  throw new Error('You must call setStateGetter before findBusiness');
};

export const setStateGetter = (f) => {
  getState = f;
};

const findBusiness = async (businessId) => {
  const state = getState();

  const { permissions } = state.profile;
  if (isValidUUID(businessId) && hasPermissionTo(PERMISSIONS.VIEW_SKYWALKER_BUSINESS_SUMMARY, permissions, '*')) {
    const { selectedBusiness } = state.business;
    return selectedBusiness && selectedBusiness.id === businessId && selectedBusiness.owners
      ? selectedBusiness
      : getBusinessSummary(businessId);
  }

  return state.profile.businesses.find(business => business.id === businessId);
};

export default findBusiness;
