import PropTypes from 'prop-types';

export default PropTypes.shape({
  totalLoanAmount: PropTypes.number,
  productType: PropTypes.string,
  purpose: PropTypes.string,
  repaymentType: PropTypes.string,
  loanTerm: PropTypes.number,
  fixedLoanTerm: PropTypes.number,
  selectedFeatures: PropTypes.arrayOf(PropTypes.string),
});
