import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import {
  dateFormatter, formatStringBool, toDollarAmountWithDecimal, toPercentage, formatLoanTerm,
} from 'shared/formatterUtils';
import styles from './AuditTrailItem.module.scss';

const returnEmptyFieldOnNull = (value, renderer) => {
  if (isEmpty(value)) {
    return 'empty field';
  }

  return isFunction(renderer) ? renderer(value) : value;
};

const formatExistingClient = value => (value === 'true' ? 'existing client' : 'new client');

const getAdviserName = (value) => {
  const adviser = JSON.parse(value);
  if (isEmpty(adviser.firstName)) {
    return isEmpty(adviser.surname) ? '' : adviser.surname;
  }
  if (isEmpty(adviser.surname)) {
    return adviser.firstName;
  }
  return `${adviser.firstName} ${adviser.surname}`;
};

const DISPLAY_NAME = {
  expectedUpfrontOverride: {
    title: 'Expected smartline upfront override',
    valueRenderer: value => returnEmptyFieldOnNull(value, toDollarAmountWithDecimal),
  },
  isUpfrontExpected: {
    title: 'Upfront expected',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatStringBool),
  },
  isTrailExpected: {
    title: 'Trail expected',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatStringBool),
  },
  upfrontPercentageOverride: {
    title: 'Upfront percentage override',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  fixedTrailPercentage: {
    title: 'Fixed trail percentage',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  fixedTrailStartDate: {
    title: 'Fixed trail start date',
    valueRenderer: value => returnEmptyFieldOnNull(value, dateFormatter.toDdmmyyyy),
  },
  fixedTrailEndDate: {
    title: 'Fixed trail end date',
    valueRenderer: value => returnEmptyFieldOnNull(value, dateFormatter.toDdmmyyyy),
  },
  excludeFromSafetyNet: {
    title: 'Exclude from safety net',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatStringBool),
  },
  isExistingClient: {
    title: 'Client origin',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatExistingClient),
  },
  referralMainCategory: {
    title: 'Referral main category',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  referralSubCategory: {
    title: 'Referral sub category',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  referralSource: {
    title: 'Referral source',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  adviser: {
    title: 'Adviser',
    valueRenderer: value => getAdviserName(value),
  },
  accountNumber: {
    title: 'Account number',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  approvalNumber: {
    title: 'Approval number',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  loanType: {
    title: 'Loan type',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  productType: {
    title: 'Product type',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  clientDiscountRate: {
    title: 'Approved discount',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  lastKnownRate: {
    title: ({ containsDiscountRate }) => (containsDiscountRate ? 'Net rate (Revert interest rate)' : 'Net rate'),
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  productBaseRate: {
    title: 'Base rate',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  productFixedRate: {
    title: 'Fixed rate',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  productDiscountRate: {
    title: 'Net rate (Introductory interest rate)',
    valueRenderer: value => returnEmptyFieldOnNull(value, toPercentage),
  },
  fixedTerm: {
    title: 'Fixed term',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatLoanTerm),
  },
  productPurpose: {
    title: 'Product purpose',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  commercialProductType: {
    title: 'Commercial product type',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  repaymentType: {
    title: 'Repayment type',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  settledAmount: {
    title: 'Loan amount',
    valueRenderer: value => returnEmptyFieldOnNull(value, toDollarAmountWithDecimal),
  },
  loanTerm: {
    title: 'Loan term',
    valueRenderer: value => returnEmptyFieldOnNull(value, formatLoanTerm),
  },
  lender: {
    title: 'Lender',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  assetFinanceLender: {
    title: 'Asset finance lender',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  subLender: {
    title: 'Sub lender',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  status: {
    title: 'Loan status',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  repaidDate: {
    title: 'Paid out date',
    valueRenderer: value => returnEmptyFieldOnNull(value, dateFormatter.toDdmmyyyy),
  },
  productName: {
    title: 'Product name',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  applicants: {
    title: 'Applicants name',
    valueRenderer: value => returnEmptyFieldOnNull(value),
  },
  settledDate: {
    title: 'Settled date',
    valueRenderer: value => returnEmptyFieldOnNull(value, dateFormatter.toDdmmyyyy),
  },
};

const getSupportedChanges = changes =>
  filter(changes, change => includes(keys(DISPLAY_NAME), change.fieldName));

const renderComplianceText = (changes) => {
  const isComplianceFormCompleted = changes.filter(change => change.fieldName === 'isComplianceFormCompleted')[0];
  if (isComplianceFormCompleted) {
    const completed = isComplianceFormCompleted.newValue === 'true';
    return (
      <li>
        Launchpad switch compliance form <span className={styles.newValue}>has {(completed || 'not ')}been</span> captured in the client drive
      </li>
    );
  }
  return null;
};

const LoanUpdatedAuditTrailItem = ({ changes }) => {
  const containsDiscountRate = filter(changes, ['fieldName', 'productDiscountRate']).length > 0;
  return (
    <ul className={styles.listWrapper}>
      {getSupportedChanges(changes)
        .map((change) => {
          const { title, valueRenderer } = DISPLAY_NAME[change.fieldName];
          return (
            <li key={title}>
              <span>{isFunction(title) ? title({ containsDiscountRate }) : title} </span>
              has changed from {valueRenderer(change.oldValue)} to
              <span className={styles.newValue}> {valueRenderer(change.newValue)}</span>
            </li>
          );
        })}
      {renderComplianceText(changes)}
    </ul>
  );
};

LoanUpdatedAuditTrailItem.defaultProps = {
  changes: [],
};

LoanUpdatedAuditTrailItem.propTypes = {
  changes: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  })),
};

export default LoanUpdatedAuditTrailItem;
