import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylePropType from 'react-style-proptype';
import styles from './PlainTextField.module.scss';
import { NewInputLabelWrapper } from '../NewInputLabelWrapper';

const PlainTextField = (props) => {
  const {
    label,
    className,
    style,
    value,
    textClassName,
  } = props;

  return (
    <NewInputLabelWrapper
      label={label}
      className={className}
      style={style}
      fullWidth
    >
      <div className={classNames(styles.plainText, textClassName)}>{value}</div>
    </NewInputLabelWrapper>
  );
};

PlainTextField.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  label: PropTypes.string,
  style: stylePropType,
};

PlainTextField.defaultProps = {
  value: null,
  className: undefined,
  label: undefined,
  textClassName: '',
  style: {},
};

export default PlainTextField;
