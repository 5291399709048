import React from 'react';
import PropTypes from 'prop-types';
import HeaderSelect from './HeaderSelect';
import { REPAYMENT_FREQUENCIES } from '../../constants';
import { ProductsTableContext } from '../ProductSearchContext';

export const REPAYMENT_FREQUENCIES_ORDER = [
  'PER_WEEK',
  'PER_QUARTER_MONTH',
  'PER_FORTNIGHT',
  'PER_HALF_MONTH',
  'PER_MONTH',
  'PER_SIX_MONTHS',
  'PER_ANNUM',
];

const REPAYMENT_FREQUENCIES_OPTIONS = REPAYMENT_FREQUENCIES_ORDER.map(value =>
  ({ value, label: REPAYMENT_FREQUENCIES[value] }));

const RepaymentFrequency = ({ menuPortalTarget }) => (
  <ProductsTableContext.Consumer>
    {context => {
      const { repaymentFrequency } = context.products;
      const { dispatchRepaymentFrequencyChange } = context;
      return (
        <HeaderSelect
          selectedValue={repaymentFrequency}
          classNamePrefix="repayment-frequency"
          onChange={dispatchRepaymentFrequencyChange}
          menuPortalTarget={menuPortalTarget}
          options={REPAYMENT_FREQUENCIES_OPTIONS}
        />
        );
    }}
  </ProductsTableContext.Consumer>
  );

RepaymentFrequency.defaultProps = {
  menuPortalTarget: undefined,
};

RepaymentFrequency.propTypes = {
  menuPortalTarget: PropTypes.instanceOf(Element),
};

export default RepaymentFrequency;
