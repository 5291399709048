export const RADIO_GROUP_OPTION = [
  {
    value: 'IMPORTANT',
    label: 'Important',
  },
  {
    value: 'NOT_IMPORTANT',
    label: 'Not important',
  },
  {
    value: 'DONT_WANT',
    label: 'Don\'t want',
  },
];

export const REFINANCE_APPLICABLE_OPTIONS = [
  {
    value: true,
    label: 'Applicable',
  },
  {
    value: false,
    label: 'Not applicable',
  },
];

export const FIXED_REASON_OPTION = [{
  id: 'CERTAINTY_OF_REPAYMENTS',
  name: 'Certainty of repayments',
},
  {
    id: 'MAKE_BUDGETING_EASIER',
    name: 'Make budgeting easier',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const RATE_TYPE_DURATION = [
  {
    value: 'SIX_MONTHS',
    label: '6 months',
  },
  {
    value: 'TWELVE_MONTHS',
    label: '12 months',
  },
  {
    value: 'EIGHTEEN_MONTHS',
    label: '18 months',
  },
  {
    value: 'ONE_YEAR',
    label: '1 year',
  },
  {
    value: 'TWO_YEARS',
    label: '2 years',
  },
  {
    value: 'TWO_YEARS_PLUS',
    label: '2 years +',
  },
  {
    value: 'THREE_YEARS',
    label: '3 years',
  },
  {
    value: 'FOUR_YEARS',
    label: '4 years',
  },
  {
    value: 'FIVE_YEARS',
    label: '5 years',
  },
];

export const VARIABLE_REASON_OPTION = [
  {
    id: 'FUTURE_DECREASES_IN_THE_INTEREST_RATE',
    name: 'To take advantage of potential future decreases in the interest rate',
  },
  {
    id: 'FLEXIBILITY_OF_LOAN',
    name: 'Flexibility with respect to repayment, redraw and/or early repayment of loan',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const FIXED_AND_VARIABLE_REASON_OPTION = [
  {
    id: 'LIMIT_RISK_OF_INCREASING_VARIABLE_INTEREST',
    name: 'Limiting risk of increasing variable interest to obtain some benefit from potential future decreases in the interest rate',
  },
  {
    id: 'RETAINING_FLEXIBILITY',
    name: 'Retaining a degree of flexibility in relation to increased payments, redraws and/or early repayment of part of the loan',
  },
  {
    id: 'MAKE_BUDGETING_EASY',
    name: 'Make budgeting easier than if the entire loan were variable',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const PRINCIPAL_AND_INTEREST_REASON_OPTION = [
  {
    id: 'MINIMISE_INTEREST_PAID',
    name: 'Minimise interest paid over life of loan',
  },
  {
    id: 'HIGHER_LENDING_LIMIT',
    name: 'Higher lending limit',
  },
  {
    id: 'LOWER_DEPOSIT_REQUIRED',
    name: 'Lower deposit required',
  },
  {
    id: 'BUILD_UP_EQUITY_FROM_THE_START',
    name: 'Build up equity from the start',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const INTEREST_ONLY_REASON_OPTION = [
  {
    id: 'ACCOMMODATE_TEMPORARY_REDUCTION_IN_INCOME',
    name: 'Accommodate temporary reduction in income (e.g. parental leave, changing circumstances)',
  },
  {
    id: 'ACCOMMODATE_ANTICIPATED_NONRECURRING_EXPENSE_ITEM',
    name: 'Accommodate anticipated non-recurring expense item (e.g. education, renovation/construction, furniture)',
  },
  {
    id: 'VARIABLE_AND_UNPREDICTABLE_INCOME',
    name: 'Variable and unpredictable income',
  },
  {
    id: 'RECOMMENDED_BY_FINANCIAL_ADVISOR_OR_ACCOUNTANT',
    name: 'Recommendation provided by an independent financial advisor/accountant',
  },
  {
    id: 'TAXATION_OR_ACCOUNTING_REASONS',
    name: 'Taxation or accounting reasons (no advice given by the broker)',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const INTEREST_IN_ADVANCE_REASON_OPTION = [
  {
    id: 'FOR_TAX_PURPOSE',
    name: 'For tax purposes (no tax advice is being given)',
  },
  {
    id: 'DISCOUNTS_ON_INTEREST_RATE',
    name: 'Discounts on interest rate',
  },
  {
    id: 'ASSIST_WITH_CASH_FLOW_AND_BUDGETING',
    name: 'To assist with cash flow and budgeting',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const LINE_OF_CREDIT_REASON_OPTION = [
  {
    id: 'ASSIST_WITH_INVESTMENT_PURCHASES_AND_TAX_PLANNING',
    name: 'Assist with investment purchases and tax planning (no tax advice is being given)',
  },
  {
    id: 'ANTICIPATED_VARIABLE_CASH_FLOWS',
    name: 'Anticipated variable cash flows',
  },
  {
    id: 'ALLOWS_ACCESS_TO_FUNDS_AT_ANY_TIME',
    name: 'Allows access to funds at any time',
  },
  {
    id: 'FLEXIBILITY_TO_MANAGE_REPAYMENTS',
    name: 'Flexibility to manage repayments',
  },
  {
    id: 'NO_NEED_TO_APPLY_FOR_FURTHER_LENDING',
    name: 'No need to apply for further lending in future',
  },
  {
    id: 'ONGOING_NEED_FOR_FUNDS',
    name: 'Ongoing need for funds - planning to make a few purchases over a period of time',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const OFFSET_REASON_OPTION = [
  {
    id: 'ALLOWS_PAYING_OFF_LOAN_SOONER',
    name: 'Allows paying off loan sooner',
  },
  {
    id: 'ALLOWS_ACCESS_TO_FUNDS',
    name: 'Allows access to funds',
  },
  {
    id: 'FOR_TAX_PURPOSES',
    name: 'For tax purposes (no tax advice is being given)',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const REDRAW_REASON_OPTION = [
  {
    id: 'FLEXIBILITY_TO_ACCESS_PREPAID_FUNDS',
    name: 'Flexibility to access prepaid funds if needed',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const REPAYMENT_FREQUENCY = [
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'FORTNIGHTLY',
    label: 'Fortnightly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
];

export const REPAYMENT_TYPE_PREFERRED_DURATION_OPTION = [
  {
    value: 'UP_TO_ONE_YEAR',
    label: 'Up to 1 year',
  },
  {
    value: 'ONE_TO_THREE_YEARS',
    label: '1 to 3 years',
  },
  {
    value: 'THREE_TO_FIVE_YEARS',
    label: '3 to 5 years',
  },
  {
    value: 'FIVE_YEARS_PLUS',
    label: '5 years +',
  },
];

export const REPAYMENT_PLAN_OPTION = [
  {
    id: 'REPAYMENT_OF_LOAN_PRIOR_TO_END_OF_TERM',
    name: 'Repayment prior to end of term',
  },
  {
    id: 'DOWNSIZING',
    name: 'Downsizing',
  },
  {
    id: 'SALE_OF_ASSETS',
    name: 'Sale of assets',
  },
  {
    id: 'SAVINGS',
    name: 'Savings',
  },
  {
    id: 'INCOME_FROM_OTHER_INVESTMENTS',
    name: 'Income from other investments',
  },
  {
    id: 'CO_APPLICANTS_INCOME',
    name: 'Co-applicant\'s income',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const CHANGE_TYPE = [
  {
    value: 'TempDecIncome',
    label: 'Temporary decrease in income',
  },
  {
    value: 'PermDecIncome',
    label: 'Permanent decrease in income',
  },
  {
    value: 'AnticipLrgExpend',
    label: 'Anticipated large expenditure',
  },
  {
    value: 'UnpaidLeave',
    label: 'Extended unpaid leave',
  },
  {
    value: 'LossEmploy',
    label: 'End of contract or loss of employment',
  },
  {
    value: 'LeavingEmploy',
    label: 'Leaving employment',
  },
  {
    value: 'IncDebtRepay',
    label: 'Increased debt repayments for an existing loan',
  },
  {
    value: 'Illness',
    label: 'Medical treatment or illness',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const REFINANCE_REASON_OPTION = [
  {
    id: 'REDUCED_REPAYMENTS',
    name: 'Reduced repayments',
  }, {
    id: 'MINIMISE_INTEREST',
    name: 'Low interest rate',
  }, {
    id: 'HIGHER_LENDING_LIMIT',
    name: 'Increase total loan amount',
  }, {
    id: 'GREATER_FLEXIBILITY',
    name: 'Greater flexibility / extra or specific loan features under new loan',
  }, {
    id: 'REDUCE_NUMBER_OF_LENDERS',
    name: 'Wants to reduce number of lenders / consolidate debt',
  }, {
    id: 'DISSATISFIED',
    name: 'Dissatisfied with other financial institution',
  }, {
    id: 'CLOSE_TO_END_OF_CURRENT_TERM',
    name: 'Close to end of current loan term',
  }, {
    id: 'OTHER',
    name: 'Other',
  },
];

export const APPLICANT_REPAYMENT_PLAN_OPTION = [
  {
    id: 'SecurAddIncome',
    name: 'Additional income source',
  },
  {
    id: 'UsingSavings',
    name: 'Using savings',
  },
  {
    id: 'AppReflectsChanges',
    name: 'Application reflects changes',
  },
  {
    id: 'ReductExpend',
    name: 'Reduce expenditure',
  },
  {
    id: 'SaleOfAssets',
    name: 'Sale of assets',
  },
  {
    id: 'CoApplicantIncome',
    name: 'Co-applicant\'s income',
  },
  {
    id: 'Other',
    name: 'Other',
  },
];

export const REPAYMENT_METHOD_OPTION = [
  {
    id: 'PriorToRetirement',
    name: 'Repayment of loan prior to retirement',
  },
  {
    id: 'DownsizingHome',
    name: 'Downsizing of home',
  },
  {
    id: 'SaleOfAssets',
    name: 'Sale of assets',
  },
  {
    id: 'SuperannuationIncome',
    name: 'Recurring income from superannuation',
  },
  {
    id: 'SuperannuationPayment',
    name: 'Superannuation lump sum following retirement',
  },
  {
    id: 'InvestmentIncome',
    name: 'Income from other investments',
  },
  {
    id: 'Savings',
    name: 'Savings',
  },
  {
    id: 'CoApplicantsIncome',
    name: 'Co-applicant\'s income',
  },
  {
    id: 'Other',
    name: 'Other',
  },
];
