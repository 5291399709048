import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import some from 'lodash/some';
import { clearingStatuses } from '../../../ApplicationDetailContentNav/Liabilities/constants';

const getLiabilities = (state) => get(state, 'application.applicationDetail.finance.liabilities');

export const getLiabilitiesInitialValue = createSelector(
  getLiabilities,
  liabilities => ({
    liabilities: (liabilities || []).map(liability => ({
      ...liability,
      repayments: liability.repayments || {
        value: null,
        periodUnit: null,
      },
      accountDetails: liability.accountDetails || {
        accountName: null,
        accountNumber: null,
        lender: null,
      },
    })),
  }),
);

const getClearingAmount = (liability) => {
  switch (liability.clearingStatus) {
    case clearingStatuses.Full:
      return liability.currentBalance;
    case clearingStatuses.Partial:
      return liability.clearingAmount;
    default:
      return null;
  }
};

export const getLiabilitiesBasicValues = createSelector(
  getLiabilities,
  liabilities => keyBy(liabilities?.map((liability) => ({
    lender: get(liability, 'accountDetails.lender'),
    type: liability.type,
    clearingAmount: getClearingAmount(liability),
    liabilityId: liability.id,
  })), 'liabilityId'),
);

export const getHasTopUp = createSelector(
  getLiabilities,
  liabilities => some(liabilities, (l) => l.clearingStatus === clearingStatuses.TopUp),
);
