import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import pick from 'lodash/pick';
import moment from 'moment';
import { downloadComparisonPdf, getDetailsForProducts } from 'shared/api';
import get from 'lodash/get';
import printPresenter from './comparisonPrintPresenter';
import { showToast } from '../../../redux/toast/actions';
import { ACTIONS } from './comparisonActions';
import { retrieveIncludeCommentsInPrintout } from '../../../store/persistState';
import { calculateTotalCost, calculateTotalRepayment } from '../../../shared/productUtils';

const openInTab = (blob) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, 'Product-Comparison.pdf');
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  }
};

export const resetSearchCriteria = (searchCriteria) =>
  ({
    type: ACTIONS.RESET_SEARCH_CRITERIA,
    searchCriteria,
  });

function isOnlyCustomProduct(selectedOptions) {
  return selectedOptions.length === 1 && get(selectedOptions[0], 'variants[0].customProduct', false) === true;
}

export const getComparisonDetails = () => (dispatch, getState) => {
  const {
    products: { searchCriteria },
    comparison: { selectedOptions },
  } = getState().productSearch.standAlone;

  const variantIds = flatMap(
    selectedOptions,
    o => o.variants
      .filter(v => !v.customProduct)
      .map(v => v.id),
  );

  const dispatchDefaultActions = () => {
    dispatch(resetSearchCriteria(searchCriteria));
    dispatch({ type: ACTIONS.SET_DEFAULT_MODIFICATION_ORIGINALS });
    dispatch({ type: ACTIONS.GET_COMPARISON_DETAILS_LOADING });
    return getDetailsForProducts(variantIds)
      .then((extraComparisonDetails) => dispatch({
        type: ACTIONS.GET_COMPARISON_DETAILS_SUCCESS,
        extraComparisonDetails,
      }))
      .catch(error => dispatch({
        type: ACTIONS.GET_COMPARISON_DETAILS_ERROR,
        error,
      }));
  };

  const dispatchCustomProductOnlyActions = () => {
    dispatch(resetSearchCriteria(searchCriteria));
    dispatch({ type: ACTIONS.GET_COMPARISON_DETAILS_LOADING });
    dispatch({ type: ACTIONS.SET_DEFAULT_MODIFICATION_ORIGINALS });
    dispatch({
      type: ACTIONS.GET_COMPARISON_DETAILS_SUCCESS,
      extraComparisonDetails: [],
    });
    return Promise.resolve();
  };

  if (isOnlyCustomProduct(selectedOptions)) {
    return dispatchCustomProductOnlyActions();
  }
  if (isEmpty(variantIds)) {
    return Promise.resolve();
  }
  return dispatchDefaultActions();
};

export const updateOptionsToComparison = (options) =>
  ({
    type: ACTIONS.UPDATE_OPTIONS_FROM_COMPARISON,
    options,
  });

export const clearComparison = () => ({ type: ACTIONS.CLEAR_COMPARISON });

export const downloadComparision = (overrideUserProfile = null) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.DOWNLOAD_COMPARISONS_LOADING });
  const state = getState().productSearch.standAlone;
  const dataForPdf = printPresenter(state, getState().profile, moment(), overrideUserProfile);
  return downloadComparisonPdf(dataForPdf)
    .then((pdfBlob) => {
      dispatch({ type: ACTIONS.DOWNLOAD_COMPARISONS_SUCCESS });
      openInTab(pdfBlob);
    })
    .catch((err) => {
      dispatch(showToast('The printout did not download. Please try again.'));
      dispatch({
        type: ACTIONS.DOWNLOAD_COMPARISONS_ERROR,
        error: err,
      });
    });
};

export const toggleIncludeCommentsInPrintout = () =>
  ({ type: ACTIONS.TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT });

export const toggleSelectedFeatureFromComparison = (featureName) => ({
  type: ACTIONS.TOGGLE_SELECTED_FEATURE_FROM_COMPARISON,
  featureName,
});

export const updateSelectedFeaturesFromComparison = (featureNames) => ({
  type: ACTIONS.UPDATE_SELECTED_FEATURES_FROM_COMPARISON,
  featureNames,
});

export const toggleFeatureModalInComparison = () => ({
  type: ACTIONS.TOGGLE_FEATURE_MODAL_VIEW,
});

export const processUpdateOptionsFromComparison = (state, action) => ({
  ...state,
  selectedOptions: action.options,
  selectedFeaturesFromComparison: [],
});

export const processClearComparison = state => ({
  ...state,
  id: undefined,
  selectedOptions: [],
  selectedFeaturesFromComparison: [],
  comment: '',
});

export const processGetComparisonDetailsLoading = state => ({
  ...state,
  hasError: false,
  isLoading: true,
  hasPrintError: false,
});

const getMappedVariant = selectedOption => selectedOption.variants.map(variant => {
  const { modificationOriginals } = variant;
  return ({
    ...variant,
    modificationOriginals: modificationOriginals || {
      totalCost: variant.totalCost,
      firstPeriodInterestOnly: variant.firstPeriodInterestOnly,
      firstPeriodPrincipalAndInterest: variant.firstPeriodPrincipalAndInterest,
      firstPeriodRepayment: variant.firstPeriodRepayment,
      upfrontFee: variant.upfrontFee,
      ongoingFee: variant.ongoingFee,
      lmi: variant.lmi,
      comparisonRate: variant.comparisonRate,
      standardRate: variant.standardRate,
    },
  });
});

export const setDefaultModificationOriginals = state => {
  const { selectedOptions } = state;
  return {
    ...state,
    selectedOptions: selectedOptions.map(selectedOption => ({
      ...selectedOption,
      variants: getMappedVariant(selectedOption),
    })),
  };
};

export const processGetComparisonDetailsSuccess = (action, state) => {
  const { extraComparisonDetails } = action;
  const { selectedOptions } = state;
  const retrieveExtraInfoWithId = id =>
    pick(extraComparisonDetails.find(variantDetail => variantDetail.id === id), ['featureDetails', 'source']);

  const getExtraInfoForCustomProduct = variant => (
    variant.customProduct
      ? {
        featureDetails: [{ featureName: 'Repay Monthly' }],
        source: `launchpad::${variant.id}`,
      }
      : {}
  );

  const applyExtraInfos = variant => ({
    ...variant,
    ...retrieveExtraInfoWithId(variant.id),
    ...getExtraInfoForCustomProduct(variant),
  });

  const optionsWithFeature = selectedOptions.map(option =>
    ({
      ...option,
      variants: option.variants.map(applyExtraInfos),
      isPopulated: true,
    }));

  return {
    ...state,
    selectedOptions: optionsWithFeature,
    hasError: false,
    isLoading: false,
  };
};
export const processGetComparisonDetailsError = state => ({
  ...state,
  hasError: true,
  isLoading: false,
});
export const processDownloadComparisonsLoading = state => ({
  ...state,
  isDownloading: true,
  hasPrintError: false,
});
export const processDownloadComparisonsSuccess = state => ({
  ...state,
  isDownloading: false,
  hasPrintError: false,
});
export const processDownloadComparisonsError = state => ({
  ...state,
  isDownloading: false,
  hasPrintError: true,
});
export const processToggleIncludeCommentsInPrintOut = state => ({
  ...state,
  includeCommentsInPrintout: !state.includeCommentsInPrintout,
});

export const processUpdateSelectedVariant = (state, action) => {
  const { selectedOptions } = state;
  const newOptions = [...selectedOptions];
  const optionToBeModified = { ...newOptions[action.optionIndex] };
  newOptions[action.optionIndex] = optionToBeModified;
  const newVariants = [...optionToBeModified.variants];
  newVariants[action.splitIndex] = action.updatedVariant;
  optionToBeModified.variants = newVariants;
  optionToBeModified.totalRepayment = calculateTotalRepayment(optionToBeModified.variants);
  optionToBeModified.totalCost = calculateTotalCost(optionToBeModified.variants);

  return {
    ...state,
    selectedOptions: newOptions,
  };
};
export const processToggleSelectedFeatureFromComparison = (state, action) => {
  const selectedFeatures = state.selectedFeaturesFromComparison.slice();
  const featureIndex = selectedFeatures.indexOf(action.featureName);
  if (featureIndex > -1) {
    selectedFeatures.splice(featureIndex, 1);
  } else {
    selectedFeatures.push(action.featureName);
  }
  return {
    ...state,
    selectedFeaturesFromComparison: selectedFeatures,
  };
};
export const processUpdateSelectedFeaturesFromComparison = (action, state) => {
  const selectedFeatures = action.featureNames;
  return {
    ...state,
    selectedFeaturesFromComparison: selectedFeatures,
  };
};
export const processToggleFeatureModalView = state => ({
  ...state,
  featureModalViewInComparison: !state.featureModalViewInComparison,
});

export const initialState = () => ({
  id: undefined,
  comment: '',
  selectedOptions: [],
  selectedFeaturesFromComparison: [],
  featureModalViewInComparison: false,
  hasError: false,
  isLoading: false,
  isDownloading: false,
  includeCommentsInPrintout: retrieveIncludeCommentsInPrintout(),
});
