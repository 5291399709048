import React from 'react';
import PropTypes from 'prop-types';

import FeatureComponent from './FeatureComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedFeature = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
      const { hasError, features } = context.features;
      const selectedFeatureNames = context.loanCriteriaList[index].selectedFeatureNames || [];
      return (
        <FeatureComponent
          features={features}
          hasError={hasError}
          selectedFeatureNames={selectedFeatureNames}
          dispatchUpdateSelectedFeaturesNames={context.dispatchUpdateSelectedFeaturesNames(index)}
          dispatchToggleSelectFeature={context.dispatchToggleSelectFeature(index)}
          dispatchGetFeatures={context.dispatchGetFeatures}
        />
        );
    }}
  </ProductSearchContext.Consumer>
  );

ConnectedFeature.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ConnectedFeature;
