import { mapLogToProps } from './utils';

const applicantsInfoSyncLogTransformer = (origin) => (
  mapLogToProps({
    origin,
    type: 'APPLICANT/S',
    summary: 'Applicants and/or guarantors information #action',
    handleChanges: () => [{
      path: '',
      value: 'Synced from Lotus Notes',
    }],
  }));

export default applicantsInfoSyncLogTransformer;
