import React, { useCallback, useMemo, useState } from 'react';
import {
  Field, FieldArray, getFormValues, change,
} from 'redux-form';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { createSelector } from '@reduxjs/toolkit';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Checkbox from 'shared/components/formFields/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import LoanProducts from './LoanProduct';
import styles from '../CustomProduct.module.scss';
import ReduxFormNumberInput from '../../../../../../../../shared/components/formFields/ReduxForm/NumberInput';

const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const isOffPanelLender = lenderName => lenderName === 'Other';

const zeroToOneHundred = value => isNil(value) || (value >= 0 && value <= 100);

const HomeLender = 'Home';

const CustomProductForm = ({
 lenders, handleSubmit, submitting, valid: formValid, form,
}) => {
  const [isCorrectConfirmed, setIsCorrectConfirmed] = useState(false);
  const [isOffPanelApprovalConfirmed, setIsOffPanelApprovalConfirmed] = useState(false);
  const formValueSelector = useMemo(() => getFormValues(form), [form]);
  const lenderSelector = useMemo(() => createSelector(formValueSelector, ({ lender }) => lender), [formValueSelector]);
  const lender = useSelector(lenderSelector);
  const isOtherLender = isOffPanelLender(lender);
  const dispatch = useDispatch();
  const options = useMemo(
    () =>
      lenders.filter(item => item.category.includes(HomeLender)).map(
        ({ name }) => ({
          label: `${name}`,
          value: name,
        }),
      ),
    [lenders],
  );
  const handleLenderChange = useCallback((value) => {
    if (!isOffPanelLender(value)) {
      setIsOffPanelApprovalConfirmed(false);
      dispatch(change(
        form,
        'lenderOverrides',
        { upfrontPercentage: null, trailPercentage: null },
      ));
    }
  }, [setIsOffPanelApprovalConfirmed, dispatch, form]);
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.sectionRow}>
        <p className={styles.info}>
          Please enter the product details if your product is not in the list.
          This product will only be saved against this application.
        </p>

        <Field
          name="lender"
          component={ReduxFormSelect}
          label="Lender"
          options={options}
          validate={required}
          isClearable
          onChange={handleLenderChange}
          isRequired
        />
        {isOtherLender && (
          <div className={styles.offPanelLenderFields}>
            <Field
              name="lenderOverrides.upfrontPercentage"
              component={ReduxFormNumberInput}
              label="Upfront commission"
              validate={required}
              placeholder="%"
              suffix="%"
              allowNegative={false}
              disabled={submitting}
              decimalScale={2}
              isAllowed={zeroToOneHundred}
              isRequired
            />
            <Field
              name="lenderOverrides.trailPercentage"
              component={ReduxFormNumberInput}
              label="Trail "
              validate={required}
              placeholder="%"
              suffix="%"
              allowNegative={false}
              disabled={submitting}
              decimalScale={2}
              isAllowed={zeroToOneHundred}
              isRequired
            />
          </div>
        )}
      </div>
      <div>
        <FieldArray
          name="loanProducts"
          component={LoanProducts}
          rerenderOnEveryChange
        />
      </div>
      <div className={styles.footer}>
        <Checkbox
          labelName="I have checked the information entered above is correct."
          checked={isCorrectConfirmed}
          onChange={() => setIsCorrectConfirmed(!isCorrectConfirmed)}
        />
        {isOtherLender && (
          <Checkbox
            labelName="I have received an approval to get an application with this off panel lender."
            checked={isOffPanelApprovalConfirmed}
            onChange={() => setIsOffPanelApprovalConfirmed(!isOffPanelApprovalConfirmed)}
          />
        )}
        <PrimaryButton
          disabled={
            !isCorrectConfirmed
            || (isOtherLender && !isOffPanelApprovalConfirmed)
            || !formValid
            || submitting
          }
          type="submit"
        >
          Add
        </PrimaryButton>
      </div>
    </form>
  );
};

CustomProductForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  lenders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
};

export default CustomProductForm;
