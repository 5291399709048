import get from 'lodash/get';
import isString from 'lodash/isString';
import buildDescriptors from '../descriptors/ServiceabilityDescriptors';
import { isConstruction } from '../../../ApplicationDetailContentNav/shared/RealEstateAsset/constants';

const buildContext = ({ application, toggles }) => ({
  normalizeType(defaultType, index) {
    // do not care finalType, let see how it goes
    const suffix = index === undefined ? '' : ` (${index + 1})`;
    return `${defaultType}${suffix}`;
  },
  getApplicantName(index) {
    return get(application, `applicants.persons[${index}].name`);
  },
  getLinkOfApplicant(index) {
    return { link: get(application, `applicants.persons[${index}].externalLink`) };
  },
  getEmploymentType(...args) {
    if (isString(args[1])) {
      return 'Employments must not be null';
    }
    const status = get(application, `applicants.persons[${args[0]}].employments[${args[1]}].status`);
    return `Employment - ${status}`;
  },
  getEmploymentIncomeType(...indexes) {
    return this.normalizeType(
      'Income', indexes[2],
    );
  },
  getAdditionalIncomeType(index) {
    return this.normalizeType(
      'Additional income',
      index,
    );
  },
  getRealEstate(index) {
    return get(application, `finance.assets.realEstates[${index}]`);
  },
  getRealEstateType(index) {
    return this.normalizeType(
      'Real estate asset', index,
    );
  },
  getNonRealEstate(index) {
    return get(application, `finance.assets.nonRealEstates[${index}]`);
  },
  getNonRealEstateType(index) {
    return this.normalizeType(
      'Non real estate asset', index,
    );
  },
  getSecurityTitle(index) {
    const ownedAssetsLength = (get(application, 'finance.assets.realEstates') || [])
      .filter(realEstate => realEstate?.transactionType === 'Owns' && realEstate?.isForConstructionLoan !== true).length;
    const title = 'To purchase/changes to owned asset';
    return this.normalizeType(title, index - ownedAssetsLength);
  },
  isRealEstate(index) {
    return !this.isToPurchaseOrChangesToAsset(index);
  },
  isNonConstructionAsset(index) {
    return !this.isConstructionAsset(index);
  },
  isConstructionAsset(index) {
    const maybeRealEstate = this.getRealEstate(index);
    const residentialType = get(maybeRealEstate, 'residentialType');

    return isConstruction(residentialType);
  },
  isToPurchaseOrChangesToAsset(index) {
    const maybeRealEstate = this.getRealEstate(index);
    const transactionType = get(maybeRealEstate, 'transactionType');
    const constructionLoan = get(maybeRealEstate, 'isForConstructionLoan', false);
    const ownedAndIsForConstructionLoan = transactionType === 'Owns' && constructionLoan;

    return transactionType === 'Purchasing' || ownedAndIsForConstructionLoan;
  },
  getLiabilityType(index) {
    return this.normalizeType(
      'Liability',
      index,
      get(application, `finance.liabilities[${index}].type`),
    );
  },
  getToggles() {
    return toggles;
  },
});

export default contextData => {
  const context = buildContext(contextData);
  const descriptors = buildDescriptors(context);
  return {
    context,
    descriptors,
  };
};
