import React from 'react';
import PropTypes from 'prop-types';
import SubSection from '../SubSection';
import styles from './styles.module.scss';
import { formatCurrency } from '../../../../../../../utils/formatters';

const SubTotalSection = ({ total, title }) => (
  <SubSection>
    <div className={styles.wrapper}>
      <h5>{title}</h5>
      <span className={styles.calculatedValue}>{formatCurrency(total, { precision: 2 })}</span>
    </div>
  </SubSection>
  );

SubTotalSection.propTypes = {
  total: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default SubTotalSection;
