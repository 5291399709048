import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  EditField,
  USER_PERMISSIONS,
} from 'Loans/components/LoanDetails/EditField';
import LenderSelector from 'Loans/components/LoanDetails/LoanAbstract/Lender/LenderSelector';
import styles from 'Loans/components/LoanDetails/LoanAbstract/LoanAbstract.module.scss';
import LenderIconImage from 'shared/components/LenderIconImage';
import ExternalLink from 'shared/components/Links/ExternalLink';
import Icon from 'shared/components/Icon/Icon';
import LoanLenderShape from 'Loans/shapes/LoanLenderShape';
import PropTypes from 'prop-types';

const renderLenderWithLogoAndLink = (lender, palette) => (
  <div className={styles.lenderInfo}>
    <LenderIconImage lender={lender} />
    {lender.bankPortalForBroker && (
      <ExternalLink
        className={styles.portalLink}
        to={lender.bankPortalForBroker}
      >
        Online Portal
        <Icon name="share" size="15" color={palette.textInformation} />
      </ExternalLink>
    )}
  </div>
);

export const LenderField = ({ lender, onChange }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <EditField
      permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
      fallback={renderLenderWithLogoAndLink(lender, themeContext.palette)}
    >
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={<LenderSelector disabled />}
      >
        <LenderSelector onChange={onChange} />
      </EditField>
    </EditField>
  );
};

LenderField.defaultProps = {
  onChange: () => {},
};

LenderField.propTypes = {
  lender: LoanLenderShape.isRequired,
  onChange: PropTypes.func,
};
