import React from 'react';
import LoanTermComponent from 'shared/components/productSearch/ProductSearchFields/LoanTermComponent';
import PropTypes from 'prop-types';

const LoanTermField = props => {
  const {
    input: { value: loanTerm, onChange: onLoanTermChange },
    meta: {
      error: loanTermError,
    },
    isTopUp,
  } = props;

  return (
    <LoanTermComponent
      value={loanTerm}
      isTopUp={isTopUp}
      onChange={onLoanTermChange}
      errorMessage={loanTermError}
    />
  );
};

LoanTermField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  isTopUp: PropTypes.bool.isRequired,
};

export default LoanTermField;
