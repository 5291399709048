import sortedUniq from 'lodash/sortedUniq';
import size from 'lodash/size';
import mapKeys from 'lodash/mapKeys';
import flatMap from 'lodash/flatMap';
import { REPAYMENT_FREQUENCIES } from '../../../../../../constants';

const TIMESTAMP_FORMAT = 'D MMM YYYY - h:mm A';
const DATE_FORMAT = 'DD/MM/YYYY';

const getComparisonPrintPresenter = ({
                  searchCriteria: currentSearchCriteria,
                  userProfile,
                  generatedAt,
                  selectedFeaturesFromComparison,
                  variantsCombinations,
                  includeCommentsInPrintout,
                  comment,
                }) => {
  const {
    applicationCriteria,
    loansCriteria,
    totalCostTerm,
    repaymentFrequency,
  } = currentSearchCriteria;

  const searchCriteria = {
    loanCriteria: loansCriteria,
    ...applicationCriteria,
    totalCostTerm,
    repaymentFrequency: REPAYMENT_FREQUENCIES[repaymentFrequency],
  };
  const featureNames = sortedUniq(flatMap(loansCriteria, 'selectedFeatureNames').slice().sort());
  const featureNamesFromComparison = sortedUniq(selectedFeaturesFromComparison.slice().sort());
  const selectedFeatureNames = featureNames.concat(featureNamesFromComparison);

  const preparedBy = {
    displayName: userProfile.username,
    email: userProfile.email,
    phoneNumber: userProfile.mobilePhone || userProfile.officePhone || undefined,
  };

  const selectedOptions = variantsCombinations.map((selectedOption) => {
    const variants = selectedOption.variants.map((variant) => ({
      ...variant,
      featureDetails: mapKeys(variant.featureDetails, 'featureName'),
    }));
    return { ...selectedOption, variants };
  });

  return {
    searchCriteria,
    selectedFeatureNames,
    preparedBy,
    selectedOptions,
    includeComments: includeCommentsInPrintout,
    generatedTimestamp: generatedAt.format(TIMESTAMP_FORMAT),
    generatedDate: generatedAt.format(DATE_FORMAT),
    isSplitSearch: size(loansCriteria) > 1,
    comment,
  };
};

export default getComparisonPrintPresenter;
