import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { ConfirmContext } from 'shared/components/ConfirmProvider';
import styles from './Component.module.scss';
import removeApplicantConfirm from '../utils/removeApplicantConfirm';
import getIndex from '../utils/getIndex';
import PersonComponent from '../Person/PersonComponent';
import CompanyComponent from '../Company/CompanyComponent';
import { FORM_NAME } from './constants';

const selector = formValueSelector(FORM_NAME);

const editInLotusNotesField = (field) => {
  const externalLink = field.input.value;
  if (externalLink) {
    return (
      <button
        type="button"
        className={styles.editInLotusNotesLink}
        onClick={() => window.open(externalLink, '_blank')}
      >
        Edit in Lotus Notes
      </button>
    );
  }
  return null;
};

const ApplicantOrGuarantorComponent = ({
  fieldNamePrefix, displayRemoveButton, actions, hasEditPermission, isApplicant,
}) => {
  const isPerson = useSelector(state => selector(state, `${fieldNamePrefix}.isPerson`));
  const action = useMemo(() => (isPerson ? actions.personAction : actions.companyAction), [actions, isPerson]);
  const { remove, replace } = action;
  const confirm = useContext(ConfirmContext);
  const handleClickRemove = useMemo(() => () => {
    const index = getIndex(fieldNamePrefix);
    if (action.isEmpty(index)) {
      remove(index);
    } else {
      removeApplicantConfirm(confirm, isApplicant)
        .then(isConfirm => isConfirm && remove(index));
    }
  }, [action, confirm, fieldNamePrefix, remove, isApplicant]);

  return isPerson ? (
    <PersonComponent
      hasEditPermission={hasEditPermission}
      fieldNamePrefix={fieldNamePrefix}
      isApplicant={isApplicant}
      replace={replace}
      editInLotusNotesField={editInLotusNotesField}
      displayRemoveButton={displayRemoveButton}
      handleClickRemove={handleClickRemove}
    />
  ) : (
    <CompanyComponent
      hasEditPermission={hasEditPermission}
      fieldNamePrefix={fieldNamePrefix}
      isApplicant={isApplicant}
      replace={replace}
      editInLotusNotesField={editInLotusNotesField}
      displayRemoveButton={displayRemoveButton}
      handleClickRemove={handleClickRemove}
    />
  );
};

ApplicantOrGuarantorComponent.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  displayRemoveButton: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    personAction: PropTypes.shape({
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      isEmpty: PropTypes.func.isRequired,
    }).isRequired,
    companyAction: PropTypes.shape({
      remove: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      isEmpty: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  isApplicant: PropTypes.bool.isRequired,
};

export default ApplicantOrGuarantorComponent;
