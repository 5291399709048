import React, { PureComponent } from 'react';
import mapValues from 'lodash/mapValues';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import hasPermissionTo from 'shared/components/authorisation/hasPermissionTo';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import actions from './redux/actions';
import { applicationDisplayStatus } from '../../constants/applicationStatus';
import { FETCHING_STATUS } from '../../../constants';
import ApplicationSwimLanes from './ApplicationSwimLanes/ApplicationSwimLanes';
import styles from './Applications.module.scss';
import ApplicationsFilter from './ApplicationsFilter';

export class Applications extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.handleConditionsChange = this.handleConditionsChange.bind(this);
    this.handleApplicationCreated = this.handleApplicationCreated.bind(this);
    this.hasEditPermission = [PERMISSIONS.EDIT_OWN_APPLICATION_TRACKING, PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING]
        .some(item => hasPermissionTo(item, props.userPermissions, props.businessId));
  }

  componentDidMount() {
    const { conditions } = this.props;
    this.fetchData(conditions);
  }

  componentDidUpdate(prevProps) {
    const {
      businessId, cleanConditions, cleanApplicants, userPermissions,
    } = this.props;
    if (prevProps.businessId !== businessId) {
      cleanConditions();
      cleanApplicants();
      this.fetchData();
    }
    this.hasEditPermission = [PERMISSIONS.EDIT_OWN_APPLICATION_TRACKING,
      PERMISSIONS.EDIT_ALL_APPLICATION_TRACKING,
      PERMISSIONS.EDIT_ALL_APPLICATION_ADDITIONAL]
      .some(item => hasPermissionTo(item, userPermissions, businessId));
  }

  handleConditionsChange(mergingConditions) {
    const { changeConditions, conditions } = this.props;
    changeConditions(mergingConditions);
    this.fetchData({ ...conditions, ...mergingConditions });
  }

  handleApplicationCreated(applicationId) {
    const { history } = this.props;
    if (applicationId) {
      setTimeout(() => {
        history.push(`/applications/${applicationId}`);
      }, 0);
    }
  }

  fetchData(conditions) {
    const { retrieveApplications, businessId } = this.props;
    const sanitizedConditions = mapValues(conditions, 'value');
    if (businessId) {
      retrieveApplications(businessId, sanitizedConditions);
    }
  }

  render() {
    const {
      swimLanes,
      fetchingStatus,
      conditions,
      options,
      businessId,
      moveApp,
      updateApplicationStatus,
      actionTimeMapInSwimLane,
      getApplicationActionTimeMap,
    } = this.props;

    const isLoading = fetchingStatus === FETCHING_STATUS.START;
    const hasError = fetchingStatus === FETCHING_STATUS.ERROR;

    return (
      <div>
        <GroupOfficeBusinessSelector key={businessId} />
        <div className={styles.applicationTracking}>
          <section className={styles.headingSection}>
            <h1 className={styles.pageHeading}>Application tracking</h1>
          </section>
          <ApplicationsFilter
            options={options}
            conditions={conditions}
            onConditionsChange={this.handleConditionsChange}
            disableFilter={isLoading}
          />

          <LoadingAndErrorWrapper isLoading={isLoading} hasError={hasError}>
            <ApplicationSwimLanes
              swimLanes={swimLanes}
              moveApp={moveApp}
              hasEditPermission={this.hasEditPermission}
              updateApplicationStatus={
                updateApplicationStatus
              }
              actionTimeMapInSwimLane={actionTimeMapInSwimLane}
              getApplicationActionTimeMap={getApplicationActionTimeMap}
            />
          </LoadingAndErrorWrapper>
        </div>
      </div>
    );
  }
}

Applications.propTypes = {
  swimLanes: PropTypes.object,
  actionTimeMapInSwimLane: PropTypes.object,
  businessId: PropTypes.string.isRequired,
  fetchingStatus: PropTypes.number.isRequired,
  retrieveApplications: PropTypes.func.isRequired,
  changeConditions: PropTypes.func.isRequired,
  cleanConditions: PropTypes.func.isRequired,
  cleanApplicants: PropTypes.func.isRequired,
  conditions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  moveApp: PropTypes.func.isRequired,
  updateApplicationStatus: PropTypes.func.isRequired,
  userPermissions: PropTypes.array.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  getApplicationActionTimeMap: PropTypes.func.isRequired,
};

Applications.defaultProps = {
  swimLanes: {
    [applicationDisplayStatus.PRE_SUBMISSION]: [],
    [applicationDisplayStatus.DRAFT]: [],
    [applicationDisplayStatus.SUBMITTED]: [],
    [applicationDisplayStatus.DECLINED_OR_WITHDRAWN]: [],
    [applicationDisplayStatus.CONDITIONALLY_APPROVED]: [],
    [applicationDisplayStatus.UNCONDITIONALLY_APPROVED]: [],
    [applicationDisplayStatus.SETTLED]: [],
  },
  actionTimeMapInSwimLane: {},
};

const mapStateToProps = state => ({
  userPermissions: get(state, 'profile.permissions', []),
  swimLanes: state.applications.swimLanes,
  businessId: get(state, 'business.selectedBusiness.id', null),
  fetchingStatus: state.applications.fetchingStatus,
  ...state.applications.filter,
  applicants: state.applications.applicants,
  actionTimeMapInSwimLane: state.applications.actionTimelineMap,
});

const connectedApplications = connect(
  mapStateToProps,
  {
    retrieveApplications: actions.retrieveApplications,
    changeConditions: actions.changeConditions,
    cleanConditions: actions.cleanConditions,
    cleanApplicants: actions.cleanApplicants,
    moveApp: actions.moveApp,
    updateApplicationStatus: actions.updateApplicationStatus,
    getApplicationActionTimeMap: actions.getApplicationActionTimeMap,
  },
)(Applications);

export default withRouter(connectedApplications);
