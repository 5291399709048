import React from 'react';
import PropTypes from 'prop-types';
import { dateFormatter } from 'shared/formatterUtils';
import InternalLink from '../../../shared/components/Links/InternalLink';
import styles from './CommissionManagementReportsSummary.module.scss';

const showMonthWithLink = (processingMonth, numberOfIssues) => {
  if (numberOfIssues === 0) {
    return dateFormatter.monthYear(processingMonth);
  }

  return (
    <InternalLink
      to={`commission-management-reports/${processingMonth.slice(0, 7)}`}
      className={styles.link}
    >
      {dateFormatter.monthYear(processingMonth)}
    </InternalLink>
);
};

const ReportRow = ({ processingDate, numberOfIssues }) => (
  <tr className={styles.tableRow}>
    <td>{showMonthWithLink(processingDate, numberOfIssues)}</td>
    <td style={{ textAlign: 'right' }}>{numberOfIssues}</td>
  </tr>
);

ReportRow.propTypes = {
  processingDate: PropTypes.string.isRequired,
  numberOfIssues: PropTypes.number.isRequired,
};

export default ReportRow;
