import React, { useState, useEffect } from 'react';
import Modal from 'shared/components/Modal/Modal';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Select from 'shared/components/formFields/Select';
import styles from './TemplateListModal.module.scss';
import templateGraqhQL
  from '../../../../../../../MyBusiness/components/TemplateManagement/TaskListTemplate/redux/templateGraqhQL';

const defaultOption = { label: 'No template selected' };
const defaultTemplate = { name: 'No template selected' };

const formatTemplate = template =>
  (isEmpty(template.id) ? defaultOption : { label: template.name, value: template.id });

const formatTemplates = templateList => templateList.map(template => formatTemplate(template));

const TemplateListModal = ({
 isOpen, onClose, businessId, applicationId, addTasksFromTemplate, order,
}) => {
  const [selectTemplate, setSelectTemplate] = useState({});
  const [templateList, setTemplateList] = useState([]);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFetching(true);
      templateGraqhQL.retrieveTaskListTemplates(businessId)
        .then(({ data: { taskListTemplates } }) => {
          const templates = taskListTemplates.businessTemplates.concat(taskListTemplates.smartlineTemplates);
          setTemplateList(isEmpty(templates) ? [] : [defaultTemplate, ...templates]);
        }).finally(() => {
          setFetching(false);
        });
    }
  }, [isOpen, businessId]);

  return (
    <Modal
      isOpen={isOpen}
      className={styles.templateListModal}
      onRequestClose={() => {
        setSelectTemplate({});
        setTemplateList([]);
        onClose();
      }}
      header={(<div>Add tasks from template</div>)}
      footer={(
        <PrimaryButton
          className={styles.btnAddTasks}
          onClick={() => {
            addTasksFromTemplate({
              name: selectTemplate.name,
              order: -order,
              applicationId,
              businessId,
              taskResourceList: selectTemplate.tasks,
            }).finally(() => {
              setSelectTemplate({});
              setTemplateList([]);
              onClose();
            });
          }}
          disabled={isEmpty(selectTemplate.id)}
        >
          Add tasks
        </PrimaryButton>
      )}
    >
      <div className={styles.templateSelectorWrapper}>
        <Select
          label="Select template"
          onChange={({ value: templateId }) => setSelectTemplate({
            ...templateList.find(({ id }) => id === templateId),
          })}
          options={formatTemplates(templateList)}
          value={formatTemplate(selectTemplate)}
          disabled={isFetching}
          fullWidth
        />
      </div>
    </Modal>
  );
};

TemplateListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  addTasksFromTemplate: PropTypes.func.isRequired,
};

export default TemplateListModal;
