import React from 'react';
import PropTypes from 'prop-types';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InternalLink from 'shared/components/Links/InternalLink';
import find from 'lodash/find';
import styles from './LoanExtraInfo.module.scss';

const getSectionHeader = (introductoryInterestTerm) => (
  introductoryInterestTerm
    ? `Introductory interest rate (${introductoryInterestTerm} months)`
    : 'Introductory interest rate');
const LoanExtraInfo = ({
 interestFields, introductoryInterestFields, identifierFields, loanLink, introductoryInterestTerm,
}) => {
  const netRate = find(introductoryInterestFields, ['title', 'Net rate']).content;
  if (!(netRate.props ? netRate.props.children?.props.value : netRate)) {
    return (
      <div className={styles.extraInfoGrid}>
        <FieldsContainer
          fields={interestFields}
          title="Interest rate"
          className={loanLink ? styles.slimInterestRateGrid : styles.interestRateGrid}
          fieldsWrapperClassName={loanLink ? styles.slimInterestRateColumnWrapper : styles.interestRateColumnWrapper}
        />
        <FieldsContainer
          fields={identifierFields}
          title="Identifiers"
          className={styles.identifiersGrid}
          fieldsWrapperClassName={styles.identifiersColumnWrapper}
        />
        {loanLink
          && (
            <div className={styles.loanLinkGrid}>
              <div className={styles.title}>Link to loan</div>
              <InternalLink className={styles.loanLink} to={loanLink.link}>{loanLink.text}</InternalLink>
            </div>
          )}
      </div>
    );
  }
 return (
   <div className={styles.extraInfoGrid}>
     <FieldsContainer
       fields={introductoryInterestFields}
       title={getSectionHeader(introductoryInterestTerm)}
       className={loanLink ? styles.slimInterestRateGrid : styles.interestRateGrid}
       fieldsWrapperClassName={loanLink ? styles.slimInterestRateColumnWrapper : styles.interestRateColumnWrapper}
     />
     <FieldsContainer
       fields={identifierFields}
       title="Identifiers"
       className={styles.identifiersGrid}
       fieldsWrapperClassName={styles.identifiersColumnWrapper}
     />
     <FieldsContainer
       fields={interestFields}
       title="Revert interest rate"
       className={loanLink ? styles.slimInterestRateGrid : styles.interestRateGrid}
       fieldsWrapperClassName={loanLink ? styles.slimInterestRateColumnWrapper : styles.interestRateColumnWrapper}
     />
     {loanLink
      && (
        <div className={styles.loanLinkGrid}>
          <div className={styles.title}>Link to loan</div>
          <InternalLink className={styles.loanLink} to={loanLink.link}>{loanLink.text}</InternalLink>
        </div>
      )}
   </div>
  );
};

LoanExtraInfo.defaultProps = {
  loanLink: null,
  introductoryInterestTerm: null,
};

LoanExtraInfo.propTypes = {
  interestFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  introductoryInterestFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  identifierFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loanLink: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  introductoryInterestTerm: PropTypes.number,
};

export default LoanExtraInfo;
