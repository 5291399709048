import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import { getTargetChangedValue } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const applicationLoanRequestDisplayFields = [
  {
    fieldName: 'approvalNumber',
    displayName: 'APPROVAL NUMBER',
  },
  {
    fieldName: 'accountNumber',
    displayName: 'ACCOUNT NUMBER',
  },
  {
    fieldName: 'isUpfrontExpected',
    displayName: 'UPFRONT EXPECTED',
  },
  {
    fieldName: 'isTrailExpected',
    displayName: 'TRAIL EXPECTED',
  },
];

const applicationLoanRequestChangeLogTransformer = (origin) => {
  const booleanContentGetter = (newValue, displayName) => (JSON.parse(newValue) ? `${displayName}` : `NO ${displayName}`);
  const defaultContentGetter = (newValue, displayName) => (isEmpty(newValue) ? `${displayName} DELETED` : `NEW ${displayName} ADDED - ${newValue}`);
  const contentGetter = fieldName => get({
    isUpfrontExpected: booleanContentGetter,
    isTrailExpected: booleanContentGetter,
  }, fieldName, defaultContentGetter);

  const changedFieldValues = origin.eventDetail.changes
    .reduce((fieldsContent, changedField) => {
      const changedValue = getTargetChangedValue(origin, changedField.path);
      return { ...fieldsContent, [changedField.path]: changedValue };
    }, {});

  const content = applicationLoanRequestDisplayFields
    .filter(item => has(changedFieldValues, item.fieldName))
    .map((item) => {
      const newValue = get(changedFieldValues, item.fieldName);
      return contentGetter(item.fieldName)(newValue, item.displayName);
    });

  return ({
    ...getBasicLogInfo(origin),
    loanRequestName: get(origin, 'eventDetail.extraInfo', ''),
    content,
  });
};

export default applicationLoanRequestChangeLogTransformer;
