import { gql } from '@apollo/client';

export const GET_LENDERS = gql`
  query lenders($category: String!) {
    lenders(category: $category) {
      id
      name
      securityGroups
    }
  }
`;
