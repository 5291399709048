import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import { formatAmount, formatYears } from 'utils/formatters';
import { BaseField, USER_PERMISSIONS } from 'Loans/components/LoanDetails/EditField';
import { InputMoney } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputMoney';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import styles from './LoanCriteria.module.scss';
import FormsyNumberInput from '../../formFields/Formsy/FormsyNumberInput';
import { REFINANCE } from '../../productSearch/ProductSearchFields/PurposeCategoryComponent';
import { formatYearAndMonth } from '../../../formatterUtils';
import { convertDecimalYearToIntegerYearAndMonth } from '../../../utils';
import LoanTermInput from './LoanTermInput';

const COMMERCIAL = 'Commercial';
const FIXED = 'Fixed';

const getSelectedOption = (value, options) => options.find(option => option.value === value);

const COMMERCIAL_PRODUCT_TYPE_OPTIONS = [
  { label: 'Bank Guarantee', value: 'Bank Guarantee' },
  { label: 'Chattel Mortgage', value: 'Chattel Mortgage' },
  { label: 'Commercial Bills', value: 'Commercial Bills' },
  { label: 'Fully Drawn Advance', value: 'Fully Drawn Advance' },
  { label: 'Hire Purchase', value: 'Hire Purchase' },
  { label: 'Insurance Premium Funding', value: 'Insurance Premium Funding' },
  { label: 'Invoice Discounting', value: 'Invoice Discounting' },
  { label: 'Lease', value: 'Lease' },
  { label: 'Letter of Credit', value: 'Letter of Credit' },
  { label: 'Overdraft', value: 'Overdraft' },
  { label: 'Term Loans', value: 'Term Loans' },
  { label: 'Other', value: 'Other' },
];
const PRODUCT_PURPOSE_OPTIONS = [
  { label: 'Investment', value: 'Investment' },
  { label: 'Owner Occupied', value: 'Owner Occupied' },
  { label: 'Business', value: 'Business' },
];
const PRODUCT_TYPE_OPTIONS = [
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Variable', value: 'Variable' },
  { label: 'Line of credit', value: 'Line of credit' },
];
const REPAYMENT_TYPE = [
  { label: 'Principal and Interest', value: 'Principal and Interest' },
  { label: 'Interest Only', value: 'Interest Only' },
];
const LOAN_TYPE_OPTIONS = [
  { label: 'Home', value: 'Home' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Consumer', value: 'Consumer' },
];

const isCommercialProductTypeRequired = (isCommercialLoanTypeSelected, loan) =>
  isCommercialLoanTypeSelected && (loan.loanType !== 'Commercial' || (loan.loanType === 'Commercial' && !isEmpty(loan.commercialProductType)));

const loanType = (loan, updateLoanType, disabled) => (
  <FormsySelect
    name="loanType"
    options={LOAN_TYPE_OPTIONS}
    value={getSelectedOption(loan.loanType, LOAN_TYPE_OPTIONS)}
    onChange={option => updateLoanType(option.value)}
    fullWidth
    required
    disabled={disabled}
  />
);

const getFields = (loan, isEditing, isCommercialLoanType,
                   updateLoanType, isFixedProductType,
                   updateProductType) =>
  [
    {
      title: 'Loan amount',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={formatAmount(loan.amount)}
          isEditing={isEditing}
        >
          <InputMoney
            name="loanAmount"
            value={loan.amount}
            fullWidth
            required
          />
        </BaseField>
      ),
    },
    {
      title: 'Purpose',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={loan.purpose}
          isEditing={isEditing}
        >
          <FormsySelect
            name="productPurpose"
            options={PRODUCT_PURPOSE_OPTIONS}
            value={getSelectedOption(loan.purpose, PRODUCT_PURPOSE_OPTIONS)}
            fullWidth
          />
        </BaseField>),
    },
    {
      title: 'Product type',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={loan.productType}
          isEditing={isEditing}
        >
          <FormsySelect
            name="productType"
            options={PRODUCT_TYPE_OPTIONS}
            onChange={option => updateProductType(option.value)}
            value={getSelectedOption(loan.productType, PRODUCT_TYPE_OPTIONS)}
            fullWidth
          />
        </BaseField>),
    },
    {
      title: 'Repayment type',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={loan.repaymentType}
          isEditing={isEditing}
        >
          <FormsySelect
            name="repaymentType"
            options={REPAYMENT_TYPE}
            value={getSelectedOption(loan.repaymentType, REPAYMENT_TYPE)}
            fullWidth
          />
        </BaseField>),
    },
    {
      title: 'Purpose category',
      content: loan.purposeCategory,
    },
    {
      title: 'Loan type',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={loan.loanType}
          isEditing={isEditing}
        >
          <BaseField
            permissions={USER_PERMISSIONS.GROUP_OFFICE}
            fallback={loanType(loan, updateLoanType, true)}
            isEditing={isEditing}
          >
            {loanType(loan, updateLoanType, false)}
          </BaseField>
        </BaseField>
      ),
    },
    {
      title: 'Loan term (1-30 yrs)',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(loan.loanTerm))}
          isEditing={isEditing}
        >
          <LoanTermInput
            name="loanTerm"
            value={loan.loanTerm}
            required
            isTopUp={loan.isTopUp}
          />
        </BaseField>),
    },
    {
      title: 'Fixed term (1-10 yrs)',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={formatYears(loan.fixedTerm)}
          isEditing={isEditing}
        >
          {isFixedProductType && (
            <FormsyNumberInput
              name="fixedTerm"
              value={loan.fixedTerm}
              isAllowed={number => number === undefined || (number > 0 && number <= 10)}
              suffix=" yrs"
              allowNegative={false}
              decimalScale={0}
              fixedDecimalScale
              fullWidth
            />
          )}
        </BaseField>
      ),
      hidden: !isFixedProductType,
    },
    {
      title: 'Interest only term',
      content: formatYears(loan.interestOnlyTerm),
      hidden: !loan.interestOnlyTerm,
    },
    {
      title: 'Purpose details',
      content: loan.purposeDetails,
      className: styles.purposeDetails,
    },
    {
      title: 'Commercial product type',
      content: (
        <BaseField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
          fallback={loan.commercialProductType}
          isEditing={isEditing}
        >
          {isCommercialLoanType && (
            <FormsySelect
              name="commercialProductType"
              options={COMMERCIAL_PRODUCT_TYPE_OPTIONS}
              value={getSelectedOption(loan.commercialProductType, COMMERCIAL_PRODUCT_TYPE_OPTIONS)}
              required={isCommercialProductTypeRequired(isCommercialLoanType, loan)}
              fullWidth
            />
          )}
        </BaseField>
      ),
      noDisplay: !isCommercialLoanType,
    },
    {
      title: 'Top up',
      content: loan.isTopUp ? 'Yes' : 'No',
      className: styles.isTopUp,
    },
    {
      title: 'Combined loan amount',
      content: formatAmount(loan.combinedLoanAmount),
      noDisplay: !loan.isTopUp,
      className: styles.combinedLoanAmount,
    },
    {
      title: 'Refinance reason',
      content: loan.refinanceReason,
      className: styles.refinanceReason,
      noDisplay: loan.purposeCategory !== REFINANCE,
    },
  ];

const LoanCriteria = ({ loan, isEditing, onLoanTypeChange }) => {
  const [isCommercialLoanType, updateIsCommercialLoanType] = useState(loan.loanType === COMMERCIAL);
  const [isFixedProductType, updateIsFixedProductType] = useState(loan.productType === FIXED);

  const updateLoanType = (value) => {
    onLoanTypeChange(value);
    updateIsCommercialLoanType(value === COMMERCIAL);
  };

  const updateProductType = value => updateIsFixedProductType(value === FIXED);

  useEffect(() => {
    updateIsCommercialLoanType(loan.loanType === COMMERCIAL);
    updateIsFixedProductType(loan.productType === FIXED);
  }, [isEditing, loan]);

  const fields = getFields(loan, isEditing, isCommercialLoanType, updateLoanType,
      isFixedProductType, updateProductType);
  return (
    <FieldsContainer
      fields={fields}
      title="Loan criteria"
    />
  );
};

LoanCriteria.defaultProps = {
  isEditing: false,
  onLoanTypeChange: noop,
};

LoanCriteria.propTypes = {
  loan: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    purpose: PropTypes.string,
    productType: PropTypes.string,
    productName: PropTypes.string,
    repaymentType: PropTypes.string,
    purposeCategory: PropTypes.string,
    refinanceReason: PropTypes.string,
    loanType: PropTypes.string,
    loanTerm: PropTypes.number,
    interestOnlyTerm: PropTypes.number,
    fixedTerm: PropTypes.number,
    purposeDetails: PropTypes.string,
    commercialProductType: PropTypes.string,
    isTopUp: PropTypes.bool,
    combinedLoanAmount: PropTypes.number,
    selectedProductName: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool,
  onLoanTypeChange: PropTypes.func,
};
export default LoanCriteria;
