import banner, {
  ACTIONS,
  showErrorBanner,
  closeErrorBanner,
} from './banner';

export default banner;
export {
  ACTIONS,
  showErrorBanner,
  closeErrorBanner,
};
