import { TYPES } from '../../../../../../constants/activityLogTypes';
import { getBasicLogInfo } from './utils';

const allianzInsuranceUpdateLogTransformer = origin => ({
  ...getBasicLogInfo(origin),
  operator: 'Launchpad',
  type: TYPES.INSURANCE,
});

export default allianzInsuranceUpdateLogTransformer;
