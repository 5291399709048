import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import styles from './Referrer.module.scss';
import { applicationStatus } from '../../../../../constants/applicationStatus';

const selector = formValueSelector('contact');
const nonEditableMainCategory = ['Group Office', 'REA'];

const fieldProps = (clientOriginReadOnly) => [
  {
    name: 'referrer.isExistingClient',
    component: ReduxFormSelect,
    props: {
      label: 'Client origin',
      className: styles.span2,
      options: [{ value: true, label: 'Existing Client' }, { value: false, label: 'New Client' }],
      readOnly: clientOriginReadOnly,
    },
  },
  {
    name: 'referrer.referralMainCategory',
    component: PlainTextField,
    props: { label: 'Referral category', className: styles.span2 },
  },
  {
    name: 'referrer.referralSubCategory',
    component: PlainTextField,
    props: { label: 'Sub-category', className: styles.span },
  },
  {
    name: 'referrer.referralSource',
    component: PlainTextField,
    props: { label: 'Source', className: styles.span2 },
  },
  {
    name: 'referrer.referralFee',
    component: ReduxFormNumberInput,
    props: {
      label: 'Referral fee',
      className: classNames(styles.span, styles.inputAlignRight),
      placeholder: '$',
      decimalScale: 2,
      prefix: '$',
      allowNegative: false,
      textClassName: styles.textClassName,
    },
  },
  {
    name: 'referrer.referralCompany',
    component: ReduxFormTextInput,
    props: { label: 'Referrer', className: styles.span2 },
  },
];

const Fields = ({ clientOriginReadOnly }) =>
  fieldProps(clientOriginReadOnly)
    .map(fieldProp => <Field key={fieldProp.name} {...fieldProp} />);

const Referrer = ({ hasEditPermission, hasAdditionalEditPermission }) => {
  const referralMainCategory = useSelector(state => selector(state, 'referrer.referralMainCategory'));
  const status = useSelector(state => get(state, 'application.applicationStatus.status', null));
  const clientOriginEditable = status !== applicationStatus.SETTLED
    && (hasAdditionalEditPermission || (hasEditPermission && !nonEditableMainCategory.includes(referralMainCategory)));

  return (
    <div className={styles.referrerWrapper}>
      <Fields clientOriginReadOnly={!clientOriginEditable} />
    </div>
  );
};

Referrer.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  hasAdditionalEditPermission: PropTypes.bool.isRequired,
};

export default Referrer;
