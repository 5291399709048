import React from 'react';
import PropTypes from 'prop-types';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import classNames from 'classnames';
import { Field, FieldArray } from 'redux-form';
import styles from './Person.module.scss';
import { toDateWithSlash } from '../../../../../../../utils/datetime';

const renderDependantsFields = ({ fields }) => fields.map((dependent, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <React.Fragment key={`dependent-${index}`}>
    <Field
      name={dependent}
      className={classNames([
            styles.fieldWrapper,
            styles.atFirstColumnOnMobile,
            index % 2 === 0 ? styles.atThirdColumnOnDesktop : styles.atSixthColumnOnDesktop,
            index % 2 === 0 ? styles.atFirstColumnOnTablet : styles.atFifthColumnOnTablet,
          ])}
      component={PlainTextField}
      label={`Dependant ${index + 1}`}
      format={(value) => ([value.firstName, value.surname].filter(Boolean).join(' '))}
    />
    <Field
      name={`${dependent}.dateOfBirth`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Date of birth"
      format={value => (value ? toDateWithSlash(value) : '')}
    />
  </React.Fragment>
    ));

const DependantsSection = React.memo(({ fieldNamePrefix }) => (
  <FieldsContainer
    title="Dependants"
    className={styles.sectionWrapper}
    fieldsWrapperClassName={styles.overrideSectionWrapper}
  >
    <Field
      name={`${fieldNamePrefix}`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.atSecondColumnOnDesktop])}
      component={PlainTextField}
      label="No. of dependants"
      format={(value) => (value.dependents && value.dependents.length > 0
            ? value.dependents.length
            : (value.numberOfDependents || 0)
        )}
    />
    <FieldArray name={`${fieldNamePrefix}.dependents`} component={renderDependantsFields} />
  </FieldsContainer>
));

DependantsSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default DependantsSection;
