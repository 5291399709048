import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput';
import style from './style';
import inputComponentStyles from './InputComponent.module.scss';

const TotalLoanAmountComponent = ({ totalLoanAmount }) => (
  <NumberInput
    label="Total loan amount ($)"
    value={totalLoanAmount || ''}
    textClassName={inputComponentStyles.inputText}
    style={style}
    disabled
  />
);

TotalLoanAmountComponent.defaultProps = {
  totalLoanAmount: undefined,
};

TotalLoanAmountComponent.propTypes = {
  totalLoanAmount: PropTypes.number,
};

export default TotalLoanAmountComponent;
