import isEmpty from 'lodash/isEmpty';

const getNameString = (...names) => names.filter(name => !isEmpty(name)).join(', ');

const convertAddresses = (addresses) => addresses.map(({
                          isPrior: priorAddress,
                          housingStatus,
                          addressDateStart: startDate,
                          addressDateEnd: endDate,
                          nonStdAddress,
                          buildingName,
                          floorNo,
                          unitNo,
                          streetType: type,
                          streetName: name,
                          streetNo: no,
                          suburb: city,
                          state,
                          postcode,
                          countryCode,
                          addressType,
  }) => ({
    priorAddress,
    housingStatus,
    startDate,
    endDate,
    addressDetail: {
      nonStdAddress,
      buildingName,
      floorNo,
      unitNo,
      street: {
        type,
        name,
        no,
      },
      city,
      state,
      postcode,
      countryCode,
      addressType,
    },
  }));

const convertClientToNewApplicant = (client) => {
  const {
 nameTitle: title,
    middleNames: otherName,
    sex: gender,
    maritalStatus: status,
    countryOfCitizenship: country,
    mobile,
    email: emailAddress,
    homePhone,
    workPhone,
    addresses,
    firstName,
    surname,
} = client;
  return ({
    ...client,
    title,
    otherName,
    name: getNameString(surname, firstName),
    gender,
    isNewApplicant: true,
    isPrimary: false,
    maritalStatus: {
      status,
    },
    citizenship: {
      country,
    },
    contactDetails: {
      mobile,
      emailAddress,
      homePhone,
      workPhone,
      addresses: convertAddresses(addresses || []),
    },
  });
};

export const convertCompanyClientToNewApplicant = (client) => {
  const {
    companyDescription: description,
    dateRegistered: dateOfRegistration,
    typeOfIncorporation: type,
    companyName,
    trustName,
  } = client;
  return ({
    ...client,
    description,
    dateOfRegistration,
    type,
    name: description === 'Trust' ? trustName : companyName,
    isNewApplicant: true,
    isPrimary: false,
  });
};

export default convertClientToNewApplicant;
