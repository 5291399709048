import { getBasicLogInfo } from './utils';
import { TYPES } from '../../../../../../constants/activityLogTypes';

const createTasksFromTemplateLogTransformer = origin => ({
  ...getBasicLogInfo(origin),
  content: origin.eventDetail.resourceName,
  subContent: 'FROM TEMPLATE',
  type: TYPES.TASK_LIST_ADDED,
});

export default createTasksFromTemplateLogTransformer;
