import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'shared/components/Icon/Icon';
import colours from 'shared/theme/colours';
import Summary from 'shared/components/Summary';
import LenderIconImage from 'shared/components/LenderIconImage';
import { AdviserSelector } from 'shared/components/AdviserSelector/AdviserSelector';
import { toRatePercentage } from 'shared/formatterUtils';
import EditableContent from 'shared/components/EditableContent/EditableContent';
import { toLongDateStr } from 'utils/datetime';
import { statusNameMapper } from '../../../../constants/applicationStatus';
import styles from './ApplicationAbstract.module.scss';
import {
  getCombinedApplicants,
  isApplicationLaunchpadCreated,
  sortApplicantListByPrimaryAndName,
} from '../../../../utils';
import PopupForm from './PopupForm/PopupForm';

const settlementDateField = applicationStatus => ({
  title: 'SETTLEMENT DATE',
  content: <div data-cy="SETTLEMENT_DATE">{applicationStatus.actionTimelineMap.SETTLED.statusDateTime}</div>,
});

const expectedSettlementDateField = applicationDetail => ({
  title: 'EXPECTED SETTLEMENT',
  content: <div data-cy="EXPECTED_SETTLEMENT_DATE">{toLongDateStr(applicationDetail.expectedSettlementDate)}</div>,
  noDisplay: !applicationDetail.expectedSettlementDate,
});

const getApplicationFields = ({
                                applicationDetail,
                                applicationStatus,
                                updateApplicationAbstract,
                                isEditable,
                              }) => ([
    {
      title: 'APPLICATION NUMBER',
      content: isEditable ? (
        <EditableContent
          content={applicationDetail.applicationNumber}
          label="Application number"
          placeholder="Add number"
          maxLength="32"
          onSave={value => updateApplicationAbstract(applicationDetail.id, { applicationNumber: value })}
        />
      ) : applicationDetail.applicationNumber,
    },
    {
      title: 'ADVISER',
      content: (isEditable && isApplicationLaunchpadCreated(applicationDetail.source)) ? (
        <PopupForm
          initData={get(applicationDetail, 'writer.id')}
          displayText={applicationDetail.adviser}
          placeholder="Add adviser"
          Slot={slotProps => (
            <AdviserSelector
              businessId={applicationDetail.businessId}
              selectedAdviserId={slotProps.defaultValue}
              {...slotProps}
            />
          )}
          onSave={value => updateApplicationAbstract(applicationDetail.id, { adviserId: value })}
        />
      ) : applicationDetail.adviser,
    },
    {
      title: 'TOTAL LOAN AMOUNT',
      content: applicationDetail.totalLoanAmount || '$--',
    },
    {
      title: 'LENDER BROKER CODE',
      content: get(applicationDetail, 'lenderBrokerCode'),
      className: applicationDetail.hasAssetFinanceLender
        ? styles.lenderBrokerCodeWithLenderSpanOne
        : styles.lenderBrokerCodeWithLenderSpanTwo,
    },
    {
      title: 'LENDER',
      content: () => renderLenderContent(get(applicationDetail, 'lender')),
      className: applicationDetail.hasAssetFinanceLender
        ? classNames(styles.lenderInfoWrapperWithOneSpan, styles.lenderInfoWrapperNew)
        : classNames(styles.lenderInfoWrapperWithTwoSpan, styles.lenderInfoWrapperNew),
    },
    {
      title: 'APPLICANT/S',
      content:
  <div>
    {sortApplicantListByPrimaryAndName(getCombinedApplicants(applicationDetail.applicants))
            .map(
              ({ name, id }) => <p key={id}>{name}</p>,
            )}
  </div>,
      className: classNames(styles.applicant, styles.setFirstItem),
    },
    {
      title: 'STATUS',
      content: get(statusNameMapper, applicationStatus.status, 'Draft'),
    },
    {
      title: 'LVR',
      content: toRatePercentage(applicationDetail.applicationLVR, ''),
    },
    {
      title: 'FINANCE DUE',
      content: <div data-cy="FINANCE_DUE">{toLongDateStr(applicationDetail.financeDueDate)}</div>,
      noDisplay: !applicationDetail.financeDueDate,
    },
    applicationStatus.isApplicationSettled
      ? settlementDateField(applicationStatus)
      : expectedSettlementDateField(applicationDetail),
    {
      title: 'ASSET FINANCE LENDER',
      content: isEmpty(applicationDetail.assetFinanceLender) ? '' : (() => (
        <LenderIconImage
          lender={applicationDetail.assetFinanceLender}
        />
)),
      noDisplay: !applicationDetail.hasAssetFinanceLender,
    },
  ]);

const renderLenderContent = lender => (
  <div className={styles.lenderInfo}>
    {lender && <LenderIconImage lender={lender} />}
    {(lender || {}).bankPortalForBroker && (
      <a className={styles.portalLink} href={lender.bankPortalForBroker} target="_blank" rel="noopener noreferrer">
        Online Portal
        <Icon name="share" size="15" color={colours.ocean} />
      </a>
    )}
  </div>
);

export const Abstract = ({
  applicationDetail, applicationStatus, updateApplicationAbstract, hasEditPermission,
}) => {
  const isEditable = hasEditPermission && !applicationStatus.isApplicationSettled;

  const fields = getApplicationFields({
    applicationDetail,
    applicationStatus,
    updateApplicationAbstract,
    isEditable,
  });
  return (
    <Summary
      fields={fields}
    />
  );
};

Abstract.propTypes = {
  applicationDetail: PropTypes.shape({
    lender: PropTypes.shape({
      name: PropTypes.string.isRequired,
      lenderId: PropTypes.string,
    }),
  }).isRequired,
  applicationStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
    isApplicationSettled: PropTypes.bool.isRequired,
  }).isRequired,
  updateApplicationAbstract: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

export default Abstract;
