import omit from 'lodash/omit';

export const formsyProps = [
  'name',
  'innerRef',
  'value',
  'validations',
  'validationError',
  'validationErrors',
  'required',
  'setValue',
  'resetValue',
  'errorMessage',
  'errorMessages',
  'isValid',
  'isValidValue',
  'isRequired',
  'showRequired',
  'showError',
  'isPristine',
  'isFormDisabled',
  'isFormSubmitted',
  'formNoValidate',
  'setValidations',
  'hasValue',
  'attachToForm',
  'detachFromForm',
  'runValidation',
  'validate',
];

export const filterFormsyProps = props => omit(props, formsyProps);
