import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import { FormMetaProvider } from 'shared/components/FormMeta';
import styles from '../PurchasingAsset.module.scss';

const ConstructionFieldsForOwnedAsset = (fields) => {
  const {
    contractPriceFieldsName: { currentValue, contractPrice },
    constructionAmountEditable,
  } = fields;
  return (
    <>
      <ReduxFormNumberInput
        {...get(fields, currentValue)}
        label="Current value"
        name={fields.fieldName}
        placeholder="$"
        decimalScale={2}
        prefix="$"
        fullWidth
        allowNegative={false}
        className={styles.landPurchasePriceField}
        textClassName={styles.textClassName}
      />
      <FormMetaProvider readOnly={!constructionAmountEditable}>
        <ReduxFormNumberInput
          {...get(fields, contractPrice)}
          label="Construction amount"
          name={fields.fieldName}
          placeholder="$"
          decimalScale={2}
          prefix="$"
          fullWidth
          allowNegative={false}
          className={styles.contractPriceField}
          textClassName={styles.textClassName}
        />
      </FormMetaProvider>
    </>
  );
};

ConstructionFieldsForOwnedAsset.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  contractPriceFieldsName: PropTypes.shape({
    currentValue: PropTypes.string.isRequired,
    contractPrice: PropTypes.string.isRequired,
  }).isRequired,
  constructionAmountEditable: PropTypes.bool.isRequired,
};

export default ConstructionFieldsForOwnedAsset;
