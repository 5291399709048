import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import media from '../../media.module.scss';
import { MEDIA_TYPES } from '../../constants/media';

export const getMediaType = () => {
  if (!window.matchMedia) {
    return MEDIA_TYPES.UNKNOWN;
  }
  if (window.matchMedia(`(min-width: ${media.desktop_width})`).matches) {
    return MEDIA_TYPES.DESKTOP;
  }

  if (window.matchMedia(`(min-width: ${media.tablet_width})`).matches) {
    return MEDIA_TYPES.TABLET;
  }

  return MEDIA_TYPES.PHONE;
};

const useMediaType = () => {
  const [state, setState] = useState(getMediaType());

  useEffect(() => {
    const handler = debounce(
      () => {
        setState(getMediaType());
      },
      150,
    );
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return state;
};

export default useMediaType;
