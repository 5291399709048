import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';

import styles from './ResponsiveTable.module.scss';
import Header from './Header';
import Line from './Line';

const ResponsiveTable = ({ header, rows, stripped }) =>
  (
    <div className={classNames(styles.tableWrapper, { [styles.emptyTable]: isEmpty(rows) })}>
      <Header header={header} />
      {
        map(rows, row => (
          <Line className={stripped ? styles.stripped : null} row={row} header={header} key={shortId.generate()} />))
      }
    </div>
  );

ResponsiveTable.defaultProps = {
  stripped: false,
};

ResponsiveTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }))).isRequired,
  stripped: PropTypes.bool,
};

export default ResponsiveTable;
