import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_HOUSEHOLD_EXPENSE, EXPENSE_SECTIONS } from '../../../ApplicationDetailContentNav/Expenses/constants';

export const formatHouseholdExpenses = (householdExpenses) => {
  if (isEmpty(householdExpenses)) {
    return [DEFAULT_HOUSEHOLD_EXPENSE];
  }
  return householdExpenses?.map(
    householdExpense => {
      const {
 id, household, expenses,
} = householdExpense;
      const expenseValues = keyBy(expenses, 'type');
      const formatExpenses = EXPENSE_SECTIONS.flatMap(s =>
        s.fields.map(field => expenseValues[field.value] || { type: field.value, periodUnit: 'Monthly' }));
      return {
        id,
        household: {
          id: household?.id,
          applicants: household?.applicantsId ?? [],
          dependents: household.dependents,
        },
        expenses: formatExpenses,
      };
    },
  );
};
