import React from 'react';
import { Field } from 'redux-form';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Primary = ({ hasGuarantors }) => (
  <InformationSection title="Primary needs and requirements, and short and long term objectives">
    <div className={styles.needs}>
      <div className={styles.left}>
        <Field
          name="primary.seekCreditReason"
          component={ReduxFormTextArea}
          label="Reasons for seeking credit"
          rows="1"
        />
        <Field
          name="primary.immediateNeedsAndObjectives"
          component={ReduxFormTextArea}
          label="Immediate needs and objectives (now to next 2 years)"
          rows="1"
        />
        <Field
          name="primary.longerTermGoal"
          component={ReduxFormTextArea}
          label="Longer term goals (2-10 years)"
          rows="1"
        />
        {
            hasGuarantors && (
              <Field
                name="primary.guarantorGoalsAndObjective"
                component={ReduxFormTextArea}
                label="Guarantor goals and exit strategy"
                rows="1"
              />
            )
          }
      </div>
      <div className={styles.right}>
        <Field
          name="primary.preferredLender"
          component={ReduxFormTextArea}
          label="Preferred lender"
          rows="1"
        />
      </div>
    </div>
  </InformationSection>
  );

Primary.propTypes = {
  hasGuarantors: PropTypes.bool.isRequired,
};

export default Primary;
