import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/flag"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(2.000000, 2.000000)">
        <path d="M11.7324219,8.43798828 C11.7324219,8.80029478 11.601401,9.09138887 11.3393555,9.3112793 C11.0773099,9.53116972 10.7104516,9.6627602 10.2387695,9.70605469 L10.2387695,10.4067383 L9.77050781,10.4067383 L9.77050781,9.71972656 C9.21451545,9.70833328 8.72689012,9.61035249 8.30761719,9.42578125 L8.30761719,8.5234375 C8.50586037,8.62141976 8.74454613,8.70800744 9.02368164,8.78320312 C9.30281715,8.85839881 9.55175672,8.90283196 9.77050781,8.91650391 L9.77050781,7.85693359 L9.54150391,7.76806641 C9.09032978,7.59033114 8.77075289,7.39721784 8.58276367,7.1887207 C8.39477445,6.98022357 8.30078125,6.72216951 8.30078125,6.41455078 C8.30078125,6.08414548 8.43009311,5.81241969 8.6887207,5.59936523 C8.9473483,5.38631078 9.3079404,5.25699893 9.77050781,5.21142578 L9.77050781,4.68847656 L10.2387695,4.68847656 L10.2387695,5.19775391 C10.760582,5.22054048 11.232257,5.32535714 11.6538086,5.51220703 L11.3325195,6.31201172 C10.977049,6.16617766 10.6124693,6.07731136 10.2387695,6.04541016 L10.2387695,7.05371094 C10.6831077,7.22461023 10.9992666,7.37272073 11.1872559,7.49804688 C11.3752451,7.62337302 11.5131018,7.76122972 11.6008301,7.91162109 C11.6885584,8.06201247 11.7324219,8.23746645 11.7324219,8.43798828 Z M10.7036133,8.48925781 C10.7036133,8.39355421 10.6648767,8.31266309 10.5874023,8.24658203 C10.509928,8.18050097 10.3937182,8.11214228 10.2387695,8.04150391 L10.2387695,8.89257812 C10.5486669,8.84016901 10.7036133,8.70573025 10.7036133,8.48925781 Z M9.32958984,6.41455078 C9.32958984,6.5148117 9.36433885,6.59741178 9.43383789,6.66235352 C9.50333694,6.72729525 9.61555912,6.79394497 9.77050781,6.86230469 L9.77050781,6.05908203 C9.47656103,6.10237652 9.32958984,6.22086492 9.32958984,6.41455078 Z" id="Combined-Shape" fill={color} />
        <path d="M2.22222222,13.6842105 L2.22222222,20 L0,20 L0,0 L10.4244444,0 C10.8451841,0.000212861231 11.2297072,0.225547988 11.4177778,0.582105263 L12.2222222,2.10526316 L18.8888889,2.10526316 C19.5025386,2.10526316 20,2.57654237 20,3.15789474 L20,14.7368421 C20,15.3181945 19.5025386,15.7894737 18.8888889,15.7894737 L11.7977778,15.7894737 C11.3770381,15.7892608 10.992515,15.5639257 10.8044444,15.2073684 L10,13.6842105 L2.22222222,13.6842105 Z M2,2 L2,11.8181818 L11.4125714,11.8181818 L12.5554286,14 L18,14 L18,4.18181818 L10.8731429,4.18181818 L9.73028571,2 L2,2 Z" id="Shape" fill={color} />
      </g>
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
