import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/edit"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M5,19 L6.414,19 L15.728,9.686 L14.314,8.272 L5,17.586 L5,19 Z M21,21 L3,21 L3,16.757 L16.435,3.322 C16.8254999,2.93161806 17.4585001,2.93161806 17.849,3.322 L20.678,6.151 C21.0683819,6.54149985 21.0683819,7.17450015 20.678,7.565 L9.243,19 L21,19 L21,21 Z M15.728,6.858 L17.142,8.272 L18.556,6.858 L17.142,5.444 L15.728,6.858 L15.728,6.858 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
