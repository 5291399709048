import React from 'react';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import ReduxFormCheckBox from 'shared/components/formFields/ReduxForm/Checkbox';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import classNames from 'classnames';
import { Field } from 'redux-form';
import styles from './Person.module.scss';
import { toDateWithSlash } from '../../../../../../../utils/datetime';

const IdentificationSection = React.memo(({ fieldNamePrefix }) => (
  <FieldsContainer
    title="Identification"
    className={styles.sectionWrapper}
    fieldsWrapperClassName={styles.overrideSectionWrapper}
  >
    <Field
      name={`${fieldNamePrefix}.driversLicense.number`}
      className={classNames([styles.fieldWrapper, styles.atSecondColumnOnDesktop])}
      component={PlainTextField}
      label="Drivers licence No."
    />
    <Field
      name={`${fieldNamePrefix}.driversLicense.state`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="State"
    />
    <Field
      name={`${fieldNamePrefix}.driversLicense.expiryDate`}
      className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
      component={PlainTextField}
      label="Expiry date"
      format={value => (value ? toDateWithSlash(value) : '')}
    />
    <Field
      name={`${fieldNamePrefix}.isVerified`}
      className={classNames([styles.isVerified, styles.fieldWrapper, styles.override])}
      component={ReduxFormCheckBox}
      labelName="Identity verified"
    />
  </FieldsContainer>
    ));

IdentificationSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default IdentificationSection;
