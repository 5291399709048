import isEmpty from 'lodash/isEmpty';
import { getCountryNameByCode } from './iso3166CountryCode';
import { getDisplayStreetType } from './streetType';

export const join = (segments, separator = ', ') => segments.filter(Boolean).join(separator);

export const buildStandardSegments = ({
  buildingName, floorNo, unitNo, street = {},
}) => {
  const { no: streetNo, name: streetName, type: streetType } = street || {};
  const displayStreetType = getDisplayStreetType(streetType) || streetType;
  return [
    buildingName,
    floorNo ? `Floor ${floorNo}` : null,
    join([join([unitNo, streetNo], '/'), streetName, displayStreetType], ' '),
  ];
};

const buildNonStandardSegments = ({ nonStdAddress }) => [nonStdAddress];

const buildCommonSegments = ({
                               city, state, postcode, countryCode,
                             }) => {
  const country = getCountryNameByCode(countryCode);
  return [city, join([state, postcode], ' '), country];
};

export default address => {
  if (isEmpty(address)) {
    return '';
  }
  const { addressType } = address;

  switch (addressType) {
    case 'STANDARD':
      return join([...buildStandardSegments(address), ...buildCommonSegments(address)]);
    case 'NON_STANDARD':
      return join([...buildNonStandardSegments(address), ...buildCommonSegments(address)]);
    default:
      return join(buildCommonSegments(address));
  }
};
