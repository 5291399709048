import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from 'constants/message';

export const updateFixedTrails = (
  businessId,
  excludeFromSafetyNet,
  percentage,
  dateFrom,
  dateTo,
  filters,
  batchSize,
) =>
client.mutate({
  mutation: gql`mutation($businessId: String!, $trailDetails: TrailDetails!, $filter: LoanFilter!, $batchSize: Int!) {
      bulkUpdateFixedTrailDetails(
          body: {
              businessId: $businessId,
              trailDetails: $trailDetails,
              filter: $filter,
              batchSize: $batchSize,
          }
      ){
          loansUpdated
      }
  }`,
  variables: {
    businessId,
    trailDetails: {
      excludeFromSafetyNet,
      percentage,
      dateFrom,
      dateTo,
    },
    filter: filters,
    batchSize,
  },
}, { messageKey: MESSAGE_KEYS.BULK_UPDATE_FIXED_TRAIL_DETAILS });
