import React from 'react';
import PropTypes from 'prop-types';

import LoanAmountComponent from './LoanAmountComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedLoanAmount = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
        const { loanAmount } = context.loanCriteriaList[index];
        const isRequired = context.loanCriteriaList.length > 1;
        return (
          <LoanAmountComponent
            loanAmount={loanAmount}
            isRequired={isRequired}
            dispatchUpdateLoanAmount={context.dispatchUpdateLoanAmount(index)}
            dispatchOnLoanAmountBlur={context.dispatchOnLoanAmountBlur}
          />
          );
      }}
  </ProductSearchContext.Consumer>
);

ConnectedLoanAmount.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ConnectedLoanAmount;
