import { TEALIUM_UTAG_URL } from './environment';

/* eslint-disable */

export default () => {
  if (TEALIUM_UTAG_URL) {
    (function (a, b, c, d) {
      a = TEALIUM_UTAG_URL;
      b = document;
      c = 'script';
      d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      a = b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d, a);
    })();
    window.utag_cfg_ovrd = { noview: true };
  }
};

/* eslint-enable */
