import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import styles from './style.module.scss';
import ConnectedProductComparisonTable from './ComparisonTable/ProductComparisonTable';
import usePageViewTracking from '../../pages/usePageViewTracking';
import BackButton from '../../shared/components/BackButton';
import ConnectedDownloadComparison from './DownloadComparison/ConnectedDownloadComparison';
import CriteriaSummary from '../CriteriaSummary/CriteriaSummary';
import { ProductsComparisonTableContext } from '../ProductSearchContext';
import { ApplicationCriteriaShape, LoanCriteriaShape, OptionShape } from '../../shapes';
import ErrorMessage from '../../shared/components/ErrorMessage';

const ProductComparison = React.memo(({ context, showDownloadButton }) => {
  useEffect(() => window.scroll({ left: 0, top: 0 }), []);

  usePageViewTracking('comparison');

  const {
    dispatchModifyCriteria,
    applicationCriteria,
    loanCriteriaList,
    backToSearchRoute,
    comparison,
  } = context;

  if (isEmpty(comparison.selectedOptions)) {
    return backToSearchRoute
      ? <Redirect to={backToSearchRoute} />
      : (
        <ErrorMessage
          title="No products compared"
          description="Please return to product search to select products"
          className={styles.emptyHint}
        />
      );
  }

  return (
    <ProductsComparisonTableContext.Provider value={context}>
      <div>
        <section className={styles.headerSection}>
          <div>
            { backToSearchRoute && <BackButton to={backToSearchRoute} text="Back to search" /> }
            <h1 className={styles.title}>Comparison</h1>
          </div>
          {showDownloadButton && <ConnectedDownloadComparison /> }
        </section>
        <CriteriaSummary
          modifiable={false}
          modifySearchCallback={dispatchModifyCriteria}
          applicationCriteria={applicationCriteria}
          loanCriteriaList={loanCriteriaList}
        />
        <ConnectedProductComparisonTable />
      </div>
    </ProductsComparisonTableContext.Provider>
  );
});

ProductComparison.defaultProps = {
  showDownloadButton: true,
};

ProductComparison.propTypes = {
  context: PropTypes.shape({
    comparison: PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(OptionShape),
    }).isRequired,
    backToSearchRoute: PropTypes.string,
    dispatchModifyCriteria: PropTypes.func.isRequired,
    loanCriteriaList: PropTypes.arrayOf(LoanCriteriaShape).isRequired,
    applicationCriteria: ApplicationCriteriaShape.isRequired,
  }).isRequired,
  showDownloadButton: PropTypes.bool,
};

export default ProductComparison;
