import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import trim from 'lodash/trim';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames/bind';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import AutoSizedTextArea
  from 'shared/components/formFields/AutoSizedTextArea/WithReadOnlyAutoSizedTextArea';
import ErrorMessage from 'shared/components/ErrorMessage';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import RetryComponent from 'shared/components/RetryComponent';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import moment from 'moment';
import { downloadComparisonPdf } from 'shared/api';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import styles from './style.module.scss';
import {
  getDetailsForVariants,
  initComparison,
  initialComparisonSelector, mockFeatureDetailsForCustomVariants,
  productComparisonLoadingStatusSelector, resetComparison,
  stateSelector,
  updateComment,
  updateComparison,
} from './redux';
import CriteriaSummary from './CriteriaSummary';
import ProductComparisonTable from './ProductComparisonTable';
import { FETCHING_STATUS } from '../../../../../constants';
import { getApplicationId } from '../../redux/selectors';
import DownloadComparison from './DownloadComparison/DownloadComparison';
import getComparisonPrintPresenter from './utils/comparisonPrintPresenter';
import openInTab from './utils/openInTab';
import getFormDataFromComparison from './utils/getFormDataFromComparison';

const cx = classNames.bind(styles);

const commentPlaceholder = 'Type your reason for product selection or only showing one product and other comments here';

const validate = formData => {
  if (trim(formData.comment).length === 0) {
    return { comment: 'Please enter a maximum of 20000 characters.' };
  }
  if (trim(formData.comment).length > 20000) {
    return { comment: 'Please enter a maximum of 20000 characters.' };
  }
  if (formData.variantsCombinations.every(it => !it.preferred)) {
    return { variantsCombinations: 'must select a preferred option' };
  }
  return null;
};

const lenderBrokerCodesSelector = state => get(state, 'profile.brokerCodes', {}) || {};

const ProductComparisonPage = () => {
  const initialComparison = useSelector(initialComparisonSelector);
  const currentComparison = useSelector(stateSelector);
  const dispatch = useDispatch();
  const {
    variantsCombinations = [],
    comment,
    searchCriteria = {},
    variantsDetailFetchingStatus,
    includeCommentsInPrintout,
    selectedFeaturesFromComparison,
  } = currentComparison || {};

  const {
    applicationCriteria,
    loansCriteria,
  } = searchCriteria;

  const [initialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isNil(currentComparison)) {
      dispatch(initComparison(initialComparison));
    }
    setIsInitialized(true);
  }, [initialComparison, currentComparison, dispatch, setIsInitialized]);

  useEffect(() => () => {
    dispatch(resetComparison());
  }, [dispatch]);

  useEffect(() => {
    const allVariants = flatten(map(variantsCombinations, 'variants'));
    const customVariants = filter(allVariants, it => it.customProduct);
    const customVariantsWithNoDetail = filter(customVariants, it => isNil(it.featureDetails));
    const realVariants = filter(allVariants, it => !it.customProduct);
    const realVariantsWithNoDetail = filter(realVariants, it => isNil(it.featureDetails));
    if (customVariantsWithNoDetail.length > 0) {
      const variantIds = map(customVariantsWithNoDetail, 'id');
      dispatch(mockFeatureDetailsForCustomVariants(variantIds, [{ featureName: 'Repay Monthly' }]));
    }

    if (realVariantsWithNoDetail.length > 0) {
      const variantIds = map(realVariantsWithNoDetail, 'id');
      dispatch(getDetailsForVariants(variantIds));
    }
  }, [variantsCombinations, dispatch]);

  const emptyCompare = initialized && isEmpty(variantsCombinations);
  const hasEditPermission = useSelector(hasEditPermissionSelector);
  const [commentFieldMeta, setCommentFieldMeta] = useState({});
  const isPristine = useMemo(
    () => isEqual(getFormDataFromComparison(initialComparison), getFormDataFromComparison(currentComparison)),
    [initialComparison, currentComparison],
  );

  const error = useMemo(() => validate({ comment, variantsCombinations }), [comment, variantsCombinations]);
  const lenderBrokerCodes = useSelector(lenderBrokerCodesSelector);
  const applicationId = useSelector(getApplicationId);

  const handleCommentChange = useCallback((value) => {
    dispatch(updateComment(value));
  }, [dispatch]);

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    dispatch(updateComparison(applicationId, currentComparison, lenderBrokerCodes)).finally(() => {
      setIsSaving(false);
    });
  }, [dispatch, currentComparison, lenderBrokerCodes, applicationId, setIsSaving]);

  const showRestoreButton = useMemo(() => !isNil(initialComparison) && !isPristine, [initialComparison, isPristine]);

  const handleRestore = useCallback(() => dispatch(initComparison(initialComparison)), [initialComparison, dispatch]);

  const loadingVariantsDetails = useSelector(productComparisonLoadingStatusSelector);
  const loadingVariantsFail = variantsDetailFetchingStatus === FETCHING_STATUS.ERROR;
  const handleDownload = useCallback(async (userProfile) => {
    const dataForPdf = getComparisonPrintPresenter({
      searchCriteria,
      userProfile,
      generatedAt: moment(),
      selectedFeaturesFromComparison,
      variantsCombinations,
      includeCommentsInPrintout,
      comment,
    });
    const pdfBlob = await downloadComparisonPdf(dataForPdf);
    openInTab(pdfBlob);
  }, [
    comment,
    includeCommentsInPrintout,
    variantsCombinations,
    searchCriteria,
    selectedFeaturesFromComparison,
  ]);
  if (loadingVariantsDetails) {
    return <LoadingSpinner />;
  }

  if (loadingVariantsFail) {
    return <RetryComponent />;
  }

  if (emptyCompare) {
    return (
      <ErrorMessage
        title="No products compared"
        description="Please return to product search to select products"
        className={styles.emptyHint}
      />
    );
  }
  return (
    <div>
      <FormMetaProvider readOnly={!hasEditPermission}>
        <UnsavedChangePrompt shouldConfirm={!isPristine} />
        <section className={styles.headerSection}>
          <div>
            <h1 className={styles.title}>Comparison</h1>
          </div>
        </section>
        <CriteriaSummary
          modifiable={false}
          applicationCriteria={applicationCriteria}
          loansCriteria={loansCriteria}
        />
        {!emptyCompare && <ProductComparisonTable />}
        <AutoSizedTextArea
          label="Comments"
          errorMessage={commentFieldMeta.touched && error && error.comment}
          value={comment}
          onChange={handleCommentChange}
          onBlur={() => {
            setCommentFieldMeta({
              touched: true,
            });
          }}
          className={styles.comments}
          disabled={isSaving || !hasEditPermission}
          isRequired
          placeholder={commentPlaceholder}
        />
        <div>
          {!emptyCompare && (
            <DownloadComparison
              onDownload={handleDownload}
              disabled={!isPristine}
              className={styles.downloadButton}
            />
          )}
          {showRestoreButton && (
            <SecondaryButton
              onClick={handleRestore}
              className={cx({
                restoreButton: true,
                lonelyRestoreButton: emptyCompare,
              })}
            >
              Go back to last saved comparison
            </SecondaryButton>
          )}
        </div>
        <StickyFooter
          onClick={handleSave}
          disabled={isSaving || isPristine || Boolean(error) || !hasEditPermission}
        />
        <LoadingWithOverlay isLoading={isSaving} />
      </FormMetaProvider>
    </div>
  );
};

export default ProductComparisonPage;
