export const applicationStatusSchema = `applicationStatus{
  status
  statusChangeTime
  actionTimelineMap{
    SETTLED{
      statusDateTime
    }
    DECLINED{
      statusDateTime
      reason
    }
    WITHDRAWN{
      statusDateTime
      reason
    }
    UNCONDITIONALLY_APPROVED{
      statusDateTime
    }
    CONDITIONALLY_APPROVED{
      statusDateTime
    }
    SUBMITTED{
      statusDateTime
    }
    DRAFT{
      statusDateTime
    }
    PRE_SUBMISSION{
     statusDateTime
    }
  }
  applyOnlineTimelineMap{
    SETTLED{
      statusDateTime
    }
    DECLINED{
      statusDateTime
    }
    WITHDRAWN{
      statusDateTime
    }
    UNCONDITIONALLY_APPROVED{
      statusDateTime
    }
    CONDITIONALLY_APPROVED{
      statusDateTime
    }
    SUBMITTED{
      statusDateTime
    }
    DRAFT{
      statusDateTime
    }
    PRE_SUBMISSION{
     statusDateTime
    }
  }
}`;

export const productComparisonSchema = `{
  id
  variantsCombinations
    {
      lenderOverrides {
        upfrontPercentage
        trailPercentage
      }
      variants
        {
          source
          id
          lenderName
          productName
          displayName
          standardRate
          discountRate
          comparisonRate
          baseRate
          minLoanValueRatio
          maxLoanValueRatio
          applicationFee
          ongoingFee
          ongoingFeeFrequency
          minLoanAmount
          maxLoanAmount
          firstPeriodRepayment
          firstPeriodInterestOnly
          firstPeriodPrincipalAndInterest
          nextgenMaliId
          totalCost
          maxTerm
          minTerm
          type
          repaymentTypes
          externalLink
          legalFee
          valuationFee
          repaymentAvailable
          discountMonths
          upfrontFee
          lmi
          modificationOriginals {
            totalCost
            firstPeriodInterestOnly
            firstPeriodPrincipalAndInterest
            firstPeriodRepayment
            upfrontFee
            ongoingFee
            lmi
            comparisonRate
            standardRate
          }
          customProduct
        },
      preferred
    },
  comment,
  selectedFeaturesFromComparison,
  includeCommentsInPrintout,
  searchCriteria {
    loansCriteria {
      loanAmount
      combinedLoanAmount
      purpose
      productType
      repaymentType
      loanTerm
      fixedLoanTerm
      interestOnlyTerm
      selectedFeatureNames
      purposeCategory
      purposeDetails
      refinanceReason
    }
    applicationCriteria {
      applicationType
      totalCombinedLoanAmount
      totalLoanAmount
      securityValue
      loanValueRatio
      selectedLenderNames
    }
    totalCostTerm
    repaymentFrequency
  }
}`;

export const activityLogSchema = `{
          id
          userId
          userName
          applicationId
          actionTime
          activityLogType
          eventDetail {
            resourceId
            resourceName
            resourceSource
            extraInfo
            changes {
              path
              oldValue
              newValue
            }
          }
          createdAt
          createdByName
        }`;

export const financeSchema = `{
  incomes {
    id
    value
    type
    governmentBenefitsType
    description
    periodUnit
    ownerships {
      percentage
      applicantId
    }
  }
  assets {
    realEstates {
      id
      value
      securityLimit
      primaryPurpose
      propertyType
      valuationType
      residentialType
      commercialType
      ruralType
      industrialType
      asSecurity
      isForConstructionLoan
      currentValue
      contractPrice {
        contractPriceAmount
        transferOfLandAmount
      }
      transactionType
      assetStatus
      ownerships {
        percentage
        applicantId
      }
      rentalIncome {
        rentalAmount
        periodUnit
      }
      investmentPropertyCost {
        value
        frequency
      }
      address{
        addressId
        nonStdAddress
        buildingName
        floorNo
        unitNo
        street{
          type
          name
          no
        }
        city
        state
        postcode
        countryCode
        addressType
      }
      isPreApproval
      preApprovalStatus
      residents
    }
    nonRealEstates {
      id
      assetType
      value
      vehicleType
      make
      year
      model
      accountNumber
      accountName
      bsb
      institution
      ownerships {
        percentage
        applicantId
      }
      description
    }
  }
  expenseInfo {
    expenseNote
    householdExpenses {
      id
      household {
        id
        applicantsId
        dependents
      }
      expenses {
        id
        type
        value
        periodUnit
        ownerships {
          percentage
          applicantId
        }
      }
    }
  }
  liabilities {
    id
    type
    currentBalance
    limit
    description
    accountDetails {
      accountNumber
      accountName
      bsb
      lender
    }

    interestRatePercent
    interestType
    remainingTermMonths
    originalTermYears
    paymentType
    repayments {
      value
      periodUnit
    }
    taxDeductible
    clearingStatus
    clearingAmount
    exitFeesBreakCosts
    ownerships {
      applicantId
      percentage
    }
    securedBy {
      relatedAssetId
    }
    existingLoan {
      id
      accountNumber
      settledAmount
      lenderName
      productName
      applicantsName
    }
  }
}`;

export const needAndObjectiveSchema = `{
  primary {
    seekCreditReason
    immediateNeedsAndObjectives
    longerTermGoal
    preferredLender
    guarantorGoalsAndObjective
  }
  rate {
    variable {
     importantDegree
     reasons
     otherDetail
    }
    fixed {
      importantDegree
      reasons
      otherDetail
      preferredDuration
    }
    fixedAndVariable {
      importantDegree
      reasons
      otherDetail
      preferredDuration
    }
  }
  repayment {
    principalAndInterest {
     importantDegree
     reasons
     otherDetail
     frequency
    }
    interestOnly {
      importantDegree
      reasons
      otherDetail
      frequency
      preferredDuration
    }
    interestInAdvance {
      importantDegree
      reasons
      otherDetail
    }
    lineOfCredit {
      importantDegree
      reasons
      otherDetail
      repaymentPlans
      repaymentPlanOtherDetail
    }
  }
  productFeature {
    offset {
     importantDegree
     reasons
     otherDetail
    }
    redraw {
      importantDegree
      reasons
      otherDetail
    }
  }
  futureCircumstances {
    applicantId
    adverseChanges {
      anyChange
      significantChanges {
        type
        typeOtherDetail
        amount
        periodOfImpact
        startDate
        repaymentPlans
        repaymentPlanOtherDetail
      }
    }
    retirementPlan {
      matureAge
      retireDuringLoanTerm
      repaymentMethods
      repaymentMethodOtherDetail
    }
  }
  guarantorFutureCircumstances {
    applicantId
    adverseChanges {
      anyChange
      significantChanges {
        type
        typeOtherDetail
        amount
        periodOfImpact
        startDate
        repaymentPlans
        repaymentPlanOtherDetail
      }
    }
    retirementPlan {
      matureAge
      retireDuringLoanTerm
      repaymentMethods
      repaymentMethodOtherDetail
    }
   }
  refinanceAndDebtConsolidation {
    applicable
    reasons
    otherDetail
  }
}`;

export const getGuarantorsSchema = `{
  persons {
    id
    applicantUniqueKey
    name
    abbreviateName
    isDeleted
    isPrimary
    isSurveyContact
    isMainContact
    isVerified
    email
    homePhone
    workPhone
    mobile
    preferredPhone
    externalLink

    title
    firstName
    otherName
    surname
    gender
    dateOfBirth
    creditGuideSentDate
    numberOfDependents
    maritalStatus {
      status
      spouse {
        relatedApplicantId
        firstName
        surname
        isNonApplicant
      }
    }
    dependents {
      firstName
      surname
      dateOfBirth
    }
    residency {
      country
      status
      permanentInAustralia
    }
    citizenship {
      country
    }
    driversLicense {
      number
      expiryDate
      state
    }
    contactDetails {
      mobile
      emailAddress
      homePhone
      workPhone
      preferredContactMethod
      addresses {
        residential
        priorAddress
        housingStatus
        startDate
        endDate
        addressDetail {
          nonStdAddress
          buildingName
          floorNo
          unitNo
          street {
            type
            name
            no
          }
          city
          state
          postcode
          countryCode
          addressType
        }
      }
    }
    employments{
      id
      status
      role
      roleDescription
      onProbation
      employmentType
      type
      financialYear
      employmentIncomes {
        id
        type
        addbackType
        governmentBenefitsType
        value
        previousValue
        periodUnit
        description
      }
      employerType
      abn {
        abnNumber
        abnEstablishedDate
      }
      employer{
        companyName
        personName{
          title
          firstName
          surname
        }
        employerContact{
          phone
          countryCode
          address{
            addressId
            nonStdAddress
            buildingName
            floorNo
            unitNo
            street{
              type
              name
              no
            }
            city
            state
            postcode
            countryCode
            addressType
          }
        }
      }
      onBenefits
      student
      homeDuties
      startDate
      endDate
    }
  }
  companies {
    id
    name
    abbreviateName
    isPrimary
    externalLink
    description
    type
    abn
    abrn
    acn
    dateOfRegistration
    trustees {
    relatedApplicantId
    }
    directors {
    relatedApplicantId
    }
    beneficiaries {
      relatedApplicantId
      firstName
      surname
      isNonApplicant
    }
    companyIncomeInfo {
      financialYear
      incomes {
        id
        type
        addbackType
        description
        incomeValues
      }
    }
  }
}`;

export const companyApplicantSchema = `
companies{
  id
  name
  abbreviateName
  isPrimary
  externalLink
  creditGuideSentDate
  description
  type
  abn
  abrn
  acn
  dateOfRegistration
  trustees {
   relatedApplicantId
  }
  directors {
  relatedApplicantId
  }
  beneficiaries {
    relatedApplicantId
    firstName
    surname
    isNonApplicant
  }
  companyIncomeInfo {
    financialYear
    incomes {
      id
      type
      addbackType
      description
      incomeValues
    }
  }
}`;

export const getApplicationActionTimeLineMap = () => `{
   ${applicationStatusSchema}
  }`;

export const getApplicationSchema = () => `{
          id,
          applicationNumber
          isRecentlySentSurvey
          isOwnerOccupied
          applicants{
            persons{
              id
              applicantUniqueKey
              name
              abbreviateName
              isDeleted
              isPrimary
              isSurveyContact
              isMainContact
              isVerified
              email
              homePhone
              workPhone
              mobile
              preferredPhone
              externalLink
              title
              firstName
              otherName
              surname
              gender
              dateOfBirth
              creditGuideSentDate
              numberOfDependents
              maritalStatus{
                status
                spouse {
                  relatedApplicantId
                  firstName
                  surname
                  isNonApplicant
                }
              }
              dependents{
                firstName
                surname
                dateOfBirth
              }
              residency{
                country
                status
                permanentInAustralia
              }
              citizenship{
                country
              }
              driversLicense{
                number
                expiryDate
                state
              }
              contactDetails{
                mobile
                emailAddress
                homePhone
                workPhone
                preferredContactMethod
                addresses{
                  residential
                  priorAddress
                  housingStatus
                  startDate
                  endDate
                  addressDetail{
                    nonStdAddress
                    buildingName
                    floorNo
                    unitNo
                    street{
                      type
                      name
                      no
                    }
                    city
                    state
                    postcode
                    countryCode
                    addressType
                  }
                }
              }
              employments{
                id
                status
                role
                roleDescription
                onProbation
                employmentType
                type
                financialYear
                employmentIncomes {
                  id
                  type
                  addbackType
                  governmentBenefitsType
                  value
                  previousValue
                  periodUnit
                  description
                }
                employerType
                abn {
                  abnNumber
                  abnEstablishedDate
                }
                employer{
                  companyName
                  personName{
                    title
                    firstName
                    surname
                  }
                  employerContact{
                    phone
                    countryCode
                    address{
                      addressId
                      nonStdAddress
                      buildingName
                      floorNo
                      unitNo
                      street{
                        type
                        name
                        no
                      }
                      city
                      state
                      postcode
                      countryCode
                      addressType
                    }
                  }
                }
                onBenefits
                student
                homeDuties
                startDate
                endDate
              }
            },
            ${companyApplicantSchema}
          }
          source
          preSubmission {
            sentToApplyOnline
            shouldSendToApplyOnline
            signedCreditCheckAuthority
            signedLowDocClientAcknowledgement
            signedFixRateLockDeclaration
            comment
            estimatedCommissionLoanAmount
            somaAdditionalComment
          }
          somas {
            id
            type
            generatedDate
            issued
            signed
          }
          fundingWorksheet {
            contributions {
              genuineSavings
              firstHomeOwnerGrant
              gift
              initialDepositAmount
              netProceedsFromSaleOfProperty
              otherFunds {
                fieldName
                fieldAmount
              }
            }
            applicationCosts {
              foreignPurchaser
              eligiblePensioner
              firstHomeBuyer
              mortgageRegistrationFee {
                value
                overrideValue
              }
              applicationFee
              fundsDisbursement
              valuationFee
              legalFee
              lmi {
                value
                overrideValue
              }
              lmiReferencedLenderId
            }
            costToPurchases {
              id
              assetId
              stampDutyOnTransferOfProperty {
                value
                overrideValue
              }
              titleTransferFee {
                value
                overrideValue
              }
              inspectionFee
              adjustmentOfRates
              otherCosts {
                fieldName
                fieldAmount
              }
            }
            refinances {
              id
              liabilityId
              dischargeFee
              others {
                fieldName
                fieldAmount
              }
            }
              constructions {
              id
              assetId
              otherCosts {
                fieldName
                fieldAmount
              }
            }
            loanAmount {
              baseLoanAmount
              capitaliseLmi
            }
            otherCosts {
              fieldName
              fieldAmount
            }
            somaComments
          }
          ${applicationStatusSchema}
          totalLoanAmount
          adviser
          writer {
            id
            fullName
          }
          lenderBrokerCode
          lender{
            lenderId
            name
            bankPortalForBroker
          }
          assetFinanceLender{
            lenderId
            name
          }
          hasAssetFinanceLender
          applicationLVR
          notesLink
          businessId
          expectedSettlementDate
          preApprovalExpiryDate
          financeDueDate
          applicationUpdates {
            id
            type
            timestamp
            name
            receivedTimestamp
            messageAnnotations {
              type
              value
            }
          }
          taskLists{
            id
            name
            order
            tasks {
              id
              name
              description
              dueDateTime
              completed
              assignee {
                firstName
                surname
                id
              }
              comments {
                name
                content
                createdAt
              }
            }
          }
          activityLogs ${activityLogSchema}
          thirdParties {
            id
            businessPartnerId
            isSearched
            type
            companyName
            firstName
            surname
            workPhone
            mobile
            emailAddress
            thirdPartyUniqueKey
            notesIdentifier
          }
          referrer {
            isExistingClient
            referralMainCategory
            referralSubCategory
            referralSource
            referralFee
            referralCompany
          }
          insuranceReferrals {
            allianzReferral {
              allianzPromptForReferralDate
              allianzOptOut
              allianzOptOutReason
              allianzReferralDetails {
                adviserId
                adviserName
                applicantId
                applicantName
                phoneNumber
                alternativePhoneNumber
                preferredTime
                email
                reason
                message
              }
            }
            loanProtectionInsurance {
              compliance {
                outcome
                discussedWithClients
              }
            }
          }
          productComparison ${productComparisonSchema}
          finance ${financeSchema}
          needAndObjective ${needAndObjectiveSchema}
          guarantors ${getGuarantorsSchema}
        loanType
        isHiddenOnSwimlane
      }`;

export const getClientSchema = `{
      id
      firstName
      surname
      email
      type
      nameTitle
      homePhone
      workPhone
      mobile
      middleNames
      maritalStatus
      dateOfBirth
      sex
      numberOfDependents
      driversLicense {
        number
        expiryDate
        state
      }
      countryOfCitizenship
      residency {
        country
        status
        permanentInAustralia
      }
      dependents {
        firstName
        surname
        dateOfBirth
      }
      addresses {
        addressType
        buildingName
        countryCode
        unitNo
        floorNo
        postcode
        state
        streetName
        streetNo
        streetType
        housingStatus
        nonStdAddress
        addressDateStart
        addressDateEnd
        isPrior
        suburb
      }
    }
`;

export const companyClientSchema = `
{
  id
  businessId
  abn
  abrn
  acn
  companyDescription
  companyKey
  companyName
  trustName
  dateRegistered
  typeOfIncorporation
}
`;

export const fundingPositionsSchema = `
{
  totalCosts
  fundsAvailable
  proposedLoanAmountBeforeLmi
  totalSecurity
  baseLvr
  totalLvr
  capitaliseLmi
  lmi
  baseLoanAmount
  topUpAmount
}
`;

export const fundingWorksheetFeesSchema = `
{
  lmi
  mortgageRegistrationFee
  costToPurchases {
    id
    titleTransferFee
    stampDutyOnTransferOfProperty
  }
}
`;
