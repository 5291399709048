import React from 'react';
import PropTypes from 'prop-types';
import BasicRichContent from '../BasicRichContent';

const LoanChangeContent = ({ loanRequestName, content }) => (
  <BasicRichContent
    content={loanRequestName}
    subContent={content}
  />
);

LoanChangeContent.propTypes = {
  loanRequestName: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

export default LoanChangeContent;
