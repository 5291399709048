import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from 'shared/components/Modal/Modal';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import reject from 'lodash/reject';
import ValidationSettlementErrorPopup from './ValidationSettlementErrorPopup';
import ConfirmSettlementPopup from '../ConfirmSettlementPopup/index';
import api from '../Applications/redux/apis';
import styles from './ValidationConfirmSettlementPopup.module.scss';
import ValidationModal from '../ApplicationDetails/ApplicationDetailContentNav/shared/ValidationModal';
import { resolveSettlementError } from '../ApplicationDetails/redux/validation/validationResolver';
import { getCombinedApplicants } from '../../utils';

const resolveErrorMessages = (errorBody, loanRequests, applicants) => {
  const isLaunchpadCreated = get(errorBody, 'isLaunchpadCreated', false);
  const validationResult = get(errorBody, 'invalidProperties');

  return isLaunchpadCreated ? {
    messages: resolveSettlementError(validationResult, { loans: loanRequests, applicants }),
    isLaunchpadCreated,
  } : errorBody;
};

const ValidationConfirmSettlementPopup = ({
 getLoanRequests, applicationId, applicants, lenderId, actionTimelineMap, submitSettlement, closeModal,
}) => {
  const [errorBody, setErrorBody] = useState(null);
  const [isValidating, setValidating] = useState(false);
  const [loanRequests, setLoanRequests] = useState([]);

  useEffect(() => {
    let isAlive = true;
    setValidating(true);
    let loans;
    getLoanRequests(applicationId)
      .then((data) => {
        setLoanRequests(data);
        loans = data;
        return api.validateApplicationSettlement(applicationId);
      })
      .catch((error) => {
        const rawErrorMessage = get(error, 'graphQLErrors[0].message');
        const errorCode = get(error, 'graphQLErrors[0].errorCode');
        if (rawErrorMessage && errorCode === 400) {
          const errorMessage = JSON.parse(rawErrorMessage);
          if (errorMessage.errorCode === 'invalid_application_settlement' && isAlive) {
            setErrorBody(resolveErrorMessages(errorMessage.body, loans, applicants));
          }
        } else {
          closeModal();
        }
      })
      .finally(() => isAlive && setValidating(false));
    return () => {
      isAlive = false;
    };
  }, [getLoanRequests, applicationId, closeModal, applicants, setLoanRequests]);

  const filteredLoanRequests = useMemo(() =>
    reject(loanRequests, loan => (loan.dateOfDeclined || loan.dateOfWithdrawn)), [loanRequests]);

  const render = () => {
    if (isValidating) {
      return (
        <Modal
          isOpen
          onRequestClose={closeModal}
          header="Loading"
          className={styles.loadingSpinnerWrapper}
        >
          <LoadingSpinner />
        </Modal>
      );
    }
    if (!errorBody) {
      return (
        <ConfirmSettlementPopup
          applicationId={applicationId}
          applicants={getCombinedApplicants(applicants)}
          lenderId={lenderId}
          actionTimelineMap={actionTimelineMap}
          onSubmit={submitSettlement}
          onClose={closeModal}
          loanRequests={filteredLoanRequests}
        />
      );
    }
    if (get(errorBody, 'isLaunchpadCreated', false)) {
        return (
          <ValidationModal
            messages={errorBody.messages}
            onRequestClose={closeModal}
            description="Please add the following missing information before you can settle the application:"
            isOpen
          />
        );
    }
   return (
     <ValidationSettlementErrorPopup
       errorBody={errorBody}
       loanRequests={loanRequests}
       onClose={closeModal}
       applicationId={applicationId}
     />
   );
  };
  return render();
};

ValidationConfirmSettlementPopup.defaultProps = {
  submitSettlement: () => null,
  closeModal: () => null,
};

ValidationConfirmSettlementPopup.propTypes = {
  submitSettlement: PropTypes.func,
  closeModal: PropTypes.func,
  actionTimelineMap: PropTypes.object.isRequired,
  applicants: PropTypes.shape({
    persons: PropTypes.array,
    companies: PropTypes.array,
  }).isRequired,
  applicationId: PropTypes.string.isRequired,
  lenderId: PropTypes.string.isRequired,
  getLoanRequests: PropTypes.func.isRequired,
};

export default ValidationConfirmSettlementPopup;
