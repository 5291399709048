import React, { useEffect, useState } from 'react';
import { dateFormatter } from 'shared/formatterUtils';
import Summary from 'shared/components/Summary';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { LenderField } from 'Loans/components/LoanDetails/LoanAbstract/Lender/LenderField';
import { AdviserField } from 'Loans/components/LoanDetails/LoanAbstract/AdviserField';
import { AccountNumberField } from 'Loans/components/LoanDetails/LoanAbstract/AccountNumberField';
import moment from 'moment';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import FormsyDatePicker from 'shared/components/formFields/Formsy/FormsyDatePicker';
import LoanShape from '../../../shapes/LoanShape';
import LoanLenderShape from '../../../shapes/LoanLenderShape';
import styles from './LoanAbstract.module.scss';
import { getApplicantNamesMultipleLines } from '../../../utils';
import { SubLenderField } from './Lender/SubLenderField';
import { EditField, USER_PERMISSIONS } from '../EditField';
import {
 getLoanStatusDescription, getOrderedLoanStatusOptions, LOAN_IN_ARREARS, PAID_OUT,
} from './loanStatusHelper';
import { selectLender } from '../redux/loanActions';
import {
  getAccountNumberValidationRule,
  getInitialLender,
  getIsLenderSmartlineAssetFinance,
  getIsLenderXeniumFinance,
  getLender,
  getLoanType,
} from '../../../selectors/loanSelector';
import useToggleCheck from '../../../../shared/hooks/useToggleCheck';
import TOGGLES from '../../../../shared/toggles';

const SMARTLINE_ASSET_FINANCE = 'Smartline Asset Finance';
const XENIUM_FINANCE = 'Xenium Finance';

const isSmartlineAssetFinanceSelected = (isLenderSmartlineAssetFinance, loan) =>
  isLenderSmartlineAssetFinance && (loan.lender.name !== SMARTLINE_ASSET_FINANCE || (
    loan.lender.name === SMARTLINE_ASSET_FINANCE && !isEmpty(loan.assetFinanceLender)
  ));

const isXeniumFinanceSelected = (isLenderXeniumFinance, loan) =>
  isLenderXeniumFinance && (loan.lender.name !== XENIUM_FINANCE || (
    loan.lender.name === XENIUM_FINANCE && !isEmpty(loan.assetFinanceLender)
  ));

const isSubLender = (selectedLoanType, isLenderSmartlineAssetFinance, isLenderXeniumFinance, loan) =>
  hasSmartlineAssetFinanceLender(isSmartlineAssetFinanceSelected(isLenderSmartlineAssetFinance, loan), selectedLoanType)
  || hasXeniumFinanceLender(isXeniumFinanceSelected(isLenderXeniumFinance, loan), selectedLoanType);

const hasSubLender = (selectedLoanType, isLenderSmartlineAssetFinance, isLenderXeniumFinance) =>
  hasSmartlineAssetFinanceLender(isLenderSmartlineAssetFinance, selectedLoanType)
  || hasXeniumFinanceLender(isLenderXeniumFinance, selectedLoanType);

const hasSmartlineAssetFinanceLender = (isSmartlineAssetFinance, type) => isSmartlineAssetFinance && (type === 'Consumer' || type === 'Commercial');

const sublenderTitle = () => (
  useToggleCheck(TOGGLES.LOAN_DETAILS_DISPLAY_SUBLENDER) ? 'SUB LENDER' : 'ASSET FINANCE LENDER');
const hasXeniumFinanceLender = (isXeniumFinance, type) => isXeniumFinance && type === 'Commercial';

const formatDate = date => (date ? dateFormatter.toDdmmyy(date) : '');

const getSelectedOption = (value, options) => options.find(option => option.value === value);

const LoanAbstract = ({
  loan, businessId, isEditing, selectedLoanType, selectedPrimaryLender, initialLender,
  dispatchSelectLender, accountNumberValidationRule, isLenderSmartlineAssetFinance, isLenderXeniumFinance,
}) => {
  useEffect(() => {
    dispatchSelectLender(initialLender);
  }, [dispatchSelectLender, initialLender, isEditing]);

  const [currentLoanStatus, updateCurrentLoanStatus] = useState(loan.status);
  const [currentPaidOutDate, updateCurrentPaidOutDate] = useState(loan.repaidDate);

  useEffect(() => {
    if (currentLoanStatus === LOAN_IN_ARREARS) {
      updateCurrentPaidOutDate(undefined);
    } else {
      updateCurrentPaidOutDate(loan.repaidDate);
    }
  }, [currentLoanStatus, loan.repaidDate]);

  const updateLenderType = (value) => {
    dispatchSelectLender({ lenderId: value.value, name: value.label });
  };

  const loanStatusOptions = getOrderedLoanStatusOptions(loan);
  const loanStatusDescription = getLoanStatusDescription(loan);

  const repaidDatePickerMinValue = loan.reconciledDate ?? '1970-01-01';

  return (
    <Summary
      fields={[
        {
          title: 'APPLICANT/S',
          content: getApplicantNamesMultipleLines(loan.applicants),
          className: styles.applicant,
        },
        {
          title: 'ACCOUNT NUMBER',
          content: (
            <AccountNumberField
              accountNumber={loan.accountNumber}
              reconciled={!isEmpty(loan.reconciledDate)}
              accountNumberValidationRule={accountNumberValidationRule}
            />
          ),
        },
        {
          title: 'ADVISER',
          content: (
            <AdviserField adviser={loan.adviser} businessId={businessId} reconciled={!isEmpty(loan.reconciledDate)} />
          ),
        },
        {
          title: sublenderTitle(),
          content: (
            <SubLenderField
              subLender={loan.assetFinanceLender}
              primaryLenderId={selectedPrimaryLender?.lenderId}
              required={isSubLender(selectedLoanType, isLenderSmartlineAssetFinance, isLenderXeniumFinance, loan)}
            />
          ),
          noDisplay: !hasSubLender(selectedLoanType, isLenderSmartlineAssetFinance, isLenderXeniumFinance),
        },
        {
          title: 'LENDER',
          content: (
            <LenderField lender={loan.lender} onChange={updateLenderType} />
          ),
          className: styles.lender,
        },
        {
          title: 'LOAN STATUS',
          content: (
            <EditField
              permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
              fallback={loanStatusDescription}
            >
              <FormsySelect
                name="status"
                options={loanStatusOptions}
                value={getSelectedOption(loan.status, loanStatusOptions)}
                onChange={option => updateCurrentLoanStatus(option.value)}
                fullWidth
              />
            </EditField>
          ),
        },
        {
          title: 'SETTLED DATE',
          content: formatDate(loan.settledDate),
          className: styles.settlementDate,
        },
        {
          title: 'RECONCILED DATE',
          content: formatDate(loan.reconciledDate),
          className: styles.reconciliationDate,
        },
        {
          title: 'PAID OUT DATE',
          content: (
            <EditField
              permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
              fallback={formatDate(loan.repaidDate)}
            >
              <FormsyDatePicker
                type="date"
                name="repaidDate"
                value={currentPaidOutDate}
                fullWidth
                max={moment().toDate()}
                min={moment(repaidDatePickerMinValue)}
                showClearButton
                disabled={!loan.reconciledDate || currentLoanStatus === LOAN_IN_ARREARS}
                validations={{
                    noLaterThanCurrentDate:
                      (values, value) => (value ? moment(value).isSameOrBefore(moment()) : true),
                    noBeforeThanReconciledDate:
                      (values, value) => (value ? moment(value).isSameOrAfter(moment(loan.reconciledDate)) : true),
                  }}
                validationErrors={{
                    noLaterThanCurrentDate: 'Please enter a date not after today',
                    noBeforeThanReconciledDate: 'Please enter a date not earlier than reconciled date',
                  }}
                required={currentLoanStatus === PAID_OUT}
              />
            </EditField>
          ),
          className: styles.paidOutDate,
        },
      ]}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    loans: {
      loan: { data, isEditing },
    },
    business: {
      selectedBusiness: { id },
    },
  } = state;

  return ({
    loan: data,
    businessId: id,
    isEditing,
    selectedPrimaryLender: getLender(state),
    selectedLoanType: getLoanType(state),
    initialLender: getInitialLender(state),
    accountNumberValidationRule: getAccountNumberValidationRule(state),
    isLenderSmartlineAssetFinance: getIsLenderSmartlineAssetFinance(state),
    isLenderXeniumFinance: getIsLenderXeniumFinance(state),
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchSelectLender: lender => dispatch(selectLender(lender)),
});

LoanAbstract.defaultProps = {
  initialLender: undefined,
};

LoanAbstract.propTypes = {
  loan: LoanShape.isRequired,
  businessId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  selectedPrimaryLender: LoanLenderShape.isRequired,
  selectedLoanType: PropTypes.string.isRequired,
  initialLender: LoanLenderShape,
  dispatchSelectLender: PropTypes.func.isRequired,
  accountNumberValidationRule: PropTypes.shape({
    lenderId: PropTypes.string,
    loanIdentifierCalledAs: PropTypes.string,
    regex: PropTypes.string.isRequired,
    maxLength: PropTypes.string.isRequired,
    minLength: PropTypes.string.isRequired,
  }).isRequired,
  isLenderSmartlineAssetFinance: PropTypes.bool.isRequired,
  isLenderXeniumFinance: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanAbstract);
