import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/Icon/svg/Spinner';
import SpeechBubble from './svg/SpeechBubble';
import FilePdf from './svg/FilePdf';
import FileExcel from './svg/FileExcel';
import Alert from './svg/Alert';
import AlertInvert from './svg/AlertInvert';
import ArrowDown from './svg/ArrowDown';
import ArrowDownSolid from './svg/ArrowDownSolid';
import ArrowLeft from './svg/ArrowLeft';
import ArrowLeftSolid from './svg/ArrowLeftSolid';
import ArrowUp from './svg/ArrowUp';
import ArrowUpSolid from './svg/ArrowUpSolid';
import ArrowRight from './svg/ArrowRight';
import ArrowRightSolid from './svg/ArrowRightSolid';
import Check from './svg/Check';
import CheckCircle from './svg/CheckCircle';
import CheckCircleInvert from './svg/CheckCircleInvert';
import Close from './svg/Close';
import CloseCircle from './svg/CloseCircle';
import Duplicate from './svg/Duplicate';
import Edit from './svg/Edit';
import EditCircle from './svg/EditCircle';
import Flag from './svg/Flag';
import Warning from './svg/Warning';
import TasksCircle from './svg/TasksCircle';
import Tasks from './svg/Tasks';
import Share from './svg/Share';
import InformationOutline from './svg/InformationOutline';
import Information from './svg/Information';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Menu from './svg/Menu';
import Note from './svg/Note';
import Delete from './svg/Delete';
import Drag from './svg/Drag';
import NoteCircle from './svg/NoteCircle';
import FlagCircle from './svg/FlagCircle';
import Add from './svg/Add';
import Refresh from './svg/Refresh';
import ArrowDropDown from './svg/ArrowDropDown';

export const iconComponents = {
  'speech-bubble': SpeechBubble,
  'file-pdf': FilePdf,
  'file-excel': FileExcel,
  'arrow-up': ArrowUp,
  'arrow-up-solid': ArrowUpSolid,
  'arrow-down': ArrowDown,
  'arrow-down-solid': ArrowDownSolid,
  'arrow-drop-down': ArrowDropDown,
  'arrow-left': ArrowLeft,
  'arrow-left-solid': ArrowLeftSolid,
  'arrow-right': ArrowRight,
  'arrow-right-solid': ArrowRightSolid,
  check: Check,
  'check-circle': CheckCircle,
  'check-circle-invert': CheckCircleInvert,
  close: Close,
  'close-circle': CloseCircle,
  duplicate: Duplicate,
  edit: Edit,
  'edit-circle': EditCircle,
  flag: Flag,
  'flag-circle': FlagCircle,
  info: Information,
  'info-outline': InformationOutline,
  share: Share,
  tasks: Tasks,
  'tasks-circle': TasksCircle,
  warning: Warning,
  'alert-invert': AlertInvert,
  alert: Alert,
  spinner: Spinner,
  menu: Menu,
  note: Note,
  'note-circle': NoteCircle,
  delete: Delete,
  drag: Drag,
  add: Add,
  refresh: Refresh,
};

/**
 * @return {null}
 */
function Icon({
 alt, name, size, color, loading, style, ...others
}) {
  if (!Object.keys(iconComponents).includes(name)) {
    // eslint-disable-next-line no-console
    console.error('Icon does not exist');
    return null;
  }

  const IconComponent = iconComponents[name];

  return loading ? (
    <LoadingSpinner mini /> // TODO: pass icon size to spinner
  ) : (
    <IconComponent height={size} width={size} alt={alt} color={color} style={style} {...others} />
  );
}

Icon.defaultProps = {
  alt: undefined,
  size: '24px',
  color: undefined,
  loading: false,
  name: undefined,
  style: undefined,
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconComponents)),
  alt: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Icon;
