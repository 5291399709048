import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const getInvoices = businessId =>
client.query({
  query: gql`{
    invoices(businessId: "${businessId}")
    {
      id
      businessId
      businessUniqueName
      processingMonth
      issueDate
      totalExcludingGst
      totalGst
      totalIncludingGst
      supplierName
      supplierCompany
      supplierAbn
      lineItems {
        description
        amountExcludingGst
        gst
        amountIncludingGst
        allAdvisers
      }
    }
  }`,
});

export default {
  getInvoices,
};
