import React from 'react';
import PropTypes from 'prop-types';
import {
  Plugin,
  Getter,
} from '@devexpress/dx-react-core';

const ClassNameInjecter = React.memo(({ classNames }) => (
  <Plugin>
    <Getter name="classNames" value={classNames} />
  </Plugin>
));

ClassNameInjecter.propTypes = {
  classNames: PropTypes.object,
};

ClassNameInjecter.defaultProps = {
  classNames: undefined,
};

export default ClassNameInjecter;
