import { beforeNow } from 'utils/datetime';
import isEmpty from 'lodash/isEmpty';
import { applicationStatus } from '../../../../constants/applicationStatus';

const getContentWithDate = (reminderName, date, isExpired = false) => ({
  isVisible: !isEmpty(date),
  date,
  reminderName,
  isExpired,
});

const getReminderPresenter = ({
 status, settledDate, expectedSettlementDate, preApprovalExpiryDate, financeDueDate,
}) => {
  const financeDueDateContent = getContentWithDate('FINANCE DUE', financeDueDate, beforeNow(financeDueDate));
  const expectedSettlementDateContent = getContentWithDate('EXPECTED SETTLEMENT', expectedSettlementDate, beforeNow(expectedSettlementDate));
  const settledDateDateContent = getContentWithDate('SETTLED', settledDate);
  const preApprovalExpiryDateContent = getContentWithDate('PRE APPROVAL EXPIRES', preApprovalExpiryDate, beforeNow(preApprovalExpiryDate));

  switch (status) {
    case applicationStatus.PRE_SUBMISSION:
      return expectedSettlementDateContent;
    case applicationStatus.DRAFT:
      return expectedSettlementDateContent;
    case applicationStatus.SUBMITTED:
      return financeDueDate ? financeDueDateContent : expectedSettlementDateContent;
    case applicationStatus.CONDITIONALLY_APPROVED:
      if (preApprovalExpiryDate) return preApprovalExpiryDateContent;
      return financeDueDate ? financeDueDateContent : expectedSettlementDateContent;
    case applicationStatus.UNCONDITIONALLY_APPROVED:
      return expectedSettlementDateContent;
    case applicationStatus.SETTLED:
      return settledDateDateContent;
    default: return {};
  }
};

export default getReminderPresenter;
