import React, { useCallback } from 'react';
import { fieldPropTypes } from 'redux-form';
import OverrideText from '../../OverrideText';

const ReduxFormOverrideText = ({ ...props }) => {
  const {
    input: { value, onChange },
  } = props;
  const handleChange = useCallback((v) => onChange(v === undefined ? null : v), [onChange]);
  return (
    <OverrideText
      value={value.value || 0}
      overrideValue={value.overrideValue}
      onChange={handleChange}
      {...props}
    />
    );
};

ReduxFormOverrideText.propTypes = fieldPropTypes;

export default ReduxFormOverrideText;
