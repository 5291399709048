import get from 'lodash/get';
import { TRANSACTION_TYPES } from '../../redux/constants';

const getOwnedRealEstate = (realEstate) => {
  const {
    asSecurity, isForConstructionLoan, securityLimit, contractPrice, assetStatus, residents,
  } = realEstate;
  return {
    ...realEstate,
    securityLimit: (asSecurity && !isForConstructionLoan) ? securityLimit : null,
    contractPrice: {
      ...contractPrice,
      contractPriceAmount: isForConstructionLoan ? get(contractPrice, 'contractPriceAmount') : null,
    },
    assetStatus: isForConstructionLoan ? assetStatus : null,
    residents: isForConstructionLoan ? residents : null,
  };
};

export const getSubmittableAssets = (assets) => ({
  nonRealEstates: assets.nonRealEstates,
  realEstates: assets.realEstates.map((realEstate) => {
    if (realEstate.transactionType === TRANSACTION_TYPES.PURCHASING) {
      return realEstate;
    }
    return getOwnedRealEstate(realEstate);
  }),
});
