import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from '../../../../constants/message';

const downloadSoma = (applicationId, somaId) => client.query({
  query: gql`query downloadSoma($applicationId: String!, $somaId: String!) {
      downloadSoma(
          applicationId: $applicationId
          somaId: $somaId
      )
  }`,
  variables: {
    applicationId, somaId,
  },
});

const createSoma = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: SomaCreateRequest! ){
        createSoma(
            applicationId: "${applicationId}"
            data: $data
        )
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.GET_SOMA_DOCUMENT_URL })
);

export default {
  downloadSoma,
  createSoma,
};
