export const ADDRESS_TYPE = {
  STANDARD: 'STANDARD',
  NON_STANDARD: 'NON_STANDARD',
};

export const ADDRESS_TYPE_OPTIONS = [{
  value: ADDRESS_TYPE.STANDARD,
  label: 'Standard',
}, {
  value: ADDRESS_TYPE.NON_STANDARD,
  label: 'Non standard',
}];

export const STATE_OPTIONS = [
  {
    value: 'ACT',
    label: 'ACT',
  },
  {
    value: 'NSW',
    label: 'NSW',
  },
  {
    value: 'NT',
    label: 'NT',
  },
  {
    value: 'QLD',
    label: 'QLD',
  },
  {
    value: 'SA',
    label: 'SA',
  },
  {
    value: 'TAS',
    label: 'TAS',
  },
  {
    value: 'VIC',
    label: 'VIC',
  },
  {
    value: 'WA',
    label: 'WA',
  },
];
