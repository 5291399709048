import { PERMISSIONS } from '../authorisation/permissions';

export const getRoutes = () => ([
  {
    title: 'DASHBOARD',
    pathname: '/dashboard',
    routes: [],
  },
  {
    title: 'CLIENT TOOLS',
    routes: [
      {
        title: 'Product search',
        pathname: '/products',
      },
    ],
  },
  {
    title: 'APPLICATIONS',
    routes: [
      {
        title: 'Application tracking',
        pathname: '/applications',
      },
      {
        title: 'All applications',
        pathname: '/all-applications',
      },
    ],
    isShrink: false,
  },
  {
    title: 'LOANS',
    pathname: '/loans',
    routes: [],
  },
  {
    title: 'MY BUSINESS',
    routes: [
      {
        title: 'Invoices',
        pathname: '/invoices',
        permission: PERMISSIONS.VIEW_LOANAPPLICATION_INVOICES,
      },
      {
        title: 'Commissions',
        pathname: '/commissions',
        permission: PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS,
      },
      {
        title: 'Commission management',
        pathname: '/commission-management-reports',
        permission: PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONMANAGEMENT,
      },
      {
        title: 'Template management',
        pathname: '/template-management',
        permission: PERMISSIONS.VIEW_TEMPLATE_MANAGEMENT,
      },
      {
        title: 'Permission settings',
        pathname: '/permissions',
        permission: PERMISSIONS.EDIT_PERMISSION,
      },
    ],
  },
  {
    title: 'REPORTS',
    pathname: '/reports',
    routes: [],
  },
]);
