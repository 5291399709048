import React, { Component } from 'react';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import Tab from 'shared/components/Tab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import { getCommissionManagementReportDetails } from '../redux/commissionManagementReportsRedux';
import ReportTable from './ReportTable';
import { columnKeys, getColumnDefinitions } from './columnDefinitions';
import {
  clawbackCommissionType,
  getLoanNotInSystemType,
  getMissingCommissionsType,
  getMissingCommissionsValue,
} from '../../renderers';
import styles from './ReportTabs.module.scss';
import IssueGroupsShape from '../../../shapes/IssueGroupsShape';
import SecondaryButton from '../../../../shared/components/Buttons/SecondaryButton';
import ExternalInfoLink from '../../../../shared/components/Links/ExternalInfoLink';

const titleWithCount = (title, issues) => `${title} (${(issues || []).length})`;
const tabs = [
  {
    key: 'MISSING_COMMISSIONS',
    title: issues => titleWithCount('Missing commissions', issues.MISSING_COMMISSIONS),
    typeRenderer: getMissingCommissionsType,
    getTypeValue: getMissingCommissionsValue,
    columns: [
      columnKeys.COMMISSION_QUERY,
      columnKeys.TYPE,
      columnKeys.LAST_KNOWN_BALANCE,
      columnKeys.CLIENT,
      columnKeys.LENDER,
      columnKeys.SETTLEMENT_DATE,
      columnKeys.APPROVED_AMOUNT,
      columnKeys.APPROVAL_NUMBER,
    ],
  },
  {
    key: 'CLAWBACKS',
    title: issues => titleWithCount('Clawbacks', issues.CLAWBACKS),
    typeRenderer: clawbackCommissionType,
    getTypeValue: clawbackCommissionType,
    columns: [
      columnKeys.COMMISSION_QUERY,
      columnKeys.TYPE,
      columnKeys.AMOUNT_AND_BALANCE,
      columnKeys.CLIENT,
      columnKeys.LENDER,
      columnKeys.SETTLEMENT_DATE,
      columnKeys.APPROVED_AMOUNT,
      columnKeys.APPROVAL_NUMBER,
    ],
  },
  {
    key: 'LOAN_NOT_IN_SYSTEM',
    title: issues => titleWithCount('Loan not in system', issues.LOAN_NOT_IN_SYSTEM),
    typeRenderer: getLoanNotInSystemType,
    getTypeValue: getLoanNotInSystemType,
    columns: [
      columnKeys.COMMISSION_QUERY,
      columnKeys.TYPE,
      columnKeys.AMOUNT_AND_BALANCE,
      columnKeys.CLIENT,
      columnKeys.LENDER,
      columnKeys.SETTLEMENT_DATE,
      columnKeys.SETTLED_AMOUNT,
      columnKeys.APPROVAL_NUMBER,
    ],
  },
  {
    key: 'PAID_OUT',
    title: issues => titleWithCount('Paid out', issues.PAID_OUT),
    columns: [
      columnKeys.COMMISSION_QUERY,
      columnKeys.PREVIOUS_LOAN_BALANCE,
      columnKeys.CLIENT,
      columnKeys.LENDER,
      columnKeys.SETTLEMENT_DATE,
      columnKeys.APPROVED_AMOUNT,
      columnKeys.APPROVAL_NUMBER,
    ],
  },
  {
    key: 'BALANCE_REDUCED',
    title: (issues) => `Balance reduced ≥ $50,000 (${(issues.BALANCE_REDUCED || []).length})`,
    columns: [
      columnKeys.COMMISSION_QUERY,
      columnKeys.PREVIOUS_BALANCE,
      columnKeys.CURRENT_BALANCE,
      columnKeys.CLIENT,
      columnKeys.LENDER,
      columnKeys.SETTLEMENT_DATE,
      columnKeys.APPROVED_AMOUNT,
      columnKeys.APPROVAL_NUMBER,
    ],
  },
];
const EXPLANATION_URL = 'https://sites.google.com/smartline.com.au/operations-manual/12-commissions-fees/commissions-reconciliations';

export class ReportTabsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleExport = this.handleExport.bind(this);
  }

  // eslint-disable-next-line camelcase,react/sort-comp
  UNSAFE_componentWillMount() {
    const {
      processingMonth,
      dispatchGetCommissionManagementReportDetails,
    } = this.props;
    dispatchGetCommissionManagementReportDetails(processingMonth);
  }

  handleExport() {
    const { issues, processingMonth } = this.props;
    const workbook = new Workbook();
    tabs.forEach(tab => {
      const sheetName = tab.title(issues);
      const worksheet = workbook.addWorksheet(sheetName);
      const columns = tab.columns.map(col => getColumnDefinitions(tab.typeRenderer, tab.getTypeValue)[col]);
      worksheet.columns = columns
        .filter(col => !col.hideInExport)
        .map(col => ({
          key: col.name,
          header: isFunction(col.title) ? col.title(processingMonth) : col.title,
          width: (col.width || 100) / 5,
          style: { alignment: { wrapText: true } },
        }));
      const rows = issues[tab.key].map(issue =>
        (
          mapValues(
            mapKeys(columns, 'name'),
            ({ name, getCellValue }) =>
              (
                isFunction(getCellValue)
                  ? getCellValue(issue)
                  : issue[name]
              ),
          )
        ));
      worksheet.addRows(rows);
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `commission_management_report_${processingMonth.replace('-', '_')}.xlsx`);
    });
  }

  render() {
    const {
      issues,
      processingMonth,
      isLoading,
      hasError,
      defaultOpenTabIndex,
    } = this.props;

    const tabViews = tabs.map(tab => ({
      key: tab.key,
      title: isFunction(tab.title) ? tab.title(issues) : tab.title,
      content: (
        <ReportTable
          typeRenderer={tab.typeRenderer}
          processingMonth={processingMonth}
          issues={issues[tab.key]}
          columns={tab.columns}
        />
      ),
    }));
    return (
      <LoadingAndErrorWrapper isLoading={isLoading} hasError={hasError}>
        <div className={styles.toolbar}>
          <ExternalInfoLink to={EXPLANATION_URL} text="What this information means" />
          <SecondaryButton onClick={this.handleExport} className={styles.exportButton}>
            Download as spreadsheet
          </SecondaryButton>
        </div>
        <Tab tabs={tabViews} defaultIndex={defaultOpenTabIndex} />
      </LoadingAndErrorWrapper>
    );
  }
}

ReportTabsComponent.defaultProps = {
  dispatchGetCommissionManagementReportDetails: () => {
  },
  issues: {},
  isLoading: false,
  hasError: false,
  defaultOpenTabIndex: 0,
};

ReportTabsComponent.propTypes = {
  processingMonth: PropTypes.string.isRequired,
  dispatchGetCommissionManagementReportDetails: PropTypes.func,
  issues: IssueGroupsShape,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  defaultOpenTabIndex: PropTypes.number,
};

const mapStateToProps = state => ({
  issues: get(state, 'myBusiness.commissionManagementReports.issues'),
  isLoading: get(state, 'myBusiness.commissionManagementReports.isLoading'),
  hasError: get(state, 'myBusiness.commissionManagementReports.hasError'),
});

const mapDispatchToProps = dispatch => ({
  dispatchGetCommissionManagementReportDetails: processingMonth =>
    dispatch(getCommissionManagementReportDetails(processingMonth)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportTabsComponent);
