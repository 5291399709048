import React from 'react';
import StickyFooter from 'shared/components/StickyFooter';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import ViewFundsAvailable from './FundsAvailable/ViewFundsAvailable';
import ViewApplicationCosts from './ApplicationCosts/ViewApplicationCosts';
import ViewCostToPurchases from './CostToPurchase/ViewCostToPurchases';
import FundingPosition from './FundingPosition/FundingPosition';
import styles from './styles.module.scss';
import ViewCalculation from './Calculation/ViewCalculation';
import ViewSOMAComments from './SOMAComments/ViewSOMAComments';
import ViewRefinances from './Refinance/ViewRefinance';
import ViewOtherCosts from './OtherCosts/ViewOtherCosts';
import { getUserGuidanceCodes } from '../../redux/selectors/fundingWorksheet';
import ViewConstructions from './Construction/ViewConstructions';

const ViewFundingWorksheet = () => {
  const userGuidanceCodes = useSelector(getUserGuidanceCodes);

  return (
    <div>
      <ViewCalculation />
      <div className={styles.fundingWorksheetWrapper}>
        <div className={styles.costsPanel}>
          <h3 className={styles.title}>Costs</h3>
          <ViewCostToPurchases userGuidanceCodes={userGuidanceCodes.costToPurchases} />
          <ViewRefinances userGuidanceCode={userGuidanceCodes.refinanceAndDebtConsolidation} />
          <ViewConstructions userGuidanceCodes={userGuidanceCodes.constructions} />
          <ViewOtherCosts userGuidanceCode={userGuidanceCodes.otherCosts} />
          <ViewApplicationCosts userGuidanceCode={userGuidanceCodes.applicationCosts} />
        </div>
        <div className={styles.contributionsPanel}>
          <ViewFundsAvailable userGuidanceCodes={pick(userGuidanceCodes, ['contributions', 'loanAmount'])} />
          <ViewSOMAComments className={styles.somaCommentsSection} />
        </div>
        <div className={styles.summaryPanel}>
          <FundingPosition userGuidanceCodes={userGuidanceCodes} />
          <ViewSOMAComments className={styles.somaCommentsSection} />
        </div>
      </div>
      <StickyFooter disabled />
    </div>
  );
};

export default ViewFundingWorksheet;
