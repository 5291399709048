import { onRemoveCriteria } from '../applicationCriteria/applicationCriteria';
import { ACTIONS } from './loanCriteriaActions';
import { PURPOSE_OPTION_VALUES } from '../../../constants';

const MIN_CRITERIA_LENGTH = 1;
const MAX_CRITERIA_LENGTH = 3;

const FIXED_LOAN_TERM_ERROR = 'Fixed loan term needs to be less than or equal to loan term.';
const INTEREST_ONLY_TERM_ERROR = 'Interest only term needs to be less than or equal to loan term.';

export const isFixedProduct = productType => [undefined, 'fixed'].includes(productType);

export const addLoanCriteria = () =>
  ({ type: ACTIONS.ADD_LOAN_CRITERIA });

export const removeLoanCriteria = (index) => (dispatch) => {
  dispatch({ type: ACTIONS.REMOVE_LOAN_CRITERIA, index });
  dispatch(onRemoveCriteria());
};

export const ensureCriteriaHaveLoanTerms = () =>
  ({ type: ACTIONS.ENSURE_LOAN_TERMS });

export const updateLoanAmount = (loanAmount, index) =>
  ({ type: ACTIONS.SET_LOAN_AMOUNT, loanAmount, index });

export const updatePurpose = (purpose, index) =>
  ({ type: ACTIONS.SET_PURPOSE, purpose, index });

export const updateProductType = (productType, index) =>
  ({ type: ACTIONS.SET_PRODUCT_TYPE, productType, index });

export const updateRepaymentType = (repaymentType, index) =>
  ({ type: ACTIONS.SET_REPAYMENT_TYPE, repaymentType, index });

export const updateLoanTerm = (loanTerm, index) =>
  ({ type: ACTIONS.SET_LOAN_TERM, loanTerm, index });

export const updateFixedLoanTerm = (fixedLoanTerm, index) =>
  ({ type: ACTIONS.SET_FIXED_LOAN_TERM, fixedLoanTerm, index });

export const updateInterestOnlyTerm = (interestOnlyTerm, index) =>
  ({ type: ACTIONS.SET_INTEREST_ONLY_TERM, interestOnlyTerm, index });

export const validateFixedLoanTerm = (index) =>
  ({ type: ACTIONS.VALIDATE_FIXED_LOAN_TERM, index });

export const validateInterestOnlyTerm = (index) =>
  ({ type: ACTIONS.VALIDATE_INTEREST_ONLY_TERM, index });

export const updateSelectedFeatureNames = (selectedFeatureNames, index) => ({
  type: ACTIONS.UPDATE_SELECTED_FEATURES_LIST,
  selectedFeatureNames,
  index,
});

export const toggleSelectFeature = (featureName, index) => ({
  type: ACTIONS.TOGGLE_SELECT_FEATURE,
  featureName,
  index,
});
const isActuallyGreaterThan = (a, b) => a && b && a > b;
export const processAddLoanCriteria = (state, stateWithNewItem) => {
  if (state.length >= MAX_CRITERIA_LENGTH) {
    return state;
  }
  let otherCriteria = state;
  if (!state[0].purpose) {
    otherCriteria = stateWithNewItem({
      ...state[0],
      purpose: PURPOSE_OPTION_VALUES.OWNER_OCCUPIED,
    }, 0);
  }
  return [
    ...otherCriteria,
    {
      loanTerm: 30,
      errorMessage: {},
      selectedFeatureNames: [],
      purpose: PURPOSE_OPTION_VALUES.OWNER_OCCUPIED,
    },
  ];
};
export const processRemoveLoanCriteria = (state, action) => {
  if (state.length <= MIN_CRITERIA_LENGTH) {
    return state;
  }
  const { index } = action;
  return [
    ...state.slice(0, index),
    ...state.slice(index + 1),
  ];
};
export const processSetLoanAmount = (action, stateWithNewItem, state) => {
  const { loanAmount, index } = action;
  return stateWithNewItem({
    ...state[index],
    loanAmount,
  }, index);
};
export const processSetPurpose = (action, state, stateWithNewItem) => {
  const { purpose, index } = action;
  if (state.length > 1 && !purpose) {
    return state;
  }
  return stateWithNewItem({
    ...state[index],
    purpose,
    purposeDetails: undefined,
    refinanceReason: undefined,
  }, index);
};
export const processSetProductType = (action, state, stateWithNewItem) => {
  const { productType, index } = action;
  const criteria = state[index];
  if (isFixedProduct(productType)) {
    return stateWithNewItem({
      ...criteria,
      productType,
    }, index);
  }

  const errorMessage = {
    ...criteria.errorMessage,
    fixedLoanTermError: undefined,
  };
  return stateWithNewItem({
    ...criteria,
    productType,
    fixedLoanTerm: undefined,
    errorMessage,
  }, index);
};
export const processSetRepaymentTypes = (action, state, stateWithNewItem) => {
  const { repaymentType, index } = action;
  let { interestOnlyTerm } = state[index];
  if (repaymentType !== 'Interest Only') {
    interestOnlyTerm = undefined;
  }
  return stateWithNewItem({
    ...state[index],
    repaymentType,
    interestOnlyTerm,
  }, index);
};
export const processSetLoanTerm = (action, state, stateWithNewItem) => {
  const { loanTerm, index } = action;
  const errorMessage = {
    ...state[index].errorMessage,
    interestOnlyTermError: undefined,
    fixedLoanTermError: undefined,
  };
  return stateWithNewItem({
    ...state[index],
    loanTerm,
    errorMessage,
  }, index);
};
export const processEnsureLoanTerms = state => state.map((s) => {
  if (s.loanTerm) {
    return s;
  }
  return {
    ...s,
    loanTerm: 30,
  };
});
export const processSetFixedLoanTerm = (action, state, stateWithNewItem) => {
  const { fixedLoanTerm, index } = action;
  const errorMessage = {
    ...state[index].errorMessage,
    fixedLoanTermError: undefined,
  };
  return stateWithNewItem({
    ...state[index],
    fixedLoanTerm,
    errorMessage,
  }, index);
};
export const processSetInterestOnlyTerm = (action, state, stateWithNewItem) => {
  const { interestOnlyTerm, index } = action;
  const errorMessage = {
    ...state[index].errorMessage,
    interestOnlyTermError: undefined,
  };
  return stateWithNewItem({
    ...state[index],
    interestOnlyTerm,
    errorMessage,
  }, index);
};
export const processUpdateSelectedFeaturesList = (action, stateWithNewItem, state) => {
  const { selectedFeatureNames, index } = action;
  return stateWithNewItem({
    ...state[index],
    selectedFeatureNames,
  }, index);
};

export const processSetSelectedPurposeCategory = (action, stateWithNewItem, state) => {
  const { purposeCategory, index } = action;
  return stateWithNewItem({
    ...state[index],
    purposeCategory,
    purposeDetails: undefined,
    refinanceReason: undefined,
  }, index);
};

export const processSetSelectedPurposeDetails = (action, stateWithNewItem, state) => {
  const { purposeDetails, index } = action;
  return stateWithNewItem({
    ...state[index],
    purposeDetails,
  }, index);
};

export const processSetSelectedRefinanceReason = (action, stateWithNewItem, state) => {
  const { refinanceReason, index } = action;
  return stateWithNewItem({
    ...state[index],
    refinanceReason,
  }, index);
};
export const processToggleSelectFeature = (action, state, stateWithNewItem) => {
  const { featureName, index } = action;

  const selectedFeatureNames = state[index].selectedFeatureNames.slice();
  const selectedFeatureIndex = selectedFeatureNames.indexOf(featureName);
  if (selectedFeatureIndex > -1) {
    selectedFeatureNames.splice(selectedFeatureIndex, 1);
  } else {
    selectedFeatureNames.push(featureName);
  }
  return stateWithNewItem({
    ...state[index],
    selectedFeatureNames,
  }, index);
};
export const processValidateFixedLoanTerm = (action, state, stateWithNewItem) => {
  const { index } = action;
  const criteria = state[index];
  if (isActuallyGreaterThan(criteria.fixedLoanTerm, criteria.loanTerm)) {
    const errorMessage = {
      ...criteria.errorMessage,
      fixedLoanTermError: FIXED_LOAN_TERM_ERROR,
    };
    return stateWithNewItem({
      ...criteria,
      errorMessage,
    }, index);
  }
  return stateWithNewItem({
    ...criteria,
    errorMessage: {
      ...criteria.errorMessage,
      fixedLoanTermError: undefined,
    },
  }, index);
};
export const processValidateInterestOnlyTerm = (action, state, stateWithNewItem) => {
  const { index } = action;
  const criteria = state[index];
  if (isActuallyGreaterThan(criteria.interestOnlyTerm, criteria.loanTerm)) {
    const errorMessage = {
      ...criteria.errorMessage,
      interestOnlyTermError: INTEREST_ONLY_TERM_ERROR,
    };
    return stateWithNewItem({
      ...criteria,
      errorMessage,
    }, index);
  }
  return stateWithNewItem({
    ...criteria,
    errorMessage: {
      ...criteria.errorMessage,
      interestOnlyTermError: undefined,
    },
  }, index);
};
export const processResetSearchCriteria = action => action.searchCriteria.loanCriteriaList;

export const initialState = [{
  loanTerm: 30,
  errorMessage: {},
  selectedFeatureNames: [],
  key: '0',
}];
