import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import InvoiceSummaryRow from './InvoiceSummaryRow';
import styles from './invoiceStyles.module.scss';
import { downloadInvoicePdf, getInvoices, downloadInvoiceDetails } from './invoicesRedux';
import { trackViewPage } from '../../../redux/tracking';

export class InvoiceSummary extends PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatchGetInvoices, dispatchTrackPageView } = this.props;
    dispatchTrackPageView();
    dispatchGetInvoices();
  }

  renderInvoices() {
    const {
 invoices, dispatchDownloadInvoicePdf, dispatchDownloadInvoiceDetails, isLoadingFile,
} = this.props;
    if (invoices && invoices.length) {
      return invoices.sort((i1, i2) => (i1.processingMonth < i2.processingMonth ? 1 : -1)).map(invoice => (
        <InvoiceSummaryRow
          key={invoice.id}
          processingMonth={invoice.processingMonth}
          totalExcludingGst={invoice.totalExcludingGst}
          totalGst={invoice.totalGst}
          totalIncludingGst={invoice.totalIncludingGst}
          downloadInvoicePdf={() => dispatchDownloadInvoicePdf(invoice)}
          downloadInvoiceDetails={() => dispatchDownloadInvoiceDetails(invoice.processingMonth)}
          isLoadingFile={isLoadingFile}
        />
      ));
    }

    return (
      <tr>
        <td colSpan={5}>
          <div>No data</div>
        </td>
      </tr>
    );
  }

  render() {
    const { hasError, isLoading } = this.props;
    return (
      <div>
        <GroupOfficeBusinessSelector />
        <h1>Invoices</h1>
        <LoadingAndErrorWrapper isLoading={isLoading} hasError={hasError}>
          <table className={styles.invoiceTable}>
            <thead>
              <tr>
                <th>Month</th>
                <th style={{ textAlign: 'right' }}>Excluding GST</th>
                <th style={{ textAlign: 'right' }}>GST</th>
                <th style={{ textAlign: 'right' }}>Total (inc. GST)</th>
                <th style={{ textAlign: 'center' }}>Downloads</th>
              </tr>
            </thead>
            <tbody>
              {this.renderInvoices()}
              <tr>
                <td className={styles.notice} colSpan={5}>Earlier invoices can be found in Lotus Notes.</td>
              </tr>
            </tbody>
          </table>
        </LoadingAndErrorWrapper>
      </div>
    );
  }
}

InvoiceSummary.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingFile: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasError: PropTypes.bool.isRequired,
  dispatchGetInvoices: PropTypes.func.isRequired,
  dispatchTrackPageView: PropTypes.func.isRequired,
  dispatchDownloadInvoicePdf: PropTypes.func.isRequired,
  dispatchDownloadInvoiceDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  invoices: state.myBusiness.invoices.invoices || [],
  isLoading: state.myBusiness.invoices.isLoading,
  isLoadingFile: state.myBusiness.invoices.isLoadingFile,
  hasError: !!state.myBusiness.invoices.errors,
});
const mapDispatchToProps = dispatch => ({
  dispatchGetInvoices: () => dispatch(getInvoices()),
  dispatchTrackPageView: () => dispatch(trackViewPage('invoiceSummary')),
  dispatchDownloadInvoicePdf: invoice => dispatch(downloadInvoicePdf(invoice)),
  dispatchDownloadInvoiceDetails: processingMonth => dispatch(downloadInvoiceDetails(processingMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSummary);
