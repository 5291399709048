import React, {
 useCallback, useContext, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import Popover from 'shared/components/NavbarMenu/Popover';
import Icon from 'shared/components/Icon/Icon';
import { ModalPortalContext } from 'shared/components/ModalPortal';
import styles from './ApplicationTitleActions.module.scss';
import NoteEditorModal from './NoteEditorModal';

const AddNoteAction = ({ onClick, onModalClose }) => {
  const { trigger, close } = useContext(ModalPortalContext);
  const onRequestClose = useCallback(() => {
    close();
    onModalClose();
  }, [close, onModalClose]);
  const handleClick = useCallback(async () => {
    onClick();
    setTimeout(() => {
      trigger(<NoteEditorModal onRequestClose={onRequestClose} />);
    });
  }, [onClick, trigger, onRequestClose]);
  return (
    <button type="button" onClick={handleClick} className={styles.dropdownItem}>
      Add a note
    </button>
  );
};

AddNoteAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

const HideApplicationAction = ({
                                 hideApplication, isHiddenOnSwimlane, isHiddenFetching, setIsHiddenFetching,
                               }) => {
  const handleClick = isHidden => async () => {
    setIsHiddenFetching(true);
    try {
      await hideApplication(isHidden);
    } finally {
      setIsHiddenFetching(false);
    }
  };
  return (
    <button type="button" onClick={handleClick(!isHiddenOnSwimlane)} className={styles.dropdownItem} disabled={isHiddenFetching}>
      {
        isHiddenOnSwimlane ? 'Unhide on Application tracking' : 'Hide on Application tracking'
      }
    </button>
  );
};

HideApplicationAction.propTypes = {
  hideApplication: PropTypes.func.isRequired,
  isHiddenOnSwimlane: PropTypes.bool.isRequired,
  isHiddenFetching: PropTypes.bool.isRequired,
  setIsHiddenFetching: PropTypes.func.isRequired,
};

const ApplicationTitleActions = ({
                                   hasEditPermission, hideApplication, isHiddenOnSwimlane,
}) => {
  const [isHiddenFetching, setIsHiddenFetching] = useState(false);
  const trigger = useRef(null);
  const renderTrigger = (togglePopover, isShown) => (
    <button
      type="button"
      onClick={togglePopover}
      className={styles.dropdownTrigger}
      ref={trigger}
    >
      Application actions
      <Icon name={isShown ? 'arrow-up-solid' : 'arrow-down-solid'} size="18px" />
    </button>
  );
  const onModalClose = useCallback(() => {
    trigger.current.focus();
  }, []);

  return (
    <Popover trigger={renderTrigger}>
      {
        (toggle, isShown) => (isShown ? (
          <div className={styles.dropdownMenu}>
            <AddNoteAction onClick={toggle} onModalClose={onModalClose} />
            {hasEditPermission && (
              <HideApplicationAction
                hideApplication={hideApplication}
                isHiddenOnSwimlane={isHiddenOnSwimlane}
                isHiddenFetching={isHiddenFetching}
                setIsHiddenFetching={setIsHiddenFetching}
              />
            )}
          </div>
        ) : null)
      }
    </Popover>
);
};

ApplicationTitleActions.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  hideApplication: PropTypes.func.isRequired,
  isHiddenOnSwimlane: PropTypes.bool.isRequired,
};

export default ApplicationTitleActions;
