import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import ClickableText from 'shared/components/formFields/ClickableText';

import styles from './TaskListTemplate.module.scss';
import TemplateTaskModal from './TaskEditModal';

const renderTaskListRow = (task, id, onClick) => (
  <li key={`task-${id}`} className={styles.taskListTaskRow}>
    <ClickableText text={task.name} onClick={onClick} />
  </li>
);

const renderTaskList = (tasks, onTaskClick) => (
  <div>
    <ul className={styles.taskListTaskUl}>
      {tasks.map((task, index) => ({ task, id: index }))
        .map(({ task, id }) => renderTaskListRow(task, id, () => onTaskClick(task)))}
    </ul>
  </div>
);

export const SmartlineTemplate = (props) => {
  const { onBackToTemplateList, selectedTemplate: { name, tasks } } = props;
  const [selectedTask, setSelectedTask] = useState(null);
  const isModalOpen = selectedTask !== null;
  const onModalClose = () => setSelectedTask(null);
  return (
    <div className={classNames(styles.newTaskListTemplate, styles.readonly)}>
      <div className={styles.backLink}>
        <span
          className={styles.backIcon}
          role="button"
          tabIndex="0"
          onKeyDown={e => (e.key === 'Enter' ? onBackToTemplateList() : null)}
          onClick={onBackToTemplateList}
        />
        <TertiaryButton
          className={styles.backButton}
          onClick={onBackToTemplateList}
        >
          Back to task list templates
        </TertiaryButton>
      </div>
      <div>
        <h2>{name}</h2>
        <div className={styles.newTemplateContent}>
          <div className={styles.newTemplateForm}>
            <div className={styles.taskContent}>
              <span className={styles.taskListLabel}>
                Tasks
              </span>
            </div>
            {renderTaskList(tasks, setSelectedTask)}
          </div>
        </div>
        <div className={styles.newTemplateFooter}>
          <div>
            <PrimaryButton onClick={onBackToTemplateList}>
              Back to templates
            </PrimaryButton>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <TemplateTaskModal
          isOpen
          defaultTask={selectedTask}
          handleCloseTaskModal={onModalClose}
          handleEditTaskFinish={onModalClose}
          isDisabled
        />
      )}
    </div>
  );
};

SmartlineTemplate.propTypes = {
  onBackToTemplateList: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  selectedTemplate: state.myBusiness.taskListTemplate.selectedTemplate,
});

export default connect(mapStateToProps, null)(SmartlineTemplate);
