import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import pick from 'lodash/pick';

const getSomas = (state) => get(state, 'application.applicationDetail.somas');

const getPreSubmission = (state) => get(state, 'application.applicationDetail.preSubmission');

const defaultFalse = (value) => (value === null ? false : value);

export const getFinaliseInitialValues = createSelector(
  getSomas,
  getPreSubmission,
  (somas, preSubmission) => ({
    somas: flow(
      map(s => ({
        ...s,
        signed: defaultFalse(s.signed),
        issued: defaultFalse(s.issued),
      })),
      orderBy(['generatedDate'], 'desc'),
    )(somas),
    preSubmission: pick(preSubmission, [
      'comment',
      'shouldSendToApplyOnline',
      'signedCreditCheckAuthority',
      'signedFixRateLockDeclaration',
      'signedLowDocClientAcknowledgement',
      'estimatedCommissionLoanAmount',
      'somaAdditionalComment',
    ]),
  }),
);
