import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { downloadDocument } from '../../../../../../redux';
import styles from './ApplyOnlineStatusChangeContent.module.scss';
import BasicRichContent from '../BasicRichContent';

const ApplyOnlineStatusChangeContent = ({
 content, applicationId, dateStr, download,
}) =>
  (
    <BasicRichContent
      type="STATUS CHANGED"
      content={(
        <TertiaryButton
          onClick={() => download(applicationId, dateStr)}
          className={styles.downloadButton}
        >
          {content}
        </TertiaryButton>
      )}
    />
  );

ApplyOnlineStatusChangeContent.propTypes = {
  content: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  dateStr: PropTypes.string.isRequired,
  download: PropTypes.func.isRequired,
};

export default connect(null,
  {
    download: downloadDocument,
  })(ApplyOnlineStatusChangeContent);
