import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import NavBarMenu from 'shared/components/NavbarMenu/NavBarMenu';
import NavBarMenuItem from 'shared/components/NavbarMenu/NavBarMenuItem';
import businessShape from 'shapes/BusinessShape';
import { logout } from 'redux/login';

import { selectBusiness } from 'redux/business';

const ENTER = 'Enter';

const getInitials = (username) => {
  let initials = (username || '').match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

const renderFullUserName = (username, numOfBusinesses, selectedBusinessName) => {
  if (numOfBusinesses > 1) {
    return (
      <div className="full-username">
        <div className="username">{username}</div>
        <div className="business-name">{selectedBusinessName}</div>
      </div>
    );
  }
  return (
    <div className="full-username">
      <div className="username">{username}</div>
    </div>
  );
};

export const UserNavbarItem = ({
                                 username, selectedBusiness, businesses,
                                 dispatchSelectBusiness, dispatchLogout,
                               }) => {
  const title = () => (
    <div className="user-menu">
      {renderFullUserName(username, businesses.length, selectedBusiness && selectedBusiness.displayName)}
      <div className="initials"><span>{getInitials(username)}</span></div>
    </div>
  );

  const keyDownHandler = fn => (event) => {
    if (event.key && event.key === ENTER) {
      fn();
    }
  };

  const selectedBusinessId = selectedBusiness ? selectedBusiness.id : undefined;

  const handleSelectBusiness = useMemo(() => async (business) => {
    const event = new Event('beforeSelectBusiness');
    event.selectedBusinessId = business.id;
    event.currentBusinessId = selectedBusinessId;
    window.dispatchEvent(event);
    if (isNil(event.confirmed) || await event.confirmed) {
        dispatchSelectBusiness(business);
    }
  }, [dispatchSelectBusiness, selectedBusinessId]);

  const handleLogout = useMemo(() => async () => {
    const event = new Event('beforeLogout');
    window.dispatchEvent(event);
    if (isNil(event.confirmed) || await event.confirmed) {
      dispatchLogout();
    }
  }, [dispatchLogout]);

  return (
    <NavBarMenu title={title()} alignRight>
      {
        businesses.length > 1 && businesses.map(business => (
          <NavBarMenuItem
            key={business.id}
            isSelected={business.id === selectedBusinessId}
            onClick={() => handleSelectBusiness(business)}
            onKeyDown={keyDownHandler(() => handleSelectBusiness(business))}
          >
            {business.displayName}
          </NavBarMenuItem>
        ))
      }
      <NavBarMenuItem onKeyDown={keyDownHandler(handleLogout)} onClick={handleLogout}>Log Out</NavBarMenuItem>
    </NavBarMenu>
  );
};
UserNavbarItem.defaultProps = {
  selectedBusiness: null,
  businesses: [],
};

UserNavbarItem.propTypes = {
  dispatchLogout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  businesses: PropTypes.arrayOf(businessShape),
  selectedBusiness: businessShape,
  dispatchSelectBusiness: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  username: state.profile.username,
  businesses: state.profile.businesses,
  toggles: state.profile.toggles,
  selectedBusiness: (state.business || {}).selectedBusiness,
});

const mapDispatchToProps = dispatch => ({
  dispatchLogout: () => dispatch(logout()),
  dispatchSelectBusiness: business => dispatch(selectBusiness(business)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbarItem);
