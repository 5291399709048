import React from 'react';
import isFunction from 'lodash/isFunction';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TemplateConnector } from '@devexpress/dx-react-core';
import styles from '../style.module.scss';
import { mergeClassNamesObjects } from '../../utils';

const tableCellFunction = (TableComponent) => {
  const tableCell = (props) => {
    const {
      className,
      style,
      column,
      value,
      row,
    } = props;
    const {
      name,
      renderer,
      classNames: classNamesFromColumn,
      allowSorting,
    } = column;
    if (style) { // remove inline background styles
      delete style.backgroundColor;
    }
    return (
      <TemplateConnector>
        {({ fixedBorderRightColumn, classNames }, actions) => {
          const calculatedChildren = isFunction(renderer) ? renderer(value, row, column, actions) : value;
          const containShadow = fixedBorderRightColumn === name;
          const mergedClassNames = mergeClassNamesObjects(classNames, classNamesFromColumn);
          const cellClassName = cx(
            styles.cell,
            mergedClassNames.cell,
            { [mergedClassNames.sortingCell]: allowSorting },
          );
          return (
            <TableComponent.Cell
              {...props}
              style={style}
              className={cx({
                [className]: true,
                [cellClassName]: !containShadow,
                [styles.primativeCell]: containShadow,
                [styles.shadowCell]: containShadow,
                [mergedClassNames.primativeCell]: containShadow,
                [mergedClassNames.primativeHeaderCell]: containShadow,
              })}
              data-test-id={name}
            >
              {containShadow ? (
                <div className={styles.shadowWrapper}>
                  <div className={cellClassName}>
                    {calculatedChildren}
                  </div>
                </div>
              ) : calculatedChildren}
            </TableComponent.Cell>
          );
        }}
      </TemplateConnector>
    );
  };

  tableCell.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    column: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      renderer: PropTypes.func,
      classNames: PropTypes.object,
      allowSorting: PropTypes.bool,
    }).isRequired,
    value: PropTypes.any,
    row: PropTypes.any,
  };

  tableCell.defaultProps = {
    className: '',
    style: {},
    value: undefined,
    row: null,
  };

  return React.memo(tableCell);
};

export default tableCellFunction;
