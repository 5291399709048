import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import get from 'lodash/get';
import includes from 'lodash/includes';
import objValues from 'lodash/values';
import noop from 'lodash/noop';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormYesNoSwitch from 'shared/components/formFields/ReduxForm/YesNoSwitch';
import Tooltip, { TooltipSize } from 'shared/components/Tooltip/Tooltip';
import styles from '../RealEstateAsset.module.scss';
import {
  isConstruction,
  PROPERTY_SUB_TYPE_MAPPING,
  PROPERTY_TYPE,
  PROPERTY_TYPE_OPTIONS,
  SUB_TYPES_NAME_MAPPING,
} from '../constants';

const clearRelatedFields = (fieldNames, fields) =>
  fieldNames.forEach(fieldName => get(fields, fieldName)?.input.onChange(null));

const renderPropertySubtypeLabel = (label, showTooltip) => {
  const themeContext = useContext(ThemeContext);
  const hintText = 'For an asset which requires or has a construction loan, select Licenced Builder House Construction or Owner Builder House Construction.';

  return showTooltip
    ? (
      <div className={styles.labelWrapper}>
        <div className={styles.label}>{label}</div>
        <Tooltip
          size="20px"
          iconName="info"
          color={themeContext.palette.textPrimary}
          tooltipText={hintText}
          tooltipSize={TooltipSize.Large}
        />
      </div>
    )
    : label;
};

const isResidential = (properTypeValue) => properTypeValue === PROPERTY_TYPE.Residential;

const PropertyTypeFields = (fields) => {
  const {
   names, onPropertyTypeChange, showConstructionSwitch, setDefaultConstructionValue,
  } = fields;
  const propertyTypeName = names[0];
  const propertyField = get(fields, propertyTypeName);
  const properTypeValue = propertyField.input.value;

  const currentValueFieldName = names[3];
  const subTypeName = names.find(name => includes(name, get(SUB_TYPES_NAME_MAPPING, properTypeValue)));
  const subTypeField = get(fields, subTypeName);
  const subtypeValue = get(subTypeField, 'input.value');

  const displayTooltip = setDefaultConstructionValue && isResidential(properTypeValue);
  const shouldShowConstructionSwitch = showConstructionSwitch
    && isResidential(properTypeValue) && isConstruction(subtypeValue);

  const isForConstructionFieldName = names[1];
  const constructionField = get(fields, isForConstructionFieldName);
  const valueFieldName = names[2];

  const resetValueOrCurrentValue = (shouldResetValue) => {
    const fieldToClear = shouldResetValue ? valueFieldName : currentValueFieldName;
    return clearRelatedFields([fieldToClear], fields);
  };

  return (
    <>
      <ReduxFormSelect
        {...propertyField}
        options={PROPERTY_TYPE_OPTIONS}
        label="Property type"
        className={styles.propertyTypeField}
        onChange={(value) => {
          propertyField.input.onChange(value);
          clearRelatedFields(names.filter(name => name !== propertyTypeName && name !== valueFieldName), fields);
          onPropertyTypeChange(value);
        }}
      />
      {subTypeName
      && (
        <ReduxFormSelect
          {...subTypeField}
          options={objValues(PROPERTY_SUB_TYPE_MAPPING[properTypeValue])}
          label={renderPropertySubtypeLabel(`${properTypeValue} type`, displayTooltip)}
          className={styles.subTypeField}
          onChange={(value) => {
            subTypeField.input.onChange(value);
            if (isConstruction(value) && setDefaultConstructionValue) {
              constructionField.input.onChange(true);
            } else {
              clearRelatedFields([isForConstructionFieldName], fields);
            }
            resetValueOrCurrentValue(isConstruction(value));
            onPropertyTypeChange(value);
          }}
        />
      )}
      {shouldShowConstructionSwitch && (
        <Field
          name={isForConstructionFieldName}
          component={ReduxFormYesNoSwitch}
          label="Apply for construction loan"
          className={styles.asConstructionField}
          switchedOnText="Yes"
          switchedOffText="No"
          onChange={(value) => {
            resetValueOrCurrentValue(value.target.checked);
          }}
        />
      )}
    </>
  );
};

PropertyTypeFields.defaultProps = {
  onPropertyTypeChange: noop,
  showConstructionSwitch: false,
  setDefaultConstructionValue: false,
};
PropertyTypeFields.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPropertyTypeChange: PropTypes.func,
  showConstructionSwitch: PropTypes.bool,
  setDefaultConstructionValue: PropTypes.bool,
};

export default PropertyTypeFields;
