import { gql } from '@apollo/client';

export const GET_PRODUCT_NAMES_BY_LENDER = gql`
  query variantsByLender($lenderName: String!) {
    variantsByLender(lenderName: $lenderName)
    {
      productName
      standardRate
      maxTerm
      externalLink
      source
      type
    }
  }
`;
