import get from 'lodash/get';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { applicationStatus as applicationStatusMap } from '../../../../../constants/applicationStatus';

const initialStatus = {};

const applicationStatus = createReducer(initialStatus, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, { data }) => ({
    ...get(data, 'applicationStatus', {}),
    isApplicationSettled: get(data, 'applicationStatus.status') === applicationStatusMap.SETTLED,
  }),
  [types.UPDATE_APPLICATION_STATUS_SUCCESS]: (state, action) => ({
    ...action.applicationStatus,
    isApplicationSettled: action.applicationStatus.status === applicationStatusMap.SETTLED,
  }),
});

export default applicationStatus;
