import { getAllProductsFromApi } from 'shared/api';
import { calculateTotalCost, calculateTotalRepayment, calculateVariantUpfrontFee } from 'shared/productUtils';
import defaultTo from 'lodash/defaultTo';
import map from 'lodash/map';
import * as types from '../types';

const organizeProducts = (products) => map(products, p => ({
  ...p,
  variants: map(p.variants, v => ({
    ...v,
    applicationFee: defaultTo(v.applicationFee, 0),
    legalFee: defaultTo(v.legalFee, 0),
    valuationFee: defaultTo(v.valuationFee, 0),
    ongoingFee: defaultTo(v.ongoingFee, 0),
    lmi: 0,
    upfrontFee: calculateVariantUpfrontFee(v),
  })),
  isSplitted: p.variants.length > 1,
  id: map(p.variants, 'id').join('-'),
  totalRepayment: calculateTotalRepayment(p.variants),
  lenderName: p.variants[0].lenderName,
  totalCost: calculateTotalCost(p.variants),
}));

export const getAllProducts = (searchCriteria, rawSearchCriteria, searchTrigger) => async (dispatch) => {
  dispatch({
    type: types.SEARCH_PRODUCTS_START,
    searchCriteria: rawSearchCriteria,
    searchTrigger,
  });
  try {
    const products = await getAllProductsFromApi(searchCriteria);
    const organizedProducts = organizeProducts(products);
    dispatch({
      type: types.SEARCH_PRODUCTS_SUCCESS,
      payload: organizedProducts,
      searchCriteria: rawSearchCriteria,
    });
    return organizedProducts;
  } catch (e) {
    dispatch({
      type: types.SEARCH_PRODUCTS_FAIL,
    });
    throw e;
  }
};
