import React from 'react';
import PropTypes from 'prop-types';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import TextInput from 'shared/components/formFields/ReduxForm/TextInput';
import { Field } from 'redux-form';
import isInteger from 'lodash/isInteger';
import styles from '../../CustomProduct.module.scss';

const required = value => (value || typeof value === 'number' ? undefined : 'Required');
const allowPercent = (value => value === undefined || value <= 100);
const allowInteger = (value => value === undefined || isInteger(value));

const getHeader = (isSplit, index) => (
  isSplit
  ? `Loan ${ index } product information`
  : 'Product information'
);
const LoanProduct = ({
 formNum, formPrefix, isSplit,
}) => (
  <div className={styles.productItem}>
    <h3>{getHeader(isSplit, formNum)}</h3>
    {/* row 1 */}
    <div className={styles.productRow}>
      <Field
        name={`${formPrefix}.productName`}
        component={TextInput}
        label="Product name"
        validate={required}
        isRequired
        isAllowed={value => value === undefined || value.length <= 150}
      />
      <Field
        name={`${formPrefix}.ongoingFee`}
        component={ReduxFormNumberInput}
        label="Ongoing fee"
        placeholder="$"
        decimalScale={2}
        prefix="$"
        suffix=" pm"
        allowNegative={false}
      />
    </div>
    {/* row 2 */}
    <h3 className={styles.rowHeader}>
      Upfront fee/rebate
    </h3>
    <div className={styles.productRow}>
      <Field
        name={`${formPrefix}.applicationFee`}
        component={ReduxFormNumberInput}
        label="Administration fee/rebate"
        placeholder="$"
        decimalScale={2}
        prefix="$"
        allowNegative
      />
      <Field
        name={`${formPrefix}.legalFee`}
        component={ReduxFormNumberInput}
        label="Legal fees"
        placeholder="$"
        decimalScale={2}
        prefix="$"
        allowNegative={false}
      />
      <Field
        name={`${formPrefix}.valuationFee`}
        component={ReduxFormNumberInput}
        label="Valuation fee"
        placeholder="$"
        decimalScale={2}
        prefix="$"
        allowNegative={false}
      />
    </div>
    {/* row 3 */}
    <h3 className={styles.rowHeader}>
      Interest rates
    </h3>
    <div className={styles.productRow}>
      <Field
        name={`${formPrefix}.standardRate`}
        component={ReduxFormNumberInput}
        label="Standard rate"
        placeholder="%"
        suffix="%"
        decimalScale={2}
        allowNegative={false}
        validate={required}
        isRequired
        isAllowed={allowPercent}
      />
      <Field
        name={`${formPrefix}.discountRate`}
        component={ReduxFormNumberInput}
        label="Discount rate"
        placeholder="%"
        suffix="%"
        decimalScale={2}
        allowNegative={false}
        isAllowed={value => value === undefined || value <= 100}
      />
      <Field
        name={`${formPrefix}.discountMonths`}
        component={ReduxFormNumberInput}
        label="Discount term"
        placeholder="months"
        suffix=" months"
        allowNegative={false}
        isAllowed={allowInteger}
      />
    </div>
  </div>
  );

LoanProduct.propTypes = {
  formNum: PropTypes.number.isRequired,
  formPrefix: PropTypes.string.isRequired,
  isSplit: PropTypes.bool.isRequired,
};

export default LoanProduct;
