import React from 'react';
import PropTypes from 'prop-types';
import LenderIconImage from 'shared/components/LenderIconImage';
import { SubLenderShape } from 'Loans/shapes/SubLenderShape';
import { EditField, USER_PERMISSIONS } from 'Loans/components/LoanDetails/EditField';
import SubLenderSelector from './SubLenderSelector';

const renderLenderWithLogoAndLink = lender => lender && <LenderIconImage lender={lender} />;

export const SubLenderField = ({ subLender, primaryLenderId, required }) => {
  if (!primaryLenderId) return null;

  return (
    <EditField
      permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
      fallback={renderLenderWithLogoAndLink(subLender)}
    >
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={<SubLenderSelector selectedSubLender={subLender} primaryLenderId={primaryLenderId} disabled />}
      >
        <SubLenderSelector selectedSubLender={subLender} primaryLenderId={primaryLenderId} required={required} />
      </EditField>
    </EditField>
  );
};

SubLenderField.defaultProps = { subLender: undefined, primaryLenderId: undefined, required: false };
SubLenderField.propTypes = {
  subLender: SubLenderShape,
  primaryLenderId: PropTypes.string,
  required: PropTypes.bool,
};
