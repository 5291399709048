import { connect } from 'react-redux';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from 'utils/cookie';
import { Alert } from '../Alert';

const typeMapping = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'danger',
};
const cookiePrefix = 'notification-banner-';

const SystemNotification = ({ notifications }) => {
  const list = useMemo(
    () =>
      notifications.map((item) => ({
        ...item,
        dismissed: Boolean(getCookie(cookiePrefix + item.id)),
      })),
    [notifications],
  );

  const alerts = list.filter(({ dismissed }) => !dismissed).map(({
      message, type, id, expiredAt,
    }) => (
      <Alert
        key={id}
        message={message}
        type={typeMapping[type]}
        onClose={() => setCookie(cookiePrefix + id, 1, expiredAt)}
      />
  ));
  return (
    <div>
      {alerts}
    </div>
  );
};

SystemNotification.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.string,
    }),
  ),
};

SystemNotification.defaultProps = {
  notifications: [],
};

const mapStateToProps = state => ({
  notifications: get(state, 'notification.list'),
});

export default connect(mapStateToProps)(SystemNotification);
