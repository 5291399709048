import { gql } from '@apollo/client';

export const GET_ALL_FEATURES = gql`
  query allFeatures {
    allFeatures {
      name
      groupName
    }
  }
`;

export const labelOf = {
  totalCost: 'Total interest and fees',
  firstPeriodRepayment: 'Repayments (incl. fees)',
  firstPeriodPrincipalAndInterest: 'Principal and interest',
  firstPeriodInterestOnly: 'Interest only',
  upfrontFee: 'Upfront fee/rebate',
  applicationFee: 'Application fee/rebate',
  legalFee: 'Legal fee',
  valuationFee: 'Valuation fee',
  ongoingFee: 'Ongoing fee',
  comparisonRate: 'Comparison rate',
  standardRate: 'Standard interest rate',
  discountRate: 'Introductory interest rate',
  discountMonths: 'Introductory interest term',
  minLoanValueRatio: 'Min. Loan to Value Ratio',
  maxLoanValueRatio: 'Max. Loan to Value Ratio',
  minLoanAmount: 'Min. package loan size',
  maxLoanAmount: 'Max. package loan size',
  costsAndFees: 'Costs and fees',
  ratesBreakdown: 'Rates breakdown',
  basicInformation: 'Basic information',
  minTerm: 'Min. loan term',
  maxTerm: 'Max. loan term',
  features: 'Features',
  comments: 'Comments',
  lmi: 'LMI',
};

export const APPLICATION_TYPES = {
  NEW_LOAN: 'NewLoan',
  TOP_UP: 'TopUp',
};

export const APPLICATION_TYPES_LABELS = {
  [APPLICATION_TYPES.NEW_LOAN]: 'New loan',
  [APPLICATION_TYPES.TOP_UP]: 'Top up',
};
