export const MotorVehicleGroup = 'MotorVehicle';
export const AccountNumberGroup = 'AccountNumber';
export const OthersGroup = 'Others';
export const OthersGroupWithInstitution = 'OthersWithInstitution';
export const OtherType = 'Other';

export const isOtherType = assetType => assetType && assetType === OtherType;

export const ASSET_TYPE = {
  MotorVehicle: {
    label: 'Motor Vehicle',
    value: 'MotorVehicle',
    group: MotorVehicleGroup,
  },
  CashManagement: {
    label: 'Cash Management',
    value: 'CashManagement',
    group: AccountNumberGroup,
  },
  ChequeAccount: {
    label: 'Cheque Account',
    value: 'ChequeAccount',
    group: AccountNumberGroup,
  },
  InvestmentSavings: {
    label: 'Investment Savings',
    value: 'InvestmentSavings',
    group: AccountNumberGroup,
  },
  OtherDepositAccount: {
    label: 'Other Deposit Account',
    value: 'OtherDepositAccount',
    group: AccountNumberGroup,
  },
  PensionAccount: {
    label: 'Pension Account',
    value: 'PensionAccount',
    group: AccountNumberGroup,
  },
  SavingsAccount: {
    label: 'Savings Account',
    value: 'SavingsAccount',
    group: AccountNumberGroup,
  },
  TermDeposit: {
    label: 'Term Deposit',
    value: 'TermDeposit',
    group: AccountNumberGroup,
  },
  Boat: {
    label: 'Boat',
    value: 'Boat',
    group: OthersGroup,
  },
  Bonds: {
    label: 'Bonds',
    value: 'Bonds',
    group: OthersGroup,
  },
  Cash: {
    label: 'Cash',
    value: 'Cash',
    group: OthersGroup,
  },
  ChargeOverCash: {
    label: 'Charge Over Cash',
    value: 'ChargeOverCash',
    group: OthersGroup,
  },
  Collections: {
    label: 'Collections',
    value: 'Collections',
    group: OthersGroup,
  },
  DebentureCharge: {
    label: 'Debenture Charge',
    value: 'DebentureCharge',
    group: OthersGroup,
  },
  FurnitureContents: {
    label: 'Furniture Contents',
    value: 'FurnitureContents',
    group: OthersGroup,
  },
  Gifts: {
    label: 'Gifts',
    value: 'Gifts',
    group: OthersGroup,
  },
  GoodwillOfBusiness: {
    label: 'Goodwill Of Business',
    value: 'GoodwillOfBusiness',
    group: OthersGroup,
  },
  Guarantee: {
    label: 'Guarantee',
    value: 'Guarantee',
    group: OthersGroup,
  },
  HomeContents: {
    label: 'Home Contents',
    value: 'HomeContents',
    group: OthersGroup,
  },
  LifeInsurance: {
    label: 'Life Insurance',
    value: 'LifeInsurance',
    group: OthersGroup,
  },
  ManagedFunds: {
    label: 'Managed Funds',
    value: 'ManagedFunds',
    group: OthersGroup,
  },
  Other: {
    label: 'Other',
    value: OtherType,
    group: OthersGroup,
  },
  PersonalEquityInAnyPrivateBusiness: {
    label: 'Personal Equity In Any Private Business',
    value: 'PersonalEquityInAnyPrivateBusiness',
    group: OthersGroup,
  },
  Receivables: {
    label: 'Receivables',
    value: 'Receivables',
    group: OthersGroup,
  },
  Shares: {
    label: 'Shares',
    value: 'Shares',
    group: OthersGroup,
  },
  StockAndMachinery: {
    label: 'Stock And Machinery',
    value: 'StockAndMachinery',
    group: OthersGroup,
  },
  Superannuation: {
    label: 'Superannuation',
    value: 'Superannuation',
    group: OthersGroupWithInstitution,
  },
  TimeShare: {
    label: 'Time Share',
    value: 'TimeShare',
    group: OthersGroup,
  },
  ToolsOfTrade: {
    label: 'Tools Of Trade',
    value: 'ToolsOfTrade',
    group: OthersGroup,
  },
};

export const findAssetTypeLabelByValue = (value) => ASSET_TYPE[value]?.label ?? value;
