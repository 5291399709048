import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import isArray from 'lodash/isArray';
import lowerCase from 'lodash/lowerCase';
import take from 'lodash/take';
import Select from 'shared/components/formFields/Select/WithReadOnlySelect';
import { FETCHING_STATUS } from '../../../constants';

const { MenuList } = components;

const CreateOptionsMenuList = (takeCount = Infinity) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...others } = props;
  const normalizedChildren = isArray(children) ? take(children, takeCount) : children;
  return (
    <MenuList {...others}>
      {normalizedChildren}
    </MenuList>
  );
};

export const ApplicantSelector = ({
 onChange, selectedValue, options, optionsTake, fetchingStatus, OptionLabel, OptionValue, readOnlyFormatter, ...others
}) => {
  const OptionsMenuList = useMemo(() => CreateOptionsMenuList(optionsTake), [optionsTake]);
  const filterOption = useMemo(() => (meta, keyword) =>
    lowerCase(meta.label === null ? meta.label : meta.label.toLowerCase())
      .includes(lowerCase(keyword === null ? keyword : keyword.toLowerCase())), []);

  return (
    <Select
      name="applicantId"
      options={options || []}
      fullWidth
      required
      onChange={option => onChange(option.value)}
      components={{
          Option: OptionLabel,
          SingleValue: OptionValue,
          MenuList: OptionsMenuList,
        }}
      loading={fetchingStatus === FETCHING_STATUS.START}
      {...others}
      value={selectedValue}
      filterOption={filterOption}
      readOnlyFormatter={readOnlyFormatter}
    />
    );
  };

ApplicantSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
  })).isRequired,
  optionsTake: PropTypes.number,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  OptionLabel: PropTypes.elementType.isRequired,
  OptionValue: PropTypes.elementType.isRequired,
  readOnlyFormatter: PropTypes.func.isRequired,
};

ApplicantSelector.defaultProps = {
  optionsTake: 20,
  selectedValue: undefined,
};
