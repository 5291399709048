export const ACTIONS = {
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  REMOVE_ERROR_MESSAGE: 'REMOVE_ERROR_MESSAGE',
};

export const closeErrorBanner = () => dispatch =>
  dispatch({ type: ACTIONS.REMOVE_ERROR_MESSAGE });

export const showErrorBanner = errorMessage => dispatch =>
  dispatch({ type: ACTIONS.SET_ERROR_MESSAGE, errorMessage });

const initialState = {
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case ACTIONS.REMOVE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};
