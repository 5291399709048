import { useEffect } from 'react';

import { trackViewPage } from '../redux/tracking';

let dispatch;

export const setPageViewTrackingDispatch = (newDispatch) => {
  dispatch = newDispatch;
};

export default pageName => useEffect(() => {
  dispatch(trackViewPage(pageName));
}, [pageName]);
/*
passing an empty array as second argument triggers the callback in useEffect
only after the initial render thus replicating `componentDidMount` lifecycle behaviour
Reference: https://reactjs.org/docs/hooks-reference.html#useeffect
*/
