import { GET_SPECIAL_TASKS_SUCCESSFUL, CHANGE_SPECIAL_TASK_STATUS_SUCEESSFUL } from '../../action/task';

const initialState = [];

export default (state = initialState, action) => {
  if (action.type === GET_SPECIAL_TASKS_SUCCESSFUL) {
    return [...action.data.specialTasks];
  }

  if (action.type === CHANGE_SPECIAL_TASK_STATUS_SUCEESSFUL) {
    return state.map(specialTask =>
      (specialTask.id === action.data.taskId
        ? ({ ...specialTask, completed: !specialTask.completed })
        : { ...specialTask }));
  }

  return state;
};
