import React from 'react';
import PropTypes from 'prop-types';
import GroupSection from './GroupSection';

const mapFeaturesByGroup = features => (features || []).reduce(
  (acc, feature) => ({ ...acc, [feature.groupName]: (acc[feature.groupName] || []).concat(feature.name) }), {},
);

const FeatureList = (props) => {
  const {
    selectedFeatureNames, fixedFeatureNames, features, onToggleCheckbox,
  } = props;
  const featuresByGroup = mapFeaturesByGroup(features);
  if (!featuresByGroup) {
    return null;
  }

  return (
    <div>
      {Object.keys(featuresByGroup).map(groupName => (
        <GroupSection
          key={groupName}
          groupName={groupName}
          featureList={featuresByGroup[groupName]}
          selectedFeatureNames={selectedFeatureNames}
          fixedFeatureNames={fixedFeatureNames}
          onToggleCheckbox={onToggleCheckbox}
        />
      ))}
    </div>
  );
};

FeatureList.defaultProps = {
  features: [],
  selectedFeatureNames: [],
  fixedFeatureNames: [],
  onToggleCheckbox: () => {},
};

FeatureList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    groupName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  fixedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  onToggleCheckbox: PropTypes.func,
};

export default FeatureList;
