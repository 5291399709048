import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getRequestIdFromAction, ifValidRequestId, startRequest } from 'redux/requestIds';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import { downloadSearchableLoansByFilterXlsx } from 'shared/api';
import { openInTab } from 'shared/utils';
import moment from 'moment';
import graphQL from '../loansGraphQL';
import { FETCHING_STATUS } from '../../../../constants';
import { isError } from '../../../../utils/actionStatusUtil';
import { INITIAL_TAKE } from '../constants';
import { ACTIONS as BUSINESS_ACTIONS } from '../../../../redux/business/business';
import { showToast } from '../../../../redux/toast/actions';
import { buildFilter } from '../../../utils';

export const ACTIONS = {
  RESET: 'LOANS:RESET',
  GET_LOANS_INIT: 'LOANS:GET_LOANS_INIT',
  GET_LOANS_START: 'LOANS:GET_LOANS_START',
  GET_LOANS_SUCCESS: 'LOANS:GET_LOANS_SUCCESS',
  GET_LOANS_ERRORS: 'LOANS:GET_LOANS_ERRORS',
  DOWNLOAD_LOANS_START: 'LOANS:DOWNLOAD_LOANS_START',
  DOWNLOAD_LOANS_SUCCESS: 'LOANS:DOWNLOAD_LOANS_SUCCESS',
  DOWNLOAD_LOANS_ERRORS: 'LOANS:DOWNLOAD_LOANS_ERRORS',
  TOGGLE_VIEW_ALL: 'LOANS:TOGGLE_VIEW_ALL',
  UPDATE_REMOTE_FILTERS: 'LOANS:UPDATE_REMOTE_FILTERS',
  UPDATE_LOAN_CATEGORIES: 'LOANS:UPDATE_CATEGORIES',
  INIT_QUERY: 'LOANS:INIT_QUERY',
};

export const toggleViewAll = () => ({
  type: ACTIONS.TOGGLE_VIEW_ALL,
});

export const reset = () => ({
  type: ACTIONS.RESET,
});

const getLoansStart = () => ({
  type: ACTIONS.GET_LOANS_START,
});

const getLoansSuccess = ({ loans, totalCount, skip }) => ({
  type: ACTIONS.GET_LOANS_SUCCESS,
  payload: {
    loans,
    totalCount,
    skip,
  },
});

const getLoansErrors = errors => ({
  type: ACTIONS.GET_LOANS_ERRORS,
  payload: {
    errors,
  },
});

const downloadLoansStart = () => ({
  type: ACTIONS.DOWNLOAD_LOANS_START,
});

const downloadLoansSuccess = () => ({
  type: ACTIONS.DOWNLOAD_LOANS_SUCCESS,
});

const downloadLoansErrors = () => ({
  type: ACTIONS.DOWNLOAD_LOANS_ERRORS,
});

export const initGetLoans = (requestedSkip, take) => ({
  type: ACTIONS.GET_LOANS_INIT,
  payload: {
    requestedSkip,
    take,
  },
});

export const updateRemoteFilters = remoteFilters => ({
  type: ACTIONS.UPDATE_REMOTE_FILTERS,
  payload: { remoteFilters },
});

export const updateLoanCategories = (loanId, categories) => ({
  type: ACTIONS.UPDATE_LOAN_CATEGORIES,
  payload: {
    loanId,
    categories,
  },
});

export const categoriesMapperFunc = categories => ({
  categories: map(categories, 'name').join(', '),
  categoryIds: map(categories, 'id').join(','),
});

export const initQuery = () => ({
  type: ACTIONS.INIT_QUERY,
});

const getBusinessId = state => get(state, 'business.selectedBusiness.id');
const getViewAll = state => get(state, 'loans.loans.viewAll');
const getRequestedSkip = state => get(state, 'loans.loans.requestedSkip');
const getTake = state => get(state, 'loans.loans.take');
const getRemoteFilters = state => get(state, 'loans.loans.remoteFilters');

export const getLoans = query => async (dispatch, getState) => {
  const state = getState();
  const { fetchingStatus } = get(state, 'loans.loans');
  if (isError(fetchingStatus)) {
    return;
  }

  if ((!getBusinessId(state) && !getViewAll(state))) {
    dispatch({
      type: ACTIONS.GET_LOANS_SUCCESS,
      payload: {
        loans: [],
      },
    });
    return;
  }

  dispatch(getLoansStart());
  const startRequestAction = startRequest(ACTIONS.GET_LOANS_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const results = await graphQL.getLoans(query);
    ifValidRequestId(
      getState().requestIds,
      ACTIONS.GET_LOANS_START,
      requestId,
      () => {
        dispatch(
          getLoansSuccess({
            ...results.data.loansSummary,
            skip: query.skip,
          }),
        );
      },
    );
  } catch (errors) {
    ifValidRequestId(
      getState().requestIds,
      ACTIONS.GET_LOANS_START,
      requestId,
      () => {
        dispatch(getLoansErrors(errors));
      },
    );
  }
};

export const querySelector = createSelector(
  getBusinessId,
  getViewAll,
  getRequestedSkip,
  getTake,
  getRemoteFilters,
  (businessId, viewAll, requestedSkip, take, remoteFilters) => {
    if ([requestedSkip, take, remoteFilters].some(isNil)) {
      return null;
    }
    return ({
      businessId: viewAll ? '' : businessId,
      skip: requestedSkip,
      take,
      filter: buildFilter(remoteFilters),
    });
  },
);

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  skip: null,
  requestedSkip: null,
  take: null,
  data: [],
  totalRowCount: null,
  errors: undefined,
  viewAll: false,
  remoteFilters: [],
  isDownloading: false,
};

export const downloadLoans = (query) => (dispatch, getState) => {
  dispatch(downloadLoansStart());
  const selectedBusiness = get(getState(), 'business.selectedBusiness');
  return downloadSearchableLoansByFilterXlsx(query)
    .then((xlsxBlob) => {
      dispatch(downloadLoansSuccess());
      openInTab(xlsxBlob, `loans_${selectedBusiness.bizName}_${moment().format('YYYY_MM_DD')}.xlsx`);
    })
    .catch(() => {
      dispatch(downloadLoansErrors());
      dispatch(
        showToast(
          'There was a problem while downloading your xlsx. Please try again later.',
          { type: 'error' },
        ),
      );
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET:
      return {
        ...initialState,
        viewAll: state.viewAll,
      };
    case ACTIONS.INIT_QUERY:
      return {
        ...initialState,
        skip: 0,
        requestedSkip: 0,
        take: INITIAL_TAKE,
        data: [],
        totalRowCount: 0,
        viewAll: state.viewAll,
      };
    case ACTIONS.GET_LOANS_INIT:
      return {
        ...state,
        requestedSkip: action.payload.requestedSkip,
        take: action.payload.take,
      };

    case ACTIONS.GET_LOANS_START:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.START,
        errors: undefined,
      };

    case ACTIONS.GET_LOANS_SUCCESS:
      return {
        ...state,
        data: action.payload.loans,
        skip: action.payload.skip,
        totalRowCount: action.payload.totalCount,
        fetchingStatus: FETCHING_STATUS.SUCCESS,
        errors: undefined,
      };

    case ACTIONS.GET_LOANS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
        fetchingStatus: FETCHING_STATUS.ERROR,
      };

    case ACTIONS.TOGGLE_VIEW_ALL:
      return {
        ...state,
        viewAll: !state.viewAll,
      };

    case ACTIONS.UPDATE_REMOTE_FILTERS:
      return {
        ...state,
        remoteFilters: action.payload.remoteFilters,
        requestedSkip: 0,
        skip: 0,
        take: INITIAL_TAKE,
        totalRowCount: 0,
      };
    case BUSINESS_ACTIONS.SELECT_BUSINESS:
      return {
        ...state,
        viewAll: false,
      };
    case ACTIONS.UPDATE_LOAN_CATEGORIES: {
      return {
        ...state,
        data: state.data.map((loan) => {
          if (loan.id === action.payload.loanId) {
            return {
              ...loan,
              ...categoriesMapperFunc(action.payload.categories),
            };
          }
          return loan;
        }),
      };
    }
    case ACTIONS.DOWNLOAD_LOANS_START:
      return {
        ...state,
        isDownloading: true,
      };
    case ACTIONS.DOWNLOAD_LOANS_SUCCESS:
      return {
        ...state,
        isDownloading: false,
      };
    case ACTIONS.DOWNLOAD_LOANS_ERRORS:
      return {
        ...state,
        isDownloading: false,
      };

    default:
      return state;
  }
};
