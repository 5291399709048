/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';

const VARIANTS = {
  primary: styles.primaryButton,
  secondary: styles.secondaryButton,
  naked: styles.nakedButton,
};

const handleMouseDown = (e) => {
  e.currentTarget.style.outline = 'none';
};
const handleBlur = (e) => {
  e.currentTarget.style.outline = '';
};

const Button = ({
  className,
  disabled,
  type,
  children,
  onClick,
  variant,
  style,
  loading,
  'data-test-id': dataTestId,
}) => (
  <button
    className={classNames(className, VARIANTS[variant], {
      [styles.disabled]: disabled,
    })}
    data-test-id={dataTestId}
    disabled={disabled}
    onClick={onClick}
    style={style}
    type={type}
    onMouseDown={handleMouseDown}
    onBlur={handleBlur}
  >
    {loading && <LoadingSpinner mini />}
    {children}
  </button>
);

export const defaultProps = {
  className: undefined,
  disabled: false,
  loading: false,
  variant: 'primary',
  type: 'button',
  onClick: () => {},
  style: {},
  'data-test-id': '',
};

export const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  style: PropTypes.shape({}),
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  'data-test-id': PropTypes.string,
};

Button.defaultProps = defaultProps;

Button.propTypes = propTypes;

export default Button;
