import React from 'react';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import { INSURANCE_REFERRAL_TAB } from '../../../../../../../../constants/navTab';
import BasicRichContent from '../BasicRichContent';

const AllianzContent = (props) => {
  const message = 'It\'s time to ';
  const referMessage = 'refer your client to Allianz or opt out of general insurance.';
  const { extraInfo, history, match: { params: { applicationId } } } = props;
  const isLinkable = !(extraInfo && !isEmpty(extraInfo.applicantNames));
  const newLog = {
    ...props,
    content: (
      <div style={{ textAlign: 'left' }}>
        <span>{message}</span>
        {isLinkable ? (
          <TertiaryButton
            onClick={() => history.push(`/applications/${applicationId}/${INSURANCE_REFERRAL_TAB.path}`)}
          >
            {referMessage}
          </TertiaryButton>
          ) : <span>{referMessage}</span>}
      </div>
    ),
  };
  return (
    <BasicRichContent {...newLog} />
  );
};

AllianzContent.defaultProps = {
  extraInfo: {
    applicantNames: [],
  },
};

AllianzContent.propTypes = {
  extraInfo: PropTypes.shape({
    applicantNames: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(AllianzContent);
