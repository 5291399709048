import PropTypes from 'prop-types';

export default PropTypes.shape({
  reportType: PropTypes.string,
  client: PropTypes.string,
  lender: PropTypes.string,
  settlementDate: PropTypes.string,
  approvedAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  settledAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  approvalNumber: PropTypes.string,
  loanBalance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  commissionAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previousLoanBalance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  props: PropTypes.shape({
    commissionType: PropTypes.string,
  }),
});
