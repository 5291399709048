export const EMPTY_CHARACTER = '\u00a0';

export const employmentTypes = {
  PAYE: 'PAYE',
  SELF_EMPLOYED: 'SelfEmployed',
  UNEMPLOYED: 'UnEmployed',
  RETIRED: 'Retired',
};

export const status = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Previous: 'Previous',
};

export const employmentTypeOptions = [{
  value: employmentTypes.PAYE,
  label: 'PAYG',
}, {
  value: employmentTypes.SELF_EMPLOYED,
  label: 'Self employed',
}, {
  value: employmentTypes.UNEMPLOYED,
  label: 'Not working',
}, {
  value: employmentTypes.RETIRED,
  label: 'Retired',
}];

export const employmentStatusOptions = [{
  value: 'FullTime',
  label: 'Full time',
}, {
  value: 'PartTime',
  label: 'Part time',
}, {
  value: 'Contract',
  label: 'Contract',
}, {
  value: 'Temporary',
  label: 'Temporary',
}, {
  value: 'Casual',
  label: 'Casual',
}];

export const employmentSectorOptions = [{
  value: 'Public',
  label: 'Public',
}, {
  value: 'Private',
  label: 'Private',
}];

const primaryOption = {
  value: status.Primary,
  label: 'Primary',
};

const secondaryOption = {
  value: status.Secondary,
  label: 'Secondary',
};

const previousOption = {
  value: status.Previous,
  label: 'Previous',
};

export const statusOptions = [primaryOption, secondaryOption, previousOption];

export const statusOptionDisablePrimary = [{
    ...primaryOption,
    isDisabled: true,
  },
  secondaryOption,
  previousOption,
];

export const PAYEIncomeTypeOptions = [{
  value: 'Bonus',
  label: 'Bonus',
}, {
  value: 'CarAllowance',
  label: 'Car allowance',
}, {
  value: 'Commission',
  label: 'Commission',
}, {
  value: 'CompanyCar',
  label: 'Company car',
}, {
  value: 'GrossRegularOvertime',
  label: 'Gross regular overtime',
}, {
  value: 'GrossSalary',
  label: 'Gross salary',
}, {
  value: 'NetRegularOvertime',
  label: 'Net regular overtime',
}, {
  value: 'NetSalary',
  label: 'Net salary',
}, {
  value: 'OtherIncome',
  label: 'Other income',
}, {
  value: 'WorkAllowance',
  label: 'Work allowance',
}, {
  value: 'WorkersCompensation',
  label: 'Workers compensation',
}];

export const selfEmployedIncomeTypeOptions = [{
  value: 'Addback',
  label: 'Addback',
}, {
  value: 'CompanyProfitBeforeTax',
  label: 'Company profit before tax',
}];

export const unemployedRetiredIncomeTypeOptions = [{
  value: 'Dividends',
  label: 'Dividends',
}, {
  value: 'GovernmentBenefits',
  label: 'Government benefits',
}, {
  value: 'InterestIncome',
  label: 'Interest income',
}, {
  value: 'OtherIncome',
  label: 'Other income',
}, {
  value: 'PrivatePension',
  label: 'Private pension',
}];

const DEPRECIATION = {
  value: 'Depreciation',
  label: 'Depreciation',
};

const NON_CASH_BENEFITS = {
  value: 'NonCashBenefits',
  label: 'Non cash benefits',
};

const NON_RECURRING_EXPENSES = {
  value: 'NonRecurringExpenses',
  label: 'Non recurring expenses',
};

const SUPERANNUATION = {
  value: 'Superannuation',
  label: 'Superannuation',
};

const Lease = {
  value: 'Lease',
  label: 'Lease',
};

const Interest = {
  value: 'Interest',
  label: 'Interest',
};

export const addbackTypeOptions = [
  DEPRECIATION,
  NON_CASH_BENEFITS,
  NON_RECURRING_EXPENSES,
  SUPERANNUATION,
];

export const companyAddbackTypeOptions = [
  DEPRECIATION,
  NON_CASH_BENEFITS,
  NON_RECURRING_EXPENSES,
  Lease,
  Interest,
];

export const governmentBenefitsTypeOptions = [{
  value: 'FamilyAllowance',
  label: 'Family allowance',
}, {
  value: 'UnemployedAllowance',
  label: 'Unemployed allowance',
}, {
  value: 'WidowsAllowance',
  label: 'Widows allowance',
}];

export const getIncomeOptionsFromEmploymentType = employmentType => {
  switch (employmentType) {
    case employmentTypes.PAYE: return PAYEIncomeTypeOptions;
    case employmentTypes.SELF_EMPLOYED: return selfEmployedIncomeTypeOptions;
    case employmentTypes.UNEMPLOYED:
    case employmentTypes.RETIRED: return unemployedRetiredIncomeTypeOptions;
    default: return null;
  }
};

export const frequencyOptions = [{
  value: 'Weekly',
  label: 'Weekly',
}, {
  value: 'Fortnightly',
  label: 'Fortnightly',
}, {
  value: 'Monthly',
  label: 'Monthly',
}, {
  value: 'Yearly',
  label: 'Yearly',
}];

export const COMPANY_PROFIT_BEFORE_TAX = {
    value: 'CompanyProfitBeforeTax',
    label: 'Company profit before tax',
  };
export const companyIncomeTypeOptions = [{
  value: 'Addback',
  label: 'Addback',
}, {
  value: 'CompanyProfitBeforeTax',
  label: 'Company profit before tax',
}, {
  value: 'GrossSalary',
  label: 'Gross salary',
}, {
  value: 'OtherIncome',
  label: 'Other income',
}];
