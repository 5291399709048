import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MultipleSelectFilter from 'shared/components/MobiusTable/components/MultipleSelectFilter/MultipleSelectFilter';
import styles from './styles.module.scss';

const StatusFilter = ({ filter, disabled, onFilter }) => {
  const statusOptions = {
    ALL: 'Select all',
    PRE_SUBMISSION: 'Pre-submission',
    DRAFT: 'Draft',
    SUBMITTED: 'Submitted',
    CONDITIONALLY_APPROVED: 'Conditional',
    UNCONDITIONALLY_APPROVED: 'Unconditional',
    SETTLED: 'Settled',
    WITHDRAWN: 'Withdrawn',
    DECLINED: 'Declined',
  };

  const getFilterName = (selectedStatusList) => {
    if (isEmpty(selectedStatusList)) {
      return '';
    }
    if (selectedStatusList.length === 1) {
      return statusOptions[selectedStatusList[0]];
    }
    if (selectedStatusList.includes('ALL')) {
      return 'All';
    }
    return 'Multiple';
  };

  return (
    <MultipleSelectFilter
      filter={filter}
      onFilter={onFilter}
      disabled={disabled}
      options={statusOptions}
      getFilterName={getFilterName}
      className={styles.statusFilter}
    />
  );
};

StatusFilter.defaultProps = {
  disabled: false,
};

StatusFilter.propTypes = {
  disabled: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StatusFilter;
