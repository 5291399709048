import React from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-bootstrap4';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TemplateConnector } from '@devexpress/dx-react-core';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import styles from '../style.module.scss';
import { mergeClassNamesObjects } from '../../utils';

const FilterCell = (props) => {
  const {
    children, className, filter, onFilter, ...others
  } = props;
  const {
    column: {
      name, allowFiltering, allowRemoteFiltering,
      classNames: classNamesFromColumn,
      filterCellRenderer,
    },
  } = others;

  const calculatedChildren = (allSelected) => {
    if (!allowFiltering && !allowRemoteFiltering) {
      return null;
    }
    if (filterCellRenderer) {
      return filterCellRenderer(filter, onFilter, allSelected);
    }
    return (
      <TextInput
        onChange={v => onFilter({ value: v })}
        value={(filter && filter.value) || ''}
        placeholder="Filter..."
        style={{ marginBottom: '.5rem' }}
        mini
        fullWidth
        disabled={allSelected}
      />
    );
  };

  return (
    <TemplateConnector>
      {({ fixedBorderRightColumn, classNames, allSelected }) => {
        const containShadow = fixedBorderRightColumn === name;
        const mergedClassNames = mergeClassNamesObjects(classNames, classNamesFromColumn);
        const cellClassName = cx(
          styles.cell,
          styles.filterCell,
          mergedClassNames.cell,
          mergedClassNames.filterCell,
        );
        return (
          <TableFilterRow.Cell
            {...others}
            className={cx(className, {
              [cellClassName]: !containShadow,
              [styles.primativeCell]: containShadow,
              [styles.shadowCell]: containShadow,
              [mergedClassNames.primativeCell]: containShadow,
              [mergedClassNames.primativeHeaderCell]: containShadow,
            })}
          >
            {containShadow ? (
              <div className={styles.shadowWrapper}>
                <div className={cellClassName}>
                  {calculatedChildren(allSelected)}
                </div>
              </div>
            ) : calculatedChildren(allSelected)}
          </TableFilterRow.Cell>
        );
      }}
    </TemplateConnector>
  );
};

FilterCell.defaultProps = {
  className: '',
  children: undefined,
  filter: undefined,
};

FilterCell.propTypes = {
  column: PropTypes.shape().isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  filter: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

export default FilterCell;
