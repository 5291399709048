import PropTypes from 'prop-types';

export default PropTypes.shape({
  lender: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lenderId: PropTypes.string,
  }).isRequired,
  status: PropTypes.string,
  accountNumber: PropTypes.string.isRequired,
  applicants: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    people: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  adviser: PropTypes.shape({}).isRequired,
  settledDate: PropTypes.string.isRequired,
  reconciledDate: PropTypes.string,
  repaidDate: PropTypes.string,
  assetFinanceLender: PropTypes.shape({
    firstName: PropTypes.string,
    surname: PropTypes.string,
  }),
  clientDiscount: PropTypes.number,
});

export const createFakeLoan = () => ({
    lender: {
      name: 'Issy',
      lenderId: 'lender-id',
    },
    accountNumber: 'account-number',
    applicants: {
      companies: [],
      people: [],
    },
    adviser: {},
    settledDate: '2019-11-13',
    standardRate: 2.74,
    productType: 'Fixed',
  });
