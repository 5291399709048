import React from 'react';
import PropTypes from 'prop-types';
import { FONTS } from '../../theme/depricatedStyles';

const NavBarMenuItem = ({
 isSelected, onClick, onKeyDown, children,
}) => {
  const itemStyle = isSelected ? { ...styles.button, ...styles.selected } : styles.button;
  return (
    <li className="dropdown-content" onClick={onClick} onKeyDown={onKeyDown} role="presentation">
      <button
        type="button"
        className="dropdown-item"
        style={itemStyle}
      >
        {children}
      </button>
    </li>
  );
};

const styles = {
  button: {
    whiteSpace: 'pre-wrap',
    width: '20rem',
  },
  selected: {
    fontFamily: FONTS.standardBold,
  },
};

NavBarMenuItem.defaultProps = {
  isSelected: false,
  onClick: () => {},
  onKeyDown: () => {},
};

NavBarMenuItem.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default NavBarMenuItem;
