import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { FETCHING_STATUS } from '../../../../../../constants';

const initialState = FETCHING_STATUS.INIT;

const employmentsUpdatingStatus = createReducer(initialState,
  {
    [types.UPDATE_EMPLOYMENTS_START]: () => FETCHING_STATUS.START,
    [types.UPDATE_EMPLOYMENTS_SUCCESS]: () => FETCHING_STATUS.SUCCESS,
    [types.GET_APPLICATION_DETAIL_SUCCESS]: () => FETCHING_STATUS.INIT,
  });

export default employmentsUpdatingStatus;
