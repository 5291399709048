import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import types from './constants';
import { MESSAGES } from '../../constants/message';

export const getMessage = (messageKey, errorCode, errorContext) => {
  const message = get(MESSAGES, [messageKey, errorCode], null);
  if (isFunction(message)) {
    return message(errorContext);
  }
  return message;
};

export const showToast = (message, options) => ({
  type: types.SHOW_TOAST,
  message,
  options,
});
