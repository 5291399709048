import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({
 title, description, children, style, className,
}) => (
  <div style={style} className={classNames(styles.container, className)}>
    <h2>{title}</h2>
    {description}
    {children}
  </div>
);

ErrorMessage.defaultProps = {
  title: '',
  description: '',
  children: undefined,
  style: {},
  className: '',
};

ErrorMessage.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ErrorMessage;
