import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popover from './Popover';
import styles from './NavBarMenu.module.scss';

const ENTER = 'Enter';

const handleKeyDown = toggle => (e) => {
  if (e.key && e.key === ENTER) {
    toggle();
  }
};

const isActivePath = (activePaths) => {
  const pathname = window.location.pathname.split('/');
  return activePaths.includes(pathname[1]);
};

const NavBarMenu = (props) => {
    const {
 title, activePaths, children, alignRight,
} = props;

    const header = toggle => (
      <div
        className={classNames('dd-header', {
          [styles.activeLink]: isActivePath(activePaths),
        })}
        role="button"
        tabIndex={0}
        onClick={toggle}
        onKeyDown={handleKeyDown(toggle)}
      >
        <div className="dropdown-trigger">
          <span data-test-id="my-business-tab" className={styles.linkTitle}>{title}</span>
          <div className="arrow-down" style={{ display: 'inline-flex' }} />
        </div>
      </div>
    );

    return (
      <Popover trigger={toggle => header(toggle)}>
        {(toggle, isShown) => {
          if (!isShown) {
            return null;
          }
          return (
            <div
              onClick={toggle}
              onKeyDown={handleKeyDown(toggle)}
              role="presentation"
            >
              <ul
                className={classNames({ [styles.alignRight]: alignRight })}
                style={{ zIndex: 2020 }}
              >
                {children}
              </ul>
            </div>
          );
        }}
      </Popover>
    );
};

NavBarMenu.defaultProps = {
  activePaths: [],
  alignRight: false,
};

NavBarMenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  alignRight: PropTypes.bool,
  activePaths: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavBarMenu;
