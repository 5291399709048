import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Button from 'shared/components/Buttons/Button';
import Checkbox from 'shared/components/formFields/Checkbox';
import styles from './styles.module.scss';

const MultipleSelectFilterContent = forwardRef(({
 onFilter, filter, onBlur, options,
}, ref) => {
  const [selection, updateSelection] = useState(filter);

  const handleCheckBoxChange = (value) => {
    if (value === 'ALL') {
      if (isSelected(value)) {
        updateSelection([]);
      } else {
        updateSelection([...Object.keys(options)]);
      }
    } else if (isSelected(value)) {
        updateSelection(selection.filter(s => s !== value && s !== 'ALL'));
      } else {
        updateSelection([...selection, value]);
      }
  };

  const handleFilter = () => {
    onFilter(selection);
  };

  const isSelected = value => selection.includes(value);

  return (
    <div className={styles.container} ref={ref} onBlur={onBlur}>
      <section className={styles.contentBody}>
        {map(options, (value, key) =>
          (
            <Checkbox
              key={key}
              checked={isSelected(key)}
              labelName={value}
              onChange={() => handleCheckBoxChange(key)}
            />
          ))}
      </section>
      <section className={styles.buttonContainer}>
        <Button onClick={handleFilter} className={styles.button}>
          Filter
        </Button>
      </section>
    </div>
  );
});

MultipleSelectFilterContent.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object.isRequired,
};

export default MultipleSelectFilterContent;
