import get from 'lodash/get';
import { toSentenceCase } from 'shared/formatterUtils';
import { PRODUCT_TYPES, REPAYMENT_TYPES } from '../constants';
import { STATE_OPTIONS } from '../ApplicationTracking/components/ApplicationDetails/ApplicationDetailContentNav/shared/AddressInput/constants';

const getLayoutType = () => {
  const isMediumOrAbove = window.matchMedia('(min-width: 1024px)').matches;

  if (!isMediumOrAbove) {
    return 'S';
  }
  const isLarge = window.matchMedia('(min-width: 1366px)').matches;
  return isLarge ? 'L' : 'M';
};

export const pageDataV100 = pageType => ({
  schema: 'iglu:au.com.smartline/page/jsonschema/1-0-0',
  data: {
    page_name: `smartline:product-search:${pageType}`,
    site: 'smartline',
    site_section: 'product-search',
    page_type: pageType,
    responsive_layout: getLayoutType(),
    language: 'english',
    platform: 'web',
  },
});

export const productSearchParametersV100 = (loanCriteriaList = []) => {
  const loanCriteria = loanCriteriaList.map(criteria => ({
    features: criteria.selectedFeatureNames,
    purpose: toSentenceCase(criteria.purpose) || 'Any',
    product_type: PRODUCT_TYPES[criteria.productType] || 'Any',
    repayment_type: REPAYMENT_TYPES[criteria.repaymentType] || 'Any',
  }));
  return ({
      schema: 'iglu:au.com.smartline/product_search_parameters/jsonschema/1-0-0',
      data: {
        number_of_loan_criteria: loanCriteriaList.length,
        split: loanCriteriaList.length > 1,
        loan_criteria: loanCriteria,
      },
    }
  );
};

const getValidAssignedStates = assignedStates => assignedStates
  ?.filter(state => STATE_OPTIONS.some(stateOption => stateOption.value === state)) ?? [];

export const userDataV100 = (userId, assignedStates) => ({
  schema: 'iglu:au.com.smartline/user/jsonschema/2-0-0',
  data: {
    locke_id: userId,
    user_id: userId,
    assigned_states: getValidAssignedStates(assignedStates),
  },
});

const CONTEXT_FUNCTIONS = {
  user: state => userDataV100(get(state, 'profile.userId'), get(state, 'profile.states')),
  page: state => pageDataV100(get(state, 'tracking.pageName')),
  product_search_parameters: state => productSearchParametersV100(get(state, 'productSearch.standAlone.products.searchCriteria.loanCriteriaList')),
};

const getContext = (state, ...names) => names.reduce((acc, name) => {
  const fn = CONTEXT_FUNCTIONS[name];
  acc[name] = fn(state);
  return acc;
}, {});

export const viewPageV100 = state => getContext(state, 'user', 'page');

export const printComparisonV100 = (state) => {
  const context = getContext(state, 'user', 'page', 'product_search_parameters');
  return {
    event: {
      schema: 'iglu:au.com.smartline/print_comparison/jsonschema/1-0-0',
      data: {
        event_name: 'print_comparison',
      },
    },
    ...context,
  };
};
export const productSearchV100 = (state) => {
  const context = {
    ...getContext(state, 'user', 'page'),
    product_search_parameters: productSearchParametersV100(state.productSearch.standAlone.loanCriteriaList),
  };

  return {
    event: {
      schema: 'iglu:au.com.smartline/click_product_search/jsonschema/1-0-0',
      data: {
        event_name: 'click_product_search',
      },
    },
    ...context,
  };
};

export const changeTotalCostTermV100 = (termSelected, state) => {
  const context = getContext(state, 'user', 'page', 'product_search_parameters');
  return {
    event: {
      schema: 'iglu:au.com.smartline/change_total_cost_term/jsonschema/1-0-0',
      data: {
        event_name: 'change_total_cost_term',
        term_selected: termSelected,
      },
    },
    ...context,
  };
};

export const changeRepaymentFrequencyV100 = (repaymentFrequencySelected, state) => {
  const context = getContext(state, 'user', 'page', 'product_search_parameters');
  return {
    event: {
      schema: 'iglu:au.com.smartline/change_repayment_frequency/jsonschema/1-0-0',
      data: {
        event_name: 'change_repayment_frequency',
        frequency_selected: repaymentFrequencySelected,
      },
    },
    ...context,
  };
};

export const submitFeedbackV100 = (currentPage, state) => {
  const context = getContext(state, 'user', 'page');
  return {
    event: {
      schema: 'iglu:au.com.smartline/submit_feedback/jsonschema/1-0-0',
      data: {
        event_name: 'submit_feedback',
        current_page: currentPage,
      },
    },
    ...context,
  };
};

export const downloadCommissionsXlsxV100 = (state) => {
  const context = getContext(state, 'user', 'page');
  return {
    event: {
      schema: 'iglu:au.com.smartline/download_commissions_xlsx/jsonschema/1-0-0',
      data: {
        event_name: 'download_commissions_xlsx',
        business_id: get(state, 'business.selectedBusiness.id'),
        financial_year: get(state, 'myBusiness.commissions.financialYears.selected'),
      },
    },
    ...context,
  };
};

export const downloadInvoicePdfV100 = (processingMonth, state) => {
  const context = getContext(state, 'user', 'page');
  return {
    event: {
      schema: 'iglu:au.com.smartline/download_invoice_pdf/jsonschema/1-0-0',
      data: {
        event_name: 'download_invoice_pdf',
        business_id: get(state, 'business.selectedBusiness.id'),
        processing_month: processingMonth,
      },
    },
    ...context,
  };
};

export const downloadInvoiceDetailsXlsxV100 = (processingMonth, state) => {
  const context = getContext(state, 'user', 'page');
  return {
    event: {
      schema: 'iglu:au.com.smartline/download_invoice_details_xlsx/jsonschema/1-0-0',
      data: {
        event_name: 'download_invoice_details_xlsx',
        business_id: get(state, 'business.selectedBusiness.id'),
        processing_month: processingMonth,
      },
    },
    ...context,
  };
};
