import React, { PureComponent } from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import difference from 'lodash/difference';
import map from 'lodash/map';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';
import RetryComponent from 'shared/components/RetryComponent';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import ExternalLink from 'shared/components/Links/ExternalLink';
import withDefault from 'shared/components/Links/withDefault';
import {
  formatCostCalculation,
  formatOngoingFee,
  toDollarAmount,
  toPercentage,
  toRatePercentage,
  formatDiscountMonth,
} from 'shared/formatterUtils';
import VariantsCell from 'shared/components/productSearch/ComparisonTable/VariantsCell';
import EmptyResult from '../../shared/components/EmptyResult';
import CompareButton from './CompareButton';
import { OptionShape } from '../../shapes';
import CalculationExplanation from '../../shared/components/productSearch/CalculationExplanation';
import { ProductsTableContext } from '../ProductSearchContext';
import SplitDisclaimer from './SplitDisclaimer';
import MobiusTable from '../../shared/components/MobiusTable';
import styles from './ProductsTable.module.scss';
import ConnectedTotalCostTerm from './TotalCostTerm';
import RepaymentFrequency from './RepaymentFrequency';

const getTableKey = memoize(() => uniqueId());

const MAX_COMPARISON_NUMBER = 5;

const formatProductNameCell = (value, variant, product, index) => (
  <div className={styles.productNameContainer}>
    {isNumber(index) && <span style={{ marginRight: '1em' }}>{index + 1}</span>}

    {product.customProduct === true
        ? <span style={{ margin: 0 }}>{`Custom: ${variant.displayName}`}</span>
        : withDefault(
          <ExternalLink to={variant.externalLink} style={{ verticalAlign: 'unset' }}>
            {variant.displayName}
          </ExternalLink>,
        )}
  </div>
);

const defaultColumnSetting = {
  wordWrapEnabled: true,
  align: 'right',
  width: 120,
};

const stickyColumnConfigs = [
  {
    name: 'lenderName',
    title: 'Lender',
    sticky: true,
    width: 180,
    allowSorting: true,
    align: undefined,
    classNames: {
      cell: styles.lenderNameCell,
      headerCell: styles.lenderNameHeaderCell,
    },
  },
  {
    name: 'externalLink',
    title: 'Product name',
    sticky: true,
    width: 220,
    align: undefined,
    classNames: {
      cell: styles.productNameCell,
    },
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={formatProductNameCell}
      >
        <div className={styles.totalInfo}>
          Total:
        </div>
      </VariantsCell>
    ),
  },
  {
    name: 'totalCost',
    title: (
      <span>
        Total interest
        <br />
        and fees
      </span>
    ),
    sticky: true,
    allowSorting: true,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatCostCalculation(variant.totalCost)}
      >
        <div className={styles.totalInfo}>
          {formatCostCalculation(product.totalCost)}
        </div>
      </VariantsCell>
    ),
    headerRenderer: (children, { allowSorting }) => (
      <div
        className={classNames({
          [styles.headerContainer]: true,
          [styles.sortingHeaderContainer]: allowSorting,
        })}
      >
        {children}
        <ConnectedTotalCostTerm menuPortalTarget={document.body} />
      </div>
    ),
  },
  {
    name: 'totalRepayment',
    title: (
      <span>
        Repayments
        <div className={styles.hint}>Incl. fees</div>
      </span>
    ),
    sticky: true,
    width: 140,
    allowSorting: true,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatCostCalculation(variant.firstPeriodRepayment, variant.repaymentAvailable)}
      >
        <div className={styles.totalInfo}>
          {formatCostCalculation(product.totalRepayment)}
        </div>
      </VariantsCell>
    ),
    headerRenderer: (children, { allowSorting }) => (
      <div
        className={classNames({
          [styles.headerContainer]: true,
          [styles.sortingHeaderContainer]: allowSorting,
        })}
      >
        {children}
        <RepaymentFrequency menuPortalTarget={document.body} />
      </div>
    ),
  },
];

const scrollableColumnsConfigs = [
  {
    name: 'standardRate',
    title: (
      <span>
        Standard
        <br />
        rate
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toRatePercentage(variant.standardRate)}
      />
    ),
    align: 'right',
  },
  {
    name: 'discountRate',
    title: (
      <span>
        Introductory
        <br />
        interest rate
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toRatePercentage(variant.discountRate)}
      />
    ),
    align: 'right',
  },
  {
    name: 'discountMonths',
    title: (
      <span>
        Introductory
        <br />
        interest term
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => (variant.discountMonths === 'N/A' ? variant.discountMonths : formatDiscountMonth(variant.discountMonths))}
      />
    ),
  },
  {
    name: 'upfrontFee',
    title: (
      <span>
        Upfront
        <br />
        fee
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.upfrontFee)}
      />
    ),
  },
  {
    name: 'ongoingFee',
    title: (
      <span>
        Ongoing
        <br />
        fee
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatOngoingFee(variant.ongoingFee, variant.ongoingFeeFrequency)}
      />
    ),
  },
  {
    name: 'minLoanValueRatio',
    title: (
      <span>
        Minimum
        <br />
        LVR
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toPercentage(variant.minLoanValueRatio)}
      />
    ),
  },
  {
    name: 'maxLoanValueRatio',
    title: (
      <span>
        Maximum
        <br />
        LVR
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toPercentage(variant.maxLoanValueRatio)}
      />
    ),
  },
  {
    name: 'minLoanAmount',
    title: (
      <span>
        Minimum
        <br />
        loan amount
      </span>
    ),
    align: 'right',
    width: 140,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.minLoanAmount)}
      />
    ),
  },
  {
    name: 'maxLoanAmount',
    title: (
      <span>
        Maximum
        <br />
        loan amount
      </span>
    ),
    width: 140,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.maxLoanAmount)}
      />
    ),
  },
];

const getRowId = row => row.id;

const getProductsCovertDiscountMonth = (products) => products.map(product => ({
  ...product,
  variants: product.variants.map(variant => ({
    ...variant,
    discountMonths: isNil(variant.discountRate) ? 'N/A' : variant.discountMonths,
  })),
}));

class ProductsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleClickLenderName = this.handleClickLenderName.bind(this);
    this.calculateColumns = this.calculateColumns.bind(this);
    this.getProductTableSectionContent = this.getProductTableSectionContent.bind(this);
    this.state = {
      columns: this.calculateColumns(props),
      isValidationPopupOpen: false,
    };
  }

  // eslint-disable-next-line camelcase,react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { context } = this.props;
    if (context.products.products !== nextProps.context.products.products) {
      this.setState((previousState) => ({
        ...previousState,
        columns: this.calculateColumns(nextProps),
      }));
    }
    if (context.products.hasMissingAppInfoError !== nextProps.context.products.hasMissingAppInfoError) {
      this.setState((previousState) => ({
        ...previousState,
        isValidationPopupOpen: get(nextProps, 'context.products.hasMissingAppInfoError'),
      }));
    }
  }

  handleClickLenderName(product) {
    return () => {
      const { context } = this.props;
      const { selectedOptions, dispatchUpdateOptionsToComparison, products } = context;
      if (selectedOptions.includes(product)) {
        dispatchUpdateOptionsToComparison(difference(selectedOptions, [product]));
      } else {
        const newIds = [...selectedOptions, product].slice(0, MAX_COMPARISON_NUMBER).map(it => it.id);
        const orderedOptions = products.products.filter(it => newIds.includes(it.id));
        dispatchUpdateOptionsToComparison(orderedOptions);
      }
    };
  }

  handleSelectionChange(selections) {
    const { context } = this.props;
    const { products, dispatchUpdateOptionsToComparison } = context;
    const options = products.products.filter(it => selections.includes(it.id));
    dispatchUpdateOptionsToComparison(options);
  }

  getProductTableSectionContent() {
    const { context } = this.props;
    const {
      products,
      selectedOptions,
    } = context;
    const {
     isLoading, hasError, searchPerformed,
    } = products;

    const { columns: enrichedColumns } = this.state;

    const totalCostAllowSorting = get(find(enrichedColumns, { name: 'totalCost' }), 'allowSorting', false);

    const selection = map(selectedOptions, ({ id }) => (id));
    if (!searchPerformed) {
      return null;
    }
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (hasError) {
      return <RetryComponent />;
    }

    if ((products.products || []).length === 0) {
      return <EmptyResult />;
    }
    const effectiveProducts = getProductsCovertDiscountMonth(products.products);

    return (
      <>
        <div className={styles.title}>
          <CompareButton />
          {this.shouldRenderTitle() && <div className={styles.titleHead}>Top 100 loans</div>}
        </div>
        <CalculationExplanation />
        <SplitDisclaimer />
        <MobiusTable
          key={getTableKey()}
          classNames={{
            cell: styles.cell,
            headerCell: styles.headerCell,
            sortingCell: styles.sortingCell,
            sortingHeaderCell: styles.sortingHeaderCell,
            primativeCell: styles.primativeCell,
          }}
          className={styles.table}
          rows={effectiveProducts}
          columns={enrichedColumns}
          isLoading={isLoading}
          virtual
          withSelection
          onSelectionChange={this.handleSelectionChange}
          getRowId={getRowId}
          selection={selection}
          maxSelectionCount={MAX_COMPARISON_NUMBER}
          defaultSorting={totalCostAllowSorting ? [{ columnName: 'totalCost', direction: 'asc' }] : undefined}
        />
      </>
    );
  }

  shouldRenderTitle() {
    const { context } = this.props;
    return context.products.products.length >= 100;
  }

  calculateColumns(props) {
    const { products } = props.context.products;
    const anySplitted = products.some(p => (p.isSplitted));
    const totalCostSortable = products.some(p => isNumber(p.totalCost));
    const totalRepaymentSortable = products.some(p => isNumber(p.totalRepayment));
    const columns = [...stickyColumnConfigs, ...scrollableColumnsConfigs]
      .map(col => ({
        ...defaultColumnSetting,
        ...col,
      }));
    return columns
      .map((col) => {
      if (col.name === 'lenderName') {
        return {
          ...col,
          renderer: (value, product) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
            <span role="button" onClick={this.handleClickLenderName(product)}>
              {value}
            </span>
          ),
          getCellValue: ({ lenderName }) => lenderName,
        };
      }

      if (col.name === 'totalCost') {
        return {
          ...col,
          width: totalCostSortable ? 160 : 140,
          allowSorting: totalCostSortable,
          getCellValue: ({ totalCost }) => totalCost,
        };
      }

      if (col.name === 'totalRepayment') {
        return {
          ...col,
          allowSorting: totalRepaymentSortable,
          getCellValue: ({ totalRepayment }) => totalRepayment,
        };
      }

      if (col.name === 'standardRate') {
        return {
          ...col,
          allowSorting: !anySplitted,
          getCellValue: ({ variants: [{ standardRate }] }) => standardRate,
        };
      }

      if (col.name === 'discountRate') {
        return {
          ...col,
          allowSorting: !anySplitted,
          getCellValue: ({ variants: [{ discountRate }] }) => discountRate,
        };
      }
      return col;
    });
  }

  render() {
    const { context } = this.props;
    return (
      <ProductsTableContext.Provider value={context}>
        {this.getProductTableSectionContent()}
      </ProductsTableContext.Provider>
    );
  }
}

ProductsTable.propTypes = {
  context: PropTypes.shape({
    products: PropTypes.shape({
      products: PropTypes.arrayOf(OptionShape),
      isLoading: PropTypes.bool,
      hasError: PropTypes.bool,
      hasMissingAppInfoError: PropTypes.bool,
      searchPerformed: PropTypes.bool,
    }),
    selectedOptions: PropTypes.arrayOf(OptionShape).isRequired,
    dispatchUpdateOptionsToComparison: PropTypes.func.isRequired,
    dispatchResetMissingAppInfoError: PropTypes.func,
    toggles: PropTypes.object,
  }).isRequired,
};

export default ProductsTable;
