import { buildStandardSegments, getStreetTypeByAbbr, joinSegments } from '../../../../../utils/address';
import { ADDRESS_TYPE } from './constants';

export default ({
  id: addressId, source: {
    state, postcode, suburb: city, street, streetNumber, streetNumberFrom, streetNumberFromPrefix,
    streetNumberFromSuffix, streetNumberToPrefix, streetNumberTo, streetNumberToSuffix, streetType: streetTypeAbbr,
    streetSuffix, unitNumber, levelNumber, buildingName,
  },
}) => {
  const commonFields = {
    addressId,
    postcode,
    city,
    state,
    countryCode: 'AU',
  };

  const streetNoFrom = joinSegments([streetNumberFromPrefix, streetNumberFrom, streetNumberFromSuffix], '');
  const streetNoTo = joinSegments([streetNumberToPrefix, streetNumberTo, streetNumberToSuffix], '');
  const streetNo = joinSegments([streetNoFrom, streetNoTo], '-');
  const streetNoOrLotNo = streetNo.length ? streetNo : streetNumber;

  const streetType = getStreetTypeByAbbr(streetTypeAbbr) || streetTypeAbbr;

  return streetSuffix ? ({
    ...commonFields,
    addressType: ADDRESS_TYPE.NON_STANDARD,
    nonStdAddress: joinSegments(buildStandardSegments({
      buildingName,
      floorNo: levelNumber,
      unitNo: unitNumber,
      street: {
        no: streetNoOrLotNo,
        name: street,
        type: joinSegments([streetType, streetSuffix], ' '),
      },
    })),
  }) : ({
    ...commonFields,
    addressType: ADDRESS_TYPE.STANDARD,
    buildingName,
    floorNo: levelNumber,
    unitNo: unitNumber,
    street: {
      no: streetNoOrLotNo,
      name: street,
      type: streetType,
    },
  });
};
