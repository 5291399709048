import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
 change, Field, FieldArray, formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import Icon from 'shared/components/Icon/Icon';
import Employment from './Employment';
import styles from './EmploymentSection.module.scss';
import { convertNetIncomeToGrossIncome, toAnnualValue } from '../../../../../utils';
import { FETCHING_STATUS } from '../../../../../../constants';
import SectionTitle from '../../shared/SectionTitle';
import { formatAmountWithDefault } from '../../../../../../utils/formatters';
import { status } from './constants';

const selector = formValueSelector('employmentsForm');

const EmploymentFields = (employmentFields) => {
  const {
 fields, hasPrimary, removeOrder, hasEditPermission,
} = employmentFields;
  const onRemove = index => () => {
    fields.remove(index);
    removeOrder(index);
  };
  const employments = fields.map((employment, index) => (
    <Employment
      key={employment}
      hasPrimary={hasPrimary}
      fieldNamePrefix={employment}
      onRemove={onRemove}
      index={index}
      hasEditPermission={hasEditPermission}
    />
  ));

  return (
    <>
      {employmentFields.order.map(index => employments[index])}
      {employmentFields.hasEditPermission
      && (
        <div className={styles.buttonGroup}>
          <SecondaryButton
            className={styles.button}
            onClick={() => {
              employmentFields.fields.push({
                employmentType: 'PAYE',
                status: employmentFields.hasPrimary ? status.Secondary : status.Primary,
                employmentIncomes: [{}],
              });
              employmentFields.appendOrder();
            }}
          >
            <span>Add current employment</span><Icon name="add" size="18" />
          </SecondaryButton>
          <SecondaryButton
            className={styles.button}
            onClick={() => {
              employmentFields.fields.push({
                employmentType: 'PAYE',
                status: status.Previous,
                employmentIncomes: [{}],
              });
              employmentFields.appendOrder();
            }}
          >
            <span>Add previous employment</span><Icon name="add" size="18" />
          </SecondaryButton>
        </div>
      )}
    </>
  );
};

const formatEmploymentIncomes = (employments) => {
  const allEmploymentIncomes = employments?.filter(employment => !isEmpty(employment.employmentIncomes))
    .flatMap(employment => employment.employmentIncomes)
    .filter(income => !isEmpty(income.periodUnit))
    .map(income => {
      const annualValue = toAnnualValue(income.periodUnit, income.value);
      if (income?.type === 'NetRegularOvertime' || income?.type === 'NetSalary') {
        return convertNetIncomeToGrossIncome(annualValue);
      }
      return annualValue;
    }) ?? [];
  return [
    {
      label: 'Annual employment income (gross)',
      displayValue: formatAmountWithDefault(sum(allEmploymentIncomes)),
      span: 2,
      column: -3,
    },
  ];
};

const EmploymentSection = ({
  fieldNamePrefix, hasEditPermission, personName, employmentsOrder, appendOrder, removeOrder, hasPrimary,
}) => {
  const employmentsHeader = (
    <Field
      name={`${fieldNamePrefix}.employments`}
      component={SectionTitle}
      title={personName}
      format={formatEmploymentIncomes}
    />
  );

  const fields = useMemo(() => {
    const append = () => appendOrder(employmentsOrder);
    const remove = (index) => removeOrder(employmentsOrder, index);
    return (
      <FieldArray
        name={`${fieldNamePrefix}.employments`}
        component={EmploymentFields}
        hasEditPermission={hasEditPermission}
        rerenderOnEveryChange={false}
        order={employmentsOrder}
        appendOrder={append}
        removeOrder={remove}
        hasPrimary={hasPrimary}
      />
    );
  }, [
    fieldNamePrefix,
    hasPrimary,
    hasEditPermission,
    employmentsOrder,
    appendOrder,
    removeOrder,
  ]);
  return (
    <div className={styles.wrapper}>
      <ShowMore
        name={employmentsHeader}
        showMoreClass={styles.showMore}
        headerClassName={styles.actionWrap}
        iconSize="25"
      >
        {fields}
      </ShowMore>
    </div>
  );
};

EmploymentSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  employmentsOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
  appendOrder: PropTypes.func.isRequired,
  removeOrder: PropTypes.func.isRequired,
  hasPrimary: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

export default connect(
  (state, props) => ({
    personName: [
      selector(state, `${props.fieldNamePrefix}.surname`),
      selector(state, `${props.fieldNamePrefix}.firstName`),
    ].filter(Boolean).join(', '),
    employmentsOrder: selector(state, `${props.fieldNamePrefix}.employmentsOrder`) || [],
    hasPrimary: (selector(state, `${props.fieldNamePrefix}.employments`) || [])
      .some(employment => employment.status === status.Primary),
    isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START
    && get(state, 'application.employmentsUpdatingStatus') === FETCHING_STATUS.SUCCESS,
  }), (dispatch, props) => ({
    appendOrder: currentOrder =>
      dispatch(change(
        'employmentsForm',
        `${props.fieldNamePrefix}.employmentsOrder`,
        [...currentOrder, currentOrder.length],
      )),
    removeOrder: (currentOrder, index) =>
      dispatch(change(
        'employmentsForm',
        `${props.fieldNamePrefix}.employmentsOrder`,
        currentOrder.filter((value, i) => i !== index).map(value => (value > index ? value - 1 : value)),
      )),
  }),
)(EmploymentSection);
