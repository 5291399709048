import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import { toRatePercentage } from 'shared/formatterUtils';
import Note from 'shared/components/Note';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { formatCurrency } from '../../../../../../utils/formatters';
import { isStart } from '../../../../../../utils/actionStatusUtil';
import { FETCHING_STATUS } from '../../../../../../constants';
import { getHasTopUp } from '../../../redux/selectors/liabilities';

const getTooltipCode = (userGuidanceCodes) => filter(userGuidanceCodes).join('+');

const FundingPosition = (
  {
    totalCosts,
    fundsAvailable,
    totalSecurity,
    baseLvr,
    totalLvr,
    fetchingStatus,
    capitaliseLmi,
    lmi,
    baseLoanAmount,
    userGuidanceCodes,
    topUpAmount,
    hasTopUp,
  },
) => {
  const themeContext = useContext(ThemeContext);

  const combinedLoanAmountBeforeLmi = baseLoanAmount + topUpAmount;
  const totalLoanAmount = capitaliseLmi
    ? combinedLoanAmountBeforeLmi + lmi
    : combinedLoanAmountBeforeLmi;

  const {
    costToPurchases = [],
    constructions = [],
    refinanceAndDebtConsolidation,
    applicationCosts,
    contributions,
    loanAmount,
    otherCosts,
    capitalisedLmi,
  } = userGuidanceCodes;

  const baseLvrHint = isNil(baseLvr)
    ? 'Base LVR is based on the combined loan amount before LMI of $--'
    : `Base LVR is based on the combined loan amount before LMI of ${formatCurrency(combinedLoanAmountBeforeLmi, { precision: 2 })}`;

  const totalCostTooltip = getTooltipCode([
    ...costToPurchases,
    refinanceAndDebtConsolidation,
    ...constructions,
    otherCosts,
    applicationCosts,
  ]);

  return (
    <div
      className={classNames(styles.fundingPosition, {
        [styles.calculating]: isStart(fetchingStatus),
      })}
    >
      <h3 className={styles.title}>Funding position</h3>
      <Note
        showSideBar
        sideBarColor={themeContext.palette.graphicTeal}
        className={styles.noteItem}
      >
        <div>
          <span
            className={classNames('hint', styles.tooltip)}
            hint={`Total costs (${totalCostTooltip})`}
          >
            TOTAL COSTS
          </span>
          <span>{formatCurrency(totalCosts, { precision: 2 })}</span>
        </div>
        <div>
          <span
            className={classNames('hint', styles.tooltip)}
            hint={`Total funds available (${getTooltipCode([contributions, loanAmount, capitalisedLmi])})`}
          >
            TOTAL FUNDS AVAILABLE
          </span>
          <span>{formatCurrency(fundsAvailable, { precision: 2 })}</span>
        </div>
        <div className={styles.noteSummary}>
          <span>Surplus/(shortfall)</span>
          <span>{formatCurrency(fundsAvailable - totalCosts, { precision: 2 })}</span>
        </div>
      </Note>
      <Note
        showSideBar
        sideBarColor={themeContext.palette.graphicBlue}
        className={styles.noteItem}
      >
        <div>
          <span
            className={classNames('hint', styles.tooltip)}
            hint={`Base loan amount (${loanAmount})`}
          >
            BASE LOAN AMOUNT
          </span>
          <span>{formatCurrency(baseLoanAmount, { precision: 2 })}</span>
        </div>
        <div>
          <span>{`CAPITALISED LMI (${capitalisedLmi})`}</span>
          <span>{capitaliseLmi ? formatCurrency(lmi, { precision: 2 }) : 'N/A'}</span>
        </div>
        { hasTopUp && (
        <div>
          <span>LOAN TO BE TOPPED UP</span>
          <span>{formatCurrency(topUpAmount, { precision: 2 })} </span>
        </div>
        )}
        <div className={styles.noteSummary}>
          <span>
            { hasTopUp ? 'Total combined loan amount' : 'Total loan amount' }
          </span>
          <span>{formatCurrency(totalLoanAmount, { precision: 2 })}</span>
        </div>
      </Note>
      <Note
        showSideBar
        sideBarColor={themeContext.palette.graphicYellow}
        className={styles.noteItem}
      >
        <div>
          <span>TOTAL SECURITY VALUE</span>
          <span>{formatCurrency(totalSecurity, { precision: 2 })}</span>
        </div>
        <div>
          <span
            className={classNames({ hint: hasTopUp, [styles.tooltip]: hasTopUp })}
            hint={baseLvrHint}
          >
            BASE LVR
          </span>
          <span>{toRatePercentage(baseLvr)}</span>
        </div>
        <div>
          <span
            className={classNames('hint', styles.tooltip)}
            hint="Total LVR includes LMI"
          >
            TOTAL LVR
          </span>
          <span>{toRatePercentage(totalLvr)}</span>
        </div>
      </Note>
    </div>
  );
};

FundingPosition.propTypes = {
  totalCosts: PropTypes.number,
  fundsAvailable: PropTypes.number,
  lmi: PropTypes.number,
  totalSecurity: PropTypes.number,
  baseLvr: PropTypes.number,
  totalLvr: PropTypes.number,
  baseLoanAmount: PropTypes.number,
  capitaliseLmi: PropTypes.bool,
  topUpAmount: PropTypes.number,
  hasTopUp: PropTypes.bool.isRequired,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  userGuidanceCodes: PropTypes.shape({
    costToPurchases: PropTypes.arrayOf(PropTypes.string),
    constructions: PropTypes.arrayOf(PropTypes.string),
    refinanceAndDebtConsolidation: PropTypes.string,
    applicationCosts: PropTypes.string,
    contributions: PropTypes.string,
    loanAmount: PropTypes.string,
    capitalisedLmi: PropTypes.string,
    otherCosts: PropTypes.string,
  }).isRequired,
};

FundingPosition.defaultProps = {
  baseLvr: undefined,
  totalLvr: undefined,
  totalCosts: undefined,
  fundsAvailable: undefined,
  lmi: undefined,
  totalSecurity: undefined,
  baseLoanAmount: undefined,
  capitaliseLmi: undefined,
  topUpAmount: undefined,
};

const mapStateToProps = state => ({
    ...get(state, 'application.fundingWorksheetCalculation.fundingPositions'),
    hasTopUp: getHasTopUp(state),
});

export default connect(mapStateToProps)(FundingPosition);
