import get from 'lodash/get';
import max from 'lodash/max';
import graphQL from 'MyBusiness/components/Commissions/commissionsGraphQL';
import {
  getRequestIdFromAction,
  ifValidRequestId,
  startRequest,
} from 'redux/requestIds';

export const ACTIONS = {
  GET_INSURANCE_COMMISSIONS_START: 'MY_BUSINESS:COMMISSIONS:GET_INSURANCE_COMMISSIONS_START',
  GET_INSURANCE_COMMISSIONS_SUCCESS: 'MY_BUSINESS:COMMISSIONS:GET_INSURANCE_COMMISSIONS_SUCCESS',
  GET_INSURANCE_COMMISSIONS_ERRORS: 'MY_BUSINESS:COMMISSIONS:GET_INSURANCE_COMMISSIONS_ERRORS',
};

const getInsuranceCommissionsStart = () => ({ type: ACTIONS.GET_INSURANCE_COMMISSIONS_START });
const getInsuranceCommissionsSuccess = insuranceCommissions =>
  ({ type: ACTIONS.GET_INSURANCE_COMMISSIONS_SUCCESS, insuranceCommissions });
const getInsuranceCommissionsErrors = errors => ({ type: ACTIONS.GET_INSURANCE_COMMISSIONS_ERRORS, errors });

export const getCommissions = financialYear => async (dispatch, getState) => {
  const businessId = get(getState(), 'business.selectedBusiness.id');
  if (!businessId) {
    return;
  }

  dispatch(getInsuranceCommissionsStart());
  const startRequestAction = startRequest(ACTIONS.GET_INSURANCE_COMMISSIONS_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const results = await graphQL.getInsuranceCommissions(businessId, financialYear);
    ifValidRequestId(getState().requestIds, ACTIONS.GET_INSURANCE_COMMISSIONS_START, requestId, () => {
      dispatch(getInsuranceCommissionsSuccess(results.data.insuranceCommissions));
    });
  } catch (error) {
    ifValidRequestId(getState().requestIds, ACTIONS.GET_INSURANCE_COMMISSIONS_START, requestId, () => {
      dispatch(getInsuranceCommissionsErrors(error));
    });
  }
};

export default (state = { isLoading: false, insuranceCommissions: [] }, action) => {
  switch (action.type) {
    case ACTIONS.GET_INSURANCE_COMMISSIONS_START:
      return {
        ...state, isLoading: true, errors: null, insuranceCommissions: [],
      };

    case ACTIONS.GET_INSURANCE_COMMISSIONS_SUCCESS: {
      const { insuranceCommissions } = action;
      const amounts = insuranceCommissions.map(commission => commission.amounts);

      return {
        ...state,
        insuranceCommissions,
        processingMonthsLength: max(amounts.map(ua => ua.length)),
        isLoading: false,
      };
    }

    case ACTIONS.GET_INSURANCE_COMMISSIONS_ERRORS:
      return { ...state, errors: action.errors, isLoading: false };

    default:
      return state;
  }
};
