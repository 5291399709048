import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Chart } from 'shared/components/Chart';
import styles from './styles.module.scss';
import { getMonthName } from '../utils';

export const MessageChart = ({ message, data, series }) => (
  <div className={cx(styles.noDataSection, styles.applicationsStatisticsSection)}>
    <div className={styles.noDataMessageContainer}>
      <div className={styles.noDataMessage}>
        {message}
      </div>
    </div>
    <Chart
      height={400}
      data={data}
      series={series}
      valueAxis={{
        hide: true,
      }}
      argumentLabelFormatter={getMonthName}
    />
  </div>
);

MessageChart.propTypes = {
  message: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
};
