import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import ThirdPartyList from './ThirdPartyList';

const ThirdPartySection = ({
 hasEditPermission, name, isSearchedThirdParty, validateValues,
}) => (
  <div>
    <FieldArray
      name={name}
      component={ThirdPartyList}
      hasEditPermission={hasEditPermission}
      validate={validateValues}
      isSearchedThirdParty={isSearchedThirdParty}
    />
  </div>
);

ThirdPartySection.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  isSearchedThirdParty: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  validateValues: PropTypes.func.isRequired,
};

export default ThirdPartySection;
