import React from 'react';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import { FETCHING_STATUS } from '../../../../constants';
import styles from './styles.module.scss';
import { MessageChart } from './MessageChart';
import { ReportChart } from './ReportChart';
import { generateDataForWindow } from './utils';
import { viewShape } from '../../shapes';

export const Report = React.memo(({
                                    views,
                                    fetchingStatus,
                                    title,
                                    className,
                                    ...others
                                  }) =>
  (
    <div className={classNames(styles.applicationsStatisticsSection, className)}>
      <h3>{title}</h3>
      {fetchingStatus === FETCHING_STATUS.ERROR && (
        <MessageChart
          series={[{
            name: '',
            key: 'fake',
            color: 'transparent',
          }]}
          message="The information is unavailable at the moment, it could be because of a temporary issue. Please try again in a few minutes."
          data={generateDataForWindow(get(views, [0, 'displayWindow'], null), { fake: 0 })}
        />
      )}
      {fetchingStatus === FETCHING_STATUS.SUCCESS && (
        <ReportChart
          {...others}
          views={views}
        />
      )}
      {fetchingStatus === FETCHING_STATUS.START && (
        <div className={styles.spinnerContainer}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  ));

Report.propTypes = {
  className: PropTypes.string,
  views: PropTypes.arrayOf(viewShape),
  title: PropTypes.string.isRequired,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
};

Report.defaultProps = {
  className: undefined,
  views: [],
};
