import PropTypes from 'prop-types';

export default PropTypes.shape({
  filterPlaceholder: PropTypes.string,
  contains: PropTypes.string,
  notContains: PropTypes.string,
  startsWith: PropTypes.string,
  endsWith: PropTypes.string,
  equal: PropTypes.string,
  notEqual: PropTypes.string,
  greaterThan: PropTypes.string,
  greaterThanOrEqual: PropTypes.string,
  lessThan: PropTypes.string,
  lessThanOrEqual: PropTypes.string,
});
