import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BusinessShape from 'shapes/BusinessShape';
import { syncBusinessId } from 'redux/business/business';

const withSyncBusinessId = (Component, redirectPath) => {
  class HOC extends PureComponent {
    componentDidMount() {
      this.syncBusinessId(true, null);
    }

    componentDidUpdate(prevProps) {
      const { selectedBusiness: prevBusiness } = prevProps;
      this.syncBusinessId(false, prevBusiness);
    }

    syncBusinessId(shouldGetBusinessIdFromURL, prevBusiness) {
      const {
        location,
        history,
        dispatchSyncBusinessId,
      } = this.props;
      dispatchSyncBusinessId(location, history, shouldGetBusinessIdFromURL, prevBusiness);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  HOC.defaultProps = {
    selectedBusiness: null,
  };

  HOC.propTypes = {
    selectedBusiness: BusinessShape,
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    dispatchSyncBusinessId: PropTypes.func.isRequired,
  };

  const mapStateToProps = state => ({
    selectedBusiness: state.business.selectedBusiness,
  });

  const mapDispatchToProps = dispatch => ({
    dispatchSyncBusinessId: (
      location,
      history,
      shouldGetBusinessIdFromURL,
      prevBusiness,
    ) =>
      dispatch(syncBusinessId(
        location,
        history,
        shouldGetBusinessIdFromURL,
        prevBusiness,
        redirectPath,
      )),
  });

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(HOC));
};

export default withSyncBusinessId;
