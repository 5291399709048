import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import CustomProductModal from './CustomProductModal';

const CustomProduct = ({
 lenders, loansCriteria, hasCustomProduct, totalCostTerm, repaymentFrequency,
}) => {
  const [isOpen, setOpened] = useState(false);
  const toggleCustomProductModal = () => setOpened(!isOpen);

  return (
    <>
      <SecondaryButton
        disabled={hasCustomProduct}
        onClick={toggleCustomProductModal}
      >
        Add custom product
      </SecondaryButton>

      <CustomProductModal
        isOpen={isOpen}
        toggle={toggleCustomProductModal}
        lenders={lenders}
        loansCriteria={loansCriteria}
        totalCostTerm={totalCostTerm}
        repaymentFrequency={repaymentFrequency}
      />
    </>
  );
};

CustomProduct.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
  })).isRequired,
  loansCriteria: PropTypes.array.isRequired,
  hasCustomProduct: PropTypes.bool.isRequired,
  totalCostTerm: PropTypes.number.isRequired,
  repaymentFrequency: PropTypes.string.isRequired,
};

export default CustomProduct;
