import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SecondaryButton from '../../../shared/components/Buttons/SecondaryButton';
import ConnectedComparisonPreparerModal from '../ComparisonPreparerModal/ConnectedComparisonPreparerModal';

const DownloadComparison = React.memo(({
  downloadPdf, hasError, isLoading, isDownloading, businessCount, disabled, className,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  if (hasError || isLoading) {
    return <div />;
  }
  const handleClick = () => {
    if (businessCount > 0) {
      setModalOpen(true);
    } else {
      downloadPdf();
    }
  };

  return (
    <>
      <SecondaryButton
        disabled={(isDownloading && businessCount === 0) || disabled}
        onClick={handleClick}
        className={className}
      >
        Download as PDF
      </SecondaryButton>
      {businessCount > 0 && (
      <ConnectedComparisonPreparerModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
      />
      )}
    </>
  );
});

DownloadComparison.defaultProps = {
  hasError: false,
  isLoading: false,
  isDownloading: false,
  disabled: false,
  className: '',
};

DownloadComparison.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isDownloading: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
  businessCount: PropTypes.number.isRequired,
};

export default DownloadComparison;
