import React, { useState } from 'react';
import compact from 'lodash/compact';
import LeftNavBar from 'shared/components/LeftNavBar/LeftNavBar';
import PropTypes from 'prop-types';
import AuditTrail from 'Loans/components/LoanDetails/AuditTrail';
import styles from './index.module.scss';
import Loan from './Loan';
import Contacts from './Contacts';
import Commissions from './Commissions';
import LoanShape from '../../../shapes/LoanShape';

const NAV_TAB = {
  LOAN: 'Loan',
  COMMISSIONS: 'Commissions',
  CONTACTS: 'Contacts',
  AUDIT_TRAIL: 'Activities',
};

const getTabs = loan => compact([
    NAV_TAB.LOAN,
    NAV_TAB.CONTACTS,
    loan.commissions ? NAV_TAB.COMMISSIONS : null,
    NAV_TAB.AUDIT_TRAIL,
  ]);

const hiddenStyle = { display: 'none' };
const selectedStyle = { display: 'block' };

const LoanDetailsContent = ({ loan, isLoading }) => {
  const [activeTab, setActiveTab] = useState(NAV_TAB.LOAN);
  const tabs = getTabs(loan);

  const getStyle = tabName => (activeTab === tabName ? selectedStyle : hiddenStyle);

  return (
    <div className={styles.contentNavWrapper}>
      <LeftNavBar
        className={styles.leftNav}
        setActiveTab={setActiveTab}
        tabs={tabs}
        activeTab={activeTab}
        title="Details"
      />
      <div className={styles.appContent}>
        <Loan style={getStyle(NAV_TAB.LOAN)} disabled={isLoading} />
        <Contacts style={getStyle(NAV_TAB.CONTACTS)} />
        { loan.commissions ? <Commissions style={getStyle(NAV_TAB.COMMISSIONS)} disabled={isLoading} /> : null}
        {activeTab === NAV_TAB.AUDIT_TRAIL && <AuditTrail loan={loan} />}
      </div>
    </div>
  );
};

LoanDetailsContent.propTypes = {
  loan: LoanShape.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LoanDetailsContent;
