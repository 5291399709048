import { calculateVariantUpfrontFee } from 'shared/productUtils';
import { fieldsWithoutDefaultValue } from './constants';
import { ACTIONS } from './variantModificationActions';

export const startEditing = (variant, fieldName, optionIndex, splitIndex) => ({
    type: ACTIONS.VARIANT_MODIFICATION_EDIT_START,
    currentModification: {
      variant, fieldName, optionIndex, splitIndex,
    },
});

export const cancelEditing = () => ({ type: ACTIONS.VARIANT_MODIFICATION_EDIT_CANCEL });

export const updateModificationValue = (fieldName, value) => (dispatch) => {
  const valueWithDefault = value || (fieldsWithoutDefaultValue.includes(fieldName) ? undefined : 0);
  dispatch({ type: ACTIONS.VARIANT_MODIFICATION_UPDATE_VALUE, fieldName, value: valueWithDefault });
};
export const processVariantModificationEditStart = (state, action) => ({
  ...state,
  isEditing: true,
  currentModification: action.currentModification,
  hasError: false,
});
export const processVariantMoficationEditCancel = state => ({
  ...state,
  isEditing: false,
  currentModification: {
    variant: {},
    fieldName: '',
    optionIndex: 0,
    splitIndex: 0,
  },
});
export const processVariantModificationEditSubmit = state => ({
  ...state,
  isLoading: true,
});
export const processVariantModificationEditSucess = state => ({
  ...state,
  isEditing: false,
  isLoading: false,
});
export const processVariantModificationUpdateValue = (state, action) => {
  const variant = {
    ...state.currentModification.variant,
  };
  variant[action.fieldName] = action.value;
  variant.upfrontFee = calculateVariantUpfrontFee(variant);
  return {
    ...state,
    currentModification: {
      ...state.currentModification,
      variant,
    },
  };
};

export const processVariantModificationEditFail = state => ({
  ...state,
  hasError: true,
  isLoading: false,
});

export const initialState = {
  isLoading: false,
  isEditing: false,
  currentModification: {
    variant: {},
    fieldName: '',
    optionIndex: 0,
    splitIndex: 0,
  },
  hasError: false,
};
