import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';

export const ACTIONS = {
  SET_PROFILE: 'SET_PROFILE',
};

export const setProfile = profile => ({ type: ACTIONS.SET_PROFILE, profile });

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SET_PROFILE: {
      if (!action.profile) {
        return {};
      }
      const username = [action.profile.firstName, action.profile.surname].filter(n => n).join(' ');
      const states = action.profile.states || [];
      const businesses = orderBy(action.profile.businesses, [business => business.displayName.toLowerCase()]) || [];
      const toggles = action.profile.toggles || {};

      return {
        ...state,
        username,
        states,
        businesses,
        toggles,
        ...pick(action.profile, 'email', 'officePhone', 'mobilePhone', 'userId', 'permissions', 'brokerCodes', 'groupOffice', 'admin'),
      };
    }
    default:
      return state;
  }
};
