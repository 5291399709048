import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.softblue.compact.css';
import 'dx-custom.scss';
import styles from './EssentialDates.module.scss';
import {
  applicationDueDates,
  applicationStatus as applicationStatusMap,
  essentialDateDisplayStatus,
} from '../../../../constants/applicationStatus';
import StatusRow from './StatusRow/StatusRow';
import { ToastContext } from '../../../../../shared/components/ToastProvider/ToastProvider';
import DueDateRow from './DueDateRow/DueDateRow';
import { MEDIA_TYPES } from '../../../../../constants/media';
import useMediaType from '../../../../../shared/hooks/useMediaType';

const {
  PRE_SUBMISSION,
  DECLINED,
  WITHDRAWN,
  DRAFT,
} = applicationStatusMap;

const isDeclinedOrWithdrawn = status => (
  isEqual(status, DECLINED) || isEqual(status, WITHDRAWN)
);

const getDueDateTitle = (mediaType) => (mediaType === MEDIA_TYPES.DESKTOP ? <div /> : <div>Application due dates</div>);

const EssentialDatesPanel = ({
                               actionTimelineMap,
                               applyOnlineTimelineMap,
                               onRecordReasonAndSave,
                               onConfirmAndSettle,
                               onUpdateStatus,
                               launchpadCreated,
                               applicationSettled,
                               hasApplicationEditPermission,
                               hasAdditionalEditPermission,
                               disabled,
                               onUpdateDueDateTime,
                               dueDates,
                             }) => {
  const { toast: showToast } = useContext(ToastContext);
  const nonEditableStatus = useMemo(() => (launchpadCreated
    ? [PRE_SUBMISSION]
    : [PRE_SUBMISSION, DRAFT]), [launchpadCreated]);
  const applicationCreateTime = launchpadCreated
    ? get(actionTimelineMap, ['PRE_SUBMISSION', 'rawDateTime'])
    : get(actionTimelineMap, ['DRAFT', 'rawDateTime']);

  const handleSettle = useCallback(async () => {
    await onConfirmAndSettle();
    showToast('Congratulations, your application has been settled.', { type: 'success' });
  }, [onConfirmAndSettle, showToast]);

  const dateHasBeenCleared = 'The date has been cleared.';
  const newDateHasBeenSaved = 'Success. The new date has been saved.';

  const mediaType = useMediaType();
  const dueDateTitle = getDueDateTitle(mediaType);

  const handleUpdateDueDateTime = useCallback(async (dueDateKey, { dueDateTime }) => {
    await onUpdateDueDateTime({ dueDateKey, dueDateTime });
    if (!dueDateTime) {
      showToast(dateHasBeenCleared, { type: 'success' });
    } else {
      showToast(newDateHasBeenSaved, { type: 'success' });
    }
  }, [onUpdateDueDateTime, showToast]);

  const handleUpdateStatusDateTime = useCallback(async (milestoneType, { reason, statusDateTime }) => {
    if (isDeclinedOrWithdrawn(milestoneType) && !reason && statusDateTime != null) {
      await onRecordReasonAndSave({ reason, statusDateTime, milestoneType });
      showToast('Success. The new date and reason has been saved.', { type: 'success' });
    } else {
      await onUpdateStatus({ statusDateTime, milestoneType, reason });
      if (!statusDateTime) {
        showToast(dateHasBeenCleared, { type: 'success' });
      } else {
        showToast(newDateHasBeenSaved, { type: 'success' });
      }
    }
  }, [onUpdateStatus, onRecordReasonAndSave, showToast]);

  const handleUpdateReason = useCallback(async (milestoneType, { reason, statusDateTime }) => {
    const result = await onRecordReasonAndSave({ reason, milestoneType, statusDateTime });
    showToast('Success. The new reason has been saved.', { type: 'success' });
    return result;
  }, [onRecordReasonAndSave, showToast]);

  return (
    <div className={styles.essentialDates}>
      <div className={styles.essentialDatesContent}>
        <div className={styles.statusHeader}>
          <div className={styles.statusHeaderCell}>Stage</div>
          <div className={styles.statusHeaderCell}>Manual processing dates</div>
          <div className={styles.statusHeaderCell}>ApplyOnline dates</div>
        </div>
        {keys(essentialDateDisplayStatus).map(statusKey => {
          const isEditable = (hasApplicationEditPermission || hasAdditionalEditPermission)
            && !applicationSettled
            && !nonEditableStatus.includes(statusKey);
          const statusDateTime = get(actionTimelineMap, [statusKey, 'rawDateTime']);
          const applyOnlineStatusDateTime = get(applyOnlineTimelineMap, [statusKey, 'statusDateTime']);
          const reason = get(actionTimelineMap, [statusKey, 'reason']);
          return (
            <StatusRow
              key={statusKey}
              statusKey={statusKey}
              statusDateTime={statusDateTime}
              applyOnlineStatusDateTime={applyOnlineStatusDateTime}
              reason={reason}
              disabled={disabled}
              editable={isEditable}
              onUpdateStatusDateTime={handleUpdateStatusDateTime}
              onUpdateReason={handleUpdateReason}
              onSettle={handleSettle}
            />
          );
        })}
      </div>
      <div className={styles.dueDateTitle}>
        {dueDateTitle}
      </div>
      <div className={styles.essentialDatesContent}>
        <div className={styles.statusHeader}>
          <div className={styles.statusHeaderCell}>Application due dates</div>
          <div className={styles.statusHeaderCell}>Date</div>
        </div>
        {keys(applicationDueDates).map(dueDateKey => {
          const isEditable = hasApplicationEditPermission && !applicationSettled;
          return (
            <DueDateRow
              key={dueDateKey}
              dueDateKey={dueDateKey}
              date={dueDates[dueDateKey]}
              disabled={disabled}
              editable={isEditable}
              onUpdateDueDateTime={handleUpdateDueDateTime}
              applicationCreateTime={applicationCreateTime}
            />
          );
        })}
      </div>
      <p className={styles.notes}>All times and dates shown in your local time</p>
    </div>
  );
};

EssentialDatesPanel.propTypes = {
  actionTimelineMap: PropTypes.object.isRequired,
  applyOnlineTimelineMap: PropTypes.object.isRequired,
  onRecordReasonAndSave: PropTypes.func.isRequired,
  onConfirmAndSettle: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateDueDateTime: PropTypes.func.isRequired,
  launchpadCreated: PropTypes.bool.isRequired,
  applicationSettled: PropTypes.bool.isRequired,
  hasApplicationEditPermission: PropTypes.bool.isRequired,
  hasAdditionalEditPermission: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  dueDates: PropTypes.object,
};

EssentialDatesPanel.defaultProps = {
  dueDates: undefined,
};
export default EssentialDatesPanel;
