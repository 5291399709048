import React from 'react';
import PropTypes from 'prop-types';
import styles from './ApplicationCopyContent.module.scss';
import ExternalLink from '../../../../../../../../../shared/components/Links/ExternalLink';

const ApplicationCopyContent = ({
                                      isCopy,
                                      applicationIdCopiedFrom,
                                      applicationIdCopiedTo,
                                      loanType,
                                      adviserFirstName,
                                      adviserSurname,
                                      applicantNames,
                                    }) => {
  const title = isCopy ? <div>Application created - <ExternalLink to={`/applications/${applicationIdCopiedFrom}`} className={styles.link}>View copied application</ExternalLink></div>
           : <div>Application copied - <ExternalLink to={`/applications/${applicationIdCopiedTo}`} className={styles.link}>View the copy</ExternalLink></div>;

  return (

    <ul className={styles.contentWrapper}>
      <li className={styles.title}>{title}</li>
      <li>Applicant/s: {applicantNames?.join('; ')}</li>
      <li>Adviser: {adviserSurname}, {adviserFirstName}</li>
      <li>Loan type: {loanType}</li>
    </ul>
  );
};

ApplicationCopyContent.defaultProps = {

};
ApplicationCopyContent.propTypes = {
  isCopy: PropTypes.bool.isRequired,
  applicationIdCopiedFrom: PropTypes.string.isRequired,
  applicationIdCopiedTo: PropTypes.string.isRequired,
  loanType: PropTypes.string.isRequired,
  adviserFirstName: PropTypes.string.isRequired,
  adviserSurname: PropTypes.string.isRequired,
  applicantNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ApplicationCopyContent;
