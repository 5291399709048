import React from 'react';
import PropTypes from 'prop-types';
import { toTimeStrWithSecond } from 'utils/datetime';
import BasicRichContent from '../BasicRichContent';

const SomasChangeContent = ({ changes, summary }) => (
  <BasicRichContent
    type="FINALISE"
    content={summary}
    subContent={changes.map(c => `${c.type} SOMA generated ${toTimeStrWithSecond(c.generatedDate)} ${c.displayValue}`)}
  />
);

SomasChangeContent.propTypes = {
  changes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    generatedDate: PropTypes.string.isRequired,
    displayValue: PropTypes.string,
  })).isRequired,
  summary: PropTypes.string.isRequired,
};

export default SomasChangeContent;
