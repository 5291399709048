import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { toDollarAmountWithDecimal } from 'shared/formatterUtils';
import InternalLink from 'shared/components/Links/InternalLink';
import styles from './AuditTrailItem.module.scss';

const renderSettledAmount = (changes) => {
  const settledAmount = find(changes, change => change.fieldName === 'settledAmount');
  return settledAmount && (
    <li key="settledAmount">
      <span className={styles.fieldName}>Settled amount </span>
      has changed from {toDollarAmountWithDecimal(settledAmount.oldValue)} to
      <span className={styles.newValue}> {toDollarAmountWithDecimal(settledAmount.newValue)}</span>
    </li>
  );
};

const renderComplianceText = (changes) => {
  const hasDeclaredChange = find(changes, change => change.fieldName === 'hasDeclared');
  const declared = hasDeclaredChange && hasDeclaredChange.newValue === 'true';

  return (
    <li>
      Split {declared ? null : 'not '}created by the business - Launchpad split compliance form{' '}
      <span className={styles.newValue}>{declared ? 'has been' : 'has not been'}</span> captured in the client drive
    </li>
  );
};

const getSplitToLoanAccountNumbers = (changes) => {
  const splitToChanges = find(changes, change => change.fieldName === 'splitTo');
  if (!splitToChanges) {
    return [];
  }
  return JSON.parse(splitToChanges.newValue);
};

const LoanSplitAuditTrailItem = ({ changes }) => (
  <ul className={styles.listWrapper}>
    {renderSettledAmount(changes)}
    <li key="newLoans">NEW LOANS</li>
    {getSplitToLoanAccountNumbers(changes)
      .map(loan =>
        <li key={loan.id}><InternalLink to={`/loans/${loan.id}`}>{loan.accountNumber}</InternalLink></li>)}
    {renderComplianceText(changes)}
  </ul>
);

LoanSplitAuditTrailItem.defaultProps = {
  changes: [],
};

LoanSplitAuditTrailItem.propTypes = {
  changes: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  })),
};

export default LoanSplitAuditTrailItem;
