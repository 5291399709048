import { getTargetChangedValue } from '../../../../../../constants/utils';
import { TYPES } from '../../../../../../constants/activityLogTypes';
import { getBasicLogInfo } from './utils';

const surveySentSuccessLogTransformer = (origin) => {
  const email = getTargetChangedValue(origin, 'surveySentTo');
  return {
    ...getBasicLogInfo(origin),
    content: `A settlement survey has been sent to the nominated receiver ${ email}`,
    operator: 'Launchpad',
    type: TYPES.SURVEY,
  };
};

export default surveySentSuccessLogTransformer;
