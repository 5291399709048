import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import { FETCHING_STATUS } from '../../../../constants';
import { perspectivesForExpectedSettlement as perspectives } from '../viewPerspectives';
import { loansByMonthToView } from '../../utils/loansByMonthToView';
import { ExpectedSettlementReport } from './ExpectedSettlementReport';
import { generateContextFromLoansAndState } from '../../utils';

const expectedSettlementReportSelector = createSelector(
  state => ({
    report: state.reports.expectedSettlementReport,
    lenderColorConfig: get(state, 'lendersConfig.colorConfig', []),
  }),
  ({
     report: { fetchingStatus, loansByMonth, reportWindow },
     lenderColorConfig,
   }) => {
    if (fetchingStatus !== FETCHING_STATUS.SUCCESS) {
      return {
        fetchingStatus,
      };
    }
    const loans = flatMap(loansByMonth);
    const contextFromData = generateContextFromLoansAndState(loans, { lenderColorConfig });
    const context = {
      ...contextFromData,
    };
    const views = perspectives.map(perspective =>
      loansByMonthToView(
        loansByMonth,
        {
          context,
          perspective,
          displayWindow: reportWindow,
        },
      ));

    return {
      fetchingStatus,
      views,
    };
  },
);

const mapStateToProps = expectedSettlementReportSelector;

export const ConnectedExpectedSettlementReport = connect(mapStateToProps)(ExpectedSettlementReport);
