import { createReducer } from 'utils/reducerGenerator';
import * as types from '../actions';
import { FETCHING_STATUS } from '../../../../constants';

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  loans: [],
  searchWindow: {},
};

export const reducer = createReducer(initialState, {
  [types.FETCH_LOANS_FOR_CONVERSION_RATE_REQUEST]: () => ({
    ...initialState,
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.FETCH_LOANS_FOR_CONVERSION_RATE_SUCCESS]: (
    state,
    { data: { loans, searchWindow } },
  ) =>
    ({
      fetchingStatus: FETCHING_STATUS.SUCCESS,
      loans,
      searchWindow,
    }),
  [types.FETCH_LOANS_FOR_CONVERSION_RATE_ERROR]: () => ({
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});
