import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import PropTypes from 'prop-types';
import YesNoFilter from 'shared/components/MobiusTable/components/YesNoFilter/YesNoFilter';
import { dateFormatter } from '../../shared/formatterUtils';
import styles from './styles.module.scss';
import MobiusTable from '../../shared/components/MobiusTable';
import { ROW_HEIGHT, PAGE_SIZE } from '../constants';
import useMediaType from '../../shared/hooks/useMediaType';
import { MEDIA_TYPES } from '../../constants/media';
import { formatAmountWithDefault, formatCurrency } from '../../utils/formatters';
import LoadingAndErrorWrapper from '../../shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import { isError, isInit, isStart } from '../../utils/actionStatusUtil';
import InternalLink from '../../shared/components/Links/InternalLink';
import RemoteFilterShape from '../../shared/components/MobiusTable/shapes/RemoteFilterShape';
import { Select } from '../../shared/components/formFields/Select/Select';
import { FromToFilter } from '../../shared/components/MobiusTable/components/FromToFilter/FromToFilter';
import StatusFilter from './StatusFilter/StatusFilter';
import DownloadExcelButton from '../../shared/components/DownloadExcelButton';
import { PERMISSIONS } from '../../shared/components/authorisation/permissions';

const customStyles = {
  menu: provided => ({
    ...provided,
    fontFamily: 'museo-sans-300, sans-serif',
    fontWeight: 'normal',
    zIndex: 1061,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0.4rem !important',
  }),
};

const PRE_APPROVAL_OPTIONS = {
  true: 'Yes',
  false: 'No',
};

const filterOption = ({ label }, text) => (toLower(label).includes(toLower(text)));

const AllApplicationsComponent = ({
                                    take,
                                    skip,
                                    businessId,
                                    result,
                                    fetchingStatus,
                                    totalRowCount,
                                    totalLoanAmount,
                                    adviserDropDownList,
                                    lenderDropDownList,
                                    reset,
                                    setPageableParams,
                                    getAllApplications,
                                    getAllApplicationsInit,
                                    remoteFilters,
                                    onRemoteFiltersChange,
                                    downloadAllApplications,
                                    disableDownloadButton,
                                  }) => {
  const sticky = useMediaType() !== MEDIA_TYPES.PHONE;

  const getApplicationNumberRender = (appNumber, row) => (
    <InternalLink to={`/applications/${row.id}/activities?from=all-applications`}>
      {appNumber || 'Undefined'}
    </InternalLink>
    );
  // eslint-disable-next-line
  const loanTotalAmountColumn = (
    <div>
      <span>
        TOTAL LOAN
        <br />
        AMOUNT
      </span>
      <div className={styles.totalAmounts}>
        <div>{isNil(totalLoanAmount)
          ? ''
          : formatCurrency(totalLoanAmount, { precision: 2 })}
        </div>
      </div>
    </div>
  );
  const timeFilterRenderer = (filter, onFilter, allSelected, endYearOfSelectOptions) => (
    <FromToFilter
      filter={filter ? filter.value : []}
      onFilter={onFilter}
      disabled={allSelected}
      placeholder="Filter (from/to)"
      endYearOfSelectOptions={endYearOfSelectOptions}
    />
  );

  const columns = useMemo(() => ([
    {
      name: 'adviserName',
      title: 'ADVISER',
      sticky,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <Select
          options={adviserDropDownList}
          filterOption={filterOption}
          disabled={allSelected}
          onChange={onFilter}
          value={filter}
          className={styles.selectInput}
          styles={customStyles}
          placeholder="Any"
        />
      ),
    },
    {
      name: 'applicantName',
      title: 'APPLICANT/S',
      sticky,
      allowRemoteFiltering: true,
    },
    {
      name: 'lenderName',
      title: 'LENDER',
      sticky,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <Select
          options={lenderDropDownList}
          filterOption={filterOption}
          disabled={allSelected}
          onChange={onFilter}
          value={filter}
          className={styles.selectInput}
          styles={customStyles}
          placeholder="Any"
        />
      ),
    },
    {
      name: 'applicationNumber',
      title: 'APP. NUMBER',
      sticky,
      width: 130,
      renderer: getApplicationNumberRender,
      allowRemoteFiltering: true,
    },
    {
      name: 'currentStatus',
      title: 'CURRENT STATUS',
      sticky,
      disallowResizing: true,
      width: 130,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <StatusFilter filter={filter ? filter.value : []} onFilter={onFilter} disabled={allSelected} />
      ),
    },
    {
      name: 'created',
      title: (
        <span>
          CREATED
        </span>
      ),
      width: 120,
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      allowRemoteFiltering: true,
      filterCellRenderer: timeFilterRenderer,
    },
    {
      name: 'submitted',
      title: (
        <span>
          SUBMITTED
        </span>
      ),
      width: 120,
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      allowRemoteFiltering: true,
      filterCellRenderer: timeFilterRenderer,
    },
    {
      name: 'conditionalApproval',
      title: (
        <span>
          CONDITIONAL
          <br />
          APPROVAL
        </span>
      ),
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      width: 120,
      allowRemoteFiltering: true,
      filterCellRenderer: timeFilterRenderer,
    },
    {
      name: 'unconditionalApproval',
      title: (
        <span>
          UNCONDITIONAL
          <br />
          APPROVAL
        </span>
      ),
      width: 130,
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      allowRemoteFiltering: true,
      filterCellRenderer: timeFilterRenderer,
    },
    {
      name: 'expectedSettlement',
      title: (
        <span>
          EXPECTED
          <br />
          SETTLEMENT
        </span>
      ),
      width: 120,
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) =>
          timeFilterRenderer(filter, onFilter, allSelected, new Date().getFullYear() + 2),
    },
    {
      name: 'settled',
      title: (
        <span>
          SETTLED
        </span>
      ),
      renderer: value => (isNil(value) ? '' : dateFormatter.toDdmmyy(value)),
      width: 120,
      allowRemoteFiltering: true,
      filterCellRenderer: timeFilterRenderer,
    },
    {
      name: 'preApproval',
      title: (
        <span>
          PRE APPROVAL
        </span>
      ),
      renderer: value => (value ? 'Yes' : 'No'),
      width: 130,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <YesNoFilter
          filter={filter ? filter.value : []}
          onFilter={onFilter}
          options={PRE_APPROVAL_OPTIONS}
          disabled={allSelected}
        />
      ),
    },
    {
      name: 'totalLoanAmount',
      align: 'right',
      title: loanTotalAmountColumn,
      width: 150,
      renderer: value => formatAmountWithDefault(value),
      allowRemoteFiltering: false,
    },
  ]), [adviserDropDownList, lenderDropDownList, loanTotalAmountColumn, sticky]);
  const classNames = { row: styles.mobiusTableRow };
  const shouldLoadApplications = businessId !== undefined && businessId !== '';

  useEffect(() => {
    reset();
  }, [reset, businessId]);

  useEffect(() => {
    if (shouldLoadApplications) {
      if (!take) {
        getAllApplicationsInit();
      } else {
        getAllApplications({
          take, skip, businessId, remoteFilters,
        });
      }
    }
  }, [skip, businessId, take, remoteFilters,
    getAllApplications, getAllApplicationsInit, shouldLoadApplications]);

  const downloadXlsxReport = () => {
    downloadAllApplications({
      take: totalRowCount,
      skip: 0,
      businessId,
      remoteFilters,
      timeZoneId: moment.tz.guess(),
    });
  };

  return (
    <div className={styles.summary}>
      <div className={styles.header}>
        <h1>All applications</h1>
        <DownloadExcelButton
          name="Download as spreadsheet"
          downloadReport={downloadXlsxReport}
          disabled={disableDownloadButton}
          withIcon={false}
          allowedPermissions={[PERMISSIONS.VIEW_ALL_APPLICATION_TRACKING, PERMISSIONS.VIEW_OWN_APPLICATION_TRACKING]}
        />
      </div>
      {
        shouldLoadApplications
          ? (
            <LoadingAndErrorWrapper
              isLoading={isInit(fetchingStatus)}
              hasError={isError(fetchingStatus)}
            >
              <MobiusTable
                rows={result}
                columns={columns}
                classNames={classNames}
                className={styles.mobiusTable}
                estimatedRowHeight={ROW_HEIGHT}
                totalRowCount={totalRowCount}
                pageSize={PAGE_SIZE}
                getRemoteRows={setPageableParams}
                skip={skip}
                isLoading={isStart(fetchingStatus)}
                remoteFilters={remoteFilters}
                onRemoteFiltersChange={onRemoteFiltersChange}
                resizingEnabled
                withRemote
                virtual
                withRemoteFilters
              />
            </LoadingAndErrorWrapper>
          )
          : (
            <div className={styles.shouldSelectBusiness}>
              Please select a business from application tracking to see applications.
            </div>
            )
      }
    </div>
  );
};

AllApplicationsComponent.defaultProps = {
  take: null,
  skip: 0,
  totalLoanAmount: null,
  adviserDropDownList: [],
  lenderDropDownList: [],
};

AllApplicationsComponent.propTypes = {
  take: PropTypes.number,
  fetchingStatus: PropTypes.number.isRequired,
  businessId: PropTypes.string.isRequired,
  skip: PropTypes.number,
  totalRowCount: PropTypes.number.isRequired,
  getAllApplications: PropTypes.func.isRequired,
  result: PropTypes.array.isRequired,
  setPageableParams: PropTypes.func.isRequired,
  getAllApplicationsInit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  totalLoanAmount: PropTypes.number,
  onRemoteFiltersChange: PropTypes.func.isRequired,
  downloadAllApplications: PropTypes.func.isRequired,
  remoteFilters: PropTypes.arrayOf(RemoteFilterShape).isRequired,
  adviserDropDownList: PropTypes.array,
  lenderDropDownList: PropTypes.array,
  disableDownloadButton: PropTypes.bool.isRequired,
};

export default AllApplicationsComponent;
