import values from 'lodash/values';
import { LOG_TYPE } from 'ApplicationTracking/constants/activityLogTypes';

export const FILTERS_MAP = {
  ALL: {
    name: 'All',
    logTypes: [LOG_TYPE.STATUS_CHANGE, LOG_TYPE.APPLICATION_UPDATE_CHANGE, LOG_TYPE.NOTE,
      LOG_TYPE.TASK_CREATE, LOG_TYPE.TASK_UPDATE, LOG_TYPE.TASK_STATUS_CHANGE,
      LOG_TYPE.ALLIANZ_INSURANCE_UPDATE_CREATE, LOG_TYPE.ALLIANZ_INSURANCE_CHANGE_FROM_LOTUS_NOTES,
      LOG_TYPE.ALLIANZ_INSURANCE_CHANGE, LOG_TYPE.EXPECTED_SETTLEMENT_DATE_CHANGE,
      LOG_TYPE.SEND_TO_APPLY_ONLINE, LOG_TYPE.APPLICATION_CREATED,
      LOG_TYPE.PRE_SUBMISSION_APPLICATION_DETAIL_CHANGE,
      LOG_TYPE.LOAN_PROTECTION_INSURANCE_COMPLIANCE_CHANGE,
      LOG_TYPE.FUNDING_WORKSHEET_CHANGE,
      LOG_TYPE.NEW_SOMA_CREATED,
      LOG_TYPE.PRODUCT_COMPARISON_CHANGE,
      LOG_TYPE.PRODUCT_COMPARISON_CHANGE,
      LOG_TYPE.SOMAS_CHANGE,
      LOG_TYPE.ALI_INSURANCE_UPDATE_ACKNOWLEDGE,
      LOG_TYPE.APPLICANTS_INFO_SYNCHRONIZED,
    ],
  },
  APPLICATION: {
    name: 'Application',
    logTypes: [
      LOG_TYPE.FUNDING_WORKSHEET_CHANGE,
      LOG_TYPE.PRE_SUBMISSION_APPLICATION_DETAIL_CHANGE,
      LOG_TYPE.NEW_SOMA_CREATED,
      LOG_TYPE.PRODUCT_COMPARISON_CHANGE,
      LOG_TYPE.ADDITIONAL_INCOME,
      LOG_TYPE.EMPLOYMENTS_UPDATED,
      LOG_TYPE.ASSETS_UPDATED,
      LOG_TYPE.CONTACT_CHANGE,
      LOG_TYPE.APPLICANTS_UPDATED,
      LOG_TYPE.EXPENSE_INFO_UPDATED,
      LOG_TYPE.LIABILITIES_UPDATED,
      LOG_TYPE.APPLICANTS_INFO_SYNCHRONIZED,
      LOG_TYPE.NEED_AND_OBJECTIVE_UPDATED,
    ],
  },
  NOTES: {
    name: 'Notes',
    logTypes: [LOG_TYPE.NOTE, LOG_TYPE.SURVEY_SENT_SUCCESS],
  },
  TASKS: {
    name: 'Tasks',
    logTypes: [LOG_TYPE.TASK_CREATE,
      LOG_TYPE.TASK_UPDATE,
      LOG_TYPE.TASK_STATUS_CHANGE],
  },
  STATUS: {
    name: 'Statuses',
    logTypes: [LOG_TYPE.STATUS_CHANGE, LOG_TYPE.APPLICATION_UPDATE_CHANGE,
      LOG_TYPE.ALLIANZ_INSURANCE_UPDATE_CREATE, LOG_TYPE.ALLIANZ_INSURANCE_CHANGE_FROM_LOTUS_NOTES,
      LOG_TYPE.ALLIANZ_INSURANCE_CHANGE, LOG_TYPE.EXPECTED_SETTLEMENT_DATE_CHANGE,
      LOG_TYPE.PREAPPROVAL_EXPIRY_DATE_CHANGE, LOG_TYPE.FINANCE_DUE_DATE_CHANGE,
      LOG_TYPE.SEND_TO_APPLY_ONLINE, LOG_TYPE.APPLICATION_CREATED,
      LOG_TYPE.APPLICATION_COPIED_FROM, LOG_TYPE.APPLICATION_COPIED_TO,
      LOG_TYPE.LOAN_PROTECTION_INSURANCE_COMPLIANCE_CHANGE,
    ],
  },
  AUDIT_TRAIL: {
    name: 'All updates',
    logTypes: values(LOG_TYPE),
  },
};

export const ACTIVITY_LOG_FILTERS = [
  FILTERS_MAP.ALL,
  FILTERS_MAP.APPLICATION,
  FILTERS_MAP.NOTES,
  FILTERS_MAP.TASKS,
  FILTERS_MAP.STATUS,
  FILTERS_MAP.AUDIT_TRAIL,
];

export const ACTIVITY_LOG_FILTERS_SHORT = [
  FILTERS_MAP.NOTES,
  FILTERS_MAP.TASKS,
  FILTERS_MAP.STATUS,
  FILTERS_MAP.AUDIT_TRAIL,
];
