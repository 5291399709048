import React from 'react';
import PropTypes from 'prop-types';
import InternalLink from 'shared/components/Links/InternalLink';
import LenderIconImage from 'shared/components/LenderIconImage';
import styles from './ExtraInfoContent.module.scss';

const ExtraInfoContent = ({ extraInfo }) => {
  const {
    lenderId, lenderName, applicantNames, applicationNumber, applicationId,
  } = extraInfo;
  return (
    <div className={styles.extraInfoContainer}>
      <div className={styles.leftInfo}>
        <div className={styles.lenderLogo}>
          <LenderIconImage lender={{ lenderId, name: lenderName }} iconSize="SMALL" />
        </div>
        <div className={styles.applicantNames}>
          {applicantNames.join('; ')}
        </div>
      </div>
      <div className={styles.applicationNumber}>
        APPLICATION NUMBER
        <InternalLink
          className={styles.applicationNumberLink}
          to={`/applications/${applicationId}`}
        >
          {applicationNumber || 'Undefined'}
        </InternalLink>
      </div>
    </div>
  );
};

ExtraInfoContent.defaultProps = {
  extraInfo: {
    applicationNumber: '',
    applicantNames: [],
    lenderId: '',
    lenderName: '',
  },
};

ExtraInfoContent.propTypes = {
  extraInfo: PropTypes.shape({
    lenderId: PropTypes.string,
    lenderName: PropTypes.string,
    applicantNames: PropTypes.array,
    applicationNumber: PropTypes.string,
    applicationId: PropTypes.string.isRequired,
  }),
};

export default ExtraInfoContent;
