export default (state, profile) => {
  const {
    applicationCriteria,
    loanCriteriaList,
    products,
  } = state;
  const { loanValueRatio, selectedLenderNames } = applicationCriteria;
  const { totalCostTerm, repaymentFrequency } = products;
  return loanCriteriaList.map((loanCriteria) => {
    const {
      loanAmount,
      productType,
      loanTerm,
      fixedLoanTerm,
      selectedFeatureNames,
      purpose,
      repaymentType,
      interestOnlyTerm,
    } = loanCriteria;
    return {
      loanAmount,
      loanValueRatio,
      loanTerm,
      fixedLoanTerm,
      productType,
      features: selectedFeatureNames || [],
      lenderNames: selectedLenderNames || [],
      purpose,
      repaymentType,
      interestOnlyTerm,
      totalCostTerm,
      repaymentFrequency,
      states: profile.states,
    };
  });
};
