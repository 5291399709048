import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Async from 'react-select/async';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { selectBusiness } from 'redux/business';
import { noLongerLoggedIn } from 'redux/login';
import BusinessShape from 'shapes/BusinessShape';
import { PALETTE, MARGIN } from 'shared/theme/depricatedStyles';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { searchBusinessSummaries } from '../../api';
import { PERMISSIONS } from '../authorisation/permissions';
import userCan from '../authorisation/userCan';
import styles from './styles.module.scss';
import debounceAsync from '../../debounceAsync';

const asyncSearchBusinessSummaries = debounceAsync(searchBusinessSummaries, 500);
const doSearch = dispatchLogout => inputValue => asyncSearchBusinessSummaries(inputValue)
  .catch((error) => {
    if (error.response && error.response.status === 401) {
      dispatchLogout();
    }
    return [{ error: 'Error searching for franchise' }];
  });

const getOptionBackgroundColor = (state) => {
  if (state.isSelected) {
    return PALETTE.multiValue;
  }
  return state.isFocused ? PALETTE.primaryBackground : PALETTE.white;
};

const HEIGHT = '5rem';
const customStyles = {
  control: existing => ({
    ...existing,
    backgroundColor: PALETTE.white,
    borderStyle: 'solid',
    borderWidth: '0.05rem',
    borderRadius: '0.2rem',
    minHeight: HEIGHT,
    height: HEIGHT,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: existing => ({
    ...existing,
    height: '3.3rem',
  }),
  singleValue: existing => ({
    ...existing,
    width: '28rem',
  }),
  option: (existing, state) => ({
    ...existing,
    backgroundColor: getOptionBackgroundColor(state),
    color: PALETTE.mineShaft,
    borderBottom: `0.1rem solid ${PALETTE.border}`,
    minHeight: '5rem',
    padding: MARGIN.small,
  }),
  menuList: existing => ({
    ...existing,
    maxHeight: '24rem',
  }),
  menu: existing => ({
    ...existing,
    zIndex: 502,
  }),
};

const renderOption = business => (
  <div className={styles.option}>
    <div className={styles.bizname}>
      <span>{business.bizName}</span>
      <span className={styles.activeFlag}>{business.active === false && '(inactive)'}</span>
    </div>
    <div className={styles.bizOwners}>{(business.owners || []).map(o => o.name).join(', ')}</div>
  </div>
);

const Option = (option) => {
  if (option.data.error) {
    return (
      <div className={styles.error}>
        { option.data.error }
      </div>
);
  }
  return (
    <components.Option {...option} className={styles.optionComponent}>
      { renderOption(option.data) }
    </components.Option>
);
};

const GroupOfficeBusinessSelector = ({
 selectedBusiness, dispatchSelectBusiness, dispatchLogout, children, isDisabled, className,
}) => {
  const [hideSelection, setHideSelection] = useState(false);

  const SingleValue = option => !hideSelection
    && (
    <components.SingleValue {...option}>
      { renderOption(option.data) }
    </components.SingleValue>
);

  const handleSelectBusiness = useMemo(() => async (business) => {
    const event = new Event('beforeSelectBusiness');
    const selectedBusinessId = get(selectedBusiness, 'id');
    event.selectedBusinessId = business.id;
    event.currentBusinessId = selectedBusinessId;
    window.dispatchEvent(event);
    if (isNil(event.confirmed) || await event.confirmed) {
      dispatchSelectBusiness(business);
    }
  }, [dispatchSelectBusiness, selectedBusiness]);

  const onBlur = () => setHideSelection(false);
  const onFocus = () => setHideSelection(true);

  return (
    <div className={`${styles.container} ${className}`}>
      {children}
      <section className={styles.businessSelectorContainer}>
        <div className={styles.label}>Business</div>
        <div className={styles.select}>
          <Async
            isDisabled={isDisabled}
            blurInputOnSelect
            placeholder="Start typing to select..."
            onFocus={onFocus}
            onBlur={onBlur}
            value={selectedBusiness}
            getOptionValue={o => o.id}
            onChange={handleSelectBusiness}
            loadOptions={doSearch(dispatchLogout)}
            components={{ Option, SingleValue }}
            openMenuOnClick={false}
            styles={customStyles}
            tabSelectsValue
          />
        </div>
      </section>
    </div>
);
};

GroupOfficeBusinessSelector.defaultProps = {
  selectedBusiness: null,
  children: null,
  isDisabled: false,
  className: '',
};

GroupOfficeBusinessSelector.propTypes = {
  dispatchSelectBusiness: PropTypes.func.isRequired,
  dispatchLogout: PropTypes.func.isRequired,
  selectedBusiness: BusinessShape,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  dispatchSelectBusiness: selectedBusiness => dispatch(selectBusiness(selectedBusiness)),
  dispatchLogout: () => dispatch(noLongerLoggedIn),
});

const mapStateToProps = state => ({
  selectedBusiness: state.business.selectedBusiness,
});

const ConnectedGroupOfficeBusinessSelector = connect(mapStateToProps, mapDispatchToProps)(GroupOfficeBusinessSelector);

export default userCan(PERMISSIONS.VIEW_SKYWALKER_BUSINESS_SUMMARY)(ConnectedGroupOfficeBusinessSelector, () => false);
