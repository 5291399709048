import keyBy from 'lodash/keyBy';
import { createReducer, createSelector } from '@reduxjs/toolkit';
import { ACTIONS } from './loanSplitActions';
import { SPLITTERS } from '../splitters';

const initialState = {
  splitSettledDate: null,
  settledAmount: null,
  splits: {
    ids: [],
    byId: {},
  },
  splitter: SPLITTERS.BUSINESS,
  hasDeclared: undefined,
};

const getSplits = state => state.splits;

export const splitsSelector = createSelector(
  getSplits,
  ({ ids, byId }) => ids.map(id => byId[id]),
);

const resetReducer = (draftState, { payload: { splitSettledDate, splits } }) => ({
  ...initialState,
  splitSettledDate,
  splits: {
    ids: splits.map(s => s.id),
    byId: keyBy(splits, split => split.id),
  },
});

const addReducer = (draftState, { payload: id }) => {
  const { splits } = draftState;
  splits.ids.push(id);
  splits.byId[id] = {
    id,
    accountNumber: '',
    settledAmount: null,
  };
};

const removeReducer = (draftState, { payload: idToRemove }) => {
  const { splits } = draftState;
  splits.ids = splits.ids.filter(id => id !== idToRemove);
  delete splits.byId[idToRemove];
};

const updateSettledAmountReducer = (draftState, { payload: settledAmount }) =>
  ({
    ...draftState,
    settledAmount,
  });

const updateSplitSettledDateReducer = (draftState, { payload: splitSettledDate }) => ({
  ...draftState,
  splitSettledDate,
});

const updateSplitAccountNumberReducer = (draftState, { payload: { id, accountNumber } }) => {
  const { splits } = draftState;
  splits.byId[id].accountNumber = accountNumber;
};

const updateSplitSettledAmountReducer = (draftState, { payload: { id, settledAmount } }) => {
  const { splits } = draftState;
  splits.byId[id].settledAmount = settledAmount;
};

const updateSplitterReducer = (draftState, { payload: splitter }) => ({
    ...draftState,
    splitter,
    hasDeclared: undefined,
});

const updateHasDeclaredReducer = (draftState, { payload: hasDeclared }) => ({
  ...draftState,
  hasDeclared,
});

export const loanSplitReducer = createReducer(initialState, {
  [ACTIONS.RESET]: resetReducer,
  [ACTIONS.ADD]: addReducer,
  [ACTIONS.REMOVE]: removeReducer,
  [ACTIONS.UPDATE_SETTLED_AMOUNT]: updateSettledAmountReducer,
  [ACTIONS.UPDATE_SPLIT_SETTLED_DATE]: updateSplitSettledDateReducer,
  [ACTIONS.UPDATE_SPLIT_ACCOUNT_NUMBER]: updateSplitAccountNumberReducer,
  [ACTIONS.UPDATE_SPLIT_SETTLED_AMOUNT]: updateSplitSettledAmountReducer,
  [ACTIONS.UPDATE_SPLITTER]: updateSplitterReducer,
  [ACTIONS.UPDATE_HAS_DECLARED]: updateHasDeclaredReducer,
});
