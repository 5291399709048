import { getAllProductsFromApi } from 'shared/api';
import defaultTo from 'lodash/defaultTo';
import { calculateTotalCost, calculateTotalRepayment, calculateVariantUpfrontFee } from 'shared/productUtils';
import extractProductSearchParams from './extractProductSearchParams';
import { clearComparison } from '../comparison';
import { ensureCriteriaHaveLoanTerms } from '../loanCriteriaList';
import { submitModifiedCriteria } from '../applicationCriteria';
import { ACTIONS } from './productsActions';

export const DEFAULT_REPAYMENT_FREQUENCY = 'PER_MONTH';

const standAloneSuccessAction = async (successActionContext) => {
  const { productsWithUpfrontFee, applicationCriteria, loanCriteriaList } = successActionContext;
  return {
    type: ACTIONS.GET_ALL_PRODUCTS_SUCCESS,
    products: productsWithUpfrontFee,
    applicationCriteria,
    loanCriteriaList,
  };
};

export const doProductSearch = (savedCriteria = {}) => (dispatch, getState) => {
  const state = {
    ...getState().productSearch.standAlone,
    ...savedCriteria,
  };
  const { applicationCriteria, loanCriteriaList, products: outerProducts } = state;
  const searchParams = extractProductSearchParams(state, getState().profile);
  return getAllProductsFromApi(searchParams)
    .then((products) => products.map(p => ({
        ...p,
        variants: p.variants.map(v => ({
          ...v,
          applicationFee: defaultTo(v.applicationFee, 0),
          legalFee: defaultTo(v.legalFee, 0),
          valuationFee: defaultTo(v.valuationFee, 0),
          ongoingFee: defaultTo(v.ongoingFee, 0),
          lmi: 0,
          upfrontFee: calculateVariantUpfrontFee(v),
        })),
      })))
    .then(async (productsWithUpfrontFee) => {
      const successActionContext = {
        productsWithUpfrontFee,
        applicationCriteria,
        loanCriteriaList,
        outerProducts,
      };

      dispatch(await standAloneSuccessAction(successActionContext));
      dispatch({
        type: ACTIONS.SAVE_SEARCH_CRITERIA,
        searchCriteria: {
          applicationCriteria,
          loanCriteriaList,
        },
      });
    })
    .catch(error => dispatch({ type: ACTIONS.GET_ALL_PRODUCTS_ERROR, error }));
};

export const getAllProducts = () => (dispatch, getState) => {
  const { loanCriteriaList } = getState().productSearch.standAlone;
  dispatch({ type: ACTIONS.GET_ALL_PRODUCTS_LOADING, loanCriteriaList });
  return dispatch(doProductSearch({}));
};

export const setTotalCostTerm = (totalCostTerm) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.SET_TOTAL_COST_TERM, totalCostTerm });
  await dispatch(doProductSearch(getState().productSearch.standAlone.products.searchCriteria));
};

export const setRepaymentFrequency = (repaymentFrequency) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.SET_REPAYMENT_FREQUENCY, repaymentFrequency });
  await dispatch(doProductSearch(getState().productSearch.standAlone.products.searchCriteria));
};

export const resetRepaymentFrequency = () => ({ type: ACTIONS.RESET_REPAYMENTS_FREQUENCY });

export const onTotalCostTermChange = (years) => dispatch => {
  dispatch(clearComparison());
  dispatch(setTotalCostTerm(years));
};

export const getProducts = () => async (dispatch) => {
  dispatch(clearComparison());
  dispatch(resetRepaymentFrequency());
  dispatch(ensureCriteriaHaveLoanTerms());
  dispatch(submitModifiedCriteria());
  await dispatch(getAllProducts());
};

export const onRepaymentFrequencyChange = (selectedFrequency) => dispatch => {
  dispatch(clearComparison());
  dispatch(setRepaymentFrequency(selectedFrequency));
};

const getMaximumProvidedLoanTerms = (loanCriteriaList) => {
  const maximumTerm = Math.max(...loanCriteriaList.map(s => s.loanTerm || 0));
  if (maximumTerm !== 0) {
    return maximumTerm;
  }
  return 30; // No loans have a term
};
export const getEnrichedProducts = products =>
  products.map(product => ({
    ...product,
    isSplitted: product.variants.length > 1,
    id: product.variants.reduce((acc, variant) => acc + variant.id, ''),
    totalRepayment: calculateTotalRepayment(product.variants),
    lenderName: product.variants[0].lenderName,
    totalCost: calculateTotalCost(product.variants),
  }));
export const processSetTotalCostTerm = (state, action) => ({
  ...state,
  totalCostTerm: action.totalCostTerm,
});
export const processSetRepaymentFrequency = (state, action) => ({
  ...state,
  repaymentFrequency: action.repaymentFrequency,
});
export const processResetRepaymentsFrequency = state => ({
  ...state,
  repaymentFrequency: DEFAULT_REPAYMENT_FREQUENCY,
});
export const processGetAllProductsLoading = (action, state) => {
  const maximumTerm = getMaximumProvidedLoanTerms(action.loanCriteriaList);
  return {
    ...state,
    products: [],
    hasError: false,
    isLoading: true,
    searchPerformed: true,
    totalCostTerm: maximumTerm,
    maximumTerm,
  };
};

export const processGetAllProductsSuccess = (action, state) => {
  const allCriteriaHaveLoanAmount = action.loanCriteriaList
    .every(loanCriteria => loanCriteria.loanAmount);
  return {
    ...state,
    products: getEnrichedProducts(action.products),
    hasError: false,
    isLoading: false,
    searchPerformed: true,
    sortedBy: allCriteriaHaveLoanAmount ? 'totalCost' : 'standardRate',
  };
};

export const processGetAllProductsError = state => ({
  ...state,
  products: [],
  hasError: true,
  isLoading: false,
  searchPerformed: true,
});

export const processSaveSearchCriteria = (state, action) => ({

    ...state,
    searchCriteria: action.searchCriteria,
  });

export const initialState = {
  products: [],
  hasError: false,
  hasMissingAppInfoError: false,
  hasServiceabilityError: false,
  hasPermissionError: false,
  isLoading: false,
  searchPerformed: false,
  sortedBy: 'totalCost',
  totalCostTerm: 30,
  repaymentFrequency: DEFAULT_REPAYMENT_FREQUENCY,
  customProduct: null,
  hasCustomProduct: false,
};
