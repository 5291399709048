import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Fields } from 'redux-form';
import objValues from 'lodash/values';
import concat from 'lodash/concat';
import noop from 'lodash/noop';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormYesNoSwitch from 'shared/components/formFields/ReduxForm/YesNoSwitch';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import {
  INVESTMENT,
  SUB_TYPES_NAME_MAPPING,
  VALUATION_TYPE_OPTIONS,
} from './constants';
import styles from './RealEstateAsset.module.scss';
import WrappedOwnerships from '../WrappedOwnerships';
import AddressInput from '../AddressInput';
import RentalIncomeFields from './fields/RentalIncomeFields';
import PrimaryPurposeField from './fields/PrimaryPurposeField';
import ValueField from './fields/ValueField';
import PropertyTypeFields from './fields/PropertyTypeFields';

const RealEstateAsset = ({
  fieldNamePrefix,
  children,
  onPropertyTypeChange,
  onPurposeChange,
  valueLabel,
  hideSecurityToggle,
  showSecurityLimit,
  canEditSecurityLimit,
  showConstructionSwitch,
  displayGuarantors,
  ownershipRowSpan,
  valueEditable,
  setDefaultConstructionValue,
}) => {
  // eslint-disable-next-line react/prop-types
  const renderRentalIncomeFields = useMemo(() => ({ input: { value } }) => (value === INVESTMENT ? (
    <RentalIncomeFields fieldNamePrefix={fieldNamePrefix} />
  ) : null), [fieldNamePrefix]);

  return (
    <div
      className={styles.wrapper}
    >
      <Fields
        names={[
          `${fieldNamePrefix}.primaryPurpose`,
          `${fieldNamePrefix}.rentalIncome.periodUnit`,
          `${fieldNamePrefix}.rentalIncome.rentalAmount`,
          `${fieldNamePrefix}.investmentPropertyCost.frequency`,
          `${fieldNamePrefix}.investmentPropertyCost.value`,
        ]}
        component={PrimaryPurposeField}
        onPurposeChange={onPurposeChange}
      />
      <Field
        name={`${fieldNamePrefix}.address`}
        component={AddressInput}
        label="Address"
        className={classNames(styles.field, styles.addressField)}
      />
      <WrappedOwnerships
        fieldName={`${fieldNamePrefix}.ownerships`}
        displayGuarantors={displayGuarantors}
        rowSpan={ownershipRowSpan}
      />
      <Field
        name={`${fieldNamePrefix}.valuationType`}
        component={ReduxFormSelect}
        label="Valuation type"
        className={classNames(styles.field, styles.valuationTypeField)}
        options={VALUATION_TYPE_OPTIONS}
      />
      <FormMetaProvider readOnly={!valueEditable}>
        <Fields
          names={[
            `${fieldNamePrefix}.${SUB_TYPES_NAME_MAPPING.Residential}`,
            `${fieldNamePrefix}.value`,
            `${fieldNamePrefix}.currentValue`,
            `${fieldNamePrefix}.isForConstructionLoan`,
          ]}
          component={ValueField}
          showConstructionSwitch={showConstructionSwitch}
          valueLabel={valueLabel}
        />
      </FormMetaProvider>
      {!hideSecurityToggle && (
        <Field
          name={`${fieldNamePrefix}.asSecurity`}
          component={ReduxFormYesNoSwitch}
          label="Use as security"
          className={styles.asSecurityField}
          switchedOnText="Yes"
          switchedOffText="No"
        />
      )}
      { showSecurityLimit && (
        <Field
          name={`${fieldNamePrefix}.securityLimit`}
          component={ReduxFormNumberInput}
          label="Security limit"
          placeholder="$"
          decimalScale={2}
          prefix="$"
          fullWidth
          allowNegative={false}
          className={styles.securityLimit}
          readOnly={!canEditSecurityLimit}
        />
      )}
      <Fields
        names={concat(
          `${fieldNamePrefix}.propertyType`,
          `${fieldNamePrefix}.isForConstructionLoan`,
          `${fieldNamePrefix}.value`,
          `${fieldNamePrefix}.currentValue`,
          objValues(SUB_TYPES_NAME_MAPPING).map(typeName => `${fieldNamePrefix}.${typeName}`),
        )}
        component={PropertyTypeFields}
        showConstructionSwitch={showConstructionSwitch}
        onPropertyTypeChange={onPropertyTypeChange}
        setDefaultConstructionValue={setDefaultConstructionValue || showConstructionSwitch}
      />
      <Field
        name={`${fieldNamePrefix}.primaryPurpose`}
        component={renderRentalIncomeFields}
      />
      {children}
    </div>
  );
};

RealEstateAsset.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  onPurposeChange: PropTypes.func,
  onPropertyTypeChange: PropTypes.func,
  children: PropTypes.node,
  valueLabel: PropTypes.string.isRequired,
  hideSecurityToggle: PropTypes.bool,
  showSecurityLimit: PropTypes.bool,
  canEditSecurityLimit: PropTypes.bool,
  showConstructionSwitch: PropTypes.bool,
  valueEditable: PropTypes.bool.isRequired,
  displayGuarantors: PropTypes.bool,
  setDefaultConstructionValue: PropTypes.bool,
  ownershipRowSpan: PropTypes.number,
};

RealEstateAsset.defaultProps = {
  children: null,
  onPurposeChange: noop,
  onPropertyTypeChange: noop,
  hideSecurityToggle: false,
  showSecurityLimit: false,
  canEditSecurityLimit: false,
  showConstructionSwitch: false,
  displayGuarantors: true,
  ownershipRowSpan: 1,
  setDefaultConstructionValue: false,
};

export default RealEstateAsset;
