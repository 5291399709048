const openInTab = (blob) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, 'Product-Comparison.pdf');
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  }
};

export default openInTab;
