import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import styles from 'shared/components/productSearch/CriteriaSummary/styles.module.scss';
import ApplicationCriteriaSummary from '../../shared/components/productSearch/CriteriaSummary/ApplicationCriteriaSummary';
import LoanCriteriaSummary from '../../shared/components/productSearch/CriteriaSummary/LoanCriteriaSummary';
import { ApplicationCriteriaShape, LoanCriteriaShape } from '../../shapes';

const CriteriaSummary = ({
                           modifiable,
                           modifySearchCallback,
                           applicationCriteria,
                           loanCriteriaList,
                         }) => {
  const isSingleLoan = loanCriteriaList.length === 1;

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <h2>Loan criteria</h2>
          </div>
          <div>
            {loanCriteriaList.map((criteria, index) => {
              const key = `loan${index}`;
              return (
                <LoanCriteriaSummary
                  key={key}
                  applicationCriteria={applicationCriteria}
                  loanCriteria={criteria}
                  loanNum={index + 1}
                  isSingleLoan={isSingleLoan}
                />
              );
            })}
            <ApplicationCriteriaSummary
              applicationCriteria={applicationCriteria}
              isSingleLoan={isSingleLoan}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {modifiable && (
            <PrimaryButton
              className={styles.topButton}
              onClick={modifySearchCallback}
            >
              Modify search
            </PrimaryButton>
          )}
        </div>
      </div>
    </>
  );
};

CriteriaSummary.propTypes = {
  modifiable: PropTypes.bool,
  modifySearchCallback: PropTypes.func,
  applicationCriteria: ApplicationCriteriaShape.isRequired,
  loanCriteriaList: PropTypes.arrayOf(LoanCriteriaShape).isRequired,
};

CriteriaSummary.defaultProps = {
  modifiable: false,
  modifySearchCallback: () => {},
};

export default CriteriaSummary;
