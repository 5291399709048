import React from 'react';
import { connect } from 'react-redux';
import { formPropTypes, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { getSecurity, getApplicants } from '../../redux/selectors';
import { updatePurchasingAssets } from '../../redux';
import { TRANSACTION_TYPES } from '../../redux/constants';
import Section from '../shared/Section';
import PurchasingAsset from './PurchasingAsset';
import OwnedSecurity from './OwnedSecurity';
import FinancialSummary from '../shared/FinancialSummary';
import { formatAmountWithDefault } from '../../../../../utils/formatters';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { getDefaultApplicantsOwnershipsWithCompanyAndGuarantors } from '../../redux/selectors/ownerships';
import { FETCHING_STATUS } from '../../../../../constants';

const defaultSecurity = {
  asSecurity: true,
  transactionType: TRANSACTION_TYPES.PURCHASING,
  isPreApproval: false,
};

const formatSecuritySectionHeader = (items) => [
  {
    label: 'Security value',
    displayValue: formatAmountWithDefault(sumBy(
      items.filter(item => item.asSecurity),
      (item) => (isNil(item.securityLimit) ? item.value : item.securityLimit),
    )),
    span: 2,
    column: -3,
  },
];

const formatPurchasingSecuritySectionHeader = (items) => [
  {
    label: 'Future security value',
    displayValue: formatAmountWithDefault(sumBy(items.filter(item => item.asSecurity), 'value')),
    span: 2,
    column: -3,
  },
];

const Security = ({
  pristine,
  handleSubmit,
  submitting,
  hasEditPermission,
  defaultOwnerships,
  invalid,
  applicants,
  isFetching,
}) => (
  <>
    <FinancialSummary />
    <form onSubmit={handleSubmit}>
      <Section
        title="Security - owned"
        formatSectionHeader={formatSecuritySectionHeader}
        fieldName="ownedSecurity"
        editable={false}
        canEditSecurityLimit={hasEditPermission}
        showSecurityLimit
        Item={OwnedSecurity}
      />
      <Section
        title="To purchase/changes to owned asset"
        formatSectionHeader={(items) => formatPurchasingSecuritySectionHeader(items)}
        fieldName="purchasingAssets"
        editable={hasEditPermission}
        Item={PurchasingAsset}
        defaultItem={{ ...defaultSecurity, ownerships: defaultOwnerships }}
        addButtonLabel="Add asset to purchase"
        applicants={applicants}
      />
      {hasEditPermission
      && (
        <StickyFooter
          disabled={pristine || submitting || invalid || isFetching}
          type="submit"
        />
      )}
      <LoadingWithOverlay isLoading={submitting || isFetching} />
      <UnsavedChangePrompt shouldConfirm={!pristine} />
    </form>
  </>
  );

Security.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state => ({
        initialValues: getSecurity(state),
        hasEditPermission: hasEditPermissionSelector(state),
        defaultOwnerships: getDefaultApplicantsOwnershipsWithCompanyAndGuarantors(state),
        applicants: getApplicants(state),
        isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START,
    }
    ),
)(reduxForm({
  form: 'securityForm',
  onSubmit: (values, dispatch) => dispatch(updatePurchasingAssets(values)),
  enableReinitialize: true,
})(Security));
