import InsuranceReferralSection
  from '../components/ApplicationDetails/ApplicationDetailContentNav/InsuranceReferralSection';
import { ActivityWithUpdateAndTaskSection } from '../components/ApplicationDetails/ApplicationDetailContentNav/Activity';
import LoanDetails from '../components/ApplicationDetails/ApplicationDetailContentNav/LoanDetails';
import ConnectedEssentialDates from '../components/ApplicationDetails/ApplicationDetailContentNav/EssentialDates';
import Contacts from '../components/ApplicationDetails/ApplicationDetailContentNav/Contacts';
import ProductSearch from '../components/ApplicationDetails/ApplicationDetailContentNav/ProductSearch';
import ProductComparison from '../components/ApplicationDetails/ApplicationDetailContentNav/ProductComparison';
import Finalise from '../components/ApplicationDetails/ApplicationDetailContentNav/Finalise';
import ApplicantsWithCompanyAndGuarantors from '../components/ApplicationDetails/ApplicationDetailContentNav/Applicants';
import Assets from '../components/ApplicationDetails/ApplicationDetailContentNav/Assets';
import Expenses from '../components/ApplicationDetails/ApplicationDetailContentNav/Expenses';
import Security from '../components/ApplicationDetails/ApplicationDetailContentNav/Security';
import FundingWorksheet from '../components/ApplicationDetails/ApplicationDetailContentNav/FundingWorksheet';
import IncomeAndEmployment from '../components/ApplicationDetails/ApplicationDetailContentNav/IncomeAndEmployment';
import NeedsAndObjectives from '../components/ApplicationDetails/ApplicationDetailContentNav/NeedsAndObjectives';
import Liabilities from '../components/ApplicationDetails/ApplicationDetailContentNav/Liabilities';

export const ACTIVITY_TAB = {
  key: 'activities',
  label: 'Activities',
  path: 'activities',
  component: ActivityWithUpdateAndTaskSection,
};

export const LOAN_DETAIL_TAB = {
  key: 'loanDetails',
  label: 'Loan details',
  path: 'loanDetails',
  component: LoanDetails,
};

export const ESSENTIAL_DATES_TAB = {
  key: 'essentialDates',
  label: 'Essential dates',
  path: 'essentialDates',
  component: ConnectedEssentialDates,
};

export const INSURANCE_REFERRAL_TAB = {
  key: 'insuranceReferral',
  label: 'Insurance referral',
  path: 'insuranceReferral',
  component: InsuranceReferralSection,
};

export const CONTACTS_TAB = {
  key: 'contacts',
  label: 'Contacts',
  path: 'contacts',
  component: Contacts,
};

const FUNDING_WORKSHEET_TAB = {
  key: 'fundingWorksheet',
  label: 'Funding worksheet',
  path: 'fundingWorksheet',
  component: FundingWorksheet,
};

export const PRODUCT_SEARCH_TAB = {
  key: 'productSearch',
  label: 'Product search',
  path: 'productSearch',
  component: ProductSearch,
};

export const PRODUCT_COMPARISON_TAB = {
  key: 'productComparison',
  label: 'Product comparison',
  path: 'productComparison',
  component: ProductComparison,
};

export const FINALISE_TAB = {
  key: 'finalise',
  label: 'Finalise',
  path: 'finalise',
  component: Finalise,
};

export const APPLICANTS_TAB = {
  key: 'applicants',
  label: 'Applicants',
  path: 'applicants',
  component: ApplicantsWithCompanyAndGuarantors,
};

export const INCOME_AND_EMPLOYMENT_TAB = {
  key: 'income_employment',
  label: 'Income and employment',
  path: 'income_and_employment',
  component: IncomeAndEmployment,
};

export const ASSETS_TAB = {
  key: 'assets',
  label: 'Assets',
  path: 'assets',
  component: Assets,
};

export const EXPENSES_TAB = {
  key: 'expenses',
  label: 'Expenses',
  path: 'expenses',
  component: Expenses,
};

export const SECURITY_TAB = {
  key: 'security',
  label: 'Security',
  path: 'security',
  component: Security,
};

export const NEEDS_AND_OBJECTIVES_TAB = {
  key: 'needsAndObjectives',
  label: 'Needs and objectives',
  path: 'needs&objectives',
  component: NeedsAndObjectives,
};

export const LIABILITIES_TAB = {
  key: 'liabilities',
  label: 'Liabilities',
  path: 'liabilities',
  component: Liabilities,
};

export const GMAIL_TAB = {
  key: 'gmail',
  label: 'gmail',
  path: 'https://mail.google.com',
};

export const APPLICATION_NAV_TABS = [
  LOAN_DETAIL_TAB,
  ESSENTIAL_DATES_TAB,
  CONTACTS_TAB,
];

export const SCENARIO_NAV_TABS = [
  FUNDING_WORKSHEET_TAB,
  PRODUCT_SEARCH_TAB,
  PRODUCT_COMPARISON_TAB,
  INSURANCE_REFERRAL_TAB,
  FINALISE_TAB,
];

export const INFORMATION_GATHERING_NAV_TABS = [
  APPLICANTS_TAB,
  INCOME_AND_EMPLOYMENT_TAB,
  ASSETS_TAB,
  LIABILITIES_TAB,
  EXPENSES_TAB,
  SECURITY_TAB,
  NEEDS_AND_OBJECTIVES_TAB,
];

export const MIGRATED_APPLICATION_NAV_TABS = [
  LOAN_DETAIL_TAB,
  ESSENTIAL_DATES_TAB,
  INSURANCE_REFERRAL_TAB,
  CONTACTS_TAB,
];
