import React, { useCallback, useMemo } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import CustomProductForm from './CustomProductForm';
import { createCustomProduct } from '../../../redux/customProduct/customProduct';

const ReduxForm = reduxForm({
  form: 'CustomProductForm',
  enableReinitialize: true,
})(CustomProductForm);

const ConnectedCustomProductForm = ({
 loansCriteria, totalCostTerm, lenders, toggleModal, repaymentFrequency,
}) => {
  const handleSubmit = useCallback(async (formData, dispatch, { reset }) => {
    await dispatch(createCustomProduct(formData, loansCriteria, totalCostTerm, repaymentFrequency));
    reset();
    toggleModal();
  }, [loansCriteria, totalCostTerm, toggleModal, repaymentFrequency]);

  const initialValues = useMemo(() => ({
    loanProducts: loansCriteria.map(() => ({})),
  }), [loansCriteria]);

  return (
    <ReduxForm
      lenders={lenders}
      loanCriteriaCount={loansCriteria.length}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};

ConnectedCustomProductForm.propTypes = {
  loansCriteria: PropTypes.array.isRequired,
  lenders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
  })).isRequired,
  toggleModal: PropTypes.func.isRequired,
  totalCostTerm: PropTypes.number.isRequired,
  repaymentFrequency: PropTypes.string.isRequired,
};

export default ConnectedCustomProductForm;
