/* eslint-disable react/prop-types */
import React from 'react';
import { COMMISSION_TYPES } from 'constants.js';
import ExternalLink from 'shared/components/Links/ExternalLink';
import { dateFormatter, withOrdinalSuffix } from 'shared/formatterUtils';
import round from 'lodash/round';
import truncate from 'lodash/truncate';
import styles from './Commissions/styles.module.scss';
import { BACKEND_BASE_URL } from '../../config/environment';

const DASH_DASH = <span>&ndash;&ndash;</span>;
const DASH_DASH_AS_VALUE = '$- -';
const NO_DATA = 'No data';

const formatThousands = arg =>
  arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const getRoundedAmount = amount => round(parseFloat(amount), 2).toFixed(2);
const formattedAmount = amount => formatThousands(getRoundedAmount(amount));

export const getLoanNotInSystemType = (row) => {
  const { commissionType } = row.props;
  return COMMISSION_TYPES[commissionType];
};

export const getMissingCommissionsType = (row) => {
  const { commissionType, numberOfMissingMonths } = row.props;
  if (commissionType === COMMISSION_TYPES.UPFRONT.toUpperCase()) {
    return 'Missing upfront';
  }
  if (commissionType === COMMISSION_TYPES.TRAIL.toUpperCase()) {
    return (
      <>
        Missing trail
        <br />
        {`(${withOrdinalSuffix(numberOfMissingMonths)} month)`}
      </>
    );
  }
  return null;
};

// value is used for filtering, sorting and exporting in MobiusTable.
export const getMissingCommissionsValue = (row) => {
  const { commissionType, numberOfMissingMonths } = row.props;
  if (commissionType === COMMISSION_TYPES.UPFRONT.toUpperCase()) {
    return 'Missing upfront';
  }
  if (commissionType === COMMISSION_TYPES.TRAIL.toUpperCase()) {
    return `Missing trail (${withOrdinalSuffix(numberOfMissingMonths)} month)`;
  }
  return null;
};

export const clawbackCommissionType = (row) => {
  const { commissionType } = row.props;
  return `${COMMISSION_TYPES[commissionType]} clawback`;
};

export const moneyValueRenderer = (amount) => {
  if (amount !== null && amount !== undefined) {
    if (amount < 0) {
      return `($${formatThousands(getRoundedAmount(-amount))})`;
    }
    return `$${formattedAmount(amount)}`;
  }
  return null;
};

export const loanTypeRenderer = (homeLoanEnum) => {
  const loanType = homeLoanEnum.split('_')[0];
  return loanType[0] + loanType.substr(1).toLowerCase();
};

export const getAmountAndBalanceForValue = ({ loanBalance, commissionAmount }) => `${commissionAmount ? moneyValueRenderer(commissionAmount) : DASH_DASH_AS_VALUE}
${loanBalance ? moneyValueRenderer(loanBalance) : DASH_DASH_AS_VALUE}`;

export const getAmountAndBalance = ({ loanBalance, commissionAmount }) => (
  <>
    {commissionAmount ? moneyValueRenderer(commissionAmount) : DASH_DASH}
    <br />
    {loanBalance ? moneyValueRenderer(loanBalance) : DASH_DASH}
  </>
);

export const approvalNumberLink = ({ approvalNumber, loanUniqueKey }) => {
  const text = getValueOrNoData(approvalNumber);
  if (loanUniqueKey) {
    return approvalNumberLinkToLoanDetail({ approvalNumber, loanUniqueKey });
  }
  return text;
};

export const approvalNumberLinkToLoanDetail = ({ approvalNumber, loanUniqueKey }) => {
  const text = getValueOrNoData(approvalNumber);
  const link = `${BACKEND_BASE_URL}/loanDetailPortal?loanUniqueKey=${loanUniqueKey}`;
  return (<ExternalLink href={link} target="_self">{text}</ExternalLink>);
};

export const getCategories = categories => (
  <span className={styles.categories}>{truncate(categories, { length: 60 })}</span>);

export const minHeightRenderer = child => (
  <div className={styles.minHeight}>{child}</div>
);

export const getTwoDecimalPlaces = amount =>
  `${+getRoundedAmount(amount)}`;

export const getDateOrNoData = value =>
  (value && dateFormatter.toDdmmyy(value)) || NO_DATA;

export const getDollarAmountOrNoData = value =>
  moneyValueRenderer(value) || NO_DATA;

export const getDollarAmountOrDashDash = value =>
  (value && moneyValueRenderer(value)) || DASH_DASH;

export const getDollarAmountOrDashDashAsValue = value =>
  (value && moneyValueRenderer(value)) || DASH_DASH_AS_VALUE;

export const getValueOrNoData = value =>
  (value === null || value === undefined ? NO_DATA : value);
