import isNil from 'lodash/isNil';
import { BigNumber } from 'bignumber.js';

export const isEmpty = value => isNil(value) || value === '';

const toBigNumber = value => new BigNumber(value);

export const calculateLVR = (totalLoanAmount, securityValue) => {
  const value = toBigNumber(totalLoanAmount)
    .dividedBy(toBigNumber(securityValue))
    .multipliedBy(100)
    .decimalPlaces(2)
    .toNumber();
  return value > 100 ? null : value;
};

export const calculateSecurityValue = (totalLoanAmount, loanValueRatio) => toBigNumber(totalLoanAmount)
  .dividedBy(toBigNumber(loanValueRatio))
  .multipliedBy(100)
  .decimalPlaces(0)
  .toNumber();

export const calculateLoanAmount = (securityValue, loanValueRatio) =>
  toBigNumber(securityValue)
    .multipliedBy(toBigNumber(loanValueRatio))
    .dividedBy(100)
    .decimalPlaces(0)
    .toNumber();
