import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../redux';
import sideEffectMiddleware from './middleware/sideEffectMiddleware';
import errorMiddleware from './middleware/errorMiddleware';

const middlewareArray = [
  thunk,
  errorMiddleware,
  sideEffectMiddleware,
];

export default () => createStore(
  reducers,
  compose(
    applyMiddleware(...middlewareArray),
    /* eslint-disable-next-line no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__(
      {
        name: `loan-application-app: ${new Date().toLocaleTimeString()}`,
      },
    ) : f => f,
  ),
);
