import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

const LazyLoad = (props) => {
  const { loader, fb, ...others } = props;
  const Component = lazy(loader);
  return (
    <Suspense fallback={fb}>
      <Component {...others} />
    </Suspense>
  );
};

LazyLoad.defaultProps = {
  fb: (<div>loading...</div>),
};

LazyLoad.propTypes = {
  loader: PropTypes.func.isRequired,
  fb: PropTypes.any,
};

export default LazyLoad;
