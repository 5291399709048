import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import PropTypes from 'prop-types';
import { TaskDetailModal } from './TaskDetailModal';
import {
 addComment, deleteTask, updateTask,
} from '../../../../redux';

const TaskDetailContainer = ({
                               taskLists, taskId, applicationId, ...others
}) => {
  const selectedTask = useMemo(() =>
    find(flatten(map(taskLists, it => it.tasks)), { id: taskId }), [taskLists, taskId]);
  return (
    <TaskDetailModal
      task={selectedTask}
      applicationId={applicationId}
      noDataMessage="Task cannot be found."
      {...others}
    />
  );
};

TaskDetailContainer.propTypes = {
  taskLists: PropTypes.array.isRequired,
  taskId: PropTypes.string,
  applicationId: PropTypes.string.isRequired,
};

TaskDetailContainer.defaultProps = {
  taskId: '',
};

export const ConnectedTaskDetailModal = connect(
  state => ({
    taskDetailStatus: get(state, 'application.tasksInfo.taskDetailStatus', {}),
    userId: get(state, 'profile.userId'),
    taskLists: get(state, 'application.tasksInfo.taskLists', []),
  }),
  {
    remove: deleteTask,
    update: updateTask,
    appendComment: addComment,
  },
)(TaskDetailContainer);
