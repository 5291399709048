import { BigNumber } from 'bignumber.js';

const toBigNumber = value => new BigNumber(value);

export const calculateLoanValueRatio = ({ totalLoanAmount, securityValue, loanValueRatio }) => {
  if (totalLoanAmount && securityValue) {
    return toBigNumber(totalLoanAmount)
      .dividedBy(toBigNumber(securityValue))
      .multipliedBy(100)
      .toNumber();
  }
  return loanValueRatio;
};

export const calculateLoanAmount = ({ securityValue, loanValueRatio }) =>
  toBigNumber(securityValue)
    .multipliedBy(toBigNumber(loanValueRatio))
    .dividedBy(100)
    .decimalPlaces(0)
    .toNumber();

export const calculateSecurityValue = ({ totalLoanAmount, securityValue, loanValueRatio }) => {
  if (!securityValue) {
    return toBigNumber(totalLoanAmount)
      .dividedBy(toBigNumber(loanValueRatio))
      .multipliedBy(100)
      .decimalPlaces(0)
      .toNumber();
  }
  return securityValue;
};

const calculate = (props) => {
  const {
    totalLoanAmount,
    securityValue,
    loanValueRatio,
    changedField,
  } = props;

  const emptyValues = [totalLoanAmount, securityValue, loanValueRatio].filter(
    value => !value || value === 0,
  ).length;
  if (emptyValues > 1) {
    return {};
  }

  if (emptyValues === 1) {
    return {
      totalLoanAmount,
      securityValue: calculateSecurityValue({ totalLoanAmount, securityValue, loanValueRatio }),
      loanValueRatio: calculateLoanValueRatio({ totalLoanAmount, securityValue, loanValueRatio }),
    };
  }

  if (changedField === 'loanValueRatio') {
    return {
      totalLoanAmount,
      securityValue: calculateSecurityValue({ totalLoanAmount, loanValueRatio }),
      loanValueRatio,
    };
  }

  return {
    totalLoanAmount,
    securityValue,
    loanValueRatio: calculateLoanValueRatio({ totalLoanAmount, securityValue }),
  };
};

export default (props) => {
  const result = calculate(props);

  if (result.loanValueRatio > 100) {
    result.loanValueRatio = null;
    result.errorMessage = { ...props.errorMessage, securityValue: 'Total security value needs to be greater than loan amount.' };
  } else if (result.securityValue >= 1000000000) {
    result.errorMessage = { ...props.errorMessage, securityValue: 'Total security value needs to be less than $1,000,000,000.' };
  }

  return result;
};
