import get from 'lodash/get';
import { getTargetChangedValue } from '../../../../../../constants/utils';
import { essentialDateDisplayStatus } from '../../../../../../constants/applicationStatus';
import { getBasicLogInfo } from './utils';

const statusChangeLogTransformer = origin =>
  ({
    ...getBasicLogInfo(origin),
    status: get(essentialDateDisplayStatus, getTargetChangedValue(origin, 'applicationStatus.status')),
  });

export default statusChangeLogTransformer;
