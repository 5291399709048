import PropTypes from 'prop-types';

export default PropTypes.shape({
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  version: PropTypes.number,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
});
