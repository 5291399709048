import React from 'react';
import shortid from 'shortid';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import values from 'lodash/values';
import head from 'lodash/head';
import mapValues from 'lodash/mapValues';
import { toDateStr } from 'utils/datetime';
import identity from 'lodash/identity';
import { toYYYYMM } from '../../shared/formatterUtils';

const transformPerson = (people) => {
  const fullName = [];
  if (people.surname) {
    fullName.push(people.surname);
  }
  if (people.firstName) {
    fullName.push(people.firstName);
  }
  return ({
    id: `person-${people.id}`,
    isPrimary: people.isPrimary,
    name: fullName.join(', ')
      .trim(),
  });
};

const transformCompany = companies => ({
  id: `company-${companies.id}`,
  isPrimary: companies.isPrimary,
  name: companies.name || '',
});

const orderApplicants = applicants => orderBy(
  [
    ...applicants.people.map(transformPerson),
    ...applicants.companies.map(transformCompany),
  ],
  ['isPrimary'],
  ['desc'],
);

export const getApplicantNamesMultipleLines = applicants => (
  <div>
    {orderApplicants(applicants)
      .map(e => (<div key={shortid.generate()}>{e.name}</div>))}
  </div>
);

export const getAdviserName = (adviser) => {
  if (!adviser) {
    return undefined;
  }
  const inactiveLabel = adviser.isActive === false ? ' (inactive)' : '';
  return `${adviser.firstName} ${adviser.surname}${inactiveLabel}`;
};

export const orderAndGroupAuditTrails = (data) => {
  const auditTrailGroups = values(groupBy(data.auditEvents, ({ actionTime }) => toDateStr(actionTime)))
    .map(items => ({
        date: toDateStr(head(items).actionTime),
        auditEvents: orderBy(items, ['actionTime'], ['desc']),
      }));
  return orderBy(auditTrailGroups, ['date'], ['desc']);
};

export const filterAuditChange = (auditEvents, standardRate) => {
  let firstUpdatedNetRate = false;
  return orderBy(auditEvents, ['actionTime']).map(event => {
    const changes = filter(event.changes, change => firstUpdatedNetRate || !(change.fieldName === 'lastKnownRate' && change.oldValue === null && change.newValue === standardRate))
      .map(change => {
        if (!firstUpdatedNetRate && change.fieldName === 'lastKnownRate' && change.oldValue === null && change.newValue !== standardRate) {
          firstUpdatedNetRate = true;
          return {
            ...change,
            oldValue: standardRate,
          };
        }
        return change;
      });
    if (changes.length !== event.changes.length) {
      firstUpdatedNetRate = true;
    }
    return { ...event, changes };
  });
};

const formatDate = (field, value) =>
  [
    {
      columnName: field.concat('From'),
      value: toYYYYMM(
        value.from.month,
        value.from.year,
      ),
    },
    {
      columnName: field.concat('To'),
      value: toYYYYMM(
        value.to.month,
        value.to.year,
      ),
    },
  ];

const transformers = {
  settledDate: ({ value }) => formatDate('settledDate', value),
  fixedTrailStartDate: ({ value }) => formatDate('fixedTrailStartDate', value),
  fixedTrailPercentage: () => [{
    columnName: 'hasFixedTrailPercentage',
    value: true,
  }],
};

export const buildFilter = (remoteFilters) => {
  const filters = remoteFilters.flatMap(item => {
    const transform = transformers[item.columnName] || identity;
    return transform(item);
  });

  return mapValues(keyBy(filters, f => f.columnName), v => v.value);
};
