import React, { useState, forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Button from 'shared/components/Buttons/Button';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import styles from './styles.module.scss';
import { ACTIVE_MISSING_COMMISSIONS, ACTIVE_OTHER, INACTIVE } from './LoanStatus';
import LoanStatusSection from './LoanStatusSection';

const LoanStatusFilterContent = forwardRef(({ onFilter, filter, onBlur }, ref) => {
  const [selection, updateSelection] = useState(filter);

  const toggleSelection = (value) => {
    if (isSelected(value)) {
      updateSelection(selection.filter(s => s !== value));
    } else {
      updateSelection([...selection, value]);
    }
  };

  const isSelected = value => selection.includes(value);

  const handleFilter = () => {
    onFilter(selection);
  };

  const handleClear = () => {
    onFilter([]);
  };

  return (
    <div className={styles.container} ref={ref} onBlur={onBlur}>
      <section className={styles.contentBody}>
        <LoanStatusSection
          title="Active - missing commissions"
          options={ACTIVE_MISSING_COMMISSIONS}
          onChange={toggleSelection}
          isSelected={isSelected}
        />
        <LoanStatusSection
          title="Active - other"
          options={ACTIVE_OTHER}
          onChange={toggleSelection}
          isSelected={isSelected}
        />
        <LoanStatusSection
          title="Inactive"
          options={INACTIVE}
          onChange={toggleSelection}
          isSelected={isSelected}
        />
      </section>
      <section className={styles.buttonContainer}>
        <Button onClick={handleFilter}>
          Filter
        </Button>
        <SecondaryButton onClick={handleClear} disabled={isEmpty(selection)}>
          Clear
        </SecondaryButton>
      </section>
    </div>
  );
});

LoanStatusFilterContent.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LoanStatusFilterContent;
