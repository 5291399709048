import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Modal from 'shared/components/Modal';
import isEmpty from 'lodash/isEmpty';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import TextArea from 'shared/components/formFields/TextArea';
import styles from './TaskListTemplate.module.scss';

const TemplateTaskModal = ({
  isOpen, handleCloseTaskModal, handleEditTaskFinish, defaultTask, isDisabled,
}) => {
  const [isError, setIsError] = useState(false);
  const [task, modifyTask] = useState({ ...defaultTask });

  useEffect(() => setIsError(false), [task.name]);

  const handleUpdateTask = () => {
    if (isEmpty(task.name.trim())) {
      setIsError(true);
    } else {
      handleEditTaskFinish(task);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      header={<div className={styles.modalHeader}>Task details</div>}
      onRequestClose={handleCloseTaskModal}
      className={styles.taskListTemplateTaskModal}
    >
      <div className={styles.modalContent}>
        <TextInput
          className={styles.contentItem}
          label="Task summary"
          onChange={value => modifyTask({ ...task, name: value })}
          value={task.name || ''}
          errorMessage={isError ? 'Please enter a Task summary' : ''}
          disabled={isDisabled}
        />
        <TextArea
          className={styles.contentItem}
          label="Task description"
          onChange={value => modifyTask({ ...task, description: value })}
          value={task.description || ''}
          disabled={isDisabled}
        />
        <div className={styles.buttonGroup}>
          <PrimaryButton
            onClick={handleUpdateTask}
          >
            Finished
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

TemplateTaskModal.propTypes = {
  defaultTask: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseTaskModal: PropTypes.func.isRequired,
  handleEditTaskFinish: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

TemplateTaskModal.defaultProps = {
  isDisabled: false,
};

export default TemplateTaskModal;
