import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const iconMapper = {
  error: {
    name: 'alert',
    color: '#df5858',
  },
  success: {
    name: 'info',
    color: '#5aa050',
  },
  info: {
    name: 'info',
    color: '#5aa050',
  },
  warning: {
    name: 'warning',
    color: '#e7a651',
  },
};

const ToastMessageScaffold = ({ children, type, iconName }) => {
  const messageIconName = iconName || iconMapper[type].name;
  const iconColor = iconMapper[type].color;
  return (
    <div className="toast-message">
      <div className="toast-message-icon">
        <Icon
          name={messageIconName}
          color={iconColor}
          size="20px"
        />
      </div>
      <div className="toast-message-container">
        {children}
      </div>
    </div>
  );
};

ToastMessageScaffold.propTypes = {
  iconName: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', 'error']),
  children: PropTypes.any,
};

ToastMessageScaffold.defaultProps = {
  iconName: undefined,
  children: null,
  type: 'error',
};

export default ToastMessageScaffold;
