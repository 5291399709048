import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 43 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/tasks-circle"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle
        id="Oval"
        stroke={color}
        strokeWidth="1.8"
        cx="20.5"
        cy="20.5"
        r="20.5"
        transform="translate(1.000000, 1.000000)"
      />
      <path
        d="M8,4 L21,4 L21,6 L8,6 L8,4 Z M3,3.5 L6,3.5 L6,6.5 L3,6.5 L3,3.5 Z M3,10.5 L6,10.5 L6,13.5 L3,13.5 L3,10.5 Z M3,17.5 L6,17.5 L6,20.5 L3,20.5 L3,17.5 Z M8,11 L21,11 L21,13 L8,13 L8,11 Z M8,18 L21,18 L21,20 L8,20 L8,18 Z"
        id="Shape"
        fill={color}
        transform="translate(10,9)"
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
