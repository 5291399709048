import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../../../shared/components/formFields/MultiSelect';

class LendersSelect extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { dispatchGetLenders } = this.props;
    dispatchGetLenders();
  }

  render() {
    const {
      lenderNames,
      selectedLenderNames,
      onChange,
      hasError,
    } = this.props;
    return (
      <div className="lenders-select">
        <MultiSelect
          label="Lenders"
          placeholder="Type to add lenders"
          name="lenders"
          classPrefix="lenders-select"
          valuesSet={lenderNames}
          selectedValues={selectedLenderNames}
          hasError={hasError}
          onChange={onChange}
        />
      </div>
    );
  }
}

LendersSelect.defaultProps = {
  lenderNames: [],
  selectedLenderNames: [],
  hasError: false,
};

LendersSelect.propTypes = {
  lenderNames: PropTypes.arrayOf(PropTypes.string),
  selectedLenderNames: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dispatchGetLenders: PropTypes.func.isRequired,
};

export default LendersSelect;
