import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';

export const formatSearchCriteria = ({ applicationCriteria, loansCriteria }, states) => {
  const {
    loanValueRatio,
    selectedLenderNames,
    applicationType,
  } = applicationCriteria;
  return loansCriteria.map((loanCriteria) => {
    const {
      loanAmount,
      combinedLoanAmount,
      productType,
      loanTerm,
      fixedLoanTerm,
      selectedFeatureNames,
      purpose,
      repaymentType,
      interestOnlyTerm,
    } = loanCriteria;
    return {
      loanAmount: applicationType === APPLICATION_TYPES.NEW_LOAN ? loanAmount : combinedLoanAmount,
      loanAmountCalculationOverride: applicationType === APPLICATION_TYPES.NEW_LOAN ? null : loanAmount,
      loanValueRatio,
      loanTerm: applicationType === APPLICATION_TYPES.NEW_LOAN ? Math.floor(loanTerm) : loanTerm,
      fixedLoanTerm,
      productType,
      features: selectedFeatureNames || [],
      lenderNames: selectedLenderNames || [],
      purpose,
      repaymentType,
      interestOnlyTerm,
      states,
    };
  });
};

export const SEARCH_TRIGGERS = {
  CRITERIA_FORM: 'CRITERIA_FORM',
  HEADER_FILTER: 'HEADER_FILTER',
};
