/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

const RadioButtonGroup = ({
 style, onSelect, items, groupName, value, disabled, readOnly,
}) => {
  const shouldDisable = disabled || readOnly;
  const [selected, select] = useState(value);
  useEffect(() => {
    if (value !== undefined && value !== selected) {
      select(value);
    }
  }, [value, selected]);
  const [focused, focus] = useState(null);
  return (
    <>
      {items.map(item => (
        <RadioButton
          key={`${groupName}-${item.label}`}
          style={style.label}
          className={`radioButtonGroup ${focused === item.value ? 'focusedFormField' : ''}`}
          name={groupName}
          disabled={shouldDisable || item.disabled}
          checked={selected === item.value}
          onFocus={() => { focus(item.value); }}
          onBlur={() => { focus(null); }}
          label={<span style={style.span}>{item.label}</span>}
          onChange={() => {
            select(item.value);
            onSelect(item.value);
          }}
          inputStyle={{
            flexShrink: 0,
            ...style.input,
          }}
        />
    ))}
    </>
);
};

RadioButtonGroup.propTypes = {
  style: PropTypes.shape({
    label: PropTypes.object,
    input: PropTypes.object,
    span: PropTypes.object,
  }),
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    value: PropTypes.string.isRequired,
  })),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  groupName: PropTypes.string.isRequired,
};

RadioButtonGroup.defaultProps = {
  style: {},
  items: [],
  value: undefined,
  disabled: false,
  readOnly: false,
};

export default RadioButtonGroup;
