import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { toDollarAmountWithDecimal } from 'shared/formatterUtils';
import BasicRichContent from 'shared/components/BasicRichContent/BasicRichContent';
import SignedFormContent from './SignedFormConent';

const generateMessage = (displayName, value) => (isNil(value)
  ? `${displayName} - empty field`
  : `${displayName} - ${toDollarAmountWithDecimal(value)}`);

const FinaliseUpdateContent = ({
                                          fieldName,
                                          fieldValue,
                                          displayName,
                                          changes,
                                        }) => {
  switch (fieldName) {
    case 'preSubmissionInfo.comment': {
      return (
        <BasicRichContent
          type="FINALISE"
          content="Lender comments"
          subContent="updated"
          text={fieldValue}
        />
      );
    }
    case 'signedForms':
      return <SignedFormContent changes={changes} />;
    case 'preSubmissionInfo.estimatedCommissionLoanAmount': {
      return (
        <BasicRichContent
          type="FINALISE"
          content="SOMA"
          subContent={generateMessage(displayName, fieldValue)}
        />
      );
    }
    case 'preSubmissionInfo.somaAdditionalComment': {
      return (
        <BasicRichContent
          type="FINALISE"
          content="SOMA"
          subContent="Additional comments updated"
          text={fieldValue}
        />
      );
    }
    default:
      return undefined;
  }
};

FinaliseUpdateContent.defaultProps = {
  fieldValue: undefined,
  displayName: undefined,
};

FinaliseUpdateContent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  displayName: PropTypes.string,
};

export default FinaliseUpdateContent;
