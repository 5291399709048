import React from 'react';
import styles from './ApplicationDetails.module.scss';

const ApplicationNotFound = () => (
  <div className={styles.applicationNotFound}>
    <div className={styles.title}>Application cannot be found.</div>
  </div>
);

export default ApplicationNotFound;
