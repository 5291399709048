import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Manager, Popper, Reference } from 'react-popper';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { Trigger } from 'shared/components/formFields/CheckboxDropdown/Trigger';
import { Popover } from 'shared/components/formFields/CheckboxDropdown/Popover';
import styles from './styles.module.scss';

const getDefaultOptionsFromProps = (propsOptions, propsSelectedOptionIds) =>
  propsOptions.map(option => ({
    ...option,
    checked: propsSelectedOptionIds.includes(option.id),
  }));

const CheckboxDropdown = ({
                                   className,
                                   popoverClassName,
                                   value,
                                   onUpdate,
                                   onError,
                                   valueRender,
                                   options: propsOptions,
                                   selectedOptionIds: propsSelectedOptionIds,
                                   disabled,
                                   readOnly,
                                   placeholder,
                                   listOnDisabled,
                                   emptyOptionsMessage,
                                   emptySelectedOptionsMessage,
                                   buttonText,
                                   onBlur,
                                   flipToggle,
                                 }) => {
  const [options, setOptions] = useState([]);
  const [isShown, setIsShownState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOptions(
      getDefaultOptionsFromProps(propsOptions, propsSelectedOptionIds),
    );
  }, [propsOptions, propsSelectedOptionIds]);

  const setIsShown = (isOpen) => {
    if (isOpen) {
      setOptions(
        getDefaultOptionsFromProps(propsOptions, propsSelectedOptionIds),
      );
    }
    setIsShownState(isOpen);
  };

  const handleUpdate = async (categoryIds) => {
    try {
      setIsLoading(true);
      await onUpdate(categoryIds);
      setIsShown(false);
    } catch (err) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  const popOverRef = useRef(null);
  const triggerRef = useRef(null);

  function isChildTarget(target) {
    return (
      popOverRef?.current?.contains(target)
      || triggerRef?.current?.contains(target)
    );
  }

  const handleOnClick = (event) => {
    if (isChildTarget(event.target)) {
      // no op
    } else {
      setIsShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOnClick, false);
    };
  });

  const onBlurHandler = (event) => {
    if (event.relatedTarget && !isChildTarget(event.relatedTarget)) {
      setIsShownState(false);
    }
  };

  const showPopoverFunc = () => (
    <Popover
      options={options}
      setOptions={setOptions}
      onUpdate={handleUpdate}
      loading={isLoading}
      disabled={disabled || readOnly}
      buttonText={buttonText}
    />
  );

  const showListFunc = () => {
    if (propsSelectedOptionIds && propsSelectedOptionIds.length > 0) {
      return (
        <ul className={styles.ul}>
          {options
            .filter(option => option.checked)
            .map(option => (
              <li key={option.id}>{option.name}</li>
            ))}
        </ul>
      );
    }
    return (<span>{emptySelectedOptionsMessage}</span>);
  };

  const showEmptyOptionsMessageFunc = () => (
    <span>{emptyOptionsMessage}</span>
  );

  function showPopover() {
    if ((disabled || readOnly) && listOnDisabled) {
      return showListFunc;
    }
    return showPopoverFunc;
  }

  const showDropdownContent = () => {
    if (options && options.length > 0) {
      return showPopover();
    }
      return showEmptyOptionsMessageFunc;
  };

  const displayValue = isFunction(valueRender) ? valueRender(value, propsOptions) : value;

  return (
    <div onBlur={onBlurHandler} className={styles.wrapper}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={triggerRef}>
              <Trigger
                ref={ref}
                className={className}
                isShown={isShown}
                setIsShown={setIsShown}
                value={displayValue}
                placeholder={placeholder}
                onBlur={onBlur}
              />
            </div>
          )}
        </Reference>
        {isShown && (
          <Popper
            placement="bottom-end"
            modifiers={[
              {
                name: 'preventOverflow',
                enabled: false,
              },
              {
                name: 'flip',
                enabled: flipToggle,
                options: {
                  padding: 48,
                },
              },
              {
                name: 'hide',
                enabled: false,
              },
            ]}
          >
            {({ ref, style, placement }) => (
              <div
                className={classNames(styles.popover, popoverClassName)}
                ref={ref}
                style={style}
                placement={placement}
              >
                <div ref={popOverRef}>
                  {showDropdownContent()()}
                </div>
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
};

CheckboxDropdown.defaultProps = {
  options: [],
  selectedOptionIds: [],
  valueRender: undefined,
  onUpdate: () => {},
  onError: () => {},
  value: undefined,
  placeholder: 'Select',
  className: '',
  popoverClassName: '',
  disabled: false,
  readOnly: false,
  listOnDisabled: false,
  emptyOptionsMessage: 'No options',
  emptySelectedOptionsMessage: 'No options selected',
  buttonText: 'Save',
  onBlur: () => {},
  flipToggle: false,
};

CheckboxDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  selectedOptionIds: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
  onError: PropTypes.func,
  valueRender: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  popoverClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  listOnDisabled: PropTypes.bool,
  emptyOptionsMessage: PropTypes.string,
  emptySelectedOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.string,
  onBlur: PropTypes.func,
  flipToggle: PropTypes.bool,
};

export default CheckboxDropdown;
