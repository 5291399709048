import map from 'lodash/map';
import pick from 'lodash/pick';
import join from 'lodash/join';
import { calculateTotalCost, calculateTotalRepayment } from 'shared/productUtils';

export const modificationFields = [
  'totalCost',
  'firstPeriodInterestOnly',
  'firstPeriodPrincipalAndInterest',
  'firstPeriodRepayment',
  'upfrontFee',
  'ongoingFee',
  'lmi',
  'comparisonRate',
  'standardRate',
];

const getEnrichedProducts = products =>
  map(products, product => ({
    ...product,
    variants: map(product.variants, (variant) => ({
      ...variant,
      modificationOriginals: variant.modificationOriginals || pick(variant, modificationFields),
    })),
    isSplitted: product.variants.length > 1,
    id: join(map(product.variants, 'id'), ''),
    totalRepayment: calculateTotalRepayment(product.variants),
    lenderName: product.variants[0].lenderName,
    totalCost: calculateTotalCost(product.variants),
  }));

export default getEnrichedProducts;
