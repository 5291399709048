import React, {
 useCallback, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import FormsyTextInput from 'shared/components/formFields/Formsy/FormsyTextInput';
import { ProductNameSelector } from './ProductNameSelector';
import { EditField, USER_PERMISSIONS } from '../../EditField';
import { InputYesNo } from '../inputs/InputYesNo';

const getIsHomeLoan = loanType => loanType === 'Home';

const getProductNameFields = ({
    updateProductName,
    isCustomProduct,
    onIsCustomProductChange,
    productName,
    lenderName,
    loanType,
}) => {
  const isHomeLoan = getIsHomeLoan(loanType);
  return [
    {
      title: 'Standard product',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        >
          <ProductNameSelector
            lenderName={lenderName}
            productName={productName}
            updateProductName={updateProductName}
            disabled={isCustomProduct}
            enableQuery={isHomeLoan}
          />
        </EditField>),
    },
    {
      title: 'Custom product',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        >
          <InputYesNo
            name="isCustomProduct"
            value={isCustomProduct}
            disabled={!isHomeLoan}
            onChange={onIsCustomProductChange}
          />
        </EditField>
      ),
    },
    {
      title: 'Custom/Lotus Notes product name',
      content: (
        <EditField
          permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        >
          {isCustomProduct && (
            <FormsyTextInput
              name="customProductName"
              value={productName}
              validations={{ maxLength: 150 }}
              validationErrors={{
                maxLength: 'Must be 150 characters or less',
              }}
              required={!!productName && isCustomProduct}
              fullWidth
            />
          )}
        </EditField>),
      hidden: !isCustomProduct,
    }];
};

const EditProduct = ({ productName, lenderName, loanType }) => {
  const [isInited, updateIsInited] = useState(false);
  const [isCustomProduct, updateIsCustomProduct] = useState(false);

  useEffect(() => {
    if (!getIsHomeLoan(loanType)) {
      updateIsCustomProduct(true);
    }
  }, [updateIsCustomProduct, loanType]);

  const onIsCustomProductChange = useCallback((value) => {
    updateIsCustomProduct(value.value);
  }, [updateIsCustomProduct]);

  const updateProductName = useCallback((value) => {
    if (!isInited) {
      updateIsCustomProduct(value === undefined);
      updateIsInited(true);
    }
  }, [updateIsCustomProduct, isInited, updateIsInited]);

  const fields = useMemo(() => getProductNameFields({
    updateProductName,
    isCustomProduct,
    onIsCustomProductChange,
    productName,
    lenderName,
    loanType,
  }), [updateProductName, loanType, isCustomProduct, onIsCustomProductChange, productName, lenderName]);

  return (
    <FieldsContainer
      fields={fields}
      title="Edit product"
    />
  );
};
EditProduct.defaultProps = {
  productName: undefined,
  lenderName: undefined,
};

EditProduct.propTypes = {
  productName: PropTypes.string,
  lenderName: PropTypes.string,
  loanType: PropTypes.string.isRequired,
 };
export default EditProduct;
