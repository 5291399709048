import React from 'react';
import PropTypes from 'prop-types';
import { ProductsComparisonTableContext } from '../../ProductSearchContext';
import ComparisonPreparerModal from './ComparisonPreparerModal';

const ConnectedComparisonPreparerModal = ({ isOpen, setOpen }) => (
  <ProductsComparisonTableContext.Consumer>
    {context => {
      const {
        userProfile, selectedBusinessId, dispatchDownloadPdf, comparison,
      } = context;
      const { isDownloading } = comparison;

      return (
        <ComparisonPreparerModal
          isOpen={isOpen}
          setOpen={setOpen}
          userProfile={userProfile}
          selectedBusinessId={selectedBusinessId}
          isDownloading={isDownloading}
          downloadPdf={dispatchDownloadPdf}
        />
        );
    }}
  </ProductsComparisonTableContext.Consumer>
);

ConnectedComparisonPreparerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConnectedComparisonPreparerModal;
