import React from 'react';
import { FieldArray } from 'redux-form';
import PlainTextField from 'shared/components/formFields/PlainTextField/PlainTextField';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import styles from '../Wrapper.module.scss';
import Incomes from './Incomes';

const RentalIncomes = () => (
  <FormMetaProvider readOnly>
    <FieldArray
      name="additionalIncomes.rentalIncomes"
      component={Incomes}
      hideDetails
      incomeTypeField={(
        <PlainTextField
          label="Income type"
          className={styles.type}
          value="Rental"
        />
      )}
    />
  </FormMetaProvider>
);

export default RentalIncomes;
