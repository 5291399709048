import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import { iSet } from 'shared/utils';
import TextInput from 'shared/components/formFields/TextInput';
import TextArea from 'shared/components/formFields/TextArea';
import Select from 'shared/components/formFields/Select';
import { Select as SelectInput } from 'shared/components/formFields/Select/Select';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import DateBox from 'devextreme-react/date-box';
import withFormMeta from 'shared/components/FormMeta/withFormMeta';
import ExternalInfoLink from 'shared/components/Links/ExternalInfoLink';
import { convertHourStrToFullTime, getUTCNow, to23HStrWithoutDate } from 'utils/datetime';
import 'devextreme/dist/css/dx.light.compact.css';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Checkbox from 'shared/components/formFields/Checkbox';
import Switch from 'shared/components/formFields/Switch';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import WithDisabledAndReadOnlyCheckboxDropdown from 'shared/components/formFields/CheckboxDropdown/WithDisabledAndReadOnlyCheckboxDropdown';
import styles from './GeneralInsurance.module.scss';
import OptOutInsuranceModal from './OptOutInsuranceModal/OptOutInsuranceModal';

const BuildingInsurance = 'Building Insurance';
const HomeAndContentsInsurance = 'Home and Contents Insurance';
const MotorVehicleInsurance = 'Motor Vehicle Insurance';
const LandlordInsurance = 'Landlord Insurance';
const InsuranceReferralReasonSplit = ';';
const DateBoxWithFormMeta = withFormMeta(DateBox);

const getToUpdateReferral = insuranceReferral => (
  {
    allianzPromptForReferralDate: getUTCNow(),
    allianzOptOut: false,
    allianzReferralDetails: {
      adviserName: insuranceReferral.adviser.label,
      adviserId: insuranceReferral.adviser.value,
      applicantId: insuranceReferral.applicant.value,
      applicantName: insuranceReferral.applicant.label,
      phoneNumber: insuranceReferral.phoneNumber,
      alternativePhoneNumber: insuranceReferral.alternativePhoneNumber || null,
      preferredTime: insuranceReferral.preferredTime,
      email: insuranceReferral.email,
      reason: insuranceReferral.reason.map(reason => reason.name).join(InsuranceReferralReasonSplit),
      message: insuranceReferral.message || null,
    },
  }
);

const getInitValue = value => value || '';

const getExistInsuranceReferral = allianzReferralDetails => (
  {
    adviser: {
      label: getInitValue(allianzReferralDetails.adviserName),
      value: getInitValue(allianzReferralDetails.adviserId),
    },
    applicant: {
      label: getInitValue(allianzReferralDetails.applicantName),
      value: getInitValue(allianzReferralDetails.applicantId),
    },
    phoneNumber: getInitValue(allianzReferralDetails.phoneNumber),
    alternativePhoneNumber: getInitValue(allianzReferralDetails.alternativePhoneNumber),
    email: getInitValue(allianzReferralDetails.email),
    preferredTime: allianzReferralDetails.preferredTime,
    reason: allianzReferralDetails.reason?.split(InsuranceReferralReasonSplit).map(reason => ({
      id: getInitValue(reason),
      name: getInitValue(reason),
    })),
    message: getInitValue(allianzReferralDetails.message),
    agreementChecked: true,
    agreement2Checked: true,
  }
);

const initInsuranceReferral = {
  adviser: null,
  applicant: null,
  phoneNumber: '',
  alternativePhoneNumber: '',
  email: '',
  preferredTime: null,
  reason: null,
  message: '',
  agreementChecked: false,
  agreement2Checked: false,
};

const validate = (data) => {
  const errors = {};
  if (isEmpty(trim(data.email))) {
    errors.email = 'Please add a contact email address';
  }

  if (isEmpty(trim(data.phoneNumber))) {
    errors.phoneNumber = 'Please add a phone number';
  }

  if (isEmpty(trim(data.adviser))) {
    errors.adviser = 'Please select a referring adviser';
  }

  if (!data.reason?.length) {
    errors.reason = 'Please select a referral reason';
  }

  return errors;
};

const defaultMeta = {
  adviser: {},
  applicant: {},
  phoneNumber: {},
  alternativePhoneNumber: {},
  preferredTime: {},
  email: {},
  reason: {},
  message: {},
};

const getAdviserOptionsAndSelected = (advisers, writerId) => {
  const options = advisers.map(adviser => ({
    label: adviser.fullName,
    value: adviser.id,
  })).sort((a, b) => a.label.localeCompare(b.label));
  const selectedAdviser = advisers.find(adviser => adviser.id === writerId);
  const selectedOption = selectedAdviser
    ? options.find(option => option.value === selectedAdviser.id)
    : undefined;
  return [options, selectedOption];
};

const getApplicantOptionsAndSelected = (applicants) => {
  const options = applicants.map(applicant => ({
    label: applicant.name,
    value: applicant.id,
  })).sort((a, b) => a.label.localeCompare(b.label));
  const primaryApplicant = applicants.find(applicant => applicant.isPrimary);
  const selectedOption = primaryApplicant
    ? options.find(option => option.value === primaryApplicant.id)
    : undefined;
  return [options, selectedOption];
};

const getSelectedApplicant = (applicants, selectedOption) =>
  selectedOption && applicants.find(applicant => applicant.id === selectedOption.value);

const getPhoneFromSelectedApplicant = (applicants, selectedOption) => {
  const applicant = getSelectedApplicant(applicants, selectedOption);
  return applicant ? getPhoneValue(applicant) : '';
};

const getEmailFromSelectedApplicant = (applicants, selectedOption) => {
  const applicant = getSelectedApplicant(applicants, selectedOption);
  return (applicant && applicant.email) || '';
};

const getPhoneValue = (applicant) => {
  const preferred = [applicant.mobile, applicant.home, applicant.work].filter(Boolean).find(phone => phone.isPreferred);
  return preferred ? preferred.number : '';
};

const filterPhoneNumber = phoneNumber => phoneNumber.replace(/[^ +\d]/, '');

const toOption = value => ({ id: value, name: value });

const reasonOptions = [BuildingInsurance, HomeAndContentsInsurance, LandlordInsurance, MotorVehicleInsurance]
  .map(toOption);

const getReasonFromLoans = loans =>
  (loans.every(loan => loan.purpose === 'Investment') ? toOption(BuildingInsurance) : toOption(HomeAndContentsInsurance));

const GeneralInsuranceReferral = (props) => {
  const {
    advisers, writer, applicants, loans, applicationId, getLoans, retrieveAdvisers,
    updateAllianzInsurance, hasEditPermission, isSentToAllianz, existAllianzReferral,
  } = props;

  const [adviserOptions, initialSelectedAdviser] = useMemo(
    () => getAdviserOptionsAndSelected(advisers, writer.id),
    [advisers, writer.id],
  );

  const [applicantOptions, initialSelectedApplicant] = useMemo(
    () => getApplicantOptionsAndSelected(applicants),
    [applicants],
  );

  const populateInsuranceReferral = useCallback(
    state => ({
      ...state,
      adviser: initialSelectedAdviser,
      applicant: initialSelectedApplicant,
      phoneNumber: getPhoneFromSelectedApplicant(applicants, initialSelectedApplicant),
      email: getEmailFromSelectedApplicant(applicants, initialSelectedApplicant),
      reason: [getReasonFromLoans(loans)],
    }),
    [initialSelectedAdviser, initialSelectedApplicant, applicants, loans],
  );

  const initialInsuranceReferral = useMemo(() => (existAllianzReferral.allianzReferralDetails
    ? getExistInsuranceReferral(existAllianzReferral.allianzReferralDetails)
    : populateInsuranceReferral(initInsuranceReferral)),
    [existAllianzReferral.allianzReferralDetails, populateInsuranceReferral]);

  const [insuranceReferral, setInsuranceReferral] = useState(initialInsuranceReferral);

  const isPristine = useMemo(
    () => isEqual(initialInsuranceReferral, insuranceReferral),
    [initialInsuranceReferral, insuranceReferral],
  );

  const errors = useMemo(() => validate(insuranceReferral), [insuranceReferral]);
  const [meta, setMeta] = useState(defaultMeta);
  const touchField = field => setMeta(iSet(meta, [field, 'touched'], true));

  const [isSendingReferral, setSendingReferral] = useState(false);

  useEffect(() => {
    if (!isSentToAllianz) {
      retrieveAdvisers();
      getLoans(applicationId);
    }
  }, [retrieveAdvisers, getLoans, applicationId, isSentToAllianz]);

  useEffect(
    () => {
      if (!isSentToAllianz) {
        setInsuranceReferral(populateInsuranceReferral);
      }
    },
    [isSentToAllianz, populateInsuranceReferral],
  );

  const [isOptOutModalOpen, setOptOutModalOpen] = useState(false);
  const sentDate = moment(existAllianzReferral.allianzPromptForReferralDate).format('D MMMM YYYY');

  const referringReasonsFormatter = ({ disabled, readOnly, value }) => (
    <SelectInput
      isDisabled={disabled}
      readOnly={readOnly}
      value={{
        label: value,
        value,
      }}
      styles={{
        singleValue: () => ({
          maxWidth: 'calc(100% - 8px)',
        }),
      }}
      fullWidth
    />
  );

  return (
    <div>
      {isSentToAllianz && (
      <div className={styles.insuranceSentMessage}>
        An insurance referral has been sent to Allianz on&nbsp;
        {sentDate}
      </div>
        )}
      <div className={styles.insuranceReferrer}>
        <Select
          className={styles.referrer}
          label="Referring adviser"
          errorMessage={meta.adviser.touched && errors.adviser}
          onChange={value => setInsuranceReferral(state => ({ ...state, adviser: value }))}
          options={adviserOptions}
          value={insuranceReferral.adviser}
          placeholder={isSentToAllianz ? '' : 'Select referring adviser'}
          onBlur={() => touchField('adviser')}
          fullWidth
        />
        <Select
          className={styles.applicant}
          label="Applicant"
          errorMessage={meta.applicant.touched && errors.applicant}
          onChange={value =>
            setInsuranceReferral(state => ({
              ...state,
              applicant: value,
              phoneNumber: getPhoneFromSelectedApplicant(applicants, value),
              alternativePhoneNumber: '',
              email: getEmailFromSelectedApplicant(applicants, value),
            }))}
          options={applicantOptions}
          value={insuranceReferral.applicant}
          placeholder={isSentToAllianz ? '' : 'Select applicant'}
          onBlur={() => touchField('applicant')}
          fullWidth
        />
        <TextInput
          className={styles.phoneNumber}
          errorMessage={meta.phoneNumber.touched && errors.phoneNumber}
          label="Phone number"
          onChange={value => setInsuranceReferral(state => ({ ...state, phoneNumber: filterPhoneNumber(value) }))}
          value={insuranceReferral.phoneNumber}
          onBlur={() => touchField('phoneNumber')}
          fullWidth
        />
        <TextInput
          className={styles.alternativePhoneNumber}
          errorMessage={meta.alternativePhoneNumber.touched && errors.alternativePhoneNumber}
          label="Alternative phone number"
          onChange={value => setInsuranceReferral(
              state => ({ ...state, alternativePhoneNumber: filterPhoneNumber(value) }),
            )}
          value={insuranceReferral.alternativePhoneNumber}
          onBlur={() => touchField('alternativePhoneNumber')}
          fullWidth
        />
        <NewInputLabelWrapper
          label="Preferred local time"
          className={styles.preferredTime}
          errorMessage={meta.preferredTime.touched && errors.preferredTime}
        >
          <DateBoxWithFormMeta
            placeholder="Any time"
            value={convertHourStrToFullTime(insuranceReferral.preferredTime)}
            onValueChanged={value => setInsuranceReferral(state => ({
              ...state,
              preferredTime: (value.value ? to23HStrWithoutDate(value.value) : null),
            }))}
            displayFormat="h:mm a"
            type="time"
            showClearButton
            useMaskBehavior
            onBlur={() => touchField('preferredTime')}
            disabled={isSentToAllianz}
          />
        </NewInputLabelWrapper>
        <TextInput
          className={styles.emailAddress}
          errorMessage={meta.email.touched && errors.email}
          label="Email address"
          onChange={value => setInsuranceReferral(state => ({ ...state, email: value }))}
          value={insuranceReferral.email}
          onBlur={() => touchField('email')}
          fullWidth
        />
        <WithDisabledAndReadOnlyCheckboxDropdown
          label="Referral reasons"
          className={styles.referralReason}
          additionalButton={(
            <ExternalInfoLink
              to="https://smartline.zendesk.com/hc/en-us/articles/4408566424975"
              text="Important update on Allianz referral"
              externalInfoLinkClassName={styles.externalInfoLink}
              textClassName={styles.externalInfoText}
            />
          )}
          readOnlyFormatter={referringReasonsFormatter}
          disabledOnlyFormatter={referringReasonsFormatter}
          labelProps={{ className: styles.insuranceReferrerReasonsLabel }}
          inputProps={{ className: styles.referralReasonInput }}
          onUpdate={value => setInsuranceReferral(state => ({ ...state, reason: value }))}
          options={reasonOptions}
          value={insuranceReferral.reason?.map(it => it.name).join(InsuranceReferralReasonSplit)}
          errorMessage={meta.reason.touched && errors.reason}
          onBlur={() => touchField('reason')}
          selectedOptionIds={insuranceReferral.reason?.map(it => it.id)}
          buttonText="Select"
          fullWidth
          customOverride
        />
        <TextArea
          errorMessage={meta.message.touched && errors.message}
          className={styles.message}
          label="Additional information"
          value={insuranceReferral.message}
          onChange={value => setInsuranceReferral(state => ({ ...state, message: value }))}
          onBlur={() => touchField('message')}
          fullWidth
        />
        <div className={styles.agreementCheckContainer}>
          <div className={styles.description}>
            Please read the below statement to your customer prior to completing the referral form.
          </div>
          <div className={styles.wrapper}>
            <Switch
              switchedOnText="Yes"
              switchedOffText="No"
              className={styles.switch}
              onChange={e => setInsuranceReferral(iSet(insuranceReferral, 'agreementChecked', e.target.checked))}
              checked={insuranceReferral.agreementChecked}
            />
            <span className={`${isSentToAllianz ? 'disabledFormFields' : ''}`}>
              Do you consent to Allianz contacting you by telephone or email about the insurance
              products you have selected to provide you with a quote and so that you can apply for cover?
            </span>
            <span className={`${isSentToAllianz ? 'disabledFormFields' : ''}`}>
              If you consent, your information will be shared with Allianz Australia
              Insurance Limited. You can find more information about their Privacy
              Policy which can be found on the Allianz website.
            </span>
          </div>
        </div>
        <div className={styles.agreement2CheckContainer}>
          <Checkbox
            onChange={({ checked }) => setInsuranceReferral(iSet(insuranceReferral, 'agreement2Checked', checked))}
            checked={insuranceReferral.agreement2Checked}
            labelName="By ticking this check box, you acknowledge that you have read the above statement to your customer and obtain their consent."
          />
        </div>
      </div>
      {!isSentToAllianz && (
        <div className={styles.buttonGroup}>
          <PrimaryButton
            disabled={
              !(
                hasEditPermission
                && insuranceReferral.agreementChecked
                && insuranceReferral.agreement2Checked
              )
              || isSendingReferral
            }
            onClick={() => {
              if (!isEmpty(errors)) {
                setMeta(mapValues(meta, () => ({ touched: true })));
              } else {
                setSendingReferral(true);
                updateAllianzInsurance(applicationId, getToUpdateReferral(insuranceReferral)).catch(() => {
                  setSendingReferral(false);
                });
              }
            }}
          >
            Share applicant information
          </PrimaryButton>
          <TertiaryButton
            onClick={() => setOptOutModalOpen(true)}
            disabled={!hasEditPermission}
            className={styles.optOutButton}
          >
            Opt out
          </TertiaryButton>
          {isOptOutModalOpen && (
            <OptOutInsuranceModal
              isOpen
              onClose={setOptOutModalOpen}
              updateAllianzInsurance={updateAllianzInsurance}
              applicationId={applicationId}
            />
          )}
        </div>
      )}
      <UnsavedChangePrompt shouldConfirm={!isPristine} />
    </div>
  );
};

const PhoneType = PropTypes.shape({
  isPreferred: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
});

GeneralInsuranceReferral.propTypes = {
  advisers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    allianzId: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  })).isRequired,
  writer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    mobile: PhoneType,
    work: PhoneType,
    home: PhoneType,
  })).isRequired,
  loans: PropTypes.arrayOf(PropTypes.shape({
    purpose: PropTypes.string,
  })).isRequired,
  applicationId: PropTypes.string.isRequired,
  getLoans: PropTypes.func.isRequired,
  retrieveAdvisers: PropTypes.func.isRequired,
  updateAllianzInsurance: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isSentToAllianz: PropTypes.bool.isRequired,
  existAllianzReferral: PropTypes.shape().isRequired,
};

export default GeneralInsuranceReferral;
