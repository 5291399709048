import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlainTextField from 'shared/components/formFields/PlainTextField/PlainTextField';
import styles from './Expense.module.scss';
import { EMPTY_CHARACTER, INVESTMENT_UTILITIES } from './constants';
import { formatAmountWithDefault } from '../../../../../utils/formatters';

const InvestmentExpense = ({ expense }) => {
  const { addressName, frequency, value } = expense;
  return (
    <div className={classNames(styles.expenseWrapper, styles.upperBorder)}>
      <div className={classNames(styles.typeLabel, 'hint-wrap')}>
        <div className={`hint ${styles.investmentExpenseLabel}`} hint={INVESTMENT_UTILITIES.hint}>{`${INVESTMENT_UTILITIES.label} - ${addressName || 'N/A'}`}</div>
      </div>
      <PlainTextField
        label="Amount"
        className={styles.amount}
        textClassName={styles.alignTextRight}
        value={formatAmountWithDefault(value)}
      />
      <PlainTextField
        label={EMPTY_CHARACTER}
        className={classNames(styles.unit, styles.investmentUnit)}
        value={frequency}
      />
    </div>
  );
};

InvestmentExpense.propTypes = {
  expense: PropTypes.object.isRequired,
};

export default React.memo(InvestmentExpense);
