import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import styles from '../styles.module.scss';
import { formatAmountWithDefault, formatCurrency } from '../../../../../../utils/formatters';
import SubSection from '../components/SubSection';
import { SubtotalContributions } from './SubtotalContributions';
import { getCheckboxContent, getNumberContent, getOtherFields } from '../contentUtils';

const getFields = ({
  genuineSavings,
  firstHomeOwnerGrant,
  gift,
  initialDepositAmount,
  netProceedsFromSaleOfProperty,
}) => [
  {
    title: 'Genuine savings',
    content: formatAmountWithDefault(genuineSavings),
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  },
  {
    title: 'First home owner grant',
    content: formatAmountWithDefault(firstHomeOwnerGrant),
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  },
  {
    title: 'Gift',
    content: formatAmountWithDefault(gift),
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  },
  {
    title: 'Deposit paid',
    content: formatAmountWithDefault(initialDepositAmount),
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  },
  {
    title: 'Sale proceed funds',
    content: formatAmountWithDefault(netProceedsFromSaleOfProperty),
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  },
];

const ViewFundsAvailable = (props) => {
  const {
    genuineSavings,
    firstHomeOwnerGrant,
    gift,
    initialDepositAmount,
    netProceedsFromSaleOfProperty,
    otherFunds,
    capitaliseLmi,
    proposedLoanAmountBeforeLmi,
    baseLoanAmount,
    userGuidanceCodes,
  } = props;

  const fields = useMemo(() => getFields({
    genuineSavings,
    firstHomeOwnerGrant,
    gift,
    initialDepositAmount,
    netProceedsFromSaleOfProperty,
  }), [
    genuineSavings,
    firstHomeOwnerGrant,
    gift,
    initialDepositAmount,
    netProceedsFromSaleOfProperty,
  ]);

  const otherFundsFields = useMemo(() => getOtherFields(otherFunds), [otherFunds]);

  const lmiFields = useMemo(() => ([
    getCheckboxContent('Capitalise LMI', capitaliseLmi),
  ]), [capitaliseLmi]);

  const baseLoanAmountFields = useMemo(() => ([
    getNumberContent(`Base loan amount (${userGuidanceCodes.loanAmount})`, baseLoanAmount),
  ]), [baseLoanAmount, userGuidanceCodes.loanAmount]);
  return (
    <div className={styles.contributions}>
      <h3 className={styles.title}>Funds available</h3>
      <InformationSection
        wrapperStyle={styles.contributionsWrapper}
        contentWrapperStyle={styles.contributionsContentWrapper}
        titleWrapperStyle={styles.titleWrapperStyle}
        title="Contributions"
      >
        <SubSection>
          <FieldsContainer
            fields={fields}
            title=""
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
        </SubSection>
        <SubSection header="Other funds" className={styles.otherFundsSection}>
          <FieldsContainer
            fields={otherFundsFields}
            title=""
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
        </SubSection>
        <SubtotalContributions contributions={props} userGuidanceCode={userGuidanceCodes.contributions} />
      </InformationSection>
      <InformationSection titleWrapperStyle={styles.titleWrapperStyle} title="Loan amount">
        <SubSection>
          <div className={styles.lmiField}>
            <FieldsContainer
              fields={lmiFields}
              title=""
              fieldsWrapperClassName={styles.fieldsWrapper}
              className={styles.fieldsContainerWrapper}
            />
          </div>
          <div className={styles.suggestedLoanAmountField}>
            <div>Minimum loan before LMI</div>
            <div>{formatCurrency(proposedLoanAmountBeforeLmi, { precision: 2 })}</div>
          </div>
        </SubSection>
        <SubSection>
          <FieldsContainer
            fields={baseLoanAmountFields}
            title=""
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
        </SubSection>
      </InformationSection>
    </div>
  );
};

const mapStateToProps = (state) => {
  const contributions = get(state, 'application.applicationDetail.fundingWorksheet.contributions');
  const loanAmount = get(state, 'application.applicationDetail.fundingWorksheet.loanAmount');
  const proposedLoanAmountBeforeLmi = get(state, 'application.fundingWorksheetCalculation.fundingPositions.proposedLoanAmountBeforeLmi');
  const pickedContributions = pick(
    contributions,
    [
      'genuineSavings',
      'firstHomeOwnerGrant',
      'gift',
      'initialDepositAmount',
      'netProceedsFromSaleOfProperty',
      'otherFunds',
    ],
  );
  return {
    ...pickedContributions,
    ...loanAmount,
    proposedLoanAmountBeforeLmi,
  };
};

ViewFundsAvailable.defaultProps = {
  genuineSavings: undefined,
  firstHomeOwnerGrant: undefined,
  gift: undefined,
  initialDepositAmount: undefined,
  netProceedsFromSaleOfProperty: undefined,
  otherFunds: undefined,
  capitaliseLmi: false,
  proposedLoanAmountBeforeLmi: undefined,
  baseLoanAmount: undefined,
};

ViewFundsAvailable.propTypes = {
  genuineSavings: PropTypes.number,
  firstHomeOwnerGrant: PropTypes.number,
  gift: PropTypes.number,
  initialDepositAmount: PropTypes.number,
  capitaliseLmi: PropTypes.bool,
  proposedLoanAmountBeforeLmi: PropTypes.number,
  baseLoanAmount: PropTypes.number,
  netProceedsFromSaleOfProperty: PropTypes.number,
  otherFunds: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
    fieldAmount: PropTypes.number,
  })),
  userGuidanceCodes: PropTypes.shape({
    contributions: PropTypes.string,
    loanAmount: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(ViewFundsAvailable);
