import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

const Loader = ({ round }) =>
  <div className={round ? styles.roundLoader : styles.barLoader} />;

Loader.defaultProps = {
  round: false,
};

Loader.propTypes = {
  round: PropTypes.bool,
};

export default Loader;
