import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { getLenderValidationConfigById } from '../../redux/lendersConfig';

const getIsEditing = state => state.loans.loan.isEditing;

const getSelectedLoanType = state => state.loans.loan.selectedLoanType;

const getExistingLoanType = state => state.loans.loan.data.loanType;

const getExistingLender = state => state.loans.loan.data.lender;

const getSelectedLender = state => get(state, 'loans.loan.selectedLender');

const getLendersConfig = state => state.lendersConfig;

export const getLoanType = createSelector(
  [getIsEditing, getSelectedLoanType, getExistingLoanType],
  (isEditing, selectedLoanType, existingLoanType) => (isEditing ? selectedLoanType : existingLoanType),
);

export const getInitialLender = createSelector(
  [getSelectedLoanType, getExistingLoanType, getExistingLender],
  (selectedLoanType, existingLoanType, existingLender) =>
    (selectedLoanType !== existingLoanType ? undefined : existingLender),
);

export const getLender = createSelector(
  [getIsEditing, getSelectedLender, getExistingLender],
  (isEditing, selectedLender, existingLender) => (isEditing ? selectedLender : existingLender),
);

export const getLenderName = createSelector([getLender], lender => get(lender, 'name'));

export const getAccountNumberValidationRule = createSelector(
  [getLendersConfig, getLender],
  (lendersConfig, lender) => getLenderValidationConfigById(lendersConfig, get(lender, 'lenderId')),
);

export const getAccountNumberValidationRuleForSpecificLender = (lenderId) => createSelector(
  getLendersConfig,
  (lendersConfig) => getLenderValidationConfigById(lendersConfig, lenderId),
);

const SMARTLINE_ASSET_FINANCE = 'Smartline Asset Finance';
const XENIUM_FINANCE = 'Xenium Finance';

export const getIsLenderSmartlineAssetFinance = createSelector(
  [getLenderName],
  lenderName => SMARTLINE_ASSET_FINANCE === lenderName,
);

export const getIsLenderXeniumFinance = createSelector(
  [getLenderName],
  lenderName => XENIUM_FINANCE === lenderName,
);
