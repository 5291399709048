import React from 'react';
import PropTypes from 'prop-types';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import styles from './ApplicationDocuments.module.scss';
import Forms from './Forms';
import Soma from './Soma';

const ApplicationDocuments = ({ hasEditPermission }) => (
  <InformationSection title="Application documents">
    <div className={styles.applicationDocumentsWrapper}>
      <section className={styles.formsSection}>
        <Forms hasEditPermission={hasEditPermission} />
      </section>
      <section className={styles.somaSelection}>
        <Soma hasEditPermission={hasEditPermission} />
      </section>
    </div>
  </InformationSection>
);

ApplicationDocuments.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

export default ApplicationDocuments;
