import React from 'react';
import PropTypes from 'prop-types';
import ApplicationDetailOverview from '../ApplicationDetailOverview';
import ApplicationDetailContentNav from '../ApplicationDetailContentNav';

export const ApplicationDetailContainer = ({ hasEditPermission }) => (
  <>
    <ApplicationDetailOverview
      hasEditPermission={hasEditPermission}
    />
    <ApplicationDetailContentNav />
  </>
);

ApplicationDetailContainer.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};
