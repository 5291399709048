import { connect } from 'react-redux';
import LoanProtectionInsurance from './LoanProtectionInsurance';
import * as actions from '../../../redux/action';

const mapStateToProps = state => ({
  applicationId: state.application.applicationDetail.id,
  loanProtectionInsurance: state.application.applicationDetail.insuranceReferrals.loanProtectionInsurance,
  isUpdating: state.application.insuranceReferral.loanProtectionInsurance.isUpdating,
});

const mapDispatchToProps = {
  updateLoanProtectionInsurance: actions.updateLoanProtectionInsurance,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanProtectionInsurance);
