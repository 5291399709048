import React from 'react';
import find from 'lodash/find';
import { getTargetChangedValue, isPropertyChanged } from '../../../../../../constants/utils';
import { TYPES } from '../../../../../../constants/activityLogTypes';
import { outcomeTypes } from '../../../../../../constants/loanProtectionInsurance';
import { getBasicLogInfo } from './utils';

const outcomes = outcomeTypes.map(type => ({
  value: type.key,
  displayedValue: type.value,
}));

const getDisplayedValue = (options, value) => (find(options, { value })).displayedValue;

const loanProtectionInsuranceChangeLogTransformer = (origin) => {
  let content;
  const discussedWithClientsPath = 'insuranceReferrals.loanProtectionInsurance.compliance.discussedWithClients';
  const isDiscussedWithClientsChanged = isPropertyChanged(origin, 'discussedWithClients');
  const currentDiscussedWithClientsValue = getTargetChangedValue(origin, discussedWithClientsPath);
  const isDiscussedWithClientsChangedToFalse = isDiscussedWithClientsChanged
    && currentDiscussedWithClientsValue === 'false';

  if (isDiscussedWithClientsChangedToFalse) {
    content = 'Discussed loan protection insurance with client switch turned from yes to no.';
  } else {
    const outcomePath = 'insuranceReferrals.loanProtectionInsurance.compliance.outcome';
    const currentOutcomeValue = getTargetChangedValue(origin, outcomePath);
    const displayedValue = getDisplayedValue(outcomes, currentOutcomeValue);
    content = (
      <span>
        Discussed loan protection insurance with client.
        <br />
        <span>
          {`${displayedValue}.`}
        </span>
      </span>
    );
  }

  return ({
    ...getBasicLogInfo(origin),
    actionTime: origin.actionTime,
    operator: origin.userName,
    type: TYPES.INSURANCE,
    content,
  });
};

export default loanProtectionInsuranceChangeLogTransformer;
