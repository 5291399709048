import get from 'lodash/get';
import set from 'lodash/set';

const slGold = '#E7A615';
const lightSquash = '#FAE89F';
const tealBlue = '#0080A6';
const sinbad = '#99CCDB';
const botticelli = '#CDE6ED';
const mineShaft = '#323232';
const brownGrey = '#9B9B9B';
const brick = '#AA2020';
const mistyRose = '#FFE0E0';
const green = '#5BA053';
const bluestone = '#333f48';
const ocean = '#00639E';
const red = '#DF585C';

const colors = [
  ocean,
  bluestone,
  mistyRose,
  green,
  tealBlue,
  sinbad,
  lightSquash,
  slGold,
  brownGrey,
  mineShaft,
  botticelli,
  red,
  brick,
];

const colorCache = {
  advisers: {},
  lenders: {},
};

let adviserColorIndex = 0;
let lenderColorIndex = 0;

export const getColorForAdviser = (adviserId) => {
  const path = ['advisers', adviserId];
  const cachedColor = get(colorCache, path, null);
  if (cachedColor) {
    return cachedColor;
  }
  const arrangedColor = colors[adviserColorIndex % colors.length];
  adviserColorIndex += 1;
  set(colorCache, path, arrangedColor);
  return arrangedColor;
};

export const getColorForLender = (lenderName, index, context) => {
  const path = ['lenders', lenderName];

  const configColor = get(context, ['lenders', lenderName, 'color'], null);
  if (configColor) {
    set(colorCache, path, configColor);
    return configColor;
  }

  const cachedColor = get(colorCache, path, null);
  if (cachedColor) {
    return cachedColor;
  }

  const arrangedColor = colors[lenderColorIndex % colors.length];
  lenderColorIndex += 1;
  set(colorCache, path, arrangedColor);
  return arrangedColor;
};
