import { TableSelection } from '@devexpress/dx-react-grid-bootstrap4';
import cx from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

export const calculateFixedColumns = (columns, withSelection) => {
  const result = columns
    .filter(column => column.sticky)
    .map(column => column.name);
  return withSelection ? [TableSelection.COLUMN_TYPE, ...result] : result;
};

export const calculateColumnExtensions = columns =>
  columns.map(column => ({
    align: column.align,
    wordWrapEnabled: column.wordWrapEnabled,
    columnName: column.name,
    width: column.width,
  }));

export const calculateIntegratedSortingColumns = (sortingState, normalColumns) =>
  normalColumns
    .filter(
      it => isFunction(it.sorter) || isObject(it.sorter),
    )
    .map(({ sorter, name }) => {
      const direction = find(sortingState, { columnName: name })?.direction;
      return ({
        columnName: name,
        compare: isFunction(sorter)
          ? (a, b) => sorter(a, b, direction)
          : get(sorter, direction),
      });
    });

export const calculateFilteringStateColumnExtensions = columns =>
  columns
    .filter(col => col.filterDisabled)
    .map(({ name }) => ({
      columnName: name,
      filteringEnabled: false,
    }));

export const calculateCustomColumnFilters = columns => columns
  .filter(column => column.filterWithRenderer)
  .map(column => ({
    columnName: column.name,
    predicate: (value, filter) => column.renderer(value)
        .includes(filter.value),
  }));

export const mergeClassNamesObjects = (argA, argB) => {
  const a = { ...argA };
  const b = { ...argB };
  return mergeWith(a, b, (valueA, valueB) => cx(valueA, valueB));
};
