import PropTypes from 'prop-types';
import ProductShape from './ProductShape';

export default PropTypes.shape({
  variants: PropTypes.arrayOf(ProductShape).isRequired,
  isSplitted: PropTypes.bool.isRequired,
  totalRepayment: PropTypes.number,
  totalCost: PropTypes.number,
  lenderName: PropTypes.string.isRequired,
});
