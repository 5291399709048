import PropTypes from 'prop-types';
import Modal from 'shared/components/Modal';
import React, { useCallback, useMemo } from 'react';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import get from 'lodash/get';
import { connect } from 'react-redux';
import styles from './RemoveFixedTrailModal.module.scss';
import { FETCHING_STATUS } from '../../../constants';
import { handleRemoveFixedTrail } from './redux/removeFixedTrailRedux';

const RemoveFixedTrailModal = (
  {
    isOpen, onClose, updatingStatus, dispatchHandleRemoveFixedTrail,
  },
) => {
  const isUpdating = useMemo(() => updatingStatus === FETCHING_STATUS.START, [updatingStatus]);

  const handleClose = useMemo(() => (value) => {
    if (!isUpdating) {
      onClose(value);
    }
  }, [isUpdating, onClose]);
  const onRemoveFixedTrailSaveClicked = useCallback(async () => {
    await dispatchHandleRemoveFixedTrail(
    );
    onClose(true);
  }, [dispatchHandleRemoveFixedTrail, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      header="Remove fixed trails?"
      disabled={isUpdating}
      footer={(
        <PrimaryButton
          onClick={onRemoveFixedTrailSaveClicked}
          loading={isUpdating}
        >
          Remove
        </PrimaryButton>
      )}
      onRequestClose={handleClose}
      className={styles.modal}
    >
      <p>All selected loans will be paid using the franchise’s default trail percentage. </p>
    </Modal>
  );
};

RemoveFixedTrailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updatingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  dispatchHandleRemoveFixedTrail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  updatingStatus: get(state, 'loans.fixedTrails.updatingStatus'),
});

export default connect(mapStateToProps, {
  dispatchHandleRemoveFixedTrail: handleRemoveFixedTrail,
})(RemoveFixedTrailModal);
