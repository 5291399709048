import { combineReducers } from 'redux';
import { reducer as reportingMeta } from './reducers/reportingMetaReducer';
import { reducer as submissionReport } from './reducers/submissionReport';
import { reducer as settlementReport } from './reducers/settlementReport';
import { reducer as expectedSettlementReport } from './reducers/expectedSettlementReport';
import { reducer as lenderReconciliationReportDownload } from './reducers/lenderReconciliationReportDownload';
import { reducer as internallyReferredAssetFinanceReportDownload } from './reducers/internallyReferredAssetFinanceReportDownload';
import { reducer as commissionSummary } from './reducers/commissionSummary';
import { reducer as settlementSummaryReport } from './reducers/settlementSummaryReport';
import { reducer as conversionRateReport } from './reducers/conversionRateReport';

import * as actionCreators from './actionCreators';

export const reducer = combineReducers({
  submissionReport,
  settlementReport,
  expectedSettlementReport,
  reportingMeta,
  lenderReconciliationReportDownload,
  internallyReferredAssetFinanceReportDownload,
  commissionSummary,
  settlementSummaryReport,
  conversionRateReport,
});

export const {
  retrieveLoanViewsForSubmissionAndSettlementReport,
  retrieveLoanViewsForExpectedSettlementReport,
  retrieveReportingMeta,
  downloadLenderReconciliationReport,
  downloadInternallyReferredAssetFinanceReport,
  retrieveCommissionSummary,
  retrieveLoanViewsForSettlementSummaryReport,
  retrieveLoanViewsForConversionRateReport,
} = actionCreators;
