import get from 'lodash/get';
import graphQL from 'MyBusiness/components/Commissions/commissionsGraphQL';
import { ACTIONS as BUSINESS_ACTIONS } from 'redux/business/business';

export const ACTIONS = {
  GET_FINANCIAL_YEARS_START:
    'MY_BUSINESS:COMMISSIONS:GET_FINANCIAL_YEARS_START',
  GET_FINANCIAL_YEARS_SUCCESS:
    'MY_BUSINESS:COMMISSIONS:GET_FINANCIAL_YEARS_SUCCESS',
  GET_FINANCIAL_YEARS_ERRORS:
    'MY_BUSINESS:COMMISSIONS:GET_FINANCIAL_YEARS_ERRORS',
  UPDATE_FINANCIAL_YEAR: 'MY_BUSINESS:COMMISSIONS:UPDATE_FINANCIAL_YEAR',
};

const getFinancialYearsStart = () => ({
  type: ACTIONS.GET_FINANCIAL_YEARS_START,
});

const getFinancialYearsSuccess = years => ({
  type: ACTIONS.GET_FINANCIAL_YEARS_SUCCESS,
  payload: years,
});

const getFinancialYearsErrors = errors => ({
  type: ACTIONS.GET_FINANCIAL_YEARS_ERRORS,
  errors,
});

const updateFinancialYearAction = financialYear => ({
  type: ACTIONS.UPDATE_FINANCIAL_YEAR,
  payload: financialYear,
});

const isBusinessLoaded = business => business && business.id && business.displayName;

export const getFinancialYears = () => async (dispatch, getState) => {
  const business = get(getState(), 'business.selectedBusiness');
  if (!isBusinessLoaded(business)) {
    return;
  }
  try {
    dispatch(getFinancialYearsStart());
    const response = await graphQL.getFinancialYears(business.id);
    dispatch(getFinancialYearsSuccess(response.data.financialYears));
  } catch (errors) {
    dispatch(getFinancialYearsErrors(errors));
  }
};

export const updateFinancialYear = financialYear => (dispatch) => {
  dispatch(updateFinancialYearAction(financialYear));
};

const initialState = {
  selected: null,
  years: [],
  isLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_FINANCIAL_YEARS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.GET_FINANCIAL_YEARS_SUCCESS: {
      const years = action.payload
        .map(data => data.year)
        .sort()
        .reverse();
      const selected = years.length > 0 ? years[0] : null;
      return {
        ...state,
        isLoading: false,
        years,
        selected,
      };
    }
    case ACTIONS.GET_FINANCIAL_YEARS_ERRORS:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };
    case ACTIONS.UPDATE_FINANCIAL_YEAR:
      return {
        ...state,
        selected: action.payload,
      };
    case BUSINESS_ACTIONS.SELECT_BUSINESS:
      return { ...initialState };
    default:
      return state;
  }
};
