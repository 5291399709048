import React, { useMemo, useState } from 'react';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import Select from 'shared/components/formFields/Select';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Switch from 'shared/components/formFields/Switch';
import PropTypes from 'prop-types';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import ExternalLink from 'shared/components/Links/ExternalLink';
import styles from './LoanProtectionInsurance.module.scss';
import { outcomeTypes } from '../../../../../constants/loanProtectionInsurance';

const validate = ({ compliance }) => {
  const { discussedWithClients, outcome } = compliance;
  if (discussedWithClients && outcome === null) {
    return { outcome: 'outcome is required' };
  }
  if (!discussedWithClients && outcome !== null) {
    return { outcome: 'outcome is forbidden' };
  }
  return null;
};

const outcomeOptions = outcomeTypes.map(type => ({ value: type.key, label: type.value }));

const LoanProtectionInsurance = ({
                                   applicationId,
                                   loanProtectionInsurance,
                                   updateLoanProtectionInsurance,
                                   hasEditPermission,
                                   isUpdating,
}) => {
  const { compliance } = loanProtectionInsurance;

  const [outcome, setOutcome] = useState(compliance.outcome);
  const [discussedWithClients, setDiscussedWithClients] = useState(compliance.discussedWithClients);

  const onSwitchValueChange = e => {
    setDiscussedWithClients(e.target.checked);
    if (!e.target.checked) {
      setOutcome(null);
    }
  };

  const outcomeValue = useMemo(() => find(outcomeOptions, { value: outcome }) || null, [outcome]);
  const formData = {
    compliance: {
      discussedWithClients,
      outcome,
    },
  };

  const handleOnSave = async () => {
    await updateLoanProtectionInsurance(applicationId, formData);
  };

  const isPristine = isEqual(formData.compliance, pick(compliance, ['discussedWithClients', 'outcome']));
  const errors = validate(formData);

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.row}>
          <div className={styles.wrapper}>
            <Switch
              switchedOnText="Yes"
              switchedOffText="No"
              className={styles.switch}
              checked={discussedWithClients}
              onChange={onSwitchValueChange}
              disabled={isUpdating}
            />
            <span>
              I have made the client aware of the importance
              of considering protection for their loan,
              and I have presented the client with the opportunity to obtain protection
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <Select
            label="Select outcome"
            options={outcomeOptions}
            value={outcomeValue}
            onChange={option => setOutcome(option.value)}
            disabled={isUpdating || !discussedWithClients}
            className={styles.outcome}
          />
          <PrimaryButton
            className={styles.saveButton}
            disabled={!hasEditPermission || isUpdating || isPristine || Boolean(errors)}
            loading={isUpdating}
            onClick={handleOnSave}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
      <div className={styles.linksWrapper}>
        <span className={styles.linksTitle}>Only complete the ALI application process if you are authorised.</span>
        <div className={styles.links}>
          <span>ALI</span>
          <ExternalLink to="https://www.aligroup.com.au/cmlogin.aspx">Website</ExternalLink>
          <span>If not proceeding</span>
          <ExternalLink to="https://sites.google.com/smartline.com.au/operations-manual/9-forms-stationery/forms-and-checklists">Client acknowledgement</ExternalLink>
        </div>
      </div>
      <UnsavedChangePrompt shouldConfirm={!isPristine} />
    </div>
  );
};

LoanProtectionInsurance.propTypes = {
  applicationId: PropTypes.string.isRequired,
  updateLoanProtectionInsurance: PropTypes.func.isRequired,
  loanProtectionInsurance: PropTypes.shape({
    compliance: PropTypes.shape({
      discussedWithClients: PropTypes.bool.isRequired,
      outcome: PropTypes.string,
    }),
  }).isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default LoanProtectionInsurance;
