import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useLocation } from 'react-router-dom';
import { ConfirmContext } from '../ConfirmProvider';

export const confirmMessage = 'You have unsaved changes on this page. Do you want to discard your changes or stay on this page?';

export const confirmMeta = {
  primaryButtonText: 'Stay on page',
  secondaryButtonText: 'Discard changes',
  title: 'Unsaved changes',
};

const UnsavedChangePrompt = ({ shouldConfirm, excludeCurrentLocation }) => {
  const confirm = useContext(ConfirmContext);
  const { pathname } = useLocation();
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (shouldConfirm) {
        e.returnValue = shouldConfirm;
      }
    };
    const handleBeforeLogout = (e) => {
      if (shouldConfirm) {
        e.confirmed = confirm({ ...confirmMeta, message: confirmMessage }).then(stay => !stay);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('beforeLogout', handleBeforeLogout);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('beforeLogout', handleBeforeLogout);
    };
  }, [shouldConfirm, confirm]);

  return (
    <Prompt
      when={shouldConfirm}
      message={(location) => (excludeCurrentLocation && (pathname === location.pathname) ? true : confirmMessage)}
    />
  );
};

UnsavedChangePrompt.defaultProps = {
  shouldConfirm: false,
  excludeCurrentLocation: true,
};

UnsavedChangePrompt.propTypes = {
  shouldConfirm: PropTypes.bool,
  excludeCurrentLocation: PropTypes.bool,
};

export default UnsavedChangePrompt;
