import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const ArrowRightSolid = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/arrowRight-solid"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon id="Path" fill={color} points="16 12 10 18 10 6" />
    </g>
  </svg>
);

ArrowRightSolid.defaultProps = iconDefaultProps;
ArrowRightSolid.propTypes = baseIcon;

export default ArrowRightSolid;
