import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';

const initialState = {};

const factFindDetail = createReducer(initialState, {
  [types.SUBMIT_APPLICATION_SUCCESS]: (state, action) => ({ ...state, ...action.data }),
});

export default factFindDetail;
