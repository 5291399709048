import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/Modal';
import CustomProductForm from '../CustomProductForm';
import styles from '../CustomProduct.module.scss';

const CustomProductModal = ({
    isOpen,
    toggle,
    lenders,
    loansCriteria,
    totalCostTerm,
    repaymentFrequency,
  }) => (
    <Modal
      header="Enter custom product details"
      isOpen={isOpen}
      onRequestClose={toggle}
      size="LARGE"
      className={styles.modal}
    >
      <CustomProductForm
        lenders={lenders}
        toggleModal={toggle}
        loansCriteria={loansCriteria}
        totalCostTerm={totalCostTerm}
        repaymentFrequency={repaymentFrequency}
      />
    </Modal>
);

CustomProductModal.defaultProps = {
  isOpen: false,
};

CustomProductModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  lenders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
  })).isRequired,
  loansCriteria: PropTypes.array.isRequired,
  totalCostTerm: PropTypes.number.isRequired,
  repaymentFrequency: PropTypes.string.isRequired,
};

export default CustomProductModal;
