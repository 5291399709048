import React, { useContext, useMemo } from 'react';
import { Field, change, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import ReduxDateSelector from 'shared/components/formFields/ReduxForm/DateSelector';
import RadioButton from 'shared/components/formFields/RadioButtonGroup/RadioButton';
import withFormMeta from 'shared/components/FormMeta/withFormMeta';
import PlainTextField from 'shared/components/formFields/PlainTextField/PlainTextField';
import Icon from 'shared/components/Icon/Icon';
import { ConfirmContext } from 'shared/components/ConfirmProvider';
import styles from './SummarySection.module.scss';
import { toDateWithSlash } from '../../../../../../../utils/datetime';
import {
  CompanyApplicantSelector,
  PersonApplicantSelector,
} from '../../../../../ApplicantSelectorWithCompanyAndGuarantorsToggleOn';
import graphQL from '../../../../redux/applicationDetailsGraphQL';
import convertClientToNewApplicant, { convertCompanyClientToNewApplicant } from '../utils/convertClientToNewApplicant';
import removeApplicantConfirm from '../utils/removeApplicantConfirm';
import getIndex from '../utils/getIndex';
import { creditGuideDateValidate, idValidate } from '../utils/validates';
import { FORM_NAME } from './constants';

const selector = formValueSelector(FORM_NAME);

const summarySectionConfig = {
  person: {
    displayInfo: entity => ({ firstName: entity.firstName, surname: entity.surname }),
    getClient: id => graphQL.getClientById(id),
    convertToApplicant: result => convertClientToNewApplicant(get(result, 'data.person')),
    selectorLabel: isApplicant => (isApplicant ? 'Applicant' : 'Guarantor'),
    typeLabel: isApplicant => (isApplicant ? 'Applicant type' : 'Guarantor type'),
    typeValue: 'Person',
    showCreditGuideSentDate: () => true,
    showPrimarySelector: isApplicant => isApplicant,
    ApplicantSelector: PersonApplicantSelector,
  },
  company: {
    displayInfo: entity => ({ name: entity.name }),
    getClient: id => graphQL.getCompanyClientById(id),
    convertToApplicant: result => convertCompanyClientToNewApplicant(get(result, 'data.company')),
    selectorLabel: () => 'Company/Trust',
    typeLabel: isApplicant => (isApplicant ? 'Applicant type' : 'Guarantor type'),
    typeValue: 'Company',
    showCreditGuideSentDate: isApplicant => isApplicant,
    showPrimarySelector: isApplicant => isApplicant,
    ApplicantSelector: CompanyApplicantSelector,
  },
};

const renderPrimaryApplicantSelector = (field, applicantIndexes) => (
  <div className={classNames(styles.radiobutton, styles.multiRowFieldWrapper)}>
    <RadioButton
      label="Primary applicant"
      checked={field.input.value}
      onChange={() => {
        map(applicantIndexes, (index) => field.meta.dispatch(
          change(FORM_NAME, `applicants[${index}]isPrimary`, false),
        ));
        field.meta.dispatch(change('applicantsAndGuarantorsForm', field.input.name, true));
      }}
    />
  </div>
);

const formatOption = (field, collection) => (option) => ({
  ...option,
  isDisabled: includes(collection.map(item => item.id), option.value) && option.value !== field.input.value,
});

const SummarySection = ({
  fieldNamePrefix, collection, handleReplace, isApplicant,
}) => {
  const applicantIndexes = useSelector(state => selector(state, 'applicants')).map((a, i) => i);
  const isDeleted = useSelector(state => selector(state, `${fieldNamePrefix}.isDeleted`));
  const isPerson = useSelector(state => selector(state, `${fieldNamePrefix}.isPerson`));
  const validateId = useMemo(() => (id) => idValidate(isApplicant, id), [isApplicant]);

  const {
    displayInfo,
    getClient,
    convertToApplicant,
    selectorLabel,
    typeLabel,
    typeValue,
    showCreditGuideSentDate,
    showPrimarySelector,
    ApplicantSelector,
  } = isPerson ? summarySectionConfig.person : summarySectionConfig.company;

  const selectedEntity = useMemo(() => (selectedId) => {
    const entity = collection.find(it => it.id === selectedId);
    return entity
      ? ({ displayInfo: displayInfo(entity), id: selectedId })
      : null;
  }, [collection, displayInfo]);

  const confirm = useContext(ConfirmContext);

  const ReduxFormApplicantSelector = useMemo(() => field => {
    const updateEntity = (id) => getClient(id)
      .then((result) => handleReplace(getIndex(fieldNamePrefix), convertToApplicant(result)));

    const handleEntityChange = (id) => {
      if (isEmpty(field.input.value)) {
        updateEntity(id);
      } else if (field.input.value !== id) {
        removeApplicantConfirm(confirm, isApplicant).then(isConfirm => isConfirm && updateEntity(id));
      }
    };

    const WithFormMetaApplicantSelector = withFormMeta(ApplicantSelector);

    return (
      <WithFormMetaApplicantSelector
        {...field}
        className={styles.multiRowFieldWrapper}
        label={selectorLabel(isApplicant)}
        formatOption={formatOption(field, collection)}
        selectedApplicant={selectedEntity(field.input.value)}
        onChange={handleEntityChange}
      />
    );
  }, [
    collection,
    selectedEntity,
    confirm,
    handleReplace,
    fieldNamePrefix,
    isApplicant,
    selectorLabel,
    convertToApplicant,
    getClient,
    ApplicantSelector,
  ]);

  const PrimaryApplicantSelector = useMemo(() => (field) =>
    renderPrimaryApplicantSelector(field, applicantIndexes), [applicantIndexes]);

  return (
    <FieldsContainer
      title=""
      className={styles.summaryWrapper}
      fieldsWrapperClassName={styles.overrideSectionWrapper}
    >
      <Field
        name={`${fieldNamePrefix}id`}
        component={ReduxFormApplicantSelector}
        validate={validateId}
      />
      <PlainTextField
        className={classNames(styles.multiRowFieldWrapper, styles.input)}
        label={typeLabel(isApplicant)}
        value={typeValue}
      />
      {showCreditGuideSentDate(isApplicant) && (
        <Field
          name={`${fieldNamePrefix}creditGuideSentDate`}
          component={ReduxDateSelector}
          className={styles.input}
          validate={creditGuideDateValidate}
          label="Date credit guide was sent"
          width="100%"
          displayFormat="dd/MM/yyyy"
          readOnlyFormatter={value => (value ? toDateWithSlash(value) : '')}
          useMaskBehavior
          hiddenComponentLabel
        />
      )}
      {showPrimarySelector(isApplicant) && (
        <Field
          name={`${fieldNamePrefix}isPrimary`}
          component={PrimaryApplicantSelector}
        />
      )}
      {isDeleted && (
        <div className={styles.deletedWarningSection}>
          <div className={styles.deletedWarning}>
            <Icon name="warning" size="18" color="#e4002b" />
            <div>This person has been removed from the client in Lotus Notes</div>
          </div>
        </div>
      )}
    </FieldsContainer>
  );
};

SummarySection.defaultProps = {
  collection: [],
};

SummarySection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  collection: PropTypes.array,
  handleReplace: PropTypes.func.isRequired,
  isApplicant: PropTypes.bool.isRequired,
};

export default SummarySection;
