import React from 'react';
import styled from 'styled-components';

const DefaultDiv = styled.div`
  color: ${({ theme }) => theme.palette.textPrimary};
  text-decoration: none;
  vertical-align: middle;
`;

const withDefault = LinkComponent => (
  LinkComponent.props.to
    ? LinkComponent
    : (
      <DefaultDiv>
        {LinkComponent.props.children}
      </DefaultDiv>
    )
);

export default withDefault;
