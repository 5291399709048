import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import styles from '../PurchasingAsset.module.scss';

const ResidentsSelector = (props) => {
  const { fieldNamePrefix, applicants } = props;

  const valueRender = useMemo(() => (value) => (value?.length > 1
    ? value?.map(id => applicants.find(item => item.id === id)?.name).join('; ')
    : applicants.find(item => item.id === value[0])?.name), [applicants]);

  return (
    <Field
      className={styles.residents}
      name={`${fieldNamePrefix}.residents`}
      component={ReduxFormCheckboxDropdown}
      inputProps={{
        className: styles.checkboxDropdown,
        options: applicants,
        valueRender,
        buttonText: 'Select',
      }}
      label="Residents at settlement"
      readOnlyFormatter={value => (
        <div className={styles.readOnlyOptions}>
          {value && value.map(id => applicants.find(a => a.id === id)).map(({ id, name }) => (
            <div key={id}>{`${name};`}</div>
          ))}
        </div>
      )}
    />
  );
};

ResidentsSelector.propTypes = {
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default ResidentsSelector;
