import { gql } from '@apollo/client';

export const GET_ADVISERS = gql`
  query advisersByBusinessId($businessId: String!) {
    advisersByBusinessId(businessId: $businessId)
    {
      id
      firstName
      surname
      isActive
      isAdviser
    }
  }
`;
