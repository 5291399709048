import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getHasTopUp } from '../../../redux/selectors/liabilities';
import InformationSection from '../../../../../../shared/components/InformationSection/InformationSection';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';
import FieldsContainer from '../../../../../../shared/components/FieldsContainer/FieldsContainer';
import { SubtotalOtherCosts } from './SubtotalOtherCosts';
import { getOtherFields } from '../contentUtils';

const ViewOtherCosts = ({ hasTopUp, otherCosts, userGuidanceCode }) => {
  if (!hasTopUp) {
    return null;
  }
  const otherCostsFields = getOtherFields(otherCosts);
  return (
    <InformationSection
      wrapperStyle={styles.infoSection}
      contentWrapperStyle={styles.infoSectionContent}
      titleWrapperStyle={styles.titleWrapperStyle}
      title="Other costs"
    >
      { !isEmpty(otherCostsFields) && (
      <SubSection>
        <FieldsContainer
          fields={otherCostsFields}
          title=""
          fieldsWrapperClassName={styles.fieldsWrapper}
          className={styles.fieldsContainerWrapper}
        />
      </SubSection>
      )}
      <SubtotalOtherCosts otherCosts={otherCosts} title={`Other costs (${userGuidanceCode})`} />
    </InformationSection>
  );
};

ViewOtherCosts.defaultProps = {
  hasTopUp: false,
  otherCosts: [],
  userGuidanceCode: '',
};

ViewOtherCosts.propTypes = {
  hasTopUp: PropTypes.bool,
  otherCosts: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
    fieldAmount: PropTypes.number,
  })),
  userGuidanceCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  hasTopUp: getHasTopUp(state),
  otherCosts: get(state, 'application.applicationDetail.fundingWorksheet.otherCosts'),
});

export default connect(mapStateToProps)(ViewOtherCosts);
