import React from 'react';

import MultiLoanCriteria from './MultiLoanCriteria';
import TotalLoanAmount from '../ProductSearchFields/TotalLoanAmount';
import SecurityValue from '../ProductSearchFields/SecurityValue';
import LoanValueRatio from '../ProductSearchFields/LoanValueRatio';
import LendersSelect from '../Lenders/LendersSelect';

export const SplitLoanCriteria = () => (
  <form>
    <MultiLoanCriteria />
    <div className="criteria-section application-criteria">
      <div className="criteria-header">
        <div className="criteria-title">
          Application criteria
        </div>
      </div>
      <div className="criteria-body">
        <div>
          <div className="first-section">
            <div>
              <TotalLoanAmount />
              <SecurityValue />
              <LoanValueRatio />
            </div>
            <div>
              <LendersSelect />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
);

export default SplitLoanCriteria;
