import { noLongerLoggedIn } from 'redux/login';

const is4XXError = statusCode => statusCode >= 400 && statusCode < 500;

const filter4XXError = (error) => {
  if (error.response) {
    return is4XXError(error.response.status) ? null : error;
  }
  if (error.networkError) {
    return is4XXError(error.networkError.statusCode) ? null : error;
  }
  if (error.graphQLErrors) {
    const non4XXErrors = error.graphQLErrors.filter(e => !is4XXError(e.errorCode));
    return non4XXErrors.length === 0 ? null : { ...error, graphQLErrors: non4XXErrors };
  }
  return null;
};

export default () => next => (action) => {
  if (action && action.error) {
    if (action.error.response && action.error.response.status === 401) {
      return noLongerLoggedIn(next);
    }
    /* eslint-disable-next-line no-console */
    console.error(`Something went wrong, error is ${action.error} ${JSON.stringify(action)}`);
    const non4XXError = filter4XXError(action.error);
    if (global.newrelic && non4XXError) {
      global.newrelic.noticeError(non4XXError);
    }
  }
  return next(action);
};
