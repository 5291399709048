import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  Plugin,
  Getter, Action,
} from '@devexpress/dx-react-core';

const SelectionMeta = React.memo(({
 maxSelectionCount, selection, disableSelection, selectAllRows, toggleSelectAllRows,
}) => (
  <Plugin>
    <Getter name="reachMaximumSelectionCount" value={maxSelectionCount <= get(selection, 'length', 0)} />
    <Getter name="maxSelectionCount" value={maxSelectionCount} />
    <Getter name="disableSelection" value={disableSelection} />
    <Getter name="selectAllRows" value={selectAllRows} />
    <Action name="toggleSelectAllRows" action={toggleSelectAllRows} />
  </Plugin>
));

SelectionMeta.propTypes = {
  maxSelectionCount: PropTypes.number,
  selection: PropTypes.array,
  disableSelection: PropTypes.bool,
  selectAllRows: PropTypes.bool,
  toggleSelectAllRows: PropTypes.func,
};

SelectionMeta.defaultProps = {
  maxSelectionCount: undefined,
  selection: [],
  disableSelection: false,
  selectAllRows: false,
  toggleSelectAllRows: () => {},
};

export default SelectionMeta;
