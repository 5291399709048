import confetti from 'canvas-confetti';
import noop from 'lodash/noop';

const showConfetti = (options = {}) => {
  confetti({
    particleCount: 100,
    spread: 100,
    zIndex: 700,
    ...options,
  }).then(noop);
};

export default showConfetti;
