import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import Loader from 'shared/components/Loader/Loader';
import { createSelector } from '@reduxjs/toolkit';
import { toDollarAmountWithDecimal } from 'shared/formatterUtils';
import styles from './styles.module.scss';
import { FETCHING_STATUS } from '../../../../../../constants';
import { ServiceabilityDetailModalContext } from './ServiceabilityDetailModalContext';

const getContent = (serviceabilityInfo, onButtonClick) => {
  if (isUndefined(serviceabilityInfo)) {
    return '';
  }
  const { data: serviceability, fetchingStatus } = serviceabilityInfo;
  switch (fetchingStatus) {
    case FETCHING_STATUS.START: return <Loader />;
    case FETCHING_STATUS.ERROR: return <span className={styles.error}>error</span>;
    case FETCHING_STATUS.SUCCESS: {
      const amount = toDollarAmountWithDecimal(serviceability?.maximumLoanAmount);
        return (
          <TertiaryButton onClick={onButtonClick}>
            {amount}
          </TertiaryButton>
        );
    }
    default: return '';
  }
};

const getServiceability = (product, serviceability = {}) => ({
  totalLVR: serviceability.lvr,
  borrowingCapacity: serviceability.maximumLoanAmount,
  umi: serviceability.surplusFunds,
  totalSecurityValue: serviceability.totalSecurityValue,
  products: product.variants.map((variant, index) => {
    const { products = [] } = serviceability;
    return ({
      id: variant.id,
      productName: variant.displayName,
      assessmentRate: products ? products[index].assessmentRate : null,
      assessmentRepayment: products ? products[index].assessmentRepayment : null,
      ongoingFee: products ? products[index].ongoingFee : null,
    });
  }),
});

const serviceabilityInfosSelector = state => get(state, 'application.productSearch.serviceabilityInfos', {});

const MaxBorrowingCapacityCell = ({ product }) => {
  const productId = product.id;
  const serviceabilitySelector = useMemo(
    () => createSelector(serviceabilityInfosSelector, (infos) => infos[productId]),
    [productId],
  );
  const serviceabilityInfo = useSelector(serviceabilitySelector);

  const showServiceabilityDetail = useContext(ServiceabilityDetailModalContext);
  const serviceability = serviceabilityInfo?.data;
  const serviceabilityDetails = serviceabilityInfo?.details;

  const handleShowServiceabilityDetail = useCallback(() => {
    showServiceabilityDetail({
      serviceability: getServiceability(product, serviceability),
      lenderName: product.lenderName,
      serviceabilityDetails,
    });
  }, [showServiceabilityDetail, serviceability, product, serviceabilityDetails]);
  return (
    <>
      <div className={styles.serviceabilityInfo}>
        {getContent(serviceabilityInfo, handleShowServiceabilityDetail)}
      </div>
    </>
  );
};

MaxBorrowingCapacityCell.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lenderName: PropTypes.string.isRequired,
    serviceability: PropTypes.shape({
      data: PropTypes.shape({
        maximumLoanAmount: PropTypes.number,
      }),
      fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)),
    }),
  }).isRequired,
};

export default React.memo(MaxBorrowingCapacityCell);
