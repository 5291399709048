import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import FeatureSelect from './FeatureSelect';
import FeatureList from './FeatureList';
import SecondaryButton from '../../Buttons/SecondaryButton';

const FeatureModal = React.memo(({
  isOpen, hasError, features, selectedFeatureNames, fixedFeatureNames, onRequestClose,
  onUpdateSelectedFeaturesNames, onToggleSelectFeature, isEditable,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Product features"
    className="all-features-modal"
    overlayClassName="all-features-overlay"
    ariaHideApp={false}
  >
    <div className="feature-modal-header">
      <button
        type="button"
        onClick={onRequestClose}
        className="close-button"
      />
      <FeatureSelect
        onChange={onUpdateSelectedFeaturesNames}
        hasError={hasError}
        features={features}
        selectedFeatureNames={selectedFeatureNames}
        fixedFeatureNames={fixedFeatureNames}
        isEditable={isEditable}
      />
    </div>
    <div className="feature-modal-body">
      <FeatureList
        features={features}
        selectedFeatureNames={selectedFeatureNames}
        fixedFeatureNames={fixedFeatureNames}
        onToggleCheckbox={onToggleSelectFeature}
      />
    </div>
    <div className="feature-modal-footer">
      <SecondaryButton onClick={onRequestClose} disabled={!isEditable}>
        Done
      </SecondaryButton>
    </div>
  </Modal>
));

FeatureModal.defaultProps = {
  features: [],
  selectedFeatureNames: [],
  fixedFeatureNames: [],
  isOpen: false,
  onRequestClose: () => {
  },
  isEditable: true,
};

FeatureModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onUpdateSelectedFeaturesNames: PropTypes.func.isRequired,
  onToggleSelectFeature: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
  })),
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  fixedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
};
export default FeatureModal;
