import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import ResponsiveTable from 'shared/components/ResponsiveTable/ResponsiveTable';
import ExternalLink from 'shared/components/Links/ExternalLink';
import { formatAmount } from '../../../../../../utils/formatters';

const HEADERS = [
  {
    key: 'ACCOUNT_NUMBER',
    content: 'ACCOUNT NUMBER',
    className: 'fixedMediumShort',
  },
  {
    key: 'LOAN_AMOUNT',
    content: 'LOAN AMOUNT',
    className: 'fixedMediumShort',
  },
  {
    key: 'SETTLEMENT_DATE',
    content: 'SETTLEMENT DATE',
    className: 'fixedMediumShort',
  },
  {
    key: 'HISTORY_OF_SPLITS',
    content: 'HISTORY OF SPLITS',
    className: 'fixedMediumShort',
  },
];

export const orderLoans = (loans) => {
  const originalLoan = filter(loans, loan => isEmpty(loan.splitFrom));
  const childLoans = filter(loans, loan => !isEmpty(loan.splitFrom));
  return [
    ...originalLoan,
    ...orderBy(childLoans, ['settledDate', 'amount', 'accountNumber'], ['desc', 'desc', 'asc']),
  ];
};

const SplitLoans = ({ loans, currentLoanId }) => {
  const renderRow = ({
                       accountNumber,
                       amount,
                       settledDate,
                       id,
                       splitFrom,
                     }) => ([
    {
      key: 'ACCOUNT_NUMBER',
      content: id === currentLoanId ? accountNumber : <ExternalLink to={`/loans/${id}`}>{accountNumber}</ExternalLink>,
    },
    {
      key: 'LOAN_AMOUNT',
      content: formatAmount(amount),
    },
    {
      key: 'SETTLEMENT_DATE',
      content: settledDate,
    },
    {
      key: 'HISTORY_OF_SPLITS',
      content: splitFrom ? `From ${splitFrom}` : 'Original Loan',
    },
  ]);

  return (
    <ResponsiveTable rows={orderLoans(loans).map(renderRow)} header={HEADERS} stripped />
  );
};

SplitLoans.propTypes = {
  loans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountNumber: PropTypes.string,
    amount: PropTypes.number.isRequired,
    settledDate: PropTypes.string.isRequired,
    splitFrom: PropTypes.string,
  })).isRequired,
  currentLoanId: PropTypes.string.isRequired,
};

export default SplitLoans;
