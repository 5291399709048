import isNumber from 'lodash/isNumber';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TotalLoanAmountComponent from 'shared/components/productSearch/ProductSearchFields/TotalLoanAmountComponent';
import SecurityValueComponent from 'shared/components/productSearch/ProductSearchFields/SecurityValueComponent';
import LoanValueRatioComponent from 'shared/components/productSearch/ProductSearchFields/LoanValueRatioComponent';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { calculateSecurityValue } from './utils';
import { NEW_LOAN_CONFIG, TOP_UP_CONFIG } from '../../constants';

const getTotalLoanAmountInfo = (props, isTopUp) => {
  const { totalLabel, totalFieldName } = isTopUp
    ? TOP_UP_CONFIG
    : NEW_LOAN_CONFIG;
  const {
    applicationCriteria: {
      [totalFieldName]: {
        input: {
          value: totalLoanAmountForLvr,
        },
      },
    },
  } = props;
  return {
    totalLoanAmountForLvrLabel: totalLabel,
    totalLoanAmountForLvr,
  };
};

const TotalLoanAmountFields = (props) => {
  const {
    applicationCriteria: {
      securityValue: {
        input: {
          value: securityValue,
          onChange: onSecurityValueChange,
        },
        meta: {
          error: securityValueError,
        },
      },
      loanValueRatio: {
        input: {
          value: lvr,
          onChange: onLVRChange,
        },
      },
    },
    formName,
    isTopUp,
  } = props;

  const {
    totalLoanAmountForLvrLabel,
    totalLoanAmountForLvr,
  } = getTotalLoanAmountInfo(props, isTopUp);

  const dispatch = useDispatch();
  const changeFieldValue = useCallback(
    (field, value) => dispatch(change(formName, field, value)),
    [formName, dispatch],
  );
  const onLVRBlur = useCallback(() => {
    if (!isNumber(lvr)) {
      return;
    }
    if (isNumber(totalLoanAmountForLvr)) {
      const calculatedSecurityValue = calculateSecurityValue(totalLoanAmountForLvr, lvr);
      changeFieldValue('applicationCriteria.securityValue', calculatedSecurityValue);
    }
  }, [totalLoanAmountForLvr, lvr, changeFieldValue]);

  const handleOnSecurityValueChange = useCallback(
    (v) => onSecurityValueChange(v === undefined ? null : v),
    [onSecurityValueChange],
  );

  const handleOnLVRChange = useCallback(
    (v) => onLVRChange(v === undefined ? null : v),
    [onLVRChange],
  );

  return (
    <>
      <TotalLoanAmountComponent
        value={totalLoanAmountForLvr}
        label={totalLoanAmountForLvrLabel}
      />
      <SecurityValueComponent
        value={securityValue}
        onChange={handleOnSecurityValueChange}
        errorMessage={securityValueError}
      />
      <LoanValueRatioComponent
        value={lvr}
        onChange={handleOnLVRChange}
        onBlur={onLVRBlur}
      />
    </>
  );
};

TotalLoanAmountFields.propTypes = {
  applicationCriteria: PropTypes.shape({
    totalLoanAmount: PropTypes.object,
    totalCombinedLoanAmount: PropTypes.object,
    securityValue: PropTypes.object,
    loanValueRatio: PropTypes.object,
  }).isRequired,
  formName: PropTypes.string.isRequired,
  isTopUp: PropTypes.bool.isRequired,
};

export default TotalLoanAmountFields;
