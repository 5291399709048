import React from 'react';
import PropTypes from 'prop-types';

const ReduxFormRadioButton = props => {
  const {
    input,
    disabled,
    ...others
  } = props;

  return (
    <input
      type="radio"
      disabled={disabled}
      {...input}
      {...others}
    />
  );
};

ReduxFormRadioButton.propTypes = {
  input: PropTypes.shape({}),
  disabled: PropTypes.bool.isRequired,
};

ReduxFormRadioButton.defaultProps = {
  input: {},
};

export default ReduxFormRadioButton;
