export const ACTIONS = {
  EDIT_START: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_START',
  EDIT_CANCEL: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_CANCEL',
  EDIT_SUBMIT: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_SUBMIT',
  EDIT_SUCCESS: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_SUCCESS',
  UPDATE_VALUE: 'VARIANT_MODIFICATION:STAND_ALONE:UPDATE_VALUE',
  EDIT_FAIL: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_FAIL',
  VARIANT_MODIFICATION_EDIT_START: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_START',
  VARIANT_MODIFICATION_EDIT_CANCEL: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_CANCEL',
  VARIANT_MODIFICATION_EDIT_SUBMIT: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_SUBMIT',
  VARIANT_MODIFICATION_EDIT_SUCCESS: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_SUCCESS',
  VARIANT_MODIFICATION_UPDATE_VALUE: 'VARIANT_MODIFICATION:STAND_ALONE:UPDATE_VALUE',
  VARIANT_MODIFICATION_EDIT_FAIL: 'VARIANT_MODIFICATION:STAND_ALONE:EDIT_FAIL',
};
