export default {
  RETRIEVE_APPLICATIONS_START: 'RETRIEVE_APPLICATIONS_START',
  RETRIEVE_APPLICATIONS_SUCCESS: 'RETRIEVE_APPLICATIONS_SUCCESS',
  RETRIEVE_APPLICATIONS_ERROR: 'RETRIEVE_APPLICATIONS_ERROR',
  CHANGE_CONDITIONS: 'APPLICATIONS_FILTER_CHANGE_CONDITIONS',
  CLEAN_CONDITIONS: 'APPLICATIONS_FILTER_CLEAN_CONDITIONS',
  CLEAN_CLIENTS: 'APPLICATIONS_CLEAN_CLIENTS',
  MOVE_APPLICATIONS: 'APPLICATIONS_MOVE_APPLICATIONS',
  SAVE_APPLICATION_STATUS_START: 'SAVE_APPLICATION_STATUS_START',
  SAVE_APPLICATION_STATUS_SUCCESS: 'SAVE_APPLICATION_STATUS_SUCCESS',
  SAVE_APPLICATION_STATUS_ERROR: 'SAVE_APPLICATION_STATUS_ERROR',
  CREATE_APPLICATION_START: 'CREATE_APPLICATION_START',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_ERROR: 'CREATE_APPLICATION_ERROR',
  GET_CLIENTS_START: 'GET_CLIENTS_START',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAIL: 'GET_CLIENTS_FAIL',
  GET_ACTION_TIME_MAP_START: 'GET_ACTION_TIME_MAP_START',
  GET_ACTION_TIME_MAP_SUCCESS: 'GET_ACTION_TIME_MAP_SUCCESS',
};
