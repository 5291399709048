import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Modal from 'shared/components/Modal/Modal';
import InfoBar from 'shared/components/InfoBar/InfoBar';
import TextArea from 'shared/components/formFields/TextArea';
import { closeCommissionQueryModal, sendCommissionQuery } from './commissionQueryRedux';
import styles from './commissionQuery.module.scss';
import CommissionQueryModalFooter from './CommissionQueryModalFooter';

export const MAX_CHARACTERS = 500;
const ERROR_MESSAGE = `Please enter a maximum of ${MAX_CHARACTERS} characters.`;
const INFO_BAR_MESSAGE = 'Loan details will be sent directly to Zendesk with this comment';

export const CommissionQueryModal = ({
                                processingMonth, isModalVisible, dispatchCloseCommissionQueryModal,
                                dispatchSendCommissionQuery, isSending, hasError, queryType,
                              }) => {
  const [state, setState] = useState({ inputHasError: false, description: '' });

  useEffect(() => () => {
    setState({ inputHasError: false, description: '' });
  }, [isModalVisible]);

  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={dispatchCloseCommissionQueryModal}
      header="Commission query comment"
      footer={(
        <CommissionQueryModalFooter
          isSending={isSending}
          preventSubmission={state.inputHasError || state.description === ''}
          dispatchSendToZendesk={() => dispatchSendCommissionQuery(state.description, queryType, processingMonth)}
        />
      )}
      className={styles.commissionQueryModal}
    >
      <div className={styles.modalBody}>
        <InfoBar text={INFO_BAR_MESSAGE} />
        <TextArea
          className={styles.textArea}
          placeholder="Please describe the problem here"
          value={state.description}
          onChange={value => setState({ description: value, inputHasError: value.length > MAX_CHARACTERS })}
          errorMessage={state.inputHasError ? ERROR_MESSAGE : undefined}
          rows="15"
          autoFocus
        />
        {hasError && (
          <div className={styles.error}>
            Something went wrong. The query was not submitted. Please try again later.
          </div>
        )}
      </div>
    </Modal>
  );
};

CommissionQueryModal.propTypes = {
  queryType: PropTypes.string.isRequired,
  processingMonth: PropTypes.string,
  isModalVisible: PropTypes.bool,
  isSending: PropTypes.bool,
  hasError: PropTypes.bool,
  dispatchCloseCommissionQueryModal: PropTypes.func.isRequired,
  dispatchSendCommissionQuery: PropTypes.func.isRequired,
};

CommissionQueryModal.defaultProps = {
  processingMonth: undefined,
  isModalVisible: false,
  isSending: false,
  hasError: false,
};

const mapStateToProps = state => ({
  isModalVisible: get(state, 'myBusiness.commissionQuery.isModalVisible'),
  isSending: get(state, 'myBusiness.commissionQuery.isSending'),
  hasError: get(state, 'myBusiness.commissionQuery.hasError'),
});

const mapDispatchToProps = dispatch => ({
  dispatchCloseCommissionQueryModal: () => dispatch(closeCommissionQueryModal()),
  dispatchSendCommissionQuery:
    (description, queryType, processingMonth) => dispatch(sendCommissionQuery(description, queryType, processingMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommissionQueryModal);
