import React from 'react';
import PropTypes from 'prop-types';
import { emptyToNA, toDollarAmount, toPercentage } from 'shared/formatterUtils';
import getStyles from './styles';
import { APPLICATION_TYPES, APPLICATION_TYPES_LABELS } from '../constants';

const renderTotalLoanAmount = (totalLoanAmount, styles) => (
  <div className="application-criteria-summary__total-loan-amount" style={styles.criteriaField}>
    <div style={styles.criteriaField.label}>Total loan amount</div>
    <div>{toDollarAmount(totalLoanAmount)}</div>
  </div>
);

const getLenderLabel = isTopUp => (isTopUp ? 'Lender of loan to be topped up' : 'Lender');

const EmbeddedApplicationCriteriaSummary = ({ applicationCriteria, isSingleLoan }) => {
  const styles = getStyles(isSingleLoan);

  const {
    selectedLenderNames,
    totalLoanAmount,
    totalCombinedLoanAmount,
    securityValue,
    loanValueRatio,
    applicationType,
  } = applicationCriteria || {};

  const lenderNamesText = (selectedLenderNames || []).join(', ') || 'N/A';
  const isTopUp = applicationType === APPLICATION_TYPES.TOP_UP;

  if (isSingleLoan) {
    return (
      <div className="application-criteria-summary__lender-names" style={styles.lenderNameField}>
        <div style={styles.criteriaField.label}>{getLenderLabel(isTopUp)}</div>
        <div>{lenderNamesText}</div>
      </div>
    );
  }

  return (
    <>
      <div className="application-criteria-summary__label" style={styles.sectionLabel}>APPLICATION</div>
      <div className="application-criteria-summary__application-type" style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>Application type</div>
        <div>{APPLICATION_TYPES_LABELS[applicationType]}</div>
      </div>
      {isTopUp
        ? renderTotalLoanAmount(totalLoanAmount, styles)
        : <div style={styles.blankField} />}
      <div className="application-criteria-summary__lender-names" style={styles.lenderNameField}>
        <div style={styles.criteriaField.label}>{getLenderLabel(isTopUp)}</div>
        <div>{lenderNamesText}</div>
      </div>
      <div />
      <div style={styles.secondLineIndent} />
      {isTopUp
        ? (
          <div className="application-criteria-summary__total-combined-loan-amount" style={styles.criteriaField}>
            <div style={styles.criteriaField.label}>Total combined loan amount</div>
            <div>{toDollarAmount(totalCombinedLoanAmount)}</div>
          </div>
        )
        : renderTotalLoanAmount(totalLoanAmount, styles)}
      <div className="application-criteria-summary__security-value" style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>Total security value</div>
        <div>{emptyToNA(securityValue, toDollarAmount)}</div>
      </div>
      <div className="application-criteria-summary__lvr" style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>LVR</div>
        <div>{emptyToNA(loanValueRatio, toPercentage)}</div>
      </div>

    </>
  );
};

EmbeddedApplicationCriteriaSummary.propTypes = {
  applicationCriteria: PropTypes.shape({
    selectedLenderNames: PropTypes.array,
    totalLoanAmount: PropTypes.number,
    securityValue: PropTypes.number,
    loanValueRatio: PropTypes.number,
  }).isRequired,
  isSingleLoan: PropTypes.bool,
};

EmbeddedApplicationCriteriaSummary.defaultProps = {
  isSingleLoan: false,
};

export default EmbeddedApplicationCriteriaSummary;
