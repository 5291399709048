import React from 'react';
import PropTypes from 'prop-types';
import FormsyTextInput from 'shared/components/formFields/Formsy/FormsyTextInput';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { EditField, USER_PERMISSIONS } from '../../EditField';

const ApprovalNumberInput = ({ approvalNumber, disabled }) => (
  <FormsyTextInput
    validations={{ maxLength: 32 }}
    validationErrors={{
      maxLength: 'Must be 32 characters or less',
    }}
    name="approvalNumber"
    value={approvalNumber}
    fullWidth
    disabled={disabled}
  />
);

ApprovalNumberInput.defaultProps = {
  approvalNumber: undefined,
  disabled: false,
};

ApprovalNumberInput.propTypes = {
  approvalNumber: PropTypes.string,
  disabled: PropTypes.bool,
};

const InputApprovalNumber = ({
  approvalNumber, reconciledDate,
}) => (
  <EditField
    permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
    fallback={approvalNumber}
  >
    {
      isEmpty(reconciledDate)
        ? <ApprovalNumberInput approvalNumber={approvalNumber} />
        : (
          <EditField
            permissions={USER_PERMISSIONS.GROUP_OFFICE}
            fallback={<ApprovalNumberInput approvalNumber={approvalNumber} disabled />}
          >
            <ApprovalNumberInput approvalNumber={approvalNumber} />
          </EditField>
        )
    }
  </EditField>
);

InputApprovalNumber.defaultProps = {
  approvalNumber: undefined,
  reconciledDate: undefined,
};

InputApprovalNumber.propTypes = {
  approvalNumber: PropTypes.string,
  reconciledDate: PropTypes.string,
};

const mapStateToProps = ({ loans: { loan: { data: { approvalNumber, reconciledDate } } } }) => ({
  approvalNumber,
  reconciledDate,
});

export default connect(mapStateToProps)(InputApprovalNumber);
