import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './category.module.scss';
import TertiaryButton from '../../../../shared/components/Buttons/TertiaryButton';
import { categoryShape } from '../shapes/categoryShape';

const cx = classNames.bind(styles);

export const Category = ({
                           category, onDelete, onRevertDelete, disabled,
}) => (
  <div className={cx({ category: true, deletedCategory: category.deleted })}>
    <div className={cx({ name: true, deletedName: category.deleted })}>
      {category.name}
    </div>
    <div className={styles.deleteBtnContainer}>
      <TertiaryButton
        className={cx({ btn: true, deleteBtn: !category.deleted, revertBtn: category.deleted })}
        disabled={disabled}
        onClick={() => {
          if (category.deleted) {
            onRevertDelete(category);
          } else {
            onDelete(category);
          }
        }}
      >
        {category.deleted ? 'Revert' : 'Delete'}
      </TertiaryButton>
    </div>
  </div>
);

Category.propTypes = {
  category: categoryShape.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRevertDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Category.defaultProps = {
  disabled: false,
};
