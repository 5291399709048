import { combineActions, createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import applicationsActions from '../../../../Applications/redux/constants';

const initialState = {
  isUpdating: false,
};

const applicationStatusRequestStatus = createReducer(
  initialState, {
    ...combineActions([types.UPDATE_APPLICATION_STATUS_START,
      applicationsActions.SAVE_APPLICATION_STATUS_START], state => ({
      ...state,
      isUpdating: true,
    })),
    ...combineActions([types.UPDATE_APPLICATION_STATUS_SUCCESS,
      applicationsActions.SAVE_APPLICATION_STATUS_SUCCESS], state => ({
      ...state,
      isUpdating: false,
    })),
    ...combineActions([types.UPDATE_APPLICATION_STATUS_ERROR,
      applicationsActions.SAVE_APPLICATION_STATUS_ERROR], state => ({
      ...state,
      isUpdating: false,
    })),
  },
);

export default applicationStatusRequestStatus;
