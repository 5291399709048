import get from 'lodash/get';
import { getTargetChangedValue } from '../../../../../../constants/utils';
import { APPLICATION_UPDATE_TYPE } from '../../../../../../constants/applicationUpdate';
import { toTimeStr } from '../../../../../../../utils/datetime';
import { getBasicLogInfo } from './utils';

const generateAcknowledgeUpdateLogContent = (type, name, timestamp) => (
  get({
    [APPLICATION_UPDATE_TYPE.EVENT]: `Acknowledged "${name} on ${toTimeStr(timestamp)}" from ApplyOnline`,
    [APPLICATION_UPDATE_TYPE.STATUS]: `Acknowledged "Application status has changed to ${name} on ${toTimeStr(timestamp)}" from ApplyOnline`,
    [APPLICATION_UPDATE_TYPE.ALLIANZ_INSURANCE]: 'Acknowledged "It\'s time to refer your client to Allianz or opt out of general insurance."',
  }, type)
);

const acknowledgeUpdateLogTransformer = (origin) => {
  const type = getTargetChangedValue(origin, 'type');
  const name = getTargetChangedValue(origin, 'name');
  const timestamp = getTargetChangedValue(origin, 'timestamp');
  return {
    ...getBasicLogInfo(origin),
    operator: getBasicLogInfo(origin).operator ?? 'Launchpad',
    content: generateAcknowledgeUpdateLogContent(type, name, timestamp),
  };
};

export default acknowledgeUpdateLogTransformer;
