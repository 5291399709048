import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Popover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: props.defaultOpen,
    };
  }

  componentDidMount() {
    const { rootClose } = this.props;
    if (rootClose) {
      document.addEventListener('mousedown', this.handleOnClick, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOnClick, true);
  }

  handleOnClick = (event) => {
    const { closeOnOutsideClick } = this.props;
    if (this.node.contains(event.target)) {
      return; // the click is inside
    }
    if (closeOnOutsideClick) {
      this.setState({ isShown: false });
    }
  };

  toggle = (value) => {
    const { contentWidth } = this.props;
    this.shouldRightAligned = contentWidth
      && (window.visualViewport.width - this.node.getBoundingClientRect().left) < contentWidth;
    this.setState(state => ({
      isShown: (typeof value === 'boolean') ? value : !state.isShown,
    }));
  };

  render() {
    const {
      trigger, children, className, contentWidth,
    } = this.props;

    const { isShown } = this.state;

    const renderChildren = () => {
      if (children instanceof Function) {
        return children(this.toggle, isShown);
      }
      return isShown ? children : null;
    };

    const contentProps = this.node && this.shouldRightAligned ? {
      style: {
        transform: `translateX(${this.node.getBoundingClientRect().width - contentWidth}px)`,
        position: 'absolute',
        zIndex: 200,
      },
    } : {};

    return (
      <div
        style={{ position: 'relative' }}
        className={className}
        ref={(node) => {
          this.node = node;
        }}
      >
        {trigger(this.toggle, isShown)}
        <div {...contentProps}>
          {renderChildren()}
        </div>
      </div>
    );
  }
}

Popover.propTypes = {
  trigger: PropTypes.func,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  rootClose: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  contentWidth: PropTypes.number,
};

Popover.defaultProps = {
  trigger: () => {
  },
  className: undefined,
  closeOnOutsideClick: true,
  defaultOpen: false,
  rootClose: true,
  contentWidth: null,
};

export default Popover;
