import React from 'react';

import LoanValueRatioComponent from './LoanValueRatioComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedLoanValueRatio = () => (
  <ProductSearchContext.Consumer>
    { context => {
        const { loanValueRatio } = context.applicationCriteria;
        return (
          <LoanValueRatioComponent
            loanValueRatio={loanValueRatio}
            dispatchOnLoanValueRatioBlur={context.dispatchOnLoanValueRatioBlur}
            dispatchUpdateLoanValueRatio={context.dispatchUpdateLoanValueRatio}
          />
        );
      }}
  </ProductSearchContext.Consumer>
);

export default ConnectedLoanValueRatio;
