import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Icon from 'shared/components/Icon/Icon';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ClickableText from 'shared/components/formFields/ClickableText';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import { Prompt } from 'react-router-dom';
import styles from './TaskListTemplate.module.scss';
import { createOrUpdateTaskListTemplate, deleteTaskListTemplate } from './redux/templateReducer';
import TemplateTaskModal from './TaskEditModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { confirmLeaveMessage } from '../../../../constants/message';

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? '2px dashed rgb(0, 128, 166)' : '2px dashed white',
  backgroundColor: isDraggingOver ? 'rgba(205, 230, 237, 0.4)' : 'white',
});

const draggingStyle = {
  borderRadius: '4px',
  boxShadow: '0 1.5px 4.5px 0 #9b9b9b',
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  backgroundColor: 'white',
  ...(isDragging ? draggingStyle : {}),
  ...draggableStyle,
});

const reOrder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export class Template extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedTemplate } = props;
    const tasks = (selectedTemplate.tasks || [])
      .map(task => ({ name: task.name || '', description: task.description || '' }));
    this.state = {
      originalTemplateName: selectedTemplate.name || '',
      originalTemplateTasks: tasks,
      templateId: selectedTemplate.id || '',
      templateName: selectedTemplate.name || '',
      templateTasks: tasks,
      isSaving: false,
      newTaskName: '',
      isTaskModalOpen: false,
      errorMessage: '',
      selectedTask: {},
      isDeleteConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.confirmClosePage);
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.confirmClosePage);
  }

  confirmClosePage = (e) => {
    const {
      templateName, originalTemplateName, templateTasks, originalTemplateTasks,
    } = this.state;

    const anyChanges = !isEqual(templateName, originalTemplateName)
    || !isEqual(templateTasks, originalTemplateTasks);
    if (anyChanges) {
      (e || window.event).returnValue = confirmLeaveMessage;
    }
  }

  handleAddTask = () => {
    const { newTaskName, templateTasks } = this.state;
    if (!isEmpty(newTaskName.trim())) {
      this.setState({
        newTaskName: '',
        templateTasks: [{ name: newTaskName.trim(), description: '' }, ...templateTasks],
      });
    }
  };

  handleRemoveTask = (task) => {
    const { templateTasks } = this.state;
    const filteredTemplateTasks = templateTasks.filter(it => it !== task);
    this.setState({
      templateTasks: filteredTemplateTasks,
    });
  };

  handleOpenTemplateTaskModal = (task) => {
    this.setState({
      isTaskModalOpen: true,
      selectedTask: task,
    });
  };

  handleEditTaskFinish = (task) => {
    const { templateTasks, selectedTask } = this.state;
    const taskList = [...templateTasks];
    const index = templateTasks.indexOf(selectedTask);
    if (index > -1) {
      taskList.splice(index, 1, { ...task });
      this.setState({
        isTaskModalOpen: false,
        templateTasks: [...taskList],
      });
    }
  };

  handleSaveTemplate = () => {
    const { saveTaskListTemplate } = this.props;
    const {
      templateId, templateName, templateTasks,
    } = this.state;

    if (isEmpty(templateName.trim())) {
      this.setState({ errorMessage: 'Please enter a Task list name' });
    } else {
      const taskListTemplate = {
        id: templateId,
        name: templateName,
        tasks: [...templateTasks],
      };
      this.setState({ isSaving: true, errorMessage: '' });
      saveTaskListTemplate(taskListTemplate).then((data) => {
        const savedTasks = data.tasks.map(task => ({ name: task.name || '', description: task.description || '' }));
        this.setState({
          templateId: data.id,
          templateName: data.name,
          templateTasks: savedTasks,
          originalTemplateName: data.name,
          originalTemplateTasks: savedTasks,
          isSaving: false,
        });
      }).catch(() => {});
    }
  };

  handleDeleteTemplate = (templateId) => {
    const { deleteTemplate } = this.props;
    deleteTemplate(templateId).finally(() => this.setState({ isDeleteConfirmationModalOpen: false }));
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { templateTasks } = this.state;
    const items = reOrder(templateTasks, result.source.index, result.destination.index);

    this.setState({
      templateTasks: items,
    });
  };

  renderTaskListRow(task, id) {
    return (
      <Draggable key={id} draggableId={`${id}`} index={id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <li key={`task-${id}`} className={styles.taskListTaskRow}>
              <div className={styles.dragIcon}><Icon name="drag" size="20" /></div>
              <ClickableText text={task.name} onClick={() => this.handleOpenTemplateTaskModal(task)} />
              <TertiaryButton
                className={styles.removeButton}
                onClick={() => this.handleRemoveTask(task)}
              >
                Remove
              </TertiaryButton>
            </li>
          </div>
        )}
      </Draggable>
    );
  }

  renderTaskList(tasks) {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="container" direction="vertical">
          {
            (provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <ul className={styles.taskListTaskUl}>
                  {tasks.map((task, index) => ({ task, id: index }))
                    .map(({ task, id }) => this.renderTaskListRow(task, id))}
                  {provided.placeholder}
                </ul>
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    const { onBackToTemplateList } = this.props;
    const {
      isSaving, templateName, templateTasks, originalTemplateName, originalTemplateTasks,
      newTaskName, isTaskModalOpen, selectedTask, templateId, errorMessage, isDeleteConfirmationModalOpen,
    } = this.state;
    const isTemplateChanged = !isEqual(templateName, originalTemplateName)
      || !isEqual(templateTasks, originalTemplateTasks);
    return (
      <div className={styles.newTaskListTemplate}>
        <Prompt
          when={isTemplateChanged}
          message={confirmLeaveMessage}
        />
        <div className={styles.backLink}>
          <span
            className={styles.backIcon}
            role="button"
            tabIndex="0"
            onKeyDown={e => (e.key === 'Enter' ? onBackToTemplateList() : null)}
            onClick={onBackToTemplateList}
          />
          <TertiaryButton
            className={styles.backButton}
            onClick={onBackToTemplateList}
          >
            Back to task list templates
          </TertiaryButton>
        </div>
        <div>
          <h2>{originalTemplateName === '' ? 'New template' : originalTemplateName}</h2>
          <div className={styles.newTemplateContent}>
            <div className={styles.newTemplateForm}>
              <div className={styles.taskContent}>
                <TextInput
                  label="Task list name"
                  onChange={value => this.setState({ templateName: value })}
                  errorMessage={isEmpty(templateName.trim()) ? errorMessage : ''}
                  value={templateName}
                />
              </div>
              <div className={styles.taskContent}>
                <TextInput
                  label="Tasks"
                  placeholder="Add new task"
                  onChange={value => this.setState({ newTaskName: value })}
                  onKeyDown={e => (e.key === 'Enter' ? this.handleAddTask() : null)}
                  value={newTaskName}
                />
                <PrimaryButton
                  className={styles.addTaskTemplateButton}
                  onClick={this.handleAddTask}
                  disabled={isEmpty(newTaskName.trim())}
                >
                  Add
                </PrimaryButton>
              </div>
              {this.renderTaskList(templateTasks)}
            </div>
          </div>
          <div className={styles.newTemplateFooter}>
            <div>
              <PrimaryButton
                onClick={this.handleSaveTemplate}
                disabled={isSaving || !isTemplateChanged}
              >
                Save template
              </PrimaryButton>
              <TertiaryButton
                className={styles.cancelButton}
                onClick={onBackToTemplateList}
              >
                Cancel
              </TertiaryButton>
            </div>
            <div>
              {templateId && (
              <SecondaryButton
                className={styles.deleteButton}
                onClick={() => this.setState({ isDeleteConfirmationModalOpen: true })}
              >
                <span className={styles.deleteButtonText}>Delete template</span>
                <Icon name="delete" color="#aa2020" size="18" />
              </SecondaryButton>
              )}
            </div>
          </div>
        </div>
        {isTaskModalOpen && (
          <TemplateTaskModal
            isOpen
            defaultTask={selectedTask}
            handleCloseTaskModal={() => this.setState({ isTaskModalOpen: false })}
            handleEditTaskFinish={this.handleEditTaskFinish}
          />
        )}
        {
          isDeleteConfirmationModalOpen && (
            <DeleteConfirmationModal
              isOpen
              templateId={templateId}
              handleDelete={this.handleDeleteTemplate}
              handleClose={() => this.setState({ isDeleteConfirmationModalOpen: false })}
            />
          )
        }
      </div>
    );
  }
}

Template.defaultProps = {
  selectedTemplate: {},
};

Template.propTypes = {
  onBackToTemplateList: PropTypes.func.isRequired,
  saveTaskListTemplate: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.shape(),
  deleteTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedTemplate: state.myBusiness.taskListTemplate.selectedTemplate,
});

export default connect(mapStateToProps, {
  saveTaskListTemplate: createOrUpdateTaskListTemplate,
  deleteTemplate: deleteTaskListTemplate,
})(Template);
