import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import withSyncBusinessId from 'shared/components/WithSyncBusinessId/withSyncBusinessId';
import LoanManagement from './LoanManagement';
import LoanDetails from './components/LoanDetails/LoanDetails';

const LIST_LOANS_PATH = '/loans';
const LOAN_DETAILS_PATH = '/loans/:id';

const LoanManagementComponent = withSyncBusinessId((LoanManagement));

const LoanDetailsComponent = withSyncBusinessId((LoanDetails), LIST_LOANS_PATH);

const LoanManagementRoutes = () => (
  <>
    <Route
      exact
      path={LIST_LOANS_PATH}
      component={LoanManagementComponent}
    />
    <Route
      exact
      path={LOAN_DETAILS_PATH}
      component={LoanDetailsComponent}
    />
  </>
);

export default withRouter(LoanManagementRoutes);
