import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';
import React from 'react';
import { filterFormsyProps } from 'shared/components/formFields/Formsy/utils';
import Select from 'shared/components/formFields/Select';

const FormsySelect = ({
                        onChange,
                        setValue,
                        value,
                        disabled,
                        isFormDisabled,
                        options,
                        customErrorMessage,
                        showRequired,
                        ...otherProps
}) => {
  const requiredMessage = showRequired ? 'This field is required' : undefined;
  return (
    <Select
      onChange={(selected) => {
        onChange(selected);
        setValue(selected);
      }}
      disabled={isFormDisabled || disabled}
      value={value}
      options={options}
      errorMessage={customErrorMessage || requiredMessage}
      maxMenuHeight={164}
      {...filterFormsyProps(otherProps)}
    />
  );
};

FormsySelect.defaultProps = {
  onChange: () => {},
  disabled: false,
  options: [],
  customErrorMessage: undefined,
  value: undefined,
};

FormsySelect.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object, PropTypes.number]).isRequired,
  })]),
  customErrorMessage: PropTypes.string,
  showRequired: PropTypes.bool.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withFormsy(FormsySelect);
