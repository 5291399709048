import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import Checkbox from 'shared/components/formFields/Checkbox';
import RemoteFilterShape from 'shared/components/MobiusTable/shapes/RemoteFilterShape';
import hasPermissionTo, { useHasPermissions } from 'shared/components/authorisation/hasPermissionTo';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import { fetchCategories } from 'LoanCategories/redux/categoriesRedux';
import moment from 'moment';
import Loans, { LoanRow } from './Loans';
import LoansToolBar from './LoansToolBar';
import {
  downloadLoans,
  getLoans,
  initGetLoans,
  initQuery,
  querySelector,
  reset,
  toggleViewAll,
  updateRemoteFilters,
} from './redux/loansRedux';
import { selectBusiness } from '../../../redux/business';
import { FETCHING_STATUS } from '../../../constants';
import { isError, isInit, isStart } from '../../../utils/actionStatusUtil';

import { PAGE_SIZE } from './constants';
import styles from './styles.module.scss';
import { LoansQueryShape } from '../../shapes/LoansQueryShape';

export const LoansSummary = ({
                               dispatchGetLoans,
                               dispatchInitGetLoans,
                               dispatchRemoteFiltersChanged,
                               dispatchReset,
                               dispatchToggleViewAll,
                               dispatchSelectBusiness,
                               dispatchInitQuery,
                               dispatchExportLoans,
                               dispatchFetchCategories,
                               fetchingStatus,
                               loans,
                               selectedBusinessId,
                               skip,
                               totalRowCount,
                               viewAll,
                               isLoansReportDownloading,
                               remoteFilters,
                               permissions,
                               query,
                               toggles,
                             }) => {
  useEffect(() => {
    dispatchReset();
  }, [
    selectedBusinessId,
    dispatchReset,
    viewAll,
  ]);

  useEffect(() => {
    if (isNil(query)) {
      dispatchInitQuery();
    } else {
      dispatchGetLoans(query);
    }
  }, [
    dispatchInitQuery,
    dispatchGetLoans,
    query,
  ]);

  const [loanSelection, updateLoanSelection] = useState([]);

  const canViewAllLoans = hasPermissionTo(
    PERMISSIONS.VIEW_ALL_LOANS, permissions, selectedBusinessId,
  );
  const canManageCategories = canViewAllLoans && hasPermissionTo(
    PERMISSIONS.EDIT_ALL_LOANS,
    permissions,
    selectedBusinessId,
  );

  const hasBulkUpdateFixedTrailPermission = useHasPermissions([PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL]);

  const canUpdateFixedTrail = hasBulkUpdateFixedTrailPermission && !viewAll;

  useEffect(() => {
    if (!isEmpty(selectedBusinessId) && canViewAllLoans) {
      dispatchFetchCategories(selectedBusinessId);
    }
  }, [selectedBusinessId, dispatchFetchCategories, canManageCategories, canViewAllLoans, toggles]);

  const hasError = isError(fetchingStatus);

  const handleCategoriesUpdated = useMemo(() => ({ categoriesUpdated, categoriesDeleted }) => {
    if (!isEmpty(categoriesUpdated) || !isEmpty(categoriesDeleted)) {
      dispatchReset();
    }
  }, [dispatchReset]);

  const handleLoanSelectionChange = useMemo(() => (selection) => {
    updateLoanSelection(selection);
  }, [updateLoanSelection]);

  const onLoansExportByFilter = () => {
    dispatchExportLoans({
      skip: 0,
      take: totalRowCount,
      businessId: query.businessId,
      filter: query.filter,
      timeZoneId: moment.tz.guess(),
    });
  };

  return (
    <div className={styles.loansSummary}>
      <GroupOfficeBusinessSelector isDisabled={viewAll}>
        <Checkbox
          disabled={isStart(fetchingStatus)}
          labelName="View all"
          checked={viewAll}
          onChange={dispatchToggleViewAll}
          className={styles.viewAllCheck}
        />
      </GroupOfficeBusinessSelector>
      <LoansToolBar
        showCategories={canManageCategories && !viewAll}
        showUpdateFixedTrail={canUpdateFixedTrail}
        disableDownloadReport={isLoansReportDownloading || viewAll}
        onCategoriesUpdated={handleCategoriesUpdated}
        onLoansExport={onLoansExportByFilter}
        loanSelection={loanSelection}
      />
      <LoadingAndErrorWrapper
        isLoading={isInit(fetchingStatus)}
        hasError={hasError}
      >
        <Loans
          rows={loans}
          isLoading={isStart(fetchingStatus)}
          skip={skip}
          getRemoteRows={dispatchInitGetLoans}
          onRemoteFiltersChange={dispatchRemoteFiltersChanged}
          remoteFilters={remoteFilters}
          totalRowCount={totalRowCount}
          pageSize={PAGE_SIZE}
          viewAll={viewAll}
          canViewCategories={canViewAllLoans}
          canUpdateFixedTrail={canUpdateFixedTrail}
          selectedBusinessId={selectedBusinessId}
          dispatchSelectBusiness={dispatchSelectBusiness}
          toggles={toggles}
          handleSelectionChange={handleLoanSelectionChange}
        />
      </LoadingAndErrorWrapper>
    </div>
  );
};

LoansSummary.propTypes = {
  loans: PropTypes.arrayOf(LoanRow).isRequired,
  dispatchGetLoans: PropTypes.func.isRequired,
  dispatchInitGetLoans: PropTypes.func.isRequired,
  dispatchRemoteFiltersChanged: PropTypes.func.isRequired,
  dispatchReset: PropTypes.func.isRequired,
  selectedBusinessId: PropTypes.string,
  skip: PropTypes.number,
  totalRowCount: PropTypes.number,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)),
  viewAll: PropTypes.bool.isRequired,
  dispatchToggleViewAll: PropTypes.func.isRequired,
  dispatchSelectBusiness: PropTypes.func.isRequired,
  remoteFilters: PropTypes.arrayOf(RemoteFilterShape).isRequired,
  query: LoansQueryShape,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggles: PropTypes.shape({}).isRequired,
  isLoansReportDownloading: PropTypes.bool,
  dispatchFetchCategories: PropTypes.func.isRequired,
  dispatchInitQuery: PropTypes.func.isRequired,
  dispatchExportLoans: PropTypes.func.isRequired,
};

LoansSummary.defaultProps = {
  selectedBusinessId: undefined,
  fetchingStatus: FETCHING_STATUS.INIT,
  totalRowCount: null,
  skip: null,
  query: null,
  isLoansReportDownloading: false,
};

const mapStateToProps = (state) => {
  const { loans: { loans }, business, profile } = state;
  return ({
    loans: loans.data,
    fetchingStatus: loans.fetchingStatus,
    selectedBusinessId: get(business, 'selectedBusiness.id'),
    remoteFilters: loans.remoteFilters,
    skip: loans.skip,
    totalRowCount: loans.totalRowCount,
    viewAll: loans.viewAll,
    query: querySelector(state),
    toggles: get(profile, 'toggles', {}),
    permissions: state.profile.permissions,
    isLoansReportDownloading: loans.isDownloading,
  });
};

const mapDispatchToProps = (dispatch) => {
  const dispatchGetLoans = query => dispatch(getLoans(query));
  const debouncedDispatchGetLoans = debounce(dispatchGetLoans, 500);
  return ({
    dispatchInitQuery: () => dispatch(initQuery()),
    dispatchFetchCategories: businessId => dispatch(fetchCategories(businessId)),
    dispatchGetLoans: debouncedDispatchGetLoans,
    dispatchInitGetLoans: (requestedSkip, take) => dispatch(initGetLoans(requestedSkip, take)),
    dispatchReset: () => dispatch(reset()),
    dispatchToggleViewAll: () => dispatch(toggleViewAll()),
    dispatchSelectBusiness: businessId => dispatch(selectBusiness({ id: businessId })),
    dispatchRemoteFiltersChanged: filters => dispatch(updateRemoteFilters(filters)),
    dispatchExportLoans: (query) => dispatch(downloadLoans(query)),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoansSummary);
