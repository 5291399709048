/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextArea.module.scss';

const TextAreaInput = ({
 value, placeholder, disabled, className, onChange, rows, readOnly, onBlur, onFocus, name, maxLength, autoFocus,
}) => (
  <textarea
    name={name}
    className={classNames(styles.textArea, className)}
    value={value}
    placeholder={placeholder}
    disabled={disabled}
    onChange={event => onChange(event.target.value)}
    rows={rows}
    readOnly={readOnly}
    onBlur={onBlur}
    onFocus={onFocus}
    maxLength={maxLength}
    autoFocus={autoFocus}
  />
);

TextAreaInput.defaultProps = {
  value: undefined,
  placeholder: undefined,
  disabled: false,
  readOnly: false,
  autoFocus: false,
  className: undefined,
  onBlur: undefined,
  onFocus: undefined,
  rows: undefined,
  maxLength: undefined,
  name: undefined,
  onChange: () => {},
};

TextAreaInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.string,
};

export default TextAreaInput;
