/* eslint-disable */
import get from 'lodash/get';
import { mapLogToProps } from './utils';
import { THIRD_PARTY_TYPE_MAP } from '../../../Contacts/constants';
import { toBasicCurrency } from 'utils/formatters';

const typeOf = (origin) => {
  const changes = get(origin, 'eventDetail.changes', []);
  for (let change of changes) {
    const type = /(persons|referrer|thirdParties)/.exec(change.path)?.[0];
    if (type) return type;
  }
};

const contactChangeLogTransformer = origin => {
  const originType = typeOf(origin);

  if (originType === 'thirdParties') {
    return thirdPartiesChangeTransformer(origin);
  }

  if (originType === 'referrer') {
    return referrerChangeTransformer(origin);
  }

  return applicantContactsChangeTransformer(origin);
};

const referrerChangeTransformer = origin => mapLogToProps({
  origin,
  type: 'CONTACTS',
  content: `Referrer details updated`,
  fieldMap: {
    referralCompany: ['Referrer'],
    referralFee: [, toBasicCurrency],
    isExistingClientOverride: ['Client Origin', value => value === 'true' ? 'Existing Client' : 'New Client'],
  }
});

const thirdPartiesChangeTransformer = origin => {
  const extraInfo = JSON.parse(get(origin, 'eventDetail.extraInfo', '{}'));
  const thirdPartiesInfo = get(extraInfo, 'thirdParties') ?? extraInfo;
  const thirdPartyId = /thirdParties\[([a-zA-Z0-9,-]+)]/.exec(get(origin, 'eventDetail.changes[0].path'))[1];
  const type = get(THIRD_PARTY_TYPE_MAP, get(thirdPartiesInfo, thirdPartyId, ''), get(thirdPartiesInfo, thirdPartyId, ''));
  return mapLogToProps({
    origin,
    type: 'CONTACTS',
    summary: `Third parties #action - ${type}`,
    handleChanges: changes => {
      const filterRegex = /thirdParties\[.*\].(isSearched|businessPartnerId)/;
      return changes.filter((value) => (
        filterRegex.exec(value.path) === null
      ));
    }
  });
};

const applicantContactsChangeTransformer = origin => {
  const extraInfo = JSON.parse(get(origin, 'eventDetail.extraInfo', '{}'));
  const applicantsInfo = get(extraInfo, 'applicants') ?? extraInfo;
  return mapLogToProps({
    origin,
    type: 'CONTACTS',
    content: 'Applicant contacts details updated',
    fieldMap: {
      isMainContact: ['Main Contact'],
      isSurveyContact: ['Survey'],
    },
    handleChanges: changes => {
      const filteredChanges = [];
      changes.forEach(({ path, value }) => {
        // only care who is mainContact and surveyContact
        if (value === 'true') {
          const applicantId = /(?:applicants|guarantors).persons\[([a-zA-Z0-9,-]+)]/.exec(path)[1];
          filteredChanges.push({ path, value: applicantsInfo[applicantId] });
        }
      });
      return filteredChanges;
    },
  });
};

export default contactChangeLogTransformer;
