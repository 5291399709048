import React from 'react';
import PropTypes from 'prop-types';
import styles from './LabelledFieldValue.module.scss';

const LabelledFieldValue = ({ label, withLabel, children }) => (
  <div className={styles.wrapper}>
    {withLabel && <span className={styles.label}>{label}</span>}
    {children}
  </div>
);

LabelledFieldValue.defaultProps = {
  withLabel: false,
  label: '',
};

LabelledFieldValue.propTypes = {
  label: PropTypes.string,
  withLabel: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default LabelledFieldValue;
