import React from 'react';
import PropTypes from 'prop-types';
import FormsyNumberInput from 'shared/components/formFields/Formsy/FormsyNumberInput';

export const InputMoney = ({
 value, name, required, disabled, isAllowed,
}) => (
  <FormsyNumberInput
    value={value}
    name={name}
    prefix="$"
    decimalScale={2}
    allowNegative={false}
    fixedDecimalScale
    fullWidth
    required={required}
    disabled={disabled}
    isAllowed={isAllowed}
  />
  );

InputMoney.defaultProps = {
  value: undefined,
  required: false,
  disabled: false,
  isAllowed: () => true,
};

InputMoney.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isAllowed: PropTypes.func,
};
