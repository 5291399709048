const processValue = (value) => {
  if (value === undefined || value === null) {
    return null;
  }
  if (typeof value === 'string') {
    return `"${value}"`;
  }
  if (Array.isArray(value)) {
    if (typeof value[0] === 'object') {
      return `[${value.map(convertObjectToGraphQLQuery)}]`;
    }
    return JSON.stringify(value);
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value;
};

export const convertObjectToGraphQLQuery = (obj) => {
  const convertedObject = Object.keys(obj)
    .map(key => `${key}: ${processValue(obj[key])}`)
    .join();
  return `{${convertedObject}}`;
};

export default (list = []) => {
  const convertedList = list.map(convertObjectToGraphQLQuery);

  return `[${convertedList}]`;
};
