import {
  formatCostCalculation,
  formatDiscountMonth, formatLoanTerm,
  formatOngoingFee,
  toDollarAmount, toPercentage,
  toRatePercentage,
} from '../../formatterUtils';

export const getAllFeatures = (loading, error, data) => {
  if (loading || error) {
    return undefined;
  }

  return data ? data.allFeatures : undefined;
};

export const formatAttribute = (attributeName, product) => {
  const COMPARISON_RATE_DEFAULT = '--%';
  const repaymentTypeAvailable = type =>
    product.repaymentAvailable && product.repaymentTypes && product.repaymentTypes.indexOf(type) >= 0;
  const formatterMap = {
    totalCost: formatCostCalculation,
    firstPeriodRepayment: repayment =>
      formatCostCalculation(repayment, product.repaymentAvailable),
    firstPeriodPrincipalAndInterest: repayment =>
      formatCostCalculation(repayment, repaymentTypeAvailable('Principal and Interest Repayment')),
    firstPeriodInterestOnly: repayment =>
      formatCostCalculation(repayment, repaymentTypeAvailable('Interest Only')),
    upfrontFee: toDollarAmount,
    ongoingFee: fee => formatOngoingFee(fee, product.ongoingFeeFrequency),
    comparisonRate: cr => toRatePercentage(cr, COMPARISON_RATE_DEFAULT),
    standardRate: toRatePercentage,
    discountRate: toRatePercentage,
    discountMonths: formatDiscountMonth,
    minLoanValueRatio: toPercentage,
    maxLoanValueRatio: toPercentage,
    minLoanAmount: toDollarAmount,
    maxLoanAmount: toDollarAmount,
    minTerm: formatLoanTerm,
    maxTerm: formatLoanTerm,
    lmi: toDollarAmount,
  };

  return (formatterMap[attributeName] || (a => a))(product[attributeName]);
};
