import React from 'react';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDisplayAddress } from 'ApplicationTracking/utils/address';
import styles from '../styles.module.scss';
import GovernmentCharges from './GovernmentCharges';
import OtherCosts from './OtherCosts';
import ConnectedSubtotalPurchaseCosts from './SubtotalPurchaseCosts';
import { EmptyCostToPurchases } from './EmptyCostToPurchase';
import { AssetSection } from './AssetSection';
import { getAssetsById } from '../../../redux/selectors';
import { AddressShape } from '../../shared/RealEstateAsset/shapes';

const EditCostToPurchase = ({
 costToPurchasePath, index, asset, title, userGuidanceCode,
}) => {
  const assetValue = asset?.isForConstructionLoan ? asset?.contractPrice?.transferOfLandAmount : asset.value;

  return (
    <div className={styles.costToPurchase}>
      <InformationSection
        title={`Cost to purchase ${title}`}
        wrapperStyle={styles.costToPurchaseWrapper}
        contentWrapperStyle={styles.costToPurchaseContentWrapper}
        titleWrapperStyle={styles.titleWrapperStyle}
      >
        <AssetSection asset={asset} />
        <GovernmentCharges costToPurchasePath={costToPurchasePath} />
        <OtherCosts costToPurchasePath={costToPurchasePath} />
        <ConnectedSubtotalPurchaseCosts
          index={index}
          assetValue={assetValue}
          userGuidanceCode={userGuidanceCode}
        />
      </InformationSection>
    </div>
  );
};

EditCostToPurchase.propTypes = {
  costToPurchasePath: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  userGuidanceCode: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    primaryPurpose: PropTypes.string,
    value: PropTypes.number,
    address: AddressShape,
    isForConstructionLoan: PropTypes.bool,
    contractPrice: PropTypes.shape({
      transferOfLandAmount: PropTypes.number,
    }),
  }).isRequired,
};

EditCostToPurchase.defaultProps = {
  title: '',
};

const EditCostToPurchasesComponent = ({ fields, assets, userGuidanceCodes }) => {
  if (fields.length === 0) {
    return <EmptyCostToPurchases />;
  }
  return fields.map(
    (name, index) => {
      const asset = assets[fields.get(index).assetId];
      const address = getDisplayAddress(asset.address);
      return (
        <EditCostToPurchase
          title={address}
          costToPurchasePath={name}
          index={index}
          asset={assets[fields.get(index).assetId]}
          key={name}
          userGuidanceCode={userGuidanceCodes[index]}
        />
      );
    },
  );
};

const mapStateToProps = (state) => ({
  assets: getAssetsById(state),
});

EditCostToPurchasesComponent.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  assets: PropTypes.object.isRequired,
};

const renderEditCostToPurchases = connect(mapStateToProps)(EditCostToPurchasesComponent);

const EditCostToPurchases = ({ userGuidanceCodes }) => (
  <FieldArray
    name="costToPurchases"
    component={renderEditCostToPurchases}
    userGuidanceCodes={userGuidanceCodes}
  />
);

EditCostToPurchases.propTypes = {
  userGuidanceCodes: PropTypes.array.isRequired,
};

export default EditCostToPurchases;
