export const FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_REQUEST = 'REPORTS_PAGE_FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_REQUEST';
export const FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_SUCCESS = 'REPORTS_PAGE_FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_SUCCESS';
export const FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_ERROR = 'REPORTS_PAGE_FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_ERROR';

export const FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_REQUEST = 'FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_REQUEST';
export const FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_SUCCESS = 'FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_SUCCESS';
export const FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_ERROR = 'FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_ERROR';

export const FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_REQUEST = 'REPORTS_PAGE_FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_REQUEST';
export const FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_SUCCESS = 'REPORTS_PAGE_FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_SUCCESS';
export const FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_ERROR = 'REPORTS_PAGE_FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_ERROR';

export const FETCH_COMMISSION_SUMMARY_META_REQUEST = 'FETCH_COMMISSION_SUMMARY_META_REQUEST';
export const FETCH_COMMISSION_SUMMARY_META_SUCCESS = 'FETCH_COMMISSION_SUMMARY_META_SUCCESS';
export const FETCH_COMMISSION_SUMMARY_META_ERROR = 'FETCH_COMMISSION_SUMMARY_META_ERROR';

export const FETCH_REPORTING_META_REQUEST = 'REPORTS_PAGE_FETCH_REPORTING_META_REQUEST';
export const FETCH_REPORTING_META_SUCCESS = 'REPORTS_PAGE_FETCH_REPORTING_META_SUCCESS';
export const FETCH_REPORTING_META_ERROR = 'REPORTS_PAGE_FETCH_REPORTING_META_FAIL';

export const DOWNLOAD_LENDER_RECONCILIATION_REPORT_START = 'REPORTS_PAGE_DOWNLOAD_LENDER_RECONCILIATION_REPORT_START';
export const DOWNLOAD_LENDER_RECONCILIATION_REPORT_FINISHED = 'REPORTS_PAGE_DOWNLOAD_LENDER_RECONCILIATION_REPORT_FINISHED';

export const DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_START = 'REPORTS_PAGE_DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_START';
export const DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_END = 'REPORTS_PAGE_DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_END';

export const FETCH_LOANS_FOR_CONVERSION_RATE_SUCCESS = 'FETCH_LOANS_FOR_CONVERSION_RATE_SUCCESS';
export const FETCH_LOANS_FOR_CONVERSION_RATE_REQUEST = 'FETCH_LOANS_FOR_CONVERSION_RATE_REQUEST';
export const FETCH_LOANS_FOR_CONVERSION_RATE_ERROR = 'FETCH_LOANS_FOR_CONVERSION_RATE_ERROR';
