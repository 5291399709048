import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

export const filterAttributeName = {
  adviserName: 'adviserId',
  applicantName: 'applicantName',
  lenderName: 'lenderName',
  applicationNumber: 'applicationNumber',
  currentStatus: 'statusList',
  timeFilterList: 'timeFilterList',
  preApproval: 'preApproval',
};

export const timeColumn = ['created', 'submitted', 'conditionalApproval', 'unconditionalApproval', 'expectedSettlement', 'settled'];

export const getFromTimeWithUTCFormatter = (item) => {
  if (isEmpty(item)) return null;
  return (!!item.year || !!item.month)
    ? moment.utc(moment(new Date(`${item.year}-${item.month}`)).startOf('month')).format()
    : null;
};

export const getToTimeWithUTCFormatter = (item) => {
  if (isEmpty(item)) return null;
  return (!!item.year || !!item.month)
    ? moment.utc(moment(new Date(`${item.year}-${item.month}`)).endOf('month')).format()
    : null;
};

export const dateSelectionFilterHandler = (remoteFilters, dateList) => (
  remoteFilters.filter(item => dateList.includes(item.columnName))
  .map(item => ({
    field: item.columnName,
    from: getFromTimeWithUTCFormatter(item.value.from),
    to: getToTimeWithUTCFormatter(item.value.to),
  }))
);

export const preApprovalFilterHandler = (remoteFilters) => remoteFilters.map(item => {
  if (item.columnName === 'preApproval') {
    const value = item.value.length > 1 ? null : item.value[0];
    return {
      ...item,
      value,
    };
  }
  return item;
});

export const commonFilterHandler = (remoteFilters, dateList) => (
  remoteFilters.filter(item => !dateList.includes(item.columnName))
);

const buildFilter = (remoteFilters, filterNameMap) => mapValues(
  keyBy(remoteFilters, f => (filterNameMap[f.columnName])),
  v => (typeof v.value === 'string' ? v.value.trim() : v.value),
);

export const filterFormatter = (remoteFilters, dateList, filterNameMap) => {
  const res = [];
  const filterList = preApprovalFilterHandler(remoteFilters);
  res.push(...commonFilterHandler(filterList, dateList));
  res.push({
    columnName: 'timeFilterList',
    value: dateSelectionFilterHandler(filterList, dateList),
  });
  return buildFilter(res, filterNameMap);
};

export const getRequestParams = query => {
  const {
    take, skip, businessId, remoteFilters, timeZoneId,
  } = query;
  const filter = filterFormatter(remoteFilters, timeColumn, filterAttributeName);
  return {
    take,
    skip,
    businessId,
    filter,
    timeZoneId,
  };
};
