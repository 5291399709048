import { createReducer } from '../../../../../../utils/reducerGenerator';
import { FETCHING_STATUS } from '../../../../../../constants';
import types from '../../constants';

const initialState = {
  lmi: null,
  mortgageRegistrationFee: null,
  costToPurchases: [],
  fetchingStatus: FETCHING_STATUS.INIT,
};

const fees = createReducer(initialState, {
  [types.FUNDING_WORKSHEET_FEES_CALCULATION_START]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.FUNDING_WORKSHEET_FEES_CALCULATION_SUCCESS]: (state, action) => ({
    ...action.payload,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
  }),
  [types.FUNDING_WORKSHEET_FEES_CALCULATION_ERROR]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});

export default fees;
