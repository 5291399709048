import React, {
 useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/Modal/Modal';
import TextArea from 'shared/components/formFields/TextArea';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import lodashRemove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Icon from 'shared/components/Icon/Icon';
import InputLabelWrapper from 'shared/components/formFields/InputLabelWrapper';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import DateBox from 'devextreme-react/date-box';
import assignApi from 'ApplicationTracking/components/ApplicationDetails/api/assigneeApi';
import get from 'lodash/get';
import classNames from 'classnames';
import { toUTCStr } from 'utils/datetime';
import Select from 'shared/components/formFields/Select';
import styles from './TaskDetailModal.module.scss';
import CommentHistory from './CommentHistory';

const formatAssignee = assignee =>
  (!isEmpty(assignee)
    ? { label: `${assignee.firstName} ${assignee.surname}`, value: assignee.id }
    : { label: 'Unassigned' });

const formatAssignees = (assigneesCandidates, currentUserId) => {
  const assembledAssignees = assigneesCandidates.map(formatAssignee);

  const [self] = lodashRemove(
    assembledAssignees,
    ({ value }) => value === currentUserId,
  );

  const partialList = [
    { label: 'Unassigned' },
    ...sortBy(assembledAssignees, ['label']),
  ];

  return isEmpty(self)
    ? partialList
    : [{ ...self, label: `${self.label} (Assign to me)` }, ...partialList];
};

export const TaskDetailModal = ({
                           taskDetailStatus,
                           remove,
                           update,
                           appendComment,
                           userId,
                           applicationId,
                           noDataMessage,
                           task,
                           onRequestClose,
                         }) => {
  const [assigneeCandidates, setAssigneeCandidates] = useState([]);

  const [taskInfo, updateTaskInfo] = useState({ ...task });

  const errors = useMemo(() => {
    if (isEmpty(get(taskInfo, 'name', '').trim())) {
      return {
        name: 'Please enter a Task summary',
      };
    }
    return {};
  }, [taskInfo]);

  const [meta, setMeta] = useState({ name: { touched: false } });

  const taskId = get(task, 'id', '');

  useEffect(() => {
    if (get(task, 'id', '') !== get(taskInfo, 'id', '')) {
      updateTaskInfo({ ...task });
    }
  }, [task, taskInfo, updateTaskInfo]);

  const handleUpdateTask = () => {
    if (isEmpty(errors)) {
      const checkMakeNoChanges = trim(taskInfo.name) === trim(task.name)
        && taskInfo.description === task.description
        && taskInfo.dueDateTime === task.dueDateTime
        && get(taskInfo, 'assignee.id') === get(task, 'assignee.id');

      if (checkMakeNoChanges) {
        onRequestClose();
      } else {
        update(taskInfo).then(onRequestClose);
      }
    } else {
      setMeta({ name: { touched: true } });
    }
  };

  const handleDeleteTask = () => remove(task.id).then(onRequestClose);

  useEffect(() => {
    assignApi(applicationId).then(
      ({ data }) => !isEmpty(data) && setAssigneeCandidates(data),
    );
  }, [applicationId, setAssigneeCandidates]);

  const tertiaryButton = (
    <TertiaryButton
      className={styles.clearDueDate}
      onClick={() => updateTaskInfo({ ...taskInfo, dueDateTime: null })}
    >
      Remove due date
    </TertiaryButton>
  );
  return (
    <Modal
      isOpen
      header={<div className={styles.modalHeader}>Task details</div>}
      footer={(
        task ? (
          <CommentHistory
            comments={task.comments}
            taskId={taskId}
            onAppendComment={appendComment}
            selectTask={null}
          />
        ) : null
      )}
      onRequestClose={onRequestClose}
      className={styles.taskDetailModal}
    >
      <>
        {task && (
          <div className={styles.modalContent}>
            <div className={styles.formRow}>
              <TextInput
                className={styles.contentItem}
                label="Task summary"
                onChange={value => updateTaskInfo({ ...taskInfo, name: value })}
                onBlur={() => setMeta({ ...meta, name: { touched: true } })}
                value={taskInfo.name || ''}
                errorMessage={(meta.name.touched && errors.name) ? errors.name : ''}
                fullWidth
              />
            </div>
            <div className={classNames(styles.formRow, styles.flex)}>
              <div className={styles.formRowCol}>
                <Select
                  label="Assigned to"
                  onChange={({ value: assigneeId }) =>
                    updateTaskInfo({
                      ...taskInfo,
                      assignee: assigneeId
                        ? assigneeCandidates.find(({ id }) => id === assigneeId)
                        : null,
                    })}
                  options={formatAssignees(assigneeCandidates, userId)}
                  value={formatAssignee(taskInfo.assignee)}
                  fullWidth
                />
              </div>
              <div className={styles.formRowCol}>
                <InputLabelWrapper
                  label={(
                    <div className={styles.labelWrapper}>
                      <span>Due date</span>
                      {' '}
                      {tertiaryButton}
                    </div>
                  )}
                  className={`${styles.contentItem} ${styles.dateTimeLabel}`}
                >
                  <div className={styles.customizedDateTimePicker}>
                    <DateBox
                      width="100%"
                      height="100%"
                      placeholder="Select a date and a time"
                      value={taskInfo.dueDateTime}
                      onValueChanged={({ value }) =>
                        updateTaskInfo({
                          ...taskInfo,
                          dueDateTime: value ? toUTCStr(value) : null,
                        })}
                      displayFormat="d MMMM yyyy h:mm a"
                      type="datetime"
                      useMaskBehavior
                    />
                  </div>
                </InputLabelWrapper>
              </div>
            </div>
            <div className={styles.formRow}>
              <TextArea
                className={styles.contentItem}
                label="Task description"
                onChange={value => updateTaskInfo({ ...taskInfo, description: value })}
                value={taskInfo.description || ''}
              />
            </div>
            <div className={styles.buttonGroup}>
              <PrimaryButton
                loading={taskDetailStatus.isUpdating}
                onClick={handleUpdateTask}
              >
                Save changes
              </PrimaryButton>
              <SecondaryButton
                loading={taskDetailStatus.isDeleting}
                onClick={handleDeleteTask}
                className={styles.deleteButton}
              >
                <span className={styles.buttonText}>Delete task</span>
                <Icon name="delete" size="18px" color="#aa2020" />
              </SecondaryButton>
            </div>
          </div>
        )}
        {!task && (
          <div className={styles.noDataMessage}>
            {noDataMessage}
          </div>
        )}
      </>
    </Modal>
  );
};

TaskDetailModal.propTypes = {
  taskDetailStatus: PropTypes.shape().isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  appendComment: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  task: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    dueDateTime: PropTypes.string,
    id: PropTypes.string,
    comments: PropTypes.array,
  }),
  onRequestClose: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string.isRequired,
};

TaskDetailModal.defaultProps = {
  task: null,
};
