import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import noop from 'lodash/noop';
import find from 'lodash/find';
import classNames from 'classnames/bind';
import {
  Chart as DXChart,
  ArgumentAxis,
  BarSeries,
  LineSeries,
  Legend,
  ScatterSeries,
  SplineSeries,
  PieSeries,
  Tooltip,
  ValueAxis,
  AreaSeries,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import {
  Animation, EventTracker, Stack, HoverState,
} from '@devexpress/dx-react-chart';
import omit from 'lodash/omit';
import styles from './styles.module.scss';
import { MEDIA_TYPES } from '../../../constants/media';
import useMediaType from '../../hooks/useMediaType';

const cx = classNames.bind(styles);

const BarPoint = props => (
  <BarSeries.Point {...props} />
);

const SplinePoint = props => (
  <ScatterSeries.Point point={{ size: 7 }} {...props} />
);

const SplineWithPoints = props => (
  <>
    <SplineSeries.Path {...props} />
    <ScatterSeries.Path {...props} />
  </>
);

const LineWithPoints = props => (
  <>
    <LineSeries.Path {...props} />
    <ScatterSeries.Path {...props} />
  </>
);

const serieMapper = {
  bar: BarSeries,
  line: LineSeries,
  spline: SplineSeries,
  area: AreaSeries,
  pie: PieSeries,
};

const decorationMapper = {
  bar: {
    pointComponent: BarPoint,
  },
  line: {
    seriesComponent: LineWithPoints,
    pointComponent: SplinePoint,
  },
  spline: {
    seriesComponent: SplineWithPoints,
    pointComponent: SplinePoint,
  },
};

const LegendLabel = () => null;

const LenderRoot = ({ className, ...others }) => (<div className={cx(styles.legendRoot, 'legend_root', className)} {...others} />);

LenderRoot.propTypes = {
  className: PropTypes.string,
};
LenderRoot.defaultProps = {
  className: '',
};

export const Chart = (props) => {
  const {
    series,
    tooltip,
    data,
    hoverTarget: hoverTargetFromProps,
    onHoverChange,
    onClick,
    argumentLabelFormatter,
    onLegendClick,
    valueAxis,
    argumentAxis,
    ...others
  } = props;

  // eslint-disable-next-line react/prop-types
  const LegendMarker = useMemo(() => ({ name, color }) => {
    const serie = find(series, { name });
    const { displayName } = serie;
    const type = serie.type || 'bar';
    return (
      <div
        className={styles.markerContainer}
        role="button"
        tabIndex={-1}
        onClick={e => onLegendClick(e, serie)}
        onKeyDown={null}
      >
        <span
          style={{ backgroundColor: color }}
          className={styles[`${type}Legend`]}
        />
        <span>
          {displayName || name}
        </span>
      </div>
    );
  }, [onLegendClick, series]);

  const [hoverTargetFromState, setHoverTargetInState] = useState(hoverTargetFromProps);

  const finalHoverTarget = hoverTargetFromProps === undefined ? hoverTargetFromState : hoverTargetFromProps;

  const handleHoverChange = (target) => {
    setHoverTargetInState(target);
    if (isFunction(onHoverChange)) {
      onHoverChange(target);
    }
  };

  const mediaType = useMediaType();

  const labelComponent = useMemo(() => (isFunction(argumentLabelFormatter)
    ? args => (
      <ArgumentAxis.Label
        {...args}
        transform={mediaType === MEDIA_TYPES.PHONE ? `rotate(-45 ${args.x} ${args.y})` : ''}
        text={argumentLabelFormatter(args.text)}
      />
    ) : ArgumentAxis.Label),
    [argumentLabelFormatter, mediaType]);

  return (
    <DXChart data={data} {...others}>
      {!argumentAxis.hide && <ArgumentAxis labelComponent={labelComponent} {...omit(argumentAxis, 'hide')} />}
      {!valueAxis.hide && <ValueAxis {...omit(valueAxis, 'hide')} />}
      {series.map(({
                     type,
                     color,
                     key,
                     name,
                   }) => {
        const Com = get(serieMapper, type, BarSeries);
        const decoration = get(decorationMapper, type, {});
        return (
          <Com
            key={key}
            name={name}
            argumentField="key"
            valueField={key}
            color={color}
            {...decoration}
          />
        );
      })}
      <Animation />
      <Legend
        position="bottom"
        markerComponent={LegendMarker}
        labelComponent={LegendLabel}
        rootComponent={LenderRoot}
        itemComponent="div"
      />
      <Stack stacks={[{ series: series.filter(it => it.stack).map(it => it.name) }]} />
      <EventTracker onClick={onClick} />
      <HoverState hover={finalHoverTarget} onHoverChange={handleHoverChange} />
      {tooltip && <Tooltip {...tooltip} />}
    </DXChart>
  );
};

Chart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    stack: PropTypes.bool,
  })),
  data: PropTypes.array,
  tooltip: PropTypes.object,
  hoverTarget: PropTypes.object,
  onHoverChange: PropTypes.func,
  onClick: PropTypes.func,
  argumentLabelFormatter: PropTypes.func,
  onLegendClick: PropTypes.func,
  valueAxis: PropTypes.object,
  argumentAxis: PropTypes.object,
};

Chart.defaultProps = {
  series: [],
  data: [],
  tooltip: {},
  hoverTarget: undefined,
  onHoverChange: undefined,
  onClick: undefined,
  argumentLabelFormatter: undefined,
  onLegendClick: noop,
  valueAxis: {},
  argumentAxis: {},
};
