import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { TRANSACTION_TYPES } from '../../constants';
import { getRentalIncomes } from '../incomes';
import {
  convertNetIncomeToGrossIncome, getCombinedApplicantIds, toAnnualValue, toMonthlyValue,
  calApplicantOwnershipsPercentage, getPersonApplicantIds,
} from '../../../../../utils';
import { getAllApplicants } from '../applicants';
import { INVESTMENT } from '../../../ApplicationDetailContentNav/shared/RealEstateAsset/constants';
import { clearingStatuses } from '../../../ApplicationDetailContentNav/Liabilities/constants';

const calcAnnualIncomes = incomes => sumBy(
  incomes?.filter(income => !isEmpty(income.periodUnit))
    .map(income => {
      const annualValue = toAnnualValue(income.periodUnit, income.value);
      if (income?.type === 'NetRegularOvertime' || income?.type === 'NetSalary') {
        return convertNetIncomeToGrossIncome(annualValue);
      }
      return annualValue;
    }),
);

const calculateCompanyIncomes = companies => {
  const incomes = flatten(map(companies, company => company?.companyIncomeInfo?.incomes));
  return sumBy(incomes, income => get(income, 'incomeValues[0]', 0));
};

const calcAnnualIncomesWithOwnershipPercentage = (applicantIds, incomes) => sumBy(
  incomes?.filter(income => !isEmpty(income.periodUnit))
    .map(income => {
      const annualValue = calApplicantOwnershipsPercentage(applicantIds, income.ownerships)
      * toAnnualValue(income.periodUnit, income.value);
      if (income?.type === 'NetRegularOvertime' || income?.type === 'NetSalary') {
        return convertNetIncomeToGrossIncome(annualValue);
      }
      return annualValue;
    }),
);

const calculateTotalAnnualIncome = (applicants, finance, rentalIncomes,
   applicantIds) => {
  const employmentsIncomes = (applicants?.persons ?? []).filter(person => !isEmpty(person.employments))
    .flatMap(person => person.employments)
    .flatMap(employment => employment.employmentIncomes);

  return calcAnnualIncomesWithOwnershipPercentage(applicantIds, finance?.incomes)
      + calcAnnualIncomesWithOwnershipPercentage(applicantIds, rentalIncomes)
      + calcAnnualIncomes(employmentsIncomes)
      + calculateCompanyIncomes(applicants.companies);
};

const calValueByApplicantsPercentage = (applicantIds, valueFormatter) => item =>
calApplicantOwnershipsPercentage(applicantIds, item.ownerships) * (valueFormatter(item) ?? 0);

const nonRealEstateAssetsValueFormatter = item => item.value;
const realEstateAssetsValueFormatter = item => {
  if (item.isForConstructionLoan) {
    return item.currentValue;
  }
  return item.value || 0;
};
const liabilitiesValueFormatter = item => item.limit - (item.clearingAmount || 0);
const monthlyLivingExpensesValueFormatter = ({ periodUnit, value }) => toMonthlyValue(periodUnit, value);
const investmentPropertyCostValueFormatter = item => toMonthlyValue(get(item, 'investmentPropertyCost.frequency'), get(item, 'investmentPropertyCost.value'));

export const getFinancialSummaryInfo = createSelector(
  state => get(state, 'application.applicationDetail.finance'),
  getRentalIncomes,
  getAllApplicants,
  (finance, rentalIncomes, applicants) => {
    const applicantIds = getCombinedApplicantIds(applicants);

    const totalAnnualIncome = calculateTotalAnnualIncome(
      applicants, finance, rentalIncomes, applicantIds,
      );
    const totalSecurityValue = sumBy(
      finance?.assets?.realEstates?.filter(realEstate => realEstate.asSecurity),
      (item) => (isNil(item.securityLimit) ? item.value : item.securityLimit),
    );

    const totalOwnedAssets = (sum(finance?.assets?.nonRealEstates
        ?.map(calValueByApplicantsPercentage(applicantIds, nonRealEstateAssetsValueFormatter)) ?? [])
      + sum(finance?.assets?.realEstates?.filter(realEstate => realEstate.transactionType === TRANSACTION_TYPES.OWNS)
        ?.map(calValueByApplicantsPercentage(applicantIds, realEstateAssetsValueFormatter)) ?? []));

    const liabilitiesWithoutTopUp = filter(
      finance?.liabilities,
      item => item.clearingStatus !== clearingStatuses.TopUp,
    );
    const itemsForTotalLiabilities = filter(
      liabilitiesWithoutTopUp,
      item => item.clearingStatus !== clearingStatuses.Full,
    );

    const totalLiabilities = sum(itemsForTotalLiabilities
      .map(calValueByApplicantsPercentage(applicantIds, liabilitiesValueFormatter)) ?? []);

    const totalMonthlyLivingExpenses = sum(finance?.expenseInfo?.householdExpenses.map(({ expenses }) =>
      expenses.map(calValueByApplicantsPercentage(applicantIds, monthlyLivingExpensesValueFormatter)))
      .flat().filter(Boolean));

    const totalInvestmentPropertyCostExpenses = sum(finance?.assets?.realEstates?.filter(realEstate =>
      realEstate.primaryPurpose === INVESTMENT
      && !isNil(get(realEstate, 'investmentPropertyCost.value'))
      && !isNil(get(realEstate, 'investmentPropertyCost.frequency')))
      .map(calValueByApplicantsPercentage(getPersonApplicantIds(applicants), investmentPropertyCostValueFormatter)));
    return (
      {
        totalAnnualIncome,
        totalOwnedAssets,
        totalSecurityValue,
        totalLiabilities,
        totalMonthlyLivingExpenses: totalMonthlyLivingExpenses + totalInvestmentPropertyCostExpenses,
      }
    );
  },
);
