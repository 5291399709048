import { APPLICATION_TYPES, APPLICATION_TYPES_LABELS } from 'shared/components/productSearch/constants';

export const APPLICATION_TYPE_OPTIONS = [{
    value: APPLICATION_TYPES.NEW_LOAN,
    label: APPLICATION_TYPES_LABELS[APPLICATION_TYPES.NEW_LOAN],
  }, {
    value: APPLICATION_TYPES.TOP_UP,
    label: APPLICATION_TYPES_LABELS[APPLICATION_TYPES.TOP_UP],
  }];

export const NEW_LOAN_CONFIG = {
  label: 'Loan amount ($)',
  fieldName: 'loanAmount',
  totalLabel: 'Total loan amount ($)',
  totalFieldName: 'totalLoanAmount',
};

export const TOP_UP_CONFIG = {
  label: 'Combined loan amount ($)',
  fieldName: 'combinedLoanAmount',
  totalLabel: 'Total combined loan amount ($)',
  totalFieldName: 'totalCombinedLoanAmount',
};

export const FORM_NAME = 'embeddedProductSearchCriteriaForm';
