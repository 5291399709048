import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import Icon from 'shared/components/Icon/Icon';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import {
  employmentTypes,
  EMPTY_CHARACTER,
  addbackTypeOptions as ADDBACK_TYPE_OPTIONS,
  governmentBenefitsTypeOptions as GOVERNMENT_BENEFITS_OPTIONS,
  getIncomeOptionsFromEmploymentType as getIncomeOptions,
  PAYEIncomeTypeOptions as PAYE_OPTIONS,
  selfEmployedIncomeTypeOptions as SELF_EMPLOYED_OPTIONS,
  unemployedRetiredIncomeTypeOptions as UNEMPLOYED_OPTIONS,
} from './constants';
import {
  AddbackTypeField,
  DetailsField,
  EmploymentIncomeTypeField,
  FrequencyField,
  GovernmentBenefitsField,
  PreviousValueField,
  ValueField,
} from './employmentIncomeFields';
import styles from './Employment.module.scss';
import {
  disableSelected,
  isAddbackTypeSelected,
  isGovernmentBenefitsTypeSelected,
  isTypeSelected,
} from './IncomeTypeUtils';

const shouldDisableAdd = (employmentType, selectedTypes) => {
  switch (employmentType) {
    case employmentTypes.PAYE:
      return selectedTypes.length === PAYE_OPTIONS.length;
    case employmentTypes.SELF_EMPLOYED:
      return selectedTypes.length === SELF_EMPLOYED_OPTIONS.length - 1 + ADDBACK_TYPE_OPTIONS.length;
    case employmentTypes.UNEMPLOYED:
    case employmentTypes.RETIRED:
      return selectedTypes.length
        === UNEMPLOYED_OPTIONS.length - 1 + GOVERNMENT_BENEFITS_OPTIONS.length;
    default:
      return false;
  }
};

const EmploymentIncome = ({
  fieldNamePrefix: prefix,
  employmentType,
  selectedTypes,
  hasEditPermission,
  appendIncome,
  removeIncome,
  isLastOne,
  isOnlyOne,
  isCompanyProfitBeforeTax,
  clearIncomeField,
}) => {
  const isPAYG = employmentType === employmentTypes.PAYE;
  const isSelfEmployed = employmentType === employmentTypes.SELF_EMPLOYED;
  const isUnemployed = employmentType === employmentTypes.UNEMPLOYED;
  const isRetired = employmentType === employmentTypes.RETIRED;
  const typeOptions = getIncomeOptions(employmentType)
    .map(disableSelected(isTypeSelected(selectedTypes)));
  const addbackTypeOptions = ADDBACK_TYPE_OPTIONS
    .map(disableSelected(isAddbackTypeSelected(selectedTypes)));
  const governmentBenefitsTypeOptions = GOVERNMENT_BENEFITS_OPTIONS
    .map(disableSelected(isGovernmentBenefitsTypeSelected(selectedTypes)));
  const disableAdd = useMemo(() => shouldDisableAdd(employmentType, selectedTypes), [employmentType, selectedTypes]);
  return (
    <>
      <EmploymentIncomeTypeField
        fieldNamePrefix={prefix}
        options={typeOptions}
        isSelfEmployed={isSelfEmployed}
        clearIncomeField={clearIncomeField}
      />
      {!isSelfEmployed && <FrequencyField fieldNamePrefix={prefix} />}
      {isSelfEmployed ? <ValueField fieldNamePrefix={prefix} allowNegative={isCompanyProfitBeforeTax} />
        : <ValueField fieldNamePrefix={prefix} />}
      {isSelfEmployed && <PreviousValueField fieldNamePrefix={prefix} allowNegative={isCompanyProfitBeforeTax} />}
      {isSelfEmployed && <AddbackTypeField fieldNamePrefix={prefix} options={addbackTypeOptions} />}
      {isSelfEmployed && <DetailsField fieldNamePrefix={prefix} />}
      {hasEditPermission && (
        <AddRemoveButtonGroup
          addFunc={appendIncome}
          removeFunc={removeIncome}
          showAdd={isLastOne}
          showRemove={!isOnlyOne || (!isPAYG && !isSelfEmployed)}
          disableAdd={disableAdd}
        />
      )}
      {(isRetired || isUnemployed) && (
        <GovernmentBenefitsField fieldNamePrefix={prefix} options={governmentBenefitsTypeOptions} />
      )}
    </>
  );
};

export const AddRemoveButtonGroup = ({
 addFunc, removeFunc, showAdd, showRemove, className, disableAdd,
}) => (
  <div className={classNames(
    styles.singleColumnWrapperOnDesktop, styles.atFifthColumnOnDesktop, styles.atLastColumnOnMobile, className,
  )}
  >
    <div className={styles.buttonGroupHeader}>{EMPTY_CHARACTER}</div>
    <div className={styles.incomeButtonGroup}>
      <div className={classNames(styles.buttonWrapper, styles.closeButtonWrapper)}>
        {showRemove && (
          <TertiaryButton onClick={removeFunc} className={styles.closeButton}>
            <Icon name="close" />
          </TertiaryButton>
        )}
      </div>
      <div className={classNames(styles.buttonWrapper, styles.addButtonWrapper)}>
        {showAdd && (
          <SecondaryButton
            onClick={addFunc}
            className={styles.incomeAddButton}
            disabled={disableAdd}
          >
            <span>Add</span>
            <Icon name="add" size="18" />
          </SecondaryButton>
        )}
      </div>
    </div>
  </div>
);

AddRemoveButtonGroup.propTypes = {
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  showAdd: PropTypes.bool.isRequired,
  showRemove: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disableAdd: PropTypes.bool,
};

AddRemoveButtonGroup.defaultProps = {
  className: '',
  disableAdd: false,
};

EmploymentIncome.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  employmentType: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isLastOne: PropTypes.bool.isRequired,
  isOnlyOne: PropTypes.bool.isRequired,
  appendIncome: PropTypes.func.isRequired,
  removeIncome: PropTypes.func.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  isCompanyProfitBeforeTax: PropTypes.bool.isRequired,
  clearIncomeField: PropTypes.func.isRequired,
};

export default EmploymentIncome;
