import withReadOnly from '../ReadOnlyAbilityWrapper';
import NumberInput from './NumberInput';
import { withFormMeta } from '../../FormMeta';
import { formatAmountWithDefault } from '../../../../utils/formatters';

const NumberInputWithReadonly = withFormMeta(withReadOnly(NumberInput));

NumberInputWithReadonly.defaultProps = {
  readOnlyFormatter: formatAmountWithDefault,
};

export default NumberInputWithReadonly;
