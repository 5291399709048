import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import floor from 'lodash/floor';
import { getVariantModificationFromApi } from 'shared/api';
import { calculateTotalCost, calculateTotalRepayment, calculateVariantUpfrontFee } from 'shared/productUtils';

import * as types from '../types';

const getOption = (lender, variants, lenderOverrides) => {
  const isSplitted = variants.length > 1;
  return ({
    customProduct: true,
    id: `custom_product_${lender}`,
    variants,
    lenderOverrides,
    isSplitted,
    totalRepayment: null,
    totalCost: null,
    lenderName: lender,
    serviceabilityStatus: {
      serviceabilityStatus: 0,
    },
  });
};

const getTerms = (loanCriteria) => {
  if (loanCriteria.productType === 'fixed') {
    return ({
      minTerm: get(loanCriteria, 'fixedLoanTerm'),
      maxTerm: get(loanCriteria, 'fixedLoanTerm'),
    });
  }
    return ({
      minTerm: 1,
      maxTerm: floor(get(loanCriteria, 'loanTerm')),
    });
};

const getFees = (product) => {
  const { applicationFee, legalFee, valuationFee } = product;
  const upfrontFee = calculateVariantUpfrontFee(product);
  return {
    applicationFee: applicationFee || 0,
    legalFee: legalFee || 0,
    valuationFee: valuationFee || 0,
    upfrontFee,
  };
};

const generateFakeId = () => uuidv4();

const getVariants = (lender, products, loansCriteria) => {
  const id = generateFakeId();

  return products.map((product, i) => {
    const loanCriteria = loansCriteria[i];
    const type = (loanCriteria.productType || 'variable').toUpperCase();
    const repaymentTypes = loanCriteria.repaymentType
      ? [loanCriteria.repaymentType]
      : ['Principal and Interest Repayment', 'Interest Only'];

    return {
    id,
    customProduct: true,
    lenderName: lender,
    productName: product.productName,
    displayName: product.productName,
    standardRate: product.standardRate,
    discountRate: product.discountRate,
    discountMonths: product.discountMonths || 0,
    type,
    ...getTerms(loanCriteria),
    ...getFees(product),
    repaymentTypes,
    repaymentAvailable: true,
    ongoingFeeFrequency: 'PER_MONTH',
    ongoingFee: product.ongoingFee || 0,
    featureDetails: [{ featureName: 'Repay Monthly' }],
    lmi: 0,
    source: `launchpad::${id}`,
    totalCost: null,
    firstPeriodInterestOnly: null,
    firstPeriodPrincipalAndInterest: null,
    firstPeriodRepayment: null,
    comparisonRate: null,
  };
});
};

export const addCustomProductToProducts = (state, { customProduct }) => {
  const updatedProducts = [
    customProduct,
    ...state.products,
  ];
  return {
    ...state,
    products: updatedProducts,
    hasCustomProduct: true,
    customProduct,
  };
};

export const createProduct = ({ lender, loanProducts, lenderOverrides = {} }, loansCriteria) => {
  const variants = getVariants(lender, loanProducts, loansCriteria);
  return getOption(lender, variants, lenderOverrides);
};

export const createCalculationContext = (loanCriteria, totalCostTerm, repaymentFrequency) => ({
    loanAmount: get(loanCriteria, 'loanAmount'),
    loanTermYears: get(loanCriteria, 'loanTerm'),
    totalCostTerm,
    interestOnlyTerm: get(loanCriteria, 'interestOnlyTerm'),
    repaymentType: get(loanCriteria, 'repaymentType'),
    repaymentFrequency,
  });

export const mapToQuery = (variant) => ({
    id: variant.id,
    standardRate: variant.standardRate,
    discountRate: variant.discountRate,
    discountTermStart: 0,
    discountTermEnd: variant.discountMonths,
    applicationFee: variant.applicationFee,
    legalFee: variant.legalFee,
    valuationFee: variant.valuationFee,
    upfrontFee: variant.upfrontFee,
    ongoingFee: variant.ongoingFee,
    ongoingFeeFrequency: variant.ongoingFeeFrequency,
    firstPeriodRepayment: variant.firstPeriodRepayment,
    firstPeriodPrincipalAndInterest: variant.firstPeriodPrincipalAndInterest,
    firstPeriodInterestOnly: variant.firstPeriodInterestOnly,
    totalCost: variant.totalCost,
    comparisonRate: variant.comparisonRate,
    repaymentTypes: variant.repaymentTypes,
    featureNames: variant.featureDetails.map(feature => feature.featureName),
    lmi: variant.lmi,
  });

const calculateVariants = (variants, loansCriteria, totalCostTerm, repaymentFrequency) =>
  Promise.all(
      variants.map(
        async (variant, i) => {
          const context = createCalculationContext(loansCriteria[i], totalCostTerm, repaymentFrequency);
          const calculatedVariant = await getVariantModificationFromApi(mapToQuery(variant), context);
          const updatedVariant = {
            ...variant,
            ...calculatedVariant,
          };
          return {
            ...updatedVariant,
            upfrontFee: calculateVariantUpfrontFee(updatedVariant),
          };
        },
      ),
  );

const calculateTotals = (variants) => ({
    totalRepayment: calculateTotalRepayment(variants),
    totalCost: calculateTotalCost(variants),
  });

export const doCalculations = async (customProduct, loansCriteria, totalCostTerm, repaymentFrequency) => {
  const calculatedVariants = await calculateVariants(customProduct.variants, loansCriteria,
    totalCostTerm, repaymentFrequency);
  const totals = calculateTotals(calculatedVariants);

  return {
    ...customProduct,
    variants: calculatedVariants,
    ...totals,
  };
};

export const createCustomProduct = (customProductFormValue,
                                    loansCriteria,
                                    totalCostTerm,
                                    repaymentFrequency) => async (dispatch) => {
  const customProduct = createProduct(customProductFormValue, loansCriteria);
  await dispatch(updateCustomProduct(customProduct,
    loansCriteria,
    totalCostTerm,
    repaymentFrequency));
};

export const updateCustomProduct = (customProduct,
                                    loansCriteria,
                                    totalCostTerm,
                                    repaymentFrequency) => async (dispatch) => {
  const calculatedProduct = await doCalculations(customProduct, loansCriteria, totalCostTerm, repaymentFrequency);

  dispatch({
    type: types.UPDATE_CUSTOM_PRODUCT,
    payload: calculatedProduct,
  });
};

export const removeCustomProduct = () => ({ type: types.REMOVE_CUSTOM_PRODUCT });
