import React from 'react';
import withDefault from '../../Links/withDefault';
import ExternalLink from '../../Links/ExternalLink';

const getPrefix = (customProduct) => (customProduct ? 'Custom: ' : '');

export const getProductName = (variant, isSplitted, variantIndex) =>
  (withDefault(
    <ExternalLink to={variant.externalLink || ''}>
      {
        `${getPrefix(variant.customProduct)}
         ${
          isSplitted
            ? `${variantIndex + 1}. ${variant.displayName}`
            : variant.displayName
        }`
      }
    </ExternalLink>,
  ));
