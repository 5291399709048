import React from 'react';
import {
 Tab as ReactTab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tab.module.scss';

const TabHeader = (props) => {
  const { selected } = props;

  return (
    <ReactTab
      className={classNames(styles.tab, { [styles.tabSelected]: selected })}
      data-test-id="tabHeader"
      {...props}
    />
);
};

TabHeader.tabsRole = 'Tab';
TabHeader.propTypes = { selected: PropTypes.bool };
TabHeader.defaultProps = { selected: false };

const Tab = ({ tabs, defaultIndex }) => (
  <Tabs defaultIndex={defaultIndex}>
    <TabList className={styles.tabList}>
      {tabs.map(tab => <TabHeader tabIndex="0" key={tab.key}>{tab.title}</TabHeader>)}
    </TabList>
    {tabs.map(tab => <TabPanel key={tab.key}>{tab.content}</TabPanel>)}
  </Tabs>
);

Tab.defaultProps = {
  defaultIndex: 0,
  tabs: [],
};

Tab.propTypes = {
  defaultIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  })),
};

export default Tab;
