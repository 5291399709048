import React, { useContext, useMemo } from 'react';
import { Field, formValueSelector } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import PropTypes from 'prop-types';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import find from 'lodash/find';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { ConfirmContext } from 'shared/components/ConfirmProvider';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import sum from 'lodash/sum';
import styles from './HouseholdExpense.module.scss';
import ExpenseSection from './ExpenseSection';
import { EXPENSE_SECTIONS } from './constants';
import { toAnnualValue, toMonthlyValue } from '../../../../utils';
import { toBasicCurrency } from '../../../../../utils/formatters';
import { INVESTMENT } from '../shared/RealEstateAsset/constants';
import convert from '../../../../utils/address/getDisplayAddress';

const validateHousehold = value => (isEmpty(value) ? 'Household must not be empty.' : undefined);

const validateDependent = value => ((value === null || value === undefined) ? 'Dependant is required.' : undefined);

const applicantOptions = (applicants, allSelectedApplicantsIds, currentSelectedApplicantsIds) =>
  map(applicants, applicant => ({
    id: applicant.id,
    name: applicant.name,
    disabled: includes(difference(allSelectedApplicantsIds,
      currentSelectedApplicantsIds), applicant.id),
  }));

const selector = formValueSelector('expensesForm');

const createInvestmentPropertyCostsSelector = (applicantIds) => state =>
  get(state, 'application.applicationDetail.finance.assets.realEstates', [])
    .filter(realEstate => realEstate.primaryPurpose === INVESTMENT
      && !isNil(get(realEstate, 'investmentPropertyCost.value'))
      && !isNil(get(realEstate, 'investmentPropertyCost.frequency')))
    .map(realEstate => {
      const inHouseholdApplicantIds = intersection(realEstate.ownerships.map(ownership =>
        ownership.applicantId), applicantIds);
      if (isEmpty(inHouseholdApplicantIds)) {
        return null;
      }
      const totalPercentage = sum(realEstate.ownerships
        .filter(ownership => inHouseholdApplicantIds.includes(ownership.applicantId))
        .map(ownership => ownership.percentage));
      return ({
        frequency: get(realEstate, 'investmentPropertyCost.frequency'),
        addressName: convert(realEstate.address),
        value: (get(realEstate, 'investmentPropertyCost.value') * totalPercentage) / 100,
      });
    })
    .filter(Boolean);

const totalValueReducer = ({ totalMonthlyValue, totalAnnualValue },
  { monthlyValue, annualValue }) => ({
  totalAnnualValue: totalAnnualValue + annualValue,
  totalMonthlyValue: totalMonthlyValue + monthlyValue,
});

const HouseholdExpense = ({
  fieldNamePrefix,
  index,
  applicants,
  allSelectedApplicantsIds,
  currentSelectedApplicantsIds,
  handleRemove,
  displayRemoveButton,
}) => {
  const options = useMemo(() => applicantOptions(applicants, allSelectedApplicantsIds, currentSelectedApplicantsIds),
    [allSelectedApplicantsIds, applicants, currentSelectedApplicantsIds]);
  const valueRender = useMemo(() => (value) => (value?.length > 1
    ? value?.map(id => find(options, item => item.id === id)?.name).join('; ')
    : find(options, item => item.id === value[0])?.name), [options]);

  const expensesArray = useSelector(state => selector(state, `${fieldNamePrefix}.expenses`));
  const { totalMonthlyValue, totalAnnualValue } = useMemo(() => expensesArray.map(({ periodUnit, value }) => ({
    monthlyValue: toMonthlyValue(periodUnit, value),
    annualValue: toAnnualValue(periodUnit, value),
  })).reduce(totalValueReducer, { totalMonthlyValue: 0, totalAnnualValue: 0 }), [expensesArray]);

  const applicantIdsInHousehold = useSelector(useMemo(() => state =>
    selector(state, `${fieldNamePrefix}.household.applicants`), [fieldNamePrefix]));

  const investmentExpenses = useSelector(useMemo(() =>
    createInvestmentPropertyCostsSelector(applicantIdsInHousehold), [applicantIdsInHousehold]));

  const investmentExpensesMonthlyTotal = useMemo(() => sum(investmentExpenses.map(expense =>
    toMonthlyValue(expense.frequency, expense.value))), [investmentExpenses]);

  const investmentExpensesAnnualTotal = useMemo(() => sum(investmentExpenses.map(expense =>
    toAnnualValue(expense.frequency, expense.value))), [investmentExpenses]);

  const confirm = useContext(ConfirmContext);
  const handleClickRemove = useMemo(() => () => {
    confirm({
      primaryButtonText: 'Confirm',
      secondaryButtonText: 'Cancel',
      title: 'Remove household',
      message: (
        <div>
          <p>
            Are you sure you want to remove this household?
          </p>
          <p>
            Once you save this page, this household and all of the expense information will be permanently removed.
          </p>
        </div>
      ),
    }).then(isConfirm => isConfirm && handleRemove());
  }, [confirm, handleRemove]);

  return (
    <div className={styles.householdExpenseWrapper}>
      <div className={styles.householdLabel}>{`Household ${index}`}</div>
      <Field
        label="Create a household"
        name={`${fieldNamePrefix}.household.applicants`}
        component={ReduxFormCheckboxDropdown}
        className={styles.householdSelect}
        inputProps={{ className: styles.checkboxDropdown }}
        options={options}
        valueRender={valueRender}
        readOnlyFormatter={value => {
          const values = map(value, (id => find(options, { id })));
          return (
            <div className={styles.readOnlyOptions}>
              {values.map(({ name, id }) => (
                <div key={id}>{`${name};`}</div>
                ))}
            </div>
          );
        }}
        buttonText="Select"
        validate={validateHousehold}
        isRequired
      />
      <Field
        label="Number of dependants in the household"
        name={`${fieldNamePrefix}.household.dependents`}
        component={ReduxFormNumberInput}
        validate={validateDependent}
        decimalScale={0}
        readOnlyFormatter={$ => $}
        thousandSeparator={false}
        className={styles.dependentInput}
        isRequired
      />
      <>
        <span className={styles.householdExpenseTitle}>
          Add detail of the household expenses below.
          Expenses are evenly split across applicants.
        </span>
        {
          EXPENSE_SECTIONS.map(section => (
            <ExpenseSection
              key={section.title}
              fieldNamePrefix={fieldNamePrefix}
              sectionTitle={section.title}
              sectionExpenses={section.fields}
              investmentPropertyCosts={section.isLivingExpense ? {
                expenses: investmentExpenses,
                monthlyTotal: investmentExpensesMonthlyTotal,
              } : undefined}
              isLivingExpense={section.isLivingExpense}
            />
          ))
        }
      </>
      {displayRemoveButton && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleClickRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
      <div className={styles.householdTotal}>
        <div className={styles.header}>
          Total expenses
        </div>
        <div className={styles.content}>
          <div className={styles.monthlyTotal}>
            MONTHLY TOTAL:
          </div>
          <div className={styles.monthlyTotalValue}>
            {toBasicCurrency(totalMonthlyValue + investmentExpensesMonthlyTotal)}
          </div>
          <div className={styles.annualTotal}>
            ANNUAL TOTAL:
          </div>
          <div className={styles.annualTotalValue}>
            {toBasicCurrency(totalAnnualValue + investmentExpensesAnnualTotal)}
          </div>
        </div>
      </div>
    </div>
  );
};

HouseholdExpense.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  applicants: PropTypes.array.isRequired,
  allSelectedApplicantsIds: PropTypes.array.isRequired,
  currentSelectedApplicantsIds: PropTypes.array.isRequired,
  handleRemove: PropTypes.func.isRequired,
  displayRemoveButton: PropTypes.bool.isRequired,
};

export default React.memo(HouseholdExpense);
