import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const getUserProfilePermissions = businessId =>
client.query({
  query: gql`
    {
      userProfilePermission(
        businessId: "${businessId}"
      ){
        userId
        userName
        isActive
        isOwner
        version
        permissions
      }
    }
  `,
});

export default {
  getUserProfilePermissions,
};
