import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { fieldMetaPropTypes } from 'redux-form';
import FieldsInputPropShapes from '../FieldsInputPropShapes';

const withReduxForm = (propsMapper) => Component => {
  const HOC = (props) => {
    const {
      input,
      meta,
      ...others
    } = props;

    const { error, touched } = meta;

    const defaultProps = useMemo(() => ({
      ...input,
      meta,
      errorMessage: (touched && error) ? error : undefined,
      ...others,
    }), [input, meta, touched, error, others]);

    const mappedProps = isFunction(propsMapper) ? propsMapper(defaultProps) : {};

    const mergedProps = {
      ...defaultProps,
      ...mappedProps,
    };
    return (
      <Component {...mergedProps} />
    );
  };

  HOC.propTypes = {
    input: PropTypes.shape(FieldsInputPropShapes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  };

  HOC.displayName = `withReduxForm(${Component.displayName || Component.name || 'component'})`;
  return HOC;
};

export default withReduxForm;
