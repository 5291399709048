import React from 'react';
import SecurityValueComponent from './SecurityValueComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedSecurityValue = () => (
  <ProductSearchContext.Consumer>
    {context => {
        const { securityValue } = context.applicationCriteria;
        const errorMessage = (context.applicationCriteria.errorMessage || {}).securityValue;
          return (
            <SecurityValueComponent
              securityValue={securityValue}
              errorMessage={errorMessage}
              dispatchOnSecurityValueBlur={context.dispatchOnSecurityValueBlur}
              dispatchUpdateSecurityValue={context.dispatchUpdateSecurityValue}
            />
          );
        }}
  </ProductSearchContext.Consumer>
);

export default ConnectedSecurityValue;
