import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/menu"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M5,4 L20,4 L20,5.875 L5,5.875 L5,4 Z M5,10.5625 L20,10.5625 L20,12.4375 L5,12.4375 L5,10.5625 Z M5,17.125 L20,17.125 L20,19 L5,19 L5,17.125 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
