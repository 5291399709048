export const outcomeTypes = [
  {
    key: 'PROCEEDING_WITH_RECOMMENDED',
    value: 'Client will proceed with ALI’s My Protection Plan',
  },
  {
    key: 'SELECT_NOT_TO_PROCEED',
    value: 'Client has selected not to proceed. Client acknowledgement signed and kept on file',
  },
  {
    key: 'UNDER_DISCUSSION',
    value: 'Still under discussion with client, outcome not finalised',
  },
  {
    key: 'CLIENTS_INSURANCE_PROVIDER',
    value: 'Client has decided to go with their current insurance provider',
  },
];
