import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import classNames from 'classnames';
import style from './style';
import inputComponentStyles from './InputComponent.module.scss';

const MAX_LVR = 100;
const isValidLoanValueRatio = newLvr =>
  (newLvr === undefined || (newLvr > 0 && newLvr <= MAX_LVR));

const LoanValueRatioComponent = (props) => {
  const {
    dispatchUpdateLoanValueRatio,
    dispatchOnLoanValueRatioBlur,
    loanValueRatio,
  } = props;
  return (
    <NumberInput
      label="LVR (%)"
      className="short"
      onChange={dispatchUpdateLoanValueRatio}
      onBlur={dispatchOnLoanValueRatioBlur}
      value={loanValueRatio}
      isAllowed={isValidLoanValueRatio}
      decimalScale={2}
      fixedDecimalScale
      style={style}
      textClassName={classNames(inputComponentStyles.inputText, inputComponentStyles.short)}
    />
  );
};

LoanValueRatioComponent.defaultProps = {
  loanValueRatio: undefined,
};

LoanValueRatioComponent.propTypes = {
  dispatchUpdateLoanValueRatio: PropTypes.func.isRequired,
  dispatchOnLoanValueRatioBlur: PropTypes.func.isRequired,
  loanValueRatio: PropTypes.number,
};

export default LoanValueRatioComponent;
