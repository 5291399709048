import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FormMetaContext from './FormMetaContext';

const FormMetaProvider = React.memo(({ readOnly, disabled, children }) => {
  const context = useMemo(() => ({
    readOnly,
    disabled,
  }), [readOnly, disabled]);
  return (
    <FormMetaContext.Provider value={context}>
      {children}
    </FormMetaContext.Provider>
  );
});

FormMetaProvider.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

FormMetaProvider.defaultProps = {
  readOnly: false,
  disabled: false,
  children: undefined,
};

export default FormMetaProvider;
