import PropTypes from 'prop-types';
import React from 'react';
import Select from 'shared/components/formFields/Select';
import style from './style';
import { PURPOSE_OPTION_VALUES } from '../../../constants';

const PURPOSE_OPTIONS = [
  { value: PURPOSE_OPTION_VALUES.ANY, label: 'Any' },
  { value: PURPOSE_OPTION_VALUES.INVESTMENT, label: 'Investment' },
  { value: PURPOSE_OPTION_VALUES.OWNER_OCCUPIED, label: 'Owner occupied' },
];

const toSelectValue = (value, options) => options.find(option => option.value === value);

const PurposeComponent = (props) => {
  const {
    allowAny,
    purpose,
    dispatchUpdatePurpose,
  } = props;
  const options = allowAny ? PURPOSE_OPTIONS : PURPOSE_OPTIONS.slice(1);

  return (
    <Select
      label="Purpose"
      className="select-input"
      value={toSelectValue(purpose, options)}
      onChange={selected => dispatchUpdatePurpose(selected.value)}
      options={options}
      style={style}
    />
  );
};

PurposeComponent.defaultProps = {
  purpose: undefined,
  allowAny: true,
};

PurposeComponent.propTypes = {
  dispatchUpdatePurpose: PropTypes.func.isRequired,
  purpose: PropTypes.oneOf(PURPOSE_OPTIONS.map(option => option.value)),
  allowAny: PropTypes.bool,
};

export default PurposeComponent;
