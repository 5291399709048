import React from 'react';
import { formPropTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import StickyFooter from 'shared/components/StickyFooter';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import Referrer from './Referrer';
import Applicants from './Applicants';
import { updateContact as updateContactAction } from '../../redux';
import styles from './Contacts.module.scss';
import { hasAdditionalEditPermissionSelector, hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { getContactsInitialValue } from '../../redux/selectors';
import ThirdParties from './ThirdParty';

const onSubmit = (values, dispatch) => {
  const contact = {
    applicants: {
      persons: values.applicants.personApplicants.map(applicant => ({
        id: applicant.id,
        isSurveyContact: applicant.id === values.survey,
        isMainContact: applicant.id === values.mainContact,
      })),
      personGuarantors: values.applicants.personGuarantors.map(applicant => ({
        id: applicant.id,
        isSurveyContact: applicant.id === values.survey,
        isMainContact: applicant.id === values.mainContact,
      })),
    },
    thirdParties: values.thirdParties,
    referrer: values.referrer,
  };
  return dispatch(updateContactAction(contact));
};

const Contacts = ({
                    handleSubmit, submitting, pristine, dirty, valid,
                    initialValues, hasEditPermission, hasAdditionalEditPermission, isRecentlySentSurvey,
}) =>
  (
    <>
      <LoadingWithOverlay isLoading={submitting} />
      <UnsavedChangePrompt shouldConfirm={dirty} />
      <FormMetaProvider readOnly={!hasEditPermission}>
        <InformationSection
          title="Applicant details"
          contentWrapperStyle={styles.thirdPartyContentWrapper}
        >
          <Applicants
            applicants={initialValues.applicants}
            hasEditPermission={hasEditPermission}
            isRecentlySentSurvey={isRecentlySentSurvey}
          />
        </InformationSection>
        <InformationSection
          title="Referrer"
          contentWrapperStyle={styles.thirdPartyContentWrapper}
        >
          <Referrer
            hasEditPermission={hasEditPermission}
            hasAdditionalEditPermission={hasAdditionalEditPermission}
          />
        </InformationSection>
        <InformationSection
          title="Third parties"
          contentWrapperStyle={styles.thirdPartyContentWrapper}
        >
          <ThirdParties
            hasEditPermission={hasEditPermission}
          />
        </InformationSection>
        {(hasEditPermission || hasAdditionalEditPermission) && (
          <StickyFooter
            disabled={pristine || submitting || !valid}
            onClick={handleSubmit}
          />
        )}
      </FormMetaProvider>
    </>
  );

Contacts.defaultProps = {
  initialValues: {},
};

Contacts.propTypes = {
  initialValues: PropTypes.object,
  isRecentlySentSurvey: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  hasAdditionalEditPermission: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state =>
    ({
      isRecentlySentSurvey: get(state, 'application.applicationDetail.isRecentlySentSurvey'),
      hasEditPermission: hasEditPermissionSelector(state),
      hasAdditionalEditPermission: hasAdditionalEditPermissionSelector(state),
      initialValues: getContactsInitialValue(state),
    }),
  {
    updateContact: updateContactAction,
  },
)(reduxForm({
  form: 'contact',
  onSubmit: (values, dispatch, props) => onSubmit(values, dispatch, props),
  enableReinitialize: true,
})(Contacts));
