import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { MARGIN } from 'shared/theme/depricatedStyles';

const style = {
  display: 'flex',
  // height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: MARGIN.mediumLarge,
};

/* eslint-disable react/self-closing-comp */
const NavbarLogo = ({ linkTo }) => (
  <div style={style}>
    <NavLink to={linkTo} activeClassName="selected-header" exact>
      <img style={{ height: '30px' }} src="/smartline-logo.svg" alt="" />
    </NavLink>
  </div>
);
/* eslint-enable react/self-closing-comp */

NavbarLogo.propTypes = {
  linkTo: PropTypes.string.isRequired,
};

export default NavbarLogo;
