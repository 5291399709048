/* eslint-disable no-sparse-arrays */
import { mapLogToProps, toUpperCase } from './utils';
import { toBasicCurrency } from '../../../../../../../utils/formatters';

const additionalIncomeChangeLogTransformer = origin =>
  mapLogToProps({
    origin,
    type: 'INCOME AND EMPLOYMENT',
    summary: 'Additional income #action',
    fieldMap: {
      value: ['Income', toBasicCurrency],
      governmentBenefitsType: ['Government Benefits Type', toUpperCase],
      description: ['Details'],
      periodUnit: ['Frequency'],
      type: [, toUpperCase],
    },
    handleChanges: changes => {
      const others = changes.filter(change => !change.path.includes('ownerships'));
      return changes.length === others.length ? changes : [...others, { path: '*.ownership', value: 'updated' }];
    },
  });

export default additionalIncomeChangeLogTransformer;
