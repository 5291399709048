import React from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import style from './style';
import { PRODUCT_TYPES } from '../../../constants';

const PRODUCT_TYPE_OPTIONS = [
  { value: undefined, label: 'Any' },
  { value: 'fixed', label: PRODUCT_TYPES.fixed },
  { value: 'credit', label: PRODUCT_TYPES.credit },
  { value: 'variable', label: PRODUCT_TYPES.variable },
];

const toSelectValue = value => PRODUCT_TYPE_OPTIONS.find(option => option.value === value);

const ProductTypeComponent = (props) => {
  const {
    productType,
    dispatchUpdateProductType,
  } = props;
  return (
    <Select
      label="Product type"
      className="select-input"
      value={toSelectValue(productType)}
      onChange={selected => dispatchUpdateProductType(selected.value)}
      options={PRODUCT_TYPE_OPTIONS}
      style={style}
    />
  );
};

ProductTypeComponent.defaultProps = {
  productType: undefined,
};

ProductTypeComponent.propTypes = {
  dispatchUpdateProductType: PropTypes.func.isRequired,
  productType: PropTypes.oneOf(PRODUCT_TYPE_OPTIONS.map(option => option.value)),
};

export default ProductTypeComponent;
