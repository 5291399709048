import React from 'react';
import PropTypes from 'prop-types';
import styles from './ApplicationCreationContent.module.scss';

const ApplicationCreationContent = ({
                                      loanType,
                                      adviserFirstName,
                                      adviserSurname,
                                      applicantNames,
                                    }) => (

                                      <ul className={styles.contentWrapper}>
                                        <li className={styles.title}>Application created</li>
                                        <li>Applicant/s: {applicantNames.join('; ')}</li>
                                        <li>Adviser: {adviserSurname}, {adviserFirstName}</li>
                                        <li>Loan type: {loanType}</li>
                                      </ul>
  );

ApplicationCreationContent.defaultProps = {

};
ApplicationCreationContent.propTypes = {
  loanType: PropTypes.string.isRequired,
  adviserFirstName: PropTypes.string.isRequired,
  adviserSurname: PropTypes.string.isRequired,
  applicantNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ApplicationCreationContent;
