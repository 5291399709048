import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import { findTargetChange } from '../../../../../../constants/utils';
import { essentialDateDisplayStatus, reasonMap } from '../../../../../../constants/applicationStatus';
import { getBasicLogInfo } from './utils';

const getStatusFromChange = change => nth(get(change, 'path', '').split('.'), -2);

const essentialDateReasonChangeLogTransformer = (origin) => {
  const targetChange = findTargetChange(origin, 'applicationStatus.actionTimelineMap.*reason');
  const status = getStatusFromChange(targetChange);
  const reasonText = get(get(reasonMap, status), get(targetChange, 'newValue'), '');
  const resultText = isEmpty(reasonText) ? 'deleted' : `updated to "${reasonText}"`;
  return {
    ...getBasicLogInfo(origin),
    content: `${essentialDateDisplayStatus[status]} reason ${resultText}`,
  };
};

export default essentialDateReasonChangeLogTransformer;
