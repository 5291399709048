import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ArrowArray from 'shared/components/ArrowArray';
import styles from './ApplicationTimeline.module.scss';
import { statusNameMapper } from '../../../../constants/applicationStatus';
import { getTimelineArrowItems } from '../../../../utils';

const ApplicationTimeline = ({ applicationStatus }) => {
  const themeContext = useContext(ThemeContext);
  const timelineArrowItems = getTimelineArrowItems(applicationStatus, themeContext.palette);
  const status = get(statusNameMapper, get(applicationStatus, 'status'), '');

  return (
    <div className={styles.timelineWrapper}>
      <ArrowArray
        className={styles.timelineArrowArray}
        items={timelineArrowItems}
        arrowColor="#e0e0e0"
        arrowAngle={120}
      />
      <div className={styles.statusName}>
        {status}
      </div>
    </div>
  );
};

ApplicationTimeline.defaultProps = {
  applicationStatus: undefined,
};

ApplicationTimeline.propTypes = {
  applicationStatus: PropTypes.object,
};

export default ApplicationTimeline;
