import React, { useState } from 'react';
import PropType from 'prop-types';
import isFunction from 'lodash/isFunction';
import { FETCHING_STATUS } from '../../../constants';

const Image = ({
 alt, src, onLoad, onError, ...others
}) => {
  const [loadingStatus, changeLoadingStatus] = useState(FETCHING_STATUS.START);
  if (loadingStatus === FETCHING_STATUS.START || loadingStatus === FETCHING_STATUS.SUCCESS) {
    const mergedOnLoad = (...args) => {
      changeLoadingStatus(FETCHING_STATUS.SUCCESS);
      if (isFunction(onLoad)) {
        onLoad(...args);
      }
    };
    const mergedOnError = (...args) => {
      changeLoadingStatus(FETCHING_STATUS.ERROR);
      if (isFunction(onError)) {
        onError(...args);
      }
    };
    return (
      <img
        alt={alt}
        src={src}
        onLoad={mergedOnLoad}
        onError={mergedOnError}
        {...others}
      />
);
  }
  return (<span {...others}>{alt}</span>);
};

Image.defaultProps = {
  onLoad: () => {},
  onError: () => {},
};

Image.propTypes = {
  src: PropType.string.isRequired,
  alt: PropType.string.isRequired,
  onLoad: PropType.func,
  onError: PropType.func,
};

export default Image;
