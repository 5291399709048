import React from 'react';
import ApplicationUpdate from './ApplicationUpdate';
import TaskSection from './TaskSection';
import styles from './Activity.module.scss';
import ActivityLogs from './ActivityLogs';

export const ActivityWithUpdateAndTaskSection = () => (
  <div className={styles.activityContent}>
    <div className={styles.applicationUpdate}>
      <ApplicationUpdate />
    </div>
    <div className={styles.activityLog}>
      <ActivityLogs />
    </div>
    <div className={styles.taskSection}>
      <TaskSection />
    </div>
  </div>
);
