import React, { useCallback } from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { NewInputLabelWrapper } from '../../formFields/NewInputLabelWrapper';
import styles from './styles.module.scss';
import { convertDecimalYearToIntegerYearAndMonth } from '../../../utils';

const MAX_TERM = 40;
const MAX_MONTH_TERM = 11;

const zeroToNull = value => (value === 0 ? null : value);

const LoanTermComponent = ({
 value, isTopUp, onChange, errorMessage,
}) => {
  const { year, month } = convertDecimalYearToIntegerYearAndMonth(value);

  const handleYearValueChange = useCallback(({ floatValue = 0 }) => {
    const updatedValue = floatValue + month / 12;
    onChange(zeroToNull(updatedValue));
  }, [month, onChange]);

  const handleMonthValueChange = useCallback(({ floatValue = 0 }) => {
    const updatedValue = year + floatValue / 12;
    onChange(zeroToNull(updatedValue));
  }, [year, onChange]);

  const isLoanTermYearsAllowed = useCallback(({ floatValue }) => (
    isNil(floatValue) || (floatValue + month / 12 <= MAX_TERM)
  ), [month]);

  const isLoanTermMonthsAllowed = useCallback(({ floatValue }) => (
    isNil(floatValue) || (floatValue > 0 && floatValue <= MAX_MONTH_TERM && floatValue / 12 + year <= MAX_TERM)
  ), [year]);

  return (
    <NewInputLabelWrapper
      label="Loan term (1-30 yrs)"
      className={styles.label}
      isRequired
      errorMessage={errorMessage}
    >
      <div className={styles.inputWrapper}>
        <NumberFormat
          value={zeroToNull(year)}
          type="text"
          onValueChange={handleYearValueChange}
          thousandSeparator
          placeholder="years"
          className={errorMessage ? classNames(styles.input, styles.error) : styles.input}
          isAllowed={isLoanTermYearsAllowed}
        />
        {isTopUp && (
          <NumberFormat
            value={zeroToNull(month)}
            type="text"
            onValueChange={handleMonthValueChange}
            thousandSeparator
            placeholder="months"
            className={errorMessage ? classNames(styles.input, styles.error) : styles.input}
            isAllowed={isLoanTermMonthsAllowed}
            disabled={year >= MAX_TERM}
          />
        )}
      </div>
    </NewInputLabelWrapper>
  );
};

LoanTermComponent.defaultProps = {
  value: undefined,
  isTopUp: false,
  errorMessage: undefined,
};

LoanTermComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTopUp: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default LoanTermComponent;
