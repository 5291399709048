const sizes = {
  none: '0rem',
  xxsmall: '.2rem',
  xsmall: '.5rem',
  small: '.7rem',
  medium: '1rem',
  mediumLarge: '1.5rem',
  large: '2.0rem',
  xlarge: '2.5rem',
  xxlarge: '3.0rem',
  xxxlarge: '5.0rem',
  xxxxlarge: '7.0rem',
};

const PADDING = {
  ...sizes,
  content: sizes.large,
};

const MARGIN = {
  ...sizes,
};

export {
  PADDING,
  MARGIN,
};
