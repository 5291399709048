import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { range } from 'utils/datetime';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
// import { toMoment } from '../../utils';
import { FETCHING_STATUS } from '../../../../constants';

const getConversionRateByMonth = (start, end, applications) => range(start, end, 'month')
  .map(month => {
    const monthYear = month.format('MMM YYYY');
    const submittedApplications = applications.filter(item => {
      const submitDateToMoment = moment(item.submittedDate);
      return submitDateToMoment.isValid() && submitDateToMoment.format('MMM YYYY') === monthYear;
    });
    return {
      monthYear: month.format('MMM'),
      numOfSubmitted: submittedApplications.length,
      numOfSettled: submittedApplications.filter(item => !isEmpty(item.settledDate)).length,
    };
  });

export const conversionRateSelector = createSelector(
  state => ({
    conversionRateReport: state.reports.conversionRateReport,
    businessName: state.business.selectedBusiness?.displayName,
  }),
  ({
    conversionRateReport: {
      loans,
      fetchingStatus,
      searchWindow,
    },
     businessName,
}) => {
    if (fetchingStatus !== FETCHING_STATUS.SUCCESS) {
      return {
        fetchingStatus,
      };
    }
    const { start: previousStart, end: currentEnd } = searchWindow;
    const applications = values(mapValues(groupBy(loans, 'applicationId'), loansForOneApplication => loansForOneApplication[0]));
    const applicationsForAdviser = values(groupBy(applications, 'adviserId'));
    const currentStart = currentEnd.clone().subtract(11, 'month').startOf('month');
    const previousEnd = previousStart.clone().add(11, 'month').endOf('month');

    const conversionRateItems = map(applicationsForAdviser, applicationsForOneAdviser => ({
      adviserId: applicationsForOneAdviser[0].adviserId,
      adviserName: applicationsForOneAdviser[0].adviserName,
      current: getConversionRateByMonth(currentStart, currentEnd, applicationsForOneAdviser),
      previous: getConversionRateByMonth(previousStart, previousEnd, applicationsForOneAdviser),
    }));

    return {
      fetchingStatus,
      conversionRateItems,
      businessName,
    };
  },
);
