import withFormMeta from 'shared/components/FormMeta/withFormMeta';
import { wrapInLabel } from 'shared/components/formFields/NewInputLabelWrapper';
import withDisabledOnlyAbility from 'shared/components/formFields/DisabledOnlyAbilityWrapper';
import withReadOnly from '../ReadOnlyAbilityWrapper';
import CheckboxDropdown from './CheckboxDropdown';
import styles from './styles.module.scss';

export default withFormMeta(wrapInLabel(
  withDisabledOnlyAbility(withReadOnly(CheckboxDropdown)),
  { error: styles.checkboxDropdownError },
));
