import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import classNames from 'classnames';
import styles from './InputComponent.module.scss';
import style from './style';

const MAX_FIXED_TERM = 10;
const isValidFixedLoanTerm = fixedLoanTerm =>
  (fixedLoanTerm === undefined || (fixedLoanTerm > 0 && fixedLoanTerm <= MAX_FIXED_TERM));

const FixedLoanTermComponent = (props) => {
  const {
    disabled,
    fixedLoanTerm,
    fixedLoanTermError,
    dispatchUpdateFixedLoanTerm,
    dispatchValidateFixedLoanTerm,
  } = props;
  return (
    <NumberInput
      label="Fixed loan term (1-10 yrs)"
      value={fixedLoanTerm}
      textClassName={classNames(styles.inputText, styles.short)}
      onChange={dispatchUpdateFixedLoanTerm}
      onBlur={dispatchValidateFixedLoanTerm}
      errorMessage={fixedLoanTermError}
      disabled={disabled}
      isAllowed={isValidFixedLoanTerm}
      style={style}
    />
  );
};

FixedLoanTermComponent.defaultProps = {
  fixedLoanTerm: undefined,
  fixedLoanTermError: undefined,
  disabled: false,
};

FixedLoanTermComponent.propTypes = {
  dispatchUpdateFixedLoanTerm: PropTypes.func.isRequired,
  dispatchValidateFixedLoanTerm: PropTypes.func.isRequired,
  fixedLoanTermError: PropTypes.string,
  fixedLoanTerm: PropTypes.number,
  disabled: PropTypes.bool,
};

export default FixedLoanTermComponent;
