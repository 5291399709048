import React from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import styles from './styles.module.scss';

const REPAYMENT_OPTIONS = [
  { value: null, label: 'Any' },
  { value: 'Interest Only', label: 'Interest only' },
  { value: 'Principal and Interest Repayment', label: 'Principal and interest' },
];

const toSelectValue = value => REPAYMENT_OPTIONS.find(option => option.value === value);

const RepaymentTypeComponent = (props) => {
  const {
    value,
    onChange,
  } = props;
  return (
    <Select
      label="Repayment type"
      className={styles.select}
      value={toSelectValue(value)}
      onChange={selected => onChange(selected.value)}
      options={REPAYMENT_OPTIONS}
    />
  );
};

RepaymentTypeComponent.defaultProps = {
  value: undefined,
};

RepaymentTypeComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(REPAYMENT_OPTIONS.map(option => option.value)),
};

export default RepaymentTypeComponent;
