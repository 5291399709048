import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubTotalSection from '../components/SubTotalSection';
import { calculateTotalApplicationCost } from '../../../redux/selectors/fundingWorksheet/utils';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';

export const SubtotalApplicationCosts = ({ applicationCosts, userGuidanceCode }) => (
  <SubTotalSection
    total={calculateTotalApplicationCost(applicationCosts)}
    title={`Subtotal application costs (${userGuidanceCode})`}
  />
);

SubtotalApplicationCosts.propTypes = {
  applicationCosts: PropTypes.object.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  applicationCosts: fundingWorksheetFormValueSelector(state, 'applicationCosts'),
});

export default connect(mapStateToProps)(SubtotalApplicationCosts);
