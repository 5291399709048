import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import LoanProtectionInsurance from './LoanProtectionInsurance';
import GeneralInsuranceSection from './GeneralInsurance';
import styles from './index.module.scss';
import { isApplicationLaunchpadCreated } from '../../../../utils';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';

export const InsuranceReferralSectionComponent = ({ hasEditPermission, applicationSource }) => (
  <FormMetaProvider readOnly={!hasEditPermission}>
    <InformationSection title="General insurance">
      <div className={styles.wrapper}>
        <GeneralInsuranceSection hasEditPermission={hasEditPermission} />
      </div>
    </InformationSection>

    {isApplicationLaunchpadCreated(applicationSource) && (
      <InformationSection title="Loan protection insurance">
        <div className={styles.wrapper}>
          <LoanProtectionInsurance hasEditPermission={hasEditPermission} />
        </div>
      </InformationSection>
    )}
  </FormMetaProvider>
);

InsuranceReferralSectionComponent.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  applicationSource: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  hasEditPermission: hasEditPermissionSelector(state),
  applicationSource: get(state, 'application.applicationDetail.source', ''),
});

export default connect(mapStateToProps)(InsuranceReferralSectionComponent);
