import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import styles from './DialogBubble.module.scss';

const DialogBubble = ({ children, className }) => (
  <div className={classNames(className, styles.bubble)}>
    {children}
  </div>
);

DialogBubble.defaultProps = {
  className: undefined,
};

DialogBubble.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default DialogBubble;
