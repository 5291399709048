import { combineReducers } from 'redux';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import types from '../constants';
import { FETCHING_STATUS } from '../../../../../constants';
import { createReducer } from '../../../../../utils/reducerGenerator';

export const initialTasksInfo = {
  taskLists: [],
  taskListsStatus: FETCHING_STATUS.INIT,
  taskListStatus: {},
  taskDetailStatus: {},
  taskDetailModalVisible: false,
};

const taskLists = createReducer(initialTasksInfo.taskLists, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, { data }) =>
    get(data, 'taskLists', initialTasksInfo.taskLists),
  [types.CREATE_TASK_SUCCESS]: (state, action) => {
    const newTaskList = get(action, 'data.data.createTask');
    const isNewTaskList = !state.some(t => t.id === newTaskList.id);
    return isNewTaskList ? sortBy(state.concat(newTaskList), ['order'])
      : state.map(t => (t.id === newTaskList.id ? newTaskList : t));
  },
  [types.UPDATE_TASK_LISTS_SUCCESS]: (state, action) =>
    get(action, 'data.data.updateTaskLists'),
  [types.DELETE_TASK_SUCCESS]: (state, action) => (
    state.map(taskList => ({
      ...taskList,
      tasks: taskList.tasks.filter(task => task.id !== action.id),
    }))
  ),
  [types.UPDATE_TASK_SUCCESS]: (state, action) => (
    state.map(taskList => ({
      ...taskList,
      tasks: taskList.tasks.map(task => (task.id === action.task.id ? action.task : task)),
    }))
  ),
  [types.ADD_COMMENT_SUCCESS]: (state, action) => (state.map(
    taskList => ({
      ...taskList,
      tasks: taskList.tasks.map(task =>
        (task.id === action.data.taskId ? { ...task, comments: action.data.comments } : task)),
    }),
  )),
  [types.CHANGE_TASK_STATUS_SUCCESS]: (state, action) => (state.map(
    taskList => ({
      ...taskList,
      tasks: taskList.tasks.map(task =>
        (task.id === action.taskId ? { ...task, completed: action.completed } : task)),
    }),
  )),
});

const taskListStatus = createReducer(initialTasksInfo.taskListStatus, {
  [types.CREATE_TASK_START]: (state, action) => ({
    status: FETCHING_STATUS.START,
    taskListId: action.data.id,
  }),
  [types.CREATE_TASK_SUCCESS]: (state, action) => {
    const newTaskListId = get(action, 'data.data.createTask.id');
    return {
      status: FETCHING_STATUS.SUCCESS,
      taskListId: newTaskListId,
    };
  },
  [types.CREATE_TASK_ERROR]: () => ({
    status: FETCHING_STATUS.ERROR,
  }),
});

const taskListsStatus = createReducer(initialTasksInfo.taskListsStatus, {
  [types.UPDATE_TASK_LISTS_START]: () => (FETCHING_STATUS.START),
  [types.UPDATE_TASK_LISTS_SUCCESS]: () => (FETCHING_STATUS.SUCCESS),
  [types.UPDATE_TASK_LISTS_ERROR]: () => (FETCHING_STATUS.ERROR),
});

const taskDetailStatus = createReducer(initialTasksInfo.taskDetailStatus, {
  [types.DELETE_TASK_START]: (state, action) => ({
      ...state,
      isDeleting: true,
      taskId: action.data,
  }),
  [types.DELETE_TASK_ERROR]: (state, action) => ({
    ...state,
    isDeleting: false,
    taskId: action.data,
  }),
  [types.DELETE_TASK_SUCCESS]: state => ({
    ...state,
    isDeleting: false,
  }),
  [types.UPDATE_TASK_START]: (state, action) => ({
    ...state,
    isUpdating: true,
    taskId: action.data.id,
  }),
  [types.UPDATE_TASK_ERROR]: (state, action) => ({
    ...state,
    isUpdating: false,
    taskId: action.data.id,
  }),
  [types.UPDATE_TASK_SUCCESS]: state => ({
    ...state,
    isUpdating: false,
  }),
});

const taskDetailModalVisible = createReducer(initialTasksInfo.taskDetailModalVisible,
  { [types.SET_TASK_DETAIL_VISIBLE]: (state, action) => action.isVisible });

export default combineReducers({
  taskLists,
  taskListsStatus,
  taskListStatus,
  taskDetailStatus,
  taskDetailModalVisible,
});
