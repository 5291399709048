import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'shared/components/Box/Box';
import { toDateStrWithoutYear, toYear } from 'utils/datetime';
import styles from './Timeline.module.scss';

const Timeline = ({ date, className, children }) => (
  <Box className={className} curvy>
    <h4 className={styles.dateHeader}>
      {toDateStrWithoutYear(date)}
      {' '}
      <span className={styles.year}>{toYear(date)}</span>
    </h4>
    {children}
  </Box>
);

Timeline.defaultProps = {
  className: undefined,
  children: null,
};

Timeline.propTypes = {
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Timeline;
