import { change } from 'redux-form';
import isNil from 'lodash/isNil';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import {
  getApplicationType,
  getLVR, getSecurityValue,
  getTotalCombinedLoanAmount,
  getTotalLoanAmount,
} from '../../../redux/selectors/productSearch';
import { FORM_NAME } from '../constants';
import { calculateSecurityValue } from '../SearchCriteria/Field/utils';

const diffLVR = (amount, securityValue, lvr) => calculateSecurityValue(amount, lvr) !== securityValue;

export const syncLVR = (calculatedLVR) => (dispatch, getState) => {
  if (isNil(calculatedLVR)) {
    return dispatch(change(FORM_NAME, 'applicationCriteria.loanValueRatio', calculatedLVR));
  }
  const state = getState();
  const applicationType = getApplicationType(state);
  const amount = applicationType === APPLICATION_TYPES.NEW_LOAN
    ? getTotalLoanAmount(state)
    : getTotalCombinedLoanAmount(state);
  const securityValue = getSecurityValue(state);
  const lvr = getLVR(state);
  if (!isNil(amount) && !isNil(securityValue) && diffLVR(amount, securityValue, lvr)) {
    return dispatch(change(FORM_NAME, 'applicationCriteria.loanValueRatio', calculatedLVR));
  }
  return undefined;
};
