import orderBy from 'lodash/orderBy';
import head from 'lodash/head';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import { toDateStr } from '../../../../../../utils/datetime';

const getOrderedLogGroups = (logGroups) => {
  const logGroupsArr = map(logGroups, (logs) => ({
    actionTime: head(logs).actionTime,
    logs: orderBy(logs, ['actionTime', getOrderByActivityLogType], ['desc', 'desc']),
  }));
  return orderBy(logGroupsArr, ['actionTime'], ['desc']);
};

const getOrderByActivityLogType = (log) => {
  switch (log.activityLogType) {
    case 'STATUS_CHANGE':
      return 0;
    default:
      return 1;
  }
};

export const organizeLogs = (activityLogs) => {
  const groupedLogs = groupBy(activityLogs, log => toDateStr(log.actionTime));
  return getOrderedLogGroups(groupedLogs);
};
