import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';

export const findTargetChange = (origin, path) => find(
  origin.eventDetail.changes,
  change => RegExp(`^${path}$`).test(change.path),
);

export const findTargetChanges = (origin, path) => filter(
  origin.eventDetail.changes,
  change => RegExp(`^${path}$`).test(change.path),
);

export const changeDetected = (origin, property) =>
  origin.eventDetail.changes.some((change) => RegExp(`^${property}$`)
    .test(change.path));

export const isPropertyChanged = (origin, property) =>
  origin.eventDetail.changes.some((change) => {
    const pathWithoutIndex = change.path.replace(/\[\d+]/g, '');
    return pathWithoutIndex.split('.').some(str => RegExp(`^${property}$`).test(str));
  });

export const getTargetChangedValue = (origin, path) => (
  get(findTargetChange(origin, path), 'newValue')
);

export const getTargetChangedValues = (origin, path) => {
  const changes = findTargetChanges(origin, path);
  return map(changes, it => ({
    path: it.path,
    value: it.newValue,
  }));
};

export const getTargetOriginalValue = (origin, path) => (
  get(findTargetChange(origin, path), 'oldValue')
);

const defaultBooleanChange = ({ oldValue, newValue }) => oldValue === null && newValue === 'false';

export const getUpdatedChanges = (origin) => filter(
  origin.eventDetail.changes,
  (change) => change.oldValue !== change.newValue && !defaultBooleanChange(change),
);
