import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'shared/components/Icon/Icon';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from './Notification.module.scss';
import CollapsibleMessage from './CollapsibleMessage';

const headIconMapper = {
  info: 'info',
};

const createRenderHeader = (
  variant,
  subtitle,
  notificationTitle,
  renderCollapsibleContent,
  renderHeaderButton,
) => (opened, toggle) => {
  const themeContext = useContext(ThemeContext);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.notificationIcon}>
        <Icon
          name={headIconMapper[variant]}
          size="20"
          color={themeContext.palette.borderBrandStatic}
        />
      </div>
      <div className={styles.notificationContent}>
        {subtitle !== undefined && (
          <div className={styles.notificationSubtitle}>{subtitle}</div>
        )}
        <div className={styles.notificationTitle}>
          <h4>
            {notificationTitle}
            {renderCollapsibleContent && (
              <TertiaryButton
                className={styles.collapseButton}
                type="button"
                onClick={() => {
                  toggle(!opened);
                }}
              >
                <u>{opened ? 'Collapse details' : 'Expand details'}</u>
              </TertiaryButton>
            )}
          </h4>
        </div>
      </div>
      <div className={styles.notificationAction}>{renderHeaderButton()}</div>
    </div>
  );
};

const Notification = ({
  className,
  defaultOpen,
  subtitle,
  title,
  variant,
  renderCollapsibleContent,
  renderHeaderButton,
  ...others
}) => (
  <CollapsibleMessage
    className={`${styles.notification} ${className || ''} ${styles[variant]}`}
    renderHeader={createRenderHeader(
      variant,
      subtitle,
      title,
      renderCollapsibleContent,
      renderHeaderButton,
    )}
    renderBody={renderCollapsibleContent}
    {...others}
  />
);

Notification.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  renderCollapsibleContent: PropTypes.func,
  variant: PropTypes.oneOf(['info']),
  renderHeaderButton: PropTypes.func,
};

Notification.defaultProps = {
  subtitle: '',
  title: '',
  className: undefined,
  defaultOpen: false,
  variant: 'info',
  renderCollapsibleContent: null,
  renderHeaderButton: ($) => $,
};

export default React.memo(Notification);
