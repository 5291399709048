import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

export const inputBaseProps = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  style: stylePropType,
};

export const inputBaseDefaultProps = {
  errorMessage: undefined,
  className: undefined,
  disabled: false,
  fullWidth: false,
  label: undefined,
  onChange: () => {},
  style: {},
};
