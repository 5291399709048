import React from 'react';
import PropTypes from 'prop-types';
import CustomProduct from './index';
import { DEFAULT_REPAYMENT_FREQUENCY } from '../../../../../../../constants';

const ConnectedCustomProduct = ({
 panelLenders, loansCriteria, hasCustomProduct, totalCostTerm, repaymentFrequency,
}) => (
  <CustomProduct
    lenders={panelLenders}
    loansCriteria={loansCriteria}
    hasCustomProduct={hasCustomProduct}
    totalCostTerm={totalCostTerm}
    repaymentFrequency={repaymentFrequency}
  />
);

ConnectedCustomProduct.defaultProps = {
  totalCostTerm: 30,
  repaymentFrequency: DEFAULT_REPAYMENT_FREQUENCY,
};

ConnectedCustomProduct.propTypes = {
  panelLenders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
  })).isRequired,
  loansCriteria: PropTypes.array.isRequired,
  totalCostTerm: PropTypes.number,
  repaymentFrequency: PropTypes.string,
  hasCustomProduct: PropTypes.bool.isRequired,
};

export default ConnectedCustomProduct;
