import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import styles from './CollapsibleMessage.module.scss';

const CollapsibleMessage = ({
 className, defaultOpen, children, renderHeader, renderBody, ...others
}) => {
  const [opened, toggle] = useState(defaultOpen);
  return (
    <div className={`${styles.collapsibleMessage} ${className || ''}`} {...others}>
      {isFunction(renderHeader) ? renderHeader(opened, toggle) : null}
      <div className={`${opened ? '' : styles.closedBody}`}>
        {isFunction(renderBody) ? renderBody(opened, toggle) : null}
      </div>
    </div>
);
};

CollapsibleMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  defaultOpen: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
};

CollapsibleMessage.defaultProps = {
  className: undefined,
  children: undefined,
  defaultOpen: false,
  renderHeader: undefined,
  renderBody: undefined,
};

export default React.memo(CollapsibleMessage);
