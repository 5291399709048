// eslint-disable-next-line import/named
import { getAllCategoryLendersFromApi } from 'shared/api';
import { createReducer } from '../utils/reducerGenerator';

const ACTIONS = {
  FETCH_ALL_CATEGORY_LENDERS_START: 'FETCH_ALL_CATEGORY_LENDERS_START',
  FETCH_ALL_CATEGORY_LENDERS_SUCCESS: 'FETCH_ALL_CATEGORY_LENDERS_SUCCESS',
  FETCH_ALL_CATEGORY_LENDERS_ERROR: 'FETCH_ALL_CATEGORY_LENDERS_ERROR',
};

export const getAllCategoryLenders = () => (dispatch) => {
  dispatch({ type: ACTIONS.FETCH_ALL_CATEGORY_LENDERS_START });
  return getAllCategoryLendersFromApi()
    .then(allCategoryLenders => dispatch({
      type: ACTIONS.FETCH_ALL_CATEGORY_LENDERS_SUCCESS,
      allCategoryLenders,
    }))
    .catch(err => dispatch({
      type: ACTIONS.FETCH_ALL_CATEGORY_LENDERS_ERROR,
      err,
    }));
};

const initialState = {
  lenders: [],
  isLoading: false,
};

export default createReducer(initialState, {
  [ACTIONS.FETCH_ALL_CATEGORY_LENDERS_START]: state => ({ ...state, ...initialState, isLoading: true }),
  [ACTIONS.FETCH_ALL_CATEGORY_LENDERS_SUCCESS]: (state, { allCategoryLenders }) => ({
    ...state,
    lenders: allCategoryLenders,
    hasError: false,
    isLoading: false,
  }),
  [ACTIONS.FETCH_ALL_CATEGORY_LENDERS_ERROR]: state => ({ ...state, ...initialState, hasError: true }),
});
