import { mapLogToProps } from './utils';

const needAndObjectiveUpdateLogTransformer = origin => mapLogToProps({
    origin,
    type: 'NEEDS AND OBJECTIVES',
    content: 'Needs and objectives updated',
    handleChanges: changes => {
      const newChanges = [];
      const primaryChange = changes.find(change => change.path.includes('primary.'));
      const rateChange = changes.find(change => change.path.includes('rate.'));
      const repaymentChange = changes.find(change => change.path.includes('repayment.'));
      const productFeatureChange = changes.find(change => change.path.includes('productFeature.'));
      const adviserSectionChange = changes.some(({ path }) => /(futureCircumstances|guarantorFutureCircumstances)/.test(path));
      const refinanceAndDebtConsolidationSectionChange = changes.find(change => change.path.includes('refinanceAndDebtConsolidation'));
      if (primaryChange) {
        newChanges.push({ path: 'Primary needs and requirements, and short and long term objectives', value: 'updated' });
      }
      if (rateChange) {
        newChanges.push({ path: 'Rate type', value: 'updated' });
      }
      if (repaymentChange) {
        newChanges.push({ path: 'Repayment type', value: 'updated' });
      }
      if (productFeatureChange) {
        newChanges.push({ path: 'Product features', value: 'updated' });
      }
      if (adviserSectionChange) {
        newChanges.push({ path: 'Adverse changes and retirement plan', value: 'updated' });
      }
      if (refinanceAndDebtConsolidationSectionChange) {
        newChanges.push({ path: 'Refinance and debt consolidation', value: 'updated' });
      }
      return newChanges;
    },
});

export default needAndObjectiveUpdateLogTransformer;
