import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { components } from 'react-select';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { ApplicantSelector } from './ApplicantSelector';

const { Option, SingleValue } = components;

const getNameByCompanyDescription = (companyName, companyDescription, trustName) =>
  (companyDescription === 'Trust' ? trustName : companyName);

const OptionValue = (props) => {
  const { data: { name } } = props;
  return (
    <SingleValue {...props}>
      <span>{ name }</span>
    </SingleValue>
  );
};

OptionValue.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

const OptionLabel = (props) => {
  const {
    data: {
      name, hidden,
    },
  } = props;
  return hidden ? null : (
    <Option {...props}>
      <span>{ name }</span>
    </Option>
  );
};

OptionLabel.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    hidden: PropTypes.bool,
  }).isRequired,
};

const readOnlyFormatter = (v) => `${get(v, 'name')}`;

const getValue = (options, value) => find(options, { value }) || null;

const getOptions = (clients, formatOption) => {
  if (isArray(clients)) {
    const results = clients.map(({
                                   id, companyName, companyDescription, trustName,
                                 }) => ({
      value: id,
      label: getNameByCompanyDescription(companyName, companyDescription, trustName),
      name: getNameByCompanyDescription(companyName, companyDescription, trustName),
      isDisabled: true,
    }));
    return map(sortBy(results, ['label']), formatOption);
  }
  return clients;
};

const CompanyApplicantSelector = (props) => {
  const { selectedApplicant, clients, formatOption } = props;

  const options = useMemo(
    () => {
      const originalOptions = getOptions(clients, formatOption);
      return selectedApplicant && selectedApplicant.displayInfo && !getValue(originalOptions, selectedApplicant.id)
        ? [...originalOptions,
          {
            ...selectedApplicant.displayInfo,
            value: selectedApplicant.id,
            label: selectedApplicant.displayInfo.name,
            hidden: true,
            isDisabled: true,
          }]
        : originalOptions;
    }, [clients, formatOption, selectedApplicant],
  );

  const value = useMemo(() => getValue(options, selectedApplicant?.id), [selectedApplicant, options]);

  return (
    <ApplicantSelector
      OptionLabel={OptionLabel}
      OptionValue={OptionValue}
      readOnlyFormatter={readOnlyFormatter}
      options={options}
      selectedValue={value}
      {...props}
    />
  );
};

CompanyApplicantSelector.propTypes = {
  selectedApplicant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayInfo: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  formatOption: PropTypes.func,
  clients: PropTypes.array.isRequired,
};

CompanyApplicantSelector.defaultProps = {
  selectedApplicant: undefined,
  formatOption: undefined,
};

const mapStateToProps = state => ({
  clients: state.applications.clients?.companies ?? [],
  fetchingStatus: state.applications.clientsFetchingStatus,
});

export default connect(
  mapStateToProps,
)(CompanyApplicantSelector);
