import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getAssetsById } from '../../../redux/selectors/assets';
import { getConstructions } from '../../../redux/selectors/fundingWorksheet';
import { getOtherFields } from '../contentUtils';
import InformationSection from '../../../../../../shared/components/InformationSection/InformationSection';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';
import FieldsContainer from '../../../../../../shared/components/FieldsContainer/FieldsContainer';
import SubtotalConstructionCosts from './SubtotalConstructionCosts';
import { getAssetFields, getConstructionTitle } from './utils';
import { ConstructionShape } from '../../shared/shapes/FundingWorksheetShapes';

const ViewConstruction = ({ construction, asset, userGuidanceCode }) => {
  const otherCostsFields = getOtherFields(construction.otherCosts);
  const constructionAmount = get(asset, 'contractPrice.contractPriceAmount');
  const assetFields = getAssetFields(asset);
  return (
    <div className={styles.construction}>
      <InformationSection
        wrapperStyle={styles.infoSection}
        contentWrapperStyle={styles.infoSectionContent}
        titleWrapperStyle={styles.titleWrapperStyle}
        title={getConstructionTitle(asset)}
      >
        <SubSection>
          <FieldsContainer
            fields={assetFields}
            fieldsWrapperClassName={styles.readOnlyAssetFieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
          { !isEmpty(otherCostsFields) && (
            <FieldsContainer
              fields={otherCostsFields}
              title=""
              fieldsWrapperClassName={styles.fieldsWrapper}
              className={styles.fieldsContainerWrapper}
            />
          )}
        </SubSection>
        <SubtotalConstructionCosts
          construction={construction}
          constructionAmount={constructionAmount}
          userGuidanceCode={userGuidanceCode}
        />
      </InformationSection>
    </div>
  );
};

ViewConstruction.propTypes = {
  asset: PropTypes.shape({
    primaryPurpose: PropTypes.string,
    contractPrice: PropTypes.shape({
      contractPriceAmount: PropTypes.number,
    }),
  }).isRequired,
  construction: ConstructionShape.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

const ViewConstructions = ({ userGuidanceCodes }) => {
  const assets = useSelector(getAssetsById);
  const constructions = useSelector(getConstructions);
  return constructions.map((construction, index) => {
    const asset = assets[construction.assetId];
    return (
      <ViewConstruction
        key={construction.id}
        asset={asset}
        userGuidanceCode={userGuidanceCodes[index]}
        construction={construction}
      />
      );
  });
};

ViewConstructions.propTypes = {
  userGuidanceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ViewConstructions;
