import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import Popover from 'shared/components/NavbarMenu/Popover';
import { PopoverContent } from './PopoverContent';
import styles from './style.module.scss';
import { checkIfKeyIsEnterOrSpace } from '../../../../utils';

export const FromToFilter = ({
                               from, to, onFilter, disabled, placeholder, endYearOfSelectOptions,
                             }) => {
  const [fromFilter, updateFromFilter] = useState(from);
  const [toFilter, updateToFilter] = useState(to);

  const formatDate = date => ((date.month && date.year)
    ? `${String(date.month)
      .padStart(2, '0')}/${String(date.year)
      .substr(2)}`
    : 'MM/YY');

  const DEFAULT_VALUE = 'MM/YY - MM/YY';

  const value = `${formatDate(fromFilter)} - ${formatDate(toFilter)}`;

  const contentWidth = 400;

  return (
    <Popover
      contentWidth={contentWidth}
      trigger={toggle => (
        <TextInput
          onClick={toggle}
          value={value === DEFAULT_VALUE ? '' : value}
          placeholder={placeholder}
          className={styles.textInput}
          onKeyDown={(event) => {
            if (checkIfKeyIsEnterOrSpace(event)) {
              toggle();
            }
          }}
          mini
          fullWidth
          disabled={disabled}
        />
      )}
    >
      {(toggle, isShown) => (isShown && (
        <PopoverContent
          onCancel={() => toggle(false)}
          onSave={(newFrom, newTo) => {
            updateFromFilter(newFrom);
            updateToFilter(newTo);
            if (newFrom !== fromFilter || newTo !== toFilter) {
              onFilter({
                value: {
                  from: newFrom,
                  to: newTo,
                },
              });
            }
            toggle();
          }}
          toFilter={toFilter}
          fromFilter={fromFilter}
          endYearOfSelectOptions={endYearOfSelectOptions}
        />
      ))}
    </Popover>
  );
};

FromToFilter.defaultProps = {
  from: {},
  to: {},
  disabled: false,
  placeholder: 'MM/YY - MM/YY',
  endYearOfSelectOptions: new Date().getFullYear(),
};

FromToFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  from: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  to: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  endYearOfSelectOptions: PropTypes.number,
};
