import React from 'react';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import { Field } from 'redux-form';
import classNames from 'classnames';
import styles from './Person.module.scss';

const ContactSection = React.memo(({ fieldNamePrefix }) => (
  <FieldsContainer
    title="Contact"
    className={styles.sectionWrapper}
    fieldsWrapperClassName={styles.overrideSectionWrapper}
  >
    <Field
      name={`${fieldNamePrefix}.contactDetails.homePhone`}
      className={classNames([styles.fieldWrapper, styles.atSecondColumnOnDesktop])}
      component={PlainTextField}
      label="Home phone"
      format={value => value || '-'}
    />
    <Field
      name={`${fieldNamePrefix}.contactDetails.mobile`}
      component={PlainTextField}
      className={styles.fieldWrapper}
      label="Mobile"
      format={value => value || '-'}
    />
    <Field
      name={`${fieldNamePrefix}.contactDetails.workPhone`}
      component={PlainTextField}
      className={styles.fieldWrapper}
      label="Work phone"
      format={value => value || '-'}
    />
    <Field
      name={`${fieldNamePrefix}.contactDetails.emailAddress`}
      component={PlainTextField}
      className={styles.fieldWrapper}
      label="Email"
      format={value => value || '-'}
    />
  </FieldsContainer>
));

ContactSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default ContactSection;
