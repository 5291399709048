import PALETTE from '../../../shared/theme/depricatedStyles/Colours';
import FONTS from '../../../shared/theme/depricatedStyles/Fonts';

const styles = {
  permissionSettings: {
    position: 'relative',
    color: PALETTE.mineShaft,
    letterSpacing: 0,
    textAlign: 'left',
  },
  table: {
    background: PALETTE.white,
    border: '#eeeeee solid 0.2rem',
    borderRadius: '0.2rem',
    borderCollapse: 'collapse',
    marginBottom: '2rem',
    width: '100%',
  },
  header: {
    height: '6rem',
  },
  rowOdd: {
    height: '4.5rem',
    backgroundColor: PALETTE.normalRow,
  },
  rowEven: {
    height: '4.5rem',
    backgroundColor: PALETTE.otherRow,
  },
  commonCell: {
    border: '#eeeeee solid 0.1rem',
    textAlign: 'left',
    padding: '1rem',
    verticalAlign: 'center',
  },
  headerCell: {
    width: '20rem',
    fontFamily: FONTS.standardBold,
    fontSize: FONTS.size.large,
    verticalAlign: 'top',
    padding: '1.3rem',
  },
  wideHeaderCell: {
    width: '40rem',
    fontFamily: FONTS.standardBold,
    fontSize: FONTS.size.large,
    verticalAlign: 'top',
    padding: '1.3rem',
  },
  nameCell: {
    fontSize: FONTS.size.large,
    fontFamily: FONTS.standardBold,
  },
  disabledText: {
    fontFamily: FONTS.standard,
    fontSize: FONTS.size.large,
    color: PALETTE.disabledText,
  },
};

export default styles;
