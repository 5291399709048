import { getTargetChangedValue, isPropertyChanged } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const generateBasicTaskInfo = subType => origin => ({
  ...getBasicLogInfo(origin),
  resourceId: origin.eventDetail.resourceId,
  name: origin.eventDetail.resourceName,
  title: subType,
});

const updateTaskInfoLogTransformer = origin => ({
  ...generateBasicTaskInfo('TASK UPDATED')(origin),
  updates: {
    summary: getTargetChangedValue(origin, 'name'),
    description: getTargetChangedValue(origin, 'description'),
    dueDateTime: getTargetChangedValue(origin, 'dueDateTime'),
    assignee: isPropertyChanged(origin, 'assignee')
      ? [getTargetChangedValue(origin, 'assignee.firstName'), getTargetChangedValue(origin, 'assignee.surname')].filter(name => name !== null).join(' ')
      : undefined,
    commentAdded: isPropertyChanged(origin, 'comments'),
    completed: isPropertyChanged(origin, 'completed')
      ? getTargetChangedValue(origin, 'completed') === 'true'
      : undefined,
  },
});

export default updateTaskInfoLogTransformer;
