import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import FeatureSelect from './FeatureSelect';
import FeatureModal from './FeatureModal';
import { GET_ALL_FEATURES } from '../../constants';
import { getAllFeatures } from '../../utils';

const Feature = ({
                   value,
                   onChange,
                 }) => {
  const { loading, error, data } = useQuery(GET_ALL_FEATURES);
  const features = getAllFeatures(loading, error, data);

  const [viewAllFeature, updateViewAllFeature] = useState(false);

  const handleToggleFeature = useCallback((featureName) => {
    const alreadyInclude = value.includes(featureName);
    const updatedValue = alreadyInclude
    ? value.filter(it => it !== featureName)
    : concat(value, featureName);
    onChange(updatedValue);
  }, [onChange, value]);

  return (
    <>
      <FeatureSelect
        buttonLabel="Show all features"
        buttonOnClick={() => updateViewAllFeature(!viewAllFeature)}
        onChange={onChange}
        hasError={!isEmpty(error)}
        features={features}
        selectedFeatureNames={value}
      />
      <FeatureModal
        isOpen={viewAllFeature}
        hasError={!isEmpty(error)}
        features={features}
        selectedFeatureNames={value}
        onRequestClose={() => updateViewAllFeature(!viewAllFeature)}
        dispatchUpdateSelectedFeaturesNames={onChange}
        dispatchToggleSelectFeature={handleToggleFeature}
      />
    </>
  );
};

Feature.defaultProps = {
  value: [],
};

Feature.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default Feature;
