import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import concat from 'lodash/concat';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from '../shared/Component.module.scss';
import SummarySection from '../shared/SummarySection';
import DetailSection from './DetailSection';
import { FORM_NAME } from '../shared/constants';

const selector = formValueSelector(FORM_NAME);

const CompanyComponent = ({
  hasEditPermission,
  fieldNamePrefix,
  isApplicant,
  replace,
  editInLotusNotesField,
  displayRemoveButton,
  handleClickRemove,
}) => {
  const persons = useSelector(state =>
    concat(
      selector(state, 'applicants')?.filter(applicant => applicant.isPerson),
      selector(state, 'guarantors')?.filter(applicant => applicant.isPerson),
    ));

  const companies = useSelector(state =>
    concat(
      selector(state, 'applicants')?.filter(applicant => !applicant.isPerson),
      selector(state, 'guarantors')?.filter(applicant => !applicant.isPerson),
    ));
  return (
    <div
      className={styles.wrapper}
    >
      <SummarySection
        fieldNamePrefix={fieldNamePrefix}
        collection={companies}
        handleReplace={replace}
        isApplicant={isApplicant}
      />
      {hasEditPermission && (
        <div className={styles.editInLotusNotesLinkRow}>
          <Field
            name={`${fieldNamePrefix}.externalLink`}
            component={editInLotusNotesField}
          />
        </div>
      )}
      <DetailSection
        fieldNamePrefix={fieldNamePrefix}
        hasEditPermission={hasEditPermission}
        persons={persons}
      />
      {displayRemoveButton && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleClickRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
    </div>
  );
};

CompanyComponent.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  isApplicant: PropTypes.bool.isRequired,
  replace: PropTypes.func.isRequired,
  editInLotusNotesField: PropTypes.elementType.isRequired,
  displayRemoveButton: PropTypes.bool.isRequired,
  handleClickRemove: PropTypes.func.isRequired,
};

export default CompanyComponent;
