import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import sum from 'lodash/sum';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { formatAmountWithDefault } from '../../../../../utils/formatters';
import styles from './ExpenseSection.module.scss';
import Expense from './Expense';
import { toMonthlyValue } from '../../../../utils';
import InvestmentExpense from './InvestmentExpense';

const createExpenseSectionSelector = (fieldNamePrefix, sectionExpenses) => state => {
  const householdExpenses = get(getFormValues('expensesForm')(state), `${fieldNamePrefix}.expenses`);
  return sectionExpenses.map(field => householdExpenses[field.index]);
};

const ExpenseSection = ({
  fieldNamePrefix, sectionTitle, sectionExpenses, investmentPropertyCosts, isLivingExpense,
}) => {
  const expenseSectionFormValues = useSelector(useMemo(() =>
      createExpenseSectionSelector(fieldNamePrefix, sectionExpenses),
    [fieldNamePrefix, sectionExpenses]), isEqual);

  const sectionFormTotal = useMemo(() => sum(expenseSectionFormValues?.filter(field => !isEmpty(field.periodUnit))
    .map(field => toMonthlyValue(field.periodUnit, field.value))), [expenseSectionFormValues]);

  const sectionTotal = sectionFormTotal + investmentPropertyCosts.monthlyTotal;

  return (
    <div className={classNames(styles.expenseContainer, { [styles.livingExpenseContainer]: isLivingExpense })}>
      <div className={styles.expenseSectionWrapper}>
        <div className={styles.expenseSectionHeader}>
          <div className={styles.headerLabel}>{sectionTitle}</div>
          <div className={styles.headerAmount}>{formatAmountWithDefault(sectionTotal)}</div>
        </div>
        <div>
          {
            sectionExpenses.map(expense => (
              <Expense
                key={expense.value}
                fieldNamePrefix={`${fieldNamePrefix}.expenses[${expense.index}]`}
                expense={expense}
              />
            ))
          }
          {
            investmentPropertyCosts.expenses.map(expense => (
              <InvestmentExpense
                key={expense.value}
                expense={expense}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

ExpenseSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionExpenses: PropTypes.array.isRequired,
  investmentPropertyCosts: PropTypes.shape({
    expenses: PropTypes.array.isRequired,
    monthlyTotal: PropTypes.number.isRequired,
  }),
  isLivingExpense: PropTypes.bool.isRequired,
};

ExpenseSection.defaultProps = {
  investmentPropertyCosts: {
    expenses: [],
    monthlyTotal: 0,
  },
};

export default ExpenseSection;
