import React from 'react';
import PropTypes from 'prop-types';
import styles from './Arrow.module.scss';

const Head = React.memo(({
 color, height, width, reversed,
}) => (
  <div
    className={`${styles.head} arrow-head`}
    style={{
    borderColor: 'transparent',
    borderRightColor: reversed ? color : 'transparent',
    borderLeftColor: reversed ? 'transparent' : color,
    borderWidth: `calc(${height} / 2)`,
width,
    borderRightWidth: reversed ? width : 0,
    borderLeftWidth: reversed ? 0 : width,
  }}
  />
));

const Tail = React.memo(({
 color, height, width, reversed,
}) => (
  <div
    className={`${styles.tail} arrow-tail`}
    style={{
    borderColor: color,
    borderLeftColor: reversed ? color : 'transparent',
    borderRightColor: reversed ? 'transparent' : color,
    borderWidth: `calc(${height} / 2)`,
width,
    borderRightWidth: reversed ? width : 0,
    borderLeftWidth: reversed ? 0 : width,
  }}
  />
));

Head.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  reversed: PropTypes.bool.isRequired,
};

Tail.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  reversed: PropTypes.bool.isRequired,
};

const Arrow = (props) => {
  const {
    noTail,
    noHead,
    children,
    direction,
    backgroundColor,
    angle,
    height,
    right,
    left,
    className,
    color,
    style,
    ...others
  } = props;
  const reverseDirection = direction !== 'ltr';
  const endsWidth = `calc(${height} / (2 * ${Math.tan((angle * Math.PI) / 360)}))`;

  return (
    <div
      className={`${styles.arrow} ${className} arrow`}
      style={{
 marginRight: right, marginLeft: left, color, ...style,
}}
      {...others}
    >
      {reverseDirection && (
      <>
        {noHead || (
        <Head
          color={backgroundColor}
          width={endsWidth}
          height={height}
          reversed={reverseDirection}
        />
)}
        <div className={`${styles.body} arrow-body`} style={{ backgroundColor, height }}><div>{children}</div></div>
        {noTail || (
        <Tail
          color={backgroundColor}
          width={endsWidth}
          height={height}
          reversed={reverseDirection}
        />
)}
      </>
)}
      {!reverseDirection && (
      <>
        {noTail || (
        <Tail
          color={backgroundColor}
          width={endsWidth}
          height={height}
          reversed={reverseDirection}
        />
)}
        <div className={`${styles.body} arrow-body`} style={{ backgroundColor, height }}><div>{children}</div></div>
        {noHead || (
        <Head
          color={backgroundColor}
          width={endsWidth}
          height={height}
          reversed={reverseDirection}
        />
)}
      </>
)}
    </div>
);
};

Arrow.propTypes = {
  noTail: PropTypes.bool,
  noHead: PropTypes.bool,
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.string,
  angle: PropTypes.number,
  left: PropTypes.string,
  right: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

Arrow.defaultProps = {
  noTail: false,
  noHead: false,
  children: null,
  backgroundColor: 'white',
  height: '2.5rem',
  direction: 'ltr',
  angle: 90,
  className: '',
  left: '0px',
  right: '0px',
  color: '#000',
  style: {},
};

export default React.memo(Arrow);
