import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import ServiceabilityDetailsModal from '../../../../../../ProductSearch/ProductsTable/ServiceabilityDetailsModal';

export const ServiceabilityDetailModalContext = React.createContext();

const loanAmountSelector = state => get(state, 'application.productSearch.searchCriteria.applicationCriteria.totalLoanAmount');

export const ServiceabilityDetailModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    serviceability: {},
    serviceabilityDetails: {},
  });
  const loanAmount = useSelector(loanAmountSelector);

  const showServiceabilityDetail = useCallback(({ serviceability, lenderName, serviceabilityDetails }) => {
    setModalProps({
      isOpen: true,
      serviceability,
      lenderName,
      serviceabilityDetails,
    });
  }, [setModalProps]);

  const handleCloseModal = useCallback(() => {
    setModalProps({
      isOpen: false,
      serviceability: {},
      serviceabilityDetails: {},
    });
  }, [setModalProps]);

  return (
    <ServiceabilityDetailModalContext.Provider value={showServiceabilityDetail}>
      {children}
      <ServiceabilityDetailsModal
        onRequestClose={handleCloseModal}
        loanAmount={loanAmount}
        {...modalProps}
      />
    </ServiceabilityDetailModalContext.Provider>
  );
};

ServiceabilityDetailModalProvider.propTypes = {
  children: PropTypes.node,
};

ServiceabilityDetailModalProvider.defaultProps = {
  children: null,
};
