import isEmpty from 'lodash/isEmpty';
import { toTimeStr } from 'utils/datetime';
import { getTargetChangedValue, getTargetOriginalValue } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const expectedSettlementDateChangeLogTransformer = (origin) => {
  const oldDate = getTargetOriginalValue(origin, 'expectedSettlementDate');
  const newDate = getTargetChangedValue(origin, 'expectedSettlementDate');
  let content;
  if (!isEmpty(oldDate) && isEmpty(newDate)) {
    content = 'Expected settlement date has been deleted';
  } else if (isEmpty(oldDate) && !isEmpty(newDate)) {
    content = `Expected settlement date set to ${toTimeStr(newDate)}`;
  } else {
    content = `Expected settlement date changed to ${toTimeStr(newDate)}`;
  }

  return {
    ...getBasicLogInfo(origin),
    content,
  };
};

export default expectedSettlementDateChangeLogTransformer;
