import { ACTIONS } from 'Loans/components/LoanDetails/redux/loanActions';
import { FETCHING_STATUS } from '../../../../constants';

const initialState = {
  data: null,
  errors: null,
  fetchingStatus: FETCHING_STATUS.INIT,
  isEditing: false,
  isLoanUpdating: false,
  hasUpdateErrors: false,
  selectedLoanType: undefined,
  selectedLender: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_LOAN_START:
      return {
        ...state,
        errors: null,
        data: null,
        fetchingStatus: FETCHING_STATUS.START,
        isEditing: false,
      };
    case ACTIONS.GET_LOAN_ERROR:
      return {
        ...state,
        errors: action.payload.errors,
        fetchingStatus: FETCHING_STATUS.ERROR,
        isEditing: false,
      };
    case ACTIONS.GET_LOAN_SUCCESS:
      return {
        ...state,
        data: action.payload.loan,
        fetchingStatus: FETCHING_STATUS.SUCCESS,
        isEditing: false,
      };
    case ACTIONS.TOGGLE_EDIT_MODE:
      return {
        ...state,
        isEditing: action.payload.isEditing,
      };
    case ACTIONS.UPDATE_LOAN_START:
      return {
        ...state,
        isLoanUpdating: true,
      };
    case ACTIONS.UPDATE_LOAN_SUCCESS:
      return {
        ...state,
        isLoanUpdating: false,
        hasUpdateErrors: false,
      };
    case ACTIONS.UPDATE_LOAN_ERROR:
      return {
        ...state,
        isLoanUpdating: false,
        hasUpdateErrors: true,
      };
    case ACTIONS.SELECT_LOAN_TYPE:
      return {
        ...state,
        selectedLoanType: action.payload.selectedLoanType,
      };
    case ACTIONS.SELECT_LENDER:
      return {
        ...state,
        selectedLender: action.payload,
      };
    default:
      return state;
  }
};
