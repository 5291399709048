import APPLICATION_ACTIONS
  from 'ApplicationTracking/components/ApplicationDetails/redux/constants';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findBusiness from 'shared/findBusiness';
import { saveSelectedBusinessId } from '../../store/persistState';
import {
  getRequestIdFromAction,
  ifValidRequestId,
  startRequest,
} from '../requestIds';

export const ACTIONS = {
  SELECT_BUSINESS: 'SELECT_BUSINESS',
};

const getSelectedBusiness = state => state.selectedBusiness;

const getSelectedBusinessId = state => get(getSelectedBusiness(state), 'id');

export const FIND_BUSINESS = 'FIND_BUSINESS';

export const selectBusiness = business => (dispatch) => {
  saveSelectedBusinessId(business ? business.id : null);
  dispatch({
    type: ACTIONS.SELECT_BUSINESS,
    business,
  });
};

const tryFindBusiness = async (businessId) => {
  try {
    return await findBusiness(businessId);
  } catch (e) {
    return undefined;
  }
};

export const syncBusinessId = (
  location,
  history,
  shouldGetBusinessIdFromURL,
  prevBusiness,
  redirectPath,
) => async (dispatch, getState) => {
  const getBusinessIdFromURL = () => new URLSearchParams(location.search).get('businessId');

  const setURL = (url) => {
    if (url !== `${location.pathname}${location.search}${location.hash}`) {
      history.replace(url);
    }
  };

  const jumpToBusiness = (businessId) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('businessId', businessId);
    setURL(`${location.pathname}?${urlSearchParams.toString()}`);
  };

  const businessState = getState().business;
  const businessId = shouldGetBusinessIdFromURL
    ? getBusinessIdFromURL() || getSelectedBusinessId(businessState)
    : getSelectedBusinessId(businessState);

  if (!businessId) {
    return;
  }

  const startRequestAction = startRequest(FIND_BUSINESS);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);

  const business = await tryFindBusiness(businessId);

  ifValidRequestId(getState().requestIds, FIND_BUSINESS, requestId, () => {
    if (!business) {
      dispatch(selectBusiness(null));
      setURL(redirectPath || location.pathname);
      return;
    }

    if (business !== getSelectedBusiness(businessState)) {
      dispatch(selectBusiness(business));
    }

    if (business !== prevBusiness) {
      if (redirectPath && prevBusiness && prevBusiness.id !== business.id) {
        setURL(redirectPath);
      } else {
        jumpToBusiness(business.id);
      }
    } else if (!getBusinessIdFromURL()) {
      jumpToBusiness(business.id);
    }
  });
};

const initialState = {
  selectedBusiness: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_BUSINESS: {
      return {
        ...state,
        selectedBusiness: action.business,
      };
    }
    case APPLICATION_ACTIONS.GET_APPLICATION_DETAIL_SUCCESS: {
      if (!isEmpty(action.business)) {
        return {
          ...state,
          selectedBusiness: action.business,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
