import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import cc from 'classnames';
import styles from 'shared/components/formFields/CheckboxDropdown/styles.module.scss';
import Icon from 'shared/components/Icon/Icon';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const ENTER_KEY_CODE = 13;
export const Trigger = React.forwardRef(
  ({
 className, isShown, setIsShown, value, placeholder, onBlur,
}, ref) => {
    const themeContext = useContext(ThemeContext);
    return (
      <div
        ref={ref}
        className={cc(className, styles.fakeSelectBox)}
        id="trigger"
        onClick={() => setIsShown(!isShown)}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER_KEY_CODE) {
            setIsShown(!isShown);
          }
        }}
        tabIndex="0"
        role="button"
        onBlur={onBlur}
      >
        <div
          className={cc(styles.text, { [styles.placeholder]: isNil(value) })}
        >
          {value ?? placeholder}
        </div>
        <div className={styles.divider} />
        <div className={styles.rhsBox}>
          <Icon
            className={styles.chevron}
            name="arrow-drop-down"
            size="20px"
            color={themeContext.palette.textInformation}
          />
        </div>
      </div>
    );
  },
);

Trigger.defaultProps = {
  value: undefined,
  onBlur: () => {},
};

Trigger.propTypes = {
  className: PropTypes.string.isRequired,
  isShown: PropTypes.bool.isRequired,
  setIsShown: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};
