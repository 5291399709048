import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getDisplayAddress } from 'ApplicationTracking/utils/address';
import ExternalInfoLink from 'shared/components/Links/ExternalInfoLink';
import styles from '../styles.module.scss';
import { SubtotalPurchaseCosts } from './SubtotalPurchaseCosts';
import { EmptyCostToPurchases } from './EmptyCostToPurchase';
import { getAssetsById } from '../../../redux/selectors';
import { AssetSection } from './AssetSection';
import { getNumberContent, getOtherFields, getOverrideContent } from '../contentUtils';
import { OverrideFieldShape } from '../OverrideFIeldShape';
import { AddressShape } from '../../shared/RealEstateAsset/shapes';
import SubSection from '../components/SubSection';

const ViewCostToPurchase = (props) => {
  const {
    stampDutyOnTransferOfProperty,
    titleTransferFee,
    inspectionFee,
    adjustmentOfRates,
    otherCosts,
    asset,
    userGuidanceCode,
  } = props;

  const governmentChargesFields = [
    getOverrideContent('Stamp duty', stampDutyOnTransferOfProperty),
    getOverrideContent('Land titles fee', titleTransferFee),
  ];

  const otherCostsFields = [
    getNumberContent('Building/pest inspection', inspectionFee),
    getNumberContent('Adjustment of rates', adjustmentOfRates),
    ...getOtherFields(otherCosts),
  ];
  const address = getDisplayAddress(asset.address);
  return (
    <div className={styles.costToPurchase}>
      <InformationSection
        title={`Cost to purchase ${address}`}
        wrapperStyle={styles.costToPurchaseWrapper}
        contentWrapperStyle={styles.costToPurchaseContentWrapper}
        titleWrapperStyle={styles.titleWrapperStyle}
      >
        <AssetSection asset={asset} fieldsWrapperClassName={styles.readOnlyAssetFieldsWrapper} />
        <SubSection
          header="Government charges"
          className={styles.costToPurchaseSubSection}
          headerClassName={styles.governmentChargesHeader}
        >
          <ExternalInfoLink
            to="https://smartline.zendesk.com/hc/en-us/articles/360001929456"
            text="How is stamp duty calculated?"
            externalInfoLinkClassName={styles.externalInfoLink}
            textClassName={styles.externalInfoText}
          />
          <FieldsContainer
            fields={governmentChargesFields}
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.governmentChargesFields}
          />
        </SubSection>
        <SubSection
          header="Other costs"
          className={classNames(styles.otherCostsSubSectionViewOnly)}
        >
          <FieldsContainer
            fields={otherCostsFields}
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.fieldsContainerWrapper}
          />
        </SubSection>
        <SubtotalPurchaseCosts
          costToPurchase={props}
          assetValue={asset.value}
          userGuidanceCode={userGuidanceCode}
        />
      </InformationSection>
    </div>

  );
};

const ViewCostToPurchases = ({ costToPurchases, assets, userGuidanceCodes }) => {
  if (isEmpty(costToPurchases)) return <EmptyCostToPurchases />;
  return (
    <>
      {
        (costToPurchases || []).map((costToPurchase, index) =>
          (
            <ViewCostToPurchase
              key={costToPurchase.id}
              showHeader={index === 0}
              asset={get(assets, costToPurchase.assetId, {})}
              {...costToPurchase}
              userGuidanceCode={userGuidanceCodes[index]}
            />
))
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const costToPurchases = get(state, 'application.applicationDetail.fundingWorksheet.costToPurchases', []);
  const assets = getAssetsById(state);
  return {
    costToPurchases: (costToPurchases || []).map((costToPurchase) => pick(costToPurchase,
    ['id', 'assetId', 'stampDutyOnTransferOfProperty', 'titleTransferFee', 'inspectionFee', 'adjustmentOfRates', 'otherCosts'])),
    assets,
  };
};

ViewCostToPurchase.defaultProps = {
  stampDutyOnTransferOfProperty: { value: undefined, overrideValue: undefined },
  titleTransferFee: { value: undefined, overrideValue: undefined },
  inspectionFee: undefined,
  adjustmentOfRates: undefined,
  otherCosts: [],
  asset: {},
};

ViewCostToPurchase.propTypes = {
  stampDutyOnTransferOfProperty: OverrideFieldShape,
  titleTransferFee: OverrideFieldShape,
  inspectionFee: PropTypes.number,
  adjustmentOfRates: PropTypes.number,
  userGuidanceCode: PropTypes.string.isRequired,
  otherCosts: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
    fieldAmount: PropTypes.number,
  })),
  asset: PropTypes.shape({
    primaryPurpose: PropTypes.string,
    value: PropTypes.number,
    address: AddressShape,
  }),
};

ViewCostToPurchases.defaultProps = {
  costToPurchases: [],
  assets: {},
};

ViewCostToPurchases.propTypes = {
  costToPurchases: PropTypes.array,
  assets: PropTypes.object,
  userGuidanceCodes: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(ViewCostToPurchases);
