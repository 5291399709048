import React from 'react';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import {
  Plugin,
  Getter,
} from '@devexpress/dx-react-core';

const TableFixedColumnsMeta = React.memo(({ leftFixedColumns }) => (
  <Plugin>
    <Getter name="fixedBorderRightColumn" value={last(leftFixedColumns)} />
  </Plugin>
));

TableFixedColumnsMeta.propTypes = {
  leftFixedColumns: PropTypes.array,
};

TableFixedColumnsMeta.defaultProps = {
  leftFixedColumns: undefined,
};

export default TableFixedColumnsMeta;
