import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import LogItem
  from 'ApplicationTracking/components/ApplicationDetails/ApplicationDetailContentNav/Activity/ActivityLogs/components/LogItem';
import { organizeLogs } from 'ApplicationTracking/components/ApplicationDetails/ApplicationDetailContentNav/Activity/ActivityLogs/util';
import Timeline from '../../../shared/components/Timeline/Timeline';
import styles from './MyActivityLog.module.scss';
import * as actions from '../../redux/action/activityLog';

const ACTIVITY_LOG_FILTERS = {
  NOTE_UPDATE_AUDIT: 'NOTE_UPDATE_AUDIT',
};

const LogList = ({ specialActivityLogs }) => {
  const filterActivityLogs = specialActivityLogs.filter(
    log => log.activityLogType !== ACTIVITY_LOG_FILTERS.NOTE_UPDATE_AUDIT,
  );
  return (
    <>
      {
        organizeLogs(filterActivityLogs)
        .map(({ actionTime, logs }) => (
          <Timeline date={actionTime} key={actionTime} className={styles.logGroup}>
            {logs.map(log => (
              <LogItem activityLog={log} key={log.id} />
            ))}
          </Timeline>
        ))
      }
    </>
  );
};

LogList.propTypes = {
  specialActivityLogs: PropTypes.array.isRequired,
};

const EmptyLog = () =>
  <div className={styles.placeholder}>There have been no application updates in the last 5 days.</div>;

const MyActivityLog = ({ businessId, getSpecialActivityLogs, specialActivityLogs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  if (!businessId) {
    return <EmptyLog />;
  }

  useEffect(() => {
    setIsLoading(true);
    getSpecialActivityLogs(businessId)
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [businessId, getSpecialActivityLogs]);

  if (isEmpty(specialActivityLogs) && !isLoading && !isError) {
    return <EmptyLog />;
  }

  return (
    <LoadingAndErrorWrapper isLoading={isLoading} hasError={isError}>
      <LogList specialActivityLogs={specialActivityLogs} />
    </LoadingAndErrorWrapper>
  );
};

MyActivityLog.propTypes = {
  businessId: PropTypes.string,
  getSpecialActivityLogs: PropTypes.func.isRequired,
  specialActivityLogs: PropTypes.array.isRequired,
};

MyActivityLog.defaultProps = {
  businessId: undefined,
};

const mapStateToProps = state => ({
  specialActivityLogs: get(state, 'dashboard.specialActivityLogs', []),
});

const mapDispatchToProps = {
  getSpecialActivityLogs: actions.getSpecialActivityLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivityLog);
