import React from 'react';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';
import PropTypes from 'prop-types';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import ConnectedTaskListTemplate from './Template';
import ConnectedSmartlineTaskListTemplate from './SmartlineTemplate';
import TaskListTemplateList from './TemplateList';
import {
  retrieveTaskListTemplates,
  toTaskListTemplate,
  toSmartlineTaskListTemplate,
  toTaskListTemplateList,
  copyTaskListTemplate,
} from './redux/templateReducer';
import { FETCHING_STATUS } from '../../../../constants';

class TaskListTemplatePage extends React.Component {
  componentDidMount() {
    const { retrieveTemplates } = this.props;
    retrieveTemplates();
  }

  renderCustomTemplateList = () => {
    const { dispatchToTaskListTemplateList } = this.props;
    return (
      <ConnectedTaskListTemplate onBackToTemplateList={dispatchToTaskListTemplateList} />
    );
  };

  renderSmartlineTemplateList = () => {
    const { dispatchToTaskListTemplateList } = this.props;
    return (
      <ConnectedSmartlineTaskListTemplate onBackToTemplateList={dispatchToTaskListTemplateList} />
    );
  };

  render() {
    const {
      currentPage, fetchingStatus, taskListTemplates: { businessTemplates, smartlineTemplates }, scrollToTop,
      dispatchToTaskListTemplate, dispatchToSmartlineTaskListTemplate, dispatchCopyTaskListTemplate,
    } = this.props;

    const isLoading = fetchingStatus === FETCHING_STATUS.START;
    const hasError = fetchingStatus === FETCHING_STATUS.ERROR;
    switch (currentPage) {
      case 'taskListTemplate':
        return this.renderCustomTemplateList();
      case 'smartlineTaskListTemplate':
        return this.renderSmartlineTemplateList();
      default:
        return (
          <div>
            <h1>Task list templates</h1>
            <LoadingAndErrorWrapper isLoading={isLoading} hasError={hasError}>
              <TaskListTemplateList
                title="Custom task list templates"
                emptyTitle="No custom templates created yet"
                emptyDescription="Create a new template and it will show up here."
                taskListTemplates={businessTemplates}
                onCreateTemplate={dispatchToTaskListTemplate}
                onCopyTemplate={(...args) => {
                  dispatchCopyTaskListTemplate(...args);
                  scrollToTop();
                }}
                editable
              />
              <TaskListTemplateList
                title="Smartline task list templates"
                emptyTitle="No Smartline templates created yet."
                taskListTemplates={smartlineTemplates}
                onCreateTemplate={dispatchToSmartlineTaskListTemplate}
                onCopyTemplate={(...args) => {
                  dispatchCopyTaskListTemplate(...args);
                  scrollToTop();
                }}
              />
            </LoadingAndErrorWrapper>
          </div>
        );
    }
  }
}

TaskListTemplatePage.defaultProps = {
  currentPage: 'taskListTemplateList',
  scrollToTop: () => animateScroll.scrollToTop({ duration: 500 }),
};

TaskListTemplatePage.propTypes = {
  currentPage: PropTypes.string,
  fetchingStatus: PropTypes.number.isRequired,
  retrieveTemplates: PropTypes.func.isRequired,
  taskListTemplates: PropTypes.object.isRequired,
  dispatchToTaskListTemplate: PropTypes.func.isRequired,
  dispatchToSmartlineTaskListTemplate: PropTypes.func.isRequired,
  dispatchToTaskListTemplateList: PropTypes.func.isRequired,
  dispatchCopyTaskListTemplate: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func,
};

const mapStateToProps = state => ({
  fetchingStatus: state.myBusiness.taskListTemplate.fetchingStatus,
  taskListTemplates: state.myBusiness.taskListTemplate.taskListTemplates,
  currentPage: state.myBusiness.taskListTemplate.currentPage,
});

const mapDispatchToProps = dispatch => ({
  retrieveTemplates: () => dispatch(retrieveTaskListTemplates()),
  dispatchToTaskListTemplate: selectedTemplate =>
    dispatch(toTaskListTemplate(selectedTemplate)),
  dispatchToSmartlineTaskListTemplate: selectedTemplate =>
      dispatch(toSmartlineTaskListTemplate(selectedTemplate)),
  dispatchToTaskListTemplateList: () =>
    dispatch(toTaskListTemplateList()),
  dispatchCopyTaskListTemplate: template => dispatch(copyTaskListTemplate(template)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskListTemplatePage);
