import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import styles from './TextArea.module.scss';

const TextAreaInput = ({
 value, placeholder, disabled, className, onChange, rows, readOnly, ...others
}) => {
  const textarea = useRef();

  const resize = useMemo(() => throttle(
    (e) => {
      window.requestAnimationFrame(() => {
        const currentHeight = e.target.offsetHeight;
        if (currentHeight !== e.target.scrollHeight) {
          const { scrollTop } = e.target.offsetParent;
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
          e.target.offsetParent.scrollTop = scrollTop + Math.max(0, (e.target.scrollHeight - currentHeight));
        }
      });
    },
    200,
  ), []);

  useEffect(() => {
    const target = textarea.current;
    target.addEventListener('cut', resize);
    target.addEventListener('paste', resize);
    target.addEventListener('drop', resize);
    target.addEventListener('keydown', resize);
    return () => {
      target.removeEventListener('cut', resize);
      target.removeEventListener('paste', resize);
      target.removeEventListener('drop', resize);
      target.removeEventListener('keydown', resize);
    };
  }, [resize]);

  useEffect(() => {
    const target = textarea.current;
    resize({ target });
  }, [value, resize]);

  const { onBlur } = others;
  return (
    <textarea
      style={{ resize: 'none', overflow: 'hidden' }}
      ref={textarea}
      className={classNames(styles.textArea, className)}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={event => onChange(event.target.value)}
      rows={rows}
      readOnly={readOnly}
      onBlur={onBlur}
    />
  );
};

TextAreaInput.defaultProps = {
  value: undefined,
  placeholder: undefined,
  disabled: false,
  readOnly: false,
  className: undefined,
  rows: undefined,
  onChange: () => {},
};

TextAreaInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.string,
};

export default TextAreaInput;
