import types from './constants';

export default (state = null, action) => {
  if (action.type === types.SHOW_TOAST) {
    return {
      message: action.message,
      options: action.options,
    };
  }
  return state;
};
