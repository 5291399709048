import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import map from 'lodash/map';
import get from 'lodash/get';
import { formatStringBool, toDollarAmountWithDecimal } from 'shared/formatterUtils';
import { formatCurrency } from 'utils/formatters';
import { changeDetected, getUpdatedChanges } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';
import { getDisplayAddress } from '../../../../../../utils/address';

export const FUNDING_WORKSHEET_DISPLAY_FIELDS = [{
  displayName: 'Genuine savings',
  section: 'contributions',
  fieldType: 'number',
  path: /fundingWorksheet\.contributions\.genuineSavings/,
}, {
  displayName: 'First home owner grant',
  section: 'contributions',
  fieldType: 'number',
  path: /fundingWorksheet\.contributions\.firstHomeOwnerGrant/,
}, {
  displayName: 'Gift',
  section: 'contributions',
  fieldType: 'number',
  path: /fundingWorksheet\.contributions\.gift/,
}, {
  displayName: 'Deposit paid',
  section: 'contributions',
  fieldType: 'number',
  path: /fundingWorksheet\.contributions\.initialDepositAmount/,
}, {
  displayName: 'Sale proceed funds',
  section: 'contributions',
  fieldType: 'number',
  path: /fundingWorksheet\.contributions\.netProceedsFromSaleOfProperty/,
}, {
  displayName: 'Other funds',
  section: 'contributions',
  fieldType: 'other',
  path: /fundingWorksheet\.contributions\.otherFunds\.custom/,
}, {
  displayName: 'Foreign purchaser',
  section: 'applicationCosts',
  fieldType: 'boolean',
  path: /fundingWorksheet\.applicationCosts\.foreignPurchaser/,
}, {
  displayName: 'Eligible pensioner (discount)',
  section: 'applicationCosts',
  fieldType: 'boolean',
  path: /fundingWorksheet\.applicationCosts\.eligiblePensioner/,
}, {
  displayName: 'First home buyer',
  section: 'applicationCosts',
  fieldType: 'boolean',
  path: /fundingWorksheet\.applicationCosts\.firstHomeBuyer/,
}, {
  displayName: 'Application Fee',
  section: 'applicationCosts',
  fieldType: 'number',
  path: /fundingWorksheet\.applicationCosts\.applicationFee/,
}, {
  displayName: 'Disbursements',
  section: 'applicationCosts',
  fieldType: 'number',
  path: /fundingWorksheet\.applicationCosts\.fundsDisbursement/,
}, {
  displayName: 'Valuation',
  section: 'applicationCosts',
  fieldType: 'number',
  path: /fundingWorksheet\.applicationCosts\.valuationFee/,
}, {
  displayName: 'Legal costs',
  section: 'applicationCosts',
  fieldType: 'number',
  path: /fundingWorksheet\.applicationCosts\.legalFee/,
},
{
  displayName: 'Mortgage registration fee',
  section: 'applicationCosts',
  fieldType: 'overrideNumber',
  path: /fundingWorksheet\.applicationCosts\.mortgageRegistrationFee\.overrideValue/,
}, {
  displayName: 'LMI',
  section: 'applicationCosts',
  fieldType: 'overrideNumber',
  path: /fundingWorksheet\.applicationCosts\.lmi\.overrideValue/,
}, {
  displayName: 'LMI based on',
  section: 'applicationCosts',
  fieldType: 'lmiReferencedLenderId',
  path: /fundingWorksheet\.applicationCosts\.lmiReferencedLenderId/,
}, {
  displayName: 'Building/pest inspection',
  section: 'costToPurchase',
  fieldType: 'number',
  path: /fundingWorksheet\.costToPurchases\[.+]\.inspectionFee/,
}, {
  displayName: 'Adjustment of rates',
  section: 'costToPurchase',
  fieldType: 'number',
  path: /fundingWorksheet\.costToPurchases\[.+]\.adjustmentOfRates/,
}, {
  displayName: 'Stamp Duty',
  section: 'costToPurchase',
  fieldType: 'overrideNumber',
  path: /fundingWorksheet\.costToPurchases\[.+]\.stampDutyOnTransferOfProperty\.overrideValue/,
}, {
  displayName: 'Land titles fee',
  section: 'costToPurchase',
  fieldType: 'overrideNumber',
  path: /fundingWorksheet\.costToPurchases\[.+]\.titleTransferFee\.overrideValue/,
}, {
  displayName: 'Other costs',
  section: 'costToPurchase',
  fieldType: 'other',
  path: /fundingWorksheet\.costToPurchases\[.+]\.otherCosts.custom/,
}, {
  displayName: 'Other costs',
  section: 'construction',
  fieldType: 'other',
  path: /fundingWorksheet\.constructions\[.+]\.otherCosts.custom/,
}, {
  displayName: 'Discharge fee',
  section: 'refinances',
  fieldType: 'number',
  path: /fundingWorksheet\.refinances\[.+]\.dischargeFee/,
}, {
  displayName: 'Other costs',
  section: 'refinances',
  fieldType: 'other',
  path: /fundingWorksheet\.refinances\[.+]\.others.custom/,
}, {
    displayName: 'Other costs',
    section: 'otherCosts',
    fieldType: 'other',
    path: /fundingWorksheet\.otherCosts\.custom/,
}, {
  displayName: 'Capitalise LMI',
  section: 'loanAmount',
  fieldType: 'capitaliseLmi',
  path: /fundingWorksheet\.loanAmount\.capitaliseLmi/,
}, {
  displayName: 'Base loan amount',
  section: 'loanAmount',
  fieldType: 'number',
  path: /fundingWorksheet\.loanAmount\.baseLoanAmount/,
}, {
  displayName: 'SOMA comments',
  section: 'somaComments',
  fieldType: 'text',
  path: /fundingWorksheet\.somaComments/,
}];

export const getFieldConfig = (path) =>
  FUNDING_WORKSHEET_DISPLAY_FIELDS.find((field) => field.path.test(path));

export const FUNDING_WORKSHEET_SECTIONS = {
  contributions: 'Contributions updated',
  applicationCosts: 'Application costs updated',
  costToPurchase: 'Cost to purchase updated',
  construction: 'Construction updated',
  loanAmount: 'Loan amount updated',
  somaComments: 'SOMA comments',
  refinances: 'Refinance and debt consolidation updated',
  otherCosts: 'Other costs updated',
};

const getUpdatedToString = (others) => others.map(({ fieldName, fieldAmount }) => (`${fieldName} - ${formatCurrency(fieldAmount, { precision: 2 })}`)).join('\n');

const generateOtherUpdatedMessage = (displayName, others) =>
  (isEmpty(others)
    ? `all ${displayName} removed`
    : `${displayName} updated to:\n${getUpdatedToString(others)}`);

const generateBooleanMessage = (displayName, value) => `${displayName} - ${formatStringBool(value)}`;

const generateOverrideMessage = (displayName, value) => (isEmpty(value)
  ? `${displayName} override - removed`
  : `${displayName} - ${toDollarAmountWithDecimal(value)} (user override)`);

const generateNumberMessage = (displayName, value) => (isNil(value)
  ? `${displayName} - empty field`
  : `${displayName} - ${toDollarAmountWithDecimal(value)}`);

const generateCapitaliseLmiMessage = (value) => (value === 'true'
  ? 'The LMI has been capitalised'
  : 'The LMI is no longer capitalised');

const generateLmiLenderChangeMessage = (displayName, newValue, { lmiReferencedLenderName }) => {
  if (isEmpty(lmiReferencedLenderName)) {
    return `${displayName} - empty field`;
  }
  return (
      `${displayName} - ${lmiReferencedLenderName}`
  );
};

const getMessage = (change, extraInfo) => {
  const field = getFieldConfig(change.path);
  switch (field.fieldType) {
    case 'boolean':
      return generateBooleanMessage(field.displayName, change.newValue);
    case 'number':
      return generateNumberMessage(field.displayName, change.newValue);
    case 'overrideNumber':
      return generateOverrideMessage(field.displayName, change.newValue);
    case 'other':
      return generateOtherUpdatedMessage(field.displayName, change.newValue);
    case 'capitaliseLmi':
      return generateCapitaliseLmiMessage(change.newValue);
    case 'lmiReferencedLenderId':
      return generateLmiLenderChangeMessage(field.displayName, change.newValue, extraInfo);
    case 'text':
      return 'updated';
    default:
      return undefined;
  }
};

const getOtherCostToPurchaseCostsChanges = (updatedChanges, newOtherCosts) => {
  const otherFundsChangedCostToPurchaseIds = uniq(updatedChanges
    .map((change) => change.path.match(/fundingWorksheet\.costToPurchases\[(.+)]\.otherCosts.+/))
    .filter(match => match)
    .map(match => match[1]));
  return otherFundsChangedCostToPurchaseIds.map(id => ({
    path: `fundingWorksheet.costToPurchases[${id}].otherCosts.custom`,
    newValue: newOtherCosts[id],
  }));
};

const getOtherRefinanceCostsChanges = (updatedChanges, newOtherRefinanceCosts) => {
  const ids = uniq(updatedChanges
    .map((change) => change.path.match(/fundingWorksheet\.refinances\[(.+)]\.others.+/))
    .filter(match => match)
    .map(match => match[1]));
  return ids.map(id => ({
    path: `fundingWorksheet.refinances[${id}].others.custom`,
    newValue: newOtherRefinanceCosts ? newOtherRefinanceCosts[id] : null,
  }));
};

const getOtherFundsChanges = (origin, newOtherFunds) => (changeDetected(origin, 'fundingWorksheet\\.contributions\\.otherFunds.+')
    ? [{
      path: 'fundingWorksheet.contributions.otherFunds.custom',
      newValue: newOtherFunds,
    }]
    : []);

const getChangeContent = (changes, extraInfo) => {
  const messages = map(changes, (change) => getMessage(change, extraInfo));
  if (messages.length === 1 && messages[0] === 'updated') {
    return {
      subContent: 'updated',
      text: changes[0].newValue,
    };
  }
  return { subContent: messages };
};

const getOtherCostsChanges = (origin, newOtherTopUpCosts) => (changeDetected(origin, 'fundingWorksheet\\.otherCosts\\[\\d+]\\..+')
    ? [{
      path: 'fundingWorksheet.otherCosts.custom',
      newValue: newOtherTopUpCosts,
    }]
    : []);

const getOtherConstructionCostsChanges = (updatedChanges, newOtherConstructionCosts) => {
  const otherFundsChangedConstructionIds = uniq(updatedChanges
  .map((change) => change.path.match(/fundingWorksheet\.constructions\[(.+)]\.otherCosts.+/))
  .filter(match => match)
  .map(match => match[1]));
  return otherFundsChangedConstructionIds.map(id => ({
    path: `fundingWorksheet.constructions[${id}].otherCosts.custom`,
    newValue: newOtherConstructionCosts[id],
  }));
};

const fundingWorksheetChangeLogTransformer = origin => {
  const extraInfo = JSON.parse(origin.eventDetail.extraInfo) || {};

  const {
    newOtherFunds = [],
    newOtherCosts = {},
    newOtherRefinanceCosts = {},
    addressDetailMap = {},
    constructionAddressDetailMap = {},
    newOtherTopUpCosts = [],
    newOtherConstructionCosts = {},
  } = extraInfo;
  const otherFundsChanges = getOtherFundsChanges(origin, newOtherFunds);
  const updatedChanges = getUpdatedChanges(origin);
  const otherCostToPurchaseCostsChanges = getOtherCostToPurchaseCostsChanges(updatedChanges, newOtherCosts);
  const otherRefinanceCostsChanges = getOtherRefinanceCostsChanges(updatedChanges, newOtherRefinanceCosts);
  const otherCostsChanges = getOtherCostsChanges(origin, newOtherTopUpCosts);
  const otherConstructionCostsChanges = getOtherConstructionCostsChanges(updatedChanges, newOtherConstructionCosts);

  const changesToDisplay = orderBy([
    ...filter(updatedChanges, (change) => getFieldConfig(change.path)),
    ...otherFundsChanges,
    ...otherCostToPurchaseCostsChanges,
    ...otherCostsChanges,
    ...otherRefinanceCostsChanges,
    ...otherConstructionCostsChanges,
  ], 'path');

  const extractCostToPurchaseId = (path) => {
    const results = path.match(/fundingWorksheet\.costToPurchases\[(.+)]\.*/);
    return results[1];
  };

  const extractConstructionId = (path) => {
    const results = path.match(/fundingWorksheet\.constructions\[(.+)]\.*/);
    return results[1];
  };

  const changeGroups = groupBy(changesToDisplay,
    (change) => {
        const field = getFieldConfig(change.path);
        switch (field.section) {
          case 'costToPurchase':
            return `${field.section} ${extractCostToPurchaseId(change.path)}`;
          case 'construction':
            return `${field.section} ${extractConstructionId(change.path)}`;
          default:
            return field.section;
        }
    });

  if (isEmpty(changeGroups)) {
    return undefined;
  }

  const getContent = (key) => {
    if (key.startsWith('costToPurchase')) {
      const costToPurchaseId = key.split(' ')[1];
      const displayAddress = getDisplayAddress(get(addressDetailMap, costToPurchaseId));
      return `${FUNDING_WORKSHEET_SECTIONS.costToPurchase}${displayAddress ? ' - ' : ''}${displayAddress}`;
    }
    if (key.startsWith('construction')) {
      const constructionId = key.split(' ')[1];
      const displayAddress = getDisplayAddress(get(constructionAddressDetailMap, constructionId));
      return `${FUNDING_WORKSHEET_SECTIONS.construction}${displayAddress ? ' - ' : ''}${displayAddress}`;
    }
    return FUNDING_WORKSHEET_SECTIONS[key];
  };
  return keys(changeGroups)
    .map(key => ({
      ...getBasicLogInfo(origin),
      type: 'FUNDING WORKSHEET',
      content: getContent(key),
      ...getChangeContent(changeGroups[key], extraInfo),
    }));
};

export default fundingWorksheetChangeLogTransformer;
