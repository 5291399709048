import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import values from 'lodash/values';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormAutocomplete from 'shared/components/formFields/ReduxForm/Autocomplete';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import {
  AccountNumberGroup,
  ASSET_TYPE,
  isOtherType,
  MotorVehicleGroup,
  OthersGroupWithInstitution,
} from '../../../../../constants/assetsType';
import { VEHICLE_TYPE } from './constants';
import styles from './NonRealEstate.module.scss';
import WrappedOwnerships from '../../shared/WrappedOwnerships';
import TertiaryButton from '../../../../../../shared/components/Buttons/TertiaryButton';

const generateLenderOptions = lenders => orderBy(uniqBy(lenders, 'name'), 'name').map(lender => ({
  label: lender.name,
  value: lender.name,
}));

const findGroup = assetType => (assetType ? find(values(ASSET_TYPE), { value: assetType }).group : null);

const bsbValidate = value => (!!value && value.toString().length < 6 ? 'BSB should be 6 digits' : undefined);

const isAllowedNumber = value => value === undefined || /^\d*$/.test(value);

const NonRealEstate = (props) => {
  const {
   fieldNamePrefix, lenders, itemValue, editable, handleRemove,
  } = props;

  const assetType = itemValue?.assetType;
  const isMotorVehicle = findGroup(assetType) === MotorVehicleGroup;
  const isAccountNumber = findGroup(assetType) === AccountNumberGroup;
  const isOthersGroupWithInstitution = findGroup(assetType) === OthersGroupWithInstitution;
  const isOther = isOtherType(assetType);

  const renderInstitutionFields = useMemo(() => (
    <>
      <Field
        name={`${fieldNamePrefix}.institution`}
        component={ReduxFormAutocomplete}
        label="Institution"
        className={styles.institution}
        items={generateLenderOptions(lenders)}
      />
    </>
  ), [fieldNamePrefix, lenders]);

  const renderAccountNumberFields = useMemo(() => (
    <>
      <Field
        name={`${fieldNamePrefix}.institution`}
        component={ReduxFormAutocomplete}
        label="Institution"
        className={styles.institution}
        items={generateLenderOptions(lenders)}
      />
      <Field
        name={`${fieldNamePrefix}.accountName`}
        component={ReduxFormTextInput}
        label="Account name"
        className={styles.accountName}
        options={values(ASSET_TYPE)}
      />
      <Field
        name={`${fieldNamePrefix}.bsb`}
        component={ReduxFormTextInput}
        label="BSB"
        isAllowed={isAllowedNumber}
        className={styles.bsb}
        maxLength={6}
        readOnlyFormatter={value => value}
        validate={bsbValidate}
      />
      <Field
        name={`${fieldNamePrefix}.accountNumber`}
        component={ReduxFormTextInput}
        label="Account number"
        className={styles.accountNumber}
      />
    </>
  ), [fieldNamePrefix, lenders]);

  const renderMotorVehicleFields = useMemo(() => (
    <>
      <Field
        name={`${fieldNamePrefix}.vehicleType`}
        component={ReduxFormSelect}
        label="Type"
        className={styles.vehicleType}
        options={VEHICLE_TYPE}
      />
      <Field
        name={`${fieldNamePrefix}.make`}
        component={ReduxFormTextInput}
        label="Make"
        className={styles.make}
      />
      <Field
        name={`${fieldNamePrefix}.model`}
        component={ReduxFormTextInput}
        label="Model"
        className={styles.model}
      />
      <Field
        name={`${fieldNamePrefix}.year`}
        component={ReduxFormTextInput}
        label="Year"
        isAllowed={isAllowedNumber}
        className={styles.year}
        maxLength={4}
        readOnlyFormatter={value => value}
      />
    </>
  ), [fieldNamePrefix]);
  const renderOtherFields = useMemo(() => (
    <>
      <Field
        name={`${fieldNamePrefix}.description`}
        component={ReduxFormTextInput}
        label="Description"
        className={styles.description}
      />
    </>
  ), [fieldNamePrefix]);
  return (
    <div className={styles.container}>
      <div
        className={styles.wrapper}
      >
        <Field
          name={`${fieldNamePrefix}.assetType`}
          component={ReduxFormSelect}
          label="Asset type"
          menuPlacement="auto"
          className={styles.assetType}
          options={values(ASSET_TYPE)}
        />
        {isMotorVehicle && renderMotorVehicleFields}
        {isAccountNumber && renderAccountNumberFields}
        {isOthersGroupWithInstitution && renderInstitutionFields}
        <WrappedOwnerships fieldName={`${fieldNamePrefix}.ownerships`} rowSpan={3} />
        <Field
          name={`${fieldNamePrefix}.value`}
          component={ReduxFormNumberInput}
          label="Value"
          placeholder="$"
          decimalScale={2}
          prefix="$"
          fullWidth
          allowNegative={false}
          className={styles.valueField}
        />
        {isOther && renderOtherFields}
      </div>
      {editable && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
    </div>
  );
};
NonRealEstate.defaultProps = {
  itemValue: {},
};

NonRealEstate.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  lenders: PropTypes.array.isRequired,
  itemValue: PropTypes.object,
  handleRemove: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default NonRealEstate;
