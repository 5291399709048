import sortedUniq from 'lodash/sortedUniq';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import { REPAYMENT_FREQUENCIES } from '../../../constants';

const TIMESTAMP_FORMAT = 'D MMM YYYY - h:mm A';
const DATE_FORMAT = 'DD/MM/YYYY';

function getAll(ary, attributeName) {
  return ary.reduce((acc, item) => acc.concat(...item[attributeName]), []);
}

export default (state, profile, generatedAt, overrideUserProfile) => {
  const {
    products, comparison, applicationCriteria, loanCriteriaList,
  } = state;

  const searchCriteria = {
    loanCriteria: loanCriteriaList,
    ...applicationCriteria,
    totalCostTerm: products.totalCostTerm,
    repaymentFrequency: REPAYMENT_FREQUENCIES[products.repaymentFrequency],
  };
  const featureNames = sortedUniq(getAll(loanCriteriaList, 'selectedFeatureNames').sort());
  const featureNamesFromComparison = sortedUniq(comparison.selectedFeaturesFromComparison.sort());
  const selectedFeatureNames = featureNames.concat(featureNamesFromComparison);
  const getPrepareBy = userProfile => ({
      displayName: userProfile.username,
      email: userProfile.email,
      phoneNumber: userProfile.mobilePhone || userProfile.officePhone || undefined,
  });

  const preparedBy = getPrepareBy(overrideUserProfile || profile);

  const selectedOptions = comparison.selectedOptions.map((selectedOption) => {
    const variants = selectedOption.variants.map((variant) => {
      const featureDetails = reduce(variant.featureDetails, (acc, fd) => {
        acc[fd.featureName] = fd;
        return acc;
      }, {});
      return {
        ...variant,
        featureDetails,
      };
    });
    return { ...selectedOption, variants };
  });

  return {
    searchCriteria,
    selectedFeatureNames,
    preparedBy,
    selectedOptions,
    includeComments: comparison.includeCommentsInPrintout,
    generatedTimestamp: generatedAt.format(TIMESTAMP_FORMAT),
    generatedDate: generatedAt.format(DATE_FORMAT),
    isSplitSearch: size(loanCriteriaList) > 1,
  };
};
