export const ACTIVE_MISSING_COMMISSIONS = {
  MISSING_TRAIL_1: 'Missing trail (1st month)',
  MISSING_TRAIL_2: 'Missing trail (2nd month)',
  MISSING_TRAIL_3: 'Missing trail (3rd month)',
  MISSING_TRAIL_4: 'Missing trail (4th month)',
  MISSING_TRAIL_5: 'Missing trail (5th month)',
  MISSING_TRAIL_6: 'Missing trail (6th month)',
  MISSING_TRAIL_6_PLUS: 'Missing trail (6+ months)',
  LOAN_IN_ARREARS: 'Loan in arrears',
};

export const ACTIVE_OTHER = {
  Settled: 'Settled',
  RECEIVING_COMMISSIONS: 'Receiving commissions',
  NO_COMMISSION_EXPECTED: 'No commission expected',
};

export const INACTIVE = {
  PAID_OUT: 'Paid out (inactive)',
  PRE_MERGER_LOAN: 'Pre-merger loan - Mortgage Force (inactive)',
  REFINANCED_BY_COMPETITOR: 'Refinanced by competitor (inactive)',
  REFINANCED_BY_LENDER: 'Refinanced by lender (inactive)',
  REFINANCED_BY_SMARTLINE: 'Refinanced by Smartline (inactive)',
  REFINANCED_BY_UNKNOWN: 'Refinanced by unknown (inactive)',
  TOPPED_UP: 'Topped up',
};

export const findStatusNameByKey = (statusKey) => {
  const listOfStatuses = { ...ACTIVE_MISSING_COMMISSIONS, ...ACTIVE_OTHER, ...INACTIVE };
  return listOfStatuses[statusKey];
};
