import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import ConfirmModal from './ConfirmModal';
import { usePromise } from '../../hooks/usePromise';

export const ConfirmContext = React.createContext(noop);

const ConfirmProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({ isOpen: false });
  const [confirm, resolve] = usePromise(() => (confirmMeta) => {
    setModalProps({ ...confirmMeta, isOpen: true });
    return () => {
      setModalProps({ isOpen: false });
    };
  }, [setModalProps]);

  const handleClickSecondaryButton = useMemo(() => () => {
    resolve(false);
  }, [resolve]);

  const handleClickPrimaryButton = useMemo(() => () => {
    resolve(true);
  }, [resolve]);

  const closeModal = useMemo(() => () => setModalProps({ isOpen: false }), [setModalProps]);

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}
      <ConfirmModal
        {...modalProps}
        closeModal={closeModal}
        onClickSecondaryButton={handleClickSecondaryButton}
        onClickPrimaryButton={handleClickPrimaryButton}
      />
    </ConfirmContext.Provider>
  );
};

ConfirmProvider.propTypes = {
  children: PropTypes.any,
};

ConfirmProvider.defaultProps = {
  children: undefined,
};

export default ConfirmProvider;
