import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/speechBubble"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M11.9995834,4.33326389 C16.5943919,4.33326389 20.3325695,7.32647251 20.3325695,11.0054859 C20.3325695,15.1028152 16.0452481,17.6393761 12.0745802,17.6393761 C10.4604808,17.6393761 9.25553102,17.3085566 8.41306612,17.1027318 C7.57976751,17.6135439 7.08395484,17.9668625 4.85321445,18.6193353 C5.29902921,17.4752163 5.45568935,16.3294307 5.35486021,15.0653167 C4.65655598,14.2320181 3.66743038,13.0654001 3.66743038,11.0054859 C3.66659723,7.32647251 7.4047748,4.33326389 11.9995834,4.33326389 Z M11.9995834,2.66666667 C6.71813674,2.66666667 2,6.1881866 2,11.0054859 C2,12.713748 2.61497438,14.3911781 3.70576226,15.6927906 C3.75159368,17.217727 2.85329778,19.4059692 2.04499813,20.9992361 C4.21324111,20.6075858 7.29561268,19.7426218 8.69305446,18.8859909 C9.87467189,19.1734789 11.0054581,19.3051401 12.0754135,19.3051401 C17.9785009,19.3051401 22,15.2744747 22,11.0046526 C21.9991667,6.16235435 17.2485313,2.66666667 11.9995834,2.66666667 Z M11.9995834,14.3328472 L7.83309029,14.3328472 L7.83309029,13.4995486 L11.9995834,13.4995486 L11.9995834,14.3328472 Z M16.1660764,11.8329514 L7.83309029,11.8329514 L7.83309029,10.9996528 L16.1660764,10.9996528 L16.1660764,11.8329514 Z M16.1660764,9.33305557 L7.83309029,9.33305557 L7.83309029,8.49975695 L16.1660764,8.49975695 L16.1660764,9.33305557 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
