import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import styles from 'shared/components/productSearch/CriteriaSummary/styles.module.scss';
import LoanCriteriaSummary from 'shared/components/productSearch/CriteriaSummary/LoanCriteriaSummary';
import EmbeddedApplicationCriteriaSummary from 'shared/components/productSearch/CriteriaSummary/EmbeddedApplicationCriteriaSummary';
import { LoanCriteriaShape, ApplicationCriteriaShape } from '../../../../../../shapes';

const CriteriaSummary = ({
                           applicationCriteria,
                           loansCriteria,
                         }) => {
  const isSingleLoan = get(loansCriteria, 'length') === 1;

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.header}>
            <h2>Loan criteria</h2>
          </div>
          <div>
            {map(loansCriteria, (criteria, index) => {
              const key = `loan${index}`;
              return (
                <LoanCriteriaSummary
                  key={key}
                  applicationCriteria={applicationCriteria}
                  loanCriteria={criteria}
                  loanNum={index + 1}
                  isSingleLoan={isSingleLoan}
                />
              );
            })}
            <EmbeddedApplicationCriteriaSummary
              applicationCriteria={applicationCriteria}
              isSingleLoan={isSingleLoan}
            />
          </div>
        </div>
      </div>
    </>
  );
};
CriteriaSummary.defaultProps = {
  applicationCriteria: {},
  loansCriteria: [],
};

CriteriaSummary.propTypes = {
  applicationCriteria: ApplicationCriteriaShape,
  loansCriteria: PropTypes.arrayOf(LoanCriteriaShape),
};

export default CriteriaSummary;
