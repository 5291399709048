import React from 'react';
import get from 'lodash/get';
import SplitDisclaimer from 'shared/components/productSearch/SplitDisclaimer/SplitDisclaimer';
import { ProductsTableContext } from '../ProductSearchContext';

const ConnectedSplitDisclaimer = () => (
  <ProductsTableContext.Consumer>
    {
      context => {
        const isSplitSearch = get(context, 'products.searchCriteria.loanCriteriaList', []).length > 1;
        return (<SplitDisclaimer isSplitSearch={isSplitSearch} />);
      }
    }
  </ProductsTableContext.Consumer>
);

export default ConnectedSplitDisclaimer;
