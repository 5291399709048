import { change, Field, Fields } from 'redux-form';
import values from 'lodash/values';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormYesNoSwitch from 'shared/components/formFields/ReduxForm/YesNoSwitch';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from '../PurchasingAsset.module.scss';
import RealEstateAsset from '../../../shared/RealEstateAsset';
import { OWNER_OCCUPIED } from '../../../shared/RealEstateAsset/constants';
import ConstructionFieldsForPurchasingAsset from '../ConstructionFieldsForPurchasingAsset';
import { STATUS_OPTIONS } from '../constants';
import PreApprovalAddressFields from '../PreApprovalAddressFields';
import ResidentsSelector from '../ResidentsSelector';

const PurchasingAssetComponent = ({
                                    selector,
                                    securityForm,
                                    isConstructionResidentialType,
                                    isOwnerOccupied,
                                    ...props
                                  }) => {
  const {
    editable,
    fieldNamePrefix,
    handleRemove,
    applicants,
  } = props;
  const isPreApproval = useSelector(state => selector(state, `${fieldNamePrefix}.isPreApproval`));
  const reduxDispatch = useDispatch();
  // eslint-disable-next-line
  const contractPriceFieldsName = {
    transferOfLand: `${fieldNamePrefix}.contractPrice.transferOfLandAmount`,
    contractPrice: `${fieldNamePrefix}.contractPrice.contractPriceAmount`,
  };
  // eslint-disable-next-line
  const handlePurposeChange = useCallback((value) => {
    const residents = value === OWNER_OCCUPIED ? applicants.map(a => a.id) : null;
    reduxDispatch(change(securityForm, `${fieldNamePrefix}.residents`, residents));
  }, [applicants, fieldNamePrefix, reduxDispatch, securityForm]);

  const handlePropertyTypeChange = useCallback(() =>
      values(contractPriceFieldsName)
        .forEach(fieldName => reduxDispatch(change(securityForm, fieldName, null))),
    [contractPriceFieldsName, reduxDispatch, securityForm]);

  return (
    <div className={styles.container}>
      <RealEstateAsset
        {...props}
        onPurposeChange={handlePurposeChange}
        onPropertyTypeChange={handlePropertyTypeChange}
        setDefaultConstructionValue
        valueLabel="Purchase price"
        displayGuarantors={false}
        ownershipRowSpan={5}
        valueEditable={editable}
      >
        {isConstructionResidentialType && (
          <Fields
            names={values(contractPriceFieldsName)}
            component={ConstructionFieldsForPurchasingAsset}
            contractPriceFieldsName={contractPriceFieldsName}
            constructionAmountEditable={editable}
          />
        )}
        <Field
          name={`${fieldNamePrefix}.assetStatus`}
          component={ReduxFormSelect}
          label="Status"
          className={styles.statusField}
          options={STATUS_OPTIONS}
          menuPlacement="auto"
        />
        <Field
          name={`${fieldNamePrefix}.isPreApproval`}
          component={ReduxFormYesNoSwitch}
          label="Pre approval"
          props={{
            className: styles.isPreApproval,
            switchedOnText: 'Yes',
            switchedOffText: 'No',
          }}
        />
        {isPreApproval && (
          <PreApprovalAddressFields
            fieldNamePrefix={fieldNamePrefix}
            selector={selector}
            securityForm={securityForm}
          />
        )}
        {isOwnerOccupied && (
          <ResidentsSelector
            fieldNamePrefix={fieldNamePrefix}
            applicants={applicants}
          />
        )}
      </RealEstateAsset>
      {editable && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
    </div>
  );
};

PurchasingAssetComponent.propTypes = {
  editable: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selector: PropTypes.func.isRequired,
  securityForm: PropTypes.string.isRequired,
  isConstructionResidentialType: PropTypes.bool.isRequired,
  isOwnerOccupied: PropTypes.bool.isRequired,
};

export default PurchasingAssetComponent;
