export const EMPTY_CHARACTER = '\u00a0';

export const liabilityTypes = {
  ATOOrCentrelinkDebt: 'ATOOrCentrelinkDebt',
  ChargeCard: 'ChargeCard',
  CommercialBill: 'CommercialBill',
  ContingentLiability: 'ContingentLiability',
  CourtRuledChildMaint: 'CourtRuledChildMaint',
  CourtRuledOtherDebt: 'CourtRuledOtherDebt',
  CreditCard: 'CreditCard',
  HECS: 'HECS',
  HirePurchase: 'HirePurchase',
  Lease: 'Lease',
  LineOfCredit: 'LineOfCredit',
  LoanAsGuarantor: 'LoanAsGuarantor',
  Maintenance: 'Maintenance',
  MortgageLoan: 'MortgageLoan',
  Other: 'Other',
  OtherLoan: 'OtherLoan',
  OutstandingTaxation: 'OutstandingTaxation',
  Overdraft: 'Overdraft',
  PersonalLoan: 'PersonalLoan',
  StoreCard: 'StoreCard',
  TermLoan: 'TermLoan',
};

export const typeOptions = [
  { value: liabilityTypes.ATOOrCentrelinkDebt, label: 'ATO or centrelink debt' },
  { value: liabilityTypes.ChargeCard, label: 'Charge card' },
  { value: liabilityTypes.CommercialBill, label: 'Commercial bill' },
  { value: liabilityTypes.ContingentLiability, label: 'Contingent liability' },
  { value: liabilityTypes.CourtRuledChildMaint, label: 'Court ruled child maint' },
  { value: liabilityTypes.CourtRuledOtherDebt, label: 'Court ruled other debt' },
  { value: liabilityTypes.CreditCard, label: 'Credit card' },
  { value: liabilityTypes.HECS, label: 'HECS' },
  { value: liabilityTypes.HirePurchase, label: 'Hire purchase' },
  { value: liabilityTypes.Lease, label: 'Lease' },
  { value: liabilityTypes.LineOfCredit, label: 'Line of credit' },
  { value: liabilityTypes.LoanAsGuarantor, label: 'Loan as guarantor' },
  { value: liabilityTypes.Maintenance, label: 'Maintenance' },
  { value: liabilityTypes.MortgageLoan, label: 'Mortgage loan' },
  { value: liabilityTypes.Other, label: 'Other' },
  { value: liabilityTypes.OtherLoan, label: 'Other loan' },
  { value: liabilityTypes.OutstandingTaxation, label: 'Outstanding taxation' },
  { value: liabilityTypes.Overdraft, label: 'Overdraft' },
  { value: liabilityTypes.PersonalLoan, label: 'Personal loan' },
  { value: liabilityTypes.StoreCard, label: 'Store card' },
  { value: liabilityTypes.TermLoan, label: 'Term loan' },
];

export const repaymentTypeFromLoan = {
  'Principal and Interest': 'PrincipalAndInterest',
  'Principal and Interest Repayment': 'PrincipalAndInterest',
  'Interest Only': 'InterestOnly',
};

export const repaymentTypeOptions = [
  { value: 'InterestOnly', label: 'Interest only' },
  { value: 'PrincipalAndInterest', label: 'Principal and interest' },
];

export const interestTypeFromLoan = {
  Fixed: 'Fixed',
  Variable: 'Variable',
  'Line of credit': 'Equity',
};

export const interestTypeOptions = [
  { value: 'Fixed', label: 'Fixed' },
  { value: 'Variable', label: 'Variable' },
  { value: 'Equity', label: 'Line of credit' },
];

export const frequencyOptions = [
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Fortnightly', label: 'Fortnightly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Yearly', label: 'Yearly' },
];

export const clearingStatuses = {
  Full: 'Full',
  Partial: 'Partial',
  No: 'No',
  TopUp: 'TopUp',
};

export const clearingStatusWithoutTopUpOptions = [
  { value: clearingStatuses.No, label: 'No' },
  { value: clearingStatuses.Full, label: 'Full pay out' },
  { value: clearingStatuses.Partial, label: 'Partial pay out' },
];

export const clearingStatusWithTopUpOptions = [
  { value: clearingStatuses.No, label: 'No' },
  { value: clearingStatuses.Full, label: 'Full pay out' },
  { value: clearingStatuses.Partial, label: 'Partial pay out' },
  { value: clearingStatuses.TopUp, label: 'Top up' },
];
