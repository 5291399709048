import React from 'react';
import pick from 'lodash/pick';
import Modal, { modalPropTypes } from 'shared/components/Modal/Modal';
import PropTypes from 'prop-types';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import styles from './Modal.module.scss';

function ModalWithButtons(props) {
  return (
    <Modal
      {...pick(props, Object.keys(modalPropTypes))}
      footer={renderFoot(props)}
    />
  );
}

const renderFoot = (args) => {
  const {
 submitText, submitButtonProps, secondarySubmitText, secondaryButtonPros,
} = args;
  return (
    <div className={styles.buttonGroup}>
      <PrimaryButton {...submitButtonProps}>
        {submitText}
      </PrimaryButton>
      {
        secondarySubmitText && (
          <SecondaryButton {...secondaryButtonPros}>
            {secondarySubmitText}
          </SecondaryButton>
        )
      }
    </div>
  );
};

ModalWithButtons.propTypes = {
  ...modalPropTypes,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondarySubmitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitButtonProps: PropTypes.object,
  secondaryButtonPros: PropTypes.object,
};

ModalWithButtons.defaultProps = {
  submitText: 'Close',
  secondarySubmitText: '',
  submitButtonProps: {},
  secondaryButtonPros: {},
};

export default ModalWithButtons;
