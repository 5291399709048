import isNil from 'lodash/isNil';
import { addbackTypeOptions, companyAddbackTypeOptions, governmentBenefitsTypeOptions } from './constants';

const GOVERNMENT_BENEFITS_TYPE = 'GovernmentBenefits';
const ADDBACK_TYPE = 'Addback';

export const isTypeSelected = (selectedTypes, isCompany = false) => value => {
  const addbackSize = (isCompany ? companyAddbackTypeOptions : addbackTypeOptions).length;

if (value === ADDBACK_TYPE) {
  return selectedTypes.filter(({ type }) => type === value).length === addbackSize;
}

if (value === GOVERNMENT_BENEFITS_TYPE) {
  return selectedTypes.filter(({ type }) => type === value).length === governmentBenefitsTypeOptions.length;
}

  return selectedTypes.find(
    ({ type, addbackType, governmentBenefitsType }) => type === value
      && isNil(addbackType)
      && isNil(governmentBenefitsType),
  );
};

export const isAddbackTypeSelected = selectedTypes => value =>
  selectedTypes.find(
    ({ addbackType }) => addbackType === value,
  );

export const isGovernmentBenefitsTypeSelected = selectedTypes => value =>
  selectedTypes.find(
    ({ governmentBenefitsType }) => governmentBenefitsType === value,
  );

export const disableSelected = isSelected => option => ({
  ...option,
  isDisabled: isSelected(option.value),
});
