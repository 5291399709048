import React from 'react';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/ReduxForm/PlainTextField/PlainTextField';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { Field, FieldArray, Fields } from 'redux-form';
import classNames from 'classnames';
import styles from './Person.module.scss';
import { getDisplayAddress } from '../../../../../../utils/address';
import { toDateWithSlash } from '../../../../../../../utils/datetime';
import { formatHousingStatus } from '../utils/formatter';

const getDate = dateStr => (dateStr ? moment(dateStr) : null);
const getEndDate = (isCurrent, endDate) => (isCurrent ? moment() : getDate(endDate));
const getYearsDiff = (startDate, endDate) => (endDate && startDate ? endDate.diff(startDate, 'years', false) : null);
const getMonthsDiff = (startDate, endDate) => (endDate && startDate ? endDate.diff(startDate, 'months', false) - 12
  * getYearsDiff(startDate, endDate) : null);

const formatTimeYear = address => {
  if (address) {
    const isCurrent = !address.priorAddress;
    const startDate = getDate(address.startDate);
    const endDate = getEndDate(isCurrent, address.endDate);
    const yearsDiff = getYearsDiff(startDate, endDate);
    return yearsDiff !== null ? `${yearsDiff} years` : null;
  }
  return null;
};

const formatTimeMonth = address => {
  if (address) {
    const isCurrent = !address.priorAddress;
    const startDate = getDate(address.startDate);
    const endDate = getEndDate(isCurrent, address.endDate);
    const monthsDiff = getMonthsDiff(startDate, endDate);
    return monthsDiff !== null ? `${monthsDiff} months` : null;
  }
  return null;
};

const ResidentialAddress = (fields) => {
  const [addressFieldName, priorAddressName] = fields.names;
  const isCurrent = !get(fields, `${priorAddressName}.input.value`, false);
  const addressDetailField = get(fields, `${addressFieldName}`);
  return (
    <PlainTextField
      label={isCurrent ? 'Current residential address' : 'Previous residential address'}
      {...addressDetailField}
      {...fields}
    />
  );
};

const renderAddressField = (address, index, fieldNamePrefix) =>
   (
     <React.Fragment key={`address-${index}`}>
       <Fields
         names={[`${fieldNamePrefix}[${index}].addressDetail`, `${fieldNamePrefix}[${index}].priorAddress`]}
         className={classNames([
        styles.fieldWrapper,
        styles.quadrupleColumnWrapper,
        styles.atSecondColumnOnDesktop,
        styles.atFirstColumnOnMobile,
      ])}
         component={ResidentialAddress}
         format={(value, name) => (name === `${fieldNamePrefix}[${index}].addressDetail` ? getDisplayAddress(value) : value)}
       />
       <Field
         name={`${fieldNamePrefix}[${index}].housingStatus`}
         className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
         component={PlainTextField}
         label="Housing status"
         format={formatHousingStatus}
       />
       <Field
         name={`${fieldNamePrefix}[${index}]`}
         className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
         component={PlainTextField}
         label="Time at address"
         format={formatTimeYear}
       />
       <Field
         name={`${fieldNamePrefix}[${index}]`}
         className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.noLabel])}
         component={PlainTextField}
         format={formatTimeMonth}
       />
       <Field
         name={`${fieldNamePrefix}[${index}].startDate`}
         className={classNames([styles.fieldWrapper, styles.singleColumnWrapperOnDesktop])}
         component={PlainTextField}
         label="Date moved in"
         format={value => (value ? toDateWithSlash(value) : '')}
       />
     </React.Fragment>
);

const renderAddressFields = ({ fields }) => {
  const addresses = (fields || []).map(address => ({
    ...address,
    priorAddress: !!address.priorAddress,
  }));
  const orderedAddresses = orderBy(addresses, ['priorAddress', 'startDate']);
  return (isEmpty(orderedAddresses) ? [{}] : orderedAddresses)
    .map((address, index) => renderAddressField(address, index, fields.name));
};

const AddressesSection = React.memo(({ fieldNamePrefix }) => (
  <FieldsContainer
    title="Addresses"
    className={styles.sectionWrapper}
    fieldsWrapperClassName={styles.overrideSectionWrapper}
  >
    <FieldArray
      name={`${fieldNamePrefix}.contactDetails.addresses`}
      component={renderAddressFields}
    />
  </FieldsContainer>
));

AddressesSection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};
export default AddressesSection;
