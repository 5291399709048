import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import style from './style';
import inputComponentStyles from './InputComponent.module.scss';

const MAX_TERM = 40;
const isValidLoanTerm = loanTerm =>
  (loanTerm === undefined || (loanTerm > 0 && loanTerm <= MAX_TERM));

const LoanTermComponent = (props) => {
  const {
    loanTerm,
    dispatchUpdateLoanTerm,
    dispatchValidateInterestOnlyTerm,
    dispatchValidateFixedLoanTerm,
  } = props;
  return (
    <NumberInput
      label="Loan term (1-30 yrs)"
      className="short"
      value={loanTerm}
      onChange={dispatchUpdateLoanTerm}
      onBlur={() => {
        dispatchValidateFixedLoanTerm();
        dispatchValidateInterestOnlyTerm();
      }}
      isAllowed={isValidLoanTerm}
      style={style}
      textClassName={classNames(inputComponentStyles.inputText, inputComponentStyles.short)}
    />
  );
};

LoanTermComponent.defaultProps = {
  loanTerm: undefined,
};

LoanTermComponent.propTypes = {
  dispatchUpdateLoanTerm: PropTypes.func.isRequired,
  dispatchValidateFixedLoanTerm: PropTypes.func.isRequired,
  dispatchValidateInterestOnlyTerm: PropTypes.func.isRequired,
  loanTerm: PropTypes.number,
};

export default LoanTermComponent;
