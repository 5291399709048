import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Notification from 'shared/components/Notification/Notification';
import { connect } from 'react-redux';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import styles from './ApplicationUpdate.module.scss';
import { APPLICATION_UPDATE_TYPE } from '../../../../../constants/applicationUpdate';
import { CONTACTS_TAB, GMAIL_TAB, INSURANCE_REFERRAL_TAB } from '../../../../../constants/navTab';
import { acknowledgeNotification } from '../../../redux';
import { hasEditPermissionSelectorRegardlessOfSettlement } from '../../../redux/selectors/permissions';

export class ApplicationUpdatePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acknowledgeUpdateId: null,
    };
  }

  setAcknowledgeUpdateId = acknowledgeUpdateId => this.setState({ acknowledgeUpdateId });

  acknowledge = (notificationId) => {
    this.setAcknowledgeUpdateId(notificationId);
    const { acknowledgeNotification: updateUpdates } = this.props;
    updateUpdates(notificationId);
    this.setAcknowledgeUpdateId(null);
  };

  buildNotificationProps({
    id, type, name, timestamp, receivedTimestamp, messageAnnotations,
  }) {
    const { acknowledgeUpdateId } = this.state;
    const {
      hasEditPermission: showButton, match: { params: { applicationId } }, history,
    } = this.props;

    let result = {
      renderCollapsibleContent: isEmpty(messageAnnotations) ? null : () => (
        <div className={styles.annotationWrapper}>
          {messageAnnotations
            .map(annotation => (<p className={styles.annotation} key={annotation.id}>{annotation.value}</p>))}
        </div>
      ),
      renderHeaderButton: () => showButton && (
        <SecondaryButton
          className={styles.okButton}
          onClick={() => this.acknowledge(id)}
          disabled={acknowledgeUpdateId === id}
        >
          OK
        </SecondaryButton>
      ),
    };
   const NOTIFICATION = {
     EVENT: {
       title: `${name} on ${timestamp}`,
       subtitle: `${receivedTimestamp} from ApplyOnline`,
     },
     STATUS: {
       title: `Application has changed status to '${name}' on ${timestamp}`,
       subtitle: `${receivedTimestamp} from ApplyOnline`,
     },
     ALLIANZ_INSURANCE: {
       title: "It's time to refer your client to Allianz or opt out of general insurance.",
       subtitle: `${timestamp}`,
       renderHeaderButton: () => (
        showButton
         && (
         <PrimaryButton
           className={styles.referButton}
           onClick={() => history.push(`/applications/${applicationId}/${INSURANCE_REFERRAL_TAB.path}`)}
         >
           Refer
         </PrimaryButton>
        )
       ),
     },
     ALI_INSURANCE: {
       title: "It's time to email ALI loan protection insurance information to applicant.",
       subtitle: `${receivedTimestamp} from Launchpad`,
       renderHeaderButton: () => (
         showButton
         && (
         <div>
           <PrimaryButton
             className={styles.goToGmailButton}
             onClick={() => window.open(GMAIL_TAB.path, '_blank')}
           >
             Go to Gmail
           </PrimaryButton>

           <SecondaryButton
             className={styles.okButton}
             onClick={() => this.acknowledge(id)}
             disabled={acknowledgeUpdateId === id}
           >
             OK
           </SecondaryButton>
         </div>
         )),
},
     EXPECTED_SETTLEMENT_DATE: {
       title: "It's time to discuss ALI loan protection insurance with the applicant",
       subtitle: `${receivedTimestamp} from Launchpad`,
       renderHeaderButton: () => showButton && (
         <div>
           <PrimaryButton
             className={styles.goToContactPageButton}
             onClick={() => history.push(`/applications/${applicationId}/${CONTACTS_TAB.path}`)}
           >
             Go to Contacts page
           </PrimaryButton>
         </div>
       ),
     },
   };
   const createNotification = ({ title, subtitle, renderHeaderButton }) => (renderHeaderButton ? (
       {
         ...result,
         title,
         subtitle,
         renderHeaderButton,
       }
     ) : ({
       ...result,
       title,
       subtitle,
       ...renderHeaderButton,
     }));

    switch (type) {
      case APPLICATION_UPDATE_TYPE.EVENT:
        result = createNotification(NOTIFICATION.EVENT);
        break;
      case APPLICATION_UPDATE_TYPE.STATUS:
       result = createNotification(NOTIFICATION.STATUS);
        break;
      case APPLICATION_UPDATE_TYPE.ALLIANZ_INSURANCE:
        result = createNotification(NOTIFICATION.ALLIANZ_INSURANCE);
        break;
      case APPLICATION_UPDATE_TYPE.ALI_INSURANCE:
       result = createNotification(NOTIFICATION.ALI_INSURANCE);
        break;
      case APPLICATION_UPDATE_TYPE.EXPECTED_SETTLEMENT_DATE:
        result = createNotification(NOTIFICATION.EXPECTED_SETTLEMENT_DATE);
        break;
      default: break;
    }
    return result;
  }

  renderNotification = (applicationUpdates) =>
     (
      applicationUpdates.map((item) => {
        const notificationProps = this.buildNotificationProps(item);
        return (
          <Notification
            key={item.id}
            variant="info"
            {...notificationProps}
          />
        );
      }));

  render() {
    const { applicationUpdates } = this.props;
    return !isEmpty(applicationUpdates) && (
      <div className={styles.applicationUpdate}>
        {this.renderNotification(applicationUpdates)}
      </div>
    );
  }
}

ApplicationUpdatePanel.propTypes = {
  applicationUpdates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    receivedTimestamp: PropTypes.string.isRequired,
    messageAnnotations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  })).isRequired,
  applicationId: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  acknowledgeNotification: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  applicationUpdates: get(state, 'application.applicationUpdates'),
  applicationId: get(state, 'application.applicationDetail.id'),
  hasEditPermission: hasEditPermissionSelectorRegardlessOfSettlement(state),
});

export default withRouter(connect(mapStateToProps, {
   acknowledgeNotification,
})(ApplicationUpdatePanel));
