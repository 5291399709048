import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import { createSelector } from '@reduxjs/toolkit';
import TotalCostTermComponent from '../../../../../../ProductSearch/ProductsTable/TotalCostTermComponent';

const totalCostTermSelector = state => get(state, 'application.productSearch.searchCriteria.totalCostTerm');

const getMaximumProvidedLoanTerms = (loansCriteria) => {
  const maximumTerm = Math.max(...loansCriteria.map(s => s.loanTerm || 0));
  if (maximumTerm !== 0) {
    return maximumTerm;
  }
  return 30; // No loans have a term
};

const maximumTermSelector = createSelector(
  state => get(state, 'application.productSearch.searchCriteria.loansCriteria'),
  getMaximumProvidedLoanTerms,
);

const currentLoanTermsSelector = createSelector(
  state => get(state, 'application.productSearch.searchCriteria.loansCriteria'),
  (loansCriteria) => map(loansCriteria, 'loanTerm'),
);

const TotalCostTermColumnHeader = ({ onSelectTotalCostTerm }) => {
  const totalCostTerm = useSelector(totalCostTermSelector);
  const maximumTerm = useSelector(maximumTermSelector);
  const currentLoanTerms = useSelector(currentLoanTermsSelector);
  return (
    <TotalCostTermComponent
      currentLoanTerms={currentLoanTerms}
      maximumTerm={maximumTerm}
      totalCostTerm={totalCostTerm}
      menuPortalTarget={document.body}
      onChange={onSelectTotalCostTerm}
    />
  );
};

TotalCostTermColumnHeader.propTypes = {
  onSelectTotalCostTerm: PropTypes.func.isRequired,
};

export default React.memo(TotalCostTermColumnHeader);
