import React, { useMemo } from 'react';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { Fields, fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import map from 'lodash/map';
import styles from './index.module.scss';
import {
  REFINANCE_REASON_OPTION,
  REFINANCE_APPLICABLE_OPTIONS,
} from './constants';
import ReduxFormRadioGroup from '../../../../../shared/components/formFields/ReduxForm/RadioGroup';
import { containOther } from './utils';
import ReduxFormCheckboxDropdown from '../../../../../shared/components/formFields/ReduxForm/CheckboxDropdown';
import ReduxFormTextArea from '../../../../../shared/components/formFields/ReduxForm/TextArea';
import createOtherDetailValidator from './validator/validateOtherDetails';

const RefinanceAndDebtConsolidationEditorContent = React.memo((props) => {
  const { refinanceAndDebtConsolidation: { applicable, reasons, otherDetail } } = props;
  const valueRender = useMemo(() => (value) => (value?.length > 1
    ? 'Multiple'
    : find(REFINANCE_REASON_OPTION, item => item.id === value[0])?.name),
    []);

  return (
    <>
      <ReduxFormRadioGroup
        options={REFINANCE_APPLICABLE_OPTIONS}
        label="Refinance and debt consolidation"
        {...applicable}
        onChange={({ target }) => {
          applicable.input.onChange(target.value === 'true');
          if (target.value !== 'true') {
            reasons.input.onChange(null);
            otherDetail.input.onChange('');
          }
        }}
        className={styles.radioGroup}
      />
      {applicable.input.value && (
        <ReduxFormCheckboxDropdown
          label="Reason of importance"
          className={styles.multipleSelect}
          inputProps={{ className: styles.input }}
          {...reasons}
          onChange={(v) => {
            reasons.input.onChange(v);
            if (!containOther(v)) {
              otherDetail.input.onChange('');
            }
          }}
          options={REFINANCE_REASON_OPTION}
          valueRender={valueRender}
          readOnlyFormatter={value => {
            const values = map(value, (id => find(REFINANCE_REASON_OPTION, { id })));
            return (
              <div className={styles.readOnlyOptions}>
                {values.map(({ name, id }) => (
                  <div key={id}>{name}</div>
                ))}
              </div>
            );
          }}
          buttonText="Select"
        />
      )}
      {containOther(reasons.input.value) && (
        <ReduxFormTextArea
          isRequired
          label="Other detail"
          rows="1"
          className={styles.otherDetail}
          {...otherDetail}
        />
      )}
    </>
  );
});

const fieldPropTypes = {
  input: PropTypes.shape({
    ...fieldInputPropTypes,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

const fieldPropShape = PropTypes.shape(fieldPropTypes);

RefinanceAndDebtConsolidationEditorContent.propTypes = {
  refinanceAndDebtConsolidation: PropTypes.shape({
    applicable: fieldPropShape,
    reasons: fieldPropShape,
    otherDetail: fieldPropShape,
  }).isRequired,
};

const RefinanceAndDebtConsolidation = () => {
  const namePrefix = 'refinanceAndDebtConsolidation';
  const names = useMemo(() => ([
    `${namePrefix}.applicable`,
    `${namePrefix}.reasons`,
    `${namePrefix}.otherDetail`,
  ]), [namePrefix]);

  const validator = useMemo(() => createOtherDetailValidator({
    selectField: `${namePrefix}.reasons`,
    otherDetailField: `${namePrefix}.otherDetail`,
  }), [namePrefix]);
  return (
    <InformationSection title="Refinance and debt consolidation">
      <div className={styles.commonSection}>
        <span>Capture how important the following features are to your client/s</span>
        <div className={styles.content}>
          <div className={styles.item}>
            <Fields
              names={names}
              namePrefix={namePrefix}
              component={RefinanceAndDebtConsolidationEditorContent}
              validate={validator}
            />
          </div>
        </div>
      </div>
    </InformationSection>
  );
};

export default RefinanceAndDebtConsolidation;
