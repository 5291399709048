export const applicationStatusKeys = {
  PRE_SUBMISSION: 'PRE_SUBMISSION',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  CONDITIONALLY_APPROVED: 'CONDITIONALLY_APPROVED',
  UNCONDITIONALLY_APPROVED: 'UNCONDITIONALLY_APPROVED',
  SETTLED: 'SETTLED',
  DECLINED_OR_WITHDRAWN: 'DECLINED_OR_WITHDRAWN',
};

export const applicationStatus = {
  PRE_SUBMISSION: 'PRE_SUBMISSION',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  CONDITIONALLY_APPROVED: 'CONDITIONALLY_APPROVED',
  UNCONDITIONALLY_APPROVED: 'UNCONDITIONALLY_APPROVED',
  DECLINED: 'DECLINED',
  SETTLED: 'SETTLED',
  WITHDRAWN: 'WITHDRAWN',
};

export const applicationDisplayStatus = {
  [applicationStatusKeys.PRE_SUBMISSION]: 'Pre-submission',
  [applicationStatusKeys.DRAFT]: 'Draft',
  [applicationStatusKeys.SUBMITTED]: 'Submitted',
  [applicationStatusKeys.CONDITIONALLY_APPROVED]: 'Conditionally approved',
  [applicationStatusKeys.UNCONDITIONALLY_APPROVED]: 'Unconditionally approved',
  [applicationStatusKeys.SETTLED]: 'Settled',
  [applicationStatusKeys.DECLINED_OR_WITHDRAWN]: 'Declined or withdrawn',
};

export const statusNameMapper = {
  [applicationStatus.PRE_SUBMISSION]: applicationDisplayStatus.PRE_SUBMISSION,
  [applicationStatus.DRAFT]: applicationDisplayStatus.DRAFT,
  [applicationStatus.SUBMITTED]: applicationDisplayStatus.SUBMITTED,
  [applicationStatus.CONDITIONALLY_APPROVED]: applicationDisplayStatus.CONDITIONALLY_APPROVED,
  [applicationStatus.UNCONDITIONALLY_APPROVED]: applicationDisplayStatus.UNCONDITIONALLY_APPROVED,
  [applicationStatus.DECLINED]: applicationDisplayStatus.DECLINED_OR_WITHDRAWN,
  [applicationStatus.WITHDRAWN]: applicationDisplayStatus.DECLINED_OR_WITHDRAWN,
  [applicationStatus.SETTLED]: applicationDisplayStatus.SETTLED,
};

export const essentialDateDisplayStatus = {
  PRE_SUBMISSION: 'Pre-submission',
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  CONDITIONALLY_APPROVED: 'Conditionally approved',
  UNCONDITIONALLY_APPROVED: 'Unconditionally approved',
  DECLINED: 'Declined',
  WITHDRAWN: 'Withdrawn',
  SETTLED: 'Settled',
};

export const applicationDueDates = {
  PRE_APPROVAL_EXPIRY: 'Pre approval expiry',
  FINANCE_DUE: 'Finance due',
  EXPECTED_SETTLEMENT: 'Expected settlement',
};

export const withdrawnReason = {
  CHANGED_MIND: 'Changed mind',
  BRIDGING_LOAN: 'Bridging loan',
  LENDER_TOO_SLOW: 'Lender too slow',
  LENDER_TOO_FUSSY: 'Lender too fussy',
  BETTER_DEAL_ELSEWHERE: 'Better deal elsewhere',
  PURCHASE_FELL_THROUGH: 'Purchase fell through',
  FAILED_AT_AUCTION: 'Failed at auction',
  WITHDRAWN_BY_GROUP_OFFICE: 'Withdrawn by group office',
  VALUATION_SHORTFALL: 'Valuation shortfall',
  INCORRECT_SECURITY_TYPE: 'Incorrect security type',
  ALTERNATIVE_COPY_SUBMITTED: 'Alternative copy submitted',
  OTHER: 'Other',
};

export const declinedReason = {
  SERVICEABILITY: 'Serviceability',
  LVR: 'LVR',
  STABILITY: 'Stability',
  CREDIT_REPORT_ISSUE: 'Credit Report issue',
  VALUATION: 'Valuation',
  OTHER: 'Other',
};

export const reasonMap = {
  WITHDRAWN: withdrawnReason,
  DECLINED: declinedReason,
};
