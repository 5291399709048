import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { arraySplice, change, fieldArrayFieldsPropTypes } from 'redux-form';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import styles from './styles.module.scss';
import LoanCriteria from './LoanCriteria';
import ApplicationCriteria from './ApplicationCriteria';
import {
  getApplicationType,
  getCalculatedLVR,
  getCalculatedTotalCombinedLoanAmount,
  getCalculatedTotalLoanAmount,
  getSelectedLenderNames,
  initialLoanCriteria,
} from '../../../redux/selectors/productSearch';
import { syncLVR } from '../redux/actions';

const LoansCriteria = ({ fields, formName }) => {
  const isSplit = fields.length > 1;
  const addLoanCriteria = fields.push;
  const removeLoanCriteria = fields.remove;
  const handleAddLoanCriteria = useCallback(() => {
    addLoanCriteria(initialLoanCriteria);
  }, [addLoanCriteria]);
  const handleRemoveLoanCriteria = useCallback((index) => {
    removeLoanCriteria(index);
  }, [removeLoanCriteria]);

  const applicationType = useSelector(getApplicationType);
  const calculatedLVR = useSelector(getCalculatedLVR);
  const calculatedTotalLoanAmount = useSelector(getCalculatedTotalLoanAmount);
  const calculatedTotalCombinedLoanAmount = useSelector(getCalculatedTotalCombinedLoanAmount);
  const selectedLenderNames = useSelector(getSelectedLenderNames);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncLVR(calculatedLVR));
  }, [calculatedLVR, dispatch]);

  useEffect(() => {
    if (applicationType === APPLICATION_TYPES.TOP_UP && selectedLenderNames.length > 1) {
      dispatch(arraySplice(
        formName,
        'applicationCriteria.selectedLenderNames',
        1,
        selectedLenderNames.length - 1,
      ));
    }
  }, [applicationType, dispatch, formName, selectedLenderNames]);

  useEffect(() => {
    dispatch(change(formName, 'applicationCriteria.totalLoanAmount', calculatedTotalLoanAmount));
  }, [calculatedTotalLoanAmount, dispatch, formName]);

  useEffect(() => {
    dispatch(change(formName, 'applicationCriteria.totalCombinedLoanAmount', calculatedTotalCombinedLoanAmount));
  }, [calculatedTotalCombinedLoanAmount, dispatch, formName]);

  return (
    <div className={styles.criteriaSection}>
      {fields.map((field, index) => (
        <LoanCriteria
          key={field}
          index={index}
          isSplit={isSplit}
          namePrefix={field}
          formName={formName}
          onRemove={handleRemoveLoanCriteria}
        />
      ))}
      { isSplit && <ApplicationCriteria loanCriteriaFields={fields} formName={formName} /> }
      <div className={styles.buttonContainer}>
        <SecondaryButton
          data-test-id="loanSplitButton"
          onClick={handleAddLoanCriteria}
        >
          Add a loan split
        </SecondaryButton>
      </div>
    </div>
  );
};

LoansCriteria.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  formName: PropTypes.string.isRequired,
};

export default LoansCriteria;
