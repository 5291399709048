import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductsTableComponent from './ProductsTable';
import {
  updateOptionsToComparison,
} from '../redux/comparison/comparison';
import { OptionShape } from '../../shapes';
import { getComparisonDetails } from '../redux/comparison';
import { onRepaymentFrequencyChange, onTotalCostTermChange } from '../redux/products/products';

const StandAloneProductsTable = ({
                                   products,
                                   selectedOptions,
                                   dispatchUpdateOptionsToComparison,
                                   dispatchOnTotalCostTermChange,
                                   dispatchRepaymentFrequencyChange,
                                   dispatchGetComparisonDetails,
                                 }) => {
  const context = {
    products,
    selectedOptions,
    comparisonRoute: '/products/comparison',
    dispatchUpdateOptionsToComparison,
    dispatchOnTotalCostTermChange,
    dispatchRepaymentFrequencyChange,
    dispatchGetComparisonDetails,
  };
  return <ProductsTableComponent context={context} />;
};
StandAloneProductsTable.defaultProps = {
  products: null,
};

StandAloneProductsTable.propTypes = {
  products: PropTypes.shape({
    products: PropTypes.arrayOf(OptionShape),
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    searchPerformed: PropTypes.bool,
    repaymentFrequency: PropTypes.string.isRequired,
  }),
  selectedOptions: PropTypes.arrayOf(OptionShape).isRequired,
  dispatchUpdateOptionsToComparison: PropTypes.func.isRequired,
  dispatchOnTotalCostTermChange: PropTypes.func.isRequired,
  dispatchRepaymentFrequencyChange: PropTypes.func.isRequired,
  dispatchGetComparisonDetails: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: state.productSearch.standAlone.products,
  selectedOptions: state.productSearch.standAlone.comparison.selectedOptions,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateOptionsToComparison: options => dispatch(updateOptionsToComparison(options)),
  dispatchGetComparisonDetails: () => dispatch(getComparisonDetails()),
  dispatchOnTotalCostTermChange: years => dispatch(onTotalCostTermChange(years)),
  dispatchRepaymentFrequencyChange:
    (selectedFrequency) => dispatch(onRepaymentFrequencyChange(selectedFrequency)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandAloneProductsTable);
