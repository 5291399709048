import React from 'react';
import usePageViewTracking from '../pages/usePageViewTracking';
import ApplicationsComponent from './components/Applications/Applications';

const ApplicationTracking = () => {
  usePageViewTracking('applicationTracking');

  return (
    <ApplicationsComponent />
  );
};

export default ApplicationTracking;
