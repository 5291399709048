import React from 'react';
import PropTypes from 'prop-types';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import FinancialSummaryField, { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import { connect } from 'react-redux';
import styles from './FinancialSummary.module.scss';
import { formatAmountWithDefault } from '../../../../../../utils/formatters';
import { getFinancialSummaryInfo } from '../../../redux/selectors';

const FinancialSummary = ({ financialSummaryInfo }) => {
  const {
    totalAnnualIncome, totalOwnedAssets, totalSecurityValue, totalLiabilities, totalMonthlyLivingExpenses,
  } = financialSummaryInfo;
  return (
    <InformationSection contentWrapperStyle={styles.summaryContentWrapper}>
      <FinancialSummaryField
        title="Total annual income"
        className={styles.summaryFieldWrapper}
        sideBarColour={FIELD_SIDE_BAR_COLORS.Ocean}
        content={formatAmountWithDefault(totalAnnualIncome)}
      />
      <FinancialSummaryField
        title="Total owned assets"
        className={styles.summaryFieldWrapper}
        sideBarColour={FIELD_SIDE_BAR_COLORS.Ocean}
        content={formatAmountWithDefault(totalOwnedAssets)}
      />
      <FinancialSummaryField
        title="Total security value"
        className={styles.summaryFieldWrapper}
        sideBarColour={FIELD_SIDE_BAR_COLORS.Ocean}
        content={formatAmountWithDefault(totalSecurityValue)}
      />
      <FinancialSummaryField
        title="Total liabilities"
        className={styles.summaryFieldWrapper}
        sideBarColour={FIELD_SIDE_BAR_COLORS.Ocean}
        content={formatAmountWithDefault(totalLiabilities)}
      />
      <FinancialSummaryField
        title="Total monthly household expenses"
        className={styles.summaryFieldWrapper}
        sideBarColour={FIELD_SIDE_BAR_COLORS.Ocean}
        content={formatAmountWithDefault(totalMonthlyLivingExpenses)}
      />
    </InformationSection>
  );
};

FinancialSummary.propTypes = {
  financialSummaryInfo: PropTypes.object.isRequired,
};

export default connect(
  state => ({
      financialSummaryInfo: getFinancialSummaryInfo(state),
    }
  ),
)(FinancialSummary);
