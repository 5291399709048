import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Field, fieldArrayPropTypes } from 'redux-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Company.module.scss';
import ReduxFormSelect from '../../../../../../../shared/components/formFields/ReduxForm/Select';
import { AddRemoveButtonGroup } from './AddRemoveButtonGroup';
import { formatPersonNameValue } from '../utils/formatter';

const DirectorsOrTrustees = ({
                         fields, hasEditPermission, persons, label,
                       }) => {
  const onRemove = useMemo(() => (i) => fields.remove(i), [fields]);
  const onAdd = useMemo(() => () => fields.push({}), [fields]);
  const selectedIds = useMemo(() => fields.getAll().map(field => field.relatedApplicantId), [fields]);
  const options = useMemo(() => persons
    .filter(({ id }) => !isEmpty(id))
    .map(({ id, name }) => ({
      label: name,
      value: id,
      isDisabled: selectedIds.includes(id),
    })), [persons, selectedIds]);

  return (
    <div className={styles.directorOrTrustee}>
      {fields.map((fieldNamePrefix, index) => {
        const nameValue = formatPersonNameValue(fields.get(index));
        return (
          <DirectorOrTrustee
            key={fieldNamePrefix}
            fieldNamePrefix={fieldNamePrefix}
            hasEditPermission={hasEditPermission}
            onRemove={() => onRemove(index)}
            onAdd={onAdd}
            options={options}
            shouldAdd={index === fields.length - 1}
            disableAdd={persons.length <= selectedIds.length}
            showRemove={fields.length !== 1}
            label={label}
            nameValue={nameValue}
          />
        );
      })}
    </div>
  );
};

const DirectorOrTrustee = ({
     fieldNamePrefix, onRemove, onAdd, hasEditPermission, options, label, shouldAdd, disableAdd, showRemove,
}) => (
  <>
    <Field
      name={`${fieldNamePrefix}.relatedApplicantId`}
      component={ReduxFormSelect}
      label={label}
      className={classNames(
        [styles.fieldWrapper, styles.input, styles.atFirstColumnOnDesktop],
      )}
      options={options}
    />
    {hasEditPermission && (
      <AddRemoveButtonGroup
        addFunc={onAdd}
        removeFunc={onRemove}
        showAdd={shouldAdd}
        disableAdd={disableAdd}
        showRemove={showRemove}
        className={classNames(
          styles.singleColumnWrapperOnDesktop, styles.atThirdColumnOnDesktop,
        )}
      />
    )}
  </>
  );

DirectorOrTrustee.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  shouldAdd: PropTypes.bool.isRequired,
  showRemove: PropTypes.bool.isRequired,
  disableAdd: PropTypes.bool.isRequired,
};

DirectorsOrTrustees.propTypes = {
  ...fieldArrayPropTypes,
};

export default DirectorsOrTrustees;
