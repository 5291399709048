import React from 'react';
import PropTypes from 'prop-types';
import Arrow from 'shared/components/Arrow';
import shortid from 'shortid';
import styles from './ArrowArray.module.scss';

const ArrowArray = (props) => {
  const {
 items, className, arrowColor, gap, height, arrowAngle, direction,
} = props;
  const reverseDirection = direction !== 'ltr';
  const arrowEndsWidth = `${height} / (2 * ${Math.tan((arrowAngle * Math.PI) / 360)})`;
  return (
    <div className={`${styles.arrowArray} ${className}`}>
      {items.map(({ label, backgroundColor, ...others }, index) => (
        <Arrow
          key={shortid.generate()}
          className={`${styles.arrow} arrow-array-item`}
          backgroundColor={backgroundColor || arrowColor}
          right={index === items.length - 1 ? '0' : `calc(${gap} - ${arrowEndsWidth})`}
          noHead={index === (reverseDirection ? 0 : items.length - 1)}
          noTail={index === (reverseDirection ? items.length - 1 : 0)}
          height={height}
          direction={direction}
          angle={arrowAngle}
          {...others}
        >
          {label}
        </Arrow>
))}
    </div>
);
};

ArrowArray.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
  })).isRequired,
  height: PropTypes.string,
  className: PropTypes.string,
  gap: PropTypes.string,
  arrowColor: PropTypes.string,
  arrowAngle: PropTypes.number,
  direction: PropTypes.string,
};

ArrowArray.defaultProps = {
  height: '2.5rem',
  className: '',
  gap: '0.5rem',
  arrowColor: 'white',
  arrowAngle: 90,
  direction: 'ltr',
};
export default ArrowArray;
