import React, { useMemo } from 'react';
import { fieldArrayPropTypes } from 'redux-form';
import LoanProduct from './LoanProduct';

const LoanProductsSection = ({ fields }) => useMemo(() => (
  <>
    {fields.map((name, index) => (
      <LoanProduct
        key={`${name}`}
        formNum={index + 1}
        formPrefix={`${name}`}
        isSplit={fields.length > 1}
      />
    ))}
  </>
  ), [fields]);

LoanProductsSection.propTypes = {
  ...fieldArrayPropTypes,
};

export default LoanProductsSection;
