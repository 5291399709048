import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ClickableText = ({ text, onClick, className }) => {
  const [focus, setFocus] = useState(false);
  return (
    <span
      role="button"
      tabIndex={0}
      className={classNames(className, focus ? 'focusedFormField' : '')}
      onClick={onClick}
      onKeyDown={event => (event.key && event.key === 'Enter' ? onClick() : null)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      {text}
    </span>
);
};

ClickableText.defaultProps = {
  className: undefined,
};

ClickableText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ClickableText;
