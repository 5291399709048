import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';

const validateTotalSecurityValue = ({
 applicationType, totalLoanAmount, totalCombinedLoanAmount, securityValue,
}) => {
  if (applicationType === APPLICATION_TYPES.NEW_LOAN) {
    if (isNumber(totalLoanAmount) && isNumber(securityValue) && totalLoanAmount > securityValue) {
      return 'Total security value needs to be greater than loan amount.';
    }
  } else if (isNumber(totalCombinedLoanAmount) && isNumber(securityValue) && totalCombinedLoanAmount > securityValue) {
      return 'Total security value needs to be greater than combined loan amount.';
    }
  return undefined;
};

const validateSelectedLenderNamesError = ({ applicationType, selectedLenderNames }) =>
  (applicationType === APPLICATION_TYPES.TOP_UP && isEmpty(selectedLenderNames)
    ? ['Required']
    : undefined);

const validateSearchCriteria = ({ applicationCriteria = {}, loansCriteria = [] }) => {
  const { applicationType } = applicationCriteria;
  const selectedLenderNamesError = validateSelectedLenderNamesError(applicationCriteria);
  const securityValueError = validateTotalSecurityValue(applicationCriteria);

  const loansCriteriaErrors = loansCriteria.map(({
    loanAmount, fixedLoanTerm, loanTerm, interestOnlyTerm, combinedLoanAmount,
  }) => {
    const fixedLoanTermError = isNumber(fixedLoanTerm) && isNumber(loanTerm) && fixedLoanTerm > loanTerm
      ? 'Fixed loan term needs to be less than or equal to loan term.'
      : undefined;

    const interestOnlyTermError = isNumber(interestOnlyTerm) && isNumber(loanTerm) && interestOnlyTerm > loanTerm
      ? 'Interest only term needs to be less than or equal to loan term.'
      : undefined;

    const loanTermError = isNumber(loanTerm)
      ? undefined
      : 'Loan term is required';

    const loanAmountError = applicationType === APPLICATION_TYPES.NEW_LOAN
      && loansCriteria.length > 1
      && !isNumber(loanAmount)
      ? 'Loan amount is required'
      : undefined;

    const combinedLoanAmountError = applicationType === APPLICATION_TYPES.TOP_UP
      && loansCriteria.length > 1
      && !isNumber(combinedLoanAmount)
      ? 'Combined loan amount is required'
      : undefined;

    return {
      fixedLoanTerm: fixedLoanTermError,
      interestOnlyTerm: interestOnlyTermError,
      loanTerm: loanTermError,
      loanAmount: loanAmountError,
      combinedLoanAmount: combinedLoanAmountError,
    };
  });
  return {
    applicationCriteria: {
      securityValue: securityValueError,
      selectedLenderNames: selectedLenderNamesError,
    },
    loansCriteria: loansCriteriaErrors,
  };
};

export default validateSearchCriteria;
