import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '../../RadioGroup';
import FieldsInputPropShapes from '../FieldsInputPropShapes';

const ReduxFormRadioGroup = props => {
  const { input, ...others } = props;
  return (
    <RadioGroup
      {...input}
      {...others}
    />
  );
};

ReduxFormRadioGroup.propTypes = {
  input: PropTypes.shape(FieldsInputPropShapes).isRequired,
};

export default ReduxFormRadioGroup;
