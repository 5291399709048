import React, { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { confirmLeaveMessage } from '../../constants/message';

export const useConfirmBeforeUnload = (shouldConfirm) => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (shouldConfirm) {
        e.returnValue = shouldConfirm;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldConfirm]);

  const prompt = useMemo(() => (
    <Prompt
      when={shouldConfirm}
      message={confirmLeaveMessage}
    />
  ), [shouldConfirm]);

  const confirmWhenDirty = useMemo(() => (message = confirmLeaveMessage) => {
    if (shouldConfirm) {
      /* eslint-disable-next-line  no-alert */
      return window.confirm(message);
    }
    return true;
  }, [shouldConfirm]);

  return {
    prompt,
    confirmWhenDirty,
  };
};
