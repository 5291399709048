export const ACTIONS = {
  SET_LOAN_AMOUNT: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_LOAN_AMOUNT',
  ADD_LOAN_CRITERIA: 'LOAN_CRITERIA_LIST:STAND_ALONE:ADD_LOAN_CRITERIA',
  SET_PURPOSE: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_PURPOSE',
  SET_PRODUCT_TYPE: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_PRODUCT_TYPE',
  REMOVE_LOAN_CRITERIA: 'LOAN_CRITERIA_LIST:STAND_ALONE:REMOVE_LOAN_CRITERIA',
  SET_REPAYMENT_TYPE: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_REPAYMENT_TYPE',
  SET_LOAN_TERM: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_LOAN_TERM',
  ENSURE_LOAN_TERMS: 'LOAN_CRITERIA_LIST:STAND_ALONE:ENSURE_LOAN_TERMS',
  SET_FIXED_LOAN_TERM: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_FIXED_LOAN_TERM',
  SET_INTEREST_ONLY_TERM: 'LOAN_CRITERIA_LIST:STAND_ALONE:SET_INTEREST_ONLY_TERM',
  VALIDATE_FIXED_LOAN_TERM: 'LOAN_CRITERIA_LIST:STAND_ALONE:VALIDATE_FIXED_LOAN_TERM',
  VALIDATE_INTEREST_ONLY_TERM: 'LOAN_CRITERIA_LIST:STAND_ALONE:VALIDATE_INTEREST_ONLY_TERM',
  UPDATE_SELECTED_FEATURES_LIST: 'LOAN_CRITERIA_LIST:STAND_ALONE:UPDATE_SELECTED_FEATURES_LIST',
  TOGGLE_SELECT_FEATURE: 'LOAN_CRITERIA_LIST:STAND_ALONE:TOGGLE_SELECT_FEATURE',
};
