import React from 'react';
import { connect } from 'react-redux';
import {
  Field, FieldArray,
  formPropTypes, reduxForm,
} from 'redux-form';
import concat from 'lodash/concat';
import PropTypes from 'prop-types';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import get from 'lodash/get';
import styles from './Expenses.module.scss';
import { updateExpenseInfo } from '../../redux';
import { getExpenseInfoInitialValues } from '../../redux/selectors';
import HouseholdExpenses from './HouseholdExpenses';
import FinancialSummary from '../shared/FinancialSummary';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { FETCHING_STATUS } from '../../../../../constants';

const Expenses = React.memo(({
 pristine, handleSubmit, submitting, hasEditPermission, applicants, invalid, isFetching,
}) => (
  <>
    <FinancialSummary />
    <FormMetaProvider readOnly={!hasEditPermission}>
      <form onSubmit={handleSubmit}>
        <div>
          <FieldArray
            name="householdExpenses"
            component={HouseholdExpenses}
            hasEditPermission={hasEditPermission}
            applicants={applicants}
          />
        </div>
        <div className={styles.wrapper}>
          <Field
            name="expenseNote"
            component={ReduxFormTextArea}
            disabled={!hasEditPermission}
            placeholder="Enter any explanation of discrepancies. This text will be used in the SOMA."
            rows="5"
            className={styles.textAreaWrapper}
            label="Expenses comments"
          />
        </div>
        {hasEditPermission
          && (
            <StickyFooter
              disabled={invalid || pristine || submitting || isFetching}
              type="submit"
            />
        )}
      </form>
      <LoadingWithOverlay isLoading={submitting || isFetching} />
      <UnsavedChangePrompt shouldConfirm={!pristine} />
    </FormMetaProvider>
  </>
));

Expenses.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state => ({
      initialValues: getExpenseInfoInitialValues(state),
      hasEditPermission: hasEditPermissionSelector(state),
      applicants: concat(state?.application?.applicationDetail?.applicants?.persons ?? [],
        state?.application?.applicationDetail?.guarantors?.persons ?? []),
      isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START,
    }
  ),
)(reduxForm({
  form: 'expensesForm',
  onSubmit: (values, dispatch) => dispatch(updateExpenseInfo(values)),
  enableReinitialize: true,
})(Expenses));
