import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { status, employmentTypes } from '../../../ApplicationDetailContentNav/IncomeAndEmployment/EmploymentForm/constants';

const employmentStatusOrder = {
  [status.Primary]: 1,
  [status.Secondary]: 2,
  [status.Previous]: 3,
};

const getEmploymentIncomes = (employment) => {
  const isEmptyIncomes = employment.status !== status.Previous && isEmpty(employment.employmentIncomes);
  if (isEmptyIncomes && employment.employmentType === employmentTypes.PAYE) {
    return [{}];
  }
  if (isEmptyIncomes && employment.employmentType === employmentTypes.SELF_EMPLOYED) {
    return [{ periodUnit: 'Yearly' }];
  }
  return employment.employmentIncomes;
};

export const getPersonEmployments = (state, isApplicant) => (get(state,
  `application.applicationDetail.${isApplicant ? 'applicants' : 'guarantors'}`) || {
    persons: [],
  }).persons.map(person => ({
    id: person.id,
    firstName: person.firstName,
    surname: person.surname,
    employments: person.employments?.map(employment => ({
        ...employment,
        employmentIncomes: getEmploymentIncomes(employment),
      })) || [],
    employmentsOrder: person.employments
      ?.sort((a, b) => employmentStatusOrder[a.status] - employmentStatusOrder[b.status])
      .map(employment => person.employments.indexOf(employment)) || [],
  }));

export const getCompanies = (state, isApplicant) => (get(state,
  `application.applicationDetail.${isApplicant ? 'applicants' : 'guarantors'}`) || {
    companies: [],
  }).companies
  .map(company => ({
    id: company.id,
    name: company.name,
    financialYear: get(company, 'companyIncomeInfo.financialYear'),
    incomes: company?.companyIncomeInfo?.incomes?.map(income => ({
      ...income,
      value: get(income, 'incomeValues[0]'),
      previousValue: get(income, 'incomeValues[1]'),
    })) || [],
  }));
