import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TertiaryButton from '../../Buttons/TertiaryButton';
import styles from './styles.module.scss';
import { formatAmountWithDefault } from '../../../../utils/formatters';

const ClickableValue = ({ onClick, value, disabled }) => (
  <TertiaryButton
    className={classNames(styles.value, { [styles.disabled]: disabled })}
    onClick={onClick}
    disabled={disabled}
  >
    {formatAmountWithDefault(value)}
  </TertiaryButton>
);

ClickableValue.defaultProps = {
  disabled: false,
  value: undefined,
};

ClickableValue.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ClickableValue;
