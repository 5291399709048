import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { TRANSACTION_TYPES } from '../../constants';
import { getAllApplicants, getGuarantors } from '../applicants';
import { formatItemsOwnerships } from '../utils';
import { getCombinedApplicants, getCombinedGuarantors } from '../../../../../utils';

export const getAssets = createSelector(
  (state) => get(state, 'application.applicationDetail.finance.assets'),
  getAllApplicants,
  getGuarantors,
  (assets, applicants, guarantors) => {
    const allApplicants = [...getCombinedApplicants(applicants), ...getCombinedGuarantors(guarantors)];
    return {
      realEstates: formatItemsOwnerships(assets?.realEstates ?? [], allApplicants),
      nonRealEstates: formatItemsOwnerships(assets?.nonRealEstates ?? [], allApplicants),
    };
  },
);

export const getAssetsById = createSelector(
  getAssets,
  (assets) => keyBy(assets?.realEstates ?? [], 'id'),
);

export const getAssetsInitialValues = createSelector(
  getAssets,
  assets => ({
    realEstates: assets?.realEstates?.filter(item => item.transactionType === TRANSACTION_TYPES.OWNS),
    nonRealEstates: assets?.nonRealEstates,
  }),
);
