import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const ArrowLeft = ({
 color, width, height, alt, style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <title>{alt}</title>

    <g
      id="Icon/arrowLeft"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path"
        fill={color}
        transform="translate(11.889000, 12.000000) rotate(-270.000000) translate(-11.889000, -12.000000) "
        points="11.889 13.061 16.839 8.111 18.253 9.525 11.889 15.889 5.525 9.525 6.939 8.111"
      />
    </g>
  </svg>
);

ArrowLeft.defaultProps = iconDefaultProps;
ArrowLeft.propTypes = baseIcon;

export default ArrowLeft;
