import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isString from 'lodash/isString';
import values from 'lodash/values';
import filter from 'lodash/filter';
import {
  formatStringBool, formatYearAndMonth, toDollarAmountWithDecimal, toRatePercentage,
} from 'shared/formatterUtils';
import { convertDecimalYearToIntegerYearAndMonth } from 'shared/utils';
import { ABS_LENDING_CODE, PRODUCT_TYPES } from '../../../../../../../constants';
import { formatCurrency } from '../../../../../../../utils/formatters';
import { getBasicLogInfo } from './utils';

const logType = 'PRODUCT COMPARISON';

const overrideFields = [{
  displayName: 'Application fee/rebate',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.applicationFee$/i,
  formatter: toDollarAmountWithDecimal,
}, {
  displayName: 'Legal fee',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.legalFee$/i,
  formatter: toDollarAmountWithDecimal,
}, {
  displayName: 'Valuation fee',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.valuationFee$/i,
  formatter: toDollarAmountWithDecimal,
}, {
  displayName: 'Ongoing fee',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.ongoingFee$/i,
  formatter: toDollarAmountWithDecimal,
}, {
  displayName: 'LMI',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.lmi$/i,
  formatter: toDollarAmountWithDecimal,
}, {
  displayName: 'Standard interest rate',
  path: /^productComparison\.variantsCombinations\[(\d+)]\.variants\[(\d+)]\.standardRate$/i,
  formatter: toRatePercentage,
}];

const getOverrideUpdateText = ({ change, field }) => `${field.displayName} - ${field.formatter(change.newValue)}`;

const getOverrideKey = ({ change, field }) => {
  const match = change.path.match(field.path);
  const [, combination, variant] = match;
  return [combination, variant];
};

const getOptionName = (index) => String.fromCharCode(+index + 65);

const getOverrideFieldsLogs = (origin, changes) => {
  const overrideChanges = changes
  .map(change => ({
    change,
    field: overrideFields.find(f => f.path.test(change.path)),
  }))
  .filter(e => e.field);

  if (isEmpty(overrideChanges)) {
    return [];
  }

  const keyedChanges = overrideChanges.map((e) => ({
    ...e,
    key: getOverrideKey(e),
  }));

  const basicLogInfo = getBasicLogInfo(origin);

  return orderBy(Object.values(groupBy(keyedChanges, 'key')), (e) => e[0].key, ['asc'])
  .map(e => {
    const { key } = e[0];
    return {
      ...basicLogInfo,
      type: logType,
      content: `Product comparison option ${getOptionName(+key[0])} loan ${+key[1] + 1} updated`,
      subContent: sortBy(e.map(getOverrideUpdateText)),
    };
  });
};

const getCommentLog = (origin, changes) => {
  const commentPath = /^productComparison\.comment$/i;
  const comment = changes.find(c => commentPath.test(c.path));
  if (comment === undefined) {
    return null;
  }
  return ({
    ...getBasicLogInfo(origin),
    type: logType,
    content: 'Comments',
    subContent: 'updated',
    text: comment.newValue,
  });
};

const getPreferredOptionLog = (origin, changes) => {
  const preferredOptionPath = /^productComparison\.variantsCombinations\[(\d+)]\.preferred$/i;
  const changed = changes.filter(c => preferredOptionPath.test(c.path));

  if (isEmpty(changed)) {
    return null;
  }

  const selected = changed.find(c => c.newValue === 'true');
  if (!selected) {
    return null;
  }

  const [, index] = selected.path.match(preferredOptionPath);
  const subContent = `Selected option - ${getOptionName(index)}`;

  return {
    ...getBasicLogInfo(origin),
    type: logType,
    content: 'Selected option updated',
    subContent,
  };
};

const getLenderChangeLog = (origin, changes) => {
  const change = changes.find(c => c.path === 'lender.name');
  if (!change) {
    return null;
  }

  return {
    ...getBasicLogInfo(origin),
    type: 'LOAN DETAILS',
    content: 'Lender updated',
    subContent: `Lender - ${change.newValue}`,
  };
};

const formatYears = value => (Number(value) === 1 ? `${value} year` : `${value} years`);
const formatRate = value => `${value}%`;

const getLoanRequestFieldMap = (productType) => ({
  amount: {
    name: 'Loan amount',
    format: value => formatCurrency(Number(value)),
  },
  combinedLoanAmount: {
    name: 'Combined loan amount',
    format: value => formatCurrency(Number(value)),
  },
  isTopUp: {
    name: 'Top up',
    format: formatStringBool,
  },
  repaymentType: 'Repayment type',
  product: {
    interestOnlyTerm: {
      name: 'Interest only term',
      format: formatYears,
    },
    fixedTerm: {
      name: 'Fixed term',
      format: formatYears,
    },
    standardRate: {
      name: 'Standard rate',
      format: formatRate,
    },
    discountRate: {
      name: 'Introductory interest rate',
      format: formatRate,
    },
    baseRate: {
      name: productType === PRODUCT_TYPES.fixed ? 'Fixed rate' : 'Base rate',
      format: formatRate,
    },
    productType: 'Product type',
    purpose: 'Purpose',
    name: 'Product name',
  },
  term: {
    name: 'Loan term',
    format: (value) => formatYearAndMonth(
      {
        ...convertDecimalYearToIntegerYearAndMonth(value),
        fullSuffix: true,
      },
    ),
  },
  purposeCategory: 'Purpose category',
  loanType: 'Loan type',
  absLendingPurposeCode: {
    name: 'Purpose details',
    format: value => ABS_LENDING_CODE[value],
  },
  refinanceReason: 'Refinance reason',
});

const loanPropertyMatcher = /^loans\[([0-9a-fA-F-]+)]\.(.+)$/;

const getLoanPropertyInfo = ({ path, newValue }) => {
  const matchedGroups = path.match(loanPropertyMatcher);
  return ({
    loanId: matchedGroups[1],
    path: matchedGroups[2],
    value: newValue,
  });
};

const getLoanRequestSubContent = (infos) => {
  const productType = get(find(infos, { path: 'product.productType' }), 'value');
  return infos
  .map(({ path, value }) => ({
    path,
    value,
    fieldMeta: get(getLoanRequestFieldMap(productType), path),
  }))
  .filter(info => info.fieldMeta)
  .map(({ value, fieldMeta }) =>
    (isString(fieldMeta)
      ? `${fieldMeta} - ${value}`
      : `${fieldMeta.name} - ${fieldMeta.format(value)}`));
};

const getLoanRequestsChangeLogs = (origin, changes) => {
  const changed = changes.filter(c => loanPropertyMatcher.test(c.path));
  if (isEmpty(changed)) {
    return [];
  }

  const loanPropertyInfos = changed.map(getLoanPropertyInfo);

  const groupedLoanPropertyInfos = groupBy(loanPropertyInfos, 'loanId');

  const updatedLoanPropertyInfos = values(groupedLoanPropertyInfos)
  .filter((infos) => infos.find(({ path }) => path === 'id')?.value);

  const orderedPropertyInfosList = sortBy(updatedLoanPropertyInfos,
    (infos) => infos.filter(it => it.path === 'createdAt')
    .map(it => it.value)[0]);

  const basicLogInfo = getBasicLogInfo(origin);
  return orderedPropertyInfosList.map((infos, index) => ({
    ...basicLogInfo,
    type: 'LOAN DETAILS',
    content: `Loan ${index + 1} criteria updated`,
    subContent: sortBy(getLoanRequestSubContent(infos)),
  }));
};

const productComparisonChangeLogTransformer = origin => {
   const changes = filter(
    origin.eventDetail.changes,
    (change) => change.oldValue !== change.newValue,
  );

  return [
    ...getOverrideFieldsLogs(origin, changes),
    getCommentLog(origin, changes),
    getPreferredOptionLog(origin, changes),
    getLenderChangeLog(origin, changes),
    ...getLoanRequestsChangeLogs(origin, changes),
  ].filter(e => !isNil(e));
};

export default productComparisonChangeLogTransformer;
