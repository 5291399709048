import React from 'react';
import PropTypes from 'prop-types';
import Field from 'shared/components/Field/Field';
import styles from './Summary.module.scss';

const Summary = ({ fields }) => (
  <div className={styles.summary}>
    {fields.map((field) => {
      const fieldProps = { ...field, className: `${field.className || ''}` };
      return <Field key={field.title} {...fieldProps} showSideBar={false} />;
    })}
  </div>
);

Summary.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    sideBarClassName: PropTypes.string,
    hidden: PropTypes.bool,
    noDisplay: PropTypes.bool,
    className: PropTypes.string,
  })),
};

Summary.defaultProps = {
  fields: [],
};

export default Summary;
