import { getBasicLogInfo } from './utils';

const basicTaskInfoLogTransformerCreator = ({ title, isLink }) => origin => ({
  ...getBasicLogInfo(origin),
  resourceId: origin.eventDetail.resourceId,
  name: origin.eventDetail.resourceName,
  title,
  isLink,
});

export default basicTaskInfoLogTransformerCreator;
