import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import FormMetaContext from 'shared/components/FormMeta/FormMetaContext';
import Liability from './Liablity';
import styles from './LiabilitiesSection.module.scss';

const LiabilitiesSection = ({ fields, defaultOwnerships }) => {
  const { readOnly } = useContext(FormMetaContext);
  const allNamePrefixes = fields.map(identity);
  return (
    <div>
      {
        fields.map((namePrefix, index) => (
          <Liability
            key={namePrefix}
            namePrefix={namePrefix}
            handleRemove={() => fields.remove(index)}
            allNamePrefixes={allNamePrefixes}
            readOnly={readOnly}
          />
        ))
      }
      {!readOnly && (
        <div className={styles.addButtonWrapper}>
          <SecondaryButton
            onClick={() => fields.push({ ownerships: defaultOwnerships })}
            className={styles.addButton}
          >
            <span>Add a liability</span><Icon name="add" size="18" />
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

LiabilitiesSection.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  defaultOwnerships: PropTypes.array.isRequired,
};

export default LiabilitiesSection;
