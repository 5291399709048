import React from 'react';
import { FieldArray, formPropTypes, reduxForm } from 'redux-form';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import styles from './IncomeAndEmployment.module.scss';
import EmploymentSection from './EmploymentSection';
import { updateEmployments } from '../../../redux';
import { FETCHING_STATUS } from '../../../../../../constants';
import { getCompanies, getPersonEmployments } from '../../../redux/selectors/employments';
import { hasEditPermissionSelector } from '../../../redux/selectors/permissions';
import CompanySection from './companyIncomes/CompanySection';

const EmploymentSections = ({ fields, hasEditPermission, SectionComponent }) => fields.map((field) => (
  <SectionComponent
    key={field}
    fieldNamePrefix={field}
    hasEditPermission={hasEditPermission}
  />
));

const IncomeAndEmployment = ({
 pristine, handleSubmit, hasEditPermission, submitting, valid, isFetching,
}) => (
  <FormMetaProvider readOnly={!hasEditPermission}>
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="persons"
        component={EmploymentSections}
        props={{
            hasEditPermission,
            SectionComponent: EmploymentSection,
          }}
      />
      <>
        <FieldArray
          name="companies"
          component={EmploymentSections}
          props={{
                hasEditPermission,
                SectionComponent: CompanySection,
              }}
        />
        <FieldArray
          name="personGuarantors"
          component={EmploymentSections}
          props={{
                hasEditPermission,
                SectionComponent: EmploymentSection,
              }}
        />
        <FieldArray
          name="companyGuarantors"
          component={EmploymentSections}
          props={{
                hasEditPermission,
                SectionComponent: CompanySection,
              }}
        />
      </>
      <div className={styles.wrapper}>
        {hasEditPermission
          && (
            <PrimaryButton
              className={styles.saveButton}
              disabled={pristine || submitting || !valid || isFetching}
              onClick={handleSubmit}
            >
              Save
            </PrimaryButton>
          )}
      </div>
    </form>
  </FormMetaProvider>
);

IncomeAndEmployment.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state => ({
      initialValues: {
        persons: getPersonEmployments(state, true),
        companies: getCompanies(state, true),
        personGuarantors: getPersonEmployments(state, false),
        companyGuarantors: getCompanies(state, false),
      },
      hasEditPermission: hasEditPermissionSelector(state),
      isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START
        && get(state, 'application.employmentsUpdatingStatus') === FETCHING_STATUS.SUCCESS,
    }
  ),
)(reduxForm({
  form: 'employmentsForm',
  onSubmit: async (values, dispatch, props) => {
    const { reset } = props;
    await dispatch(updateEmployments(values));
    reset();
  },
  enableReinitialize: true,
})(IncomeAndEmployment));
