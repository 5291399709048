import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubTotalSection from '../components/SubTotalSection';
import { calculateTotalPurchaseCosts } from '../../../redux/selectors/fundingWorksheet/utils';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';

export const SubtotalPurchaseCosts = ({ costToPurchase, assetValue, userGuidanceCode }) => (
  <SubTotalSection
    total={calculateTotalPurchaseCosts(costToPurchase, assetValue)}
    title={`Subtotal purchase costs (${userGuidanceCode})`}
  />
);

SubtotalPurchaseCosts.defaultProps = {
  assetValue: 0,
};

SubtotalPurchaseCosts.propTypes = {
  costToPurchase: PropTypes.object.isRequired,
  assetValue: PropTypes.number,
  userGuidanceCode: PropTypes.string.isRequired,
};

const ConnectedSubtotalPurchaseCosts = ({
 costToPurchases, index, assetValue, userGuidanceCode,
}) => (
  <SubtotalPurchaseCosts
    costToPurchase={costToPurchases[index]}
    assetValue={assetValue}
    userGuidanceCode={userGuidanceCode}
  />
);

ConnectedSubtotalPurchaseCosts.defaultProps = {
  assetValue: 0,
};

ConnectedSubtotalPurchaseCosts.propTypes = {
  costToPurchases: PropTypes.array.isRequired,
  assetValue: PropTypes.number,
  userGuidanceCode: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  costToPurchases: fundingWorksheetFormValueSelector(state, 'costToPurchases'),
});

export default connect(mapStateToProps)(ConnectedSubtotalPurchaseCosts);
