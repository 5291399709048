import React from 'react';
import isNil from 'lodash/isNil';
import { frequencyAbbreviations } from 'shared/formatterUtils';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import styles from './styles';
import { labelOf } from '../constants';
import { formatAttribute } from '../utils';
import editingInputStyles from './EditingInput.module.scss';

export const percentageValidation = newRate => (newRate === undefined || (newRate > 0 && newRate <= 100));
export const amountValidation = newValue => (newValue === undefined || (newValue >= 0 && newValue < 1000000000));
export const amountAllowNegativeValidation = newValue => (newValue === undefined || newValue < 1000000000);

export default (currentModification, onChange, isEditable = true) => {
  const { fieldName, variant } = currentModification;
  const getInput = (validation, decimalScale, onChangeFieldName, options = {}) => (
    <NumberInput
      label=""
      prefix={options.prefix}
      suffix={options.suffix}
      onChange={value => onChange(onChangeFieldName, value)}
      value={isNil(variant[onChangeFieldName]) ? 0 : variant[onChangeFieldName]}
      isAllowed={validation}
      decimalScale={decimalScale}
      fixedDecimalScale={(decimalScale !== 0)}
      style={styles.inputStyle}
      fullWidth
      disabled={!isEditable}
      textClassName={editingInputStyles.editingInputPartialInputText}
    />
  );

  const editingInputPartials = {
    standardRate: (
      <div style={styles.editingFieldSection}>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf[fieldName]}
          </div>
          <div style={styles.inputContainer}>
            {getInput(percentageValidation, 2, fieldName, { suffix: '%' })}
          </div>
        </div>
      </div>
    ),
    ongoingFee: (
      <div style={styles.editingFieldSection}>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf[fieldName]}
          </div>
          <div style={styles.inputContainer}>
            {getInput(amountValidation, 0, fieldName, { prefix: '$' })}
            <div className="editing-input__suffix-unit" style={styles.suffixFieldUnit}>
              {frequencyAbbreviations[variant.ongoingFeeFrequency]}
            </div>
          </div>
        </div>
      </div>
    ),
    upfrontFee: (
      <div style={styles.editingFieldSection}>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf.applicationFee}
          </div>
          <div style={styles.inputContainer}>
            {getInput(amountAllowNegativeValidation, 0, 'applicationFee', { prefix: '$' })}
          </div>
        </div>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf.legalFee}
          </div>
          <div style={styles.inputContainer}>
            {getInput(amountValidation, 0, 'legalFee', { prefix: '$' })}
          </div>
        </div>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf.valuationFee}
          </div>
          <div style={styles.inputContainer}>
            {getInput(amountValidation, 0, 'valuationFee', { prefix: '$' })}
          </div>
        </div>
        <div className="editing-input__upfront-total" style={styles.upfrontTotal}>
          <div>Total upfront fee/rebate</div>
          <div>{formatAttribute('upfrontFee', variant)}</div>
        </div>
      </div>
    ),
    lmi: (
      <div style={styles.editingFieldSection}>
        <div style={styles.inputRow}>
          <div className="editing-input__label" style={styles.editingFieldLabel}>
            {labelOf.lmi}
          </div>
          <div style={styles.inputContainer}>
            {getInput(amountValidation, 0, 'lmi', { prefix: '$', size: 'medium' })}
          </div>
        </div>
      </div>
    ),
  };

  return (editingInputPartials[fieldName] || (<div />));
};
