import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import {
  getRequestIdFromAction,
  ifValidRequestId,
  startRequest,
} from 'redux/requestIds';
import graphQL from './permissionSettingsGraphQL';
import { updateUserPermissions } from './permissionSettingsApi';
import { closeErrorBanner, showErrorBanner } from '../../../redux/banner';

export const ACTIONS = {
  SAVE_PERMISSIONS_START: 'SAVE_PERMISSIONS_START',
  SAVE_PERMISSIONS_SUCCESS: 'SAVE_PERMISSIONS_SUCCESS',
  SAVE_PERMISSIONS_ERROR: 'SAVE_PERMISSIONS_ERROR',
  RETRIEVE_USER_INFO_LIST_START: 'RETRIEVE_USER_INFO_LIST_START',
  RETRIEVE_USER_INFO_LIST_SUCCESS: 'RETRIEVE_USER_INFO_LIST_SUCCESS',
  RETRIEVE_USER_INFO_LIST_ERROR: 'RETRIEVE_USER_INFO_LIST_ERROR',
  UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
};

const BUSINESS_SPLITTER = '::business:';

export const updatePermissions = payload => ({
  type: ACTIONS.UPDATE_PERMISSIONS,
  payload,
});

const startSaveUserPermissions = () => ({ type: ACTIONS.SAVE_PERMISSIONS_START });

export const savePermissionSettings = permissionsToBeUpdated => (dispatch) => {
  dispatch(closeErrorBanner());
  dispatch(startSaveUserPermissions());
  return updateUserPermissions(permissionsToBeUpdated)
    .then((data) => {
      dispatch({
        type: ACTIONS.SAVE_PERMISSIONS_SUCCESS,
        userPermission: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.SAVE_PERMISSIONS_ERROR,
        error,
      });
    });
};

const startRetrieveUserInfoList = () => ({ type: ACTIONS.RETRIEVE_USER_INFO_LIST_START });

export const retrieveUserInfoList = businessId => async (dispatch, getState) => {
  dispatch(startRetrieveUserInfoList());
  const startRequestAction = startRequest(ACTIONS.RETRIEVE_USER_INFO_LIST_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const data = await graphQL.getUserProfilePermissions(businessId);
    ifValidRequestId(getState().requestIds, ACTIONS.RETRIEVE_USER_INFO_LIST_START, requestId, () => {
      dispatch({
        type: ACTIONS.RETRIEVE_USER_INFO_LIST_SUCCESS,
        userInfoList: data.data.userProfilePermission,
      });
    });
  } catch (errors) {
    ifValidRequestId(getState().requestIds, ACTIONS.RETRIEVE_USER_INFO_LIST_START, requestId, () => {
      dispatch({
        type: ACTIONS.RETRIEVE_USER_INFO_LIST_ERROR,
        error: errors,
      });
      dispatch(showErrorBanner('There was a problem while fetching permissions. Please try again later.'));
    });
  }
};

const initState = {
  userInfoList: [],
  originalUserInfoList: [],
  isSaving: false,
  isLoading: false,
};

const mapToFilteredPermission = (permissionsToDelete, businessId) => (permission) => {
  const permissionAndScope = permission.split(BUSINESS_SPLITTER);
  const permissionName = permissionsToDelete.find(item => item === permissionAndScope[0]);
  if (isEmpty(permissionName)) {
    return permission;
  }
  const otherBusinessIds = permissionAndScope[1].split(',').filter(id => id !== businessId);
  return isEmpty(otherBusinessIds) ? null : `${permissionName}${BUSINESS_SPLITTER}${otherBusinessIds.join(',')}`;
};

const mapToAddedPermission = businessId => permission => `${permission}${BUSINESS_SPLITTER}${businessId}`;

const getUpdatedUsersOfGroup = ({ userInfoList }, { payload }) => {
  const {
    userId, businessId, permissionsToAdd, permissionsToDelete,
  } = payload;

  const foundUserInfo = userInfoList.find(userInfo => userInfo.userId === userId);
  const modifiedPermissions = compact(foundUserInfo.permissions
    .map(mapToFilteredPermission(permissionsToDelete, businessId))
    .concat(permissionsToAdd.map(mapToAddedPermission(businessId))));

  return userInfoList.map(user => (
    user.userId !== userId ? user : {
      ...user,
      permissions: modifiedPermissions,
    }
  ));
};

export default (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_PERMISSIONS: {
      return {
        ...state,
        userInfoList: getUpdatedUsersOfGroup(state, action),
      };
    }
    case ACTIONS.SAVE_PERMISSIONS_START:
      return {
        ...state,
        isSaving: true,
      };
    case ACTIONS.SAVE_PERMISSIONS_SUCCESS: {
      const currentUserInfoList = cloneDeep(state.userInfoList);
      const savedUserInfoList = currentUserInfoList.map((userInfo) => {
        const foundModified = action.userPermission.find(userPermission => userInfo.userId === userPermission.userId);
        if (foundModified) {
          return {
            ...userInfo,
            permissions: foundModified.permissions,
            version: foundModified.version,
          };
        }
        return {
          ...userInfo,
        };
      });
      return {
        ...state,
        isSaving: false,
        userInfoList: savedUserInfoList,
        originalUserInfoList: cloneDeep(savedUserInfoList),
      };
    }
    case ACTIONS.SAVE_PERMISSIONS_ERROR: {
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    }
    case ACTIONS.RETRIEVE_USER_INFO_LIST_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.RETRIEVE_USER_INFO_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        userInfoList: cloneDeep(action.userInfoList),
        originalUserInfoList: cloneDeep(action.userInfoList),
      };
    }
    case ACTIONS.RETRIEVE_USER_INFO_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
