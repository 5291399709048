import { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import withReduxForm from '../ReduxFormFieldWrapper';
import WithReadOnlySelect from '../../Select/WithReadOnlySelect';

const ReduxFormSelect = withReduxForm((props) => {
  const {
    value,
    onChange,
    onBlur,
    options,
  } = props;

  const handleChange = useCallback((v) => onChange(isEmpty(v) ? null : v.value),
    [onChange]);

  const selectedValue = useMemo(() => options?.find(option => {
      if (isObject(value)) {
        return JSON.stringify(option.value) === JSON.stringify(value);
      }
      return option.value === value;
    }) ?? null,
    [options, value]);

  const handleBlur = useMemo(() => () => {
    onBlur();
  }, [onBlur]);

  return ({
    onChange: handleChange,
    value: selectedValue,
    onBlur: handleBlur,
  });
})(WithReadOnlySelect);

export default ReduxFormSelect;
