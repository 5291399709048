import React from 'react';
import { FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';
import ReduxFormFieldArrayTupleInput, { validateValues } from '../components/FieldArrayTupleInput';
import ConnectedSubtotalOtherCosts from './SubtotalOtherCosts';
import { getHasTopUp } from '../../../redux/selectors/liabilities';

const EditOtherCosts = ({ hasTopUp, userGuidanceCode }) => {
  if (!hasTopUp) {
    return null;
  }
  return (
    <div>
      <InformationSection
        wrapperStyle={styles.infoSection}
        contentWrapperStyle={styles.infoSectionContent}
        titleWrapperStyle={styles.titleWrapperStyle}
        title="Other"
      >
        <SubSection>
          <FieldArray
            name="otherCosts"
            component={ReduxFormFieldArrayTupleInput}
            validate={validateValues}
            allowNegative={false}
          />
        </SubSection>
        <ConnectedSubtotalOtherCosts title={`Other costs (${userGuidanceCode})`} />
      </InformationSection>
    </div>
  );
};

EditOtherCosts.defaultProps = {
  hasTopUp: false,
  userGuidanceCode: '',
};

EditOtherCosts.propTypes = {
  hasTopUp: PropTypes.bool,
  userGuidanceCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  hasTopUp: getHasTopUp(state),
});

export default connect(mapStateToProps)(EditOtherCosts);
