import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import { FETCHING_STATUS } from '../../../../constants';
import { perspectivesForSettlement as perspectives } from '../viewPerspectives';
import { loansByMonthToView } from '../../utils/loansByMonthToView';
import { SettlementReport } from './SettlementReport';
import { generateContextFromLoansAndState } from '../../utils';

const settlementReportSelector = createSelector(
  state => ({
    report: state.reports.settlementReport,
    lenderColorConfig: get(state, 'lendersConfig.colorConfig', []),
  }),
  ({
     report: { fetchingStatus, loansByMonth, reportWindow },
     lenderColorConfig,
   }) => {
    if (fetchingStatus !== FETCHING_STATUS.SUCCESS) {
      return {
        fetchingStatus,
      };
    }
    const loans = flatMap(loansByMonth);
    const context = generateContextFromLoansAndState(loans, { lenderColorConfig });
    const views = perspectives.map(perspective =>
      loansByMonthToView(
        loansByMonth,
        {
          context,
          perspective,
          displayWindow: reportWindow,
        },
      ));

    return {
      fetchingStatus,
      views,
    };
  },
);

const mapStateToProps = settlementReportSelector;

export const ConnectedSettlementReport = connect(mapStateToProps)(SettlementReport);
