import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AddbackTypeField,
  DetailsField,
  PreviousValueField,
  ValueField,
} from '../employmentIncomeFields';
import { AddRemoveButtonGroup } from '../EmploymentIncome';
import styles from './CompanyIncome.module.scss';
import ReduxFormSelect from '../../../../../../../shared/components/formFields/ReduxForm/Select';
import {
  disableSelected,
  isAddbackTypeSelected,
  isTypeSelected,
} from '../IncomeTypeUtils';
import {
  companyAddbackTypeOptions as ADDBACK_TYPE_OPTIONS,
  companyIncomeTypeOptions as TYPE_OPTIONS,
} from '../constants';

const CompanyIncome = ({
 fieldNamePrefix, selectedTypes, hasEditPermission, appendIncome, removeIncome, isLastOne, isOnlyOne,
}) => (
  <div className={styles.companyIncomeWrapper}>
    <Field
      name={`${fieldNamePrefix}.type`}
      className={classNames(styles.incomeType, styles.fieldWrapper)}
      label="Income type"
      component={ReduxFormSelect}
      options={TYPE_OPTIONS.map(disableSelected(isTypeSelected(selectedTypes, true)))}
    />
    <AddbackTypeField
      fieldNamePrefix={fieldNamePrefix}
      className={classNames(styles.addbackType, styles.fieldWrapper)}
      options={ADDBACK_TYPE_OPTIONS.map(disableSelected(isAddbackTypeSelected(selectedTypes)))}
    />
    <DetailsField
      fieldNamePrefix={fieldNamePrefix}
      className={classNames(styles.details, styles.fieldWrapper)}
    />
    <ValueField
      fieldNamePrefix={fieldNamePrefix}
      className={classNames(styles.fieldWrapper, styles.value)}
      allowNegative
    />
    <PreviousValueField
      fieldNamePrefix={fieldNamePrefix}
      className={classNames(styles.fieldWrapper, styles.previousValue)}
      allowNegative
    />
    {hasEditPermission && (
      <AddRemoveButtonGroup
        addFunc={appendIncome}
        removeFunc={removeIncome}
        showAdd={isLastOne}
        showRemove={!isOnlyOne}
        disableAdd={selectedTypes.length === TYPE_OPTIONS.length - 1 + ADDBACK_TYPE_OPTIONS.length}
        className={styles.removeButton}
      />
    )}
  </div>
);

CompanyIncome.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isLastOne: PropTypes.bool.isRequired,
  isOnlyOne: PropTypes.bool.isRequired,
  appendIncome: PropTypes.func.isRequired,
  removeIncome: PropTypes.func.isRequired,
};

export default CompanyIncome;
