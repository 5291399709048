import React from 'react';
import StandAloneProductsTable from '../ProductSearch/ProductsTable/StandAloneProductsTable';
import StandAloneProductSearchCriteria from '../ProductSearch/ProductSearchCriteria/StandAloneProductSearchCriteria';
import usePageViewTracking from './usePageViewTracking';

const Products = () => {
  usePageViewTracking('products');

  return (
    <>
      <StandAloneProductSearchCriteria />
      <StandAloneProductsTable />
    </>
  );
};

export default Products;
