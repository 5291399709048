import { formatBool, toRatePercentage } from 'shared/formatterUtils';
import { InputYesNo } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputYesNo';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import { formatAmount } from 'utils/formatters';
import { InputPercentage } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputPercentage';
import { InputMoney } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputMoney';
import React from 'react';
import {
  EditField,
  USER_PERMISSIONS,
} from 'Loans/components/LoanDetails/EditField';
import styles from '../index.module.scss';

export const getUpfrontFields = (
  {
    isUpfrontExpected,
    actualBusinessUpfront,
    upfrontPercentage,
    commissionLoanAmount,
    expectedBusinessUpfront,
    expectedUpfront,
    upfrontPercentageOverride,
    settledAmount,
    expectedUpfrontOverride,
  },
  reconciledDatePresent,
) => [
  {
    title: 'Upfront expected',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        fallback={formatBool(isUpfrontExpected)}
      >
        <InputYesNo value={isUpfrontExpected} name="isUpfrontExpected" disabled={reconciledDatePresent} />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Expected business upfront',
    content: isUpfrontExpected ? formatAmount(expectedBusinessUpfront) : '',
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.expectedBusinessUpfront,
    hidden: false,
  },
  {
    title: 'Actual business upfront',
    content: formatAmount(actualBusinessUpfront),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Commission loan amount',
    content: formatAmount(commissionLoanAmount),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.commissionLoanAmount,
  },
  {
    title: 'Upfront percentage',
    content: toRatePercentage(upfrontPercentage, ''),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.upfrontPercentage,
  },
  {
    title: 'Expected Smartline upfront',
    content: formatAmount(expectedUpfront),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.expectedSmartlineUpfront,
  },
  {
    title: 'Actual Smartline upfront',
    content: undefined, // TODO: Check with shiraz, this field doesn't currently exist
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.actualSmartlineUpfront,
    hidden: true,
  },
  {
    title: 'Settled amount',
    content: formatAmount(settledAmount),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.settledAmount,
  },
  {
    title: 'Upfront percentage override',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={toRatePercentage(upfrontPercentageOverride, '')}
      >
        <InputPercentage
          value={upfrontPercentageOverride}
          name="upfrontPercentageOverride"
        />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.upfrontPercentageOverride,
  },
  {
    title: 'Expected Smartline upfront override',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        fallback={formatAmount(expectedUpfrontOverride)}
      >
        <InputMoney
          value={expectedUpfrontOverride}
          name="expectedUpfrontOverride"
          disabled={reconciledDatePresent}
          isAllowed={value => value !== 0}
        />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.expectedSmartlineupfrontoverride,
  },
  {
    title: 'Marketing levy amount',
    content: undefined, // TODO: Check with shiraz, this field doesn't currently exist
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.marketingLevyAmount,
    hidden: true,
  },
];
