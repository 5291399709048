import { getTargetChangedValue } from '../../../../../../constants/utils';
import { TYPES } from '../../../../../../constants/activityLogTypes';
import { getBasicLogInfo } from './utils';

const ALI_INSURANCE_ACKNOWLEDGEMENT = {
  EXPECTED_SETTLEMENT_DATE: 'It is time to discuss ALI loan protection insurance with the applicant',
  ALI_INSURANCE: 'It is time to email ALI loan protection insurance information to applicant',
};

const aliInsuranceAcknowledgementChangeLogTransformer = (origin) => {
  const type = getTargetChangedValue(origin, 'type');
  return ({
    ...getBasicLogInfo(origin),
    operator: getBasicLogInfo(origin).operator ?? 'Launchpad',
    type: TYPES.INSURANCE,
    content: 'Loan protection insurance notification dismissed',
    subContent: ALI_INSURANCE_ACKNOWLEDGEMENT[type],
  });
};

export default aliInsuranceAcknowledgementChangeLogTransformer;
