import React from 'react';
import PropTypes from 'prop-types';
import { LoanBookSummary } from './LoanBook/LoanBookSummary';
import { SettlementSummaryReport } from './Settlement/SettlementSummaryReport';
import styles from './styles.module.scss';
import HasPermissions from '../../../../shared/components/authorisation/HasPermissions';
import { PERMISSIONS } from '../../../../shared/components/authorisation/permissions';
import { FETCHING_STATUS } from '../../../../constants';
import LoadingSpinner from '../../../../shared/components/LoadingSpinner/LoadingSpinner';
import DataCalculateDescription from '../DataCalculateDescription/DataCalculateDescription';

export const SummaryReport = ({ loanBook, settledApplication }) => {
  const isFetchingSuccess = loanBook.fetchingStatus === FETCHING_STATUS.SUCCESS
    || settledApplication.fetchingStatus === FETCHING_STATUS.SUCCESS;
  return (
    <>
      {
        !isFetchingSuccess && <LoadingSpinner />
      }
      {
        isFetchingSuccess && (
          <div>
            <h3>Summary</h3>
            <div className={styles.container}>
              <SettlementSummaryReport {...settledApplication} />
              <HasPermissions permissions={[PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS]} fallback={null}>
                <LoanBookSummary {...loanBook} />
              </HasPermissions>
            </div>
            <DataCalculateDescription />
          </div>
        )
      }
    </>
  );
};

SummaryReport.propTypes = {
  loanBook: PropTypes.object.isRequired,
  settledApplication: PropTypes.object.isRequired,
};
