import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Modal from 'shared/components/Modal/Modal';
import isEmpty from 'lodash/isEmpty';
import { getUTCNow } from 'utils/datetime';
import TextArea from 'shared/components/formFields/TextArea';
import styles from './OptOutInsuranceModal.module.scss';

const OptOutInsuranceModal = ({
 isOpen, onClose, applicationId, updateAllianzInsurance,
}) => {
  const [reason, setReason] = useState('');
  const [optOuting, setOptOuting] = useState(false);

  return (
    <Modal
      className={styles.optOutModal}
      isOpen={isOpen}
      onRequestClose={() => {
        onClose(false);
      }}
      header={<div className={styles.optOutModalHeader}>Confirm opt out of insurance</div>}
      footer={(
        <PrimaryButton
          className={styles.optOutModalButton}
          onClick={() => {
            setOptOuting(true);
            updateAllianzInsurance(applicationId, {
              allianzPromptForReferralDate: getUTCNow(),
              allianzOptOut: true,
              allianzOptOutReason: reason,
            }).catch(() => {
              setOptOuting(false);
            });
          }}
          disabled={isEmpty(reason) || optOuting}
        >
          Opt out
        </PrimaryButton>
      )}
    >
      <div className={styles.optOutModalBody}>
        <TextArea
          className={styles.optOutReason}
          label="Enter your reason for opting out"
          onChange={value => setReason(value.trim())}
          autoFocus
          fullWidth
        />
      </div>
    </Modal>
  );
};

OptOutInsuranceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  updateAllianzInsurance: PropTypes.func.isRequired,
};

export default OptOutInsuranceModal;
