import React from 'react';
import PropTypes from 'prop-types';
import RetryComponent from 'shared/components/RetryComponent';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';

const LoadingAndErrorWrapper = ({ isLoading, hasError, children }) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (hasError) {
    return <RetryComponent />;
  }
  return children;
};

LoadingAndErrorWrapper.defaultProps = {
  isLoading: false,
  hasError: false,
};

LoadingAndErrorWrapper.propTypes = {
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default LoadingAndErrorWrapper;
