import React from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, Field } from 'redux-form';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import sumBy from 'lodash/sumBy';
import AdditionalIncomesWrapper from './Wrappers/AdditionalIncomesWrapper';
import { updateAdditionalIncomes } from '../../../redux';
import {
  getIncomes,
  getRentalIncomes,
} from '../../../redux/selectors';
import styles from './AdditionalIncomeForm.module.scss';
import SectionTitle from '../../shared/SectionTitle';
import { formatAmountWithDefault } from '../../../../../../utils/formatters';
import { toAnnualValue } from '../../../../../utils';
import { hasEditPermissionSelector } from '../../../redux/selectors/permissions';
import { getDefaultOwnershipsWithCompanyAndGuarantors } from '../../../redux/selectors/ownerships';

const formatAdditionalIncomes = (items) => [
    {
      label: 'Additional annual income (gross)',
      displayValue: formatAmountWithDefault(
        sumBy(
          concat(items.incomes, items.rentalIncomes).filter(
            (income) => !isEmpty(income.periodUnit),
          ),
          (income) => toAnnualValue(income.periodUnit, income.value),
        ),
      ),
      span: 2,
      column: -3,
    },
  ];

const AdditionalIncomeForm = ({
  handleSubmit,
  pristine,
  valid,
  submitting,
  hasEditPermission,
  defaultOwnerships,
}) => {
  const additionalIncomesHeader = (
    <Field
      name="additionalIncomes"
      component={SectionTitle}
      title="Additional incomes"
      format={formatAdditionalIncomes}
    />
  );

  return (
    <FormMetaProvider readOnly={!hasEditPermission}>
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <ShowMore
          name={additionalIncomesHeader}
          iconSize="25"
          headerClassName={styles.showMoreHeader}
        >
          <FieldArray
            name="additionalIncomes.incomes"
            component={AdditionalIncomesWrapper}
            ownerships={defaultOwnerships}
          />
        </ShowMore>
        {
          hasEditPermission && (
            <div className={styles.btnWrapper}>
              <PrimaryButton
                disabled={pristine || !valid || submitting}
                type="submit"
              >Save
              </PrimaryButton>
            </div>
          )
        }
      </form>
    </FormMetaProvider>
  );
};

AdditionalIncomeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  defaultOwnerships: PropTypes.array.isRequired,
};

const AdditionalIncomeReduxForm = reduxForm({
  form: 'additionalIncomesForm',
  onSubmit: (values, dispatch) => dispatch(updateAdditionalIncomes(values.additionalIncomes.incomes)),
  enableReinitialize: true,
})(AdditionalIncomeForm);

export default connect(
  state => ({
    initialValues: {
      additionalIncomes: {
        incomes: getIncomes(state),
        rentalIncomes: getRentalIncomes(state),
      },
    },
    hasEditPermission: hasEditPermissionSelector(state),
    defaultOwnerships: getDefaultOwnershipsWithCompanyAndGuarantors(state),
  }),
)(AdditionalIncomeReduxForm);
