import React from 'react';
import { connect } from 'react-redux';
import { formPropTypes, getFormValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import get from 'lodash/get';
import { updateOwnsAndNonRealAssets } from '../../redux';
import Section from '../shared/Section';
import NonRealEstate from './NonRealEstate';
import {
  getAssetsInitialValues,
  getDefaultOwnershipsWithCompanyAndGuarantors,
} from '../../redux/selectors';
import { TRANSACTION_TYPES } from '../../redux/constants';
import RealEstate from './RealEstate';
import FinancialSummary from '../shared/FinancialSummary';
import { formatAmountWithDefault } from '../../../../../utils/formatters';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { getSubmittableAssets } from './utils';
import { FETCHING_STATUS } from '../../../../../constants';

const isValid = item => isEmpty(omit(item, ['transactionType']));

const formatRealEstateSectionHeader = (items) => [
  {
    label: 'Real estate assets value',
    displayValue: formatAmountWithDefault(sumBy(items, item => {
      if (item.isForConstructionLoan && item.transactionType === TRANSACTION_TYPES.OWNS) {
        return item.currentValue;
      }
      return item.value || 0;
    })),
    span: 2,
    column: -3,
  },
];

const formatNonRealEstateSectionHeader = (items) => [
  {
    label: 'Non real estate assets value',
    displayValue: formatAmountWithDefault(sumBy(items, 'value')),
    span: 2,
    column: -3,
  },
];

const Assets = ({
 pristine, handleSubmit, submitting, valid, hasEditPermission, assetsFormValues, lenders, defaultOwnerships, isFetching,
}) => {
  const { realEstates, nonRealEstates } = assetsFormValues;
  const hasEmptyAsset = realEstates.some(isValid) || nonRealEstates.some(isEmpty);
  const defaultRealEstate = {
    transactionType: TRANSACTION_TYPES.OWNS,
    asSecurity: false,
    isPreApproval: false,
    ownerships: defaultOwnerships,
  };
  const defaultNonRealEstate = {
    ownerships: defaultOwnerships,
  };
  return (
    <>
      <FinancialSummary />
      <form onSubmit={handleSubmit}>
        <Section
          title="Real estate assets"
          fieldName="realEstates"
          editable={hasEditPermission}
          Item={RealEstate}
          defaultItem={defaultRealEstate}
          formatSectionHeader={formatRealEstateSectionHeader}
          addButtonLabel="Add a real estate asset"
          showConstructionSwitch
        />
        <Section
          title="Non real estate assets"
          fieldName="nonRealEstates"
          editable={hasEditPermission}
          Item={NonRealEstate}
          defaultItem={defaultNonRealEstate}
          formatSectionHeader={formatNonRealEstateSectionHeader}
          addButtonLabel="Add a non real estate asset"
          lenders={lenders}
          assets={assetsFormValues}
        />
        {hasEditPermission
        && (
          <StickyFooter
            disabled={pristine || hasEmptyAsset || submitting || !valid || isFetching}
            type="submit"
          />
        )}
        <LoadingWithOverlay isLoading={submitting || isFetching} />
        <UnsavedChangePrompt shouldConfirm={!pristine} />
      </form>
    </>
  );
};

Assets.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state => ({
      initialValues: getAssetsInitialValues(state),
      hasEditPermission: hasEditPermissionSelector(state),
      lenders: state.lenders?.lenders ?? [],
      defaultOwnerships: getDefaultOwnershipsWithCompanyAndGuarantors(state),
      assetsFormValues: getFormValues('assetsForm')(state) || { realEstates: [], nonRealEstates: [] },
      isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START,
    }
  ),
)(reduxForm({
  form: 'assetsForm',
  onSubmit: (formValues, dispatch) => dispatch(updateOwnsAndNonRealAssets(getSubmittableAssets(formValues))),
  enableReinitialize: true,
})(Assets));
