import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, change, getFormValues } from 'redux-form';
import { createSelector } from '@reduxjs/toolkit';
import React, { useCallback, useMemo } from 'react';
import range from 'lodash/range';
import { APPLICATION_TYPE_OPTIONS } from '../../constants';
import ReduxFormSelect from '../../../../../../../shared/components/formFields/ReduxForm/Select';
import styles from '../styles.module.scss';

const ApplicationTypeField = ({ formName }) => {
  const dispatch = useDispatch();
  const formValuesSelector = useMemo(() => getFormValues(formName), [formName]);
  const loansCriteriaCountSelector = useMemo(
    () => createSelector(formValuesSelector, ({ loansCriteria }) => loansCriteria.length),
    [formValuesSelector],
  );
  const loansCriteriaCount = useSelector(loansCriteriaCountSelector);
  const handleApplicationTypeChanged = useCallback(() => {
    range(loansCriteriaCount).forEach((index) => {
      dispatch(change(formName, `loansCriteria[${index}].loanTerm`, 30));
    });
  }, [dispatch, formName, loansCriteriaCount]);
  return (
    <Field
      name="applicationCriteria.applicationType"
      component={ReduxFormSelect}
      label="Application type"
      options={APPLICATION_TYPE_OPTIONS}
      className={styles.criteriaSelectField}
      onChange={handleApplicationTypeChanged}
    />
  );
};

ApplicationTypeField.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default ApplicationTypeField;
