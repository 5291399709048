import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Field } from 'redux-form';
import ReduxFormCheckbox from 'shared/components/formFields/ReduxForm/Checkbox';
import ExternalLink from 'shared/components/Links/ExternalLink';
import Icon from 'shared/components/Icon/Icon';
import PropTypes from 'prop-types';
import styles from './Forms.module.scss';

const SL_OPS_MANUAL_FORMS_LINK = 'https://sites.google.com/smartline.com.au/operations-manual/9-forms-stationery/forms-and-checklists';
const Forms = ({ hasEditPermission }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <div>
      <div className={styles.title}>Forms</div>
      <div className={styles.signedForms}>
        <div className={styles.formListHeader}>
          <h5>Form type</h5>
          <h5>Signed by all applicants</h5>
        </div>
        <Field
          name="preSubmission.signedCreditCheckAuthority"
          component={ReduxFormCheckbox}
          labelName="Credit check authority"
          className={styles.checkbox}
          disabled={!hasEditPermission}
        />
        <Field
          name="preSubmission.signedFixRateLockDeclaration"
          component={ReduxFormCheckbox}
          labelName="Fixed rate lock declaration"
          className={styles.checkbox}
          disabled={!hasEditPermission}
        />
        <Field
          name="preSubmission.signedLowDocClientAcknowledgement"
          component={ReduxFormCheckbox}
          labelName="Low doc client acknowledgement"
          className={styles.checkbox}
          disabled={!hasEditPermission}
        />
        <ExternalLink
          className={styles.externalLink}
          to={SL_OPS_MANUAL_FORMS_LINK}
        >
          Forms and checklist
          <Icon name="share" size="15" color={themeContext.palette.linkPrimary} />
        </ExternalLink>
      </div>
    </div>
  );
};

Forms.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

export default Forms;
