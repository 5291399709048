import React from 'react';
import {
  emptyToAny, emptyToNA, toDollarAmount, toSentenceCase, formatLoanTerm, formatYearAndMonth,
} from 'shared/formatterUtils';
import { convertDecimalYearToIntegerYearAndMonth } from 'shared/utils';
import getStyles from './styles';
import { ABS_LENDING_CODE, PRODUCT_TYPES, REPAYMENT_TYPES } from '../../../../constants';
import stylesheet from './styles.module.scss';
import { REFINANCE } from '../ProductSearchFields/PurposeCategoryComponent';

export default (isSingleLoan) => {
  const styles = getStyles(isSingleLoan);

  const getCombinedLoanAmountField = (loanCriteria) => (
    <div className="loan-criteria-summary__combined-loan-amount" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Combined loan amount</div>
      <div>{emptyToNA(loanCriteria.combinedLoanAmount, toDollarAmount)}</div>
    </div>
    );

  const getLoanAmountField = (loanCriteria) => (
    <div className="loan-criteria-summary__loan-amount" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Loan amount</div>
      <div>{emptyToNA(loanCriteria.loanAmount, toDollarAmount)}</div>
    </div>
    );

  const getPurposeField = loanCriteria => (
    <div className="loan-criteria-summary__purpose" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Purpose</div>
      <div>{emptyToAny(toSentenceCase(loanCriteria.purpose))}</div>
    </div>
  );

  const getProductTypeField = loanCriteria => (
    <div className="loan-criteria-summary__product-type" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Product type</div>
      <div>{emptyToAny(PRODUCT_TYPES[loanCriteria.productType])}</div>
    </div>
  );

  const getRepaymentTypeField = loanCriteria => (
    <div className="loan-criteria-summary__repayment-type" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Repayment type</div>
      <div>{emptyToAny(REPAYMENT_TYPES[loanCriteria.repaymentType])}</div>
    </div>
  );

  const getPurposeCategoryField = loanCriteria => {
    if (!loanCriteria?.purposeCategory) {
      return null;
    }
    return (
      <div style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>Purpose category</div>
        <div>{loanCriteria.purposeCategory}</div>
      </div>
    );
  };

  const getPurposeDetailsField = loanCriteria => {
    if (!loanCriteria?.purposeCategory) {
      return null;
    }
    return (
      <div style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>Purpose details</div>
        <div>{emptyToAny(ABS_LENDING_CODE[loanCriteria.purposeDetails])}</div>
      </div>
    );
  };

  const getRefinanceReasonsField = loanCriteria => {
    if (!loanCriteria?.purposeCategory || loanCriteria.purposeCategory !== REFINANCE) {
      return null;
    }
    return (
      <div style={styles.criteriaField}>
        <div style={styles.criteriaField.label}>Refinance reason</div>
        <div>{emptyToAny(loanCriteria.refinanceReason)}</div>
      </div>
    );
  };

  const getLoanTermField = (loanCriteria, isTopUp) => (
    <div className="loan-criteria-summary__loan-term" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Loan term</div>
      {isTopUp
        ? <div>{formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(loanCriteria.loanTerm)) || 'N/A'}</div>
        : <div>{formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(Math.floor(loanCriteria.loanTerm))) || 'N/A'}</div>}
    </div>
  );

  const getFixedLoanTermField = loanCriteria => (
    <div className="loan-criteria-summary__fixed-loan-term" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Fixed loan term</div>
      <div>{formatLoanTerm(loanCriteria.fixedLoanTerm)}</div>
    </div>
  );

  const getInterestOnlyTermField = loanCriteria => (
    <div className="loan-criteria-summary__interest-only-term" style={styles.criteriaField}>
      <div style={styles.criteriaField.label}>Interest only term</div>
      <div>{formatLoanTerm(loanCriteria.interestOnlyTerm)}</div>
    </div>
  );

  const getFeaturesField = (loanCriteria, featuresFieldStyle) => {
    const { selectedFeatureNames } = loanCriteria;
    const featureNamesText = (selectedFeatureNames || []).join(', ') || 'N/A';
    return (
      <div className={stylesheet.loanCriteriaSummaryFeatures} style={featuresFieldStyle}>
        <div style={styles.criteriaField.label}>Features</div>
        <div>{featureNamesText}</div>
      </div>
    );
  };

  return {
    getLoanAmountField,
    getPurposeField,
    getProductTypeField,
    getRepaymentTypeField,
    getLoanTermField,
    getFixedLoanTermField,
    getInterestOnlyTermField,
    getFeaturesField,
    getPurposeCategoryField,
    getPurposeDetailsField,
    getRefinanceReasonsField,
    getCombinedLoanAmountField,
  };
};
