import {
  FONTS, MARGIN, PADDING,
} from '../../../theme/depricatedStyles';

export default (isSingleLoan, numOfItemsPerRow = 3) => {
  const fieldHeight = '1.8rem + 1.8rem';
  const fieldWidth = '18rem';
  const sectionLabelWidth = '8.4rem';
  const fieldMarginRightSingle = MARGIN.xxlarge;
  const fieldMarginRightSplit = MARGIN.mediumLarge;
  const fieldMarginBottom = MARGIN.medium;

  const singleLoanCriteriaLeftHalfWidth = `(${numOfItemsPerRow} * (${fieldWidth} + ${fieldMarginRightSingle}))`;
  const splitLoanCriteriaLeftHalfWidth = `(${sectionLabelWidth} + 4 * ${fieldWidth} + 5 * ${fieldMarginRightSplit})`;

  const lenderFieldWidthForSplitLoans = `calc(100% - ${sectionLabelWidth} - 3 * ${fieldWidth} - 4 * ${fieldMarginRightSplit})`;

  const loanCriteriaLeftHalfWidth = isSingleLoan
    ? singleLoanCriteriaLeftHalfWidth
    : splitLoanCriteriaLeftHalfWidth;

  return {
    title: {
      marginBottom: MARGIN.large,
      padding: PADDING.large,
      lineHeight: '1.15',
      fontFamily: FONTS.title,
      fontSize: FONTS.size.xxlarge,
      borderBottom: 'solid 0.1rem #e0e0e0',
    },
    sectionLabel: {
      display: 'inline-block',
      position: 'relative',
      marginRight: fieldMarginRightSplit,
      paddingTop: '0.3rem',
      width: sectionLabelWidth,
      verticalAlign: 'top',
      fontSize: FONTS.size.regular,
      fontFamily: FONTS.title,
    },
    criteriaField: {
      display: 'inline-block',
      marginRight: isSingleLoan ? fieldMarginRightSingle : fieldMarginRightSplit,
      width: fieldWidth,
      verticalAlign: 'top',
      label: {
        fontFamily: FONTS.standardBold,
      },
      value: {},
      marginBottom: MARGIN.medium,
    },
    secondLineIndent: {
      display: 'inline-block',
      width: `calc(${sectionLabelWidth} + ${fieldMarginRightSplit})`,
    },
    blankField: {
      display: 'inline-block',
      width: `calc(${fieldWidth} + ${fieldMarginRightSplit})`,
    },
    lenderNameField: {
      display: 'inline-block',
      maxWidth: isSingleLoan ? `calc(${loanCriteriaLeftHalfWidth})` : lenderFieldWidthForSplitLoans,
      verticalAlign: 'top',
      marginBottom: MARGIN.medium,
    },
    loanCriteriaSummary: {
      leftHalf: {
        display: 'inline-block',
        width: `calc(${loanCriteriaLeftHalfWidth})`,
      },
      featuresField: {
        display: 'inline-block',
        marginTop: isSingleLoan ? `calc(${fieldHeight} + ${fieldMarginBottom})` : '0',
        width: `calc(100% - ${loanCriteriaLeftHalfWidth})`,
        verticalAlign: 'top',
        marginBottom: '1rem',
        // NB this is overridden by media query that is not possbile here: ./_CriteriaSummary.scss
      },
    },
  };
};
