import React from 'react';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import LeftNavBar from 'shared/components/LeftNavBar/LeftNavBar';
import styles from './TemplateManagement.module.scss';
import TaskListTemplatePage from './TaskListTemplate';

const TemplateManagement = () => (
  <div>
    <GroupOfficeBusinessSelector />
    <div className={styles.contentNavWrapper}>
      <LeftNavBar activeTab="Task list templates" setActiveTab={() => {}} tabs={['Task list templates']} title="Templates" />
      <div className={styles.appContent}>
        <TaskListTemplatePage />
      </div>
    </div>
  </div>
);

export default TemplateManagement;
