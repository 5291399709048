import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  Route, withRouter, matchPath, Redirect,
} from 'react-router-dom';
import withSyncBusinessId from 'shared/components/WithSyncBusinessId/withSyncBusinessId';
import ApplicationTracking from './ApplicationTracking';
import ApplicationDetails from './components/ApplicationDetails/ApplicationDetails';
import { ConnectedTaskDetailModal }
  from './components/ApplicationDetails/ApplicationDetailContentNav/Activity/TaskSection/TaskDetailModal';
import { FETCHING_STATUS } from '../constants';

const ApplicationsComponent = withSyncBusinessId(ApplicationTracking);

const renderTaskDetailModal = (context) => {
  const { history, match, location: { hash } } = context;
  const matchProfile = matchPath(hash, {
    path: '#/tasks/:taskId',
  });
  if (!matchProfile) {
    return null;
  }
  const applicationId = get(match, 'params.applicationId', null);
  const taskId = get(matchProfile, 'params.taskId', null);
  return (
    <ConnectedTaskDetailModal
      applicationId={applicationId}
      onRequestClose={() => history.push(`${history.location.pathname}${history.location.search}`)}
      taskId={taskId}
      {...context}
    />
  );
};

const ApplicationTrackingRoutes = ({ applicationFetchingStatus }) => (
  <>
    <Route exact path="/applications" component={ApplicationsComponent} />
    <Route path="/applications/:applicationId/:activeTab?" component={ApplicationDetails} />
    {applicationFetchingStatus === FETCHING_STATUS.SUCCESS
    && <Route path="/applications/:applicationId" render={renderTaskDetailModal} />}
    <Route
      path="/applications/:applicationId/tasks/:taskId"
      render={(params) => {
        const { match, history } = params;
        const applicationId = get(match, 'params.applicationId', '');
        const taskId = get(match, 'params.taskId', '');
        const search = get(history, 'location.search', '');
        return (
          <Redirect to={`/applications/${applicationId}${search}#/tasks/${taskId}`} />
        );
      }}
    />
  </>
  );

ApplicationTrackingRoutes.propTypes = {
  applicationFetchingStatus: PropTypes.number,
};

ApplicationTrackingRoutes.defaultProps = {
  applicationFetchingStatus: null,
};

export default withRouter(connect(state => ({
  applicationFetchingStatus: state.application.applicationFetchingStatus.fetchingStatus,
}))(ApplicationTrackingRoutes));
