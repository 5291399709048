import React from 'react';
import DateBox from 'devextreme-react/date-box';
import PropTypes from 'prop-types';
import styles from 'shared/components/formFields/DatePicker/DatePicker.module.scss';
import moment from 'moment';
import classNames from 'classnames';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import { inputBaseDefaultProps, inputBaseProps } from 'shared/components/formFields/base/baseProps';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';

const DatePicker = ({
  value,
  onChange,
  type,
  label,
  disabled,
  style,
  className,
  errorMessage,
  fullWidth,
  inline,
  hideIcon,
  displayFormat,
  mini,
  ...props
}) => {
  const propsForPickerType = {
    date: {
      type: 'date',
      displayFormat: 'd MMMM yyyy',
    },
    localDate: {
      type: 'date',
      displayFormat: 'dd/MM/yyyy',
    },
    datetime: {
      type: 'datetime',
      displayFormat: 'd MMMM yyyy h:mm a',
    },
    time: {
      type: 'time',
      displayFormat: 'h:mm a',
    },
    shortDate: {
      type: 'date',
      displayFormat: 'd MMM yyyy',
    },
  };

  const parsedDate = value ? moment(value).toDate() : undefined;

  return (
    <NewInputLabelWrapper
      label={label}
      className={classNames(styles.inputLabelWrapper, className, {
        [styles.fullWidthInputLabelWrapper]: fullWidth,
      })}
      errorMessage={errorMessage}
      fullWidth={fullWidth}
      inline={inline}
      style={style}
    >
      <DateBox
        width="100%"
        disabled={disabled}
        className={classNames(styles.textContainer, {
          [styles.textContainerDisabled]: disabled,
          [styles.textContainerError]: !!errorMessage,
          [styles.textContainerMini]: mini,
        })}
        value={parsedDate}
        onValueChanged={({ value: newValue }) => onChange(newValue)}
        useMaskBehavior
        openOnFieldClick={hideIcon}
        showDropDownButton={!hideIcon}
        {...propsForPickerType[type]}
        {...props}
      />
    </NewInputLabelWrapper>
  );
};

DatePicker.defaultProps = {
  value: undefined,
  hideIcon: false,
  mini: false,
  ...inputBaseDefaultProps,
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  type: PropTypes.oneOf(['date', 'datetime', 'time', 'localDate', 'shortDate']).isRequired,
  hideIcon: PropTypes.bool,
  mini: PropTypes.bool,
  ...inputBaseProps,
};

export default DatePicker;
