import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import styles from './ResponsiveTable.module.scss';

const Header = ({ header }) => (
  <div
    className={classNames(styles.line, styles.header)}
  >
    {map(header, column => (
      <div
        className={classNames(styles.cell, styles[column.className])}
        key={column.key}
      >
        {column.content}
      </div>
    ))}
  </div>
);

Header.propTypes = {
  header: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
  })).isRequired,
};

export default Header;
