const COMMENTS_IN_PRINTOUT = 'includeCommentsInComparisionPrintout';
const SELECTED_BUSINESS_ID = 'selectedBusinessId';

export const saveIncludeCommentsInPrintout = (value) => {
  localStorage.setItem(COMMENTS_IN_PRINTOUT, value);
};

export const retrieveIncludeCommentsInPrintout = () => {
  const value = localStorage.getItem(COMMENTS_IN_PRINTOUT);
  const result = value === 'true';
  return result;
};

export const saveSelectedBusinessId = (id) => {
  localStorage.setItem(SELECTED_BUSINESS_ID, id || '');
};

export const retrieveLocalSelectedBusinessId = () => localStorage.getItem(SELECTED_BUSINESS_ID);
