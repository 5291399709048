import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import ReportRow from './ReportRow';
import { getCommissionManagementReportsSummary } from './redux/commissionManagementReportsRedux';
import { trackViewPage } from '../../../redux/tracking';
import styles from './CommissionManagementReportsSummary.module.scss';

const renderReportRows = (summary) => {
  if (summary && summary.length) {
    return orderBy(summary, ['processingMonth'], 'desc').map(report => (
      <ReportRow
        key={report.processingMonth}
        processingDate={report.processingMonth}
        numberOfIssues={report.numberOfIssues}
      />
    ));
  }
  return (
    <tr>
      <td colSpan={2}>
        <div>No data</div>
      </td>
    </tr>
  );
};

export class CommissionManagementReportsSummaryComponent extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      businessId,
      dispatchGetCommissionManagementSummary,
      dispatchPageTracking,
    } = this.props;
    dispatchGetCommissionManagementSummary(businessId);
    dispatchPageTracking('commissionManagementReportsSummary');
  }

  render() {
    const { summary, isLoading, hasError } = this.props;

    return (
      <>
        <GroupOfficeBusinessSelector />
        <h1>Commission management reports</h1>
        <LoadingAndErrorWrapper isLoading={isLoading} hasError={hasError}>
          <table className={styles.tableStyles}>
            <thead>
              <tr>
                <th>Month</th>
                <th style={{ textAlign: 'right' }}>Reported issues</th>
              </tr>
            </thead>
            <tbody>
              {renderReportRows(summary)}
              <tr>
                <td className={styles.notice} colSpan={2}>
                  Earlier reports can be found in your email inbox.
                </td>
              </tr>
            </tbody>
          </table>
        </LoadingAndErrorWrapper>
      </>
    );
  }
}

CommissionManagementReportsSummaryComponent.defaultProps = {
  summary: [],
};

CommissionManagementReportsSummaryComponent.propTypes = {
  businessId: PropTypes.string.isRequired,
  summary: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  dispatchGetCommissionManagementSummary: PropTypes.func.isRequired,
  dispatchPageTracking: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  businessId: get(state, 'business.selectedBusiness.id'),
  summary: state.myBusiness.commissionManagementReports.summary,
  isLoading: state.myBusiness.commissionManagementReports.isLoading,
  hasError: state.myBusiness.commissionManagementReports.hasError,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetCommissionManagementSummary: businessId =>
    dispatch(getCommissionManagementReportsSummary(businessId)),
  dispatchPageTracking: pageName => dispatch(trackViewPage(pageName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionManagementReportsSummaryComponent);
