import React, { useContext } from 'react';
import FormMetaContext from './FormMetaContext';

export default (Component) => {
  const HOC = (props) => {
    const { disabled, readOnly } = useContext(FormMetaContext);
    return (
      <Component
        readOnly={readOnly}
        disabled={disabled}
        {...props}
      />
    );
  };
  HOC.displayName = `withFormMeta(${Component.displayName || Component.name || 'component'})`;
  return HOC;
};
