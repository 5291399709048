export default {
  ADVISER: {
    surname: 'Surname',
    firstName: 'First name',
  },
  APPLICANT: {
    // general
    surname: 'Surname',
    isPrimary: 'Primary applicant',
    contactDetails: 'Contact details',
    businessName: 'Business name',
    trustName: 'Trust name',
  },
  LIABILITY: {
    clearingStatus: 'Pay out/top up',
  },
  REAL_ESTATE: {
    address: 'Address',
    residents: 'Residents at settlement',
  },
  LOAN: {
    term: 'Loan term',
    amount: 'Loan amount',
    loanType: 'Loan type',
    isTopUp: 'Top up',
    purpose: 'Loan purpose',
    fixedTerm: 'Fixed term',
    fixedTermPeriod: 'Fixed term period',
    repaymentType: 'Repayment type',
    productType: 'Product type',
  },
  INSURANCE_REFERRAL: {
    outcome: 'Outcome',
  },
};
