import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RadioButtonGroup from 'shared/components/formFields/RadioButtonGroup';
import Checkbox from 'shared/components/formFields/Checkbox';
import styles from './LoanSplit.module.scss';
import SplitComplianceFormLink from './SplitComplianceFormLink';
import { SPLITTERS } from './splitters';

const ComplianceSection = ({
  splitter,
  hasDeclared,
  onSplitterChange,
  onHasDeclaredChange,
}) => {
  const makeComplianceOption = options =>
    options.map(option => ({
      label: option,
      value: option,
    }));

  const complianceOptions = makeComplianceOption(Object.values(SPLITTERS));
  const getComplianceCheck = () => {
    const isBusiness = splitter === SPLITTERS.BUSINESS;
    return (
      <div className={styles.declaration}>
        <h4>Declaration</h4>

        <Checkbox
          className={styles.checkBoxLabel}
          checked={isBusiness ? hasDeclared : hasDeclared === false}
          onChange={({ checked }) => {
            if (checked === false) {
              onHasDeclaredChange(undefined);
            } else {
              onHasDeclaredChange(isBusiness ? checked : !checked);
            }
          }}
          inputProps={{ className: styles.checkBoxInput }}
          labelName={
            isBusiness ? (
              <>
                I have completed and attached the
                <span className={styles.boldText}> LAUNCHPAD SPLIT COMPLIANCE FORM</span> to the client file.
              </>
            ) : (
              <>
                <span className={styles.boldText}>NO</span> compliance form
                completed as I did not initiate the split. This action is to
                update existing data only.
              </>
            )
          }
        />
      </div>
    );
  };
  return (
    <section className={styles.compliance}>
      <h4>Compliance</h4>
      <p>
        If <i>this business</i> created the split, you must complete the&nbsp;
        <SplitComplianceFormLink /> and attach it to the client file.
      </p>
      <p>Who created the split?</p>
      <RadioButtonGroup
        items={complianceOptions}
        value={splitter}
        groupName="splitter"
        onSelect={onSplitterChange}
      />
      {getComplianceCheck()}
    </section>
  );
};

ComplianceSection.defaultProps = {
  hasDeclared: undefined,
};

ComplianceSection.propTypes = {
  hasDeclared: PropTypes.bool,
  onSplitterChange: PropTypes.func.isRequired,
  splitter: PropTypes.string.isRequired,
  onHasDeclaredChange: PropTypes.func.isRequired,
};

export default memo(ComplianceSection);
