import get from 'lodash/get';
import { getActivityLogs } from '../../../api';

export const GET_SPECIAL_ACTIVITY_LOGS_SUCCESSFUL = 'GET_SPECIAL_ACTIVITY_LOGS_SUCCESSFUL';

export const getSpecialActivityLogs = businessId => dispatch =>
  getActivityLogs(businessId)
    .then((result) => {
      const specialActivityLogs = get(result, 'data.specialActivityLogsSearch', []);
      dispatch({ type: GET_SPECIAL_ACTIVITY_LOGS_SUCCESSFUL, data: { specialActivityLogs } });
    });
