import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getBasicLogInfo } from './utils';

const applicationDisplayFields = {
  applicationNumber: 'application number',
  'writer.name': 'adviser',
};

const getContent = ({ path, oldValue, newValue }) => {
  if (path === 'writer.name') {
    return `Adviser has been updated from ${oldValue} to ${newValue}`;
  }
  const displayName = get(applicationDisplayFields, path);
  return isEmpty(newValue)
  ? `The ${displayName} has been deleted`
  : `A new ${displayName} has been added - ${newValue}`;
};

const applicationDetailChangeLogTransformer = (origin) => {
  const foundChange = origin.eventDetail.changes.find(change => has(applicationDisplayFields, change.path));
  if (!foundChange) {
    return null;
  }
  const content = getContent(foundChange);
  return ({
    ...getBasicLogInfo(origin),
    content,
  });
};

export default applicationDetailChangeLogTransformer;
