import GraphQL from './GraphQL';
import Request from './Request';
import {
  ALL_APPLICATION_XLSX,
  BUSINESS_SUMMARIES,
  COMMISSIONS_XLSX,
  INTERNALLY_REFERRED_XLSX,
  LENDER_RECONCILIATION_XLSX,
  MALI_DOCUMENT,
  NOTIFICATION,
  PDF_COMPARISON,
  SEARCHABLE_LOAN_BY_FILTER_XLSX,
  USER_PROFILE,
} from './APIEndpoints';

export { addressLookup as getAddressSuggestions } from './LocationsSuggest';

export const getAllProductsFromApi = props =>
  GraphQL.getAllProducts(props).then(response => response.data.allVariantCombinations);

export const getServiceabilityFromApi = request =>
  GraphQL.getServiceability(request).then(response =>
    ({
      response: response.data.serviceability.assessments[0],
      details: convertServiceabilityDetailsData(response.data.serviceability.details),
    }))
    .catch((error) => {
      throw error;
    });

export const getPropertyReportUrl = info =>
  GraphQL.getPropertyReportUrl(info).then(response => response.data.propertyReportUrl);

export const getDetailsForProducts = ids =>
  GraphQL.getDetailsForProducts(ids).then(response => response.data.variants);

export const getUserProfile = () =>
  Request.get(USER_PROFILE)
    .then(response => response.data);

export const getAllFeaturesFromApi = () =>
  GraphQL.getAllFeatures().then(response => response.data.allFeatures);

export const getAllLendersFromApi = () =>
  GraphQL.getAllLenders().then(response => response.data.allLenders);

export const downloadComparisonPdf = async comparision =>
  Request.post(PDF_COMPARISON, comparision, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/pdf' }));

export const downloadCommissionsXlsx = async (financialYear, businessId) =>
  Request.get(`${COMMISSIONS_XLSX}/${businessId}/${financialYear}`, null, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const downloadLenderReconciliationXlsx = async processingMonth =>
  Request.get(`${LENDER_RECONCILIATION_XLSX}/${processingMonth}`, null, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const downloadInternallyReferredAssetFinanceXlsx = async () =>
  Request.get(`${INTERNALLY_REFERRED_XLSX}`, null, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const downloadSearchableLoansByFilterXlsx = async request =>
  Request.post(SEARCHABLE_LOAN_BY_FILTER_XLSX, request, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const downloadSearchableAllApplicationsXlsx = async request =>
  Request.post(ALL_APPLICATION_XLSX, request, { responseType: 'arraybuffer' })
  .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const searchBusinessSummaries = query => (query
  ? Request.get(`${BUSINESS_SUMMARIES}/search`, { query }).then(response => response.data.data)
  : Promise.resolve([]));

export const getBusinessSummary = id =>
  Request.get(`${BUSINESS_SUMMARIES}/${id}`)
    .then(response => response.data);

export const getVariantModificationFromApi = (variant, context) =>
  GraphQL.getVariantModification(variant, context)
    .then(response => response.data.variantModification);

export const getLendersConfigFromApi = () => GraphQL.getLendersConfig()
  .then(response => response.data.lendersConfig);

export const getAllCategoryLendersFromApi = () => GraphQL.getAllCategoryLenders()
  .then(response => response.data.allCategoryLenders);

export const getLendersColorConfigFromApi = () => GraphQL.getLendersColorConfig()
  .then(response => response.data.lendersColorConfig);

export const getUserContactsByBusinessId = businessId => GraphQL.getUserContactsByBusinessId(businessId)
  .then(response => response.data.businessUsers);

export const downloadMALIDocument = async applicationId =>
  Request.get(`${MALI_DOCUMENT}/${applicationId}`, null, { responseType: 'arraybuffer' })
    .then(response => ({
      blob: new Blob([response.data], { type: 'application/xml' }),
      applicantNames: response.headers.applicantnames,
    }));

export const convert = (metrics, id) => metrics.filter(metric => metric.pid === id)
  .map(metric => ({ ...metric, metrics: convert(metrics, metric.id) }));

export const convertServiceabilityDetailsData = flattedData => {
  if (!flattedData) {
    return null;
  }
  return ({
    metricLists: flattedData.metricLists.map(data => ({
      ...data,
      metrics: convert(data.metrics, null),
    })),
  });
};

export const getActiveNotifications = () =>
  Request.get(NOTIFICATION, { isActive: true })
    .then(response => response.data);
