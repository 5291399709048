import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Fields } from 'redux-form';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import TextInput from 'shared/components/formFields/ReduxForm/TextInput';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import Checkbox from 'shared/components/formFields/ReduxForm/Checkbox';
import DatePicker from 'shared/components/formFields/DatePicker/WithReadOnlyDatePicker';
import NumberInput from 'shared/components/formFields/NumberInput/WithReadOnlyNumberInput';
import PhoneInput from 'shared/components/formFields/PhoneInput/WithReadOnlyPhoneInput';
import { isAfterToday, isBeforeThan, toDateWithSlash } from '../../../../../../utils/datetime';
import {
  employmentSectorOptions,
  employmentStatusOptions,
  employmentTypeOptions,
  employmentTypes,
  EMPTY_CHARACTER,
  statusOptionDisablePrimary,
  statusOptions,
  status,
} from './constants';
import { sortedOccupationOptions } from './occupationOptions';
import styles from './Employment.module.scss';
import dateUtil from './dateUtil';
import AddressInput from '../../shared/AddressInput';

const validateStartDate = (startDate, endDate, isPrevious) => {
  if (!startDate) {
    return undefined;
  }
  if (isAfterToday(startDate)) {
    return 'You cannot set a future date';
  }
  if (isPrevious && !!endDate && isBeforeThan(endDate, startDate)) {
    return 'Start date cannot be later than End date';
  }
  return undefined;
};

const validateEndDate = (startDate, endDate, isPrevious) => {
  if (!isPrevious || !endDate) {
    return undefined;
  }
  if (isAfterToday(endDate)) {
    return 'You cannot set a future date';
  }
  if (!!startDate && isBeforeThan(endDate, startDate)) {
    return 'End date cannot be earlier than Start date';
  }
  return undefined;
};

const validateABNNumber = value => (value && value.length !== 11 ? 'Please enter an 11 digit ABN' : undefined);

const isAllowedABN = value => value === undefined || /^\d*$/.test(value);

const basePropTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export const EmploymentTypeField = ({
 fieldNamePrefix, setEmploymentIncome, setFinancialYear, clearEmployer,
}) => (
  <Field
    name={`${fieldNamePrefix}.employmentType`}
    className={classNames(
      styles.fieldWrapper,
      styles.multiRowField,
      styles.atFirstColumnOnDesktop,
      styles.atFirstColumnOnMobile,
    )}
    label="Employment type"
    component={ReduxFormSelect}
    options={employmentTypeOptions}
    onChange={newValue => {
      if (newValue === employmentTypes.PAYE) {
        setEmploymentIncome([{}]);
        setFinancialYear(null);
      } else if (newValue === employmentTypes.SELF_EMPLOYED) {
        setEmploymentIncome([{ periodUnit: 'Yearly' }]);
        setFinancialYear(dateUtil.getCurrentFinancialYear());
      } else {
        setEmploymentIncome(null);
        setFinancialYear(null);
        clearEmployer();
      }
    }}
  />
);

export const EmploymentStatusField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.type`}
    className={classNames(styles.fieldWrapper, styles.multiRowField)}
    label="Employment status"
    component={ReduxFormSelect}
    options={employmentStatusOptions}
  />
);

export const EmploymentSectorField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.employerType`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
    label="Employment sector"
    component={ReduxFormSelect}
    options={employmentSectorOptions}
  />
);

export const OccupationField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.role`}
    className={classNames(styles.fieldWrapper, styles.atFirstColumnOnDesktop, styles.atFirstColumnOnMobile)}
    label="Occupation"
    component={ReduxFormSelect}
    isClearable
    options={sortedOccupationOptions}
  />
);

export const JobTitleField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.roleDescription`}
    className={styles.fieldWrapper}
    placeholder="Job title"
    label="Job title"
    component={TextInput}
  />
);

export const TimeDurationFields = ({
 fieldNamePrefix, isPrevious, durationLabel,
}) => {
  const TimeInputFields = useMemo(() => (fields) => {
    const [startDateField, endDateField] = fields.names.map(name => get(fields, name));
    const startDate = startDateField.input.value;
    const endDate = isPrevious ? endDateField.input.value : undefined;
    const { yearsDiff, monthsDiff } = dateUtil.getTimeDuration(startDate, endDate, isPrevious);
    const [yearsSuffix, monthsSuffix] = [
      yearsDiff === 1 ? ' year' : ' years',
      monthsDiff === 1 ? ' month' : ' months',
    ];
    return (
      <>
        <NumberInput
          className={classNames(
            styles.fieldWrapper,
            styles.singleColumnWrapperOnDesktop,
            styles.atFirstColumnOnDesktop,
            styles.atFirstColumnOnMobile,
          )}
          label={durationLabel}
          placeholder="Years"
          suffix={yearsSuffix}
          isAllowed={number => number === undefined || (number < 1000 && number >= 0)}
          disabled={isPrevious}
          value={yearsDiff}
          readOnlyFormatter={value => isNumber(value) && (value === 1 ? `${value} year` : `${value} years`)}
          onManualChange={event => {
            const { value } = event.target;
            if (value) {
              startDateField.input.onChange(
                dateUtil.dateString(
                  moment()
                    .subtract(value.split(yearsSuffix)[0], 'year')
                    .subtract(monthsDiff, 'month'),
                ),
              );
            }
          }}
        />
        <NumberInput
          className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
          label={EMPTY_CHARACTER}
          placeholder="Months"
          suffix={monthsSuffix}
          isAllowed={number => number === undefined || (number < 12 && number >= 0)}
          disabled={isPrevious}
          value={monthsDiff}
          readOnlyFormatter={value => isNumber(value) && (value === 1 ? `${value} month` : `${value} months`)}
          onManualChange={event => {
            const { value } = event.target;
            if (value) {
              startDateField.input.onChange(
                dateUtil.dateString(
                  moment()
                    .subtract(yearsDiff, 'year')
                    .subtract(value.split(monthsSuffix)[0], 'month'),
                ),
              );
            }
          }}
        />
        <DatePicker
          label="Start date"
          type="localDate"
          className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.datePicker)}
          value={startDate}
          errorMessage={startDateField.meta.error}
          onChange={value => startDateField.input.onChange(dateUtil.dateString(value))}
          readOnlyFormatter={value => (value ? toDateWithSlash(value) : '')}
        />
        {isPrevious && (
          <DatePicker
            label="End date"
            type="localDate"
            className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop, styles.datePicker)}
            value={endDate}
            errorMessage={endDateField.meta.error}
            onChange={value => endDateField.input.onChange(dateUtil.dateString(value))}
            readOnlyFormatter={value => (value ? toDateWithSlash(value) : '')}
          />
        )}
      </>
    );
  }, [isPrevious, durationLabel]);
  return (
    <Fields
      names={[`${fieldNamePrefix}.startDate`, `${fieldNamePrefix}.endDate`]}
      validate={{
        [`${fieldNamePrefix}.startDate`]: (value, allValues) =>
          validateStartDate(value, get(allValues, `${fieldNamePrefix}.endDate`), isPrevious),
        [`${fieldNamePrefix}.endDate`]: (value, allValues) =>
          validateEndDate(get(allValues, `${fieldNamePrefix}.startDate`), value, isPrevious),
      }}
      component={TimeInputFields}
    />
  );
};

export const StatusField = ({
  fieldNamePrefix, hasPrimary, clearEndDate, clearEmploymentIncome, setDefaultEmploymentIncome,
}) => (
  <Field
    name={`${fieldNamePrefix}.status`}
    component={ReduxFormSelect}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
    label="Status"
    options={hasPrimary ? statusOptionDisablePrimary : statusOptions}
    onChange={(value) => {
      if (value === status.Previous) {
        clearEmploymentIncome();
      } else {
        setDefaultEmploymentIncome();
        clearEndDate();
      }
    }}
  />
  );

const OnProbationCheckbox = fields => (
  <div className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}>
    <div className={styles.fieldTitle}>{EMPTY_CHARACTER}</div>
    <Checkbox {...fields} labelName="On probation" className={styles.checkBox} />
  </div>
);

export const OnProbationField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.onProbation`}
    component={OnProbationCheckbox}
  />
);

export const CompanyNameField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.employer.companyName`}
    className={classNames(
      styles.fieldWrapper, styles.multiRowField, styles.atFirstColumnOnDesktop, styles.atFirstColumnOnMobile,
    )}
    label="Company name"
    placeholder="Company name"
    component={TextInput}
  />
);

export const EmployerFirstNameField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.employer.personName.firstName`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
    placeholder="First name"
    label="Employer first name"
    component={TextInput}
  />
);

export const EmployerSurnameField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.employer.personName.surname`}
    className={classNames(styles.fieldWrapper, styles.singleColumnWrapperOnDesktop)}
    placeholder="Surname"
    label="Employer surname"
    component={TextInput}
  />
);

const PhoneField = (fields) => {
  const [countryCodeField, phoneField] = fields.names.map(name => get(fields, name));
  const country = countryCodeField.input.value;
  const phoneNumber = phoneField.input.value;
  return (
    <PhoneInput
      label="Employer phone"
      className={classNames(styles.fieldWrapper, styles.atFourthColumnOnDesktop)}
      classNames={{
        inputClass: styles.phoneInput,
        dropDownClass: styles.phoneDropDown,
        buttonClass: styles.phoneButton,
      }}
      value={`${country}${phoneNumber}`}
      readOnlyFormatter={() => `${country && `+${country}`} ${phoneNumber && ` ${phoneNumber}`}`}
      onChange={(countryCode, phone) => {
        countryCodeField.input.onChange(countryCode);
        phoneField.input.onChange(phone);
      }}
    />
  );
};

export const EmployerPhoneField = ({ fieldNamePrefix }) => (
  <Fields
    names={[
      `${fieldNamePrefix}.employer.employerContact.countryCode`,
      `${fieldNamePrefix}.employer.employerContact.phone`,
    ]}
    component={PhoneField}
  />
);

export const ABNNumberField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.abn.abnNumber`}
    className={classNames(styles.fieldWrapper, styles.atFirstColumnOnMobile, styles.atFirstColumnOnDesktop)}
    placeholder="ABN"
    label="ABN"
    component={TextInput}
    isAllowed={isAllowedABN}
    validate={validateABNNumber}
  />
);

const DatePickerField = field => (
  <DatePicker
    label="Date ABN established"
    type="localDate"
    className={classNames(styles.fieldWrapper, styles.datePicker)}
    value={field.input.value}
    onChange={value => field.input.onChange(dateUtil.dateString(value))}
    readOnlyFormatter={value => (value ? toDateWithSlash(value) : '')}
  />
);

export const ABNEstablishedDateField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.abn.abnEstablishedDate`}
    component={DatePickerField}
  />
);

export const OnBenefitsField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.onBenefits`}
    className={classNames(styles.atFirstColumnOnDesktop, styles.thinCheckbox)}
    labelName="On benefits"
    component={Checkbox}
  />
);

export const StudentField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.student`}
    className={classNames(styles.atFirstColumnOnDesktop, styles.thinCheckbox)}
    labelName="Student"
    component={Checkbox}
  />
);

export const HomeDutiesField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.homeDuties`}
    className={classNames(styles.atFirstColumnOnDesktop, styles.thinCheckbox)}
    labelName="Home duties"
    component={Checkbox}
  />
);

export const AddressField = ({ fieldNamePrefix }) => (
  <Field
    name={`${fieldNamePrefix}.employer.employerContact.address`}
    component={AddressInput}
    label="Address"
    className={classNames(styles.fieldWrapper, styles.addressField)}
  />
);

EmploymentTypeField.propTypes = {
  ...basePropTypes,
  setEmploymentIncome: PropTypes.func.isRequired,
  setFinancialYear: PropTypes.func.isRequired,
  clearEmployer: PropTypes.func.isRequired,
};
EmploymentStatusField.propTypes = basePropTypes;
EmploymentSectorField.propTypes = basePropTypes;
OccupationField.propTypes = basePropTypes;
JobTitleField.propTypes = basePropTypes;
TimeDurationFields.propTypes = {
  ...basePropTypes,
  isPrevious: PropTypes.bool.isRequired,
  durationLabel: PropTypes.string,
};
TimeDurationFields.defaultProps = {
  durationLabel: 'Time employed',
};
StatusField.propTypes = {
  ...basePropTypes,
  hasPrimary: PropTypes.bool.isRequired,
  clearEndDate: PropTypes.func.isRequired,
  clearEmploymentIncome: PropTypes.func.isRequired,
  setDefaultEmploymentIncome: PropTypes.func,
};
StatusField.defaultProps = {
  setDefaultEmploymentIncome: noop,
};
OnProbationField.propTypes = basePropTypes;
CompanyNameField.propTypes = basePropTypes;
EmployerFirstNameField.propTypes = basePropTypes;
EmployerSurnameField.propTypes = basePropTypes;
EmployerPhoneField.propTypes = basePropTypes;
ABNNumberField.propTypes = basePropTypes;
ABNEstablishedDateField.propTypes = basePropTypes;
OnBenefitsField.propTypes = basePropTypes;
StudentField.propTypes = basePropTypes;
HomeDutiesField.propTypes = basePropTypes;
AddressField.propTypes = basePropTypes;
