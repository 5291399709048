import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const housingStatus = [
  { value: 'Boarding', displayValue: 'Boarding' },
  { value: 'Caravan', displayValue: 'Caravan' },
  { value: 'Other', displayValue: 'Other' },
  { value: 'OwnHome', displayValue: 'Own Home' },
  { value: 'OwnHomeMortgage', displayValue: 'Own Home Mortgage' },
  { value: 'Renting', displayValue: 'Renting' },
  { value: 'SuppliedByEmployer', displayValue: 'Supplied By Employer' },
  { value: 'WithParents', displayValue: 'With Parents' },
];

const residencyStatus = [
  { value: 'Citizen', displayValue: 'Citizen' },
  { value: 'NonResident', displayValue: 'Non Resident' },
  { value: 'Resident', displayValue: 'Resident' },
  { value: 'TemporaryResident', displayValue: 'Temporary Resident' },
];

export const formatHousingStatus = value => housingStatus.find(status => status.value === value)?.displayValue;
export const formatResidencyStatus = value => residencyStatus.find(status => status.value === value)?.displayValue;
export const formatPersonNameValue = nameValue => [get(nameValue, 'surname'), get(nameValue, 'firstName')].filter(name => !isEmpty(name)).join(', ');
