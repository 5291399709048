import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { connect, useSelector } from 'react-redux';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import ExternalInfoLink from 'shared/components/Links/ExternalInfoLink';
import styles from '../styles.module.scss';
import { OverrideFieldShape } from '../OverrideFIeldShape';
import {
  getBooleanContent,
  getLmiReferencedLenderContent,
  getNumberContent,
  getOverrideContent,
} from '../contentUtils';
import SubSection from '../components/SubSection';
import { SubtotalApplicationCosts } from './SubtotalApplicationCosts';
import { getLenders } from '../../../redux/selectors/lenders';
import { hasCostToPurchases } from '../../../redux/selectors/fundingWorksheet';

const ViewApplicationCosts = (props) => {
  const {
    firstHomeBuyer,
    foreignPurchaser,
    eligiblePensioner,
    mortgageRegistrationFee,
    applicationFee,
    fundsDisbursement,
    valuationFee,
    legalFee,
    lmi,
    lmiReferencedLenderId,
    userGuidanceCode,
  } = props;
  const fields = useMemo(() => (
    [
      getNumberContent('Application fee', applicationFee),
      getNumberContent('Disbursements', fundsDisbursement),
      getNumberContent('Valuation', valuationFee),
      getNumberContent('Legal costs fee', legalFee),
      getOverrideContent('Mortgage registration fee', mortgageRegistrationFee),
    ]
  ), [
    mortgageRegistrationFee,
    applicationFee,
    fundsDisbursement,
    valuationFee,
    legalFee,
  ]);

  const stampDutyFields = useMemo(() => ([
    getBooleanContent('Foreign purchaser', foreignPurchaser),
    getBooleanContent('Eligible pensioner (discount)', eligiblePensioner),
    getBooleanContent('First home buyer', firstHomeBuyer),
  ]), [
    firstHomeBuyer,
    foreignPurchaser,
    eligiblePensioner,
  ]);

  const lenders = useSelector(getLenders);
  const lender = useMemo(() => find(lenders, { id: lmiReferencedLenderId }), [lenders, lmiReferencedLenderId]);

  const lmiFields = useMemo(() => ([
    getLmiReferencedLenderContent('LMI based on', lender?.name),
    getOverrideContent('LMI', lmi),
  ]), [lender, lmi]);

  const showStampDutyDropdown = useSelector(hasCostToPurchases);

  return (
    <div className={styles.applicationCosts}>
      <InformationSection
        wrapperStyle={styles.infoSection}
        contentWrapperStyle={styles.infoSectionContent}
        titleWrapperStyle={styles.titleWrapperStyle}
        title="Application costs"
      >
        <SubSection>
          <FieldsContainer
            fields={fields}
            title=""
            className={styles.fieldsContainerWrapper}
            fieldsWrapperClassName={styles.fieldsWrapper}
          />
        </SubSection>
        <SubSection
          header="LMI"
          className={styles.lmiSubSection}
          headerClassName={styles.lmiHeader}
        >
          <ExternalInfoLink
            to="https://smartline.zendesk.com/hc/en-us/articles/360001900416"
            text="How is LMI calculated?"
            externalInfoLinkClassName={styles.externalInfoLink}
            textClassName={styles.externalInfoText}
          />
          <FieldsContainer
            fields={lmiFields}
            title=""
            className={styles.lmiFields}
            fieldsWrapperClassName={styles.fieldsWrapper}
          />
        </SubSection>
        { showStampDutyDropdown && (
        <SubSection className={styles.stampDutyContainer} header="Stamp duty">
          <FieldsContainer
            fields={stampDutyFields}
            title=""
            className={styles.fieldsContainerWrapper}
            fieldsWrapperClassName={styles.fieldsWrapper}
          />
        </SubSection>
        )}
        <SubtotalApplicationCosts applicationCosts={props} userGuidanceCode={userGuidanceCode} />
      </InformationSection>
    </div>
  );
};

const mapStateToProps = (state) => get(state, 'application.applicationDetail.fundingWorksheet.applicationCosts');

ViewApplicationCosts.defaultProps = {
  firstHomeBuyer: false,
  foreignPurchaser: false,
  eligiblePensioner: false,
  mortgageRegistrationFee: {
    value: undefined,
    overrideValue: undefined,
  },
  applicationFee: undefined,
  fundsDisbursement: undefined,
  valuationFee: undefined,
  legalFee: undefined,
  lmi: {
    value: undefined,
    overrideValue: undefined,
  },
  lmiReferencedLenderId: '',
};

ViewApplicationCosts.propTypes = {
  firstHomeBuyer: PropTypes.bool,
  foreignPurchaser: PropTypes.bool,
  eligiblePensioner: PropTypes.bool,
  mortgageRegistrationFee: OverrideFieldShape,
  applicationFee: PropTypes.number,
  userGuidanceCode: PropTypes.string.isRequired,
  fundsDisbursement: PropTypes.number,
  valuationFee: PropTypes.number,
  legalFee: PropTypes.number,
  lmi: OverrideFieldShape,
  lmiReferencedLenderId: PropTypes.string,
};

export default connect(mapStateToProps)(ViewApplicationCosts);
