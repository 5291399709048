import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNil } from 'lodash';
import { formatCostCalculation, formatYearAndMonth } from 'shared/formatterUtils';
import flattenDepth from 'lodash/flattenDepth';
import RadioButton from '../../formFields/RadioButtonGroup/RadioButton';
import MobiusTable from '../../MobiusTable/MobiusTable';
import styles from './styles';
import cssStyles from './style.module.scss';
import { getProductName } from './getTableHead';
import { getCellContent } from './getProductInfoSection';
import { getFeatureHeader, getFeatureCell, getFeature } from './getFeatureSection';
import {
  basicInformation, costsAndFees, ratesBreakDown, groupNames,
} from './constants';
import { REPAYMENT_FREQUENCIES, REPAYMENT_FREQUENCIES_VALUES } from '../../../../constants';
import getRowNameCell from './CellTemplates';
import OptionShape from '../../../../shapes/OptionShape';
import { convertDecimalYearToIntegerYearAndMonth } from '../../../utils';

const rowClassSelector = ({ row: { rowName } }) => (groupNames.includes(rowName) ? cssStyles.groupNameRow : null);

const emptyRenderer = () => null;

const getRowHeaderRenderers = () => ({
  productInfo: (row) => {
    const { rowName, props: { totalCostTerm, repaymentFrequency } } = row;
    return (
      <div className={cx({ [cssStyles.groupName]: groupNames.includes(rowName) })}>
        {getRowNameCell(rowName, { totalCostTerm, repaymentFrequency })}
      </div>
    );
  },
  featureHeader: getFeatureHeader,
  feature: getFeature,
});

const getRowRenderers = (onFieldEditing) => (
  {
    productInfo: (row, column) => (groupNames.includes(row.rowName)
      ? null
      : getCellContent(row, column, onFieldEditing)),
    featureHeader: emptyRenderer,
    feature: getFeatureCell,
  }
);

const titleColumn = {
  name: 'title',
  headerRenderer: emptyRenderer,
  sticky: true,
  width: 240,
  classNames: {
    cell: cssStyles.rowHeaderCell,
  },
};

const calculateColumns = (props, rowRenderers, rowHeaderRenderers) => {
  const {
    totalCostTerm, repaymentFrequency, onPreferredOptionChange,
    variantsCombinationsConvertWithDiscountRate: variantsCombinations,
  } = props;
  const cellRenderer = (value, row, column) => ((rowRenderers[row.key] || emptyRenderer)(row, column));
  const rowHeaderCellRenderer = (value, row, column) => ((rowHeaderRenderers[row.key] || emptyRenderer)(row, column));
  const variantsColumnsPack = variantsCombinations.map((it, optionIndex) => {
    const variantColumnsAsBandedChildren = it.variants.map((v, index) => ({
      columnName: `${it.id}-${index}`,
    }));

    const bandedColumn = {
      title: (<span className={cssStyles.lenderName}>{it.lenderName}</span>),
      children: it.isSplitted ? [{
        title: (
          <div className={cssStyles.totals}>
            <div>
              TOTAL INTEREST AND FEES
              <br />
              <span className={cssStyles.number}>{formatCostCalculation(it.totalCost)}</span>
              &nbsp;
              (
              {formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(totalCostTerm))}
              )
            </div>
            <div>
              TOTAL REPAYMENTS (INCL. FEES)
              <br />
              <span className={cssStyles.number}>{formatCostCalculation(it.totalRepayment)}</span>
              &nbsp;
              <span className={cssStyles.totalRepaymentText}>
                (
                {REPAYMENT_FREQUENCIES[repaymentFrequency]}
                )
              </span>
            </div>
          </div>
        ),
        children: variantColumnsAsBandedChildren,
      }] : variantColumnsAsBandedChildren,
    };
    return ({
      bandedColumn: {
        title: onPreferredOptionChange && (
          <RadioButton
            className={cssStyles.radio}
            label={`Selected option ${String.fromCharCode(optionIndex + 65)}`}
            name="preferredProductIndex"
            value={it.id}
            checked={it.preferred ?? false}
            onChange={() => {
              onPreferredOptionChange(it.id);
            }}
          />
          ),
        children: [
          bandedColumn,
        ],
      },
      columns: it.variants.map((v, splitIndex) => ({
        name: `${it.id}-${splitIndex}`,
        title: getProductName(v, it.isSplitted, splitIndex),
        renderer: cellRenderer,
        variant: v,
        optionIndex,
        splitIndex,
        width: 260,
        wordWrapEnabled: true,
        classNames: optionIndex % 2 === 0 ? {
          cell: cssStyles.evenColumnCell,
        } : undefined,
      })),
    });
  });
  const blankColumn = {
    title: (<span />),
    width: 10,
    classNames: {
      cell: cssStyles.blankCell,
      headerCell: cssStyles.blankCell,
    },
  };
  const variantsColumns = flattenDepth(variantsColumnsPack.map((it, index) => ([index === 0 ? [] : [{ ...blankColumn, name: `blank-${index}` }], it.columns])), 2);
  const bandedColumns = variantsColumnsPack.map(it => (it.bandedColumn));

  return [
    {
      ...titleColumn,
      renderer: rowHeaderCellRenderer,
    },
    ...variantsColumns,
    ...bandedColumns,
  ];
};

const calculateRows = (props) => {
  const {
    selectedFeaturesFromComparison,
    selectedFeatureNames,
    onToggleIncludeCommentsInPrintout,
    onEditFeature,
    includeCommentsInPrintout,
  } = props;
  const productInfoKeys = [...costsAndFees, ...ratesBreakDown, ...basicInformation];
  const productInfoRows = productInfoKeys.map(name => ({
    key: 'productInfo',
    id: `productInfo-${name}`,
    rowName: name,
    props,
  }));
  const allFeatures = selectedFeaturesFromComparison.concat(selectedFeatureNames);
  const featureHeaderRow = {
    id: 'featureHeader',
    key: 'featureHeader',
    rowName: 'features',
    hasFeatures: allFeatures.length > 0,
    includeCommentsInPrintout,
    onToggleIncludeCommentsInPrintout,
    onEditFeature,
    props,
  };
  const featureRows = flattenDepth(allFeatures.map(name => ([
    {
      id: `feature-availability-${name}`,
      key: 'feature',
      rowName: name,
      type: 'availability',
      props,
    },
    {
      id: `feature-comments-${name}`,
      key: 'feature',
      rowName: name,
      type: 'comments',
      props,
    },
  ])));
  return [...productInfoRows, featureHeaderRow, ...featureRows];
};

const tableClassNames = {
  row: rowClassSelector,
  cell: cssStyles.tableCell,
  headerCell: cssStyles.headerCell,
};

const ProductComparisonTableComponent = React.memo((props) => {
  const {
    totalCostTerm,
    repaymentFrequency,
    variantsCombinations,
    selectedFeatureNames,
    selectedFeaturesFromComparison,
    includeCommentsInPrintout,
    onToggleIncludeCommentsInPrintout,
    onStartVariantEditing,
    onEditFeature,
    onPreferredOptionChange,
  } = props;

  const variantsCombinationsConvertWithDiscountRate = variantsCombinations?.map(v => ({
    ...v,
    variants: v?.variants.map(variant => ({
      ...variant,
      discountMonths: isNil(variant.discountRate) ? 'N/A' : variant.discountMonths,
    })),
  }));

  const pickedProps = {
    variantsCombinationsConvertWithDiscountRate,
    totalCostTerm,
    repaymentFrequency,
    selectedFeatureNames,
    selectedFeaturesFromComparison,
    includeCommentsInPrintout,
    onToggleIncludeCommentsInPrintout,
    onEditFeature,
    onPreferredOptionChange,
  };
  const rowRenderers = getRowRenderers(onStartVariantEditing);
  const rowHeaderRenderers = getRowHeaderRenderers();
  const columns = calculateColumns(pickedProps, rowRenderers, rowHeaderRenderers);
  const rows = calculateRows(pickedProps);
  const anySplitted = variantsCombinationsConvertWithDiscountRate.some(it => it.isSplitted);
  const tableClassName = useMemo(() => cx(cssStyles.table, cssStyles.tableWithPreferredRadio, {
    [cssStyles.splittedTable]: anySplitted,
  }), [anySplitted]);
  return (
    <div style={styles.comparisonTable}>
      <MobiusTable
        className={tableClassName}
        columns={columns}
        rows={rows}
        virtual
        classNames={tableClassNames}
      />
    </div>
  );
});

ProductComparisonTableComponent.defaultProps = {
  selectedFeatureNames: [],
  selectedFeaturesFromComparison: [],
  onPreferredOptionChange: undefined,
  totalCostTerm: 30,
  repaymentFrequency: REPAYMENT_FREQUENCIES_VALUES.PER_MONTH,
  includeCommentsInPrintout: false,
};

ProductComparisonTableComponent.propTypes = {
  variantsCombinations: PropTypes.arrayOf(OptionShape).isRequired,
  totalCostTerm: PropTypes.number,
  repaymentFrequency: PropTypes.string,
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  selectedFeaturesFromComparison: PropTypes.arrayOf(PropTypes.string),
  includeCommentsInPrintout: PropTypes.bool,
  onToggleIncludeCommentsInPrintout: PropTypes.func.isRequired,
  onStartVariantEditing: PropTypes.func.isRequired,
  onEditFeature: PropTypes.func.isRequired,
  onPreferredOptionChange: PropTypes.func,
};

export default ProductComparisonTableComponent;
