import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { ABS_LENDING_CODE } from 'constants.js';
import styles from './styles.module.scss';

export const PURCHASE = 'Purchase';
const CONSTRUCTION = 'Construction';
export const REFINANCE = 'Refinance';
const OTHER = 'Other';

const reasons = [
  { label: 'Convenience / Flexibility', value: 'Convenience / Flexibility' },
  { label: 'Consolidating or restructuring finances', value: 'Consolidating or restructuring finances' },
  { label: 'Dissatisfaction with service', value: 'Dissatisfaction with service' },
  { label: 'More competitive pricing', value: 'More competitive pricing' },
  { label: 'Specific features and products', value: 'Specific features and products' },
];
const purchaseDetails = [
  { label: ABS_LENDING_CODE['ABS-125'], value: 'ABS-125' },
  { label: ABS_LENDING_CODE['ABS-127'], value: 'ABS-127' },
  { label: ABS_LENDING_CODE['ABS-129'], value: 'ABS-129' },
  { label: ABS_LENDING_CODE['ABS-131'], value: 'ABS-131' },
  { label: ABS_LENDING_CODE['ABS-231'], value: 'ABS-231' },
];

const ownerOccupied = {
  [PURCHASE]: {
    details: purchaseDetails,
    reasons: [],
  },
  [CONSTRUCTION]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-121'], value: 'ABS-121' },
      { label: ABS_LENDING_CODE['ABS-123'], value: 'ABS-123' },
      { label: ABS_LENDING_CODE['ABS-327'], value: 'ABS-327' }],
    reasons: [],
  },
  [REFINANCE]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-133'], value: 'ABS-133' },
      { label: ABS_LENDING_CODE['ABS-239'], value: 'ABS-239' },
      { label: ABS_LENDING_CODE['ABS-348'], value: 'ABS-348' }],
    reasons,
  },
  [OTHER]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-221'], value: 'ABS-221' },
      { label: ABS_LENDING_CODE['ABS-223'], value: 'ABS-223' },
      { label: ABS_LENDING_CODE['ABS-225'], value: 'ABS-225' },
      { label: ABS_LENDING_CODE['ABS-227'], value: 'ABS-227' },
      { label: ABS_LENDING_CODE['ABS-229'], value: 'ABS-229' },
      { label: ABS_LENDING_CODE['ABS-237'], value: 'ABS-237' },
      { label: ABS_LENDING_CODE['ABS-241'], value: 'ABS-241' },
      { label: ABS_LENDING_CODE['ABS-349'], value: 'ABS-349' },
    ],
    reasons: [],
  },
};

const investment = {
  [PURCHASE]: {
    details: purchaseDetails,
    reasons: [],
  },
  [CONSTRUCTION]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-121'], value: 'ABS-121' },
      { label: ABS_LENDING_CODE['ABS-123'], value: 'ABS-123' },
      { label: ABS_LENDING_CODE['ABS-320'], value: 'ABS-320' }],
    reasons: [],
  },
  [REFINANCE]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-133'], value: 'ABS-133' },
      { label: ABS_LENDING_CODE['ABS-239'], value: 'ABS-239' },
      { label: ABS_LENDING_CODE['ABS-348'], value: 'ABS-348' },
    ],
    reasons,
  },
  [OTHER]: {
    details: [
      { label: ABS_LENDING_CODE['ABS-247'], value: 'ABS-247' },
      { label: ABS_LENDING_CODE['ABS-249'], value: 'ABS-249' },
    ],
    reasons: [],
  },
};

const createOption = name => ({ label: name, value: name });

const categoryOptions = [
    createOption(PURCHASE),
    createOption(CONSTRUCTION),
    createOption(REFINANCE),
    createOption(OTHER),
  ];

const toSelectValue = (value, options) => options.find(option => option.value === value) ?? null;

const getValue = (value, options, defaultValue) => {
  if (isNil(value)) {
    return defaultValue;
  }
  return toSelectValue(value, options);
};

const PurposeCategoryComponent = ({
                                    purpose,
                                    purposeCategory,
                                    purposeDetails,
                                    refinanceReason,
                                    onPurposeCategoryChange,
                                    onPurposeDetailsChange,
                                    onRefinanceReasonChange,
}) => {
  const selectedPurposeCategoryOption = useMemo(
    () => getValue(purposeCategory, categoryOptions, createOption(PURCHASE)),
    [purposeCategory],
  );

  const productPurpose = purpose === 'Owner Occupied'
    ? ownerOccupied[selectedPurposeCategoryOption.value]
    : investment[selectedPurposeCategoryOption.value];

  const selectedPurposeDetailsOption = useMemo(
    () => getValue(purposeDetails, productPurpose.details, null),
    [purposeDetails, productPurpose],
  );

  const selectedRefinanceReasonOption = useMemo(
    () => getValue(refinanceReason, productPurpose.reasons, null),
    [refinanceReason, productPurpose],
  );

  const showReason = !isEmpty(productPurpose.reasons);
  return (
    <div className={styles.purposeCategory}>
      <Select
        styles={{ container: (base) => ({ ...base, zIndex: 503 }) }}
        label="Purpose category"
        className={styles.select}
        onChange={selected => onPurposeCategoryChange(selected.value)}
        options={categoryOptions}
        value={selectedPurposeCategoryOption}
      />
      <Select
        label="Purpose details"
        className={styles.select}
        options={productPurpose.details}
        onChange={selected => onPurposeDetailsChange(selected.value)}
        value={selectedPurposeDetailsOption}
      />
      {showReason
      && (
      <Select
        label="Refinance reason"
        className={styles.select}
        options={productPurpose.reasons}
        onChange={selected => onRefinanceReasonChange(selected.value)}
        value={selectedRefinanceReasonOption}
      />
      )}
    </div>
   );
};
PurposeCategoryComponent.defaultProps = {
  purposeCategory: undefined,
  purposeDetails: undefined,
  refinanceReason: undefined,
};

PurposeCategoryComponent.propTypes = {
  purpose: PropTypes.string.isRequired,
  purposeCategory: PropTypes.string,
  purposeDetails: PropTypes.string,
  refinanceReason: PropTypes.string,
  onPurposeCategoryChange: PropTypes.func.isRequired,
  onPurposeDetailsChange: PropTypes.func.isRequired,
  onRefinanceReasonChange: PropTypes.func.isRequired,
};

export default PurposeCategoryComponent;
