import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/Modal/Modal';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import { getUserContactsByBusinessId } from 'shared/api';
import Select from 'shared/components/formFields/Select';
import styles from './ComparisonPreparerModal.module.scss';

const ComparisonPreparerModal = ({
 selectedBusinessId, downloadPdf, isOpen, setOpen, userProfile, isDownloading,
}) => {
  const [availableAdvisers, setAdvisers] = useState([userProfile]);
  const [selectedAdviser, selectAdviser] = useState(userProfile);
  const [isFetchingAdvisers, setFetching] = useState(false);

  useEffect(() => {
    if (isOpen && selectedBusinessId) {
      setFetching(true);
      getUserContactsByBusinessId(selectedBusinessId)
        .then((advisers) => {
          const otherUsers = advisers
            .filter(adviser => adviser.email !== userProfile.email)
            .sort((adviser1, adviser2) => (adviser1.username.toLowerCase() > adviser2.username.toLowerCase() ? 1 : -1));
          setAdvisers([userProfile, ...otherUsers]);
          selectAdviser(userProfile);
        })
        .finally(() => setFetching(false));
      }
    }, [selectedBusinessId, userProfile, isOpen]);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      header={(<div className={styles.header}>Comparison printout adviser name</div>)}
      footer={(
        <PrimaryButton
          className={styles.button}
          disabled={isDownloading}
          onClick={() => downloadPdf(selectedAdviser).then(() => setOpen(false))}
        >
          Download PDF
        </PrimaryButton>
      )}
    >
      <div>
        <div className={styles.label}>Which adviser would you like on the printout?</div>
        <Select
          className={styles.select}
          label=""
          value={({ label: selectedAdviser.username, value: selectedAdviser.email })}
          onChange={value => selectAdviser(availableAdvisers.find(adviser => adviser.email === value.value))}
          disabled={availableAdvisers.length <= 1 || isFetchingAdvisers}
          options={availableAdvisers.map(user => ({ label: user.username, value: user.email }))}
        />
      </div>
    </Modal>
  );
};

ComparisonPreparerModal.defaultProps = {
  isDownloading: false,
};

ComparisonPreparerModal.propTypes = {
  selectedBusinessId: PropTypes.string.isRequired,
  downloadPdf: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  isDownloading: PropTypes.bool,
};

export default ComparisonPreparerModal;
