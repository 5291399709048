import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import * as actions from '../actions';
import { FETCHING_STATUS } from '../../../../constants';
import { range } from '../../../../utils/datetime';

export const reducer = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_COMMISSION_SUMMARY_META_REQUEST:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.START,
      };

    case actions.FETCH_COMMISSION_SUMMARY_META_SUCCESS: {
      const {
        meta,
        loans,
        searchWindow: { start, end },
      } = action.data;

      if (!(start && end)) {
        return {
          ...state,
          meta,
          fetchingStatus: FETCHING_STATUS.SUCCESS,
        };
      }

      const loanListsByMonth = range(start, end, 'month')
      .map((m) => {
        const key = m.format('YYYY-MM');
        return {
          key,
          loans: loans.filter(loan => loan.settledDate.isValid() && loan.settledDate.format('YYYY-MM') === key),
        };
      });

      const loansByMonth = mapValues(
        mapKeys(loanListsByMonth, 'key'),
        'loans',
      );

      return {
        ...state,
        meta,
        loansByMonth,
        fetchingStatus: FETCHING_STATUS.SUCCESS,
      };
    }

    case actions.FETCH_COMMISSION_SUMMARY_META_ERROR:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.ERROR,
      };

    default:
      return state;
  }
};
