import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextArea from 'shared/components/formFields/ReduxForm/TextArea';
import styles from '../styles.module.scss';
import { getMaxLengthValidator } from '../../shared/utils';

const validateMaxLength = getMaxLengthValidator(2000);

const EditSOMAComments = ({ className }) => (
  <div className={className}>
    <h3>Funding worksheet SOMA comments</h3>

    <Field
      name="somaComments"
      component={TextArea}
      className={styles.somaComments}
      placeholder="This text will be displayed in the SOMA. Please include explanations for a surplus or shortfall if relevant."
      validate={validateMaxLength}
    />
  </div>
);

EditSOMAComments.propTypes = {
  className: PropTypes.string,
};

EditSOMAComments.defaultProps = {
  className: '',
};

export default EditSOMAComments;
