import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { useSelector } from 'react-redux';
import Icon from 'shared/components/Icon/Icon';
import ReduxFormOverrideText from 'shared/components/formFields/ReduxForm/OverrideText';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import find from 'lodash/find';
import isString from 'lodash/isString';
import classNames from 'classnames';
import ExternalInfoLink from 'shared/components/Links/ExternalInfoLink';
import ReduxFormYesNoSwitch from 'shared/components/formFields/ReduxForm/YesNoSwitch';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import styles from '../styles.module.scss';
import ConnectedSubtotalApplicationCosts from './SubtotalApplicationCosts';
import SubSection from '../components/SubSection';
import { getLenders, hasCostToPurchases } from '../../../redux/selectors';
import { LENDER_OPTIONS } from './lmiBaseLenders';
import { formatCurrency } from '../../../../../../utils/formatters';

const iconStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
};

const EditApplicationCosts = ({ form, userGuidanceCode }) => {
  const themeContext = useContext(ThemeContext);
  const createValueSelector = useMemo(() => (path) => state => formValueSelector(form)(state, path), [form]);
  const foreignPurchaser = useSelector(createValueSelector('applicationCosts.foreignPurchaser'));
  const eligiblePensioner = useSelector(createValueSelector('applicationCosts.eligiblePensioner'));
  const yesSelected = foreignPurchaser || eligiblePensioner;
  const lenders = useSelector(getLenders);
  const showStampDutyDropdown = useSelector(hasCostToPurchases);
  const lmiInsurerOptions = useMemo(() =>
    (LENDER_OPTIONS.map(o => (
      {
        label: o.name,
        value: find(lenders, { name: o.name, category: o.category })?.id,
      }
    )).filter(o => isString(o.value))),
   [lenders]);
  return (
    <div className={styles.applicationCosts}>
      <InformationSection
        wrapperStyle={styles.infoSection}
        contentWrapperStyle={styles.infoSectionContent}
        titleWrapperStyle={styles.titleWrapperStyle}
        title="Application costs"
      >
        <SubSection>
          <Field
            name="applicationCosts.applicationFee"
            component={ReduxFormNumberInput}
            label="Application fee"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="applicationCosts.fundsDisbursement"
            component={ReduxFormNumberInput}
            label="Disbursements"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="applicationCosts.valuationFee"
            component={ReduxFormNumberInput}
            label="Valuation"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="applicationCosts.legalFee"
            component={ReduxFormNumberInput}
            label="Legal costs"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="applicationCosts.mortgageRegistrationFee"
            component={ReduxFormOverrideText}
            className={styles.overrideTextField}
            label="Mortgage registration fee"
            allowNegative={false}
            clearButtonText={({ value }) => (`Return to system calculated value (${formatCurrency(value, { precision: 2 })})`)}
            clearable
          />
        </SubSection>
        <SubSection
          header="LMI"
          className={styles.lmiSubSection}
          headerClassName={styles.lmiHeader}
        >
          <ExternalInfoLink
            to="https://smartline.zendesk.com/hc/en-us/articles/360001900416"
            text="How is LMI calculated?"
            externalInfoLinkClassName={styles.externalInfoLink}
            textClassName={styles.externalInfoText}
          />
          <div className={styles.lmiField}>
            <Field
              name="applicationCosts.lmiReferencedLenderId"
              component={ReduxFormSelect}
              options={lmiInsurerOptions}
              label="LMI based on"
              isClearable
              className={classNames(styles.sideBySideSelectBox)}
            />
          </div>
          <Field
            name="applicationCosts.lmi"
            component={ReduxFormOverrideText}
            className={styles.lmiField}
            label="LMI"
            clearButtonText={({ value }) => (`Return to system calculated value (${formatCurrency(value, { precision: 2 })})`)}
            allowNegative={false}
            header="Edit LMI"
            clearable
          />
        </SubSection>
        {showStampDutyDropdown && (
          <SubSection className={styles.stampDutyContainer} header="Stamp duty">
            <Field
              name="applicationCosts.foreignPurchaser"
              component={ReduxFormYesNoSwitch}
              label="Foreign purchaser"
              props={{
                className: styles.sideBySideSwitch,
                switchedOnText: 'Yes',
                switchedOffText: 'No',
              }}
            />

            <Field
              name="applicationCosts.eligiblePensioner"
              component={ReduxFormYesNoSwitch}
              label="Eligible pensioner (discount)"
              props={{
                className: styles.sideBySideSwitch,
                switchedOnText: 'Yes',
                switchedOffText: 'No',
              }}
            />
            <Field
              name="applicationCosts.firstHomeBuyer"
              component={ReduxFormYesNoSwitch}
              label="First home buyer"
              props={{
                className: styles.sideBySideSwitch,
                switchedOnText: 'Yes',
                switchedOffText: 'No',
              }}
            />
            {yesSelected && (
              <div>
                <Icon name="info" size="1.3rem" color={themeContext.palette.textInformation} style={iconStyle} />
                <span className={styles.stampDutyInfo}>
                  Please use another stamp duty calculator for accurate figures
                  for foreign purchasers and eligible pensioner discounts
                </span>
              </div>
            )}
          </SubSection>
        )}
        <ConnectedSubtotalApplicationCosts userGuidanceCode={userGuidanceCode} />
      </InformationSection>
    </div>
  );
};

EditApplicationCosts.propTypes = {
  form: PropTypes.string.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

export default EditApplicationCosts;
