import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput';
import styles from './styles.module.scss';

const TotalLoanAmountComponent = ({ label, value }) => (
  <NumberInput
    label={label}
    value={value || ''}
    className={styles.label}
    textClassName={styles.inputText}
    disabled
  />
);

TotalLoanAmountComponent.defaultProps = {
  value: undefined,
};

TotalLoanAmountComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default TotalLoanAmountComponent;
