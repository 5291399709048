import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from '../../../constants/message';

export const updateLoanViaGraphql = (updateLoanRequest, loanId) => {
  const mutationOptions = {
    mutation: gql`
        mutation updateLoan($loanId: String!, $updateLoanRequest: UpdateLoanRequest!){
          updateLoan(id: $loanId loan: $updateLoanRequest)
        }
      `,
    variables: { loanId, updateLoanRequest },
  };
  return client.mutate(mutationOptions, { messageKey: MESSAGE_KEYS.UPDATE_LOAN });
};

export const getLoanViaGraphql = loanId =>
client.query({
  query: gql`{
      loanDetails(loanId: "${loanId}")
      {
          id
          applicationId
          accountNumber
          loanType
          settledDate
          repaidDate
          reconciledDate
          status
          productName
          amount
          purpose
          productType
          dateOfPayment
          repaymentType
          purposeCategory
          loanTerm
          fixedTerm
          lastKnownRate
          commercialProductType
          purposeDetails
          standardRate
          discountRate
          discountRateTerm
          baseRate
          clientDiscount
          approvalNumber
          isTopUp
          isActive
          combinedLoanAmount
          interestOnlyTerm
          brokerPlatformId
          applicants {
              companies {
                  id
                  externalLink
                  name
                  isPrimary
              }
              people {
                  id
                  externalLink
                  firstName
                  surname
                  email
                  home {
                      number
                      isPreferred
                  }
                  work {
                      number
                      isPreferred
                  }
                  mobile {
                      number
                      isPreferred
                  }
                  isPrimary
              }
          }
          assetFinanceLender{
              id,
              name
          }
          lender {
              lenderId,
              name,
              bankPortalForBroker
          }
          adviser {
              id
              firstName
              surname
          }
          referrer {
              isExistingClient
              referralMainCategory
              referralSubCategory
              referralSource
          }
          commissions {
              contractNumber
              currentBalance
              excludeFromSafetyNet
              fixedTrailEndDate
              fixedTrailPercentage
              fixedTrailStartDate
              internallyReferred
              isTrailExpected
              isUpfrontExpected
              originationFee
              commissionExcludingOriginationFee
              referringAdviser
              actualBusinessUpfront
              upfrontPercentage
              commissionLoanAmount
              expectedBusinessUpfront
              upfrontPercentageOverride
              settledAmount
              expectedUpfrontOverride
              expectedUpfront
          }
          refinancedFrom {
              id
              accountNumber
          }
          refinancedTo {
              id
              accountNumber
          }
          splitLoans {
              id
              accountNumber
              amount
              settledDate
              splitFrom
          }
      }
  }`,
});
