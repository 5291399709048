import { combineReducers } from 'redux';

import commissions from 'MyBusiness/components/Commissions/redux/commissionsReducer';
import permissionSettings from 'MyBusiness/components/PermissionSettings/permissionSettingsRedux';
import commissionManagementReports from 'MyBusiness/components/CommissionManagementReports/redux/commissionManagementReportsRedux';
import invoices from 'MyBusiness/components/Invoices/invoicesRedux';
import commissionQuery from 'MyBusiness/components/CommissionQuery/commissionQueryRedux';
import taskListTemplate from 'MyBusiness/components/TemplateManagement/TaskListTemplate/redux/templateReducer';

export default combineReducers({
  commissions,
  permissionSettings,
  commissionManagementReports,
  invoices,
  commissionQuery,
  taskListTemplate,
});
