import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import EssentialDatesPanel from './EssentialDatesPanel';
import { getUTCNow, toUTCStr } from '../../../../../utils/datetime';
import { updateStatusDatetime, updateApplicationAbstract } from '../../redux';
import { usePromise } from '../../../../../shared/hooks/usePromise';
import { isApplicationLaunchpadCreated } from '../../../../utils';
import RecordReasonPopup from '../../../RecordReasonPopup/RecordReasonPopup';
import ValidationConfirmSettlementPopup from '../../../ValidationConfirmSettlementPopup';
import ModalWithButtons from '../../../../../shared/components/Modal/ModalWithButtons';
import styles from './EssentialDates.module.scss';
import Icon from '../../../../../shared/components/Icon/Icon';
import { applicationStatus as applicationStatusMap } from '../../../../constants/applicationStatus';
import { hasAdditionalEditPermissionSelector, hasEditPermissionSelector } from '../../redux/selectors/permissions';
import showConfetti from '../../../../../utils/showConfetti';

const {
  SETTLED,
} = applicationStatusMap;

const notesButtonStyle = {
  height: '3rem',
  minWidth: '16rem',
  padding: '0',
};

const doneButtonStyle = {
  height: '3rem',
  minWidth: '10rem',
  padding: '0',
};

export const EssentialDates = ({ hasEditPermission: hasApplicationEditPermission, hasAdditionalEditPermission }) => {
  const application = useSelector(state => get(state, 'application.applicationDetail'));
  const launchpadCreated = isApplicationLaunchpadCreated(application.source);
  const applicationStatus = useSelector(state => get(state, 'application.applicationStatus'));
  const {
    id: applicationId,
    notesLink,
    isOwnerOccupied,
    preApprovalExpiryDate,
    financeDueDate,
    expectedSettlementDate,
  } = application;
  const dueDates = {
    PRE_APPROVAL_EXPIRY: preApprovalExpiryDate,
    FINANCE_DUE: financeDueDate,
    EXPECTED_SETTLEMENT: expectedSettlementDate,
  };
  const { isApplicationSettled, actionTimelineMap, applyOnlineTimelineMap } = applicationStatus ?? {};
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const updateStatus = useCallback(({ statusDateTime, reason, milestoneType }) => {
    const formData = {
      applicationId,
      actionDateTime: getUTCNow(),
      statusDateTime: isNil(statusDateTime) ? '' : toUTCStr(statusDateTime),
      reason: reason ?? '',
      milestoneType,
    };
    setIsUpdating(true);
    return dispatch(updateStatusDatetime(formData)).finally(() => {
      setIsUpdating(false);
    });
  }, [applicationId, dispatch, setIsUpdating]);

  const updateDueDateTime = useCallback(({ dueDateKey, dueDateTime }) => {
    const formData = {
      applicationId,
      dueDateTime: isNil(dueDateTime) ? '' : toUTCStr(dueDateTime),
      dueDateKey,
    };
    setIsUpdating(true);
    const dueDatesKeyFieldsMap = {
      PRE_APPROVAL_EXPIRY: 'preApprovalExpiryDate',
      FINANCE_DUE: 'financeDueDate',
      EXPECTED_SETTLEMENT: 'expectedSettlementDate',
    };
    const data = {};
    data[dueDatesKeyFieldsMap[dueDateKey]] = formData.dueDateTime;
    return dispatch(updateApplicationAbstract(applicationId, data)).finally(() => {
      setIsUpdating(false);
    });
  }, [applicationId, dispatch, setIsUpdating]);

  const [recordReasonModalState, setRecordReasonModalState] = useState({ isOpen: false });
  const [recordReasonAndSave, resolveRecordReasonModal, rejectRecordReasonModal] = usePromise(() => (args) => {
    setRecordReasonModalState({ isOpen: true, ...args });
    const { statusDateTime } = args;
    return async (resolved, { reason, milestoneType }) => {
      if (resolved) {
        await updateStatus({ reason, milestoneType, statusDateTime });
        setRecordReasonModalState({ isOpen: false });
      } else {
        setRecordReasonModalState({ isOpen: false });
      }
    };
  }, [setRecordReasonModalState, updateStatus]);

  const [showReminderModal, toggleReminderModal] = useState(false);
  const [remind, resolveReminder] = usePromise(() => () => {
    toggleReminderModal(true);
    return () => {
      toggleReminderModal(false);
    };
  }, [toggleReminderModal]);

  const [settlementConfirmModalState, setSettlementConfirmModalState] = useState({ isOpen: false });
  const [confirmAndSettle, resolveConfirmAndSettleModal, rejectConfirmAndSettleModal] = usePromise(() => (args) => {
    setSettlementConfirmModalState({ isOpen: true, ...args });
    return async (resolved, { statusDateTime }) => {
      if (resolved) {
        await updateStatus({ statusDateTime, milestoneType: SETTLED });
        setSettlementConfirmModalState({ isOpen: false });
        showConfetti();
        if (!launchpadCreated && isOwnerOccupied) {
          remind();
        }
      } else {
        setSettlementConfirmModalState({ isOpen: false });
      }
    };
  }, [setSettlementConfirmModalState, isOwnerOccupied, remind]);

  const openLink = useCallback(() => window.open(notesLink, '_blank'), [notesLink]);

  return (
    <>
      {recordReasonModalState.isOpen && (
        <RecordReasonPopup
          onSubmit={resolveRecordReasonModal}
          onClose={rejectRecordReasonModal}
          status={{
            name: recordReasonModalState.milestoneType,
            reason: recordReasonModalState.reason,
          }}
          disableType
          disabled={isUpdating}
        />
      )}
      {settlementConfirmModalState.isOpen && (
        <ValidationConfirmSettlementPopup
          applicationId={application.id}
          lenderId={get(application, 'lender.lenderId')}
          actionTimelineMap={applicationStatus.actionTimelineMap}
          applicants={get(application, 'applicants')}
          closeModal={rejectConfirmAndSettleModal}
          submitSettlement={resolveConfirmAndSettleModal}
          {...settlementConfirmModalState}
        />
      )}
      <ModalWithButtons
        isOpen={showReminderModal}
        header="Update loan address"
        className={styles.notesReminderWapper}
        onRequestClose={resolveReminder}
        submitText={<div className={styles.notesButton}>Open Lotus Notes <Icon name="share" color="#ffffff" size="1.8rem" /></div>}
        submitButtonProps={{ style: notesButtonStyle, onClick: openLink }}
        secondarySubmitText={<div className={styles.notesDoneButton}>Done</div>}
        secondaryButtonPros={{ style: doneButtonStyle, onClick: resolveReminder }}
      >
        <div className={styles.notesReminderContents}>
          Please make sure the property address is
          correct in Lotus Notes before settling this
          Owner Occupied application.
        </div>
      </ModalWithButtons>
      <EssentialDatesPanel
        disabled={isUpdating}
        applicationSettled={isApplicationSettled}
        actionTimelineMap={actionTimelineMap}
        applyOnlineTimelineMap={applyOnlineTimelineMap}
        launchpadCreated={launchpadCreated}
        onRecordReasonAndSave={recordReasonAndSave}
        onConfirmAndSettle={confirmAndSettle}
        onUpdateStatus={updateStatus}
        onUpdateDueDateTime={updateDueDateTime}
        hasApplicationEditPermission={hasApplicationEditPermission}
        hasAdditionalEditPermission={hasAdditionalEditPermission}
        dueDates={dueDates}
      />
    </>
  );
};

EssentialDates.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  hasAdditionalEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hasEditPermission: hasEditPermissionSelector(state),
  hasAdditionalEditPermission: hasAdditionalEditPermissionSelector(state),
});

export default connect(mapStateToProps)(EssentialDates);
