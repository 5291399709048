import React from 'react';
import PropTypes from 'prop-types';
import toLower from 'lodash/toLower';

import Select from 'shared/components/formFields/Select';
import styles from './ApplicationsFilter.module.scss';

const inputCommonStyle = {
  menu: originalStyles => ({
    ...originalStyles,
    zIndex: 11,
  }),
};

const fields = [
  {
    key: 'applicant',
    label: 'Applicant',
    placeholder: 'Any',
    styles: inputCommonStyle,
    maxMenuHeight: 164,
  },
  {
    key: 'lender',
    label: 'Lender',
    placeholder: 'Any',
    styles: inputCommonStyle,
    maxMenuHeight: 164,
  },
  {
    key: 'applicationNumber',
    label: 'Application number',
    placeholder: 'Any',
    styles: inputCommonStyle,
    maxMenuHeight: 164,
  },
  {
    key: 'adviser',
    label: 'Adviser',
    placeholder: 'Any',
    styles: inputCommonStyle,
    maxMenuHeight: 164,
  },
];

const filterOption = ({ label }, text) => (toLower(label).includes(toLower(text)));

const ApplicationsFilter = ({
 conditions, options, onConditionsChange, disableFilter,
}) => (
  <div className={styles.applicationsFilter}>
    <div className={styles.filterHeader}>
      <h2>
        Filter applications
      </h2>
    </div>
    <div className={styles.fieldsContainer}>
      {fields.map(({ key, ...others }) => (
        <div key={key} className={styles.field}>
          <Select
            {...others}
            className={styles.filterItem}
            filterOption={filterOption}
            disabled={disableFilter}
            onChange={(value) => { onConditionsChange({ [key]: value }); }}
            options={options[key] || []}
            value={conditions[key]}
          />
        </div>
      ))}
    </div>
  </div>
);

ApplicationsFilter.propTypes = {
  conditions: PropTypes.shape({
    applicant: PropTypes.object,
    lender: PropTypes.object,
    applicationNumber: PropTypes.object,
    adviser: PropTypes.object,
  }).isRequired,
  options: PropTypes.shape({
    applicant: PropTypes.array,
    lender: PropTypes.array,
    applicationNumber: PropTypes.array,
    adviser: PropTypes.array,
  }).isRequired,
  onConditionsChange: PropTypes.func.isRequired,
  disableFilter: PropTypes.bool.isRequired,
};

export default ApplicationsFilter;
