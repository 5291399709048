import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import Popover from 'shared/components/NavbarMenu/Popover';
import styles from './styles.module.scss';
import MultipleSelectFilterContent from './MultipleSelectFilterContent';

const MultipleSelectFilter = ({
 filter, disabled, onFilter, options, getFilterName, placeholder, className,
}) => {
  const [filterName, updateFilterName] = useState(getFilterName(filter));

  const contentRef = useRef(null);
  const triggerRef = useRef(null);

  const isChildTarget = target => (
    contentRef?.current?.contains(target)
    || triggerRef?.current?.contains(target)
  );

  const handleOnBlur = (event, toggle) => {
    if (event.relatedTarget && !isChildTarget(event.relatedTarget)) {
      toggle(false);
    }
  };

  return (
    <Popover
      className={className}
      trigger={toggle => (
        <div ref={triggerRef}>
          <TextInput
            onFocus={() => toggle(true)}
            onBlur={(event) => {
              handleOnBlur(event, toggle);
            }}
            mini
            fullWidth
            value={filterName}
            placeholder={placeholder}
            disabled={disabled}
            className={styles.statusInput}
          />
        </div>
      )}
    >
      {(toggle, isShown) => (isShown && (
        <MultipleSelectFilterContent
          ref={contentRef}
          onBlur={(event) => {
            handleOnBlur(event, toggle);
          }}
          filter={filter}
          onFilter={(selection) => {
            updateFilterName(getFilterName(selection));
            onFilter(isEmpty(selection) ? undefined : { value: selection });
            toggle();
          }}
          options={options}
        />
      ))}
    </Popover>
  );
};

MultipleSelectFilter.defaultProps = {
  disabled: false,
  placeholder: 'Filter...',
  className: undefined,
};

MultipleSelectFilter.propTypes = {
  disabled: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.object.isRequired,
  getFilterName: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default MultipleSelectFilter;
