import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Field } from 'redux-form';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import styles from '../styles.module.scss';

export const GET_ALL_LENDERS = gql`
  query allLenders {
    allLenders {
      name
    }
  }
`;

const getLenderNameOptions = (loading, error, data) => {
  if (loading || error || !data) {
    return undefined;
  }

  return map(
    sortBy(data.allLenders, ({ name }) => name.toLowerCase()),
    ({ name }) => ({ label: name, value: name }),
  );
};

const LenderSelect = ({
  name,
}) => {
  const { loading, error, data } = useQuery(GET_ALL_LENDERS);
  const lenderNames = useMemo(
    () => getLenderNameOptions(loading, error, data),
    [loading, error, data],
  );

  return (
    <Field
      name={name}
      component={ReduxFormSelect}
      props={{
        label: 'Lender of loan to be topped up',
        options: lenderNames,
        isRequired: true,
      }}
      className={styles.criteriaSelectField}
    />
  );
};

LenderSelect.defaultProps = {
  name: undefined,
};

LenderSelect.propTypes = {
  name: PropTypes.string,
};

export default LenderSelect;
