import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import stylePropType from 'react-style-proptype';
import PropTypes from 'prop-types';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import { formatBool } from 'shared/formatterUtils';
import { formatAmount } from 'utils/formatters';
import isNil from 'lodash/isNil';
import { getUpfrontFields } from 'Loans/components/LoanDetails/LoanDetailsContent/Commissions/sections/upfrontFields';
import { getTrailFields } from 'Loans/components/LoanDetails/LoanDetailsContent/Commissions/sections/trailFields';
import { getFixedTrailFields } from 'Loans/components/LoanDetails/LoanDetailsContent/Commissions/sections/fixedTrailFields';
import styles from './index.module.scss';

const formatAmountWithExGST = amount =>
  (isNil(amount) ? '' : `${formatAmount(amount)} (ex. GST)`);

const getInternallyReferredAssetFinanceFields = ({
  internallyReferred,
  referringAdviser,
  contractNumber,
  commissionExcludingOriginationFee,
  originationFee,
}) => [
  {
    title: 'Internally referred loan?',
    content: formatBool(internallyReferred),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Referring adviser',
    content: referringAdviser,
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Contract number',
    content: contractNumber,
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Commission amount (ex. origination fee)',
    content: formatAmountWithExGST(commissionExcludingOriginationFee),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  },
  {
    title: 'Origination fee',
    content: formatAmountWithExGST(originationFee),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
    className: styles.originationFee,
  },
];

const Commissions = ({
 commissions, style, reconciledDatePresent,
}) => (
  <div style={style}>
    <InformationSection title="">
      <FieldsContainer
        fields={getUpfrontFields(commissions, reconciledDatePresent)}
        title="Upfront"
        fieldsWrapperClassName={styles.detailsGrid}
      />
      <FieldsContainer
        fields={getTrailFields(commissions, reconciledDatePresent)}
        title="Trail"
        fieldsWrapperClassName={styles.detailsGrid}
      />
      <FieldsContainer
        fields={getFixedTrailFields(commissions)}
        title="Fixed trail"
        fieldsWrapperClassName={styles.detailsGrid}
      />
      {commissions.internallyReferred && (
      <FieldsContainer
        fields={getInternallyReferredAssetFinanceFields(commissions)}
        title="Internally referred asset finance"
      />
    )}
    </InformationSection>
  </div>
);

const mapStateToProps = state => ({
  commissions: get(state, 'loans.loan.data.commissions'),
  isEditing: get(state, 'loans.loan.isEditing', false),
  reconciledDatePresent: !!get(state, 'loans.loan.data.reconciledDate', false),
});

Commissions.defaultProps = { style: {} };
Commissions.propTypes = {
  style: stylePropType,
  reconciledDatePresent: PropTypes.bool.isRequired,
  commissions: PropTypes.shape({
    isUpfrontExpected: PropTypes.bool,
    isTrailExpected: PropTypes.bool,
    currentBalance: PropTypes.number,
    fixedTrailPercentage: PropTypes.number,
    fixedTrailStartDate: PropTypes.string,
    fixedTrailEndDate: PropTypes.string,
    excludeFromSafetyNet: PropTypes.bool,
    internallyReferred: PropTypes.bool,
    referringAdviser: PropTypes.string,
    contractNumber: PropTypes.string,
    commissionExcludingOriginationFee: PropTypes.number,
    originationFee: PropTypes.number,
    actualBusinessUpfront: PropTypes.number,
    upfrontPercentage: PropTypes.number,
    commissionLoanAmount: PropTypes.number,
    expectedBusinessUpfront: PropTypes.number,
    upfrontPercentageOverride: PropTypes.number,
    settledAmount: PropTypes.number,
    expectedUpfrontOverride: PropTypes.number,
  }).isRequired,
};

export default connect(mapStateToProps)(Commissions);
