import concat from 'lodash/concat';
import upperCase from 'lodash/upperCase';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { applicationStatus } from 'ApplicationTracking/constants/applicationStatus';
import { downloadInternallyReferredAssetFinanceXlsx, downloadLenderReconciliationXlsx } from 'shared/api';
import { openInTab } from 'shared/utils';
import * as api from './api';
import * as types from './actions';
import { loanFormatter } from '../utils';
import { showToast } from '../../../redux/toast/actions';
import { getDateRange } from '../utils/commissionSummaryDateHelper';

const searchDateFormat = 'YYYY-MM-DD';

export const retrieveCommissionSummary = (businessId) => (dispatch) => {
  dispatch({ type: types.FETCH_COMMISSION_SUMMARY_META_REQUEST });

  api.retrieveCommissionSummaryMeta(businessId)
    .then(
      ({ data: { commissionSummaryMeta } }) => {
        const latestDate = commissionSummaryMeta.currentYearLoanBookSize?.date;
        if (isEmpty(latestDate)) {
          dispatch({
            type: types.FETCH_COMMISSION_SUMMARY_META_SUCCESS,
            data: {
              meta: commissionSummaryMeta,
              loans: [],
              searchWindow: {},
            },
          });
        } else {
          const { start, end } = getDateRange(latestDate);
          retrieveAllLoanViews(businessId, start, end, commissionSummaryMeta)(dispatch);
        }
      },
    )
    .catch(
      (err) => {
        dispatch({ type: types.FETCH_COMMISSION_SUMMARY_META_ERROR });
        return Promise.reject(err);
      },
    );
};

const retrieveAllLoanViews = (businessId, start, end, commissionSummaryMeta) => (dispatch) => (
   api.retrieveAllLoanViews(
    businessId,
    start.format(searchDateFormat),
    end.format(searchDateFormat),
    ['submittedDate', 'settledDate'],
  ).then(
    ({ data: { allLoanViews } }) => {
      dispatch({
        type: types.FETCH_COMMISSION_SUMMARY_META_SUCCESS,
        data: {
          meta: commissionSummaryMeta,
          loans: allLoanViews.map(loanFormatter),
          searchWindow: { start, end },
        },
      });
    },
  ).catch((err) => {
    dispatch({ type: types.FETCH_COMMISSION_SUMMARY_META_ERROR });
    return Promise.reject(err);
  })
);

export const retrieveLoanViewsForSubmissionAndSettlementReport = (businessId, start, end) => (dispatch) => {
  dispatch({ type: types.FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_REQUEST });
  const searchStart = start.clone().subtract(1, 'year');
  return api.retrieveLoanViews(
    businessId,
    searchStart.format(searchDateFormat),
    end.format(searchDateFormat),
    ['submittedDate', 'settledDate'],
  ).then(({ data: { loanViews } }) => {
    dispatch({
      type: types.FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_SUCCESS,
      data: {
        loans: loanViews.map(loanFormatter),
        searchWindow: { start: searchStart, end },
        reportWindow: {
          start,
          end,
        },
      },
    });
    return loanViews;
  }).catch((err) => {
    dispatch({ type: types.FETCH_LOANS_FOR_SUBMISSION_AND_SETTLEMENT_REPORT_ERROR });
    return Promise.reject(err);
  });
};

export const retrieveLoanViewsForSettlementSummaryReport = (businessId) => (dispatch) => {
  dispatch({ type: types.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_REQUEST });
  const end = moment();
  const start = moment(end.format('YYYY-MM')).subtract(1, 'year');
  return api.retrieveLoanViews(
    businessId,
    start.format(searchDateFormat),
    end.format(searchDateFormat),
    ['settledDate'],
  ).then(({ data: { loanViews } }) => {
    dispatch({
      type: types.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_SUCCESS,
      data: {
        loans: loanViews.map(loanFormatter),
        reportWindow: {
          start,
          end,
        },
      },
    });
    return loanViews;
  }).catch((err) => {
    dispatch({ type: types.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_ERROR });
    return Promise.reject(err);
  });
};

const isDeclinedOrWithdraw = status => (
  [applicationStatus.DECLINED, applicationStatus.WITHDRAWN].includes(upperCase(status))
);

export const retrieveLoanViewsForConversionRateReport = businessId => (dispatch) => {
  const end = moment().endOf('month');
  const start = end.clone().subtract(23, 'month').startOf('month');

  dispatch({ type: types.FETCH_LOANS_FOR_CONVERSION_RATE_REQUEST });
  const fetchTask = api.retrieveLoanViewsForConversionRate(
    businessId,
    start.format(searchDateFormat),
    end.format(searchDateFormat),
  );

  return fetchTask.then(({ data: { loanViews } }) => {
    dispatch({
      type: types.FETCH_LOANS_FOR_CONVERSION_RATE_SUCCESS,
      data: {
        loans: loanViews,
        searchWindow: { start, end },
      },
    });
  }).catch(() => {
    dispatch({
      type: types.FETCH_LOANS_FOR_CONVERSION_RATE_ERROR,
    });
  });
};

export const retrieveLoanViewsForExpectedSettlementReport = businessId => (dispatch) => {
  const start = moment().startOf('month');
  const end = start.clone().add(3, 'month').endOf('month');
  const previousYearStart = start.clone().subtract(1, 'year');
  const previousYearEnd = end.clone().subtract(1, 'year');
  dispatch({ type: types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_REQUEST });
  const thisYearFetchTask = api.retrieveLoanViews(
    businessId,
    start.format(searchDateFormat),
    end.format(searchDateFormat),
    ['settledDate', 'expectedSettlementDate'],
  );
  const previousYearFetchTask = api.retrieveLoanViews(
    businessId,
    previousYearStart.format(searchDateFormat),
    previousYearEnd.format(searchDateFormat),
    ['settledDate', 'expectedSettlementDate'],
  );
  return Promise.all([thisYearFetchTask, previousYearFetchTask]).then(([
    { data: { loanViews: loanViewsForThisYear } },
    { data: { loanViews: loanViewsForPreviousYear } },
                                                                       ]) => {
    const allLoans = concat(loanViewsForThisYear, loanViewsForPreviousYear).map(loanFormatter);
    const filteredLoans = allLoans.filter(({ expectedSettlementDate: date, applicationStatus: status }) =>
      date.isValid()
      && !isDeclinedOrWithdraw(status)
      && (
        date.isBetween(start, end, undefined, '[]')
        || date.isBetween(previousYearStart, previousYearEnd, undefined, '[]')
      ));
    dispatch({
      type: types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_SUCCESS,
      data: {
        loans: filteredLoans,
        searchWindow: {
          start: previousYearStart,
          end,
        },
        reportWindow: {
          start,
          end,
        },
      },
    });
  }).catch(() => {
    dispatch({
      type: types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_ERROR,
    });
  });
};

export const retrieveReportingMeta = businessId => (dispatch) => {
  dispatch({ type: types.FETCH_REPORTING_META_REQUEST });
  return api.retrieveReportingMeta(businessId).then(({ data: { reportingMeta } }) => {
    dispatch({
      type: types.FETCH_REPORTING_META_SUCCESS,
      data: reportingMeta,
    });
    return reportingMeta;
  }).catch(() => {
    dispatch({ type: types.FETCH_REPORTING_META_ERROR });
  });
};

export const downloadLenderReconciliationReport = () => async (dispatch) => {
  dispatch({
    type: types.DOWNLOAD_LENDER_RECONCILIATION_REPORT_START,
  });
  try {
    const processingMonth = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    const xlsxBlob = await downloadLenderReconciliationXlsx(processingMonth);
    openInTab(
      xlsxBlob,
      `Lender Reconciliation Report ${processingMonth.substring(0, 7)}.xlsx`,
    );
  } catch (error) {
    dispatch(
      showToast(
        'There was a problem while downloading your xlsx. Please try again later.',
        { type: 'error' },
      ),
    );
  } finally {
    dispatch({ type: types.DOWNLOAD_LENDER_RECONCILIATION_REPORT_FINISHED });
  }
};

export const downloadInternallyReferredAssetFinanceReport = () => async (dispatch) => {
  dispatch({
    type: types.DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_START,
  });
  try {
    const xlsxBlob = await downloadInternallyReferredAssetFinanceXlsx();
    openInTab(
      xlsxBlob,
      'Asset Finance.xlsx',
    );
  } catch (error) {
    dispatch(
      showToast(
        'There was a problem while downloading your xlsx. Please try again later.',
        { type: 'error' },
      ),
    );
  } finally {
    dispatch({ type: types.DOWNLOAD_INTERNALLY_REFERRED_ASSET_FINANCE_REPORT_END });
  }
};
