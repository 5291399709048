import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import HeaderSelect from '../../../../../../ProductSearch/ProductsTable/HeaderSelect';
import { REPAYMENT_FREQUENCIES } from '../../../../../../constants';

export const REPAYMENT_FREQUENCIES_ORDER = [
  'PER_WEEK',
  'PER_QUARTER_MONTH',
  'PER_FORTNIGHT',
  'PER_HALF_MONTH',
  'PER_MONTH',
  'PER_SIX_MONTHS',
  'PER_ANNUM',
];

const REPAYMENT_FREQUENCIES_OPTIONS = REPAYMENT_FREQUENCIES_ORDER.map(value =>
  ({ value, label: REPAYMENT_FREQUENCIES[value] }));

const repaymentFrequencySelector = state => get(state, 'application.productSearch.searchCriteria.repaymentFrequency');

const RepaymentFrequencyColumnHeader = ({ onSelectRepaymentFrequency }) => {
  const repaymentFrequency = useSelector(repaymentFrequencySelector);
  return (
    <HeaderSelect
      selectedValue={repaymentFrequency}
      classNamePrefix="repayment-frequency"
      onChange={onSelectRepaymentFrequency}
      menuPortalTarget={document.body}
      options={REPAYMENT_FREQUENCIES_OPTIONS}
    />
  );
};

RepaymentFrequencyColumnHeader.propTypes = {
  onSelectRepaymentFrequency: PropTypes.func.isRequired,
};

export default React.memo(RepaymentFrequencyColumnHeader);
