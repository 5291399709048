import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DateBox from 'devextreme-react/date-box';
import isEqual from 'lodash/isEqual';
import styles from './DueDateRow.module.scss';
import { applicationDueDates } from '../../../../../constants/applicationStatus';
import { MEDIA_TYPES } from '../../../../../../constants/media';
import useMediaType from '../../../../../../shared/hooks/useMediaType';
import { ToastContext } from '../../../../../../shared/components/ToastProvider/ToastProvider';

const getDueDateName = (key, mediaType) => {
  const name = applicationDueDates[key];
  return mediaType === MEDIA_TYPES.DESKTOP ? name : <h3>{name}</h3>;
};

const DueDateRow = ({
                      dueDateKey,
                      date,
                      editable,
                      disabled,
                      onUpdateDueDateTime,
                      applicationCreateTime,
                    }) => {
  const { toast: showToast } = useContext(ToastContext);
  const mediaType = useMediaType();
  const dueDateName = getDueDateName(dueDateKey, mediaType);

  const onValueChanged = ({
                            value, component, previousValue, event,
                          }) => {
    if (!event || !applicationCreateTime) return;
    if (value === null && date === null) return;
    if (isEqual(new Date(value), new Date(date))) return;
    if (value && new Date(applicationCreateTime).getTime() > new Date(value).getTime()) {
      const errorMessage = 'Please select a more recent date and time. It cannot be before the time and date of application created.';
      showToast(errorMessage, { type: 'error' });
      const componentValue = previousValue ? new Date(previousValue) : null;
      component.dateValue(componentValue, event);
      return;
    }
    setTimeout(async () => {
      try {
        await onUpdateDueDateTime(dueDateKey, {
          dueDateTime: value,
        });
      } catch {
        component.dateValue(previousValue);
      }
    }, 0);
  };

  const isIphoneOrIpodOrIpad = navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/iPad/i);

  const triggerByBlurConfig = isIphoneOrIpodOrIpad
    ? { valueChangeEvent: 'blur' }
    : { valueChangeEvent: 'change' };
  const renderDueDateManualCellContent = () => (
    <DateBox
      defaultValue={date}
      displayFormat="d MMMM yyyy h:mm a"
      type="datetime"
      applyButtonText="Save"
      placeholder="Select a date and a time"
      invalidDateMessage="Please select a date and a time"
      useMaskBehavior
      validationMessageMode="always"
      disabled={!editable || disabled}
      width="100%"
      onValueChanged={onValueChanged}
      elementAttr={{
        'data-cy': `data-cy-${dueDateKey}`,
       }}
      {...triggerByBlurConfig}
    />
    );
  return (
    <div className={styles.dueDate} key={dueDateKey}>
      <div className={styles.dueDateCell}>
        <div className={classNames(styles.dueDateCellContent, styles.dateName)}>
          {dueDateName}
        </div>
      </div>
      <div className={styles.dueDateManualCell}>
        <div className={styles.dueDateManualCellTitle}>Date</div>
        <div className={styles.dueDateManualCellContent}>
          {renderDueDateManualCellContent()}
        </div>
      </div>
    </div>
  );
};

DueDateRow.propTypes = {
  dueDateKey: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  date: PropTypes.string,
  onUpdateDueDateTime: PropTypes.func.isRequired,
  applicationCreateTime: PropTypes.any.isRequired,
};

DueDateRow.defaultProps = {
  date: undefined,
};

export default DueDateRow;
