import React, { useEffect } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, formValueSelector, isDirty } from 'redux-form';
import { FETCHING_STATUS } from '../../../../../../constants';
import { isSuccess } from '../../../../../../utils/actionStatusUtil';

const isDirtySelector = isDirty('fundingWorksheet');
const formSelector = formValueSelector('fundingWorksheet');

const FieldsMapping = {
  mortgageRegistrationFee: 'applicationCosts.mortgageRegistrationFee.value',
  lmi: 'applicationCosts.lmi.value',
};

const CostToPurchasesFields = {
  titleTransferFee: (index) => `costToPurchases[${index}].titleTransferFee.value`,
  stampDutyOnTransferOfProperty: (index) => `costToPurchases[${index}].stampDutyOnTransferOfProperty.value`,
};

const CalculationUpdater = ({
  dirty,
  fundingWorksheetFees,
  formFundingWorksheetFees,
  dispatchChange,
}) => {
  useEffect(() => {
    if (dirty && isSuccess(fundingWorksheetFees.fetchingStatus)) {
      forEach(FieldsMapping, (field, key) => {
        if (fundingWorksheetFees[key] !== formFundingWorksheetFees[key]) {
          dispatchChange(field, fundingWorksheetFees[key]);
        }
      });

      const costToPurchases = keyBy(fundingWorksheetFees.costToPurchases, 'id');
      formFundingWorksheetFees.costToPurchases.forEach((formCostToPurchase, index) => {
        forEach(CostToPurchasesFields, (getField, key) => {
          const value = get(costToPurchases, [formCostToPurchase.id, key]);
          if (formCostToPurchase[key] !== value) {
            dispatchChange(getField(index), value);
          }
        });
      });
    }
  }, [
    dirty,
    fundingWorksheetFees,
    formFundingWorksheetFees,
    dispatchChange,
  ]);
  return (<></>);
};

const mapStateToProps = state => ({
  dirty: isDirtySelector(state),
  fundingWorksheetFees: get(state, 'application.fundingWorksheetCalculation.fees'),
  formFundingWorksheetFees: {
    lmi: formSelector(state, 'applicationCosts.lmi.value'),
    mortgageRegistrationFee: formSelector(state, 'applicationCosts.mortgageRegistrationFee.value'),
    costToPurchases: formSelector(state, 'costToPurchases')
    .map(c => ({
      id: c.id,
      titleTransferFee: c.titleTransferFee.value,
      stampDutyOnTransferOfProperty: c.stampDutyOnTransferOfProperty.value,
    })),
  },
});

const mapDispatchToProps = dispatch => ({
  dispatchChange: (field, value) => dispatch(change('fundingWorksheet', field, value)),
});

CalculationUpdater.defaultProps = {
  fundingWorksheetFees: {},
  formFundingWorksheetFees: {},
};

CalculationUpdater.propTypes = {
  fundingWorksheetFees: PropTypes.shape({
    lmi: PropTypes.number,
    mortgageRegistrationFee: PropTypes.number,
    costToPurchases: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      titleTransferFee: PropTypes.number,
      stampDutyOnTransferOfProperty: PropTypes.number,
    })),
    fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  }),
  formFundingWorksheetFees: PropTypes.shape({
    lmi: PropTypes.number,
    mortgageRegistrationFee: PropTypes.number,
    costToPurchases: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      titleTransferFee: PropTypes.number,
      stampDutyOnTransferOfProperty: PropTypes.number,
    })),
  }),
  dispatchChange: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationUpdater);
