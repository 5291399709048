import React from 'react';
import PropTypes from 'prop-types';
import { toTimeStr } from 'utils/datetime';
import styles from './StatusChangeContent.module.scss';
import BasicRichContent from '../BasicRichContent';

const StatusChangeContent = ({ status, actionTime }) => {
  const content = (
    <div>Application has changed status to&nbsp;
      <span className={styles.status}>{status}</span>
      {` at ${toTimeStr(actionTime)}`}
    </div>
);
  return (
    <BasicRichContent type="STATUS CHANGED" content={content} />
  );
};

StatusChangeContent.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  actionTime: PropTypes.string.isRequired,
};

export default StatusChangeContent;
