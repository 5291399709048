import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import getEditingInputPartial from 'shared/components/productSearch/ComparisonEditingModal/getEditingInputPartial';
import styles from 'shared/components/productSearch/ComparisonEditingModal/styles';

const ComparisonEditingModal = React.memo(({
  isEditing,
  isLoading,
  currentModification,
  style,
  dispatchCancelVariantEditing,
  dispatchVariantModificationUpdateValue,
  dispatchApplyModification,
  hasError,
}) => {
  const applyButtonOrLoadingSpinner = isLoading
    ? (<LoadingSpinner style={styles.loading} />)
    : (
      <PrimaryButton
        style={styles.applyButton}
        onClick={dispatchApplyModification}
      >
        Apply
      </PrimaryButton>
    );

  return (
    <Modal
      isOpen={isEditing}
      shouldCloseOnOverlayClick
      onRequestClose={dispatchCancelVariantEditing}
      style={{ ...styles.editModalStyle, ...style }}
      ariaHideApp={false}
    >
      <div style={styles.editingContentContainer}>
        <div style={styles.editingContentTitle}>
          Edit field
        </div>
        <button
          onClick={dispatchCancelVariantEditing}
          type="button"
          style={styles.crossButton}
        >
          &#x2715;
        </button>
      </div>
      {getEditingInputPartial(currentModification, dispatchVariantModificationUpdateValue)}
      <div style={styles.editingButtonsSection}>
        {hasError && (
          <p style={styles.errorMessage}>
            Please input valid number
          </p>
        )}
        {applyButtonOrLoadingSpinner}
      </div>
    </Modal>
  );
});

ComparisonEditingModal.propTypes = {
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentModification: PropTypes.shape({
    variant: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    optionIndex: PropTypes.number.isRequired,
    splitIndex: PropTypes.number.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  dispatchCancelVariantEditing: PropTypes.func.isRequired,
  dispatchVariantModificationUpdateValue: PropTypes.func.isRequired,
  dispatchApplyModification: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

ComparisonEditingModal.defaultProps = {
  isEditing: false,
  isLoading: false,
  currentModification: {
    variant: {},
    fieldName: '',
    optionIndex: 0,
    splitIndex: 0,
  },
  style: {},
  hasError: false,
};

export default ComparisonEditingModal;
