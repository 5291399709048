import { getActiveNotifications } from 'shared/api';
import { createReducer } from '../utils/reducerGenerator';

export const ACTIONS = {
  FETCH_NOTIFICATION_START: 'FETCH_NOTIFICATION_START',
  FETCH_NOTIFICATION_SUCCESS: 'FETCH_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_ERROR: 'FETCH_NOTIFICATION_ERROR',
};

export const getNotifications = () => (dispatch) => {
  dispatch({ type: ACTIONS.FETCH_NOTIFICATION_START });
  return getActiveNotifications()
    .then(notifications => dispatch({
      type: ACTIONS.FETCH_NOTIFICATION_SUCCESS,
      list: notifications.data,
    }))
    .catch(err => dispatch({
      type: ACTIONS.FETCH_NOTIFICATION_ERROR,
      err,
    }));
};

const initialState = {
  list: [],
  hasError: false,
  isLoading: false,
};

export default createReducer(initialState, {
  [ACTIONS.FETCH_NOTIFICATION_START]: state => ({ ...state, ...initialState, isLoading: true }),
  [ACTIONS.FETCH_NOTIFICATION_SUCCESS]: (state, { list }) => ({
    ...state,
    list,
    hasError: false,
    isLoading: false,
  }),
  [ACTIONS.FETCH_NOTIFICATION_ERROR]: state => ({ ...state, ...initialState, hasError: true }),
});
