import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { P, InformationLg, Stack } from '@rea-group/construct-kit-core';

const Disclaimer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundSecondaryBase};
  color: ${({ theme }) => theme.palette.textPrimary};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.extraSmall};
  margin-top: ${({ theme }) => theme.spacing.twoExtraSmall};
  padding-bottom: ${({ theme }) => theme.spacing.large};
  padding-top: ${({ theme }) => theme.spacing.large};
  position: relative;
  text-decoration: none;
  width: 100%;
`;

const DisclaimerSidebar = styled.div`
  border-left: solid 0.3rem ${({ theme }) => theme.palette.textInformation};
  border-radius: 0.4rem 0 0 0.4rem;
  bottom: 0;
  content: '';
  position: absolute;
  top: 0;
`;

const DisclaimerIcon = styled(Stack)`
  margin: 0 ${({ theme }) => theme.spacing.small};
`;

const SplitDisclaimer = ({ isSplitSearch }) => {
  if (!isSplitSearch) {
    return <div />;
  }

  return (
    <Disclaimer>
      <DisclaimerSidebar />
      <DisclaimerIcon>
        <InformationLg />
      </DisclaimerIcon>
      <P>
        NOTE: Not all products shown can be grouped together. If you are unsure,
        please check with the lender. You can also try adding features (e.g.
        professional pack) to the search criteria to narrow down the options.
      </P>
    </Disclaimer>
  );
};

SplitDisclaimer.propTypes = {
  isSplitSearch: PropTypes.bool.isRequired,
};

export default SplitDisclaimer;
