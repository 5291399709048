import compact from 'lodash/compact';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { getTargetChangedValue } from '../../../../../../constants/utils';
import { APPLICATION_UPDATE_TYPE } from '../../../../../../constants/applicationUpdate';
import { toTimeStr } from '../../../../../../../utils/datetime';
import { getBasicLogInfo } from './utils';

const generateApplicationUpdateLogContent = (type, name, timestamp) => (
  get({
    [APPLICATION_UPDATE_TYPE.EVENT]: `${name} on ${toTimeStr(timestamp)}`,
    [APPLICATION_UPDATE_TYPE.STATUS]: `Application status has changed to ${name} on ${toTimeStr(timestamp)}`,
  }, type)
);

const applicationUpdateChangeLogTransformer = (origin) => {
  const type = getTargetChangedValue(origin, 'type');
  const name = getTargetChangedValue(origin, 'name');
  const timestamp = getTargetChangedValue(origin, 'timestamp');
  const details = compact(filter(
    origin.eventDetail.changes,
    change => RegExp('^messageAnnotations.*value$').test(change.path),
  ).map(i => i.newValue));

  return {
    ...getBasicLogInfo(origin),
    title: generateApplicationUpdateLogContent(type, name, timestamp),
    details,
    operator: 'ApplyOnline',
  };
};

export default applicationUpdateChangeLogTransformer;
