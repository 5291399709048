import React, { useCallback } from 'react';
import PurposeComponent from 'shared/components/productSearch/ProductSearchFields/PurposeComponent';
import { PURCHASE } from 'shared/components/productSearch/ProductSearchFields/PurposeCategoryComponent';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';

const PurposeField = props => {
  const {
    input: { value: purpose, onChange: onPurposeChange },
    formName,
    namePrefix,
  } = props;

  const dispatch = useDispatch();

  const handlePurposeChange = useCallback((data) => {
    dispatch(change(formName, `${namePrefix}.purposeCategory`, PURCHASE));
    dispatch(change(formName, `${namePrefix}.purposeDetails`, null));
    dispatch(change(formName, `${namePrefix}.refinanceReason`, null));
    onPurposeChange(data);
  }, [dispatch, formName, namePrefix, onPurposeChange]);

  return (
    <PurposeComponent
      allowAny={false}
      value={purpose}
      onChange={handlePurposeChange}
    />
  );
};

PurposeField.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default PurposeField;
