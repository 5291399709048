import get from 'lodash/get';
import { createReducer } from 'utils/reducerGenerator';
import isEmpty from 'lodash/isEmpty';
import { FETCHING_STATUS } from '../../../../../constants';
import graphQL from './templateGraqhQL';
import { showToast } from '../../../../../redux/toast/actions';
import { ACTIONS as BUSINESS_ACTIONS } from '../../../../../redux/business';

export const ACTIONS = {
  RETRIEVE_TASK_LIST_TEMPLATES_START: 'RETRIEVE_TASK_LIST_TEMPLATES_START',
  RETRIEVE_TASK_LIST_TEMPLATES_SUCCESS: 'RETRIEVE_TASK_LIST_TEMPLATES_SUCCESS',
  RETRIEVE_TASK_LIST_TEMPLATES_ERROR: 'RETRIEVE_TASK_LIST_TEMPLATES_ERROR',
  TO_TASK_LIST_TEMPLATE: 'TO_TASK_LIST_TEMPLATE',
  TO_TASK_LIST_TEMPLATE_LIST: 'TO_TASK_LIST_TEMPLATE_LIST',
  CREATE_OR_UPDATE_TEMPLATES_SUCCESS: 'CREATE_OR_UPDATE_TEMPLATES_SUCCESS',
  DELETE_TASK_LIST_TEMPLATE: 'DELETE_TASK_LIST_TEMPLATE',
};

export const copyTaskListTemplate = template => createOrUpdateTaskListTemplate({
  ...template,
  id: '',
  name: `${template.name} - Copy`,
}, false);

export const toTaskListTemplate = selectedTemplate => (dispatch) => {
  dispatch({
    type: ACTIONS.TO_TASK_LIST_TEMPLATE,
    currentPage: 'taskListTemplate',
    selectedTemplate,
  });
};

export const toSmartlineTaskListTemplate = selectedTemplate => (dispatch) => {
  dispatch({
    type: ACTIONS.TO_TASK_LIST_TEMPLATE,
    currentPage: 'smartlineTaskListTemplate',
    selectedTemplate,
  });
};

export const toTaskListTemplateList = () => (dispatch) => {
  dispatch({
    type: ACTIONS.TO_TASK_LIST_TEMPLATE_LIST,
    currentPage: 'taskListTemplateList',
  });
};

// actions
export const createOrUpdateTemplatesSuccess = template => (
  {
    type: ACTIONS.CREATE_OR_UPDATE_TEMPLATES_SUCCESS,
    data: template,
  }
);

export const retrieveTaskListTemplates = () => (dispatch, getState) => {
  const state = getState();
  const currBusinessId = get(state, 'business.selectedBusiness.id', null);
  dispatch({ type: ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_START });

  return graphQL.retrieveTaskListTemplates(currBusinessId)
    .then(({ data: { taskListTemplates } }) => {
      dispatch({
        type: ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_SUCCESS,
        data: taskListTemplates,
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_ERROR,
      });
    });
};

export const createOrUpdateTaskListTemplate = (template, notification = true) => (dispatch, getState) => {
  const state = getState();
  const businessId = get(state, 'business.selectedBusiness.id', null);
  const newOrUpdatedTemplate = {
    id: template.id,
    businessId,
    name: template.name,
    tasks: template.tasks,
  };
  return graphQL.createOrUpdateTaskListTemplate(newOrUpdatedTemplate).then((response) => {
    dispatch(createOrUpdateTemplatesSuccess(response.data.createOrUpdateTaskListTemplate));
    if (notification) {
      dispatch(showToast('Your task list template has been saved.', { type: 'success' }));
    }
    return Promise.resolve(response.data.createOrUpdateTaskListTemplate);
  }).catch((error) => {
    if (!isEmpty(error.graphQLErrors)) {
      dispatch(showToast('Unfortunately your task list template has not been saved. Please try again', { type: 'error' }));
    }
    return Promise.reject();
  });
};

export const deleteTaskListTemplate = templateId => dispatch =>
  graphQL.deleteTaskListTemplate(templateId).then(() => {
    dispatch(showToast('Your task list template has been deleted.', { type: 'success' }));
    dispatch({ type: ACTIONS.DELETE_TASK_LIST_TEMPLATE, templateId });
    return Promise.resolve();
  }).catch((error) => {
    if (!isEmpty(error.graphQLErrors)) {
      dispatch(showToast('Unfortunately your task list template has not been deleted. Please try again', { type: 'error' }));
    }
    return Promise.reject();
  });

// reducer
const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  currentPage: null,
  taskListTemplates: {
    businessTemplates: [],
    smartlineTemplates: [],
  },
};

const templateReducer = createReducer(initialState, {
  [ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_START]: state => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
    taskListTemplates: initialState.taskListTemplates,
  }),
  [ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_SUCCESS]: (state, action) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
    taskListTemplates: action.data,
  }),
  [ACTIONS.RETRIEVE_TASK_LIST_TEMPLATES_ERROR]: state => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
    taskListTemplates: initialState.taskListTemplates,
  }),
  [ACTIONS.TO_TASK_LIST_TEMPLATE]: (state, action) => ({
    ...state,
    currentPage: action.currentPage,
    selectedTemplate: action.selectedTemplate,
  }),
  [ACTIONS.TO_TASK_LIST_TEMPLATE_LIST]: (state, action) => ({
    ...state,
    currentPage: action.currentPage,
    selectedTemplate: {},
  }),
  [ACTIONS.CREATE_OR_UPDATE_TEMPLATES_SUCCESS]: (state, action) => ({
      ...state,
      taskListTemplates: {
        ...state.taskListTemplates,
        businessTemplates: [
          action.data,
          ...state.taskListTemplates.businessTemplates.filter(t => t.id !== action.data.id),
        ],
      },
    }
  ),
  [ACTIONS.DELETE_TASK_LIST_TEMPLATE]: (state, action) => ({
    ...state,
    currentPage: action.currentPage,
    selectedTemplate: {},
    taskListTemplates: {
      ...state.taskListTemplates,
      businessTemplates: state.taskListTemplates.businessTemplates.filter(t => t.id !== action.templateId),
    },
  }),
  [BUSINESS_ACTIONS.SELECT_BUSINESS]: state => ({
    ...state,
    currentPage: null,
  }),
});

export default templateReducer;
