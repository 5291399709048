import trim from 'lodash/trim';
import { withFormMeta } from 'shared/components/FormMeta';
import WithReadOnlyTextInput from 'shared/components/formFields/TextInput/WithReadOnlyTextInput';
import withReduxForm from '../ReduxFormFieldWrapper';

const ReduxFromTextInput = withFormMeta(
  withReduxForm((props) => {
    const {
      onBlur,
    } = props;
    return ({
      onBlur: (e) => {
        onBlur(e === undefined ? null : trim(e.target.value));
      },
    });
  })(WithReadOnlyTextInput),
);

export default ReduxFromTextInput;
