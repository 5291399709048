import PALETTE from '../../theme/depricatedStyles/Colours';
import { FONTS } from '../../theme/depricatedStyles';

const styles = {
  box: {
    height: '15rem',
    borderRadius: '0.2rem',
    border: 'solid 0.2rem #eeeeee',
    backgroundColor: PALETTE.white,
    margin: '1rem',
    textAlign: 'center',
    position: 'relative',
  },

  commonText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: '50%',
  },

  title: {
    height: '70%',
    fontFamily: FONTS.title,
    fontSize: '1.8rem',
  },

  text: {
    height: '45%',
    fontFamily: FONTS.standard,
    fontSize: '1.3rem',
  },
};

export default styles;
