import mapKeys from 'lodash/mapKeys';
import map from 'lodash/map';
import get from 'lodash/get';
import { iSet } from 'shared/utils';

export const applyDetailsToVariantsCombinations = (variantsCombinations, variantsDetails) => {
  const variantsDetailsMap = mapKeys(variantsDetails, 'id');
  return map(variantsCombinations, (comb) => {
    const { variants } = comb;
    return {
      ...comb,
      variants: variants.map(variant => {
        if (variant.customProduct) {
          return variant;
        }
        return ({
          ...variant,
          ...get(variantsDetailsMap, variant.id, []),
        });
      }),
    };
  });
};
export const applyMockFeatureDetailsToCustomProducts = (variantsCombinations, featureDetails) => map(
  variantsCombinations,
  (comb) => ({
    ...comb,
    variants: map(
      comb.variants,
      variant => (variant.customProduct
        ? iSet(variant, 'featureDetails', featureDetails)
        : variant),
    ),
  }),
);
