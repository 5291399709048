import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import uniqueId from 'lodash/uniqueId';
import MobiusTable from '../../../shared/components/MobiusTable';
import { minHeightRenderer, moneyValueRenderer } from '../renderers';
import {
  calculateTotalAmounts,
  CommissionRowShape,
  getMonthsColumnConfig,
} from './commissionUtil/commission';
import styles from './styles.module.scss';

const columns = [
  {
    name: 'client',
    title: 'Client',
    sticky: true,
    wordWrapEnabled: true,
    renderer: client => (client ? minHeightRenderer(client) : 'No data'),
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'insurer',
    title: 'Insurer',
    sticky: true,
    wordWrapEnabled: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'policyNumber',
    title: (
      <span>
        Policy
        <br />
        number
      </span>
    ),
    sticky: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'adviser',
    title: 'Adviser',
    sticky: true,
    wordWrapEnabled: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'percentage',
    title: (
      <span>
        Current
        <br />
        franchise %
      </span>
    ),
    sticky: true,
    allowSorting: true,
    disallowResizing: true,
  },
];

const cellRenderGenerator = () => moneyValueRenderer;
const headerRender = monthIndex => (children, column, rowItems) => {
  const totalAmounts = calculateTotalAmounts(rowItems, monthIndex, 'amounts');
  return (
    <div className={styles.amountHeaderCell}>
      {children}
      <div className={styles.totalAmounts}>
        <div>{moneyValueRenderer(totalAmounts)}</div>
      </div>
    </div>
  );
};
const replaceNonePrefixWord = input => {
  if (input.match(/^\w/)) {
    return input.replace(/\W+/g, '');
  }
  return input;
};
const toLowerCase = value => String(value).toLowerCase();
const partialSearchPredicate = (value, filter) => filter.value.split(' ')
  .every(input => toLowerCase(value).split(/[,; ]+/)
  .some(partial => partial.startsWith(replaceNonePrefixWord(toLowerCase(input.trim())))));
const integratedFilteringColumnExtensions = [
  {
    columnName: 'client',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'lender',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'adviser',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'approvalNumber',
    predicate: partialSearchPredicate,
  },
];
const InsuranceCommissions = ({
                                rows,
                                processingMonthsLength,
                                isLoading,
                                virtual,
                              }) => {
  const amountColumns = useMemo(() => getMonthsColumnConfig(
    rows,
    processingMonthsLength,
    'amounts',
    cellRenderGenerator,
    headerRender,
  ), [rows, processingMonthsLength]);

  const calculateColumnWidth = () => {
    if (mergedColumns.length > 9) {
      return mergedColumns;
    }
    const totalWidth = Math.max(document.body.scrollWidth,
      document.documentElement.scrollWidth);
    const defaultWidth = totalWidth / mergedColumns.length;
    return mergedColumns.map(item => ({
      ...item,
      width: defaultWidth,
    }));
  };

  const mergedColumns = [...columns, ...amountColumns];

  return (
    <MobiusTable
      key={uniqueId()}
      resizingEnabled
      style={{ height: '85vh' }}
      rows={rows}
      columns={calculateColumnWidth(mergedColumns)}
      isLoading={isLoading}
      virtual={virtual}
      defaultSorting={[{ columnName: 'client', direction: 'asc' }]}
      customColumnFilters={integratedFilteringColumnExtensions}
    />
  );
};

InsuranceCommissions.propTypes = {
  rows: PropTypes.arrayOf(CommissionRowShape),
  isLoading: PropTypes.bool,
  processingMonthsLength: PropTypes.number,
  virtual: PropTypes.bool,
};

InsuranceCommissions.defaultProps = {
  rows: [],
  isLoading: false,
  processingMonthsLength: 0,
  virtual: true,
};

export default InsuranceCommissions;
