import { withRouter } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import CompareButton from 'shared/components/productSearch/CompareButton';
import { ProductsTableContext } from '../ProductSearchContext';

const ConnectedCompareButton = ({ history }) => (
  <ProductsTableContext.Consumer>
    {
      context => (
        <CompareButton
          history={history}
          comparisonRoute={context.comparisonRoute}
          comparisonTitle={context.comparisonTitle}
          comparisonSize={context.selectedOptions.length}
          dispatchGetComparisonDetails={context.dispatchGetComparisonDetails}
        />
      )
    }
  </ProductsTableContext.Consumer>
);

ConnectedCompareButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default withRouter(ConnectedCompareButton);
