/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './BasicRichContent.module.scss';

const renderSubContent = subContent => {
  if (subContent) {
    return (
      <div className={styles.details}>
        {([].concat(subContent)).map((text, index) => <div key={index}>{text}</div>)}
      </div>
    );
  }
  return null;
};

const BasicRichContent = ({
 type, content, subContent, text,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.type}>{type}</div>
    <div className={classNames(styles.description, { [styles.fullWidthDescription]: !subContent })}>{content}</div>
    {renderSubContent(subContent)}
    {text && (<div className={styles.text}>{text}</div>)}
  </div>
  );

BasicRichContent.defaultProps = {
  type: '',
  subContent: '',
  text: '',
};

BasicRichContent.propTypes = {
  type: PropTypes.string,
  content: PropTypes.node.isRequired,
  subContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string,
};

export default BasicRichContent;
