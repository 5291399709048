import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import LoadingAndErrorWrapper from 'shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import RetryComponent from 'shared/components/RetryComponent';
import PermissionSettingsTable from './PermissionSettingsTable';
import styles from './styles';
import {
  retrieveUserInfoList,
  savePermissionSettings,
  updatePermissions,
} from './permissionSettingsRedux';
import UserPermissionShape from '../../../shapes/UserPermissionShape';
import BusinessShape from '../../../shapes/BusinessShape';
import PrimaryButton from '../../../shared/components/Buttons/PrimaryButton';
import ErrorShape from '../../../shapes/ErrorShape';

const getDifferentUsers = (originalUserInfoList, userInfoList) =>
  userInfoList.filter((userInfo) => {
    const origin = originalUserInfoList.find(
      originalUserInfo => originalUserInfo.userId === userInfo.userId,
    );
    return !(
      origin && isEqual(origin.permissions.sort(), userInfo.permissions.sort())
    );
  });

const handleClick = (
  originalUserInfoList,
  userInfoList,
  dispatchSavePermissions,
) => {
  const permissionsToBeUpdated = getDifferentUsers(
    originalUserInfoList,
    userInfoList,
  ).map(userInfo => ({
    userId: userInfo.userId,
    permissions: userInfo.permissions,
    version: userInfo.version,
  }));
  dispatchSavePermissions(permissionsToBeUpdated);
};

const getPermissionSettings = (
  userInfoList,
  selectedBusiness,
  originalUserInfoList,
  isSaving,
  dispatchUpdatePermissions,
  dispatchSavePermissions,
) => (
  <>
    <PermissionSettingsTable
      users={userInfoList}
      updatePermissions={dispatchUpdatePermissions}
      business={selectedBusiness}
    />
    <PrimaryButton
      style={{
        float: 'right',
        marginBottom: '2rem',
      }}
      disabled={
        isSaving
        || getDifferentUsers(originalUserInfoList, userInfoList).length === 0
      }
      onClick={() =>
        handleClick(originalUserInfoList, userInfoList, dispatchSavePermissions)}
      loading={isSaving}
      data-test-id="saveButton"
    >
      {isSaving ? 'Saving' : 'Save'}
    </PrimaryButton>
  </>
);

const getErrorComponent = (error) => {
  if (get(error, 'response.status') === 409) {
    return (
      // we love es-lint:
      <RetryComponent>
        The permissions for this business have been updated by someone else.
        Please
        {' '}
        <a href={`${window.location}#`}>refresh</a>
        {' '}
        the page to view the
        latest settings.
      </RetryComponent>
    );
  }
  return <RetryComponent />;
};

export class PermissionSettingsComponent extends PureComponent {
  componentDidMount() {
    const { selectedBusiness, dispatchRetrieveUserInfoList } = this.props;
    if (selectedBusiness && selectedBusiness.id && selectedBusiness.displayName) {
      dispatchRetrieveUserInfoList(selectedBusiness.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedBusiness, dispatchRetrieveUserInfoList } = this.props;
    const { selectedBusiness: prevBusiness } = prevProps;
    if (
      selectedBusiness && selectedBusiness.id && selectedBusiness.displayName
      && selectedBusiness !== prevBusiness
    ) {
      dispatchRetrieveUserInfoList(selectedBusiness.id);
    }
  }

  render() {
    const {
      userInfoList,
      selectedBusiness,
      originalUserInfoList,
      dispatchUpdatePermissions,
      dispatchSavePermissions,
      error,
      isSaving,
      isLoading,
    } = this.props;
    return (
      <div>
        <GroupOfficeBusinessSelector />
        <LoadingAndErrorWrapper isLoading={isLoading} hasError={false}>
          <div style={styles.permissionSettings}>
            <h1>Permission Settings</h1>
            {selectedBusiness
              && (error
                ? getErrorComponent(error)
                : getPermissionSettings(
                    userInfoList,
                    selectedBusiness,
                    originalUserInfoList,
                    isSaving,
                    dispatchUpdatePermissions,
                    dispatchSavePermissions,
                  ))}
          </div>
        </LoadingAndErrorWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfoList: state.myBusiness.permissionSettings.userInfoList,
  originalUserInfoList:
    state.myBusiness.permissionSettings.originalUserInfoList,
  error: state.myBusiness.permissionSettings.error,
  isSaving: state.myBusiness.permissionSettings.isSaving,
  isLoading: state.myBusiness.permissionSettings.isLoading,
  selectedBusiness: state.business.selectedBusiness,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdatePermissions: payload => dispatch(updatePermissions(payload)),
  dispatchRetrieveUserInfoList: businessId =>
    dispatch(retrieveUserInfoList(businessId)),
  dispatchSavePermissions: permissionsToBeUpdated =>
    dispatch(savePermissionSettings(permissionsToBeUpdated)),
});

PermissionSettingsComponent.defaultProps = {
  selectedBusiness: { id: '' },
  error: null,
  isSaving: false,
  isLoading: false,
};

PermissionSettingsComponent.propTypes = {
  userInfoList: PropTypes.arrayOf(UserPermissionShape).isRequired,
  originalUserInfoList: PropTypes.arrayOf(UserPermissionShape).isRequired,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedBusiness: BusinessShape,
  dispatchUpdatePermissions: PropTypes.func.isRequired,
  dispatchRetrieveUserInfoList: PropTypes.func.isRequired,
  dispatchSavePermissions: PropTypes.func.isRequired,
  error: ErrorShape,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionSettingsComponent);
