import CheckBox from 'shared/components/formFields/Checkbox';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import Button from 'shared/components/Buttons/Button';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

export const Popover = ({
  options,
  onUpdate,
  setOptions,
  loading,
  disabled,
  buttonText,
}) => {
  const createCheckBox = option => (
    <CheckBox
      key={option.id}
      labelName={option.name}
      checked={option.checked}
      disabled={disabled || option.disabled}
      onChange={({ checked }) => {
        const newOptions = cloneDeep(options);
        newOptions.find(opt => opt.id === option.id).checked = checked;
        setOptions(newOptions);
      }}
    />
  );
  return (
    <>
      {options.map(category => createCheckBox(category))}
      <Button
        disabled={loading || disabled}
        loading={loading}
        style={{ minWidth: '7rem' }}
        onClick={() =>
          onUpdate(
            options
              .filter(option => option.checked)
              .map(option => pick(option, ['id', 'name'])),
          )}
      >
        {buttonText}
      </Button>
    </>
  );
};

Popover.propTypes = {
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
};
