import React from 'react';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import { connect } from 'react-redux';
import { formPropTypes, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import ApplicationDocuments from './ApplicationDocuments';
import styles from './Finalise.module.scss';
import SendToApplyOnlineSection from './SendToApplyOnlineSection';
import LenderComments from './LenderComments';
import { getFinaliseInitialValues } from '../../redux/selectors/finalise';
import { updateFinalise } from '../../redux';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';

const onSubmit = (values, dispatch) => dispatch(updateFinalise(values));

const FinalisePanel = ({
  handleSubmit, pristine, submitting, dirty, hasEditPermission, valid,
}) => (
  <div>
    <form onSubmit={handleSubmit}>
      <div className={styles.firstSection}>
        <div className={styles.lenderCommentsSection}>
          <LenderComments hasEditPermission={hasEditPermission} />
        </div>
        <div className={styles.sendToApplyOnlineSection}>
          <SendToApplyOnlineSection hasEditPermission={hasEditPermission} />
        </div>
      </div>
      <ApplicationDocuments hasEditPermission={hasEditPermission} />
      <StickyFooter
        type="submit"
        disabled={!hasEditPermission || pristine || submitting || !valid}
      />
    </form>
    <LoadingWithOverlay isLoading={submitting} />
    <UnsavedChangePrompt shouldConfirm={dirty} />
  </div>
);

FinalisePanel.propTypes = {
  ...formPropTypes,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: getFinaliseInitialValues(state),
  hasEditPermission: hasEditPermissionSelector(state),
});

export default connect(mapStateToProps)(reduxForm(
  {
    form: 'finalise',
    onSubmit,
    enableReinitialize: true,
  },
)(FinalisePanel));
