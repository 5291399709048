export const VEHICLE_TYPE = [
  {
    label: 'Bike',
    value: 'Bike',
  }, {
    label: 'Large',
    value: 'Large',
  }, {
    label: 'Luxury Car',
    value: 'LuxuryCar',
  }, {
    label: '4WD',
    value: 'FourWD',
  }, {
    label: 'Medium',
    value: 'Medium',
  }, {
    label: 'Small',
    value: 'Small',
  }, {
    label: 'Small Medium',
    value: 'SmallMedium',
  },
];
