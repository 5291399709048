import { ACTIONS } from './comparisonActions';
import {
  initialState,
  processClearComparison,
  processDownloadComparisonsError,
  processDownloadComparisonsLoading,
  processDownloadComparisonsSuccess,
  processGetComparisonDetailsError,
  processGetComparisonDetailsLoading,
  processGetComparisonDetailsSuccess,
  processToggleFeatureModalView,
  processToggleIncludeCommentsInPrintOut,
  processToggleSelectedFeatureFromComparison,
  processUpdateOptionsFromComparison,
  processUpdateSelectedFeaturesFromComparison,
  processUpdateSelectedVariant,
  setDefaultModificationOriginals,
} from './comparison';
import { registerAfterFunction, registerBeforeFunction } from '../../../store/middleware/sideEffectMiddleware';
import { trackCustomEvent } from '../../../tealium/Tracker';
import { printComparisonV100 } from '../../../tealium/TrackingDataBuilder';
import { saveIncludeCommentsInPrintout } from '../../../store/persistState';

registerBeforeFunction(ACTIONS.DOWNLOAD_COMPARISONS_LOADING, (store) => {
  trackCustomEvent(printComparisonV100(store.getState()));
});

registerAfterFunction(ACTIONS.TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT, store =>
  saveIncludeCommentsInPrintout(store.getState().productSearch.standAlone.comparison.includeCommentsInPrintout));

export default (state = initialState(), action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_OPTIONS_FROM_COMPARISON: {
     return processUpdateOptionsFromComparison(state, action);
    }

    case ACTIONS.CLEAR_COMPARISON: {
      return processClearComparison(state);
    }
    case ACTIONS.GET_COMPARISON_DETAILS_LOADING: {
      return processGetComparisonDetailsLoading(state);
    }
    case ACTIONS.GET_COMPARISON_DETAILS_SUCCESS: {
      return processGetComparisonDetailsSuccess(action, state);
    }
    case ACTIONS.SET_DEFAULT_MODIFICATION_ORIGINALS: {
      return setDefaultModificationOriginals(state);
    }
    case ACTIONS.GET_COMPARISON_DETAILS_ERROR: {
      return processGetComparisonDetailsError(state);
    }
    case ACTIONS.DOWNLOAD_COMPARISONS_LOADING: {
      return processDownloadComparisonsLoading(state);
    }
    case ACTIONS.DOWNLOAD_COMPARISONS_SUCCESS: {
      return processDownloadComparisonsSuccess(state);
    }
    case ACTIONS.DOWNLOAD_COMPARISONS_ERROR: {
      return processDownloadComparisonsError(state);
    }
    case ACTIONS.TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT: {
      return processToggleIncludeCommentsInPrintOut(state);
    }
    case ACTIONS.UPDATE_SELECTED_VARIANT: {
      return processUpdateSelectedVariant(state, action);
    }
    case ACTIONS.TOGGLE_SELECTED_FEATURE_FROM_COMPARISON: {
      return processToggleSelectedFeatureFromComparison(state, action);
    }
    case ACTIONS.UPDATE_SELECTED_FEATURES_FROM_COMPARISON: {
      return processUpdateSelectedFeaturesFromComparison(action, state);
    }
    case ACTIONS.TOGGLE_FEATURE_MODAL_VIEW: {
      return processToggleFeatureModalView(state);
    }
    default:
      return state;
  }
};
