import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './InformationSection.module.scss';

const InformationSection = ({
 title, children, titleWrapperStyle, wrapperStyle, contentWrapperStyle,
}) => (
  <div className={classNames(styles.wrapper, wrapperStyle)}>
    {title && <div className={classNames(styles.titleWrapper, titleWrapperStyle)}>{title}</div>}
    <div className={classNames(styles.contentWrapper, contentWrapperStyle)}>{children}</div>
  </div>
);

InformationSection.defaultProps = {
  title: undefined,
  titleWrapperStyle: undefined,
  wrapperStyle: undefined,
  contentWrapperStyle: undefined,
};

InformationSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  titleWrapperStyle: PropTypes.string,
  wrapperStyle: PropTypes.string,
  contentWrapperStyle: PropTypes.string,
};

export default InformationSection;
