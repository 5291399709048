import PropTypes from 'prop-types';

export const ApplicationShape = PropTypes.shape({
  id: PropTypes.string,
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviateName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      applicantUniqueKey: PropTypes.string,
    }),
  ).isRequired,
  adviser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  applicationNumber: PropTypes.string,
  lender: PropTypes.shape({
    name: PropTypes.string,
    lenderId: PropTypes.string,
  }),
  totalLoanAmount: PropTypes.number.isRequired,
  applicationUpdateCount: PropTypes.number,
  applicationStatus: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  expectedSettlementDate: PropTypes.string,
  settledDate: PropTypes.string,
});
