import { getBasicLogInfo } from './utils';

const transformerCreator = ({ isCopy }) => origin => {
  const extraInfo = JSON.parse(origin.eventDetail.extraInfo);
  const { applicants, ...others } = extraInfo;
  return ({
    ...getBasicLogInfo(origin),
    isCopy,
   ...others,
    applicantNames: Object.values(applicants),
  });
};

export default transformerCreator;
