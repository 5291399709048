import first from 'lodash/first';
import get from 'lodash/get';
import map from 'lodash/map';
import slice from 'lodash/slice';
import getToggles from 'shared/toggles/getToggles';
import { getServiceabilityFromApi } from 'shared/api';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import size from 'lodash/size';
import find from 'lodash/find';
import { buildRequestsFromProducts } from './utils';
import * as types from '../types';
import graphQL from '../../../../redux/applicationDetailsGraphQL';
import { resolveServiceabilityError } from '../../../../redux/validation/validationResolver';
import { getMessage, showToast } from '../../../../../../../redux/toast/actions';
import { MESSAGE_KEYS } from '../../../../../../../constants/message';

const hasApplicationError = (error) => get(error, 'graphQLErrors[0].errorCode', false) === 400;

const isForbidden = (error) => get(error, 'graphQLErrors[0].errorCode', false) === 403;

const processGetServiceAbilityItem = (request, totalLoanAmount) => async (dispatch) => {
  try {
    const { response, details } = await getServiceabilityFromApi(request);
    dispatch({
      type: types.GET_SERVICEABILITY_ITEM_SUCCESS,
      data: response,
      productId: request.productId,
      totalLoanAmount,
      details,
    });
    return ({
      success: true,
      response,
    });
  } catch (err) {
    dispatch({
      type: types.GET_SERVICEABILITY_ITEM_FAIL,
      productId: request.productId,
    });
    if (hasApplicationError(err)) {
      const graphQLErrors = JSON.parse(err.graphQLErrors[0].message);
      return {
        success: false,
        applicationError: true,
        errorMessage: graphQLErrors.message,
      };
    }
    if (isForbidden(err)) {
      const graphQLErrors = JSON.parse(err.graphQLErrors[0].message);
      return {
        success: false,
        forbidden: true,
        errorMessage: graphQLErrors.message,
      };
    }
    return {
      success: false,
    };
  }
};

const processFirstRequest = (request, applicationId, totalLoanAmount) => async (dispatch) => {
  const result = await dispatch(processGetServiceAbilityItem(request, totalLoanAmount));
  const {
    success, applicationError, forbidden, errorMessage,
  } = result;
  if (!success) {
    if (applicationError) {
      dispatch({
        type: types.GET_SERVICEABILITY_INFOS_ABORT,
      });
      const { data } = await graphQL.getApplicationDetail(applicationId);
      const toggles = getToggles();
      throw resolveServiceabilityError(errorMessage, {
        application: get(data, 'application', {}),
        toggles,
      });
    }
    if (forbidden) {
      dispatch({
        type: types.GET_SERVICEABILITY_INFOS_ABORT,
      });
      await dispatch(showToast(
        getMessage(MESSAGE_KEYS.GET_SERVICEABILITY, 403),
        {
          type: 'error',
          iconName: 'info',
        },
      ));
    }
  }
  return result;
};

const processRestRequests = (requestParams, totalLoanAmount) => (dispatch) => {
  const promises = slice(requestParams, 1)
    .map((request) => dispatch(processGetServiceAbilityItem(request, totalLoanAmount)));
  return Promise.all(promises);
};

export const getServiceability = ({
                                    loansCriteria,
                                    products,
                                    lenders,
                                    applicationId,
                                    totalLoanAmount,
                                  }) => async (dispatch) => {
  const requestParams = buildRequestsFromProducts({
    products, applicationId, loansCriteria, lenders,
  });

  if (size(requestParams) === 0) {
    return;
  }
  dispatch({
    type: types.GET_SERVICEABILITY_INFOS_START,
    productIds: map(requestParams, 'productId'),
  });

  const firstRequestResult = await dispatch(processFirstRequest(
    first(requestParams),
    applicationId,
    totalLoanAmount,
  ));

  const {
    applicationError, forbidden,
  } = firstRequestResult;

  if (applicationError || forbidden || size(requestParams) === 1) {
    return;
  }

  const restRequestResults = await dispatch(processRestRequests(requestParams, totalLoanAmount));

  if (!isNil(find(concat(firstRequestResult, restRequestResults), { success: false }))) {
    await dispatch(showToast(
      getMessage(MESSAGE_KEYS.GET_SERVICEABILITY, 500),
      {
        type: 'error',
        iconName: 'info',
      },
    ));
  }
};
