import get from 'lodash/get';
import buildDescriptors from '../descriptors/SettlementDescriptors';

const buildContext = application => ({
    normalizeType(defaultType, finalType, index) {
      const suffix = index === undefined ? '' : ` #${index + 1}`;
      return `${defaultType}${suffix} ${finalType || ''}`;
    },
    getPersonName(index) {
      return this.normalizeType(
        '',
        get(application, `applicants.persons[${index}].name`) || `Person ${index + 1}`,
      );
    },
    getCompanyName(index) {
      return this.normalizeType(
        '',
        get(application, `applicants.companies[${index}].name`) || `Company ${index + 1}`,
      );
    },
    getSecurityType() {
      return this.normalizeType(
        '',
        'Security asset',
      );
    },
    getLoanName(index) {
      return this.normalizeType(
        '',
        `Loan ${index + 1} ${get(application, `loans[${index}].productName`) || ''}`,
      );
  },
  });

export default contextData => {
  const context = buildContext(contextData);
  const descriptors = buildDescriptors(context);
  return {
    context,
    descriptors,
  };
};
