import get from 'lodash/get';
import isNil from 'lodash/isNil';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import { sumBy } from '../utils';
import { getColorForAdviser, getColorForLender } from '../utils/colorsCache';

const getFinalLoanAmount = loan => (isNil(loan.commissionLoanAmount) ? loan.amount : loan.commissionLoanAmount);
export const perspectivesForSubmission = [
  {
    getValue: getFinalLoanAmount,
    key: 'adviser',
    name: 'Adviser',
    classifier: 'adviserId',
    getSeriesName: (key, index, context) => (get(context, ['advisers', key, 'name'], null)),
    getSeriesColor: getColorForAdviser,
  },
  {
    getValue: getFinalLoanAmount,
    key: 'lender',
    name: 'Lender',
    classifier: 'lenderName',
    getSeriesName: lenderName => (lenderName),
    getSeriesColor: getColorForLender,
    take: 10,
  },
];

export const perspectivesForSettlement = [
  {
    getValue: getFinalLoanAmount,
    key: 'adviser',
    name: 'Adviser',
    classifier: 'adviserId',
    getSeriesName: (key, index, context) => (get(context, ['advisers', key, 'name'], null)),
    getSeriesColor: getColorForAdviser,
  },
  {
    getValue: getFinalLoanAmount,
    key: 'lender',
    name: 'Lender',
    classifier: 'lenderName',
    getSeriesName: lenderName => (lenderName),
    getSeriesColor: getColorForLender,
    take: 10,
  },
];

export const perspectivesForExpectedSettlement = [
  {
    getValue: 'amount',
    key: 'adviser',
    name: 'Adviser',
    classifier: 'adviserId',
    getSeriesName: (key, index, context) => (get(context, ['advisers', key, 'name'], null)),
    getSeriesColor: getColorForAdviser,
    getMonthlyExtraData: (loanGroup) => {
      const allLoans = flatMap(loanGroup);
      return {
        expectedUpfrontCommission: sumBy(filter(allLoans, loan => loan.isUpfrontExpected), 'expectedUpfrontCommission'),
      };
    },
  },
];
