import React, { useEffect, useState } from 'react';
import MonthYearPicker from 'shared/components/formFields/MonthYearPicker';
import Button from 'shared/components/Buttons/Button';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

export const PopoverContent = ({
 fromFilter, toFilter, onSave, onCancel, endYearOfSelectOptions,
}) => {
  const [showMissingFromFields, updateShowMissingFromFields] = useState(false);
  const [showMissingToFields, updateShowMissingToFields] = useState(false);
  const [from, updateFrom] = useState(fromFilter);
  const [to, updateTo] = useState(toFilter);

  useEffect(() => {
    updateShowMissingFromFields(!isValid(from.month, from.year));
    updateShowMissingToFields(!isValid(to.month, to.year));
  }, [from, to, updateShowMissingFromFields, updateShowMissingToFields]);

  const isValid = (val1, val2) =>
    (val1 === undefined && val2 === undefined) || (val1 !== undefined && val2 !== undefined);

  return (
    <div className={styles.container}>
      <span>From</span>
      <MonthYearPicker
        onChange={updateFrom}
        month={from.month}
        year={from.year}
        showMissingFields={showMissingFromFields}
        endYear={endYearOfSelectOptions}
      />
      <span>To</span>
      <MonthYearPicker
        onChange={updateTo}
        month={to.month}
        year={to.year}
        showMissingFields={showMissingToFields}
        endYear={endYearOfSelectOptions}
      />
      <div className={styles.buttonContainer}>
        <Button
          variant="primary"
          onClick={() => onSave(from, to)}
          disabled={showMissingToFields || showMissingFromFields}
        >
          Save
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

PopoverContent.defaultProps = {
  endYearOfSelectOptions: new Date().getFullYear(),
};

PopoverContent.propTypes = {
  fromFilter: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }).isRequired,
  toFilter: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }).isRequired,
  endYearOfSelectOptions: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
