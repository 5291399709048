import React from 'react';
import { dateFormatter } from 'shared/formatterUtils';
import moment from 'moment';
import isFunction from 'lodash/isFunction';
import { alignment } from 'shared/components/MobiusTable';
import CommissionQueryComponent from '../../CommissionQuery/CommissionQuery';
import {
  approvalNumberLink,
  getAmountAndBalance,
  getAmountAndBalanceForValue,
  getDateOrNoData,
  getDollarAmountOrDashDash,
  getDollarAmountOrDashDashAsValue,
  getDollarAmountOrNoData,
  getValueOrNoData,
} from '../../renderers';

export const columnKeys = {
  AMOUNT_AND_BALANCE: 'amountAndBalance',
  APPROVAL_NUMBER: 'approvalNumber',
  APPROVED_AMOUNT: 'approvedAmount',
  CLIENT: 'client',
  CURRENT_BALANCE: 'currentBalance',
  LAST_KNOWN_BALANCE: 'lastKnownBalance',
  LENDER: 'lender',
  PREVIOUS_BALANCE: 'previousBalance',
  PREVIOUS_LOAN_BALANCE: 'previousLoanBalance',
  SETTLED_AMOUNT: 'settledAmount',
  SETTLEMENT_DATE: 'settlementDate',
  TYPE: 'type',
  COMMISSION_QUERY: 'commissionQuery',
};

const previousBalance = {
  key: columnKeys.PREVIOUS_BALANCE,
  title: processingMonth => dateFormatter.monthYear(moment(processingMonth).subtract(1, 'months')),
  name: 'previousLoanBalance',
  sticky: true,
  align: alignment.RIGHT,
  wordWrapEnabled: true,
  renderer: getDollarAmountOrDashDash,
  width: 160,
};

export const getColumnDefinitions = (typeRenderer, getTypeValue) => ({
  [columnKeys.COMMISSION_QUERY]: {
    key: columnKeys.COMMISSION_QUERY,
    title: 'Raise a query',
    name: 'id',
    sticky: true,
    width: 80,
    wordWrapEnabled: true,
    renderer: issueId => <CommissionQueryComponent issueId={issueId} />,
    hideInExport: true,
  },
  [columnKeys.TYPE]: {
    key: columnKeys.TYPE,
    title: 'Type',
    name: 'type',
    sticky: true,
    getCellValue: getTypeValue,
    renderer: (v, row) => (typeRenderer(row)),
    width: 140,
    wordWrapEnabled: true,
  },
  [columnKeys.LAST_KNOWN_BALANCE]: {
    key: columnKeys.LAST_KNOWN_BALANCE,
    title: 'Last known balance',
    name: 'loanBalance',
    sticky: true,
    wordWrapEnabled: true,
    width: 160,
    align: alignment.RIGHT,
    getCellValue: (row) => getDollarAmountOrDashDashAsValue(row.loanBalance),
    renderer: (v, row) => getDollarAmountOrDashDash(row.loanBalance),
  },
  [columnKeys.AMOUNT_AND_BALANCE]: {
    key: columnKeys.AMOUNT_AND_BALANCE,
    title: processingMonth => dateFormatter.monthYear(processingMonth),
    name: 'amountAndBalance',
    width: 260,
    sticky: true,
    align: alignment.RIGHT,
    wordWrapEnabled: true,
    getCellValue: getAmountAndBalanceForValue,
    renderer: (v, row) => (getAmountAndBalance(row)),
  },
  [columnKeys.PREVIOUS_BALANCE]: previousBalance,
  [columnKeys.PREVIOUS_LOAN_BALANCE]: {
    ...previousBalance,
    title: (processingMonth) => `${isFunction(previousBalance.title) ? previousBalance.title(processingMonth) : previousBalance.title} balance`,
  },
  [columnKeys.CURRENT_BALANCE]: {
    key: columnKeys.CURRENT_BALANCE,
    title: processingMonth => dateFormatter.monthYear(processingMonth),
    name: 'loanBalance',
    sticky: true,
    align: alignment.RIGHT,
    wordWrapEnabled: true,
    getCellValue: (row) => getDollarAmountOrDashDashAsValue(row.loanBalance),
    renderer: (v, row) => getDollarAmountOrDashDash(row.loanBalance),
    width: 160,
  },
  [columnKeys.CLIENT]: {
    key: columnKeys.CLIENT,
    title: 'Client',
    name: 'client',
    sticky: true,
    width: 260,
    wordWrapEnabled: true,
    disallowResizing: true,
    getCellValue: (row) => getValueOrNoData(row.client),
  },
  [columnKeys.LENDER]: {
    key: columnKeys.LENDER,
    title: 'Lender',
    name: 'lender',
    wordWrapEnabled: true,
    width: 260,
  },
  [columnKeys.SETTLEMENT_DATE]: {
    key: columnKeys.SETTLEMENT_DATE,
    title: 'Settlement date',
    name: 'settlementDate',
    getCellValue: (row) => getDateOrNoData(row.settlementDate),
    align: alignment.RIGHT,
    wordWrapEnabled: true,
    width: 125,
  },
  [columnKeys.SETTLED_AMOUNT]: {
    key: columnKeys.SETTLED_AMOUNT,
    title: 'Settled amount',
    name: 'settledAmount',
    getCellValue: (row) => getDollarAmountOrNoData(row.settledAmount),
    align: alignment.RIGHT,
    wordWrapEnabled: true,
    width: 125,
  },
  [columnKeys.APPROVED_AMOUNT]: {
    key: columnKeys.APPROVED_AMOUNT,
    title: 'Approved amount',
    name: 'approvedAmount',
    getCellValue: (row) => getDollarAmountOrNoData(row.approvedAmount),
    align: alignment.RIGHT,
    wordWrapEnabled: true,
    width: 200,
  },
  [columnKeys.APPROVAL_NUMBER]: {
    key: columnKeys.APPROVAL_NUMBER,
    title: 'Approval number',
    name: 'approvalNumber',
    renderer: (v, row) => approvalNumberLink(row),
    wordWrapEnabled: true,
    width: 260,
  },
});
