import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExternalLink from './ExternalLink';
import Icon from '../Icon/Icon';
import styles from './styles.module.scss';

const iconStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
};

const ExternalInfoLink = ({
  to,
  text,
  externalInfoLinkClassName,
  textClassName,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <ExternalLink
      to={to}
      className={classNames(styles.externalInfoLink, externalInfoLinkClassName)}
    >
      <Icon name="info" size="16px" color={themeContext.palette.linkPrimary} style={iconStyle} />
      <span className={classNames(styles.text, textClassName)}>{text}</span>
    </ExternalLink>
  );
};

ExternalInfoLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
  externalInfoLinkClassName: PropTypes.string,
  textClassName: PropTypes.string,
};

ExternalInfoLink.defaultProps = {
  externalInfoLinkClassName: '',
  textClassName: '',
};

export default ExternalInfoLink;
