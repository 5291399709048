import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Buttons/Button';
import styles from './StickyFooter.module.scss';

const allSaved = 'All changes are saved';
const notSaved = 'There are unsaved changes';

const StickyFooter = (props) => {
  const { disabled, buttonText, className } = props;
  const description = disabled ? allSaved : notSaved;
  return (
    <div className={classNames(styles.stickyFooter, className)}>
      <div className={styles.phantomStyle} />
      <div className={styles.stickyContainer}>
        <div className={styles.shadow} />
        <div className={styles.stickyButton}>
          <div className={styles.description}>{description}</div>
          <Button
            {...props}
            className={styles.button}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

StickyFooter.defaultProps = {
  disabled: true,
  buttonText: 'Save',
  className: undefined,
};

StickyFooter.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  className: PropTypes.string,
};

export default StickyFooter;
