import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubTotalSection from '../components/SubTotalSection';
import { calculateTotalContributions } from '../../../redux/selectors/fundingWorksheet/utils';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';

export const SubtotalContributions = ({ contributions, userGuidanceCode }) => (
  <SubTotalSection
    total={calculateTotalContributions(contributions)}
    title={`Contribution (${userGuidanceCode})`}
  />
);

SubtotalContributions.propTypes = {
  contributions: PropTypes.object.isRequired,
  userGuidanceCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  contributions: fundingWorksheetFormValueSelector(state, 'contributions'),
});

export default connect(mapStateToProps)(SubtotalContributions);
