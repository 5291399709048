import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';
import { ApplicationShape } from './ApplicationShape';
import { ApplicationCardContent } from './ApplicationCardContent';

const ENTER = 'Enter';

export const ApplicationCard = ({
 history, application, index, isDragDisabled,
}) => {
  const handleKeyUp = (event) => {
    if (event.key && event.key === ENTER) {
      event.preventDefault();
      history.push(`/applications/${application.id}`);
    }
  };

  const handleClick = () => {
    history.push(`/applications/${application.id}`);
  };

  return (
    <Draggable
      key={application.id}
      draggableId={application.id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, { isDragging, isDropAnimating }) => {
        const providedStyle = get(provided, 'draggableProps.style', {});
        const style = isDragging ? providedStyle : omit(providedStyle, 'transform');
        return (
          <ApplicationCardContent
            application={application}
            innerRef={provided.innerRef}
            dragHandleProps={provided.dragHandleProps}
            draggableProps={provided.draggableProps}
            style={style}
            isDragging={isDragging}
            isDropAnimating={isDropAnimating}
            isDragDisabled={isDragDisabled}
            onClick={handleClick}
            onKeyUp={handleKeyUp}
          />
        );
      }}
    </Draggable>
  );
};

ApplicationCard.propTypes = {
  application: ApplicationShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool,
};

ApplicationCard.defaultProps = {
  isDragDisabled: false,
};

export default withRouter(React.memo(ApplicationCard));
