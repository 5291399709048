import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import Modal from 'shared/components/Modal';
import { SIZES } from 'shared/components/Modal/Modal';
import { connect } from 'react-redux';
import Button from 'shared/components/Buttons/Button';
import Checkbox from 'shared/components/formFields/Checkbox';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import NumberInput from 'shared/components/formFields/NumberInput';
import DatePicker from 'shared/components/formFields/DatePicker';
import { toDateStamp } from 'utils/datetime';
import moment from 'moment';
import styles from './styles.module.scss';
import { handleUpdateFixedTrail } from './redux/updateFixedTrailRedux';
import { FETCHING_STATUS } from '../../../constants';

const REQUIRED_FIELD_ERROR_MESSAGE = 'This field is required';
const START_DATE_ERROR_MESSAGE = 'Start date cannot be after end date';
const END_DATE_ERROR_MESSAGE = 'End date cannot be before start date';

export const SetFixedTrailModalComponent = ({
 isOpen, dispatchHandleUpdateFixedTrail, onClose, updatingStatus,
}) => {
  const [excludeFromSafetyNet, toggleExcludeFromSafetyNet] = useState(false);
  const [percentageErrorMessage, updatePercentageErrorMessage] = useState(undefined);
  const [startDateErrorMessage, updateStartDateErrorMessage] = useState(undefined);
  const [endDateErrorMessage, updateEndDateErrorMessage] = useState(undefined);
  const [trailPercentage, updateTrailPercentage] = useState(undefined);
  const [trailStartDate, updateTrailStartDate] = useState(undefined);
  const [trailEndDate, updateTrailEndDate] = useState(undefined);

  useEffect(
    () => {
      toggleExcludeFromSafetyNet(false);
      updateTrailPercentage(undefined);
      updateTrailStartDate(undefined);
      updateTrailEndDate(undefined);
      updatePercentageErrorMessage(undefined);
      updateStartDateErrorMessage(undefined);
      updateEndDateErrorMessage(undefined);
    },
  [isOpen],
  );

  const isUpdating = useMemo(() => updatingStatus === FETCHING_STATUS.START, [updatingStatus]);

  const handleClose = useMemo(() => (value) => {
    if (!isUpdating) {
      onClose(value);
    }
  }, [isUpdating, onClose]);

  const onUpdateFixedTrailSaveClicked = async () => {
      await dispatchHandleUpdateFixedTrail({
        excludeFromSafetyNet,
        trailPercentage,
        trailStartDate,
        trailEndDate,
      });
      onClose(true);
  };

  const disableButton = useMemo(() =>
    trailPercentage === undefined
    || trailStartDate === undefined
    || !!endDateErrorMessage
    || isUpdating
    || !!percentageErrorMessage
    || !!startDateErrorMessage,
    [
      trailPercentage,
      trailStartDate,
      endDateErrorMessage,
      isUpdating,
      percentageErrorMessage,
      startDateErrorMessage,
    ]);

  const onPercentageChange = (value) => {
    if (isNil(value)) {
      updatePercentageErrorMessage(REQUIRED_FIELD_ERROR_MESSAGE);
    } else {
      updatePercentageErrorMessage(undefined);
    }
    updateTrailPercentage(value);
  };

  useEffect(() => {
    if (trailEndDate && trailStartDate && moment(trailEndDate).isAfter(moment(trailStartDate))) {
      updateStartDateErrorMessage(undefined);
      updateEndDateErrorMessage(undefined);
    }
  }, [trailStartDate, trailEndDate]);

  const onStartDateChange = (newStartDateValue) => {
    if (isNil(newStartDateValue)) {
      updateStartDateErrorMessage(REQUIRED_FIELD_ERROR_MESSAGE);
    } else if (trailEndDate && moment(newStartDateValue).isAfter(moment(trailEndDate))) {
      updateStartDateErrorMessage(START_DATE_ERROR_MESSAGE);
      } else {
        updateStartDateErrorMessage(undefined);
        updateEndDateErrorMessage(undefined);
      }
    updateTrailStartDate(newStartDateValue ? toDateStamp(newStartDateValue) : undefined);
  };

  const onEndDateChange = (newEndDateValue) => {
    if (newEndDateValue && trailStartDate && moment(trailStartDate).isAfter(moment(newEndDateValue))) {
      updateEndDateErrorMessage(END_DATE_ERROR_MESSAGE);
    } else {
      updateEndDateErrorMessage(undefined);
      if (!isNil(trailStartDate)) {
        updateStartDateErrorMessage(undefined);
      }
    }
    updateTrailEndDate(newEndDateValue ? toDateStamp(newEndDateValue) : undefined);
  };

  return (
    <Modal
      isOpen={isOpen}
      header="Fixed trails"
      disabled={isUpdating}
      footer={(
        <Button
          onClick={() => onUpdateFixedTrailSaveClicked()}
          disabled={disableButton}
          loading={isUpdating}
        >
          Save
        </Button>
      )}
      className={styles.modal}
      onRequestClose={handleClose}
      size={SIZES.LARGE}
    >
      <div className={styles.modalBody}>
        <section>
          <NewInputLabelWrapper label="Exclude from safety net?">
            <Checkbox
              checked={excludeFromSafetyNet}
              onChange={({ checked }) => toggleExcludeFromSafetyNet(checked)}
              labelName="Yes"
            />
          </NewInputLabelWrapper>

        </section>
        <section className={styles.bottomSection}>
          <NumberInput
            label="Percentage"
            decimalScale={2}
            value={trailPercentage}
            onChange={onPercentageChange}
            fullWidth
            isAllowed={number => number === undefined || number <= 100}
            suffix="%"
            allowNegative={false}
            fixedDecimalScale
            placeholder="Type to enter percentage"
            errorMessage={percentageErrorMessage}
          />
          <DatePicker
            label="Start date"
            type="date"
            value={trailStartDate}
            showClearButton
            onChange={onStartDateChange}
            fullWidth
            height="auto"
            errorMessage={startDateErrorMessage}
          />
          <DatePicker
            label="End date"
            type="date"
            showClearButton
            value={trailEndDate}
            onChange={onEndDateChange}
            errorMessage={endDateErrorMessage}
            fullWidth
            height="auto"
          />
        </section>
      </div>
    </Modal>
  );
};

SetFixedTrailModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatchHandleUpdateFixedTrail: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updatingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
};

const mapStateToProps = state => ({
  updatingStatus: get(state, 'loans.fixedTrails.updatingStatus'),
});

export default connect(mapStateToProps, {
  dispatchHandleUpdateFixedTrail: handleUpdateFixedTrail,
})(SetFixedTrailModalComponent);
