export const OWNER_OCCUPIED = 'OwnerOccupied';
export const INVESTMENT = 'Investment';
export const PRIMARY_PURPOSE_OPTIONS = [
  {
    label: 'Owner Occupied',
    value: OWNER_OCCUPIED,
  },
  {
    label: INVESTMENT,
    value: INVESTMENT,
  },
];

export const PROPERTY_TYPE = {
  Residential: 'Residential',
  Commercial: 'Commercial',
  Rural: 'Rural',
  Industrial: 'Industrial',
};

export const PROPERTY_TYPE_OPTIONS = Object.keys(PROPERTY_TYPE).map(type => ({ label: type, value: type }));

export const PROPERTY_SUB_TYPE_MAPPING = {
  Residential: {
    AptUnitFlat: {
      label: 'Apt Unit Flat',
      value: 'AptUnitFlat',
    },
    BedsitterBachelor: {
      label: 'Bedsitter Bachelor',
      value: 'BedsitterBachelor',
    },
    BoardingHouse: {
      label: 'Boarding House',
      value: 'BoardingHouse',
    },
    CombiShopResidence: {
      label: 'Combi Shop Residence',
      value: 'CombiShopResidence',
    },
    Commercial: {
      label: 'Commercial',
      value: 'Commercial',
    },
    CompanyTitleUnit: {
      label: 'Company Title Unit',
      value: 'CompanyTitleUnit',
    },
    ConvertedCommercialProperty: {
      label: 'Converted Commercial Property',
      value: 'ConvertedCommercialProperty',
    },
    ConvertedIndustrialProperty: {
      label: 'Converted Industrial Property',
      value: 'ConvertedIndustrialProperty',
    },
    ConvertedMotelUnits: {
      label: 'Converted Motel Units',
      value: 'ConvertedMotelUnits',
    },
    ConvertedProperty: {
      label: 'Converted Property',
      value: 'ConvertedProperty',
    },
    DisplayHome: {
      label: 'Display Home',
      value: 'DisplayHome',
    },
    Duplex: {
      label: 'Duplex',
      value: 'Duplex',
    },
    FullyDetachedHouse: {
      label: 'Fully Detached House',
      value: 'FullyDetachedHouse',
    },
    GovtRentalGuarantee: {
      label: 'Govt Rental Guarantee',
      value: 'GovtRentalGuarantee',
    },
    HobbyFarm: {
      label: 'Hobby Farm',
      value: 'HobbyFarm',
    },
    HolidayHome: {
      label: 'Holiday Home',
      value: 'HolidayHome',
    },
    HolidayRental: {
      label: 'Holiday Rental',
      value: 'HolidayRental',
    },
    Industrial: {
      label: 'Industrial',
      value: 'Industrial',
    },
    InnerCityApartment: {
      label: 'Inner City Apartment',
      value: 'InnerCityApartment',
    },
    KitHome: {
      label: 'Kit Home',
      value: 'KitHome',
    },
    LicencedBuilderHouseConstruction: {
      label: 'Licenced Builder House Construction',
      value: 'LicencedBuilderHouseConstruction',
    },
    LuxuryHouse: {
      label: 'Luxury House',
      value: 'LuxuryHouse',
    },
    LuxuryOther: {
      label: 'Luxury Other',
      value: 'LuxuryOther',
    },
    MultipleOnTitle: {
      label: 'Multiple On Title',
      value: 'MultipleOnTitle',
    },
    NewStrataTitleUnit: {
      label: 'New Strata Title Unit',
      value: 'NewStrataTitleUnit',
    },
    NursingHome: {
      label: 'Nursing Home',
      value: 'NursingHome',
    },
    OwnerBuilderHouseConstruction: {
      label: 'Owner Builder House Construction',
      value: 'OwnerBuilderHouseConstruction',
    },
    PropertyDevelopment: {
      label: 'Property Development',
      value: 'PropertyDevelopment',
    },
    RelocatableHome: {
      label: 'Relocatable Home',
      value: 'RelocatableHome',
    },
    RentalGuarantee: {
      label: 'Rental Guarantee',
      value: 'RentalGuarantee',
    },
    ResortUnit: {
      label: 'Resort Unit',
      value: 'ResortUnit',
    },
    RetirementUnit: {
      label: 'Retirement Unit',
      value: 'RetirementUnit',
    },
    SemiDetachedHouse: {
      label: 'Semi Detached House',
      value: 'SemiDetachedHouse',
    },
    ServicedApt: {
      label: 'Serviced Apt',
      value: 'ServicedApt',
    },
    SingleBedroomLess50m2: {
      label: 'Single Bedroom Less 50 m2',
      value: 'SingleBedroomLess50m2',
    },
    SnowLease: {
      label: 'Snow Lease',
      value: 'SnowLease',
    },
    StrataTitleUnit: {
      label: 'Strata Title Unit',
      value: 'StrataTitleUnit',
    },
    StudentAccommodation: {
      label: 'Student Accommodation',
      value: 'StudentAccommodation',
    },
    StudioWarehouseApt: {
      label: 'Studio Warehouse Apt',
      value: 'StudioWarehouseApt',
    },
    Terrace: {
      label: 'Terrace',
      value: 'Terrace',
    },
    Timeshare: {
      label: 'Timeshare',
      value: 'Timeshare',
    },
    Townhouse: {
      label: 'Townhouse',
      value: 'Townhouse',
    },
    TransportableHome: {
      label: 'Transportable Home',
      value: 'TransportableHome',
    },
    UnitStudentAccom: {
      label: 'Unit Student Accom',
      value: 'UnitStudentAccom',
    },
    VacantLand: {
      label: 'Vacant Land',
      value: 'VacantLand',
    },
    Villa: {
      label: 'Villa',
      value: 'Villa',
    },
    WarehouseConversion: {
      label: 'Warehouse Conversion',
      value: 'WarehouseConversion',
    },
  },
  Commercial: {
    Factory: {
      label: 'Factory',
      value: 'Factory',
    },
    NonSpecialisedCommercial: {
      label: 'Non Specialised Commercial',
      value: 'NonSpecialisedCommercial',
    },
    Offices: {
      label: 'Offices',
      value: 'Offices',
    },
    ProfChambers: {
      label: 'Prof Chambers',
      value: 'ProfChambers',
    },
    ResidentialCommercial: {
      label: 'Residential Commercial',
      value: 'ResidentialCommercial',
    },
    RetirementVillage: {
      label: 'Retirement Village',
      value: 'RetirementVillage',
    },
    VacantLand: {
      label: 'Vacant Land',
      value: 'VacantLand',
    },
    Warehouse: {
      label: 'Warehouse',
      value: 'Warehouse',
    },
    Other: {
      label: 'Other',
      value: 'Other',
    },
  },
  Rural: {
    EightHectaresOrLess: {
      label: '8 Hectares Or Less',
      value: 'EightHectaresOrLess',
    },
    OverEightLessThanFourtyHectares: {
      label: 'Over 8 Less Than 40 Hectares',
      value: 'OverEightLessThanFourtyHectares',
    },
    OverFourtyHectares: {
      label: 'Over 40 Hectares',
      value: 'OverFourtyHectares',
    },
  },
  Industrial: {
    NonSpecialisedIndustrial: {
      label: 'Non Specialised Industrial',
      value: 'NonSpecialisedIndustrial',
    },
    LightIndustrial: {
      label: 'Light Industrial',
      value: 'LightIndustrial',
    },
    Other: {
      label: 'Other',
      value: 'Other',
    },
  },
};

export const VALUATION_TYPE_OPTIONS = [
  {
    label: 'Applicant Estimate',
    value: 'ApplicantEstimate',
  },
  {
    label: 'Certified Valuation',
    value: 'CertifiedValuation',
  },
];

export const SUB_TYPES_NAME_MAPPING = {
  Residential: 'residentialType',
  Commercial: 'commercialType',
  Rural: 'ruralType',
  Industrial: 'industrialType',
};

export const CONSTRUCTION_RESIDENTIAL = [
  PROPERTY_SUB_TYPE_MAPPING.Residential.LicencedBuilderHouseConstruction.value,
  PROPERTY_SUB_TYPE_MAPPING.Residential.OwnerBuilderHouseConstruction.value,
];

export const RENTAL_FREQUENCY_OPTIONS = [{
  value: 'Weekly',
  label: 'Weekly',
}, {
  value: 'Fortnightly',
  label: 'Fortnightly',
}, {
  value: 'Monthly',
  label: 'Monthly',
}, {
  value: 'Yearly',
  label: 'Yearly',
}];

export const isConstruction = residencyType => CONSTRUCTION_RESIDENTIAL.includes(residencyType);
