import React from 'react';
import { Field, Fields } from 'redux-form';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import styles from './Expense.module.scss';
import { FREQUENCY_TYPE_OPTIONS } from './constants';

const renderTypeComponent = (label, hint) => (
  <div className={classNames(styles.typeLabel, 'hint-wrap')}>
    <div className="hint" hint={hint}>{label}</div>
  </div>
);

const ExpenseValueUnitFields = (fields) => {
  const [valueField, periodUnitField] = fields.names.map(name => get(fields, name));
  return (
    <>
      <ReduxFormNumberInput
        label="Amount"
        className={styles.amount}
        textClassName={styles.alignTextRight}
        placeholder="$"
        decimalScale={2}
        prefix="$"
        fullWidth
        allowNegative={false}
        {...valueField}
      />
      <ReduxFormSelect
        className={styles.unit}
        fullWidth
        options={FREQUENCY_TYPE_OPTIONS}
        {...periodUnitField}
      />
    </>
  );
};

const Expense = ({ fieldNamePrefix, expense }) => {
  const { label, hint } = expense;
  return (
    <div className={styles.expenseWrapper}>
      <Field
        name={`${fieldNamePrefix}.type`}
        component={() => renderTypeComponent(label, hint)}
      />
      <Fields
        names={[`${fieldNamePrefix}.value`, `${fieldNamePrefix}.periodUnit`, `${fieldNamePrefix}.monthlyValue`]}
        component={ExpenseValueUnitFields}
      />
    </div>
  );
};

Expense.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  expense: PropTypes.object.isRequired,
};

export default React.memo(Expense);
