import axios from 'axios';
import { LOCATION_SUGGEST_ENDPOINT } from '../../config/environment';

const addressLookup = query => axios.get(LOCATION_SUGGEST_ENDPOINT, {
  params: {
    query,
    max: 20,
    type: 'address',
    src: 'smartline',
  },
  withCredentials: false,
});

export {
  addressLookup,
};
