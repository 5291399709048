import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
                   color, width, height, alt, ...others
                 }) => (
                   <svg
                     width={width}
                     height={height}
                     viewBox="0 0 36 36"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     {...others}
                   >
                     <title>{alt}</title>

                     <g
                       id="Icon/add"
                       stroke="none"
                       strokeWidth="1"
                       fill="none"
                       fillRule="evenodd"
                     >
                       <path
                         id="Shape"
                         fill={color}
                         d="M16.5 16.5L16.5 7.5 19.5 7.5 19.5 16.5 28.5 16.5 28.5 19.5 19.5 19.5 19.5 28.5 16.5 28.5 16.5 19.5 7.5 19.5 7.5 16.5z"
                       />
                     </g>
                   </svg>
                 );

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
