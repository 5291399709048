import moment from 'moment';

export const getUTCNow = () => moment().utc().format();

export const toUTCStr = date => moment(date).utc().format();

export const to23HStrWithoutDate = dateTime => moment(dateTime).format('HH:mm:ss');

export const toTimeStrWithoutDate = dateTime => moment(dateTime).format('h:mm A');

export const toTimeStr = dateTime => moment(dateTime).format('D MMMM YYYY h:mm A');

export const toTimeStrWithSecond = dateTime => moment(dateTime).format('D MMMM YYYY h:mm:ss A');

export const toDateStr = dateTime => moment(dateTime).format('YYYYMMDD');

export const toShortDateStr = dateTime => moment(dateTime).format('DD MMM YY').toUpperCase();

export const toDateStamp = date => moment(date).format('YYYY-MM-DD');

export const toReverseDateStr = date => moment(date).format('DD-MM-YYYY');

export const toDateStrWithoutYear = dateTime => moment(dateTime).format('dddd D MMMM');

export const toYearStrWithoutDate = dateTime => moment(dateTime).format('YYYY');

export const toWeekAndDayMonthStr = dateTime => (moment(dateTime).format('dddd DD MMMM'));

export const toLongDateStr = dateTime => (moment(dateTime).format('DD MMMM YYYY'));

export const toYear = dateTime => (moment(dateTime).format('YYYY'));

export const toShortMonthStr = dateTime => (moment(dateTime).format('MMM')).toUpperCase();

export const toLongMonthStr = dateTime => (moment(dateTime).format('MMMM')).toUpperCase();

export const beforeNow = dateTime => (moment(dateTime).isBefore(moment.now()));

export const isBeforeThan = (left, right) => moment(left).isBefore(moment(right));

export const isInOneDay = lastCompletedTime => (lastCompletedTime ? moment(moment.now()).isBefore(moment(lastCompletedTime).add(1, 'days')) : false);

export const toDateWithSlash = dateStr => (dateStr ? moment(dateStr).format('DD/MM/YYYY') : '');

export const toDateTimeWithSlash = (datetime) => moment(datetime).format('DD/MM/YY h:mm A');

export const convertHourStrToFullTime = (hourStr) => {
  if (!hourStr) return null;
  const timeArr = hourStr.split(':');
  const dateStr = moment().hour(timeArr[0]).minute(timeArr[1]).second(timeArr[2]);
  return moment(dateStr).format();
};

export const range = (start, end, unit = 'day', step = 1) => {
  const [former, later, direction] = start.isAfter(end) ? [end, start, -1] : [start, end, 1];
  const duration = Math.floor(later.diff(former, unit, true)) + 1;
  const length = (direction * duration) / step;
  return Array.from({ length }, (value, key) => key * step).map(m => start.clone().add(m, unit));
};

const convertToDate = date => moment(moment(date).format('YYYY-MM-DD'));

export const isAfterToday = date => convertToDate(date).isAfter(convertToDate(moment.now()));

export default null;
