import get from 'lodash/get';

export const MESSAGE_KEYS = {
  DEFAULT: 'default',
  APPLICATION_DETAILS: 'applicationDetails',
  APPLICATION_ACTION_TIMELINE_MAP: 'applicationActionTimelineMap',
  APPLICATION_EDIT: 'applicationEdit',
  NETWORK_ERROR: 'networkError',
  APPLICATION_STATUS_UPDATE: 'applicationStatusUpdate',
  ACKNOWLEDGE_APPLICATION_UPDATE: 'acknowledgeApplicationUpdate',
  GET_ALL_CLIENTS: 'getAllClients',
  TASK_LIST_STATUS: 'taskListStatus',
  UPDATE_CONTACT: 'updateContact',
  UPDATE_SURVEY_CONTACT: 'updateSurveyContact',
  UPDATE_APPLICATION: 'updateApplication',
  UPDATE_LOAN: 'updateLoan',
  SPLIT_LOAN: 'splitLoan',
  LOAN_DETAILS: 'loanDetails',
  EXPECTED_SETTLEMENT_DATE_UPDATE: 'expectedSettlementDateUpdate',
  LOAN_CATEGORIES: 'loanCategories',
  CREATE_LOAN_CATEGORIES: 'createLoanCategories',
  DELETE_LOAN_CATEGORIES: 'deleteLoanCategories',
  UPDATE_LOAN_CATEGORIES: 'updateLoanCategories',
  SET_LOAN_CATEGORIES: 'SET_LOAN_CATEGORIES',
  BULK_UPDATE_FIXED_TRAIL_DETAILS: 'bulkUpdateFixedTrailDetails',
  BULK_REMOVE_FIXED_TRAIL_DETAILS: 'bulkRemoveFixedTrailDetails',
  CREATE_APPLICATION: 'createApplication',
  COPY_APPLICATION: 'copyApplication',
  PRE_SUBMISSION_INFO_UPDATE: 'preSubmissionInfoUpdate',
  SEND_TO_APPLY_ONLINE: 'sendToApplyOnline',
  APPLY_ONLINE_VALIDATE: 'applyOnlineValidate',
  GET_SOMA_DOCUMENT_URL: 'createSoma',
  UPDATE_APPLICANTS: 'updateApplicants',
  UPDATE_ASSETS: 'updateAssets',
  UPDATE_EXPENSE_INFO: 'updateExpenseInfo',
  UPDATE_EMPLOYMENTS: 'updateEmployments',
  UPDATE_PRODUCT_COMPARISON: 'updateProductComparison',
  GET_CLIENT: 'getClient',
  UPDATE_NEED_AND_OBJECTIVE: 'updateNeedAndObjective',
  UPDATE_LIABILITIES: 'updateLiabilities',
  SYNC_APPLICANTS_INFO: 'syncApplicantsInfo',
  GET_SERVICEABILITY: 'getServiceability',
  FUNDING_WORKSHEET_CALCULATION: 'fundingWorksheetCalculation',
  CREATE_NOTE: 'createNote',
  UPDATE_NOTE: 'updateNote',
  HIDE_APPLICATION_ON_SWIMLANE: 'hideApplicationOnSwimlane',
};

const ERROR_MESSAGE_MAPPING = {
  invalid_future_date: 'You cannot set a future date. Please use the current date or a past date.',
  settled_date_existed: 'The action wasn’t completed because of an issue. Please try again.',
  mandatory_field: 'The action wasn’t completed because of an issue. Please try again.',
};

export const confirmLeaveMessage = 'you have unsaved changes. are you sure you want to leave?';

export const MESSAGES = {
  [MESSAGE_KEYS.DEFAULT]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_EMPLOYMENTS]: {
    400: 'There was a problem saving your changes. Please try again.',
  },
  [MESSAGE_KEYS.APPLICATION_DETAILS]: {
    404: 'Application not found.',
    403: 'You have no permission to view this application.',
  },
  [MESSAGE_KEYS.LOAN_DETAILS]: {
    400: 'There was a problem saving your changes. Please try again.',
    404: 'Loan not found.',
    500: 'The action wasn’t completed because of an issue. Please try again.',
  },
  [MESSAGE_KEYS.NETWORK_ERROR]: {
    message: 'Please check your internet connection and try again.',
  },
  [MESSAGE_KEYS.APPLICATION_STATUS_UPDATE]: {
    500: 'The action wasn’t completed because of an issue. Please try again.',
    403: 'You have no permission to edit this application.',
    400: ({ message }) => {
      const error = JSON.parse(message);
      return get(ERROR_MESSAGE_MAPPING, error.errorCode, null);
    },
  },
  [MESSAGE_KEYS.ACKNOWLEDGE_APPLICATION_UPDATE]: {
    500: 'The action wasn’t completed because of an issue. Please try again.',
    403: 'You have no permission to edit this application.',
    400: 'Something has gone wrong and your changes weren’t applied. Please try again.',
    401: 'Something has gone wrong and your changes weren’t applied. Please try again.',
  },
  [MESSAGE_KEYS.TASK_LIST_STATUS]: {
    400: 'Failed to add task.',
    500: 'Failed to add task.',
  },
  [MESSAGE_KEYS.SPLIT_LOAN]: {
    400: 'The splits were not created. Please try again.',
    500: 'The splits were not created. Please try again.',
  },
  [MESSAGE_KEYS.UPDATE_SURVEY_CONTACT]: {
    400: 'There was a problem saving your changes. Please try again.',
    401: 'Unable to save the changes. Please try again.',
    403: 'Unable to save the changes. Please try again.',
    404: 'Unable to save the changes. Please try again.',
    500: 'Unable to save the changes. Please try again.',
  },
  [MESSAGE_KEYS.UPDATE_APPLICATION]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'The action wasn’t completed because of an issue. Please try again.',
    403: 'You have no permission to update application',
  },
  [MESSAGE_KEYS.UPDATE_LOAN]: {
    400: 'There was a problem saving your changes. Please try again.',
    401: 'There was a problem saving your changes. Please try again.',
    403: 'There was a problem saving your changes. Please try again.',
    404: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
  },
  [MESSAGE_KEYS.EXPECTED_SETTLEMENT_DATE_UPDATE]: {
    500: 'The action wasn’t completed because of an issue. Please try again.',
    403: 'You have no permission to edit this application.',
    400: 'The action wasn’t completed because of an issue. Please try again.',
    404: 'Application not found.',
  },
  [MESSAGE_KEYS.UPDATE_LOAN_CATEGORIES]: {
    200: 'Your changes have been saved successfully',
    403: 'You have no permission to edit categories.',
    400: ({ message }) => message,
    500: 'There was a problem saving your changes. Please try again.',
  },
  [MESSAGE_KEYS.SET_LOAN_CATEGORIES]: {
    400: 'Could not update categories. Please try again.',
    403: 'Could not update categories. Please try again.',
    500: 'Could not update categories. Please try again.',
  },
  [MESSAGE_KEYS.SEND_TO_APPLY_ONLINE]: {
    200: 'Success, the application information has been sent to ApplyOnline.',
    500: 'Error, the application information has not been sent to ApplyOnline. Please try again.',
  },
  [MESSAGE_KEYS.APPLY_ONLINE_VALIDATE]: {
    200: 'Everything in this application is valid for ApplyOnline, you can transfer it when you are ready.',
  },
  [MESSAGE_KEYS.BULK_UPDATE_FIXED_TRAIL_DETAILS]: {
    403: 'You have no permission to update fixed trail details.',
    400: ({ message }) => message,
    500: 'There was a problem saving your changes. Please try again.',
  },
  [MESSAGE_KEYS.BULK_REMOVE_FIXED_TRAIL_DETAILS]: {
    403: 'You have no permission to remove fixed trail details.',
    400: ({ message }) => message,
    500: 'An error occurred. Fixed trails have not been removed. Please try again.',
  },
  [MESSAGE_KEYS.CREATE_APPLICATION]: {
    403: 'You do not have permission to create an application.',
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
  },
  [MESSAGE_KEYS.COPY_APPLICATION]: {
    200: 'Your application was successfully copied.',
    403: 'You do not have permission to copy an application.',
    400: 'Unfortunately your application was not copied. Please try again.',
    500: 'Unfortunately your application was not copied. Please try again.',
  },
  [MESSAGE_KEYS.PRE_SUBMISSION_INFO_UPDATE]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_CONTACT]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_APPLICANTS]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_PRODUCT_COMPARISON]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to update product comparison.',
  },
  [MESSAGE_KEYS.UPDATE_NEED_AND_OBJECTIVE]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_EXPENSE_INFO]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.UPDATE_LIABILITIES]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.SYNC_APPLICANTS_INFO]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem updating applicants from Lotus Notes. Please try again.',
    403: 'You have no permission to edit this application.',
    200: 'Applicant information has been updated from Lotus Notes',
  },
  [MESSAGE_KEYS.GET_SERVICEABILITY]: {
    500: 'Not all serviceability results have loaded. Please refresh your page to try again.',
    403: 'You have no permission to read serviceability.',
  },
  [MESSAGE_KEYS.UPDATE_ASSETS]: {
    400: 'There was a problem saving your changes. Please try again.',
    500: 'There was a problem saving your changes. Please try again.',
    403: 'You have no permission to edit this application.',
  },
  [MESSAGE_KEYS.FUNDING_WORKSHEET_CALCULATION]: {
    400: 'Something has gone wrong. Please try again.',
    500: 'Something has gone wrong. Please try again.',
    403: 'You have no permission to view this application.',
  },
  [MESSAGE_KEYS.CREATE_NOTE]: {
    413: 'Unfortunately your note exceeds the size limit. Please reduce the text or image size and try again.',
    400: 'Unfortunately your note has not been added. Please try again.',
    404: 'Unfortunately your note has not been added. Please try again.',
    500: 'Unfortunately your note has not been added. Please try again.',
  },
  [MESSAGE_KEYS.UPDATE_NOTE]: {
    413: 'Unfortunately your note exceeds the size limit. Please reduce the text or image size and try again.',
    400: 'Unfortunately your note has not been updated. Please try again.',
    404: 'Unfortunately your note has not been updated. Please try again.',
    500: 'Unfortunately your note has not been updated. Please try again.',
  },
  [MESSAGE_KEYS.HIDE_APPLICATION_ON_SWIMLANE]: {
    500: 'Unfortunately your note has not been updated. Please try again.',
  },
};

export default null;
