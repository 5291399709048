import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from '../styles.module.scss';

const SubSection = ({
  header, children, className, headerClassName,
}) => (
  <div className={classNames(styles.section, className)}>
    {header && <h4 className={classNames(headerClassName)}>{header}</h4>}
    {children}
  </div>
);

SubSection.defaultProps = {
  header: undefined,
  className: '',
  headerClassName: '',
};

SubSection.propTypes = {
  header: PropTypes.string,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
};

export default SubSection;
