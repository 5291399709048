import map from 'lodash/map';
import { getTargetChangedValue, getTargetChangedValues } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const createApplicationContentLogTransformer = origin => {
  const loanType = getTargetChangedValue(origin, 'loans[[\\da-f-]+]\\.loanType');
  const adviserSurname = getTargetChangedValue(origin, 'writer\\.surname');
  const adviserFirstName = getTargetChangedValue(origin, 'writer\\.firstName');
  const personNames = map(getTargetChangedValues(origin, 'applicants\\.persons[[\\da-f-]+]\\.name'), 'value');
  const companyNames = map(getTargetChangedValues(origin, 'applicants\\.companies[[\\da-f-]+]\\.name'), 'value');

  return ({
    ...getBasicLogInfo(origin),
    loanType,
    adviserFirstName,
    adviserSurname,
    applicantNames: [...(personNames || []), ...(companyNames || [])],
  });
};

export default createApplicationContentLogTransformer;
