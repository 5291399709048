import get from 'lodash/get';
import omit from 'lodash/omit';
import graphQL from '../applicationDetailsGraphQL';
import { delayedUpdateActivityLogs, getApplicationDetails } from '../action';
import { TRANSACTION_TYPES } from '../constants';

const updateAssets = ({ realEstates, nonRealEstates }) => (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');
  return graphQL.updateAssets(applicationId,
    {
      realEstates: realEstates?.map(item => omit(item, 'tempId')),
      nonRealEstates: nonRealEstates?.map(item => omit(item, 'tempId')),
    })
    .then(() => dispatch(getApplicationDetails(applicationId)))
    .then(() => dispatch(delayedUpdateActivityLogs(applicationId)));
};

const getRealEstatesByType = (transactionType, assets) =>
  assets?.realEstates?.filter(
    realEstate => realEstate.transactionType === transactionType,
  ) ?? [];

export const updateOwnsAndNonRealAssets = ({
                                             realEstates,
                                             nonRealEstates,
                                           }) => (dispatch, getState) => {
  const assets = get(getState(), 'application.applicationDetail.finance.assets');
  const purchasingAssets = getRealEstatesByType(TRANSACTION_TYPES.PURCHASING, assets);
  return dispatch(updateAssets({
    nonRealEstates,
    realEstates: [...realEstates, ...purchasingAssets],
  }));
};

export const updatePurchasingAssets = ({ purchasingAssets, ownedSecurity }) => (dispatch, getState) => {
  const assets = get(getState(), 'application.applicationDetail.finance.assets');
  const ownedNonSecurityRealEstates = getRealEstatesByType(TRANSACTION_TYPES.OWNS, assets)
    .filter(e => !e.asSecurity && !e.isForConstructionLoan);
  return dispatch(updateAssets({
    ...assets,
    realEstates: [...ownedNonSecurityRealEstates, ...ownedSecurity, ...purchasingAssets],
  }));
};
