import React from 'react';
import OverrideText from 'shared/components/formFields/OverrideText';
import map from 'lodash/map';
import { formatBool } from 'shared/formatterUtils';
import Checkbox from 'shared/components/formFields/Checkbox';
import styles from './styles.module.scss';
import { formatAmountWithDefault } from '../../../../../utils/formatters';

export const getTextContent = (title, data) => ({
  title,
  content: data,
  showSideBar: false,
  contentAlignRight: true,
  fieldWrapperClassName: styles.fieldWrapperClassName,
});

export const getNumberContent = (title, data) => ({
  title,
  content: formatAmountWithDefault(data),
  showSideBar: false,
  contentAlignRight: true,
  fieldWrapperClassName: styles.fieldWrapperClassName,
});

export const getOverrideContent = (title, data) => ({
  title,
  content: <OverrideText
    value={data && data.value}
    overrideValue={data && data.overrideValue}
    clickable={false}
  />,
  showSideBar: false,
  contentAlignRight: true,
  fieldWrapperClassName: styles.fieldWrapperClassName,
});

export const getLmiReferencedLenderContent = (title, data) => ({
  title,
  content: data,
  showSideBar: false,
  contentAlignRight: true,
  fieldWrapperClassName: styles.fieldWrapperClassName,
});

export const getBooleanContent = (title, data) => ({
  title,
  content: formatBool(data),
  showSideBar: false,
  fieldWrapperClassName: styles.fieldWrapperClassName,
});

export const getCheckboxContent = (title, data) => ({
  title: '',
  content: (<Checkbox
    labelName={title}
    checked={data}
    readOnly
    disabled
  />),
  showSideBar: false,
});

export const getOtherFields = (otherCosts) => map(otherCosts, otherCost => ({
  title: otherCost.fieldName,
  content: formatAmountWithDefault(otherCost.fieldAmount),
  showSideBar: false,
  contentAlignRight: true,
  fieldWrapperClassName: styles.fieldWrapperClassName,
}));
