import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { toUTCStr } from 'utils/datetime';
import { MESSAGE_KEYS } from '../../../../constants/message';
import {
  activityLogSchema,
  getApplicationSchema,
  getClientSchema,
  fundingPositionsSchema,
  fundingWorksheetFeesSchema, companyClientSchema,
} from '../../../constants/graphQLSchema';
import somaGraphQL from './somaGraphQL';

const FUNDING_WORKSHEET_TOAST_ID = 'calculation';
const getApplicationDetail = applicationId =>
  client.query({
    query: gql`{
      application(applicationId: "${applicationId}")
      ${getApplicationSchema()}
    }`,
  }, { messageKey: MESSAGE_KEYS.APPLICATION_DETAILS, options: { toastId: FUNDING_WORKSHEET_TOAST_ID } });

const getLoans = applicationId =>
  client.query({
    query: gql`{
      loans(applicationId: "${applicationId}")
      {
        id
        createdAt
        amount
        purpose
        repaymentType
        purposeCategory
        refinanceReason
        approvalNumber
        accountNumber
        productType
        productName
        loanType
        loanTerm
        fixedTerm
        interestOnlyTerm
        standardRate
        discountRate
        discountRateTerm
        baseRate
        clientDiscountRate

        commercialProductType
        purposeDetails
        isUpfrontExpected
        isTrailExpected
        referringAdviser
        contractNumber
        commissionExcludingOriginationFee
        originationFee
        internallyReferred
        dateOfWithdrawn
        dateOfDeclined

        isTopUp
        combinedLoanAmount
      }
    }`,
  }, { messageKey: MESSAGE_KEYS.LOAN_DETAILS });

const getActivityLogsFromApplication = applicationId =>
  client.query({
    query: gql`{
      application(applicationId: "${applicationId}")
      {
        activityLogs ${activityLogSchema}
      }
    }`,
  });

const createNote = (
  {
    applicationId, userId, userName, content,
  },
) => client.mutate({
  mutation: gql`mutation{
    createNote(
      input: {
        applicationId: "${applicationId}",
        userId: "${userId}",
        userName: "${userName}",
        content: ${JSON.stringify(content)}
      }
    )
    {
      id
    }
  }`,
}, { messageKey: MESSAGE_KEYS.CREATE_NOTE });

const updateNote = (
  {
    applicationId, userId, userName, content, id,
  },
) => client.mutate({
  mutation: gql`mutation updateNote($id: String!, $input: NoteInput!){
    updateNote(
      input: $input
      id: $id
    )
    {
      id
    }
  }`,
  variables: {
    id,
    input: {
      applicationId, userId, userName, content,
    },
  },
}, { messageKey: MESSAGE_KEYS.UPDATE_NOTE });

const deleteNote = (
  {
    applicationId, userId, userName, content, id,
  },
) => client.mutate({
  mutation: gql`mutation deleteNote($id: String!, $input: NoteInput!){
    deleteNote(
      input: $input
      id: $id
    )
  }`,
  variables: {
    id,
    input: {
      applicationId, userId, userName, content,
    },
  },
});

const deleteTask = taskId => client.mutate({
  mutation: gql`mutation{
    deleteTask(
      taskId: "${taskId}"
    )
  }
  `,
});

const addComment = ({
  taskId, name, createdAt, content,
}) => client.mutate({
    mutation: gql`mutation{
    addComment (
        taskId: "${taskId}"
        name: ${JSON.stringify(name.trim())}
        createdAt: "${createdAt}"
        content: ${JSON.stringify(content)}
    )
    {
      comments {
        name
        content
        createdAt
      }
    }
  }`,
  });

const updateTask = (task) => {
  const taskDescription = task.description ? `${JSON.stringify(task.description)}` : null;
  const dueDateTime = task.dueDateTime ? `${JSON.stringify(task.dueDateTime)}` : null;
  const assigneeId = get(task, 'assignee.id') ? `"${get(task, 'assignee.id')}"` : null;
  return client.mutate({
    mutation: gql`mutation{
    updateTask (
        id: "${task.id}"
        name: ${JSON.stringify(task.name.trim())}
        description: ${taskDescription}
        dueDateTime: ${dueDateTime}
        assigneeId: ${assigneeId}
    )
    {
      id
      name
      description
      dueDateTime
      completed
      assignee {
        firstName
        surname
        id
      }
      comments {
        name
        content
        createdAt
      }
    }
  }`,
  });
};

const acknowledgeApplicationUpdate = (
  {
    businessId, applicationUpdateId, applicationId,
  },
) => client.mutate({
  mutation: gql`mutation{
    acknowledgeUpdate(
      businessId: "${businessId}"
      applicationId: "${applicationId}"
      applicationUpdateId: "${applicationUpdateId}"
    )
    {
      id
      type
      date
      timestamp
      name
      receivedTimestamp
      messageAnnotations{
        type
        value
      }
    }
  }`,
}, { messageKey: MESSAGE_KEYS.ACKNOWLEDGE_APPLICATION_UPDATE });

const formatTaskResourceList = (tasks, applicationId, busienssId) =>
  (tasks ? [
      tasks.map(task =>
        `{
          applicationId: "${applicationId}",
          businessId: "${busienssId}",
          name: ${JSON.stringify(task.name.trim())},
          description: ${JSON.stringify(task.description && task.description.trim())}
        }`),
    ] : []
  );

const createTask = ({
  id, applicationId, businessId, name, order, taskResource, taskResourceList,
}) => {
  const queryId = isEmpty(id) ? '' : `id: "${id}"`;
  const taskResourceValue = taskResource ? `{
      applicationId: "${taskResource.applicationId}"
      businessId: "${businessId}"
      name: ${JSON.stringify(taskResource.taskName.trim())}
  }` : null;

  return client.mutate({
    mutation: gql`mutation{
      createTask(
        input: {
          ${queryId}
          applicationId: "${applicationId}"
          name: ${JSON.stringify(name.trim())}
          order: ${order}
          taskResource: ${taskResourceValue}
          taskResourceList: [${formatTaskResourceList(taskResourceList, applicationId, businessId)}]
        }
      )
      {
        id
        name
        order
        tasks {
          id
          name
          description
          dueDateTime
          completed
          assignee {
            firstName
            surname
            id
          }
          comments {
            name
            content
            createdAt
          }
        }
      }
    }`,
  }, { messageKey: MESSAGE_KEYS.TASK_LIST_STATUS });
};

const updateTaskLists = ({ applicationId, taskLists }) => {
  // TODO: refactor the build schema
  const buildTasksSchema = tasks => `[
    ${tasks.map(({ id }) => `"${id}"`).join(',')}
  ]`;

  const taskListsSchema = `[
    ${taskLists.map(({
      id, order, tasks, name,
    }) => `{id: "${id}", order: ${order}, name: ${JSON.stringify(trim(name))}, taskIds: ${buildTasksSchema(tasks)}}`).join(',')}
  ]`;

  return client.mutate({
    mutation: gql`mutation{
      updateTaskLists(
          applicationId: "${applicationId}"
          taskLists: ${taskListsSchema}
      )
      {
        id
        name
        order
        tasks {
          id
          name
          description
          dueDateTime
          completed
          assignee {
            firstName
            surname
            id
          }
          comments {
            name
            content
            createdAt
          }
        }
      }
    }`,
  });
};

const changeTaskStatus = (taskId, completed) => {
  const lastCompletedTime = completed ? `"${toUTCStr(new Date())}"` : null;

  return client.mutate({
    mutation: gql`mutation{
      changeTaskStatus(
        taskId: "${taskId}"
        completed: ${completed}
        lastCompletedTime: ${lastCompletedTime}
      )
    }
    `,
  });
};

const updateSurveyContact = (applicationId, applicantId) => client.mutate({
  mutation: gql`mutation{
    updateSurveyContact(
      applicationId: "${applicationId}"
      applicantId: "${applicantId}"
    )
  }
  `,
}, { messageKey: MESSAGE_KEYS.UPDATE_SURVEY_CONTACT });

const updateContact = (applicationId, contact) => client.mutate({
    mutation: gql`mutation($contact: ContactInput!) {
      updateContact(
        input: {
          applicationId: "${applicationId}"
          contact: $contact
        }
      )
    }`,
    variables: {
      contact,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_CONTACT });

const allianzAdvisersByBusinessId = businessId => client.query({
  query: gql`query AllianzAdvisersByBusinessId($businessId: String!) {
    allianzAdvisersByBusinessId(businessId: $businessId) {
      id
      allianzId
      fullName
    }
  }`,
  variables: {
    businessId,
  },
});

const updateApplicationAbstract = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: ApplicationAbstract!){
      updateAbstract(
        input: {
          applicationId: "${applicationId}"
          applicationAbstract: $data
        }
      ) {
        id
        applicationNumber
        adviserId
        adviser
        expectedSettlementDate
        preApprovalExpiryDate
        financeDueDate
      }
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_APPLICATION })
);

const updateApplicationLoanRequests = (applicationId, loanRequests) => client.mutate({
  mutation: gql`mutation ($loanRequests: [LoanRequestUpdate]!){
      updateApplicationLoanRequests(
        applicationId: "${applicationId}"
        loanRequests: $loanRequests
      )
    }`,
  variables: {
    loanRequests,
  },
}, { messageKey: MESSAGE_KEYS.UPDATE_APPLICATION });

const updateAllianzInsurance = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: AllianzReferralInput!){
      updateAllianzInsurance(
        applicationId: "${applicationId}"
        data: $data
      )
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.PRE_SUBMISSION_INFO_UPDATE })
);

const submitApplication = (factFindId, data, messageKey) => (
  client.mutate({
    mutation: gql`mutation($data: SubmissionEvent!){
      submitApplication(
        factFindId: "${factFindId}"
        data: $data
      )
    }`,
    variables: {
      data,
    },
  }, { messageKey })
);

const sendToApplyOnline = (applicationId) => (
  client.mutate({
    mutation: gql`mutation{
      sendToApplyOnline(
        applicationId: "${applicationId}"
      )
    }`,
  }, { messageKey: MESSAGE_KEYS.SEND_TO_APPLY_ONLINE })
);

const applyOnlineValidate = (applicationId) => (
  client.mutate({
    mutation: gql`mutation{
      applyOnlineValidate(
        applicationId: "${applicationId}"
      )
    }`,
  }, { messageKey: MESSAGE_KEYS.APPLY_ONLINE_VALIDATE })
);

const updateFundingWorksheet = (applicationId, data) => (
    client.mutate({
      mutation: gql`mutation($data: FundingWorksheetInput! $applicationId: String!){
        updateFundingWorksheet(
          applicationId: $applicationId
          data: $data
        )
      }`,
      variables: {
        applicationId,
        data,
      },
    }, { messageKey: MESSAGE_KEYS.PRE_SUBMISSION_INFO_UPDATE, options: { toastId: FUNDING_WORKSHEET_TOAST_ID } })
  );

const calculateFundingPositions = (applicationId, fundingWorksheet) =>
  client.query({
    query: gql`query FundingPositionsCalculation(
      $applicationId: String!
      $fundingWorksheet: FundingWorksheetInput!
    ) {
      fundingPositionsCalculation(
        applicationId: $applicationId
        data: $fundingWorksheet
      )
      ${fundingPositionsSchema}
    }`,
    variables: {
      applicationId,
      fundingWorksheet,
    },
  }, { messageKey: MESSAGE_KEYS.FUNDING_WORKSHEET_CALCULATION, options: { toastId: FUNDING_WORKSHEET_TOAST_ID } });

const calculateFundingWorksheetFees = (applicationId, fundingWorksheet) =>
client.query({
  query: gql`query fundingWorksheetFeesCalculation(
    $applicationId: String!
    $fundingWorksheet: FundingWorksheetInput!
  ) {
    fundingWorksheetFeesCalculation(
      applicationId: $applicationId
      data: $fundingWorksheet
    )
    ${fundingWorksheetFeesSchema}
  }`,
  variables: {
    applicationId,
    fundingWorksheet,
  },
}, { messageKey: MESSAGE_KEYS.FUNDING_WORKSHEET_CALCULATION, options: { toastId: FUNDING_WORKSHEET_TOAST_ID } });

const updateFinalise = (applicationId, somas, preSubmissionInfoInput) => (
  client.mutate({
    mutation: gql`mutation($somas: [UpdateSomaRequest]! $applicationId: String! $preSubmissionInfoInput: PreSubmissionInfoInput!){
        updateSomas(
            applicationId: $applicationId
            data: $somas
        )
        updatePreSubmissionInfo(
          applicationId: $applicationId
          data: $preSubmissionInfoInput
        )
    }`,
    variables: {
      applicationId,
      somas,
      preSubmissionInfoInput,
    },
  }, { messageKey: MESSAGE_KEYS.PRE_SUBMISSION_INFO_UPDATE })
);

const updateLoanProtectionInsurance = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: LoanProtectionInsuranceInput!){
          updateLoanProtectionInsurance(
              applicationId: "${applicationId}"
              data: $data
          )
      }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.PRE_SUBMISSION_INFO_UPDATE })
);
const updateApplicants = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($applicationId: String! $data: ApplicantsAndGuarantorsUpdateRequest!){
          updateApplicants(
              applicationId: $applicationId
              data: $data
          )
      }`,
    variables: {
      applicationId,
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_APPLICANTS })
);

const updateAssets = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($applicationId: String! $data: UpdateAssetsInput!){
          updateAssets(
              applicationId: $applicationId
              data: $data
          )
      }`,
    variables: {
      applicationId,
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_ASSETS })
);

const updateProductComparison = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: ProductComparisonInput!){
      updateProductComparison(
        applicationId: "${applicationId}"
        data: $data
      ){
          applicationId
          lender {
            lenderId
            name
            bankPortalForBroker
          }
          totalLoanAmount
          productComparisonId
      }
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_PRODUCT_COMPARISON })
);

const updateNeedsAndObjectives = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: NeedAndObjectiveInput!){
      updateNeedAndObjective(
        applicationId: "${applicationId}"
        data: $data
      )
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_NEED_AND_OBJECTIVE })
);

const updateEmployments = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($applicationId: String! $data: UpdateEmploymentsInput!){
      updateEmployments(
        applicationId: $applicationId
        data: $data
      )
    }`,
    variables: {
      applicationId,
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_EMPLOYMENTS })
);

const updateAdditionalIncomes = (applicationId, data) => client.mutate({
  mutation: gql`mutation($applicationId: String! $data: [AdditionalIncomeInput]!){
    updateAdditionalIncomes(
      applicationId: $applicationId
      data: $data
    )
  }`,
  variables: {
    applicationId,
    data,
  },
}, { messageKey: MESSAGE_KEYS.DEFAULT });

const getClientById = (id) => (
client.query({
  query: gql`{
    person(id: "${id}")
    ${getClientSchema}
  }`,
  variables: {
    id,
  },
}, { messageKey: MESSAGE_KEYS.GET_CLIENT })
);

const getCompanyClientById = (id) => (
  client.query({
    query: gql`{
      company(id: "${id}")
      ${companyClientSchema}
    }`,
    variables: {
      id,
    },
  }, { messageKey: MESSAGE_KEYS.GET_CLIENT })
);

const updateExpenseInfo = (applicationId, expenseInfo) => (
  client.mutate({
    mutation: gql`mutation($input: UpdateExpenseInfoInput!){
      updateExpenseInfo(
        input: $input
      )
    }`,
    variables: {
      input: {
        applicationId,
        expenseInfo,
      },
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_EXPENSE_INFO })
);

const updateLiabilities = (applicationId, data) => (
  client.mutate({
    mutation: gql`mutation($data: LiabilitiesInput!){
      updateLiabilities(
        applicationId: "${applicationId}"
        data: $data
      )
    }`,
    variables: {
      data,
    },
  }, { messageKey: MESSAGE_KEYS.UPDATE_LIABILITIES })
);

const syncApplicantsInfo = (applicationId) => (
  client.mutate({
    mutation: gql`mutation($applicationId: String!){
      syncApplicantsInfo(
        applicationId: $applicationId
      )
      ${getApplicationSchema()}
    }`,
    variables: {
      applicationId,
    },
  }, { messageKey: MESSAGE_KEYS.SYNC_APPLICANTS_INFO })
);

export default {
  getApplicationDetail,
  getActivityLogsFromApplication,
  createNote,
  deleteNote,
  updateNote,
  deleteTask,
  updateTask,
  createTask,
  addComment,
  updateTaskLists,
  acknowledgeApplicationUpdate,
  changeTaskStatus,
  getLoans,
  updateContact,
  updateSurveyContact,
  allianzAdvisersByBusinessId,
  updateApplicationAbstract,
  updateApplicationLoanRequests,
  updateAllianzInsurance,
  updateFundingWorksheet,
  updateLoanProtectionInsurance,
  submitApplication,
  sendToApplyOnline,
  applyOnlineValidate,
  updateApplicants,
  updateAssets,
  updateProductComparison,
  updateFinalise,
  updateNeedsAndObjectives,
  updateLiabilities,
  updateEmployments,
  ...somaGraphQL,
  getClientById,
  getCompanyClientById,
  updateAdditionalIncomes,
  updateExpenseInfo,
  syncApplicantsInfo,
  calculateFundingPositions,
  calculateFundingWorksheetFees,
};
