import ReactAutocomplete from 'react-autocomplete';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { inputBaseDefaultProps, inputBaseProps } from 'shared/components/formFields/base/baseProps';
import classNames from 'classnames';
import styles from './Autocomplete.module.scss';

const renderInput = (props) => <input className={styles.autocompleteInput} {...props} />;

const shouldItemRender = (item, selectedValue) => item.label.toLowerCase().indexOf(selectedValue.toLowerCase()) > -1;

const renderStaticItem = (label) => (
  <div className={classNames(styles.item, styles.emptyItem)} key={label}>
    {label}
  </div>
);

export const Autocomplete = ({
                       value,
                       onChange,
                       onSelect,
                       onBlur,
                       placeholder,
                       items,
                       keyExtract,
                       noOptionsMessage,
                       loading,
                       loadingMessage,
                       inputDisabled,
                       ...others
                     }) => {
  const isEmpty = !value;

  const renderItem = useMemo(() => (item, isHighlighted) => (
    <div
      className={classNames(styles.item, { [styles.highlightedItem]: isHighlighted })}
      key={keyExtract ? keyExtract(item.value) : item.value}
    >
      {item.label}
    </div>
  ), [keyExtract]);

  const renderMenu = useMemo(() => (menuItems) => {
    if (loading && loadingMessage) {
      return (
        <div className={styles.menu}>{renderStaticItem(loadingMessage)}</div>
      );
    }
    if (menuItems.length) {
      return (
        <div className={styles.menu}>{menuItems}</div>
      );
    }
    if (!isEmpty && noOptionsMessage) {
      return (
        <div className={styles.menu}>{renderStaticItem(noOptionsMessage)}</div>
      );
    }
    return (<></>);
  }, [isEmpty, loading, loadingMessage, noOptionsMessage]);

  return (
    <div className={styles.autocomplete}>
      <ReactAutocomplete
        items={items}
        value={value}
        getItemValue={item => item.label}
        shouldItemRender={shouldItemRender}
        onChange={onChange}
        onSelect={onSelect}
        renderInput={renderInput}
        renderItem={renderItem}
        renderMenu={renderMenu}
        inputProps={{ onBlur, disabled: inputDisabled, placeholder }}
        {...others}
      />
    </div>
  );
};

Autocomplete.defaultProps = {
  ...inputBaseDefaultProps,
  keyExtract: value => value,
  noOptionsMessage: null,
  loading: false,
  loadingMessage: 'Loading',
  inputDisabled: false,
  placeholder: '',
};

Autocomplete.propTypes = {
  ...inputBaseProps,
  keyExtract: PropTypes.func,
  noOptionsMessage: PropTypes.node,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.node,
  inputDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
