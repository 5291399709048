import React from 'react';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import styles from 'Loans/components/LoanDetails/LoanDetailsContent/Commissions/index.module.scss';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const SELECT_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const InputYesNo = ({
 value, name, disabled, onChange,
}) => (
  <FormsySelect
    options={SELECT_OPTIONS}
    value={SELECT_OPTIONS.find(option => option.value === value)}
    className={styles.select}
    name={name}
    fullWidth
    disabled={disabled}
    onChange={onChange}
  />
);
InputYesNo.defaultProps = {
  disabled: false,
  onChange: noop,
};

InputYesNo.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
