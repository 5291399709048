import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from '../constants/message';

const getCategories = (businessId) => {
  const queryOptions = {
    query: gql`
      query loanCategories($businessId: String!) {
        loanCategories(businessId: $businessId) {
          id
          name
          createTimestamp
        }
      }
    `,
    variables: {
      businessId,
    },
  };
  return client.query(queryOptions, { messageKey: MESSAGE_KEYS.LOAN_CATEGORIES });
};

const updateCategories = loanCategoriesUpdateInput => client.mutate({
  mutation: gql`mutation($loanCategoriesUpdateInput: LoanCategoriesUpdateInput){
    updateLoanCategories(
      input: $loanCategoriesUpdateInput
    ) {
      id
      name
      createTimestamp
    }
  }`,
  variables: {
    loanCategoriesUpdateInput,
  },
}, { messageKey: MESSAGE_KEYS.UPDATE_LOAN_CATEGORIES });

export default {
  getCategories,
  updateCategories,
};
