import { createSelector } from '@reduxjs/toolkit';
import { isInOneDay } from 'utils/datetime';
import moment from 'moment';

const specialTasksSelector = state => state.dashboard.specialTasks;

export default createSelector(
  specialTasksSelector,
  tasks => tasks
    .filter(({ completed, lastCompletedTime }) => (completed ? isInOneDay(lastCompletedTime, 1) : true))
    .sort(
      (task1, task2) =>
        moment(task1.dueDateTime) - moment(task2.dueDateTime) || task1.name.localeCompare(task2.name),
    ),
);
