import React from 'react';
import PropTypes from 'prop-types';
import hasPermissionTo from 'shared/components/authorisation/hasPermissionTo';
import { connect } from 'react-redux';
import NoAccess from '../NoAccess/NoAccess';

const HasPermissions = ({
 permissions, permissionsFromProfile, children, fallback, business,
}) => {
  const hasPermission = permissions.some(item => hasPermissionTo(item, permissionsFromProfile, business ? business.id : '*'));
  if (hasPermission) {
    return children;
  }
  return fallback;
};

HasPermissions.defaultProps = {
  permissions: [],
  children: null,
  fallback: <NoAccess />,
  business: undefined,
};

HasPermissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape({
    application: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  })).isRequired,
  permissionsFromProfile: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  fallback: PropTypes.node,
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  permissionsFromProfile: state.profile.permissions,
  business: state.business.selectedBusiness,
});

export default connect(mapStateToProps)(HasPermissions);
