import { withFormMeta } from 'shared/components/FormMeta';
import DateBox from 'devextreme-react/date-box';
import withReduxForm from '../ReduxFormFieldWrapper';
import { wrapInLabel } from '../../NewInputLabelWrapper';
import styles from './DateSelector.module.scss';
import withReadOnly from '../../ReadOnlyAbilityWrapper';

const ReduxFormDateSelector = withFormMeta(
  withReduxForm((props) => {
    const { onChange, onBlur } = props;

    return ({
      onChange: undefined, // replace onChange with onValueChange
      onValueChanged: (v) => {
        onChange(v.value);
      },
      onFocusOut: () => onBlur(),
    });
  })(withReadOnly(wrapInLabel(DateBox, { error: styles.withError }))),
);

export default ReduxFormDateSelector;
