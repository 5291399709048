export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const USER_PROFILE = '/userProfile';
export const PDF_COMPARISON = '/pdf/comparison';
export const COMMISSIONS_XLSX = '/generateXlsx/commissions';
export const LENDER_RECONCILIATION_XLSX = '/generateXlsx/reconciled-loans';
export const INTERNALLY_REFERRED_XLSX = '/generateXlsx/internally-referred-loans';
export const SEARCHABLE_LOAN_BY_FILTER_XLSX = '/generateXlsx/loans/xlsx';
export const BUSINESS_SUMMARIES = '/business-summaries';
export const MALI_DOCUMENT = '/mali-document';
export const ALL_APPLICATION_XLSX = '/generateXlsx/all-applications/xlsx';
export const NOTIFICATION = '/notifications';
