import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import styles from './Field.module.scss';

export const FIELD_SIDE_BAR_COLORS = {
  Default: 'Default',
  DarkBlue: 'DarkBlue',
  EggShellBlue: 'EggShellBlue',
  LightSquash: 'LightSquash',
  Ocean: 'Ocean',
  Pink: 'Pink',
};

const Field = ({
  title, className, fieldWrapperClassName,
  hidden, content, sideBarClassName, showSideBar, noDisplay, sideBarColour, contentAlignRight,
}) => (
  <div className={classNames(className, styles.wrapper,
      {
        [styles.hidden]: hidden,
        [styles.noDisplay]: noDisplay,
      })}
  >
    {showSideBar && (
    <div className={classNames(
          styles.sideBar,
          sideBarClassName,
          { [styles[`sideBar${sideBarColour}`]]: true },
        )}
    />
      )}
    <div className={classNames(styles.fieldWrapper, fieldWrapperClassName, {
        [styles.noPadding]: !showSideBar,
      })}
    >
      {title !== '' && <div className={styles.title}>{title}</div>}
      <div className={classNames(styles.content, {
          [styles.alignRight]: contentAlignRight,
        })}
      >
        {isFunction(content) ? content() : content}
      </div>
    </div>

  </div>
  );

Field.propTypes = {
  title: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.object],
  ).isRequired,
  content: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.func, PropTypes.node],
  ),
  className: PropTypes.string,
  sideBarClassName: PropTypes.string,
  fieldWrapperClassName: PropTypes.string,
  hidden: PropTypes.bool,
  noDisplay: PropTypes.bool,
  showSideBar: PropTypes.bool,
  contentAlignRight: PropTypes.bool,
  sideBarColour: PropTypes.oneOf(Object.values(FIELD_SIDE_BAR_COLORS)),
};

Field.defaultProps = {
  className: '',
  content: '',
  sideBarClassName: '',
  fieldWrapperClassName: '',
  hidden: false,
  noDisplay: false,
  showSideBar: true,
  sideBarColour: FIELD_SIDE_BAR_COLORS.Default,
  contentAlignRight: false,
};

export default Field;
