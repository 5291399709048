import React from 'react';
import { ProductsComparisonTableContext } from '../../ProductSearchContext';

import DownloadComparison from './DownloadComparison';

const ConnectedDownloadComparison = () => (
  <ProductsComparisonTableContext.Consumer>
    {
        context => {
          const { comparison: { hasError, isLoading, isDownloading }, disableDownload, hideDownloadButton } = context;
          const { businessCount } = context;
          if (hideDownloadButton) {
            return null;
          }
          return (
            <DownloadComparison
              downloadPdf={context.dispatchDownloadPdf}
              hasError={hasError}
              isLoading={isLoading}
              isDownloading={isDownloading}
              businessCount={businessCount}
              disabled={disableDownload}
            />
          );
        }
      }
  </ProductsComparisonTableContext.Consumer>
  );

export default ConnectedDownloadComparison;
