import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

const withDisabledOnlyAbility = Component => {
  const HOC = (props) => {
    const {
      disabledOnlyFormatter,
      ...others
    } = props;

    const {
      disabled,
      value,
    } = others;
    if (disabled) {
      const formattedValue = isFunction(disabledOnlyFormatter)
        ? disabledOnlyFormatter(others)
        : value;
      return (
        <>
          {formattedValue}
        </>
      );
    }
    return <Component {...others} />;
  };
  HOC.propTypes = {
    disabledOnlyFormatter: PropTypes.func,
  };
  HOC.defaultProps = {
    disabledOnlyFormatter: undefined,
  };
  HOC.displayName = `withDisabledOnlyAbility(${Component.displayName || Component.name || 'component'})`;
  return HOC;
};

export default withDisabledOnlyAbility;
