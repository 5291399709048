import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import OptOutInsuranceReferral from './OptOutInsurance/OptOutInsuranceReferral';
import GeneralInsuranceReferral from './Insurance/index';
import FormMetaProvider from '../../../../../../shared/components/FormMeta/FormMetaProvider';

const GeneralInsuranceSection = ({ allianzReferral, hasEditPermission }) => {
  const isOptOut = Boolean(allianzReferral.allianzPromptForReferralDate) && allianzReferral.allianzOptOut === true;
  const isSentToAllianz = Boolean(allianzReferral.allianzPromptForReferralDate)
    && allianzReferral.allianzOptOut === false;

  return (
    <FormMetaProvider readOnly={!hasEditPermission} disabled={isSentToAllianz}>
      {isOptOut
        ? <OptOutInsuranceReferral allianzReferral={allianzReferral} />
        : (
          <div>
            <GeneralInsuranceReferral
              hasEditPermission={hasEditPermission}
              isSentToAllianz={isSentToAllianz}
              existAllianzReferral={allianzReferral}
            />
          </div>
        )}
    </FormMetaProvider>
  );
};

GeneralInsuranceSection.propTypes = {
  allianzReferral: PropTypes.shape({
    allianzPromptForReferralDate: PropTypes.string,
    allianzOptOut: PropTypes.bool,
    allianzOptOutReason: PropTypes.string,
    allianzReferralDetails: PropTypes.shape({
      adviserName: PropTypes.string,
      adviserId: PropTypes.string,
      applicantId: PropTypes.string,
      applicantName: PropTypes.string,
      phoneNumber: PropTypes.string,
      alternativePhoneNumber: PropTypes.string,
      preferredTime: PropTypes.string,
      email: PropTypes.string,
      reason: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  allianzReferral: get(state, 'application.applicationDetail.insuranceReferrals.allianzReferral') || {},
});

export default connect(mapStateToProps)(GeneralInsuranceSection);
