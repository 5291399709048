import { createSelector } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import every from 'lodash/every';
import isNumber from 'lodash/isNumber';
import sum from 'lodash/sum';
import { formValueSelector } from 'redux-form';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import { getProductComparison } from '../productComparison';
import { getApplicationId } from '../applicationIdSelector';
import { PURPOSE_OPTION_VALUES, REPAYMENT_FREQUENCIES_VALUES } from '../../../../../../constants';
import { FORM_NAME } from '../../../ApplicationDetailContentNav/ProductSearch/constants';
import { calculateLVR } from '../../../ApplicationDetailContentNav/ProductSearch/SearchCriteria/Field/utils';

export const initialLoanCriteria = {
  loanAmount: undefined,
  combinedLoanAmount: undefined,
  loanTerm: 30,
  errorMessage: {},
  selectedFeatureNames: [],
  key: '0',
  purposeCategory: 'Purchase',
  purposeDetails: null,
  refinanceReason: null,
  repaymentType: null,
  productType: null,
  purpose: PURPOSE_OPTION_VALUES.OWNER_OCCUPIED,
  fixedLoanTerm: null,
  interestOnlyTerm: null,
};

const initialValues = {
  loansCriteria: [initialLoanCriteria],
  applicationCriteria: {
    totalLoanAmount: null,
    totalCombinedLoanAmount: null,
    securityValue: null,
    loanValueRatio: null,
    errorMessage: {},
    modifySearch: true,
    selectedLenderNames: [],
    applicationType: APPLICATION_TYPES.NEW_LOAN,
  },
  totalCostTerm: 30,
  repaymentFrequency: REPAYMENT_FREQUENCIES_VALUES.PER_MONTH,
};

export const getInitialCriteria = createSelector(
  getProductComparison,
  getApplicationId,
  (productComparison, applicationId) => {
    if (!productComparison) {
      return { ...initialValues, applicationId };
    }
    const {
      searchCriteria: {
        loansCriteria,
        applicationCriteria,
        totalCostTerm = 30,
        repaymentFrequency = REPAYMENT_FREQUENCIES_VALUES.PER_MONTH,
      },
    } = productComparison;
    return ({
      loansCriteria,
      applicationCriteria: {
        ...applicationCriteria,
        applicationType: applicationCriteria.applicationType || APPLICATION_TYPES.NEW_LOAN,
        selectedLenderNames: applicationCriteria.selectedLenderNames || [],
      },
      totalCostTerm,
      repaymentFrequency,
      applicationId,
    });
  },
);

const embeddedProductSearchCriteriaFormValueSelector = formValueSelector(FORM_NAME);

export const getApplicationType = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.applicationType');

export const getTotalLoanAmount = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.totalLoanAmount');

export const getTotalCombinedLoanAmount = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.totalCombinedLoanAmount');

export const getSecurityValue = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.securityValue');

export const getLVR = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.loanValueRatio');

export const getCalculatedLVR = createSelector(
  getApplicationType,
  getTotalLoanAmount,
  getTotalCombinedLoanAmount,
  getSecurityValue,
  (applicationType, totalLoanAmount, totalCombinedLoanAmount, securityValue) => {
    const amount = applicationType === APPLICATION_TYPES.NEW_LOAN ? totalLoanAmount : totalCombinedLoanAmount;
    return isNil(amount) || isNil(securityValue)
      ? null
      : calculateLVR(amount, securityValue);
  },
);

const getLoansCriteria = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'loansCriteria');

const getLoanAmounts = createSelector(
  getLoansCriteria,
  loansCriteria => map(loansCriteria, 'loanAmount'),
);

const getCombinedLoanAmounts = createSelector(
  getLoansCriteria,
  loansCriteria => map(loansCriteria, 'combinedLoanAmount'),
);

export const getCalculatedTotalLoanAmount = createSelector(
  getLoanAmounts,
  loanAmounts => (every(loanAmounts, isNumber) ? sum(loanAmounts) : null),
);

export const getCalculatedTotalCombinedLoanAmount = createSelector(
  getCombinedLoanAmounts,
  combinedLoanAmount => (every(combinedLoanAmount, isNumber) ? sum(combinedLoanAmount) : null),
);

export const getSelectedLenderNames = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationCriteria.selectedLenderNames');

export const getApplicationIdInForm = (state) =>
  embeddedProductSearchCriteriaFormValueSelector(state, 'applicationId');
