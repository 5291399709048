import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
                   color, width, height, alt,
                 }) => (
                   <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 35">
                     <title>{alt}</title>
                     <path fill={color} fillRule="evenodd" d="M10.778 8.8V4.45c0-.8.646-1.45 1.444-1.45h17.334C30.353 3 31 3.65 31 4.45v20.3c0 .8-.647 1.45-1.444 1.45h-4.334v4.35c0 .8-.65 1.45-1.454 1.45H6.455a1.443 1.443 0 0 1-1.028-.422A1.454 1.454 0 0 1 5 30.55l.004-20.3c0-.8.65-1.45 1.455-1.45h4.319zm-2.885 2.9L7.89 29.1h14.444V11.7H7.893zm5.774-2.9h11.555v14.5h2.89V5.9H13.666v2.9z" />
                   </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
