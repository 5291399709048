import get from 'lodash/get';
import moment from 'moment';
import * as api from '../allApplicationsGraphQL';
import { ACTIONS } from './actions';
import { downloadSearchableAllApplicationsXlsx } from '../../shared/api';
import { openInTab } from '../../shared/utils';
import { showToast } from '../../redux/toast/actions';
import { getRequestParams } from './actionCreatorsHelper';

export const getPageableApplications = query => async (dispatch) => {
  dispatch({ type: ACTIONS.GET_ALL_APPLICATIONS_START });
  const request = getRequestParams(query);

  try {
    const { data: { allApplicationsSearch } } = await api.pageableQueryAllApplications(request);
    dispatch({
      type: ACTIONS.GET_ALL_APPLICATIONS_SUCCESS,
      data: allApplicationsSearch,
    });
  } catch (errors) {
    dispatch({
      type: ACTIONS.GET_ALL_APPLICATIONS_ERRORS,
      errors,
    });
  }
};

export const downloadAllApplications = query => (dispatch, getState) => {
  dispatch(downloadAllApplicationsStart());
  const request = getRequestParams(query);
  const selectedBusiness = get(getState(), 'business.selectedBusiness');
  return downloadSearchableAllApplicationsXlsx(request)
  .then((xlsxBlob) => {
    openInTab(xlsxBlob, `applications_${selectedBusiness.bizName}_${moment().format('YYYY_MM_DD')}.xlsx`);
    dispatch(downloadAllApplicationsSuccess());
  })
  .catch(() => {
    dispatch(downloadAllApplicationsErrors());
    dispatch(
      showToast(
        'There was a problem while downloading your xlsx. Please try again later.',
        { type: 'error' },
      ),
    );
  });
};

export const setPageableParams = (skip, take) => ({
  type: ACTIONS.GET_ALL_APPLICATIONS_INIT,
  payload: {
    skip,
    take,
  },
});

export const reset = ({
  type: ACTIONS.RESET,
});

export const requestInit = () => ({
  type: ACTIONS.INIT_QUERY,
});

const downloadAllApplicationsStart = () => ({
  type: ACTIONS.DOWNLOAD_ALL_APPLICATIONS_START,
});

const downloadAllApplicationsSuccess = () => ({
  type: ACTIONS.DOWNLOAD_ALL_APPLICATIONS_SUCCESS,
});

const downloadAllApplicationsErrors = () => ({
  type: ACTIONS.DOWNLOAD_ALL_APPLICATIONS_ERRORS,
});

export const updateRemoteFilters = remoteFilters => ({
  type: ACTIONS.UPDATE_REMOTE_FILTERS,
  payload: { remoteFilters },
});
