export const EMPTY_CHARACTER = '\u00a0';

export const MONTHLY = {
  label: 'Monthly',
  value: 'Monthly',
};

export const FREQUENCY_TYPE_OPTIONS = [
  {
    label: 'Weekly',
    value: 'Weekly',
  }, {
    label: 'Fortnightly',
    value: 'Fortnightly',
  },
  MONTHLY,
  {
    label: 'Yearly',
    value: 'Yearly',
  },
];

export const INVESTMENT_UTILITIES = {
  label: 'Investment property costs',
  hint: 'Costs associated with any property owned for investment purposes. Includes body corporate fees, strata fees, land tax, property management fees, rates, water, sewage, repairs and maintenance, electricity, gas, heating oil and wood for fuel, purchase, hire and repair of household and outdoor furnishings and equipment, furnishings, floor coverings, linen, tableware, gardening tools, power tools, electrical, lights, trees, shrubs, plants, pool chemicals, landlords insurance, home insurance, contents insurance and appliance insurance.',
};

export const LIVING_EXPENSES = [
  {
    index: 0,
    label: 'Board',
    hint: 'Ongoing board commitments that will continue to be paid after settlement',
    value: 'Board',
  },
  {
    index: 1,
    label: 'Childcare',
    hint: 'Childcare including nannies and non-compulsory pre-school.',
    value: 'Childcare',
  },
  {
    index: 2,
    label: 'Child and spousal maintenance',
    hint: 'Maintenance for either dependent or non-dependent children, and spousal or de facto partner maintenance.',
    value: 'ChildMaintenance',
  },
  {
    index: 3,
    label: 'Clothing & personal care',
    hint: 'Clothing, footwear, personal care products, cosmetics, hair services and accessories (including travel goods, handbags, umbrellas, wallets, and baby-goods) for men, women, children and infants, including purchases, repairs and alterations.',
    value: 'ClothingPersonalCare',
  },
  {
    index: 4,
    label: 'Groceries',
    hint: 'Groceries (food and non-alcoholic beverages), toiletries and cleaning products.',
    value: 'Groceries',
  },
  {
    index: 5,
    label: 'Higher education & vocational training',
    hint: 'Higher education and vocational training fees (including TAFE, business college, drama, music, dance) excluding HECS, and professional fees (union dues, professional association subscriptions, legal, accountant and tax agent fees).',
    value: 'EducationTertiary',
  },
  {
    index: 6,
    label: 'Medical & health',
    hint: 'Medical and health care services including GPs, specialists, physiotherapists, chiropractors, and opticians. Medicines, pharmaceuticals, prescriptions, glasses, purchase or hire of therapeutic appliances and equipment, hospital charges, nursing home charges. Excludes health insurance and ambulance insurance.',
    value: 'MedicalHealth',
  },
  {
    index: 7,
    label: 'O/Occ Strata, Body Corp, Land tax',
    hint: 'O/Occ Strata, Body Corporate Fees & Land Tax \n Land tax, body corporate and strata fees on owner-occupied principal place of residence (excluding investment properties, secondary residences, and properties maintained for other purposes such as holiday properties or residences maintained for parents or children)',
    value: 'OwnerOccupiedLandTaxBodyCorpAndStrataFees',
  },
  {
    index: 8,
    label: 'Other regular & recurring expenses',
    hint: 'Any other regular or recurring expenses not covered by the specific categories above (e.g. overseas holidays, purchase and maintenance of recreation vehicles (caravan, boat etc), cleaning or gardening services, etc.)',
    value: 'OtherExpenses',
  },
  {
    index: 9,
    label: 'Pet care',
    hint: 'Pet food, grooming, minding services, care products, health products, veterinarian fees.',
    value: 'Petcare',
  },
  {
    index: 10,
    label: 'Primary residence costs',
    hint: 'Costs associated with the primary residence, either owned or rented. Includes rates, water, sewage, repairs and maintenance, electricity, gas, heating oil and wood for fuel, purchase, hire and repair of household and outdoor furnishings and equipment, furnishings, floor coverings, linen, tableware, gardening tools, power tools, electrical, lights, trees, shrubs, plants, pool chemicals, home insurance, contents insurance and appliance insurance. Excludes body corporate fees, strata fees and land tax.',
    value: 'PrimaryResidenceCosts',
  },
  {
    index: 11,
    label: 'Primary & secondary education (public)',
    hint: 'Public or government, primary and secondary school tuition, school and sports fees including compulsory kindergarten/reception/pre-primary/prep.',
    value: 'EducationPublic',
  },
  {
    index: 12,
    label: 'Private education',
    hint: 'Tuition fees, school fees and sports fees for private schooling, including independent schools (Catholic or non-Catholic) and private tuition including compulsory age kindergarten/reception/pre-primary/prep.',
    value: 'EducationPrivate',
  },
  {
    index: 13,
    label: 'Rent',
    hint: 'Ongoing Rent commitments that will continue to be paid after settlement',
    value: 'Rent',
  },
  {
    index: 14,
    label: 'Secondary residence & holiday home costs',
    hint: 'Costs associated with any secondary residence, either rented or owned for non-investment purposes, such as a holiday property that is not rented to generate income, or a property that family members (parents or children) are allowed to live in rent-free. Includes body corporate fees, strata fees, land tax, property management fees, rates, water, sewage, repairs and maintenance, electricity, gas, heating oil and wood for fuel, purchase, hire and repair of household and outdoor furnishings and equipment, furnishings, floor coverings, linen, tableware, gardening tools, power tools, electrical, lights, trees, shrubs, plants, pool chemicals, landlords insurance, home insurance, contents insurance and appliance insurance.',
    value: 'SecondaryResidenceCosts',
  },
  {
    index: 15,
    label: 'Telephone, internet, pay TV and media streaming subscriptions',
    hint: 'Telephone accounts (home and mobile), internet, pay TV and media streaming subscriptions (such as Netflix and Spotify).',
    value: 'TelephoneInternet',
  },
];
export const LEISURE_ENTERTAINMENT_EXPENSES = [
  {
    index: 16,
    label: 'Recreation & entertainment',
    hint: 'Meals in restaurants, hotels and clubs, fast food and takeaway including coffee. Alcoholic beverages and tobacco products. Purchase, hire, repair of recreational personal belongings including electronics, computers, games consoles, AV equipment, cameras. Toys, hobbies, stationery, arts, crafts, sports, camping, fishing and recreation equipment including musical instruments, accessories and lessons. Non-subscription TV, video, games, and audio content, books, newspapers, magazines (including physical material and digital content). Recreation, gym, and health and fitness studios fees, court hire, sports lessons, admission fees and tickets (sports, theatre, gallery, museum, music events). Domestic holidays (fares, fuel, accommodation, theme parks, zoos tours). Recreational gambling, including lottery-type games, scratch cards, poker machines, club and casino gaming, and sports betting. Excludes travel insurance.',
    value: 'Recreation',
  },
];
export const TRANSPORT_EXPENSES = [
  {
    index: 17,
    label: 'Transport',
    hint: 'Public transport, taxis, ride-sharing and non-holiday domestic airfares (excludes overseas airfares). Running costs for essential vehicles including fuel, servicing, repairs, parking, tolls (excludes insurance) and registration of motor vehicles (excludes recreational vehicles such as caravans, trailers, motorcycles, boats and aircraft).',
    value: 'Transport',
  },
];
export const INSURANCE_EXPENSES = [
  {
    index: 18,
    label: 'General insurance',
    hint: 'General basic insurances include ambulance, car (not recreation vehicles), travel, and personal belongings insurance. Excludes property (house and contents), health, sickness and personal accident, life, income protection and pet insurance.',
    value: 'InsuranceGeneral',
  },
  {
    index: 19,
    label: 'Personal insurance',
    hint: 'Personal Insurance (Life, Health, Sickness and Personal Accident) \n Hospital, medical and dental health insurance, sickness and personal accident insurance, life insurance',
    value: 'InsuranceMedical',
  },
  {
    index: 20,
    label: 'Other insurance',
    hint: 'Insurance of recreational vehicles such as motor cycle, caravan, trailer, boat and aircraft including combined insurance and registration',
    value: 'InsurancesOther',
  },
];

export const EXPENSE_SECTIONS = [
  {
    title: 'Living expenses',
    fields: LIVING_EXPENSES,
    isLivingExpense: true,
  },
  {
    title: 'Leisure / Entertainment',
    fields: LEISURE_ENTERTAINMENT_EXPENSES,
    isLivingExpense: false,
  },
  {
    title: 'Transport',
    fields: TRANSPORT_EXPENSES,
    isLivingExpense: false,
  },
  {
    title: 'Insurance',
    fields: INSURANCE_EXPENSES,
    isLivingExpense: false,
  },
];

export const DEFAULT_HOUSEHOLD_EXPENSE = {
  expenses: EXPENSE_SECTIONS.flatMap(section => section.fields.map((field) =>
    ({ type: field.value, periodUnit: MONTHLY.value }))),
};
