import { mapLogToProps } from './utils';

const expenseInfoChangeLogTransformer = origin => {
  const extraInfo = JSON.parse(origin.eventDetail.extraInfo) || {};
  const originChanges = origin.eventDetail.changes || [];
  const noteChange = originChanges.find(change => change.path.includes('.expenseNote'));
  const householdChange = originChanges.find(change => change.path.includes('.householdExpenses'));
  const householdExpenseId = new RegExp('householdExpenses\\[([\\w-]+)]').exec(householdChange?.path)?.[1];
  const householdInfo = extraInfo[householdExpenseId]?.join('; ') || '';
  const summary = noteChange ? 'Expenses comment #action' : `Expenses #action - ${householdInfo}`;
  return mapLogToProps({
    origin,
    prefix: 'finance.expenseInfo.householdExpenses',
    type: 'EXPENSES',
    summary,
    fieldMap: {
      dependents: ['Number of dependants in the household'],
    },
    handleChanges: changes => {
      const changeMap = {
        household: null,
        expense: null,
        dependents: null,
      };

      if (noteChange) {
        changeMap.comment = { path: '*.comment', value: `${noteChange?.newValue}` };
      }

      changes.forEach((current) => {
        const { path } = current;
        if (path.includes('.household.')) {
          if (path.includes('dependents')) {
            changeMap.dependents = current;
          } else {
            changeMap.household = { path: '*.household', value: `${householdInfo}` };
          }
        }

        if (path.includes('.expenses')) {
          changeMap.expenses = { path: '*.expenses', value: 'updated' };
        }
      }, {});

      return Object.values(changeMap);
    },
  });
};

export default expenseInfoChangeLogTransformer;
