import React from 'react';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import styles from './index.module.scss';
import PreferenceEditor from './components/PreferenceEditor';
import { OFFSET_REASON_OPTION, REDRAW_REASON_OPTION } from './constants';

const offsetProps = {
  importantDegree: {
    label: 'Offset',
  },
  reason: {
    options: OFFSET_REASON_OPTION,
  },
};

const redrawProps = {
  importantDegree: {
    label: 'Redraw',
  },
  reason: {
    options: REDRAW_REASON_OPTION,
  },
};

const ProductFeature = () => (
  <InformationSection title="Product features">
    <div className={styles.commonSection}>
      <span>Capture how important the following product features are to your client/s</span>
      <div className={styles.content}>
        <PreferenceEditor
          props={offsetProps}
          namePrefix="productFeature.offset"
        />
        <PreferenceEditor
          props={redrawProps}
          namePrefix="productFeature.redraw"
        />
      </div>
    </div>
  </InformationSection>
  );

export default ProductFeature;
