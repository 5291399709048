import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
                   color, width, height, alt,
                 }) => (
                   <svg
                     width={width}
                     height={height}
                     viewBox="0 0 43 43"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <title>{alt}</title>

                     <g
                       id="Icon/FlagCircle"
                       stroke="none"
                       strokeWidth="1"
                       fill="none"
                       fillRule="evenodd"
                     >
                       <circle
                         id="Oval"
                         stroke={color}
                         strokeWidth="1.8"
                         cx="20.5"
                         cy="20.5"
                         r="20.5"
                         transform="translate(1.000000, 1.000000)"
                       />
                       <path
                         id="Shape"
                         fill={color}
                         transform="translate(12.000000, 12.000000)"
                         d="M2,14 L2,19 L0,19 L0,-7.07287052e-07 L19.138,-4.03630258e-17 C19.3163706,-0.00029935694 19.4813928,0.0944463643 19.5710635,0.248638852 C19.6607343,0.40283134 19.6614722,0.593116695 19.573,0.748 L16,7 L19.573,13.252 C19.6614722,13.4068833 19.6607343,13.5971687 19.5710635,13.7513611 C19.4813928,13.9055536 19.3163706,14.0002994 19.138,14 L2,14 Z M2,2 L2,12 L16.554,12 L13.696,7 L16.554,2 L2,2 Z"
                       />
                     </g>
                   </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
