import { connect } from 'react-redux';
import get from 'lodash/get';
import TemplateListModal from './TemplateListModal';
import { createTask } from '../../../../redux';

export default connect(
  state => ({
    businessId: get(state, 'business.selectedBusiness.id'),
    applicationId: get(state, 'application.applicationDetail.id'),
  }),
  {
    addTasksFromTemplate: createTask,
  },
)(TemplateListModal);
