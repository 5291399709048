export const TYPES = {
  FINALISE: 'FINALISE',
  INSURANCE: 'INSURANCE',
  SURVEY: 'SURVEY',
  TASK_LIST_ADDED: 'TASK LIST ADDED',
};

export const LOG_TYPE = {
  CONTACT_CHANGE: 'CONTACT_CHANGE',
  ASSETS_UPDATED: 'ASSETS_UPDATED',
  ADDITIONAL_INCOME: 'ADDITIONAL_INCOME',
  STATUS_CHANGE: 'STATUS_CHANGE',
  ESSENTIAL_DATE_REASON_CHANGE: 'ESSENTIAL_DATE_REASON_CHANGE',
  ESSENTIAL_DATE_CHANGE: 'ESSENTIAL_DATE_CHANGE',
  SURVEY_SENT_SUCCESS: 'SURVEY_SENT_SUCCESS',
  ACKNOWLEDGE_UPDATE: 'ACKNOWLEDGE_UPDATE',
  APPLICATION_UPDATE_CHANGE: 'APPLICATION_UPDATE_CHANGE',
  APPLICATION_CHANGE_FROM_LOTUS_NOTES: 'APPLICATION_CHANGE_FROM_LOTUS_NOTES',
  NOTE: 'NOTE',
  NOTE_DELETE_AUDIT: 'NOTE_DELETE_AUDIT',
  TASK_CREATE: 'TASK_CREATE',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_DELETE: 'TASK_DELETE',
  TASK_STATUS_CHANGE: 'TASK_STATUS_CHANGE',
  TASK_LIST_CREATE: 'TASK_LIST_CREATE',
  TASK_LIST_UPDATE: 'TASK_LIST_UPDATE',
  TASK_LIST_DELETE: 'TASK_LIST_DELETE',
  SURVEY_CONTACT_CHANGE: 'SURVEY_CONTACT_CHANGE',
  TASK_LIST_CREATE_FROM_TEMPLATE: 'TASK_LIST_CREATE_FROM_TEMPLATE',
  ALLIANZ_INSURANCE_UPDATE_CREATE: 'ALLIANZ_INSURANCE_UPDATE_CREATE',
  APPLICATION_DETAIL_CHANGE: 'APPLICATION_DETAIL_CHANGE',
  APPLICATION_LOAN_REQUEST_CHANGE: 'APPLICATION_LOAN_REQUEST_CHANGE',
  ALLIANZ_INSURANCE_CHANGE_FROM_LOTUS_NOTES: 'ALLIANZ_INSURANCE_CHANGE_FROM_LOTUS_NOTES',
  ALLIANZ_INSURANCE_CHANGE: 'ALLIANZ_INSURANCE_CHANGE',
  LOAN_PROTECTION_INSURANCE_COMPLIANCE_CHANGE: 'LOAN_PROTECTION_INSURANCE_COMPLIANCE_CHANGE',
  EXPECTED_SETTLEMENT_DATE_CHANGE: 'EXPECTED_SETTLEMENT_DATE_CHANGE',
  FINANCE_DUE_DATE_CHANGE: 'FINANCE_DUE_DATE_CHANGE',
  PREAPPROVAL_EXPIRY_DATE_CHANGE: 'PREAPPROVAL_EXPIRY_DATE_CHANGE',
  SEND_TO_APPLY_ONLINE: 'SEND_TO_APPLY_ONLINE',
  APPLICATION_CREATED: 'APPLICATION_CREATED',
  APPLICATION_COPIED_FROM: 'APPLICATION_COPIED_FROM',
  APPLICATION_COPIED_TO: 'APPLICATION_COPIED_TO',
  PRE_SUBMISSION_APPLICATION_DETAIL_CHANGE: 'PRE_SUBMISSION_APPLICATION_DETAIL_CHANGE',
  FUNDING_WORKSHEET_CHANGE: 'FUNDING_WORKSHEET_CHANGE',
  PRODUCT_COMPARISON_CHANGE: 'PRODUCT_COMPARISON_CHANGE',
  LOAN_REQUESTS_GENERATED: 'LOAN_REQUESTS_GENERATED',
  NEW_SOMA_CREATED: 'NEW_SOMA_CREATED',
  SOMAS_CHANGE: 'SOMAS_CHANGE',
  SOMA_DOWNLOAD: 'SOMA_DOWNLOAD',
  ALI_INSURANCE_UPDATE_ACKNOWLEDGE: 'ALI_INSURANCE_UPDATE_ACKNOWLEDGE',
  EMPLOYMENTS_UPDATED: 'EMPLOYMENTS_UPDATED',
  EXPENSE_INFO_UPDATED: 'EXPENSE_INFO_UPDATED',
  APPLICANTS_UPDATED: 'APPLICANTS_UPDATED',
  LIABILITIES_UPDATED: 'LIABILITIES_UPDATED',
  APPLICANTS_INFO_SYNCHRONIZED: 'APPLICANTS_INFO_SYNCHRONIZED',
  THIRD_PARTIES: 'THIRD_PARTIES',
  NEED_AND_OBJECTIVE_UPDATED: 'NEED_AND_OBJECTIVE_UPDATED',
};
