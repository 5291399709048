import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { FETCHING_STATUS } from '../../../../constants';
import { updateFixedTrails } from './updateFixedTrailGraphQL';
import { buildFilter } from '../../../utils';
import { showToast } from '../../../../redux/toast/actions';

export const actions = {
  UPDATE_FIXED_TRAIL_START: 'UPDATE_FIXED_TRAIL_START',
  UPDATE_FIXED_TRAIL_SUCCESS: 'UPDATE_FIXED_TRAIL_SUCCESS',
  UPDATE_FIXED_TRAIL_ERROR: 'UPDATE_FIXED_TRAIL_ERROR',
};

export const handleUpdateFixedTrail = ({
                                         excludeFromSafetyNet,
                                         trailPercentage,
                                         trailStartDate,
                                         trailEndDate,
                                       }) => async (dispatch, getState) => {
  dispatch({ type: actions.UPDATE_FIXED_TRAIL_START });
  try {
    const businessId = get(getState(), 'business.selectedBusiness.id');
    const filters = get(getState(), 'loans.loans.remoteFilters');
    const totalCount = get(getState(), 'loans.loans.totalRowCount');

    const { data: { bulkUpdateFixedTrailDetails: { loansUpdated } } } = await updateFixedTrails(
      businessId, excludeFromSafetyNet, trailPercentage, trailStartDate, trailEndDate, buildFilter(filters), totalCount,
    );
    dispatch(showToast(`Your changes to ${loansUpdated} loan${loansUpdated > 1 ? 's' : ''} have been saved`, { type: 'success' }));
    dispatch({
      type: actions.UPDATE_FIXED_TRAIL_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: actions.UPDATE_FIXED_TRAIL_ERROR });
    throw error;
  }
};

const initialState = {
  updatingStatus: FETCHING_STATUS.INIT,
};

export default createReducer(
  initialState, {
    [actions.UPDATE_FIXED_TRAIL_START]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.START,
    }),
    [actions.UPDATE_FIXED_TRAIL_ERROR]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.ERROR,
    }),
    [actions.UPDATE_FIXED_TRAIL_SUCCESS]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.SUCCESS,
    }),
  },
);
