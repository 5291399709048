import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import some from 'lodash/some';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import RetryComponent from 'shared/components/RetryComponent';
import BackButton from 'shared/components/BackButton';
import NoAccess from 'shared/components/NoAccess/NoAccess';
import Formsy from 'formsy-react';
import {
  getLoan,
  updateLoan,
} from 'Loans/components/LoanDetails/redux/loanActions';
import EditButtons from './EditButtons';
import SplitButton from '../LoanSplit/SplitButton';
import LoanAbstract from './LoanAbstract';
import styles from './LoansSummaryDetails.module.scss';
import LoanDetailsContent from './LoanDetailsContent';
import { FETCHING_STATUS } from '../../../constants';
import { ComplianceModal } from './ComplianceModal';

const LoansSummaryDetails = ({
                              id,
                              loan,
                              dispatchGetLoan,
                              isForbidden,
                              fetchingStatus,
                              dispatchUpdateLoan,
                              isEditing,
                              isLoanUpdating,
                              hasUpdateErrors,
                            }) => {
  const [hasFormChanged, updateHasFormChanged] = useState(false);
  const [isFormValid, updateIsFormValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modifiedLoan, setModifiedLoan] = useState({});
  const [shouldResetModal, setShouldResetModal] = useState();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatchGetLoan(id);
  }, [id, dispatchGetLoan]);

  useEffect(() => {
    if (isEditing === false) {
      updateHasFormChanged(false);
      setShouldResetModal(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (!hasUpdateErrors) closeModal();
  }, [isEditing, hasUpdateErrors]);

  const renderContent = () => {
    switch (fetchingStatus) {
      case FETCHING_STATUS.START:
        return <LoadingSpinner />;
      case FETCHING_STATUS.SUCCESS:
        return (
          <>
            <div className={styles.headerContent}>
              <h1>Loan details</h1>
              <div className={styles.buttonGroups}>
                {loan.isActive && <SplitButton />}
                <EditButtons
                  disabled={!hasFormChanged || !isFormValid}
                  isEditing={isEditing}
                  isLoading={isLoanUpdating}
                />
                <ComplianceModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  onSave={isComplianceFormCompleted => dispatchUpdateLoan(modifiedLoan, isComplianceFormCompleted)}
                  isLoading={isLoanUpdating}
                  shouldReset={shouldResetModal}
                />
              </div>
            </div>
            <LoanAbstract />
            <LoanDetailsContent loan={loan} isLoading={isLoanUpdating} />
          </>
        );
      case FETCHING_STATUS.ERROR:
        return isForbidden ? <NoAccess /> : <RetryComponent />;
      default:
        return false;
    }
  };

  const submitForm = (submittedLoan) => {
    setModifiedLoan(submittedLoan);
    setIsModalOpen(true);
    setShouldResetModal(false);
  };

  return (
    <Formsy
      className={styles.form}
      onSubmit={submitForm}
      disabled={isLoanUpdating}
      onChange={(currentValues, changeFromInitialValues) => {
        updateHasFormChanged(changeFromInitialValues);
      }}
      onValid={() => updateIsFormValid(true)}
      onInvalid={() => updateIsFormValid(false)}
    >
      <BackButton text="Back" to="/loans" />
      {renderContent()}
    </Formsy>
  );
};

const FORBIDDEN = 403;

const getIsForbidden = errors =>
  some(errors, error => error.errorCode === FORBIDDEN);

LoansSummaryDetails.defaultProps = {
  loan: null,
  isEditing: false,
  isLoanUpdating: false,
  hasUpdateErrors: false,
};

LoansSummaryDetails.propTypes = {
  loan: PropTypes.shape({ isActive: PropTypes.bool.isRequired }),
  isEditing: PropTypes.bool,
  isLoanUpdating: PropTypes.bool,
  dispatchGetLoan: PropTypes.func.isRequired,
  dispatchUpdateLoan: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isForbidden: PropTypes.bool.isRequired,
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  hasUpdateErrors: PropTypes.bool,
};

const mapStateToProps = ({
                           loans: {
                             loan: {
                               data,
                               errors,
                               hasUpdateErrors,
                               fetchingStatus,
                               isEditing,
                               isLoanUpdating,
                             },
                           },
                         }) => ({
  loan: data,
  isEditing,
  isForbidden: getIsForbidden(errors),
  fetchingStatus,
  isLoanUpdating,
  hasUpdateErrors,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetLoan: id => dispatch(getLoan(id)),
  dispatchUpdateLoan: (loan, isComplianceFormCompleted) => dispatch(updateLoan(loan, isComplianceFormCompleted)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoansSummaryDetails);
