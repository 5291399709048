import React from 'react';
import CalculationTrigger from './CalculationTrigger';
import CalculationUpdater from './CalculationUpdater';

const EditCalculation = () => (
  <>
    <CalculationTrigger />
    <CalculationUpdater />
  </>
);

export default EditCalculation;
