import get from 'lodash/get';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';

const initialState = [];

const applicationUpdates = createReducer(initialState, {
  [types.ACKNOWLEDGE_UPDATE_START]: (state) => state,
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, { data }) =>
    (get(data, 'applicationUpdates', initialState)),
  [types.ACKNOWLEDGE_UPDATE_SUCCESS]: (state) => state,
  [types.ACKNOWLEDGE_UPDATE_ERROR]: (state) => state,
  [types.UPDATE_APPLICATION_UPDATES]: (state, { application }) => (application.applicationUpdates),
  [types.UPDATE_APPLICATION_STATUS_SUCCESS]: (state, action) => (action.applicationUpdates),
  [types.UPDATE_APPLICATION_GENERAL_INSURANCE_SUCCESS]: (state, action) => ([
    ...state.filter(item => action.data.insuranceNotificationId !== item.id),
  ]),
});
export default applicationUpdates;
