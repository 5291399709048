import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { getApplicants } from '../applicants';

export const getGuarantors = (state) => get(state, 'application.applicationDetail.guarantors.persons');
export const getNeedAndObjective = (state) => get(state, 'application.applicationDetail.needAndObjective');
export const getExistedApplicantFutureCircumstances = (state) => get(state, 'application.applicationDetail.needAndObjective.futureCircumstances');
export const getExistedGuarantorFutureCircumstances = (state) => get(state, 'application.applicationDetail.needAndObjective.guarantorFutureCircumstances');

const toFutureCircumstances = (applicants, existedFutureCircumstances) => applicants?.map(applicant => {
  const item = find(existedFutureCircumstances, value => value.applicantId === applicant.id);
  return ({
    applicantId: applicant.id,
    applicantName: applicant.name,
    adverseChanges: {
      anyChange: item?.adverseChanges?.anyChange?.toString() ?? null,
      significantChanges: item?.adverseChanges?.significantChanges?.map(it => ({
        ...it,
        repaymentPlans: it.repaymentPlans ?? [],
      })),
    },
    retirementPlan: {
      ...item?.retirementPlan,
      retireDuringLoanTerm: item?.retirementPlan?.retireDuringLoanTerm?.toString() ?? null,
      repaymentMethods: item?.retirementPlan?.repaymentMethods ?? [],
      matureAge: item?.retirementPlan?.matureAge ?? null,
    },
  });
});

export const getNeedAndObjectiveInitialValues = createSelector(
  getNeedAndObjective,
  getApplicants,
  getGuarantors,
  getExistedApplicantFutureCircumstances,
  getExistedGuarantorFutureCircumstances,
  (
    needAndObjective,
    applicants,
    guarantors,
    existedApplicantFutureCircumstances,
    existedGuarantorFutureCircumstances,
  ) => ({
   ...needAndObjective,
    primary: {
      seekCreditReason: get(needAndObjective, 'primary.seekCreditReason', null),
      immediateNeedsAndObjectives: get(needAndObjective, 'primary.immediateNeedsAndObjectives', null),
      longerTermGoal: get(needAndObjective, 'primary.longerTermGoal', null),
      preferredLender: get(needAndObjective, 'primary.preferredLender', null),
      guarantorGoalsAndObjective: get(needAndObjective, 'primary.guarantorGoalsAndObjective', null),
    },
    futureCircumstances: toFutureCircumstances(applicants, existedApplicantFutureCircumstances),
    guarantorFutureCircumstances: toFutureCircumstances(guarantors, existedGuarantorFutureCircumstances),
  }),
);
