/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/Modal';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import styles from './ValidationModal.module.scss';
import Node from './Node';

const ValidationModal = props => {
  const {
    isOpen,
    onRequestClose,
    description,
    messages,
  } = props;

  return (
    <Modal
      className={styles.validationModal}
      isOpen={isOpen}
      header={<span className={styles.header}>Missing information</span>}
      onRequestClose={onRequestClose}
      footer={(
        <PrimaryButton onClick={onRequestClose}>
          OK
        </PrimaryButton>
        )}
    >
      <div>
        <div className={styles.description}>
          {description}
        </div>
        <div>
          {messages.map(({ name, nodes }, index) => (
            <div className={styles.section} key={index}>
              <Node name={name} nodes={nodes} />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

ValidationModal.defaultProps = {
  onRequestClose: $ => $,
  description: '',
};

ValidationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  description: PropTypes.string,
  messages: PropTypes.array.isRequired,
};

export default ValidationModal;
