export const ACTIONS = {
  UPDATE_OPTIONS_FROM_COMPARISON: 'COMPARISON:STAND_ALONE:UPDATE_OPTIONS_FROM_COMPARISON',
  CLEAR_COMPARISON: 'COMPARISON:STAND_ALONE:CLEAR_COMPARISON',
  GET_COMPARISON_DETAILS_LOADING: 'COMPARISON:STAND_ALONE:GET_COMPARISON_DETAILS_LOADING',
  SET_DEFAULT_MODIFICATION_ORIGINALS: 'COMPARISON:STAND_ALONE:SET_DEFAULT_MODIFICATION_ORIGINALS',
  GET_COMPARISON_DETAILS_SUCCESS: 'COMPARISON:STAND_ALONE:GET_COMPARISON_DETAILS_SUCCESS',
  GET_COMPARISON_DETAILS_ERROR: 'COMPARISON:STAND_ALONE:GET_COMPARISON_DETAILS_ERROR',
  DOWNLOAD_COMPARISONS_LOADING: 'COMPARISON:STAND_ALONE:DOWNLOAD_COMPARISONS_LOADING',
  DOWNLOAD_COMPARISONS_SUCCESS: 'COMPARISON:STAND_ALONE:DOWNLOAD_COMPARISONS_SUCCESS',
  DOWNLOAD_COMPARISONS_ERROR: 'COMPARISON:STAND_ALONE:DOWNLOAD_COMPARISONS_ERROR',
  TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT: 'COMPARISON:STAND_ALONE:TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT',
  RESET_SEARCH_CRITERIA: 'COMPARISON:STAND_ALONE:RESET_SEARCH_CRITERIA',
  UPDATE_SELECTED_VARIANT: 'COMPARISON:STAND_ALONE:UPDATE_SELECTED_VARIANT',
  TOGGLE_SELECTED_FEATURE_FROM_COMPARISON: 'COMPARISON:STAND_ALONE:TOGGLE_SELECTED_FEATURE_FROM_COMPARISON',
  UPDATE_SELECTED_FEATURES_FROM_COMPARISON: 'COMPARISON:STAND_ALONE:UPDATE_SELECTED_FEATURES_FROM_COMPARISON',
  TOGGLE_FEATURE_MODAL_VIEW: 'COMPARISON:STAND_ALONE:TOGGLE_FEATURE_MODAL_VIEW',
};
