import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../formFields/Checkbox';
import styles from './Feature.module.scss';

const isInList = (featureNames, name) => featureNames.indexOf(name) > -1;

const GroupSection = (props) => {
  const {
    groupName, featureList, selectedFeatureNames, fixedFeatureNames, onToggleCheckbox,
  } = props;
  return (
    <div className={styles.groupSection}>
      <div className={styles.sectionTitle}>{groupName}</div>
      <div className={styles.checkboxItems}>
        {featureList.sort().map(name => (
          <Checkbox
            key={name}
            labelName={name}
            checked={isInList(selectedFeatureNames, name) || isInList(fixedFeatureNames, name)}
            disabled={isInList(fixedFeatureNames, name)}
            onChange={() => onToggleCheckbox(name)}
          />
        ))}
      </div>
    </div>
  );
};

GroupSection.propTypes = {
  groupName: PropTypes.string.isRequired,
  featureList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixedFeatureNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleCheckbox: PropTypes.func.isRequired,
};

export default GroupSection;
