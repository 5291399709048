export const calculateVariantUpfrontFee = ({ applicationFee, legalFee, valuationFee }) =>
  (applicationFee || 0) + (legalFee || 0) + (valuationFee || 0);

export const calculateTotalRepayment = variants => (
  variants.reduce(
    (sum, variant) => (
      sum == null || variant.firstPeriodRepayment == null
        ? null
        : (sum + Math.ceil(variant.firstPeriodRepayment))
    ),
    0,
  )
);

export const calculateTotalCost = variants => (
  variants.reduce(
    (sum, variant) => (
      sum == null || variant.totalCost == null
        ? null
        : (sum + Math.ceil(variant.totalCost))
    ),
    0,
  )
);
