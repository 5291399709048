import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ApplicationAbstract from './ApplicationAbstract';
import { updateApplicationAbstract as updateAbstract } from '../redux';
import ApplicationTimeline from './ApplicationTimeline';

const ApplicationDetailOverview = ({
  applicationDetail,
  applicationStatus,
  updateApplicationAbstract,
  hasEditPermission,
}) => (
  <>
    <ApplicationAbstract
      applicationDetail={applicationDetail}
      applicationStatus={applicationStatus}
      updateApplicationAbstract={updateApplicationAbstract}
      hasEditPermission={hasEditPermission}
    />
    <ApplicationTimeline applicationStatus={applicationStatus} />
  </>
);

ApplicationDetailOverview.propTypes = {
  applicationStatus: PropTypes.object.isRequired,
  applicationDetail: PropTypes.object.isRequired,
  updateApplicationAbstract: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  applicationStatus: get(state, 'application.applicationStatus'),
  applicationDetail: get(state, 'application.applicationDetail'),
  // todo: remove it
  applicationStage: get(state, 'application.applicationStage'),
});

export default connect(mapStateToProps, {
  updateApplicationAbstract: updateAbstract,
})(ApplicationDetailOverview);
