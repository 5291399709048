import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt, style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <title>{alt}</title>

    <g
      id="Icon/information"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M11,11 L11,17 L13,17 L13,11 L11,11 Z M11,7 L11,9 L13,9 L13,7 L11,7 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
