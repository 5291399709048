import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const ArrowRight = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/arrowRight"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path"
        fill={color}
        transform="translate(12.111000, 12.000000) rotate(-90.000000) translate(-12.111000, -12.000000) "
        points="12.111 13.061 17.061 8.111 18.475 9.525 12.111 15.889 5.747 9.525 7.161 8.111"
      />
    </g>
  </svg>
);

ArrowRight.defaultProps = iconDefaultProps;
ArrowRight.propTypes = baseIcon;

export default ArrowRight;
