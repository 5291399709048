import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import styles from './commissionQuery.module.scss';

const CommissionQueryModalFooter = ({
   preventSubmission, dispatchSendToZendesk, isSending,
    }) => (
      <div className={styles.commissionQueryFooter}>
        <PrimaryButton
          loading={isSending}
          className={styles.sendButton}
          disabled={preventSubmission || isSending}
          onClick={dispatchSendToZendesk}
        >
          {isSending ? 'Sending...' : 'Send to Zendesk'}
        </PrimaryButton>
      </div>
  );

CommissionQueryModalFooter.defaultProps = {
  preventSubmission: false,
  isSending: false,
};

CommissionQueryModalFooter.propTypes = {
  preventSubmission: PropTypes.bool,
  isSending: PropTypes.bool,
  dispatchSendToZendesk: PropTypes.func.isRequired,
};

export default CommissionQueryModalFooter;
