import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import styles from '../RealEstateAsset.module.scss';
import { isConstruction } from '../constants';

const ValueField = (fields) => {
  const { names, valueLabel, showConstructionSwitch } = fields;
  const residencyType = get(fields, `${names[0]}.input.value`);
  const isForConstructionLoan = get(fields, `${names[3]}.input.value`);
  const fieldName = (showConstructionSwitch && isConstruction(residencyType) && isForConstructionLoan)
    ? names[2]
    : names[1];
  const getLabel = () => {
    if (isConstruction(residencyType)) {
      return showConstructionSwitch && isForConstructionLoan ? 'Current value' : 'Value (TOC)';
    }
    return valueLabel;
  };
  return (
    <ReduxFormNumberInput
      {...get(fields, fieldName)}
      label={getLabel()}
      name={fieldName}
      placeholder="$"
      decimalScale={2}
      prefix="$"
      fullWidth
      allowNegative={false}
      className={classNames(styles.field, styles.valueField)}
      textClassName={styles.textClassName}
    />
  );
};

ValueField.defaultProps = {
  showConstructionSwitch: false,
};

ValueField.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  valueLabel: PropTypes.string.isRequired,
  showConstructionSwitch: PropTypes.bool,
};

export default ValueField;
