import { createAction } from '@reduxjs/toolkit';
import types from '../constants';
import graphQL from '../applicationDetailsGraphQL';
import { getRequestIdFromAction, ifValidRequestId, startRequest } from '../../../../../redux/requestIds';

const calculateFundingPositionsStart = createAction(types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START);
const calculateFundingPositionsSuccess = createAction(types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_SUCCESS);
const calculateFundingPositionsError = createAction(types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_ERROR);

const calculateFundingFeesStart = createAction(types.FUNDING_WORKSHEET_FEES_CALCULATION_START);
const calculateFundingFeesSuccess = createAction(types.FUNDING_WORKSHEET_FEES_CALCULATION_SUCCESS);
const calculateFundingFeesError = createAction(types.FUNDING_WORKSHEET_FEES_CALCULATION_ERROR);

export const calculateFundingPositions = (applicationId, fundingWorksheet) => async (dispatch, getState) => {
  dispatch(calculateFundingPositionsStart());
  const startRequestAction = startRequest(types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const { data: { fundingPositionsCalculation } } = await graphQL.calculateFundingPositions(
      applicationId,
      fundingWorksheet,
    );
    ifValidRequestId(
      getState().requestIds,
      types.FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START,
      requestId,
      () => dispatch(calculateFundingPositionsSuccess({ ...fundingPositionsCalculation })),
    );
  } catch (e) {
    dispatch(calculateFundingPositionsError());
  }
};

export const calculateFees = (applicationId, fundingWorksheet) => async (dispatch, getState) => {
  dispatch(calculateFundingFeesStart());
  const startRequestAction = startRequest(types.FUNDING_WORKSHEET_FEES_CALCULATION_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const { data: { fundingWorksheetFeesCalculation } } = await graphQL.calculateFundingWorksheetFees(
      applicationId,
      fundingWorksheet,
    );
    ifValidRequestId(
      getState().requestIds,
      types.FUNDING_WORKSHEET_FEES_CALCULATION_START,
      requestId,
      () => dispatch(calculateFundingFeesSuccess({ ...fundingWorksheetFeesCalculation })),
    );
  } catch (e) {
    dispatch(calculateFundingFeesError());
  }
};
