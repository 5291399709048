import withReduxForm from '../ReduxFormFieldWrapper';
import WithReadOnlyAutocomplete from '../../Autocomplete/WithReadOnlyAutocomplete';

const ReduxFormAutocomplete = withReduxForm((props) => {
  const {
    onChange,
    customOnChange,
  } = props;

  const handleSelect = (v) => {
    if (customOnChange) {
      customOnChange(v);
    }
    onChange(v);
  };

  return ({
    onSelect: handleSelect,
  });
})(WithReadOnlyAutocomplete);

export default ReduxFormAutocomplete;
