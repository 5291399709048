import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import FeatureSelect from './FeatureSelect';
import FeatureList from './FeatureList';
import SecondaryButton from '../../../Buttons/SecondaryButton';
import styles from './Feature.module.scss';

const FeatureModal = React.memo(({
  isOpen, hasError, features, selectedFeatureNames, fixedFeatureNames, onRequestClose,
  dispatchUpdateSelectedFeaturesNames, dispatchToggleSelectFeature,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Product features"
    className={styles.allFeaturesModal}
    overlayClassName={styles.allFeaturesOverlay}
    ariaHideApp={false}
  >
    <div className={styles.featureModalHeader}>
      <button
        type="button"
        onClick={onRequestClose}
        className={styles.closeButton}
      />
      <FeatureSelect
        onChange={dispatchUpdateSelectedFeaturesNames}
        hasError={hasError}
        features={features}
        selectedFeatureNames={selectedFeatureNames}
        fixedFeatureNames={fixedFeatureNames}
      />
    </div>
    <div className={styles.featureModalBody}>
      <FeatureList
        features={features}
        selectedFeatureNames={selectedFeatureNames}
        fixedFeatureNames={fixedFeatureNames}
        onToggleCheckbox={dispatchToggleSelectFeature}
      />
    </div>
    <div className={styles.featureModalFooter}>
      <SecondaryButton onClick={onRequestClose}>
        Done
      </SecondaryButton>
    </div>
  </Modal>
));

FeatureModal.defaultProps = {
  features: [],
  selectedFeatureNames: [],
  fixedFeatureNames: [],
  isOpen: false,
  onRequestClose: () => {
  },
};

FeatureModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  dispatchUpdateSelectedFeaturesNames: PropTypes.func.isRequired,
  dispatchToggleSelectFeature: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
  })),
  selectedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  fixedFeatureNames: PropTypes.arrayOf(PropTypes.string),
  hasError: PropTypes.bool.isRequired,
};
export default FeatureModal;
