import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'shared/components/formFields/TextInput';
import Checkbox from 'shared/components/formFields/Checkbox';
import styles from './ConfirmSettlementPopup.module.scss';

const LoanRequestDetails = ({
                              loanRequest, errorMessage, alias, onLoanRequestChange,
}) => (
  <div className={styles.loanCard}>
    <span>{loanRequest.loanRequestName}</span>
    <TextInput
      className={styles.accountNumberInput}
      onChange={value => onLoanRequestChange({ accountNumber: { value } })}
      errorMessage={errorMessage}
      value={loanRequest.accountNumber.value}
      label={`Account number${alias ? ` (${alias})` : ''}`}
      fullWidth
    />
    <Checkbox
      className={styles.checkbox}
      labelName="Upfront expected"
      defaultChecked={loanRequest.isUpfrontExpected}
      onChange={({ checked }) => onLoanRequestChange({ isUpfrontExpected: checked })}
    />
    <Checkbox
      className={styles.checkbox}
      labelName="Trail expected"
      defaultChecked={loanRequest.isTrailExpected}
      onChange={({ checked }) => onLoanRequestChange({ isTrailExpected: checked })}
    />
  </div>
);

LoanRequestDetails.defaultProps = {
  alias: null,
  errorMessage: null,
};

LoanRequestDetails.propTypes = {
  loanRequest: PropTypes.shape({
    loanRequestName: PropTypes.string.isRequired,
    isUpfrontExpected: PropTypes.bool,
    isTrailExpected: PropTypes.bool,
    accountNumber: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  errorMessage: PropTypes.string,
  alias: PropTypes.string,
  onLoanRequestChange: PropTypes.func.isRequired,
};

export default LoanRequestDetails;
