import React from 'react';
import { Report } from '../Report';
import { Tooltip } from './Tooltip';
import { perspectivesForSettlement } from '../viewPerspectives';

export const SettlementReport = props => (
  <Report
    title="Applications Settled"
    toolTipComponent={Tooltip}
    perspectives={perspectivesForSettlement}
    {...props}
  />
);
