import get from 'lodash/get';
import pullAll from 'lodash/pullAll';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import isNull from 'lodash/isNull';
import { mapLogToProps } from './utils';
import { toBasicCurrency } from '../../../../../../../utils/formatters';
import { clearingStatuses } from '../../../Liabilities/constants';

const LIABILITY_TYPE_DISPLAY_NAME_MAP = {
  ATOOrCentrelinkDebt: 'ATO or centrelink debt',
  ChargeCard: 'Charge card',
  CommercialBill: 'Commercial bill',
  ContingentLiability: 'Contingent liability',
  CourtRuledChildMaint: 'Court ruled child maint',
  CourtRuledOtherDebt: 'Court ruled other debt',
  CreditCard: 'Credit card',
  HECS: 'HECS',
  HirePurchase: 'Hire purchase',
  Lease: 'Lease',
  LineOfCredit: 'Line of credit',
  LoanAsGuarantor: 'Loan as guarantor',
  Maintenance: 'Maintenance',
  MortgageLoan: 'Mortgage loan',
  Other: 'Other',
  OtherLoan: 'Other loan',
  OutstandingTaxation: 'Outstanding taxation',
  Overdraft: 'Overdraft',
  PersonalLoan: 'Personal loan',
  StoreCard: 'Store card',
  TermLoan: 'Term loan',
};

const REPAYMENT_TYPES_MAP = {
  InterestOnly: 'Interest only',
  PrincipalAndInterest: 'Principal and interest',
};

const INTEREST_TYPES_MAP = {
  Fixed: 'Fixed',
  Variable: 'Variable',
  Equity: 'Line of credit',
};

const CLEARING_STATUSES_MAP = {
  [clearingStatuses.No]: 'No',
  [clearingStatuses.Full]: 'Full pay out',
  [clearingStatuses.Partial]: 'Partial pay out',
  [clearingStatuses.TopUp]: 'Top up',
};

const formatDateChange = (number, suffix) => (number === 1 ? `${number} ${suffix}` : `${number} ${suffix}s`);

const formatRemainingTerm = months => {
  const monthNumber = months % 12;
  const yearNumber = (months - monthNumber) / 12;
  return `${formatDateChange(yearNumber, 'year')} ${formatDateChange(monthNumber, 'month')}`;
};

const liabilitiesUpdateLogTransformer = (origin) => {
  const id = get(origin, 'eventDetail.changes[0].path', '').replace(/^.+liabilities\[([a-zA-Z0-9,-]+)].+$/, '$1');
  const extraInfoMap = JSON.parse(get(origin, 'eventDetail.extraInfo', {}));
  const liabilityType = get(extraInfoMap, id);
  const mainSummarySuffix = get(LIABILITY_TYPE_DISPLAY_NAME_MAP, liabilityType);

  return mapLogToProps({
    origin,
    prefix: 'finance.liabilities',
    type: 'LIABILITIES',
    summary: ['Liability #action', mainSummarySuffix].filter(Boolean).join(' - '),
    fieldMap: {
      type: ['Liability type', type => LIABILITY_TYPE_DISPLAY_NAME_MAP[type]],
      lender: ['Lender'],
      paymentType: ['Repayment type', type => REPAYMENT_TYPES_MAP[type]],
      originalTermYears: ['Original term', number => number && formatDateChange(number, 'year')],
      remainingTermMonths: ['Remaining term', number => number && formatRemainingTerm(number)],
      taxDeductible: ['Tax deductible', value => (value === 'true' ? 'Yes' : 'No')],
      accountDetails: ['Account details'],
      limit: ['Loan limit', value => value && toBasicCurrency(value)],
      currentBalance: ['Outstanding balance', value => value && toBasicCurrency(value)],
      clearingStatus: ['Pay out/top up', value => value && CLEARING_STATUSES_MAP[value]],
      clearingAmount: ['Clearing amount', value => value && toBasicCurrency(value)],
      interestType: ['Interest type', type => INTEREST_TYPES_MAP[type]],
      interestRatePercent: ['Interest rate', value => value && `${value}%`],
      repaymentsValue: ['Repayments value', value => value && toBasicCurrency(value)],
      repaymentsFrequency: ['Repayments frequency'],
      description: ['Description'],
      existingLoan: ['Existing Smartline Loan'],
    },
    handleChanges: changes => {
      const lenderChange = changes.find(change => change.path.includes('accountDetails.lender'));
      const securedByChange = changes.filter(change => change.path.includes('securedBy'));
      const ownershipChange = changes.filter(change => change.path.includes('ownership'));
      const repaymentsValueChange = changes.find(change => change.path.includes('repayments.value'));
      const repaymentsFrequencyChange = changes.find(change => change.path.includes('repayments.periodUnit'));
      const existingLoanChanges = changes.filter(change => change.path.includes('existingLoan'));
      const existingLoanMessage = every(existingLoanChanges, (change) => (isNull(change.value)))
        ? 'removed'
        : 'updated';
      const rebuildChanges = [
        ...(pullAll(changes, [lenderChange, repaymentsValueChange, repaymentsFrequencyChange,
          securedByChange, ...existingLoanChanges])),
        lenderChange && { path: '*.lender', value: lenderChange.value },
        repaymentsValueChange && { path: '*.repaymentsValue', value: repaymentsValueChange.value },
        repaymentsFrequencyChange && { path: '*.repaymentsFrequency', value: repaymentsFrequencyChange.value },
        !isEmpty(ownershipChange) && { path: '*.ownership', value: 'updated' },
        !isEmpty(securedByChange) && { path: '*.securedBy', value: 'updated' },
        !isEmpty(existingLoanChanges) && { path: '*.existingLoan', value: existingLoanMessage },
      ].filter(Boolean);
      const others = rebuildChanges.filter(
        change => !(
          change.path.includes('accountDetails.bsb')
          || change.path.includes('accountDetails.accountNumber')
          || change.path.includes('accountDetails.accountName')
          || change.path.match(/ownerships\[\d+\]\.applicantId$/)
          || change.path.match(/ownerships\[\d+\]\.percentage$/)
          || change.path.includes('relatedAssetId')
        ),
      );
      return !rebuildChanges.find(change => change.path.includes('accountDetails'))
        ? others
        : [...others, { path: '*.accountDetails', value: 'updated' }];
    },
  });
};

export default liabilitiesUpdateLogTransformer;
