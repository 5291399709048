import { createReducer } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { ACTIONS as BUSINESS_ACTIONS } from 'redux/business';
import graphQL from '../categoriesGraphQL';
import { FETCHING_STATUS } from '../../constants';

export const actions = {
  FETCH_CATEGORIES_START: 'LOANS_FETCH_CATEGORIES_START',
  FETCH_CATEGORIES_SUCCESS: 'LOANS_FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_ERROR: 'LOANS_FETCH_CATEGORIES_ERROR',
  UPDATE_CATEGORIES_START: 'LOANS_UPDATE_CATEGORIES_START',
  UPDATE_CATEGORIES_SUCCESS: 'LOANS_UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_ERROR: 'LOANS_UPDATE_CATEGORIES_ERROR',
};

export const fetchCategories = businessId => async (dispatch) => {
  dispatch({ type: actions.FETCH_CATEGORIES_START, businessId });
  try {
    const { data: { loanCategories } } = await graphQL.getCategories(businessId);
    dispatch({
      type: actions.FETCH_CATEGORIES_SUCCESS,
      categories: sortBy(loanCategories, 'createTimestamp'),
      businessId,
    });
  } catch {
    dispatch({ type: actions.FETCH_CATEGORIES_ERROR, businessId });
  }
};

export const updateCategories = ({
                                   businessId,
                                   categoriesToUpdate,
                                   categoriesToCreate,
                                   categoriesToDelete,
                                 }) => async (dispatch) => {
    dispatch({ type: actions.UPDATE_CATEGORIES_START });
    try {
      const { data: { updateLoanCategories } } = await graphQL.updateCategories({
        businessId,
        categoriesToUpdate,
        categoriesToCreate,
        categoriesToDelete,
      });
      dispatch({
        type: actions.UPDATE_CATEGORIES_SUCCESS,
        updatedCategories: sortBy(updateLoanCategories, 'createTimestamp'),
        businessId,
      });
    } catch (error) {
      dispatch({
        type: actions.UPDATE_CATEGORIES_ERROR,
        businessId,
      });
      throw error;
    }
};

const initialState = {
  businessId: null,
  fetchingStatus: FETCHING_STATUS.INIT,
  updatingStatus: FETCHING_STATUS.INIT,
  categories: null,
};

export default createReducer(
  initialState,
  {
    [actions.FETCH_CATEGORIES_START]: (state, { businessId }) => ({
      ...state,
      fetchingStatus: FETCHING_STATUS.START,
      businessId,
    }),
    [actions.FETCH_CATEGORIES_SUCCESS]: (state, { categories, businessId }) => {
      if (businessId !== state.businessId) {
        return state;
      }
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.SUCCESS,
        categories,
        businessId,
      };
    },
    [actions.FETCH_CATEGORIES_ERROR]: (state, { businessId }) => {
      if (businessId !== state.businessId) {
        return state;
      }
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.ERROR,
        businessId,
      };
    },
    [actions.UPDATE_CATEGORIES_START]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.START,
    }),
    [actions.UPDATE_CATEGORIES_SUCCESS]: (state, { updatedCategories, businessId }) => {
      if (businessId !== state.businessId) {
        return state;
      }
      return {
        ...state,
        updatingStatus: FETCHING_STATUS.SUCCESS,
        categories: updatedCategories,
        businessId,
      };
    },
    [actions.UPDATE_CATEGORIES_ERROR]: state => ({
      ...state,
      updatingStatus: FETCHING_STATUS.ERROR,
    }),
    [BUSINESS_ACTIONS.SELECT_BUSINESS]: (state, { business }) => {
      if (state.businessId !== business.id) {
        return initialState;
      }
      return state;
    },
  },
);
