import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './formFields.module.scss';

const InputLabelWrapper = ({
  label, errorMessage, className, children, additionalButton, style,
}) => (
  <div className={classNames(className, styles.content)} style={style}>
    {label && (
    <span className={styles.label}>
      {label}
      {additionalButton}
    </span>
)}
    {children}
    {errorMessage && (
      <div className={styles.error}>
        {errorMessage}
      </div>
    )}
  </div>
);

InputLabelWrapper.defaultProps = {
  label: undefined,
  errorMessage: undefined,
  className: undefined,
  additionalButton: undefined,
  style: {},
};

InputLabelWrapper.propTypes = {
  label: PropTypes.node,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
  additionalButton: PropTypes.object,
  style: PropTypes.object,
};

export default InputLabelWrapper;
