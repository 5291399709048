import {
  dateFormatter,
  formatBool,
  toRatePercentage,
} from 'shared/formatterUtils';
import { InputPercentage } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputPercentage';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import FormsyDatePicker from 'shared/components/formFields/Formsy/FormsyDatePicker';
import { InputYesNo } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputYesNo';
import React from 'react';
import {
  EditField,
  USER_PERMISSIONS,
} from 'Loans/components/LoanDetails/EditField';

const formatDate = date => (date ? dateFormatter.toDdmmyy(date) : '');

export const getFixedTrailFields = (
  {
    fixedTrailPercentage,
    fixedTrailStartDate,
    fixedTrailEndDate,
    excludeFromSafetyNet,
  },
) => [
  {
    title: 'Percentage',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={toRatePercentage(fixedTrailPercentage, '')}
      >
        <InputPercentage
          name="fixedTrailPercentage"
          value={fixedTrailPercentage}
        />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
  {
    title: 'Start date',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={formatDate(fixedTrailStartDate)}
      >
        <FormsyDatePicker type="date" name="fixedTrailStartDate" value={fixedTrailStartDate} fullWidth />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
  {
    title: 'End date',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={formatDate(fixedTrailEndDate)}
      >
        <FormsyDatePicker type="date" name="fixedTrailEndDate" value={fixedTrailEndDate} fullWidth />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
  {
    title: 'Exclude from safety net?',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.GROUP_OFFICE}
        fallback={formatBool(excludeFromSafetyNet)}
      >
        <InputYesNo value={excludeFromSafetyNet} name="excludeFromSafetyNet" />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
];
