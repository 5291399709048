import PropTypes from 'prop-types';

export const iconDefaultProps = {
  width: '24px',
  height: '24px',
  color: '#000',
  alt: '',
};

export const baseIcon = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  alt: PropTypes.string,
};
