import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { hasEditApplicationPermission, hasAdditionalEditApplicationPermission } from 'ApplicationTracking/utils';

const getSelectedBusinessId = state => get(state, 'business.selectedBusiness.id');
const getPermissions = state => get(state, 'profile.permissions', []);
const getIsSettled = state => get(state, 'application.applicationStatus.isApplicationSettled');

export const hasEditPermissionSelector = createSelector(
  getSelectedBusinessId,
  getPermissions,
  getIsSettled,
  (businessId, permissions, isSettled) =>
    !isSettled && hasEditApplicationPermission(businessId, permissions),
);

export const hasEditPermissionSelectorRegardlessOfSettlement = createSelector(
  getSelectedBusinessId,
  getPermissions,
  (businessId, permissions) => hasEditApplicationPermission(businessId, permissions),
);

export const hasAdditionalEditPermissionSelector = createSelector(
  getSelectedBusinessId,
  getPermissions,
  getIsSettled,
  (businessId, permissions, isSettled) =>
    !isSettled && hasAdditionalEditApplicationPermission(businessId, permissions),
);
