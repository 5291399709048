import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ActivityLogFilters.module.scss';
import { ACTIVITY_LOG_FILTERS, ACTIVITY_LOG_FILTERS_SHORT } from '../../constants';

const handleKeyDown = (onSelectChange, name) => (e) => {
  if (e.key && e.key === 'Enter') {
    onSelectChange(name);
  }
};

const ActivityLogFilters = ({ selectedFilterName, onSelectChange, filters }) => {
  const cypressFilterAttributes = filters.map(filter => `data-cy-${filter.name.toLowerCase().replace(/\s(\w)/g, '-$1')}`);
  return (
    <div className={styles.filtersWrapper}>
      <p>Filter by</p>
      <div className={styles.filterTags}>
        {
          filters.map(({ name }, index) => (
            <div
              key={name}
              className={classNames(styles.filter, { [styles.selected]: name === selectedFilterName })}
              onClick={() => onSelectChange(name)}
              onKeyDown={handleKeyDown(onSelectChange, name)}
              role="button"
              tabIndex={0}
              data-cy={cypressFilterAttributes[index]}
            >
              {name}
            </div>
          ))
        }
      </div>
    </div>
  );
};

ActivityLogFilters.propTypes = {
  selectedFilterName: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  filters: PropTypes.oneOf([ACTIVITY_LOG_FILTERS_SHORT, ACTIVITY_LOG_FILTERS]).isRequired,
};

export default ActivityLogFilters;
