import React from 'react';
import { connect } from 'react-redux';
import {
 Field, FieldArray, formPropTypes, reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { toMonthlyValue } from '../../../../utils';
import LiabilitiesSection from './LiabilitiesSection';
import { updateLiabilities } from '../../redux';
import { getApplicantsAndGuarantors, getLiabilitiesInitialValue } from '../../redux/selectors';
import { FETCHING_STATUS } from '../../../../../constants';
import FinancialSummary from '../shared/FinancialSummary';
import styles from './Liabilities.module.scss';
import SectionTitle from '../shared/SectionTitle';
import { formatAmountWithDefault } from '../../../../../utils/formatters';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { clearingStatuses } from './constants';

const formatLiabilities = (items) => {
  const liabilitiesWithoutTopUp = filter(
    items,
    item => item.clearingStatus !== clearingStatuses.TopUp,
  );
  const itemsForTotalLiabilities = filter(
    liabilitiesWithoutTopUp,
    item => item.clearingStatus !== clearingStatuses.Full,
  );
  return [
    {
      label: 'Monthly repayments',
      displayValue: formatAmountWithDefault(sumBy(
        items?.filter(item => !isEmpty(item?.repayments?.periodUnit)),
        (item) => toMonthlyValue(item?.repayments?.periodUnit, item?.repayments?.value),
      )),
      span: 2,
      column: -4,
    },
    {
      label: 'Total liabilities',
      displayValue: formatAmountWithDefault(
        sumBy(
          itemsForTotalLiabilities,
          ({ clearingAmount, limit = 0 }) => {
            const amount = limit;
            return amount - (clearingAmount || 0);
          },
        ),
      ),
      column: -2,
    },
  ];
};

const onSubmit = (formData, dispatch) => dispatch(updateLiabilities(formData));

const Liabilities = ({
                       hasEditPermission, handleSubmit, valid, pristine, submitting, isFetching, defaultOwnerships,
                     }) =>
  (
    <>
      <FinancialSummary />
      <FormMetaProvider readOnly={!hasEditPermission}>
        <ShowMore
          name={(
            <Field
              name="liabilities"
              component={SectionTitle}
              title="Liabilities"
              format={items => formatLiabilities(items)}
            />
          )}
          iconSize="25"
          headerClassName={styles.showMoreHeader}
        >
          <FieldArray
            name="liabilities"
            props={{ defaultOwnerships }}
            component={LiabilitiesSection}
          />
        </ShowMore>
        {hasEditPermission && (
          <StickyFooter
            disabled={pristine || submitting || !valid || isFetching}
            onClick={handleSubmit}
            type="submit"
          />
        )}
        <LoadingWithOverlay isLoading={submitting || isFetching} />
        <UnsavedChangePrompt shouldConfirm={!pristine} />
      </FormMetaProvider>
    </>
  );

Liabilities.propTypes = {
  ...formPropTypes,
  isFetching: PropTypes.bool.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  defaultOwnerships: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    initialValues: getLiabilitiesInitialValue(state),
    isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START,
    hasEditPermission: hasEditPermissionSelector(state),
    defaultOwnerships: getApplicantsAndGuarantors(state).map(applicant => ({ applicantId: applicant.id })),
  });

export default connect(mapStateToProps)(reduxForm({
  form: 'liabilitiesForm',
  enableReinitialize: true,
  onSubmit,
})(Liabilities));
