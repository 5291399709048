import { combineReducers } from 'redux';
import loanCommissions from 'MyBusiness/components/Commissions/redux/loanCommissionsRedux';
import insuranceCommissions from 'MyBusiness/components/Commissions/redux/insuranceCommissionsRedux';
import financialYears from 'MyBusiness/components/Commissions/redux/financialYearRedux';

export default combineReducers({
  loanCommissions,
  insuranceCommissions,
  financialYears,
});
