import React from 'react';
import PropTypes from 'prop-types';
import styles from './OptOutInsuranceReferral.module.scss';

const OptOutInsuranceReferral = ({ allianzReferral }) => (
  <div>
    <div>
      You have chosen to opt out of insurance for this application.
    </div>
    <div className={styles.reasonSection}>
      <div className={styles.reasonTitle}>Reason</div>
      {allianzReferral.allianzOptOutReason ? allianzReferral.allianzOptOutReason : '-'}
    </div>
  </div>
);

OptOutInsuranceReferral.propTypes = {
  allianzReferral: PropTypes.shape({
    allianzPromptForReferralDate: PropTypes.string,
    allianzOptOut: PropTypes.bool,
    allianzOptOutReason: PropTypes.string,
  }).isRequired,
};

export default OptOutInsuranceReferral;
