import React from 'react';
import sum from 'lodash/sum';
import NavbarLogo from 'shared/components/Navbar/NavbarLogo';
import SubmitFeedbackButton from 'shared/components/Navbar/SubmitFeedbackButton';
import UserNavbarItemComponent from 'shared/components/Navbar/userMenu/UserNavbarItem';
import cx from 'classnames';
import NavigationItem from './NavigationItem';
import { getRoutes } from './menuRoutes';
import styles from './Navigation.module.scss';
import Popover from '../NavbarMenu/Popover';
import Icon from '../Icon/Icon';

const renderTrigger = (toggle, isShown) => (
  <div
    className={styles.menuToggle}
    onClick={toggle}
    role="button"
    tabIndex="-1"
    onKeyDown={(e) => {
      if (e.key === 'ENTER') {
        toggle();
      }
    }}
  >
    <Icon name={isShown ? 'close' : 'menu'} />
  </div>
);

const getRouteLength = (items = []) => (items.length + sum(items.map(it => getRouteLength(it.routes))));

/* eslint-disable react/no-array-index-key */
export const NavigationBar = () => {
  const routes = getRoutes();

  return (
    <div className={cx(styles.topNavbar, 'top-navbar')}>
      <div className={cx(styles.section)}>
        <div className={styles.desktopMenuContainer}>
          <NavbarLogo linkTo="/" />
          {routes.map((route, index) => (
            <NavigationItem key={index} {...route} />
          ))}
        </div>
        <Popover trigger={renderTrigger} className={styles.mobileMenuContainer}>
          {(toggle, isShown) => {
            const allItemsCount = getRouteLength(routes);
            return (
              <div
                className={styles.menuPopover}
                style={{
                  maxHeight: isShown ? `calc(5.2rem * ${allItemsCount})` : 0,
                }}
              >
                {routes.map((route, index) => (
                  <NavigationItem
                    key={index}
                    {...route}
                    subMenuStyle="static"
                    defaultOpenMenu
                    onClick={toggle}
                  />
                ))}
              </div>
            );
          }}
        </Popover>
      </div>
      <div className={cx(styles.section, 'section')}>
        <SubmitFeedbackButton />
        <UserNavbarItemComponent />
      </div>
    </div>
  );
};

export default NavigationBar;
