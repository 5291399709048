import React from 'react';
import PropTypes from 'prop-types';
import { fieldArrayFieldsPropTypes } from 'redux-form';
import FutureCircumstance from './FutureCircumstance';

const ApplicantFutureCircumstances = ({ fields }) => (
  <div>
    {fields?.map((namePrefix, index) => (
      <FutureCircumstance
        key={namePrefix}
        namePrefix={namePrefix}
        applicantName={fields.get(index).applicantName}
      />
    ))}
  </div>
  );

const GuarantorFutureCircumstances = ({ fields }) => (
  <div>
    {fields?.map((namePrefix, index) => (
      <FutureCircumstance
        key={namePrefix}
        namePrefix={namePrefix}
        applicantName={`${fields.get(index).applicantName} - Guarantor`}
      />
      ))}
  </div>
);

ApplicantFutureCircumstances.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

GuarantorFutureCircumstances.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

export {
  ApplicantFutureCircumstances,
  GuarantorFutureCircumstances,
};
