import React from 'react';
import pick from 'lodash/pick';
import { formPropTypes, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import EditFundsAvailable from './FundsAvailable/EditFundsAvailable';
import EditApplicationCosts from './ApplicationCosts/EditApplicationCosts';
import styles from './styles.module.scss';
import { updateFundingWorksheet } from '../../redux';
import { getFundingWorksheetInitialValues } from '../../redux/selectors';
import EditCostToPurchases from './CostToPurchase/EditCostToPurchases';
import FundingPosition from './FundingPosition/FundingPosition';
import EditCalculation from './Calculation/EditCalculation';
import EditSOMAComments from './SOMAComments/EditSOMAComments';
import EditRefinances from './Refinance/EditRefinances';
import EditOtherCosts from './OtherCosts/EditOtherCosts';
import { getUserGuidanceCodes } from '../../redux/selectors/fundingWorksheet';
import EditConstructions from './Construction/EditConstructions';

const onSubmit = async (formData, dispatch, props) => {
  const { reset } = props;
  await dispatch(updateFundingWorksheet(formData));
  reset();
};

const FundingWorksheet = ({
  handleSubmit, pristine, submitting, dirty, valid, form,
}) => {
  const userGuidanceCodes = useSelector(getUserGuidanceCodes);

  return (
    <>
      <form>
        <EditCalculation />
        <div className={styles.fundingWorksheetWrapper}>
          <div className={styles.costsPanel}>
            <h3 className={styles.title}>Costs</h3>
            <EditCostToPurchases userGuidanceCodes={userGuidanceCodes.costToPurchases} />
            <EditRefinances userGuidanceCode={userGuidanceCodes.refinanceAndDebtConsolidation} />
            <EditConstructions userGuidanceCodes={userGuidanceCodes.constructions} />
            <EditOtherCosts userGuidanceCode={userGuidanceCodes.otherCosts} />
            <EditApplicationCosts
              userGuidanceCode={userGuidanceCodes.applicationCosts}
              form={form}
            />
          </div>
          <div className={styles.contributionsPanel}>
            <EditFundsAvailable userGuidanceCodes={pick(userGuidanceCodes, ['contributions', 'loanAmount'])} />
            <EditSOMAComments className={styles.somaCommentsSection} />
          </div>
          <div className={styles.summaryPanel}>
            <FundingPosition userGuidanceCodes={userGuidanceCodes} />
            <EditSOMAComments className={styles.somaCommentsSection} />
          </div>
        </div>
        <StickyFooter
          disabled={pristine || submitting || !valid}
          onClick={handleSubmit}
        />
      </form>
      <LoadingWithOverlay isLoading={submitting} />
      <UnsavedChangePrompt shouldConfirm={dirty} />
    </>
  );
};

const mapStateToProps = (state) => ({
  initialValues: getFundingWorksheetInitialValues(state),
});

FundingWorksheet.propTypes = {
  ...formPropTypes,
};

export default connect(mapStateToProps)(reduxForm({
  form: 'fundingWorksheet',
  onSubmit,
  enableReinitialize: true,
})(FundingWorksheet));
