import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import FormMetaContext from 'shared/components/FormMeta/FormMetaContext';
import styles from '../Wrapper.module.scss';
import RentalIncomes from './RentalIncomes';
import Incomes from './Incomes';

const AdditionalIncomesWrapper = props => {
  const { fields, ownerships } = props;
  const { readOnly } = useContext(FormMetaContext);
  return (
    <div className={styles.wrapper}>
      <Incomes fields={fields} />
      <RentalIncomes />
      {
        !readOnly && (
          <div className={styles.btnWrapper}>
            <SecondaryButton
              onClick={() => fields.push({ ownerships, tempId: shortId() })}
            >
              <span>Add income</span><Icon name="add" size="18" />
            </SecondaryButton>
          </div>
        )
      }
    </div>
  );
};

AdditionalIncomesWrapper.propTypes = {
  fields: PropTypes.object.isRequired,
  ownerships: PropTypes.array.isRequired,
};

export default AdditionalIncomesWrapper;
