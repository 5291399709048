import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { beforeNow, toTimeStr } from 'utils/datetime';
import Checkbox from 'shared/components/formFields/Checkbox';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import Image from 'shared/components/Image';
import * as actions from '../../redux/action/task';
import validSpecialTasksSelector from '../../redux/selector/task';
import styles from './MyTask.module.scss';

const EmptyTask = () => <div className={styles.placeholder}>You have no assigned tasks due in the next 5 days.</div>;

const MyTask = ({
  businessId, getSpecialTasks, specialTasks, changeSpecialTaskStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!businessId) {
    return <EmptyTask />;
  }

  useEffect(() => {
    setIsLoading(true);
    getSpecialTasks(businessId)
      .finally(() => setIsLoading(false));
  }, [businessId, getSpecialTasks]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isEmpty(specialTasks) && !isLoading) {
    return <EmptyTask />;
  }

  return (
    <>
      {specialTasks.map(({
        id, name, applicationId, completed, applicants, lenderId, dueDateTime,
      }) => (
        <div className={styles.taskWrapper} key={id}>
          <div className={styles.checkBoxContainer}>
            <Checkbox
              className={styles.taskCheck}
              checked={!!completed}
              onChange={() => changeSpecialTaskStatus(id, !completed)}
            />
          </div>
          <div className={classNames({ [styles.completed]: !!completed }, styles.contentContainer)}>
            <Link className={styles.link} to={`/applications/${applicationId}/tasks/${id}`}>
              <div className={styles.contentWrapper}>
                <div className={styles.name}>{name}</div>
                <div className={styles.applicants}>
                  <div className={styles.label}>APPLICANT/S</div>
                  <div>{applicants.join('; ')}</div>
                </div>
              </div>
              <div className={styles.extraInfoContainer}>
                <div className={styles.lenderLogo}>
                  <Image src={`/lenders/${lenderId}/SMALL.png`} alt="" />
                </div>
                <div className={classNames({ [styles.timeHighlight]: beforeNow(dueDateTime) })}>{`Due: ${toTimeStr(dueDateTime)}`}</div>
              </div>
            </Link>
          </div>
        </div>
        ))}
    </>
  );
};

MyTask.propTypes = {
  businessId: PropTypes.string,
  getSpecialTasks: PropTypes.func.isRequired,
  specialTasks: PropTypes.array.isRequired,
  changeSpecialTaskStatus: PropTypes.func.isRequired,
};

MyTask.defaultProps = {
  businessId: undefined,
};

const mapStateToProps = state => ({
  specialTasks: validSpecialTasksSelector(state),
});

const mapDispatchToProps = {
  getSpecialTasks: actions.getSpecialTasks,
  changeSpecialTaskStatus: actions.changeSpecialTaskStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTask);
