import React from 'react';
import PropTypes from 'prop-types';
import PlainTextField from 'shared/components/formFields/PlainTextField/PlainTextField';
import withReduxForm from 'shared/components/formFields/ReduxForm/ReduxFormFieldWrapper';
import styles from './SectionTitle.module.scss';

const SectionTitle = ({ title, value }) => (
  <div className={styles.header}>
    <span>{title}</span>
    {value.map((field) => (
      <PlainTextField
        key={field.label}
        label={field.label}
        value={field.displayValue}
        className={styles.labelWrapper}
        textClassName={styles.textClassName}
        style={{
          gridColumnStart: field.column,
          gridColumnEnd: `span ${field.span || 1}`,
        }}
      />
    ))}
  </div>
);

SectionTitle.defaultProps = {
  value: [],
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      displayValue: PropTypes.string,
      column: PropTypes.number,
      span: PropTypes.number,
    }),
  ),
};

export default withReduxForm()(SectionTitle);
