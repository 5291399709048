import React from 'react';
import omit from 'lodash/omit';
import cx from 'classnames';
import { Plugin, TemplateConnector } from '@devexpress/dx-react-core';
import PropTypes from 'prop-types';
import styles from '../style.module.scss';

/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
const TableSelectAllCell = (props) => {
  const {
    style,
    className,
  } = props;

  const styleWithoutBackgroundColor = omit(style, 'backgroundColor');

  return (
    <Plugin>
      <TemplateConnector>
        {({ classNames = {}, selectAllRows }, { toggleSelectAllRows }) => (
          <th
            className={cx(className, styles.cell, styles.selectionCell, classNames.cell, classNames.selectionCell)}
            style={styleWithoutBackgroundColor}
          >
            <input
              type="checkbox"
              checked={selectAllRows}
              onChange={() => {
                toggleSelectAllRows();
              }}
            />
          </th>
        )}
      </TemplateConnector>
    </Plugin>
  );
};
/* eslint-enable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
TableSelectAllCell.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

TableSelectAllCell.defaultProps = {
  style: {},
  className: '',
};

export default TableSelectAllCell;
