import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import styles from '../styles.module.scss';
import FieldsContainer from '../../../../../../shared/components/FieldsContainer/FieldsContainer';
import { PRIMARY_PURPOSE_OPTIONS } from '../../shared/RealEstateAsset/constants';
import { getNumberContent, getTextContent } from '../contentUtils';
import SubSection from '../components/SubSection';

export const AssetSection = ({ asset, fieldsWrapperClassName }) => {
  const assetValue = asset?.isForConstructionLoan ? asset?.contractPrice?.transferOfLandAmount : asset.value;
  const assetFields = [
    getTextContent('Purpose', find(PRIMARY_PURPOSE_OPTIONS, ['value', asset.primaryPurpose])?.label),
    getNumberContent('Purchase price', (assetValue)),
  ];
  return (

    <SubSection>
      <FieldsContainer
        fields={assetFields}
        fieldsWrapperClassName={fieldsWrapperClassName || styles.assetFieldsWrapper}
        className={styles.fieldsContainerWrapper}
      />
    </SubSection>
  );
};

AssetSection.defaultProps = {
  fieldsWrapperClassName: '',
};

AssetSection.propTypes = {
  asset: PropTypes.shape({
    primaryPurpose: PropTypes.string,
    isForConstructionLoan: PropTypes.bool,
    contractPrice: PropTypes.shape({
      transferOfLandAmount: PropTypes.number,
    }),
    value: PropTypes.number,
  }).isRequired,
  fieldsWrapperClassName: PropTypes.string,
};
