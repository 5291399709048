import React from 'react';
import PropTypes from 'prop-types';
import PurposeComponent from './PurposeComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedPurpose = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
      const { purpose } = context.loanCriteriaList[index];
      const allowAny = context.loanCriteriaList.length === 1;
      return (
        <PurposeComponent
          purpose={purpose}
          allowAny={allowAny}
          dispatchUpdatePurpose={context.dispatchUpdatePurpose(index)}
        />
      );
    }}
  </ProductSearchContext.Consumer>
);

ConnectedPurpose.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedPurpose;
