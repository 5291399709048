import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const ArrowDown = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/arrowDown"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path"
        fill={color}
        points="12 13.172 16.95 8.222 18.364 9.636 12 16 5.636 9.636 7.05 8.222"
      />
    </g>
  </svg>
);

ArrowDown.defaultProps = iconDefaultProps;
ArrowDown.propTypes = baseIcon;

export default ArrowDown;
