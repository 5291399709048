export const STATUS_OPTIONS = [
  {
    label: 'Established',
    value: 'Established',
  },
  {
    label: 'New Building',
    value: 'NewBuilding',
  },
  {
    label: 'To Be Built',
    value: 'ToBeBuilt',
  },
  {
    label: 'Vacant Land',
    value: 'VacantLand',
  },
];
