import React, { useState, useMemo } from 'react';
import TextInput from 'shared/components/formFields/TextInput';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { validateIgnoringSpaces } from 'ApplicationTracking/utils';
import { getAccountNumberValidationRuleForSpecificLender } from '../../selectors/loanSelector';
import { generateErrorMessage } from '../../../utils/accountNumber';

const AccountNumberInput = ({
 accountNumber, label, accountNumberValidationRule, onValidInput, onInvalidInput,
}) => {
  const [isValid, setValid] = useState(true);
  const [accountNumberValue, setAccountNumberValue] = useState(accountNumber);
  const errorMessage = useMemo(() => generateErrorMessage(
    accountNumberValidationRule,
  ), [accountNumberValidationRule]);

  const onChange = (value) => {
    setAccountNumberValue(value);
    if (validateIgnoringSpaces(accountNumberValidationRule.regex, value)) {
      setValid(true);
      onValidInput(value);
    } else {
      setValid(false);
      onInvalidInput(value);
      onValidInput(null);
    }
  };

  return (
    <TextInput
      value={accountNumberValue}
      label={label}
      onChange={onChange}
      errorMessage={isValid ? null : errorMessage}
      fullWidth
    />
  );
};

AccountNumberInput.defaultProps = {
  accountNumber: '',
  onInvalidInput: noop,
};

AccountNumberInput.propTypes = {
  accountNumber: PropTypes.string,
  label: PropTypes.string.isRequired,
  onValidInput: PropTypes.func.isRequired,
  onInvalidInput: PropTypes.func,
  accountNumberValidationRule: PropTypes.shape({
    loanIdentifierCalledAs: PropTypes.string,
    regex: PropTypes.string.isRequired,
    maxLength: PropTypes.string.isRequired,
    minLength: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  accountNumberValidationRule: getAccountNumberValidationRuleForSpecificLender(ownProps.lenderId)(state),
});

const ConnectedAccountNumberInput = connect(mapStateToProps)(AccountNumberInput);

export default ConnectedAccountNumberInput;

export { ConnectedAccountNumberInput as AccountNumberInput };
