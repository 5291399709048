import { createReducer } from 'utils/reducerGenerator';
import { range } from 'utils/datetime';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import * as types from '../actions';
import { FETCHING_STATUS } from '../../../../constants';

export const reducer = createReducer({
  fetchingStatus: FETCHING_STATUS.INIT,
}, {
  [types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_REQUEST]: () => ({
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_SUCCESS]: (
    state,
    { data: { loans, searchWindow: { start, end }, reportWindow } },
  ) => {
    const loanListsByMonth = range(start, end, 'month')
      .map((m) => {
        const key = m.format('YYYY-MM');
        return {
          key,
          loans: loans.filter(loan => loan.expectedSettlementDate.isValid() && loan.expectedSettlementDate.format('YYYY-MM') === key),
        };
      });

    const loansByMonth = mapValues(
      mapKeys(loanListsByMonth, 'key'),
      'loans',
    );

    return ({
      fetchingStatus: FETCHING_STATUS.SUCCESS,
      loansByMonth,
      reportWindow,
    });
  },
  [types.FETCH_LOANS_FOR_EXPECTED_SETTLEMENT_REPORT_ERROR]: () => ({
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});
