import { getTargetChangedValue } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const newSomaCreateChangeLogTransformer = origin => {
  const somaType = getTargetChangedValue(origin, 'somas[[\\da-f-]+]\\.type');
  return ({
    ...getBasicLogInfo(origin),
    actionTime: origin.actionTime,
    operator: origin.userName,
    somaType,
  });
};
export default newSomaCreateChangeLogTransformer;
