import isEmpty from 'lodash/isEmpty';
import { LOG_TYPE } from 'ApplicationTracking/constants/activityLogTypes';

const RULES = {
  [LOG_TYPE.ADDITIONAL_INCOME]: '^finance.incomes\\[[a-zA-Z0-9,-]+\\]',
  [LOG_TYPE.EMPLOYMENTS_UPDATED]: '^((applicants|guarantors)\\.persons\\[[a-zA-Z0-9,-]+\\]\\.employments\\[[a-zA-Z0-9,-]+\\]|(applicants|guarantors)\\.companies\\[[a-zA-Z0-9,-]+\\]\\.companyIncomeInfo)',
  [LOG_TYPE.ASSETS_UPDATED]: '^finance.assets.*Estates\\[[a-zA-Z0-9,-]+\\]',
  [LOG_TYPE.APPLICANTS_UPDATED]: '^(applicants|guarantors)\\.(persons|companies)\\[[a-zA-Z0-9,-]+\\]',
  [LOG_TYPE.EXPENSE_INFO_UPDATED]: '^(finance\\.expenseInfo\\.householdExpenses\\[[\\w\\-]+\\])|(finance\\.expenseInfo\\.expenseNote)',
  [LOG_TYPE.LIABILITIES_UPDATED]: '^finance\\.liabilities\\[[a-zA-Z0-9,-]+\\]',
  [LOG_TYPE.CONTACT_CHANGE]: '(?:(?:thirdParties\\[[-\\w]+\\])|(?:persons)|(?:referrer))',
};

const findUniquePath = ({ path, type }) => {
  if (!RULES[type]) return '@@DEFAULT';
  return new RegExp(`${(RULES[type])}`).exec(path)?.[0];
};

const normalize = value =>
    ((value === null || value === undefined) ? '' : value);

const beautify = changes => changes.filter(({ newValue, oldValue }) => normalize(newValue) !== normalize(oldValue));

const splitLogByPath = log => {
  const { eventDetail: { changes }, activityLogType: type } = log;

  if (!RULES[type]) return [log];

  const changesMap = changes.reduce((result, current) => {
    const key = findUniquePath({ path: current.path, type });
    return isEmpty(key) ? result : { ...result, [key]: (result[key] || []).concat(current) };
  }, {});

  return isEmpty(changesMap) ? [log] : Object.values(changesMap).map(maybeChanges => {
    const validChanges = beautify(maybeChanges);
    return validChanges.length > 0 ? ({
      ...log,
      eventDetail: {
        ...log.eventDetail,
        changes: validChanges,
      },
    }) : null;
  }).filter(Boolean);
};

export default splitLogByPath;
