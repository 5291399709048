import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Select from 'shared/components/formFields/Select';
import sortBy from 'lodash/sortBy';
import { GET_ADVISERS } from './advisersGraphQL';
import { getAdviserName } from '../../../Loans/utils';

const getSelectedOption = (value, options) => options.find(option => option.value === value);

const getOptions = (advisers = [], excludeInactive) =>
 advisers
   .filter(adviser => (excludeInactive ? adviser.isAdviser && adviser.isActive : adviser.isAdviser))
   .map(adviser => ({ label: getAdviserName(adviser), value: adviser.id }));

const getValue = (selectedAdviserId, options) => {
  if (selectedAdviserId === undefined) {
    return undefined;
  }
  return getSelectedOption(selectedAdviserId, options);
};

export const AdviserSelector = ({
 businessId, selectedAdviserId, disabled, onChange, editable, excludeInactive, label, placeholder, ...others
}) => {
  const { loading, data } = useQuery(GET_ADVISERS, {
    variables: { businessId },
    onCompleted: result =>
      !getValue(selectedAdviserId, getOptions(get(result, 'advisersByBusinessId'), excludeInactive))
      && onChange(null),
    onError: () => onChange(null),
  });

  const isStaff = (!disabled && !editable);

  const options = useMemo(() =>
    sortBy(getOptions(get(data, 'advisersByBusinessId'), excludeInactive), ['label']), [data, excludeInactive]);

  const value = useMemo(() =>
    getValue(selectedAdviserId, options), [selectedAdviserId, options]);

  return (
    <Select
      label={label}
      name="adviserId"
      options={isStaff ? [value] : options}
      placeholder={placeholder}
      fullWidth
      required
      disabled={disabled}
      onChange={option => onChange(option.value, option.label)}
      {...others}
      value={value === undefined ? null : value}
      loading={loading}
    />
  );
};

AdviserSelector.defaultProps = {
  disabled: false,
  selectedAdviserId: undefined,
  editable: true,
  excludeInactive: false,
  label: 'Adviser',
  placeholder: 'Select Adviser',
};

AdviserSelector.propTypes = {
  businessId: PropTypes.string.isRequired,
  selectedAdviserId: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  excludeInactive: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
