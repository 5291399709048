import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import PropTypes from 'prop-types';
import LoanLenderShape from 'Loans/shapes/LoanLenderShape';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { connect } from 'react-redux';
import { GET_LENDERS } from './lendersGraphQL';
import { getInitialLender, getLoanType } from '../../../../selectors/loanSelector';

const getOptions = (loading, error, data) => {
  if (loading || error) {
    return undefined;
  }

  const lenders = filter(data.lenders,
      lender => includes(lender.securityGroups, '[SmartlineStaff]')
        || includes(lender.securityGroups, '[SmartlineAll]'));

  return (data ? lenders.map(lender => ({
    label: lender.name,
    value: lender.id,
  })) : undefined);
};

const getValue = (initialLender, options) => (initialLender && options
  ? find(options, option => option.value === initialLender.lenderId)
  : undefined);

const LenderSelector = ({
 initialLender, loanType, onChange, disabled,
}) => {
  const { loading, error, data } = useQuery(GET_LENDERS, {
    variables: { category: loanType },
  });

  const options = useMemo(() => getOptions(loading, error, data), [loading, error, data]);
  const createOption = lender => lender && ({ label: lender.name, value: lender.lenderId });
  return (
    <FormsySelect
      key={loanType + (getValue(initialLender, options))}
      name="lender"
      options={options}
      onChange={onChange}
      loading={loading}
      loadingMessage="Getting lenders"
      customErrorMessage={(error ? 'Error getting lenders' : undefined)}
      value={getValue(initialLender, options) || createOption(initialLender)}
      fullWidth
      required={!loading}
      disabled={disabled}
    />
  );
};
const mapStateToProps = state => ({
  initialLender: getInitialLender(state),
  loanType: getLoanType(state),
});

LenderSelector.defaultProps = {
  onChange: () => {},
  initialLender: undefined,
  disabled: false,
};

LenderSelector.propTypes = {
  initialLender: LoanLenderShape,
  onChange: PropTypes.func,
  loanType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default connect(mapStateToProps)(LenderSelector);
