import RawPhoneInput from 'react-phone-input-2';
import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/bootstrap.css';
import { wrapInLabel } from '../NewInputLabelWrapper';

const PhoneInput = ({
 value, defaultCountry, onChange, classNames,
}) => (
  <RawPhoneInput
    inputClass={classNames.inputClass}
    dropdownClass={classNames.dropDownClass}
    buttonClass={classNames.buttonClass}
    country={defaultCountry}
    preferredCountries={['au']}
    countryCodeEditable={false}
    copyNumbersOnly
    autoFormat
    value={value}
    onChange={(newValue, countryData) => {
      const countryNumber = countryData.dialCode;
      const phone = newValue.replace(`${countryNumber}`, '');
      return onChange(countryNumber, phone);
    }}
  />
);

PhoneInput.propTypes = {
  value: PropTypes.string,
  defaultCountry: PropTypes.string,
  onChange: PropTypes.func,
  classNames: PropTypes.shape({
    inputClass: PropTypes.string,
    dropDownClass: PropTypes.string,
    buttonClass: PropTypes.string,
    containerClass: PropTypes.string,
  }),
};

PhoneInput.defaultProps = {
  defaultCountry: 'au',
  onChange: () => {},
  value: '',
  classNames: {},
};

export default wrapInLabel(PhoneInput);
