import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import RadioGroup from '../../RadioGroup';
import FieldsInputPropShapes from '../FieldsInputPropShapes';

const options = [{
  label: 'Yes',
  value: true,
}, {
  label: 'No',
  value: false,
}];

const ReduxFormYesNoRadioGroup = ({ ...props }) => {
  const {
    input: { value, onChange, name },
    ...others
  } = props;

  const handleChange = useCallback((v) =>
      onChange(isEmpty(v) ? null : v.target.value === 'true'),
    [onChange]);

  const selectedValue = useMemo(() =>
      options.find(option => option.value === value)?.value ?? null,
    [value]);

  return (
    <RadioGroup
      value={selectedValue}
      onChange={handleChange}
      options={options}
      name={name}
      {...others}
    />
  );
};

ReduxFormYesNoRadioGroup.propTypes = {
  input: PropTypes.shape(FieldsInputPropShapes).isRequired,
};

export default ReduxFormYesNoRadioGroup;
