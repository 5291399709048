export const PERMISSIONS = {
  VIEW_LOANAPPLICATION_INVOICES: { application: 'loanbook', area: 'invoices', action: 'view' },
  VIEW_LOANAPPLICATION_COMMISSIONS: { application: 'loanbook', area: 'commissions', action: 'view' },
  EDIT_PERMISSION: { application: 'permission', area: '*', action: 'edit' },
  VIEW_LOANAPPLICATION_COMMISSIONMANAGEMENT: { application: 'loanbook', area: 'commission-management', action: 'view' },
  VIEW_SKYWALKER_BUSINESS_SUMMARY: { application: 'skywalker', area: 'business-summary', action: 'view' },
  VIEW_ALL_APPLICATION_TRACKING: { application: 'application', area: 'tracking', action: 'view-all' },
  VIEW_OWN_APPLICATION_TRACKING: { application: 'application', area: 'tracking', action: 'view-own' },
  EDIT_ALL_APPLICATION_TRACKING: { application: 'application', area: 'tracking', action: 'edit-all' },
  EDIT_ALL_APPLICATION_ADDITIONAL: { application: 'application', area: 'tracking', action: 'edit-all-additional' },
  EDIT_OWN_APPLICATION_TRACKING: { application: 'application', area: 'tracking', action: 'edit-own' },
  VIEW_TEMPLATE_MANAGEMENT: { application: 'workflow', area: 'template-management', action: 'view' },
  EDIT_TEMPLATE_MANAGEMENT: { application: 'workflow', area: 'template-management', action: 'edit' },
  VIEW_ALL_LOANS: { application: 'loans', area: 'management', action: 'view-all' },
  VIEW_OWN_LOANS: { application: 'loans', area: 'management', action: 'view-own' },
  EDIT_ALL_LOANS: { application: 'loans', area: 'management', action: 'edit-all' },
  EDIT_ALL_LOANS_ADDITIONAL: { application: 'loans', area: 'management', action: 'edit-all-additional' },
  EDIT_OWN_LOANS: { application: 'loans', area: 'management', action: 'edit-own' },
};

export default null;
