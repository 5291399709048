import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditFundingWorksheet from './EditFundingWorksheet';
import ViewFundingWorksheet from './ViewFundingWorksheet';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';

const FundingWorksheet = ({ hasEditPermission }) => (hasEditPermission
  ? (<EditFundingWorksheet />)
  : (<ViewFundingWorksheet />));

FundingWorksheet.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hasEditPermission: hasEditPermissionSelector(state),
});

export default connect(mapStateToProps)(FundingWorksheet);
