import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import { Item, Timeline } from 'shared/components/Timeline';
import map from 'lodash/map';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import RetryComponent from 'shared/components/RetryComponent';
import { filterAuditChange, orderAndGroupAuditTrails } from '../../../utils';
import styles from './AuditTrail.module.scss';
import LoanUpdatedAuditTrailItem from './LoanUpdatedAuditTrailItem';
import LoanShape from '../../../shapes/LoanShape';
import LoanSplitAuditTrailItem from './LoanSplitAuditTrailItem';
import { GET_AUDIT_TRAILS } from './auditTrailGraphQL';
import { PRODUCT_TYPES } from '../../../../constants';
import useToggleCheck from '../../../../shared/hooks/useToggleCheck';
import TOGGLES from '../../../../shared/toggles';

const DATA_SOURCE = {
  launchpad: 'Launchpad',
  brokerPlatform: 'Broker Platform',
};

const AUDIT_TRAIL_TYPE = {
  NEW_LOAN: 'NEW_LOAN',
  LOAN_UPDATED: 'LOAN_UPDATED',
  LOAN_SPLIT: 'LOAN_SPLIT',
  NEW_LOAN_SPLIT: 'NEW_LOAN_SPLIT',
};

const displayMap = {
  [AUDIT_TRAIL_TYPE.NEW_LOAN]: {
    typeDisplayName: 'NEW LOAN',
    renderer: () => (<p className={styles.content}>This loan has been created</p>),
  },
  [AUDIT_TRAIL_TYPE.LOAN_UPDATED]: {
    typeDisplayName: 'LOAN UPDATED',
    renderer: auditEvent => (<LoanUpdatedAuditTrailItem changes={auditEvent.changes} />),
  },
  [AUDIT_TRAIL_TYPE.LOAN_SPLIT]: {
    typeDisplayName: 'LOAN SPLIT',
    renderer: auditEvent => (<LoanSplitAuditTrailItem changes={auditEvent.changes} />),
  },
  [AUDIT_TRAIL_TYPE.NEW_LOAN_SPLIT]: {
    typeDisplayName: 'NEW LOAN',
    renderer: () => (<p className={styles.content}>This loan has been created from a split</p>),
  },
};

const getUserName = user => get(
  DATA_SOURCE,
  user.id,
  [user.firstName, user.surname].map(s => (isNil(s) ? '' : s.trim())).join(' ').trim() || 'unknown',
);

const separateBaseRate = (auditEvents, productType, displaySubLenderToggle) => {
  let currentProductType = productType;
  return orderBy(auditEvents, ['actionTime'], ['desc'])
    .map(event => {
      const newChanges = event.changes.map(change => {
        if (change.fieldName === 'productBaseRate') {
          return {
            ...change,
            fieldName: (currentProductType === PRODUCT_TYPES.fixed ? 'productFixedRate' : 'productBaseRate'),
          };
        }
        if (change.fieldName === 'assetFinanceLender') {
          return {
            ...change,
            fieldName: displaySubLenderToggle ? 'subLender' : 'assetFinanceLender',
          };
        }
        return change;
      });
      currentProductType = newChanges.find(change => change.fieldName === 'productType')?.oldValue || currentProductType;
      return { ...event, changes: newChanges };
    });
};

const renderData = ({
                      auditTrail, standardRate, productType, displaySubLenderToggle,
                    }) => {
  const auditEventsFilterNetRate = filterAuditChange(
    auditTrail.auditEvents,
    standardRate ? String(standardRate) : standardRate,
  );
  const auditEventsSeparateBaseRate = separateBaseRate(
    auditEventsFilterNetRate, productType, displaySubLenderToggle,
  );

  const auditTrailGroups = orderAndGroupAuditTrails({ auditEvents: auditEventsSeparateBaseRate });
  return map(auditTrailGroups, auditTrailGroup => (
    <Timeline date={auditTrailGroup.date} key={auditTrailGroup.date}>
      {auditTrailGroup.auditEvents.map((auditEvent) => {
        const { typeDisplayName, renderer } = displayMap[auditEvent.type];
        return (
          <Item
            iconName="tasks-circle"
            actionTime={auditEvent.actionTime}
            operator={getUserName(auditEvent.user)}
            key={auditEvent.id}
          >
            <div className={styles.contentWrapper}>
              <span className={styles.logType}>{typeDisplayName}</span>
              {renderer(auditEvent)}
            </div>
          </Item>
        );
      })}
    </Timeline>
  ));
};

const AuditTrail = ({ loan }) => {
  const {
    loading, error, data, refetch, networkStatus,
  } = useQuery(GET_AUDIT_TRAILS, {
    variables: { loanId: loan.id },
  });
  const displaySubLenderToggle = useToggleCheck(TOGGLES.LOAN_DETAILS_DISPLAY_SUBLENDER);
  useEffect(() => { refetch(); }, [refetch, loan]);

  if (error) {
    return <RetryComponent style={{ marginTop: '0' }} />;
  }
  if (loading || networkStatus === 4) {
    return <LoadingSpinner />;
  }
  return renderData({ auditTrail: data.auditTrail, displaySubLenderToggle, ...loan });
};

AuditTrail.propTypes = {
  loan: LoanShape.isRequired,
};

export default AuditTrail;
