import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import styles from './Switch.module.scss';

const Switch = ({
                  switchedOnText,
                  switchedOffText,
                  className,
                  style,
                  inputProps,
                  checked,
                  disabled,
                  onChange,
                  name,
                  readOnly,
                  hasError,
                  ...others
                }) => {
  const { className: inputClassName, ...restInputProps } = inputProps;
  const id = useMemo(() => uniqueId('switch_'), []);
  return (
    <>
      <input
        className={cx(styles.input, inputClassName)}
        {...restInputProps}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={readOnly || disabled}
        readOnly={readOnly}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={cx(styles.switch, className)}
        style={style}
        htmlFor={id}
        {...others}
      >

        <div>{switchedOnText}</div>
        <div>{switchedOffText}</div>
        <div className={styles.slideContainer}>
          <div className={styles.slide} />
        </div>
      </label>
    </>
  );
};

Switch.propTypes = {
  switchedOnText: PropTypes.string,
  switchedOffText: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  inputProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  switchedOnText: 'ON',
  switchedOffText: 'OFF',
  className: undefined,
  style: undefined,
  name: undefined,
  checked: undefined,
  onChange: undefined,
  disabled: undefined,
  readOnly: undefined,
  hasError: undefined,
  inputProps: {},
};

export default Switch;
