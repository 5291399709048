import trim from 'lodash/trim';
import WithReadOnlyTextArea from '../../TextArea/WithReadOnlyTextArea';
import withReduxForm from '../ReduxFormFieldWrapper';

export default withReduxForm((props) => {
  const {
    onBlur,
  } = props;
  return ({
    onBlur: (e) => {
      onBlur(e === undefined ? null : trim(e.target.value));
    },
  });
})(WithReadOnlyTextArea);
