import React from 'react';
import { iconDefaultProps, baseIcon } from './baseIcon';

const Alert = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/alert"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

Alert.defaultProps = iconDefaultProps;
Alert.propTypes = baseIcon;

export default Alert;
