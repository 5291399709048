import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from '@reduxjs/toolkit';
import { getAssets } from '../assets';
import { getApplicantsAndGuarantors } from '../applicants';
import { formatItemsOwnerships } from '../utils';

export const getIncomes = createSelector(
  (state) => get(state, 'application.applicationDetail.finance.incomes') ?? [],
  getApplicantsAndGuarantors,
  (incomes, applicantsAndGuarantors) => formatItemsOwnerships(incomes ?? [], applicantsAndGuarantors),
);

export const getRentalIncomes = state => getAssets(state).realEstates
  .filter(realEstate => !isEmpty(realEstate.rentalIncome))
  .map(({ rentalIncome: { rentalAmount, periodUnit }, ownerships }, index) => ({
    periodUnit,
    ownerships,
    value: rentalAmount,
    index,
  }));
