import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import isFinite from 'lodash/isFinite';
import round from 'lodash/round';
import isNil from 'lodash/isNil';
import isInteger from 'lodash/isInteger';

export const formatCurrency = (originalNumber, option) => {
  const precision = get(option, 'precision', 0);
  const separator = get(option, 'separator', ',');
  const symbol = get(option, 'symbol', '$');
  const fallback = get(option, 'fallback', '');
  if (!isNaN(parseFloat(originalNumber)) && isFinite(originalNumber)) {
    const number = Number(originalNumber);
    const absNumber = (typeof precision !== 'undefined' ? round(Math.abs(number), precision).toFixed(precision) : Math.abs(number)).toString();
    const parts = absNumber.split('.');
    const convertedParts = [parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`), ...parts.slice(1)];
    const result = `${symbol}${convertedParts.join('.')}`;
    if (number < 0) {
      return `(${result})`;
    }
    return result;
  }
  return fallback;
};

export const toBasicCurrency = val => formatCurrency(Number(val), { precision: 2 });

export const formatYears = (years, fullSuffix = false) => {
  if (isNil(years)) {
    return '';
  }
  const yearSuffix = fullSuffix ? 'year' : 'yr';
  const pluralYears = years > 1;
  return `${years} ${yearSuffix}${pluralYears ? 's' : ''}`;
};

export const toCamelCase = str =>
  str
    .replace(/\s(.)/g, $1 => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, $1 => $1.toLowerCase());

export const toFirstCapitalized = str => str.replace(/^\s*(.)/, $1 => $1.toUpperCase());

export const formatAmount = amount =>
  formatCurrency(amount, { precision: isInteger(amount) ? 0 : 2 });

export const formatAmountWithDefault = (amount) => (isNil(amount)
  ? '$0.00'
  : formatCurrency(amount, { precision: 2 }));

export const formatAmountInteger = amount => (isNil(amount) ? '$0' : formatCurrency(amount));
