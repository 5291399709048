import PropTypes from 'prop-types';

const OtherAmountShape = PropTypes.shape({
  fieldName: PropTypes.string,
  fieldAmount: PropTypes.number,
});

export const ConstructionShape = PropTypes.shape({
  id: PropTypes.string,
  assetId: PropTypes.string,
  otherCosts: PropTypes.arrayOf(OtherAmountShape),
});
