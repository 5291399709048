import pick from 'lodash/pick';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import { MARITAL_STATUS_DEFACTO, MARITAL_STATUS_MARRIED } from '../../shared/constants';
import { formatPersonNameValue } from '../../utils/formatter';

const nonApplicantOption = {
  label: 'Non applicant',
  value: {
    isNonApplicant: true,
    firstName: null,
    surname: null,
    relatedApplicantId: null,
  },
};

const getSpouseOptions = (currentApplicantId, personalApplicants) => {
  const options = personalApplicants
    .filter(({ id }) => id !== currentApplicantId)
    .map(({ id, firstName, surname }) => ({
      label: formatPersonNameValue({ surname, firstName }),
      value: {
        isNonApplicant: false,
        relatedApplicantId: id,
      },
    }));
  return concat(sortBy(options, ['label']), nonApplicantOption);
};

const SpouseSelectorForLNClient = (props) => {
  const {
    names,
    personalApplicants,
  } = props;
  const pickedProps = pick(props, [
    'className',
    'label',
    'placeholder',
    'applicantIds',
    'personalApplicants',
    'isClearable',
  ]);
  const [maritalStatusFieldName, spouseFieldName, currentApplicantIdFieldName] = names;
  const maritalStatus = get(props, `${maritalStatusFieldName}.input.value`);
  const currentApplicantId = get(props, `${currentApplicantIdFieldName}.input.value`);
  const options = useMemo(
    () => getSpouseOptions(currentApplicantId, personalApplicants),
    [currentApplicantId, personalApplicants],
  );
  const spouse = get(props, `${spouseFieldName}`);

  const selectedOption = useMemo(() => {
    const spouseValue = get(spouse, 'input.value');
    if (isEmpty(spouseValue)) {
      return null;
    }
    return find(options, (option) => get(option, 'value.isNonApplicant') === get(spouseValue, 'isNonApplicant')
      && get(option, 'value.relatedApplicantId', '') === get(spouseValue, 'relatedApplicantId', ''))?.value ?? null;
  }, [options, spouse]);

  if (maritalStatus !== MARITAL_STATUS_MARRIED && maritalStatus !== MARITAL_STATUS_DEFACTO) {
    return null;
  }

  return (
    <ReduxFormSelect
      {...pickedProps}
      {...spouse}
      options={options}
      value={selectedOption}
    />
  );
};

SpouseSelectorForLNClient.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  personalApplicants: PropTypes.array.isRequired,
};

export default SpouseSelectorForLNClient;
