import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { TemplateConnector } from '@devexpress/dx-react-core';
import cx from 'classnames';
import {
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import styles from '../style.module.scss';
import { mergeClassNamesObjects } from '../../utils';

const TableHeaderCell = (props) => {
  const {
    className,
    children,
    style,
    column,
    colSpan,
    rowSpan,
    ...others
  } = props;

  const {
    align,
    headerRenderer,
    name,
    classNames: classNamesFromColumn,
    allowSorting,
  } = column;

  if (style) { // remove inline background styles
    delete style.backgroundColor;
  }

  return (
    <TemplateConnector>
      {({
 fixedBorderRightColumn, classNames, rows, tableColumnResizingEnabled,
}) => {
        const containShadow = fixedBorderRightColumn === name;
        const mergedClassNames = mergeClassNamesObjects(classNames, classNamesFromColumn);
        const cellClassName = cx(
          styles.cell,
          styles.headerCell,
          mergedClassNames.headerCell,
          { [mergedClassNames.sortingHeaderCell]: allowSorting },
        );

        const calculatedChildren = isFunction(headerRenderer) ? headerRenderer(children, column, rows) : children;
        return (
          <TableHeaderRow.Cell
            {...others}
            style={style}
            colSpan={colSpan}
            rowSpan={rowSpan}
            column={column}
            resizingEnabled={tableColumnResizingEnabled && !column.disallowResizing}
            className={cx(className, {
              'text-right': align === 'right',
              [cellClassName]: !containShadow,
              [styles.primativeCell]: containShadow,
              [styles.shadowCell]: containShadow,
              [mergedClassNames.primativeHeaderCell]: containShadow,
            })}
          >
            {containShadow ? (
              <div className={styles.shadowWrapper}>
                <div className={cellClassName}>
                  {calculatedChildren}
                </div>
              </div>
            ) : calculatedChildren}
          </TableHeaderRow.Cell>
        );
      }}
    </TemplateConnector>
  );
};
TableHeaderCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  column: PropTypes.shape({
    align: PropTypes.string,
    headerRenderer: PropTypes.func,
    name: PropTypes.string.isRequired,
    classNames: PropTypes.object,
    allowSorting: PropTypes.bool,
    disallowResizing: PropTypes.bool,
  }).isRequired,
  children: PropTypes.any,
  rowSpan: PropTypes.number,
  colSpan: PropTypes.number,
};

TableHeaderCell.defaultProps = {
  className: '',
  style: {},
  children: undefined,
  rowSpan: undefined,
  colSpan: undefined,
};

export default TableHeaderCell;
