import React from 'react';
import PropTypes from 'prop-types';

import InterestOnlyTermComponent from './InterestOnlyTermComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedInterestOnlyTerm = ({ index }) => (
  <ProductSearchContext.Consumer>
    {context => {
      const { interestOnlyTermError } = context.loanCriteriaList[index].errorMessage || {};
      const { interestOnlyTerm } = context.loanCriteriaList[index];
      const { repaymentType } = context.loanCriteriaList[index];
      return (
        <InterestOnlyTermComponent
          interestOnlyTermError={interestOnlyTermError}
          interestOnlyTerm={interestOnlyTerm}
          repaymentType={repaymentType}
          dispatchUpdateInterestOnlyTerm={context.dispatchUpdateInterestOnlyTerm(index)}
          dispatchValidateInterestOnlyTerm={context.dispatchValidateInterestOnlyTerm(index)}
        />
        );
    }}
  </ProductSearchContext.Consumer>
);

ConnectedInterestOnlyTerm.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedInterestOnlyTerm;
