import React from 'react';
import PropTypes from 'prop-types';
import { ProductsTableContext } from '../ProductSearchContext';
import TotalCostTermComponent from './TotalCostTermComponent';

const ConnectedTotalCostTerm = ({ menuPortalTarget }) => (
  <ProductsTableContext.Consumer>
    {
      context => {
        const { maximumTerm, totalCostTerm } = context.products;
        return (
          <TotalCostTermComponent
            maximumTerm={maximumTerm}
            totalCostTerm={totalCostTerm}
            menuPortalTarget={menuPortalTarget}
            onChange={context.dispatchOnTotalCostTermChange}
          />
        );
      }
    }
  </ProductsTableContext.Consumer>
);

ConnectedTotalCostTerm.defaultProps = {
  menuPortalTarget: undefined,
};

ConnectedTotalCostTerm.propTypes = {
  menuPortalTarget: PropTypes.instanceOf(Element),
};

export default ConnectedTotalCostTerm;
