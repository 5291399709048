import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
                   color, width, height, alt,
                 }) => (
                   <svg
                     width={width}
                     height={height}
                     viewBox="0 0 43 43"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <title>{alt}</title>

                     <g
                       id="Icon/FlagCircle"
                       stroke="none"
                       strokeWidth="1"
                       fill="none"
                       fillRule="evenodd"
                     >
                       <circle
                         id="Oval"
                         stroke={color}
                         strokeWidth="1.8"
                         cx="20.5"
                         cy="20.5"
                         r="20.5"
                         transform="translate(1.000000, 1.000000)"
                       />
                       <path
                         d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM8 7h8v2H8V7zm0 4h8v2H8v-2zm0 4h5v2H8v-2z"
                         fill={color}
                         transform="translate(10,9)"
                         id="Shape"
                       />
                     </g>
                   </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
