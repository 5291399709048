import React, { useState } from 'react';
import PropTypes from 'prop-types';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import Select from 'shared/components/formFields/Select';
import classNames from 'classnames';
import {
 applicationStatus, essentialDateDisplayStatus, withdrawnReason, declinedReason,
} from '../../constants/applicationStatus';
import styles from './RecordReasonPopup.module.scss';

const submitButtonStyle = {
  height: '3rem',
  minWidth: '15rem',
};

const otherOption = { value: 'OTHER', label: 'Other' };

export const withdrawnReasonOptions = Object.keys(withdrawnReason)
  .filter(key => key !== 'OTHER')
  .sort()
  .map(key => ({ value: key, label: withdrawnReason[key] }))
  .concat([otherOption]);
export const declinedReasonOptions = Object.keys(declinedReason)
  .filter(key => key !== 'OTHER')
  .sort()
  .map(key => ({ value: key, label: declinedReason[key] }))
  .concat([otherOption]);

const options = [
  { value: applicationStatus.DECLINED, label: essentialDateDisplayStatus.DECLINED, children: declinedReasonOptions },
  { value: applicationStatus.WITHDRAWN, label: essentialDateDisplayStatus.WITHDRAWN, children: withdrawnReasonOptions },
];
const RecordReasonPopup = ({
 onSubmit, onClose, status, disableType: typeSelectorDisabled, disabled,
}) => {
  const [selectedStatus, updateSelectedStatus] = useState(
    getStatusTypeOption(get(status, 'name')) || head(options),
  );
  const [selectedReason, updateSelectedReason] = useState(
    getStatusReasonOption(status) || head(selectedStatus.children),
  );

  const submitButtonProps = {
    style: submitButtonStyle,
    disabled,
    onClick: () => onSubmit({
      milestoneType: selectedStatus.value,
      reason: selectedReason.value,
    }),
  };

  return (
    <ModalWithButtons
      isOpen
      header="Record reason"
      submitText="Save"
      className={styles.popupWrapper}
      onRequestClose={onClose}
      submitButtonProps={submitButtonProps}
    >
      <div className={styles.selectWrapper}>
        <Select
          label="Type"
          value={selectedStatus}
          className={classNames(styles.selectItem, 'select')}
          disabled={typeSelectorDisabled}
          options={options}
          onChange={(selectedValue) => {
            updateSelectedStatus(selectedValue);
            updateSelectedReason(head(selectedValue.children));
          }}
        />
        <Select
          label="Reason description"
          value={selectedReason}
          className={classNames(styles.selectItem, 'select')}
          options={selectedStatus.children}
          onChange={selectedValue => updateSelectedReason(selectedValue)}
        />
      </div>
    </ModalWithButtons>
  );
};

const getStatusTypeOption = status => options.find(option => isEqual(option.value, status));

const getStatusReasonOption = (status) => {
  const name = get(status, 'name');
  const reason = get(status, 'reason');
  const statusTypeOption = getStatusTypeOption(name);
  return get(statusTypeOption, 'children').find(option => isEqual(option.value, reason));
};

RecordReasonPopup.defaultProps = {
  onSubmit: () => null,
  onClose: () => null,
  status: { name: applicationStatus.DECLINED },
  disableType: false,
  disabled: false,
};

RecordReasonPopup.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.object,
  disableType: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RecordReasonPopup;
