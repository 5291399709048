import React, {
 useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import styles from './styles.module.scss';
import SearchCriteriaForm from './SearchCriteriaForm';
import CriteriaSummary from '../CriteriaSummary';
import { getApplicationIdInForm, getInitialCriteria } from '../../../redux/selectors/productSearch';

const SearchCriteria = ({ onSearch }) => {
  const [modifySearch, updateModifySearch] = useState(true);
  const initialCriteria = useSelector(getInitialCriteria);
  const applicationIdInForm = useSelector(getApplicationIdInForm);

  // only re-initialise when switching application.
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialCriteria.applicationId !== applicationIdInForm) {
      dispatch(initialize(SearchCriteriaForm.formName, initialCriteria));
    }
  }, [initialCriteria, applicationIdInForm, dispatch]);

  const handleSearch = useCallback(async (value) => {
    updateModifySearch(false);
    await onSearch(value);
  }, [onSearch]);

  const handleModifySearch = useCallback(() => {
    updateModifySearch(true);
  }, []);

  return (
    <div className={styles.productsSearch}>
      <h1>Product search</h1>
      {modifySearch && <SearchCriteriaForm initialValues={initialCriteria} onSubmit={handleSearch} /> }
      {!modifySearch && <CriteriaSummary onModifySearch={handleModifySearch} /> }
    </div>
  );
};

SearchCriteria.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchCriteria;
