import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';

const initialState = [];

const applicationLoans = createReducer(initialState, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: () => initialState,
  [types.GET_LOANS_START]: () => initialState,
  [types.GET_LOANS_SUCCESS]: (state, { loans }) => loans,
  [types.UPDATE_APPLICATION_LOANS_SUCCESS]: (state, { data }) => state.map((loan) => {
    const updateInfo = data.find(item => item.id === loan.id);
    return updateInfo ? { ...loan, ...updateInfo } : loan;
  }),
});

export default applicationLoans;
