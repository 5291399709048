import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toDateTimeWithSlash } from 'utils/datetime';
import styles from './Reminder.module.scss';
import getReminderPresenter from './getReminderPresenter';

const Reminder = React.memo((props) => {
  const {
   applicationStatus: { status }, settledDate, expectedSettlementDate, ...rest
} = props;
  const presenter = getReminderPresenter({
      status, expectedSettlementDate, settledDate, ...rest,
      });
  return presenter.isVisible
    ? (
      <div className={styles.reminder}>
        <div className={classNames(styles.label)}>{presenter.reminderName}</div>
        <div
          className={classNames(styles.time, styles.item, { [styles.overDue]: presenter.isExpired })}
        >
          {toDateTimeWithSlash(presenter.date)}
        </div>
      </div>
    )
    : null;
});

Reminder.propTypes = {
  applicationStatus: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  expectedSettlementDate: PropTypes.string,
  settledDate: PropTypes.string,
  preApprovalExpiryDate: PropTypes.string,
  financeDueDate: PropTypes.string,
};

Reminder.defaultProps = {
  expectedSettlementDate: '',
  settledDate: '',
  financeDueDate: '',
  preApprovalExpiryDate: '',
};

export default Reminder;
