import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TemplateConnector } from '@devexpress/dx-react-core';
import { TableBandHeader } from '@devexpress/dx-react-grid-bootstrap4';
import styles from '../style.module.scss';

const BandCell = ({ children, className, ...others }) => (
  <TemplateConnector>
    {({ classNames = {} }) => {
        const cellClassName = cx(
          styles.cell,
          styles.bandCell,
          {
            [classNames.bandCell]: classNames.bandCell,
          },
        );
        return (
          <TableBandHeader.Cell
            {...others}
            className={cx(className, cellClassName)}
          >
            {children}
          </TableBandHeader.Cell>
        );
      }}
  </TemplateConnector>
  );

BandCell.defaultProps = {
  className: '',
  children: undefined,
};

BandCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default BandCell;
