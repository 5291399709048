import React from 'react';
import withSyncBusinessId from '../shared/components/WithSyncBusinessId/withSyncBusinessId';
import usePageViewTracking from '../pages/usePageViewTracking';
import AllApplicationsComponent from './components';

const AllApplications = () => {
  usePageViewTracking('all-applications');
  return (
    <AllApplicationsComponent />
  );
};

export default withSyncBusinessId(AllApplications);
