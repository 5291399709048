import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ReduxFormNumberInput from '../../formFields/ReduxForm/NumberInput';

const MAX_FIXED_TERM = 10;
const isValidFixedLoanTerm = fixedLoanTerm =>
  (fixedLoanTerm === undefined || (fixedLoanTerm > 0 && fixedLoanTerm <= MAX_FIXED_TERM));

const style = {
  display: 'inline-block',
  margin: '0rem 1.2rem 2rem 0rem',
  padding: 0,
  verticalAlign: 'top',
  width: '19.5rem',
};

const FixedLoanTermComponent = (props) => (
  <ReduxFormNumberInput
    label="Fixed loan term (1-10 yrs)"
    className={styles.label}
    textClassName={classNames(styles.inputText, styles.short)}
    isAllowed={isValidFixedLoanTerm}
    style={style}
    {...props}
  />
);

export default FixedLoanTermComponent;
