import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import * as actions from '../actions';
import { FETCHING_STATUS } from '../../../../constants';

export const reducer = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.START,
      };

    case actions.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_SUCCESS: {
      const {
        loans,
        reportWindow: { start, end },
      } = action.data;

      const loanListsByMonth = [start, end]
      .map((m) => {
        const key = m.format('YYYY-MM');
        return {
          key,
          loans: loans.filter(loan => loan.settledDate.isValid() && loan.settledDate.format('YYYY-MM') === key),
        };
      });

      const loansByMonth = mapValues(
        mapKeys(loanListsByMonth, 'key'),
        'loans',
      );

      return {
        ...state,
        loansByMonth,
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
        fetchingStatus: FETCHING_STATUS.SUCCESS,
      };
    }

    case actions.FETCH_LOANS_FOR_SETTLEMENT_SUMMARY_REPORT_ERROR:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.ERROR,
      };

    default:
      return state;
  }
};
