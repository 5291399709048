import isUUID from 'validator/lib/isUUID';
import set from 'lodash/fp/set';
import isNil from 'lodash/isNil';
import round from 'lodash/round';

export const openInTab = (blob, fileName) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.setAttribute('download', fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }
};

export const iSet = (state, path, value) => set(path, value, state);

export const isValidUUID = id => isUUID(id || '');

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const checkIfKeyIsEnterOrSpace = (event) => {
  const ENTER_KEY_CODE = 13;
  const SPACE_KEY_CODE = 32;
  return (event.keyCode === ENTER_KEY_CODE || event.keyCode === SPACE_KEY_CODE);
};

const MONTHS_IN_YEAR = 12;

export const convertDecimalYearToIntegerYearAndMonth = (decimalYear) => {
  if (isNil(decimalYear)) {
    return {
      year: null,
      month: null,
    };
  }
  const yearValue = Math.trunc(decimalYear);
  const monthValue = round((decimalYear - yearValue) * MONTHS_IN_YEAR);
  return {
    year: yearValue,
    month: monthValue,
  };
};
