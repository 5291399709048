import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import MobiusTable from 'shared/components/MobiusTable';
import IssueShape from 'MyBusiness/shapes/IssueShape';
import { getColumnDefinitions, columnKeys } from '../columnDefinitions';

const ReportTable = ({
                       processingMonth, issues, typeRenderer, columns, virtual,
                     }) => {
  const columnDefinitions = getColumnDefinitions(typeRenderer, undefined);
  const visibleColumns = columns.map(columnKey => columnDefinitions[columnKey]).map(col =>
    ({
      ...col,
      title: isFunction(col.title) ? col.title(processingMonth) : col.title,
    }));
  return (
    <MobiusTable
      resizingEnabled
      virtual={virtual}
      rows={issues}
      columns={visibleColumns}
    />
  );
};

ReportTable.propTypes = {
  processingMonth: PropTypes.string.isRequired,
  typeRenderer: PropTypes.func,
  issues: PropTypes.arrayOf(IssueShape),
  columns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(columnKeys))).isRequired,
  virtual: PropTypes.bool,
};

ReportTable.defaultProps = {
  typeRenderer: undefined,
  issues: [],
  virtual: true,
};

export default ReportTable;
