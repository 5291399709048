import { useQuery } from '@apollo/client';
import React, {
 useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import cx from 'classnames';
import CalculationExplanation from 'shared/components/productSearch/CalculationExplanation';
import { createSelector } from '@reduxjs/toolkit';
import { GET_ALL_FEATURES } from 'shared/components/productSearch/constants';
import { getAllFeatures } from 'shared/components/productSearch/utils';
import ComparisonEditingModal from 'shared/components/productSearch/ComparisonEditingModal/ComparisonEditingModal';
import FeatureModal from 'shared/components/productSearch/FeatureModal';
import ProductComparisonTableComponent from 'shared/components/productSearch/ComparisonTable';
import styles from '../../../../../ProductSearch/ProductComparison/style.module.scss';
import {
  stateSelector, updatePreferredOption,
  selectFeatureFromComparison, updateSelectFeaturesFromComparison, toggleIncludeCommentsInPrintout, applyModification,
} from './redux';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';

const totalCostTermSelector = (state) => get(state, 'application.productComparison.searchCriteria.totalCostTerm');
const repaymentFrequencySelector = (state) => get(state, 'application.productComparison.searchCriteria.repaymentFrequency');
const getLoansCriteria = (state) => get(state, 'application.productComparison.searchCriteria.loansCriteria');

const selectedFeatureNamesSelector = createSelector(
  getLoansCriteria,
  (loansCriteriaValue) => uniq(flatMap(loansCriteriaValue, i => i.selectedFeatureNames)),
);

const ProductComparisonTable = () => {
  const dispatch = useDispatch();
  const currentComparison = useSelector(stateSelector);
  const {
    variantsCombinations = [],
    includeCommentsInPrintout,
    selectedFeaturesFromComparison,
  } = currentComparison || {};
  const totalCostTerm = useSelector(totalCostTermSelector);
  const repaymentFrequency = useSelector(repaymentFrequencySelector);
  const hasEditPermission = useSelector(hasEditPermissionSelector);
  const loansCriteria = useSelector(getLoansCriteria);
  const [isFeatureModalOpen, updateIsFeatureModalOpen] = useState(false);

  const selectedFeatureNames = useSelector(selectedFeatureNamesSelector);
  const handlePreferredOptionChange = useCallback((optionId) => {
    dispatch(updatePreferredOption(optionId));
  }, [dispatch]);

  const { loading, error, data } = useQuery(GET_ALL_FEATURES);
  const features = getAllFeatures(loading, error, data);

  const handleSelectFeatureFromComparison = useCallback((value) => {
    dispatch(selectFeatureFromComparison(value));
  }, [dispatch]);

  const handleUpdateSelectFeaturesFromComparison = useCallback((value) => {
    dispatch(updateSelectFeaturesFromComparison(value));
  }, [dispatch]);

  const handleToggleIncludeCommentsInPrintout = useCallback(({ checked }) => {
    dispatch(toggleIncludeCommentsInPrintout(checked));
  }, [dispatch]);

  const [variantEditingState, setVariantEditingState] = useState({
    isEditing: false,
    isLoading: false,
    hasError: false,
  });
  const handleCloseComparisonEditingModal = useCallback(() => {
    setVariantEditingState({
      isEditing: false,
      isLoading: false,
      hasError: false,
    });
  }, [setVariantEditingState]);

  const handleApplyModification = useCallback(async (modification) => {
    setVariantEditingState({
      ...variantEditingState,
      isLoading: true,
    });
    dispatch(applyModification(
      modification,
      {
        loansCriteria,
        totalCostTerm,
        repaymentFrequency,
      },
    )).then(() => {
      setVariantEditingState({
        ...variantEditingState,
        isLoading: false,
        isEditing: false,
      });
    }).catch(() => {
      setVariantEditingState({
        ...variantEditingState,
        isLoading: false,
        hasError: true,
      });
    });
  }, [variantEditingState, setVariantEditingState, dispatch, loansCriteria, totalCostTerm, repaymentFrequency]);
  const handleStartVariantEditing = useCallback((variant, fieldName, optionIndex, splitIndex) => {
    setVariantEditingState({
      isEditing: true,
      isLoading: false,
      currentModification: {
        variant,
        fieldName,
        optionIndex,
        splitIndex,
      },
      hasError: false,
    });
  }, [setVariantEditingState]);
  const showFeatureModal = useCallback(() => { updateIsFeatureModalOpen(true); }, [updateIsFeatureModalOpen]);
  const hideFeatureModal = useCallback(() => { updateIsFeatureModalOpen(false); }, [updateIsFeatureModalOpen]);

  return (
    <div>
      <div className={styles.tableHintContainer}>
        <div
          className={cx(
            styles.calculationExplanation,
            styles.replacedCalculationExplanation,
          )}
        >
          <CalculationExplanation />
        </div>
        <div className={styles.preferredOptionMessage}>
          <span className={styles.redStar} />
          You must select an option before saving and downloading
        </div>
      </div>
      <ComparisonEditingModal
        onRequestClose={handleCloseComparisonEditingModal}
        onApplyModification={handleApplyModification}
        {...variantEditingState}
        isEditable={hasEditPermission}
      />
      <ProductComparisonTableComponent
        totalCostTerm={totalCostTerm}
        repaymentFrequency={repaymentFrequency}
        variantsCombinations={variantsCombinations}
        selectedFeatureNames={selectedFeatureNames}
        selectedFeaturesFromComparison={selectedFeaturesFromComparison}
        includeCommentsInPrintout={includeCommentsInPrintout}
        onToggleIncludeCommentsInPrintout={handleToggleIncludeCommentsInPrintout}
        onStartVariantEditing={handleStartVariantEditing}
        onEditFeature={showFeatureModal}
        onPreferredOptionChange={handlePreferredOptionChange}
      />
      <FeatureModal
        isOpen={isFeatureModalOpen}
        onRequestClose={hideFeatureModal}
        features={features}
        hasError={!isNil(error)}
        selectedFeatureNames={selectedFeaturesFromComparison}
        fixedFeatureNames={selectedFeatureNames}
        onToggleSelectFeature={handleSelectFeatureFromComparison}
        onUpdateSelectedFeaturesNames={handleUpdateSelectFeaturesFromComparison}
        isEditable={hasEditPermission}
      />
    </div>
  );
};

export default ProductComparisonTable;
