import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'shared/components/formFields/Checkbox';
import Icon from 'shared/components/Icon/Icon';
import isEmpty from 'lodash/isEmpty';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { Link } from 'react-router-dom';
import { beforeNow, toTimeStr } from 'utils/datetime';
import styles from './Task.module.scss';

const renderByMode = (task, isViewMode, handleChangeStatus, disabled) => {
  if (isViewMode) {
    const isCompleted = task.completed || false;
    return (
      <Checkbox
        className={styles.taskCheck}
        checked={isCompleted}
        onChange={() => handleChangeStatus(task.id, !isCompleted)}
        disabled={disabled}
      />
    );
  }

  return (
    <div className={styles.taskItemDraggable}>
      <Icon name="drag" size="20" />
    </div>
  );
};

const renderTaskInfo = (task) => {
  const { assignee, dueDateTime, completed } = task;
  return (
    <>
      <div className={styles.taskSummary}>{task.name}</div>
      <div className={styles.taskInfo}>
        <div>
          {!isEmpty(assignee) && `${assignee.firstName} ${assignee.surname}`}
        </div>
        {!isEmpty(dueDateTime) && <div className={beforeNow(dueDateTime) && !completed ? styles.overDueTask : ''}>{`Due: ${toTimeStr(dueDateTime)}`}</div>}
      </div>
    </>
  );
};

const Task = ({
 task, isViewMode, onChangeStatus, onRemove, applicationId,
}) => {
  const [disabled, setDisabled] = useState(false);

  const handleChangeStatus = (taskId, completed) => {
    setDisabled(true);
    onChangeStatus(taskId, completed).finally(() => setDisabled(false));
  };

  return (
    <li key={task.id} className={`${styles.taskItem} ${isViewMode ? '' : styles.alignCenter}`}>
      {renderByMode(task, isViewMode, handleChangeStatus, disabled)}
      {isViewMode && (
        <Link
          className={`${task.completed ? `${styles.taskCompleted}` : ''}`}
          to={`/applications/${applicationId}/tasks/${task.id}`}
        >
          {renderTaskInfo(task)}
        </Link>
      )}
      {!isViewMode && (
        <span className={`${task.completed ? `${styles.taskCompleted}` : ''}`}>
          {renderTaskInfo(task)}
        </span>
      )}
      {
        !isViewMode
        && <TertiaryButton onClick={onRemove} className={styles.deleteTaskButton}>Delete</TertiaryButton>
      }
    </li>
  );
};
Task.defaultProps = {
  isViewMode: true,
  onChangeStatus: $ => $,
  onRemove: $ => $,
  applicationId: '',
};

Task.propTypes = {
  task: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool,
  onChangeStatus: PropTypes.func,
  onRemove: PropTypes.func,
  applicationId: PropTypes.string,
};

export default Task;
