import React from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatters';
import get from 'lodash/get';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import hasPermissionTo from '../../../../../shared/components/authorisation/hasPermissionTo';
import { PERMISSIONS } from '../../../../../shared/components/authorisation/permissions';

const Tooltip = (props) => {
  const {
    targetItem: { point, series: name },
    data, series, permissions, selectedBusinessId,
  } = props;
  const serie = find(series, { name });
  const { key } = serie;
  const item = data[point];
  const isPreviousYearSerie = key === 'previousYearTotalLoanAmount';
  const canViewUpfrontCommission = () => (!isPreviousYearSerie
    && (hasPermissionTo(PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS,
    permissions,
      selectedBusinessId) || hasPermissionTo(PERMISSIONS.VIEW_SKYWALKER_BUSINESS_SUMMARY,
    permissions, '*')));
  return (
    <table className={styles.tooltipContent}>
      <tbody>
        <tr>
          <td>LOAN TOTALS</td>
          <td>{formatCurrency(
            (isPreviousYearSerie ? item.previousYearTotalLoanAmount : item.monthlyTotalLoanAmount)
            || 0,
          )}
          </td>
        </tr>
        { canViewUpfrontCommission() && (
          <tr>
            <td>EXPECTED BUSINESS UPFRONT</td>
            <td>{formatCurrency(item.expectedUpfrontCommission)}</td>
          </tr>
        )}
        <tr>
          <td>NO. OF APPLICATIONS</td>
          <td>{(isPreviousYearSerie ? item.previousYearApplicationsCount : item.applicationsCount) || 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  const { business } = state;
  return ({
    selectedBusinessId: get(business, 'selectedBusiness.id'),
    permissions: state.profile.permissions,
  });
};

Tooltip.propTypes = {
  targetItem: PropTypes.shape({
    point: PropTypes.number,
    series: PropTypes.string,
  }).isRequired,
  data: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedBusinessId: PropTypes.string,
};

Tooltip.defaultProps = {
  selectedBusinessId: undefined,
};

export default connect(mapStateToProps)(Tooltip);
