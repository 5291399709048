import { combineReducers } from 'redux';
import { createReducer } from '../../../../../utils/reducerGenerator';
import ACTIONS from '../constants';

const initalState = {
  generalInsurance: {
    advisers: [],
  },
  loanProtectionInsurance: {
    isUpdating: false,
  },
};

const generalInsurance = createReducer(initalState.generalInsurance, {
  [ACTIONS.RETRIEVE_REFERRAL_ADVISERS_SUCCESS]: (state, { advisers }) => ({
    ...state,
    advisers,
  }),
});

const loanProtectionInsurance = createReducer(initalState.loanProtectionInsurance, {
  [ACTIONS.UPDATE_LOAN_PROTECTION_INSURANCE_START]: (state) => ({
    ...state,
    isUpdating: true,
  }),
  [ACTIONS.UPDATE_LOAN_PROTECTION_INSURANCE_SUCCESS]: (state) => ({
    ...state,
    isUpdating: false,
  }),
  [ACTIONS.UPDATE_LOAN_PROTECTION_INSURANCE_ERROR]: (state) => ({
    ...state,
    isUpdating: false,
  }),
});

export default combineReducers({
  generalInsurance,
  loanProtectionInsurance,
});
