import React from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylePropType from 'react-style-proptype';
import Select from '../Select';
import { MONTH_OPTIONS } from './months';
import styles from './MonthYearPicker.module.scss';

const yearOptions = endYear => range(endYear, 1989)
    .map(year => ({
      label: year,
      value: year,
    }));

const getOptionValue = option => (option ? option.value : undefined);

const MonthYearPicker = ({
 endYear, onChange, style, className, month, year, showMissingFields, readOnly, disabled,
}) => {
  const monthOption = MONTH_OPTIONS.find(opt => opt.value === month);
  const yearOption = yearOptions(endYear)
    .find(opt => opt.value === year);
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <Select
        label="Month"
        value={monthOption}
        options={MONTH_OPTIONS}
        onChange={option => onChange({
          month: getOptionValue(option),
          year,
        })}
        errorMessage={(showMissingFields && !monthOption) ? 'You must select a month' : undefined}
        fullWidth
        isClearable
        readOnly={readOnly}
        disabled={disabled}
      />
      <Select
        label="Year"
        value={yearOption}
        options={yearOptions(endYear)}
        onChange={option => onChange({
          month,
          year: getOptionValue(option),
        })}
        errorMessage={(showMissingFields && !yearOption) ? 'You must select a year' : undefined}
        fullWidth
        isClearable
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  );
};

MonthYearPicker.defaultProps = {
  endYear: new Date().getFullYear(),
  onChange: () => {},
  style: {},
  className: undefined,
  month: undefined,
  year: undefined,
  showMissingFields: false,
  disabled: false,
  readOnly: false,
};
MonthYearPicker.propTypes = {
  endYear: PropTypes.number,
  onChange: PropTypes.func,
  style: stylePropType,
  className: PropTypes.string,
  month: PropTypes.number,
  year: PropTypes.number,
  showMissingFields: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MonthYearPicker;
