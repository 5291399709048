import { toReverseDateStr } from 'utils/datetime';
import { getBasicLogInfo } from './utils';

const sendToApplyOnlineLogContentLogTransformer = origin => ({
  content: 'Application has been transferred to ApplyOnline - Download application XML',
  applicationId: origin.applicationId,
  dateStr: toReverseDateStr(origin.actionTime),
  ...getBasicLogInfo(origin),
});

export default sendToApplyOnlineLogContentLogTransformer;
