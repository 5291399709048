import { createSelector } from '@reduxjs/toolkit';
import { getAllApplicants, getApplicants, getGuarantors } from '../applicants';
import { getCombinedApplicants, getCombinedGuarantors } from '../../../../../utils';

function calculatePercentage(owners) {
  const numberOfOwners = owners.length;
  const mod = 100 % numberOfOwners;
  const average = (100 - mod) / numberOfOwners;
  return owners.map((owner, index) => ({
    applicantId: owner.id,
    percentage: index === 0 ? (mod + average) : average,
  }));
}

export const getDefaultOwnerships = createSelector(
  getApplicants,
  applicants => calculatePercentage(applicants),
);

export const getDefaultOwnershipsWithCompanyAndGuarantors = createSelector(
  getAllApplicants,
  getGuarantors,
  (applicants, guarantors) =>
    calculatePercentage([...getCombinedApplicants(applicants), ...getCombinedGuarantors(guarantors)]),
);

export const getDefaultApplicantsOwnershipsWithCompanyAndGuarantors = createSelector(
  getAllApplicants,
  applicants => calculatePercentage(getCombinedApplicants(applicants)),
);
