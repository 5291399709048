import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import styles from '../RealEstateAsset.module.scss';
import { RENTAL_FREQUENCY_OPTIONS } from '../constants';

const RentalIncomeFields = ({ fieldNamePrefix }) => ((
  <>
    <Field
      name={`${fieldNamePrefix}.rentalIncome.periodUnit`}
      component={ReduxFormSelect}
      label="Rental frequency"
      className={classNames(styles.rentalFrequencyField, styles.field)}
      options={RENTAL_FREQUENCY_OPTIONS}
      isClearable
    />
    <Field
      name={`${fieldNamePrefix}.rentalIncome.rentalAmount`}
      component={ReduxFormNumberInput}
      label="Rental income"
      placeholder="$"
      decimalScale={2}
      prefix="$"
      fullWidth
      allowNegative={false}
      className={classNames(styles.rentalIncomeField, styles.field)}
      textClassName={styles.textClassName}
    />
    <Field
      name={`${fieldNamePrefix}.investmentPropertyCost.frequency`}
      component={ReduxFormSelect}
      label="Costs frequency"
      className={classNames(styles.investmentPropertyCostFrequencyField, styles.field)}
      options={RENTAL_FREQUENCY_OPTIONS}
      isClearable
    />
    <Field
      name={`${fieldNamePrefix}.investmentPropertyCost.value`}
      component={ReduxFormNumberInput}
      label="Investment property costs (expenses)"
      placeholder="$"
      decimalScale={2}
      prefix="$"
      fullWidth
      allowNegative={false}
      className={classNames(styles.investmentPropertyCostField, styles.field)}
      textClassName={styles.textClassName}
    />
  </>
  ));

RentalIncomeFields.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default RentalIncomeFields;
