import moment from 'moment';

export const getYearAndMonth = (yearString, index) => {
  const year = index < 6 ? yearString.split('-')[0] : yearString.split('-')[1];
  const monthIndex = index === 5 ? 12 : (index + 7) % 12;
  const month = monthIndex < 10 ? `0${monthIndex}` : monthIndex;
  return `${year}-${month}`;
};

export const getDateRange = (dateString) => {
  if (!dateString) return {};
  const start = moment(dateString).subtract(2, 'year');
  const end = moment(dateString).endOf('month');
  return {
    start,
    end,
  };
};

export const getTwelveMonthsFrom = (startDateString) => {
  const result = [];
  const curMoment = moment(startDateString);
  result.push(curMoment.format('YYYY-MM'));
  for (let i = 0; i < 11; i += 1) {
    result.push(curMoment.add(1, 'months').format('YYYY-MM'));
  }
  return result;
};
