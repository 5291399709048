import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import typography from 'shared/theme/typography.module.scss';
import { dateFormatter } from 'shared/formatterUtils';
import BackButton from 'shared/components/BackButton';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import styles from './CommissionManagementReportDetails.module.scss';
import ReportTabs from './ReportTabs';
import CommissionQueryModalComponent from '../../CommissionQuery/CommissionQueryModal';

const CommissionManagementReportDetails = ({
  match: {
    params: { processingMonth },
  },
}) => (
  <div>
    <GroupOfficeBusinessSelector />
    <div className={styles.commissionManagementReportDetails}>
      <BackButton text="Back to all reports" to="/commission-management-reports" />
      <div className={classNames(styles.title, typography.pageTitle)}>
        Commission management report
        <div className={styles.subTitle}>
          {dateFormatter.monthYear(processingMonth)}
        </div>
      </div>
      <CommissionQueryModalComponent processingMonth={processingMonth} queryType="COMMISSION_MANAGEMENT" />
      <div>
        <ReportTabs processingMonth={processingMonth} />
      </div>
    </div>
  </div>
);

export default CommissionManagementReportDetails;

CommissionManagementReportDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      processingMonth: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
