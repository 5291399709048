import React, { useCallback } from 'react';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import { change, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { isImportant } from './utils';
import PreferenceEditor from './components/PreferenceEditor';
import {
  FIXED_AND_VARIABLE_REASON_OPTION,
  FIXED_REASON_OPTION,
  RATE_TYPE_DURATION,
  VARIABLE_REASON_OPTION,
} from './constants';

const variablePreferenceEditorProps = {
  importantDegree: {
    label: 'Variable',
  },
  reason: {
    options: VARIABLE_REASON_OPTION,
  },
};

const fixedPreferenceEditorProps = {
  importantDegree: {
    label: 'Fixed',
  },
  reason: {
    options: FIXED_REASON_OPTION,
  },
};

const fixedAndVariablePreferenceEditorProps = {
  importantDegree: {
    label: 'Fixed and Variable',
  },
  reason: {
    options: FIXED_AND_VARIABLE_REASON_OPTION,
  },
};

const durationRender = (args) => {
  const { importantDegree, namePrefix } = args;
  return (
     isImportant(importantDegree?.input.value) && (
       <Field
         component={ReduxFormSelect}
         name={`${namePrefix}.preferredDuration`}
         label="Fixed rate duration"
         options={RATE_TYPE_DURATION}
         className={styles.select}
       />
    )
  );
};

const RateType = () => {
  const dispatch = useDispatch();
  const clearFixedDuration = useCallback(() => dispatch(change('needsAndObjectivesForm', 'rate.fixed.preferredDuration', null)), [dispatch]);
  const clearFixedAndVariableDuration = useCallback(() => dispatch(change('needsAndObjectivesForm', 'rate.fixedAndVariable.preferredDuration', null)), [dispatch]);
  return (
    <InformationSection title="Rate type">
      <div className={styles.commonSection}>
        <span>Capture how important the following rate types are to your client/s</span>
        <div className={styles.content}>
          <PreferenceEditor
            props={variablePreferenceEditorProps}
            namePrefix="rate.variable"
          />
          <PreferenceEditor
            props={fixedPreferenceEditorProps}
            namePrefix="rate.fixed"
            extraRender={durationRender}
            clearValue={clearFixedDuration}
          />
          <PreferenceEditor
            props={fixedAndVariablePreferenceEditorProps}
            namePrefix="rate.fixedAndVariable"
            extraRender={durationRender}
            clearValue={clearFixedAndVariableDuration}
          />
        </div>
      </div>
    </InformationSection>
  );
};

export default RateType;
