const size = {
  small: '1rem', // 10px
  regular: '1.3rem', // 13px
  large: '1.4rem', // 14px
  xlarge: '1.6rem', // 16px
  xxlarge: '1.8rem', // 18px
  xxxlarge: '2rem', // 20px
};

export default {
  size,
  standard: 'Museo-Sans-300, sans-serif',
  standardBold: 'Museo-Sans-500, sans-serif',
  title: 'Museo-Sans-700',
};
