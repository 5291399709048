import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
 Field, Fields, formValueSelector, change,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import {
  FeatureField, FixedLoanTermField, InterestOnlyTermField, LendersSelectField,
  LoanAmountFields, LoanTermField, ProductTypeField,
  PurposeField, RepaymentTypeField, PurposeCategoryField,
} from './Field';
import styles from './styles.module.scss';
import { getApplicationType } from '../../../redux/selectors/productSearch';
import ApplicationTypeField from './Field/ApplicationTypeField';
import LenderSelectField from './Field/LenderSelectField';

const MAX_NUM = 1000000000;
const isAllowed = a => (a === undefined || (a > 0 && a < MAX_NUM));

const LoanCriteria = ({
 namePrefix, isSplit, formName, index, onRemove,
}) => {
  const dispatch = useDispatch();
  const selector = useMemo(() => formValueSelector(formName), [formName]);
  const productTypeSelector = useCallback(
    (state) => selector(state, `${namePrefix}.productType`),
    [selector, namePrefix],
  );

  const repaymentTypeSelector = useCallback(
    (state) => selector(state, `${namePrefix}.repaymentType`),
    [selector, namePrefix],
  );

  const productType = useSelector(productTypeSelector);
  const repaymentType = useSelector(repaymentTypeSelector);
  const applicationType = useSelector(getApplicationType);

  const loanAmountFieldNames = useMemo(() => ([
    `${namePrefix}.loanAmount`,
    `${namePrefix}.combinedLoanAmount`,
    'applicationCriteria.totalLoanAmount',
    'applicationCriteria.totalCombinedLoanAmount',
    'applicationCriteria.securityValue',
    'applicationCriteria.loanValueRatio',
  ]), [namePrefix]);

  const isTopUp = applicationType === APPLICATION_TYPES.TOP_UP;

  const handleSwitchProductType = useCallback((value) => {
    if (value !== null && value !== 'fixed') {
      dispatch(change(formName, `${namePrefix}.fixedLoanTerm`, null));
    }
  }, [dispatch, formName, namePrefix]);

  const handleSwitchRepaymentType = useCallback((value) => {
    if (value !== 'Interest Only') {
      dispatch(change(formName, `${namePrefix}.interestOnlyTerm`, null));
    }
  }, [dispatch, formName, namePrefix]);

  return (
    <div className={styles.criteriaSection}>
      <div className={styles.criteriaHeader}>
        {isSplit && (
          <>
            <div className={styles.splitLoanTitle}>
              <h2>Loan criteria</h2>
              <h3>{`Loan ${index + 1}`}</h3>
            </div>
            <TertiaryButton onClick={() => onRemove(index)} icon="close">
              Remove
            </TertiaryButton>
          </>
        )}
        {!isSplit && (<h2>Loan criteria</h2>)}
      </div>
      <div className={styles.criteriaBody}>
        <div className={styles.firstSection}>
          <div className={classNames({ [styles.topUpContainer]: isSplit && isTopUp })}>
            { !isSplit && (<ApplicationTypeField formName={formName} />)}
            {isTopUp && (
            <Field
              name={`${namePrefix}.loanAmount`}
              component={ReduxFormNumberInput}
              props={{
                label: 'Loan amount ($)',
                decimalScale: 0,
                isAllowed,
              }}
              className={styles.criteriaNumberField}
            />
            )}
          </div>
          <Fields
            names={loanAmountFieldNames}
            component={LoanAmountFields}
            isSplit={isSplit}
            isTopUp={isTopUp}
            namePrefix={namePrefix}
            formName={formName}
          />
          <div>
            <Field
              name={`${namePrefix}.purpose`}
              component={PurposeField}
              props={{
                allowAny: false,
                formName,
                namePrefix,
              }}
            />
            <Field
              name={`${namePrefix}.productType`}
              format={null}
              component={ProductTypeField}
              onChange={handleSwitchProductType}
            />
            <Field
              name={`${namePrefix}.repaymentType`}
              format={null}
              component={RepaymentTypeField}
              onChange={handleSwitchRepaymentType}
            />
          </div>
          <div>
            <Field
              name={`${namePrefix}.loanTerm`}
              component={LoanTermField}
              isTopUp={isTopUp}
            />
            <Field
              name={`${namePrefix}.fixedLoanTerm`}
              component={FixedLoanTermField}
              disabled={![null, 'fixed'].includes(productType)}
            />
            <Field
              name={`${namePrefix}.interestOnlyTerm`}
              component={InterestOnlyTermField}
              disabled={repaymentType !== 'Interest Only'}
            />
          </div>
          <Fields
            names={[
              `${namePrefix}.purpose`,
              `${namePrefix}.purposeCategory`,
              `${namePrefix}.purposeDetails`,
              `${namePrefix}.refinanceReason`,
            ]}
            namePrefix={namePrefix}
            component={PurposeCategoryField}
            formName={formName}
          />
          <div>
            {!isSplit && (
              isTopUp
              ? (<LenderSelectField name="applicationCriteria.selectedLenderNames[0]" />)
              : (
                <Field
                  name="applicationCriteria.selectedLenderNames"
                  component={LendersSelectField}
                  format={null}
                />
              )
            )}
          </div>
        </div>
        <div className={styles.featuresSection}>
          <Field
            name={`${namePrefix}.selectedFeatureNames`}
            component={FeatureField}
          />
        </div>
      </div>
    </div>
  );
};

LoanCriteria.propTypes = {
  index: PropTypes.number.isRequired,
  namePrefix: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  isSplit: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default LoanCriteria;
