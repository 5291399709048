import isEmpty from 'lodash/isEmpty';
import {
  assetChangeLogTransformer,
  additionalIncomeChangeLogTransformer,
  statusChangeLogTransformer,
  essentialDateReasonChangeLogTransformer,
  essentialDateChangeLogTransformer,
  surveySentSuccessLogTransformer,
  acknowledgeUpdateLogTransformer,
  applicationUpdateChangeLogTransformer,
  applicationChangeFromLotusNotesLogTransformer,
  noteLogTransformer,
  deleteNoteLogTransformer,
  updateSurveyContactInfoLogTransformer,
  createTasksFromTemplateLogTransformer,
  allianzInsuranceUpdateLogTransformer,
  applicationDetailChangeLogTransformer,
  applicationLoanRequestChangeLogTransformer,
  allianzInsuranceChangeLogTransformer,
  loanProtectionInsuranceChangeLogTransformer,
  expectedSettlementDateChangeLogTransformer,
  sendToApplyOnlineLogContentLogTransformer,
  createApplicationContentLogTransformer,
  finaliseChangeLogTransformer,
  fundingWorksheetChangeLogTransformer,
  productComparisonChangeLogTransformer,
  newSomaCreateChangeLogTransformer,
  somasUpdateChangeLogTransformer,
  newSomasUpdateChangeLogTransformer,
  aliInsuranceAcknowledgementChangeLogTransformer,
  employmentsUpdateChangeLogTransformer,
  basicTaskInfoLogTransformerCreator,
  contactChangeLogTransformer,
  updateTaskInfoLogTransformer,
  expenseInfoChangeLogTransformer,
  applicantsChangeLogTransformer,
  liabilitiesUpdateLogTransformer,
  applicantsInfoSyncLogTransformer,
  needAndObjectiveUpdateLogTransformer,
  copyApplicationContentLogTransformerCreator,
} from './transformers';

import splitLogByPath from './transformers/utils/splitLogByPath';

import {
  BasicRichContent,
  StatusChangeContent,
  CollapsibleContent,
  NoteContent,
  TaskContent,
  AllianzContent,
  LoanChangeContent,
  ApplyOnlineStatusChangeContent,
  ApplicationCreationContent,
  PreApplicationDetailUpdateContent,
  SomasChangeContent,
  NewSomaCreateContent,
  ApplicationCopyContent,
} from './components/contents';
import { LOG_TYPE } from '../../../../../constants/activityLogTypes';
import preApprovalExpiryDateChangeLogTransformer from './transformers/preApprovalExpiryDateChangeLogTransformer';
import financeDueDateChangeLogTransformer from './transformers/financeDueDateChangeLogTransformer';

const logConfiguration = {
  [LOG_TYPE.ASSETS_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: assetChangeLogTransformer,
    icon: 'edit-circle',
    filterEmpty: true,
  },
  [LOG_TYPE.ADDITIONAL_INCOME]: {
    displayComponent: BasicRichContent,
    logTransformer: additionalIncomeChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.STATUS_CHANGE]: {
    displayComponent: StatusChangeContent,
    logTransformer: statusChangeLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.ESSENTIAL_DATE_REASON_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: essentialDateReasonChangeLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.ESSENTIAL_DATE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: essentialDateChangeLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.SURVEY_SENT_SUCCESS]: {
    displayComponent: BasicRichContent,
    logTransformer: surveySentSuccessLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.ACKNOWLEDGE_UPDATE]: {
    displayComponent: BasicRichContent,
    logTransformer: acknowledgeUpdateLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.APPLICATION_UPDATE_CHANGE]: {
    displayComponent: CollapsibleContent,
    logTransformer: applicationUpdateChangeLogTransformer,
  },
  [LOG_TYPE.APPLICATION_CHANGE_FROM_LOTUS_NOTES]: {
    displayComponent: BasicRichContent,
    logTransformer: applicationChangeFromLotusNotesLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.NOTE]: {
    displayComponent: NoteContent,
    logTransformer: noteLogTransformer,
    icon: 'note-circle',
  },
  [LOG_TYPE.NOTE_DELETE_AUDIT]: {
    displayComponent: NoteContent,
    logTransformer: deleteNoteLogTransformer,
    icon: 'note-circle',
  },
  [LOG_TYPE.TASK_CREATE]: {
    displayComponent: TaskContent,
    logTransformer: basicTaskInfoLogTransformerCreator({ title: 'TASK ADDED' }),
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_UPDATE]: {
    displayComponent: TaskContent,
    logTransformer: updateTaskInfoLogTransformer,
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_DELETE]: {
    displayComponent: TaskContent,
    logTransformer: basicTaskInfoLogTransformerCreator({ title: 'TASK DELETED' }),
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_STATUS_CHANGE]: {
    displayComponent: TaskContent,
    logTransformer: updateTaskInfoLogTransformer,
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_LIST_CREATE]: {
    displayComponent: TaskContent,
    logTransformer: basicTaskInfoLogTransformerCreator({ title: 'TASK LIST ADDED', isLink: false }),
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_LIST_UPDATE]: {
    displayComponent: TaskContent,
    logTransformer: basicTaskInfoLogTransformerCreator({ title: 'TASK LIST UPDATED', isLink: false }),
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.TASK_LIST_DELETE]: {
    displayComponent: TaskContent,
    logTransformer: basicTaskInfoLogTransformerCreator({ title: 'TASK LIST DELETED', isLink: false }),
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.SURVEY_CONTACT_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: updateSurveyContactInfoLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.TASK_LIST_CREATE_FROM_TEMPLATE]: {
    displayComponent: BasicRichContent,
    logTransformer: createTasksFromTemplateLogTransformer,
    icon: 'check-circle-invert',
  },
  [LOG_TYPE.ALLIANZ_INSURANCE_UPDATE_CREATE]: {
    displayComponent: AllianzContent,
    logTransformer: allianzInsuranceUpdateLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.APPLICATION_DETAIL_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: applicationDetailChangeLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.APPLICATION_LOAN_REQUEST_CHANGE]: {
    displayComponent: LoanChangeContent,
    logTransformer: applicationLoanRequestChangeLogTransformer,
    icon: 'tasks-circle',
  },
  [LOG_TYPE.ALLIANZ_INSURANCE_CHANGE_FROM_LOTUS_NOTES]: {
    displayComponent: BasicRichContent,
    logTransformer: allianzInsuranceChangeLogTransformer,
  },
  [LOG_TYPE.ALLIANZ_INSURANCE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: allianzInsuranceChangeLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.LOAN_PROTECTION_INSURANCE_COMPLIANCE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: loanProtectionInsuranceChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.EXPECTED_SETTLEMENT_DATE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: expectedSettlementDateChangeLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.PREAPPROVAL_EXPIRY_DATE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: preApprovalExpiryDateChangeLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.FINANCE_DUE_DATE_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: financeDueDateChangeLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.SEND_TO_APPLY_ONLINE]: {
    displayComponent: ApplyOnlineStatusChangeContent,
    logTransformer: sendToApplyOnlineLogContentLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.APPLICATION_CREATED]: {
    displayComponent: ApplicationCreationContent,
    logTransformer: createApplicationContentLogTransformer,
    icon: 'flag-circle',
  },
  [LOG_TYPE.APPLICATION_COPIED_FROM]: {
    displayComponent: ApplicationCopyContent,
    logTransformer: copyApplicationContentLogTransformerCreator({ isCopy: false }),
    icon: 'flag-circle',
  },
  [LOG_TYPE.APPLICATION_COPIED_TO]: {
    displayComponent: ApplicationCopyContent,
    logTransformer: copyApplicationContentLogTransformerCreator({ isCopy: true }),
    icon: 'flag-circle',
  },
  [LOG_TYPE.PRE_SUBMISSION_APPLICATION_DETAIL_CHANGE]: {
    displayComponent: PreApplicationDetailUpdateContent,
    logTransformer: finaliseChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.FUNDING_WORKSHEET_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: fundingWorksheetChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.PRODUCT_COMPARISON_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: productComparisonChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.NEW_SOMA_CREATED]: {
    displayComponent: NewSomaCreateContent,
    logTransformer: newSomaCreateChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.SOMAS_CHANGE]: {
    displayComponent: SomasChangeContent,
    logTransformer: newSomasUpdateChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.SOMA_DOWNLOAD]: {
    displayComponent: SomasChangeContent,
    logTransformer: somasUpdateChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.ALI_INSURANCE_UPDATE_ACKNOWLEDGE]: {
    displayComponent: BasicRichContent,
    logTransformer: aliInsuranceAcknowledgementChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.EMPLOYMENTS_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: employmentsUpdateChangeLogTransformer,
    icon: 'edit-circle',
    filterEmpty: true,
  },
  [LOG_TYPE.EXPENSE_INFO_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: expenseInfoChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.APPLICANTS_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: applicantsChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.CONTACT_CHANGE]: {
    displayComponent: BasicRichContent,
    logTransformer: contactChangeLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.LIABILITIES_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: liabilitiesUpdateLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.APPLICANTS_INFO_SYNCHRONIZED]: {
    displayComponent: BasicRichContent,
    logTransformer: applicantsInfoSyncLogTransformer,
    icon: 'edit-circle',
  },
  [LOG_TYPE.NEED_AND_OBJECTIVE_UPDATED]: {
    displayComponent: BasicRichContent,
    logTransformer: needAndObjectiveUpdateLogTransformer,
    icon: 'edit-circle',
  },
};

export default new Proxy(logConfiguration, {
  get: (target, prop) => ({
      ...target[prop],
      logTransformer: (log, ...args) => {
        const { logTransformer, filterEmpty } = target[prop];
        const logs = splitLogByPath(log);
        return logs
          .filter(l => !(filterEmpty && isEmpty(l.eventDetail.changes)))
          .map(l => logTransformer(l, ...args)).filter(Boolean).flat();
      },
    }),
});
