import React, { useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tab from 'shared/components/Tab';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import RetryComponent from 'shared/components/RetryComponent';
import { getCommissions } from 'MyBusiness/components/Commissions/redux/loanCommissionsRedux';
import { getCommissions as getInsuranceCommissions } from 'MyBusiness/components/Commissions/redux/insuranceCommissionsRedux';
import {
  getFinancialYears,
  updateFinancialYear,
} from 'MyBusiness/components/Commissions/redux/financialYearRedux';
import usePageViewTracking from '../../../pages/usePageViewTracking';
import FinancialYearComponent from './FinancialYearComponent';
import UpfrontCommissions from './UpfrontCommissions';
import TrailCommissions from './TrailCommissions';
import InsuranceCommissions from './InsuranceCommissions';
import DownloadCommissionsComponent from './DownloadCommissions';
import LoadingAndErrorWrapper from '../../../shared/components/LoadingAndErrorWrapper/LoadingAndErrorWrapper';
import CommissionQueryModalComponent from '../CommissionQuery/CommissionQueryModal';
import BusinessShape from '../../../shapes/BusinessShape';
import { CommissionRowShape } from './commissionUtil/commission';

export const Commissions = ({
  dispatchGetFinancialYears,
  dispatchGetCommissions,
  dispatchGetInsuranceCommissions,
  dispatchUpdateFinancialYear,
  financialYears,
  financialYearsHasErrors,
  insuranceCommissions,
  insuranceCommissionsHasErrors,
  insuranceCommissionsIsLoading,
  insuranceCommissionsProcessingMonthsLength,
  loanCommissionsHasErrors,
  loanCommissionsIsLoading,
  loanCommissionsProcessedMonthsLength,
  selectedBusiness,
  selectedFinancialYear,
  trailCommissions,
  upfrontCommissions,
  toggles,
}) => {
  usePageViewTracking('commissions');

  useEffect(() => {
    if (selectedFinancialYear) {
      dispatchGetCommissions(selectedFinancialYear);
      dispatchGetInsuranceCommissions(selectedFinancialYear);
    }
  }, [
    selectedBusiness,
    selectedFinancialYear,
    financialYears,
    dispatchGetCommissions,
    dispatchGetInsuranceCommissions,
  ]);

  useEffect(() => {
    dispatchGetFinancialYears();
  }, [selectedBusiness, dispatchGetFinancialYears]);

  const upfrontCommissionsComponent = loanCommissionsHasErrors ? (
    <RetryComponent />
  ) : (
    <UpfrontCommissions
      rows={upfrontCommissions}
      processingMonthsLength={loanCommissionsProcessedMonthsLength}
      isLoading={loanCommissionsIsLoading}
      toggles={toggles}
    />
  );

  const trailCommissionsComponent = loanCommissionsHasErrors ? (
    <RetryComponent />
  ) : (
    <TrailCommissions
      rows={trailCommissions}
      processingMonthsLength={loanCommissionsProcessedMonthsLength}
      isLoading={loanCommissionsIsLoading}
      toggles={toggles}
    />
  );

  const insuranceCommissionComponent = insuranceCommissionsHasErrors ? (
    <RetryComponent />
  ) : (
    <InsuranceCommissions
      rows={insuranceCommissions}
      processingMonthsLength={insuranceCommissionsProcessingMonthsLength}
      isLoading={insuranceCommissionsIsLoading}
    />
  );

  return (
    <div>
      <GroupOfficeBusinessSelector />
      <section style={{ marginBottom: '1rem' }}>
        <h1>Commissions</h1>
        {selectedFinancialYear && (
          <DownloadCommissionsComponent financialYear={selectedFinancialYear} />
        )}
        <FinancialYearComponent
          selected={selectedFinancialYear}
          years={financialYears}
          dispatchUpdateFinancialYear={dispatchUpdateFinancialYear}
        />
      </section>
      <CommissionQueryModalComponent queryType="ALL_COMMISSIONS" />
      {selectedBusiness && selectedBusiness.id && selectedBusiness.displayName && (
        <LoadingAndErrorWrapper
          isLoading={!selectedFinancialYear}
          hasError={financialYearsHasErrors}
        >
          <Tab
            tabs={[
              {
                key: '1',
                title: 'Upfront',
                content: upfrontCommissionsComponent,
              },
              {
                key: '2',
                title: 'Trail',
                content: trailCommissionsComponent,
              },
              {
                key: '3',
                title: 'Insurance',
                content: insuranceCommissionComponent,
              },
            ]}
          />
        </LoadingAndErrorWrapper>
      )}
    </div>
  );
};

Commissions.propTypes = {
  dispatchGetCommissions: PropTypes.func.isRequired,
  dispatchGetFinancialYears: PropTypes.func.isRequired,
  dispatchGetInsuranceCommissions: PropTypes.func.isRequired,
  dispatchUpdateFinancialYear: PropTypes.func.isRequired,
  financialYears: PropTypes.arrayOf(PropTypes.string),
  financialYearsHasErrors: PropTypes.bool,
  insuranceCommissions: PropTypes.arrayOf(CommissionRowShape).isRequired,
  insuranceCommissionsHasErrors: PropTypes.bool,
  insuranceCommissionsIsLoading: PropTypes.bool.isRequired,
  insuranceCommissionsProcessingMonthsLength: PropTypes.number,
  loanCommissionsHasErrors: PropTypes.bool,
  loanCommissionsIsLoading: PropTypes.bool.isRequired,
  loanCommissionsProcessedMonthsLength: PropTypes.number,
  selectedBusiness: BusinessShape,
  selectedFinancialYear: PropTypes.string,
  trailCommissions: PropTypes.arrayOf(CommissionRowShape).isRequired,
  upfrontCommissions: PropTypes.arrayOf(CommissionRowShape).isRequired,
  toggles: PropTypes.object,
};

Commissions.defaultProps = {
  loanCommissionsHasErrors: false,
  financialYears: [],
  financialYearsHasErrors: false,
  insuranceCommissionsHasErrors: false,
  insuranceCommissionsProcessingMonthsLength: 0,
  loanCommissionsProcessedMonthsLength: 0,
  selectedBusiness: undefined,
  selectedFinancialYear: undefined,
  toggles: {},
};

const mapStateToProps = state => ({
  selectedFinancialYear: state.myBusiness.commissions.financialYears.selected,
  financialYears: state.myBusiness.commissions.financialYears.years,
  financialYearsHasErrors: !!state.myBusiness.commissions.financialYears.errors,
  upfrontCommissions:
    state.myBusiness.commissions.loanCommissions.upfrontCommissions,
  trailCommissions:
    state.myBusiness.commissions.loanCommissions.trailCommissions,
  loanCommissionsProcessedMonthsLength:
    state.myBusiness.commissions.loanCommissions.processingMonthsLength,
  loanCommissionsIsLoading:
    state.myBusiness.commissions.loanCommissions.isLoading,
  loanCommissionsHasErrors: !!state.myBusiness.commissions.loanCommissions
    .errors,
  insuranceCommissions:
    state.myBusiness.commissions.insuranceCommissions.insuranceCommissions,
  insuranceCommissionsProcessingMonthsLength:
    state.myBusiness.commissions.insuranceCommissions.processingMonthsLength,
  insuranceCommissionsIsLoading:
    state.myBusiness.commissions.insuranceCommissions.isLoading,
  insuranceCommissionsHasErrors: !!state.myBusiness.commissions
    .insuranceCommissions.errors,
  selectedBusiness: get(state, 'business.selectedBusiness'),
  toggles: get(state, 'profile.toggles'),
});

const mapDispatchToProps = dispatch => ({
  dispatchGetCommissions: financialYear =>
    dispatch(getCommissions(financialYear)),
  dispatchGetInsuranceCommissions: financialYear =>
    dispatch(getInsuranceCommissions(financialYear)),
  dispatchGetFinancialYears: () => dispatch(getFinancialYears()),
  dispatchUpdateFinancialYear: financialYear =>
    dispatch(updateFinancialYear(financialYear)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Commissions);
