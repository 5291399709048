import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import { FETCHING_STATUS } from '../../constants';
import { INITIAL_TAKE, TOTAL_ROW_COUNT } from '../constants';
import { ACTIONS } from './actions';

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  take: null,
  result: [],
  remoteFilters: [],
  totalRowCount: TOTAL_ROW_COUNT,
  totalLoanAmount: null,
  skip: null,
  disableDownloadButton: false,
};

const mapper = {
  SETTLED: 'Settled',
  DECLINED: 'Declined',
  WITHDRAWN: 'Withdrawn',
  UNCONDITIONALLY_APPROVED: 'Unconditional',
  CONDITIONALLY_APPROVED: 'Conditional',
  SUBMITTED: 'Submitted',
  DRAFT: 'Draft',
  PRE_SUBMISSION: 'Pre-submission',
};

const transformer = (status) => mapper[status] || status;

const extractApplicantsNames = (applicants) => sortApplicantListByPrimaryAndName(getCombinedApplicants(applicants))
  .map(it => it.name).join('; ');

const getCombinedApplicants = applicants => concat(
  get(applicants, 'persons', []).map(person => ({ isPerson: true, ...person })),
  get(applicants, 'companies', []).map(company => ({ isPerson: false, ...company })),
);

const sortApplicantListByPrimaryAndName = applicantList =>
  orderBy(applicantList.map(applicant => ({
    ...applicant,
    isPrimary: isNil(applicant.isPrimary) ? false : applicant.isPrimary,
  })), ['isPrimary', 'isPerson', 'name'], ['desc', 'desc', 'asc']);

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET:
      return {
        ...initialState,
      };
    case ACTIONS.INIT_QUERY:
      return {
        ...state,
        take: INITIAL_TAKE,
        skip: 0,
      };
    case ACTIONS.GET_ALL_APPLICATIONS_START:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.START,
      };
    case ACTIONS.GET_ALL_APPLICATIONS_SUCCESS: {
      const {
        totalCount, totalLoanAmount, applicationList, adviserList, lenderList,
      } = action.data;

      const lenderDropDownList = lenderList.filter(lender => lender).map(lender => ({ label: lender, value: lender }));
      const adviserDropDownList = adviserList.map(adviser => ({
        label: adviser.name,
        value: adviser.id,
      }));
      const defaultValue = { label: 'Any', value: '' };
      lenderDropDownList.unshift(defaultValue);
      adviserDropDownList.unshift(defaultValue);

      const result = applicationList.map(it => ({
          id: it.id,
          adviserName: it.adviser?.name,
          applicantName: extractApplicantsNames(it.applicants),
          lenderName: it.lender?.name,
          applicationNumber: it.applicationNumber,
          currentStatus: transformer(it.applicationStatus.status),
          created: it.applicationCreateTime,
          submitted: it.submittedDate,
          conditionalApproval: it.conditionalApprovedDate,
          unconditionalApproval: it.unconditionalApprovedDate,
          expectedSettlement: it.expectedSettlementDate,
          settled: it.settledDate,
          preApproval: it.isPreApproval,
          totalLoanAmount: it.totalLoanAmount,
        }));

      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.SUCCESS,
        result,
        totalRowCount: totalCount,
        totalLoanAmount,
        adviserDropDownList,
        lenderDropDownList,
      };
    }
    case ACTIONS.GET_ALL_APPLICATIONS_ERRORS:
      return {
        ...state,
        fetchingStatus: FETCHING_STATUS.ERROR,
        errors: action.errors,
      };
    case ACTIONS.GET_ALL_APPLICATIONS_INIT:
      return {
        ...state,
        skip: action.payload.skip,
        take: action.payload.take,
      };
    case ACTIONS.UPDATE_REMOTE_FILTERS:
      return {
        ...state,
        remoteFilters: action.payload.remoteFilters,
        skip: 0,
        take: INITIAL_TAKE,
        totalRowCount: 0,
      };
    case ACTIONS.DOWNLOAD_ALL_APPLICATIONS_START:
      return {
        ...state,
        disableDownloadButton: true,
      };
    case ACTIONS.DOWNLOAD_ALL_APPLICATIONS_ERRORS:
      return {
        ...state,
        disableDownloadButton: false,
      };
    case ACTIONS.DOWNLOAD_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        disableDownloadButton: false,
      };
    default:
      return state;
  }
};
