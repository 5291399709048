import React, { useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import colours from 'shared/theme/colours';
import Icon from 'shared/components/Icon/Icon';
import { toRatePercentage } from 'shared/formatterUtils';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import LoanCriteria from 'shared/components/LoanDetails/LoanCriteria';
import LoanExtraInfo from 'shared/components/LoanDetails/LoanExtraInfo';
import AssetFinance from 'shared/components/LoanDetails/AssetFinance';
import EditableContent from 'shared/components/EditableContent/EditableContent';
import styles from './LoanDetails.module.scss';
import { getLoans, updateApplicationLoanRequests } from '../../redux';
import { PRODUCT_TYPES } from '../../../../../constants';
import {
  generateAOLURL,
  generateLoanLink,
  generateLoanRequestName,
  getNextGenIdentifier,
  isApplicationLaunchpadCreated,
  validateIgnoringSpaces,
} from '../../../../utils';
import { generateErrorMessage, getAlias as getAccountNumberAlias } from '../../../../../utils/accountNumber';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';

const getAccountNumber = accountNumber => (isEmpty(accountNumber) ? 'Undefined' : accountNumber);

const getIdentifiers = (
  loan, applicationId, loanRequestName, updateLoanRequests,
  isEditable, lenderConfig,
) => {
  const { alias, regex } = lenderConfig;

  const errorMessage = generateErrorMessage(lenderConfig);

  return ([
    {
      title: 'Approval number',
      content: isEditable ? (
        <EditableContent
          content={loan.approvalNumber}
          label="Approval number"
          placeholder="Add number"
          maxLength="32"
          onSave={value => (updateLoanRequests(applicationId, [{
            approvalNumber: value,
            id: loan.id,
            loanRequestName,
          }]))}
        />
      ) : loan.approvalNumber,
      sideBarColour: FIELD_SIDE_BAR_COLORS.DarkBlue,
    },
    {
      title: `Account number${alias ? ` (${alias})` : ''}`,
      content: isEditable ? (
        <EditableContent
          content={loan.accountNumber}
          label={`Account number${alias ? ` (${alias})` : ''}`}
          placeholder="Add number"
          errorMessage={errorMessage}
          onSave={
            value => (updateLoanRequests(applicationId, [{
              accountNumber: value.trim(),
              id: loan.id,
              loanRequestName,
            }]))
          }
          onValidate={(value) => {
            const trimmed = value.trim();
            return isEmpty(trimmed) || validateIgnoringSpaces(regex, trimmed);
          }}
        />
      ) : getAccountNumber(loan.accountNumber),
      sideBarColour: FIELD_SIDE_BAR_COLORS.DarkBlue,
    },
  ]);
};

const getInterests = (loan, isLaunchpadCreated) => {
  const baseRate = {
    title: loan.productType === PRODUCT_TYPES.fixed ? 'Fixed rate' : 'Base rate',
    content: toRatePercentage(loan.baseRate, ''),
    sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
  };

  const interestRates = [
    {
      title: 'Approved discount',
      content: toRatePercentage(loan.clientDiscountRate, ''),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
      className: styles.clientDiscountRate,
    },
    {
      title: 'Net rate',
      content: toRatePercentage(loan.standardRate, ''),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    }];
  return isLaunchpadCreated ? [baseRate, ...interestRates] : interestRates;
};

const getIntroductoryInterests = (loan, isLaunchpadCreated) => {
  const baseRate = {
    title: loan.productType === PRODUCT_TYPES.fixed ? 'Fixed rate' : 'Base rate',
    content: 'N/A',
    sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
  };

  const interestRates = [
    {
      title: 'Approved discount',
      content: 'N/A',
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    },
    {
      title: 'Net rate',
      content: toRatePercentage(loan.discountRate, ''),
      sideBarColour: FIELD_SIDE_BAR_COLORS.Pink,
    }];
  return isLaunchpadCreated ? [baseRate, ...interestRates] : interestRates;
};

// eslint-disable-next-line max-len
const renderLoanPanel = (loan, index, applicationId, isApplicationSettled, updateLoanRequests, isEditable, lenderConfig, isLaunchpadCreated) => {
  const isInactiveLoan = get(loan, 'dateOfWithdrawn') || get(loan, 'dateOfDeclined');
  return (
    <InformationSection
      key={index}
      titleWrapperStyle={styles.loanTitleWrapper}
      title={(
        <>
          <div>
            <span className={styles.loanIndex}>{`Loan ${index + 1}`}</span>
            {loan.productName}
          </div>
          {isInactiveLoan && <span className={styles.inactiveLoanTag}>Declined or withdrawn</span>}
        </>
      )}
    >
      <LoanCriteria loan={loan} />
      <LoanExtraInfo
        interestFields={getInterests(loan, isLaunchpadCreated)}
        introductoryInterestFields={getIntroductoryInterests(loan, isLaunchpadCreated)}
        identifierFields={getIdentifiers(
          loan, applicationId, generateLoanRequestName(index, loan.productName),
          updateLoanRequests, isEditable, lenderConfig,
        )}
        loanLink={isApplicationSettled && !isInactiveLoan ? generateLoanLink(loan) : null}
        introductoryInterestTerm={loan.discountRateTerm}
      />
      {loan.internallyReferred && <AssetFinance loan={loan} />}
    </InformationSection>
  );
};

const renderLink = ({ href, text, isNewPage }) => { // eslint-disable-line react/prop-types
  const moreProps = isNewPage ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <a href={href} className={styles.link} {...moreProps}>
      {text}
      <Icon name="share" size="15" color={colours.ocean} />
    </a>
  );
};

const renderApplicationLink = (notesLink, source) => (
  <div className={styles.linksWrapper}>
    <div className={styles.title}>Linked Applications</div>
    {!isEmpty(notesLink) && renderLink({ href: notesLink, text: 'Application in Lotus Notes' })}
    {renderLink({
      href: generateAOLURL(getNextGenIdentifier(source)),
      text: 'Application in ApplyOnline',
      isNewPage: true,
    })}
  </div>
);

const LoanDetails = ({
  application, getApplicationLoans, updateLoanRequests, loans, hasEditPermission, lenderId,
                       lendersConfig, isApplicationSettled,
}) => {
  useEffect(() => {
    getApplicationLoans(application.id);
  }, [application.id, getApplicationLoans]);
  const { config: { lenderConfigList, defaultLenderValidationConfig } } = lendersConfig;
  const lenderConfig = lenderConfigList.find(config => config.lenderId === lenderId) || defaultLenderValidationConfig;
  const isEditable = hasEditPermission && !isApplicationSettled && lenderId;
  const isLaunchpadCreated = isApplicationLaunchpadCreated(application.source);
  const isSentToApplyOnline = isLaunchpadCreated
    ? application?.preSubmission?.sentToApplyOnline : true;

  return (
    <div className={styles.loanDetails}>
      {orderBy(loans, 'createdAt').map((loan, index) =>
        renderLoanPanel(loan, index, application.id, isApplicationSettled, updateLoanRequests,
          isEditable, {
          ...lenderConfig,
            alias: getAccountNumberAlias(lenderConfig),
          }, isLaunchpadCreated))}
      {isSentToApplyOnline && renderApplicationLink(application.notesLink, application.source)}
    </div>
  );
};

LoanDetails.propTypes = {
  application: PropTypes.object.isRequired,
  getApplicationLoans: PropTypes.func.isRequired,
  loans: PropTypes.array.isRequired,
  lenderId: PropTypes.string.isRequired,
  lendersConfig: PropTypes.shape({
    config: PropTypes.shape({
      lenderConfigList: PropTypes.arrayOf(PropTypes.shape({
        lenderId: PropTypes.string.isRequired,
        loanIdentifierCalledAs: PropTypes.string,
        regex: PropTypes.string.isRequired,
        maxLength: PropTypes.string.isRequired,
        minLength: PropTypes.string.isRequired,
      })).isRequired,
      defaultLenderValidationConfig: PropTypes.shape({
        regex: PropTypes.string.isRequired,
        maxLength: PropTypes.string.isRequired,
        minLength: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    hasError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  updateLoanRequests: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isApplicationSettled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  application: get(state, 'application.applicationDetail'),
  loans: get(state, 'application.applicationLoans'),
  lenderId: get(state, 'application.applicationDetail.lender.lenderId', ''),
  lendersConfig: get(state, 'lendersConfig'),
  isApplicationSettled: get(state, 'application.applicationStatus.isApplicationSettled'),
  hasEditPermission: hasEditPermissionSelector(state),
});

export default connect(mapStateToProps, {
  getApplicationLoans: getLoans,
  updateLoanRequests: updateApplicationLoanRequests,
})(LoanDetails);
