import React from 'react';
import HasPermissions from 'shared/components/authorisation/HasPermissions';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import Button from 'shared/components/Buttons/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleEdit } from 'Loans/components/LoanDetails/redux/loanActions';

export const EditButtonsComponent = ({
 toggleEditMode, isEditing, disabled, isLoading,
}) => (
  <HasPermissions
    permissions={[
      PERMISSIONS.EDIT_ALL_LOANS,
      PERMISSIONS.EDIT_OWN_LOANS,
      PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL,
    ]}
    fallback={null}
  >
    {isEditing ? (
      <>
        <Button variant="secondary" onClick={() => toggleEditMode(!isEditing)} disabled={isLoading}>Cancel</Button>
        <Button variant="primary" type="submit" disabled={disabled || isLoading} loading={isLoading}>Save</Button>
      </>
    ) : (
      <Button onClick={() => toggleEditMode(!isEditing)}>Edit / Switch</Button>
    )}
  </HasPermissions>
);

EditButtonsComponent.defaultProps = {
  isEditing: false,
  isLoading: false,
  disabled: true,
};

EditButtonsComponent.propTypes = {
  toggleEditMode: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  toggleEditMode: editMode => dispatch(toggleEdit(editMode)),
});

export default connect(
  null,
  mapDispatchToProps,
)(EditButtonsComponent);
