import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RetryComponent from 'shared/components/RetryComponent';
import { withRouter } from 'react-router-dom';

import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import GroupOfficeBusinessSelector from 'shared/components/GroupOfficeBusinessSelector/GroupOfficeBusinessSelector';
import BackButton from 'shared/components/BackButton';
import NoAccess from 'shared/components/NoAccess/NoAccess';
import { ModalPortalProvider } from 'shared/components/ModalPortal';
import { FETCHING_STATUS } from '../../../../constants';
import * as actionCreators from '../redux';
import ApplicationNotFound from '../ApplicationNotFound';
import styles from '../ApplicationDetails.module.scss';
import ApplicationTitleActions from '../ApplicationTitleActions';
import { ApplicationDetailContainer } from './ApplicationDetailContainer';
import { hasEditPermissionSelector } from '../redux/selectors/permissions';

export class SummaryDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: FETCHING_STATUS.INIT,
      backUrl: props.location.search,
    };
    this.handleBeforeSelectBusiness = this.handleBeforeSelectBusiness.bind(this);
  }

  componentDidMount() {
    const { getApplicationDetails, applicationId } = this.props;
    this.setState({ status: FETCHING_STATUS.START });
    getApplicationDetails(applicationId)
      .then(() => this.setState({ status: FETCHING_STATUS.SUCCESS }))
      .catch(() => this.setState({ status: FETCHING_STATUS.ERROR }));
    window.addEventListener('beforeSelectBusiness', this.handleBeforeSelectBusiness);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeSelectBusiness', this.handleBeforeSelectBusiness);
  }

  handleBeforeSelectBusiness(e) {
    const { history } = this.props;
    e.confirmed = false;
    history.push(`/applications?businessId=${e.selectedBusinessId}`);
  }

  getBackUrl = () => {
    const { backUrl } = this.state;
    const hashMap = {
      'all-applications': '/all-applications',
    };
    const searchMap = new URLSearchParams(backUrl);
    return hashMap[searchMap.get('from')] || '/applications';
  };

  renderApplication() {
    const { status } = this.state;

    if (status !== FETCHING_STATUS.SUCCESS) {
      return null;
    }

    const { hasEditPermission } = this.props;

    return <ApplicationDetailContainer hasEditPermission={hasEditPermission} />;
  }

  renderAppDetails() {
    const {
      applicationNotFound,
      applicationForbidden,
    } = this.props;

    if (applicationForbidden) {
      return <NoAccess />;
    } if (applicationNotFound) {
      return <ApplicationNotFound />;
    }

    const { status } = this.state;
    return (
      <ModalPortalProvider>
        <div>
          <div className={styles.applicationDetailsHeaderContent}>
            <h1>Application details</h1>
            { status === FETCHING_STATUS.SUCCESS && <ApplicationTitleActions /> }
          </div>
          {status === FETCHING_STATUS.START && <LoadingSpinner />}
          {status === FETCHING_STATUS.ERROR && <RetryComponent />}
          {this.renderApplication()}
        </div>
      </ModalPortalProvider>
    );
  }

  render() {
    return (
      <div>
        <GroupOfficeBusinessSelector />
        <div className={styles.page}>
          <BackButton text="Back to all applications" to={this.getBackUrl()} />
          {this.renderAppDetails()}
        </div>
      </div>
    );
  }
}

SummaryDetails.propTypes = {
  applicationId: PropTypes.string.isRequired,
  getApplicationDetails: PropTypes.func.isRequired,
  applicationNotFound: PropTypes.bool.isRequired,
  applicationForbidden: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  ...state.application.applicationFetchingStatus,
  ...state.application.applicationDetail,
  hasEditPermission: hasEditPermissionSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getApplicationDetails: (applicationId) => dispatch(actionCreators.getApplicationDetails(applicationId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SummaryDetails),
);
