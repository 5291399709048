import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CompareButton from 'shared/components/productSearch/CompareButton';
import { getApplicationId } from '../../../redux/selectors';

const ConnectedCompareButton = ({ history, onViewComparison, comparisonSize }) => {
  const applicationId = useSelector(getApplicationId);
  const comparisonRoute = `/applications/${applicationId}/productComparison`;
  return (
    <CompareButton
      history={history}
      comparisonRoute={comparisonRoute}
      comparisonTitle="View comparison"
      comparisonSize={comparisonSize}
      dispatchGetComparisonDetails={onViewComparison}
    />
  );
};

ConnectedCompareButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onViewComparison: PropTypes.func.isRequired,
  comparisonSize: PropTypes.number.isRequired,
};

export default withRouter(ConnectedCompareButton);
