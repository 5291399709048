import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import noop from 'lodash/noop';
import NumberInput from 'shared/components/formFields/NumberInput';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import styles from './LoanSplit.module.scss';
import { AccountNumberInput } from './AccountNumberInput';

const renderAccountNumber = (
  number,
  accountNumber,
  lenderId,
  onAccountNumberChange,
) => {
  const label = `Split ${number} account number`;
  return number === 1 ? (
    <>
      <NewInputLabelWrapper label={label} fullWidth>
        <div>{accountNumber}</div>
      </NewInputLabelWrapper>
    </>
  ) : (
    <AccountNumberInput
      lenderId={lenderId}
      accountNumber={accountNumber}
      label={label}
      onValidInput={onAccountNumberChange}
      fullWidth
    />
  );
};

const renderRemove = (number, onRemove) => {
  if (number === 2) {
    return <div className={styles.removeButtonWrapper} />;
  }
  if (number > 2) {
    return (
      <div className={styles.removeButtonWrapper}>
        <TertiaryButton className={styles.removeButton} onClick={onRemove}>
          Remove
        </TertiaryButton>
      </div>
    );
  }
  return null;
};

const LoanSplitInput = ({
  number,
  accountNumber,
  lenderId,
  settledAmount,
  onAccountNumberChange,
  onSettledAmountChange,
  onRemove,
}) => (
  <>
    {renderAccountNumber(
      number,
      accountNumber,
      lenderId,
      onAccountNumberChange,
    )}
    <div className={styles.numberRemoveWrapper}>
      <NumberInput
        label={`Split ${number} amount`}
        decimalScale={2}
        value={settledAmount}
        prefix="$"
        onChange={onSettledAmountChange}
        fullWidth
        allowNegative={false}
      />
      {renderRemove(number, onRemove)}
    </div>
  </>
);

const MemoLoanSplitInput = memo(LoanSplitInput);

export { MemoLoanSplitInput as LoanSplitInput };

LoanSplitInput.defaultProps = {
  onRemove: noop,
  accountNumber: '',
  settledAmount: null,
  onAccountNumberChange: noop,
};

LoanSplitInput.propTypes = {
  number: PropTypes.number.isRequired,
  accountNumber: PropTypes.string,
  lenderId: PropTypes.string.isRequired,
  settledAmount: PropTypes.number,
  onAccountNumberChange: PropTypes.func,
  onSettledAmountChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};
