import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/warning"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12.866,3 L22.392,19.5 C22.5706305,19.8093971 22.5706329,20.1905896 22.3920064,20.499989 C22.2133798,20.8093884 21.8832611,21 21.526,21 L2.474,21 C2.11673895,21 1.78662024,20.8093884 1.60799365,20.499989 C1.42936705,20.1905896 1.42936947,19.8093971 1.608,19.5 L11.134,3 C11.3126394,2.69062372 11.6427526,2.500044 12,2.500044 C12.3572474,2.500044 12.6873606,2.69062372 12.866,3 Z M4.206,19 L19.794,19 L12,5.5 L4.206,19 Z M11,16 L13,16 L13,18 L11,18 L11,16 Z M11,9 L13,9 L13,14 L11,14 L11,9 Z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
