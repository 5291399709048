import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmDeleteNoteModal.module.scss';
import Modal from '../../../../../../../../../shared/components/Modal';
import PrimaryButton from '../../../../../../../../../shared/components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../../../../../../shared/components/Buttons/SecondaryButton';

export const ConfirmDeleteNoteModal = ({
                                     onRequestClose, onConfirmed, isOpen,
                                   }) =>
  (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header={<div className={styles.header}>Delete note</div>}
      className={styles.confirmDeleteModal}
      footer={(
        <div className={styles.buttonsContainer}>
          <PrimaryButton
            onClick={onConfirmed}
            className={styles.deleteButton}
            tabIndex={0}
          >
            Confirm
          </PrimaryButton>
          <SecondaryButton
            onClick={onRequestClose}
            className={styles.cancelButton}
            tabIndex={0}
            autoFocus
          >
            Cancel
          </SecondaryButton>
        </div>
      )}
    >
      <div className={styles.message}>
        <p>
          Are you sure you want to delete this note?
        </p>
        <p>
          Once you confirm, it will be removed permanently and you will not be able to see it again.
        </p>
      </div>
    </Modal>
  );

ConfirmDeleteNoteModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
