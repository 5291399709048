import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';
import Icon from '../Icon/Icon';

export const TooltipSize = {
  Small: 'Small',
  Large: 'Large',
};

const Tooltip = ({
 size, color, tooltipText, iconName, tooltipSize,
}) => (
  <div
    className={classNames('hint', styles[`hint${tooltipSize}`], styles.tooltip)}
    hint={tooltipText}
    style={{
        width: size,
        height: size,
      }}
  >
    <Icon name={iconName} size={size} color={color} />
  </div>
  );

Tooltip.defaultProps = {
  size: '24px',
  color: undefined,
  tooltipText: '',
  iconName: undefined,
  tooltipSize: TooltipSize.Small,
};

Tooltip.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  tooltipText: PropTypes.string,
  iconName: PropTypes.string,
  tooltipSize: PropTypes.oneOf(Object.keys(TooltipSize)),
};

export default Tooltip;
