import React, { useState } from 'react';
import { connect } from 'react-redux';
import HasPermissions from 'shared/components/authorisation/HasPermissions';
import Button from 'shared/components/Buttons/Button';
import PropTypes from 'prop-types';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import {
  reset,
} from 'Loans/components/LoanSplit/redux/loanSplitActions';
import { LoanSplitModal } from './LoanSplitModal';

const SplitButton = ({ isEditing }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <HasPermissions
      permissions={[
        PERMISSIONS.EDIT_ALL_LOANS,
        PERMISSIONS.EDIT_OWN_LOANS,
        PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL,
      ]}
      fallback={null}
    >
      {!isEditing && (
        <>
          <Button onClick={() => setIsOpen(true)}>Split</Button>
          <LoanSplitModal isOpen={isOpen} onClose={closeModal} />
        </>
      )}
    </HasPermissions>
  );
};

SplitButton.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  loans: {
    loan: { data: { accountNumber }, isEditing },
  },
}) => ({
  isEditing,
  accountNumber,
});

const mapDispatchToProps = dispatch => ({
  dispatchReset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplitButton);
