/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Box } from 'shared/components/Box/Box';
import { Timeline } from 'shared/components/Timeline';
import getToggles from 'shared/selectors/togglesSelector';
import { LOG_TYPE } from '../../../../../constants/activityLogTypes';
import styles from './ActivityLog.module.scss';
import ActivityLogFilters from './components/ActivityLogFilters';
import LogItem from './components/LogItem';
import ApplicationTitleActions from '../../../ApplicationTitleActions/AddNote';
import { organizeLogs } from './util';
import { ACTIVITY_LOG_FILTERS, ACTIVITY_LOG_FILTERS_SHORT, FILTERS_MAP } from './constants';

export const getLogTypesByFilterName = filterName =>
  ACTIVITY_LOG_FILTERS.find(item => item.name === filterName).logTypes;

export const filterLogsByLogTypes = (logs, logTypes) => logs.filter(
  log => logTypes.includes(log.activityLogType),
);

const editableTypes = [LOG_TYPE.NOTE];

const renderAdditionalAddNoteButton = () => (
  <Box className={styles.additionalAddNoteButtonBox}>
    <ApplicationTitleActions />
  </Box>
);

const ActivityLogs = ({
  activityLogs,
  hideUselessFilter,
  applicants,
  toggles,
}) => {
  const filters = hideUselessFilter ? ACTIVITY_LOG_FILTERS_SHORT : ACTIVITY_LOG_FILTERS;
  const [filterName, setFilterName] = useState(filters[0].name);
  const [logGroups, setLogGroups] = useState([]);

  useEffect(() => {
    const filteredLogs = filterLogsByLogTypes(activityLogs, getLogTypesByFilterName(filterName));
    setLogGroups(organizeLogs(filteredLogs));
  }, [filterName, activityLogs]);

  const context = useMemo(() => ({ applicants, toggles }), [applicants, toggles]);

  return (
    <div className={styles.activityLog}>
      <h3>Activity Logs</h3>
      <Box className={styles.topBox}>
        <ActivityLogFilters
          selectedFilterName={filterName}
          onSelectChange={setFilterName}
          filters={filters}
        />
      </Box>
      {filterName === FILTERS_MAP.NOTES.name && renderAdditionalAddNoteButton()}
      <div>
        {isEmpty(logGroups)
        ? (
          <Box className={styles.emptyHint}>
            There are no activities to show under this filter.
          </Box>
        ) : (
          logGroups.map(({ actionTime, logs }) => (
            <Timeline date={actionTime} className={styles.logGroup} key={actionTime}>
              {logs.map((log, index) => (
                <LogItem
                  activityLog={log}
                  key={`${log.id}-${index}`}
                  editable={editableTypes.includes(log.activityLogType)}
                  context={context}
                />
              ))}
            </Timeline>
          ))
        )}
      </div>
    </div>
  );
};

ActivityLogs.defaultProps = {
  applicants: {
    persons: [],
    companies: [],
  },
  hideUselessFilter: false,
  toggles: {},
};

ActivityLogs.propTypes = {
  activityLogs: PropTypes.array.isRequired,
  applicants: PropTypes.object,
  hideUselessFilter: PropTypes.bool,
  toggles: PropTypes.object,
};

const mapStateToProps = state => ({
  activityLogs: state.application.activityLogs,
  applicants: state.application.applicationDetail.applicants,
  hideUselessFilter: get(state, 'profile.toggles.HIDE_USELESS_ACTIVITY_LOG_FILTER', false),
  toggles: getToggles(state),
});

export default connect(mapStateToProps)(ActivityLogs);
