import React from 'react';
import LoanCriteria from './LoanCriteria';
import { ProductSearchContext } from '../../ProductSearchContext';

const MultiLoanCriteria = () => (
  <ProductSearchContext.Consumer>
    {context => {
        const { loanCriteriaList } = context;
        return (loanCriteriaList.map((lc, index) => {
          const key = `LOAN-${index}`;
          return (
            <LoanCriteria
              index={index}
              key={key}
              onRemoveClick={context.dispatchRemoveLoanCriteria}
            />
);
        }));
      }}
  </ProductSearchContext.Consumer>
  );

export default MultiLoanCriteria;
