import withReadOnly from '../ReadOnlyAbilityWrapper';
import { Select } from './Select';
import styles from './Select.module.scss';
import { wrapInLabel } from '../NewInputLabelWrapper';
import withFormMeta from '../../FormMeta/withFormMeta';

const SelectWithReadonly = withFormMeta(withReadOnly(wrapInLabel(Select, { error: styles.reactSelectError })));

SelectWithReadonly.defaultProps = {
  readOnlyFormatter: value => (value || {}).label,
};

export default SelectWithReadonly;
