import { createReducer } from '../../../../../utils/reducerGenerator';
import types from '../constants';

const initialState = {
  isHidden: false,
};

export default createReducer(initialState, {
  [types.UPDATE_APPLICATION_ON_SWIMLANE_STATUS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, action) => ({
      isHidden: action.data.isHiddenOnSwimlane,
    }),
});
