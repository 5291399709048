import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import LoanAmountComponent from 'shared/components/productSearch/ProductSearchFields/LoanAmountComponent';
import SecurityValueComponent from 'shared/components/productSearch/ProductSearchFields/SecurityValueComponent';
import LoanValueRatioComponent from 'shared/components/productSearch/ProductSearchFields/LoanValueRatioComponent';
import get from 'lodash/get';
import {
  calculateLoanAmount, calculateSecurityValue,
} from './utils';
import { NEW_LOAN_CONFIG, TOP_UP_CONFIG } from '../../constants';

const getLoanAmountInfo = (props, isTopUp, namePrefix) => {
  const { label, fieldName, totalFieldName } = isTopUp
    ? TOP_UP_CONFIG
    : NEW_LOAN_CONFIG;

  const loanAmountFieldPath = `${namePrefix}.${fieldName}`;
  const {
    [fieldName]: {
      input: {
        value: loanAmount,
        onChange: onLoanAmountChange,
      },
    },
  } = get(props, namePrefix, {});
  const totalLoanAmountForLvr = get(props, `applicationCriteria.${totalFieldName}.input.value`);
  return {
    loanAmountFieldPath,
    loanAmountLabel: label,
    loanAmount,
    onLoanAmountChange,
    totalLoanAmountForLvr,
  };
};

const LoanAmountFields = (props) => {
  const {
    isSplit, isTopUp, namePrefix, formName,
  } = props;
  const {
    loanAmountFieldPath,
    loanAmountLabel,
    loanAmount,
    onLoanAmountChange,
    totalLoanAmountForLvr,
  } = getLoanAmountInfo(props, isTopUp, namePrefix);

  const {
    securityValue: {
      input: {
        value: securityValue,
        onChange: onSecurityValueChange,
        onBlur: onSecurityValueBlur,
      },
      meta: {
        error: securityValueError,
      },
    },
    loanValueRatio: {
      input: {
        value: lvr,
        onChange: onLVRChange,
      },
    },
  } = get(props, 'applicationCriteria', {});

  const handleOnSecurityValueChange = useCallback(
    (v) => onSecurityValueChange(v === undefined ? null : v),
    [onSecurityValueChange],
  );

  const handleOnLoanAmountChange = useCallback(
    (v) => onLoanAmountChange(v === undefined ? null : v),
    [onLoanAmountChange],
  );

  const handleOnLVRChange = useCallback(
    (v) => onLVRChange(v === undefined ? null : v),
    [onLVRChange],
  );

  const {
    meta: {
      error: loanAmountError,
    },
  } = get(props, loanAmountFieldPath, {});

  const dispatch = useDispatch();
  const changeFieldValue = useCallback(
    (field, value) => dispatch(change(formName, field, value === undefined ? null : value)),
    [formName, dispatch],
  );

  const onLoanAmountBlur = useCallback(() => {
    if (!isNumber(loanAmount)) {
      return;
    }
    if (!isNumber(securityValue) && isNumber(lvr)) {
      const calculatedSecurityValue = calculateSecurityValue(totalLoanAmountForLvr, lvr);
      changeFieldValue('applicationCriteria.securityValue', calculatedSecurityValue);
    }
  }, [loanAmount, totalLoanAmountForLvr, securityValue, lvr, changeFieldValue]);

  const handleSecurityValueBlur = useCallback(() => {
    onSecurityValueBlur();
    if (!isNumber(securityValue)) {
      return;
    }
    if (!isNumber(totalLoanAmountForLvr) && isNumber(lvr) && !isSplit) {
      const calculatedLoanAmount = calculateLoanAmount(securityValue, lvr);
      changeFieldValue(loanAmountFieldPath, calculatedLoanAmount);
    }
  }, [onSecurityValueBlur, securityValue, totalLoanAmountForLvr, lvr, isSplit, changeFieldValue, loanAmountFieldPath]);

  const onLVRBlur = useCallback(() => {
    if (!isNumber(lvr)) {
      return;
    }
    if (isNumber(totalLoanAmountForLvr)) {
      const calculatedSecurityValue = calculateSecurityValue(totalLoanAmountForLvr, lvr);
      changeFieldValue('applicationCriteria.securityValue', calculatedSecurityValue);
    } else if (isNumber(securityValue) && !isSplit) {
      const calculatedLoanAmount = calculateLoanAmount(securityValue, lvr);
      changeFieldValue(loanAmountFieldPath, calculatedLoanAmount);
    }
  }, [lvr, totalLoanAmountForLvr, securityValue, isSplit, changeFieldValue, loanAmountFieldPath]);

  return (
    <>
      <LoanAmountComponent
        value={loanAmount}
        onChange={handleOnLoanAmountChange}
        onBlur={onLoanAmountBlur}
        label={loanAmountLabel}
        errorMessage={loanAmountError}
      />
      {!isSplit && (
        <>
          <SecurityValueComponent
            value={securityValue}
            onChange={handleOnSecurityValueChange}
            onBlur={handleSecurityValueBlur}
            errorMessage={securityValueError}
          />
          <LoanValueRatioComponent
            value={lvr}
            onChange={handleOnLVRChange}
            onBlur={onLVRBlur}
          />
        </>
      )}
    </>
  );
};

LoanAmountFields.propTypes = {
  formName: PropTypes.string.isRequired,
  isSplit: PropTypes.bool.isRequired,
  isTopUp: PropTypes.bool.isRequired,
  namePrefix: PropTypes.string.isRequired,
};

export default LoanAmountFields;
