import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './LoadingWithOverlay.module.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const LoadingWithOverlay = ({ className, isLoading }) => (
  <div className={classNames(styles.overlay, { [styles.show]: isLoading }, className)}>
    <LoadingSpinner />
  </div>
);

LoadingWithOverlay.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

LoadingWithOverlay.defaultProps = {
  className: '',
  isLoading: false,
};

export default LoadingWithOverlay;
