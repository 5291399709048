import { combineReducers } from 'redux';

import { reducer as reduxFromReducer } from 'redux-form';
import dashboard from 'Dashboard/redux/reducer';
import myBusiness from 'MyBusiness/redux/myBusinessReducer';
import loans from 'Loans/redux/loansReducer';
import loanCategories from 'LoanCategories/redux/categoriesRedux';
import { reducer as reports } from 'pages/ReportsPage/redux';
import { application, applications } from 'ApplicationTracking/redux';
import loginReducer from './login';
import profile from './profile';
import banner from './banner';
import toast from './toast/toastReducer';
import tracking from './tracking';
import business from './business';
import lendersConfig from './lendersConfig';
import lenders from './lender';
import requestIds from './requestIds';
import productSearch from '../ProductSearch/redux';
import allApplications from '../AllApplications/redux/reducer';
import notification from './notification';

export default combineReducers({
  dashboard,
  myBusiness,
  reports,
  banner,
  login: loginReducer,
  profile,
  tracking,
  business,
  applications,
  toast,
  loans,
  loanCategories,
  application,
  lendersConfig,
  lenders,
  requestIds,
  productSearch,
  form: reduxFromReducer,
  allApplications,
  notification,
});
