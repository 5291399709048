import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { isStart } from 'utils/actionStatusUtil';
import styles from './TaskList.module.scss';
import { changeTaskStatus as changeTaskStatusAction, createTask } from '../../../../redux';
import Task from './Task';

const shouldButtonDisabled = newTaskName => isEmpty(newTaskName.trim());

export const TaskList = ({
 taskList, taskListStatus, applicationId, businessId, addTask, changeTaskStatus,
}) => {
  const [newTaskName, setNewTaskName] = useState('');

  const onAddTask = () => {
    if (isEmpty(newTaskName)) return;
    addTask({
      ...taskList, applicationId, businessId, taskResource: { applicationId, taskName: newTaskName },
    });
    setNewTaskName('');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onAddTask();
    }
  };

  const onChange = e => setNewTaskName(e.target.value);

  const isLoading = ({ status, taskListId }) => isStart(status) && taskListId === taskList.id;

  const tasks = get(taskList, 'tasks', []);

  return (
    <div className={styles.taskList}>
      <div className={styles.taskListTitle}>{taskList.name}</div>
      <div className={styles.taskCreateForm}>
        <div className={styles.formItem}>
          <input
            placeholder="Add new task"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={newTaskName}
          />
        </div>
        <PrimaryButton
          loading={isLoading(taskListStatus)}
          disabled={shouldButtonDisabled(newTaskName) || isLoading(taskListStatus)}
          onClick={onAddTask}
        >
          Add
        </PrimaryButton>
      </div>
      <ul>
        {tasks.map(task => (
          <Task
            task={task}
            key={task.id}
            applicationId={applicationId}
            onChangeStatus={changeTaskStatus}
          />
        ))}
      </ul>
    </div>
  );
};

TaskList.propTypes = {
  taskList: PropTypes.object.isRequired,
  taskListStatus: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  addTask: PropTypes.func.isRequired,
  changeTaskStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  applicationId: get(state, 'application.applicationDetail.id'),
  businessId: get(state, 'business.selectedBusiness.id'),
  taskListStatus: get(state, 'application.tasksInfo.taskListStatus'),
});

export default connect(mapStateToProps, { addTask: createTask, changeTaskStatus: changeTaskStatusAction })(TaskList);
