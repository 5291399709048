import React from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';

export default React.memo(() => (
  <Plugin>
    <Template name="tableRow">
      {({ tableRow, ...restParams }) => (
        <TemplateConnector>
          {({ rows }) => {
            const rowIndex = rows.indexOf(tableRow.row);
            const params = rowIndex > -1 ? { rowIndex, tableRow, ...restParams } : { tableRow, ...restParams };
            return (
              <TemplatePlaceholder
                params={params}
              />
            );
          }}
        </TemplateConnector>
      )}
    </Template>
  </Plugin>
));
