import { connect } from 'react-redux';
import {
  retrieveAdvisers,
  getLoans,
  updateAllianzInsurance,
} from '../../../../redux';
import GeneralInsuranceReferral from './GeneralInsuranceReferral';

const mapStateToProps = state => ({
  advisers: state.application.insuranceReferral.generalInsurance.advisers,
  writer: state.application.applicationDetail.writer,
  applicants: state.application.applicationDetail.applicants.persons,
  loans: state.application.applicationLoans,
  applicationId: state.application.applicationDetail.id,
});

const mapDispatchToProps = {
  retrieveAdvisers,
  getLoans,
  updateAllianzInsurance,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInsuranceReferral);
