import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Fields } from 'redux-form';
import { useSelector } from 'react-redux';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import { LendersSelectField, TotalLoanAmountFields } from './Field';
import styles from './styles.module.scss';
import { getApplicationType } from '../../../redux/selectors/productSearch';
import ApplicationTypeField from './Field/ApplicationTypeField';
import LenderSelectField from './Field/LenderSelectField';

const ApplicationCriteria = ({ formName }) => {
  const applicationType = useSelector(getApplicationType);
  const isTopUp = applicationType === APPLICATION_TYPES.TOP_UP;
  return (
    <div className={classNames(styles.criteriaSection, styles.applicationCriteria)}>
      <div className={classNames(styles.criteriaHeader, styles.criteriaTitle)}>
        Application criteria
      </div>
      <div className={styles.criteriaBody}>
        <div className={styles.firstSection}>
          <div className={styles.criteriaWrapper}>
            <ApplicationTypeField formName={formName} />
            {isTopUp && (
              <Field
                name="applicationCriteria.totalLoanAmount"
                component={ReduxFormNumberInput}
                label="Total loan amount ($)"
                disabled
                className={styles.criteriaNumberField}
              />
            )}
          </div>
          <div className={isTopUp ? styles.criteriaWrapperAddInputMarginTop : styles.criteriaWrapper}>
            <Fields
              names={[
                'applicationCriteria.totalLoanAmount',
                'applicationCriteria.totalCombinedLoanAmount',
                'applicationCriteria.securityValue',
                'applicationCriteria.loanValueRatio',
              ]}
              format={null}
              component={TotalLoanAmountFields}
              formName={formName}
              isTopUp={isTopUp}
            />
          </div>
          <div>
            {isTopUp
              ? (<LenderSelectField name="applicationCriteria.selectedLenderNames[0]" />)
              : (
                <Field
                  name="applicationCriteria.selectedLenderNames"
                  component={LendersSelectField}
                  format={null}
                />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicationCriteria.propTypes = {
  formName: PropTypes.string.isRequired,
};

export default ApplicationCriteria;
