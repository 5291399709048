import { FONTS, PALETTE } from 'shared/theme/depricatedStyles';

export default {
  editingContentContainer: { padding: '2rem', position: 'relative', borderBottom: `1px solid ${PALETTE.border}` },
  editingContentTitle: {
    display: 'inline', width: 'auto', fontSize: FONTS.size.regular, fontFamily: FONTS.standardBold,
  },
  crossButton: {
    border: 'none',
    padding: 0,
    float: 'right',
    fontSize: FONTS.size.regular,
    background: 'transparent',
    fontFamily: FONTS.standardBold,
  },
  editingFieldSection: { padding: '2rem', fontSize: FONTS.size.regular },
  editingFieldLabel: { minWidth: '12.6rem' },
  suffixFieldUnit: { display: 'inline-block', marginLeft: '.5rem' },
  prefixUnit: { display: 'inline-block', marginRight: '.5rem' },
  editingButtonsSection: { padding: '2rem', position: 'relative', borderTop: `1px solid ${PALETTE.border}` },
  applyButton: {
    minWidth: '12.6rem',
    width: '12.6rem',
    fontSize: FONTS.size.regular,
    verticalAlign: 'middle',
  },
  inputContainer: {
    display: 'inline-block',
    marginLeft: '2rem',
  },
  loading: {
    display: 'inline-block',
    padding: 0,
    width: '12.6rem',
    height: '4rem',
    image: {
      margin: '1.3rem auto',
      verticalAlign: 'middle',
      width: FONTS.size.large,
      height: FONTS.size.large,
    },
  },
  inputStyle: {
    display: 'inline-block',
    width: '8.8rem',
    margin: 0,
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.7rem',
  },
  upfrontTotal: {
    paddingTop: '.8rem',
    paddingRight: '.7rem',
    fontFamily: FONTS.standardBold,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  editModalStyle: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      maxWidth: '31.6rem',
      minWidth: '28.5rem',
      padding: 0,
    },
    overlay: {
      backgroundColor: 'rgba(135,135,135,0.35)',
      zIndex: 500,
    },
  },
  errorMessage: {
    color: PALETTE.error,
    fontSize: FONTS.size.regular,
    marginTop: 0,
  },
};
