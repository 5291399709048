import React from 'react';
import PropTypes from 'prop-types';
import { FETCHING_STATUS } from '../../../../constants';
import LoadingSpinner from '../../../../shared/components/LoadingSpinner/LoadingSpinner';
import ConversionRate from './ConversionRate/ConversionRate';

export const ConversionReport = ({ fetchingStatus, conversionRateItems, businessName }) => {
  const isFetchingSuccess = fetchingStatus === FETCHING_STATUS.SUCCESS && businessName !== '';
  return (
    <>
      {!isFetchingSuccess && <LoadingSpinner />}
      {isFetchingSuccess && (
        <div>
          <h3>Conversion</h3>
          <ConversionRate conversionRateItems={conversionRateItems} businessName={businessName} />
        </div>
      )}
    </>
  );
};

ConversionReport.defaultProps = {
  conversionRateItems: [],
  businessName: '',
};

ConversionReport.propTypes = {
  fetchingStatus: PropTypes.number.isRequired,
  conversionRateItems: PropTypes.array,
  businessName: PropTypes.string,
};
