import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import fonts from 'shared/theme/fonts.scss';

const ExternalLink = ({
  children,
  to,
  style,
  onClick,
  className,
  ...others
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <a
      style={{
        fontFamily: fonts.fontbody,
        textDecoration: 'none',
        color: themeContext.palette.linkPrimary,
        ...style,
      }}
      className={className}
      href={to}
      target="_blank"
      onClick={onClick}
      rel="noopener noreferrer"
      {...others}
    >
      {children}
    </a>
  );
};

ExternalLink.defaultProps = {
  children: null,
  style: {},
  to: null,
  onClick: () => null,
  className: undefined,
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ExternalLink;
