import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import trim from 'lodash/trim';
import debounce from 'lodash/debounce';
import Autocomplete from 'shared/components/formFields/Autocomplete';
import MiniSpinner from 'shared/components/LoadingSpinner/MiniSpinner';
import getAddressSuggestions from './request';
import { getDisplayAddress } from '../../../../../utils/address';
import { ADDRESS_TYPE } from './constants';
import AddressModal from './AddressModal';
import AddressLabel from './AddressLabel';

const defaultValue = {
  addressType: ADDRESS_TYPE.STANDARD,
  countryCode: 'AU',
};

const generateInitialValues = currentValue => ({
  ...defaultValue,
  ...currentValue,
});

const toOption = address => ({
  label: getDisplayAddress(address),
  value: address,
});

const keyExtract = value => value.addressId;
const shouldItemRender = () => true;

const AddressInput = ({
                            value: address,
                            onChange,
                            className,
                            label,
                          }) => {
  const [isOpen, setIsOpen] = useState(false);
  const initialDisplayAddress = getDisplayAddress(address);
  const [displayAddress, setDisplayAddress] = useState(initialDisplayAddress);
  const [state, setState] = useState({
    query: null,
    options: [],
    loading: false,
  });
  const handleEnterManuallyButtonClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    setDisplayAddress(initialDisplayAddress);
  }, [initialDisplayAddress]);

  const handleApply = useCallback((v) => {
    setIsOpen(false);
    if (!isEqual(v, generateInitialValues(address))) {
      const { addressId, ...rest } = v;
      onChange(rest);
    }
  }, [address, onChange]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const getSuggestions = useMemo(() => debounce(async value => {
    const addresses = await getAddressSuggestions(value);
    setState(prevState => {
      const shouldUpdate = prevState.query === value;
      return ({
        query: shouldUpdate ? value : prevState.query,
        options: shouldUpdate ? addresses.map(toOption) : prevState.options,
        loading: false,
      });
    });
  }, 500), [setState]);

  const handleInputChange = useCallback((event, value) => {
    setDisplayAddress(value);
    const trimmedValue = trim(value);
    if (isEmpty(trimmedValue)) {
      setState({ query: trimmedValue, options: [], loading: false });
    } else if (trimmedValue !== state.query) {
      setState({ query: trimmedValue, options: [], loading: true });
      getSuggestions(trimmedValue);
    }
  }, [getSuggestions, state]);

  const handleSelect = useCallback((display, { value }) => {
    setDisplayAddress(getDisplayAddress(value));
    return onChange(value);
  }, [onChange]);

  const handleBlur = useCallback((event) => {
    if (isEmpty(event.target.value)) {
      onChange(null);
    } else {
      setDisplayAddress(initialDisplayAddress);
    }
    if (!address) {
      setState({ query: null, options: [], loading: false });
    }
  }, [initialDisplayAddress, onChange, setState, address]);

  return (
    <>
      <Autocomplete
        value={displayAddress}
        label={<AddressLabel onClick={handleEnterManuallyButtonClick} name={label} />}
        className={className}
        onChange={handleInputChange}
        onSelect={handleSelect}
        onBlur={handleBlur}
        items={state.options}
        keyExtract={keyExtract}
        shouldItemRender={shouldItemRender}
        noOptionsMessage="No options"
        loading={state.loading}
        loadingMessage={<MiniSpinner text="Loading" />}
      />
      {isOpen && (
        <AddressModal
          initialValues={generateInitialValues(address)}
          onSubmit={handleApply}
          onRequestClose={handleClose}
          isOpen
        />
      )}
    </>
  );
};

AddressInput.defaultProps = {
  className: '',
};

AddressInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default AddressInput;
