import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import PlainTextField from '../PlainTextField/PlainTextField';

const withReadyOnlyAbility = Component => {
  const HOC = (props) => {
    const {
      readOnlyFormatter,
      customOverride,
      ...others
    } = props;

    const plainTextFiledRender = (formattedValue) => (
      <PlainTextField
        label={label}
        value={formattedValue}
        className={className}
        textClassName={textClassName}
        style={style}
      />
    );

    const customRender = (formattedValue) => (<>{formattedValue}</>);

    const {
      readOnly,
      label,
      className,
      textClassName,
      style,
      value,
    } = others;
    if (readOnly) {
      const formattedValue = isFunction(readOnlyFormatter) ? readOnlyFormatter(value) : value;
      return customOverride ? customRender(readOnlyFormatter(others)) : plainTextFiledRender(formattedValue);
    }
    return <Component {...others} />;
  };
  HOC.propTypes = {
    readOnlyFormatter: PropTypes.func,
    customOverride: PropTypes.bool,
  };
  HOC.defaultProps = {
    readOnlyFormatter: undefined,
    customOverride: false,
  };
  HOC.displayName = `withReadyOnlyAbility(${Component.displayName || Component.name || 'component'})`;
  return HOC;
};

export default withReadyOnlyAbility;
