import React from 'react';
import PropTypes from 'prop-types';
import RealEstateAsset from '../../shared/RealEstateAsset';
import styles from './RealEstate.module.scss';
import TertiaryButton from '../../../../../../shared/components/Buttons/TertiaryButton';

const RealEstate = (props) => {
  const {
    editable, handleRemove,
  } = props;
  return (
    <div className={styles.container}>
      <RealEstateAsset
        {...props}
        valueLabel="Value"
        ownershipRowSpan={4}
        valueEditable={editable}
      />
      {editable && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
    </div>
  );
};

RealEstate.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default RealEstate;
