import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { calculateTotalOtherCosts } from '../../../redux/selectors/fundingWorksheet/utils';
import SubTotalSection from '../components/SubTotalSection';
import { fundingWorksheetFormValueSelector } from '../../../redux/selectors/fundingWorksheet';

export const SubtotalOtherCosts = ({ otherCosts, title }) => (
  <SubTotalSection
    total={calculateTotalOtherCosts(otherCosts)}
    title={title}
  />
);

SubtotalOtherCosts.defaultProps = {
  otherCosts: [],
  title: '',
};

SubtotalOtherCosts.propTypes = {
  otherCosts: PropTypes.arrayOf(PropTypes.shape({
    fieldName: PropTypes.string,
    fieldAmount: PropTypes.number,
  })),
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  otherCosts: fundingWorksheetFormValueSelector(state, 'otherCosts'),
});

export default connect(mapStateToProps)(SubtotalOtherCosts);
