import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import computeLoanCriteria, { calculateLoanAmount } from './computeLoanCriteria';
import { ACTIONS } from './applicationCriteriaActions';
import { updateLoanAmount as updateLoanAmountAction } from '../loanCriteriaList';

const updateTotalLoanAmount = (totalLoanAmount) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_TOTAL_LOAN_AMOUNT, totalLoanAmount });
};

export const updateLoanAmount = (loanAmount, index) => (dispatch, getState) => {
  const { loanCriteriaList } = getState().productSearch.standAlone;
  if (loanAmount !== loanCriteriaList[index]) {
    dispatch(updateLoanAmountAction(loanAmount, index));

    const {
      loanCriteriaList: updatedLoanCriteriaList,
      applicationCriteria: { securityValue, loanValueRatio },
    } = getState().productSearch.standAlone;
    const totalLoanAmount = calculateTotalLoanAmount(updatedLoanCriteriaList);

    dispatch(updateTotalLoanAmount(totalLoanAmount));
    if (securityValue) {
      dispatch({
        type: ACTIONS.UPDATE_CRITERIA,
        newCriteria: computeLoanCriteria({
          totalLoanAmount,
          securityValue,
          loanValueRatio,
          changedField: 'totalLoanAmount',
          loanCriteriaList,
        }),
      });
    }
  }
};

export const updateSecurityValue = (securityValue) => (dispatch, getState) => {
  const productSearchState = getState().productSearch.standAlone;

  if (securityValue !== productSearchState.applicationCriteria.securityValue) {
    dispatch({ type: ACTIONS.SET_SECURITY_VALUE, securityValue });

    const { loanCriteriaList, applicationCriteria: { totalLoanAmount, loanValueRatio } } = productSearchState;

    if (securityValue || loanValueRatio) {
      dispatch({
        type: ACTIONS.UPDATE_CRITERIA,
        newCriteria: computeLoanCriteria({
          totalLoanAmount,
          securityValue,
          loanValueRatio,
          changedField: 'securityValue',
          loanCriteriaList,
        }),
      });
    }
  }
};

export const updateLoanValueRatio = (loanValueRatio) => (dispatch) => {
  dispatch({ type: ACTIONS.SET_LOAN_VALUE_RATIO, loanValueRatio });
};

const recalculateFields = props => (dispatch) => {
  const { changedField, loanCriteriaList } = props;
  if (changedField && !props[changedField]) {
    return;
  }

  const shouldCalculateLoanAmount = props.securityValue
    && props.loanValueRatio
    && loanCriteriaList.length === 1
    && !loanCriteriaList[0].loanAmount;

  if (shouldCalculateLoanAmount) {
    const calculatedLoanAmount = calculateLoanAmount({
      securityValue: props.securityValue,
      loanValueRatio: props.loanValueRatio,
    });
    dispatch(updateTotalLoanAmount(calculatedLoanAmount));
    dispatch(updateLoanAmountAction(calculatedLoanAmount, 0));
    return;
  }

  if (props.securityValue || props.loanValueRatio) {
    dispatch({
      type: ACTIONS.UPDATE_CRITERIA,
      newCriteria: computeLoanCriteria(props),
    });
  }
};

const calculateTotalLoanAmount = (loanCriteriaList) => {
  if (loanCriteriaList.every(criteria => criteria.loanAmount)) {
    return sumBy(loanCriteriaList, criteria => criteria.loanAmount);
  }
  return undefined;
};

export const onLoanAmountBlur = () => (dispatch, getState) => {
  const productSearchState = getState().productSearch.standAlone;
  const { securityValue, loanValueRatio } = productSearchState.applicationCriteria;
  const { loanCriteriaList } = productSearchState;
  const totalLoanAmount = calculateTotalLoanAmount(loanCriteriaList);
  dispatch(updateTotalLoanAmount(totalLoanAmount));
  dispatch(recalculateFields({
    totalLoanAmount,
    securityValue,
    loanValueRatio,
    changedField: 'totalLoanAmount',
    loanCriteriaList,
  }));
};

export const onRemoveCriteria = () => (dispatch, getState) => {
  const productSearchState = getState().productSearch.standAlone;
  const { securityValue, loanValueRatio } = productSearchState.applicationCriteria;
  const { loanCriteriaList } = productSearchState;
  const totalLoanAmount = calculateTotalLoanAmount(loanCriteriaList);
  dispatch(updateTotalLoanAmount(totalLoanAmount));
  dispatch(recalculateFields({
    totalLoanAmount,
    securityValue,
    loanValueRatio,
    loanCriteriaList,
  }));
};

export const onSecurityValueBlur = () => (dispatch, getState) => {
  const productSearchState = getState().productSearch.standAlone;
  const { totalLoanAmount, securityValue, loanValueRatio } = productSearchState.applicationCriteria;
  const { loanCriteriaList } = productSearchState;
  dispatch(recalculateFields({
    totalLoanAmount,
    securityValue,
    loanValueRatio,
    changedField: 'securityValue',
    loanCriteriaList,
  }));
};

export const onLoanValueRatioBlur = () => (dispatch, getState) => {
  const productSearchState = getState().productSearch.standAlone;
  const { loanCriteriaList } = productSearchState;
  const { totalLoanAmount, securityValue, loanValueRatio } = productSearchState.applicationCriteria;
  dispatch(
    recalculateFields({
      totalLoanAmount,
      securityValue,
      loanValueRatio,
      changedField: 'loanValueRatio',
      loanCriteriaList,
    }),
  );
};

export const updateSelectedLenderNames = (selectedLenderNames) => ({
  type: ACTIONS.UPDATE_SELECTED_LENDER_NAMES,
  selectedLenderNames,
});

export const modifyCriteria = () => ({ type: ACTIONS.MODIFY_CRITERIA });
export const submitModifiedCriteria = () => ({ type: ACTIONS.SUBMIT_MODIFIED_CRITERIA });

const isEqualWithDecimalPlaces = (v1, v2, n) => {
  const v1FixN = round(Number(v1), n).toFixed(n);
  const v2FixN = round(Number(v2), n).toFixed(n);
  return v1FixN === v2FixN;
};

export const processAddLoanCriteria = state => ({
  ...state,
  totalLoanAmount: undefined,
});

export const processUpdateCriteria = (state, action) => ({
  ...state,
  errorMessage: {
    ...state.errorMessage,
    securityValue: undefined,
  },
  ...action.newCriteria,
});

export const processSetTotalLoanAmount = (state, action) => ({
  ...state,
  totalLoanAmount: action.totalLoanAmount,
});

export const processSetSecurityValue = (state, action) => ({
  ...state,
  securityValue: action.securityValue,
  errorMessage: {
    ...state.errorMessage,
    securityValue: undefined,
  },
});

export const processSetLoanValueRatio = (action, state) => {
  const { loanValueRatio } = action;
  if (isEqualWithDecimalPlaces(state.loanValueRatio, loanValueRatio, 2)) {
    return state;
  }
  return {
    ...state,
    loanValueRatio,
  };
};

export const processUpdateSelectedLenderNames = (state, action) => ({
  ...state,
  selectedLenderNames: action.selectedLenderNames,
});

export const processModifyCriteria = state => ({
  ...state,
  modifySearch: true,
});

export const processSubmitModifiedCriteria = state => ({
  ...state,
  modifySearch: false,
});

export const processResetSearchCriteria = action => ({ ...action.searchCriteria.applicationCriteria });

export const initialState = {
  totalLoanAmount: undefined,
  securityValue: undefined,
  loanValueRatio: undefined,
  errorMessage: {},
  modifySearch: true,
};
