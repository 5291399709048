import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

const defaultUnitMap = {
  1: 'K',
  2: 'M',
};

// i.e 5,000,000 => 5M, 500,000 => 500k
export const formatAxisLabel = (text, unitMap = defaultUnitMap) => {
  const parts = text.split(',');
  const replaceBoundary = findIndex(parts.reverse(), (part, i) => part !== '000' || !((i + 1) in unitMap));
  const suffix = get(unitMap, [replaceBoundary], '');
  return `${parts.reverse().slice(0, parts.length - replaceBoundary).join(',')}${suffix}`;
};
