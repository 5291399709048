import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import Modal from 'shared/components/Modal';
import styles from './TaskListTemplate.module.scss';

const DeleteConfirmationModal = ({
 isOpen, handleClose, handleDelete, templateId,
}) => (
  <Modal
    isOpen={isOpen}
    header={<div className={styles.modalHeader}>Delete template</div>}
    onRequestClose={handleClose}
    className={styles.deleteConfirmation}
    footer={(
      <div className={styles.confirmationButton}>
        <PrimaryButton onClick={() => handleDelete(templateId)}>
          Delete
        </PrimaryButton>
      </div>
    )}
  >
    <div>
      <div className={styles.confirmationInfo}>
        Are you sure you want to delete this template?
        <br />
        Deleting a template cannot be undone.
      </div>
    </div>
  </Modal>
  );

DeleteConfirmationModal.propTypes = {
  templateId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
