import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import classNames from 'classnames/bind';
import {
 change, Field, FieldArray, Fields, getFormValues,
} from 'redux-form';
import ReduxFormRadioGroup from 'shared/components/formFields/ReduxForm/RadioGroup';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import FormMetaContext from 'shared/components/FormMeta/FormMetaContext';
import find from 'lodash/find';
import map from 'lodash/map';
import Changes from './Changes';
import styles from './styles.module.scss';
import { containOther } from '../utils';
import { REPAYMENT_METHOD_OPTION } from '../constants';
import createOtherDetailValidator from '../validator/validateOtherDetails';

const cx = classNames.bind(styles);
const YesOrNo = ({
 title, className, ...others
}) => (
  <div className={cx(className, styles.yesOrNo)}>
    <div>{title}</div>
    <div>
      <ReduxFormRadioGroup
        className={styles.yesOrNoSelect}
        {...others}
        options={[
          {
            label: 'Yes',
            value: 'true',
          }, {
            label: 'No',
            value: 'false',
          },
        ]}
      />
    </div>
  </div>
);

YesOrNo.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
};

YesOrNo.defaultProps = {
  className: '',
};

const valueRender = value => (value?.length > 1 ? 'Multiple' : find(REPAYMENT_METHOD_OPTION, item => item.id === value[0])?.name);

const RepaymentMethodSection = props => {
  const { namePrefix, ...others } = props;
  const fields = get(others, `${namePrefix}.retirementPlan`, {});
  const { repaymentMethods, repaymentMethodOtherDetail } = fields;
  return (
    <>
      <ReduxFormCheckboxDropdown
        name={`${namePrefix}.retirementPlan.repaymentMethods`}
        label="How would they propose to repay the loan?"
        className={styles.repaymentMethodField}
        options={REPAYMENT_METHOD_OPTION}
        inputProps={{ className: styles.repaymentMethodInput }}
        {...repaymentMethods}
        onChange={(v) => {
          repaymentMethods.input.onChange(v);
          if (!containOther(v)) {
            repaymentMethodOtherDetail.input.onChange('');
          }
        }}
        valueRender={valueRender}
        readOnlyFormatter={value => {
          const values = map(value, id => find(REPAYMENT_METHOD_OPTION, { id }));
          return (
            <div className={styles.readOnlyOptions}>
              {values.map(({ name, id }) => (
                <div key={id}>{name}</div>
              ))}
            </div>
          );
        }}
        buttonText="Select"
        flipToggle
      />
      {containOther(repaymentMethods.input.value) && (
        <ReduxFormTextArea
          isRequired
          label="Other detail"
          name={`${namePrefix}.retirementPlan.repaymentMethodOtherDetail`}
          rows="1"
          {...repaymentMethodOtherDetail}
          className={styles.repaymentMethodOtherDetailField}
        />
      )}
    </>
  );
};

RepaymentMethodSection.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

const FutureCircumstance = ({ namePrefix, applicantName }) => {
  const { readOnly } = useContext(FormMetaContext);
  const anyChangeValue = useSelector(state => get(getFormValues('needsAndObjectivesForm')(state), `${namePrefix}.adverseChanges.anyChange`, null));
  const retireDuringLoanTermValue = useSelector(state => get(getFormValues('needsAndObjectivesForm')(state), `${namePrefix}.retirementPlan.retireDuringLoanTerm`, null));

  const dispatch = useDispatch();

  const validator = useMemo(() => createOtherDetailValidator({
    selectField: `${namePrefix}.retirementPlan.repaymentMethods`,
    otherDetailField: `${namePrefix}.retirementPlan.repaymentMethodOtherDetail`,
  }), [namePrefix]);

  return (
    <InformationSection title={applicantName}>
      <div className={cx(styles.container, { [styles.readOnlyContainer]: readOnly })}>
        <div className={styles.sectionTitle}>
          Adverse changes
        </div>
        <Field
          name={`${namePrefix}.adverseChanges.anyChange`}
          component={YesOrNo}
          title="Do they plan or anticipate changes to their future financial circumstances that might impact ability to repay the loan?"
          className={styles.isPlannedField}
          onChange={(e) => {
            const significantChanges = e.target.value === 'false'
            ? []
            : [{
                type: null,
                amount: null,
                periodOfImpact: null,
                startDate: null,
                repaymentPlans: null,
                repaymentPlanOtherDetail: null,
              }];
            dispatch(change('needsAndObjectivesForm', `${namePrefix}.adverseChanges.significantChanges`, significantChanges));
          }}
        />
        {anyChangeValue === 'true' && (
          <FieldArray
            name={`${namePrefix}.adverseChanges.significantChanges`}
            component={Changes}
          />
        )}
        <div className={styles.sectionTitle}>
          Retirement planning
        </div>
        <Field
          name={`${namePrefix}.retirementPlan.matureAge`}
          component={ReduxFormNumberInput}
          type="text"
          label="Planned retirement age"
          formatter="##"
          readOnlyFormatter={value => value}
          className={styles.matureAgeField}
          isAllowed={value => value === undefined || value > 0}
        />
        <Field
          name={`${namePrefix}.retirementPlan.retireDuringLoanTerm`}
          component={YesOrNo}
          title={`Will ${applicantName} reach their planned retirement age during the loan term?`}
          className={styles.retireDuringLoanTermField}
          onChange={(e) => {
            if (e.target.value === 'false') {
              dispatch(change('needsAndObjectivesForm', `${namePrefix}.retirementPlan.repaymentMethods`, []));
              dispatch(change('needsAndObjectivesForm', `${namePrefix}.retirementPlan.repaymentMethodOtherDetail`, ''));
            }
          }}
        />
        {retireDuringLoanTermValue === 'true' && (
          <Fields
            names={[`${namePrefix}.retirementPlan.repaymentMethods`,
              `${namePrefix}.retirementPlan.repaymentMethodOtherDetail`]}
            namePrefix={namePrefix}
            component={RepaymentMethodSection}
            validate={validator}
          />
        )}
      </div>
    </InformationSection>
  );
};

FutureCircumstance.propTypes = {
  applicantName: PropTypes.string.isRequired,
  namePrefix: PropTypes.string.isRequired,
};

export default FutureCircumstance;
