import React from 'react';
import { Field, FieldArray } from 'redux-form';
import classNames from 'classnames';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import PropTypes from 'prop-types';
import ReduxFormFieldArrayTupleInput, { validateValues } from '../components/FieldArrayTupleInput';
import styles from '../styles.module.scss';
import SubSection from '../components/SubSection';

const OtherCosts = ({ costToPurchasePath }) => (
  <SubSection
    header="Other costs"
    className={classNames(styles.costToPurchaseSubSection, styles.otherCostsSubSection)}
    headerClassName={styles.subSectionHeader}
  >
    <Field
      name={`${costToPurchasePath}.inspectionFee`}
      component={ReduxFormNumberInput}
      label="Building/pest inspection"
      placeholder="$"
      className={styles.sideBySideTextInput}
      decimalScale={2}
      prefix="$"
      fullWidth
      allowNegative={false}
    />
    <Field
      name={`${costToPurchasePath}.adjustmentOfRates`}
      component={ReduxFormNumberInput}
      label="Adjustment of rates"
      placeholder="$"
      className={styles.sideBySideTextInput}
      decimalScale={2}
      prefix="$"
      fullWidth
      allowNegative={false}
    />
    <section className={styles.otherCostsWrapper}>
      <FieldArray
        name={`${costToPurchasePath}.otherCosts`}
        component={ReduxFormFieldArrayTupleInput}
        validate={validateValues}
        descriptionLabel="Other cost"
        descriptionPlaceholder="description"
        allowNegative={false}
      />
    </section>
  </SubSection>
);

OtherCosts.propTypes = {
  costToPurchasePath: PropTypes.string.isRequired,
};

export default OtherCosts;
