import { changeDetected, getTargetChangedValue, isPropertyChanged } from '../../../../../../constants/utils';
import { TYPES } from '../../../../../../constants/activityLogTypes';
import { getBasicLogInfo } from './utils';

const allianzInsuranceChangeLogTransformer = (origin) => {
  const hasAllianzReferralInInsuranceReferrals = changeDetected(origin, 'insuranceReferrals.allianzReferral.*');
  const pathPrefix = hasAllianzReferralInInsuranceReferrals ? 'insuranceReferrals.allianzReferral' : 'allianzReferral';
  const date = getTargetChangedValue(origin, `${pathPrefix}.allianzPromptForReferralDate`);
  const allianzOptOutChangedValue = getTargetChangedValue(origin, `${pathPrefix}.allianzOptOut`);
  const isOptOutChangeToTrue = isPropertyChanged(origin, 'allianzOptOut') ? allianzOptOutChangedValue : 'false';
  const isOptOutReasonChanged = isPropertyChanged(origin, 'allianzOptOutReason');
  let content = '';
  let subContent = '';
  if (date) {
    if ((isOptOutChangeToTrue || '').toLocaleLowerCase() === 'true' || isOptOutReasonChanged) {
      content = 'Opted out of general insurance.';
    } else {
      content = 'General insurance referral has been sent to Allianz.';
      subContent = 'CONSENT CONFIRMED OBLIGATIONS ADHERED TO';
    }
  }
  return ({
    ...getBasicLogInfo(origin),
    actionTime: date || origin.actionTime,
    operator: origin.userName ? origin.userName : 'Lotus Notes',
    type: TYPES.INSURANCE,
    content,
    subContent,
  });
};

export default allianzInsuranceChangeLogTransformer;
