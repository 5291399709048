import React from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import PrimaryButton from '../../Buttons/PrimaryButton';

const styles = {
  position: 'relative',
  right: '0',
};

const CompareButton = ({
                         comparisonRoute,
                         history,
                         comparisonSize,
                         comparisonTitle,
                         dispatchGetComparisonDetails,
}) => {
  const onClick = () => {
    dispatchGetComparisonDetails();
    history.push(comparisonRoute);
  };

  return (
    <PrimaryButton
      onClick={onClick}
      disabled={comparisonSize === 0}
      style={styles}
    >
      {comparisonTitle}
      {comparisonSize ? ` (${comparisonSize})` : ''}
    </PrimaryButton>
  );
};

CompareButton.defaultProps = {
  comparisonTitle: 'Compare',
  dispatchGetComparisonDetails: noop,
};

CompareButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  comparisonTitle: PropTypes.string,
  comparisonRoute: PropTypes.string.isRequired,
  comparisonSize: PropTypes.number.isRequired,
  dispatchGetComparisonDetails: PropTypes.func,
};

export default CompareButton;
