import get from 'lodash/get';
import graphQL from 'ApplicationTracking/components/ApplicationDetails/redux/applicationDetailsGraphQL';
import { getTasks } from '../../../api';

export const GET_SPECIAL_TASKS_SUCCESSFUL = 'GET_SPECIAL_TASKS_SUCCESSFUL';
export const CHANGE_SPECIAL_TASK_STATUS_SUCEESSFUL = 'CHANGE_SPECIAL_TASK_STATUS_SUCEESSFUL';

export const getSpecialTasks = businessId => dispatch =>
  getTasks(businessId)
    .then((result) => {
      const specialTasks = get(result, 'data.specialTasksSearch', []);
      dispatch({ type: GET_SPECIAL_TASKS_SUCCESSFUL, data: { specialTasks } });
    });

export const changeSpecialTaskStatus = (taskId, completed) => dispatch =>
  graphQL.changeTaskStatus(taskId, completed).then((res) => {
    dispatch({
      type: CHANGE_SPECIAL_TASK_STATUS_SUCEESSFUL,
      data: {
        taskId: res.data.changeTaskStatus,
      },
    });
  });
