import PropTypes from 'prop-types';

export default PropTypes.shape({
  row: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  cell: PropTypes.string,
  headerCell: PropTypes.string,
  primativeCell: PropTypes.string,
  primativeHeaderCell: PropTypes.string,
  filterCell: PropTypes.string,
  sortingCell: PropTypes.string,
  sortingHeaderCell: PropTypes.string,
  selectionCell: PropTypes.string,
});
