export const THIRD_PARTY_TYPE_MAP = {
  Accountant: 'Accountant',
  AgentVendor: 'Real estate agent',
  Builder: 'Builder',
  Conveyancer: 'Conveyancer',
  Director: 'Director',
  Insurer: 'Insurer',
  Lawyer: 'Lawyer',
  Valuer: 'Valuer',
  Other: 'Other',
};
