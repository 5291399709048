import React from 'react';
import PropTypes from 'prop-types';
import ExternalInfoLink from '../../Links/ExternalInfoLink';

const CalculationExplanation = () => (
  <ExternalInfoLink
    to="https://smartline.zendesk.com/hc/en-us/articles/360000629116"
    text="How the calculations are done"
  />
);

CalculationExplanation.propTypes = {
  customizedStyles: PropTypes.shape({
    calcExplanation: PropTypes.object,
  }),
};

CalculationExplanation.defaultProps = {
  customizedStyles: {},
};

export default CalculationExplanation;
