import React from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import ReduxFormCheckbox from 'shared/components/formFields/ReduxForm/Checkbox';
import ReduxFormFieldArrayTupleInput, { validateValues as validateOtherFunds } from '../components/FieldArrayTupleInput';
import styles from '../styles.module.scss';
import ConnectedSubtotalContributions from './SubtotalContributions';
import SubSection from '../components/SubSection';
import { formatCurrency } from '../../../../../../utils/formatters';

const proposedLoanAmountBeforeLmiSelector = state => get(
  state,
  'application.fundingWorksheetCalculation.fundingPositions.proposedLoanAmountBeforeLmi',
);

const EditFundsAvailable = ({ userGuidanceCodes }) => {
  const proposedLoanAmountBeforeLmi = useSelector(proposedLoanAmountBeforeLmiSelector);
  return (
    <div className={styles.contributions}>
      <h3 className={styles.title}>Funds available</h3>
      <InformationSection
        wrapperStyle={styles.contributionsWrapper}
        contentWrapperStyle={styles.contributionsContentWrapper}
        titleWrapperStyle={styles.titleWrapperStyle}
        title="Contributions"
      >
        <SubSection>
          <Field
            name="contributions.genuineSavings"
            component={ReduxFormNumberInput}
            label="Genuine savings"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="contributions.firstHomeOwnerGrant"
            component={ReduxFormNumberInput}
            label="First home owner grant"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="contributions.gift"
            component={ReduxFormNumberInput}
            label="Gift"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="contributions.initialDepositAmount"
            component={ReduxFormNumberInput}
            label="Deposit paid"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
          <Field
            name="contributions.netProceedsFromSaleOfProperty"
            component={ReduxFormNumberInput}
            label="Sale proceed funds"
            placeholder="$"
            className={styles.sideBySideTextInput}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
        </SubSection>
        <SubSection header="Other funds" className={styles.otherFundsSection}>
          <FieldArray
            name="contributions.otherFunds"
            component={ReduxFormFieldArrayTupleInput}
            validate={validateOtherFunds}
            allowNegative={false}
          />
        </SubSection>
        <ConnectedSubtotalContributions userGuidanceCode={userGuidanceCodes.contributions} />
      </InformationSection>
      <InformationSection titleWrapperStyle={styles.titleWrapperStyle} title="Loan amount">
        <SubSection>
          <div className={styles.lmiField}>
            <Field
              name="loanAmount.capitaliseLmi"
              component={ReduxFormCheckbox}
              labelName="Capitalise LMI"
            />
          </div>
          <div className={styles.suggestedLoanAmountField}>
            <div>Minimum loan before LMI</div>
            <div>{formatCurrency(proposedLoanAmountBeforeLmi, { precision: 2 })}</div>
          </div>
        </SubSection>
        <SubSection>
          <Field
            name="loanAmount.baseLoanAmount"
            component={ReduxFormNumberInput}
            label={`Base loan amount (${userGuidanceCodes.loanAmount})`}
            placeholder="$"
            className={styles.baseLoanAmount}
            decimalScale={2}
            prefix="$"
            fullWidth
            allowNegative={false}
          />
        </SubSection>
      </InformationSection>
    </div>
  );
};

EditFundsAvailable.propTypes = {
  userGuidanceCodes: PropTypes.shape({
    contributions: PropTypes.string,
    loanAmount: PropTypes.string,
  }).isRequired,
};

export default EditFundsAvailable;
