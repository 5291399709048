import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import moment from 'moment';
import styles from './Soma.module.scss';
import { FETCHING_STATUS } from '../../../../../../../constants';
import { downloadApplicantSoma, downloadGuarantorSoma } from '../../../../redux';

const GenerateSoma = ({
     handleDownloadApplicantSoma,
     handleDownloadGuarantorSoma,
     applicantSomaFetchingStatus,
     guarantorSomaFetchingStatus,
                        disabled,
                        hasNotGuarantors,
}) => {
  const onDownloadApplicantSoma = () => {
    const data = {
      somaType: 'APPLICANT',
      generateDate: moment().format(),
    };
    handleDownloadApplicantSoma(data);
  };

  const onDownloadGuarantorSoma = () => {
    const data = {
      somaType: 'GUARANTOR',
      generateDate: moment().format(),
    };
    handleDownloadGuarantorSoma(data);
  };

  const isApplicantSomaFetching = useMemo(() => applicantSomaFetchingStatus === FETCHING_STATUS.START,
    [applicantSomaFetchingStatus]);
  const isGuarantorSomaFetching = useMemo(() => guarantorSomaFetchingStatus === FETCHING_STATUS.START,
    [guarantorSomaFetchingStatus]);

  return (
    <div className={styles.section}>
      <h5 className={styles.subTitle}>Generate new SOMA</h5>
      <div className={styles.generateSomaButtonContainer}>
        <PrimaryButton
          onClick={onDownloadApplicantSoma}
          disabled={disabled || isApplicantSomaFetching}
          loading={isApplicantSomaFetching}
        >
          Applicant SOMA
        </PrimaryButton>
        <PrimaryButton
          onClick={onDownloadGuarantorSoma}
          disabled={disabled || isGuarantorSomaFetching || hasNotGuarantors}
          loading={isGuarantorSomaFetching}
        >
          Guarantor SOMA
        </PrimaryButton>
      </div>
    </div>
  );
};

GenerateSoma.propTypes = {
  applicantSomaFetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  guarantorSomaFetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  handleDownloadApplicantSoma: PropTypes.func.isRequired,
  handleDownloadGuarantorSoma: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  hasNotGuarantors: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  applicantSomaFetchingStatus: state.application.somaFetchingStatus.applicantSomaFetchingStatus,
  guarantorSomaFetchingStatus: state.application.somaFetchingStatus.guarantorSomaFetchingStatus,
  hasNotGuarantors: (
    isEmpty(get(state, 'application.applicationDetail.guarantors.persons', []))
    && isEmpty(get(state, 'application.applicationDetail.guarantors.companies', []))
  ),
});

export default connect(mapStateToProps, {
  handleDownloadApplicantSoma: downloadApplicantSoma,
  handleDownloadGuarantorSoma: downloadGuarantorSoma,
})(GenerateSoma);
