import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import map from 'lodash/map';
import floor from 'lodash/floor';
import pick from 'lodash/pick';
import { APPLICATION_TYPES } from 'shared/components/productSearch/constants';
import {
  APPLICATION_CRITERIA_FIELDS,
  LOAN_CRITERIA_FIELDS,
  MODIFICATION_ORIGINAL_FIELDS,
  VARIANT_FIELDS,
} from '../constants';

const formatLoanCriteria = (loanCriteria, isNewLoan) => {
  const pickedLoanCriteria = pick(loanCriteria, LOAN_CRITERIA_FIELDS);

  if (isNewLoan) {
    return {
      ...pickedLoanCriteria,
      loanTerm: floor(pickedLoanCriteria.loanTerm),
      combinedLoanAmount: null,
    };
  }
  return pickedLoanCriteria;
};

const formatApplicationCriteria = (applicationCriteria, isNewLoan) => {
  const pickedApplicationCriteria = pick(applicationCriteria, APPLICATION_CRITERIA_FIELDS);

  if (isNewLoan) {
    return {
      ...pickedApplicationCriteria,
      totalCombinedLoanAmount: null,
    };
  }
  return pickedApplicationCriteria;
};

const getFormDataFromComparison = currentComparison => {
  if (isNil(currentComparison) || isNil(currentComparison.searchCriteria)) {
    return null;
  }
  const {
    id, searchCriteria, variantsCombinations, comment, selectedFeaturesFromComparison, includeCommentsInPrintout,
  } = currentComparison;

  const isNewLoan = searchCriteria.applicationCriteria.applicationType === APPLICATION_TYPES.NEW_LOAN;

  return {
    id,
    comment: trim(comment),
    selectedFeaturesFromComparison,
    includeCommentsInPrintout,
    searchCriteria: {
      loansCriteria: map(searchCriteria.loansCriteria, loanCriteria => formatLoanCriteria(loanCriteria, isNewLoan)),
      applicationCriteria: formatApplicationCriteria(searchCriteria.applicationCriteria, isNewLoan),
      totalCostTerm: isNewLoan ? floor(searchCriteria.totalCostTerm) : searchCriteria.totalCostTerm,
      repaymentFrequency: searchCriteria.repaymentFrequency,
    },
    variantsCombinations: map(variantsCombinations, comb => ({
      preferred: comb.preferred,
      lenderOverrides: comb.lenderOverrides,
      variants: map(comb.variants, variant => {
        const pickedVariant = pick(variant, VARIANT_FIELDS);
        return {
          ...pickedVariant,
          modificationOriginals: pick(variant.modificationOriginals, MODIFICATION_ORIGINAL_FIELDS),
        };
      }),
    })),
  };
};

export default getFormDataFromComparison;
