import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const retrieveTaskListTemplates = businessId =>
client.query({
  query: gql`{
    taskListTemplates {
      businessTemplates(businessId: "${businessId}") {
        id
        name
        businessId
        lastModifiedDateTime
        tasks {
          name
          description
        }
      },
      smartlineTemplates {
        id
        name
        businessId
        lastModifiedDateTime
        tasks {
          name
          description
        }
      }
    }
  }`,
});

const formatTasks = tasks =>
  tasks.map(task =>
    `{name: ${JSON.stringify(task.name.trim())}, description: ${JSON.stringify(task.description && task.description.trim())}}`);

const createOrUpdateTaskListTemplate = (
  {
    id, businessId, name, tasks,
  },
) => client.mutate({
    mutation: gql`mutation{
      createOrUpdateTaskListTemplate(
        input: {
          id: "${id}"
          businessId: "${businessId}"
          name: ${JSON.stringify(name.trim())}
          tasks: [${formatTasks(tasks)}]
        }
      )
      {
        id
        name
        tasks {
          name
          description
        }
      }
    }`,
  });

const deleteTaskListTemplate = templateId =>
  client.mutate({
    mutation: gql`mutation{
      deleteTaskListTemplate(
        templateId: "${templateId}"
      )
    }`,
  });

export default {
  retrieveTaskListTemplates,
  createOrUpdateTaskListTemplate,
  deleteTaskListTemplate,
};
