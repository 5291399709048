import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ConfirmModal.module.scss';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import Modal from '../Modal';

const ConfirmModal = ({
  title,
  closeModal,
  onClickSecondaryButton,
  onClickPrimaryButton,
  message,
  primaryButtonText,
  secondaryButtonText,
  className,
  ...others
}) => (
  <Modal
    {...others}
    header={<span className={styles.header}>{title}</span>}
    className={classNames(styles.modalWrapper, className)}
    onRequestClose={closeModal}
    footer={(
      <div className={styles.buttonContainer}>
        <PrimaryButton
          className={styles.button}
          onClick={onClickPrimaryButton}
        >
          {primaryButtonText}
        </PrimaryButton>
        {secondaryButtonText && (
          <SecondaryButton
            className={styles.button}
            onClick={onClickSecondaryButton}
          >
            {secondaryButtonText}
          </SecondaryButton>
        )}
      </div>
    )}
  >
    <div className={styles.modalBody}>
      {message}
    </div>
  </Modal>
);

ConfirmModal.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onClickSecondaryButton: PropTypes.func.isRequired,
  onClickPrimaryButton: PropTypes.func.isRequired,
  message: PropTypes.node,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  className: PropTypes.string,
};

ConfirmModal.defaultProps = {
  title: '',
  primaryButtonText: 'OK',
  secondaryButtonText: 'CANCEL',
  message: '',
  className: undefined,
};

export default ConfirmModal;
