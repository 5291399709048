import React from 'react';
import PropTypes from 'prop-types';

import RepaymentTypeComponent from './RepaymentTypeComponent';
import { ProductSearchContext } from '../../ProductSearchContext';

const ConnectedRepaymentType = ({ index }) => (
  <ProductSearchContext.Consumer>
    {
      context => {
        const { repaymentType } = context.loanCriteriaList[index];
        return (
          <RepaymentTypeComponent
            repaymentType={repaymentType}
            dispatchUpdateRepaymentType={context.dispatchUpdateRepaymentType(index)}
          />
        );
      }
    }
  </ProductSearchContext.Consumer>
);

ConnectedRepaymentType.propTypes = ({
  index: PropTypes.number.isRequired,
});

export default ConnectedRepaymentType;
