import { createReducer } from 'utils/reducerGenerator';
import moment from 'moment';
import * as types from '../actions';
import { FETCHING_STATUS } from '../../../../constants';

export const reducer = createReducer({
  fetchingStatus: FETCHING_STATUS.INIT,
}, {
  [types.FETCH_REPORTING_META_REQUEST]: () => ({
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.FETCH_REPORTING_META_SUCCESS]: (state, { data }) => ({
    fetchingStatus: FETCHING_STATUS.SUCCESS,
    ...data,
    earliestDate: moment(data.earliestDate),
  }),
  [types.FETCH_REPORTING_META_ERROR]: () => ({
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});
