import React from 'react';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const AddressLabel = React.memo(({ onClick, name }) => (
  <div className={styles.addressLabel}>
    <div>{name}</div>
    <TertiaryButton
      className={styles.enterManuallyButton}
      onClick={onClick}
    >
      Enter manually
    </TertiaryButton>
  </div>
));

AddressLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default AddressLabel;
