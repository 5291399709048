import get from 'lodash/get';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { FETCHING_STATUS } from '../../../../../../constants';

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
    applicationNotFound: false,
    applicationForbidden: false,
};

const applicationFetchingStatus = createReducer(initialState, {
  [types.GET_APPLICATION_DETAIL_START]: () => ({
    applicationNotFound: false,
    applicationForbidden: false,
    fetchingStatus: FETCHING_STATUS.START,
  }),
  [types.GET_APPLICATION_DETAIL_SUCCESS]: () => ({
    applicationNotFound: false,
    applicationForbidden: false,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
  }),
  [types.GET_APPLICATION_DETAIL_ERROR]: (state, action) => ({
    ...state,
    ...get(action, 'data', {}),
    fetchingStatus: FETCHING_STATUS.ERROR,
  }),
});

export default applicationFetchingStatus;
