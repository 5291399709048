import React from 'react';
import { Provider } from 'react-redux';
import { setDispatchFunction as setGraphQLDispatchFucntion } from 'shared/api/GraphQL';
import { setStateGetter as findBusinessStateGetter } from 'shared/findBusiness';
import { BrowserRouter } from 'react-router-dom';
import { confirmMeta } from 'shared/components/UnsavedChangePrompt';
import ConfirmProvider, { ConfirmContext } from 'shared/components/ConfirmProvider';
import BaseApp from './App';
import initializeStore from './store';
import { setPageViewTrackingDispatch } from './pages/usePageViewTracking';
import { configureDataSourceForGlobalToggleGetter } from './shared/toggles/getToggles';

const store = initializeStore();

if (process.env.REACT_APP_ENV === 'dev') {
  import('./developerHelper').then(({ default: configureDeveloperHelper }) => {
    configureDeveloperHelper(store);
  });
}

setGraphQLDispatchFucntion(store.dispatch);
setPageViewTrackingDispatch(store.dispatch);
configureDataSourceForGlobalToggleGetter(store.getState);
findBusinessStateGetter(store.getState);

export default () => (
  <Provider store={store}>
    <ConfirmProvider>
      <ConfirmContext.Consumer>
        {(confirm) => (
          <BrowserRouter
            getUserConfirmation={(message, callback) => {
                confirm({ ...confirmMeta, message })
                  .then(shouldStay => callback(!shouldStay));
              }}
          >
            <BaseApp />
          </BrowserRouter>
        )}
      </ConfirmContext.Consumer>
    </ConfirmProvider>
  </Provider>
);
