import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import styles from './Modal.module.scss';

export const SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

export const modalPropTypes = {
  isOpen: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
    .isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZES)),
  overlayClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

const Modal = ({
                 isOpen,
                 onRequestClose,
                 children,
                 header,
                 footer,
                 className,
                 shouldCloseOnOverlayClick,
                 shouldCloseOnEsc,
                 size,
                 overlayClassName,
                 disabled,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Product features"
    overlayClassName={classNames('all-features-overlay',
      overlayClassName, {
      [styles.modalWrapperSmall]: size === SIZES.SMALL,
      [styles.modalWrapperMedium]: size === SIZES.MEDIUM,
      [styles.modalWrapperLarge]: size === SIZES.LARGE,
    })}
    className={classNames(
      {
        [styles.modalSmall]: size === SIZES.SMALL,
        [styles.modalMedium]: size === SIZES.MEDIUM,
        [styles.modalLarge]: size === SIZES.LARGE,
      },
      className,
    )}
    ariaHideApp={false}
    shouldCloseOnOverlayClick={!disabled && shouldCloseOnOverlayClick}
    shouldCloseOnEsc={!disabled && shouldCloseOnEsc}
  >
    <div className={classNames(styles.header, 'modal_header')}>
      <div className={classNames(styles.headerContent, 'modal_header')}>
        {header}
      </div>
      <button
        disabled={disabled}
        type="button"
        onClick={onRequestClose}
        className={styles.closeButton}
      />
    </div>
    <div className={styles.body}>{children}</div>
    <div className={classNames(styles.footer, 'modal_footer')}>{footer}</div>
  </ReactModal>
);

Modal.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  footer: undefined,
  header: undefined,
  className: undefined,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  size: SIZES.SMALL,
  overlayClassName: undefined,
  disabled: false,
};

Modal.propTypes = modalPropTypes;

export default Modal;
