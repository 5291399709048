import React from 'react';
import { Route } from 'react-router-dom';
import Products from 'pages/Products';
import Navbar from 'shared/components/Navbar/NavigationBar';
import ProductComparison from 'pages/Comparison';
import Dashboard from 'pages/Dashboard';
import ErrorBanner from 'shared/components/Banners/ErrorBanner';
import MyBusinessRoutes from 'MyBusiness/components/MyBusinessRoutes';
import ApplicationTrackingRoutes from 'ApplicationTracking/ApplicationTrackingRoutes';
import LoanManagementRoutes from 'Loans/LoanManagementRoutes';
import DashboardWrapper from 'pages/Dashboard/DashboardWrapper';
import { ConnectedReportsPage } from 'pages/ReportsPage';
import withSyncBusinessId from '../WithSyncBusinessId/withSyncBusinessId';
import AllApplications from '../../../AllApplications/AllApplications';

const ReportsPageComponent = withSyncBusinessId(ConnectedReportsPage);

const Routes = () => (
  <main>
    <Navbar />
    <ErrorBanner />
    <div className="content">
      <Route exact path="/" component={DashboardWrapper} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/products/comparison" component={ProductComparison} />
      <ApplicationTrackingRoutes />
      <Route exact path="/all-applications" component={AllApplications} />
      <LoanManagementRoutes />
      <MyBusinessRoutes />
      <Route exact path="/reports" component={ReportsPageComponent} />
    </div>
  </main>
);

export default Routes;
