import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

export const SummaryTile = ({
                              title, currentInfo, currentValueAmount, previousInfo, previousValueAmount,
                            }) =>
  (
    <div className={styles.summaryTileSection}>
      <div />
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.itemKey}>
        { !!currentInfo.length && currentInfo.map(item => (
          <p key={item.key}>{item.value}</p>
        ))}
      </div>
      <div className={styles.currentItemAmount}>
        {currentValueAmount}
      </div>
      <div className={styles.itemKey}>
        { !!previousInfo.length && previousInfo.map(item => (
          <p key={item.key}>{item.value}</p>
        ))}
      </div>
      <div className={styles.previousItemAmount}>
        {previousValueAmount}
      </div>
    </div>
  );

SummaryTile.defaultProps = {
  currentInfo: [],
  currentValueAmount: 'N/A',
  previousInfo: [],
  previousValueAmount: 'N/A',
};

SummaryTile.propTypes = {
  title: PropTypes.string.isRequired,
  currentInfo: PropTypes.array,
  currentValueAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  previousInfo: PropTypes.array,
  previousValueAmount: PropTypes.string,
};
