import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReduxFormOverrideText from 'shared/components/formFields/ReduxForm/OverrideText';
import ExternalInfoLink from 'shared/components/Links/ExternalInfoLink';
import styles from '../styles.module.scss';
import { formatCurrency } from '../../../../../../utils/formatters';
import SubSection from '../components/SubSection';

const GovernmentCharges = ({ costToPurchasePath }) => (
  <SubSection
    header="Government charges"
    className={styles.costToPurchaseSubSection}
    headerClassName={styles.governmentChargesHeader}
  >
    <ExternalInfoLink
      to="https://smartline.zendesk.com/hc/en-us/articles/360001929456"
      text="How is stamp duty calculated?"
      externalInfoLinkClassName={styles.externalInfoLink}
      textClassName={styles.externalInfoText}
    />
    <Field
      name={`${costToPurchasePath}.stampDutyOnTransferOfProperty`}
      component={ReduxFormOverrideText}
      className={styles.overrideTextField}
      label="Stamp duty"
      allowNegative={false}
      clearButtonText={({ value }) => (`Return to system calculated value (${formatCurrency(value, { precision: 2 })})`)}
      clearable
    />
    <Field
      name={`${costToPurchasePath}.titleTransferFee`}
      component={ReduxFormOverrideText}
      className={styles.overrideTextField}
      label="Land titles fee"
      allowNegative={false}
      clearButtonText={({ value }) => (`Return to system calculated value (${formatCurrency(value, { precision: 2 })})`)}
      clearable
    />
  </SubSection>
);

GovernmentCharges.propTypes = {
  costToPurchasePath: PropTypes.string.isRequired,
};

export default GovernmentCharges;
