import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/Modal';
import { SIZES } from 'shared/components/Modal/Modal';
import PropTypes from 'prop-types';
import Button from 'shared/components/Buttons/Button';
import RadioButtonGroup from 'shared/components/formFields/RadioButtonGroup';
import Checkbox from 'shared/components/formFields/Checkbox';
import styles from './ComplianceModal.module.scss';
import SwitchComplianceLink from './SwitchComplianceLink';
import { UPDATE_PURPOSES } from './updatePurposes';

export const ComplianceModal = ({
                                  isOpen,
                                  onClose,
                                  onSave,
                                  isLoading,
                                  shouldReset,
                                }) => {
  const DEFAULT_PURPOSE = UPDATE_PURPOSES.PRODUCT_SWITCH;

  const [purpose, setPurpose] = useState(DEFAULT_PURPOSE);
  const [hasDeclared, setHasDeclared] = useState(false);

  useEffect(() => {
    if (shouldReset) {
      setPurpose(DEFAULT_PURPOSE);
      setHasDeclared(false);
    }
  }, [DEFAULT_PURPOSE, shouldReset]);

  const makeComplianceOption = options =>
    options.map(option => ({
      label: option,
      value: option,
    }));

  const complianceOptions = makeComplianceOption(Object.values(UPDATE_PURPOSES));
  const getComplianceCheckBox = () => (
    <div className={styles.declaration}>
      <h4>Declaration</h4>
      <Checkbox
        className={styles.checkBoxLabel}
        inputProps={{ className: styles.checkBoxInput }}
        checked={hasDeclared}
        onChange={({ checked }) => setHasDeclared(checked)}
        labelName={(purpose === UPDATE_PURPOSES.PRODUCT_SWITCH
          ? (
            <>
              I have completed and
              attached the <span className={styles.boldText}>LAUNCHPAD SWITCH COMPLIANCE FORM</span> to the client
              file.
            </>
          ) : (
            <>
              <span className={styles.boldText}>NO</span> compliance form
              completed as I am updating after a split or this action is
              to update existing data only.
            </>
          ))}
      />
    </div>
  );

  const onPurposeChanges = (newPurpose) => {
    setPurpose(newPurpose);
    setHasDeclared(false);
  };

  const onSaveButtonClick = () => {
    const isComplianceFormCompleted = purpose === UPDATE_PURPOSES.PRODUCT_SWITCH;
    onSave(isComplianceFormCompleted);
  };

  return (
    <Modal
      className={styles.complianceModal}
      isOpen={isOpen}
      header="Compliance"
      footer={
        <Button onClick={onSaveButtonClick} disabled={isLoading || !hasDeclared} loading={isLoading}>Save</Button>
      }
      onRequestClose={onClose}
      size={SIZES.LARGE}
    >
      <section className={styles.compliance}>
        <p>What is the purpose of this edit?</p>
        <RadioButtonGroup
          items={complianceOptions}
          groupName="purpose"
          value={purpose}
          onSelect={onPurposeChanges}
        />
        <p className={styles.info}>
          If you are performing a <i>product switch</i>, you must complete the&nbsp;
          <SwitchComplianceLink /> and attach it to the client file.
        </p>
        {getComplianceCheckBox()}
      </section>
    </Modal>
  );
};

ComplianceModal.defaultProps = {
  isOpen: false,
  isLoading: false,
  shouldReset: false,
};

ComplianceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shouldReset: PropTypes.bool,
};
