import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ProductComparison from './ProductComparison';
import { ApplicationCriteriaShape, LoanCriteriaShape, OptionShape } from '../../shapes';
import {
  downloadComparision,
  toggleFeatureModalInComparison,
  toggleIncludeCommentsInPrintout, toggleSelectedFeatureFromComparison, updateSelectedFeaturesFromComparison,
} from '../redux/comparison/comparison';
import { cancelEditing, startEditing, updateModificationValue } from '../redux/variantModification';
import { modifyCriteria } from '../redux/applicationCriteria';
import applyModification from '../redux/variantModification/applyModification';

const StandAloneProductComparison = ({
                                       comparison,
                                       totalCostTerm,
                                       repaymentFrequency,
                                       loanCriteriaList,
                                       applicationCriteria,
                                       features,
                                       featuresHaveError,
                                       businessCount,
                                       variantModification,
                                       userProfile,
                                       selectedBusinessId,
                                       dispatchToggleIncludeCommentsInPrintout,
                                       dispatchStartVariantEditing,
                                       dispatchToggleFeatureModalView,
                                       dispatchToggleSelectFeatureFromComparison,
                                       dispatchUpdateSelectedFeaturesFromComparison,
                                       dispatchModifyCriteria,
                                       dispatchDownloadPdf,
                                       dispatchCancelVariantEditing,
                                       dispatchVariantModificationUpdateValue,
                                       dispatchApplyModification,
                                     }) => {
  const context = {
    comparison,
    totalCostTerm,
    repaymentFrequency,
    loanCriteriaList,
    applicationCriteria,
    features,
    featuresHaveError,
    businessCount,
    variantModification,
    userProfile,
    selectedBusinessId,
    backToSearchRoute: '/products',
    dispatchToggleIncludeCommentsInPrintout,
    dispatchStartVariantEditing,
    dispatchToggleFeatureModalView,
    dispatchToggleSelectFeatureFromComparison,
    dispatchUpdateSelectedFeaturesFromComparison,
    dispatchModifyCriteria,
    dispatchDownloadPdf,
    dispatchCancelVariantEditing,
    dispatchVariantModificationUpdateValue,
    dispatchApplyModification,
    featureFlags: {},
  };
  return (
    <ProductComparison context={context} />
  );
};

StandAloneProductComparison.propTypes = {
  comparison: PropTypes.shape({
    selectedOptions: PropTypes.arrayOf(OptionShape).isRequired,
    hasError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    includeCommentsInPrintout: PropTypes.bool.isRequired,
    featureModalViewInComparison: PropTypes.bool.isRequired,
    selectedFeaturesFromComparison: PropTypes.arrayOf(PropTypes.string).isRequired,
    isDownloading: PropTypes.bool.isRequired,
  }).isRequired,
  totalCostTerm: PropTypes.number.isRequired,
  repaymentFrequency: PropTypes.string.isRequired,
  loanCriteriaList: PropTypes.arrayOf(LoanCriteriaShape).isRequired,
  applicationCriteria: ApplicationCriteriaShape.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
  })).isRequired,
  featuresHaveError: PropTypes.bool.isRequired,
  businessCount: PropTypes.number.isRequired,
  variantModification: PropTypes.shape({
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentModification: PropTypes.shape({
      variant: PropTypes.object.isRequired,
      fieldName: PropTypes.string.isRequired,
      optionIndex: PropTypes.number.isRequired,
      splitIndex: PropTypes.number.isRequired,
    }).isRequired,
    hasError: PropTypes.bool.isRequired,
  }).isRequired,
  userProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  selectedBusinessId: PropTypes.string.isRequired,
  dispatchToggleIncludeCommentsInPrintout: PropTypes.func.isRequired,
  dispatchStartVariantEditing: PropTypes.func.isRequired,
  dispatchToggleFeatureModalView: PropTypes.func.isRequired,
  dispatchToggleSelectFeatureFromComparison: PropTypes.func.isRequired,
  dispatchUpdateSelectedFeaturesFromComparison: PropTypes.func.isRequired,
  dispatchModifyCriteria: PropTypes.func.isRequired,
  dispatchDownloadPdf: PropTypes.func.isRequired,
  dispatchCancelVariantEditing: PropTypes.func.isRequired,
  dispatchVariantModificationUpdateValue: PropTypes.func.isRequired,
  dispatchApplyModification: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  comparison: state.productSearch.standAlone.comparison,
  totalCostTerm: state.productSearch.standAlone.products.totalCostTerm,
  repaymentFrequency: state.productSearch.standAlone.products.repaymentFrequency,
  loanCriteriaList: state.productSearch.standAlone.loanCriteriaList,
  applicationCriteria: state.productSearch.standAlone.applicationCriteria,
  features: state.productSearch.features.features,
  featuresHaveError: state.productSearch.features.hasError,
  businessCount: state.profile.businesses.length,
  variantModification: state.productSearch.standAlone.variantModification,
  userProfile: state.profile,
  selectedBusinessId: get(state, 'business.selectedBusiness.id'),
});

const mapDispatchToProps = dispatch => ({
  dispatchToggleIncludeCommentsInPrintout: () => dispatch(toggleIncludeCommentsInPrintout()),
  dispatchStartVariantEditing: (variant, fieldName, optionIndex, splitIndex) =>
    dispatch(startEditing(variant, fieldName, optionIndex, splitIndex)),
  dispatchToggleFeatureModalView: () => dispatch(toggleFeatureModalInComparison()),
  dispatchToggleSelectFeatureFromComparison: featureName =>
    dispatch(toggleSelectedFeatureFromComparison(featureName)),
  dispatchUpdateSelectedFeaturesFromComparison: featureNames =>
    dispatch(updateSelectedFeaturesFromComparison(featureNames)),
  dispatchModifyCriteria: () => dispatch(modifyCriteria()),
  dispatchDownloadPdf: (overrideUserProfile) => dispatch(downloadComparision(overrideUserProfile)),
  dispatchCancelVariantEditing: () => dispatch(cancelEditing()),
  dispatchApplyModification: () => dispatch(applyModification()),
  dispatchVariantModificationUpdateValue: (fieldName, value) =>
    dispatch(updateModificationValue(fieldName, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StandAloneProductComparison);
