import get from 'lodash/get';
import filter from 'lodash/filter';
import has from 'lodash/has';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import last from 'lodash/last';
import { getUpdatedChanges } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const getId = content => {
  const regex = RegExp(/somas\[(.+)\].*/);

  const result = regex.exec(content);
  return result[1];
};

const getFieldName = change => last(change.path.split('.'));

export const extractExtraInfo = (extraInfos, path) => {
  const id = getId(path);
  return ({
    generatedDate: extraInfos[id].generatedDate,
    type: startCase(extraInfos[id].type.toLowerCase()),
  });
};

export const SOMAS_FIELDS = {
  issued: 'The Previously generated SOMA have been updated',
  signed: 'The Previously generated SOMA have been updated',
  downloadedDate: 'SOMA has been downloaded',
};

const somasUpdateChangeLogTransformer = origin => {
  const extraInfos = JSON.parse(get(origin, 'eventDetail.extraInfo', '[]'));
  const changesToDisplay = filter(getUpdatedChanges(origin), (change) => has(SOMAS_FIELDS, getFieldName(change)));

  const changes = map(changesToDisplay, (change) => ({
    ...extractExtraInfo(extraInfos, change.path),
    fieldName: getFieldName(change),
    newValue: change.newValue,
  }));

  return isEmpty(changesToDisplay) ? undefined : {
    ...getBasicLogInfo(origin),
    summary: SOMAS_FIELDS[changes[0].fieldName],
    changes,
  };
};
export default somasUpdateChangeLogTransformer;
