import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FONTS,
  PALETTE,
  MARGIN,
  PADDING,
} from 'shared/theme/depricatedStyles';
import { closeErrorBanner } from 'redux/banner';

const styles = {
  banner: {
    fontFamily: FONTS.standard,
    fontSize: FONTS.size.large,
    color: PALETTE.error,
    backgroundColor: PALETTE.errorBackground,
    textDecoration: 'none',
    marginTop: MARGIN.xsmall,
    width: '100%',
    padding: `${PADDING.mediumLarge} ${PADDING.large} ${PADDING.mediumLarge} ${PADDING.large}`,
    borderBottom: `1px solid ${PALETTE.error}`,
    display: 'flex',
    alignItems: 'center',
  },
  bannerIcon: {
    display: 'inline-block',
    marginRight: MARGIN.medium,
    marginTop: '.1rem',
    width: '1.4rem',
    height: '1.4rem',
  },
  bannerText: {
    display: 'inline-block',
    flex: 1,
  },
};

const ErrorBanner = ({ message, dispatchCloseErrorBanner }) => {
  if (!message) {
    return <div />;
  }

  setTimeout(() => {
    dispatchCloseErrorBanner();
  }, 5000);

  return (
    <div className="error-banner" style={styles.banner}>
      <img style={styles.bannerIcon} src="/error-icon.svg" alt="" />
      <span style={styles.bannerText}>
        {message}
      </span>
      <button
        type="button"
        onClick={dispatchCloseErrorBanner}
        className="close-button"
      />
    </div>
  );
};

ErrorBanner.defaultProps = {
  message: null,
};

ErrorBanner.propTypes = {
  message: PropTypes.string,
  dispatchCloseErrorBanner: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  message: state.banner.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  dispatchCloseErrorBanner: () => dispatch(closeErrorBanner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBanner);
