import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const styles = {
  spinner: {
    paddingLeft: '1rem',
  },
};

const MiniSpinner = ({ text }) => (
  <>
    <FontAwesomeIcon icon={faCircleNotch} spin />
    <span style={styles.spinner}>{text}</span>
  </>
);

MiniSpinner.propTypes = {
  text: PropTypes.string,
};

MiniSpinner.defaultProps = {
  text: '',
};

export default MiniSpinner;
