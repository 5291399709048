import { getTargetChangedValue } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const noteLogTransformer = origin => ({
  ...getBasicLogInfo(origin),
  lastModifiedTime: origin.updateTime,
  operator: origin.createdByName || origin.userName,
  lastModifiedByName: origin.userName,
  content: getTargetChangedValue(origin, 'content'),
  hasLastModify: !!origin.createdAt && origin.updateTime !== origin.createdAt,
});

export default noteLogTransformer;
