import React, { cloneElement } from 'react';
import { VirtualTableState as VirtualTableStateBase } from '@devexpress/dx-react-grid';
import { emptyVirtualRows } from '@devexpress/dx-grid-core';
import { Action } from '@devexpress/dx-react-core';

export class VirtualTableState extends VirtualTableStateBase {
  handleUpdateRowsCache = (updater) => {
    const { virtualRowsCache } = this.state;
    this.setState({ virtualRowsCache: updater(virtualRowsCache) });
  };

  clearRowsCacheAction = (arg, getters, { requestNextPage }) => {
    this.setState({
      virtualRowsCache: emptyVirtualRows,
    }, () => {
      requestNextPage({ forceReload: true });
    });
  };

  changeColumnFilterAction = (arg, getters, { clearRowCache }) => {
    this.setState({
      requestedStartIndex: 0,
    }, clearRowCache);
  };

  render() {
    const result = super.render();
    const updateRowsCacheAction = (<Action key="action" name="updateRowsCache" action={this.handleUpdateRowsCache} />);
    return cloneElement(result, {}, [...result.props.children, updateRowsCacheAction]);
  }
}
