import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { CategoriesManagementModal } from './components/CategoriesManagementModal';
import * as actions from '../../redux/categoriesRedux';
import { FETCHING_STATUS } from '../../../constants';
import { categoryShape } from './shapes/categoryShape';

const mapStateToProps = state => ({
  ...state.loanCategories,
  businessId: state.business.selectedBusiness.id,
});

const WrappedCategoriesManagementModal = ({
                                           fetchCategories,
                                           businessId,
                                           updateCategories,
                                           categories,
                                           fetchingStatus,
                                           updatingStatus,
                                           onRequestClose,
                                           onCategoriesUpdated,
                                           ...others
                                         }) => {
  const handleSaveCategories = useMemo(() => async ({
                                                      categoriesToCreate,
                                                      categoriesToUpdate,
                                                      categoriesToDelete,
                                                    }) => {
    if (!isEmpty(categoriesToCreate) || !isEmpty(categoriesToUpdate) || !isEmpty(categoriesToDelete)) {
      try {
        await updateCategories({
          businessId,
          categoriesToCreate,
          categoriesToUpdate,
          categoriesToDelete,
        });
        if (isFunction(onCategoriesUpdated)) {
          onCategoriesUpdated({
            categoriesCreated: categoriesToCreate,
            categoriesUpdated: categoriesToUpdate,
            categoriesDeleted: categoriesToDelete,
          });
        }
      } catch {
        return;
      }
    }
    onRequestClose();
  }, [updateCategories, businessId, onRequestClose, onCategoriesUpdated]);
  return (
    <CategoriesManagementModal
      onSaveCategories={handleSaveCategories}
      defaultCategories={categories || []}
      fetchingStatus={fetchingStatus}
      updatingStatus={updatingStatus}
      onRequestClose={onRequestClose}
      {...others}
    />
  );
};

WrappedCategoriesManagementModal.propTypes = {
  fetchCategories: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onCategoriesUpdated: PropTypes.func,
  categories: PropTypes.arrayOf(categoryShape),
  fetchingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
  updatingStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)).isRequired,
};

WrappedCategoriesManagementModal.defaultProps = {
  categories: null,
  onCategoriesUpdated: null,
};

export const ConnectedCategoriesManagementModal = connect(
  mapStateToProps,
  {
    fetchCategories: actions.fetchCategories,
    updateCategories: actions.updateCategories,
  },
)(WrappedCategoriesManagementModal);
