import max from 'lodash/max';
import get from 'lodash/get';
import { closeErrorBanner, showErrorBanner } from 'redux/banner';
import { downloadCommissionsXlsx } from 'shared/api'; // TODO: move to myBusinessApi.js
import { openInTab } from 'shared/utils';
import graphQL from 'MyBusiness/components/Commissions/commissionsGraphQL';
import {
  getRequestIdFromAction,
  ifValidRequestId,
  startRequest,
} from 'redux/requestIds';
import { registerBeforeFunction } from '../../../../store/middleware/sideEffectMiddleware';
import { trackCustomEvent } from '../../../../tealium/Tracker';
import { downloadCommissionsXlsxV100 } from '../../../../tealium/TrackingDataBuilder';

export const ACTIONS = {
  GET_COMMISSIONS_START: 'MY_BUSINESS:COMMISSIONS:GET_COMMISSIONS_START',
  GET_COMMISSIONS_SUCCESS: 'MY_BUSINESS:COMMISSIONS:GET_COMMISSIONS_SUCCESS',
  GET_COMMISSIONS_ERRORS: 'MY_BUSINESS:COMMISSIONS:GET_COMMISSIONS_ERRORS',
  DOWNLOAD_COMMISSIONS_START: 'MY_BUSINESS:COMMISSIONS:DOWNLOAD_COMMISSIONS_START',
  DOWNLOAD_COMMISSIONS_SUCCESS: 'MY_BUSINESS:COMMISSIONS:DOWNLOAD_COMMISSIONS_SUCCESS',
  DOWNLOAD_COMMISSIONS_ERROR: 'MY_BUSINESS:COMMISSIONS:DOWNLOAD_COMMISSIONS_ERROR',
};

registerBeforeFunction(ACTIONS.DOWNLOAD_COMMISSIONS_START, (store) => { // TODO: necessary?
  trackCustomEvent(downloadCommissionsXlsxV100(store.getState()));
});

const getCommissionsStart = () => ({ type: ACTIONS.GET_COMMISSIONS_START });
const getCommissionsSuccess = commissions => ({ type: ACTIONS.GET_COMMISSIONS_SUCCESS, commissions });
const getCommissionsErrors = errors => ({ type: ACTIONS.GET_COMMISSIONS_ERRORS, errors });
const downloadCommissionsStart = () => ({ type: ACTIONS.DOWNLOAD_COMMISSIONS_START });
const downloadCommissionsSuccess = () => ({ type: ACTIONS.DOWNLOAD_COMMISSIONS_SUCCESS });
const downloadCommissionsErrors = error => ({ type: ACTIONS.DOWNLOAD_COMMISSIONS_ERROR, error });

export const getCommissions = financialYear => async (dispatch, getState) => {
  const businessId = get(getState(), 'business.selectedBusiness.id');
  if (!businessId) {
    return;
  }

  dispatch(getCommissionsStart());
  const startRequestAction = startRequest(ACTIONS.GET_COMMISSIONS_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const results = await graphQL.getCommissions(businessId, financialYear);
    ifValidRequestId(getState().requestIds, ACTIONS.GET_COMMISSIONS_START, requestId, () => {
      dispatch(getCommissionsSuccess(results.data.commissions));
    });
  } catch (error) {
    ifValidRequestId(getState().requestIds, ACTIONS.GET_COMMISSIONS_START, requestId, () => {
      dispatch(getCommissionsErrors(error));
    });
  }
};

export const downloadCommissions = financialYear => (dispatch, getState) => {
  dispatch(closeErrorBanner());
  dispatch(downloadCommissionsStart());
  const businessId = get(getState(), 'business.selectedBusiness.id');
  return downloadCommissionsXlsx(financialYear, businessId)
    .then((xlsxBlob) => {
      dispatch(downloadCommissionsSuccess());
      openInTab(xlsxBlob, `Commissions ${financialYear}.xlsx`);
    })
    .catch((err) => {
      dispatch(showErrorBanner('There was a problem while downloading your xlsx. Please try again later.'));
      dispatch(downloadCommissionsErrors(err));
    });
};

const initialState = {
  isLoading: false,
  upfrontCommissions: [],
  trailCommissions: [],
  xlsx: {
    error: undefined,
    isDownloading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_COMMISSIONS_START:
      return {
        ...state, isLoading: true, errors: null, upfrontCommissions: [],
      };

    case ACTIONS.GET_COMMISSIONS_SUCCESS: {
      const processingMonthsLength = max([
        max(action.commissions.map(commission => commission.upfrontAmounts.length)),
        max(action.commissions.map(commission => commission.trailAmounts.length)),
      ]);

      const upfrontCommissions = action.commissions.filter(commission => commission.upfrontPercentage);
      const trailCommissions = action.commissions.filter(commission => commission.trailPercentage);

      return {
        ...state,
        upfrontCommissions,
        trailCommissions,
        processingMonthsLength,
        isLoading: false,
      };
    }

    case ACTIONS.GET_COMMISSIONS_ERRORS:
      return { ...state, errors: action.errors, isLoading: false };

    case ACTIONS.DOWNLOAD_COMMISSIONS_START:
      return {
        ...state,
        xlsx: {
          error: undefined,
          isDownloading: true,
        },
      };

    case ACTIONS.DOWNLOAD_COMMISSIONS_SUCCESS:
      return {
        ...state,
        xlsx: {
            isDownloading: false,
        },
      };

    case ACTIONS.DOWNLOAD_COMMISSIONS_ERROR:
      return {
        ...state,
        xlsx: {
          isDownloading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
