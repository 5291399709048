import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  change,
  Field, FieldArray, formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import sum from 'lodash/sum';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from './CompanyIncome.module.scss';
import { toAnnualValue } from '../../../../../../utils';
import SectionTitle from '../../../shared/SectionTitle';
import { formatAmountWithDefault } from '../../../../../../../utils/formatters';
import { AddRemoveButtonGroup } from '../EmploymentIncome';
import CompanyIncome from './CompanyIncome';
import { FinancialYearFields } from '../employmentIncomeFields';
import dateUtil from '../dateUtil';
import { FETCHING_STATUS } from '../../../../../../../constants';

const selector = formValueSelector('employmentsForm');

const formatIncomes = (incomes = []) => {
  const allIncomes = incomes.map(income =>
    toAnnualValue('Yearly', income.value)) ?? [];
  return [
    {
      label: 'Annual income',
      displayValue: formatAmountWithDefault(sum(allIncomes)),
      span: 2,
      column: -3,
    },
  ];
};

const CompanyIncomes = ({ fieldNamePrefix, input: { value: incomes }, hasEditPermission }) => {
  const selectedTypes = (incomes || []).map(({ type, addbackType }) => ({ type, addbackType }));

  return (
    <FieldArray
      name={`${fieldNamePrefix}.incomes`}
      component={Incomes}
      hasEditPermission={hasEditPermission}
      selectedTypes={selectedTypes}
    />
  );
};

CompanyIncomes.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
};

const Incomes = ({ fields, selectedTypes, hasEditPermission }) => (
  <>
    {(fields.length === 0 && hasEditPermission) && (
      <AddRemoveButtonGroup
        addFunc={() => fields.push({})}
        removeFunc={noop}
        showAdd
        showRemove={false}
      />
    )}
    {fields.map((field, index) => (
      <CompanyIncome
        key={field}
        fieldNamePrefix={field}
        appendIncome={() => fields.push({ periodUnit: 'Yearly' })}
        removeIncome={() => fields.remove(index)}
        isLastOne={index === fields.length - 1}
        isOnlyOne={fields.length === 1}
        hasEditPermission={hasEditPermission}
        selectedTypes={selectedTypes}
      />
    ))}
  </>
);

Incomes.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  selectedTypes: PropTypes.array.isRequired,
};

const CompanySection = ({
  fieldNamePrefix, hasEditPermission, companyName, incomes, updateField, isFetching,
}) => {
  const employmentsHeader = (
    <Field
      name={`${fieldNamePrefix}.incomes`}
      component={SectionTitle}
      title={companyName}
      format={formatIncomes}
    />
  );

  return (
    <div className={styles.wrapper}>
      <ShowMore
        name={employmentsHeader}
        showMoreClass={styles.showMore}
        headerClassName={styles.actionWrap}
        iconSize="25"
      >
        {!isEmpty(incomes) && !isFetching && (
          <div className={classNames(styles.contentWrapper, styles.sectionWrapper)}>
            <div className={styles.title}>Income</div>
            <FinancialYearFields fieldNamePrefix={fieldNamePrefix} />
            <Field
              fieldNamePrefix={fieldNamePrefix}
              name={`${fieldNamePrefix}.incomes`}
              component={CompanyIncomes}
              hasEditPermission={hasEditPermission}
            />
            {hasEditPermission && (
            <TertiaryButton
              onClick={() => {
                updateField('incomes', []);
                updateField('financialYear', null);
              }}
              className={styles.remove}
            >
              Remove
            </TertiaryButton>
            )}
          </div>
        )}
        {hasEditPermission && isEmpty(incomes) && !isFetching && (
          <div className={styles.btnWrapper}>
            <SecondaryButton
              className={styles.button}
              onClick={() => {
                updateField('financialYear', dateUtil.getCurrentFinancialYear());
                updateField('incomes', [{}]);
              }}
            >
              <span>Add Income</span><Icon name="add" size="18" />
            </SecondaryButton>
          </div>
        )}
      </ShowMore>
    </div>
  );
};

CompanySection.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  incomes: PropTypes.array,
  hasEditPermission: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

CompanySection.defaultProps = {
  incomes: [],
};

export default connect(
  (state, props) => ({
    companyName: selector(state, `${props.fieldNamePrefix}.name`),
    incomes: selector(state, `${props.fieldNamePrefix}.incomes`),
    isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START
      && get(state, 'application.employmentsUpdatingStatus') === FETCHING_STATUS.SUCCESS,
  }),
  (dispatch, props) => ({
    updateField: (field, value = null) =>
      dispatch(change('employmentsForm', `${props.fieldNamePrefix}.${field}`, value)),
  }),
)(CompanySection);
