import get from 'lodash/get';
import { submitCommissionQuery } from '../../api/myBusinessApi';

export const ACTIONS = {
  SHOW_MODAL: 'MY_BUSINESS:COMMISSION_QUERY:SHOW_MODAL',
  CLOSE_MODAL: 'MY_BUSINESS:COMMISSION_QUERY:CLOSE_MODAL',
  SEND_IN_PROGRESS: 'MY_BUSINESS:COMMISSION_QUERY:SEND_IN_PROGRESS',
  SEND_SUCCESS: 'MY_BUSINESS:COMMISSION_QUERY:SEND_SUCCESS',
  SEND_ERROR: 'MY_BUSINESS:COMMISSION_QUERY:SEND_ERROR',
};

export const openCommissionQueryModal = issueId => (dispatch) => {
  dispatch({ type: ACTIONS.SHOW_MODAL, issueId });
};

export const closeCommissionQueryModal = () => (dispatch) => {
  dispatch({ type: ACTIONS.CLOSE_MODAL });
};

export const sendCommissionQuery = (description, queryType, processingMonth) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.SEND_IN_PROGRESS });
  const businessId = get(getState(), 'business.selectedBusiness.id');
  const issueId = get(getState(), 'myBusiness.commissionQuery.issueId');
  const selectedFinancialYear = get(getState(), 'myBusiness.commissions.financialYears.selected');
  try {
    await submitCommissionQuery(issueId, description, queryType, businessId, processingMonth, selectedFinancialYear);
    dispatch({ type: ACTIONS.SEND_SUCCESS });
  } catch (err) {
    dispatch({ type: ACTIONS.SEND_ERROR, error: err });
  }
};

export default (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.SHOW_MODAL:
      return {
        ...state,
        isModalVisible: true,
        issueId: action.issueId,
      };
    case ACTIONS.CLOSE_MODAL:
    case ACTIONS.SEND_SUCCESS:
      return {
        ...state,
        isModalVisible: false,
        issueId: undefined,
        isSending: false,
        hasError: false,
      };
    case ACTIONS.SEND_IN_PROGRESS:
      return {
        ...state,
        isSending: true,
        hasError: false,
      };
    case ACTIONS.SEND_ERROR:
      return {
        ...state,
        isSending: false,
        hasError: true,
      };
    default:
      return state;
  }
};
