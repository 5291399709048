import sum from 'lodash/sum';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import pick from 'lodash/pick';

const getFinalValue = (overrideField) => {
  if (isNil(overrideField)) {
    return null;
  }
  return overrideField.overrideValue ?? overrideField.value;
};

export const calculateTotalPurchaseCosts = (costToPurchase, assetValue) => {
  const valueFields = [
    'adjustmentOfRates',
    'inspectionFee',
  ];
  const overrideFields = [
    'stampDutyOnTransferOfProperty',
    'titleTransferFee',
  ];
  return sum([
    ...map(valueFields, field => costToPurchase[field]),
    ...map(overrideFields, field => getFinalValue(costToPurchase[field])),
    ...map(costToPurchase.otherCosts, 'fieldAmount'),
    assetValue,
  ]) ?? 0;
};

export const calculateTotalConstructionCosts = (construction, constructionAmount) => sum([
  ...map(construction.otherCosts, 'fieldAmount'),
  constructionAmount,
]);

export const calculateTotalRefinances = (refinances, liabilities) =>
  sum([
    ...map(refinances, 'dischargeFee'),
    ...map(flatten(map(refinances, 'others')), 'fieldAmount'),
    ...map(liabilities, 'clearingAmount'),
  ]) ?? 0;

export const calculateTotalApplicationCost = (applicationCosts) => {
  const valueFields = [
    'applicationFee',
    'fundsDisbursement',
    'valuationFee',
    'legalFee',
  ];
  const overrideFields = ['mortgageRegistrationFee'];
  return sum([
    ...map(valueFields, field => applicationCosts[field]),
    ...map(overrideFields, field => getFinalValue(applicationCosts[field])),
    getFinalValue(applicationCosts.lmi),
  ]) ?? 0;
};

export const calculateTotalContributions = (contributions) => {
  const valueFields = [
    'genuineSavings',
    'firstHomeOwnerGrant',
    'gift',
    'initialDepositAmount',
    'netProceedsFromSaleOfProperty',
  ];
  return sum([
    ...map(valueFields, field => contributions[field]),
    ...map(contributions.otherFunds, 'fieldAmount'),
  ]) ?? 0;
};

export const calculateTotalOtherCosts = (otherCosts) => sum(map(otherCosts, 'fieldAmount')) ?? 0;

const getDefaultOtherField = (fields) => (isEmpty(fields) ? [{ fieldName: null, fieldAmount: null }] : fields);

export const mapRefinanceFromStateToFormData = (refinances) => refinances.map((refinance) => {
    const result = pick(refinance, ['id', 'liabilityId', 'dischargeFee', 'others']);
    return {
      ...result,
      others: getDefaultOtherField(result.others),
    };
});

export const mapContributionsFromStateToFormData = (contributions) => {
  const result = pick(contributions,
    ['genuineSavings', 'firstHomeOwnerGrant', 'gift', 'initialDepositAmount', 'netProceedsFromSaleOfProperty', 'otherFunds']);
  return {
    ...result,
    otherFunds: getDefaultOtherField(result.otherFunds),
  };
};

export const mapCostToPurchasesFromStateToFormData = (costToPurchases) => (costToPurchases || [])
  .map((costToPurchase) => {
    const result = pick(costToPurchase,
      ['id', 'assetId', 'stampDutyOnTransferOfProperty', 'titleTransferFee',
        'inspectionFee', 'adjustmentOfRates', 'otherCosts']);
    return {
      ...result,
      stampDutyOnTransferOfProperty: result.stampDutyOnTransferOfProperty || { value: null, overrideValue: null },
      titleTransferFee: result.titleTransferFee || { value: null, overrideValue: null },
      otherCosts: getDefaultOtherField(result.otherCosts),
    };
  });

export const mapConstructionsFromStateToFormData = (constructions) => map(constructions, (construction) => {
  const result = pick(construction, ['id', 'assetId', 'otherCosts']);
  return {
    ...result,
    otherCosts: getDefaultOtherField(result.otherCosts),
  };
});

export const mapLoanAmountFromStateToFormData = (loanAmount) => {
  const result = pick(loanAmount, ['capitaliseLmi', 'baseLoanAmount']);
  return {
    ...result,
    capitaliseLmi: result.capitaliseLmi || false,
  };
};

export const mapOtherCostsFromStateToFormData = getDefaultOtherField;

export const mapApplicationCostsFromStateToFormData = (applicationCosts) => {
  const result = pick(applicationCosts,
    ['mortgageRegistrationFee', 'applicationFee', 'fundsDisbursement', 'valuationFee',
      'legalFee', 'lmi', 'foreignPurchaser', 'eligiblePensioner', 'firstHomeBuyer',
      'lmiReferencedLenderId']);
  return {
    ...result,
    foreignPurchaser: result.foreignPurchaser || false,
    eligiblePensioner: result.eligiblePensioner || false,
    firstHomeBuyer: result.firstHomeBuyer || false,
    lmi: result.lmi || { value: null, overrideValue: null },
    mortgageRegistrationFee: result.mortgageRegistrationFee || { value: null, overrideValue: null },
    lmiReferencedLenderId: result.lmiReferencedLenderId || null,
  };
};
