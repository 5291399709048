const registeredBeforeFunctions = {};
const registeredAfterFunctions = {};

export default store => next => (action) => {
  try {
    const registeredFunction = registeredBeforeFunctions[action.type];
    if (registeredFunction) {
      return next(registeredFunction(store, action) || action);
    }
    return next(action);
  } finally {
    const registeredFunction = registeredAfterFunctions[action.type];
    if (registeredFunction) {
      registeredFunction(store, action);
    }
  }
};

export const registerBeforeFunction = (name, fn) => {
  registeredBeforeFunctions[name] = fn;
};

export const registerAfterFunction = (name, fn) => {
  registeredAfterFunctions[name] = fn;
};
