import { range } from 'utils/datetime';

export const generateDataForWindow = (window, placeholders = {}) => {
  if (!window) {
    return [];
  }
  const { start, end } = window;
  const dates = range(start, end, 'month', 1);
  return dates.map(d => ({
    ...placeholders,
    key: d.format('YYYY-MM'),
  }));
};
