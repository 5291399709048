import React, {
 useContext, useEffect, useMemo, useState,
} from 'react';
import get from 'lodash/get';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import PropTypes from 'prop-types';
import Icon from 'shared/components/Icon/Icon';
import colours from 'shared/theme/colours';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import { connect, useSelector } from 'react-redux';
import { Field, isDirty } from 'redux-form';
import ReduxFormCheckbox from 'shared/components/formFields/ReduxForm/Checkbox';
import { ConfirmContext } from 'shared/components/ConfirmProvider';
import useToggleCheck from 'shared/hooks/useToggleCheck';
import TOGGLES from 'shared/toggles';
import { FETCHING_STATUS } from '../../../../../../constants';
import styles from './sendToApplyOnlineSection.module.scss';
import { generateAOLURL, getNextGenIdentifier } from '../../../../../utils';
import ValidationModal from '../../shared/ValidationModal';
import { applyOnlineValidate, sendToApplyOnline } from '../../../redux';

const missingApplicantMessage = {
  content: ' should be removed from the application as you have removed them from the Client record in Lotus Notes via a change in Marital Status.',
  lowerContent: 'If you continue, you will not be able to update any of their information on the Applicant/s tab in Launchpad or link to their profile.',
  generateDisplayApplicantNames: names => [names.slice(0, -1).join(', '), names.slice(-1)[0]].filter(Boolean).join(' and '),
};

const renderLink = ({ href, text, isNewPage }) => { // eslint-disable-line react/prop-types
  const moreProps = isNewPage ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <a href={href} className={styles.link} {...moreProps}>
      {text}
      <Icon name="share" size="15" color={colours.ocean} />
    </a>
  );
};

const renderApplicationLink = applicationSource => (
  <div className={styles.linksWrapper}>
    <div className={styles.title}>Linked Applications</div>
    {renderLink({
      href: generateAOLURL(getNextGenIdentifier(applicationSource)),
      text: 'Application in ApplyOnline',
      isNewPage: true,
    })}
  </div>
);

const SendToApplyOnlineSection = ({
                                    application,
                                    handleSendToApplyOnline,
                                    handleApplyOnlineValidate,
                                    hasEditPermission,
                                    dirty,
}) => {
  const {
    sentToApplyOnline,
    sendToApplyOnlineStatus,
    sendToApplyOnlineError,
    shouldSendToApplyOnline,
  } = get(application, 'preSubmission');

  const notResidential = get(application, 'loanType') !== 'Home';
  const isSubmitting = sendToApplyOnlineStatus === FETCHING_STATUS.START;
  const applicationId = get(application, 'id');
  const applicationSource = get(application, 'source');
  const [showModal, setShowModal] = useState(false);

  const confirm = useContext(ConfirmContext);
  const deletedApplicantNames = useSelector(state =>
    concat(get(state, 'application.applicationDetail.applicants.persons', []), get(state, 'application.applicationDetail.guarantors.persons', []))
      .filter(person => person.isDeleted)).map(person => [person.firstName, person.surname].filter(Boolean).join(' '));
  const checkApplicantsAndSendToAOL = useMemo(() => () => {
    if (deletedApplicantNames.length > 0) {
      confirm({
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Continue anyway',
        title: 'Missing applicant',
        message: (
          <>
            {missingApplicantMessage.generateDisplayApplicantNames(deletedApplicantNames)}
            {missingApplicantMessage.content}
            <br />
            <br />
            {missingApplicantMessage.lowerContent}
          </>),
        className: styles.missingApplicantModal,
      }).then(cancel => !cancel && handleSendToApplyOnline(applicationId, applicationSource));
    } else {
      handleSendToApplyOnline(applicationId, applicationSource);
    }
  }, [deletedApplicantNames, confirm, handleSendToApplyOnline, applicationId, applicationSource]);

  const isDisableApplyOnline = useToggleCheck(TOGGLES.NO_SEND_TO_APPLYONLINE);
  const isRemoveApplyOnline = useToggleCheck(TOGGLES.REMOVE_APPLYONLINE);
  const sendToApplyOnlineDisabled = !shouldSendToApplyOnline
    || sentToApplyOnline
    || dirty
    || isDisableApplyOnline;

  useEffect(() => {
      setShowModal(sendToApplyOnlineStatus === FETCHING_STATUS.ERROR && !isEmpty(sendToApplyOnlineError));
    }, [sendToApplyOnlineStatus, sendToApplyOnlineError]);

  useEffect(() => {
    setShowModal(false);
  }, []);

  const [isChecking, setIsChecking] = useState(false);

  const checkMissingFields = useMemo(() => appId => {
    setIsChecking(true);
    handleApplyOnlineValidate(appId).then(isSuccessful => {
      setIsChecking(false);
      if (!isSuccessful) {
        setShowModal(true);
      }
    });
  }, [handleApplyOnlineValidate]);

  return (
    <>
      { showModal && (
        <ValidationModal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          description="Please add the following missing information before you can send the application information to ApplyOnline:"
          messages={sendToApplyOnlineError}
        />
      )}
      {hasEditPermission && (!isRemoveApplyOnline || notResidential) && (
        <SecondaryButton
          className={styles.sendToApplyOnlineButton}
          onClick={() => checkMissingFields(applicationId)}
          loading={isChecking && !isDisableApplyOnline}
          disabled={isChecking || isDisableApplyOnline}
        >
          Check missing information
        </SecondaryButton>
      )}
      {hasEditPermission && (!isRemoveApplyOnline || notResidential) && (
        <PrimaryButton
          className={styles.sendToApplyOnlineButton}
          onClick={checkApplicantsAndSendToAOL}
          disabled={sendToApplyOnlineDisabled}
          loading={isSubmitting}
        >
          Send to ApplyOnline
        </PrimaryButton>
      )}

      {(!isRemoveApplyOnline || notResidential) && (
        <Field
          name="preSubmission.shouldSendToApplyOnline"
          format={value => !value}
          normalize={value => !value}
          component={ReduxFormCheckbox}
          labelName="This application will not be sent through ApplyOnline"
          disabled={!hasEditPermission || sentToApplyOnline || isDisableApplyOnline}
          className={styles.notSentThroughApplyOnlineCheckBox}
        />
      )}
      {sentToApplyOnline && renderApplicationLink(applicationSource)}
    </>
  );
};

SendToApplyOnlineSection.propTypes = {
  application: PropTypes.shape({
    preSubmission: PropTypes.shape({
      sentToApplyOnline: PropTypes.bool,
      sendToApplyOnlineStatus: PropTypes.oneOf(Object.values(FETCHING_STATUS)),
      sendToApplyOnlineError: PropTypes.array,
    }).isRequired,
    loanType: PropTypes.string,
  }).isRequired,
  dirty: PropTypes.bool.isRequired,
  handleSendToApplyOnline: PropTypes.func.isRequired,
  handleApplyOnlineValidate: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  application: get(state, 'application.applicationDetail'),
  dirty: isDirty('finalise')(state),
});

export default connect(mapStateToProps, {
  handleSendToApplyOnline: sendToApplyOnline,
  handleApplyOnlineValidate: applyOnlineValidate,
})(SendToApplyOnlineSection);
