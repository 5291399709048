/* eslint-disable no-sparse-arrays */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toBasicCurrency } from 'utils/formatters';
import { mapLogToProps, toUpperCase } from './utils';
import { TRANSACTION_TYPES } from '../../../../redux/constants';
import { isConstruction } from '../../../shared/RealEstateAsset/constants';

const VEHICLE_TYPES_MAP = {
  Bike: 'Bike',
  Large: 'Large',
  LuxuryCar: 'Luxury Car',
  FourWD: '4WD',
  Medium: 'Medium',
  Small: 'Small',
  SmallMedium: 'Small Medium',
};

const RURAL_TYPES_MAP = {
  EightHectaresOrLess: '8 Hectares Or Less',
  OverEightLessThanFourtyHectares: 'Over 8 Less Than 40 Hectares',
  OverFourtyHectares: 'Over 40 Hectares',
};

const getAssetId = origin => {
  const randomPath = origin.eventDetail.changes[0].path;
  return new RegExp('finance.assets.*Estates\\[([\\w-]+)]').exec(randomPath)?.[1];
};

const getTransactionType = origin => {
  const extraInfo = JSON.parse(origin.eventDetail.extraInfo) || {};
  const purchasingIds = get(extraInfo, TRANSACTION_TYPES.PURCHASING) ?? [];
  const assetId = getAssetId(origin);
  return purchasingIds.includes(assetId) ? TRANSACTION_TYPES.PURCHASING : TRANSACTION_TYPES.OWNS;
};

const getResidentialType = origin => {
  const extraInfo = JSON.parse(origin.eventDetail.extraInfo) || {};
  const assetId = getAssetId(origin);
  const assetExtraInfo = get(extraInfo, assetId, {});
  return assetExtraInfo.residentialType;
};

const getSummary = origin => {
  const transactionType = getTransactionType(origin);
  return `Asset #action - ${transactionType}`;
};

const getValueFieldLabel = origin => {
  const residentialType = getResidentialType(origin);
  if (isConstruction(residentialType)) {
    return 'Value (TOC)';
  }
  const transactionType = getTransactionType(origin);
  if (transactionType === TRANSACTION_TYPES.PURCHASING) {
    return 'Purchase price';
  }
  return 'Value';
};

const toBasicCurrencyOrRemoved = (value) => (value === null ? 'removed' : toBasicCurrency(value));
const assetChangeLogTransformer = (origin) => mapLogToProps({
    origin,
    prefix: 'finance.assets.*Estates',
    type: 'ASSETS/SECURITY',
    summary: getSummary(origin),
    fieldMap: {
      asSecurity: ['Use As Security'],
      primaryPurpose: ['Purpose', toUpperCase],
      transferOfLandAmount: ['Purchase price (house construction)', toBasicCurrencyOrRemoved],
      contractPriceAmount: ['Construction amount', toBasicCurrencyOrRemoved],
      assetStatus: ['Status', toUpperCase],
      value: [getValueFieldLabel(origin), toBasicCurrencyOrRemoved],
      currentValue: ['Current value', toBasicCurrencyOrRemoved],
      securityLimit: ['Security limit', toBasicCurrency],
      periodUnit: ['Rental Frequency'],
      rentalAmount: ['Rental Income', toBasicCurrency],
      valuationType: [, toUpperCase],
      residentialType: [, toUpperCase],
      ruralType: [, value => RURAL_TYPES_MAP[value]],
      industrialType: [, toUpperCase],
      commercialType: [, toUpperCase],
      assetType: [, toUpperCase],
      isPreApproval: ['PRE APPROVAL', value => (value === 'true' ? 'YES' : 'NO')],
      vehicleType: [, value => VEHICLE_TYPES_MAP[value]],
      investmentPropertyCostValue: ['Investment property costs', toBasicCurrency],
      investmentPropertyCostFrequency: ['Costs frequency'],
      residents: ['Residents at settlement'],
      isForConstructionLoan: ['Apply for construction loan'],
    },
    handleChanges: changes => {
      const securityChangeValue = changes.find(({ path }) => path.includes('asSecurity'))?.value === 'true';
      const others = changes.filter(({ path }) => !/(ownerships|address|preApprovalStatus|investmentPropertyCost|residents)/.test(path))
        .filter(({ path }) => !(/(isPreApproval)/.test(path) && !securityChangeValue));
      const addressChanges = changes.filter(({ path }) => path.includes('address'));
      const propertyCostValueChange = changes.find(({ path }) => path.includes('investmentPropertyCost.value'));
      const propertyCostFrequencyChange = changes.find(({ path }) => path.includes('investmentPropertyCost.frequency'));
      const hasAddressChanges = addressChanges.length > 0;
      const isAddressRemoved = addressChanges.every(item => isEmpty(item.value));
      const hasOwnershipsChanges = changes.some(({ path }) => path.includes('ownerships'));
      const hasResidentsChanges = changes.some(({ path }) => path.includes('residents'));

    return [
      ...others,
      hasOwnershipsChanges && { path: '*.ownership', value: 'updated' },
      hasResidentsChanges && { path: '*.residents', value: 'updated' },
      hasAddressChanges && { path: '*.address', value: isAddressRemoved ? 'removed' : 'updated' },
      propertyCostValueChange && { path: '*.investmentPropertyCostValue', value: propertyCostValueChange.value },
      propertyCostFrequencyChange && { path: '*.investmentPropertyCostFrequency', value: propertyCostFrequencyChange.value },
      ];
    },
  });

export default assetChangeLogTransformer;
