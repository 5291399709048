import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="SvgIcon/arrowUp"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path"
        fill={color}
        transform="translate(12.000000, 11.889000) rotate(-180.000000) translate(-12.000000, -11.889000) "
        points="12 12.95 16.95 8 18.364 9.414 12 15.778 5.636 9.414 7.05 8"
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
