import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';
import React from 'react';
import { filterFormsyProps } from 'shared/components/formFields/Formsy/utils';
import TextInput from 'shared/components/formFields/TextInput';

const FormsyTextInput = ({
     setValue,
     value,
     disabled,
     isFormDisabled,
     errorMessage,
     showRequired,
     ...otherProps
}) => {
    const requiredMessage = showRequired ? 'This field is required' : undefined;
    return (
      <TextInput
        {...filterFormsyProps(otherProps)}
        onChange={(v) => {
          setValue(v || null);
        }}
        disabled={isFormDisabled || disabled}
        value={value || ''}
        errorMessage={errorMessage || requiredMessage}
      />
    );
  };

FormsyTextInput.defaultProps = {
  className: '',
  onChange: () => {},
  disabled: false,
  errorMessage: undefined,
  value: undefined,
};

FormsyTextInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  isFormDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showRequired: PropTypes.bool.isRequired,
};

export default withFormsy(FormsyTextInput);
