import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';
import React from 'react';
import { filterFormsyProps } from 'shared/components/formFields/Formsy/utils';
import DatePicker from 'shared/components/formFields/DatePicker/DatePicker';
import moment from 'moment';

export const formatDate = (date) => {
  if (!date) {
    return date;
  }
  return moment(date).format('YYYY-MM-DD');
};

const Input = ({
 setValue, value, isFormDisabled, disabled, errorMessage, showRequired, ...otherProps
}) => {
  const requiredMessage = showRequired ? 'This field is required' : undefined;
  return (
    <DatePicker
      {...filterFormsyProps(otherProps)}
      onChange={v => setValue(formatDate(v))}
      disabled={isFormDisabled || disabled}
      value={value || ''}
      errorMessage={errorMessage || requiredMessage}
    />
  );
};

Input.defaultProps = {
  disabled: false,
  errorMessage: undefined,
  value: undefined,
};

Input.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isFormDisabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool.isRequired,
};

export default withFormsy(Input);
