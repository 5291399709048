import { change, Field, Fields } from 'redux-form';
import values from 'lodash/values';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import styles from '../PurchasingAsset.module.scss';
import RealEstateAsset from '../../../shared/RealEstateAsset';
import { OWNER_OCCUPIED } from '../../../shared/RealEstateAsset/constants';
import ConstructionFieldsForOwnedAsset from '../ConstructionFieldsForOwnedAsset';
import { STATUS_OPTIONS } from '../constants';
import ResidentsSelector from '../ResidentsSelector';

const OwnedAssetForConstructionLoan = ({
 securityForm, isConstructionResidentialType, isOwnerOccupied, ...props
}) => {
  const {
    editable, fieldNamePrefix, applicants,
  } = props;
  const reduxDispatch = useDispatch();
  // eslint-disable-next-line
  const contractPriceFieldsName = {
    currentValue: `${fieldNamePrefix}.currentValue`,
    contractPrice: `${fieldNamePrefix}.contractPrice.contractPriceAmount`,
  };
  const handlePurposeChange = useCallback((value) => {
    const residents = value === OWNER_OCCUPIED ? applicants.map(a => a.id) : null;
    reduxDispatch(change(securityForm, `${fieldNamePrefix}.residents`, residents));
  }, [applicants, fieldNamePrefix, reduxDispatch, securityForm]);

  const handlePropertyTypeChange = useCallback(() =>
      values(contractPriceFieldsName).forEach(fieldName => reduxDispatch(change(securityForm, fieldName, null))),
    [contractPriceFieldsName, reduxDispatch, securityForm]);

  return (
    <div className={styles.container}>
      <FormMetaProvider readOnly>
        <RealEstateAsset
          {...props}
          onPurposeChange={handlePurposeChange}
          onPropertyTypeChange={handlePropertyTypeChange}
          valueLabel="Purchase price"
          displayGuarantors={false}
          ownershipRowSpan={5}
          valueEditable={editable}
        >
          {isConstructionResidentialType && (
            <Fields
              names={values(contractPriceFieldsName)}
              component={ConstructionFieldsForOwnedAsset}
              contractPriceFieldsName={contractPriceFieldsName}
              hidePurchasePrice
              constructionAmountEditable={editable}
            />
          )}
          <FormMetaProvider readOnly={!editable}>
            <Field
              name={`${fieldNamePrefix}.assetStatus`}
              component={ReduxFormSelect}
              label="Status"
              className={styles.statusField}
              options={STATUS_OPTIONS}
            />
            {isOwnerOccupied && (
              <ResidentsSelector
                fieldNamePrefix={fieldNamePrefix}
                applicants={applicants}
              />

            )}
          </FormMetaProvider>
        </RealEstateAsset>
      </FormMetaProvider>
    </div>
  );
};

OwnedAssetForConstructionLoan.propTypes = {
  editable: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  securityForm: PropTypes.string.isRequired,
  isConstructionResidentialType: PropTypes.bool.isRequired,
  isOwnerOccupied: PropTypes.bool.isRequired,
};

export default OwnedAssetForConstructionLoan;
