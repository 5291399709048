import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import round from 'lodash/round';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { getTwelveMonthsFrom } from '../../utils/commissionSummaryDateHelper';
import { FETCHING_STATUS } from '../../../../constants';

const getLoanBookRunOffRateOfPeriod = (startBalance, runoff) => {
  if (!startBalance) {
    return 'N/A';
  }
  const bookRunOffRate = (runoff * 100) / startBalance;
  return `${round(bookRunOffRate, 2)}%`;
};

const getLoanBookRunOff = (startBalance, settlements, closeBalance) =>
  (startBalance || 0) + (settlements || 0) - (closeBalance || 0);

const getTotalSettlementsOfPeriod = (dateList, loansByMonth) => {
  if (!loansByMonth) return undefined;
  return dateList.filter(date => loansByMonth[date])
  .map((date) => loansByMonth[date].reduce((acc, cur) => acc + cur.amount, 0))
  .reduce((acc, cur) => acc + cur, 0);
};

const getRunOffRateOfYear = (runOffRateMeta, loansByMonth) => {
  if (!runOffRateMeta || isEmpty(runOffRateMeta)) {
    return {};
  }
  const { startingBalance, closingBalance } = runOffRateMeta;
  const curYearlyTotalSettlements = getTotalSettlementsOfPeriod(
    getTwelveMonthsFrom(startingBalance.date),
    loansByMonth,
  );
  const runoff = getLoanBookRunOff(
    startingBalance.loanBookSize,
    curYearlyTotalSettlements,
    closingBalance.loanBookSize,
  );
  const runOffRate = getLoanBookRunOffRateOfPeriod(
    startingBalance.loanBookSize, runoff,
  );
  return {
    runoff,
    runOffRate,
    startDate: startingBalance.date,
    endDate: closingBalance.date,
  };
};

const getRunOffOfMonth = (runoffObject) => {
  const { runoff, endDate } = runoffObject;
  if (isNil(runoff)) {
    return {};
  }
  return {
    runOff: round(runoff / 12),
    date: endDate,
  };
};

const summaryReportSelector = createSelector(
  state => ({
    commissions: state.reports.commissionSummary,
  }),
  ({
     commissions: { fetchingStatus, meta, loansByMonth },
   }) => {
    if (fetchingStatus !== FETCHING_STATUS.SUCCESS) {
      return {
        fetchingStatus,
      };
    }
    if (!meta || isEmpty(meta)) return { fetchingStatus };

    const currentLoanBookObject = { ...meta.currentYearLoanBookSize };

    const lastOneYearLoanBookObject = { ...meta.lastYearLoanBookSize };

    const curYearlyRunOffRateObject = getRunOffRateOfYear(meta.currentYearlyRunOffRate, loansByMonth);

    const lastOneYearlyRunOffRateObject = getRunOffRateOfYear(meta.lastYearYearlyRunOffRate, loansByMonth);

    const curMonthlyRunOffObject = getRunOffOfMonth(curYearlyRunOffRateObject);

    const lastOneMonthlyRunOffObject = getRunOffOfMonth(lastOneYearlyRunOffRateObject);

    return {
      fetchingStatus,
      currentLoanBookObject,
      lastOneYearLoanBookObject,
      curYearlyRunOffRateObject,
      lastOneYearlyRunOffRateObject,
      curMonthlyRunOffObject,
      lastOneMonthlyRunOffObject,
    };
  },
);

const settlementSummarySelector = createSelector(
  state => ({
    settlementSummaryReport: state.reports.settlementSummaryReport,
  }),
  ({
     settlementSummaryReport: {
       fetchingStatus,
       loansByMonth,
       start,
       end,
     },
   }) => {
    if (fetchingStatus !== FETCHING_STATUS.SUCCESS) {
      return {
        fetchingStatus,
      };
    }
    const curDate = moment(end).format('YYYY-MM');
    const preDate = moment(start).format('YYYY-MM');
    const curMonthSettledApp = loansByMonth[curDate];
    const preMonthSettledApp = loansByMonth[preDate];
    const curSettlementApplicationNumber = uniqBy(curMonthSettledApp, 'applicationId').length;

    return {
      fetchingStatus,
      settlementSummary: {
        curDate,
        preDate,
        curAmount: curMonthSettledApp.reduce((acc, cur) => acc + cur.amount, 0),
        preAmount: preMonthSettledApp.reduce((acc, cur) => acc + cur.amount, 0),
        curSettlementApplicationNumber,
      },
    };
  },
);

export {
  summaryReportSelector,
  settlementSummarySelector,
  getLoanBookRunOffRateOfPeriod,
  getTotalSettlementsOfPeriod,
};
