import React from 'react';
import { render } from 'react-dom';
import AppWithStore from './AppWithStore';
import newRelic from './config/newRelic';
import initializeTealium from './config/tealium';

newRelic();
initializeTealium();

render(<AppWithStore />, document.getElementById('root'));
