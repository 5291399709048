const OCCUPATION_DISPLAY_VALUE_MAP = {
  '1111-11': 'Parliamentarian or Councillor',
  '1111-13': 'Judge',
  '1111-15': 'Magistrate',
  '1111-17': 'Tribunal Member',
  '1111-79': 'Legislators and Government Appointed Officials nec',
  '1112-11': 'General Manager',
  '1191-11': 'Construction Project Manager',
  '1191-13': 'Project Builder',
  '1192-11': 'Importer or Exporter',
  '1192-13': 'Wholesaler',
  '1193-11': 'Manufacturer',
  '1211-11': 'Finance Manager',
  '1212-11': 'Company Secretary',
  '1213-11': 'Human Resource Manager',
  '1221-11': 'Engineering Manager',
  '1222-11': 'Production Manager (Manufacturing)',
  '1222-13': 'Production Manager (Mining)',
  '1223-11': 'Supply and Distribution Manager',
  '1224-11': 'Information Technology Manager',
  '1231-11': 'Sales and Marketing Manager',
  '1291-11': 'Policy and Planning Manager',
  '1292-11': 'Director of Nursing',
  '1292-13': 'Medical Administrator',
  '1293-11': 'School Principal',
  '1293-13': 'Faculty Head',
  '1293-15': 'Regional Education Manager',
  '1293-79': 'Education Managers nec',
  '1294-11': 'Commissioned Defence Force Officer',
  '1294-13': 'Commissioned Fire Officer',
  '1294-15': 'Commissioned Police Officer',
  '1294-81': 'Trainee Commissioned Defence Force Officer',
  '1295-11': 'Child Care Co-ordinator',
  '1296-11': 'Media Producer',
  '1296-13': 'Artistic Director',
  '1299-11': 'Research and Development Manager',
  '1299-13': 'Laboratory Manager',
  '1299-15': 'Welfare Centre Manager',
  '1299-17': 'Environment, Parks and Land Care Manager',
  '1299-19': 'Sports Administrator',
  '1299-79': 'Specialist Managers nec',
  '1311-11': 'Mixed Crop and Livestock Farmer',
  '1312-11': 'Mixed Livestock Farmer',
  '1312-13': 'Beef Cattle Farmer',
  '1312-15': 'Dairy Farmer',
  '1312-17': 'Sheep Farmer',
  '1312-19': 'Pig Farmer',
  '1312-21': 'Poultry Farmer',
  '1312-23': 'Horse Breeder',
  '1312-25': 'Apiarist',
  '1312-79': 'Livestock Farmers nec',
  '1313-11': 'Grain, Oilseed and Pasture Grower',
  '1313-13': 'Sugar Cane Grower',
  '1313-15': 'Tobacco Grower',
  '1313-17': 'Fruit and Nut Grower',
  '1313-19': 'Vegetable Grower',
  '1313-21': 'Flower Grower',
  '1313-79': 'Crop Farmers nec',
  '1314-11': 'Aquaculture Farmer',
  '2111-11': 'Chemist',
  '2112-11': 'Geologist',
  '2112-13': 'Geophysicist',
  '2113-11': 'Anatomist or Physiologist',
  '2113-13': 'Botanist',
  '2113-15': 'Zoologist',
  '2113-17': 'Biochemist',
  '2113-19': 'Marine Biologist',
  '2113-79': 'Life Scientists nec',
  '2114-11': 'Environmental Research Scientist',
  '2114-13': 'Forester',
  '2114-15': 'Park Ranger',
  '2114-17': 'Soil Scientist',
  '2114-19': 'Agricultural Scientist',
  '2114-21': 'Agricultural Adviser',
  '2114-79': 'Environmental and Agricultural Science Professionals nec',
  '2115-11': 'Medical Scientist',
  '2119-11': 'Physicist',
  '2119-13': 'Meteorologist',
  '2119-15': 'Extractive Metallurgist',
  '2119-17': 'Physical Metallurgist',
  '2119-19': 'Materials Scientist',
  '2119-79': 'Natural and Physical Science Professionals nec',
  '2121-11': 'Architect',
  '2121-13': 'Landscape Architect',
  '2122-11': 'Quantity Surveyor',
  '2123-11': 'Cartographer',
  '2123-13': 'Surveyor',
  '2124-11': 'Civil Engineer',
  '2125-11': 'Electrical Engineer',
  '2125-13': 'Electronics Engineer',
  '2126-11': 'Mechanical Engineer',
  '2126-13': 'Production or Plant Engineer',
  '2127-11': 'Mining Engineer (excluding Petroleum)',
  '2127-13': 'Petroleum Engineer',
  '2127-15': 'Materials Engineer',
  '2128-11': 'Civil Engineering Technologist',
  '2128-13': 'Mechanical Engineering Technologist',
  '2128-15': 'Electrical or Electronics Engineering Technologist',
  '2128-79': 'Engineering Technologists nec',
  '2129-11': 'Aeronautical Engineer',
  '2129-13': 'Agricultural Engineer',
  '2129-15': 'Biomedical Engineer',
  '2129-17': 'Chemical Engineer',
  '2129-19': 'Industrial Engineer',
  '2129-21': 'Naval Architect',
  '2129-79': 'Building and Engineering Professionals nec',
  '2211-11': 'Accountant',
  '2212-11': 'External Auditor',
  '2212-13': 'Internal Auditor',
  '2213-11': 'Corporate Treasurer',
  '2221-11': 'Public Relations Officer',
  '2221-13': 'Marketing Specialist',
  '2221-15': 'Market Research Analyst',
  '2221-17': 'Advertising Specialist',
  '2222-11': 'Sales Representative (Industrial Products)',
  '2222-13': 'Sales Representative (Information and Communication Products)',
  '2222-15': 'Sales Representative (Medical and Pharmaceutical Products)',
  '2222-79': 'Technical Sales Representatives nec',
  '2231-11': 'Systems Manager',
  '2231-13': 'Systems Designer',
  '2231-15': 'Software Designer',
  '2231-17': 'Applications and Analyst Programmer',
  '2231-19': 'Systems Programmer',
  '2231-21': 'Computer Systems Auditor',
  '2231-79': 'Computing Professionals nec',
  '2291-11': 'Personnel Officer',
  '2291-13': 'Personnel Consultant',
  '2291-15': 'Industrial Relations Officer',
  '2291-17': 'Training Officer',
  '2292-11': 'Librarian',
  '2293-11': 'Mathematician',
  '2293-13': 'Statistician',
  '2293-15': 'Actuary',
  '2294-11': 'Management Consultant',
  '2294-13': 'Organisation and Methods Analyst',
  '2294-15': 'Quality Assurance Manager',
  '2295-11': 'Valuer',
  '2295-13': 'Land Economist',
  '2299-11': 'Health Information Manager',
  '2299-13': 'Records Manager',
  '2299-15': 'Archivist',
  '2299-17': 'Policy Analyst',
  '2299-19': 'Intelligence Officer',
  '2299-79': 'Business and Information Professionals nec',
  '2311-11': 'General Medical Practitioner',
  '2311-81': 'Medical Practitioner in Training',
  '2312-11': 'Anaesthetist',
  '2312-13': 'Dermatologist',
  '2312-15': 'Emergency Medicine Specialist',
  '2312-17': 'Obstetrician and Gynaecologist',
  '2312-19': 'Ophthalmologist',
  '2312-21': 'Paediatrician',
  '2312-23': 'Pathologist',
  '2312-25': 'Specialist Physician',
  '2312-27': 'Psychiatrist',
  '2312-29': 'Radiologist',
  '2312-31': 'Surgeon',
  '2312-79': 'Specialist Medical Practitioners nec',
  '2321-11': 'Nurse Manager',
  '2322-11': 'Nurse Educator',
  '2322-13': 'Nurse Researcher',
  '2323-11': 'Registered Nurse',
  '2324-11': 'Registered Midwife',
  '2325-11': 'Registered Mental Health Nurse',
  '2326-11': 'Registered Developmental Disability Nurse',
  '2381-11': 'Dentist',
  '2381-13': 'Dental Specialist',
  '2382-11': 'Hospital Pharmacist',
  '2382-13': 'Industrial Pharmacist',
  '2382-15': 'Retail Pharmacist',
  '2383-11': 'Occupational Therapist',
  '2384-11': 'Optometrist',
  '2385-11': 'Physiotherapist',
  '2386-11': 'Speech Pathologist',
  '2387-11': 'Chiropractor',
  '2387-13': 'Osteopath',
  '2388-11': 'Podiatrist',
  '2391-11': 'Medical Diagnostic Radiographer',
  '2391-13': 'Radiation Therapist',
  '2391-15': 'Nuclear Medicine Technologist',
  '2391-17': 'Sonographer',
  '2392-11': 'Veterinarian',
  '2393-11': 'Dietitian',
  '2394-11': 'Naturopath',
  '2394-13': 'Acupuncturist',
  '2394-79': 'Natural Therapy Professionals nec',
  '2399-11': 'Audiologist',
  '2399-13': 'Orthoptist',
  '2399-15': 'Orthotist',
  '2399-79': 'Health Professionals nec',
  '2411-11': 'Pre-Primary School Teacher',
  '2412-11': 'Primary School Teacher',
  '2413-11': 'Secondary School Teacher',
  '2414-11': 'Special Needs Teacher',
  '2414-13': 'Teacher of the Hearing Impaired',
  '2414-15': 'Teacher of the Sight Impaired',
  '2414-79': 'Special Education Teachers nec',
  '2421-11': 'University Lecturer',
  '2421-13': 'University Tutor',
  '2422-11': 'Vocational Education Teacher',
  '2491-11': 'Art Teacher (Private)',
  '2491-13': 'Music Teacher (Private)',
  '2491-15': 'Dance Teacher (Private)',
  '2491-17': 'Drama Teacher (Private)',
  '2491-79': 'Extra-Systemic Teachers nec',
  '2492-11': 'English as a Second Language Teacher',
  '2493-11': 'Education Officer',
  '2511-11': 'Social Worker',
  '2512-11': 'Welfare Worker',
  '2512-13': 'Community Worker',
  '2513-11': 'Rehabilitation Counsellor',
  '2513-13': 'Drug and Alcohol Counsellor',
  '2513-15': 'Family Counsellor',
  '2513-17': 'Careers Counsellor',
  '2513-19': 'Student Counsellor',
  '2513-79': 'Counsellors nec',
  '2514-11': 'Clinical Psychologist',
  '2514-13': 'Educational Psychologist',
  '2514-15': 'Organisational Psychologist',
  '2514-79': 'Psychologists nec',
  '2515-11': 'Minister of Religion',
  '2521-11': 'Barrister',
  '2521-13': 'Solicitor',
  '2521-79': 'Legal Professionals nec',
  '2522-11': 'Economist',
  '2523-11': 'Urban and Regional Planner',
  '2529-11': 'Historian',
  '2529-13': 'Interpreter',
  '2529-15': 'Translator',
  '2529-79': 'Social Professionals nec',
  '2531-11': 'Painter (Visual Arts)',
  '2531-13': 'Sculptor',
  '2531-15': 'Potter or Ceramic Artist',
  '2531-79': 'Visual Arts and Crafts Professionals nec',
  '2532-11': 'Photographer',
  '2533-11': 'Fashion Designer',
  '2533-13': 'Graphic Designer',
  '2533-15': 'Industrial Designer',
  '2533-17': 'Interior Designer',
  '2533-19': 'Illustrator',
  '2534-11': 'Editor',
  '2534-13': 'Print Journalist',
  '2534-15': 'Television Journalist',
  '2534-17': 'Radio Journalist',
  '2534-19': 'Copywriter',
  '2534-21': 'Technical Writer',
  '2534-79': 'Journalists and Related Professionals nec',
  '2535-11': 'Author',
  '2535-13': 'Book Editor',
  '2535-15': 'Script Editor',
  '2536-11': 'Art Director (Film, Television or Stage)',
  '2536-13': 'Director (Film, Television, Radio or Stage)',
  '2536-15': 'Director of Photography',
  '2536-17': 'Film and Video Editor',
  '2536-19': 'Stage Manager',
  '2536-21': 'Program Director (Radio or Television)',
  '2536-23': 'Technical Director',
  '2536-79': 'Film, Television, Radio and Stage Directors nec',
  '2537-11': 'Music Director',
  '2537-13': 'Singer',
  '2537-15': 'Instrumental Musician',
  '2537-17': 'Composer',
  '2537-79': 'Musicians and Related Professionals nec',
  '2538-11': 'Actor',
  '2538-13': 'Dancer or Choreographer',
  '2538-79': 'Actors, Dancers and Related Professionals nec',
  '2539-11': 'Radio Presenter',
  '2539-13': 'Television Presenter',
  '2541-11': 'Aircraft Pilot',
  '2541-13': 'Air Traffic Controller',
  '2541-15': 'Flight Service Officer',
  '2541-17': 'Flight Engineer',
  '2541-19': 'Flying Instructor',
  '2541-79': 'Air Transport Professionals nec',
  '2542-11': 'Ship\'s Master',
  '2542-13': 'Master Fisher',
  '2542-15': 'Ship\'s Engineer',
  '2542-17': 'Ship\'s Surveyor',
  '2542-19': 'Ship\'s Officer',
  '2542-79': 'Sea Transport Professionals nec',
  '2543-11': 'Occupational Health and Safety Officer',
  '2543-13': 'Environmental Health Officer',
  '2549-11': 'Conservator',
  '2549-13': 'Electorate Officer',
  '2549-15': 'Patents Examiner',
  '2549-17': 'Oenologist',
  '2549-19': 'Recreation Officer',
  '2549-21': 'Museum or Gallery Curator',
  '2549-79': 'Professionals nec',
  '3111-11': 'Medical Laboratory Technical Officer',
  '3111-79': 'Medical Technical Officers nec',
  '3112-11': 'Chemistry Technical Officer',
  '3112-13': 'Earth Science Technical Officer',
  '3112-15': 'Life Science Technical Officer',
  '3112-17': 'Agricultural Technical Officer',
  '3112-79': 'Science Technical Officers nec',
  '3121-11': 'Building Associate',
  '3121-13': 'Architectural Associate',
  '3121-15': 'Surveying and Cartographic Associate',
  '3121-17': 'Building Inspector',
  '3121-19': 'Plumbing Inspector',
  '3121-21': 'Plumbing Engineering Associate',
  '3122-11': 'Civil Engineering Associate',
  '3122-13': 'Civil Engineering Technician',
  '3123-11': 'Electrical Engineering Associate',
  '3123-13': 'Electrical Engineering Technician',
  '3124-11': 'Electronic Engineering Associate',
  '3124-13': 'Electronic Engineering Technician',
  '3125-11': 'Mechanical Engineering Associate',
  '3125-13': 'Mechanical Engineering Technician',
  '3129-11': 'Biomedical Engineering Associate',
  '3129-13': 'Metallurgical and Materials Technician',
  '3129-15': 'Mine Deputy',
  '3129-79': 'Building and Engineering Associate Professionals nec',
  '3211-11': 'Branch Accountant (Financial Institution)',
  '3211-13': 'Financial Institution Branch Manager',
  '3212-11': 'Stockbroking Dealer',
  '3212-13': 'Futures Trader',
  '3212-15': 'Financial Market Dealer',
  '3212-17': 'Commodities Trader',
  '3212-19': 'Insurance Broker',
  '3212-21': 'Bookmaker',
  '3212-79': 'Financial Dealers and Brokers nec',
  '3213-11': 'Financial Investment Adviser',
  '3291-11': 'Office Manager',
  '3292-11': 'Project or Program Administrator',
  '3293-11': 'Real Estate Agency Manager',
  '3293-13': 'Property Manager',
  '3293-15': 'Real Estate Salesperson',
  '3294-11': 'Computing Support Technician',
  '3311-11': 'Shop Manager',
  '3321-11': 'Restaurant and Catering Manager',
  '3322-01': 'Head Chef',
  '3322-11': 'Chef',
  '3323-11': 'Hotel or Motel Manager',
  '3324-11': 'Club Manager (Licensed Premises)',
  '3325-11': 'Caravan Park and Camping Ground Manager',
  '3329-11': 'Other Hospitality and Accommodation Managers',
  '3391-11': 'Fitness Centre Manager',
  '3391-13': 'Other Sports Centre Manager',
  '3391-15': 'Amusement Centre Manager',
  '3391-79': 'Sport and Recreation Managers nec',
  '3392-11': 'Customer Service Manager',
  '3393-11': 'Transport Company Manager',
  '3399-11': 'Post Office Manager',
  '3399-13': 'Railway Station Manager',
  '3399-15': 'Betting Agency Branch Manager',
  '3399-17': 'Hair and Beauty Salon Manager',
  '3399-19': 'Car Rental Agency Manager',
  '3399-21': 'Fleet Manager',
  '3399-23': 'Stock and Station Agent',
  '3399-25': 'Travel Agency Manager',
  '3399-27': 'Theatre or Cinema Manager',
  '3399-29': 'Funeral Director',
  '3399-79': 'Managing Supervisors (Sales and Service) nec',
  '3411-11': 'Enrolled Nurse',
  '3421-11': 'Parole or Probation Officer',
  '3421-13': 'Youth Worker',
  '3421-15': 'Residential Care Officer',
  '3421-17': 'Disabilities Services Officer',
  '3421-19': 'Family Support Worker',
  '3491-11': 'Ambulance Officer',
  '3491-13': 'Intensive Care Ambulance Paramedic',
  '3492-11': 'Dental Therapist',
  '3492-13': 'Dental Hygienist',
  '3492-15': 'Dental Technician',
  '3493-11': 'Aboriginal and Torres Strait Islander Health Worker',
  '3494-11': 'Massage Therapist',
  '3911-01': 'Supervisor, Police Officers',
  '3911-11': 'Police Officer',
  '3991-11': 'Primary Products Inspector',
  '3992-11': 'Safety Inspector',
  '3993-11': 'Jockey',
  '3993-13': 'Golfer',
  '3993-15': 'Footballer',
  '3993-17': 'Other Sportsperson',
  '3993-19': 'Gymnastics Coach',
  '3993-21': 'Tennis Coach',
  '3993-23': 'Swimming Coach',
  '3993-25': 'Horseriding Coach',
  '3993-27': 'Other Sports Coach',
  '3993-29': 'Sports Development Officer',
  '3993-31': 'Sports Umpire',
  '3993-33': 'Horse or Dog Racing Official',
  '3993-35': 'Other Sports Official',
  '3994-11': 'Senior Non-Commissioned Defence Force Officer',
  '3995-11': 'Senior Fire Fighter',
  '3996-11': 'Retail Buyer',
  '3997-11': 'Library Technician',
  '3999-11': 'Interior Decorator',
  '3999-13': 'Museum or Art Gallery Technician',
  '3999-15': 'Radio Operator',
  '3999-17': 'Private Investigator',
  '3999-19': 'Security Adviser',
  '3999-79': 'Associate Professionals nec',
  '4111-01': 'Supervisor, General Mechanical Engineering Tradespersons',
  '4111-11': 'General Mechanical Engineering Tradesperson',
  '4111-81': 'Apprentice General Mechanical Engineering Tradesperson',
  '4112-01': 'Supervisor, Metal Fitters and Machinists',
  '4112-11': 'Fitter',
  '4112-13': 'Metal Machinist (First Class)',
  '4112-15': 'Textile, Clothing or Footwear Mechanic',
  '4112-81': 'Apprentice Fitter',
  '4112-83': 'Apprentice Metal Machinist',
  '4112-85': 'Apprentice Textile, Clothing or Footwear Mechanic',
  '4113-01': 'Supervisor, Toolmakers',
  '4113-11': 'Toolmaker',
  '4113-81': 'Apprentice Toolmaker',
  '4114-01': 'Supervisor, Aircraft Maintenance Engineers',
  '4114-11': 'Aircraft Maintenance Engineer (Mechanical)',
  '4114-13': 'Aircraft Maintenance Engineer (Structures)',
  '4114-15': 'Aircraft Maintenance Engineer (Avionics)',
  '4114-81': 'Apprentice Aircraft Maintenance Engineer (Mechanical)',
  '4114-83': 'Apprentice Aircraft Maintenance Engineer (Structures)',
  '4114-85': 'Apprentice Aircraft Maintenance Engineer (Avionics)',
  '4115-01': 'Supervisor, Precision Metal Tradespersons',
  '4115-11': 'Precision Instrument Maker and Repairer',
  '4115-13': 'Watch and Clock Maker and Repairer',
  '4115-15': 'Locksmith',
  '4115-17': 'Saw Maker and Repairer',
  '4115-19': 'Gunsmith',
  '4115-21': 'Engraver',
  '4115-81': 'Apprentice Precision Metal Tradesperson',
  '4121-01': 'Supervisor, General Fabrication Engineering Tradespersons',
  '4121-11': 'General Fabrication Engineering Tradesperson',
  '4121-81': 'Apprentice General Fabrication Engineering Tradesperson',
  '4122-01': 'Supervisor, Structural Steel and Welding Tradespersons',
  '4122-11': 'Metal Fabricator',
  '4122-13': 'Pressure Welder',
  '4122-15': 'Welder (First Class)',
  '4122-81': 'Apprentice Metal Fabricator',
  '4122-83': 'Apprentice Welder',
  '4123-01': 'Supervisor, Forging Tradespersons',
  '4123-11': 'Blacksmith',
  '4123-13': 'Farrier',
  '4123-81': 'Apprentice Blacksmith',
  '4123-83': 'Apprentice Farrier',
  '4124-01': 'Supervisor, Sheetmetal Tradespersons',
  '4124-11': 'Sheetmetal Worker (First Class)',
  '4124-81': 'Apprentice Sheetmetal Worker',
  '4125-01': 'Supervisor, Metal Casting Tradespersons',
  '4125-11': 'Metal Casting Tradesperson',
  '4125-81': 'Apprentice Metal Casting Tradesperson',
  '4126-01': 'Supervisor, Metal Finishing Tradespersons',
  '4126-11': 'Metal Polisher',
  '4126-13': 'Electroplater (First Class)',
  '4126-81': 'Apprentice Metal Polisher',
  '4126-83': 'Apprentice Electroplater',
  '4211-01': 'Supervisor, Motor Mechanics',
  '4211-11': 'Motor Mechanic',
  '4211-81': 'Apprentice Motor Mechanic',
  '4212-01': 'Supervisor, Automotive Electricians',
  '4212-11': 'Automotive Electrician',
  '4212-81': 'Apprentice Automotive Electrician',
  '4213-01': 'Supervisor, Panel Beaters',
  '4213-11': 'Panel Beater',
  '4213-81': 'Apprentice Panel Beater',
  '4214-01': 'Supervisor, Vehicle Painters',
  '4214-11': 'Vehicle Painter',
  '4214-81': 'Apprentice Vehicle Painter',
  '4215-01': 'Supervisor, Vehicle Body Makers',
  '4215-11': 'Vehicle Body Maker',
  '4215-81': 'Apprentice Vehicle Body Maker',
  '4216-01': 'Supervisor, Vehicle Trimmers',
  '4216-11': 'Vehicle Trimmer',
  '4216-81': 'Apprentice Vehicle Trimmer',
  '4311-01': 'Supervisor, Electricians',
  '4311-11': 'General Electrician',
  '4311-13': 'Electrician (Special Class)',
  '4311-15': 'Lift Mechanic',
  '4311-81': 'Apprentice Electrician',
  '4311-83': 'Apprentice Lift Mechanic',
  '4312-01': 'Supervisor, Refrigeration and Airconditioning Mechanics',
  '4312-11': 'Refrigeration and Airconditioning Mechanic',
  '4312-81': 'Apprentice Refrigeration and Airconditioning Mechanic',
  '4313-01': 'Supervisor, Electrical Distribution Tradespersons',
  '4313-11': 'Electrical Powerline Tradesperson',
  '4313-13': 'Cable Jointer',
  '4313-81': 'Apprentice Electrical Powerline Tradesperson',
  '4313-83': 'Apprentice Cable Jointer',
  '4314-01': 'Supervisor, Electronic Instrument Tradespersons',
  '4314-11': 'General Electronic Instrument Tradesperson',
  '4314-13': 'Electronic Instrument Tradesperson (Special Class)',
  '4314-81': 'Apprentice Electronic Instrument Tradesperson',
  '4315-01': 'Supervisor, Electronic and Office Equipment Tradespersons',
  '4315-11': 'Electronic Equipment Tradesperson',
  '4315-13': 'Business Machine Mechanic',
  '4315-81': 'Apprentice Electronic Equipment Tradesperson',
  '4315-83': 'Apprentice Business Machine Mechanic',
  '4316-01': 'Supervisor, Communications Tradespersons',
  '4316-11': 'General Communications Tradesperson',
  '4316-13': 'Communications Linesperson',
  '4316-81': 'Apprentice General Communications Tradesperson',
  '4316-83': 'Apprentice Communications Linesperson',
  '4411-01': 'Supervisor, Carpentry and Joinery Tradespersons',
  '4411-11': 'Carpenter and Joiner',
  '4411-13': 'Carpenter',
  '4411-15': 'Joiner',
  '4411-81': 'Apprentice Carpenter and Joiner',
  '4411-83': 'Apprentice Carpenter',
  '4411-85': 'Apprentice Joiner',
  '4412-01': 'Supervisor, Fibrous Plasterers',
  '4412-11': 'Fibrous Plasterer',
  '4412-81': 'Apprentice Fibrous Plasterer',
  '4413-01': 'Supervisor, Roof Slaters and Tilers',
  '4413-11': 'Roof Slater and Tiler',
  '4413-81': 'Apprentice Roof Slater and Tiler',
  '4414-01': 'Supervisor, Bricklayers',
  '4414-11': 'Bricklayer',
  '4414-81': 'Apprentice Bricklayer',
  '4415-01': 'Supervisor, Solid Plasterers',
  '4415-11': 'Solid Plasterer',
  '4415-81': 'Apprentice Solid Plasterer',
  '4416-01': 'Supervisor, Wall and Floor Tilers and Stonemasons',
  '4416-11': 'Wall and Floor Tiler',
  '4416-13': 'Stonemason',
  '4416-81': 'Apprentice Wall and Floor Tiler',
  '4416-83': 'Apprentice Stonemason',
  '4421-01': 'Supervisor, Painters and Decorators',
  '4421-11': 'Painter and Decorator',
  '4421-81': 'Apprentice Painter and Decorator',
  '4422-01': 'Supervisor, Signwriters',
  '4422-11': 'Signwriter',
  '4422-81': 'Apprentice Signwriter',
  '4423-01': 'Supervisor, Floor Finishers',
  '4423-11': 'Floor Finisher',
  '4423-81': 'Apprentice Floor Finisher',
  '4431-01': 'Supervisor, Plumbers',
  '4431-11': 'General Plumber',
  '4431-13': 'Gasfitter',
  '4431-15': 'Drainer',
  '4431-17': 'Roof Plumber',
  '4431-19': 'Mechanical Services and Airconditioning Plumber',
  '4431-81': 'Apprentice General Plumber',
  '4431-83': 'Apprentice Gasfitter',
  '4431-85': 'Apprentice Drainer',
  '4431-87': 'Apprentice Roof Plumber',
  '4431-89': 'Apprentice Mechanical Services and Airconditioning Plumber',
  '4511-01': 'Supervisor, Meat Tradespersons',
  '4511-11': 'Butcher',
  '4511-13': 'Smallgoods Maker',
  '4511-15': 'Slaughterperson',
  '4511-81': 'Apprentice Butcher',
  '4511-83': 'Apprentice Smallgoods Maker',
  '4511-85': 'Apprentice Slaughterperson',
  '4512-01': 'Supervisor, Bakers and Pastrycooks',
  '4512-11': 'Baker',
  '4512-13': 'Pastrycook',
  '4512-81': 'Apprentice Baker',
  '4512-83': 'Apprentice Pastrycook',
  '4513-11': 'Cook',
  '4513-81': 'Apprentice Cook or Chef',
  '4519-11': 'Miller',
  '4519-13': 'Buttermaker or Cheesemaker',
  '4519-15': 'Confectioner',
  '4519-81': 'Apprentice Miller',
  '4519-83': 'Apprentice Buttermaker or Cheesemaker',
  '4519-85': 'Apprentice Confectioner',
  '4611-11': 'Farm Overseer',
  '4612-11': 'Shearer',
  '4613-11': 'Wool Classer',
  '4613-13': 'Hide and Skin Classer',
  '4614-11': 'Horse Trainer',
  '4614-79': 'Animal Trainers nec',
  '4621-11': 'Nurseryperson',
  '4621-81': 'Apprentice Nurseryperson',
  '4622-11': 'Greenkeeper',
  '4622-81': 'Apprentice Greenkeeper',
  '4623-01': 'Head Gardener',
  '4623-11': 'General Gardener',
  '4623-13': 'Landscape Gardener',
  '4623-15': 'Tree Surgeon',
  '4623-81': 'Apprentice General Gardener',
  '4623-83': 'Apprentice Landscape Gardener',
  '4623-85': 'Apprentice Tree Surgeon',
  '4911-11': 'Graphic Pre-Press Tradesperson',
  '4911-81': 'Apprentice Graphic Pre-Press Tradesperson',
  '4912-11': 'Printing Machinist',
  '4912-13': 'Small Offset Printer',
  '4912-81': 'Apprentice Printing Machinist',
  '4912-83': 'Apprentice Small Offset Printer',
  '4913-11': 'Binder and Finisher',
  '4913-81': 'Apprentice Binder and Finisher',
  '4914-11': 'Screen Printer',
  '4914-81': 'Apprentice Screen Printer',
  '4921-11': 'Wood Machinist (A-Grade)',
  '4921-13': 'Wood Turner',
  '4921-81': 'Apprentice Wood Machinist',
  '4921-83': 'Apprentice Wood Turner',
  '4922-01': 'Supervisor, Cabinetmakers',
  '4922-11': 'Cabinetmaker',
  '4922-81': 'Apprentice Cabinetmaker',
  '4929-11': 'Picture Framer',
  '4929-13': 'Furniture Finisher',
  '4929-79': 'Wood Tradespersons nec',
  '4929-81': 'Apprentice Picture Framer',
  '4929-83': 'Apprentice Furniture Finisher',
  '4929-99': 'Apprentice Wood Tradespersons nec',
  '4931-01': 'Supervisor, Hairdressers',
  '4931-11': 'Hairdresser',
  '4931-81': 'Apprentice Hairdresser',
  '4941-11': 'General Clothing Tradesperson',
  '4941-13': 'Tailor',
  '4941-15': 'Dressmaker',
  '4941-17': 'Apparel Cutter',
  '4941-19': 'Patternmaker-Grader (Clothing)',
  '4941-79': 'Clothing Tradespersons nec',
  '4941-81': 'Apprentice Clothing Tradesperson',
  '4942-11': 'Furniture Upholsterer',
  '4942-79': 'Upholsterers and Bedding Tradespersons nec',
  '4942-81': 'Apprentice Upholsterer or Bedding Tradespersons',
  '4943-11': 'Shoemaker',
  '4943-13': 'Medical Grade Shoemaker',
  '4943-81': 'Apprentice Shoemaker',
  '4944-11': 'Leather Goods Maker',
  '4944-13': 'Canvas Goods Maker',
  '4944-15': 'Sail Maker',
  '4944-81': 'Apprentice Leather Goods, Canvas Goods or Sail Maker',
  '4981-11': 'Shipwright',
  '4981-13': 'Boat Builder and Repairer',
  '4981-81': 'Apprentice Shipwright',
  '4981-83': 'Apprentice Boat Builder and Repairer',
  '4982-11': 'Flat Glass Tradesperson',
  '4982-13': 'Glass Blower',
  '4982-81': 'Apprentice Flat Glass Tradesperson',
  '4982-83': 'Apprentice Glass Blower',
  '4983-11': 'Jeweller',
  '4983-13': 'Gem Cutter and Polisher',
  '4983-81': 'Apprentice Jeweller',
  '4983-83': 'Apprentice Gem Cutter and Polisher',
  '4984-11': 'Florist',
  '4985-11': 'Fire Fighter',
  '4986-01': 'Supervisor, Drillers',
  '4986-11': 'Driller',
  '4987-01': 'Supervisor, Chemical, Petroleum and Gas Plant Operators',
  '4987-11': 'Chemical Plant Operator',
  '4987-13': 'Petroleum and Gas Plant Operator',
  '4988-01': 'Supervisor, Power Generation Plant Operators',
  '4988-11': 'Power Generation Plant Operator',
  '4991-11': 'Defence Force Member Not Elsewhere Included',
  '4991-81': 'Trainee Defence Force Member Not Elsewhere Included',
  '4992-11': 'Sound Technician',
  '4992-13': 'Camera Operator (Film, Television or Video)',
  '4992-15': 'Television Equipment Operator',
  '4992-17': 'Broadcast Transmitter Operator',
  '4992-19': 'Motion Picture Projectionist',
  '4992-21': 'Light Technician',
  '4992-23': 'Production Assistant (Film, Television or Radio)',
  '4992-25': 'Production Assistant (Theatre)',
  '4992-27': 'Make Up Artist',
  '4992-79': 'Performing Arts Support Workers nec',
  '4999-11': 'Optical Mechanic',
  '4999-13': 'Diver',
  '4999-15': 'Aircraft Safety Equipment Worker',
  '4999-17': 'Piano Tuner',
  '4999-79': 'Tradespersons and Related Workers nec',
  '5111-11': 'Secretary',
  '5111-13': 'Personal Assistant',
  '5911-11': 'Bookkeeper',
  '5912-11': 'Credit and Loans Officer',
  '5991-11': 'Clerk of Court',
  '5991-13': 'Law Clerk',
  '5991-15': 'Trust Officer',
  '5992-11': 'Court or Hansard Reporter',
  '5993-11': 'Insurance Agent',
  '5994-11': 'Insurance Risk Surveyor',
  '5994-13': 'Insurance Investigator',
  '5994-15': 'Insurance Loss Adjuster',
  '5995-11': 'Desktop Publishing Operator',
  '5996-11': 'Flight Service Director',
  '5996-13': 'Flight Attendant',
  '5996-79': 'Travel Attendants nec',
  '5999-11': 'Radio Despatcher',
  '5999-13': 'Auctioneer',
  '5999-15': 'Visual Merchandiser',
  '5999-17': 'Photographer\'s Assistant',
  '6111-11': 'General Clerk',
  '6121-11': 'Typist and Word Processing Operator',
  '6121-13': 'Data Entry Operator',
  '6131-11': 'Receptionist',
  '6141-01': 'Supervisor, Accounting Clerks',
  '6141-11': 'Accounts Clerk',
  '6141-13': 'Credit Clerk',
  '6141-15': 'Cost Clerk',
  '6142-11': 'Payroll Clerk',
  '6143-01': 'Supervisor, Bank Workers',
  '6143-11': 'Bank Worker',
  '6144-01': 'Supervisor, Insurance Clerks',
  '6144-11': 'Insurance Clerk',
  '6145-01': 'Supervisor, Money Market and Statistical Clerks',
  '6145-11': 'Money Market Clerk',
  '6145-13': 'Statistical Clerk',
  '6151-11': 'Production Recording Clerk',
  '6152-01': 'Supervisor, Transport and Despatching Clerks',
  '6152-11': 'Receiving and Despatching Clerk',
  '6152-13': 'Import-Export Clerk',
  '6153-01': 'Supervisor, Stock and Purchasing Clerks',
  '6153-11': 'Stock Clerk',
  '6153-13': 'Purchasing Officer',
  '6153-15': 'Order Clerk',
  '6153-17': 'Sales Clerk',
  '6191-11': 'Inquiry Clerk',
  '6191-13': 'Admissions Clerk',
  '6192-11': 'Library Assistant',
  '6193-11': 'Personnel Records Clerk',
  '6193-13': 'Employment Office Clerk',
  '6194-11': 'Customs Inspector',
  '6194-13': 'Taxation Inspector',
  '6194-15': 'Social Security Assessor',
  '6194-17': 'Motor Vehicle Licence Examiner',
  '6194-19': 'Transport Operations Inspector',
  '6194-21': 'Train Examiner',
  '6194-23': 'Water Inspector',
  '6194-79': 'Intermediate Inspectors and Examiners nec',
  '6199-11': 'Proof Reader',
  '6199-13': 'Debt Collector',
  '6199-15': 'Mail Supervisor',
  '6199-17': 'Court Orderly',
  '6199-19': 'Bailiff or Sheriff',
  '6199-21': 'Coding Clerk',
  '6199-79': 'Intermediate Clerical Workers nec',
  '6211-11': 'Sales Representative (Personal and Household Goods)',
  '6211-13': 'Sales Representative (Business Services)',
  '6211-15': 'Sales Representative (Builder\'s and Plumber\'s Supplies)',
  '6211-17': 'Sales Representative (Motor Vehicle Parts and Accessories)',
  '6211-79': 'Sales Representatives nec',
  '6212-11': 'Motor Vehicle and Caravan Salesperson',
  '6212-13': 'Motor Vehicle Parts Interpreter',
  '6213-11': 'Retail Supervisor',
  '6213-13': 'Checkout Supervisor',
  '6311-11': 'Pre-School Aide',
  '6311-13': 'Integration Aide',
  '6311-15': 'Teachers\' Aide',
  '6311-17': 'Aboriginal and Torres Strait Islander Education Worker',
  '6312-11': 'Child Care Worker',
  '6312-13': 'Family Day Care Worker',
  '6312-15': 'Nanny',
  '6313-11': 'Hostel Parent',
  '6313-13': 'Child or Youth Residential Care Assistant',
  '6313-15': 'Refuge Worker',
  '6313-17': 'Aged or Disabled Person Carer',
  '6313-19': 'Therapy Aide',
  '6314-11': 'Personal Care Assistant',
  '6314-13': 'Nursing Assistant',
  '6321-11': 'Hotel Service Supervisor',
  '6322-01': 'Supervisor, Bar Attendants',
  '6322-11': 'Bar Attendant',
  '6323-01': 'Supervisor, Waiters',
  '6323-11': 'General Waiter',
  '6323-13': 'Drink Waiter',
  '6324-11': 'Hospitality Trainee',
  '6391-11': 'Dental Assistant',
  '6392-11': 'Veterinary Nurse',
  '6393-11': 'Prison Officer',
  '6394-11': 'Gaming Pit Boss',
  '6394-13': 'Gaming Table Supervisor',
  '6394-15': 'Gaming Dealer',
  '6395-11': 'Beauty Therapist',
  '6395-13': 'Natural Remedy Consultant',
  '6395-15': 'Weight Loss Consultant',
  '6396-11': 'Fitness Instructor',
  '6396-13': 'Outdoor Adventure Leader',
  '6397-11': 'Travel Agent',
  '6397-13': 'Tourist Information Officer',
  '6397-15': 'Tour Guide',
  '6399-11': 'Museum or Gallery Attendant',
  '6399-13': 'Driving Instructor',
  '6399-15': 'Animal Attendant',
  '6399-17': 'Pest and Weed Controller',
  '6399-79': 'Intermediate Service Workers nec',
  '7111-11': 'General Construction Plant Operator',
  '7111-13': 'Bulldozer Operator',
  '7111-15': 'Backhoe Operator',
  '7111-17': 'Loader Operator',
  '7111-19': 'Grader Operator',
  '7111-21': 'Excavator Operator',
  '7111-23': 'Paving Plant Operator',
  '7111-25': 'Road Roller Operator',
  '7112-11': 'Forklift Driver',
  '7119-11': 'Agricultural and Horticultural Mobile Plant Operator',
  '7119-13': 'Railway Track Repair Mobile Plant Operator',
  '7119-15': 'Streetsweeper Operator',
  '7119-17': 'Logging Plant Operator',
  '7119-79': 'Mobile Plant Operators nec',
  '7121-11': 'Engine or Boiler Operator',
  '7122-11': 'Crane, Hoist or Lift Operator',
  '7123-01': 'Supervisor, Engineering Production Systems Workers',
  '7123-11': 'Engineering Production Systems Worker',
  '7124-11': 'Pulp Mill Operator',
  '7124-13': 'Paper Mill Operator',
  '7129-01': 'Supervisor, Other Intermediate Stationary Plant Operators',
  '7129-11': 'Railway Signal Operator',
  '7129-13': 'Train Controller',
  '7129-15': 'Concrete Pump Operator',
  '7129-17': 'Cement Production Plant Operator',
  '7129-19': 'Concrete Batching Plant Operator',
  '7129-21': 'Water and Waste Water Plant Operator',
  '7129-23': 'Bulk Materials Handling Plant Operator',
  '7129-79': 'Intermediate Stationary Plant Operators nec',
  '7211-01': 'Supervisor, Sewing Machinists',
  '7211-11': 'Sewing Machinist',
  '7212-01': 'Supervisor, Textile and Footwear Production Machine Operators',
  '7212-11': 'Yarn Carding and Spinning Machine Operator',
  '7212-13': 'Weaving Machine Operator',
  '7212-15': 'Knitting Machine Operator',
  '7212-17': 'Textile Dyeing and Finishing Machine Operator',
  '7212-19': 'Hide and Skin Processing Machine Operator',
  '7212-21': 'Footwear Production Machine Operator',
  '7212-79': 'Textile and Footwear Production Machine Operators nec',
  '7291-01': 'Supervisor, Plastics Production Machine Operators',
  '7291-11': 'General Plastics Production Machine Operator',
  '7291-13': 'Plastics Compounding and Reclamation Machine Operator',
  '7291-15': 'Reinforced Plastic and Composite Production Worker',
  '7291-17': 'Plastic Cablemaking Machine Operator',
  '7291-79': 'Plastics Production Machine Operators nec',
  '7292-01': 'Supervisor, Rubber Production Machine Operators',
  '7292-11': 'Tyre Production Machine Operator',
  '7292-79': 'Rubber Production Machine Operators nec',
  '7293-01': 'Supervisor, Chemical Production Machine Operators',
  '7293-11': 'Chemical Production Machine Operator',
  '7294-01': 'Supervisor, Wood Processing Machine Operators',
  '7294-11': 'Wood Processing Machine Operator',
  '7295-11': 'Paper Products Machine Operator',
  '7296-01': 'Supervisor, Glass Production Machine Operators',
  '7296-11': 'Glass Production Machine Operator',
  '7297-01': 'Supervisor, Clay, Stone and Concrete Processing Machine Operators',
  '7297-11': 'Clay Products Machine Operator',
  '7297-13': 'Stone Processing Machine Operator',
  '7297-15': 'Concrete Products Machine Operator',
  '7297-79': 'Clay, Stone and Concrete Processing Machine Operators nec',
  '7298-11': 'Photographic Developer and Printer',
  '7299-11': 'Industrial Spray Painter',
  '7299-79': 'Intermediate Machine Operators nec',
  '7311-11': 'Heavy Truck Driver',
  '7311-13': 'Furniture Removalist',
  '7312-11': 'Bus Driver',
  '7312-13': 'Tram Driver',
  '7313-11': 'Automobile Driver',
  '7314-11': 'Delivery Driver',
  '7315-11': 'Train Driver',
  '7315-13': 'Train Driver\'s Assistant',
  '7911-11': 'Miner',
  '7912-11': 'Blasting Worker',
  '7913-11': 'Scaffolder',
  '7913-13': 'Steel Fixer',
  '7913-15': 'Structural Steel Erector',
  '7913-17': 'Construction Rigger',
  '7914-11': 'Building Insulation Installer',
  '7914-13': 'Home Improvements Installer',
  '7991-11': 'Motor Vehicle Parts and Accessories Fitter',
  '7992-11': 'Product Examiner',
  '7992-13': 'Product Grader',
  '7992-15': 'Product Tester',
  '7993-01': 'Supervisor, Storepersons',
  '7993-11': 'Storeperson',
  '7994-11': 'Seafarer',
  '7994-13': 'Fishing Hand',
  '7995-01': 'Supervisor, Forestry and Logging Workers',
  '7995-11': 'Tree Faller',
  '7995-13': 'Forestry Worker',
  '7995-15': 'Logging Assistant',
  '7996-11': 'Printing Table Hand',
  '7996-13': 'Printer\'s Assistant',
  '8111-11': 'Registry or Filing Clerk',
  '8112-11': 'Mail Clerk',
  '8112-13': 'Postal Sorting Officer',
  '8113-11': 'Switchboard Operator',
  '8114-11': 'Courier',
  '8114-13': 'Postal Delivery Officer',
  '8115-11': 'Betting Agency Counter Clerk',
  '8115-13': 'Telephone Betting Clerk',
  '8115-15': 'Bookmaker\'s Clerk',
  '8115-79': 'Betting Clerks nec',
  '8116-11': 'Office Trainee',
  '8119-11': 'Meter Reader',
  '8119-13': 'Classified Advertising Clerk',
  '8119-15': 'Interviewer',
  '8119-17': 'Parking Inspector',
  '8119-79': 'Elementary Clerks nec',
  '8211-11': 'Sales Assistant (Food and Drink Products)',
  '8211-13': 'Sales Assistant (Fabric, Clothing and Footwear)',
  '8211-15': 'Sales Assistant (Other Personal and Household Goods)',
  '8211-17': 'Sales Assistant (Postal Services)',
  '8211-79': 'Sales Assistants nec',
  '8291-11': 'Checkout Operator',
  '8291-13': 'Office Cashier',
  '8292-11': 'Ticket Seller',
  '8292-13': 'Transport Conductor',
  '8293-11': 'Street Vendor',
  '8293-13': 'Cash Van Salesperson',
  '8293-15': 'Door-to-Door Salesperson',
  '8294-11': 'Telemarketer',
  '8295-11': 'Sales Demonstrator',
  '8295-13': 'Model',
  '8296-11': 'Service Station Attendant',
  '8297-11': 'Sales and Service Trainee',
  '8299-11': 'Rental Salesperson',
  '8299-13': 'Materials Recycler',
  '8299-79': 'Elementary Sales Workers nec',
  '8311-11': 'Security Officer',
  '8311-13': 'Armoured Car Escort',
  '8311-79': 'Guards and Security Officers nec',
  '8312-11': 'Ticket Collector or Usher',
  '8312-13': 'Luggage Porter or Doorperson',
  '8313-11': 'Domestic Housekeeper',
  '8314-11': 'Caretaker',
  '8315-11': 'Laundry Worker',
  '8319-11': 'Railways Assistant',
  '8319-13': 'Hair and Beauty Salon Assistant',
  '8319-15': 'Sterilisation Technology Worker',
  '8319-17': 'Car Park Attendant',
  '8319-19': 'Crossing Supervisor',
  '8319-21': 'Trolley Collector',
  '8319-23': 'Leaflet and Newspaper Deliverer',
  '8319-25': 'Examination Supervisor',
  '8319-27': 'Prostitute or Escort',
  '8319-79': 'Elementary Service Workers nec',
  '9111-11': 'Commercial Cleaner',
  '9111-13': 'Domestic Cleaner',
  '9111-15': 'Carpet Cleaner',
  '9111-17': 'Vehicle Cleaner',
  '9111-19': 'Window Cleaner',
  '9111-79': 'Cleaners nec',
  '9211-11': 'Engineering Production Process Worker',
  '9212-11': 'Product Assembler',
  '9213-11': 'Meatworks Labourer',
  '9213-13': 'Meat Boner and Slicer',
  '9213-15': 'Poultry Process Worker',
  '9213-17': 'Fish Process Worker',
  '9214-11': 'Food and Drink Processing Machine Attendant',
  '9214-13': 'Baking Factory Hand',
  '9214-15': 'Dairy Factory Hand',
  '9214-17': 'Fruit and Vegetable Factory Hand',
  '9214-19': 'Food Products Millhand',
  '9214-79': 'Food Factory Hands nec',
  '9215-11': 'Sawmill Labourer',
  '9215-13': 'Timberyard Labourer',
  '9215-15': 'Wood and Wood Products Factory Hand',
  '9215-17': 'Pulp and Paper Mill General Hand',
  '9219-11': 'Cement and Concrete Plant Labourer',
  '9219-13': 'Clay Processing Factory Hand',
  '9219-15': 'Chemical Plant Labourer',
  '9219-17': 'Rubber and Plastics Factory Hand',
  '9219-19': 'Hide and Skin Processing Labourer',
  '9219-21': 'Glass Processing Labourer',
  '9219-23': 'Fabric and Textile Factory Hand',
  '9219-25': 'Footwear Factory Hand',
  '9219-79': 'Process Workers nec',
  '9221-11': 'Hand Packer',
  '9222-11': 'Packager and Container Filler',
  '9911-11': 'Mining Support Worker',
  '9911-13': 'Driller\'s Assistant',
  '9912-11': 'Earthmoving Labourer',
  '9913-11': 'Paving and Surfacing Labourer',
  '9914-11': 'Survey Hand',
  '9915-11': 'Railway Labourer',
  '9916-11': 'Construction Assistant',
  '9916-13': 'Plumber\'s Assistant',
  '9917-11': 'Concreter',
  '9918-11': 'Electrical or Telecommunications Trades Assistant',
  '9919-11': 'Lagger',
  '9919-13': 'Crane Chaser',
  '9919-15': 'Fence Erector',
  '9921-11': 'General Farm Hand',
  '9921-13': 'Fruit, Vegetable or Nut Farm Hand',
  '9921-15': 'Stud Hand or Stable Hand',
  '9921-17': 'Shearing Shed Hand',
  '9921-79': 'Farm Hands nec',
  '9922-11': 'Horticultural Nursery Assistant',
  '9922-13': 'Garden Labourer',
  '9929-11': 'Shooter-Trapper',
  '9929-13': 'Rural Trainee',
  '9929-79': 'Agricultural and Horticultural Labourers nec',
  '9931-11': 'Kitchenhand',
  '9932-11': 'Fast Food Cook',
  '9933-11': 'Pastrycook\'s Assistant',
  '9933-79': 'Food Trades Assistants nec',
  '9991-11': 'Garbage Collector',
  '9992-11': 'Freight Handler (Road and Rail)',
  '9992-13': 'Waterside Worker',
  '9992-15': 'Truck Driver\'s Offsider',
  '9993-11': 'Handyperson',
  '9999-11': 'Vending Machine Attendant',
  '9999-13': 'Sign Erector',
  '9999-15': 'Mechanic\'s Assistant',
  '9999-17': 'Stormwater, Drainage and Sewerage System Labourer',
  '9999-79': 'Labourers and Related Workers nec',
};

export default OCCUPATION_DISPLAY_VALUE_MAP;
