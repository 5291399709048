import React from 'react';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import PropType from 'prop-types';
import styles from './styles.module.scss';

const UpdateFixedTrailButton = ({
                                  onClick,
                                  disabled,
                                  text,
                                }) => (
                                  <SecondaryButton
                                    disabled={disabled}
                                    className={styles.updateFixedTrailButton}
                                    onClick={onClick}
                                  >
                                    <span>{text}</span>
                                  </SecondaryButton>
  );

UpdateFixedTrailButton.propTypes = {
  onClick: PropType.func.isRequired,
  disabled: PropType.bool,
  text: PropType.string.isRequired,
};

UpdateFixedTrailButton.defaultProps = {
  disabled: false,
};

export default UpdateFixedTrailButton;
