import React from 'react';
import isUndefined from 'lodash/isUndefined';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from './styles';
import { formatAttribute } from './utils';
import { modifiableAttributes } from './constants';

const isModifiable = attributeName => modifiableAttributes.includes(attributeName);

const getOptionalOriginalValueFragment = (attributeName, variant) => {
  const { modificationOriginals } = variant;
  const originalValue = (modificationOriginals || {})[attributeName];
  const modified = !isUndefined(originalValue) && variant[attributeName] !== originalValue;

  return modified && (
    <>
      (
      <span style={{ textDecoration: 'line-through' }} className="content-cell__original-value">
        {formatAttribute(attributeName, { ...variant, ...modificationOriginals })}
      </span>
      )
    </>
  );
};

export const getCellContent = (row, { variant, optionIndex, splitIndex }, onFieldEditing) => {
  const { rowName, props: { readOnly } } = row;
  const onFieldEditingClick = () => onFieldEditing(variant, rowName, optionIndex, splitIndex);
  /* eslint-disable
        jsx-a11y/no-static-element-interactions,
        jsx-a11y/click-events-have-key-events,
    */
  const valueFragment = isModifiable(rowName)
    ? (
      <TertiaryButton style={styles.modifiableField} onClick={onFieldEditingClick} disabled={readOnly}>
        <span className="content-cell__value--modifiable" style={styles.modifiableField.text}>{formatAttribute(rowName, variant)}</span>
      </TertiaryButton>
    )
    : <span className="content-cell__value--unmodifiable" style={{ marginLeft: '.5rem' }}>{formatAttribute(rowName, variant)}</span>;
  /*  eslint-enable */

  return (
    <>
      {getOptionalOriginalValueFragment(rowName, variant)}
      {valueFragment}
    </>
  );
};

export default {
  getCellContent,
};
