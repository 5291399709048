import React from 'react';
import { Report } from '../Report';
import { Tooltip } from './Tooltip';
import { perspectivesForSubmission } from '../viewPerspectives';

export const SubmissionReport = props => (
  <Report
    perspectives={perspectivesForSubmission}
    title="Applications Submitted"
    toolTipComponent={Tooltip}
    {...props}
  />
);
