import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import { change, Field, getFormValues } from 'redux-form';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import map from 'lodash/map';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import PreferenceEditor from './components/PreferenceEditor';
import { containOther, isImportant } from './utils';
import {
  INTEREST_IN_ADVANCE_REASON_OPTION,
  INTEREST_ONLY_REASON_OPTION,
  LINE_OF_CREDIT_REASON_OPTION,
  PRINCIPAL_AND_INTEREST_REASON_OPTION,
  REPAYMENT_FREQUENCY,
  REPAYMENT_PLAN_OPTION,
  REPAYMENT_TYPE_PREFERRED_DURATION_OPTION,
} from './constants';

const principalAndInterestProps = {
  importantDegree: {
    label: 'Principal and interest',
  },
  reason: {
    options: PRINCIPAL_AND_INTEREST_REASON_OPTION,
  },
};

const interestOnlyProps = {
  importantDegree: {
    label: 'Interest only',
  },
  reason: {
    options: INTEREST_ONLY_REASON_OPTION,
  },
};

const interestInAdvanceProps = {
  importantDegree: {
    label: 'Interest in advance',
  },
  reason: {
    options: INTEREST_IN_ADVANCE_REASON_OPTION,
  },
};

const lineOfCreditProps = {
  importantDegree: {
    label: 'Line of credit',
  },
  reason: {
    options: LINE_OF_CREDIT_REASON_OPTION,
  },
};

const repaymentFrequencyField = (namePrefix) => (
  <Field
    component={ReduxFormSelect}
    name={`${namePrefix}.frequency`}
    label="Repayment frequency"
    options={REPAYMENT_FREQUENCY}
    className={styles.select}
  />
);

const principalAndInterestExtraRender = (args) => {
  const { importantDegree, namePrefix } = args;
  return isImportant(importantDegree?.input.value) && repaymentFrequencyField(namePrefix);
};

const interestOnlyExtraRender = (args) => {
  const { importantDegree, namePrefix } = args;
  return isImportant(importantDegree?.input.value) && (
    <div className={styles.selectWrapper}>
      {repaymentFrequencyField(namePrefix)}
      <Field
        component={ReduxFormSelect}
        name={`${namePrefix}.preferredDuration`}
        label="Interest only period"
        options={REPAYMENT_TYPE_PREFERRED_DURATION_OPTION}
        className={styles.select}
      />
    </div>
  );
};

const valueRender = (value) => (value?.length > 1 ? 'Multiple'
  : find(REPAYMENT_PLAN_OPTION, item => item.id === value[0])?.name);

const LineOfCreditExtraRender = ({ importantDegree, namePrefix }) => {
  const reduxFormValueSelector = (formName, path, defaultValue) =>
    state => get(getFormValues(formName)(state), path, defaultValue);
  const repaymentPlans = useSelector(reduxFormValueSelector('needsAndObjectivesForm', 'repayment.lineOfCredit.repaymentPlans', null));
  const dispatch = useDispatch();
  return (
    <div>
      {
        isImportant(importantDegree?.input.value) && (
          <Field
            component={ReduxFormCheckboxDropdown}
            name={`${namePrefix}.repaymentPlans`}
            label="Repayment plan"
            options={REPAYMENT_PLAN_OPTION}
            className={styles.multipleSelect}
            inputProps={{ className: styles.input }}
            onChange={(v) => {
              if (!containOther(v)) {
                dispatch(change('needsAndObjectivesForm', `${namePrefix}.repaymentPlanOtherDetail`, null));
              }
            }}
            valueRender={valueRender}
            readOnlyFormatter={value => {
              const values = map(value, id => find(REPAYMENT_PLAN_OPTION, { id }));
              return (
                <div className={styles.readOnlyOptions}>
                  {values.map(({ name, id }) => (
                    <div key={id}>{name}</div>
                  ))}
                </div>
              );
            }}
            buttonText="Select"
          />
        )
      }
      {
        containOther(repaymentPlans) && (
        <Field
          component={ReduxFormTextArea}
          isRequired
          name={`${namePrefix}.repaymentPlanOtherDetail`}
          label="Other detail"
          rows="1"
          className={styles.otherDetail}
        />
        )
      }
    </div>
);
};
LineOfCreditExtraRender.propTypes = {
  importantDegree: PropTypes.object.isRequired,
  namePrefix: PropTypes.string.isRequired,
};
const RepaymentType = () => {
  const dispatch = useDispatch();
  const clearPrincipalAndInterestRepaymentFrequency = useCallback(() => dispatch(change('needsAndObjectivesForm', 'repayment.principalAndInterest.frequency', null)), [dispatch]);
  const clearRepaymentFrequencyAndPeriod = useCallback(() => {
    dispatch(change('needsAndObjectivesForm', 'repayment.interestOnly.frequency', null));
    dispatch(change('needsAndObjectivesForm', 'repayment.interestOnly.preferredDuration', null));
  }, [dispatch]);
  const clearRepaymentPlan = useCallback(() => {
    dispatch(change('needsAndObjectivesForm', 'repayment.lineOfCredit.repaymentPlans', null));
    dispatch(change('needsAndObjectivesForm', 'repayment.lineOfCredit.repaymentPlanOtherDetail', null));
  }, [dispatch]);

  return (
    <InformationSection title="Repayment type">
      <div className={styles.commonSection}>
        <span>Capture how important the following repayment features are to your client/s</span>
        <div className={styles.content}>
          <PreferenceEditor
            props={principalAndInterestProps}
            namePrefix="repayment.principalAndInterest"
            extraRender={principalAndInterestExtraRender}
            clearValue={clearPrincipalAndInterestRepaymentFrequency}
          />
          <PreferenceEditor
            props={interestOnlyProps}
            namePrefix="repayment.interestOnly"
            extraRender={interestOnlyExtraRender}
            clearValue={clearRepaymentFrequencyAndPeriod}
          />
          <PreferenceEditor
            props={interestInAdvanceProps}
            namePrefix="repayment.interestInAdvance"
          />
          <PreferenceEditor
            props={lineOfCreditProps}
            namePrefix="repayment.lineOfCredit"
            extraRender={LineOfCreditExtraRender}
            clearValue={clearRepaymentPlan}
          />
        </div>
      </div>
    </InformationSection>
  );
};

export default RepaymentType;
