import React from 'react';
import PropTypes from 'prop-types';
import Field from 'shared/components/Field/Field';
import HasPermissions from 'shared/components/authorisation/HasPermissions';
import styles from './Referrer.module.scss';
import InputClientOrigin from './InputClientOrigin';
import { PERMISSIONS } from '../../authorisation/permissions';

const getClientOriginValue = (isExistingClient) => {
  if (isExistingClient === undefined) return '';
  return isExistingClient ? 'Existing Client' : 'New Client';
};

const Referrer = ({ referrer, isEditing }) => {
  const {
    isExistingClient,
    referralMainCategory,
    referralSubCategory,
    referralSource,
  } = referrer;

  const readonlyClientOrigin = getClientOriginValue(isExistingClient);

  const editableClientOrigin = (
    <HasPermissions permissions={[PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL]} fallback={readonlyClientOrigin}>
      <InputClientOrigin value={isExistingClient} />
    </HasPermissions>
  );

  return (
    <div className={styles.referrerWrapper}>
      <Field title="Client origin" content={isEditing ? editableClientOrigin : readonlyClientOrigin} className={styles.referrerItem} />
      <Field title="Referral category" content={referralMainCategory} className={styles.referrerItem} />
      <Field title="Sub-category" content={referralSubCategory} className={styles.referrerItem} />
      <Field title="Source" content={referralSource} className={styles.referrerItem} />
    </div>
  );
};

Referrer.defaultProps = {
  referrer: {},
  isEditing: false,
};

Referrer.propTypes = {
  referrer: PropTypes.object,
  isEditing: PropTypes.bool,
};

export default Referrer;
