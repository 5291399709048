import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import {
  getPageableApplications, setPageableParams, requestInit, reset, updateRemoteFilters, downloadAllApplications,
} from '../redux/actionCreators';
import AllApplicationsComponent from './AllApplicationsComponent';

const mapStateToProps = (state) => {
  const { business, allApplications } = state;
  const {
    take, skip, result, fetchingStatus, totalRowCount, totalLoanAmount,
    remoteFilters, adviserDropDownList, lenderDropDownList, disableDownloadButton,
  } = allApplications;
  return ({
    businessId: get(business, 'selectedBusiness.id'),
    fetchingStatus,
    totalRowCount,
    totalLoanAmount,
    take,
    skip,
    remoteFilters,
    result,
    adviserDropDownList,
    lenderDropDownList,
    disableDownloadButton,
  });
};

const mapDispatchToProps = (dispatch) => {
  const getAllApplications = query => dispatch(getPageableApplications(query));
  const getAllApplicationsWithDebounce = debounce(getAllApplications, 500);
  return {
    getAllApplications: getAllApplicationsWithDebounce,
    setPageableParams: (skip, take) => dispatch(setPageableParams(skip, take)),
    reset: () => dispatch(reset),
    getAllApplicationsInit: () => dispatch(requestInit()),
    onRemoteFiltersChange: filters => dispatch(updateRemoteFilters(filters)),
    downloadAllApplications: query => dispatch(downloadAllApplications(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllApplicationsComponent);
