import { getVariantModificationFromApi } from 'shared/api';
import { calculateVariantUpfrontFee } from 'shared/productUtils';
import omit from 'lodash/omit';
import { ACTIONS } from './variantModificationActions';
import { ACTIONS as COMPARISON_ACTIONS } from '../comparison';

export default () => (dispatch, getState) => {
  const {
    loanCriteriaList,
    variantModification,
    products,
  } = getState().productSearch.standAlone;
  const { totalCostTerm, repaymentFrequency } = products;

  const { currentModification } = variantModification;
  const { optionIndex, splitIndex } = currentModification;
  const {
    id,
    standardRate, discountRate, discountMonths,
    applicationFee, legalFee, valuationFee, upfrontFee, ongoingFee, ongoingFeeFrequency,
    firstPeriodRepayment, firstPeriodPrincipalAndInterest, firstPeriodInterestOnly,
    totalCost, comparisonRate, repaymentTypes, featureDetails, customProduct, lmi,
  } = currentModification.variant;

  const {
    loanAmount, loanTerm, interestOnlyTerm, repaymentType,
  } = loanCriteriaList[currentModification.splitIndex];
  const discountTermEnd = discountMonths === 'N/A' ? 0 : discountMonths;
  const variant = {
    id,
    standardRate,
    discountRate,
    discountTermStart: 0,
    discountTermEnd,
    applicationFee,
    legalFee,
    valuationFee,
    upfrontFee,
    ongoingFee,
    ongoingFeeFrequency,
    firstPeriodRepayment,
    firstPeriodPrincipalAndInterest,
    firstPeriodInterestOnly,
    totalCost,
    comparisonRate,
    repaymentTypes: repaymentTypes || [],
    featureNames: featureDetails.map(fd => fd.featureName),
    customProduct,
    lmi,
  };
  const context = {
    totalCostTerm,
    loanAmount,
    interestOnlyTerm,
    repaymentType,
    loanTermYears: loanTerm,
    repaymentFrequency,
  };
  dispatch({ type: ACTIONS.VARIANT_MODIFICATION_EDIT_SUBMIT });

  const getCalculatedVariantFromApi = (modifiedVariant, searchContext) =>

    getVariantModificationFromApi(omit(modifiedVariant, 'customProduct'), searchContext)

      .then((calculatedVariant) => {
        const updatedVariant = {
          ...currentModification.variant,
          discountMonths: currentModification.variant?.discountMonths === 'N/A' ? 0 : discountMonths,
          ...calculatedVariant,
        };

        return {
          ...updatedVariant,
          upfrontFee: calculateVariantUpfrontFee(updatedVariant),
        };
      });

  return getCalculatedVariantFromApi(variant, context)
    .then((updatedVariant) => {
      dispatch({ type: ACTIONS.VARIANT_MODIFICATION_EDIT_SUCCESS });
      dispatch({
        type: COMPARISON_ACTIONS.UPDATE_SELECTED_VARIANT,
        updatedVariant,
        optionIndex,
        splitIndex,
      });
    })
    .catch((error) => {
      const { graphQLErrors = [] } = error;
      const invalidParameter = graphQLErrors.find(({ errorCode }) => errorCode === 400);
      if (invalidParameter) {
        return dispatch({
          type: ACTIONS.VARIANT_MODIFICATION_EDIT_FAIL,
          error,
        });
      }
     return Promise.reject();
  });
};
