import React from 'react';
import PropTypes from 'prop-types';
import SpouseSelectorForLNClient from './SpouseSelectorForLNClient';

const SpouseSelector = (props) => (
  <SpouseSelectorForLNClient {...props} />
);

SpouseSelector.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  personalApplicants: PropTypes.array.isRequired,
};

export default SpouseSelector;
