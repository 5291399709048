import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { getAllApplicants, getGuarantors } from '../../../redux/selectors';
import Ownerships from './Ownerships';
import { getCombinedApplicants, getCombinedGuarantors } from '../../../../../utils';

const mapStateToProps = (state, { displayGuarantors }) => {
  const applicants = getAllApplicants(state);
  const guarantors = getGuarantors(state);
  const allApplicants = displayGuarantors
    ? [...getCombinedApplicants(applicants), ...getCombinedGuarantors(guarantors)]
    : getCombinedApplicants(applicants);
  return {
    applicants: allApplicants,
  };
};

const ConnectedOwnerships = connect(mapStateToProps)(Ownerships);

const validateOwnership = values => {
  if (!isEmpty(values)) {
    const total = values.map(({ percentage }) => percentage)
      .reduce((a, b) => a + b);

    return total > 100 ? 'Can\'t be more than 100%' : undefined;
  }
  return undefined;
};

const WrappedOwnerships = ({ fieldName, displayGuarantors, rowSpan }) => (
  <Field
    name={fieldName}
    component={ConnectedOwnerships}
    validate={validateOwnership}
    displayGuarantors={displayGuarantors}
    rowSpan={rowSpan}
  />
  );

WrappedOwnerships.propTypes = {
  fieldName: PropTypes.string.isRequired,
  displayGuarantors: PropTypes.bool,
  rowSpan: PropTypes.number,
};

WrappedOwnerships.defaultProps = {
  displayGuarantors: true,
  rowSpan: 1,
};

export default WrappedOwnerships;
