import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import InternalLink from 'shared/components/Links/InternalLink';
import isNull from 'lodash/isNull';
import styles from './index.module.scss';
import SplitLoans from './SplitLoans';

const getRefinancedLoanLinks = refinancedLoans => (
  <div className={styles.links}>
    {refinancedLoans.map(l => (
      <InternalLink
        key={l.id}
        to={`/loans/${l.id}`}
      >
        {l.accountNumber}
      </InternalLink>
 ))}
  </div>
);

const getApplicationLink = applicationId => (
  <div className={styles.links}>
    <InternalLink to={`/applications/${applicationId}`}>Application in Launchpad</InternalLink>
  </div>
);

const getFields = ({
 refinancedFrom, refinancedTo, applicationId, splitLoans, currentLoanId, brokerPlatformId,
}) => compact([
    !isNull(brokerPlatformId) ? null : {
      title: 'Application',
      content: getApplicationLink(applicationId),
      showSideBar: false,
      className: styles.linkGrid,
    },
    isEmpty(refinancedFrom) ? null : {
      title: 'Refinanced from',
      content: getRefinancedLoanLinks(refinancedFrom),
      showSideBar: false,
      className: styles.linkGrid,
    },
    isEmpty(refinancedTo) ? null : {
      title: 'Refinanced to',
      content: getRefinancedLoanLinks(refinancedTo),
      showSideBar: false,
      className: styles.linkGrid,
    },
    isEmpty(splitLoans) ? null : {
      title: 'Post settlement loans splits/switches',
      content: (
        <SplitLoans
          loans={splitLoans}
          currentLoanId={currentLoanId}
        />
      ),
      showSideBar: false,
      hidden: false,
      className: styles.splitTable,
    },
  ]);

const LoanLinks = ({
  refinancedFrom,
  refinancedTo,
  applicationId,
  splitLoans,
  currentLoanId,
  brokerPlatformId,
}) => {
  const fields = getFields({
      refinancedFrom, refinancedTo, applicationId, splitLoans, currentLoanId, brokerPlatformId,
    });
  return isEmpty(fields) ? null : (
    <FieldsContainer
      fields={fields}
      title="Links"
    />
  );
  };

LoanLinks.defaultProps = {
  refinancedFrom: [],
  refinancedTo: [],
  splitLoans: [],
};

LoanLinks.propTypes = {
  refinancedFrom: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })),
  refinancedTo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
  })),
  applicationId: PropTypes.string.isRequired,
  splitLoans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountNumber: PropTypes.string,
    amount: PropTypes.number.isRequired,
    settledDate: PropTypes.string.isRequired,
    splitFrom: PropTypes.string,
  })),
  currentLoanId: PropTypes.string.isRequired,
  brokerPlatformId: PropTypes.number.isRequired,
};

export default LoanLinks;
