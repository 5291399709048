import React from 'react';
import { connect } from 'react-redux';
import {
 change, Field, FieldArray, formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { COMPANY_PROFIT_BEFORE_TAX, employmentTypes, status } from './constants';
import styles from './Employment.module.scss';
import {
  ABNEstablishedDateField,
  ABNNumberField,
  CompanyNameField,
  EmployerFirstNameField,
  EmployerPhoneField,
  EmployerSurnameField,
  EmploymentSectorField,
  EmploymentStatusField,
  EmploymentTypeField,
  HomeDutiesField,
  JobTitleField,
  OccupationField,
  OnBenefitsField,
  OnProbationField,
  StatusField,
  StudentField,
  TimeDurationFields,
  AddressField,
} from './employmentFields';
import EmploymentIncome, { AddRemoveButtonGroup } from './EmploymentIncome';
import { FinancialYearFields } from './employmentIncomeFields';

const selector = formValueSelector('employmentsForm');

const PAYEFields = ({
  fieldNamePrefix, isPrevious, hasPrimary, clearField,
}) => (
  <>
    <EmploymentTypeField
      fieldNamePrefix={fieldNamePrefix}
      setEmploymentIncome={(value) => clearField('employmentIncomes', value)}
      setFinancialYear={(value) => clearField('financialYear', value)}
      clearEmployer={() => clearField('employer')}
    />
    <EmploymentStatusField fieldNamePrefix={fieldNamePrefix} />
    <EmploymentSectorField fieldNamePrefix={fieldNamePrefix} />
    <OccupationField fieldNamePrefix={fieldNamePrefix} />
    <JobTitleField fieldNamePrefix={fieldNamePrefix} />
    <TimeDurationFields fieldNamePrefix={fieldNamePrefix} isPrevious={isPrevious} />
    <StatusField
      fieldNamePrefix={fieldNamePrefix}
      hasPrimary={hasPrimary}
      clearEndDate={() => clearField('endDate')}
      clearEmploymentIncome={() => clearField('employmentIncomes')}
      setDefaultEmploymentIncome={() => clearField('employmentIncomes', [{}])}
    />
    <OnProbationField fieldNamePrefix={fieldNamePrefix} />
    <CompanyNameField fieldNamePrefix={fieldNamePrefix} />
    <EmployerFirstNameField fieldNamePrefix={fieldNamePrefix} />
    <EmployerSurnameField fieldNamePrefix={fieldNamePrefix} />
    <AddressField fieldNamePrefix={fieldNamePrefix} />
    <EmployerPhoneField fieldNamePrefix={fieldNamePrefix} />
  </>
);

const SelfEmployedFields = ({
  fieldNamePrefix, isPrevious, hasPrimary, clearField,
}) => (
  <>
    <EmploymentTypeField
      fieldNamePrefix={fieldNamePrefix}
      setEmploymentIncome={(value) => clearField('employmentIncomes', value)}
      setFinancialYear={(value) => clearField('financialYear', value)}
      clearEmployer={() => clearField('employer')}
    />
    <EmploymentStatusField fieldNamePrefix={fieldNamePrefix} />
    <OccupationField fieldNamePrefix={fieldNamePrefix} />
    <JobTitleField fieldNamePrefix={fieldNamePrefix} />
    <TimeDurationFields fieldNamePrefix={fieldNamePrefix} isPrevious={isPrevious} />
    <StatusField
      fieldNamePrefix={fieldNamePrefix}
      hasPrimary={hasPrimary}
      clearEndDate={() => clearField('endDate')}
      clearEmploymentIncome={() => clearField('employmentIncomes')}
      setDefaultEmploymentIncome={() => clearField('employmentIncomes', [{ periodUnit: 'Yearly' }])}
    />
    <CompanyNameField fieldNamePrefix={fieldNamePrefix} />
    <EmployerFirstNameField fieldNamePrefix={fieldNamePrefix} />
    <EmployerSurnameField fieldNamePrefix={fieldNamePrefix} />
    <AddressField fieldNamePrefix={fieldNamePrefix} />
    <EmployerPhoneField fieldNamePrefix={fieldNamePrefix} />
    <ABNNumberField fieldNamePrefix={fieldNamePrefix} />
    <ABNEstablishedDateField fieldNamePrefix={fieldNamePrefix} />
  </>
);

const UnemployedFields = ({
  fieldNamePrefix, isPrevious, hasPrimary, clearField,
}) => (
  <>
    <EmploymentTypeField
      fieldNamePrefix={fieldNamePrefix}
      setEmploymentIncome={(value) => clearField('employmentIncomes', value)}
      setFinancialYear={(value) => clearField('financialYear', value)}
      clearEmployer={() => clearField('employer')}
    />
    <TimeDurationFields fieldNamePrefix={fieldNamePrefix} isPrevious={isPrevious} durationLabel="Time not working" />
    <StatusField
      fieldNamePrefix={fieldNamePrefix}
      hasPrimary={hasPrimary}
      clearEndDate={() => clearField('endDate')}
      clearEmploymentIncome={() => clearField('employmentIncomes')}
    />
    <OnBenefitsField fieldNamePrefix={fieldNamePrefix} />
    <StudentField fieldNamePrefix={fieldNamePrefix} />
    <HomeDutiesField fieldNamePrefix={fieldNamePrefix} />
  </>
);

const RetiredFields = ({
 fieldNamePrefix, isPrevious, hasPrimary, clearField,
}) => (
  <>
    <EmploymentTypeField
      fieldNamePrefix={fieldNamePrefix}
      setEmploymentIncome={(value) => clearField('employmentIncomes', value)}
      setFinancialYear={(value) => clearField('financialYear', value)}
      clearEmployer={() => clearField('employer')}
    />
    <TimeDurationFields fieldNamePrefix={fieldNamePrefix} isPrevious={isPrevious} durationLabel="Years retired" />
    <StatusField
      fieldNamePrefix={fieldNamePrefix}
      hasPrimary={hasPrimary}
      clearEndDate={() => clearField('endDate')}
      clearEmploymentIncome={() => clearField('employmentIncomes')}
    />
    <OnBenefitsField fieldNamePrefix={fieldNamePrefix} />
  </>
);

const EmploymentIncomes = ({
  fieldNamePrefix, input: { value: incomes }, employmentType,
                             hasEditPermission,
                             clearIncomeField,
}) => {
  const selectedTypes = (incomes || []).map(
    ({ type, addbackType, governmentBenefitsType }) => ({ type, addbackType, governmentBenefitsType }),
  );
  return (
    <FieldArray
      name={`${fieldNamePrefix}.employmentIncomes`}
      component={Incomes}
      employmentType={employmentType}
      selectedTypes={selectedTypes}
      hasEditPermission={hasEditPermission}
      clearIncomeField={clearIncomeField}
    />
  );
};

const Incomes = ({
 fields,
 employmentType,
 selectedTypes,
 hasEditPermission,
 clearIncomeField,
}) => (
  <>
    {(fields.length === 0 && hasEditPermission) && (
      <AddRemoveButtonGroup
        addFunc={() => fields.push({})}
        removeFunc={noop}
        showAdd
        showRemove={false}
      />
    )}
    {fields.map((field, index) => (
      <EmploymentIncome
        key={field}
        fieldNamePrefix={field}
        employmentType={employmentType}
        selectedTypes={selectedTypes}
        appendIncome={() => fields.push(employmentType === employmentTypes.SELF_EMPLOYED ? { periodUnit: 'Yearly' } : {})}
        removeIncome={() => fields.remove(index)}
        isLastOne={index === fields.length - 1}
        isOnlyOne={fields.length === 1}
        hasEditPermission={hasEditPermission}
        isCompanyProfitBeforeTax={selectedTypes[index].type === COMPANY_PROFIT_BEFORE_TAX.value}
        clearIncomeField={clearIncomeField}
      />
      ))}
  </>
);

const Employment = ({
  employmentType,
  fieldNamePrefix,
  hasPrimary,
  isPrevious,
  hasEditPermission,
  clearField,
  clearChildField,
  onRemove,
  index,
}) => (
  <div className={styles.sectionWrapper}>
    <div className={isPrevious ? styles.fullWidthSection : styles.halfWidthSection}>
      <div className={styles.title}>{isPrevious ? 'Previous employment' : 'Current employment'}</div>
      {employmentType === employmentTypes.PAYE && (
        <PAYEFields
          fieldNamePrefix={fieldNamePrefix}
          isPrevious={isPrevious}
          hasPrimary={hasPrimary}
          clearField={clearField}
        />
      )}
      {employmentType === employmentTypes.SELF_EMPLOYED && (
        <SelfEmployedFields
          fieldNamePrefix={fieldNamePrefix}
          isPrevious={isPrevious}
          hasPrimary={hasPrimary}
          clearField={clearField}
        />
      )}
      {employmentType === employmentTypes.UNEMPLOYED && (
        <UnemployedFields
          fieldNamePrefix={fieldNamePrefix}
          isPrevious={isPrevious}
          hasPrimary={hasPrimary}
          clearField={clearField}
        />
      )}
      {employmentType === employmentTypes.RETIRED && (
        <RetiredFields
          fieldNamePrefix={fieldNamePrefix}
          isPrevious={isPrevious}
          hasPrimary={hasPrimary}
          clearField={clearField}
        />
      )}
    </div>
    {!isPrevious && (
      <div className={isPrevious ? styles.fullWidthSection : styles.halfWidthSection}>
        <div className={styles.title}>{isPrevious ? '' : 'Employment income'}</div>
        {employmentType === employmentTypes.SELF_EMPLOYED && (
          <FinancialYearFields fieldNamePrefix={fieldNamePrefix} />
        )}

        <Field
          fieldNamePrefix={fieldNamePrefix}
          name={`${fieldNamePrefix}.employmentIncomes`}
          component={EmploymentIncomes}
          employmentType={employmentType}
          hasEditPermission={hasEditPermission}
          clearIncomeField={(prefix, fieldName) => clearChildField(prefix, fieldName)}
        />
      </div>
    )}
    {hasEditPermission && <TertiaryButton onClick={onRemove(index)} className={styles.remove}> Remove</TertiaryButton>}
  </div>
);

const employmentPropTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  hasPrimary: PropTypes.bool.isRequired,
  isPrevious: PropTypes.bool.isRequired,
  clearField: PropTypes.func.isRequired,
};

PAYEFields.propTypes = employmentPropTypes;
SelfEmployedFields.propTypes = employmentPropTypes;
UnemployedFields.propTypes = employmentPropTypes;
RetiredFields.propTypes = employmentPropTypes;

Employment.propTypes = {
  ...employmentPropTypes,
  employmentType: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};

EmploymentIncomes.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  employmentType: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  clearIncomeField: PropTypes.func.isRequired,
};

Incomes.propTypes = {
  fields: PropTypes.object.isRequired,
  employmentType: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  clearIncomeField: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => ({
    employmentType: selector(state, `${props.fieldNamePrefix}.employmentType`),
    isPrevious: selector(state, `${props.fieldNamePrefix}.status`) === status.Previous,
  }),
  (dispatch, props) => ({

    clearField: (field, value = null) =>
      dispatch(change('employmentsForm', `${props.fieldNamePrefix}.${field}`, value)),
    clearChildField: (fieldPrefix, field, value = null) =>
      dispatch(change('employmentsForm', `${fieldPrefix}.${field}`, value)),

  }),
)(Employment);
