import Request from 'shared/api/Request';
import {
  LOANBOOK_INVOICE,
  LOANBOOK_INVOICE_DETAILS,
  COMMISSION_QUERY,
} from './myBusinessAPIEndpoints';

export const downloadInvoicePdf = async invoice =>
  Request.post(LOANBOOK_INVOICE, invoice, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/pdf' }));

export const downloadInvoiceDetailsXlsx = async (processingMonth, businessId) =>
  Request.get(`${LOANBOOK_INVOICE_DETAILS}?businessId=${businessId}&processingMonth=${processingMonth}`, null, { responseType: 'arraybuffer' })
    .then(response => new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

export const submitCommissionQuery = async (issueId, description, queryType,
                                            businessId, processingMonth, financialYear) =>
  Request.post(
    `${COMMISSION_QUERY}`,
    {
      issueId,
      description,
      queryType,
      businessId,
      processingMonth,
      financialYear,
    },
  )
    .then(response => response.data);
