import get from 'lodash/get';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import buildDescriptors from '../descriptors/AOLDescriptors';

const buildContext = ({ application, toggles }) => ({
    normalizeType(defaultType, index) {
      // do not care finalType, let see how it goes
      const suffix = index === undefined ? '' : ` (${index + 1})`;
      return `${defaultType}${suffix}`;
    },
    getLiabilityType(index) {
      return this.normalizeType(
        'Liability',
        index,
        get(application, `finance.liabilities[${index}].type`),
      );
    },
    getRealEstate(index) {
      return get(application, `finance.assets.realEstates[${index}]`);
    },
    getRealEstateType(index) {
      return this.normalizeType(
        'Real estate asset', index,
      );
    },
    getNonRealEstate(index) {
      return get(application, `finance.assets.nonRealEstates[${index}]`);
    },
    getNonRealEstateType(index) {
      return this.normalizeType(
        'Non real estate asset', index,
      );
    },
    getApplicantName(prefix) {
      return index => get(application, `${prefix}[${index}].name`);
    },
    getSecurityType() {
      return 'Security asset';
    },
    isRealEstate(index) {
      return !this.isSecurity(index);
    },
    isSecurity(index) {
      const maybeRealEstate = this.getRealEstate(index);
      return get(maybeRealEstate, 'transactionType') === 'Purchasing';// Owns
    },
    getAddressType(...indexes) {
      const address = get(application, `applicants.persons[${indexes[0]}].contactDetails.addresses[${indexes[1]}]`);
      const isPrevious = get(address, 'priorAddress');
      return isPrevious ? 'Previous address' : 'Current address';
    },
    getBeneficiaryKey(...indexes) {
      return this.normalizeType(
        'Beneficiary', indexes[1],
      );
    },
    getLinkOfApplicant(prefix) {
      return index => ({ link: get(application, `${prefix}[${index}].externalLink`) });
    },
    getEmploymentType(...args) {
      return prefix => {
        if (isString(args[1])) {
          return 'Employments must not be null';
        }
        const status = get(application, `${prefix}[${args[0]}].employments[${args[1]}].status`);
        return `Employment - ${status}`;
      };
    },
    getEmploymentIncomeType(...indexes) {
      return this.normalizeType(
        'Income', indexes[2],
      );
    },
    getCompanyIncomeType(...indexes) {
      return this.normalizeType(
        'Income', indexes[1],
      );
    },
    getAdditionalIncomeType(index) {
      return this.normalizeType(
        'Additional income',
        index,
      );
    },
    getFutureCircumstanceType(applicantType) {
      return ((index) => (
        `${this.normalizeType(
          'Adverse Changes',
          index,
        )} - ${applicantType}`
      ));
    },
    getSignificantChangeType(...indexes) {
      return this.normalizeType(
        'Planned or anticipated change',
        indexes[1],
      );
    },
    getThirdPartyType(index) {
      return this.normalizeType('3rd parties', index);
    },
    getLoanName(index) {
      return `Loan ${index + 1} ${get(application, `loans[${index}].productName`) || ''}`;
    },
    isProductSelected(index) {
      return !isNil(get(application, `loans[${index}].productName`));
    },
    getToggles() {
      return toggles;
    },
  });

export default contextData => {
  const context = buildContext(contextData);
  const descriptors = buildDescriptors(context);
  return {
    context,
    descriptors,
  };
};
