import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReportsPage } from './ReportsPage';
import {
  retrieveLoanViewsForSubmissionAndSettlementReport,
  retrieveReportingMeta,
  retrieveLoanViewsForExpectedSettlementReport,
  retrieveCommissionSummary,
  downloadLenderReconciliationReport,
  downloadInternallyReferredAssetFinanceReport,
  retrieveLoanViewsForSettlementSummaryReport,
  retrieveLoanViewsForConversionRateReport,
} from './redux';
import { FETCHING_STATUS } from '../../constants';

const ReportsPageContainer = React.memo(({ businessId, ...others }) => (
  <ReportsPage
    key={businessId}
    businessId={businessId}
    {...others}
  />
));

ReportsPageContainer.propTypes = {
  businessId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    reports: {
      submissionReport,
      settlementReport,
      lenderReconciliationReportDownload,
      internallyReferredAssetFinanceReportDownload,
    },
  } = state;
  const fetchingStatusList = map([submissionReport, settlementReport], 'fetchingStatus');
  const anyLoading = fetchingStatusList.some(status => status === FETCHING_STATUS.START);
  return ({
    businessId: get(state, 'business.selectedBusiness.id', null),
    anyLoading,
    reportingMeta: get(state, 'reports.reportingMeta', {}),
    isLenderReconciliationReportDownloading: FETCHING_STATUS.START === get(lenderReconciliationReportDownload, 'fetchingStatus'),
    isInternallyReferredAssetFinanceReportDownloading: FETCHING_STATUS.START === get(internallyReferredAssetFinanceReportDownload, 'fetchingStatus'),
    commissionLists: get(state, 'reports.commissionSummary.commissionLists'),
    toggles: get(state, 'profile.toggles'),
  });
};

export const ConnectedReportsPage = connect(
  mapStateToProps,
  {
    retrieveLoanViewsForSubmissionAndSettlementReport,
    retrieveReportingMeta,
    retrieveLoanViewsForExpectedSettlementReport,
    downloadLenderReconciliationReport,
    downloadInternallyReferredAssetFinanceReport,
    retrieveCommissionSummary,
    retrieveLoanViewsForSettlementSummaryReport,
    retrieveLoanViewsForConversionRateReport,
  },
)(ReportsPageContainer);
