import React, { useState, useMemo, useCallback } from 'react';
import keys from 'lodash/keys';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import reduce from 'lodash/reduce';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/formatters';
import { getUTCNow } from 'utils/datetime';
import ModalWithButtons from 'shared/components/Modal/ModalWithButtons';
import Icon from 'shared/components/Icon/Icon';
import colours from 'shared/theme/colours';
import styles from '../Applications.module.scss';
import {
  applicationDisplayStatus,
  applicationStatus,
} from '../../../constants/applicationStatus';
import ApplicationCardView from '../ApplicationCard/ApplicationCard';
import RecordReasonPopup from '../../RecordReasonPopup/RecordReasonPopup';
import ValidationConfirmSettlementPopup from '../../ValidationConfirmSettlementPopup';
import showConfetti from '../../../../utils/showConfetti';

function getCards(swimLanes, statusKey, hasEditPermission, isSettledColumn) {
  const isDisabled = !hasEditPermission || isSettledColumn(statusKey);
  return swimLanes[applicationDisplayStatus[statusKey]].map((application, index) => (
    <ApplicationCardView
      key={application.id}
      index={index}
      application={application}
      isDragDisabled={isDisabled}
    />
  ));
}

const DroppableColumnRaw = ({
                              statusKey,
                              swimLanes,
                              isSettledColumn,
                              hasEditPermission,
                            }) =>
  (
    <Droppable droppableId={statusKey} isDropDisabled>
      {provided => (
        <div
          key={statusKey}
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ backgroundColor: 'white' }}
        >
          {getCards(swimLanes, statusKey, hasEditPermission, isSettledColumn)}
        </div>
      )}
    </Droppable>
);

DroppableColumnRaw.propTypes = {
  swimLanes: PropTypes.shape({
    [applicationDisplayStatus.DRAFT]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.SUBMITTED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.DECLINED_OR_WITHDRAWN]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.CONDITIONALLY_APPROVED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.UNCONDITIONALLY_APPROVED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.SETTLED]: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  isSettledColumn: PropTypes.func.isRequired,
  statusKey: PropTypes.string.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
};

const DroppableColumn = React.memo(DroppableColumnRaw);

const isDeclinedOrWithDrawnColumn = column => column === 'DECLINED_OR_WITHDRAWN';

const isSettledColumn = column => column === applicationStatus.SETTLED;

const getDropColumnStyle = isDragging => ({
  width: 'calc(100% - 10px)',
  margin: '0 5px',
  height: '100%',
  backgroundColor: 'rgba(213, 226, 246, 0.4)',
  position: 'absolute',
  left: 0,
  top: 0,
  border: `1.5px dashed ${colours.ocean}`,
  borderRadius: '4px',
  transition: 'opacity 0.3s',
  zIndex: isDragging ? 10 : 0,
  opacity: isDragging ? 1 : 0,
});

const notesButtonStyle = {
  height: '3rem',
  minWidth: '16rem',
  padding: '0',
};

const doneButtonStyle = {
  height: '3rem',
  minWidth: '10rem',
  padding: '0',
};

const saveApplicationStatus = (applicationId, data, updateApplicationStatus) => {
  const now = getUTCNow();
  return updateApplicationStatus({
    applicationId,
    actionDateTime: now,
    statusDateTime: now,
    ...data,
  });
};

const ApplicationSwimLanes = ({
  swimLanes, moveApp, hasEditPermission, updateApplicationStatus,
  actionTimeMapInSwimLane, getApplicationActionTimeMap,
                              }) => {
  const [isDragging, changeIsDragging] = useState(false);
  const [dragDropInfo, setDragDropInfo] = useState({});
  const [remenderModalVisible, setRemenderModalVisible] = useState(false);
  const isEmpty = !reduce(keys(swimLanes).map(key => swimLanes[key].length), (res, length) => res + length, 0);
  const renderSwimLaneHeader = () => keys(applicationDisplayStatus).map(statusKey => (
    <div key={statusKey} className={styles.statusName}>
      <div className={styles.statusDiv}>
        {applicationDisplayStatus[statusKey].toUpperCase()}
        <br />
        {!isEmpty && (
          <span className={styles.statusLoanAmount}>
            (
            {formatCurrency(swimLanes[applicationDisplayStatus[statusKey]]
              .reduce((init, current) => init + current.totalLoanAmount, 0))}
            )
          </span>
        )}
      </div>
    </div>
  ));
  const renderEmptyMask = () => (<div className={styles.emptyMask}>There are no applications.</div>);

  const renderApplications = () => (
    <div className={styles.droppableContainer}>
      {keys(applicationDisplayStatus).map(statusKey => (
        <div
          key={`${statusKey}-column`}
          className={styles.applicationsColumn}
        >
          <Droppable droppableId={`${statusKey}-fake`}>
            {provided => (
              <div
                key={statusKey}
                style={getDropColumnStyle(isDragging)}
                ref={provided.innerRef}
                {...provided.droppableProps}
              />
            )}
          </Droppable>
          <DroppableColumn
            statusKey={statusKey}
            swimLanes={swimLanes}
            isSettledColumn={isSettledColumn}
            hasEditPermission={hasEditPermission}
          />
        </div>
      ))}
    </div>
  );

  const processAppMove = ({
                            applicationId, targetColumnName, reason, success = true,
  }) =>
    new Promise((resolve, reject) => {
      if (targetColumnName && reason === 'DROP' && applicationId) {
        moveApp(applicationId, targetColumnName);
        const blinkClass = success ? 'blinkEle' : 'blinkErrorEle';
        setTimeout(() => {
          const target = document.getElementById(applicationId);
          if (target) {
            const intersectionObserver = new IntersectionObserver((entries) => {
              const [entry] = entries;
              if (entry.isIntersecting) {
                intersectionObserver.disconnect();
                setTimeout(resolve, 100);
              }
            });
            intersectionObserver.observe(target);
            target.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth',
            });
            target.classList.remove(blinkClass);
            target.classList.add(blinkClass);
          } else {
            resolve();
          }
        });
      } else {
        reject();
      }
    });

  const handlePopupClose = () => {
    const applicationId = get(dragDropInfo, 'application.id');
    const sourceColumnName = get(dragDropInfo, 'sourceColumnName');
    setDragDropInfo({ notesLink: dragDropInfo.notesLink });
    processAppMove({
      reason: 'DROP',
      targetColumnName: sourceColumnName,
      applicationId,
      success: false,
    });
  };

  const findApplicationIsDraging = useCallback(
    (sourceColumnName, applicationId) =>
    get(swimLanes, get(applicationDisplayStatus, sourceColumnName), [])
      .find(item => item.id === applicationId),
       [swimLanes],
  );

  const isMigratedApplication = useCallback(
    (sourceColumnName, applicationId) => {
      const application = findApplicationIsDraging(sourceColumnName, applicationId);
      return startsWith(get(application, 'source'), 'lotusnotes');
    },
    [findApplicationIsDraging],
  );

  const handlePopupSubmit = (data) => {
    const applicationId = get(dragDropInfo, 'application.id');
    const sourceColumnName = get(dragDropInfo, 'sourceColumnName');
    const targetColumnName = get(dragDropInfo, 'targetColumnName');
    const isOwnerOccupied = get(dragDropInfo, 'application.isOwnerOccupied');

    return saveApplicationStatus(applicationId, data, updateApplicationStatus)
      .then(() => {
        if (isSettledColumn(targetColumnName)) {
          showConfetti();
        }
        if (isMigratedApplication(targetColumnName, applicationId)
          && isSettledColumn(targetColumnName) && isOwnerOccupied) {
          setRemenderModalVisible(true);
        }
        setDragDropInfo({ notesLink: get(dragDropInfo, 'application.notesLink') });
      })
      .catch(() => {
        processAppMove({
          sourceColumnName: targetColumnName,
          targetColumnName: sourceColumnName,
          applicationId,
          success: false,
        });
      });
  };

  const closeRemenderModal = () => {
    setRemenderModalVisible(false);
  };

  const handleDragEnd = (data) => {
    const {
      source, destination, reason, draggableId: applicationId,
    } = data;
    changeIsDragging(false);
    const sourceColumnName = get(source, 'droppableId', '');
    const targetColumnName = get(destination, 'droppableId', '').split('-fake')[0];
    if (targetColumnName === '' || sourceColumnName === targetColumnName) {
      return;
    }
    const application = findApplicationIsDraging(sourceColumnName, applicationId);
    if (isMigratedApplication(sourceColumnName, applicationId)
        && targetColumnName === applicationStatus.PRE_SUBMISSION
    ) {
      processAppMove({
        applicationId, targetColumnName: sourceColumnName, reason, success: false,
      });
      return;
    }
    processAppMove({ applicationId, targetColumnName, reason }).then(() => {
      if (isDeclinedOrWithDrawnColumn(targetColumnName) || isSettledColumn(targetColumnName)) {
        setDragDropInfo({
          application,
          sourceColumnName,
          targetColumnName,
          reason,
        });
      } else {
        saveApplicationStatus(applicationId, { milestoneType: targetColumnName }, updateApplicationStatus)
          .catch(() => {
            processAppMove({
              applicationId, targetColumnName: sourceColumnName, reason, success: false,
            });
          });
      }
    });
    if (isSettledColumn(targetColumnName)) {
      getApplicationActionTimeMap(applicationId);
    }
  };

  const onDragStart = useMemo(() => () => { changeIsDragging(true); }, [changeIsDragging]);

  const openLink = () => {
    window.open(get(dragDropInfo, 'notesLink'), '_blank');
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd}>
      <Droppable droppableId="container" direction="horizontal" isDropDisabled>
        {containerProvided => (
          <div ref={containerProvided.innerRef} {...containerProvided.droppableProps}>
            <div className={styles.swimLanesWrapper}>
              <div className={styles.swimLanesHeader}>
                { renderSwimLaneHeader()}
              </div>
              <div className={styles.swimLanesContent}>
                {isEmpty ? renderEmptyMask() : renderApplications() }
              </div>
            </div>
          </div>
        )}
      </Droppable>
      {isDeclinedOrWithDrawnColumn(dragDropInfo.targetColumnName)
      && <RecordReasonPopup onSubmit={handlePopupSubmit} onClose={handlePopupClose} />}
      {isSettledColumn(dragDropInfo.targetColumnName) && (
        <ValidationConfirmSettlementPopup
          submitSettlement={handlePopupSubmit}
          closeModal={handlePopupClose}
          applicationId={get(dragDropInfo, 'application.id')}
          lenderId={get(dragDropInfo, 'application.lender.lenderId')}
          actionTimelineMap={actionTimeMapInSwimLane}
          applicants={get(dragDropInfo, 'application.originalApplicants')}
          setRemenderModalVisible={setRemenderModalVisible}
        />
      )}
      {remenderModalVisible
      && (
        <ModalWithButtons
          isOpen
          header="Update loan address"
          className={styles.notesReminderWapper}
          onRequestClose={closeRemenderModal}
          submitText={<div className={styles.notesButton}>Open Lotus Notes <Icon name="share" color="#ffffff" size="1.8rem" /></div>}
          submitButtonProps={{ style: notesButtonStyle, onClick: openLink }}
          secondarySubmitText={<div className={styles.notesDoneButton}>Done</div>}
          secondaryButtonPros={{ style: doneButtonStyle, onClick: closeRemenderModal }}
        >
          <div className={styles.notesReminderContents}>
            Please make sure the property address is
            correct in Lotus Notes before settling this
            Owner Occupied application.
          </div>
        </ModalWithButtons>
)}
    </DragDropContext>
  );
};
ApplicationSwimLanes.propTypes = {
  swimLanes: PropTypes.shape({
    [applicationDisplayStatus.DRAFT]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.SUBMITTED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.DECLINED_OR_WITHDRAWN]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.CONDITIONALLY_APPROVED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.UNCONDITIONALLY_APPROVED]: PropTypes.arrayOf(PropTypes.object).isRequired,
    [applicationDisplayStatus.SETTLED]: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  moveApp: PropTypes.func.isRequired,
  updateApplicationStatus: PropTypes.func.isRequired,
  hasEditPermission: PropTypes.bool,
  actionTimeMapInSwimLane: PropTypes.object,
  getApplicationActionTimeMap: PropTypes.func.isRequired,
};

ApplicationSwimLanes.defaultProps = {
  hasEditPermission: false,
  actionTimeMapInSwimLane: {},
};

export default React.memo(ApplicationSwimLanes);
