import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
                   color, width, height, alt,
                 }) => (
                   <svg
                     width={width}
                     height={height}
                     viewBox="0 0 43 43"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <title>{alt}</title>

                     <g
                       id="Icon/FlagCircle"
                       stroke="none"
                       strokeWidth="1"
                       fill="none"
                       fillRule="evenodd"
                     >
                       <circle
                         id="Oval"
                         stroke={color}
                         strokeWidth="1.8"
                         cx="20.5"
                         cy="20.5"
                         r="20.5"
                         transform="translate(1.000000, 1.000000)"
                       />

                       <path
                         id="Shape"
                         fill={color}
                         transform="translate(10.000000, 10.000000)"
                         d="M10.353 15.177L19.529 6l1.412 1.412L10.353 18 4 11.647l1.412-1.411z"
                       />
                     </g>
                   </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
