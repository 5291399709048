import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import Icon from 'shared/components/Icon/Icon';
import { match } from 'utils/routeUtils';
import { connect } from 'react-redux';
import styles from './NavigationItem.module.scss';
import Popover from '../NavbarMenu/Popover';
import hasPermissionTo from '../authorisation/hasPermissionTo';

const renderPopOverTrigger = (toggle, isShown, title, isActived, className) => (
  <div
    className={cx(className, styles.popoverTrigger, { [styles.activedRoute]: isActived, 'actived-route': isActived })}
    onClick={toggle}
    role="button"
    tabIndex="-1"
    onKeyDown={(e) => {
      if (e.key === 'ENTER') {
        toggle();
      }
    }}
  >
    <span>{title}</span>
    <Icon name={isShown ? 'arrow-up-solid' : 'arrow-down-solid'} />
  </div>
);

const NavigationItem = ({
                          pathname,
                          title,
                          routes,
                          className,
                          location,
                          permission,
                          permissions,
                          toggle,
                          toggles,
                          business,
                          subMenuStyle,
                          defaultOpenMenu,
                          onClick,
                          isShrink,
}) => {
  const isVisibleRoute = route => ((!route.toggle || toggles[route.toggle]) && (!route.permission || hasPermissionTo(route.permission, permissions, get(business, 'id', '*'))));
  const useOverlayMenu = subMenuStyle === WrappedNavigationItem.subMenuStyles.overlay;
  const allChildrenHidden = routes.length > 0 && !routes.some(isVisibleRoute);
  if (!isVisibleRoute({ toggle, permission }) || allChildrenHidden) {
    return null;
  }
  const actived = match(location.pathname, pathname)
      || routes.some(route => (match(location.pathname, route.pathname)));
  const shrinkStyle = isShrink ? '' : styles.routeItemShrink;
  /* eslint-disable react/no-array-index-key */
  return (
    isEmpty(routes) ? (
      <Link
        className={cx(className, styles.routeItem, {
          [styles.activedRoute]: actived,
          'actived-route': actived,
        })}
        onClick={onClick}
        to={{ pathname }}
      >
        {title}
      </Link>
    ) : (
      <Popover
        rootClose={useOverlayMenu}
        defaultOpen={defaultOpenMenu}
        className={cx(styles.routeItem, styles.routesGroupItem, 'route-item', shrinkStyle)}
        trigger={(toggleMenu, isShown) => renderPopOverTrigger(toggleMenu, isShown, title, actived, className)}
      >
        {(toggleMenu, isShown) => (
          <div
            className={cx(styles.routesMenu, 'routes-menu', { [styles.overlayMenu]: useOverlayMenu })}
            style={{
              maxHeight: isShown ? `calc(5.2rem * ${routes.length})` : 0,
            }}
          >
            {routes.map((route, index) => (
              <WrappedNavigationItem
                key={index}
                onClick={onClick || toggleMenu}
                className={className}
                defaultOpen={defaultOpenMenu}
                {...route}
                subMenuStyle={subMenuStyle}
              />
            ))}
          </div>
        )}

      </Popover>
    )
  );
  /* eslint-enable react/no-array-index-key */
};

NavigationItem.propTypes = {
  pathname: PropTypes.string,
  location: PropTypes.object.isRequired,
  routes: PropTypes.array,
  title: PropTypes.any,
  className: PropTypes.string,
  toggle: PropTypes.bool,
  permission: PropTypes.object,
  permissions: PropTypes.array.isRequired,
  toggles: PropTypes.object.isRequired,
  business: PropTypes.object,
  subMenuStyle: PropTypes.oneOf(['overlay', 'static']),
  defaultOpenMenu: PropTypes.bool,
  isShrink: PropTypes.bool,
  onClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  routes: [],
  title: null,
  className: '',
  toggle: undefined,
  permission: undefined,
  pathname: undefined,
  subMenuStyle: 'overlay',
  defaultOpenMenu: false,
  onClick: undefined,
  isShrink: true,
  business: null,
};

const mapStateToProps = state => ({
  toggles: state.profile.toggles,
  permissions: state.profile.permissions,
  business: state.business.selectedBusiness,
});

const WrappedNavigationItem = withRouter(connect(mapStateToProps)(NavigationItem));

WrappedNavigationItem.subMenuStyles = {
  overlay: 'overlay',
  static: 'static',
};

export default WrappedNavigationItem;
