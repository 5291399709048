import get from 'lodash/get';
import findBusiness from 'shared/findBusiness';
import { showToast } from 'redux/toast/actions';
import { createAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import trim from 'lodash/trim';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import _values from 'lodash/values';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import differenceWith from 'lodash/differenceWith';
import { getMediaType } from 'shared/hooks/useMediaType';
import { downloadMALIDocument } from 'shared/api';
import { openInTab, wait } from 'shared/utils';
import { download } from 'shared/download';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { getFormInitialValues } from 'redux-form';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import some from 'lodash/some';
import filter from 'lodash/filter';
import map from 'lodash/map';
import graphQL from './applicationDetailsGraphQL';
import api from '../../Applications/redux/apis';
import {
  formatApplication, formatApplicationStatus, generateAOLURL, getNextGenIdentifier, toMonthlyValue,
} from '../../../utils';
import types from './constants';
import { MEDIA_TYPES } from '../../../../constants/media';
import { resolveError } from './validation/validationResolver';
import { toDateStamp } from '../../../../utils/datetime';
import { MONTHLY } from '../ApplicationDetailContentNav/Expenses/constants';
import getToggles from '../../../../shared/selectors/togglesSelector';
import { employmentTypes } from '../ApplicationDetailContentNav/IncomeAndEmployment/EmploymentForm/constants';
import applicationFilterTypes from '../../Applications/redux/constants';

export { calculateFundingPositions, calculateFees } from './actions/fundingWorksheetActions';

const isGraphqlError = error => !isEmpty(error.graphQLErrors);

const REQUEST_DELAY = 2000;

export const switchApplication = () => ({ type: types.SWITCH_APPLICATION });

export const updateApplicationAbstract = (id, data) => async (dispatch) => {
  const response = await graphQL.updateApplicationAbstract(id, data);
  dispatch({
    type: types.UPDATE_APPLICATION_ABSTRACT_SUCCESS,
    applicationAbstract: response.data.updateAbstract,
  });
  dispatch(delayedUpdateActivityLogs(id));
};

export const updateApplicationLoanRequests = (id, data) => dispatch => (
  graphQL.updateApplicationLoanRequests(id, data)
    .then(() => {
      dispatch({
        type: types.UPDATE_APPLICATION_LOANS_SUCCESS,
        data,
      });
      dispatch(delayedUpdateActivityLogs(id));
      return Promise.resolve();
    })
);

const fetchApplication = (id, currBusinessId, hasBusinessInfo, dispatch) =>
  graphQL.getApplicationDetail(id)
    .then(({ data: { application } }) => {
      if (currBusinessId === application.businessId && hasBusinessInfo) {
        return { application };
      }
      return findBusiness(application.businessId)
        .then(business => ({
          application,
          business,
        }));
    })
    .then(({ application, business }) => {
      dispatch({
        type: types.GET_APPLICATION_DETAIL_SUCCESS,
        rawApplication: application,
        data: formatApplication(application),
        business,
      });
    })
    .catch(({ graphQLErrors }) => {
      const applicationNotFound = get(graphQLErrors, [0, 'errorCode'], null) === 404;
      const applicationForbidden = get(graphQLErrors, [0, 'errorCode'], null) === 403;
      return Promise.reject(dispatch({
        applicationNotFound,
        applicationForbidden,
        type: types.GET_APPLICATION_DETAIL_ERROR,
      }));
    });

export const getApplicationDetails = id => (dispatch, getState) => {
  const state = getState();
  const currBusinessId = get(state, 'business.selectedBusiness.id', null);
  const hasBusinessInfo = get(state, 'business.selectedBusiness.name') || get(state, 'business.selectedBusiness.owner');
  dispatch({ type: types.GET_APPLICATION_DETAIL_START });

  return fetchApplication(id, currBusinessId, hasBusinessInfo, dispatch);
};

const delayUpdateDueDateTime = data => (dispatch) => {
  setTimeout(() => {
    graphQL.getApplicationDetail(data.applicationId)
      .then(({ data: { application } }) => {
        dispatch({
          type: types.UPDATE_APPLICATION_DUE_DATE_SUCCESS,
          expectedSettlementDate: application.expectedSettlementDate,
          preApprovalExpiryDate: application.preApprovalExpiryDate,
          financeDueDate: application.financeDueDate,
        });
      })
      .catch(() => {
        dispatch({ type: types.UPDATE_APPLICATION_DUE_DATE_ERROR });
      });
  }, REQUEST_DELAY);
};

export const hideApplication = (isHiddenOnSwimlane) => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id', null);
  await api.hideApplication(applicationId, isHiddenOnSwimlane);
  dispatch({
    type: types.UPDATE_APPLICATION_ON_SWIMLANE_STATUS,
    data: {
      isHidden: isHiddenOnSwimlane,
    },
  });
  dispatch({
    type: applicationFilterTypes.CLEAN_CONDITIONS,
  });
};

export const copyApplication = () => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id', null);
  dispatch({ type: types.COPY_APPLICATION_START });
  try {
    const result = await api.copyApplication(applicationId);

    return await new Promise((resolve) => {
      setTimeout(() => {
        dispatch({
          type: types.COPY_APPLICATION_SUCCESS,
        });
        dispatch(delayedUpdateActivityLogs(applicationId));
        resolve(result);
      }, 1000);
    });
  } catch (error) {
    dispatch({
      type: types.COPY_APPLICATION_ERROR,
    });
    throw error;
  }
};

const delayedUpdateApplicationStatus = id => (dispatch) => {
  dispatch({ type: types.UPDATE_APPLICATION_STATUS_START });
  setTimeout(() => {
    graphQL.getApplicationDetail(id)
      .then(({ data: { application } }) => {
        const applicationStatus = formatApplicationStatus(application.applicationStatus);
        dispatch({
          type: types.UPDATE_APPLICATION_STATUS_SUCCESS,
          activityLogs: application.activityLogs,
          applicationStatus,
          applicationUpdates: application.applicationUpdates,
          referrer: application.referrer,
        });
      })
      .catch(() => {
        dispatch({ type: types.UPDATE_APPLICATION_STATUS_ERROR });
      });
  }, REQUEST_DELAY);
};

export const delayedUpdateActivityLogs = applicationId => async (dispatch) => {
  await wait(REQUEST_DELAY);
  const { data: { application } } = await graphQL.getActivityLogsFromApplication(applicationId);
  dispatch({
    type: types.UPDATE_ACTIVITY_LOGS,
    activityLogs: application.activityLogs,
  });
};

export const updateStatusDatetime = data => dispatch => api.updateApplicationTimeline(data)
  .then(() => {
    dispatch(delayedUpdateApplicationStatus(data.applicationId));
    return Promise.resolve();
  });

export const updateDueDate = data => dispatch => api.updateDueDateTime(data)
  .then(() => {
    dispatch(delayUpdateDueDateTime(data));
    return Promise.resolve();
  });

export const createNote = content => (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id', null);
  const userId = get(state, 'profile.userId', null);
  const userName = get(state, 'profile.username', null);
  return graphQL.createNote({
    applicationId,
    userId,
    userName,
    content,
  })
    .then(() => {
      dispatch(delayedUpdateActivityLogs(applicationId));
    });
};

export const updateNote = (content, id) => (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id', null);
  const userId = get(state, 'profile.userId', null);
  const userName = get(state, 'profile.username', null);
  return graphQL.updateNote({
    applicationId,
    userId,
    userName,
    content,
    id,
  })
    .then(() => {
      dispatch(delayedUpdateActivityLogs(applicationId));
    });
};

export const deleteNote = (content, id) => (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id', null);
  const userId = get(state, 'profile.userId', null);
  const userName = get(state, 'profile.username', null);
  return graphQL.deleteNote({
    applicationId,
    userId,
    userName,
    content,
    id,
  })
    .then(() => {
      dispatch(showToast('Your note has been deleted.', { type: 'success' }));
      dispatch(delayedUpdateActivityLogs(applicationId));
      return Promise.resolve();
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your note has not been deleted. Please try again', { type: 'error' }));
      }
      return Promise.reject();
    });
};

export const createTask = data => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: types.CREATE_TASK_START,
    data,
  });
  return graphQL.createTask(data)
    .then((taskList) => {
      dispatch({
        type: types.CREATE_TASK_SUCCESS,
        data: taskList,
      });
      dispatch(delayedUpdateActivityLogs(state.application.applicationDetail.id));
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your task has not been added. Please try again', { type: 'error' }));
      }
      dispatch({
        type: types.CREATE_TASK_ERROR,
        data,
      });
    });
};

export const updateTask = data => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_TASK_START,
    data,
  });
  return graphQL.updateTask(data)
    .then((response) => {
      dispatch({
        type: types.UPDATE_TASK_SUCCESS,
        task: response.data.updateTask,
      });
      dispatch(delayedUpdateActivityLogs(getState().application.applicationDetail.id));
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your task detail are not saved. Please try again', { type: 'error' }));
      }
      dispatch({
        type: types.UPDATE_TASK_ERROR,
        data,
      });
      return Promise.reject();
    });
};

export const deleteTask = data => (dispatch, getState) => {
  dispatch({
    type: types.DELETE_TASK_START,
    data,
  });
  return graphQL.deleteTask(data)
    .then((response) => {
      dispatch({
        type: types.DELETE_TASK_SUCCESS,
        id: response.data.deleteTask,
      });
      dispatch(delayedUpdateActivityLogs(getState().application.applicationDetail.id));
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your task has not been deleted. Please try again', { type: 'error' }));
      }
      dispatch({
        type: types.DELETE_TASK_ERROR,
        data,
      });
      return Promise.reject();
    });
};

export const addComment = dataWithoutName => (dispatch, getState) => {
  const state = getState();
  const data = {
    ...dataWithoutName,
    name: get(state, 'profile.username', ''),
  };
  return graphQL.addComment(data)
    .then((response) => {
      dispatch({
        type: types.ADD_COMMENT_SUCCESS,
        data: {
          comments: response.data.addComment.comments,
          taskId: data.taskId,
        },
      });
      dispatch(delayedUpdateActivityLogs(state.application.applicationDetail.id));
      return data;
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your comment has not been added. Please try again', { type: 'error' }));
      }
      return Promise.reject();
    });
};

export const showErrorToast = message => dispatch => (
  dispatch(showToast(message, { type: 'error' }))
);

export const acknowledgeNotification = notificationId => async (dispatch, getState) => {
  const state = getState();
  const businessId = get(state, 'business.selectedBusiness.id', null);
  const applicationId = get(state, 'application.applicationDetail.id', null);
  const notification = {
    businessId,
    applicationId,
    applicationUpdateId: notificationId,
  };
  try {
    dispatch({
      type: types.ACKNOWLEDGE_UPDATE_START,
      updateId: notificationId,
    });
    await graphQL.acknowledgeApplicationUpdate(notification);
    dispatch({
      type: types.ACKNOWLEDGE_UPDATE_SUCCESS,
      updateId: notificationId,
    });
  } catch (error) {
    dispatch({
      type: types.ACKNOWLEDGE_UPDATE_ERROR,
      updateId: notificationId,
    });
    return;
  }
  await dispatch(getApplicationDetails(applicationId));
};

export const updateTaskLists = data => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_TASK_LISTS_START,
    data,
  });
  return graphQL.updateTaskLists(data)
    .then((taskLists) => {
      dispatch({
        type: types.UPDATE_TASK_LISTS_SUCCESS,
        data: taskLists,
      });
      dispatch(delayedUpdateActivityLogs(getState().application.applicationDetail.id));
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Unfortunately your changes have not been added. Please try again', { type: 'error' }));
      }
      dispatch({
        type: types.UPDATE_TASK_LISTS_ERROR,
        data,
      });
    });
};

export const changeTaskStatus = (taskId, completed) => (dispatch, getState) =>
  graphQL.changeTaskStatus(taskId, completed)
    .then((response) => {
      dispatch({
        type: types.CHANGE_TASK_STATUS_SUCCESS,
        taskId: response.data.changeTaskStatus,
        completed,
      });
      dispatch(delayedUpdateActivityLogs(getState().application.applicationDetail.id));
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        dispatch(showToast('Your changes were unable to be saved. Please try again', { type: 'error' }));
      }
    });

export const getLoans = applicationId => (dispatch) => {
  dispatch({ type: types.GET_LOANS_START });
  return graphQL.getLoans(applicationId)
    .then(({ data: { loans } }) => {
      dispatch({
        type: types.GET_LOANS_SUCCESS,
        loans,
      });
      return loans;
    });
};

export const updateSurveyContact = applicantId => (dispatch, getState) => {
  const applicationId = getState().application.applicationDetail.id;
  return graphQL.updateSurveyContact(applicationId, applicantId)
    .then((response) => {
      dispatch({
        type: types.UPDATE_SURVEY_CONTACT_SUCCESS,
        applicationId: response.data.updateSurveyContact,
        applicantId,
      });
      dispatch(delayedUpdateActivityLogs(applicationId));
    });
};

export const updateContact = contact => async (dispatch, getState) => {
  const applicationId = getState().application.applicationDetail.id;
  await graphQL.updateContact(applicationId, contact);
  await dispatch(getApplicationDetails(applicationId));
  dispatch(delayedUpdateActivityLogs(applicationId));
};

export const retrieveAdvisers = () => (dispatch, getState) => {
  const businessId = getState().business.selectedBusiness.id;
  graphQL.allianzAdvisersByBusinessId(businessId)
    .then((response) => {
      dispatch({
        type: types.RETRIEVE_REFERRAL_ADVISERS_SUCCESS,
        advisers: response.data.allianzAdvisersByBusinessId,
      });
    });
};

export const updateAllianzInsurance = (id, data) => dispatch =>
  graphQL.updateAllianzInsurance(id, data)
    .then(() => {
      dispatch({
        type: types.UPDATE_APPLICATION_GENERAL_INSURANCE_SUCCESS,
        data,
      });
      dispatch(delayedUpdateActivityLogs(id));
      return Promise.resolve();
    })
    .catch((error) => {
      if (isGraphqlError(error)) {
        const errorMessage = JSON.parse(error);
        if (errorMessage.errorCode === 'settled_date_existed') {
          return Promise.reject();
        }
        if (data.allianzOptOut) {
          dispatch(showToast('Failed to update. Please try again.', { type: 'error' }));
        } else {
          dispatch(showToast('Unfortunately, we cant connect to Allianz at the moment. Please try later.', { type: 'error' }));
        }
      }
      return Promise.reject();
    });

export const updateLoanProtectionInsurance = (id, data) => async (dispatch) => {
  dispatch({ type: types.UPDATE_LOAN_PROTECTION_INSURANCE_START });
  try {
    await graphQL.updateLoanProtectionInsurance(id, data);
  } catch (error) {
    dispatch({
      type: types.UPDATE_LOAN_PROTECTION_INSURANCE_ERROR,
      data,
    });
    return;
  }
  dispatch({
    type: types.UPDATE_LOAN_PROTECTION_INSURANCE_SUCCESS,
    data,
  });
  await dispatch(delayedUpdateActivityLogs(id));
};

export const APPLICATION_TRACKING = 'APPLICATION_TRACKING';

const resolveValidationMessages = (responseResult, contextData) => {
  const { errorCode, message } = get(responseResult, 'graphQLErrors[0]');
  let messages = [];
  if (errorCode === 400) {
    messages = resolveError(get(JSON.parse(message), 'message', ''), contextData);
  }
  return messages;
};

export const downloadDocument = (factFindId, dateStr) => (dispatch) =>
  downloadMALIDocument(factFindId)
    .then(response => openInTab(response.blob,
      `${decodeURIComponent((response.applicantNames || '').replace(/\+/g, ' '))}_${dateStr}.xml`))
    .catch(() => dispatch(showErrorToast('The document wasn’t downloaded because of an issue. Please try again.')));

// funding worksheet related actions

const updateFundingWorksheetAction = createAction(types.UPDATE_FUNDING_WORKSHEET);

export const getNormalizedFundingWorksheetFormData = (data) => {
  const notEmptyOrMissingValue = it => !isEmpty(it) && !some(it, isNil);
  const trimFieldName = it => ({
    ...it,
    fieldName: trim(it.fieldName),
  });
  return ({
    applicationCosts: data.applicationCosts,
    contributions: {
      ...data.contributions,
      otherFunds: filter(data.contributions.otherFunds, notEmptyOrMissingValue)
        .map(trimFieldName),
    },
    costToPurchases: map(data.costToPurchases, (costToPurchase) => ({
      ...costToPurchase,
      otherCosts: filter(costToPurchase.otherCosts, notEmptyOrMissingValue)
        .map(trimFieldName),
    })),
    refinances: map(data.refinances, (refinance) => ({
      ...refinance,
      others: filter(refinance.others, notEmptyOrMissingValue)
        .map(trimFieldName),
    })),
    constructions: map(data.constructions, (construction) => ({
      ...construction,
      otherCosts: filter(construction.otherCosts, notEmptyOrMissingValue)
        .map(trimFieldName),
    })),
    loanAmount: data.loanAmount,
    somaComments: data.somaComments,
    otherCosts: filter(data.otherCosts, notEmptyOrMissingValue).map(trimFieldName),
  });
};

export const updateFundingWorksheet = (data) => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id');
  const normalizedFormData = getNormalizedFundingWorksheetFormData(data);

  await graphQL.updateFundingWorksheet(applicationId, normalizedFormData);
  dispatch(updateFundingWorksheetAction(normalizedFormData));
  await dispatch(getApplicationDetails(applicationId));
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

const updateSomasAction = createAction(types.UPDATE_SOMA);

export const updateFinalise = (data) => async (dispatch, getState) => {
  const state = getState();
  const { somas, preSubmission } = data;
  const applicationId = get(state, 'application.applicationDetail.id');
  const initialValues = getFormInitialValues('finalise')(state);
  const changedSomas = differenceWith(
    somas,
    initialValues.somas,
    isEqual,
  )
    .map(soma => pick(soma, ['id', 'issued', 'signed']));
  await graphQL.updateFinalise(applicationId, changedSomas, preSubmission);
  dispatch(updateSomasAction(data));
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

export const applyOnlineValidate = (applicationId) => async (dispatch, getState) => {
  try {
    await graphQL.applyOnlineValidate(applicationId);
    return Promise.resolve(true);
  } catch (error) {
    const { data: { loans } } = await graphQL.getLoans(applicationId);
    const { data } = await graphQL.getApplicationDetail(applicationId);
    const errorMessages = resolveValidationMessages(error, {
      application: {
        ...get(data, 'application', {}),
        loans,
      },
      toggles: getToggles(getState()),
    });
    dispatch({
      type: types.APPLY_ONLINE_VALIDATION_ERROR,
      error: errorMessages,
    });
    return Promise.resolve(false);
  }
};

export const sendToApplyOnline = (applicationId, applicationSource) => async (dispatch, getState) => {
  dispatch({ type: types.SEND_TO_APPLY_ONLINE_START });
  try {
    await graphQL.sendToApplyOnline(applicationId);
    dispatch({ type: types.SEND_TO_APPLY_ONLINE_SUCCESS });
    await dispatch(delayedUpdateActivityLogs(applicationId));
    const mediaType = getMediaType();
    if (mediaType !== MEDIA_TYPES.PHONE) {
      window.open(generateAOLURL(getNextGenIdentifier(applicationSource)), '_blank');
    }
  } catch (error) {
    const { data: { loans } } = await graphQL.getLoans(applicationId);
    const { data } = await graphQL.getApplicationDetail(applicationId);
    const errorMessages = resolveValidationMessages(error, {
      application: {
        ...get(data, 'application', {}),
        loans,
      },
      toggles: getToggles(getState()),
    });
    dispatch({
      type: types.SEND_TO_APPLY_ONLINE_ERROR,
      error: errorMessages,
    });
  }
};

export const downloadSoma = somaId => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id');
  try {
    const urlResponse = await graphQL.downloadSoma(applicationId, somaId);
    download(urlResponse.data.downloadSoma);
  } catch (error) {
    if (isGraphqlError(error)) {
      dispatch(showToast('SOMA download failed. Please try again.'));
    }
    return;
  }
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

export const downloadApplicantSoma = (data) => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id');
  dispatch({ type: types.APPLICANT_SOMA_DOWNLOAD_START });
  try {
    const urlResponse = await graphQL.createSoma(applicationId, data);
    download(urlResponse.data.createSoma);
    dispatch({ type: types.APPLICANT_SOMA_DOWNLOAD_SUCCESS });
  } catch (error) {
    dispatch({ type: types.APPLICANT_SOMA_DOWNLOAD_ERROR });
    if (isGraphqlError(error)) {
      dispatch(showToast('SOMA download failed. Please try again.'));
    }
    return;
  }
  await dispatch(getApplicationDetails(applicationId));
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

export const downloadGuarantorSoma = (data) => async (dispatch, getState) => {
  const state = getState();
  const applicationId = get(state, 'application.applicationDetail.id');
  dispatch({ type: types.GUARANTOR_SOMA_DOWNLOAD_START });
  try {
    const urlResponse = await graphQL.createSoma(applicationId, data);
    download(urlResponse.data.createSoma);

    dispatch({ type: types.GUARANTOR_SOMA_DOWNLOAD_SUCCESS });
  } catch (error) {
    dispatch({ type: types.GUARANTOR_SOMA_DOWNLOAD_ERROR });
    if (isGraphqlError(error)) {
      dispatch(showToast('SOMA download failed. Please try again.'));
    }
    return;
  }
  await dispatch(getApplicationDetails(applicationId));
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

const dateValueFormatter = value => (value ? moment(value)
  .format('YYYY-MM-DD') : value);

export const updateApplicants = (values) => async (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');
  const data = {
    applicants: {
      persons: values.applicants.map(({
                                        id, creditGuideSentDate, maritalStatus,
                                        isPrimary, isNewApplicant, isVerified,
                                      }) => {
        const spouse = get(maritalStatus, 'spouse');
        return ({
          id,
          isNewApplicant,
          creditGuideSentDate: dateValueFormatter(creditGuideSentDate),
          clientTimezone: momentTimezone.tz.guess(),
          maritalStatus: {
            spouse: isEmpty(spouse) ? null : {
              relatedApplicantId: spouse.relatedApplicantId,
              firstName: trim(spouse.firstName),
              surname: trim(spouse.surname),
              isNonApplicant: spouse.isNonApplicant,
            },
          },
          isPrimary,
          isVerified: isVerified || false,
        });
      }),
    },
  };
  await graphQL.updateApplicants(applicationId, data);
  await dispatch(getApplicationDetails(applicationId));
  await dispatch(delayedUpdateActivityLogs(applicationId));
};

const getPersonCommonFields = (person) => {
  const {
 id, creditGuideSentDate, maritalStatus, isNewApplicant, isVerified,
} = person;
  const spouse = get(maritalStatus, 'spouse');
  return ({
    id,
    isNewApplicant,
    creditGuideSentDate: dateValueFormatter(creditGuideSentDate),
    clientTimezone: momentTimezone.tz.guess(),
    maritalStatus: {
      spouse: isEmpty(spouse) ? null : {
        relatedApplicantId: spouse.relatedApplicantId,
        firstName: trim(spouse.firstName),
        surname: trim(spouse.surname),
        isNonApplicant: spouse.isNonApplicant,
      },
    },
    isVerified: isVerified || false,
  });
};

export const updateApplicantsAndGuarantors = (values) => async (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');

  const data = {
    applicants: {
      persons: values.applicants?.filter(applicant => applicant.isPerson)?.map(applicant => {
        const { isPrimary } = applicant;
        return { ...getPersonCommonFields(applicant), isPrimary };
      }),
      companies: values.applicants?.filter(applicant => !applicant.isPerson)?.map(({
                                                     id, creditGuideSentDate, isPrimary,
                                                     isNewApplicant, directors, trustees, beneficiaries,
                                                   }) => ({
        id,
        isPrimary,
        isNewApplicant,
        creditGuideSentDate: dateValueFormatter(creditGuideSentDate),
        clientTimezone: momentTimezone.tz.guess(),
        directors: filter(directors, item => !isEmpty(item)),
        trustees: filter(trustees, item => !isEmpty(item)),
        beneficiaries: filter(beneficiaries, item => !isEmpty(item)),
      })),
    },
    guarantors: {
      persons: values.guarantors?.filter(guarantor => guarantor.isPerson)?.map(guarantor => (
      getPersonCommonFields(guarantor))),
      companies: values.guarantors?.filter(guarantor => !guarantor.isPerson)?.map(({
        id, isNewApplicant, directors, trustees, beneficiaries,
         }) => ({
        id,
        isNewApplicant,
        directors: filter(directors, item => !isEmpty(item)),
        trustees: filter(trustees, item => !isEmpty(item)),
        beneficiaries: filter(beneficiaries, item => !isEmpty(item)),
      })),
    },
  };
  dispatch({ type: types.UPDATE_APPLICANTS_START });
  await graphQL.updateApplicants(applicationId, data).then(() => {
    dispatch({ type: types.UPDATE_APPLICANTS_SUCCESS });
    dispatch(getApplicationDetails(applicationId));
    dispatch(delayedUpdateActivityLogs(applicationId));
  });
};

export const updateAdditionalIncomes = additionalIncomes => (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');
  const processedData = additionalIncomes.map(
    additionalIncome => {
      const isGbType = additionalIncome.type === 'GovernmentBenefits';
      return {
        ...omit(additionalIncome, 'tempId'),
        governmentBenefitsType: isGbType ? additionalIncome.governmentBenefitsType : undefined,
      };
    },
  );

  return graphQL.updateAdditionalIncomes(applicationId, processedData)
    .then(() => dispatch(getApplicationDetails(applicationId)))
    .then(() => dispatch(delayedUpdateApplicationStatus(applicationId)));
};

const formatDefaultOwnerships = applicantsId => {
  if (applicantsId.length === 0) {
    return [];
  }
  const defaultPercentageValue = (100 - (100 % applicantsId.length)) / applicantsId.length;
  const defaultOwnerships = applicantsId.map(id => (
    {
      applicantId: id,
      percentage: defaultPercentageValue,
    }
  ));
  const remainPercentageValue = 100 % applicantsId.length;
  if (defaultOwnerships.length > 0) {
    defaultOwnerships[0] = {
      ...defaultOwnerships[0],
      percentage: defaultPercentageValue + remainPercentageValue,
    };
  }
  return defaultOwnerships;
};

export const formatHouseholdExpenses = householdExpenses => householdExpenses?.map(householdExpense => {
  const applicantsId = householdExpense?.household?.applicants;
  return {
    id: householdExpense?.id || null,
    household: {
      id: householdExpense?.household?.id || null,
      applicantsId,
      dependents: householdExpense?.household?.dependents,
    },
    expenses: (householdExpense?.expenses || []).map(expense => ({
      ...expense,
      monthlyValue: toMonthlyValue(expense.periodUnit || MONTHLY.value, expense.value || 0),
      ownerships: formatDefaultOwnerships(applicantsId),
    })),
  };
});

export const updateExpenseInfo = expenseInfo => (dispatch, getState) => {
  const formattedExpenseInfo = {
    expenseNote: expenseInfo.expenseNote,
    householdExpenses: formatHouseholdExpenses(expenseInfo?.householdExpenses),
  };
  const applicationId = get(getState(), 'application.applicationDetail.id');
  return graphQL.updateExpenseInfo(applicationId, formattedExpenseInfo)
    .then(() => dispatch(getApplicationDetails(applicationId)))
    .then(() => dispatch(delayedUpdateActivityLogs(applicationId)));
};

export const updateEmployments = (values) => async (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');
  const data = {
    persons: values.persons.map(getPersonInfo),
    companies: values.companies.map(getCompanyInfo),
    personGuarantors: values.personGuarantors.map(getPersonInfo),
    companyGuarantors: values.companyGuarantors.map(getCompanyInfo),
  };
  dispatch({ type: types.UPDATE_EMPLOYMENTS_START });
  await graphQL.updateEmployments(applicationId, data)
    .then(() => {
      dispatch({ type: types.UPDATE_EMPLOYMENTS_SUCCESS });
      dispatch(getApplicationDetails(applicationId));
      dispatch(delayedUpdateActivityLogs(applicationId));
    });
};

const validIncomeRule = (ignoreFields) => (income) =>
  !isEmpty(income) && _values(omit(income, ignoreFields)).some(item => item);

const getPersonInfo = person => ({
  id: person.id,
  employments: person.employments?.map(
    employment => ({
      ...employment,
      employmentIncomes: employment.employmentIncomes?.filter(
        validIncomeRule(['id', employment.employmentType === employmentTypes.SELF_EMPLOYED && 'periodUnit'].filter(Boolean)),
      ),
    }),
  ),
});

const getCompanyInfo = ({ id, financialYear, incomes }) => ({
  id,
  companyIncomeInfo: {
    financialYear,
    incomes: incomes?.filter(validIncomeRule(['id', 'periodUnit', 'incomeValues']))
      .map(income => ({
        id: income.id,
        type: income.type,
        addbackType: income.addbackType,
        description: income.description,
        incomeValues: [income.value, income.previousValue],
      })),
  },
});

const formatFutureCircumstances = futureCircumstances => futureCircumstances.map(item => ({
  applicantId: item.applicantId,
  adverseChanges: {
    ...item.adverseChanges,
    significantChanges: item.adverseChanges?.significantChanges?.map(change => ({
      ...change,
      typeOtherDetail: change.typeOtherDetail?.trim(),
      startDate: change.startDate ? toDateStamp(change.startDate) : undefined,
      repaymentPlanOtherDetail: change.repaymentPlanOtherDetail?.trim(),
    })),
  },
  retirementPlan: {
    ...item.retirementPlan,
    repaymentMethodOtherDetail: item.retirementPlan.repaymentMethodOtherDetail?.trim(),
  },
}));

const filtered = futureCircumstances => filter(futureCircumstances, (item) => {
  const targetItem = omit(item, ['applicantId', 'applicantName']);
  const values = flatten(map(targetItem, v => map(v)));
  return some(values, value => !(isNil(value) || (!isNumber(value) && isEmpty(value))));
});

export const updateNeedsAndObjectives = (data) => (dispatch, getState) => {
  const state = getState();
  const applicationId = state?.application.applicationDetail.id;

  const normalizedFormData = {
    ...data,
    futureCircumstances: formatFutureCircumstances(filtered(get(data, 'futureCircumstances'))),
    guarantorFutureCircumstances: formatFutureCircumstances(filtered(get(data, 'guarantorFutureCircumstances'))),
  };
  return graphQL.updateNeedsAndObjectives(applicationId, normalizedFormData)
    .then(() => dispatch(getApplicationDetails(applicationId)))
    .then(() => dispatch(delayedUpdateActivityLogs(applicationId)));
};

export const updateLiabilities = (data) => (dispatch, getState) => {
  const state = getState();
  const applicationId = state?.application.applicationDetail.id;
  const filteredData = {
    liabilities: data?.liabilities?.map(omitEmptyObject),
  };

  return graphQL.updateLiabilities(applicationId, filteredData)
    .then(() => {
      dispatch({
        type: types.UPDATE_LIABILITIES_SUCCESS,
        data: filteredData,
        applicationId,
      });
      dispatch(getApplicationDetails(applicationId));
      dispatch(delayedUpdateActivityLogs(applicationId));
    });
};

export const syncApplicantsInfo = () => (dispatch, getState) => {
  const applicationId = get(getState(), 'application.applicationDetail.id');
  dispatch({ type: types.SYNC_APPLICANTS_INFO_START });
  return graphQL.syncApplicantsInfo(applicationId)
    .then(response => {
      dispatch({
        type: types.SYNC_APPLICANTS_INFO_SUCCESS,
        data: formatApplication(get(response, 'data.syncApplicantsInfo')),
      });
      dispatch(delayedUpdateActivityLogs(applicationId));
    })
    .catch(() => dispatch({ type: types.SYNC_APPLICANTS_INFO_ERROR }));
};

const omitEmptyObject = (data) => omitBy(data, property => (
  isPlainObject(property) && isEmpty(omitBy(property, subProp => isNil(subProp) || subProp === ''))
));
