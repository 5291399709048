export const LENDER_OPTIONS = [
  {
    name: 'LMI - Gemis',
    category: 'Mortgage Insurer',
  },
  {
    name: 'Westpac',
    category: 'Home',
  },
  {
    name: 'NAB',
    category: 'Home',
  },
  {
    name: 'ANZ',
    category: 'Home',
  },
  {
    name: 'CBA',
    category: 'Home',
  },
];
