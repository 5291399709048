import React from 'react';
import shortid from 'shortid';
import isInteger from 'lodash/isInteger';
import { formatCurrency } from 'utils/formatters';
import { toShortDateStr } from 'utils/datetime';
import { applicationStatus } from 'ApplicationTracking/constants/applicationStatus';
import styles from '../../Applications.module.scss';
import emptyStringToNA from '../../../../utils';
import { ApplicationShape } from '../ApplicationShape';

const formatLoanAmount = amount =>
  formatCurrency(amount, { symbol: '$', precision: isInteger(amount) ? 0 : 2 });

const ApplicationInfo = ({ application }) => {
  const {
    applicants,
    totalLoanAmount,
    adviser,
    applicationNumber,
    applicationStatus: {
      status,
      statusChangeTime,
    },
  } = application;
  return (
    <div className={styles.applicationInfo}>
      <div>
        <p className={styles.title}>APPLICANT/S</p>
        {applicants && applicants.map(({ abbreviateName, applicantUniqueKey }) => (
          <p className={styles.info} key={applicantUniqueKey || shortid.generate()}>{abbreviateName}</p>
        ))}
      </div>
      <div>
        <p className={styles.title}>LOAN AMOUNT</p>
        <p className={styles.info}>{formatLoanAmount(totalLoanAmount)}</p>
      </div>
      <div>
        <p className={styles.title}>ADVISER</p>
        <p className={styles.info}>{adviser.name}</p>
      </div>
      {
        status === applicationStatus.PRE_SUBMISSION
        ? (
          <div>
            <p className={styles.title}>DATE CREATED</p>
            <p className={styles.info}>{emptyStringToNA(toShortDateStr(statusChangeTime))}</p>
          </div>
        )
        : (
          <div>
            <p className={styles.title}>APPLICATION NUMBER</p>
            <p className={styles.info}>{emptyStringToNA(applicationNumber)}</p>
          </div>
        )
      }
    </div>
  );
};

ApplicationInfo.propTypes = {
  application: ApplicationShape.isRequired,
};

export default ApplicationInfo;
