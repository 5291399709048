import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import userCan from 'shared/components/authorisation/userCan';
import NoAccess from 'shared/components/NoAccess/NoAccess';
import withSyncBusinessId from 'shared/components/WithSyncBusinessId/withSyncBusinessId';
import ConnectedCommissions from './Commissions/Commissions';
import PermissionSettings from './PermissionSettings/PermissionSettings';
import CommissionManagementReportsSummary from './CommissionManagementReports/CommissionManagementReportsSummary';
import InvoiceSummaryComponent from './Invoices/InvoiceSummary';
import CommissionManagementReportDetails from './CommissionManagementReports/CommissionManagementReportDetails';
import TemplateManagement from './TemplateManagement/TemplateManagement';
import { PERMISSIONS } from '../../shared/components/authorisation/permissions';

const MyBusinessRoutes = () => (
  <>
    <Route exact path="/invoices" component={withSyncBusinessId(userCan(PERMISSIONS.VIEW_LOANAPPLICATION_INVOICES)(InvoiceSummaryComponent))} />
    <Route exact path="/commissions" component={withSyncBusinessId(userCan(PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONS)(ConnectedCommissions))} />
    <Route exact path="/permissions" component={withSyncBusinessId(userCan(PERMISSIONS.EDIT_PERMISSION)(PermissionSettings))} />
    <Route exact path="/commission-management-reports" component={withSyncBusinessId(userCan(PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONMANAGEMENT)(CommissionManagementReportsSummary))} />
    <Route
      path="/commission-management-reports/:processingMonth"
      component={
      withSyncBusinessId(
        userCan(PERMISSIONS.VIEW_LOANAPPLICATION_COMMISSIONMANAGEMENT)(CommissionManagementReportDetails),
        '/commission-management-reports',
      )
}
    />
    <Route exact path="/template-management" component={withSyncBusinessId(userCan(PERMISSIONS.VIEW_TEMPLATE_MANAGEMENT)(TemplateManagement))} />
    <Route exact path="/noAccess" component={NoAccess} />
  </>
);

export default withRouter(MyBusinessRoutes);
