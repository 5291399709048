import React from 'react';
import PropTypes from 'prop-types';

import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import ConnectedLoanAmount from '../ProductSearchFields/LoanAmount';
import ConnectedPurpose from '../ProductSearchFields/Purpose';
import ConnectedProductType from '../ProductSearchFields/ProductType';
import ConnectedRepaymentType from '../ProductSearchFields/RepaymentType';
import ConnectedLoanTerm from '../ProductSearchFields/LoanTerm';
import ConnectedFixedLoanTerm from '../ProductSearchFields/FixedLoanTerm';
import ConnectedInterestOnlyTerm from '../ProductSearchFields/InterestOnlyTerm';
import Feature from '../Feature';

const LoanCriteria = ({ index, onRemoveClick }) => (
  <div className="criteria-section">
    <div className="criteria-header">
      <div>
        <h2 style={{ display: 'inline-block' }}> Loan criteria </h2>
        <h3
          className="criteria-subtitle"
          style={{ display: 'inline-block', marginLeft: '1rem' }}
        >
          {`Loan ${index + 1}`}
        </h3>
      </div>

      {index !== 0 && (
        <span className="criteria-remove-button">
          <TertiaryButton onClick={() => onRemoveClick(index)} icon="close">
            Remove
          </TertiaryButton>
        </span>
      )}
    </div>
    <div className="criteria-body">
      <div className="first-section">
        <div>
          <ConnectedLoanAmount index={index} />
        </div>
        <div>
          <ConnectedPurpose index={index} />
          <ConnectedProductType index={index} />
          <ConnectedRepaymentType index={index} />
        </div>
        <div>
          <ConnectedLoanTerm index={index} />
          <ConnectedFixedLoanTerm index={index} />
          <ConnectedInterestOnlyTerm index={index} />
        </div>
      </div>
      <div className="features-section">
        <Feature index={index} />
      </div>
    </div>
  </div>
);

LoanCriteria.defaultProps = {
  onRemoveClick: () => {},
};

LoanCriteria.propTypes = {
  index: PropTypes.number.isRequired,
  onRemoveClick: PropTypes.func,
};

export default LoanCriteria;
