import omit from 'lodash/omit';
import { getLendersConfigFromApi } from 'shared/api';
import { createReducer } from '../utils/reducerGenerator';

export const ACTIONS = {
  FETCH_LENDERS_CONFIG_START: 'FETCH_LENDERS_CONFIG_START',
  FETCH_LENDERS_CONFIG_SUCCESS: 'FETCH_LENDERS_CONFIG_SUCCESS',
  FETCH_LENDERS_CONFIG_ERROR: 'FETCH_LENDERS_CONFIG_ERROR',
};

export const getLendersConfig = () => (dispatch) => {
  dispatch({ type: ACTIONS.FETCH_LENDERS_CONFIG_START });
  return getLendersConfigFromApi()
    .then(lendersConfig => dispatch({
      type: ACTIONS.FETCH_LENDERS_CONFIG_SUCCESS,
      lendersConfig,
    }))
    .catch(err => dispatch({
      type: ACTIONS.FETCH_LENDERS_CONFIG_ERROR,
      err,
    }));
};

export const getLenderValidationConfigById = (lenderConfigs, lenderId) => {
  const { config: { lenderConfigList, defaultLenderValidationConfig } } = lenderConfigs;

  return lenderConfigList.find(config => config.lenderId === lenderId) || defaultLenderValidationConfig;
};

const initialState = {
  config: {
    lenderConfigList: [],
  },
  hasError: false,
  isLoading: false,
};

export default createReducer(initialState, {
  [ACTIONS.FETCH_LENDERS_CONFIG_START]: state => ({ ...state, ...initialState, isLoading: true }),
  [ACTIONS.FETCH_LENDERS_CONFIG_SUCCESS]: (state, { lendersConfig }) => ({
    ...state,
    config: omit(lendersConfig, 'lendersColorConfig'),
    colorConfig: lendersConfig.lendersColorConfig,
    hasError: false,
    isLoading: false,
  }),
  [ACTIONS.FETCH_LENDERS_CONFIG_ERROR]: state => ({ ...state, ...initialState, hasError: true }),
});
