import React from 'react';
import PropTypes from 'prop-types';
import CircleNumber from 'shared/components/CircleNumber';
import LenderIconImage from 'shared/components/LenderIconImage';
import styles from '../../Applications.module.scss';

const CardHeaderInfo = ({ lender, applicationUpdateCount }) => (
  <>
    <div>
      {!!applicationUpdateCount && <CircleNumber number={applicationUpdateCount} />}
    </div>
    {lender.name && <LenderIconImage lender={lender} className={styles.lenderIcon} />}
  </>
);

CardHeaderInfo.defaultProps = {
  lender: {
    name: '',
    lenderId: '',
  },
};

CardHeaderInfo.propTypes = {
  lender: PropTypes.shape({
    name: PropTypes.string,
    lenderId: PropTypes.string,
  }),
  applicationUpdateCount: PropTypes.number,
};

CardHeaderInfo.defaultProps = {
  applicationUpdateCount: undefined,
};

export default CardHeaderInfo;
