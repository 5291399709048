import React from 'react';
import PropTypes from 'prop-types';
import { SummaryTile } from '../SummaryTile';
import { formatAmountInteger } from '../../../../../utils/formatters';
import { FETCHING_STATUS } from '../../../../../constants';
import { toLongMonthStr, toYear } from '../../../../../utils/datetime';
import LoadingSpinner from '../../../../../shared/components/LoadingSpinner/LoadingSpinner';
import styles from './styles.module.scss';

const getItem = (infoKey, infoValue) => ({
  key: infoKey,
  value: infoValue,
});

const longDateFormatter = (date) => (`${toLongMonthStr(date)} ${toYear(date)}`);

export const SettlementSummaryReport = React.memo(({
                                           fetchingStatus,
                                           settlementSummary,
                                         }) => {
  const {
    curDate,
    preDate,
    curAmount,
    preAmount,
    curSettlementApplicationNumber,
  } = settlementSummary;

  const settledSummaryCurInfo = [
    getItem('curDate', longDateFormatter(curDate)),
    getItem('so far', '(so far)'),
  ];
  const settledSummaryPreInfo = [getItem('preDate', longDateFormatter(preDate))];

  const getCurAmountNode = (amount, applicationNumber) => (
    <div className={styles.amountNode}>
      <div className={styles.price}>{amount}</div>
      <div className={styles.number}>
        {applicationNumber}
        <span>appl.</span>
      </div>
    </div>
);

  return (
    <>
      {
        fetchingStatus === FETCHING_STATUS.START && <LoadingSpinner />
      }
      {
        fetchingStatus === FETCHING_STATUS.SUCCESS && (
          <div className={styles.summaryReport}>
            <div className={styles.summarySmallSection}>
              <SummaryTile
                title="Value of Settled applications"
                currentInfo={settledSummaryCurInfo}
                currentValueAmount={getCurAmountNode(formatAmountInteger(curAmount),
                  curSettlementApplicationNumber)}
                previousInfo={settledSummaryPreInfo}
                previousValueAmount={formatAmountInteger(preAmount)}
              />
            </div>
          </div>
        )
      }
    </>
  );
});

SettlementSummaryReport.defaultProps = {
  fetchingStatus: FETCHING_STATUS.START,
  settlementSummary: {},
};

SettlementSummaryReport.propTypes = {
  fetchingStatus: PropTypes.number,
  settlementSummary: PropTypes.object,
};
