import React from 'react';
import { baseIcon, iconDefaultProps } from './baseIcon';

const SvgIcon = ({
  color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>
    <g
      id="Icon/drag"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M15 4.667c.917 0 1.667.75 1.667 1.666C16.667 7.25 15.917 8 15 8s-1.667-.75-1.667-1.667c0-.916.75-1.666 1.667-1.666zm0 12c.917 0 1.667.75 1.667 1.666 0 .917-.75 1.667-1.667 1.667s-1.667-.75-1.667-1.667c0-.916.75-1.666 1.667-1.666zm0-6c.917 0 1.667.75 1.667 1.666 0 .917-.75 1.667-1.667 1.667s-1.667-.75-1.667-1.667c0-.916.75-1.666 1.667-1.666zm-5.333-6c.916 0 1.666.75 1.666 1.666 0 .917-.75 1.667-1.666 1.667C8.75 8 8 7.25 8 6.333c0-.916.75-1.666 1.667-1.666zm0 12c.916 0 1.666.75 1.666 1.666 0 .917-.75 1.667-1.666 1.667C8.75 20 8 19.25 8 18.333c0-.916.75-1.666 1.667-1.666zm0-6c.916 0 1.666.75 1.666 1.666 0 .917-.75 1.667-1.666 1.667C8.75 14 8 13.25 8 12.333c0-.916.75-1.666 1.667-1.666z"
        id="Shape"
        fill={color}
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
