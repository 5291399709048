import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, formPropTypes, reduxForm } from 'redux-form';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import styles from './styles.module.scss';
import LoansCriteria from './LoansCriteria';
import { FORM_NAME } from '../constants';
import validateSearchCriteria from './validateSearchCriteria';

const SearchCriteriaForm = ({
 form, handleSubmit, submitting, valid,
}) => (
  <form onSubmit={handleSubmit}>
    <div className={styles.criteriaContainer}>
      <FieldArray
        name="loansCriteria"
        component={LoansCriteria}
        formName={form}
      />
    </div>
    <div className={styles.searchButton}>
      <PrimaryButton
        disabled={!valid || submitting}
        type="submit"
        loading={submitting}
      >
        Search
      </PrimaryButton>
    </div>
  </form>
);

SearchCriteriaForm.propTypes = {
  form: PropTypes.string.isRequired,
  ...formPropTypes,
};

SearchCriteriaForm.formName = FORM_NAME;

export default reduxForm({
  form: SearchCriteriaForm.formName,
  destroyOnUnmount: false,
  validate: validateSearchCriteria,
  enableReinitialize: false,
})(SearchCriteriaForm);
