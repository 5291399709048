import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import styles from '../styles.module.scss';

const getClassName = (valueOrFunction, params) =>
  (isFunction(valueOrFunction) ? valueOrFunction(params) : valueOrFunction);

const tableRowFunction = (TableComponent, rowClassName) => {
  const tableRow = (props) => {
    const tableRowClassName = getClassName(rowClassName, props);
    const { className, rowIndex, ...others } = props;
    return (
      <TableComponent.Row
        {...others}
        className={classNames(
          styles.tableRow,
          tableRowClassName,
          rowIndex % 2 === 1 ? 'mobius-table-odd-row' : 'mobius-table-even-row',
          {
            [styles.oddRow]: rowIndex % 2 === 1,
          },
        )}
      />
    );
  };

  tableRow.propTypes = {
    className: PropTypes.string,
    tableRow: PropTypes.shape({
      rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired,
    style: PropTypes.object,
    row: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    rowIndex: PropTypes.number.isRequired,
  };

  tableRow.defaultProps = {
    className: '',
    style: {},
  };

  return React.memo(tableRow);
};

export default tableRowFunction;
