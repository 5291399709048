import get from 'lodash/get';
import { containOther } from '../utils';

const createOtherDetailValidator = ({ otherDetailField, selectField }) => ({
  [otherDetailField]: (otherDetail, allValues) => {
    const value = get(allValues, selectField);
    if (containOther(value) && !otherDetail?.trim()) {
      return 'Other detail is required';
    }
    return null;
  },
});

export default createOtherDetailValidator;
