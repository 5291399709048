import { ACTIONS } from './variantModificationActions';
import {
  initialState,
  processVariantModificationEditFail,
  processVariantModificationEditStart,
  processVariantModificationEditSubmit,
  processVariantModificationEditSucess,
  processVariantModificationUpdateValue,
  processVariantMoficationEditCancel,
} from './variantModification';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.VARIANT_MODIFICATION_EDIT_START: {
      return processVariantModificationEditStart(state, action);
    }
    case ACTIONS.VARIANT_MODIFICATION_EDIT_CANCEL: {
      return processVariantMoficationEditCancel(state);
    }
    case ACTIONS.VARIANT_MODIFICATION_EDIT_SUBMIT: {
      return processVariantModificationEditSubmit(state);
    }
    case ACTIONS.VARIANT_MODIFICATION_EDIT_SUCCESS: {
      return processVariantModificationEditSucess(state);
    }
    case ACTIONS.VARIANT_MODIFICATION_UPDATE_VALUE: {
      return processVariantModificationUpdateValue(state, action);
    }
    case ACTIONS.VARIANT_MODIFICATION_EDIT_FAIL: {
      return processVariantModificationEditFail(state);
    }
    default:
      return state;
  }
};
