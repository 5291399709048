import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import TextInput from 'shared/components/formFields/TextInput/TextInput';
import Popover from 'shared/components/NavbarMenu/Popover';
import LoanStatusFilterContent from './LoanStatusFilterContent';
import { findStatusNameByKey } from './LoanStatus';
import styles from './styles.module.scss';

const LoanStatusFilter = ({ disabled, onFilter, filter }) => {
  const [filterName, updateFilterName] = useState('');

  const contentRef = useRef(null);
  const triggerRef = useRef(null);

  const isChildTarget = target => (
      contentRef?.current?.contains(target)
      || triggerRef?.current?.contains(target)
    );

  const handleOnBlur = (event, toggle) => {
    if (event.relatedTarget && !isChildTarget(event.relatedTarget)) {
      toggle(false);
    }
  };

  const getFilterName = (selectedStatusList) => {
    if (isEmpty(selectedStatusList)) {
      return '';
    }
    if (selectedStatusList.length === 1) {
      return findStatusNameByKey(selectedStatusList[0]);
    }
    return 'Multiple';
  };

  return (
    <Popover
      trigger={toggle => (
        <div ref={triggerRef}>
          <TextInput
            onFocus={() => toggle(true)}
            onBlur={(event) => {
              handleOnBlur(event, toggle);
            }}
            mini
            fullWidth
            value={filterName}
            placeholder="Filter..."
            disabled={disabled}
            className={styles.loanStatusInput}
          />
        </div>
      )}
    >
      {(toggle, isShown) => (isShown && (
        <LoanStatusFilterContent
          ref={contentRef}
          onBlur={(event) => {
            handleOnBlur(event, toggle);
          }}
          filter={filter}
          onFilter={(selection) => {
            updateFilterName(getFilterName(selection));
            onFilter(isEmpty(selection) ? undefined : { value: selection });
            toggle();
          }}
        />
      ))}
    </Popover>
  );
};

LoanStatusFilter.defaultProps = {
  disabled: false,
};

LoanStatusFilter.propTypes = {
  disabled: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LoanStatusFilter;
