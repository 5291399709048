import React, {
 useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import NumberInputWithReadonly from 'shared/components/formFields/NumberInput/WithReadOnlyNumberInput';
import styles from './WrappedOwnerships.module.scss';

const Ownerships = ({
  meta: { error },
  applicants,
  input: { value: ownerships, onChange },
  rowSpan,
}) => {
  const [ownershipMap, setOwnershipMap] = useState(
    applicants.reduce((result, { id }) => {
      const ownership = ownerships.find(({ applicantId }) => id === applicantId) ?? {
        applicantId: id,
        percentage: 0,
      };
      return { ...result, [id]: ownership };
    }, {}),
  );
  const nameMap = useMemo(() =>
    applicants.reduce((result, { id, name }) => ({
      ...result, [id]: name,
    }), {}),
   [applicants]);

  const handleInputChange = useMemo(() => (value, applicantId) => {
    const latestOwnershipMap = {
      ...ownershipMap,
      [applicantId]: {
        applicantId,
        percentage: value || 0,
      },
    };
    setOwnershipMap(latestOwnershipMap);
  }, [ownershipMap, setOwnershipMap]);

  useEffect(() => {
    const validOwnerships = Object.values(ownershipMap)
      .filter(({ percentage }) => percentage > 0);
    onChange(validOwnerships);
  }, [onChange, ownershipMap]);

  return (
    <div className={styles.ownershipField} style={{ gridRowEnd: `span ${rowSpan}` }}>
      <NewInputLabelWrapper label="Ownership" errorMessage={error}>
        {Object.values(ownershipMap).map(({ applicantId, percentage }) => (
          <NumberInputWithReadonly
            key={applicantId}
            className={styles.labelInputOverlay}
            suffix="%"
            placeholder="%"
            allowNegative={false}
            decimalScale={0}
            readOnlyFormatter={value => `${value}%`}
            isAllowed={value => (100 - (value || 0)) >= 0}
            label={nameMap[applicantId]}
            title={nameMap[applicantId]}
            value={percentage}
            onChange={value => handleInputChange(value, applicantId)}
          />
          ))}
      </NewInputLabelWrapper>
    </div>
  );
};

Ownerships.propTypes = {
  meta: PropTypes.object.isRequired,
  applicants: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  rowSpan: PropTypes.number.isRequired,
};

export default Ownerships;
