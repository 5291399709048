import React from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import classNames from 'classnames';
import styles from './styles.module.scss';

const toSelectValue = (value, options) =>
  options.find(option => option.value === value);

const FinancialYearComponent = (props) => {
  const { selected, years, dispatchUpdateFinancialYear } = props;
  const options = years.map(year => ({ value: year, label: year }));
  return (
    <Select
      label="Financial year"
      className={classNames(
        'select-input',
        'financialYear',
        styles.financialYear,
      )}
      value={toSelectValue(selected, options)}
      onChange={e => dispatchUpdateFinancialYear(e.value)}
      options={options}
    />
  );
};

FinancialYearComponent.defaultProps = {
  selected: undefined,
  years: [],
};

FinancialYearComponent.propTypes = {
  dispatchUpdateFinancialYear: PropTypes.func.isRequired,
  selected: PropTypes.string,
  years: PropTypes.arrayOf(PropTypes.string),
};

export default FinancialYearComponent;
