import { formatAmount } from 'utils/formatters';
import { InputYesNo } from 'Loans/components/LoanDetails/LoanDetailsContent/inputs/InputYesNo';
import { formatBool } from 'shared/formatterUtils';
import { FIELD_SIDE_BAR_COLORS } from 'shared/components/Field/Field';
import React from 'react';
import {
  EditField,
  USER_PERMISSIONS,
} from 'Loans/components/LoanDetails/EditField';

export const getTrailFields = (
  { isTrailExpected, currentBalance },
  reconciledDatePresent,
) => [
  {
    title: 'Trail expected',
    content: (
      <EditField
        permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
        fallback={formatBool(isTrailExpected)}
      >
        <InputYesNo value={isTrailExpected} name="isTrailExpected" disabled={reconciledDatePresent} />
      </EditField>
    ),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
  {
    title: 'Loan balance',
    content: formatAmount(currentBalance),
    sideBarColour: FIELD_SIDE_BAR_COLORS.LightSquash,
  },
];
