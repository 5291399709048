import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'shared/components/formFields/NumberInput/NumberInput.module.scss';
import NumberFormat from 'react-number-format';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import {
  inputBaseDefaultProps,
  inputBaseProps,
} from 'shared/components/formFields/base/baseProps';

const MAX_NUMBER = 999999999.99;

const getClassName = (className, isDisabled, errorMessage, textClassName) =>
  classNames(
    className,
    textClassName,
    { [styles.disabled]: isDisabled },
    { [styles.textContainerError]: !!errorMessage },
  );

const NumberInput = ({
  value,
  label,
  errorMessage,
  className,
  textClassName,
  disabled,
  onChange,
  onManualChange,
  fullWidth,
  style,
  isAllowed,
  isRequired,
  title,
  formatter,
  ...others
}) => {
  const isAllowedWrapper = number => (number === undefined || number <= MAX_NUMBER) && isAllowed(number);

  return (
    <NewInputLabelWrapper
      label={label}
      errorMessage={errorMessage}
      className={className}
      fullWidth={fullWidth}
      style={style}
      isRequired={isRequired}
      title={title}
    >
      <NumberFormat
        value={value}
        type="text"
        className={getClassName(styles.textContainer, disabled, errorMessage, textClassName)}
        onValueChange={values => onChange(values.floatValue)}
        onChange={onManualChange}
        disabled={disabled}
        isAllowed={values => isAllowedWrapper(values.floatValue)}
        thousandSeparator
        getInputRef={input =>
          input && input.setAttribute('data-lpignore', true)}
        {...others}
      />
    </NewInputLabelWrapper>
  );
};

NumberInput.defaultProps = {
  value: undefined,
  isAllowed: () => true,
  ...inputBaseDefaultProps,
};

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAllowed: PropTypes.func,
  onManualChange: PropTypes.func,
  ...inputBaseProps,
};

export default NumberInput;
