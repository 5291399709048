import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import keys from 'lodash/keys';
import mapKeys from 'lodash/mapKeys';
import groupBy from 'lodash/groupBy';
import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import parseInt from 'lodash/parseInt';
import orderBy from 'lodash/orderBy';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import { sum, sumBy } from './index';

const previousYearSerie = {
  name: 'previousYearTotalLoanAmount',
  displayName: 'Previous year values',
  key: 'previousYearTotalLoanAmount',
  type: 'spline',
  color: '#df5858',
};

const compressedOtherSerie = {
  name: 'others',
  key: 'others',
  displayName: 'Other',
  stack: true,
  color: '#666666',
};

export const loansByMonthToView = (loansByMonth, { perspective, displayWindow, context }) => {
  const { take } = perspective;
  const view = getPrimitiveView(
    loansByMonth,
    {
      perspective, viewKey: perspective.key, context, displayWindow,
    },
  );

  const compressedView = compressView(view, take);

  const viewWithPreviousYearInfo = getViewWithPreviousYearInfo(compressedView);

  return viewWithPreviousYearInfo;
};

export const getPrimitiveView = (
  loansByMonth,
  {
    perspective,
    context,
    viewKey,
    displayWindow,
  },
) => {
  const { classifier } = perspective;

  const loanGroupsByMonth = mapValues(loansByMonth, loans => groupBy(loans, classifier));

  const allGroupKeys = uniq(flatten(map(loanGroupsByMonth, keys)));

  const placeholder = mapValues(mapKeys(allGroupKeys, v => (v)), () => 0);

  const viewData = map(
    loanGroupsByMonth,
    (loanGroup, key) => getViewDataForMonth(loanGroup, key, perspective, placeholder),
  );

  const sortedGroupKeys = orderBy(
    allGroupKeys,
    key => sum(map(viewData, key)),
  ).reverse();

  const series = sortedGroupKeys.map((groupKey, index) => ({
    name: groupKey,
    key: groupKey,
    stack: true,
    displayName: perspective.getSeriesName(groupKey, index, context),
    color: perspective.getSeriesColor(groupKey, index, context),
  }));

  const subViews = series.length <= 1 ? [] : series.map((serie) => {
    const loansProjectionByMonth = mapValues(
      loanGroupsByMonth,
      loanGroup => get(loanGroup, serie.key, []),
    );
    return getPrimitiveView(
      loansProjectionByMonth,
      {
        perspective, viewKey: serie.key, context, displayWindow,
      },
    );
  });

  return {
    key: viewKey,
    name: perspective.name,
    series,
    data: viewData,
    displayWindow,
    subViews,
  };
};

const compressView = (view, take) => {
  const { series, data, subViews } = view;
  if (!isNumber(take) || series.length <= take) {
    return view;
  }
  const sortedSeries = orderBy(
    series,
    ({ key }) => (sum(map(data, key))),
  ).reverse();
  const seriesKeysToComposite = map(sortedSeries.slice(take), 'key');

  const reducedData = data.map(d => ({
    ...omit(d, seriesKeysToComposite),
    loanGroup: {
      ...d.loanGroup,
      others: flatten(map(seriesKeysToComposite, k => get(d, ['loanGroup', k], []))),
    },
    others: sum(map(seriesKeysToComposite, key => get(d, key, 0))),
  }));

  const reducedSubViews = subViews
    .filter(v => !seriesKeysToComposite.includes(v.key))
    .map(v => compressView(v, { take }));

  return {
    ...view,
    series: [...sortedSeries.slice(0, take), compressedOtherSerie],
    data: reducedData,
    compositedSeries: seriesKeysToComposite,
    subViews: [
      ...reducedSubViews,
      {
        key: 'others',
        name: 'others',
        subViews: [],
        displayWindow: view.displayWindow,
        series: [
          compressedOtherSerie,
        ],
        data: reducedData.map(d => ({
          key: d.key,
          others: d.others,
          monthlyTotalLoanAmount: d.others,
          applicationsCount: uniqBy(
            get(d, 'loanGroup.others', []),
            'applicationId',
          ).length,
        })),
      },
    ],
  };
};

const getViewDataForMonth = (loanGroup, key, { getMonthlyExtraData, getValue }, placeholders = {}) => {
  const groupedLoanAmount = mapValues(
    loanGroup,
    loans => (sumBy(loans, getValue)),
  );
  const monthlyTotalLoanAmount = sum(map(groupedLoanAmount));
  const extraInfo = isFunction(getMonthlyExtraData) ? getMonthlyExtraData(loanGroup, key) : {};
  return {
    key,
    applicationsCount: uniqBy(flatMap(loanGroup), 'applicationId').length,
    monthlyTotalLoanAmount,
    ...placeholders,
    ...groupedLoanAmount,
    loanGroup,
    ...extraInfo,
  };
};

const getViewWithPreviousYearInfo = (view) => {
  const { series, data, subViews = [] } = view;
  if (series.length < 1) {
    return view;
  }
  const dataMap = mapKeys(data, 'key');
  const getInfoByMonthKey = monthKey => (get(dataMap, monthKey, null));
  const getPreviousYearInfo = (monthKey) => {
    const [yearStr, month] = monthKey.split('-');
    const year = parseInt(yearStr);
    return getInfoByMonthKey(`${year - 1}-${month}`);
  };
  return {
    ...view,
    series: [
      ...series,
      previousYearSerie,
    ],
    data: data.map((item) => {
      const previousYearInfo = getPreviousYearInfo(item.key);
      return ({
        ...item,
        [previousYearSerie.key]: sum(
          map(
            series,
            s => get(previousYearInfo, [s.key], null),
          ),
        ),
        previousYearApplicationsCount: get(previousYearInfo, 'applicationsCount', 0),
      });
    }),
    subViews: subViews.map(v => getViewWithPreviousYearInfo(v)),
  };
};
