import React, {
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { NewInputLabelWrapper } from 'shared/components/formFields/NewInputLabelWrapper';
import CheckBox from 'shared/components/formFields/Checkbox';
import { getApplicantsAndGuarantors } from '../../../redux/selectors';
import styles from './Ownerships.module.scss';

const checkBoxStyles = {
  label: {
    marginBottom: 0,
  },
};

const Ownerships = ({
                      applicants,
                      className,
                      input: { value: ownerships, onChange },
                      onChange: onSelectionChange,
                    }) => {
  const getName = useMemo(() => id => applicants.find($ => $.id === id)?.name, [applicants]);

  const handleInputChange = useMemo(() => ({ checked }, id) => {
    const otherApplicants = isEmpty(ownerships) ? [] : ownerships.filter(({ applicantId }) => applicantId !== id);
    const filteredOwnerships = checked ? [...otherApplicants, { applicantId: id }] : otherApplicants;
    onSelectionChange();
    return onChange(filteredOwnerships);
  }, [onChange, ownerships, onSelectionChange]);

  return (
    <div className={className}>
      <NewInputLabelWrapper label="Ownership" className={styles.inputLabelWrapper}>
        {applicants.map(({ id }) => (
          <CheckBox
            key={id}
            styles={checkBoxStyles}
            checked={isEmpty(ownerships) ? false
              : !!ownerships.find((ownership) => ownership.applicantId === id)}
            labelName={getName(id)}
            onChange={value => handleInputChange(value, id)}
          />
        ))}
      </NewInputLabelWrapper>
    </div>
  );
};

Ownerships.defaultProps = {
  onChange: () => {},
};

Ownerships.propTypes = {
  className: PropTypes.string.isRequired,
  applicants: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
    applicants: getApplicantsAndGuarantors(state),
  });

export default connect(mapStateToProps)(Ownerships);
