export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const COMMISSION_TYPES = {
 UPFRONT: 'Upfront',
 TRAIL: 'Trail',
};

export const PURPOSE_OPTION_VALUES = {
  ANY: undefined,
  INVESTMENT: 'Investment',
  OWNER_OCCUPIED: 'Owner Occupied',
};

export default {
  LOGGED_IN,
  LOGGED_OUT,
};

export const PRODUCT_TYPES = {
  fixed: 'Fixed',
  credit: 'Line of credit',
  variable: 'Variable',
};

export const REPAYMENT_TYPES = {
  'Principal and Interest Repayment': 'Principal and interest',
  'Interest Only': 'Interest only',
};

export const REPAYMENT_FREQUENCIES_VALUES = {
  PER_WEEK: 'PER_WEEK',
  PER_QUARTER_MONTH: 'PER_QUARTER_MONTH',
  PER_FORTNIGHT: 'PER_FORTNIGHT',
  PER_HALF_MONTH: 'PER_HALF_MONTH',
  PER_MONTH: 'PER_MONTH',
  PER_QUARTER: 'PER_QUARTER',
  PER_SIX_MONTHS: 'PER_SIX_MONTHS',
  PER_ANNUM: 'PER_ANNUM',
};

export const DEFAULT_REPAYMENT_FREQUENCY = 'PER_MONTH';

export const REPAYMENT_FREQUENCIES = {
  [REPAYMENT_FREQUENCIES_VALUES.PER_WEEK]: 'Weekly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_QUARTER_MONTH]: 'Quarter-monthly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_FORTNIGHT]: 'Fortnightly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_HALF_MONTH]: 'Bi-monthly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_MONTH]: 'Monthly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_QUARTER]: 'Quarterly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_SIX_MONTHS]: 'Half-yearly',
  [REPAYMENT_FREQUENCIES_VALUES.PER_ANNUM]: 'Yearly',
};

export const FETCHING_STATUS = {
  INIT: 0,
  START: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export const ABS_LENDING_CODE = {
  'ABS-121': 'Construction of dwellings - House by first mortgage',
  'ABS-123': 'Construction of dwellings - Other dwelling first mortgage',
  'ABS-125': 'Purchase of newly erected dwellings - House by first mortgage',
  'ABS-127': 'Purchase of newly erected dwellings - Other dwelling by first mortgage',
  'ABS-129': 'Purchase of established dwelling - House by first mortgage',
  'ABS-131': 'Purchase of established dwelling - Other dwelling - secured by first mortgage',
  'ABS-133': 'Re-finance of existing home loans - Refinancing housing loans of other financial institutions',
  'ABS-221': 'Motor cars and station wagons - new',
  'ABS-223': 'Motor cars and station wagons - used',
  'ABS-225': 'Other motor vehicles',
  'ABS-227': 'Motor cycles, scooters',
  'ABS-229': 'Boats, caravans and trailers',
  'ABS-231': 'Purchases of individual residential blocks of land',
  'ABS-237': 'Purchase of household and personal goods',
  'ABS-239': 'Debt consolidation (excluding refinance for existing loans)',
  'ABS-241': 'Travel and holidays',
  'ABS-247': 'Other personal investment (including shares and purchase of land for investment)',
  'ABS-249': 'Other (including shares and purchase of land for investment)',
  'ABS-320': 'Construction Finance - Erection of dwellings for rental/resale (not owner occupied)',
  'ABS-327': 'Construction Finance - Alteration and/or additions (excludes maintenance and repairs and other improvements that don\'t include building work) (Construction of Real Estate)',
  'ABS-348': 'Refinancing - Personal Finance',
  'ABS-349': 'Other - all purpose (eg. maintenance and repairs and other improvements that don\'t involve building work)',
};

export const PHONE_TYPE = {
  MOBILE: { value: 'Mobile', name: 'mobile' },
  HOME: { value: 'Home', name: 'home' },
  WORK: { value: 'Work', name: 'work' },
};

export const LOAN_TYPES = {
  Home: 'Home',
  Commercial: 'Commercial',
  Consumer: 'Consumer',
};
