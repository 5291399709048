import React from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import styles from '../PurchasingAsset.module.scss';

const ConstructionFieldsForPurchasingAsset = (fields) => {
  const {
    contractPriceFieldsName: { transferOfLand, contractPrice },
  } = fields;
  return (
    <>
      <ReduxFormNumberInput
        {...get(fields, transferOfLand)}
        label="Purchase price"
        name={fields.fieldName}
        placeholder="$"
        decimalScale={2}
        prefix="$"
        fullWidth
        allowNegative={false}
        className={classNames(styles.landPurchasePriceField, styles.field)}
        textClassName={styles.textClassName}
      />
      <ReduxFormNumberInput
        {...get(fields, contractPrice)}
        label="Construction amount"
        name={fields.fieldName}
        placeholder="$"
        decimalScale={2}
        prefix="$"
        fullWidth
        allowNegative={false}
        className={classNames(styles.contractPriceField, styles.field)}
        textClassName={styles.textClassName}
      />
    </>
);
};

ConstructionFieldsForPurchasingAsset.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  contractPriceFieldsName: PropTypes.shape({
    transferOfLand: PropTypes.string.isRequired,
    contractPrice: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConstructionFieldsForPurchasingAsset;
