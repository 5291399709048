import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hasPermissionTo from './hasPermissionTo';
import NoAccess from '../NoAccess/NoAccess';

const userCan = permission => (WrappedComponent, NoAccessComponent = NoAccess) => {
  const HOC = (props) => {
    const { permissions, business } = props;
    if (hasPermissionTo(permission, permissions, business ? business.id : '*')) {
      return <WrappedComponent {...props} />;
    }
    return <NoAccessComponent />;
  };

  HOC.defaultProps = {
    business: null,
  };

  HOC.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    business: PropTypes.shape(),
  };

  const mapStateToProps = state => ({
    permissions: state.profile.permissions,
    business: state.business.selectedBusiness,
  });

  return connect(mapStateToProps)(HOC);
};

export default userCan;
