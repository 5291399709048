import debounce from 'lodash/debounce';

/*
NOTE: This function produces some promises that are never resolved or rejected. It is up to the caller of the debounced
function to discard these when appropriate (eg. when the debounced function is called again) otherwise a memory leak
will result.
 */
export default (asyncFn, wait, options) => {
  const debouncee = (resolve, reject, ...args) => asyncFn(...args).then(resolve).catch(reject);
  const debounced = debounce(debouncee, wait, options);

  return (...args) => new Promise((resolve, reject) => debounced(resolve, reject, ...args));
};
