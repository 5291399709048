import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { formatCurrency } from '../../../../utils/formatters';
import ClickableValue from './ClickableValue';

const WithOverride = ({
 value, overrideValue, onClick, disabledClick,
}) => (
  <div className={styles.overrideValueWrapper}>
    <div className={classNames(styles.value, styles.originalValue)}>{formatCurrency(value, { precision: 2 })}</div>
    <ClickableValue onClick={onClick} value={overrideValue} disabled={disabledClick} />
  </div>
);

WithOverride.defaultProps = {
  value: undefined,
  overrideValue: undefined,
};

WithOverride.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
  overrideValue: PropTypes.number,
  disabledClick: PropTypes.bool.isRequired,
};

export default WithOverride;
