import {
  getAllFeaturesFromApi,
} from 'shared/api';

export const ACTIONS = {
  GET_ALL_FEATURES_LOADING: 'GET_ALL_FEATURES_LOADING',
  GET_ALL_FEATURES_SUCCESS: 'GET_ALL_FEATURES_SUCCESS',
  GET_ALL_FEATURES_ERROR: 'GET_ALL_FEATURES_ERROR',
};

export const getFeatures = () => (dispatch) => {
  dispatch({ type: ACTIONS.GET_ALL_FEATURES_LOADING });

  return getAllFeaturesFromApi()
    .then(features =>
      dispatch({ type: ACTIONS.GET_ALL_FEATURES_SUCCESS, features }))
    .catch(err =>
      dispatch({ type: ACTIONS.GET_ALL_FEATURES_ERROR, error: err }));
};

const initialState = {
  features: [],
  hasError: false,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ALL_FEATURES_LOADING: {
      return {
        ...state,
        features: [],
        hasError: false,
        isLoading: true,
      };
    }
    case ACTIONS.GET_ALL_FEATURES_SUCCESS: {
      return {
        ...state,
        features: action.features,
        hasError: false,
        isLoading: false,
      };
    }
    case ACTIONS.GET_ALL_FEATURES_ERROR: {
      return {
        ...state,
        features: [],
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
