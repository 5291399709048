import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSelection,
  SortingState,
  SelectionState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableFixedColumns,
  TableHeaderRow,
  VirtualTable,
  Table,
  TableFilterRow,
  TableSelection,
  TableBandHeader,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-bootstrap4';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import noop from 'lodash/noop';
import EmptyResult from 'shared/components/EmptyResult';
import RowIndexer from './plugins/RowIndexer';
import tableCell from './components/TableCell';
import { VirtualTableState } from './components/VirtualTableState';
import tableRow from './components/tableRow';
import TableHeaderCell from './components/TableHeaderCell';
import SortLabel from './components/TableHeaderCell/SortLabel';
import styles from './styles.module.scss';
import FilterMessagesShape from './shapes/FilterMessagesShape';
import TableSelectionCell from './components/TableSelectionCell';
import FilterCell from './components/FilterCell';
import SelectionMeta from './plugins/SelectionMeta';
import TableFixedColumnsMeta from './plugins/TableFixedColumnsMeta';
import {
  calculateFixedColumns,
  calculateColumnExtensions,
  calculateIntegratedSortingColumns,
  calculateFilteringStateColumnExtensions, calculateCustomColumnFilters,
} from './utils';
import ClassNameInjecter from './plugins/ClassNameInjecter';
import ClassNamesShape from './shapes/ClassNamesShape';
import BandCell from './components/BandCell';
import RemoteFilterShape from './shapes/RemoteFilterShape';
import TableSelectAllCell from './components/TableSelectAllCell';

const calculateColumns = (columns, withSelection) => {
  const visibleColumns = columns.filter(col => !col.hidden);

  const {
    bandedColumns = [],
    normalColumns = [],
  } = groupBy(visibleColumns, col => ('children' in col ? 'bandedColumns' : 'normalColumns'));

  return {
    bandedColumns,
    normalColumns,
    withBandedColumn: bandedColumns.length > 0,
    withSorting: normalColumns.some(col => (col.allowSorting)),
    sortingColumnExtensions: normalColumns.map(({ allowSorting = false, name }) => ({
      columnName: name,
      sortingEnabled: allowSorting,
    })),
    withFilters: normalColumns.some(col => (col.allowFiltering)),
    withRemoteFilters: normalColumns.some(col => (col.allowRemoteFiltering)),
    filteringStateColumnExtensions: calculateFilteringStateColumnExtensions(normalColumns),
    leftFixedColumns: calculateFixedColumns(normalColumns, withSelection),
    getCellValue: (row, columnName) => {
      const column = normalColumns.find(c => c.name === columnName);
      return column.getCellValue
        ? column.getCellValue(row)
        : get(row, columnName);
    },
    columnExtensions: calculateColumnExtensions(normalColumns),
  };
};

const calculateComponents = (virtual, isLoading, rowClassName) => {
  const TableComponent = virtual ? VirtualTable : Table;
  return {
    TableComponent,
    TableCellComponent: tableCell(TableComponent),
    TableRowComponent: tableRow(TableComponent, rowClassName),
    NoDataCellComponent: () => (
      isLoading ? (
        <td>
          <LoadingSpinner />
        </td>
      ) : (
        <td className={styles.noDataContainer}>
          <EmptyResult className={styles.noData} />
        </td>
      )
    ),
  };
};

const MobiusTable = ({
                       rows,
                       columns,
                       virtual,
                       isLoading,
                       defaultFilters,
                       customColumnFilters,
                       filterMessages,
                       defaultSorting,
                       withSelection,
                       selection,
                       getRowId,
                       onSelectionChange,
                       showSelectAll,
                       className,
                       maxSelectionCount,
                       classNames,
                       style,
                       skip,
                       getRemoteRows,
                       onRemoteFiltersChange,
                       remoteFilters,
                       totalRowCount,
                       pageSize,
                       withRemote,
                       estimatedRowHeight,
                       infiniteScrolling,
                       disableSelection,
                       resizingMode,
                       resizingEnabled,
                     }) => {
  const {
    bandedColumns = [],
    normalColumns = [],
    withBandedColumn,
    withSorting,
    withFilters,
    withRemoteFilters,
    filteringStateColumnExtensions,
    sortingColumnExtensions,
    leftFixedColumns,
    getCellValue,
    columnExtensions,
  } = useMemo(
    () => calculateColumns(columns, withSelection),
    [columns, withSelection],
  );

  const [sortingState, setSortingState] = useState(defaultSorting);

  const integratedSortingColumns = useMemo(
    () => calculateIntegratedSortingColumns(sortingState, normalColumns), [sortingState, normalColumns],
  );

  // selection configuration
  const [selectionState, changeSelection] = useState(selection);

  useEffect(() => {
    if (selection !== undefined && selection !== selectionState) {
      changeSelection(selection);
    }
  }, [selection, selectionState]);

  const rowClassName = classNames && classNames.row;

  const {
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    NoDataCellComponent,
  } = useMemo(() => calculateComponents(virtual, isLoading, rowClassName), [virtual, isLoading, rowClassName]);

  const [selectAllRows, setSelectAllRows] = useState(false);

  const [rowsIds, setRowsIds] = useState(rows.map(row => row.id));

  const setSelection = useMemo(() => (args) => {
    if (isFunction(onSelectionChange)) {
      onSelectionChange(args);
    }
    changeSelection(args);
  }, [onSelectionChange]);

  useEffect(() => {
    if (rows !== undefined) {
      setRowsIds(existingRowsIds => uniq([...existingRowsIds, ...rows.map(row => row.id)]));
    }
  }, [rows]);

  useEffect(() => {
    if (showSelectAll) {
      if (selectAllRows) {
        setSelection(rowsIds);
      } else {
        setSelection([]);
      }
    }
  }, [selectAllRows, rowsIds, setSelection, showSelectAll]);

  const toggleSelectAllRows = () => {
    setSelectAllRows(!selectAllRows);
  };

  const defaultColumnWidths = useMemo(() => (columns || []).map(it => ({
    columnName: it.name,
    width: it.width || 100,
  })), [columns]);

  const columnsResizingExtensions = useMemo(() => columns.map(it => ({
    columnName: it.name,
    maxWidth: it.maxWait,
    minWidth: it.minWidth,
  })), [columns]);

  return (
    <div
      key={withSelection}
      className={`mobiusTable ${className || ''}`}
      style={style}
    >
      <Grid
        rows={rows}
        columns={normalColumns}
        getCellValue={getCellValue}
        getRowId={getRowId}
      >
        {withRemote && (
          <VirtualTableState
            infiniteScrolling={infiniteScrolling}
            loading={isLoading}
            totalRowCount={totalRowCount}
            pageSize={pageSize}
            skip={skip}
            getRows={getRemoteRows}
          />
        )}

        {withSelection && (
          <SelectionState
            selection={selectionState}
            onSelectionChange={(...args) => {
              changeSelection(...args);
              if (isFunction(onSelectionChange)) {
                onSelectionChange(...args);
              }
            }}
          />
        )}

        {withFilters && (
          <FilteringState
            defaultFilters={defaultFilters}
            columnExtensions={filteringStateColumnExtensions}
          />
        )}

        {withFilters && (
          <IntegratedFiltering columnExtensions={[...customColumnFilters, ...calculateCustomColumnFilters(columns)]} />
        )}

        {withRemoteFilters && (
          <FilteringState
            filters={remoteFilters}
            onFiltersChange={onRemoteFiltersChange}
          />
        )}

        {withSorting && (
          <SortingState
            sorting={sortingState}
            columnExtensions={sortingColumnExtensions}
            onSortingChange={setSortingState}
          />
        )}

        {withSorting && <IntegratedSorting columnExtensions={integratedSortingColumns} />}

        <TableComponent
          estimatedRowHeight={estimatedRowHeight}
          columnExtensions={columnExtensions}
          cellComponent={TableCellComponent}
          rowComponent={TableRowComponent}
          noDataCellComponent={NoDataCellComponent}
        />

        <RowIndexer />

        <ClassNameInjecter classNames={classNames} />
        {withSelection && (
          <SelectionMeta
            maxSelectionCount={maxSelectionCount}
            selection={selectionState}
            disableSelection={disableSelection}
            selectAllRows={selectAllRows}
            toggleSelectAllRows={toggleSelectAllRows}
          />
        )}
        {resizingEnabled && (
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            columnExtensions={columnsResizingExtensions}
            resizingMode={resizingMode}
          />
        )}
        <TableHeaderRow
          cellComponent={TableHeaderCell}
          showSortingControls={withSorting}
          sortLabelComponent={SortLabel}
        />

        {(withFilters || withRemoteFilters) && (
          <TableFilterRow
            cellComponent={FilterCell}
            messages={filterMessages}
          />
        )}

        {withBandedColumn && (
          <TableBandHeader
            columnBands={bandedColumns}
            cellComponent={BandCell}
          />
        )}

        {withSelection && <IntegratedSelection />}

        {withSelection && (
          <TableSelection
            cellComponent={TableSelectionCell}
            showSelectAll={showSelectAll && !isNumber(maxSelectionCount)}
            headerCellComponent={TableSelectAllCell}
          />
        )}
        <TableFixedColumnsMeta leftFixedColumns={leftFixedColumns} />
        <TableFixedColumns leftColumns={leftFixedColumns} />
      </Grid>
    </div>
  );
};

export const alignment = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

MobiusTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /** Name that row objects will reference */
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      width: PropTypes.number,
      /** if this is a fixed column */
      sticky: PropTypes.bool,
      /** Text alignment, 'left' or 'right' */
      align: PropTypes.oneOf(Object.values(alignment)),
      /** Word wrap instead of ellipsis */
      wordWrapEnabled: PropTypes.bool,
      /** Function to render the cell value */
      getCellValue: PropTypes.func,
      /** Function to run on each cell of column */
      renderer: PropTypes.func,
      /** if column allow sorting, default false */
      allowSorting: PropTypes.bool,
      /** custom sorter of column */
      sorter: PropTypes.oneOfType([
        PropTypes.shape({ asc: PropTypes.func, desc: PropTypes.func }),
        PropTypes.func,
      ]),
      /** if column allow filter, default false */
      allowFiltering: PropTypes.bool,
      /** if column filter control is disabled, default false */
      allowRemoteFiltering: PropTypes.bool,
      /** if column remote filter control is disabled, default false */
      filterWithRenderer: PropTypes.bool,
      /** if filter should be done on value after been altered by "renderer", default false */
      filterDisabled: PropTypes.bool,
      /** class name to pass to every cell of a column(except for header) */
      className: PropTypes.string,
      /** class name to pass to the header cell of a column */
      headerClassName: PropTypes.string,
      /** a function to customize column header */
      headerRenderer: PropTypes.func,
      classNames: ClassNamesShape,
      /** required for banded columns */
      children: PropTypes.array,
      /** whether this column should be hidden */
      hidden: PropTypes.bool,
      maxWidth: PropTypes.number,
      minWidth: PropTypes.number,
      disallowResizing: PropTypes.bool,
    }),
  ).isRequired,
  /** Show loading spinner */
  isLoading: PropTypes.bool,
  /** Use virtualisation (only use for large amounts of data) */
  virtual: PropTypes.bool,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  customColumnFilters: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      predicate: PropTypes.func.isRequired,
    }),
  ),
  resizingMode: PropTypes.oneOf(['widget', 'nextColumn']),
  filterMessages: FilterMessagesShape,
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
  ),
  withSelection: PropTypes.bool,
  selection: PropTypes.array,
  onSelectionChange: PropTypes.func,
  getRowId: PropTypes.func,
  showSelectAll: PropTypes.bool,
  resizingEnabled: PropTypes.bool,
  className: PropTypes.string,
  maxSelectionCount: PropTypes.number,
  classNames: ClassNamesShape,
  style: PropTypes.object,
  skip: PropTypes.number,
  getRemoteRows: PropTypes.func,
  onRemoteFiltersChange: PropTypes.func,
  remoteFilters: PropTypes.arrayOf(RemoteFilterShape),
  totalRowCount: PropTypes.number,
  pageSize: PropTypes.number,
  withRemote: PropTypes.bool,
  estimatedRowHeight: PropTypes.number,
  infiniteScrolling: PropTypes.bool,
  disableSelection: PropTypes.bool,
};

MobiusTable.defaultProps = {
  isLoading: false,
  virtual: false,
  defaultFilters: [],
  customColumnFilters: [],
  filterMessages: {},
  defaultSorting: [],
  withSelection: false,
  selection: undefined,
  onSelectionChange: undefined,
  getRowId: row => row.id,
  showSelectAll: false,
  className: undefined,
  maxSelectionCount: undefined,
  classNames: undefined,
  style: undefined,
  skip: undefined,
  getRemoteRows: noop,
  onRemoteFiltersChange: noop,
  remoteFilters: [],
  totalRowCount: undefined,
  pageSize: undefined,
  withRemote: false,
  estimatedRowHeight: 49,
  infiniteScrolling: false,
  resizingEnabled: false,
  disableSelection: false,
  resizingMode: 'widget',
};

export default React.memo(MobiusTable);
