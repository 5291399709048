import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { arraySplice, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import Icon from 'shared/components/Icon/Icon';
import styles from './Applicants.module.scss';
import ApplicantOrGuarantorComponent from './ApplicantOrGuarantorComponent';
import { FORM_NAME } from './constants';

const selector = formValueSelector(FORM_NAME);

const getAddButton = (label, disabled, onClick) => {
  const themeContext = useContext(ThemeContext);
  return (
    <SecondaryButton
      className={classNames(styles.addButton)}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{label}</span>
      <Icon name="add" color={themeContext.palette.textPrimary} size="18" />
    </SecondaryButton>
  );
};

const ApplicantsOrGuarantorsSection = ({
  fields,
  hasEditPermission,
  addPersonDisabled,
  addCompanyDisabled,
  isApplicant,
}) => {
  const dispatch = useDispatch();
  const getValue = useSelector((state) => (fieldName) =>
    selector(state, fieldName));
  const personAction = useMemo(() => {
    const replace = (index, applicant) => fields.splice(index, 1, applicant);

    const removeRelated = (personId, fieldName) => {
      const changedList = (list) => {
        const filteredList = list.filter(
          (it) => it.relatedApplicantId !== personId,
        );
        if (list.length === filteredList.length) {
          return null;
        }
        return isEmpty(filteredList) ? [{}] : filteredList;
      };
      const collection = getValue(fieldName);
      collection.forEach((item, itemIndex) => {
        const updated = {
          maritalStatus:
            personId === item?.maritalStatus?.spouse?.relatedApplicantId
              ? {
                  ...item.maritalStatus,
                  spouse: null,
                }
              : null,
          trustees: item.trustees ? changedList(item.trustees) : null,
          directors: item.directors ? changedList(item.directors) : null,
          beneficiaries: item.beneficiaries
            ? changedList(item.beneficiaries)
            : null,
        };
        const omittedUpdate = omitBy(updated, isNil);
        if (!isEmpty(omittedUpdate)) {
          dispatch(
            arraySplice(FORM_NAME, fieldName, itemIndex, 1, {
              ...item,
              ...omittedUpdate,
            }),
          );
        }
      });
    };

    const updateOnRemoval = (person) => {
      if (person.id) {
        removeRelated(person.id, 'applicants');
        removeRelated(person.id, 'guarantors');
      }
    };

    const updateAfterRemoval = (person) => {
      if (isApplicant && person.isPrimary) {
        replace(0, { ...fields.get(0), isPrimary: true });
      }
    };

    return {
      remove: (i) => {
        const person = fields.get(i);
        updateOnRemoval(person);
        fields.remove(i);
        updateAfterRemoval(person);
      },
      replace: (i, newApplicant) => {
        const person = fields.get(i);
        updateOnRemoval(person);
        replace(i, {
          ...newApplicant,
          isPerson: true,
        });
        updateAfterRemoval(person);
      },
      isEmpty: (i) => {
        const person = fields.get(i);
        return isEmpty(person.id);
      },
    };
  }, [fields, isApplicant, dispatch, getValue]);

  const companyAction = useMemo(() => {
    const replace = (index, applicant) => fields.splice(index, 1, applicant);
    const removeRelated = (companyId, fieldName) => {
      const changedList = (list) => {
        const filteredList = list.filter(
          (it) => it.relatedApplicantId !== companyId,
        );
        if (list.length === filteredList.length) {
          return null;
        }
        return isEmpty(filteredList) ? [{}] : filteredList;
      };
      const collection = getValue(fieldName);
      collection.forEach((item, itemIndex) => {
        const updated = {
          trustees: item.trustees ? changedList(item.trustees) : null,
          directors: item.directors ? changedList(item.directors) : null,
          beneficiaries: item.beneficiaries
            ? changedList(item.beneficiaries)
            : null,
        };
        const omittedUpdate = omitBy(updated, isNil);
        if (!isEmpty(omittedUpdate)) {
          dispatch(
            arraySplice(FORM_NAME, fieldName, itemIndex, 1, {
              ...item,
              ...omittedUpdate,
            }),
          );
        }
      });
    };

    const updateOnRemoval = (company) => {
      if (company.id) {
        removeRelated(company.id, 'applicants');
        removeRelated(company.id, 'guarantors');
      }
    };

    const updateAfterRemoval = (company) => {
      if (isApplicant && company.isPrimary) {
        replace(0, { ...fields.get(0), isPrimary: true });
      }
    };
    return {
      remove: (i) => {
        const company = fields.get(i);
        updateOnRemoval(company);
        fields.remove(i);
        updateAfterRemoval(company);
      },
      replace: (i, newApplicant) => {
        const company = fields.get(i);
        updateOnRemoval(company);
        replace(i, {
          ...newApplicant,
          isPerson: false,
          directors: [{}],
          trustees: [{}],
          beneficiaries: [{}],
        });
        updateAfterRemoval(company);
      },
      isEmpty: (i) => {
        const company = fields.get(i);
        return isEmpty(company.id);
      },
    };
  }, [fields, isApplicant, dispatch, getValue]);

  return (
    <>
      {fields.map((fieldNamePrefix) => (
        <ApplicantOrGuarantorComponent
          fieldNamePrefix={fieldNamePrefix}
          key={fieldNamePrefix}
          displayRemoveButton={
          hasEditPermission
            && (!isApplicant || fields.length > 1)
          }
          actions={{ personAction, companyAction }}
          hasEditPermission={hasEditPermission}
          isApplicant={isApplicant}
        />
      ))}
      <div className={styles.buttonWrapper}>
        { hasEditPermission
          && getAddButton('Add a person', addPersonDisabled, () =>
            fields.push({ isPerson: true }))}
        { hasEditPermission
          && getAddButton('Add a company/trust', addCompanyDisabled, () =>
            fields.push({
              isPerson: false,
              directors: [{}],
              trustees: [{}],
              beneficiaries: [{}],
            }))}
      </div>
    </>
  );
};

ApplicantsOrGuarantorsSection.propTypes = {
  fields: PropTypes.object.isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  addPersonDisabled: PropTypes.bool.isRequired,
  addCompanyDisabled: PropTypes.bool.isRequired,
  isApplicant: PropTypes.bool.isRequired,
};

export default ApplicantsOrGuarantorsSection;
