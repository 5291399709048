import keys from 'lodash/keys';
import isFunction from 'lodash/isFunction';

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (keys(handlers).includes(action.type) && isFunction(handlers[action.type])) {
    return handlers[action.type](state, action);
  }
  return state;
};

export const combineActions = (actions = [], handler) => (
  actions.reduce((result, action) => ({ ...result, [action]: handler }), {})
);
