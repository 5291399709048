import {
 ApolloClient, InMemoryCache, ApolloLink, createHttpLink, gql,
} from '@apollo/client';

import uniq from 'lodash/uniq';
import { onError } from '@apollo/client/link/error';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { getMessage, showToast } from '../../redux/toast/actions';

import convertObjectsToGraphQLQuery, { convertObjectToGraphQLQuery } from './convertObjectsToGraphQLQuery';

import { BACKEND_BASE_URL } from '../../config/environment';
import { noLongerLoggedIn } from '../../redux/login';
import { MESSAGE_KEYS } from '../../constants/message';

let dispatch;
export const setDispatchFunction = (newDispatch) => {
  dispatch = newDispatch;
};

const isTokenExpired = error => error.statusCode === 401;

const httpLink = createHttpLink({ uri: `${BACKEND_BASE_URL}/graphql`, credentials: 'include' });

/* eslint-disable no-console */
const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ));
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    if (isTokenExpired(networkError)) {
      dispatch(noLongerLoggedIn);
    }
  }
});
/* eslint-enable no-console */

/* eslint-disable prefer-promise-reject-errors */
const processResult = (promise, messageKey, context, options) => promise.then((...args) => {
    const successMessage = getMessage(messageKey, 200, context);
    if (!isNil(successMessage)) {
      dispatch(showToast(successMessage, { type: 'success', ...options }));
    }
    return Promise.resolve(...args);
  }).catch((error) => {
    const { graphQLErrors = [], networkError } = error;
    uniq(graphQLErrors
      .map(({ errorCode, message }) => getMessage(messageKey, errorCode, { ...context, message }))
      .filter(message => (!isNil(message))))
      .forEach((message) => {
        dispatch(showToast(message, options));
      });
    if (networkError && !isTokenExpired(networkError)) {
      dispatch(showToast(
        getMessage(MESSAGE_KEYS.NETWORK_ERROR, 'message'),
        { toastId: 'network_error', ...options },
      ));
    }
    return Promise.reject(error);
  });

class GraphQLClient extends ApolloClient {
  query(queryOptions, messageConfig = {}) {
    const enabledMessageConfig = get(queryOptions, 'context.messageConfig', messageConfig);
    const { messageKey = '', context, options } = enabledMessageConfig;
    const promise = super.query(queryOptions);
    return processResult(promise, messageKey, context, options);
  }

  mutate(queryOptions, messageConfig = {}) {
    const enabledMessageConfig = get(queryOptions, 'context.messageConfig', messageConfig);
    const { messageKey = '', context, options } = enabledMessageConfig;
    const promise = super.mutate(queryOptions);
    return processResult(promise, messageKey, context, options);
  }
}
/* eslint-enable prefer-promise-reject-errors */

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

const clientConfig = {
  link: ApolloLink.from([
    onErrorLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: object => JSON.stringify(object),
    addTypename: false,
  }),
  defaultOptions,
};

export const client = new GraphQLClient(clientConfig);

export const resolveErrorClient = new GraphQLClient({
  ...clientConfig,
  defaultOptions: {
    ...defaultOptions,
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

const getAllProducts = searchParams => client.query({
  query: gql`
      {
        allVariantCombinations(
          criteria: ${convertObjectsToGraphQLQuery(searchParams)}
        ) {
          variants {
            applicationFee
            comparisonRate
            discountMonths
            discountRate
            baseRate
            displayName
            externalLink
            firstPeriodInterestOnly
            firstPeriodPrincipalAndInterest
            firstPeriodRepayment
            id
            legalFee
            lenderName
            maxLoanAmount
            maxLoanValueRatio
            maxTerm
            minLoanAmount
            minLoanValueRatio
            minTerm
            nextgenAbsCode
            nextgenFeatureCodes
            nextgenMaliId
            nextgenPaymentType
            nextgenPropertyStatus
            ongoingFee
            ongoingFeeFrequency
            productName
            repaymentAvailable
            repaymentTypes
            standardRate
            totalCost
            type
            valuationFee
          }
        }
      }
    `,
});

const getDetailsForProducts = ids => client.query({
  query: gql`
    {
      variants(
        ids: ${JSON.stringify(ids)}
      ) {
          id
          source
          featureDetails {
            featureName
            comments
          }
      }
    }
  `,
});

const getAllFeatures = () =>
  client.query({
    query: gql`
      {
        allFeatures {
          name
          groupName
        }
      }
    `,
  });

const getAllLenders = () =>
  client.query({
    query: gql`
      {
        allLenders {
          name
        }
      }
    `,
  });

const getVariantModification = (variant, context) =>
  client.query({
    query: gql`
      {
        variantModification(
          modification: {
            variant: ${convertObjectToGraphQLQuery(variant)},
            context: ${convertObjectToGraphQLQuery(context)}
          }
        ) {
          id
          standardRate
          ongoingFee
          ongoingFeeFrequency
          firstPeriodRepayment
          firstPeriodInterestOnly
          firstPeriodPrincipalAndInterest
          totalCost
          applicationFee
          legalFee
          valuationFee
          comparisonRate
          repaymentAvailable
        }
      }
    `,
  });

const getLendersConfig = () =>
  client.query({
    query: gql`
      {
        lendersConfig{
          lenderConfigList{
            lenderId
            lenderName
            loanIdentifierCalledAs
            regex
            minLength
            maxLength
            loanIdentifierAllowedChars
          }
          defaultLenderValidationConfig{
            regex
            minLength
            maxLength
          }
          lendersColorConfig{
            lenderId
            lenderName
            hexColor
          }
        }
      }
    `,
  });

const getUserContactsByBusinessId = businessId =>
  client.query({
    query: gql`
      {
        businessUsers(businessId: "${businessId}")
        {
          email
          username
          mobilePhone
          officePhone
        }
      }
    `,
  });

const getAllCategoryLenders = () =>
client.query({
  query: gql`
    {
      allCategoryLenders {
        id
        name
        category,
        serviceabilityLenderCode
      }
    }
  `,
});

const getServiceability = (request) => client.query({
    query: gql`
        {
            serviceability(
                applicationId: "${request.applicationId}",
                loanCriteriaList: ${convertObjectsToGraphQLQuery(request.loansCriteria)},
                productPackages: ${convertObjectsToGraphQLQuery(request.productPackages)}
            )
            {
                assessments {
                  maximumLoanAmount
                  nextgenLenderCode
                  lvr
                  baseLvr
                  totalNetIncome
                  livingExpenses
                  totalCommitments
                  surplusFunds
                  totalSecurityValue
                  netMonthlySurplus
                  debtToIncomeRatio
                  products {
                    assessmentRate
                    assessmentRepayment
                    productName
                  }
                }
                details {
                  metricLists {
                    lender
                    metrics {
                      id
                      pid
                      code
                      displayName
                      value
                      displayValue
                      identifier
                      type
                      description
                      dependentMetrics {
                        code
                        identifier
                      }
                      additionalValues {
                        type
                        value
                        relatedTo
                        valueItems {
                          type
                          value
                          relatedTo
                        }
                      }
                      detailTable
                      validationResults {
                        validationID
                        severity
                        description
                        suggestedAction
                        helpText
                        controlID
                      }
                    }
                  }
                }
            }
        }
    `,
  });

const getPropertyReportUrl = (info) => client.query({
    query: gql`
        {
            propertyReportUrl(
                userId:"${info.userId}",
                selectedAdviserId:"${info.selectedAdviserId}",
                address:"${info.address}",
                state:"${info.state}",
            )
            {
                 url
                 warningType
            }
        }
  `,
});

export default {
  getAllProducts,
  getAllFeatures,
  getAllLenders,
  getDetailsForProducts,
  getVariantModification,
  getLendersConfig,
  getAllCategoryLenders,
  getUserContactsByBusinessId,
  getServiceability,
  getPropertyReportUrl,
};
