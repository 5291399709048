import React from 'react';
import { Report } from '../Report';
import Tooltip from './Tooltip';
import { perspectivesForExpectedSettlement } from '../viewPerspectives';

export const ExpectedSettlementReport = props => (
  <Report
    title="Expected Settlements"
    toolTipComponent={Tooltip}
    perspectives={perspectivesForExpectedSettlement}
    {...props}
  />
);
