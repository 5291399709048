import React from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import CommissionQueryComponent from '../../CommissionQuery/CommissionQuery';
import {
  approvalNumberLinkToLoanDetail,
  getDateOrNoData,
  getDollarAmountOrNoData,
  loanTypeRenderer,
  minHeightRenderer,
  moneyValueRenderer,
} from '../../renderers';
import styles from '../styles.module.scss';
import '../_ResizingMobiusTable.scss';

export const CommissionRowShape = PropTypes.shape({});

export const COMMISSION_AMOUNT_TYPE = {
  TRAIL: 'trailAmounts',
  BALANCE: 'balances',
  UPFRONT: 'upfrontAmounts',
  UPFRONT_LOAN: 'upfrontLoanAmounts',
};

const months = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

export const calculateTotalAmounts = (commissions, monthIndex, amountType) =>
  sum(compact(commissions.map(
    row => toNumber(get(row, amountType, [])[monthIndex]),
  )));

export const amountHeaderRenderGenerator = (monthIndex, { commissionKey, balanceKey }) =>
  (children, column, rowItems) => {
  const totalCommissionAmounts = calculateTotalAmounts(rowItems, monthIndex, commissionKey);
  const totalBalanceAmounts = calculateTotalAmounts(rowItems, monthIndex, balanceKey);
  return (
    <div className={styles.amountHeaderCell}>
      {children}
      <div className={styles.totalAmounts}>
        <div>{moneyValueRenderer(totalCommissionAmounts)}</div>
        <div>{moneyValueRenderer(totalBalanceAmounts)}</div>
      </div>
    </div>

  );
};

export const amountCellRenderGenerator = (monthIndex, { commissionKey, balanceKey }) => (value, row) => (
    isNil(get(row, [commissionKey, monthIndex])) ? null : (
      <span>
        {moneyValueRenderer(get(row, [commissionKey, monthIndex]))}
        <br />
        {moneyValueRenderer(get(row, [balanceKey, monthIndex])) || 'No data'}
      </span>
    )
  );

export const getMonthsColumnConfig = (rows, totalMonths, nameField, cellRenderGenerator, headerRendererGenerator) => {
  if (rows.length === 0) {
    return [];
  }

  const financialYears = rows[0].financialYear.split('-');
  return Array(totalMonths)
    .fill()
    .map((ignore, monthIndex) => {
      const year = monthIndex < 6 ? financialYears[0] : financialYears[1];
      const title = (
        <span>
          {months[monthIndex]}
          <br />
          {year}
        </span>
      );

      const headerRenderer = isFunction(headerRendererGenerator)
          ? headerRendererGenerator(monthIndex) : children => children;

      return {
        title,
        align: 'right',
        allowSorting: true,
        name: `${nameField}[${monthIndex}]`,
        renderer: cellRenderGenerator(monthIndex),
        headerRenderer,
      };
    }).reverse();
};

export const getBasicCommissionColumns = () => ([
  {
    name: 'id',
    title: 'Raise a query',
    sticky: true,
    width: 120,
    wordWrapEnabled: true,
    renderer: issueId => <CommissionQueryComponent issueId={issueId} />,
  },
  {
    name: 'client',
    title: 'Client',
    sticky: true,
    wordWrapEnabled: true,
    renderer: client => (client ? minHeightRenderer(client) : 'No data'),
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'lender',
    title: 'Lender',
    sticky: true,
    wordWrapEnabled: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'approvalNumber',
    title: (
      <span>
        Approval
        <br />
        number
      </span>
    ),
    sticky: true,
    renderer: (v, client) => (
      approvalNumberLinkToLoanDetail(client)
    ),
    allowFiltering: true,
    allowSorting: true,
    disallowResizing: true,
    width: 145,
  },
  {
    name: 'loanType',
    title: 'Loan type',
    renderer: loanType => (loanType ? loanTypeRenderer(loanType) : 'No data'),
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'adviser',
    title: 'Adviser',
    wordWrapEnabled: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'settlementDate',
    title: (
      <span>
        Settlement
        <br />
        date
      </span>
    ),
    renderer: getDateOrNoData,
    width: 125,
    filterWithRenderer: true,
    allowFiltering: true,
    allowSorting: true,
  },
  {
    name: 'approvedAmount',
    title: (
      <span>
        Approved
        <br />
        amount
      </span>
    ),
    align: 'right',
    width: 125,
    renderer: getDollarAmountOrNoData,
    allowSorting: true,
  },
]);
