/* eslint-disable no-sparse-arrays */
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { mapLogToProps, toUpperCase } from './utils';
import OCCUPATION_DISPLAY_NAME_MAP from './employmentOccupationConstants';
import { formatDateTime } from '../../../../../../utils';

const EMPLOYMENT_TYPE_DISPLAY_NAME_MAP = {
  PAYE: 'PAYG',
  SelfEmployed: 'Self employed',
  UnEmployed: 'Not working',
  Retired: 'Retired',
};

const mapCompanyLog = (origin, samplePath) => {
  const companyId = samplePath.replace(/^.+companies\[([a-zA-Z0-9,-]+)].+$/, '$1');
  const extraInfoMap = JSON.parse(get(origin, 'eventDetail.extraInfo', {}));
  const { applicantName } = get(extraInfoMap, companyId);
  return mapLogToProps({
    origin,
    type: 'INCOME AND EMPLOYMENT',
    summary: `Company income #action - ${applicantName}`,
    fieldMap: {
      companyIncomeInfo: ['Income'],
    },
    handleChanges: changes => {
      const hasIncomeChange = changes.some(({ path }) => path.includes('companyIncomeInfo'));
      return [
        hasIncomeChange && {
          path: '*.companyIncomeInfo',
          value: 'updated',
        },
      ].filter(Boolean);
    },
  });
};

const mapPersonLog = (origin, samplePath) => {
  const id = samplePath.replace(/^.+employments\[([a-zA-Z0-9,-]+)].+$/, '$1');
  const extraInfoMap = JSON.parse(get(origin, 'eventDetail.extraInfo', {}));
  const { applicantName, status } = get(extraInfoMap, id);

  const prefix = status === 'Previous' ? 'Previous' : 'Current';

  return mapLogToProps({
    origin,
    type: 'INCOME AND EMPLOYMENT',
    summary: `${prefix} employment #action - ${applicantName}`,
    prefix: '.+employments',
    fieldMap: {
      type: ['Employment status', toUpperCase],
      employmentType: ['Employment type', type => EMPLOYMENT_TYPE_DISPLAY_NAME_MAP[type] || type],
      onProbation: ['On probation', $ => ($ === 'true' ? 'Yes' : 'No')],
      employmentIncomes: ['Income'],
      surname: ['Employer surname'],
      firstName: ['Employer first name'],
      employerType: ['Employment sector'],
      roleDescription: ['Job title'],
      role: ['Occupation', role => OCCUPATION_DISPLAY_NAME_MAP[role] || role],
      abnNumber: ['ABN'],
      abnEstablishedDate: ['Date ABN established', date => formatDateTime(date, 'DD/MM/YYYY')],
      onBenefits: ['On benefits', $ => ($ === 'true' ? 'Yes' : 'No')],
      homeDuties: ['Home Duties', $ => ($ === 'true' ? 'Yes' : 'No')],
      student: ['Student', $ => ($ === 'true' ? 'Yes' : 'No')],
      phone: ['Employer phone'],
      countryCode: ['Country code', code => `+${code}`],
      startDate: ['Start date', date => formatDateTime(date, 'DD/MM/YYYY')],
      endDate: ['End date', date => formatDateTime(date, 'DD/MM/YYYY')],
    },
    handleChanges: changes => {
      const others = changes.filter(({ path }) => !/(employmentIncomes|financialYear|countryCode|phone|employerContact\.address)/.test(path));
      const employerPhoneChanges = sortBy(changes.filter(({ path }) => /(employerContact\.countryCode|phone)/.test(path)), 'path');
      const hasEmploymentIncomes = changes.some(({ path }) => path.includes('employmentIncomes'));
      const addressChanges = changes.filter(({ path }) => path.includes('employerContact.address'));
      const hasAddressChanges = addressChanges.length > 0;
      const isAddressRemoved = addressChanges.every(item => isEmpty(item.value));

      return [
        ...others,
        ...employerPhoneChanges,
        hasEmploymentIncomes && { path: '*.employmentIncomes', value: 'updated' },
        hasAddressChanges && { path: '*.address', value: isAddressRemoved ? 'removed' : 'updated' },
      ].filter(Boolean);
    },
  });
};

const employmentsUpdateChangeLogTransformer = origin => {
  const samplePath = get(origin, 'eventDetail.changes[0].path', '');
  if (samplePath.includes('companies')) {
    return mapCompanyLog(origin, samplePath);
  }
  return mapPersonLog(origin, samplePath);
};

export default employmentsUpdateChangeLogTransformer;
