import PropTypes from 'prop-types';
import React from 'react';
import range from 'lodash/range';
import ceil from 'lodash/ceil';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import HeaderSelect from './HeaderSelect';
import { convertDecimalYearToIntegerYearAndMonth } from '../../shared/utils';
import { formatYearAndMonth } from '../../shared/formatterUtils';

export const getTotalCostTermOptions = (maximumTerm, mustHaveLoanTerms) => {
  const baseTerms = range(1, ceil(maximumTerm));
  const combinedTerms = concat(baseTerms, mustHaveLoanTerms, maximumTerm);
  const finalTerms = sortBy(uniq(combinedTerms)).reverse();
  return finalTerms.map(termValue => ({
    value: termValue,
    label: formatYearAndMonth(convertDecimalYearToIntegerYearAndMonth(termValue)),
  }));
};

const TotalCostTermComponent = (
  {
    maximumTerm, totalCostTerm, onChange, menuPortalTarget, currentLoanTerms,
  },
) => {
  const options = getTotalCostTermOptions(maximumTerm, currentLoanTerms);

  return (
    <HeaderSelect
      selectedValue={totalCostTerm}
      classNamePrefix="total-cost-term"
      onChange={onChange}
      menuPortalTarget={menuPortalTarget}
      options={options}
    />
  );
};

TotalCostTermComponent.defaultProps = {
  menuPortalTarget: undefined,
  currentLoanTerms: [],
};

TotalCostTermComponent.propTypes = {
  maximumTerm: PropTypes.number.isRequired,
  totalCostTerm: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  menuPortalTarget: PropTypes.instanceOf(Element),
  currentLoanTerms: PropTypes.arrayOf(PropTypes.string),
};

export default TotalCostTermComponent;
