import withReduxForm from 'shared/components/formFields/ReduxForm/ReduxFormFieldWrapper';
import ProductTypeComponent from 'shared/components/productSearch/ProductSearchFields/ProductTypeComponent';
import RepaymentTypeComponent from 'shared/components/productSearch/ProductSearchFields/RepaymentTypeComponent';
import FixedLoanTermField from 'shared/components/productSearch/ProductSearchFields/FixedLoanTermComponent';
import InterestOnlyTermField from 'shared/components/productSearch/ProductSearchFields/InterestOnlyTermComponent';
import LendersSelectComponent from 'shared/components/productSearch/ProductSearchFields/LendersSelectComponent';
import FeatureComponent from 'shared/components/productSearch/ProductSearchFields/Feature/FeatureComponent';
import TotalLoanAmountFields from './TotalLoanAmountFields';
import PurposeCategoryField from './PurposeCategoryField';
import LoanAmountFields from './LoanAmountFields';
import PurposeField from './PurposeField';
import LoanTermField from './LoanTermField';

const ProductTypeField = withReduxForm(({ onBlur }) => ({ onBlur: () => onBlur() }))(ProductTypeComponent);
const RepaymentTypeField = withReduxForm(({ onBlur }) => ({ onBlur: () => onBlur() }))(RepaymentTypeComponent);
const LendersSelectField = withReduxForm(({ onBlur }) => ({ onBlur: () => onBlur() }))(LendersSelectComponent);
const FeatureField = withReduxForm(({ onBlur }) => ({ onBlur: () => onBlur() }))(FeatureComponent);

export {
  LoanAmountFields,
  PurposeField,
  ProductTypeField,
  RepaymentTypeField,
  LoanTermField,
  FixedLoanTermField,
  InterestOnlyTermField,
  LendersSelectField,
  FeatureField,
  TotalLoanAmountFields,
  PurposeCategoryField,
};
