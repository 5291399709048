import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'shared/components/formFields/NumberInput/NumberInput';
import style from './style';
import inputComponentStyles from './InputComponent.module.scss';

const MAX_NUM = 1000000000;
const isAllowed = value => (value === undefined || (value > 0 && value < MAX_NUM));

const SecurityValueComponent = (props) => {
  const {
    dispatchUpdateSecurityValue, dispatchOnSecurityValueBlur, securityValue, errorMessage,
  } = props;
  return (
    <NumberInput
      label="Total security value ($)"
      onChange={dispatchUpdateSecurityValue}
      onBlur={dispatchOnSecurityValueBlur}
      value={securityValue}
      errorMessage={errorMessage}
      isAllowed={isAllowed}
      textClassName={inputComponentStyles.inputText}
      style={style}
    />
  );
};

SecurityValueComponent.defaultProps = {
  securityValue: undefined,
  errorMessage: undefined,
};

SecurityValueComponent.propTypes = {
  dispatchUpdateSecurityValue: PropTypes.func.isRequired,
  dispatchOnSecurityValueBlur: PropTypes.func.isRequired,
  securityValue: PropTypes.number,
  errorMessage: PropTypes.string,
};

export default SecurityValueComponent;
