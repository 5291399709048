import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import CollapsibleMessage from 'shared/components/Notification/CollapsibleMessage';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from './CollapsibleLog.module.scss';

const getContentRenderer = title => (opened, toggle) => (
  <div>
    {title}
    <TertiaryButton
      className={styles.collapseButton}
      type="button"
      onClick={() => {
        toggle(!opened);
      }}
    >
      <u>{opened ? 'Collapse details' : 'Expand details'}</u>
    </TertiaryButton>
  </div>
);

const getDetailsRenderer = details => () => (
  <>
    {details.map(detail => <div key={shortId.generate()}>{detail}</div>)}
  </>
);

const CollapsibleContent = ({ title, details }) => (
  <div className={styles.contentWrapper}>
    <CollapsibleMessage
      renderHeader={getContentRenderer(title)}
      renderBody={getDetailsRenderer(details)}
    />
  </div>
);

CollapsibleContent.propTypes = {
  title: PropTypes.node.isRequired,
  details: PropTypes.node.isRequired,
};

export default CollapsibleContent;
