import { combineReducers } from 'redux';
import tasksInfo from './tasksInfo';
import insuranceReferral from './insuranceReferral';

import fundingWorksheetCalculation from './fundingWorksheetCalculation/index';
import applicationDetail from './applicationDetail';
import applicationFetchingStatus from './applicationFetchingStatus';
import applicationStatus from './applicationStatus';
import applicationStatusRequestStatus from './applicationStatusRequestStatus';
import activityLogs from './activityLogs';
import applicationUpdates from './applicationUpdates';
import applicationLoans from './applicationLoans';
import factFindDetail from './factFindDetail';
import applicationStage from './applicationStage';
import somaFetchingStatus from './somaFetchingStatus';
import employmentsUpdatingStatus from './employmentsUpdatingStatus';
import applicantsSyncStatus from './applicantsSyncStatus';
import applicantsUpdatingStatus from './applicantsUpdatingStatus';
import copyApplication from './copyApplication';
import productSearchReducer from '../../ApplicationDetailContentNav/ProductSearch/redux';
import productComparisonReducer from '../../ApplicationDetailContentNav/ProductComparison/redux';
import swimlaneInfo from './swimlaneInfo';

export default combineReducers({
  applicationDetail,
  applicationFetchingStatus,
  applicationStatus,
  applicationStatusRequestStatus,
  activityLogs,
  applicationUpdates,
  tasksInfo,
  insuranceReferral,
  applicationLoans,
  factFindDetail,
  applicationStage,
  somaFetchingStatus,
  employmentsUpdatingStatus,
  applicantsUpdatingStatus,
  applicantsSyncStatus,
  fundingWorksheetCalculation,
  copyApplication,
  productSearch: productSearchReducer,
  productComparison: productComparisonReducer,
  swimlaneInfo,
});
