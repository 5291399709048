import { createReducer } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import identity from 'lodash/identity';
import * as types from './types';
import { FETCHING_STATUS } from '../../../../../../constants';
import applicationDetailsTypes from '../../../redux/constants';
import { iSet } from '../../../../../../shared/utils';
import { SEARCH_TRIGGERS } from '../utils';

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  products: [],
  serviceabilityInfos: {},
};

const productSearchReducer = createReducer(initialState, {
  [types.SEARCH_PRODUCTS_START]: (state, { searchCriteria, searchTrigger }) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
    products: searchTrigger === SEARCH_TRIGGERS.CRITERIA_FORM ? [] : state.products,
    searchCriteria,
    serviceabilityInfos: {},
    searchTrigger,
  }),
  [types.SEARCH_PRODUCTS_SUCCESS]: (state, { payload, searchCriteria }) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
    products: payload,
    searchCriteria,
  }),
  [types.SEARCH_PRODUCTS_FAIL]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
    products: [],
    searchCriteria: null,
  }),
  [types.UPDATE_CUSTOM_PRODUCT]: (state, { payload }) => ({
    ...state,
    customProduct: payload,
  }),
  [types.REMOVE_CUSTOM_PRODUCT]: (state) => ({
    ...state,
    customProduct: null,
  }),
  [applicationDetailsTypes.SWITCH_APPLICATION]: () => ({
    ...initialState,
  }),
  [types.GET_SERVICEABILITY_INFOS_START]: (state, { productIds }) => ({
      ...state,
      serviceabilityInfos: mapValues(mapKeys(productIds, identity), () => ({
        fetchingStatus: FETCHING_STATUS.START,
      })),
    }),
  [types.GET_SERVICEABILITY_INFOS_ABORT]: (state) => ({
    ...state,
    serviceabilityInfos: mapValues(state.serviceabilityInfos, () => ({
      fetchingStatus: FETCHING_STATUS.INIT,
    })),
  }),
  [types.GET_SERVICEABILITY_ITEM_SUCCESS]: (state, {
    data, productId, totalLoanAmount, details,
  }) => ({
      ...state,
      serviceabilityInfos: iSet(state.serviceabilityInfos, productId, {
        fetchingStatus: FETCHING_STATUS.SUCCESS,
        data,
        details,
        serviceabilityResult: totalLoanAmount <= data.maximumLoanAmount,
      }),
    }),
  [types.GET_SERVICEABILITY_ITEM_FAIL]: (state, { productId }) => ({
    ...state,
    serviceabilityInfos: iSet(state.serviceabilityInfos, productId, {
      fetchingStatus: FETCHING_STATUS.ERROR,
    }),
  }),
});

export default productSearchReducer;
