import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import uniqueId from 'lodash/uniqueId';
import {
  getTwoDecimalPlaces,
  getCategories,
} from '../renderers';
import {
  amountCellRenderGenerator,
  amountHeaderRenderGenerator,
  getBasicCommissionColumns,
  COMMISSION_AMOUNT_TYPE,
  getMonthsColumnConfig,
} from './commissionUtil/commission';
import MobiusTable from '../../../shared/components/MobiusTable';

const amountKeys = {
  commissionKey: COMMISSION_AMOUNT_TYPE.UPFRONT,
  balanceKey: COMMISSION_AMOUNT_TYPE.UPFRONT_LOAN,
};

const cellRender = monthIndex => amountCellRenderGenerator(monthIndex, amountKeys);
const headerRender = monthIndex => amountHeaderRenderGenerator(monthIndex, amountKeys);

const getColumns = () => ([
  ...getBasicCommissionColumns(),
  {
    name: 'upfrontPercentage',
    title: (
      <span>
        Current
        <br />
        franchise %
      </span>
    ),
    wordWrapEnabled: true,
    align: 'right',
    renderer: getTwoDecimalPlaces,
    allowSorting: true,
  },
]);
const replaceNonePrefixWord = input => {
  if (input.match(/^\w/)) {
    return input.replace(/\W+/g, '');
  }
  return input;
};
const toLowerCase = value => String(value).toLowerCase();
const partialSearchPredicate = (value, filter) => filter.value.split(' ')
  .every(input => toLowerCase(value).split(/[,; ]+/)
  .some(partial => partial.startsWith(replaceNonePrefixWord(toLowerCase(input.trim())))));
const integratedFilteringColumnExtensions = [
  {
    columnName: 'client',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'lender',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'adviser',
    predicate: partialSearchPredicate,
  },
  {
    columnName: 'approvalNumber',
    predicate: partialSearchPredicate,
  },
];
const UpfrontCommissions = ({
                              rows,
                              processingMonthsLength,
                              isLoading,
                              virtual,
                            }) => {
  const amountColumns = useMemo(() => getMonthsColumnConfig(
    rows,
    processingMonthsLength,
    amountKeys.commissionKey,
    cellRender,
    headerRender,
  ), [rows, processingMonthsLength]);

  // eslint-disable-next-line
  const categoriesColumn = {
      name: 'categories',
      title: 'Categories',
      renderer: getCategories,
      wordWrapEnabled: true,
      allowFiltering: true,
      hidden: false,
    };

  const columns = useMemo(() => getColumns(), []);
  const mergedColumns = useMemo(
    () => [...columns, ...amountColumns, categoriesColumn],
    [columns, amountColumns, categoriesColumn],
  );

  return (
    <MobiusTable
      key={uniqueId()}
      resizingEnabled
      style={{ height: '85vh' }}
      rows={rows}
      columns={mergedColumns}
      isLoading={isLoading}
      virtual={virtual}
      defaultSorting={[{ columnName: 'client', direction: 'asc' }]}
      customColumnFilters={integratedFilteringColumnExtensions}
    />
  );
};

UpfrontCommissions.propTypes = {
  rows: PropTypes.array,
  isLoading: PropTypes.bool,
  processingMonthsLength: PropTypes.number,
  virtual: PropTypes.bool,
};

UpfrontCommissions.defaultProps = {
  rows: [],
  isLoading: false,
  processingMonthsLength: 0,
  virtual: true,
};

export default UpfrontCommissions;
