import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const getReportsSummary = businessId =>
client.query({
  query: gql`{
    commissionManagementReports( businessId: "${businessId}" )
    {
      processingMonth
      numberOfIssues
    }
  }`,
});

const getReportDetails = (businessId, processingMonth) =>
client.query({
  query: gql`{
    commissionManagementReports( businessId: "${businessId}", processingMonth: "${processingMonth}" )
    {
      issues {
        id
        approvalNumber
        approvedAmount
        client
        commissionAmount
        loanUniqueKey
        externalLink
        lender
        loanBalance
        previousLoanBalance
        reportType
        settledAmount
        settlementDate
        props {
          commissionType
          numberOfMissingMonths
        }
      }
    }
  }`,
});

export default {
  getReportsSummary,
  getReportDetails,
};
