import React from 'react';
import FormsySelect from 'shared/components/formFields/Formsy/FormsySelect';
import styles from 'Loans/components/LoanDetails/LoanDetailsContent/Commissions/index.module.scss';
import PropTypes from 'prop-types';

const SELECT_OPTIONS = [
  { value: true, label: 'Existing Client' },
  { value: false, label: 'New Client' },
];
const InputClientOrigin = ({ value }) => (
  <FormsySelect
    name="isExistingClient"
    options={SELECT_OPTIONS}
    value={SELECT_OPTIONS.find(option => option.value === value)}
    className={styles.select}
    fullWidth
  />
  );

InputClientOrigin.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default InputClientOrigin;
