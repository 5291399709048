import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { ToastContainer, toast as showToast } from 'react-toastify/index';
import ToastMessageScaffold from 'shared/components/ToastMessageScaffold';
import 'react-toastify/dist/ReactToastify.min.css';

export const ToastContext = React.createContext({ toast: noop });

class ToastProvider extends PureComponent {
  componentDidUpdate(prevProps) {
    const { toast } = this.props;
    if (toast !== prevProps.toast) {
      const { message, options = {} } = toast;
      this.toast(message, { type: 'error', ...options });
    }
  }

  toast = (message, options = {}) => {
    showToast((
      <ToastMessageScaffold
        type={options.type}
        iconName={options.iconName}
      >
        {message}
      </ToastMessageScaffold>
    ), { type: 'error', ...options });
  };

  render() {
    const { children } = this.props;
    return (
      <ToastContext.Provider value={{ toast: this.toast }}>
        <ToastContainer
          className="toast-container"
          closeButton={false}
          rtl
          draggable={false}
        />
        {children}
      </ToastContext.Provider>
    );
  }
}

ToastProvider.propTypes = {
  toast: PropTypes.object,
  children: PropTypes.any,
};

ToastProvider.defaultProps = {
  toast: undefined,
  children: undefined,
};

const mapStateToProps = state => ({
  toast: state.toast,
});

export default connect(mapStateToProps)(ToastProvider);
