import React from 'react';
import SecondaryButton from 'shared/components/Buttons/SecondaryButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { downloadCommissions } from 'MyBusiness/components/Commissions/redux/loanCommissionsRedux';

const style = {
  float: 'right',
};

export const DownloadCommissions = ({
    financialYear, isDownloading, isCommissionsLoading, dispatchDownloadCommissionsXlsx,
}) => (
  <SecondaryButton
    style={style}
    disabled={isDownloading || isCommissionsLoading}
    onClick={() => dispatchDownloadCommissionsXlsx(financialYear)}
  >
    Download as spreadsheet
  </SecondaryButton>
  );

DownloadCommissions.defaultProps = {
  isDownloading: false,
  isCommissionsLoading: false,
};

DownloadCommissions.propTypes = {
  financialYear: PropTypes.string.isRequired,
  isDownloading: PropTypes.bool,
  isCommissionsLoading: PropTypes.bool,
  dispatchDownloadCommissionsXlsx: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isDownloading: state.myBusiness.commissions.loanCommissions.xlsx.isDownloading,
  isCommissionsLoading: state.myBusiness.commissions.loanCommissions.isLoading,
});

const mapDispatchToProps = dispatch => ({
  dispatchDownloadCommissionsXlsx: financialYear => dispatch(downloadCommissions(financialYear)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCommissions);
