import withReduxForm from '../ReduxFormFieldWrapper';
import { withFormMeta } from '../../../FormMeta';
import Switch from '../../Switch/Switch';
import { wrapInLabel } from '../../NewInputLabelWrapper';

const ReduxFormYesNoSwitch = withFormMeta(
  withReduxForm(({ value }) => ({
    checked: value || false,
  }))(wrapInLabel(Switch)),
);

export default ReduxFormYesNoSwitch;
