import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './Company.module.scss';
import TertiaryButton from '../../../../../../../shared/components/Buttons/TertiaryButton';
import Icon from '../../../../../../../shared/components/Icon/Icon';
import SecondaryButton from '../../../../../../../shared/components/Buttons/SecondaryButton';

export const AddRemoveButtonGroup = ({
                                       addFunc, removeFunc, showAdd, disableAdd, showRemove, className,
}) => (
  <div className={className}>
    <div>{'\u00a0'}</div>
    <div className={styles.buttonGroup}>
      <div className={classNames(styles.buttonWrapper, styles.closeButtonWrapper)}>
        {showRemove && (
        <TertiaryButton onClick={removeFunc} className={styles.closeButton}>
          <Icon name="close" />
        </TertiaryButton>
        )}
      </div>
      <div className={classNames(styles.buttonWrapper, styles.addButtonWrapper)}>
        {showAdd && (
        <SecondaryButton
          onClick={addFunc}
          disabled={disableAdd}
          className={styles.addButton}
        >
          <span>Add</span>
          <Icon name="add" size="18" />
        </SecondaryButton>
        )}
      </div>
    </div>
  </div>
);

AddRemoveButtonGroup.propTypes = {
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  showAdd: PropTypes.bool.isRequired,
  showRemove: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  disableAdd: PropTypes.bool,
};

AddRemoveButtonGroup.defaultProps = {
  disableAdd: false,
};
