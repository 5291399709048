import { combineReducers } from 'redux';

import loans from '../components/Loans/redux/loansRedux';
import loan from '../components/LoanDetails/redux/loanReducer';
import fixedTrails from '../components/LoanSetFixedTrail/redux/updateFixedTrailRedux';

import { loanSplitReducer as loanSplit } from '../components/LoanSplit/redux/loanSplitReducer';

export default combineReducers({
  loans,
  loan,
  loanSplit,
  fixedTrails,
});
