import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import isNil from 'lodash/isNil';
import { filterFormsyProps } from '../../formFields/Formsy/utils';
import NumberInput from '../../formFields/NumberInput';
import { convertDecimalYearToIntegerYearAndMonth } from '../../../utils';
import styles from './LoanCriteria.module.scss';

const zeroToNull = value => (value === 0 ? null : value);
const MAX_TERM = 40;
const MAX_MONTH_TERM = 11;

const LoanTermInput = ({
                         value,
                         setValue,
                         isTopUp,
                         showRequired,
                         isFormDisabled,
                         errorMessage,
                         disabled,
                         ...otherProps
}) => {
  const requiredMessage = showRequired ? 'This field is required' : undefined;
  const { year, month } = convertDecimalYearToIntegerYearAndMonth(value);

  const handleYearValueChange = useCallback((newYearValue = 0) => {
    const updatedValue = newYearValue + month / 12;
    setValue(zeroToNull(updatedValue));
  }, [month, setValue]);

  const handleMonthValueChange = useCallback((newMonthValue = 0) => {
    const updatedValue = year + newMonthValue / 12;
    setValue(zeroToNull(updatedValue));
  }, [year, setValue]);

  const isLoanTermYearsAllowed = useCallback((newYearValue) => (
    isNil(newYearValue) || (newYearValue + month / 12 <= MAX_TERM)
  ), [month]);

  const isLoanTermMonthsAllowed = useCallback((newMonthValue) => (
    isNil(newMonthValue)
    || (newMonthValue > 0
      && newMonthValue <= MAX_MONTH_TERM
      && newMonthValue / 12 + year <= MAX_TERM
    )
  ), [year]);
  return (
    <div className={styles.loanTermInput}>
      <NumberInput
        {...filterFormsyProps(otherProps)}
        onChange={handleYearValueChange}
        disabled={isFormDisabled || disabled}
        value={zeroToNull(year)}
        errorMessage={errorMessage || requiredMessage}
        suffix={year > 1 ? ' years' : ' year'}
        allowNegative={false}
        decimalScale={0}
        fixedDecimalScale
        placeholder="years"
        fullWidth
        isAllowed={isLoanTermYearsAllowed}
      />
      {isTopUp && (
        <NumberInput
          {...filterFormsyProps(otherProps)}
          onChange={handleMonthValueChange}
          disabled={isFormDisabled || disabled || year >= MAX_TERM}
          value={zeroToNull(month)}
          errorMessage={errorMessage || requiredMessage}
          suffix={month > 1 ? ' months' : ' month'}
          allowNegative={false}
          decimalScale={0}
          fixedDecimalScale
          placeholder="months"
          fullWidth
          isAllowed={isLoanTermMonthsAllowed}
        />
      )}
    </div>
  );
};

LoanTermInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number,
  errorMessage: PropTypes.string,
  isFormDisabled: PropTypes.bool.isRequired,
  showRequired: PropTypes.bool.isRequired,
  isTopUp: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

LoanTermInput.defaultProps = {
  disabled: false,
  value: null,
  errorMessage: undefined,
};

export default withFormsy(LoanTermInput);
