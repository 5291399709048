import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import concat from 'lodash/concat';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import styles from '../shared/Component.module.scss';
import GeneralSection from './GeneralSection';
import ContactSection from './ContactSection';
import AddressesSection from './AddressesSection';
import IdentificationSection from './IdentificationSection';
import DependantsSection from './DependantsSection';
import SummarySection from '../shared/SummarySection';
import { FORM_NAME } from '../shared/constants';

const selector = formValueSelector(FORM_NAME);

const PersonComponent = ({
  hasEditPermission,
  fieldNamePrefix,
  isApplicant,
  replace,
  editInLotusNotesField,
  displayRemoveButton,
  handleClickRemove,
}) => {
  const persons = useSelector(state =>
    concat(
      selector(state, 'applicants')?.filter(applicant => applicant.isPerson),
      selector(state, 'guarantors')?.filter(applicant => applicant.isPerson),
    ));
  return (
    <div
      className={styles.wrapper}
    >
      <SummarySection
        fieldNamePrefix={fieldNamePrefix}
        collection={persons}
        handleReplace={replace}
        isApplicant={isApplicant}
      />
      {hasEditPermission && (
        <div className={styles.editInLotusNotesLinkRow}>
          <Field
            name={`${fieldNamePrefix}.externalLink`}
            component={editInLotusNotesField}
          />
        </div>
      )}
      <GeneralSection fieldNamePrefix={fieldNamePrefix} persons={persons} />
      <ContactSection fieldNamePrefix={fieldNamePrefix} />
      <AddressesSection fieldNamePrefix={fieldNamePrefix} />
      <IdentificationSection fieldNamePrefix={fieldNamePrefix} />
      <DependantsSection fieldNamePrefix={fieldNamePrefix} />
      {displayRemoveButton && (
        <div className={styles.buttonRow}>
          <TertiaryButton className={styles.removeButton} onClick={handleClickRemove}>
            Remove
          </TertiaryButton>
        </div>
      )}
    </div>
  );
};

PersonComponent.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  isApplicant: PropTypes.bool.isRequired,
  replace: PropTypes.func.isRequired,
  editInLotusNotesField: PropTypes.elementType.isRequired,
  displayRemoveButton: PropTypes.bool.isRequired,
  handleClickRemove: PropTypes.func.isRequired,
};

export default PersonComponent;
