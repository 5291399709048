import React from 'react';
import PropTypes from 'prop-types';
import FormsyNumberInput from 'shared/components/formFields/Formsy/FormsyNumberInput';

export const InputPercentage = ({
  value, name, disabled, onBlur,
}) => (
  <FormsyNumberInput
    value={value}
    name={name}
    decimalScale={2}
    isAllowed={number => number === undefined || (number !== 0 && number <= 100)}
    suffix="%"
    allowNegative={false}
    fixedDecimalScale
    fullWidth
    disabled={disabled}
    onBlur={onBlur}
  />
);

InputPercentage.defaultProps = {
  value: undefined,
  disabled: false,
  onBlur: undefined,
};

InputPercentage.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};
