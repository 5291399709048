import React from 'react';
import { ProductsComparisonTableContext } from '../../ProductSearchContext';
import ComparisonEditingModal from './ComparisonEditingModal';

const ConnectedComparisonEditingModal = () => (
  <ProductsComparisonTableContext.Consumer>
    {context => {
      const {
        isEditing,
        isLoading,
        currentModification,
        hasError,
      } = context.variantModification;

      return (
        <ComparisonEditingModal
          isEditing={isEditing}
          isLoading={isLoading}
          currentModification={currentModification}
          hasError={hasError}
          dispatchCancelVariantEditing={context.dispatchCancelVariantEditing}
          dispatchVariantModificationUpdateValue={context.dispatchVariantModificationUpdateValue}
          dispatchApplyModification={context.dispatchApplyModification}
        />
      );
    }}
  </ProductsComparisonTableContext.Consumer>
);

export default ConnectedComparisonEditingModal;
