import React from 'react';
import classNames from 'classnames';
import NumberInput from 'shared/components/formFields/NumberInput/index';
import styles from './styles.module.scss';

const MAX_LVR = 100;
const isValidLoanValueRatio = newLvr =>
  (newLvr === undefined || (newLvr > 0 && newLvr <= MAX_LVR));

const style = {
  display: 'inline-block',
  margin: '0rem 1.2rem 1rem 0rem',
  padding: 0,
  verticalAlign: 'top',
  width: '19.5rem',
};

const LoanValueRatioComponent = (props) => (
  <NumberInput
    label="LVR (%)"
    className={styles.label}
    isAllowed={isValidLoanValueRatio}
    decimalScale={2}
    fixedDecimalScale
    {...props}
    style={style}
    textClassName={classNames(styles.inputText, styles.short)}
  />
);

export default LoanValueRatioComponent;
