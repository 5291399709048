import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { FETCHING_STATUS } from '../../../../../../constants';

const initialState = {
  applicantSomaFetchingStatus: FETCHING_STATUS.INIT,
  guarantorSomaFetchingStatus: FETCHING_STATUS.INIT,
};
const somaFetchingStatus = createReducer(initialState,
  {
    [types.APPLICANT_SOMA_DOWNLOAD_START]: state => (
      {
        ...state,
        applicantSomaFetchingStatus: FETCHING_STATUS.START,
      }
    ),
    [types.APPLICANT_SOMA_DOWNLOAD_SUCCESS]: state => (
      {
        ...state,
        applicantSomaFetchingStatus: FETCHING_STATUS.SUCCESS,
      }
    ),
    [types.APPLICANT_SOMA_DOWNLOAD_ERROR]: state => (
      {
        ...state,
        applicantSomaFetchingStatus: FETCHING_STATUS.ERROR,
      }
    ),

    [types.GUARANTOR_SOMA_DOWNLOAD_START]: state => (
      {
        ...state,
        guarantorSomaFetchingStatus: FETCHING_STATUS.START,
      }
    ),
    [types.GUARANTOR_SOMA_DOWNLOAD_SUCCESS]: state => (
      {
        ...state,
        guarantorSomaFetchingStatus: FETCHING_STATUS.SUCCESS,
      }
    ),
    [types.GUARANTOR_SOMA_DOWNLOAD_ERROR]: state => (
      {
        ...state,
        guarantorSomaFetchingStatus: FETCHING_STATUS.ERROR,
      }
    ),
  });

export default somaFetchingStatus;
