import React, { useCallback } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import PurposeCategoryComponent from 'shared/components/productSearch/ProductSearchFields/PurposeCategoryComponent';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';

const PurposeCategoryField = props => {
  const { namePrefix, formName } = props;
  const {
    purpose: {
      input: {
        value: purpose,
      },
    },
    purposeCategory: {
      input: {
        value: purposeCategory,
        onChange: onPurposeCategoryChange,
      },
    },
    purposeDetails: {
      input: {
        value: purposeDetails,
        onChange: onPurposeDetailsChange,
      },
    },
    refinanceReason: {
      input: {
        value: refinanceReason,
        onChange: onRefinanceReasonChange,
      },
    },
  } = get(props, namePrefix, {});

  const dispatch = useDispatch();
  const handlePurposeCategoryChange = useCallback((value) => {
    onPurposeCategoryChange(value);
    dispatch(change(formName, `${namePrefix}.purposeDetails`, null));
    dispatch(change(formName, `${namePrefix}.refinanceReason`, null));
  }, [onPurposeCategoryChange, formName, namePrefix, dispatch]);

  const handlePurposeDetailsChange = useCallback((value) => {
    onPurposeDetailsChange(value);
    dispatch(change(formName, `${namePrefix}.refinanceReason`, null));
  }, [onPurposeDetailsChange, formName, namePrefix, dispatch]);

  return (
    <PurposeCategoryComponent
      purpose={purpose}
      onPurposeCategoryChange={handlePurposeCategoryChange}
      onPurposeDetailsChange={handlePurposeDetailsChange}
      onRefinanceReasonChange={onRefinanceReasonChange}
      purposeCategory={purposeCategory}
      purposeDetails={purposeDetails}
      refinanceReason={refinanceReason}
    />
  );
};

PurposeCategoryField.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
};

export default PurposeCategoryField;
