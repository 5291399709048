import React from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/formFields/Select';
import { PRODUCT_TYPES } from 'constants.js';
import styles from './styles.module.scss';

const PRODUCT_TYPE_OPTIONS = [
  { value: null, label: 'Any' },
  { value: 'fixed', label: PRODUCT_TYPES.fixed },
  { value: 'credit', label: PRODUCT_TYPES.credit },
  { value: 'variable', label: PRODUCT_TYPES.variable },
];

const toSelectValue = value => PRODUCT_TYPE_OPTIONS.find(option => option.value === value);

const ProductTypeComponent = ({ value, onChange }) => (
  <Select
    label="Product type"
    className={styles.select}
    value={toSelectValue(value)}
    onChange={(selected) => onChange(selected.value)}
    options={PRODUCT_TYPE_OPTIONS}
  />
);

ProductTypeComponent.defaultProps = {
  value: undefined,
};

ProductTypeComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(PRODUCT_TYPE_OPTIONS.map(option => option.value)),
};

export default ProductTypeComponent;
