import React from 'react';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import HasPermissions from 'shared/components/authorisation/HasPermissions';
import get from 'lodash/get';
import { connect } from 'react-redux';

export const USER_PERMISSIONS = {
  GROUP_OFFICE: [PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL],
  ADVISER: [
    PERMISSIONS.EDIT_ALL_LOANS,
    PERMISSIONS.EDIT_OWN_LOANS,
  ],
  ADVISER_AND_GROUP_OFFICE: [
    PERMISSIONS.EDIT_ALL_LOANS,
    PERMISSIONS.EDIT_OWN_LOANS,
    PERMISSIONS.EDIT_ALL_LOANS_ADDITIONAL,
  ],
};

export const BaseField = ({
 permissions, children, fallback, isEditing,
}) => {
  if (isEditing) {
    return (
      <HasPermissions permissions={permissions} fallback={fallback}>
        {children}
      </HasPermissions>
    );
  }
  return fallback || null;
};

const mapStateToProps = state => ({
  isEditing: get(state, 'loans.loan.isEditing', false),
});

export const EditField = connect(mapStateToProps)(BaseField);
