import PropTypes from 'prop-types';
import React from 'react';
import styles from './style.module.scss';

const VariantsCell = ({
                        product,
                        value,
                        format,
                        children,
                      }) => {
  if (!product.isSplitted) {
    return format(value, product.variants[0], product);
  }
  return (
    <div
      className={styles.cellWrapper}
      style={
        {
          gridTemplateRows: `${product.variants.map(() => '1fr')
            .join(' ')} 2.8rem`,
        }
      }
    >
      {product.variants.map((variant, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.cellItem} key={index}>
          {format(value, variant, product, index)}
        </div>
      ))}
      {product.isSplitted && children}
    </div>
  );
};

VariantsCell.propTypes = {
  product: PropTypes.object.isRequired,
  value: PropTypes.any,
  format: PropTypes.func.isRequired,
  children: PropTypes.any,
};

VariantsCell.defaultProps = {
  value: PropTypes.undefined,
  children: null,
};

export default VariantsCell;
