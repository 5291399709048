import { combineReducers } from 'redux';
import applicationCriteria from './applicationCriteria/standAloneApplicationCriteriaReducer';
import loanCriteriaList from './loanCriteriaList/standAloneLoanCriteriaReducer';
import variantModification from './variantModification/standAloneVariantModificationReducer';
import comparison from './comparison/standAloneComparisonReducer';
import products from './products/standAloneProductsReducer';

export default combineReducers({
  applicationCriteria,
  loanCriteriaList,
  variantModification,
  comparison,
  products,
});
