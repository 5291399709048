/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ShowMore.module.scss';
import Icon from '../Icon/Icon';

const ShowMore = props => {
  const {
    name, children, headerClassName, iconSize, showMoreClass, showLessClass, extended,
  } = props;
  const [showMore, setShowMore] = useState(extended);
  return (
    <div className={classNames(styles.showMoreWrap, { [styles.showMore]: showMore })}>
      <div
        className={classNames(styles.header, headerClassName)}
        onClick={() => setShowMore(!showMore)}
        role="presentation"
        onKeyDown={e => {
          if (e && (e.key === 'Enter' || e.key === ' ')) {
            setShowMore(!showMore);
          }
        }}
      >
        <div className={classNames(styles.name, 'show-more-name')}>{name}</div>
        <div className={classNames('show-more-icon')}>
          <div
            className={styles.icon}
            style={{
              height: `${iconSize}px`,
              width: `${iconSize}px`,
            }}
            tabIndex={0}
          >
            <Icon name="arrow-down" size={iconSize || '24'} color="#333f48" />
          </div>
        </div>
      </div>
      <div className={classNames(styles.content, { [showMoreClass]: showMore, [showLessClass]: !showMore })}>
        {children}
      </div>
    </div>
  );
};

ShowMore.propTypes = {
  name: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  headerClassName: PropTypes.string,
  iconSize: PropTypes.string,
  showMoreClass: PropTypes.string,
  showLessClass: PropTypes.string,
  extended: PropTypes.bool,
};

ShowMore.defaultProps = {
  headerClassName: '',
  iconSize: '',
  showMoreClass: '',
  showLessClass: '',
  extended: true,
};

export default ShowMore;
