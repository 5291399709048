import get from 'lodash/get';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';

const initialState = [];

const getActivityLogs = (logs) => (logs.map(log => ({
  ...log,
  actionTime: log.createdAt || log.actionTime,
  updateTime: log.actionTime,
})));

const activityLogs = createReducer(initialState, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, { data }) =>
    (getActivityLogs(get(data, 'activityLogs', initialState))),
  [types.UPDATE_APPLICATION_STATUS_SUCCESS]: (state, action) => (getActivityLogs(action.activityLogs)),
  [types.UPDATE_APPLICATION_UPDATES]: (state, { application }) => (getActivityLogs(application.activityLogs)),
  [types.UPDATE_ACTIVITY_LOGS]: (state, action) => (getActivityLogs(action.activityLogs)),
}, initialState);

export default activityLogs;
