import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  FieldArray, formPropTypes, formValueSelector, reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton';
import UnsavedChangePrompt from 'shared/components/UnsavedChangePrompt';
import FormMetaProvider from 'shared/components/FormMeta/FormMetaProvider';
import ShowMore from 'shared/components/ShowMore/ShowMore';
import StickyFooter from 'shared/components/StickyFooter';
import LoadingWithOverlay from 'shared/components/LoadingWithOverlay/LoadingWithOverlay';
import styles from './components/shared/Applicants.module.scss';
import { syncApplicantsInfo, updateApplicantsAndGuarantors } from '../../redux';
import actions from '../../../Applications/redux/actions';
import { FETCHING_STATUS } from '../../../../../constants';
import { hasEditPermissionSelector } from '../../redux/selectors/permissions';
import { getApplicantsInitialValues, getGuarantorsInitialValues } from '../../redux/selectors/applicants';
import ApplicantsOrGuarantorsSection from './components/shared/ApplicantsOrGuarantorsSection';
import { FORM_NAME } from './components/shared/constants';

const onSubmit = (values, dispatch) => dispatch(updateApplicantsAndGuarantors(values));
const selector = formValueSelector(FORM_NAME);

const ApplicantsAndGuarantors = ({
                      valid,
                      pristine,
                      submitting,
                      handleSubmit,
                      hasEditPermission,
                      businessId,
                      getClientsByBusinessId,
                      updateFromLotusNotes,
                      isSyncingApplicantsInfo,
                      isFetching,
                    }) => {
  useEffect(() => {
    getClientsByBusinessId(businessId);
  }, [businessId, getClientsByBusinessId]);

  const syncInfoFromLotusNotes = useMemo(() => () => {
    updateFromLotusNotes().then(() => getClientsByBusinessId(businessId));
  }, [updateFromLotusNotes, businessId, getClientsByBusinessId]);

  const personNumber = useSelector(state => selector(state, 'applicants')
        ?.filter(applicant => applicant.isPerson)?.length
      + selector(state, 'guarantors')
        ?.filter(applicant => applicant.isPerson)?.length);
  const companyNumber = useSelector(state =>
    selector(state, 'applicants')?.filter(applicant => !applicant.isPerson)?.length
    + selector(state, 'guarantors')?.filter(applicant => !applicant.isPerson)?.length);
  const personClientsNumber = useSelector(state => get(state, 'applications.clients')?.persons?.length);
  const companiesClientsNumber = useSelector(state => get(state, 'applications.clients')?.companies?.length);

  const needDisableAddPerson = useMemo(() => personNumber >= personClientsNumber || submitting,
    [personNumber, personClientsNumber, submitting]);

  const needDisabledAddCompany = useMemo(() => companyNumber >= companiesClientsNumber || submitting,
    [companyNumber, companiesClientsNumber, submitting]);

  const getSectionWrapper = useMemo(() => (isApplicant) => (
    <div className={styles.sectionWrapper}>
      <ShowMore
        name={isApplicant ? 'Applicants' : 'Guarantors'}
        showMoreClass={styles.showMore}
        headerClassName={styles.actionWrap}
        iconSize="25"
      >
        <FieldArray
          name={isApplicant ? 'applicants' : 'guarantors'}
          component={ApplicantsOrGuarantorsSection}
          props={{
            hasEditPermission,
            addPersonDisabled: needDisableAddPerson,
            addCompanyDisabled: needDisabledAddCompany,
            isApplicant,
          }}
        />
      </ShowMore>
    </div>
  ), [hasEditPermission, needDisableAddPerson, needDisabledAddCompany]);
  return (
    <>
      <div className={styles.headerContainer}>
        {
          hasEditPermission && (
          <PrimaryButton
            onClick={syncInfoFromLotusNotes}
            disabled={isSyncingApplicantsInfo}
            loading={isSyncingApplicantsInfo}
          >
            Update from Lotus Notes
          </PrimaryButton>
          )
        }
      </div>
      <FormMetaProvider readOnly={!hasEditPermission}>
        <form onSubmit={handleSubmit}>
          {getSectionWrapper(true)}
          {getSectionWrapper(false)}
          {hasEditPermission && (
          <StickyFooter
            disabled={pristine || !valid || submitting || isFetching}
            type="submit"
          />
            )}
        </form>
        <LoadingWithOverlay isLoading={submitting || isFetching} />
        <UnsavedChangePrompt shouldConfirm={!pristine} />
      </FormMetaProvider>
    </>
  );
};

ApplicantsAndGuarantors.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
  businessId: PropTypes.string.isRequired,
  updateFromLotusNotes: PropTypes.func.isRequired,
  isSyncingApplicantsInfo: PropTypes.bool.isRequired,
  ...formPropTypes,
};

export default connect(
  state => ({
      initialValues: {
        applicants: getApplicantsInitialValues(state),
        guarantors: getGuarantorsInitialValues(state),
      },
      hasEditPermission: hasEditPermissionSelector(state),
      businessId: get(state, 'business.selectedBusiness.id', null),
      isSyncingApplicantsInfo: get(state, 'application.applicantsSyncStatus') === FETCHING_STATUS.START,
      isFetching: get(state, 'application.applicationFetchingStatus.fetchingStatus') === FETCHING_STATUS.START
        && get(state, 'application.applicantsUpdatingStatus') === FETCHING_STATUS.SUCCESS,
    }
  ), {
    getClientsByBusinessId: actions.getClientsByBusinessId,
    updateFromLotusNotes: syncApplicantsInfo,
  },
)(reduxForm({ form: FORM_NAME, onSubmit, enableReinitialize: true })(ApplicantsAndGuarantors));
