import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

export const retrieveLoanViews = (businessId, start, end, dateRangeFilterFields) => {
  const dateRangeFilterFieldsAsStr = dateRangeFilterFields.map(field => `"${field}"`).join(',');
  return client.query({
    query: gql`{
        loanViews(businessId: "${businessId}",
            start: "${start || ''}",
            end: "${end || ''}",
            dateRangeFilterFields: [${dateRangeFilterFieldsAsStr}]
        )
        {
            id
            applicationId
            applicationStatus
            expectedSettlementDate
            submittedDate
            settledDate
            loanType
            loanStatus
            amount
            commissionLoanAmount
            businessId
            lenderId
            lenderName
            adviserId
            adviserName
            expectedUpfrontCommission
            isUpfrontExpected
        }
    }`,
  });
};

export const retrieveLoanViewsForConversionRate = (businessId, start, end) =>
  client.query({
    query: gql`{
        loanViews(businessId: "${businessId}",
            start: "${start || ''}",
            end: "${end || ''}",
            dateRangeFilterFields: ["submittedDate"]
        )
        {
            id
            applicationId
            applicationStatus
            submittedDate
            settledDate
            businessId
            adviserId
            adviserName
        }
    }`,
  });

export const retrieveReportingMeta = businessId => client.query({
  query: gql`{
      reportingMeta(businessId: "${businessId}")
      {
          earliestDate
      }
  }`,
});

export const retrieveCommissionSummaryMeta = businessId => client.query({
  query: gql`{
      commissionSummaryMeta(businessId: "${businessId}")
      {
          currentYearLoanBookSize
          {
            loanBookSize
            date
          }
          lastYearLoanBookSize
          {
            loanBookSize
            date
          }
          currentYearlyRunOffRate
          {
            startingBalance
            {
              loanBookSize
              date
            }
            closingBalance
            {
              loanBookSize
              date
            }
          }
          lastYearYearlyRunOffRate
          {
            startingBalance
            {
              loanBookSize
              date
            }
            closingBalance
            {
              loanBookSize
              date
            }
          }
          currentMonthlyRunOffRate
          {
            startingBalance
            {
              loanBookSize
              date
            }
            closingBalance
            {
              loanBookSize
              date
            }
          }
          lastYearMonthlyRunOffRate
          {
            startingBalance
            {
              loanBookSize
              date
            }
            closingBalance
            {
              loanBookSize
              date
            }
          }
      }
  }`,
});

export const retrieveAllLoanViews = (businessId, start, end, dateRangeFilterFields) => {
  const dateRangeFilterFieldsAsStr = dateRangeFilterFields.map(field => `"${field}"`).join(',');
  return client.query({
    query: gql`{
        allLoanViews(businessId: "${businessId}",
            start: "${start || ''}",
            end: "${end || ''}",
            dateRangeFilterFields: [${dateRangeFilterFieldsAsStr}]
        )
        {
            id
            applicationId
            applicationStatus
            expectedSettlementDate
            submittedDate
            settledDate
            loanType
            loanStatus
            amount
            commissionLoanAmount
            businessId
            lenderId
            lenderName
            adviserId
            adviserName
            expectedUpfrontCommission
            isUpfrontExpected
        }
    }`,
  });
};
