import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import Select from 'shared/components/formFields/Select';
import { PERMISSIONS } from 'shared/components/authorisation/permissions';
import { useHasPermission } from 'shared/components/authorisation/hasPermissionTo';
import styles from './styles.module.scss';

const getPercentage = (molecular, denominator) => (
  denominator === 0 ? 'N/A' : `${((molecular / denominator) * 100).toFixed()}%`
);

const getItemsForAllAdviser = (conversionRateItems, rangeType) => {
  const rateItemsByRangeType = map(conversionRateItems, rangeType);
  return rateItemsByRangeType[0].map(({ monthYear }, index) => {
    const allItemsOfOneMonth = rateItemsByRangeType.map(itemOfOneYear => itemOfOneYear[index]);
    return {
      monthYear,
      numOfSubmitted: sumBy(allItemsOfOneMonth, 'numOfSubmitted'),
      numOfSettled: sumBy(allItemsOfOneMonth, 'numOfSettled'),
    };
  });
};

const getConversionRate = (conversionRateItems, currentOption, currentFranchiseName) => {
  if (currentOption.value === currentFranchiseName) {
    const currentForAllAdviser = getItemsForAllAdviser(conversionRateItems, 'current');
    const previousForAllAdviser = getItemsForAllAdviser(conversionRateItems, 'previous');
    return {
      current: currentForAllAdviser,
      previous: previousForAllAdviser,
    };
  }
  return conversionRateItems.find(item => item.adviserId === currentOption.value);
};

const ConversionRate = ({ conversionRateItems, businessName }) => {
  if (isEmpty(conversionRateItems)) {
    const end = moment();
    const start = moment(end.format('YYYY-MM')).subtract(11, 'month');
    return (
      <div className={styles.noDataMessageContainer}>
        <span className={styles.noDataMessage}>No data to show for {start.format('MMM YYYY')} - {end.format('MMM YYYY')}</span>
      </div>
);
  }

  const isViewALl = useHasPermission(PERMISSIONS.VIEW_ALL_APPLICATION_TRACKING);

  const OPTION_FRANCHISE = {
    label: `${businessName} (Franchise)`,
    value: businessName,
  };

  const optionsFromData = sortBy(map(conversionRateItems, (item) => ({
    value: item.adviserId,
    label: item.adviserName,
  })), item => item.label.toLowerCase());
  const options = isViewALl ? concat(OPTION_FRANCHISE, optionsFromData) : optionsFromData;

  const [currentOption, setCurrentOption] = useState(options[0]);

  const conversionRate = getConversionRate(conversionRateItems, currentOption, businessName);
  const months = conversionRate.current.map(
    (item) => item.monthYear,
  );
  return (
    <div className={styles.container}>
      <div className={styles.title}>Conversion rates Submitted - Settled</div>
      <div className={styles.selectorWrapper}>
        <Select options={options} value={currentOption} onChange={setCurrentOption} />
      </div>
      <div className={styles.row}>
        <div className={styles.name} />
        <div className={styles.content}>
          {months.map((item) => (
            <div key={item} className={styles.month}>{item}</div>
          ))}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.name}>Last 12 months</div>
        <div className={classNames(styles.content, styles.lastThreeChild)}>
          {conversionRate.current.map((item) => (
            <div key={item.monthYear}>
              <div className={styles.textLarge}>{getPercentage(item.numOfSettled, item.numOfSubmitted)}</div>
              <div>
                <span className={styles.textMiddle}>{item.numOfSettled}/</span>
                <span className={styles.textSmall}>{item.numOfSubmitted} appl.</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr className={styles.separator} />
      <div className={styles.row}>
        <div className={styles.name}>Previous 12 months</div>
        <div className={styles.content}>
          {conversionRate.previous.map((item) => (
            <div key={item.monthYear}>
              <div className={styles.textMiddle}>{getPercentage(item.numOfSettled, item.numOfSubmitted)}</div>
              <div className={styles.textSmall}>
                {item.numOfSettled}/{item.numOfSubmitted} appl.
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ConversionRate.propTypes = {
  conversionRateItems: PropTypes.array.isRequired,
  businessName: PropTypes.string.isRequired,
};

export default ConversionRate;
