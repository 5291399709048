import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { SummaryTile } from '../SummaryTile';
import styles from './styles.module.scss';
import LoadingSpinner from '../../../../../shared/components/LoadingSpinner/LoadingSpinner';
import { toLongMonthStr, toYear } from '../../../../../utils/datetime';
import { FETCHING_STATUS } from '../../../../../constants';
import { formatAmountInteger } from '../../../../../utils/formatters';

const INFO_TEMPLATES = {
  LONG_DATE: 'LONG_DATE',
  LONG_DATE_EXTRA: 'LONG_DATE_EXTRA',
  LAST: 'LAST',
  PREVIOUS: 'PREVIOUS',
  LAST_AVERAGE: 'LAST_AVERAGE',
  PREVIOUS_AVERAGE: 'PREVIOUS_AVERAGE',
};

const getItem = (infoKey, infoValue) => ({
  key: infoKey,
  value: infoValue,
});

const longDateFormatter = (date) => (`${toLongMonthStr(date)} ${toYear(date)}`);

export const LoanBookSummary = React.memo(({
                                             fetchingStatus,
                                             currentLoanBookObject,
                                             lastOneYearLoanBookObject,
                                             curYearlyRunOffRateObject,
                                             lastOneYearlyRunOffRateObject,
                                             curMonthlyRunOffObject,
                                             lastOneMonthlyRunOffObject,
                                           }) => {
  const longDateTemplate = ['date'];
  const longDateWithExtraTemplate = ['date', 'last updated'];

  const getInfo = (object, type) => {
    if (isEmpty(object)) {
      return [];
    }
    switch (type) {
      case INFO_TEMPLATES.LAST: {
        return [getItem(INFO_TEMPLATES.LAST, 'Last 12 months')];
      }
      case INFO_TEMPLATES.PREVIOUS: {
        return [getItem(INFO_TEMPLATES.LAST, 'Previous 12 months')];
      }
      case INFO_TEMPLATES.LAST_AVERAGE: {
        const templateArr = ['Last 12', 'month average'];
        return templateArr.map((key) => getItem(key, key));
      }
      case INFO_TEMPLATES.PREVIOUS_AVERAGE: {
        const templateArr = ['Previous 12', 'month average'];
        return templateArr.map((key) => getItem(key, key));
      }
      case INFO_TEMPLATES.LONG_DATE:
        return longDateTemplate.map((key) => getItem(key, longDateFormatter(object.date)));

      case INFO_TEMPLATES.LONG_DATE_EXTRA: {
        const valueArr = [longDateFormatter(object.date), '(last updated)'];
        return longDateWithExtraTemplate.map((key, index) => getItem(key, valueArr[index]));
      }
      default:
        return [];
    }
  };

  const getValueAmount = (object, key) => (isEmpty(object) ? 'N/A' : formatAmountInteger(object[key]));

  return (
    <>
      {
        fetchingStatus === FETCHING_STATUS.START && <LoadingSpinner />
      }
      {
        fetchingStatus === FETCHING_STATUS.SUCCESS && (
          <div className={styles.summaryReport}>
            <div className={styles.summarySmallSection}>
              <SummaryTile
                title="Current loan book size"
                currentInfo={getInfo(currentLoanBookObject, INFO_TEMPLATES.LONG_DATE_EXTRA)}
                currentValueAmount={getValueAmount(currentLoanBookObject, 'loanBookSize')}
                previousInfo={getInfo(lastOneYearLoanBookObject, INFO_TEMPLATES.LONG_DATE)}
                previousValueAmount={getValueAmount(lastOneYearLoanBookObject, 'loanBookSize')}
              />
            </div>
            <div className={styles.summaryLargeSection}>
              <SummaryTile
                title="Annual loan book run off"
                currentInfo={getInfo(curYearlyRunOffRateObject, INFO_TEMPLATES.LAST)}
                currentValueAmount={curYearlyRunOffRateObject.runOffRate}
                previousInfo={getInfo(lastOneYearlyRunOffRateObject, INFO_TEMPLATES.PREVIOUS)}
                previousValueAmount={lastOneYearlyRunOffRateObject.runOffRate}
              />
              <SummaryTile
                title="Monthly loan book run off"
                currentInfo={getInfo(curMonthlyRunOffObject, INFO_TEMPLATES.LAST_AVERAGE)}
                currentValueAmount={getValueAmount(curMonthlyRunOffObject, 'runOff')}
                previousInfo={getInfo(lastOneMonthlyRunOffObject, INFO_TEMPLATES.PREVIOUS_AVERAGE)}
                previousValueAmount={getValueAmount(lastOneMonthlyRunOffObject, 'runOff')}
              />
            </div>
          </div>
        )
      }
    </>
  );
});

LoanBookSummary.defaultProps = {
  fetchingStatus: FETCHING_STATUS.START,
  currentLoanBookObject: {},
  lastOneYearLoanBookObject: {},
  curYearlyRunOffRateObject: {},
  lastOneYearlyRunOffRateObject: {},
  curMonthlyRunOffObject: {},
  lastOneMonthlyRunOffObject: {},
};

LoanBookSummary.propTypes = {
  fetchingStatus: PropTypes.number,
  currentLoanBookObject: PropTypes.object,
  lastOneYearLoanBookObject: PropTypes.object,
  curYearlyRunOffRateObject: PropTypes.object,
  lastOneYearlyRunOffRateObject: PropTypes.object,
  curMonthlyRunOffObject: PropTypes.object,
  lastOneMonthlyRunOffObject: PropTypes.object,
};
