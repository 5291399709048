import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { TRANSACTION_TYPES } from '../../../redux/constants';
import OwnedAssetForConstructionLoan from './OwnedAssetForConstructionLoan';
import PurchasingAssetComponent from './PurchasingAssetComponent';
import { isConstruction, OWNER_OCCUPIED, SUB_TYPES_NAME_MAPPING } from '../../shared/RealEstateAsset/constants';

const securityForm = 'securityForm';
const selector = formValueSelector(securityForm);

const checkOwnedAssetWithConstructionLoan = (transactionType, isForConstructionLoan) =>
  transactionType === TRANSACTION_TYPES.OWNS && isForConstructionLoan;

const PurchasingAsset = (props) => {
  const { fieldNamePrefix } = props;
  const transactionType = useSelector(state => selector(state, `${fieldNamePrefix}.transactionType`));
  const isForConstructionLoan = useSelector(state => selector(state, `${fieldNamePrefix}.isForConstructionLoan`));
  const isOwnedAssetWithConstructionLoan = useMemo(
    () => checkOwnedAssetWithConstructionLoan(transactionType, isForConstructionLoan),
    [transactionType, isForConstructionLoan],
  );

  const purpose = useSelector(state => selector(state, `${fieldNamePrefix}.primaryPurpose`));
  const isOwnerOccupied = useMemo(() => purpose === OWNER_OCCUPIED,
    [purpose]);

  const residentialType = useSelector(state =>
    selector(state, `${fieldNamePrefix}.${SUB_TYPES_NAME_MAPPING.Residential}`));
  const isConstructionResidentialType = useMemo(() => isConstruction(residentialType),
    [residentialType]);

  return isOwnedAssetWithConstructionLoan
  ? (
    <OwnedAssetForConstructionLoan
      {...props}
      securityForm={securityForm}
      isOwnerOccupied={isOwnerOccupied}
      isConstructionResidentialType={isConstructionResidentialType}
    />
  )
  : (
    <PurchasingAssetComponent
      {...props}
      securityForm={securityForm}
      selector={selector}
      isOwnerOccupied={isOwnerOccupied}
      isConstructionResidentialType={isConstructionResidentialType}
    />
  );
};

PurchasingAsset.propTypes = {
  editable: PropTypes.bool.isRequired,
  fieldNamePrefix: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default PurchasingAsset;
