import uniqueId from 'lodash/uniqueId';

export const ACTIONS = {
  START_REQUEST: 'REQUEST_IDS:START_REQUEST',
};

export const startRequest = key => ({
  type: ACTIONS.START_REQUEST,
  payload: {
    requestId: uniqueId(),
    key,
  },
});

export const getRequestIdFromAction = ({ payload: { requestId } }) => requestId;

const getRequestId = (state, key) => state[key];

export const ifValidRequestId = (state, key, requestId, func) => {
  if (getRequestId(state, key) === requestId) {
    func();
  }
};

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.START_REQUEST: {
      return {
        ...state,
        [action.payload.key]: action.payload.requestId,
      };
    }
    default:
      return state;
  }
};
