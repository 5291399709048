import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Field, fieldArrayPropTypes } from 'redux-form';
import ReduxFormCheckbox from 'shared/components/formFields/ReduxForm/Checkbox';
import Icon from 'shared/components/Icon/Icon';
import { connect } from 'react-redux';
import styles from './Soma.module.scss';
import { toTimeStrWithSecond } from '../../../../../../../utils/datetime';
import { downloadSoma } from '../../../../redux';

const SOMA_TYPES = {
  APPLICANT: 'Applicant/s',
  GUARANTOR: 'Guarantor',
};

const SomaRow = (props) => {
  const themeContext = useContext(ThemeContext);
  const { fields, hasEditPermission, handleDownloadSoma } = props;
  return (
    <>
      {isEmpty(fields) && <div>No SOMAs have been generated yet</div>}
      {fields.map((field, index) =>
        (
          <div className={styles.somasRow} key={field}>
            <div>{SOMA_TYPES[fields.get(index).type]}</div>
            <div>{toTimeStrWithSecond(fields.get(index).generatedDate)}</div>
            <div className={styles.issued}>
              <Field
                name={`${field}.issued`}
                component={ReduxFormCheckbox}
                disabled={!hasEditPermission}
              />
            </div>
            <div className={styles.signed}>
              <Field
                name={`${field}.signed`}
                component={ReduxFormCheckbox}
                disabled={!hasEditPermission}
              />
            </div>
            <div className={styles.download}>
              <button type="button" className={styles.downloadButton} onClick={() => handleDownloadSoma(fields.get(index).id)}>
                <Icon name="file-pdf" size="23px" color={themeContext.palette.textInformation} />
              </button>
            </div>
          </div>
        ))}
    </>
  );
};

SomaRow.propTypes = {
  ...fieldArrayPropTypes,
  hasEditPermission: PropTypes.bool.isRequired,
  handleDownloadSoma: PropTypes.func.isRequired,
};

export default connect(null, {
  handleDownloadSoma: downloadSoma,
})(SomaRow);
