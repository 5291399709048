import React from 'react';
import PropTypes from 'prop-types';
import RealEstateAsset from '../../shared/RealEstateAsset';
import styles from './OwnedSecurity.module.scss';

const OwnedSecurity = (props) => (
  <div className={styles.container}>
    <RealEstateAsset
      {...props}
      valueLabel="Value"
      hideSecurityToggle
      ownershipRowSpan={4}
      valueEditable={false}
    />
  </div>
);

OwnedSecurity.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
};

export default OwnedSecurity;
