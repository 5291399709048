import React from 'react';
import PropTypes from 'prop-types';
import MiniSpinner from 'shared/components/LoadingSpinner/MiniSpinner';
import spinnerGif from './loading_spinner.gif';

const LoadingSpinner = ({ style, mini }) => (
  mini ? <MiniSpinner />
  : (
    <div className="loading-spinner" style={style}>
      <img src={spinnerGif} alt="Loading..." className="loading-image" style={style.image} />
    </div>
)
);

LoadingSpinner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  mini: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  style: {},
  mini: false,
};

export default LoadingSpinner;
