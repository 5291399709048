import {
  getRequestIdFromAction,
  ifValidRequestId,
  startRequest,
} from 'redux/requestIds';
import types from './constants';
import api from './apis';

const getRetrieveApplicationsStart = () => ({ type: types.RETRIEVE_APPLICATIONS_START });

const getRetrieveApplicationsSuccess = (applications) => ({
  type: types.RETRIEVE_APPLICATIONS_SUCCESS,
  payload: { applications },
});

const getRetrieveApplicationsError = error => ({
  type: types.RETRIEVE_APPLICATIONS_ERROR,
  payload: { error },
});

const retrieveApplications = (businessId, conditions) => async (dispatch, getState) => {
  dispatch(getRetrieveApplicationsStart());
  const startRequestAction = startRequest(types.RETRIEVE_APPLICATIONS_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  try {
    const {
      data: { applicationsSearch },
    } = await api.retrieveApplications(businessId, conditions);
    ifValidRequestId(getState().requestIds, types.RETRIEVE_APPLICATIONS_START, requestId, () => {
      dispatch(getRetrieveApplicationsSuccess(applicationsSearch));
    });
  } catch (error) {
    ifValidRequestId(getState().requestIds, types.RETRIEVE_APPLICATIONS_START, requestId, () => {
      dispatch(getRetrieveApplicationsError(error));
    });
  }
};

const updateApplicationStatus = data => (dispatch) => {
  dispatch({ type: types.SAVE_APPLICATION_STATUS_START });
  return api.updateApplicationTimeline(data).then(() => {
      dispatch({ type: types.SAVE_APPLICATION_STATUS_SUCCESS, payload: data });
      return Promise.resolve();
    }).catch((error) => {
      dispatch({ type: types.SAVE_APPLICATION_STATUS_ERROR });
      return Promise.reject(error);
    });
};

const createApplicationWithClients = ({
                             businessId,
                             adviserId,
                             loanType,
                             applicants,
                           }) => async (dispatch) => {
  dispatch({ type: types.CREATE_APPLICATION_START });
  try {
    const result = await api.createApplication({
      businessId,
      adviserId,
      loanType,
      applicants,
    });

    return await new Promise((resolve) => {
      setTimeout(() => {
        dispatch({
          type: types.CREATE_APPLICATION_SUCCESS,
        });
        resolve(result);
      }, 1000);
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_APPLICATION_ERROR,
    });
    throw error;
  }
};

const changeConditions = conditions => ({
  type: types.CHANGE_CONDITIONS,
  conditions,
});

const cleanConditions = () => ({
  type: types.CLEAN_CONDITIONS,
});

const cleanApplicants = () => ({
  type: types.CLEAN_CLIENTS,
});

const moveApp = (appId, dest) => ({
    type: types.MOVE_APPLICATIONS,
    applicationId: appId,
    dest,
});

const getClientsByBusinessId = businessId => (dispatch, getState) => {
  dispatch({ type: types.GET_CLIENTS_START });
  const startRequestAction = startRequest(types.GET_CLIENTS_START);
  const requestId = getRequestIdFromAction(startRequestAction);
  dispatch(startRequestAction);
  return api.getAllClientsByBusinessId(businessId)
    .then(({ data: { clients } }) => {
      ifValidRequestId(getState().requestIds, types.GET_CLIENTS_START, requestId, () => {
        dispatch({
          type: types.GET_CLIENTS_SUCCESS,
          businessId,
          data: clients,
        });
      });
    })
    .catch((err) => {
      ifValidRequestId(getState().requestIds, types.GET_CLIENTS_START, requestId, () => {
        dispatch({
          type: types.GET_CLIENTS_FAIL,
          businessId,
        });
      });
      return Promise.reject(err);
    });
};

const getApplicationActionTimeMap = applicationId => (dispatch) => {
  dispatch({ type: types.GET_ACTION_TIME_MAP_START });
  return api.getApplicationActionTimeMap(applicationId).then(({ data: { application } }) => {
    dispatch({ type: types.GET_ACTION_TIME_MAP_SUCCESS, application });
    return application;
  });
};

export default {
  updateApplicationStatus,
  retrieveApplications,
  changeConditions,
  cleanConditions,
  moveApp,
  createApplicationWithClients,
  getClientsByBusinessId,
  cleanApplicants,
  getApplicationActionTimeMap,
};
