import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { FETCHING_STATUS } from '../../../../../../constants';

const initialState = FETCHING_STATUS.INIT;

const applicantsSyncStatus = createReducer(initialState,
  {
    [types.SYNC_APPLICANTS_INFO_START]: () => FETCHING_STATUS.START,
    [types.SYNC_APPLICANTS_INFO_SUCCESS]: () => FETCHING_STATUS.SUCCESS,
    [types.SYNC_APPLICANTS_INFO_ERROR]: () => FETCHING_STATUS.ERROR,
  });

export default applicantsSyncStatus;
