import { useSelector } from 'react-redux';
import get from 'lodash/get';

const DOUBLE_SEPARATOR = '::';
const SINGLE_SEPARATOR = ':';
const ALLACCESS = '*';

const filterApplicationPermissions = (permissions, application) =>
  permissions.filter((permission) => {
    const parts = permission.split(SINGLE_SEPARATOR);

    return parts.length && parts[0] === application;
  });

const filterBusinessPermissions = (permissions, businessId) =>
  permissions.filter((permission) => {
    const parts = permission.split(DOUBLE_SEPARATOR);

    if (parts.length !== 2) return false;

    const lastPermissionParts = parts[1].split(SINGLE_SEPARATOR);
    if (lastPermissionParts.length !== 2) return false;

    // TODO: revisit this "business" restriction if necessary
    return lastPermissionParts[0] === 'business' && (lastPermissionParts[1].includes(businessId) || lastPermissionParts[1] === '*');
  });

const filterAreaPermissions = (permissions, area) =>
  permissions.filter((permission) => {
    const parts = permission.split(SINGLE_SEPARATOR);

    if (parts.length < 2) return false;

    return parts[1] === ALLACCESS || parts[1] === area;
  });

const filterActionPermissions = (permissions, permission) =>
  permissions.filter((_permission) => {
    const parts = _permission.split(SINGLE_SEPARATOR);

    if (parts.length < 3) return false;

    return parts[2] === ALLACCESS || parts[2] === permission;
  });

const hasPermissionTo = (permission, permissions, businessId) => {
  if (!permissions || !permissions.length) return false;

  const applicationPermissions = filterApplicationPermissions(permissions, permission.application);
  const businessPermissions = filterBusinessPermissions(applicationPermissions, businessId);
  const areaPermissions = filterAreaPermissions(businessPermissions, permission.area);
  const actionPermissions = filterActionPermissions(areaPermissions, permission.action);

  return !!actionPermissions.length;
};

export const useHasPermission = (permission) => {
  const permissions = useSelector(state => state.profile?.permissions);
  const businessId = useSelector(state => state.business?.selectedBusiness?.id);
  return hasPermissionTo(permission, permissions, businessId);
};

export const useHasPermissions = (permissionsToCheck) => {
  const permissions = useSelector(state => state.profile?.permissions);
  const businessId = useSelector(state => state.business?.selectedBusiness?.id);
  return permissionsToCheck.every(perm => hasPermissionTo(perm, permissions, businessId));
};

export const useHasAnyPermission = (permissionsToCheck) => {
  const permissions = useSelector(state => state.profile?.permissions);
  const businessId = useSelector(state => state.business?.selectedBusiness?.id);
  const isSettled = useSelector(state => get(state, 'application.applicationStatus.isApplicationSettled'));
  return !isSettled && permissionsToCheck.some(perm => hasPermissionTo(perm, permissions, businessId));
};

export default hasPermissionTo;
