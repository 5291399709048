import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

let getDataSource;
export const configureDataSourceForGlobalToggleGetter = getData => {
  getDataSource = getData;
};

const getToggles = () => {
  if (!isFunction(getDataSource)) {
    throw new Error('data source not configured for toggle getter');
  }

  const toggles = get(getDataSource(), 'profile.toggles', null);
  if (isNil(toggles)) {
    throw new Error('toggles not fetched yet');
  }

  return toggles;
};

export default getToggles;
