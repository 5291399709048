import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';
import { MESSAGE_KEYS } from 'constants/message';

export const removeFixedTrails = (
  businessId,
  filters,
  batchSize,
) =>
client.mutate({
  mutation: gql`mutation($businessId: String!, $filter: LoanFilter!, $batchSize: Int!) {
      bulkRemoveFixedTrailDetails(
          body: {
              businessId: $businessId,
              filter: $filter,
              batchSize: $batchSize,
          }
      ){
        loansUpdated
      }
  }`,
  variables: {
    businessId,
    filter: filters,
    batchSize,
  },
}, { messageKey: MESSAGE_KEYS.BULK_REMOVE_FIXED_TRAIL_DETAILS });
