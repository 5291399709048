import React from 'react';
import NumberInput from '../../formFields/NumberInput/index';
import styles from './styles.module.scss';

const MAX_NUM = 1000000000;
const isAllowed = a => (a === undefined || (a > 0 && a < MAX_NUM));

const style = {
  display: 'inline-block',
  margin: '0rem 1.2rem 1rem 0rem',
  padding: 0,
  verticalAlign: 'top',
  width: '19.5rem',
};

const LoanAmountComponent = (props) => (
  <NumberInput
    isAllowed={isAllowed}
    className={styles.label}
    {...props}
    style={style}
    textClassName={styles.inputText}
  />
);

export default LoanAmountComponent;
