import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import round from 'lodash/round';

const NOT_APPLICABLE = 'N/A';
const ANY = 'Any';
const NON_BREAKING_SPACE = '\u00a0';

export const frequencyAbbreviations = {
  PER_MONTH: 'pm',
  PER_WEEK: 'pw',
  PER_QUARTER: 'pq',
  PER_ANNUM: 'pa',
  PER_SIX_MONTHS: 'p6m',
};

const emptyToZero = (intNumber, formatter = x => x) =>
  (isNil(intNumber) ? formatter(0) : formatter(intNumber));

export const emptyToAny = (intNumber, fn = x => x) => (isNil(intNumber) ? ANY : fn(intNumber));

export const emptyToNA = (intNumber, fn = x => x, defaultValue = NOT_APPLICABLE) =>
  (isNil(intNumber) ? defaultValue : fn(intNumber));

/* eslint-disable no-restricted-globals */
export const notANumberToNA = (intNumber, fn = x => x, defaultValue = NOT_APPLICABLE) =>
  (isNaN(intNumber) ? defaultValue : fn(intNumber));

export const notANumberToZero = (intNumber, formatter = x => x) =>
  (isNaN(intNumber) ? formatter(0) : formatter(intNumber));

export const notANumberToDefaultValue = (intNumber, formatter = x => x, defaultValue) =>
  (isNaN(intNumber) ? defaultValue : formatter(intNumber));
/* eslint-enable no-restricted-globals */

const formatIntegerAsInteger = (x, n) => {
  const value = round(x, n);
  return Number.isInteger(value) ? value.toFixed(0) : value.toFixed(n);
};

export const formatBool = bool => (bool ? 'Yes' : 'No');

export const formatStringBool = bool => (bool === 'true' ? 'Yes' : 'No');

export const toPercentage = intNumber => emptyToNA(intNumber, a => notANumberToNA(a, b => `${formatIntegerAsInteger(b, 2)}%`));

export const toRatePercentage = (intNumber, defaultValue) =>
  emptyToNA(intNumber, a => notANumberToNA(a, b => `${round(b, 2).toFixed(2)}%`, defaultValue), defaultValue);

export const toDollarAmount = (intNumber) => notANumberToZero(intNumber, a => {
    const value = round(a || 0, 0)
      .toFixed(0);

    const prefix = value >= 0 ? '$' : '-$';
    return `${prefix}${Math.abs(value)}`.replace(/\d(?=(\d{3})+$)/g, '$&,');
  });

export const toDollarAmountWithDecimal = intNumber =>
  notANumberToZero(intNumber, a => `${a >= 0 ? '$' : '-$'}${round(Math.abs(a) || 0, 2).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);

export const formatOngoingFee = (ongoingFee, frequency) =>
  `${toDollarAmount(ongoingFee)}${NON_BREAKING_SPACE}${frequencyAbbreviations[frequency]}`;

export const formatCostCalculation = (repayment, isAvailable = true) => {
  if (!isAvailable) {
    return NOT_APPLICABLE;
  }
  if (isNil(repayment)) {
    return '$--';
  }
  return toDollarAmount(Math.ceil(repayment));
};

export const formatTotals = repayments =>
  formatCostCalculation(
    repayments.reduce(
      (acc, repayment) => (repayment == null || acc == null ? null : acc + Math.ceil(repayment)),
      0,
    ),
  );

export const formatLoanTerm = term => emptyToNA(term, t => (toString(t) === '1' ? '1 yr' : `${t} yrs`));

export const formatDiscountMonth = month =>
  emptyToZero(month, t => (t === 1 ? '1 month' : `${t} months`));

export const toSentenceCase = sentence =>
  (sentence
    ? sentence.substring(0, 1).toUpperCase() + sentence.substring(1).toLowerCase()
    : sentence);

export const toYYYYMM = (month, year) => {
  if (!month || !year) {
    return undefined;
  }
  return `${String(year)}-${String(month).padStart(2, '0')}`;
};

export const dateFormatter = {
  toDdmmyy: dateString => moment(dateString).format('DD/MM/YY'),
  toDdmmyyyy: dateString => moment(dateString).format('DD/MM/YYYY'),
  monthYear: dateString => moment(dateString).format('MMMM YYYY'),
};

export const withOrdinalSuffix = (i) => {
  if (i === null || i === undefined) return null;

  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i }st`;
  }
  if (j === 2 && k !== 12) {
    return `${i }nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i }rd`;
  }
  return `${i }th`;
};

export const formatYearAndMonth = ({ year, month, fullSuffix = false }) => {
  const yearSuffix = fullSuffix ? 'year' : 'yr';
  const monthSuffix = fullSuffix ? 'month' : 'mth';
  const pluralYears = year > 1;
  const pluralMonths = month > 1;
  const yearStr = year > 0 ? `${year} ${yearSuffix}${pluralYears ? 's' : ''}` : '';
  const monthStr = month > 0 ? `${month} ${monthSuffix}${pluralMonths ? 's' : ''}` : '';
  return [yearStr, monthStr].filter(it => !isEmpty(it)).join(' ');
};
