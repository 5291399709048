import React from 'react';
import PropTypes from 'prop-types';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import MobiusTable from 'shared/components/MobiusTable';
import { dateFormatter } from 'shared/formatterUtils';
import InternalLink from 'shared/components/Links/InternalLink';
import RemoteFilterShape from 'shared/components/MobiusTable/shapes/RemoteFilterShape';
import { FromToFilter } from 'shared/components/MobiusTable/components/FromToFilter/FromToFilter';
import useMediaType from 'shared/hooks/useMediaType';
import { DatePickerFilter } from 'shared/components/MobiusTable/components/DatePickerFilter/DatePickerFilter';
import YesNoFilter from 'shared/components/MobiusTable/components/YesNoFilter/YesNoFilter';
import {
  getDollarAmountOrNoData,
  getValueOrNoData,
  moneyValueRenderer,
  getDateOrNoData,
} from '../../../MyBusiness/components/renderers';
import styles from './styles.module.scss';
import { ROW_HEIGHT } from './constants';
import { MEDIA_TYPES } from '../../../constants/media';
import CategoriesDropdown from '../../../LoanCategories/components/CategoriesDropdown/CategoriesDropdown';
import { categoriesMapperFunc } from './redux/loansRedux';
import LoanStatusFilter from '../LoanStatusFilter/LoanStatusFilter';

export const LoanRow = PropTypes.shape({
  id: PropTypes.string.isRequired,
  applicantsName: PropTypes.string,
  lenderName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  loanType: PropTypes.string,
  adviserName: PropTypes.string.isRequired,
  settledDate: PropTypes.string.isRequired,
  settledAmount: PropTypes.number.isRequired,
  status: PropTypes.string,
  currentBalance: PropTypes.number,
  categories: PropTypes.string,
  categoryIds: PropTypes.string,
  previousOwner: PropTypes.string,
  business: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
});

const FIXED_TRAIL_OPTIONS = {
  true: 'Yes',
};

const Loans = ({
  rows,
  isLoading,
  virtual,
  skip,
  getRemoteRows,
  onRemoteFiltersChange,
  totalRowCount,
  pageSize,
  viewAll,
  canViewCategories,
  dispatchSelectBusiness,
  selectedBusinessId,
  remoteFilters,
  canUpdateFixedTrail,
  handleSelectionChange,
}) => {
  const accountNumberRenderer = (accountNumber, loan) => {
    if (!loan.business || loan.business.id === selectedBusinessId) {
      return (
        <InternalLink to={`/loans/${loan.id}?businessId=${selectedBusinessId}`}>
          {accountNumber}
        </InternalLink>
      );
    }

    return (
      <InternalLink
        to={`/loans/${loan.id}?businessId=${loan.business.id}`}
        onClick={() => dispatchSelectBusiness(loan.business.id)}
      >
        {accountNumber}
      </InternalLink>
    );
  };

  const sticky = useMediaType() !== MEDIA_TYPES.PHONE;

  const categoriesCacheUpdater = actions => (loanId, selectedCategories) => {
    actions.updateRowsCache(cache => ({
      skip: cache.skip,
      rows: cache.rows.map(row =>
        (row.id === loanId
          ? {
              ...row,
              ...categoriesMapperFunc(selectedCategories),
            }
          : row)),
    }));
  };

  const columns = [
    {
      name: 'business.name',
      title: 'BUSINESS',
      sticky,
      hidden: !viewAll,
    },
    {
      name: 'applicantsName',
      title: 'APPLICANT/S',
      sticky,
      allowRemoteFiltering: true,
    },
    {
      name: 'lenderName',
      title: 'LENDER',
      sticky,
      allowRemoteFiltering: true,
    },
    {
      name: 'accountNumber',
      title: 'ACCOUNT NUMBER',
      sticky,
      renderer: accountNumberRenderer,
      width: 200,
      allowRemoteFiltering: true,
      disallowResizing: true,
    },
    {
      name: 'loanType',
      title: 'LOAN TYPE',
      renderer: getValueOrNoData,
    },
    {
      name: 'adviserName',
      title: 'ADVISER',
      allowRemoteFiltering: true,
    },
    {
      name: 'status',
      title: 'LOAN STATUS',
      renderer: getValueOrNoData,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <LoanStatusFilter filter={filter ? filter.value : []} onFilter={onFilter} disabled={allSelected} />
      ),
    },
    {
      name: 'settledDate',
      title: (
        <span>
          SETTLEMENT
          <br />
          DATE
        </span>
      ),
      renderer: value => dateFormatter.toDdmmyy(value),
      width: 125,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <FromToFilter filter={filter} onFilter={onFilter} disabled={allSelected} />
        ),
    },
    {
      name: 'settledAmount',
      title: (
        <span>
          SETTLED
          <br />
          AMOUNT
        </span>
      ),
      align: 'right',
      width: 125,
      renderer: moneyValueRenderer,
    },
    {
      name: 'currentBalance',
      title: (
        <span>
          LOAN
          <br />
          BALANCE
        </span>
      ),
      align: 'right',
      width: 125,
      renderer: getDollarAmountOrNoData,
    },
    {
      name: 'categories',
      title: 'CATEGORIES',
      align: 'right',
      width: 200,
      hidden: !canViewCategories || viewAll,
      classNames: {
        filterCell: styles.categoriesFilterCell,
        cell: styles.categoriesCell,
      },
      // eslint-disable-next-line react/prop-types
      renderer: (value, { categories, categoryIds, id }, col, actions) => (
        <CategoriesDropdown
          popoverClassName={styles.categoriesPopover}
          selectedCategoryIds={categoryIds}
          value={categories}
          loanId={id}
          onUpdated={categoriesCacheUpdater(actions)}
        />
      ),
    },
    {
      name: 'previousOwner',
      title: (
        <span>
          PREVIOUS
          <br />
          OWNER
        </span>
      ),
      renderer: getValueOrNoData,
    },
    {
      name: 'purchaseDate',
      title: (
        <span>
          PURCHASE
          <br />
          DATE
        </span>
      ),
      hidden: false,
      renderer: getDateOrNoData,
      width: 125,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <DatePickerFilter onFilter={onFilter} disabled={allSelected} />
      ),
    },
    {
      name: 'fixedTrailPercentage',
      title: 'FIXED TRAIL',
      renderer: value => value && 'Yes',
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <YesNoFilter
          filter={filter ? filter.value : []}
          onFilter={onFilter}
          options={FIXED_TRAIL_OPTIONS}
          disabled={allSelected}
        />
      ),
      hidden: !canUpdateFixedTrail || viewAll,
    },
    {
      name: 'fixedTrailStartDate',
      title: (
        <span>
          FIXED TRAIL
          <br />
          START DATE
        </span>
      ),
      renderer: value => value && dateFormatter.toDdmmyy(value),
      width: 125,
      allowRemoteFiltering: true,
      filterCellRenderer: (filter, onFilter, allSelected) => (
        <FromToFilter filter={filter} onFilter={onFilter} disabled={allSelected} />
      ),
      hidden: !canUpdateFixedTrail || viewAll,
    },
  ];

  return (
    <MobiusTable
      resizingEnabled
      rows={rows}
      columns={columns}
      withSelection={canUpdateFixedTrail}
      showSelectAll={canUpdateFixedTrail}
      onSelectionChange={handleSelectionChange}
      isLoading={isLoading}
      virtual={virtual}
      classNames={{ row: styles.loansSummaryMobiusTableRow }}
      className={styles.loansSummaryMobiusTable}
      withRemote
      pageSize={pageSize}
      skip={skip}
      getRemoteRows={getRemoteRows}
      onRemoteFiltersChange={onRemoteFiltersChange}
      remoteFilters={remoteFilters}
      disableSelection
      totalRowCount={totalRowCount}
      estimatedRowHeight={ROW_HEIGHT}
    />
  );
};

Loans.propTypes = {
  rows: PropTypes.arrayOf(LoanRow),
  isLoading: PropTypes.bool,
  virtual: PropTypes.bool,
  skip: PropTypes.number,
  getRemoteRows: PropTypes.func.isRequired,
  onRemoteFiltersChange: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  viewAll: PropTypes.bool.isRequired,
  canViewCategories: PropTypes.bool.isRequired,
  dispatchSelectBusiness: PropTypes.func.isRequired,
  selectedBusinessId: PropTypes.string,
  remoteFilters: PropTypes.arrayOf(RemoteFilterShape).isRequired,
  canUpdateFixedTrail: PropTypes.bool.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
};

Loans.defaultProps = {
  skip: null,
  totalRowCount: null,
  rows: [],
  isLoading: false,
  virtual: true,
  selectedBusinessId: null,
};

export default Loans;
