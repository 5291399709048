import React from 'react';
import {
  baseIcon,
  iconDefaultProps,
} from './baseIcon';

const SvgIcon = ({
 color, width, height, alt,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{alt}</title>

    <g
      id="Icon/arrowUp-solid"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Path-Copy-3"
        fill={color}
        transform="translate(12.333333, 12.000000) rotate(-90.000000) translate(-12.333333, -12.000000) "
        points="15.3333333 12 9.33333333 18 9.33333333 6"
      />
    </g>
  </svg>
);

SvgIcon.defaultProps = iconDefaultProps;
SvgIcon.propTypes = baseIcon;

export default SvgIcon;
