import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const getLoans = ({
 businessId, skip, take, filter,
}) => {
  const queryOptions = {
    query: gql`
      query loansSummary($businessId: String!, $skip: Int!, $take: Int!, $filter: LoanFilter) {
        loansSummary(businessId: $businessId skip: $skip take: $take filter: $filter) {
          loans {
            id
            applicantsName
            lenderName
            accountNumber
            loanType
            settledDate
            settledAmount
            status
            previousOwner
            adviserName
            currentBalance
            categories
            categoryIds
            business {
              name
              id
            }
            purchaseDate
            fixedTrailPercentage
            fixedTrailStartDate
          }
          totalCount
        }
      }
    `,
    variables: {
      businessId, skip, take, filter,
    },
  };

  return client.query(queryOptions);
};

export default {
  getLoans,
};
