import React from 'react';
import PropTypes from 'prop-types';
import SectionWrapper from 'shared/components/SectionWrapper/SectionWrapper';
import Referrer from 'shared/components/LoanDetails/Referrer';
import { connect } from 'react-redux';
import get from 'lodash/get';
import stylePropType from 'react-style-proptype';
import Applicants from './Applicants';

const Contacts = ({
   applicants, referrer, isEditing, style,
  }) => (
    <div style={style}>
      <SectionWrapper title="Applicant details">
        <Applicants applicants={applicants} />
      </SectionWrapper>
      <SectionWrapper title="Referrer">
        <Referrer referrer={referrer} isEditing={isEditing} />
      </SectionWrapper>
    </div>
);

Contacts.defaultProps = { style: {} };
Contacts.propTypes = {
  style: stylePropType,
  applicants: PropTypes.shape({
    people: PropTypes.arrayOf(PropTypes.shape({})),
    companies: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  referrer: PropTypes.shape({
    isExistingClient: PropTypes.bool.isRequired,
    referralMainCategory: PropTypes.string,
    referralSubCategory: PropTypes.string,
    referralSource: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  applicants: get(state, 'loans.loan.data.applicants'),
  referrer: get(state, 'loans.loan.data.referrer'),
  isEditing: get(state, 'loans.loan.isEditing', false),
});

export default connect(mapStateToProps)(Contacts);
