import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import TextArea from 'shared/components/formFields/TextArea';
import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';

const somaCommentsSelector = state => get(state, 'application.applicationDetail.fundingWorksheet.somaComments') || '';
const ViewSOMAComments = ({ className }) => {
  const somaComments = useSelector(somaCommentsSelector);
  return (
    <div className={className}>
      <h3>Funding worksheet SOMA comments</h3>
      <TextArea
        disabled
        className={styles.somaComments}
        value={somaComments}
        placeholder="This text will be displayed in the SOMA. Please include explanations for a surplus or shortfall if relevant."
      />
    </div>
  );
};

ViewSOMAComments.propTypes = {
  className: PropTypes.string,
};

ViewSOMAComments.defaultProps = {
  className: '',
};

export default ViewSOMAComments;
