import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import fonts from 'shared/theme/fonts.scss';
import { closeErrorBanner } from 'redux/banner';

const InternalLink = ({
  children,
  to,
  style,
  dispatchCloseErrorBanner,
  className,
  onClick,
}) => {
  const themeContext = useContext(ThemeContext);
  const onLinkClick = () => {
    onClick();
    dispatchCloseErrorBanner();
  };
  return (
    <Link
      style={{
        fontFamily: fonts.fontbody,
        textDecoration: 'none',
        color: themeContext.palette.linkPrimary,
        verticalAlign: 'middle',
        ...style,
      }}
      to={to}
      onClick={onLinkClick}
      className={className}
    >
      {children}
    </Link>
  );
};

InternalLink.defaultProps = {
  children: null,
  style: {},
  to: null,
  className: undefined,
  dispatchCloseErrorBanner: () => {},
  onClick: () => {},
};

InternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.shape(),
  dispatchCloseErrorBanner: PropTypes.func,
  onClick: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseErrorBanner: () => dispatch(closeErrorBanner()),
});

export default connect(null, mapDispatchToProps)(InternalLink);
