import isEmpty from 'lodash/isEmpty';
import nth from 'lodash/nth';
import get from 'lodash/get';
import { toTimeStr } from 'utils/datetime';
import { findTargetChange, getTargetChangedValue } from '../../../../../../constants/utils';
import { essentialDateDisplayStatus } from '../../../../../../constants/applicationStatus';
import { getBasicLogInfo } from './utils';

const getStatusFromChange = change => nth(get(change, 'path', '').split('.'), -2);

const essentialDateChangeLogTransformer = (origin) => {
  const dateTimePath = 'applicationStatus.actionTimelineMap.*statusDateTime';
  const targetChange = findTargetChange(origin, dateTimePath);
  const status = getStatusFromChange(targetChange);
  const statusDateTime = getTargetChangedValue(origin, dateTimePath);
  const resultText = isEmpty(statusDateTime) ? 'deleted' : `updated to ${toTimeStr(statusDateTime)}`;

  return {
    ...getBasicLogInfo(origin),
    content: `${essentialDateDisplayStatus[status]} date ${resultText}`,
  };
};

export default essentialDateChangeLogTransformer;
