import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styles from './Dashboard.module.scss';
import MyTask from '../../Dashboard/components/MyTask';
import usePageViewTracking from '../usePageViewTracking';
import MyActivityLog from '../../Dashboard/components/MyActivityLog';

const Dashboard = ({ businessId, userFullName }) => {
  usePageViewTracking('dashboard');

  return (
    <div className={styles.dashboard}>
      <div className={styles.welcome}>
        <h1>Welcome back {userFullName.split(' ', 1)}!</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.logContainer}>
          <h2>Latest application updates</h2>
          <MyActivityLog businessId={businessId} />
        </div>
        <div className={styles.taskContainer}>
          <h2>My Tasks</h2>
          <MyTask businessId={businessId} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  businessId: get(state, 'business.selectedBusiness.id'),
  userFullName: get(state, 'profile.username'),
});

Dashboard.propTypes = {
  businessId: PropTypes.string,
  userFullName: PropTypes.string.isRequired,
};

Dashboard.defaultProps = {
  businessId: undefined,
};

export default connect(mapStateToProps)(Dashboard);
