import { EditField, USER_PERMISSIONS } from 'Loans/components/LoanDetails/EditField';
import { getAdviserName } from 'Loans/utils';
import { FormsyAdviserSelector } from 'shared/components/AdviserSelector/FormsyAdviserSelector';
import PropTypes from 'prop-types';
import React from 'react';
import AdviserShape from 'shared/components/AdviserSelector/AdviserShape';

export const AdviserField = ({ adviser, businessId, reconciled }) => (
  <EditField
    permissions={USER_PERMISSIONS.ADVISER_AND_GROUP_OFFICE}
    fallback={getAdviserName(adviser)}
  >
    {
      reconciled
        ? (
          <EditField
            permissions={USER_PERMISSIONS.GROUP_OFFICE}
            fallback={<FormsyAdviserSelector businessId={businessId} selectedAdviser={adviser} disabled />}
          >
            <FormsyAdviserSelector businessId={businessId} selectedAdviser={adviser} />
          </EditField>
        )
        : <FormsyAdviserSelector businessId={businessId} selectedAdviser={adviser} />
    }
  </EditField>
);

AdviserField.propTypes = {
  adviser: AdviserShape.isRequired,
  businessId: PropTypes.string.isRequired,
  reconciled: PropTypes.bool.isRequired,
};
