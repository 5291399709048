import React, {
  useMemo,
} from 'react';
import get from 'lodash/get';
import concat from 'lodash/concat';
import PropTypes from 'prop-types';
import ResponsiveTable from 'shared/components/ResponsiveTable/ResponsiveTable';
import {
  getHeadersWithSurvey,
  getRowPresenterWithSurvey,
} from 'shared/helpers/applicantsTableHelper';

const Applicants = ({
  applicants,
  hasEditPermission,
  isRecentlySentSurvey,
}) => {
  const personalApplicants = get(applicants, 'personApplicants', []);
  const companyApplicants = get(applicants, 'companyApplicants', []);
  const personGuarantors = get(applicants, 'personGuarantors', []);
  const companyGuarantors = get(applicants, 'companyGuarantors', []);
  const rows = useMemo(() => {
      const personalApplicantRows = personalApplicants.map(applicant => getRowPresenterWithSurvey(
        applicant,
        isRecentlySentSurvey || !hasEditPermission,
        !hasEditPermission,
      ));
      const companyApplicantRows = companyApplicants.map(applicant => getRowPresenterWithSurvey(
        applicant,
        true,
        true,
        true,
      ));
      const personGuarantorRows = personGuarantors.map(applicant => getRowPresenterWithSurvey(
        applicant,
        isRecentlySentSurvey || !hasEditPermission,
        !hasEditPermission,
      ));
      const companyGuarantorRows = companyGuarantors.map(applicant => getRowPresenterWithSurvey(
        applicant,
        true,
        true,
        true,
      ));

      return concat(personalApplicantRows, companyApplicantRows, personGuarantorRows, companyGuarantorRows);
    },
    [
      personalApplicants,
      companyApplicants,
      personGuarantors,
      companyGuarantors,
      hasEditPermission,
      isRecentlySentSurvey,
    ]);

  return (
    <ResponsiveTable
      header={
      getHeadersWithSurvey()
    }
      rows={
      rows
    }
    />
  );
};

Applicants.propTypes = {
  applicants: PropTypes.shape({
    persons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      isPrimary: PropTypes.bool,
      isSurveyContact: PropTypes.bool,
      isMainContact: PropTypes.bool,
      email: PropTypes.string,
      home: PropTypes.shape({
        number: PropTypes.string,
        isPreferred: PropTypes.bool,
      }),
      mobile: PropTypes.shape({
        number: PropTypes.string,
        isPreferred: PropTypes.bool,
      }),
      work: PropTypes.shape({
        number: PropTypes.string,
        isPreferred: PropTypes.bool,
      }),
      contactDetails: PropTypes.shape({
        emailAddress: PropTypes.string,
        mobile: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        preferredContactMethod: PropTypes.string,
      }),
    })),
    companies: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      isPrimary: PropTypes.bool,
    })),
  }).isRequired,
  hasEditPermission: PropTypes.bool.isRequired,
  isRecentlySentSurvey: PropTypes.bool.isRequired,
};
export default Applicants;
