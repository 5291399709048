import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExternalLink from 'shared/components/Links/ExternalLink';
import { FONTS, PADDING } from 'shared/theme/depricatedStyles';
import { trackSubmitFeedback } from 'redux/tracking';
import Icon from 'shared/components/Icon/Icon';

const styles = {
  feedbackLink: {
    fontFamily: FONTS.title,
    padding: `0 ${PADDING.medium}`,
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  speechBubble: {
    padding: `${PADDING.xxsmall} ${PADDING.small}`,
  },
};

const SubmitFeedbackButton = ({ dispatchTrackSubmitFeedback }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <ExternalLink
      style={styles.feedbackLink}
      to="https://smartline.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000028395"
      onClick={dispatchTrackSubmitFeedback}
    >
      <div style={styles.speechBubble}>
        <Icon name="speech-bubble" size="14px" color={themeContext.palette.textInformation} />
      </div>
      Give us feedback
    </ExternalLink>
  );
};

SubmitFeedbackButton.propTypes = {
  dispatchTrackSubmitFeedback: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchTrackSubmitFeedback: () => dispatch(trackSubmitFeedback()),
});

export default connect(null, mapDispatchToProps)(SubmitFeedbackButton);
