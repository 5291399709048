import React from 'react';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import styles from '../styles.module.scss';

export const EmptyCostToPurchases = () => (
  <div className={styles.emptyCostToPurchase}>
    <InformationSection>
      <div className={styles.emptyMessage}>
        No properties to purchase have been saved.
        Please go to Security tab to add asset to purchase details.
      </div>
    </InformationSection>
  </div>
);
