import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import styles from './CircleNumber.module.scss';

const CircleNumber = ({
 number, className,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <span
      className={`${styles.circleNumber} ${className} circleNumber`}
      style={{ backgroundColor: themeContext.palette.backgroundBrandStatic }}
    >
      <span className={styles.number} style={{ color: themeContext.palette.textInvertedStatic }}>
        {number > 99 ? '99+' : number}
      </span>
    </span>
  );
};

CircleNumber.propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CircleNumber.defaultProps = {
  className: '',
};

export default CircleNumber;
