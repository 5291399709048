export const COMPARE_PRODUCTS = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:COMPARE_PRODUCTS';
export const UPDATE_COMMENT = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_COMMENT';
export const INIT_COMPARISON = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:INIT_COMPARISON';
export const UPDATE_PREFERRED_OPTION = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_PREFERRED_OPTION';
export const UPDATE_SELECTED_FEATURE_FROM_COMPARISON = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_SELECTED_FEATURE_FROM_COMPARISON';
export const UPDATE_SELECTED_FEATURES_FROM_COMPARISON = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_SELECTED_FEATURES_FROM_COMPARISON';
export const TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:TOGGLE_INCLUDE_COMMENTS_IN_PRINTOUT';
export const APPLY_VARIANT_MODIFICATION = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:APPLY_VARIANT_MODIFICATION';
export const GET_VARIANTS_DETAILS_START = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:GET_VARIANTS_DETAILS_START';
export const GET_VARIANTS_DETAILS_SUCCESS = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:GET_VARIANTS_DETAILS_SUCCESS';
export const GET_VARIANTS_DETAILS_FAIL = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:GET_VARIANTS_DETAILS_FAIL';
export const UPDATE_PRODUCT_COMPARISON_SUCCESS = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_PRODUCT_COMPARISON_SUCCESS';
export const UPDATE_PRODUCT_COMPARISON_START = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_PRODUCT_COMPARISON_START';
export const UPDATE_PRODUCT_COMPARISON_ERROR = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_PRODUCT_COMPARISON_ERROR';
export const UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT';
export const MOCK_FEATURE_DETAILS_FOR_CUSTOM_VARIANTS = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:MOCK_FEATURE_DETAILS_FOR_CUSTOM_VARIANTS';
export const RESET_COMPARISON = 'EMBEDDED_PRODUCT_COMPARISON_PAGE:RESET_COMPARISON';
