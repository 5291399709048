export const ACTIONS = {
  RESET: 'ALL_APPLICATIONS:RESET',
  GET_ALL_APPLICATIONS_INIT: 'ALL_APPLICATIONS:GET_ALL_APPLICATIONS_INIT',
  GET_ALL_APPLICATIONS_START: 'ALL_APPLICATIONS:GET_ALL_APPLICATIONS_START',
  GET_ALL_APPLICATIONS_SUCCESS: 'ALL_APPLICATIONS:GET_ALL_APPLICATIONS_SUCCESS',
  GET_ALL_APPLICATIONS_ERRORS: 'ALL_APPLICATIONS:GET_ALL_APPLICATIONS_ERRORS',
  DOWNLOAD_ALL_APPLICATIONS_START: 'ALL_APPLICATIONS:DOWNLOAD_ALL_APPLICATIONS_START',
  DOWNLOAD_ALL_APPLICATIONS_SUCCESS: 'ALL_APPLICATIONS:DOWNLOAD_ALL_APPLICATIONS_SUCCESS',
  DOWNLOAD_ALL_APPLICATIONS_ERRORS: 'ALL_APPLICATIONS:DOWNLOAD_ALL_APPLICATIONS_ERRORS',
  TOGGLE_VIEW_ALL: 'ALL_APPLICATIONS:TOGGLE_VIEW_ALL',
  INIT_QUERY: 'ALL_APPLICATIONS:INIT_QUERY',
  UPDATE_REMOTE_FILTERS: 'ALL_APPLICATIONS:UPDATE_REMOTE_FILTERS',
};
