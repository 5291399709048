export const fireWhenReady = (fire) => {
  if (typeof (window.utag) === 'object' && typeof (window.utag.view) === 'function') {
    fire();
  } else {
    setTimeout(() => {
      fireWhenReady(fire);
    }, 500);
  }
};

export const trackPageView = (data) => {
  fireWhenReady(() => {
    window.utag.view(data);
  });
};

export const trackCustomEvent = (data) => {
  fireWhenReady(() => {
    window.utag.link(data);
  });
};
