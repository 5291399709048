import React from 'react';
import PropTypes from 'prop-types';
import { withFormMeta } from '../../FormMeta';

const RadioButton = ({
 style, className, label, inputStyle, readOnly, disabled, ...others
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    style={style}
    className={className}
  >
    <input
      type="radio"
      style={inputStyle}
      {...others}
      disabled={disabled || readOnly}
    />
    {label}
  </label>
);

RadioButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.any,
  inputStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  style: undefined,
  className: undefined,
  label: undefined,
  inputStyle: undefined,
  readOnly: false,
  disabled: false,
};

export default withFormMeta(RadioButton);
