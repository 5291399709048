import { toFirstCapitalized } from 'utils/formatters';
import * as GLOSSARY from '../glossary/ServiceabilityGlossary';

const SKIP_ALL = '^((?!SKIP_ALL).)*$';

export default context => [{
  title: 'Loan criteria',
  descriptors: [{
    path: 'loanCriteriaList',
    title: index => `Loan criteria (${index + 1})`,
    glossary: GLOSSARY.LOAN_CRITERIA,
  }],
}, {
  title: 'Application',
  descriptors: [{
    title: errorMessage => `${toFirstCapitalized(errorMessage)}`,
    path: 'application.postcodeValidation',
  }],
}, {
  title: 'Applicants',
  descriptors: [{
    path: 'application.applicants.persons',
    title: context.getApplicantName,
    getInfo: context.getLinkOfApplicant,
    glossary: GLOSSARY.APPLICANT,
    skips: ['employments', 'contactDetails', 'maritalStatus'],
    validate(index, entity) {
      const keys = Object.keys(entity || {});
      return keys.some(key => key !== 'employments');
    },
    descriptors: [{
      path: 'maritalStatus',
      title: 'Marital status',
    }],
  }],
}, {
  title: 'Income and employment',
  descriptors: [{
    path: 'application.applicants.persons',
    title: context.getApplicantName,
    glossary: GLOSSARY.EMPLOYMENT,
    skips: [SKIP_ALL],
    validate(index, entity) {
      const keys = Object.keys(entity || {});
      return keys.some(key => key === 'employments');
    },
    descriptors: [{
      path: 'employments',
      title: context.getEmploymentType,
      skips: ['employer', 'employmentIncomes'],
      descriptors: [{
        path: 'employer',
        title: 'Employer',
      }, {
        path: 'employmentIncomes',
        title: context.getEmploymentIncomeType,
      },
      ],
    }],
  }, {
    path: 'application.finance.incomes',
    title: context.getAdditionalIncomeType,
    glossary: GLOSSARY.ADDITIONAL_INCOME,
  }],
}, {
  title: 'Assets',
  descriptors: [{
    path: 'application.finance.assets.realEstates',
    title: context.getRealEstateType,
    glossary: GLOSSARY.REAL_ESTATE,
    validate: context.isRealEstate,
    skips: ['value'],
    descriptors: [{
      validate: context.isConstructionAsset,
      path: 'value',
      title: 'Value (TOC)',
    },
      {
        validate: context.isNonConstructionAsset,
        path: 'value',
        title: 'Value',
      },
    ],
  }, {
    path: 'application.finance.assets.nonRealEstates',
    title: context.getNonRealEstateType,
    glossary: GLOSSARY.NON_REAL_ESTATE,
  }],
}, {
  title: 'Liabilities',
  descriptors: [{
    path: 'application.finance.liabilities',
    title: context.getLiabilityType,
    glossary: GLOSSARY.LIABILITY,
  }],
}, {
  title: 'Expenses',
  descriptors: [{
    path: 'application.finance.expenseInfo.householdExpenses',
    title: index => `Household (${index + 1})`,
    skips: ['expenses'],
    descriptors: [
      {
        path: 'expenses',
        title: (...args) => `Expense (${args[1] + 1})`,
        glossary: GLOSSARY.EXPENSE,
      },
    ],
  },
    {
      path: 'application.households',
      title: 'Household',
    }],
}, {
  title: 'Security',
  descriptors: [{
    path: 'application.finance.assets.realEstates',
    title: index => context.getSecurityTitle(index),
    glossary: GLOSSARY.SECURITY,
    validate: context.isToPurchaseOrChangesToAsset,
    skips: ['value'],
    descriptors: [{
      validate: context.isConstructionAsset,
      path: 'value',
      title: 'Value (TOC)',
    },
      {
        validate: context.isNonConstructionAsset,
        path: 'value',
        title: 'Purchase price',
      },
    ],
  }],
}];
