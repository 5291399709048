import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';
import React from 'react';
import { filterFormsyProps } from 'shared/components/formFields/Formsy/utils';
import NumberInput from 'shared/components/formFields/NumberInput';

const NewNumberInput = ({
 setValue, value, disabled, isFormDisabled, errorMessage, showRequired, ...otherProps
}) => {
  const requiredMessage = showRequired ? 'This field is required' : undefined;
  return (
    <NumberInput
      {...filterFormsyProps(otherProps)}
      onChange={(v) => {
        setValue(v === undefined ? null : v);
      }}
      disabled={isFormDisabled || disabled}
      value={value}
      errorMessage={errorMessage || requiredMessage}
    />
  );
};

NewNumberInput.defaultProps = {
  disabled: false,
  errorMessage: undefined,
  value: undefined,
};

NewNumberInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number,
  errorMessage: PropTypes.string,
  isFormDisabled: PropTypes.bool.isRequired,
  showRequired: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default withFormsy(NewNumberInput);
