const slGrey = '#ECECEC';
const slLightGrey = '#F4F4F4';
const blueLight = '#d5e2f6';
const mineShaft = '#323232';
const brownGrey = '#9B9B9B';
const white = '#FFFFFF';
const brick = '#AA2020';
const mistyRose = '#FFE0E0';
const blueExtraLight = '#f0f5fc';

const PALETTE = {
  white,
  mineShaft,
  primaryBackground: blueExtraLight,
  primaryText: white,
  border: slGrey,
  error: brick,
  errorBackground: mistyRose,
  normalRow: white,
  otherRow: white,
  multiValue: blueLight,
  inputBorder: brownGrey,
  disabledText: brownGrey,
  comparisonTable: {
    darkBackground: mineShaft,
    greyBackground: brownGrey,
    groupNameRow: slGrey,
    rowDark: slGrey,
    rowGrey: slLightGrey,
    rowWhite: white,
  },
};

export default PALETTE;
