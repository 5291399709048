import { gql } from '@apollo/client';
import { client } from 'shared/api/GraphQL';

const getCommissions = (businessId, financialYear) =>
  client.query({
    query: gql`
      query getComissions($businessId: String!, $financialYear: String!) {
        commissions(criteria: {
          businessId: $businessId
          financialYear: $financialYear
        })
        {
          id
          approvalNumber
          approvedAmount
          financialYear
          settlementDate
          client
          lender
          trailPercentage
          upfrontPercentage
          balances
          upfrontAmounts
          upfrontLoanAmounts
          trailAmounts
          loanType
          loanUniqueKey
          adviser
          externalLink
          categories
        }
      }`,
    variables: {
      businessId, financialYear,
    },
  });

const getInsuranceCommissions = (businessId, financialYear) =>
  client.query({
    query: gql`{
    insuranceCommissions(criteria: {
      businessId: "${businessId}"
      financialYear: "${financialYear}"
    })
    {
      id
      client
      insurer
      financialYear
      policyNumber
      adviser
      percentage
      amounts
    }
  }`,
  });

const getFinancialYears = businessId =>
  client.query({
    query: gql`{
      financialYears(
        businessId: "${businessId}"
      )
      {
        year
      }
    }`,
  });

export default {
  getCommissions,
  getFinancialYears,
  getInsuranceCommissions,
};
