import get from 'lodash/get';
import find from 'lodash/find';
import React from 'react';
import { getTargetChangedValue, isPropertyChanged } from '../../../../../../constants/utils';
import { getBasicLogInfo } from './utils';

const updateSurveyContactInfoLogTransformer = (origin, context) => {
  const persons = get(context, 'applicants.persons', []);
  const newSurveyContactId = isPropertyChanged(origin, 'surveyContact')
    ? getTargetChangedValue(origin, 'applicants.person.surveyContact')
    : '';
  const newSurveyContactName = get(find(persons, { id: newSurveyContactId }), 'name', '');
  return ({
    ...getBasicLogInfo(origin),
    content: (
      <span>
        Survey contact has been changed to &nbsp;
        <span style={{ fontFamily: 'Museo-Sans-700' }}>
          {newSurveyContactName}
        </span>
      </span>),
  });
};

export default updateSurveyContactInfoLogTransformer;
