import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
 change, Field, Fields, formValueSelector,
} from 'redux-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import find from 'lodash/find';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormNumberInput from 'shared/components/formFields/ReduxForm/NumberInput';
import ReduxFromTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import ReduxFormAutocomplete from 'shared/components/formFields/ReduxForm/Autocomplete';
import NumberInputWithReadonly from 'shared/components/formFields/NumberInput/WithReadOnlyNumberInput';
import ReduxFormYesNoRadioGroup from 'shared/components/formFields/ReduxForm/YesNoRadioGroup';
import TertiaryButton from 'shared/components/Buttons/TertiaryButton';
import { getDisplayAddress } from 'ApplicationTracking/utils/address';
import ReduxFormCheckboxDropdown from 'shared/components/formFields/ReduxForm/CheckboxDropdown';
import useToggleCheck from 'shared/hooks/useToggleCheck';
import TOGGLES from 'shared/toggles';
import styles from './Liability.module.scss';
import {
  clearingStatuses,
  clearingStatusWithTopUpOptions,
  clearingStatusWithoutTopUpOptions,
  EMPTY_CHARACTER,
  frequencyOptions,
  interestTypeOptions,
  liabilityTypes,
  repaymentTypeOptions,
  typeOptions,
} from './constants';
import Ownerships from '../shared/Ownerships';
import { formatAmount } from '../../../../../utils/formatters';
import { TRANSACTION_TYPES } from '../../redux/constants';
import { findAssetTypeLabelByValue } from '../../../../constants/assetsType';
import { getAllApplicants } from '../../redux/selectors';
import ExistingLoanSelector from './ExistingLoanSelector';
import ExistingLoanSelect from './ExistingLoanSelect';
import { LOAN_TYPES } from '../../../../../constants';

const selector = formValueSelector('liabilitiesForm');

const clearFieldsMap = {
  clearingStatusFields: [
    'remainingTermMonths', 'interestRatePercent', 'repayments.value', 'repayments.periodUnit', 'clearingAmount',
  ],
  loanFields: [
    'accountDetails.bsb',
  ],
  cardFields: [
    'accountDetails.accountNumber', 'accountDetails.accountName', 'originalTermYears', 'paymentType', 'interestType',
  ],
  mortgageLoanFields: [
    'taxDeductible',
  ],
  otherTypeFields: [
    'description',
  ],
  clearingAmount: [
    'clearingAmount',
  ],
  liabilityTypeFields: [
    'clearingStatus',
  ],
  existingLoanFields: [
    'existingLoan',
  ],
};

const bsbValidate = value => (!!value && value.toString().length < 6 ? 'BSB should be 6 digits' : undefined);

const isAllowedNumber = value => value === undefined || /^\d*$/.test(value);

const OriginalTermField = field => {
  const suffix = field.input.value === 1 ? ' year' : ' years';
  return (
    <NumberInputWithReadonly
      label="Original term"
      className={styles.originalTermField}
      allowNegative={false}
      placeholder="Years"
      suffix={suffix}
      isAllowed={number => number === undefined || (number < 1000 && number >= 0)}
      readOnlyFormatter={value => isNumber(value) && (value === 1 ? `${value} year` : `${value} years`)}
      decimalScale={0}
      value={field.input.value}
      onChange={value => field.input.onChange(value || null)}
    />
  );
};

const RemainingTermFields = field => {
  const months = field.input.value;
  const monthsNumber = months && months % 12;
  const yearsNumber = months && (months - monthsNumber) / 12;
  const [yearsSuffix, monthsSuffix] = [
    yearsNumber === 1 ? ' year' : ' years',
    monthsNumber === 1 ? ' month' : ' months',
  ];
  return (
    <>
      <NumberInputWithReadonly
        className={classNames(
          styles.remainingTermYearField,
          {
            [styles.remainingTermDense]:
            field.liabilityType === undefined
            || field.liabilityType === liabilityTypes.CreditCard
            || field.liabilityType === liabilityTypes.StoreCard,
          },
        )}
        value={yearsNumber}
        placeholder="Years"
        label="Remaining term"
        suffix={yearsSuffix}
        isAllowed={number => number === undefined || (number < 1000 && number >= 0)}
        readOnlyFormatter={value => isNumber(value) && (value === 1 ? `${value} year` : `${value} years`)}
        allowNegative={false}
        decimalScale={0}
        onManualChange={event => {
          const { value } = event.target;
          if (value) {
            const yearsValue = Number(value.split(yearsSuffix)[0]);
            field.input.onChange(yearsValue * 12 + monthsNumber || 0);
          }
        }}
      />
      <NumberInputWithReadonly
        className={classNames(
          styles.remainingTermMonthField,
          {
            [styles.remainingTermDense]:
            field.liabilityType === undefined
            || field.liabilityType === liabilityTypes.CreditCard
            || field.liabilityType === liabilityTypes.StoreCard,
          },
        )}
        value={monthsNumber}
        placeholder="Months"
        label={EMPTY_CHARACTER}
        suffix={monthsSuffix}
        isAllowed={number => number === undefined || (number < 12 && number >= 0)}
        readOnlyFormatter={value => isNumber(value) && (value === 1 ? `${value} month` : `${value} months`)}
        allowNegative={false}
        decimalScale={0}
        onManualChange={event => {
          const { value } = event.target;
          if (value) {
            const monthsValue = Number(value.split(monthsSuffix)[0]);
            field.input.onChange(monthsValue + (yearsNumber || 0) * 12);
          }
        }}
      />
    </>
  );
};

const renderSecuredByCheckBoxDropDown = (field) => {
  const selectedOptionIds = map(field.input.value, 'relatedAssetId');
  return (
    <ReduxFormCheckboxDropdown
      {...field}
      onChange={(values) => {
        field.input.onChange(values?.map((id) => ({
          relatedAssetId: id,
        })) ?? []);
      }}
      selectedOptionIds={selectedOptionIds}
    />
  );
};

const buildSecuredByOptionName = (addressOrType, value) => {
  const optionAddressOrType = isEmpty(addressOrType) ? 'N/A' : addressOrType;
  return (isEmpty(value) ? optionAddressOrType : `${optionAddressOrType} - ${value}`);
};

const buildOwnedRealEstatesOptions = (realEstates) =>
  realEstates?.filter(item => item.transactionType === TRANSACTION_TYPES.OWNS)
    .map(({ id, address, value }) => ({
      id,
      name: buildSecuredByOptionName(getDisplayAddress(address), formatAmount(value)),
    })) ?? [];

const buildNonRealEstatesOptions = (nonRealEstates) =>
  nonRealEstates?.map(({ id, assetType, value }) => ({
      id,
      name: buildSecuredByOptionName(findAssetTypeLabelByValue(assetType), formatAmount(value)),
    })) ?? [];

const securedByValueRender = (value, options) =>
  (value?.length > 1 ? 'Multiple' : options.find(option => value[0]?.relatedAssetId === option.id)?.name);

const getClearingStatusOptions = (liabilityType) => (
  liabilityType === liabilityTypes.MortgageLoan || liabilityType === liabilityTypes.LineOfCredit
    ? clearingStatusWithTopUpOptions
    : clearingStatusWithoutTopUpOptions
);

const Liability = ({
                     namePrefix, lenders, assets, liabilityType, clearingStatus, clearFields, handleRemove, readOnly,
                     ownerships, businessId, allApplicants, lender, allOtherExistingLoanIds,
                 }) => {
  const lenderOptions = useMemo(() => orderBy(uniqBy(lenders, 'name'), 'name')
    .map(lenderItem => ({ label: lenderItem.name, value: lenderItem.id })), [lenders]);

  const selectedLenderId = useMemo(() => find(lenders, { name: lender, category: LOAN_TYPES.Home })?.id,
    [lender, lenders]);

  const securedByOptions = useMemo(() => concat(
    buildOwnedRealEstatesOptions(assets.realEstates), buildNonRealEstatesOptions(assets.nonRealEstates),
    ), [assets]);

  const clearFieldsForClearingStatus = useMemo(() => status => {
    if (status === clearingStatuses.Full) {
      clearFields(clearFieldsMap.clearingStatusFields);
    } else if (status === clearingStatuses.No) {
      clearFields(clearFieldsMap.clearingAmount);
    }
  }, [clearFields]);

  const clearFieldsForLiabilityType = useMemo(() => type => {
    if (type !== liabilityTypes.Other) {
      clearFields(clearFieldsMap.otherTypeFields);
    }
    if (type !== liabilityTypes.MortgageLoan) {
      clearFields(clearFieldsMap.mortgageLoanFields);
    }
    if (type !== liabilityTypes.MortgageLoan
      && type !== liabilityTypes.OtherLoan
      && type !== liabilityTypes.PersonalLoan
      && type !== liabilityTypes.TermLoan) {
      clearFields(clearFieldsMap.loanFields);
    }
    if (type === liabilityTypes.CreditCard || type === liabilityTypes.StoreCard) {
      clearFields(clearFieldsMap.cardFields);
    }
    if (type !== liabilityTypes.MortgageLoan && type !== liabilityTypes.LineOfCredit) {
      clearFields(clearFieldsMap.existingLoanFields);
    }
  }, [clearFields]);

  useEffect(() => {
    if (clearingStatus === clearingStatuses.TopUp
      && liabilityType !== liabilityTypes.LineOfCredit
      && liabilityType !== liabilityTypes.MortgageLoan) {
      clearFields(clearFieldsMap.liabilityTypeFields);
    }
  }, [clearingStatus, liabilityType, clearFields]);

  const clearExistingLoan = useMemo(() => () => { clearFields(clearFieldsMap.existingLoanFields); },
    [clearFields]);

  const readOnlyFormatter = values => {
    const selectedOptionsIds = map(values, 'relatedAssetId');
    const selectedOptions = securedByOptions.filter(option => selectedOptionsIds.includes(option.id));
    return (
      <div className={styles.readOnlyOptions}>
        {selectedOptions.map(({ name, id }) => (
          <div key={id}>{`${name};`}</div>
        ))}
      </div>
    );
  };

  const populateLoanDetailsToggleOn = useToggleCheck(TOGGLES.POPULATE_LOAN_DETAILS);

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <Field
          name={`${namePrefix}.type`}
          component={ReduxFormSelect}
          onChange={clearFieldsForLiabilityType}
          menuPlacement="auto"
          props={{
            label: 'Liability type',
            className: styles.typeField,
            options: typeOptions,
          }}
        />
        <Field
          name={`${namePrefix}.accountDetails.lender`}
          component={ReduxFormAutocomplete}
          props={{
            label: 'Lender',
            className: styles.lenderField,
            items: lenderOptions,
          }}
          customOnChange={clearExistingLoan}
        />
        <Field
          component={renderSecuredByCheckBoxDropDown}
          name={`${namePrefix}.securedBy`}
          label="Secured by"
          options={securedByOptions}
          className={styles.securedByField}
          inputProps={{ className: styles.input }}
          valueRender={securedByValueRender}
          buttonText="Select"
          readOnlyFormatter={readOnlyFormatter}
          flipToggle
        />
        <Field
          name={`${namePrefix}.ownerships`}
          component={Ownerships}
          props={{
            className: styles.ownershipsField,
          }}
          onChange={clearExistingLoan}
        />
        <Field
          name={`${namePrefix}.currentBalance`}
          component={ReduxFormNumberInput}
          props={{
            label: 'Outstanding balance',
            className: classNames(
              styles.amountField,
              styles.row1Column8,
            ),
            textClassName: styles.readOnlyNumber,
            decimalScale: 2,
            placeholder: '$',
            prefix: '$',
            allowNegative: false,
          }}
        />
        <Field
          name={`${namePrefix}.limit`}
          component={ReduxFormNumberInput}
          props={{
            label: 'Loan limit',
            className: classNames(
              styles.amountField,
              styles.row1Column9,
            ),
            textClassName: styles.readOnlyNumber,
            decimalScale: 2,
            placeholder: '$',
            prefix: '$',
            allowNegative: false,
          }}
        />
        <Field
          name={`${namePrefix}.clearingStatus`}
          component={ReduxFormSelect}
          onChange={clearFieldsForClearingStatus}
          props={{
            label: 'Pay out/top up',
            className: styles.toBePaidOutField,
            options: getClearingStatusOptions(liabilityType),
          }}
        />
        {liabilityType === liabilityTypes.Other && (
          <Field
            name={`${namePrefix}.description`}
            component={ReduxFromTextInput}
            props={{
              label: 'Description',
              className: styles.descriptionField,
            }}
          />
        )}
        {liabilityType
          && liabilityType !== liabilityTypes.CreditCard
          && liabilityType !== liabilityTypes.StoreCard && (
            <Field
              name={`${namePrefix}.paymentType`}
              component={ReduxFormSelect}
              props={{
                label: 'Repayment type',
                className: styles.repaymentTypeField,
                options: repaymentTypeOptions,
              }}
            />
          )}
        {liabilityType
          && liabilityType !== liabilityTypes.CreditCard
          && liabilityType !== liabilityTypes.StoreCard && (
            <Field
              name={`${namePrefix}.interestType`}
              component={ReduxFormSelect}
              props={{
                label: 'Interest type',
                className: styles.interestTypeField,
                options: interestTypeOptions,
              }}
            />
          )}
        {liabilityType
          && liabilityType !== liabilityTypes.CreditCard
          && liabilityType !== liabilityTypes.StoreCard && (
            <Field
              name={`${namePrefix}.originalTermYears`}
              component={OriginalTermField}
            />
          )}
        {!!liabilityType && (
          <Field
            name={`${namePrefix}.taxDeductible`}
            component={ReduxFormYesNoRadioGroup}
            props={{
              label: 'Tax deductible',
              className: styles.taxDeductibleField,
            }}
          />
        )}
        {clearingStatus && (
          <Field
            name={`${namePrefix}.interestRatePercent`}
            component={ReduxFormNumberInput}
            props={{
              label: 'Interest rate',
              className: styles.interestRateField,
              decimalScale: 2,
              allowNegative: false,
              suffix: '%',
              placeholder: '%',
              isAllowed: (value) => value === undefined || value <= 100,
              readOnlyFormatter: (value) => isNumber(value) && `${value}%`,
            }}
          />
        )}
        {clearingStatus && (
          <Field
            name={`${namePrefix}.repayments.value`}
            component={ReduxFormNumberInput}
            props={{
              label: 'Repayments',
              className: styles.repaymentsField,
              textClassName: styles.readOnlyNumber,
              decimalScale: 2,
              placeholder: '$',
              prefix: '$',
              allowNegative: false,
            }}
          />
        )}
        {clearingStatus && (
          <Field
            name={`${namePrefix}.repayments.periodUnit`}
            component={ReduxFormSelect}
            props={{
              label: 'Frequency',
              className: styles.frequencyField,
              options: frequencyOptions,
            }}
          />
        )}
        {clearingStatus && clearingStatus === clearingStatuses.Partial && (
          <Field
            name={`${namePrefix}.clearingAmount`}
            component={ReduxFormNumberInput}
            props={{
              label: 'Clearing amount',
              className: styles.clearingAmount,
              textClassName: styles.readOnlyNumber,
              decimalScale: 2,
              placeholder: '$',
              prefix: '$',
              allowNegative: false,
            }}
          />
        )}
        {liabilityType
        && (liabilityType === liabilityTypes.MortgageLoan
          || liabilityType === liabilityTypes.LineOfCredit) && (
          populateLoanDetailsToggleOn ? (
            <Fields
              names={[
                `${namePrefix}.existingLoan`,
                `${namePrefix}.accountDetails.lender`,
                `${namePrefix}.limit`,
                `${namePrefix}.paymentType`,
                `${namePrefix}.interestType`,
                `${namePrefix}.originalTermYears`,
              ]}
              component={ExistingLoanSelect}
              props={{
                namePrefix,
                label: 'Existing Smartline loan',
                className: styles.existingLoan,
                businessId,
                allApplicants,
                ownerships,
                lenderId: selectedLenderId,
                allOtherExistingLoanIds,
              }}
            />
          ) : (
            <Field
              name={`${namePrefix}.existingLoan`}
              component={ExistingLoanSelector}
              onChange={clearFieldsForClearingStatus}
              props={{
                label: 'Existing Smartline loan',
                className: styles.existingLoan,
                businessId,
                allApplicants,
                ownerships,
                lenderId: selectedLenderId,
                allOtherExistingLoanIds,
              }}
            />
          )
        )}
        {clearingStatus && (
          <Field
            name={`${namePrefix}.remainingTermMonths`}
            label="Remaining term"
            component={RemainingTermFields}
            liabilityType={liabilityType}
          />
        )}
        {liabilityType
          && liabilityType !== liabilityTypes.CreditCard
          && liabilityType !== liabilityTypes.StoreCard && (
            <Field
              name={`${namePrefix}.accountDetails.accountName`}
              component={ReduxFromTextInput}
              props={{
                label: 'Account name',
                className: styles.accountNameField,
              }}
            />
          )}
        {liabilityType
          && (liabilityType === liabilityTypes.MortgageLoan
            || liabilityType === liabilityTypes.OtherLoan
            || liabilityType === liabilityTypes.PersonalLoan
            || liabilityType === liabilityTypes.TermLoan) && (
            <Field
              name={`${namePrefix}.accountDetails.bsb`}
              component={ReduxFromTextInput}
              validate={bsbValidate}
              props={{
                label: 'BSB',
                className: styles.bsbField,
                isAllowed: isAllowedNumber,
                maxLength: 6,
                readOnlyFormatter: (value) => value,
              }}
            />
          )}
        {liabilityType
          && liabilityType !== liabilityTypes.CreditCard
          && liabilityType !== liabilityTypes.StoreCard && (
            <Field
              name={`${namePrefix}.accountDetails.accountNumber`}
              component={ReduxFromTextInput}
              props={{
                label: 'Account number',
                className: styles.accountNumberField,
              }}
            />
          )}
      </div>
      {
        !readOnly && (
          <div className={styles.buttonRow}>
            <TertiaryButton className={styles.removeButton} onClick={handleRemove}>
              Remove
            </TertiaryButton>
          </div>
        )
      }
    </div>
  );
};

Liability.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  lenders: PropTypes.array.isRequired,
  liabilityType: PropTypes.string,
  clearingStatus: PropTypes.string,
  clearFields: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  assets: PropTypes.object,
  allApplicants: PropTypes.shape({
    persons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  ownerships: PropTypes.arrayOf(PropTypes.shape({
    applicantId: PropTypes.string.isRequired,
  })).isRequired,
  lender: PropTypes.string,
  allOtherExistingLoanIds: PropTypes.arrayOf(PropTypes.string),
};

Liability.defaultProps = {
  liabilityType: undefined,
  clearingStatus: undefined,
  assets: {},
  lender: undefined,
  allOtherExistingLoanIds: [],
};

export default connect((state, { namePrefix, allNamePrefixes }) => ({
  lenders: state.lenders?.lenders ?? [],
  assets: state.application.applicationDetail.finance?.assets ?? {},
  liabilityType: selector(state, `${namePrefix}.type`),
  clearingStatus: selector(state, `${namePrefix}.clearingStatus`),
  lender: selector(state, `${namePrefix}.accountDetails.lender`),
  ownerships: selector(state, `${namePrefix}.ownerships`),
  businessId: state.business?.selectedBusiness?.id,
  allApplicants: getAllApplicants(state),
  allOtherExistingLoanIds: allNamePrefixes?.filter(name => name !== namePrefix)
    .map(fieldName => selector(state, `${fieldName}.existingLoan.id`))
    .filter(id => !isNil(id)),
}), (dispatch, props) => ({
  clearFields: (fields = [], value = null) =>
    fields.map(field => dispatch(change('liabilitiesForm', `${props.namePrefix}.${field}`, value))),
}))(Liability);
