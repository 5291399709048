import { change, Field } from 'redux-form';
import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import ReduxFormTextInput from 'shared/components/formFields/ReduxForm/TextInput';
import { ADDRESS_TYPE, STATE_OPTIONS } from '../../../shared/AddressInput/constants';
import styles from '../PurchasingAsset.module.scss';

const PreApprovalAddressFields = (props) => {
  const { fieldNamePrefix, selector, securityForm } = props;
  const reduxDispatch = useDispatch();
  const address = useSelector(state => selector(state, `${fieldNamePrefix}.address`));
  const countryCode = useSelector(state => selector(state, `${fieldNamePrefix}.address.countryCode`));

  const renderState = useMemo(() => (field) => (countryCode === 'AU' || isNil(countryCode)
    ? <ReduxFormSelect {...field} className={styles.state} options={STATE_OPTIONS} />
    : <ReduxFormTextInput {...field} className={styles.state} />), [countryCode]);

  const onPreApprovalAddressChange = useMemo(() => () => {
    if (isEmpty(address)) {
      reduxDispatch(change(securityForm, `${fieldNamePrefix}.address`, {
        countryCode: 'AU',
        addressType: ADDRESS_TYPE.NON_STANDARD,
      }));
    }
  }, [address, fieldNamePrefix, reduxDispatch, securityForm]);

  return (
    <>
      <Field
        name={`${fieldNamePrefix}.address.state`}
        onChange={onPreApprovalAddressChange}
        component={renderState}
        menuPlacement="auto"
        label="State"
      />
      <Field
        name={`${fieldNamePrefix}.address.postcode`}
        onChange={onPreApprovalAddressChange}
        component={ReduxFormTextInput}
        label="Postcode"
        className={styles.postcode}
      />
    </>
  );
};

PreApprovalAddressFields.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  securityForm: PropTypes.string.isRequired,
};
export default PreApprovalAddressFields;
