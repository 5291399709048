import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import FieldsContainer from 'shared/components/FieldsContainer/FieldsContainer';
import { toDollarAmountWithDecimal } from 'shared/formatterUtils';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import SubSection from '../components/SubSection';
import SubTotalSection from '../components/SubTotalSection';
import { liabilityTypes, typeOptions } from '../../Liabilities/constants';
import styles from '../styles.module.scss';
import { getLiabilitiesBasicValues } from '../../../redux/selectors/liabilities';
import { getOtherFields } from '../contentUtils';
import { calculateTotalRefinances } from '../../../redux/selectors/fundingWorksheet/utils';

const getFields = (refinance, liabilities) => {
  const liability = liabilities[refinance.liabilityId];
  const commonFields = [{
    title: 'Lender',
    content: liability.lender,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }, {
    title: 'Liability type',
    content: typeOptions.find(option => option.value === liabilityTypes[liability.type])?.label,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }, {
    title: 'Clearing amount',
    content: liability.clearingAmount ? toDollarAmountWithDecimal(liability.clearingAmount) : null,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  }];
  const dischargeFeeField = {
    title: 'Discharge fee',
    content: refinance.dischargeFee ? toDollarAmountWithDecimal(refinance.dischargeFee) : null,
    showSideBar: false,
    contentAlignRight: true,
    fieldWrapperClassName: styles.fieldWrapperClassName,
  };
  const otherCostFields = getOtherFields(refinance.others);

  const showDischargeFee = [liabilityTypes.LineOfCredit, liabilityTypes.MortgageLoan].includes(liability.type);

  return showDischargeFee
    ? [...commonFields, dischargeFeeField, ...otherCostFields]
    : [...commonFields, ...otherCostFields];
};

const ViewRefinances = ({ refinances, liabilities, userGuidanceCode }) => {
  const total = useMemo(
    () => calculateTotalRefinances(refinances, liabilities),
    [refinances, liabilities],
  );

  if (isEmpty(refinances)) {
    return null;
  }

  return (
    <InformationSection
      title="Refinance and debt consolidation"
      wrapperStyle={styles.infoSection}
      contentWrapperStyle={styles.infoSectionContent}
      titleWrapperStyle={styles.titleWrapperStyle}
    >
      {refinances.map((refinance) => (
        <SubSection
          key={refinance.id}
          className={styles.refinanceSubSection}
        >
          <FieldsContainer
            fields={(getFields(refinance, liabilities))}
            fieldsWrapperClassName={styles.fieldsWrapper}
            className={styles.readonlyFieldsContainer}
          />
        </SubSection>
      ))}
      <SubTotalSection total={total} title={`Refinance/debt consolidation (${userGuidanceCode})`} />
    </InformationSection>
  );
};

ViewRefinances.defaultProps = {
  userGuidanceCode: '',
};

ViewRefinances.propTypes = {
  refinances: PropTypes.array.isRequired,
  liabilities: PropTypes.object.isRequired,
  userGuidanceCode: PropTypes.string,
};

const mapStateToProps = (state) => {
  const refinances = get(state, 'application.applicationDetail.fundingWorksheet.refinances', []) || [];
  const liabilities = getLiabilitiesBasicValues(state);
  return {
    refinances: refinances.map(refinance => pick(refinance,
      ['id', 'liabilityId', 'dischargeFee', 'others'])),
    liabilities,
  };
};

export default connect(mapStateToProps)(ViewRefinances);
