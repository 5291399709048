import React, {
 useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import some from 'lodash/some';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import every from 'lodash/every';
import isNumber from 'lodash/isNumber';
import RetryComponent from 'shared/components/RetryComponent';
import LoadingSpinner from 'shared/components/LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import EmptyResult from 'shared/components/EmptyResult';
import MobiusTable from 'shared/components/MobiusTable/MobiusTable';
import SplitDisclaimer from 'shared/components/productSearch/SplitDisclaimer/SplitDisclaimer';
import CalculationExplanation from 'shared/components/productSearch/CalculationExplanation';
import styles from './styles.module.scss';
import { FETCHING_STATUS } from '../../../../../../constants';
import { ServiceabilityDetailModalProvider } from './ServiceabilityDetailModalContext';
import calculateColumns from './calculateColumns';
import CompareButton from './CompareButton';
import { SEARCH_TRIGGERS } from '../utils';

const MAX_COMPARISON_NUMBER = 5;

const getRowId = row => row.id;

const getProductsCovertDiscountMonth = (products) => products.map(product => ({
  ...product,
  variants: product?.variants.map(variant => ({
    ...variant,
    discountMonths: isNil(variant.discountRate) ? 'N/A' : variant.discountMonths,
  })),
}));

const productsSearchSelector = state => get(state, 'application.productSearch', {});

const tableClassNames = {
  cell: styles.cell,
  headerCell: styles.headerCell,
  sortingCell: styles.sortingCell,
  sortingHeaderCell: styles.sortingHeaderCell,
  primativeCell: styles.primativeCell,
};

const ProductsTable = ({ onSelectTotalCostTerm, onSelectRepaymentFrequency, onStartCompare }) => {
  const {
    products: searchedProducts,
    customProduct,
    fetchingStatus,
    searchCriteria,
    serviceabilityInfos,
    searchTrigger,
  } = useSelector(productsSearchSelector);

  const serviceabilityInfosRef = useRef();
  useEffect(() => {
    serviceabilityInfosRef.current = serviceabilityInfos;
  }, [serviceabilityInfos]);
  window.serviceabilityInfosRef = serviceabilityInfosRef;

  const isSplitSearch = get(searchCriteria, 'loansCriteria.length', 0) > 1;
  const [selection, setSelection] = useState([]);
  const selectionRef = useRef();
  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);
  const products = useMemo(
    () => (customProduct ? concat(customProduct, searchedProducts) : searchedProducts),
    [customProduct, searchedProducts],
  );

  const clickLenderNameHandlerCreator = useCallback((product) => () => {
    const currentSelection = selectionRef.current;
    if (currentSelection.includes(product.id)) {
      setSelection(currentSelection.filter(it => it !== product.id));
    } else if (currentSelection.length < MAX_COMPARISON_NUMBER) {
      setSelection(concat(currentSelection, product.id));
    }
  }, [setSelection]);

  const [columns, setColumns] = useState([]);

  const anySplitted = useMemo(() => products.some(p => (p.isSplitted)), [products]);
  const totalCostSortable = useMemo(() => products.some(p => isNumber(p.totalCost)), [products]);
  const totalRepaymentSortable = useMemo(() => products.some(p => isNumber(p.totalRepayment)), [products]);
  const maximumBorrowingCapacitySortable = useMemo(
    () => {
      const isServiceabilityRequestsFinished = every(
        serviceabilityInfos,
        (serviceability) => ![FETCHING_STATUS.START, FETCHING_STATUS.INIT].includes(serviceability?.fetchingStatus),
      );
      const hasValidServiceabilityResult = some(
        serviceabilityInfos,
        (serviceability) => isNumber(serviceability?.data?.maximumLoanAmount),
      );
      return isServiceabilityRequestsFinished && hasValidServiceabilityResult;
    },
    [serviceabilityInfos],
  );

  useEffect(
    () => {
      const params = {
        anySplitted,
        totalCostSortable,
        totalRepaymentSortable,
        maximumBorrowingCapacitySortable,
        clickLenderNameHandlerCreator,
        onSelectTotalCostTerm,
        onSelectRepaymentFrequency,
        serviceabilityInfosRef,
      };
      setColumns(calculateColumns(params));
    },
    [clickLenderNameHandlerCreator, onSelectTotalCostTerm, onSelectRepaymentFrequency,
      products, maximumBorrowingCapacitySortable, setColumns,
      anySplitted, totalCostSortable, totalRepaymentSortable],
  );

  const isLoading = fetchingStatus === FETCHING_STATUS.START;

  const totalCostColumnSortable = useMemo(
    () => some(columns, {
      name: 'totalCost',
      allowSorting: true,
    }),
    [columns],
  );

  const defaultSorting = useMemo(
    () => (totalCostColumnSortable
      ? [{
        columnName: 'totalCost',
        direction: 'asc',
      }]
      : []),
    [totalCostColumnSortable],
  );

  const handleStartCompare = useCallback(() => {
    const selectedProducts = selection.map(id => find(products, { id })).filter(it => !isNil(it));
    onStartCompare(selectedProducts);
  }, [onStartCompare, products, selection]);

  const tableKey = useMemo(() => uniqueId(`${totalCostColumnSortable}${columns.length}`), [columns.length, totalCostColumnSortable]);

  if (fetchingStatus === FETCHING_STATUS.INIT) {
    return null;
  }

  if (isLoading && searchTrigger === SEARCH_TRIGGERS.CRITERIA_FORM) {
    return <LoadingSpinner />;
  }

  if (fetchingStatus === FETCHING_STATUS.ERROR) {
    return <RetryComponent />;
  }

  if (isEmpty(products) && !isLoading) {
    return <EmptyResult />;
  }

  const effectiveProducts = getProductsCovertDiscountMonth(products);

  return (
    <ServiceabilityDetailModalProvider>
      <div className={styles.title}>
        <CompareButton comparisonSize={selection.length} onViewComparison={handleStartCompare} />
        {searchedProducts.length >= 100 && <div className={styles.titleHead}>Top 100 loans</div>}
      </div>
      <CalculationExplanation />
      <SplitDisclaimer isSplitSearch={isSplitSearch} />
      <MobiusTable
        key={tableKey}
        classNames={tableClassNames}
        className={styles.table}
        rows={effectiveProducts}
        columns={columns}
        isLoading={isLoading}
        virtual
        withSelection
        onSelectionChange={setSelection}
        getRowId={getRowId}
        selection={selection}
        maxSelectionCount={MAX_COMPARISON_NUMBER}
        defaultSorting={defaultSorting}
      />
    </ServiceabilityDetailModalProvider>
  );
};

ProductsTable.propTypes = {
  onStartCompare: PropTypes.func.isRequired,
  onSelectTotalCostTerm: PropTypes.func.isRequired,
  onSelectRepaymentFrequency: PropTypes.func.isRequired,
};

export default React.memo(ProductsTable);
