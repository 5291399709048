import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import ReduxFormSelect from 'shared/components/formFields/ReduxForm/Select';
import styles from '../RealEstateAsset.module.scss';
import { INVESTMENT, PRIMARY_PURPOSE_OPTIONS } from '../constants';

const PrimaryPurposeField = (fields) => {
  const {
    names: [primaryPurposeName,
      rentalIncomePeriodUnit, rentalIncomeRentalAmount,
      investmentPropertyCostFrequency, investmentPropertyCostValue],
    onPurposeChange,
  } = fields;
  return (
    <ReduxFormSelect
      {...get(fields, primaryPurposeName)}
      label="Purpose"
      className={classNames(styles.field, styles.primaryPurposeField)}
      options={PRIMARY_PURPOSE_OPTIONS}
      onChange={(value) => {
        get(fields, primaryPurposeName).input.onChange(value);
        if (value !== INVESTMENT) {
          get(fields, rentalIncomePeriodUnit).input.onChange(null);
          get(fields, rentalIncomeRentalAmount).input.onChange(null);
          get(fields, investmentPropertyCostFrequency).input.onChange(null);
          get(fields, investmentPropertyCostValue).input.onChange(null);
        }
        onPurposeChange(value);
      }}
    />
  );
};

PrimaryPurposeField.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPurposeChange: PropTypes.func.isRequired,
};

export default PrimaryPurposeField;
