import { createReducer } from 'utils/reducerGenerator';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import filterFn from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';
import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import concat from 'lodash/concat';
import { FETCHING_STATUS } from '../../../../constants';
import types from './constants';
import {
  applicationDisplayStatus, applicationStatus, applicationStatusKeys,
  statusNameMapper,
} from '../../../constants/applicationStatus';
import { getCombinedApplicants, sortApplicantListByPrimaryAndName } from '../../../utils';

export const formatApplicationSwimLanes = (applications) => {
  const appsWithDisplayStatus = applications.map(app => ({
    ...app,
    applicants: sortApplicantListByPrimaryAndName(getCombinedApplicants(get(app, 'applicants', {}))),
    originalApplicants: get(app, 'applicants', {}),
    displayStatus: get(statusNameMapper, [get(app, 'applicationStatus.status')], applicationDisplayStatus.DRAFT),
  }));

  const groupByDisplayStatus = groupBy(appsWithDisplayStatus, 'displayStatus');

  return reduce(keys(applicationDisplayStatus), (res, displayStatusKey) => {
    const displayStatus = applicationDisplayStatus[displayStatusKey];
    const listOfApplications = get(groupByDisplayStatus, displayStatus, []);

    res[displayStatus] = orderApplicationsByApplicant(listOfApplications);

    return res;
  }, {});
};

const getSwimLanesAfterAppMoves = (swimLanes, applicationId, dest, statusDateTime) => {
  const newStatus = dest.startsWith('DECLINED') ? 'DECLINED' : dest;
  const application = find(flatten(map(swimLanes)), { id: applicationId });
  return mapValues(swimLanes, (applications, statusType) => {
    if (statusType === applicationDisplayStatus[dest]) {
      return orderApplicationsByApplicant(uniqBy([...(applications.filter(app => app.id !== applicationId)), {
        ...application,
        settledDate: dest === applicationStatus.SETTLED ? statusDateTime : undefined,
        applicationStatus: {
          status: newStatus,
        },
      }], 'id'));
    }
    return applications.filter(it => (it.id !== applicationId));
  });
};

export const orderApplicationsByApplicant = (applications) => {
  const applicantNameToOrder = applicants => applicants.filter(applicant => !!applicant.isPrimary)[0] || applicants[0];
  return orderBy(applications, application => applicantNameToOrder(application.applicants)?.name, ['asc']);
};

const emptySwimLanes = {
  [applicationDisplayStatus.DRAFT]: [],
  [applicationDisplayStatus.SUBMITTED]: [],
  [applicationDisplayStatus.DECLINED_OR_WITHDRAWN]: [],
  [applicationDisplayStatus.CONDITIONALLY_APPROVED]: [],
  [applicationDisplayStatus.UNCONDITIONALLY_APPROVED]: [],
  [applicationDisplayStatus.SETTLED]: [],
};

const initValue = {
  fetchingStatus: FETCHING_STATUS.INIT,
  creatingStatus: FETCHING_STATUS.INIT,
  swimLanes: emptySwimLanes,
  filter: {
    conditions: {
      applicant: {
        label: 'Any',
        value: '',
      },
      lender: {
        label: 'Any',
        value: '',
      },
      applicationNumber: {
        label: 'Any',
        value: '',
      },
      adviser: {
        label: 'Any',
        value: '',
      },
    },
    options: {},
  },
  clientsFetchingStatus: FETCHING_STATUS.INIT,
  borrowingGroupFetchingStatus: FETCHING_STATUS.INIT,
  clients: [],
  borrowingGroup: {},
};

const valuesToOptions = (values) => {
  const result = uniq(values.filter(v => (!isEmpty(v))))
    .sort()
    .map(v => ({
      value: v,
      label: v,
    }));
  result.unshift({
    label: 'Any',
    value: '',
  });
  return result;
};

const generateFilterOptions = (data) => {
  const personApplicants = map(data, 'applicants.persons');
  const companyApplicants = map(data, 'applicants.companies');
  const applicantOptions = uniqBy(flatten(concat(personApplicants, filterFn(companyApplicants, c => c !== undefined))), 'name')
    .map(it => ({
      value: it.name,
      label: it.name,
    }));
  const adviserOptions = map(uniqBy(data, 'adviser.id'), it => ({
    label: `${it.adviser.surname}, ${it.adviser.firstName}`,
    value: it.adviser.id,
  }));
  return {
    applicant: [{
      label: 'Any',
      value: '',
    }, ...sortBy(applicantOptions, 'label')],
    lender: valuesToOptions(map(data, 'lender.name')),
    applicationNumber: valuesToOptions(map(data, 'applicationNumber')),
    adviser: [{
      label: 'Any',
      value: '',
    }, ...sortBy(adviserOptions, 'label')],
  };
};

const normalizeConditions = (options, conditions) => mapValues(
  conditions,
  (selection, key) => find(
    options[key],
    { value: get(selection, 'value', null) },
  ) || {
    label: 'Any',
    value: '',
  },
);

const getAppStatusDateTimeUpdatedSwimLanes = (swimLanes, appStatusChangePayload) => {
  const { applicationId, milestoneType, statusDateTime } = appStatusChangePayload;
  const isDeclinedOrWithdrawn = milestoneType === applicationStatus.WITHDRAWN
    || milestoneType === applicationStatus.DECLINED;
  const dest = isDeclinedOrWithdrawn
    ? applicationStatusKeys.DECLINED_OR_WITHDRAWN
    : applicationStatusKeys[milestoneType];
  return getSwimLanesAfterAppMoves(swimLanes, applicationId, dest, statusDateTime);
};

export default createReducer(initValue, {
  [types.CREATE_APPLICATION_START]: state => ({
    ...state,
    creatingStatus: FETCHING_STATUS.START,
  }),
  [types.CREATE_APPLICATION_SUCCESS]: state => ({
    ...state,
    creatingStatus: FETCHING_STATUS.SUCCESS,
  }),
  [types.CREATE_APPLICATION_ERROR]: state => ({
    ...state,
    creatingStatus: FETCHING_STATUS.ERROR,
  }),
  [types.RETRIEVE_APPLICATIONS_START]: state => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
    swimLanes: emptySwimLanes,
  }),
  [types.RETRIEVE_APPLICATIONS_SUCCESS]: (state, action) => {
    const { filter } = state;
    const { payload: { applications } } = action;
    const options = generateFilterOptions(applications);
    return {
      ...state,
      fetchingStatus: FETCHING_STATUS.SUCCESS,
      swimLanes: formatApplicationSwimLanes(applications),
      filter: {
        ...filter,
        options,
        conditions: normalizeConditions(options, filter.conditions),
      },
    };
  },
  [types.RETRIEVE_APPLICATIONS_ERROR]: state => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
    swimLanes: emptySwimLanes,
  }),
  [types.CHANGE_CONDITIONS]: (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      conditions: {
        ...state.filter.conditions,
        ...action.conditions,
      },
    },
  }),
  [types.CLEAN_CONDITIONS]: state => ({
    ...state,
    filter: {
      ...state.filter,
      conditions: initValue.filter.conditions,
    },
  }),
  [types.CLEAN_CLIENTS]: state => {
    const newState = omit(state, 'clients');
     return {
       ...newState,
       clientsFetchingStatus: FETCHING_STATUS.INIT,
     };
  },
  [types.MOVE_APPLICATIONS]: (state, { applicationId, dest }) => ({
    ...state,
    swimLanes: getSwimLanesAfterAppMoves(state.swimLanes, applicationId, dest),
  }),
  [types.SAVE_APPLICATION_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    swimLanes: getAppStatusDateTimeUpdatedSwimLanes(state.swimLanes, payload),
  }),
  [types.GET_CLIENTS_START]: (state) => ({
    ...state,
    clientsFetchingStatus: FETCHING_STATUS.START,
  }),

  [types.GET_CLIENTS_SUCCESS]: (state, { data }) => ({
    ...state,
    clients: data,
    clientsFetchingStatus: FETCHING_STATUS.SUCCESS,
  }),

  [types.GET_CLIENTS_FAIL]: (state) => ({
    ...state,
    clients: [],
    clientsFetchingStatus: FETCHING_STATUS.ERROR,

  }),
  [types.GET_ACTION_TIME_MAP_SUCCESS]: (state, { application }) => ({
    ...state,
    actionTimelineMap: application.applicationStatus.actionTimelineMap,
  }),
});
