import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Box.module.scss';

export const Box = ({ children, curvy, className }) => (
  <div className={classNames(className, styles.box, {
    [styles.curvy]: curvy,
  })}
  >
    {children}
  </div>
);

Box.defaultProps = {
  curvy: false,
  className: undefined,
};

Box.propTypes = {
  curvy: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
